import {
  SCALE_TYPE,
  calcAlignedPosition,
  calcBorderInPx,
  convertCmToInch,
  convertInchToCm,
  LENGTH_UNIT
} from '@prodigi-group/components-image-editor'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ArtworkInterface,
  ArtworkProgressType,
  ArtworkTransformationsInterface,
  BorderUnitType,
  DimensionsInterface,
  ImageEditorTransformationsInterface,
  PointInterface,
  ScaleType
} from '../interfaces'
import { calculatePrintQuality, calculateScaleFactorAdjustment, convertPositionToPrintPx } from '../helpers'
import {
  DictionaryInterface,
  FetchErrorInterface,
  PrintAreaNameType,
  StatusType,
  V1DashboardArtworkInterface
} from '../../../interfaces'
import { createToast } from '../../Toast'
import AlertPanel from '../../AlertPanel'
import OverlayPortal from '../../OverlayPortal'
import { transformImage } from '../../../helpers'
import LoadingSpinner from '../../LoadingSpinner'
import { PdfPreview } from './PdfPreview.component'
import styles from './MultiAssetImageEditor.module.css'
import MockupPreview from '../../ImageEditorMockupPreview'
import { PrintAreaToggle } from './PrintAreaToggle.component'
import { BASE_64_TRANSPARENT_IMAGE } from '../../../constants'
import ImageEditorPreview from './ImageEditorPreview.component'
import { AddImageFromLibrary } from './AddImageFromLibrary.component'
import { AlignmentPointType } from '../interfaces/AlignmentPoint.type'
import { MockupPollCompleteInterface } from './MockupsPolling.component'
import { ImageEditorSaveButton } from './ImageEditorSaveButton.component'
import { calculateFitScaleFactor } from '../helpers/calculateFitScaleFactor.helper'
import { calculateFillScaleFactor } from '../helpers/calculateFillScaleFactor.helper'
import { ImageLibraryConfigPropInterface } from '../../ImageLibrary/ImageLibrary.component'
import { selectFirstAvailableTemplate } from '../helpers/selectFirstAvailableTemplate.helper'
import MultiAssetImageEditorControlPanel from './MultiAssetImageEditorControlPanel.component'
import { TemplateServiceSuccessResponseInterface } from '../../../hooks/useTemplateService.hook'
import { convertPrintPxPositionToUserUnit } from '../helpers/convertPrintPxPositionToUserUnit.helper'
import { ImageMockupInterface, TemplateDataInterface, TemplateOrientationType } from '../../../hooks'
import { addOrRemoveArtworkTransformations } from '../helpers/addOrRemoveArtworkTransformations.helper'

export interface ImageEditorSaveDataInterface {
  transformDataByPrintArea: Record<string, { transformationApiUrl: string; url: string } | undefined>
}

interface ImageEditorControllerProps {
  artworkUploadProgress: ArtworkProgressType
  className?: string
  description: string
  enableImageLibraryThumbnailRefresh?: boolean
  imageLibraryConfig?: ImageLibraryConfigPropInterface
  isSaving?: boolean
  itemCategory: string
  productType: string
  printAreas: Record<
    PrintAreaNameType,
    {
      artwork?: ArtworkInterface
      artworkTransformations?: ArtworkTransformationsInterface
    }
  >
  productSku: string
  selectedAttributes: DictionaryInterface<string>
  selectedPrintArea: string
  showRemoveArtworkButton?: boolean
  supportsPrintBorders: boolean
  supportsTiling: boolean
  templates: TemplateServiceSuccessResponseInterface
  buildImageId: (printAreaName: string) => string
  onCancel: () => void
  onOpenImageLibrary?: () => void
  onRemoveArtwork: (printAreaName: string) => void
  onSave: (
    artworkTransformations: Record<PrintAreaNameType, ArtworkTransformationsInterface | undefined | null>,
    selectedPrintArea: string,
    additionalData: ImageEditorSaveDataInterface
  ) => void
  onSelectImageLibraryImage: (artworkData: {
    artwork: V1DashboardArtworkInterface
    artworkTransformations: ArtworkTransformationsInterface
    selectedPrintArea: string
  }) => void
  resetImage: (printAreaName: string) => void
}

export interface ImageUrlsInterface {
  artwork: string
  overlay: string
  underlay?: string
}

type ImageEditorViewsType = 'design' | 'mockup'

type ImageEditorControllerState = {
  transformationId: string | null
  mockupData: ImageMockupInterface | null
  mockupGenerationStatus: StatusType
  mockupErrorState: { message: string; code: string } | null
  selectedView: ImageEditorViewsType
  isArtworkLoading: boolean
  isOverlayLoading: boolean
  isPreviewOnly: boolean
  artworkTransformations: Record<string, ArtworkTransformationsInterface | undefined | null>
  artworkPreviewDimensionsInPx: DimensionsInterface | null
  selectedPrintArea: string
  saveStatus: StatusType
  selectedMockupView: string | null
}

export const IMAGE_EDITOR_ERROR_TOAST_ID = 'prodigiImageEditorErrorToast'

export default class MultiAssetImageEditorController extends React.PureComponent<
  ImageEditorControllerProps,
  ImageEditorControllerState
> {
  state: ImageEditorControllerState = {
    transformationId: null,
    mockupGenerationStatus: 'idle',
    mockupErrorState: null,
    mockupData: null,
    selectedView: 'design',
    isArtworkLoading: true,
    isOverlayLoading: true,
    isPreviewOnly: false,
    artworkTransformations: this.getArtworkTransformations(),
    selectedPrintArea: this.props.selectedPrintArea,
    artworkPreviewDimensionsInPx: null,
    saveStatus: 'idle',
    selectedMockupView: null
  }

  componentDidUpdate(prevProps: ImageEditorControllerProps) {
    const prevTransformations = this.state.artworkTransformations
    const currentTransformations = this.getArtworkTransformations()
    const updatedTransformations = addOrRemoveArtworkTransformations(prevTransformations, currentTransformations)

    const selectedPrintArea = this.state.selectedPrintArea
    const hasArtworkChanged =
      prevProps.printAreas[selectedPrintArea].artwork?.originalImageUrl !==
      this.props.printAreas[selectedPrintArea].artwork?.originalImageUrl

    if (hasArtworkChanged) {
      this.setState({ isArtworkLoading: true })
    }

    this.setState({ artworkTransformations: updatedTransformations })
  }

  componentWillUnmount() {
    toast.dismiss(IMAGE_EDITOR_ERROR_TOAST_ID)
  }

  getArtworkTransformations(): Record<string, ArtworkTransformationsInterface | undefined | null> {
    return this.printAreaArtworkEntries.reduce(
      (
        transformationsAcc: Record<string, ArtworkTransformationsInterface | undefined | null>,
        [printAreaName, printArtworkData]
      ) => {
        transformationsAcc[printAreaName] = printArtworkData.artworkTransformations
        return transformationsAcc
      },
      {}
    )
  }

  get artworks(): Record<string, ArtworkInterface | undefined> {
    return this.printAreaArtworkEntries.reduce(
      (artworkAcc: Record<string, ArtworkInterface | undefined>, [printAreaName, printArtworkData]) => {
        artworkAcc[printAreaName] = printArtworkData.artwork
        return artworkAcc
      },
      {}
    )
  }

  get artwork(): ArtworkInterface | undefined {
    return this.artworks[this.state.selectedPrintArea]
  }

  get imageUrls(): ImageUrlsInterface {
    return {
      artwork: this.artwork?.mediumImageUrl || BASE_64_TRANSPARENT_IMAGE,
      overlay: this.printAreaTemplate.imageUrls.overlay,
      underlay: this.printAreaTemplate.imageUrls.underlay
    }
  }

  get printAreaTemplate(): TemplateDataInterface {
    const selectedPrintArea = this.selectedPrintArea
    const selectedOrientation = this.printAreaArtworkTransformations.orientation
    const template = this.props.templates.printAreas[selectedPrintArea].orientations[selectedOrientation]
    const firstAvailableTemplate = selectFirstAvailableTemplate(this.props.templates, selectedPrintArea)

    if (!firstAvailableTemplate) {
      throw Error('firstAvailableTemplate cannot be null')
    }

    return template ?? firstAvailableTemplate
  }

  get printAreaArtworkEntries(): [
    string,
    { artwork?: ArtworkInterface; artworkTransformations?: ArtworkTransformationsInterface }
  ][] {
    const printAreaEntries = Object.entries(this.props.printAreas)
    return printAreaEntries
  }

  get selectedPrintArea(): string {
    return this.state.selectedPrintArea
  }

  changeTemplateOrientation = (newOrientation: Exclude<TemplateOrientationType, 'square'>) => {
    this.setState((state) => {
      let newTransformations: ArtworkTransformationsInterface | null = null

      const currentTransformations = state.artworkTransformations[this.selectedPrintArea]
      if (currentTransformations) {
        newTransformations = { ...currentTransformations, orientation: newOrientation }
      }

      return {
        isArtworkLoading: true,
        isOverlayLoading: true,
        artworkTransformations: {
          ...state.artworkTransformations,
          [this.selectedPrintArea]: newTransformations
        }
      }
    })
  }

  get printAreaArtworkTransformations(): ArtworkTransformationsInterface {
    const artworkTransformations = this.state.artworkTransformations[this.selectedPrintArea]
    if (!artworkTransformations) {
      return {
        orientation: 'portrait',
        scaleFactor: 0,
        position: {
          x: 0,
          y: 0
        },
        rotationAngle: 0,
        borderFactor: 0,
        borderScale: 'cm',
        isTiled: false
      }
    }
    return artworkTransformations
  }

  onLoadOverlay: () => void = () => this.setState({ isOverlayLoading: false })

  onTogglePreviewMode: (isPreviewOnly: boolean) => void = (isPreviewOnly) => this.setState({ isPreviewOnly })

  onLoadArtwork = ({ detail: artworkPreviewDimensionsInPx }: CustomEvent<DimensionsInterface>) => {
    this.setState({
      isArtworkLoading: false,
      artworkPreviewDimensionsInPx
    })
  }

  changePosition = ({ detail: newPositionInUserUnit }: CustomEvent<PointInterface>) => {
    const position = convertPositionToPrintPx(
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      this.printAreaTemplate.cropRectangle,
      this.printAreaTemplate.printDpi,
      this.displayedPositionUnit,
      newPositionInUserUnit
    )
    this.setTransformations({ position })
  }

  setTransformations = (updatedTransformations: Partial<ArtworkTransformationsInterface>, afterUpdate?: () => void) => {
    if (!this.state.artworkTransformations[this.selectedPrintArea]) {
      return
    }
    this.setState((state) => {
      let newTransformations: ArtworkTransformationsInterface | null = null

      const currentTransformations = state.artworkTransformations[this.selectedPrintArea]
      if (currentTransformations) {
        newTransformations = { ...currentTransformations, ...updatedTransformations }
      }

      return {
        artworkTransformations: {
          ...state.artworkTransformations,
          [this.selectedPrintArea]: newTransformations
        }
      }
    }, afterUpdate)
  }

  onRotationChange = ({ detail: rotationAngle }: CustomEvent<number>) => {
    this.setTransformations({ rotationAngle })
  }

  changeScaleInImageEditorUnit = ({ detail: newScaleInImageEditorUnit }: CustomEvent<number>) => {
    this.setTransformations({ scaleFactor: (newScaleInImageEditorUnit / this.scaleFactorAdjustment) * 100 })
  }

  changeScaleInUserUnit = ({ detail: newScaleInUserUnit }: CustomEvent<number>) => {
    this.setTransformations({ scaleFactor: newScaleInUserUnit * 100 })
  }

  onBorderChange = ({ detail: borderFactor }: CustomEvent<number>) => {
    this.setTransformations({ borderFactor })
  }

  onAlignmentPointChange = ({ detail: alignmentPoint }: CustomEvent<AlignmentPointType>) => {
    if (!this.state.artworkPreviewDimensionsInPx) {
      throw Error('artworkPreviewDimensionsInPx cannot be null')
    }

    const updatedPositionInEditorUnit = calcAlignedPosition(
      alignmentPoint,
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      this.state.artworkPreviewDimensionsInPx,
      this.printAreaTemplate.cropRectangle,
      this.printAreaTemplate.printDpi,
      this.transformations
    )

    this.changePosition({ detail: updatedPositionInEditorUnit } as CustomEvent)
  }

  onFit: () => void = () => {
    if (!this.state.artworkPreviewDimensionsInPx) {
      throw Error('artworkPreviewDimensionsInPx cannot be null')
    }

    const borderInPx = calcBorderInPx(
      this.printAreaTemplate.printDpi,
      this.printAreaArtworkTransformations.borderFactor,
      this.displayedPositionUnit
    )

    const newScaleFactor = calculateFitScaleFactor(
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      borderInPx,
      this.transformations.rotationAngle
    )

    this.applyFitOrFillScaleFactor(newScaleFactor)
  }

  onFill: () => void = () => {
    if (!this.state.artworkPreviewDimensionsInPx) {
      throw Error('artworkPreviewDimensionsInPx cannot be null')
    }

    const borderInPx = calcBorderInPx(
      this.printAreaTemplate.printDpi,
      this.printAreaArtworkTransformations.borderFactor,
      this.displayedPositionUnit
    )

    const newScaleFactor = calculateFillScaleFactor(
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      borderInPx,
      this.transformations.rotationAngle
    )

    this.applyFitOrFillScaleFactor(newScaleFactor)
  }

  applyFitOrFillScaleFactor: (scaleFactor: number) => void = (scaleFactor: number) => {
    if (!this.state.artworkTransformations[this.selectedPrintArea]) {
      return
    }

    this.setTransformations({
      scaleFactor,
      position: {
        x: 0,
        y: 0
      }
    })
  }

  onPositionUnitChange = (newUnit: BorderUnitType) => {
    this.setTransformations({
      borderScale: newUnit,
      borderFactor:
        newUnit === LENGTH_UNIT.IN
          ? convertCmToInch(this.printAreaArtworkTransformations.borderFactor)
          : convertInchToCm(this.printAreaArtworkTransformations.borderFactor)
    })
  }

  onChangePrintArea: (selectedPrintArea: string) => void = (selectedPrintArea: string) => {
    if (selectedPrintArea === this.state.selectedPrintArea) {
      return
    }

    toast.dismiss(IMAGE_EDITOR_ERROR_TOAST_ID)
    this.setState({
      isArtworkLoading: true,
      isOverlayLoading: true,
      selectedPrintArea
    })
  }

  onRemoveArtwork: (printAreaName: string) => void = (printAreaName: string) => {
    this.props.onRemoveArtwork(printAreaName)
  }

  onToggleTiling = (isTilingOn: boolean) => {
    if (isTilingOn) {
      const newScaleFactor = this.originalFillScaleFactor < 100 ? this.originalFillScaleFactor : 100

      this.setTransformations({
        position: {
          x: 0,
          y: 0
        },
        rotationAngle: 0,
        scaleFactor: newScaleFactor,
        isTiled: true
      })
    } else {
      this.setTransformations(
        {
          rotationAngle: 0,
          isTiled: false
        },
        this.onFill
      )
    }
  }

  onRemoveCurrentPrintAreaArtwork: () => void = () => {
    this.props.onRemoveArtwork(this.state.selectedPrintArea)
  }

  get originalFillScaleFactor(): number {
    if (!this.state.artworkPreviewDimensionsInPx) {
      throw Error('artworkPreviewDimensionsInPx cannot be null')
    }

    const borderInPx = calcBorderInPx(
      this.printAreaTemplate.printDpi,
      this.printAreaArtworkTransformations.borderFactor,
      this.displayedPositionUnit
    )

    const newScaleFactor = calculateFillScaleFactor(
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      borderInPx,
      this.transformations.rotationAngle
    )

    return newScaleFactor
  }

  get imageEditorScale(): number {
    return (this.printAreaArtworkTransformations.scaleFactor * this.scaleFactorAdjustment) / 100
  }

  get scaleFactorAdjustment(): number {
    return calculateScaleFactorAdjustment(
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      this.printAreaTemplate.cropRectangle,
      this.printAreaTemplate.printDpi,
      this.printAreaArtworkTransformations.borderFactor,
      this.displayedPositionUnit
    )
  }

  get artworkDimensionsInPx(): DimensionsInterface {
    return {
      height: this.artwork?.artworkHeight || 0,
      width: this.artwork?.artworkWidth || 0
    }
  }

  get transformations(): ImageEditorTransformationsInterface {
    return {
      scaleFactor: this.imageEditorScale,
      scaleType: SCALE_TYPE.PRINT_SIZE as ScaleType,
      position: this.positionInUserUnit,
      positionUnit: this.displayedPositionUnit,
      rotationAngle: this.printAreaArtworkTransformations.rotationAngle,
      borderWidth: this.printAreaArtworkTransformations.borderFactor,
      borderUnit: this.displayedPositionUnit,
      isTiled: this.printAreaArtworkTransformations.isTiled
    }
  }

  get displayedScaleFactor(): number {
    return this.printAreaArtworkTransformations.scaleFactor / 100
  }

  get positionInUserUnit(): PointInterface {
    return convertPrintPxPositionToUserUnit(
      this.printAreaArtworkTransformations.position,
      this.displayedPositionUnit,
      this.printAreaTemplate.printResolution,
      this.artworkDimensionsInPx,
      this.printAreaTemplate.cropRectangle,
      this.printAreaTemplate.printDpi
    )
  }

  get displayedPosition(): PointInterface {
    const { x, y } = this.positionInUserUnit

    return {
      x: Number(x.toFixed(2)),
      y: Number(y.toFixed(2))
    }
  }

  get displayedPositionUnit(): BorderUnitType {
    return this.printAreaArtworkTransformations.borderScale
  }

  get imageQuality() {
    if (!this.artwork) {
      return null
    }

    return calculatePrintQuality({
      artwork: this.artwork,
      artworkTransformations: this.printAreaArtworkTransformations,
      printAreaName: this.selectedPrintArea,
      templates: this.props.templates,
      scaleType: this.transformations.scaleType
    })?.value
  }

  get showTemplateOrientationToggle(): boolean {
    const templateOrientations = this.props.templates.printAreas[this.selectedPrintArea].orientations
    const nonNullableTemplates = Object.values(templateOrientations).filter((template) => template !== null)
    const hasMoreThanOneTemplateOrientation = nonNullableTemplates.length > 1

    return hasMoreThanOneTemplateOrientation
  }

  get imageEditorKey(): string {
    const { artwork, overlay } = this.imageUrls
    return this.state.selectedPrintArea + this.printAreaArtworkTransformations.orientation + artwork + overlay
  }

  get hasArtwork(): boolean {
    return Boolean(this.artwork)
  }

  get areImagesLoading(): boolean {
    return (
      this.artwork?.mimeType !== 'application/pdf' &&
      Boolean(this.state.isArtworkLoading || this.state.isOverlayLoading || this.artworkUploadStatus === 'LOADING')
    )
  }

  get hasError(): boolean {
    return this.artworkUploadStatus === 'ERROR'
  }

  get imageId(): string {
    return this.props.buildImageId(this.state.selectedPrintArea)
  }

  get errorMessage(): string {
    return (
      this.props.artworkUploadProgress[this.imageId]?.errorMessage ??
      'Something went wrong. Please try again and contact us if the issue persists.'
    )
  }

  get artworkUploadStatus(): 'LOADING' | 'SUCCESS' | 'ERROR' | 'IDLE' {
    return this.props.artworkUploadProgress[this.imageId]?.status ?? 'IDLE'
  }

  get imageLibraryId() {
    return this.props.printAreas[this.selectedPrintArea].artwork?.imageLibraryId
  }

  get isSaving() {
    return this.props.isSaving || this.state.saveStatus === 'loading'
  }

  resetImage: () => void = () => this.props.resetImage(this.state.selectedPrintArea)

  setSelectedView = async (view: string) => {
    if (view === 'design') {
      this.setState({ transformationId: null, selectedView: view })
    }

    if (view === 'mockup' && this.imageLibraryId) {
      this.setState({ selectedView: view })
      await this.handleGenerateMockup()
    }
  }

  handleGenerateMockup: () => Promise<void> = async () => {
    this.setState({ mockupData: null, mockupErrorState: null })

    try {
      if (!this.artwork || !this.imageLibraryId || !this.printAreaTemplate) {
        return
      }

      this.setState({ mockupGenerationStatus: 'loading' })

      const response = await transformImage({
        artwork: this.artwork,
        imageLibraryId: this.imageLibraryId,
        template: this.printAreaTemplate,
        transformations: this.printAreaArtworkTransformations,
        preview: true
      })
      this.setState({ transformationId: response.transformation_id, mockupGenerationStatus: 'success' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string | null }>

      this.setState({
        mockupErrorState: {
          message: errorResponse.responseBodyJson?.message ?? errorResponse.message,
          code: errorResponse.status?.toString() ?? 'TIE'
        },
        mockupGenerationStatus: 'error'
      })
    }
  }

  onMockupsPollingComplete: (details: MockupPollCompleteInterface) => void = (details) => {
    const { mockupsData, mockupsFetchError } = details

    // Handle success
    if (mockupsData?.url) {
      this.setState({ mockupData: mockupsData })
      return
    }

    // Handle error
    if (mockupsFetchError) {
      this.setState({
        mockupErrorState: {
          message: mockupsFetchError.responseBodyJson?.detail ?? mockupsFetchError.message,
          code: `${this.state.transformationId ?? '0'}-${mockupsFetchError?.status ?? 'MPE'}`
        },
        mockupGenerationStatus: 'error'
      })
    }
  }

  render(): ReactNode {
    return (
      <div
        key={this.imageEditorKey}
        className={twMerge(
          'min-full-viewport-height relative flex w-full flex-col-reverse overflow-auto bg-gray-800 md:flex-row',
          this.props.className
        )}
      >
        <MultiAssetImageEditorControlPanel
          attributes={{ ...this.props.selectedAttributes }}
          artwork={this.artwork}
          transformationId={this.state.transformationId}
          productSku={this.props.productSku}
          mockupData={this.state.mockupData}
          onMockupsPollingComplete={this.onMockupsPollingComplete}
          selectedView={this.state.selectedView}
          onViewSelected={this.setSelectedView}
          imageLibraryId={this.imageLibraryId}
          mockupGenerationStatus={this.state.mockupGenerationStatus}
          supportsTiling={this.props.supportsTiling}
          onToggleTiling={this.onToggleTiling}
          artworkUploadStatus={this.artworkUploadStatus}
          imageQuality={this.imageQuality}
          productName={this.props.description}
          printAreaArtworkEntries={this.printAreaArtworkEntries}
          areImagesLoading={this.areImagesLoading}
          hasArtwork={this.hasArtwork}
          imageLibraryConfig={this.props.imageLibraryConfig}
          itemCategory={this.props.itemCategory}
          productType={this.props.productType}
          showTemplateOrientationToggle={this.showTemplateOrientationToggle}
          templateOrientation={this.printAreaArtworkTransformations.orientation}
          showBorderControlPanel={this.props.supportsPrintBorders}
          templates={this.props.templates}
          onChangeTemplateOrientation={this.changeTemplateOrientation}
          selectedPrintArea={this.selectedPrintArea}
          onAlignmentPointChange={this.onAlignmentPointChange}
          onFit={this.onFit}
          onFill={this.onFill}
          onBorderChange={this.onBorderChange}
          onPositionChange={this.changePosition}
          onRotationAngleChange={this.onRotationChange}
          onScaleChange={this.changeScaleInUserUnit}
          onPositionUnitChange={this.onPositionUnitChange}
          onChangePrintArea={this.onChangePrintArea}
          onRemoveArtwork={this.onRemoveArtwork}
          printDimensionsInPx={this.printAreaTemplate.printResolution}
          artworkDimensionsInPx={this.artworkDimensionsInPx}
          printDpi={this.printAreaTemplate.printDpi}
          transformations={this.transformations}
          displayedPosition={this.displayedPosition}
          displayedPositionUnit={this.displayedPositionUnit}
          displayedScaleFactor={this.displayedScaleFactor}
          onSelectImageLibraryImage={this.props.onSelectImageLibraryImage}
          onTogglePreviewMode={this.onTogglePreviewMode}
          selectedMockupView={this.state.selectedMockupView}
          onSelectMockupView={(view: string) => {
            this.setState({ selectedMockupView: view })
          }}
        />

        <motion.div
          className={clsx(
            'relative flex flex-1 flex-col justify-center p-8 transition-colors',
            this.state.selectedView === 'mockup' ? 'bg-white' : 'bg-gray-800'
          )}
        >
          <div>
            <div className="flex w-full justify-end">
              <button
                id="cancel-btn"
                className={clsx(
                  'mr-12 justify-end',
                  this.state.selectedView === 'mockup' ? 'text-gray-900' : 'text-gray-200'
                )}
                onClick={this.props.onCancel}
              >
                Cancel<span className="hidden md:inline"> changes</span>
              </button>

              <ImageEditorSaveButton
                artworks={this.artworks}
                artworkTransformations={this.state.artworkTransformations}
                isLoading={this.isSaving}
                templates={this.props.templates}
                onSaveStart={() => {
                  this.setState({ saveStatus: 'loading' })
                }}
                onSaveError={() => {
                  this.setState({ saveStatus: 'error' })
                }}
                onSaveSuccess={(additionalData) => {
                  this.props.onSave(this.state.artworkTransformations, this.state.selectedPrintArea, additionalData)
                  this.setState({ saveStatus: 'success' })
                }}
              />
            </div>
          </div>

          {this.state.selectedView === 'design' && (
            <>
              <div
                className={clsx('flex flex-1 items-center justify-center py-16 md:py-2', {
                  'opacity-1 visible': !this.areImagesLoading,
                  'invisible opacity-0': this.areImagesLoading,
                  [styles.hideControls]: !this.hasArtwork
                })}
              >
                {this.artwork && this.artwork?.mimeType === 'application/pdf' && (
                  <PdfPreview
                    artwork={this.artwork}
                    onArtworkError={(errorCode?: CustomEvent<unknown> | string) => {
                      createToast({
                        heading: 'Failed to load preview',
                        id: IMAGE_EDITOR_ERROR_TOAST_ID,
                        content: 'Please try re-uploading your file and contact support if the issue persists',
                        footer:
                          typeof errorCode === 'string'
                            ? errorCode ?? 'PDF-0'
                            : `PDF-${this.artwork?.imageLibraryId ?? '0'}`,
                        duration: Infinity,
                        type: 'error-with-close'
                      })
                      this.onRemoveCurrentPrintAreaArtwork()
                    }}
                  />
                )}

                {this.artwork?.mimeType !== 'application/pdf' && (
                  <ImageEditorPreview
                    key={this.imageEditorKey + this.state.isPreviewOnly.toString()}
                    cropRectangle={this.printAreaTemplate.cropRectangle}
                    enableImageLibraryThumbnailRefresh={this.props.enableImageLibraryThumbnailRefresh ?? false}
                    transformations={this.transformations}
                    printDimensionsInPx={this.printAreaTemplate.printResolution}
                    artworkDimensionsInPx={this.artworkDimensionsInPx}
                    imageUrls={this.imageUrls}
                    imageLibraryId={this.artwork?.imageLibraryId}
                    printDpi={this.printAreaTemplate.printDpi}
                    onPositionChange={this.changePosition}
                    onRotationChange={this.onRotationChange}
                    onScaleChange={this.changeScaleInImageEditorUnit}
                    onLoadArtwork={this.onLoadArtwork}
                    onLoadOverlay={this.onLoadOverlay}
                    isPreviewOnly={!this.hasArtwork || this.state.isPreviewOnly}
                    onRemoveArtwork={
                      this.props.showRemoveArtworkButton && this.hasArtwork
                        ? this.onRemoveCurrentPrintAreaArtwork
                        : undefined
                    }
                    onArtworkError={(errorCode?: CustomEvent<unknown> | string) => {
                      createToast({
                        heading: 'Failed to load image',
                        id: IMAGE_EDITOR_ERROR_TOAST_ID,
                        content: 'Please try re-uploading your image and contact support if the issue persists',
                        footer:
                          typeof errorCode === 'string' ? errorCode ?? '0' : `${this.artwork?.imageLibraryId ?? '0'}`,
                        duration: Infinity,
                        type: 'error-with-close'
                      })
                      this.onRemoveCurrentPrintAreaArtwork()
                    }}
                    onOverlayError={() => {
                      createToast({
                        heading: 'Failed to load editor',
                        id: IMAGE_EDITOR_ERROR_TOAST_ID,
                        content: 'Please try again later and contact support if the issue persists',
                        footer: 'Code (TS-OF)',
                        duration: Infinity,
                        type: 'error-with-close'
                      })
                    }}
                    onUnderlayError={() => {
                      createToast({
                        heading: 'Failed to load editor',
                        id: IMAGE_EDITOR_ERROR_TOAST_ID,
                        content: 'Please try again later and contact support if the issue persists',
                        footer: 'Code (TS-UF)',
                        duration: Infinity,
                        type: 'error-with-close'
                      })
                    }}
                  />
                )}

                {!this.hasArtwork && !this.hasError && (
                  <AddImageFromLibrary
                    imageLibraryConfig={this.props.imageLibraryConfig}
                    itemCategory={this.props.itemCategory}
                    productType={this.props.productType}
                    templates={this.props.templates}
                    printAreaName={this.selectedPrintArea}
                    onOpenImageLibrary={this.props.onOpenImageLibrary}
                    onTogglePreviewMode={this.onTogglePreviewMode}
                    onSelectImageLibraryImage={({ artwork, artworkTransformations }) =>
                      this.props.onSelectImageLibraryImage?.({
                        artwork,
                        artworkTransformations,
                        selectedPrintArea: this.selectedPrintArea
                      })
                    }
                  />
                )}

                <AlertPanel
                  className="absolute inset-0 m-auto max-h-[200px] max-w-[350px] overflow-y-auto"
                  heading="Image upload error"
                  isVisible={this.hasError}
                  onClose={this.resetImage}
                >
                  <span>{this.errorMessage}</span>
                </AlertPanel>
              </div>

              <div className="mx-auto flex max-w-[80vw] overflow-x-auto">
                <div className="mr-auto"></div>
                <PrintAreaToggle
                  printAreaNames={Object.keys(this.props.printAreas)}
                  onChangePrintArea={this.onChangePrintArea}
                  selectedPrintArea={this.selectedPrintArea}
                />
                <div className="ml-auto"></div>
              </div>

              <div
                className={clsx(
                  'pointer-events-none absolute inset-0 grid place-content-center transition-opacity',
                  this.areImagesLoading ? 'opacity-100' : 'opacity-0'
                )}
              >
                <LoadingSpinner className="h-16 w-16 text-white" />
              </div>
            </>
          )}

          {this.state.selectedView === 'mockup' && (
            <div className="flex max-w-full flex-1 items-center justify-center">
              <MockupPreview
                attributes={{ ...this.props.selectedAttributes }}
                mockupErrorState={this.state.mockupErrorState}
                mockupGenerationStatus={this.state.mockupGenerationStatus}
                mockupData={this.state.mockupData}
                orientation={this.printAreaArtworkTransformations.orientation}
                orientationSelectionAvailable={this.showTemplateOrientationToggle}
                productSku={this.props.productSku}
                selectedMockupView={this.state.selectedMockupView}
                transformationId={this.state.transformationId}
              />
            </div>
          )}
        </motion.div>

        {this.isSaving && <OverlayPortal />}
      </div>
    )
  }
}
