// @flow
import { FRAMED, FRAMED_PRINTS } from './productCategories'

export const MAX_PRODUCT_DIMENSIONS_MM = 'maxProductDimensionsMm'
export const PRODUCT_ASPECT_RATIO = 'productAspectRatio'
export const FRAME_COLOUR = 'frameColour'
export const GENDER = 'gender'
export const GLAZE = 'glaze'
export const WRAP = 'wrap'
export const MOUNT_COLOUR = 'mountColour'
export const PAPER_TYPE = 'paperType'
export const SUBSTRATE_WEIGHT = 'substrateWeight'
export const SIZE = 'size'

export const BLACK = 'black'
export const NONE_ATTRIBUTE = 'none'

export const PRODUCT_ATTRIBUTES = [
  MAX_PRODUCT_DIMENSIONS_MM,
  PRODUCT_ASPECT_RATIO,
  FRAME_COLOUR,
  GENDER,
  GLAZE,
  MOUNT_COLOUR,
  PAPER_TYPE,
  SUBSTRATE_WEIGHT
]

export const DEFAULT_ATTRIBUTE_CATEGORIES: {|
  Framed: {| frameColour: string |},
  "Framed Prints": {| frameColour: string |},
|} = {
  [FRAMED]: {
    [FRAME_COLOUR]: BLACK
  },
  [FRAMED_PRINTS]: {
    [FRAME_COLOUR]: BLACK
  }
}
