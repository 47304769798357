import { useSWRConfig } from 'swr'
import { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../Button'
import NotFound from '../NotFound'
import SupportLink from '../SupportLink'
import { useMerchantService, useUser, useZendeskAuth } from '../../hooks'
import { generateUserId, resetBeamerConfig } from '../../helpers'
import { logOut, refreshAccessToken } from '../../../actions/auth'
import V1LoadingIndicatorFullPage from '../V1LoadingIndicatorFullPage'
import { getHasOrderInProgress } from '../../../selectors/manualOrderForm'

/**
 * Renders children only when user related data is successfully loaded
 * Useful to handle user data loading and error states at a top level so that
 * user data related hooks can be used without worrying about the unsuccessful states
 * throughout the app
 */
export default function UserSetup({ children }: { children: ReactNode }) {
  const { isLoading: isLoadingUser, error: userFetchError } = useUser()
  const { isLoading: isLoadingMerchantDetails, merchantDetailsFetchError } = useMerchantService()

  if (userFetchError) {
    return (
      <UserSetupError
        code={`USFE-${userFetchError.status ?? '0'}`}
        message={userFetchError.responseBodyJson?.message ?? userFetchError.message}
        status={userFetchError.status}
      />
    )
  }

  if (merchantDetailsFetchError) {
    return (
      <UserSetupError
        code={`MDFE-${merchantDetailsFetchError?.status ?? '0'}`}
        message={merchantDetailsFetchError.responseBodyJson?.message ?? merchantDetailsFetchError.message}
        status={merchantDetailsFetchError.status}
      />
    )
  }

  if (isLoadingUser || isLoadingMerchantDetails) {
    return <V1LoadingIndicatorFullPage />
  }

  return <UserSetupSuccess>{children}</UserSetupSuccess>
}

function UserSetupSuccess({ children }: { children: ReactNode }) {
  const { user } = useUser()
  const { merchantDetails } = useMerchantService()
  const { jwt } = useZendeskAuth(user?.merchantUniqueId)
  const hasOrderInProgress: boolean = useSelector(getHasOrderInProgress)

  // Beamer data setup - https://www.getbeamer.com/advanced-segmentation-guide/#tracking-user-data
  useEffect(() => {
    if (!user) {
      console.warn(`No user found, this shouldn't happen in this component`)
      return
    }

    if (!merchantDetails) {
      console.warn(`No merchant details found, this shouldn't happen in component`)
      return
    }
    const [firstName, ...lastNameArray] = user.fullName.split(' ')
    const lastName = lastNameArray.join(' ')

    window.Beamer?.init?.()
    window.Beamer?.update?.({
      user_firstname: firstName,
      user_lastname: lastName ?? '',
      user_email: user.email,
      user_id: generateUserId(user.merchantUniqueId),
      has_ordered_sample_pack: merchantDetails.hasOrderedSamplePack,
      has_ordered_product: merchantDetails.hasOrderedProduct,
      has_order_in_progress: hasOrderInProgress,
      has_payment_card_setup: user.billing.hasPaymentSetup
    })

    return () => {
      window.Beamer?.destroy?.()
      resetBeamerConfig()
    }
  }, [hasOrderInProgress, merchantDetails, user])

  // zendesk setup
  useEffect(() => {
    if (!jwt) {
      return
    }

    if (window.zE) {
      window.zE('messenger', 'loginUser', function (callback: (arg0: string) => void) {
        callback(jwt)
      })
    }
  }, [jwt])

  return <>{children}</>
}

function UserSetupError({ code, message, status }: { code: string; message: string | number; status?: number }) {
  const dispatch = useDispatch()
  const { cache } = useSWRConfig()

  function handleLogout() {
    // SWR v1.3.0 supports this as per the docs but is not typed
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cache as any)?.clear?.()
    // TODO: v2: Remove v1 logout when updated
    dispatch(logOut())
  }

  useEffect(() => {
    if (status === 401) {
      dispatch(refreshAccessToken())
    }
  }, [dispatch, status])

  const isUnauthorised = status === 401

  const heading = isUnauthorised ? 'The login session has expired' : 'An error occurred with the user session'

  const body = isUnauthorised ? (
    <div className="text-center">
      <p>Please try refreshing the page or log in again.</p>
    </div>
  ) : (
    <div className="text-center">
      <p className="break-words">{message ?? 'An unknown error occurred'}</p>
      <p>
        Please refresh to try again and <SupportLink>contact us</SupportLink> if the issue persists (Code: {code}).
      </p>
    </div>
  )

  return (
    <main className="tailwind">
      <div className="grid min-h-screen place-content-center">
        <NotFound body={body} heading={heading}>
          <Button className="mx-auto mt-8 max-w-xs" variant="primary" onClick={handleLogout}>
            Log in
          </Button>
        </NotFound>
      </div>
    </main>
  )
}
