// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Orders ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5 12.5H10.5V14H4.5V12.5ZM4.5 8.75H13.5V10.25H4.5V8.75ZM4.5 16.25H8.25V17.75H4.5V16.25ZM15.75 2.75H13.5V2C13.5 1.17157 12.8284 0.5 12 0.5H6C5.17157 0.5 4.5 1.17157 4.5 2V2.75H2.25C1.42157 2.75 0.75 3.42157 0.75 4.25V20C0.75 20.8284 1.42157 21.5 2.25 21.5H15.75C16.5784 21.5 17.25 20.8284 17.25 20V4.25C17.25 3.42157 16.5784 2.75 15.75 2.75ZM6 2H12V5H6V2ZM15.75 20H2.25V4.25H4.5V6.5H13.5V4.25H15.75V20Z" fill="#565656"/>
    </svg>
  )
}
