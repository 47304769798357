import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

import SupportLink from '../SupportLink'
import { ROUTE_PATHS } from '../../constants/routePaths.const'

interface NotFoundPropsInterface {
  className?: string
  children?: ReactNode
  body?: ReactNode
  heading?: string
  icon?: ReactNode
}

export default function NotFound({ className = '', children, body, heading, icon }: NotFoundPropsInterface) {
  return (
    <motion.div
      animate={{ scale: 1, opacity: 1 }}
      className={twMerge('max-w-md rounded-2xl border-2 border-gray-600 px-6 py-12', className)}
      data-test="page-not-found"
      initial={{ scale: 0.8, opacity: 0 }}
      transition={{ type: 'tween' }}
    >
      {icon ?? <ExclamationCircleIcon className="mx-auto mb-10 h-16 w-16" />}
      <h2 className="m-0 mb-8 break-words text-center">{heading ?? 'Not found'}</h2>
      {body ?? (
        <p className="break-words text-center">
          <>
            We could not find the page you were looking for. Please check the URL and{' '}
            <SupportLink>contact us</SupportLink> if you think this is an error.
          </>
        </p>
      )}
      {children ?? (
        <Link className="btn btn-primary mx-auto mt-8 max-w-xs" to={ROUTE_PATHS.DASHBOARD}>
          Back to home
        </Link>
      )}
    </motion.div>
  )
}
