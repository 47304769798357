// @flow
import React from 'react'

import Svg from '../../../components/svg'
import styles from './sharedStyles.module.css'
import { LIVE } from '../../../data/constants'
import LoadingDots from '../../../components/LoadingDots'
import InputUnderlined from '../../../components/InputUnderlined'

type Props = {|
  brandName: string,
  errorText: string,
  inputPrefix: string,
  isAvailable: boolean | null,
  isLoadingAvailability: boolean,
  brandNameChangeHandler: (event: SyntheticInputEvent<HTMLInputElement>) => void
|}

class BrandName extends React.Component<Props> {
  render (): React$Node {
    const {
      brandName,
      brandNameChangeHandler,
      errorText,
      inputPrefix,
      isAvailable,
      isLoadingAvailability
    } = this.props

    let availabilityStatus
    if (isAvailable !== null && brandName !== '') {
      availabilityStatus = isAvailable
        ? (<div className={styles.availabilityIcon}>
          <Svg.TickMark className={styles.greenAlertSvg} />
        </div>)
        : (<div className={styles.availabilityIcon}>
          <Svg.Cross className={styles.pinkAlertSvg} />
        </div>)
    }

    let availabilityLoader
    if (isLoadingAvailability) {
      availabilityLoader = (
        <div className={styles.feedbackLoaderContainer}>
          <span className={styles.loaderText}>Checking Availability</span>
          <div className={styles.feedbackLoader}>
            <LoadingDots />
          </div>
        </div>
      )
    }

    return (
      <div className={styles.stepBrandName}>
        <div className={process.env.REACT_APP_ENV === LIVE ? styles.brandNameInput : styles.brandNameInputWide}>
          <InputUnderlined
            autoFocus
            error={errorText}
            inputLabel="SHOP NAME"
            inputPrefix={inputPrefix}
            maxLength="64"
            onChange={brandNameChangeHandler}
            placeholder="your-brand-name"
            value={brandName} />
        </div>
        {availabilityStatus}
        {availabilityLoader}
      </div>
    )
  }
}

export default BrandName
