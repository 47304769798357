import Button from '../Button'
import Modal from '../Modal'
import ProductDetails, { ProductDetailsPropsInterface } from '../ProductDetails'

interface ProductDetailsModalPropsInterface extends ProductDetailsPropsInterface {
  actions?: JSX.Element
  description: string
  open: boolean
  showCountryPicker: boolean
  setOpen: (open: boolean) => void
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export default function ProductDetailsModal({
  actions,
  countryCode,
  description,
  open,
  sku,
  showCountryPicker,
  setOpen,
  ...props
}: ProductDetailsModalPropsInterface) {
  const footerComponent = actions ?? (
    <div className="sticky bottom-0 flex max-w-full bg-white pb-6 pt-8 md:pb-10 md:pt-12">
      <div className="flex max-w-full justify-start gap-4 md:ml-[200px]">
        <Button variant="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </div>
  )

  return (
    <Modal className="mb-0 max-w-screen-lg sm:min-w-[600px]" open={open} title={description} setOpen={setOpen}>
      <aside className="max-h-[70vh] min-h-[30vh] w-full overflow-y-auto pt-16 pr-2 md:max-h-80vh">
        <ProductDetails
          {...props}
          countryCode={countryCode}
          footer={footerComponent}
          loadingConfig={{ className: 'pt-12' }}
          sku={sku}
          showCountryPicker={showCountryPicker}
        />
      </aside>
    </Modal>
  )
}
