// @flow

import type { RecentItemsState } from '../types/recentItems'
import type { RecentItemsActions, SaveRecentItems, RemoveRecentItem } from '../actions/types'

import { SAVE_RECENT_ITEMS, CLEAR_RECENT_ITEMS, REMOVE_RECENT_ITEM } from '../actions/recentItems'
import { PRINT_AREA_NAME } from '../data'

const initialState: RecentItemsState = {
  items: []
}

function recentItemsReducer (state: RecentItemsState = initialState, action: RecentItemsActions): RecentItemsState {
  switch (action.type) {
    case SAVE_RECENT_ITEMS: {
      const currentAction: SaveRecentItems = action
      return {
        items: currentAction.items
      }
    }

    case CLEAR_RECENT_ITEMS: {
      return initialState
    }

    case REMOVE_RECENT_ITEM: {
      const currentAction: RemoveRecentItem = action
      return {
        items: state.items.filter(item => {
          if (!item.printAreas) {
            return false
          }
          const itemPrintAreas = item.printAreas
          const allPrintAreaNames = Object.keys(itemPrintAreas)
          const selectedPrintAreaName = allPrintAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
            ? PRINT_AREA_NAME.DEFAULT
            : allPrintAreaNames[0] ?? null
          if (!selectedPrintAreaName || !itemPrintAreas[selectedPrintAreaName]) {
            return false
          }

          return itemPrintAreas[selectedPrintAreaName]?.artwork?.originalImageUrl !== currentAction.originalImageUrl
        })
      }
    }

    default:
      return state
  }
}

export default recentItemsReducer
