function BigCommerceLogoIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="66 120 485 115"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            fill="#34313F"
            d="M201.1,174.5c7.3,0,12.2,3.3,15.2,7.4v-58.4c0-1.1-1.3-1.8-2.2-1.1L142,175.1h1.9c7.5,0,11.3,4.8,11.3,10
            c0,4.1-2.1,7.1-5,8.4c-0.5,0.2-0.5,0.9,0,1.1c3.3,1.4,5.7,4.9,5.7,9.1c0,6-3.9,10.7-11.4,10.7H124c-0.4,0-0.8-0.3-0.8-0.8v-24.8
            l-53.7,39.3c-0.9,0.8-0.5,2.2,0.7,2.2h144.8c0.7,0,1.2-0.5,1.2-1.2v-19.9c-3.9,3.6-9,5.9-15.2,5.9c-11.6,0-21-8-21-20.3
            C180.1,182.4,189.6,174.5,201.1,174.5z M172.4,213.7c0,0.4-0.3,0.7-0.7,0.7h-6.9c-0.4,0-0.7-0.3-0.7-0.7v-37.9
            c0-0.4,0.3-0.7,0.7-0.7h6.9c0.4,0,0.7,0.3,0.7,0.7V213.7z"
          />
        </g>
        <path
          fill="#34313F"
          d="M132.4,190.7h9.7c2.8,0,4.6-1.6,4.6-4.1c0-2.4-1.8-4.1-4.6-4.1H132c-0.2,0-0.4,0.2-0.4,0.4v7.1
          C131.6,190.4,132,190.7,132.4,190.7z"
        />
        <path
          fill="#34313F"
          d="M132.4,207.1h10.1c3.1,0,5-1.7,5-4.5c0-2.5-1.8-4.5-5-4.5h-10.1c-0.4,0-0.8,0.3-0.8,0.8v7.4
          C131.6,206.7,132,207.1,132.4,207.1z"
        />
        <path
          fill="#34313F"
          d="M210,186.4c-1.9-2.5-5.1-4.5-8.9-4.5c-7.2,0-12.4,5.5-12.4,12.9c0,7.4,5.2,12.9,12.4,12.9
          c3.3,0,6.4-1.4,8.1-2.8c0.1-0.1,0.2-0.3,0.2-0.5v-3.8c0-0.3-0.3-0.6-0.6-0.6h-8.9c-0.5,0-0.8-0.4-0.8-0.8v-5.6
          c0-0.5,0.4-0.8,0.8-0.8h16.5v-8.9l-5.3,2.9C210.7,186.9,210.2,186.7,210,186.4z"
        />
        <path
          fill="#34313F"
          d="M222.5,194.8c0-12.1,8.7-20.3,19.9-20.3c7.3,0,12,3.7,14.8,8.2c0.2,0.3,0.1,0.8-0.3,0.9l-4.5,2.4
          c-0.3,0.2-0.7,0.1-0.8-0.2c-1.9-3-5.4-5.2-9.2-5.2c-7.5,0-13.1,5.9-13.1,14.2c0,8.2,5.6,14.2,13.1,14.2c3.8,0,7.3-2.2,9.2-5.2
          c0.2-0.3,0.5-0.4,0.8-0.2l4.5,2.3c0.4,0.2,0.5,0.6,0.3,1c-2.9,4.5-7.6,8.3-14.8,8.3C231.2,215.1,222.5,206.8,222.5,194.8z"
        />
        <path
          fill="#34313F"
          d="M261.1,194.8c0-9.8,5.6-17.4,14-19.6c14.3-3.8,27.4,9.6,24.3,24.5c-1.9,9.2-9.2,15.5-18.9,15.5
          C269.1,215.1,261.1,206.5,261.1,194.8z M293,194.8c0-8.1-5-14.2-12.6-14.2c-7.7,0-12.6,6.1-12.6,14.2c0,8.1,4.9,14.2,12.6,14.2
          C288.1,209,293,202.8,293,194.8z"
        />
        <path
          fill="#34313F"
          d="M340.6,213.9V184l-11.9,30.1c0,0.1-0.2,0.2-0.3,0.2h-2.5c-0.1,0-0.3-0.1-0.3-0.2l-12-30.1v29.9
          c0,0.3-0.2,0.5-0.5,0.5h-6c-0.3,0-0.5-0.2-0.5-0.5v-38.4c0-0.3,0.2-0.5,0.5-0.5h8.6c0.4,0,0.7,0.2,0.9,0.6l10.5,27l10.4-27
          c0.1-0.4,0.5-0.6,0.9-0.6h8.6c0.3,0,0.5,0.2,0.5,0.5v38.4c0,0.3-0.2,0.5-0.5,0.5h-6C340.9,214.4,340.6,214.2,340.6,213.9z"
        />
        <path
          fill="#34313F"
          d="M408.6,213.7v-37.9c0-0.4,0.3-0.7,0.7-0.7h25.5c0.4,0,0.7,0.3,0.7,0.7v4.6c0,0.4-0.3,0.7-0.7,0.7h-18.6
          c-0.4,0-0.7,0.3-0.7,0.7v8.7c0,0.4,0.3,0.7,0.7,0.7h18.2c0.4,0,0.7,0.3,0.7,0.7v4.6c0,0.4-0.3,0.7-0.7,0.7h-18.2
          c-0.4,0-0.7,0.3-0.7,0.7v9.5c0,0.4,0.3,0.7,0.7,0.7h18.6c0.4,0,0.7,0.3,0.7,0.7v4.6c0,0.4-0.3,0.7-0.7,0.7h-25.5
          C408.9,214.4,408.6,214.1,408.6,213.7z"
        />
        <path
          fill="#34313F"
          d="M467.5,214l-8.3-14c-0.1-0.2-0.4-0.3-0.6-0.3h-5.8c-0.4,0-0.7,0.3-0.7,0.7v13.3c0,0.4-0.3,0.7-0.7,0.7H446
          c-0.4,0-0.7-0.3-0.7-0.7v-37.9c0-0.4,0.3-0.7,0.7-0.7h16.6c7.8,0,12.9,5,12.9,12.3c0,6.5-4,10.2-8.4,11.3c-0.5,0.1-0.7,0.6-0.5,1.1
          c1.6,2.6,6.6,10.6,8.5,13.5c0.3,0.5,0,1.1-0.6,1.1h-6.3C467.9,214.4,467.7,214.3,467.5,214z M468.4,187.3c0-3.8-2.8-6.2-6.7-6.2
          h-8.8c-0.4,0-0.7,0.3-0.7,0.7v11.1c0,0.4,0.3,0.7,0.7,0.7h8.8C465.6,193.6,468.4,191.2,468.4,187.3z"
        />
        <path
          fill="#34313F"
          d="M522.1,213.7v-37.9c0-0.4,0.3-0.7,0.7-0.7h25.6c0.4,0,0.7,0.3,0.7,0.7v4.7c0,0.4-0.3,0.7-0.7,0.7h-18.7
          c-0.4,0-0.7,0.3-0.7,0.7v8.8c0,0.4,0.3,0.7,0.7,0.7h18.2c0.4,0,0.7,0.3,0.7,0.7v4.7c0,0.4-0.3,0.7-0.7,0.7h-18.2
          c-0.4,0-0.7,0.3-0.7,0.7v9.6c0,0.4,0.3,0.7,0.7,0.7h18.7c0.4,0,0.7,0.3,0.7,0.7v4.7c0,0.4-0.3,0.7-0.7,0.7h-25.6
          C522.4,214.4,522.1,214.1,522.1,213.7z"
        />
        <path
          fill="#34313F"
          d="M480.5,194.6c0-12.1,8.7-20.3,19.9-20.3c7.3,0,12,3.7,14.8,8.2c0.2,0.3,0.1,0.8-0.3,0.9l-4.5,2.4
          c-0.3,0.2-0.7,0.1-0.8-0.2c-1.9-3-5.4-5.2-9.2-5.2c-7.5,0-13.1,5.9-13.1,14.2c0,8.2,5.6,14.2,13.1,14.2c3.8,0,7.3-2.2,9.2-5.2
          c0.2-0.3,0.5-0.4,0.8-0.2l4.5,2.3c0.4,0.2,0.5,0.6,0.3,1c-2.9,4.5-7.6,8.3-14.8,8.3C489.2,214.9,480.5,206.7,480.5,194.6z"
        />
        <path
          fill="#34313F"
          d="M391.6,213.9V184l-11.9,30.1c0,0.1-0.2,0.2-0.3,0.2h-2.5c-0.1,0-0.3-0.1-0.3-0.2l-12-30.1v29.9
          c0,0.3-0.2,0.5-0.5,0.5h-6c-0.3,0-0.5-0.2-0.5-0.5v-38.4c0-0.3,0.2-0.5,0.5-0.5h8.6c0.4,0,0.7,0.2,0.9,0.6l10.5,27l10.4-27
          c0.1-0.4,0.5-0.6,0.9-0.6h8.6c0.3,0,0.5,0.2,0.5,0.5v38.4c0,0.3-0.2,0.5-0.5,0.5h-6C391.8,214.4,391.6,214.2,391.6,213.9z"
        />
      </g>
    </svg>
  )
}

export { BigCommerceLogoIcon }
