// @flow
import { setRsaaStatus, resetRsaaStatus } from '../actions/rsaa'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { AppState, Action } from '../types'
import { ERROR } from '../data/rsaa'
import getErrorMessageFromAction from '../helpers/errorMessages/getErrorMessageFromAction'
import { getStatusCodeFromAction } from '../helpers/getStatusCodeFromAction'
import { buildRsaaActionId } from '../helpers/rsaa'

export default (store: MiddlewareAPI<AppState, Action>): ((next: any) => (action: Action) => any) => (next: Dispatch<Action>) => (action: Action) => {
  const dispatch = store.dispatch
  const state = store.getState()

  const rsaaActionId = action.meta && action.meta.loggerId
    ? buildRsaaActionId(action.type, action.meta.loggerId)
    : action.type

  const hasRsaaConfig = Object.keys(state.rsaa.config).includes(rsaaActionId)
  if (!hasRsaaConfig) {
    return next(action)
  }

  const rsaaActionConfig = state.rsaa.config[rsaaActionId]
  const currentStatus = state.rsaa[rsaaActionConfig.actionId]
  const isError = rsaaActionConfig.relatedStatus === ERROR

  const setRsaaStatusProps = {
    status: rsaaActionConfig.relatedStatus,
    statusMessage: isError
      ? getErrorMessageFromAction(action)
      : '',
    statusCode: getStatusCodeFromAction(action),
    errorPayload: isError
      ? action.payload
      : null
  }

  dispatch(setRsaaStatus(rsaaActionConfig.actionId, setRsaaStatusProps))

  if (currentStatus.ignore) {
    dispatch(resetRsaaStatus(rsaaActionConfig.actionId))
  }

  return next(action)
}
