// @flow
import type { FormattedCsvRow, Dictionary, Cost } from '../../../../types'
import React, { Component } from 'react'
import CsvUploadOverview from './CsvUploadOverview'
import { ALL_ORDERS, REQUIRE_INFO, ORDERS_WITH_ERRORS } from '../../../../data/csvUpload'
import { formatCost } from '../../../../helpers/charge/formatCost'

export type Props = {|
  submitCsvLoading: boolean,
  submitCsvError: boolean,
  submitCsvSuccess: boolean,
  canSubmit: boolean,
  submitCsvMsg: string,
  isTransformUrlsOn: boolean,
  uploadedOrders: Dictionary<FormattedCsvRow>,
  totalOrdersToSubmit: number,
  totalIgnoredOrders: number,
  numberOfOrdersWithErrors: number,
  currencyCode: string,
  totalCsvCost: Cost,
  hasCsvShippingFetchedSuccessfully: boolean,
  ordersRequiringAction: Dictionary<FormattedCsvRow>,
  ordersWithErrors: Dictionary<FormattedCsvRow>,
  isEveryOrderCompleted: boolean,
  hasAllV4ProductDetails: boolean,
  isCsvSubmitting: boolean,
  isFetchingQuotes: boolean,
  totalSubmittedOrders: number,
  isUsSalesTaxCollectedForAllUsOrders: boolean,
  isAnyCsvOrderForUnitedStates: boolean,
  isUsSalesTaxAlreadyCollected: boolean,
  toggleUsSalesTax: () => void,
  resetCsvUploadState: () => void,
  submitCsvOrders: (isTransformUrlsOn: boolean) => void
|}

type State = {|
  totalOrdersToSubmit: number,
  filter: string
|}

const initialState: State = {
  totalOrdersToSubmit: 0,
  filter: ALL_ORDERS
}

export default class CsvUploadOverviewController extends Component<Props, State> {
  state: State = { ...initialState }

  componentDidUpdate() {
    const uploadedOrderCount = Object.keys(this.props.uploadedOrders).length
    if (this.state.totalOrdersToSubmit === 0 && uploadedOrderCount > 0) {
      this.setState({ totalOrdersToSubmit: uploadedOrderCount })
    }

    const shouldNotReset = this.props.submitCsvLoading || this.props.submitCsvSuccess || this.props.submitCsvError

    if (this.totalOrders === 0 && !shouldNotReset) {
      this.props.resetCsvUploadState()
    }
  }

  resetUpload: () => void = () => {
    if (!this.props.submitCsvLoading) {
      this.props.resetCsvUploadState()
    }
  }

  changeFilter: (newFilter: string) => void = (newFilter: string) => {
    this.setState({ filter: newFilter })
  }

  submitCsvOrders: () => void = () => {
    this.props.submitCsvOrders(this.props.isTransformUrlsOn)
    this.setState({ filter: ALL_ORDERS })
  }

  get canSubmit(): boolean {
    return Boolean(
      this.props.hasAllV4ProductDetails &&
        this.state.totalOrdersToSubmit - this.props.totalIgnoredOrders > 0 &&
        this.props.isEveryOrderCompleted &&
        !this.props.isFetchingQuotes
    )
  }

  get numberOfOrdersWithErrors(): number {
    return Object.keys(this.props.ordersWithErrors).length
  }

  get numberOfOrdersRequiringAction(): number {
    return Object.keys(this.props.ordersRequiringAction).length
  }

  get totalOrders(): number {
    return Object.keys(this.props.uploadedOrders).length
  }

  get ordersToDisplay(): Dictionary<FormattedCsvRow> {
    if (this.state.filter === REQUIRE_INFO) {
      return this.props.ordersRequiringAction
    } else if (this.state.filter === ORDERS_WITH_ERRORS) {
      return this.props.ordersWithErrors
    } else {
      return this.props.uploadedOrders
    }
  }

  get totalCostWithoutTax(): string {
    return formatCost(this.props.totalCsvCost)
  }

  render(): React$Node {
    return (
      <CsvUploadOverview
        isAnyCsvOrderForUnitedStates={this.props.isAnyCsvOrderForUnitedStates}
        isUsSalesTaxCollectedForAllUsOrders={this.props.isUsSalesTaxCollectedForAllUsOrders}
        isUsSalesTaxAlreadyCollected={this.props.isUsSalesTaxAlreadyCollected}
        toggleUsSalesTax={this.props.toggleUsSalesTax}
        totalSubmittedOrders={this.props.totalSubmittedOrders}
        resetUpload={this.resetUpload}
        canSubmit={this.canSubmit}
        totalOrders={this.totalOrders}
        numberOfOrdersRequiringAction={this.numberOfOrdersRequiringAction}
        numberOfOrdersWithErrors={this.numberOfOrdersWithErrors}
        totalOrdersToSubmit={this.state.totalOrdersToSubmit}
        submitCsvLoading={this.props.submitCsvLoading}
        submitCsvError={this.props.submitCsvError}
        submitCsvSuccess={this.props.submitCsvSuccess}
        submitCsvMsg={this.props.submitCsvMsg}
        totalIgnoredOrders={this.props.totalIgnoredOrders}
        changeFilter={this.changeFilter}
        selectedFilter={this.state.filter}
        currencyCode={this.props.currencyCode}
        totalCostWithoutTax={this.totalCostWithoutTax}
        isFetchingQuotes={this.props.isFetchingQuotes}
        submitCsvOrders={this.submitCsvOrders}
        ordersToDisplay={this.ordersToDisplay}
      />
    )
  }
}
