import { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import useSWRInfinite from 'swr/infinite'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'
import { useMerchantService } from './useMerchantService.hook'

interface SalesChannelProductsSuccessResponseInterface {
  outcome: string
  traceParent: string
  data: {
    products: SalesChannelProductInterface[]
    nextUrl: string
    hasMore: boolean
  }
}

export interface SalesChannelProductInterface {
  id: number
  rutterId: string
  merchantId: string
  salesChannelId: string
  platformId: string
  type: string
  name: string
  numberOfFulfilledVariants: number
  description: string
  fulfill: boolean
  imageUrls: string[]
  status: 'active' | 'archived' | 'inactive' | 'draft'
  productUrl: string
  search: string
  totalNumberOfVariants: number
  variants: SalesChannelProductVariantInterface[]
}

interface SalesChannelProductVariantInterface {
  id: number
  rutterProductId: number
  rutterId: string
  title: string
  sku: string
  attributes: Array<{
    id: number
    variantId: number
    name: string
    value: string
  }>
}

export interface SalesChannelProductsErrorResponseInterface {
  outcome?: string
  traceParent?: string
  failures?: string[]
}

export function useSalesChannelProducts(salesChannelId: string) {
  const { merchantDetails } = useMerchantService()
  const { cache } = useSWRConfig()

  const baseUrl = `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/merchants/${merchantDetails?.id}/saleschannel/${salesChannelId}/products`

  const {
    data: salesChannelProductPages,
    isValidating: isValidatingSalesChannelProducts,
    error: salesChannelProductsFetchError,
    size: salesChannelProductPagesToLoadSize,
    mutate: mutateSalesChannelProducts,
    setSize: setSalesChannelProductPagesToLoadSize
  } = useSWRInfinite<
    SalesChannelProductsSuccessResponseInterface,
    FetchErrorInterface<SalesChannelProductsErrorResponseInterface>
  >((pageIndex: number, previousPageData: SalesChannelProductsSuccessResponseInterface) => {
    const hasReachedEnd = previousPageData && !previousPageData.data.hasMore
    if (hasReachedEnd) {
      return null
    }

    const searchParams = new URLSearchParams(window.location.search)
    const isFirstPage = pageIndex === 0
    if (isFirstPage) {
      return `${baseUrl}?${searchParams.toString()}`
    }

    return previousPageData.data.nextUrl
  }, fetcher)

  const clearPagesCache = useCallback(() => {
    const keysToDelete = []

    // Note: Test when updating SWR
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    for (const key of (cache as any)?.keys?.()) {
      const cacheKey = key as string | undefined
      const isCacheKeyForNonInitialProductPage =
        cacheKey?.includes(baseUrl) && cacheKey.includes('paginationReferenceId')

      if (isCacheKeyForNonInitialProductPage) {
        keysToDelete.push(cacheKey)
      }
    }

    for (const keyToDelete of keysToDelete) {
      cache.delete(keyToDelete)
    }
  }, [baseUrl, cache])

  return {
    isLoadingSalesChannelProducts: !salesChannelProductPages && !salesChannelProductsFetchError,
    isValidatingSalesChannelProducts,
    salesChannelProductPages,
    salesChannelProductPagesToLoadSize,
    salesChannelProductsFetchError,
    clearPagesCache,
    mutateSalesChannelProducts,
    setSalesChannelProductPagesToLoadSize
  }
}
