import { Link } from 'react-router-dom'
import { Squares2X2Icon, BuildingOfficeIcon, PhotoIcon, DocumentCurrencyDollarIcon } from '@heroicons/react/24/outline'

import { useSplitToggle } from '../../../hooks'
import { NavGroupTitle } from './NavGroupTitle.component'
import { FEATURE_NAMES } from 'src/split-io/feature-names'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupCatalogue({ className }: { className?: string }) {
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })
  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return null
  }

  return (
    <div className={className}>
      <NavGroupTitle>Catalogue</NavGroupTitle>

      <NavGroupListContainer>
        {!isShopifySplitOn && (
          <li>
            <Link
              to={isAPCSplitOn ? ROUTE_PATHS.PRODUCT_CATALOGUE : ROUTE_PATHS.PRODUCT_INFO}
              className="side-nav-item"
            >
              <Squares2X2Icon className="h-7 w-7" />
              Browse products
            </Link>
          </li>
        )}

        <li>
          <Link to={ROUTE_PATHS.SALES_CHANNELS.INDEX} className="side-nav-item">
            <BuildingOfficeIcon className="h-7 w-7" />
            Sales channels
          </Link>
        </li>

        {isAPCSplitOn && (
          <li>
            <Link to="/pricing/" className="side-nav-item">
              <DocumentCurrencyDollarIcon className="h-7 w-7" />
              Pricing sheets
            </Link>
          </li>
        )}

        <li>
          <Link to={ROUTE_PATHS.IMAGE_LIBRARY.INDEX} className="side-nav-item">
            <PhotoIcon className="h-7 w-7" />
            Image library
          </Link>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
