// @flow

import type { Element } from 'react'
import type { PageAction } from '../types'

import React from 'react'
import Button from '../../Button'
import styles from './styles.module.css'

type Props = {|
  primaryAction?: PageAction,
  secondaryActions?: PageAction[]
|}

function PageFooter ({ primaryAction, secondaryActions }: Props): null | Element<"footer"> {
  if (!primaryAction && !secondaryActions) {
    return null
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.actionsContainer}>
        {secondaryActions && secondaryActions.map((action, i) => (
          <Button
            destructive={action.isDestructive}
            icon={action.icon}
            iconPosition={action.iconPosition}
            onClick={action.onClick}
            disabled={action.disabled}
            key={action.title}
          >
            {action.title}
          </Button>
        ))}
        {primaryAction && (
          <Button
            primary
            destructive={primaryAction.isDestructive}
            icon={primaryAction.icon}
            iconPosition={primaryAction.iconPosition}
            disabled={primaryAction.disabled}
            onClick={primaryAction.onClick}
          >
            {primaryAction.title}
          </Button>
        )}
      </div>
    </footer>
  )
}

export default PageFooter
