// @flow

export const SUBMIT_CSV_ORDERS = 'SUBMIT_CSV_ORDERS'
export const SUBMIT_CSV_ORDERS_SUCCESS = 'SUBMIT_CSV_ORDERS_SUCCESS'
export const SUBMIT_CSV_ORDERS_ERROR = 'SUBMIT_CSV_ORDERS_ERROR'
export const CREATE_CSV_ORDER = 'CREATE_CSV_ORDER'
export const CREATE_CSV_ORDER_SUCCESS = 'CREATE_CSV_ORDER_SUCCESS'
export const CREATE_CSV_ORDER_ERROR = 'CREATE_CSV_ORDER_ERROR'
export const ADD_IMAGES_TO_CSV_ORDER = 'ADD_IMAGES_TO_CSV_ORDER'
export const ADD_IMAGES_TO_CSV_ORDER_SUCCESS = 'ADD_IMAGES_TO_CSV_ORDER_SUCCESS'
export const ADD_IMAGES_TO_CSV_ORDER_ERROR = 'ADD_IMAGES_TO_CSV_ORDER_ERROR'
export const SUBMIT_CSV_ORDER = 'SUBMIT_CSV_ORDER'
export const SUBMIT_CSV_ORDER_SUCCESS = 'SUBMIT_CSV_ORDER_SUCCESS'
export const SUBMIT_CSV_ORDER_ERROR = 'SUBMIT_CSV_ORDER_ERROR'

export const ACTION_TYPES__SUBMIT_CSV_ORDERS = [
  SUBMIT_CSV_ORDERS,
  SUBMIT_CSV_ORDERS_SUCCESS,
  SUBMIT_CSV_ORDERS_ERROR
]
