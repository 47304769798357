// @flow
import * as React from 'react'

function GetStarted(): React$Node {
  return (
    <div className="tailwind">
      <div>
        <p>
          Simply export your orders directly from your e-commerce platform and upload the CSV file to begin processing
          orders.
        </p>
        <p className="mt-2">
          For more information please see our{' '}
          <a
            className="text-purple-500"
            href="https://www.prodigi.com/csv-importer/"
            target="_blank"
            rel="noreferrer noopener"
          >
            website
          </a>
          , or use our live chat in the corner of this page to contact us.
        </p>
      </div>

      <div className="mt-4">
        <p>Compatible with:</p>
        <div className="mt-8 mb-20 flex flex-wrap items-center gap-6">
          <ChannelImage src="/img/tools/shopify_logo.png" alt="Shopify logo" />
          <ChannelImage src="/img/tools/etsy_logo.png" alt="Etsy logo" />
          <ChannelImage src="/img/tools/woocommerce.svg" alt="WooCommerce logo" />
          <ChannelImage src="/img/tools/wix black.png" alt="Wix logo" />
          <ChannelImage src="/img/tools/squarespace-logo-horizontal-black.png" alt="Squarespace logo" />
        </div>
      </div>
    </div>
  )
}

type ChannelImagePropsType = {| alt: string, src: string |}

function ChannelImage({ alt, src }: ChannelImagePropsType) {
  return <img className="block h-10 object-contain" src={src} alt={alt} />
}

export default GetStarted
