// @flow

import { SCALE_TYPE, LENGTH_UNIT } from '@prodigi-group/components-image-editor'
import { calcScaleFactorAdjustment } from '../helpers/imageEditor'
import type { ThumbnailItem, FormattedOrderItem, ArtworkTransformations, MultiAssetTemplates, Artwork } from '../types'
import { selectTemplate } from './selectTemplate'

export function createThumbnailItemFromCsvItem(
  item: FormattedOrderItem,
  artwork: ?Artwork,
  artworkTransformations: ?ArtworkTransformations,
  templates: ?MultiAssetTemplates
): ?ThumbnailItem {
  if (!artwork || !artworkTransformations || !templates || !item.selectedPrintArea) {
    return null
  }
  const template = selectTemplate(templates, item.selectedPrintArea, artworkTransformations.orientation)

  if (!template || !template.printDpi) {
    return null
  }
  const printDpi = template.printDpi

  const artworkDimensionsInPx = {
    width: artwork.artworkWidth,
    height: artwork.artworkHeight
  }
  const scaleFactorAdjustment = calcScaleFactorAdjustment(
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    printDpi,
    artworkTransformations.borderFactor,
    artworkTransformations.borderScale
  )

  return {
    id: item.id,
    artwork,
    cropRectangle: template.cropRectangle,
    transformations: {
      borderUnit: artworkTransformations.borderScale,
      borderWidth: artworkTransformations.borderFactor,
      rotationAngle: artworkTransformations.rotationAngle,
      scaleFactor: (artworkTransformations.scaleFactor * scaleFactorAdjustment) / 100,
      scaleType: SCALE_TYPE.PRINT_SIZE,
      position: artworkTransformations.position,
      positionUnit: LENGTH_UNIT.PRINT_PX,
      isTiled: artworkTransformations.isTiled
    },
    printDimensionsInPx: template.printResolution,
    artworkDimensionsInPx,
    imageUrls: {
      overlay: template.imageUrls.overlay,
      underlay: template.imageUrls.underlay,
      artwork: artwork.smallImageUrl
    },
    printDpi
  }
}
