import { formatCost } from '../../../helpers'
import ClipboardCopy from '../../ClipboardCopy'
import { useMerchantService } from '../../../hooks'
import OrderItemSource from '../../OrderItemSource'
import { getCountryName } from '../../../../helpers/countryNames'
import { CostInterface } from '../../../interfaces/Cost.interface'

interface OrderDetailSummaryPropsInterface {
  merchantReference?: string
  numberOfItems: number
  recipientAddressCountryCode: string
  recipientName: string
  salesChannelId: string
  totalCost: CostInterface
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export function OrderDetailSummary({
  merchantReference,
  numberOfItems,
  recipientAddressCountryCode,
  recipientName,
  salesChannelId,
  totalCost
}: OrderDetailSummaryPropsInterface) {
  const { merchantDetails } = useMerchantService()

  const salesChannel = merchantDetails?.salesChannels.find((channel) => channel.id.toString() === salesChannelId)

  const formattedCurrency = formatCost({
    amount: totalCost?.amount,
    currencyCode: totalCost?.currencyCode
  })

  return (
    <div className="isolate w-full" data-test="order-detail-summary">
      <div className="flex flex-col border bg-white p-6">
        <h2 className="mt-0 text-lg">Summary</h2>
        <span className="mt-4" data-test="order-detail-summary__recipient-name">
          {recipientName}
        </span>
        <span data-test="order-detail-summary__country">{getCountryName(recipientAddressCountryCode)}</span>
        <span className="mt-4" data-test="order-detail-summary__items">
          {numberOfItems} {numberOfItems > 1 ? 'items' : 'item'}, {formattedCurrency || 'Pending'}
        </span>

        {merchantReference && (
          <div className="mt-4">
            <div className="font-medium">Order reference</div>
            <div data-test="order-detail-summary__reference">
              <ClipboardCopy text={merchantReference} />
            </div>
          </div>
        )}

        {salesChannel && <OrderItemSource className="mt-4" source={salesChannel} />}
      </div>
    </div>
  )
}
