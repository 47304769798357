import { useMemo } from 'react'
import { motion } from 'framer-motion'
import { useHistory, useLocation } from 'react-router'

import Button from '../../Button'
import EmptyState from '../../EmptyState'
import { QUERY_PARAMS } from '../../../constants'
import { ProductCards } from './ProductCards.component'
import { CatalogueCategoryInterface } from '../../../hooks'

export function SearchResults({ searchData }: { searchData: CatalogueCategoryInterface | undefined }) {
  const location = useLocation()
  const history = useHistory()

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const doesSearchDataHaveProducts = searchData && Object.keys(searchData.products).length > 0

  if (doesSearchDataHaveProducts) {
    return <ProductCards products={searchData.products} />
  }

  function handleClear() {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(QUERY_PARAMS.PRODUCT_CATALOGUE.SEARCH)
    history.push({ search: newSearchParams.toString() })
  }

  return (
    <div className="pt-6">
      <EmptyState>
        <div className="mx-auto max-w-5xl">
          <h2 className="mt-16">We could not find any matching products</h2>

          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: -10, opacity: 0 }}
            className="mt-16"
            transition={{ delay: 0.3 }}
          >
            <Button variant="primary" className="mx-auto w-fit" onClick={handleClear}>
              Clear search
            </Button>
          </motion.div>
        </div>
      </EmptyState>
    </div>
  )
}
