import { useState } from 'react'

import {
  PrintAreasType,
  ArtworkInterface,
  ArtworkProgressType,
  ArtworkTransformationsInterface,
  ImageEditorPrintAreaArtworkInterface
} from '../interfaces'
import { DictionaryInterface, PrintAreaNameType } from '../../../interfaces'
import { ImageLibraryConfigPropInterface } from '../../ImageLibrary/ImageLibrary.component'
import { TemplateServiceSuccessResponseInterface } from '../../../hooks/useTemplateService.hook'
import MultiAssetImageEditor, { ImageEditorSaveDataInterface } from './MultiAssetImageEditor.component'
import { buildCroppedImageUrls, getDefaultTransformations, generatePigUrlsForArtworkInTemplate } from '../helpers'

interface ImageEditorControllerPropsInterface {
  category: string
  productType: string
  description: string
  enableImageLibraryThumbnailRefresh?: boolean
  imageLibraryConfig?: ImageLibraryConfigPropInterface
  isSaving?: boolean
  printAreas: Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface | null | undefined>
  productSku: string
  templates: TemplateServiceSuccessResponseInterface
  selectedAttributes: DictionaryInterface<string>
  selectedPrintArea: string
  supportsTiling: boolean
  supportsPrintBorders: boolean
  onCancel: () => void
  onOpenImageLibrary?: () => void
  onSave: (
    printAreasArtworkData: Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface | null | undefined>,
    selectedPrintArea: string
  ) => void
}

function getDefaultPrintAreas({
  printAreas,
  templates
}: {
  printAreas: Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface | null | undefined>
  templates: TemplateServiceSuccessResponseInterface
}): Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface> {
  const defaultPrintAreasData = Object.entries(printAreas).reduce(
    (
      defaultPrintAreasDataAcc: Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface>,
      [printAreaName, printAreaData]
    ) => {
      if (!printAreaData || !printAreaData.artwork) {
        return {
          ...defaultPrintAreasDataAcc,
          [printAreaName]: {}
        }
      }

      if (printAreaData.artworkTransformations) {
        return {
          ...defaultPrintAreasDataAcc,
          [printAreaName]: printAreaData
        }
      }

      const defaultArtworkTransformations = getDefaultTransformations(
        printAreaData.artwork,
        templates,
        'fillPrintArea',
        printAreaName
      )

      if (!defaultArtworkTransformations) {
        return { ...defaultPrintAreasDataAcc, [printAreaName]: {} }
      }

      const pigUrls = generatePigUrlsForArtworkInTemplate({
        artwork: printAreaData.artwork,
        transformations: defaultArtworkTransformations,
        printAreaName,
        templates
      })

      if (!pigUrls) {
        return { ...defaultPrintAreasDataAcc, [printAreaName]: {} }
      }

      const { previewImageUrl, printImageUrl } = pigUrls
      return {
        ...defaultPrintAreasDataAcc,
        [printAreaName]: {
          ...printAreaData,
          artworkTransformations: defaultArtworkTransformations,
          previewImageUrl,
          printImageUrl,
          transformImageUrl: printAreaData.artwork.originalImageUrl
        }
      }
    },
    {}
  )

  return defaultPrintAreasData
}

export function ImageEditorController({
  category,
  productType,
  description,
  enableImageLibraryThumbnailRefresh = false,
  imageLibraryConfig,
  isSaving = false,
  printAreas,
  productSku,
  templates,
  selectedAttributes,
  selectedPrintArea,
  supportsTiling,
  supportsPrintBorders,
  onCancel,
  onOpenImageLibrary,
  onSave
}: ImageEditorControllerPropsInterface) {
  const [printAreasState, setPrintAreasState] = useState<PrintAreasType>(() =>
    getDefaultPrintAreas({ printAreas, templates })
  )
  const [artworkUploadProgress, setArtworkUploadProgress] = useState<ArtworkProgressType>({})

  function handleSelectImageLibraryImage({
    artwork,
    artworkTransformations,
    selectedPrintArea
  }: {
    artwork: ArtworkInterface
    artworkTransformations: ArtworkTransformationsInterface
    selectedPrintArea: string
  }) {
    setPrintAreasState({
      ...printAreasState,
      [selectedPrintArea]: {
        ...printAreasState[selectedPrintArea],
        artwork,
        artworkTransformations
      }
    })
  }

  function handleRemoveArtwork(printAreaName: string) {
    setPrintAreasState({
      ...printAreasState,
      [printAreaName]: {}
    })
  }

  function handleResetImage(printAreaName: string) {
    setArtworkUploadProgress({
      ...artworkUploadProgress,
      [printAreaName]: {
        ...artworkUploadProgress[printAreaName],
        status: 'IDLE',
        errorMessage: ''
      }
    })
  }

  function handleSave(
    artworkTransformations: Record<string, ArtworkTransformationsInterface | null | undefined>,
    selectedPrintArea: string,
    { transformDataByPrintArea }: ImageEditorSaveDataInterface
  ) {
    const pigUrls = buildCroppedImageUrls(printAreasState, artworkTransformations, templates)
    const updatedPrintAreasData = Object.entries(artworkTransformations).reduce(
      (
        artworkTransformationsAcc: Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface | null>,
        [printAreaName, updatedArtworkTransformations]
      ) => {
        const printAreaArtwork = printAreasState[printAreaName].artwork
        let transformImageUrl = transformDataByPrintArea[printAreaName]?.transformationApiUrl

        if (!updatedArtworkTransformations || !pigUrls[printAreaName] || !printAreaArtwork || !transformImageUrl) {
          return {
            ...artworkTransformationsAcc,
            [printAreaName]: null
          }
        }

        let printImageUrl = pigUrls[printAreaName].printImageUrl
        let previewImageUrl = pigUrls[printAreaName].previewImageUrl

        if (printAreaArtwork.mimeType === 'application/pdf') {
          transformImageUrl = printAreaArtwork.originalImageUrl
          printImageUrl = printAreaArtwork.originalImageUrl
          previewImageUrl = printAreaArtwork.originalImageUrl
        }

        const updatedPrintAreaArtworkData: ImageEditorPrintAreaArtworkInterface = {
          artwork: printAreaArtwork,
          artworkTransformations: updatedArtworkTransformations,
          printImageUrl,
          previewImageUrl,
          transformImageUrl
        }

        return {
          ...artworkTransformationsAcc,
          [printAreaName]: updatedPrintAreaArtworkData
        }
      },
      {}
    )

    onSave(updatedPrintAreasData, selectedPrintArea)
  }

  return (
    <MultiAssetImageEditor
      artworkUploadProgress={artworkUploadProgress}
      description={description}
      enableImageLibraryThumbnailRefresh={enableImageLibraryThumbnailRefresh}
      imageLibraryConfig={imageLibraryConfig}
      itemCategory={category}
      productType={productType}
      productSku={productSku}
      isSaving={isSaving}
      printAreas={printAreasState}
      selectedAttributes={selectedAttributes}
      selectedPrintArea={selectedPrintArea}
      showRemoveArtworkButton={true}
      supportsTiling={supportsTiling}
      supportsPrintBorders={supportsPrintBorders}
      templates={templates}
      buildImageId={(printAreaName: string) => printAreaName}
      onCancel={onCancel}
      onOpenImageLibrary={onOpenImageLibrary}
      onRemoveArtwork={handleRemoveArtwork}
      onSave={handleSave}
      onSelectImageLibraryImage={handleSelectImageLibraryImage}
      resetImage={handleResetImage}
    />
  )
}
