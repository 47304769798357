import { formatDateToString } from '../../../helpers'

export function formatDateFilter({
  startDateParam,
  endDateParam
}: {
  startDateParam: string | null
  endDateParam: string | null
}) {
  const selectedStartDateOption = startDateParam ? new Date(startDateParam) : null
  const selectedEndDateOption = endDateParam ? new Date(endDateParam) : null
  if (selectedStartDateOption && !selectedEndDateOption) {
    return `Since ${formatDateToString(selectedStartDateOption)}`
  } else if (!selectedStartDateOption && selectedEndDateOption) {
    return `Up to ${formatDateToString(selectedEndDateOption)}`
  } else {
    return `${formatDateToString(selectedStartDateOption)} to ${formatDateToString(selectedEndDateOption)}`
  }
}
