// @flow
import type { ArtworkTransformations } from '../../types'
import type { UpdateArtworkTransformations } from '../types'

export const UPDATE_ARTWORK_TRANSFORMATIONS = 'UPDATE_ARTWORK_TRANSFORMATIONS'

export function updateArtworkTransformations (
  transformations: ArtworkTransformations,
  itemId: string
): UpdateArtworkTransformations {
  return {
    type: UPDATE_ARTWORK_TRANSFORMATIONS,
    id: itemId,
    transformations
  }
}
