// @flow
import type { AuthTokens } from '../../types'
import { REFRESH_TOKEN, ACCESS_TOKEN } from '../../data/localStorageKeys'

export function saveJwtTokens ({accessToken, refreshToken}: AuthTokens): void {
  saveAccessToken(accessToken)
  saveRefreshToken(refreshToken)
}

export function removeJwtTokens (): void {
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
}

export function saveAccessToken (accessToken: string): void {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken)
}

export function saveRefreshToken (refreshToken: string): void {
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken)
}

export function getAccessToken (): ?string {
  return window.localStorage.getItem(ACCESS_TOKEN)
}

export function getRefreshToken (): ?string {
  return window.localStorage.getItem(REFRESH_TOKEN)
}
