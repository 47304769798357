// @flow
import type { Dictionary, FormattedOrderItem } from '../../types'
import { values } from '../../helpers/dictionary'
import productAttributeFormat from '../../helpers/productAttributeFormat'
import { getAttrNameForCategory } from '../../helpers/getAttrNameForCategory'

export function createListOfCsvSkus (orderItems: Dictionary<FormattedOrderItem>): string[] {
  const itemSkus = []

  values(orderItems).forEach(item => {
    if (item.sku) {
      itemSkus.push(item.sku)
    }
  })

  return Array.from(new Set(itemSkus))
}

type Attribute = {|
  name: string,
  value: string
|}

export function getFormattedAttribute (attributeName: string, attributeValue: string, category: string): Attribute {
  return {
    name: getAttrNameForCategory(attributeName, category),
    value: productAttributeFormat(attributeValue)
  }
}
