// @flow
import type { AppState, DispatchFunc, Country } from '../../types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CsvUploadSplit from './CsvUploadSplit'
import { uploadCsv, UPLOAD_CSV } from '../../actions/csvUpload'
import { getItemCategories } from '../../actions/catalogue'
import { fetchCountries } from '../../actions/manualOrderForm'
import { getUser } from '../../selectors/user'
import {
  getIsUploadingImages,
  getHasCsvUploadedSuccessfully,
  getIsFetchingCsvOrderQuotes,
  getIsFetchingV3ProductDetailsForCsvOrders,
  getIsFetchingV4ProductDetailsForCsvOrders,
  getIsFetchingTemplates
} from '../../selectors/csvUpload'
import WithRsaa from '../../components/hoc/WithRsaa'
import WithUserDetails from '../../components/hoc/WithUserDetails'

type DispatchProps = {|
  uploadCsv: (file: File, countries: Country[]) => void,
  getItemCategories: () => void,
  fetchCountries: () => void
|}

type StateProps = {|
  merchantId: string,
  hasCsvUploadedSuccessfully: boolean,
  countries: Country[],
  isFetchingQuotes: boolean,
  isFetchingV4ProductDetails: boolean,
  isFetchingV3ProductDetails: boolean,
  isFetchingTemplates: boolean,
  isUploadingImages: boolean
|}

function mapStateToProps(state: AppState): StateProps {
  return {
    hasCsvUploadedSuccessfully: getHasCsvUploadedSuccessfully(state),
    countries: state.manualOrderForm.countries,
    isUploadingImages: getIsUploadingImages(state),
    isFetchingQuotes: getIsFetchingCsvOrderQuotes(state),
    isFetchingV4ProductDetails: getIsFetchingV4ProductDetailsForCsvOrders(state),
    isFetchingV3ProductDetails: getIsFetchingV3ProductDetailsForCsvOrders(state),
    isFetchingTemplates: getIsFetchingTemplates(state),
    merchantId: getUser(state).merchantUniqueId
  }
}

function mapDispatchToProps(dispatch: DispatchFunc): DispatchProps {
  return {
    uploadCsv: (file: File, countries: Country[]) => {
      window.analytics.track('Upload csv file', { label: 'csv uploader' })
      dispatch(uploadCsv(file, countries))
    },
    getItemCategories: () => {
      dispatch(getItemCategories())
    },
    fetchCountries: () => {
      dispatch(fetchCountries())
    }
  }
}

export default (compose(
  WithUserDetails,
  WithRsaa(UPLOAD_CSV, 'csv', false),
  connect(mapStateToProps, mapDispatchToProps)
)(CsvUploadSplit): any)
