// @flow
import React from 'react'

import DomainsView from './DomainsView'
import { ABORT_ERROR_NAME } from '../../../../../data/constants'
import processFetchResponse from '../../../../../helpers/processFetchResponse'
import getDefaultFetchOptions from '../../../../../helpers/getDefaultFetchOptions'
import { PRINT_ENGINE_PARAMS, PRINT_ENGINE_URL } from '../../../../Apps/constants'
import type { AbortController, AppSettingsType, Match, MatchAppType, User } from '../../../../../types'

const {
  APP_TYPE,
  BRAND_NAME,
  DOMAINS,
  MERCHANT_ID
} = PRINT_ENGINE_PARAMS

type Props = {|
  appTypeSelected: MatchAppType,
  match: Match,
  user: User,
  appSettings: AppSettingsType,
  onUpdateDomains: (domains: {| [key: string]: {| is_validated: boolean |} |}) => void,
  onAddDomain: (domain: string) => void
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  isUpdatingDomains: boolean,
  updateDomainsError: boolean
|}

export default class DomainsController extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const controller = new window.AbortController()
    const signal = controller.signal

    this.state = {
      abortController: controller,
      abortSignal: signal,
      isUpdatingDomains: false,
      updateDomainsError: false
    }
  }

  handleUpdateDomains: ((domainToAdd?: string) => void) = (domainToAdd?: string) => {
    if (domainToAdd) {
      this.props.onAddDomain(domainToAdd)
    }

    this.setState({
      isUpdatingDomains: true,
      updateDomainsError: false
    })

    const { appTypeSelected, user } = this.props
    const { appId } = this.props.match.params
    const { appSettings } = this.props
    const printEngineAppType = appTypeSelected.printEngineType

    const url = `${PRINT_ENGINE_URL}/domain/`
    fetch(url, {
      ...getDefaultFetchOptions(),
      method: 'POST',
      body: JSON.stringify({
        [APP_TYPE]: printEngineAppType,
        [BRAND_NAME]: appId,
        [DOMAINS]: (appSettings.domains).map((domain) => domain.value),
        [MERCHANT_ID]: user.merchantUniqueId
      }),
      signal: this.state.abortSignal
    })
      .then(processFetchResponse)
      .then((json) => {
        this.props.onUpdateDomains(json)
        this.setState({ isUpdatingDomains: false })
      })
      .catch((err) => {
        if (err.name !== ABORT_ERROR_NAME) {
          console.error(err)
          this.setState({
            isUpdatingDomains: false,
            updateDomainsError: true
          })
        }
      })
  }

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    const {
      user
    } = this.props
    const { appId, appType } = this.props.match.params
    const { appSettings } = this.props
    const {
      isUpdatingDomains,
      updateDomainsError
    } = this.state

    return (
      <DomainsView
        appId={appId}
        appType={appType}
        domains={appSettings.domains}
        isUpdatingDomains={isUpdatingDomains}
        updateDomainsError={updateDomainsError}
        user={user}
        onUpdateDomainStatus={(domainAdded?: string) => this.handleUpdateDomains(domainAdded)} />
    )
  }
}
