import clsx from 'clsx'
import { ReactNode } from 'react'
import { AnimatePresence } from 'framer-motion'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { CloudArrowUpIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'

import { DropboxLogo } from '../../svg/DropboxLogo.icon'
import { InlineMenuOptionInterface } from '../../InlineMenu'
import SingleSelectDropdown from '../../SingleSelectDropdown'

export type UploadOptionValueType = 'file' | 'url' | 'dropbox'

interface UploadOptionInterface extends InlineMenuOptionInterface {
  value: UploadOptionValueType
}

export const UPLOAD_OPTIONS: UploadOptionInterface[] = [
  {
    content: (
      <span className="flex items-center pr-10">
        <CloudArrowUpIcon className="mr-2 h-7 w-7 text-gray-700" /> Upload images
      </span>
    ),
    searchLabel: 'Upload images',
    value: 'file'
  },
  {
    content: (
      <span className="flex items-center pr-10">
        <GlobeAmericasIcon className="mr-2 h-7 w-7 text-gray-700" /> Add from URL
      </span>
    ),
    searchLabel: 'Add from URL',
    value: 'url'
  },
  {
    content: (
      <span className="flex items-center pr-10">
        <DropboxLogo className="mr-2 h-7 w-7 text-gray-500" /> Add from Dropbox
      </span>
    ),
    searchLabel: 'Add from Dropbox',
    value: 'dropbox'
  }
]

interface UploadOptionsPropsInterface {
  children: ReactNode
  manageLibrary: boolean
  uploadChoice: UploadOptionValueType
  setUploadChoice: (value: UploadOptionValueType) => void
}

export function UploadOptions({ children, manageLibrary, uploadChoice, setUploadChoice }: UploadOptionsPropsInterface) {
  return (
    <div
      className={clsx('flex gap-3 transition-opacity', {
        'pointer-events-none opacity-60': manageLibrary
      })}
    >
      <div>
        <AnimatePresence initial={false} exitBeforeEnter>
          {children}
        </AnimatePresence>
      </div>

      <div className="mr-4">
        <SingleSelectDropdown
          align="start"
          className="aspect-square h-full w-fit border-gray-500"
          dataTest="upload-options-dropdown-button"
          dropdownName="Options"
          triggerContent={
            <>
              <EllipsisHorizontalIcon className="mx-auto h-7 w-7 text-black" />
              <span className="sr-only">Options</span>
            </>
          }
          options={UPLOAD_OPTIONS.filter((uploadOption) => uploadOption.value !== uploadChoice)}
          updateSelected={(value) => setUploadChoice(value as UploadOptionValueType)}
        />
      </div>
    </div>
  )
}
