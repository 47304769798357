import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
import { motion, useMotionValue, useTransform } from 'framer-motion'

interface ClipboardCopyPropsInterface {
  className?: string
  showText?: boolean
  text: string
}

const ANIMATION_DURATION = 0.4
const CLIPBOARD_ICON_VARIANTS = {
  showTick: { opacity: 0 },
  hideTick: { opacity: 1 }
}
const CHECKMARK_ICON_VARIANTS = {
  showTick: { pathLength: 1 },
  hideTick: { pathLength: 0 }
}

export default function ClipboardCopy({ className = '', showText = true, text }: ClipboardCopyPropsInterface) {
  const [showTick, setShowTick] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>()

  const pathLength = useMotionValue(0)
  const opacity = useTransform(pathLength, [0, 0.5], [0, 1])

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId)
    }
  }, [timeoutId])

  const iconClassName = 'absolute h-7 w-7 cursor-pointer transition-colors'
  return (
    <span className={twMerge('inline-flex items-center align-bottom', className)}>
      {showText && <span data-test="clipboard-copy-text">{text}</span>}
      <button
        className="relative mx-1 flex h-7 w-7 items-center justify-center text-gray-500 hover:text-purple-500"
        onClick={() => {
          navigator.clipboard.writeText(text)
          setShowTick(true)
          clearTimeout(timeoutId)
          const newTimeoutId = setTimeout(() => {
            setShowTick(false)
          }, 2500)
          setTimeoutId(newTimeoutId)
        }}
      >
        <span className="sr-only">Copy to clipboard</span>

        {/* Clipboard icon */}
        <svg
          className={`${iconClassName} text-inherit`}
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            animate={showTick ? 'showTick' : 'hideTick'}
            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
            initial={false}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            transition={{ duration: ANIMATION_DURATION }}
            variants={CLIPBOARD_ICON_VARIANTS}
          />
        </svg>

        {/* Checkmark icon */}
        <svg
          className={`${iconClassName} text-green-600`}
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            animate={showTick ? 'showTick' : 'hideTick'}
            d="M5 13l4 4L19 7"
            initial={false}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ pathLength, opacity }}
            transition={{ duration: ANIMATION_DURATION }}
            variants={CHECKMARK_ICON_VARIANTS}
          />
        </svg>
      </button>
    </span>
  )
}
