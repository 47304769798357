// @flow
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import AsyncApp from './AsyncApp'
import configureStore from '../configureStore'

if (process.env.REACT_APP_USE_DEV_TOOLS) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, { trackAllPureComponents: true })
}

const store = configureStore()

type Props = {|
  store: any
|}

class Root extends Component<Props> {
  render (): React$Node {
    return (
      <Provider store={store}>
        <AsyncApp history={store.getState().router.history} />
      </Provider>)
  }
}

export default Root
