import { useLocation } from 'react-router'
import { Elements } from '@stripe/react-stripe-js'

import NotFound from '../NotFound'
import { QUERY_PARAMS } from '../../constants'
import { PaymentAuthContent, PaymentAuthContainer } from './components'
import { DEFAULT_STRIPE_OPTIONS, stripePromise } from '../../helpers/stripePromise.helper'

export default function PaymentAuth() {
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const orderId = params.get(QUERY_PARAMS.PAYMENT.ORDER_ID)
  const fulfilmentId = params.get(QUERY_PARAMS.PAYMENT.FULFILMENT_ID)

  if (!orderId || !fulfilmentId) {
    return (
      <PaymentAuthContainer>
        <NotFound />
      </PaymentAuthContainer>
    )
  }

  return (
    <Elements stripe={stripePromise} options={DEFAULT_STRIPE_OPTIONS}>
      <PaymentAuthContainer>
        <PaymentAuthContent fulfilmentId={fulfilmentId} orderId={orderId} />
      </PaymentAuthContainer>
    </Elements>
  )
}
