// @flow
import type { MultiAssetTemplates, MultiAssetTemplate, Orientation } from '../types'

export function selectTemplate (templates: MultiAssetTemplates, printAreaName: string, orientation: ?Orientation): ?MultiAssetTemplate {
  if (!orientation) {
    return null
  }

  return templates.printAreas[printAreaName]?.orientations[orientation]
}
