import { ReactNode } from 'react'
import { SALES_EMAIL } from './constants'

interface SalesLinkPropsInterface {
  children: ReactNode
  className?: string
}

export default function SalesLink({ children, className }: SalesLinkPropsInterface) {
  return (
    <a className={`text-purple-500 ${className}`} href={`mailto:${SALES_EMAIL}`}>
      {children}
    </a>
  )
}
