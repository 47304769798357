// @flow
import type { CsvUploadState } from '../../types'
import type { CsvOrderSubmissionAction } from '../../actions/types'

export function updateOrderSubmissionProgress (state: CsvUploadState, action: CsvOrderSubmissionAction<any>): CsvUploadState {
  const orderId = action.order.id
  const hasPwintyOrderId = state.progress[orderId] && state.progress[orderId].pwintyOrderId

  return {
    ...state,
    progress: {
      ...state.progress,
      [orderId]: {
        id: orderId,
        lastActionType: action.type,
        pwintyOrderId: hasPwintyOrderId ? state.progress[orderId].pwintyOrderId : action.pwintyOrderId,
        errorMessage: action.errorMessage,
        statusCode: action.statusCode
      }
    }
  }
}
