import { cva } from 'class-variance-authority'

export const buttonStyles = cva(null, {
  variants: {
    variant: {
      primary: 'btn',
      secondary: 'btn',
      tertiary: 'btn',
      text: 'btn',
      none: null
    },
    size: {
      sm: 'btn-sm',
      md: ''
    },
    theme: {
      brand: null,
      danger: null,
      greyscale: null,
      none: null
    }
  },
  compoundVariants: [
    {
      variant: 'primary',
      theme: 'brand',
      className: 'btn-primary'
    },
    {
      variant: 'primary',
      theme: 'danger',
      className: 'btn-danger'
    },
    {
      variant: 'secondary',
      theme: 'brand',
      className: 'btn-secondary'
    },
    {
      variant: 'secondary',
      theme: 'danger',
      className: 'btn-secondary btn-danger'
    },
    {
      variant: 'tertiary',
      theme: 'brand',
      className: 'btn-primary btn-tertiary'
    },
    {
      variant: 'tertiary',
      theme: 'danger',
      className: 'btn-danger btn-tertiary'
    },
    {
      variant: 'tertiary',
      theme: 'greyscale',
      className: 'btn-tertiary'
    },
    {
      variant: 'text',
      theme: 'brand',
      className: 'btn-primary btn-text'
    },
    {
      variant: 'text',
      theme: 'danger',
      className: 'btn-danger btn-text'
    },
    {
      variant: 'text',
      theme: 'greyscale',
      className: 'btn-text'
    }
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'md',
    theme: 'brand'
  }
})
