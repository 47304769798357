// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function BigCommerceIcon ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="30" cy="30" r="30" />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.7214 17.0527C41.8362 16.9379 42.0371 17.024 41.9941 17.1818V40.8273C41.9941 40.9134 41.9223 40.9851 41.8362 40.9851H18.2051C18.0186 40.9851 17.9325 40.7555 18.0617 40.6264L27.89 30.8411V37.599C27.89 37.6851 27.9761 37.7712 28.0765 37.7712H34.4183C36.7284 37.7712 37.9192 36.3507 37.9192 34.5717C37.9192 33.309 37.1875 32.2329 36.1544 31.8311C35.9966 31.7737 35.9966 31.5729 36.1401 31.5155C37.0297 31.1137 37.8618 30.2098 37.8618 28.9759C37.8618 27.4119 36.5131 25.9771 34.2318 25.9771H32.7683L41.7214 17.0527ZM33.7726 32.8499C34.7627 32.8499 35.3079 33.4524 35.3079 34.1986C35.3079 35.0595 34.734 35.5473 33.7726 35.5473H30.6448C30.5444 35.5473 30.4726 35.4756 30.4583 35.3608V33.0221C30.4583 32.936 30.5444 32.8499 30.6448 32.8499H33.7726ZM33.6723 28.1868C34.5332 28.1868 35.0783 28.7033 35.0783 29.4207C35.0783 30.1811 34.5332 30.6546 33.6723 30.6546H30.6448C30.5444 30.6546 30.4726 30.5829 30.4583 30.4825V28.359C30.4583 28.2729 30.5444 28.1868 30.6448 28.1868H33.6723Z" />
    </svg>
  )
}
