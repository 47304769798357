// @flow
import type { AppState, Quote, RsaaStatus, Dictionary } from '../../types'
import type { GetCsvOrderQuoteRsaaStatusByOrderId, GetQuotesById } from './types'
import { GET_MOF_QUOTES_REQUEST_ID, GET_QUOTES } from '../../actions/quotes'
import { buildRsaaActionId } from '../../helpers/rsaa'
import { selectRsaaStatus } from '../rsaa'
import { identity, returnSecondArg } from '../helpers'
import { createSelector } from 'reselect'

export function selectQuotes (state: AppState): Dictionary<Quote[]> {
  return state.quotes
}

export function selectMofQuotes (state: AppState): Quote[] {
  return state.quotes[GET_MOF_QUOTES_REQUEST_ID] ?? []
}

export function selectCsvOrderQuoteRsaaStatusByOrderId (
  state: AppState,
  orderId: string
): RsaaStatus {
  const actionId = buildRsaaActionId(GET_QUOTES, orderId)

  return selectRsaaStatus(state, actionId)
}

export function selectQuotesById (quotes: Dictionary<Quote[]>, id: string): Quote[] {
  return quotes[id] ?? []
}

export const getCsvOrderQuoteRsaaStatusByOrderId: GetCsvOrderQuoteRsaaStatusByOrderId = createSelector(selectCsvOrderQuoteRsaaStatusByOrderId, identity)

export const getQuotesById: GetQuotesById = createSelector(
  selectQuotes,
  returnSecondArg,
  selectQuotesById
)
