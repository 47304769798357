// @flow
import { ACTION_TYPES__UPLOAD_CSV } from '../csvUpload/uploadCsv'
import { ACTION_TYPES__SUBMIT_CSV_ORDERS } from '../csvUpload/orderSubmissionActions'
import { ACTION_TYPES__TAKE_STRIPE_PAYMENT, ACTION_TYPES__SUBMIT_CARD_TO_STRIPE } from '../payments'
import { ACTION_TYPES__UPDATE_USER } from '../user/updateUser'
import type { DispatchFunc, Thunk } from '../../types'
import { addRsaaAction } from './addRsaaAction'
import { ACTION_TYPES__ADD_PRODUCT_TO_BASKET } from '../manualOrderForm/addProductToBasket'

// $FlowFixMe: TypeScript file
import { ACTION_TYPES__LOG_IN } from '../../v2/actions'

export const RSAA_ACTION_TYPES = [
  ACTION_TYPES__LOG_IN,
  ACTION_TYPES__UPLOAD_CSV,
  ACTION_TYPES__SUBMIT_CSV_ORDERS,
  ACTION_TYPES__ADD_PRODUCT_TO_BASKET,
  ACTION_TYPES__TAKE_STRIPE_PAYMENT,
  ACTION_TYPES__SUBMIT_CARD_TO_STRIPE,
  ACTION_TYPES__UPDATE_USER
]

export function configureRsaaLogging(): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    RSAA_ACTION_TYPES.forEach((actionTypes) => {
      if (!actionTypes) {
        // this "solves" a problem with hot reloading that sometimes occurs during the development
        window.location.reload()
      }
      dispatch(addRsaaAction(actionTypes))
    })
  }
}
