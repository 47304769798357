import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'

import { formatCost } from '../../../helpers'
import OrderIssueFlag from '../../OrderIssueFlag'
import dateFormat from '../../../helpers/dateFormat'
import timeFormat from '../../../helpers/timeFormat'
import { OrderDetailIssueEnum } from '../../../enums'
import { OrdersListItemInterface } from '../../../interfaces'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { getCountryName } from '../../../../helpers/countryNames'
import { OrderListItemSource } from './OrderListItemSource.component'
import OrderStatusFlag from '../../OrderStatusFlag/OrderStatusFlag.component'

interface OrdersListItemComponentInterface {
  index: number
  ordersListItem: OrdersListItemInterface
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function OrdersListItem({ index, ordersListItem }: OrdersListItemComponentInterface) {
  const formattedCurrency = formatCost({
    amount: ordersListItem.totalCost?.amount,
    currencyCode: ordersListItem.totalCost?.currencyCode
  })
  const { id: orderId } = ordersListItem

  const unresolvedCSIssues =
    ordersListItem?.unresolvedIssues?.filter((issue) => issue !== OrderDetailIssueEnum.PaymentRequiresAuthorisation) ??
    []

  return (
    <motion.tr
      onClick={() => {
        window.analytics.track('Clicked Order Details', { orderId })
      }}
      initial={{ y: -4, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: ordersListItem.animationDelay }}
      className="block cursor-pointer border-t border-solid transition-colors duration-200 ease-in first:border-none focus-within:outline focus-within:outline-2 focus-within:outline-offset-[-2px] focus-within:outline-purple-300 hover:bg-purple-100 hover:duration-100 hover:ease-out active:outline-none sm:table-row"
      data-test={`orders-list-item-row-${index}`}
      data-test-id={`${orderId}`}
    >
      <td className="block align-top sm:table-cell">
        <OrderListItemLink orderId={orderId} tabIndex={0} className="h-full">
          <div className="flex h-full flex-row items-end justify-between pl-4 pt-6 sm:inline-block sm:pl-8 sm:pb-6">
            <span className="flex items-center space-x-2">
              <span className="flex sm:block">{orderId}</span>
              {unresolvedCSIssues.length > 0 && <OrderIssueFlag condensed unresolvedIssues={unresolvedCSIssues} />}
            </span>
            <OrderStatusFlag className="pr-4 sm:pr-0" statusText={ordersListItem.status} reverseOnMobile />
          </div>
        </OrderListItemLink>
      </td>

      <td className="block align-top sm:table-cell">
        <OrderListItemLink orderId={orderId} className="h-full">
          <div className="h-full pl-4 pt-2 sm:pb-6 sm:pt-6 xl:whitespace-nowrap">
            {ordersListItem.recipientName}
            <span className="sm:hidden">,</span>{' '}
            <span className="font-light sm:block">{getCountryName(ordersListItem.recipientAddressCountryCode)}</span>
          </div>
        </OrderListItemLink>
      </td>

      <td
        className="block align-top sm:table-cell"
        data-test={`orders-list-item-row-date`}
        data-test-date={`${ordersListItem?.createdDate}`}
      >
        <OrderListItemLink orderId={orderId} className="h-full">
          <div className="h-full pl-4 pt-2 sm:pb-6 sm:pt-6 lg:whitespace-nowrap">
            <span>{dateFormat(ordersListItem.createdDate)}</span>{' '}
            <span className="font-light sm:block">at {timeFormat(ordersListItem.createdDate)}</span>
          </div>
        </OrderListItemLink>
      </td>

      <td className="block align-top sm:table-cell">
        <OrderListItemLink orderId={orderId} className="h-full">
          <div className="h-full pl-4 pt-2 sm:pb-6 sm:pt-6">
            <OrderListItemSource salesChannelId={ordersListItem.salesChannelId} />
          </div>
        </OrderListItemLink>
      </td>

      <td className="block align-top sm:table-cell">
        <OrderListItemLink orderId={orderId} className="h-full">
          <div className="h-full whitespace-nowrap pl-4 pr-8 pt-2 pb-6 sm:pb-6 sm:pt-6 sm:text-right">
            {formattedCurrency || 'Pending'}
          </div>
        </OrderListItemLink>
      </td>
    </motion.tr>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

function OrderListItemLink({
  orderId,
  className,
  children,
  tabIndex = -1
}: {
  orderId: string
  className?: string
  children: ReactNode
  tabIndex?: number
}) {
  const location = useLocation()
  const currentPageQueryParams = new URLSearchParams(location.search).toString()
  const orderDetailLinkQueryParams = currentPageQueryParams
    ? `?${QUERY_PARAMS.ORDER_DETAIL.PREV}=${encodeURIComponent(currentPageQueryParams)}`
    : ''

  return (
    <Link
      className={`block focus-visible:outline-none ${className}`}
      to={ROUTE_PATHS.ORDER_DETAIL(orderId) + orderDetailLinkQueryParams}
      tabIndex={tabIndex}
    >
      {children}
    </Link>
  )
}
