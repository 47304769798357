import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { PlusCircleIcon } from '@heroicons/react/20/solid'

import { ROUTE_PATHS } from '../../../constants'

export function CreateOrderButton({ className = '' }: { className?: string }) {
  return (
    <Link
      to={ROUTE_PATHS.CREATE_ORDER}
      className={twMerge('btn btn-sm border-none bg-white text-purple-950', className)}
    >
      <PlusCircleIcon className="text-inherit h-7 w-7" />
      <span>Create order</span>
    </Link>
  )
}
