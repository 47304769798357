// @flow
import { apparelSizes } from '../constants'
import { FacetValueInterface, SizeAndScoreInterface } from '../interfaces'

export interface SizeFacet {
  value: string
  count: number
  selected: boolean
  enabled: boolean
}

type SizeElement = SizeFacet | string

export function sortApparelBySize(
  prev: SizeElement | FacetValueInterface,
  next: SizeElement | FacetValueInterface
): number {
  const firstSizeAndScore = getSizeAndScore(prev)
  const secondSizeAndScore = getSizeAndScore(next)

  if (firstSizeAndScore && secondSizeAndScore) {
    return firstSizeAndScore.score - secondSizeAndScore.score
  }
  return 0
}

function getSizeAndScore(element: SizeElement | FacetValueInterface): SizeAndScoreInterface | undefined {
  if (typeof element !== 'string' && element.value && typeof element.value === 'string') {
    return findSizeAndScore(element.value.toLowerCase())
  } else if (typeof element === 'string') {
    return findSizeAndScore(element.toLowerCase())
  }
  return undefined
}

function findSizeAndScore(sizeToCheck: string): SizeAndScoreInterface | undefined {
  return apparelSizes.find((size) => size.value === sizeToCheck)
}
