import { ATTRIBUTES } from '../constants'
import { formatPrice } from './formatPrice'
import { reorderAttributes } from './productAttributes.helper'
import { isNoImageProduct } from '../../helpers/isNoImageProduct'
import { CatalogueItemInterface, DictionaryInterface, ProductInterface } from '../interfaces'

export function mapAzureSearchResultsToProduct(searchResults: CatalogueItemInterface[]): ProductInterface[] {
  return searchResults.map((catalogueItem) => ({
    attributes: mapAttributes(catalogueItem.attributes, { category: catalogueItem.category }),
    basePriceFrom: catalogueItem.basePriceFrom,
    category: catalogueItem.category,
    currency: catalogueItem.currency,
    description: catalogueItem.description,
    formattedPrice: formatPrice(catalogueItem.sku, catalogueItem.currency, catalogueItem.basePriceFrom),
    name: catalogueItem.description,
    noImageProduct: isNoImageProduct(catalogueItem.productType),
    optimumDpi: catalogueItem.optimumDpi,
    price: catalogueItem.price,
    productHeightMm: catalogueItem.productHeightMm,
    productionCountries: catalogueItem.productionCountries,
    productType: catalogueItem.productType,
    productWidthMm: catalogueItem.productWidthMm,
    shortcode: catalogueItem.shortcode,
    sku: catalogueItem.sku.toUpperCase()
  }))
}

function mapAttributes(
  searchResultItem: DictionaryInterface<string[]>,
  { category }: { category: string }
): DictionaryInterface<string[]> {
  const attributes = Object.entries(searchResultItem).reduce(
    (
      attributeAcc: DictionaryInterface<string[]>,
      [searchPropName, searchPropValue]: [string, string[]]
    ): DictionaryInterface<string[]> => {
      if (Array.isArray(searchPropValue) && isAttribute(searchPropName, searchPropValue)) {
        attributeAcc[searchPropName] = reorderAttributes(searchPropName, searchPropValue.filter(Boolean), category)
      }

      return attributeAcc
    },
    {}
  )
  return attributes
}

function isAttribute(searchPropName: string, searchPropValue: string[]): boolean {
  return Boolean(ATTRIBUTES.includes(searchPropName) && searchPropValue.length && searchPropValue.some(Boolean))
}
