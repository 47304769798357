import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE } from '../../constants'
import Pricing from '../../components/Pricing/Pricing.component'

export default function PricingPage() {
  useEffect(() => {
    document.title = 'Pricing | Prodigi'
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <Pricing />
    </main>
  )
}
