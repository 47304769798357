import { memo, useEffect, useLayoutEffect, useRef } from 'react'

import { ImageUrlsInterface } from './MultiAssetImageEditor.component'
import { CropRectangleInterface, useImageLibraryThumbnailGeneration } from '../../../hooks'
import { DimensionsInterface, ImageEditorTransformationsInterface, PointInterface } from '../interfaces'

type ImageEditorProps = {
  cropRectangle: CropRectangleInterface
  enableImageLibraryThumbnailRefresh?: boolean
  isPreviewOnly: boolean
  transformations: ImageEditorTransformationsInterface
  imageUrls: ImageUrlsInterface
  imageLibraryId?: string
  printDimensionsInPx: DimensionsInterface
  artworkDimensionsInPx: DimensionsInterface
  printDpi: number
  containerStyle?: Record<string, string>
  dataTest?: string
  onPositionChange?: (e: CustomEvent<PointInterface>) => void
  onRotationChange?: (e: CustomEvent<number>) => void
  onScaleChange?: (e: CustomEvent<number>) => void
  onLoadArtwork?: (e: CustomEvent<DimensionsInterface>) => void
  onLoadOverlay?: (e: CustomEvent<HTMLImageElement>) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onArtworkError?: (e?: CustomEvent<any> | string) => void
  onRemoveArtwork?: () => void
  onOverlayError?: () => void
  onUnderlayError?: () => void
}

export const IMAGE_LIBRARY_URL_EXPIRY_DATE_QUERY_PARAM = 'se'

function ImageLibraryPreviewLoader({
  enableImageLibraryThumbnailRefresh = false,
  imageUrls,
  imageLibraryId,
  onArtworkError,
  ...props
}: ImageEditorProps) {
  const mediumImageUrl = imageUrls.artwork
  const imageUrlExpiryParam = new URL(mediumImageUrl).searchParams.get(IMAGE_LIBRARY_URL_EXPIRY_DATE_QUERY_PARAM)
  const imageExpiryDate = imageUrlExpiryParam ? new Date(imageUrlExpiryParam) : null
  const currentDate = new Date()
  const hasImageUrlExpired = imageExpiryDate ? currentDate > imageExpiryDate : false

  const { imageLibraryImage, imageLibraryImageFetchError, isLoadingImageLibraryImage } =
    useImageLibraryThumbnailGeneration(
      enableImageLibraryThumbnailRefresh && hasImageUrlExpired ? imageLibraryId : undefined,
      {
        touch: false,
        config: { revalidateOnFocus: false }
      }
    )

  useEffect(() => {
    if (imageLibraryImageFetchError) {
      onArtworkError?.(`${imageLibraryImageFetchError.status ?? '0'}-${imageLibraryId ?? '0'}`)
    }
  }, [imageLibraryId, imageLibraryImageFetchError, onArtworkError])

  if (isLoadingImageLibraryImage) {
    return null
  }

  if (imageLibraryImageFetchError) {
    return null
  }

  return (
    <ImageEditorPreviewWrapper
      imageUrls={{ ...imageUrls, artwork: imageLibraryImage?.thumbnail_urls?.large ?? imageUrls.artwork }}
      onArtworkError={onArtworkError}
      {...props}
    />
  )
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'image-editor-preview-controller': any
    }
  }
}

function ImageEditorPreviewWrapper({
  cropRectangle,
  isPreviewOnly = false,
  transformations,
  imageUrls,
  onPositionChange,
  printDimensionsInPx,
  artworkDimensionsInPx,
  printDpi,
  onLoadArtwork,
  onArtworkError,
  onScaleChange,
  onRemoveArtwork,
  onLoadOverlay,
  onRotationChange,
  containerStyle,
  onOverlayError,
  onUnderlayError,
  dataTest
}: ImageEditorProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageEditorRef = useRef<any>(null)

  useLayoutEffect(() => {
    if (!imageEditorRef.current) {
      return
    }

    const imageEditor = imageEditorRef.current

    imageEditor.addEventListener('positionChange', onPositionChange)
    imageEditor.addEventListener('rotationChange', onRotationChange)
    imageEditor.addEventListener('scaleChange', onScaleChange)
    imageEditor.addEventListener('loadArtwork', onLoadArtwork)
    imageEditor.addEventListener('loadOverlay', onLoadOverlay)
    imageEditor.addEventListener('artworkError', onArtworkError)
    imageEditor.addEventListener('overlayError', onOverlayError)
    imageEditor.addEventListener('underlayError', onUnderlayError)
    imageEditor.addEventListener('removeArtwork', onRemoveArtwork)

    imageEditor.cropRectangle = cropRectangle
    imageEditor.transformations = transformations
    imageEditor.isPreviewOnly = isPreviewOnly
    imageEditor.imageUrls = imageUrls
    imageEditor.inlineStyles = containerStyle || {}
    imageEditor.printDpi = printDpi
    imageEditor.printDimensionsInPx = printDimensionsInPx
    imageEditor.artworkDimensionsInPx = artworkDimensionsInPx
    imageEditor.showRemoveArtworkButton = Boolean(onRemoveArtwork)

    return () => {
      imageEditor.removeEventListener('positionChange', onPositionChange)
      imageEditor.removeEventListener('rotationChange', onRotationChange)
      imageEditor.removeEventListener('scaleChange', onScaleChange)
      imageEditor.removeEventListener('loadArtwork', onLoadArtwork)
      imageEditor.removeEventListener('loadOverlay', onLoadOverlay)
      imageEditor.removeEventListener('artworkError', onArtworkError)
      imageEditor.removeEventListener('overlayError', onOverlayError)
      imageEditor.removeEventListener('underlayError', onUnderlayError)
      imageEditor.removeEventListener('removeArtwork', onRemoveArtwork)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerStyle,
    cropRectangle,
    transformations,
    isPreviewOnly,
    imageUrls,
    printDpi,
    onScaleChange,
    onPositionChange,
    onRemoveArtwork,
    onRotationChange,
    artworkDimensionsInPx,
    onLoadArtwork,
    onLoadOverlay,
    printDimensionsInPx
  ])

  return <image-editor-preview-controller ref={imageEditorRef} data-test={dataTest ?? 'image-editor-preview'} />
}

const ImageEditorPreview = memo(ImageLibraryPreviewLoader)

export default ImageEditorPreview
