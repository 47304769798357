// @flow
import React, { PureComponent } from 'react'
import Address from '../../../../components/Address'
import LinkButton from '../../../../components/LinkButton'
import styles from './CsvOrderShippingDetails.module.css'
import CsvAddressModal from '../CsvAddressModal'
import SelectField from '../../../../components/inputs/SelectField'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'
import type { Quote, CustomerAddress, Event } from '../../../../types'
import type { Element } from 'react'
import { toTitleCase } from '../../../../helpers/string'
import Option from '../../../../helpers/Option'
import Heading from '../Heading'

type Props = {|
  orderId: string,
  address: CustomerAddress,
  canBeEdited: boolean,
  isAddressModalOpen: boolean,
  selectedShippingMethodName: string,
  isSelectedShippingMethodValid: boolean,
  isShipmentSelectorEnabled: boolean,
  preferredButUnavailableShippingMethod: Option<string>,
  isLoading: boolean,
  quotes: Quote[],
  toggleAddressModal: () => void,
  onShippingMethodChange: (name: Event) => void,
  formatShippingMethodFromQuote: (quote: Quote) => string
|}

export default class CsvOrderShippingDetailsView extends PureComponent<Props> {
  render (): React$Node {
    return (
      <section className={styles.container}>
        <Heading>Shipping address</Heading>
        <Address address={this.props.address} isNormalWeight={true}/>
        {this.props.canBeEdited && <LinkButton className={styles.editAddressLink} onClick={this.props.toggleAddressModal} dataTest="edit-address-button">Edit address</LinkButton>}

        <div className={styles.userActions}>
          <Heading>Shipping method</Heading>

          {this.props.isLoading
            ? <LoadingEllipsis className={styles.loadingEllipsis} />
            : (
              <SelectField
                disabled={!this.props.isShipmentSelectorEnabled}
                className={styles.shippingMethod}
                onChange={this.props.onShippingMethodChange}
                value={this.props.selectedShippingMethodName}>

                {this.props.preferredButUnavailableShippingMethod
                  .whenSome(unavailableMethod =>
                    <option disabled value={unavailableMethod}>
                      {toTitleCase(unavailableMethod)} (not available)
                    </option>
                  )
                  .whenNone(() => !this.props.isSelectedShippingMethodValid
                    ? <option disabled value=''>Not available</option>
                    : null
                  )}

                {this.props.quotes.map(option =>
                  toShippingMethodOptionV4(this.props.formatShippingMethodFromQuote, option)
                )}
              </SelectField>
            )}
        </div>

        {this.props.isAddressModalOpen &&
          <CsvAddressModal orderId={this.props.orderId} address={this.props.address} closeModal={this.props.toggleAddressModal} />}
      </section>
    )
  }
}

function toShippingMethodOptionV4 (formatLabel: (option: Quote) => string, quote: Quote): Element<*> {
  return (
    <option key={quote.shipmentMethod} value={quote.shipmentMethod.toUpperCase()}>
      {formatLabel(quote)}
    </option>
  )
}
