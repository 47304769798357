import { FetchError, fetcher } from '.'
import { FetchErrorInterface } from '../interfaces'
import { ImageLibraryImageInterface } from '../hooks'

export async function requestImageLibraryThumbnailGeneration(imageLibraryId: string) {
  return fetcher<ImageLibraryImageInterface>(
    `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryId}/thumbnail?touch=true`
  ).catch((error) => {
    const errorResponse = error as FetchErrorInterface<{ detail?: string }>
    throw new FetchError({
      message: errorResponse?.message,
      responseBodyJson: errorResponse.responseBodyJson
        ? {
            ...errorResponse.responseBodyJson,
            imageLibraryId
          }
        : { imageLibraryId },
      name: 'FETCH_ERROR',
      status: error?.status
    })
  })
}
