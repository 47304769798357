// @flow
import type { DispatchFunc, Dictionary, GetStateFunc, FormattedOrderItem, Thunk, ThunkAsync } from '../../types'
import { values } from '../../helpers/dictionary'
import { fetchTemplate, buildTemplateId } from '../images'
import { getCsvItemDetailsBySku } from '../../selectors/csvUpload'

export function getCsvTemplates (orderItems: Dictionary<FormattedOrderItem>): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const uniqueSkusAndAttributes = values(orderItems).reduce(
      (skuAndAttributeAcc, orderItem) => {
        const templateId = buildTemplateId(orderItem.sku, orderItem.selectedAttributes)

        if (orderItem.sku) {
          skuAndAttributeAcc[templateId] = [orderItem.sku, orderItem.selectedAttributes]
        }

        return skuAndAttributeAcc
      }, {}
    )

    const fetchTemplatePromises = values(uniqueSkusAndAttributes).map(
      ([sku, attributes]) => dispatch(fetchCsvItemTemplates(sku, attributes))
    )

    return Promise.all(fetchTemplatePromises)
  }
}

export function fetchCsvItemTemplates (sku: string, attributes: Dictionary<string>): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const productDetails = getCsvItemDetailsBySku(getState(), sku)

    if (productDetails) {
      await dispatch(fetchTemplate(sku, attributes))
    }
  }
}
