import { fetcher } from '../../../helpers'
import { SalesChannelPlatformEnum } from '../../../enums'
import { DictionaryInterface, SalesChannelInterface } from '../../../interfaces'

interface CreationFailureInterface {
  code: string
  providedValue: string
}

export interface NewRutterSalesChannelErrorInterface {
  outcome: string
  failures: DictionaryInterface<CreationFailureInterface[]>
}

export function postNewRutterSalesChannel({
  merchantId,
  platform,
  publicToken
}: {
  merchantId: number
  platform?: SalesChannelPlatformEnum
  publicToken: string
}) {
  return fetcher<{ data: SalesChannelInterface }>(
    `${process.env.REACT_APP_PRODIGI_MERCHANT_SERVICE}/merchants/${merchantId}/saleschannels`,
    {
      body: JSON.stringify({
        identifier: publicToken,
        platform,
        description: platform ? `My ${platform} Store` : 'My store'
      }),
      method: 'POST'
    }
  )
}
