// @flow
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../../types'
import { getFacets } from './getFacets'

export const SELECT_PRODUCT_TYPE = 'SELECT_PRODUCT_TYPE'

export function selectProductType (productType: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    await dispatch({
      type: SELECT_PRODUCT_TYPE,
      productType })
    await dispatch(getFacets())
  }
}
