// @flow
import { fetchUserDetails, RECEIVE_USER_DETAILS } from './fetchUserDetails'
import { setupSegment } from '../../scripts/segment'
import { getIsUserInitialised } from '../../selectors/user'
import type { DispatchFunc, GetStateFunc, Thunk, ThunkAsync, User } from '../../types'
import type { SetUpUserSuccess, SetUpUserError, SetUpUserStart, SetUpUser } from '../types'
import { createKiteAccount } from './createKiteAccount'
import { splitFullNameIntoFirstAndLastName } from '../../helpers/splitFullNameIntoFirstAndLastName'

export const SET_UP_USER_START = 'SET_UP_USER_START'
export const SET_UP_USER_SUCCESS = 'SET_UP_USER_SUCCESS'
export const SET_UP_USER_ERROR = 'SET_UP_USER_ERROR'

export function setUpUser(): ThunkAsync<SetUpUser> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const isUserInitialised = getIsUserInitialised(getState())
    if (isUserInitialised) {
      return dispatch(setUpUserSuccess())
    }

    dispatch(setUpUserStart())

    const userDetailsAction = await dispatch(fetchUserDetails())
    if (userDetailsAction.type === RECEIVE_USER_DETAILS) {
      return dispatch(processUserSetup(userDetailsAction.payload.data))
    }

    return dispatch(setUpUserError())
  }
}

function processUserSetup(userDetails: User): ThunkAsync<SetUpUserSuccess> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    if (userDetails.hasKiteAccount === false) {
      dispatch(createKiteAccount())
    }
    if (!window.Cypress) {
      dispatch(setUpThirdPartyTools(userDetails))
    }

    return dispatch(setUpUserSuccess())
  }
}

function setUpThirdPartyTools(userDetails: User): Thunk<void> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const { firstName, lastName } = splitFullNameIntoFirstAndLastName(userDetails.fullName)

    setupSegment({
      firstName,
      lastName,
      merchantUniqueId: userDetails.merchantUniqueId,
      email: userDetails.email,
      company: userDetails.company.name
    })
  }
}

function setUpUserStart(): SetUpUserStart {
  return {
    type: SET_UP_USER_START
  }
}

function setUpUserSuccess(): SetUpUserSuccess {
  return {
    type: SET_UP_USER_SUCCESS
  }
}

function setUpUserError(): SetUpUserError {
  return {
    type: SET_UP_USER_ERROR
  }
}
