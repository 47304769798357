// @flow

import type { Style } from '../../types'

import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import styles from './DropDown.module.css'
import cx from 'classnames'

type Props = {|
  onClick: Function,
  text: string,
  style?: Style,
  icon: string,
  disabled?: boolean,
  hidden?: boolean,
  dataTest?: string
|}

type State = {|
  id: string
|}

function getId (props: Props): string {
  return `${(props.text || props.icon || `no_name`).toLowerCase().replace(/\s+/g, '_')}_dropdown_btn_item`
}

class DropDownButtonItem extends Component<Props, State> {
  state: State = { id: getId(this.props) }

  onClick: (() => void) = () => {
    if (!this.props.disabled) {
      this.props.onClick()
    }
  }

  render (): React$Node {
    const { hidden } = this.props
    const isEnabled = !this.props.disabled

    if (!hidden) {
      return (
        <div
          id={this.state.id}
          className={cx(styles.dropDownItem, this.props.style,
            {[styles.isEnabled]: isEnabled, [styles.notEnabled]: !isEnabled})}
          onClick={this.onClick}
          data-test={this.props.dataTest}
        >
          {this.props.icon ? <FontAwesome name={this.props.icon} className={styles.icon} /> : ''}
          {this.props.text}
        </div>
      )
    }
    return null
  }
}

export default DropDownButtonItem
