export const ATTRIBUTES = [
  'finish',
  'gender',
  'style',
  'frameColour',
  'wrap',
  'frame',
  'glaze',
  'edge',
  'mountColour',
  'mount',
  'substrateWeight',
  'paperType',
  'brand',
  'size'
]

export const RETRIEVABLE_PROPERTIES: Array<string> = [
  ...ATTRIBUTES,
  'searchWeighting',
  'sizeUnits',
  'fullProductVerticalDimensions',
  'fullProductHorizontalDimensions',
  'printedAreaVerticalDimensions',
  'printedAreaHorizontalDimensions',
  'shortcode',
  'priceCurrency',
  'basePriceFrom',
  'lastUpdated',
  'size',
  'optimumDpi',
  'productAspectRatio',
  'productHeightMm',
  'productWidthMm',
  'maxProductDimensionsMm',
  'productType',
  'description',
  'category',
  'productionCountries',
  'sku',
  'sla'
]
