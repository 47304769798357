import { SortOrdersByOption } from '../enums'

export function formatSortOrdersByName(option: SortOrdersByOption) {
  switch (option) {
    case SortOrdersByOption.NEWEST:
      return 'Newest first'
    case SortOrdersByOption.OLDEST:
      return 'Oldest first'
    default:
      return option
  }
}
