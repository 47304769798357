import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { useMerchantService } from '../../../hooks'
import ImageEditorModal from '../../ImageEditorModal'
import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { saveVariantData } from '../helpers/saveVariantData.helper'
import { VariantDataInterface } from '../SalesChannelProduct.component'
import { SALES_CHANANEL_PRODUCT_TOAST_IDS } from '../constants/toastIds.const'
import { useSalesChannelProduct } from '../../../hooks/useSalesChannelProduct.hook'
import { FetchErrorInterface, OMSErrorResponseInterface, StatusType } from '../../../interfaces'

export function VariantImageEditorModal({
  countryCode,
  isImageEditorModalOpen,
  validSelectedPrintArea,
  variantData,
  onImageEditorModalOpenChange,
  onSaveImageSuccess
}: {
  countryCode: string
  isImageEditorModalOpen: boolean
  validSelectedPrintArea: string
  variantData: VariantDataInterface
  onImageEditorModalOpenChange: (isOpen: boolean) => void
  onSaveImageSuccess: () => void
}) {
  const [saveImageEditStatus, setSaveImageEditStatus] = useState<StatusType>('idle')
  const { merchantDetails } = useMerchantService()
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { mutateSalesChannelProduct } = useSalesChannelProduct({ productId, salesChannelId })

  useEffect(() => {
    if (!isImageEditorModalOpen) {
      setSaveImageEditStatus('idle')
      toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)
    }
  }, [isImageEditorModalOpen])

  async function handleSaveImageEdits(printAreasArtworkData: PrintAreasArtworkDataType, newSelectedPrintArea: string) {
    setSaveImageEditStatus('loading')
    toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)

    const printAreasWithProvidedArtwork = Object.keys(printAreasArtworkData ?? {}).filter((printArea) =>
      Boolean(printAreasArtworkData?.[printArea])
    )
    const requiredPrintAreas = Object.keys(variantData.assetRequirementData ?? {}).filter((printArea) =>
      Boolean(variantData.assetRequirementData?.[printArea].required)
    )
    const hasAllRequiredImages = requiredPrintAreas.length === printAreasWithProvidedArtwork.length

    try {
      const newVariantData = {
        ...variantData,
        artworkData: printAreasArtworkData,
        selectedPrintArea: newSelectedPrintArea
      }
      await saveVariantData({
        merchantId: merchantDetails?.id ?? null,
        newVariantData,
        productId,
        salesChannelId
      })
      await mutateSalesChannelProduct()
      setSaveImageEditStatus('success')
      if (hasAllRequiredImages) {
        window.analytics.track('Sales channels - auto-fulfilment configured')
      }
      onSaveImageSuccess()
    } catch (error) {
      if (hasAllRequiredImages) {
        window.analytics.track('Sales channels - failed to set up auto-fulfilment')
      }
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${variantData.id}-${errorResponse.responseBodyJson?.traceParent ?? '0'}-${
        errorResponse.status ?? '0'
      }`
      createErrorToast({ id: SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR, errorCode, heading: 'Failed to update' })
      setSaveImageEditStatus('error')
    }
  }

  return (
    <>
      <ImageEditorModal
        attributes={variantData?.selectedProductData?.attributes ?? undefined}
        countryCode={countryCode}
        enableImageLibraryThumbnailRefresh={true}
        imageLibraryConfig={{ forceSaveToLibrary: true }}
        isSaving={saveImageEditStatus === 'loading'}
        open={isImageEditorModalOpen}
        printAreasArtworkData={variantData?.artworkData ?? undefined}
        selectedPrintArea={validSelectedPrintArea ?? undefined}
        sku={variantData?.selectedProductData?.sku as string}
        setOpen={onImageEditorModalOpenChange}
        onOpenImageLibrary={() => window.analytics.track('Image library opened: Sales channel product config')}
        onCancel={() => onImageEditorModalOpenChange(false)}
        onSave={handleSaveImageEdits}
      />

      {saveImageEditStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
