import { useCallback } from 'react'

import { fetcher } from '../helpers'
import { useUserV1 } from './useUserV1.hook'
import { selectEnvironmentName } from '../selectors/appSettings'
import { LIVE, SANDBOX, TEST } from '../constants/environments.const'

export function useLogToInsightOps() {
  const user = useUserV1()
  const environmentName = selectEnvironmentName()

  const logToInsightOps = useCallback(
    (
      msg: string,
      {
        props = {},
        level = 'info'
      }: {
        props?: Record<string, unknown>
        level?: 'info' | 'error' | 'warning'
      } = {}
    ) => {
      const body = { [level]: { msg: msg, userId: user?.id, merchantId: user?.merchantUniqueId, ...props } }

      const shouldLogInTestEnvironment = environmentName === TEST && !window.location.origin.includes('localhost')
      if (shouldLogInTestEnvironment) {
        submit({ TEST: body })
        return
      }
      if (environmentName === LIVE) {
        submit({ LIVE: body })
      } else if (environmentName === SANDBOX) {
        submit({ SANDBOX: body })
      }
    },
    [environmentName, user?.id, user?.merchantUniqueId]
  )
  return { logToInsightOps }
}

function submit(body: unknown) {
  const token = process.env.REACT_APP_LOGENTRIES_TOKEN
  const endpoint = `https://webhook.logentries.com/noformat/logs/${token || ''}`

  fetcher(endpoint, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  })
}
