import { ArtworkTransformationsInterface } from '../interfaces'

export function addOrRemoveArtworkTransformations(
  prevTransformations: Record<string, ArtworkTransformationsInterface | undefined | null>,
  newTransformations: Record<string, ArtworkTransformationsInterface | undefined | null>
): Record<string, ArtworkTransformationsInterface | undefined | null> {
  const prevPrintAreasWithTransformations = selectPrintAreaNamesOfNonNullTransformations(prevTransformations)
  const currentPrintAreasWithTransformations = selectPrintAreaNamesOfNonNullTransformations(newTransformations)

  const addedPrintAreas = currentPrintAreasWithTransformations.filter((printAreaName) => {
    return !prevPrintAreasWithTransformations.includes(printAreaName)
  })

  const removedPrintAreas = prevPrintAreasWithTransformations.filter((printAreaName) => {
    return Boolean(
      currentPrintAreasWithTransformations.length === 0 || !currentPrintAreasWithTransformations.includes(printAreaName)
    )
  })

  if (addedPrintAreas.length === 0 && removedPrintAreas.length === 0) {
    return prevTransformations
  }

  const prevArtworkTransformationsWithoutRemovedPrintAreas = removeTransformations(
    prevTransformations,
    removedPrintAreas
  )

  const artworkTransformationsToAdd = selectTransformationsToAdd(newTransformations, addedPrintAreas)

  return {
    ...prevArtworkTransformationsWithoutRemovedPrintAreas,
    ...artworkTransformationsToAdd
  }
}

function selectPrintAreaNamesOfNonNullTransformations(
  transformations: Record<string, ArtworkTransformationsInterface | undefined | null>
): string[] {
  return Object.entries(transformations).reduce((printAreaNameAcc: string[], [printAreaName, transformations]) => {
    return transformations ? [...printAreaNameAcc, printAreaName] : printAreaNameAcc
  }, [])
}

function removeTransformations(
  transformations: Record<string, ArtworkTransformationsInterface | undefined | null>,
  printAreasToRemove: string[]
): Record<string, ArtworkTransformationsInterface | undefined | null> {
  return Object.entries(transformations).reduce(
    (
      transformationsAcc: Record<string, ArtworkTransformationsInterface | null | undefined>,
      [printAreaName, transformations]
    ) => {
      if (!printAreasToRemove.includes(printAreaName)) {
        transformationsAcc[printAreaName] = transformations
      } else {
        transformationsAcc[printAreaName] = null
      }

      return transformationsAcc
    },
    {}
  )
}

function selectTransformationsToAdd(
  allTransformations: Record<string, ArtworkTransformationsInterface | undefined | null>,
  printAreasToAdd: string[]
): Record<string, ArtworkTransformationsInterface | undefined | null> {
  return Object.entries(allTransformations).reduce(
    (
      transformationsAcc: Record<string, ArtworkTransformationsInterface | undefined | null>,
      [printAreaName, transformations]
    ) => {
      if (printAreasToAdd.includes(printAreaName)) {
        transformationsAcc[printAreaName] = transformations
      }

      return transformationsAcc
    },
    {}
  )
}
