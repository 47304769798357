// @flow
import React, { PureComponent } from 'react'
import LoadingDots from '../../../../components/LoadingDots'
import currencyFormat from '../../../../helpers/currencyFormat'
import styles from './SelectProduct.module.css'
import { ZERO_COST_SKUS } from '../../../../data/noImageProducts'

type Props = {|
  fetchingPrices: boolean,
  price?: number,
  currency: string,
  basePriceFrom: number,
  basePriceCurrency: string,
  sku: string
|}

const containsOnlyLetters = /[a-zA-Z]+/

export default class Price extends PureComponent<Props> {
  isZeroPricedItem: ((sku: string) => boolean) = (sku: string) => Object.values(ZERO_COST_SKUS).includes(sku)

  isPriceValid: ((price: ?number, currency: string) => ?(number | string | boolean)) = (price: ?number, currency: string) =>
    price && !isNaN(price) && currency && containsOnlyLetters.test(currency)

  render (): React$Node {
    const { price, currency, basePriceFrom, basePriceCurrency, fetchingPrices } = this.props

    if (fetchingPrices) {
      return <div className={styles.price}> <LoadingDots /> </div>
    }

    return (
      <div className={styles.price}>
        {this.isZeroPricedItem(this.props.sku)
          ? <strong>Free </strong>
          : (
            <div data-test='product-price'>
              {this.isPriceValid(price, currency)
                ? <strong>From {currencyFormat(price, currency)}</strong>
                : (
                  <div>
                    {this.isPriceValid(basePriceFrom, basePriceCurrency)
                      ? <strong>From {currencyFormat(basePriceFrom, basePriceCurrency)}</strong>
                      : <span>Price unavailable</span>
                    }
                  </div>
                )}
            </div>
          )}
      </div>
    )
  }
}
