// @flow

import { PRINT_AREA_NAME } from '../../../data'
import { APP_VERSION } from '../../../data/appVersion'
import type { MultiAssetBasketItem } from '../../../types'

export function updateToVersion12 (itemsToUpdate: MultiAssetBasketItem[]): MultiAssetBasketItem[] {
  return itemsToUpdate.map((item) => {
    try {
      return {
        ...item,
        appVersion: APP_VERSION.WHICH_INTRODUCED.MULTI_ASSET_SUPPORT,
        selectedPrintArea: item.selectedPrintArea ? item.selectedPrintArea : PRINT_AREA_NAME.DEFAULT,
        printAreas: item.printAreas
          ? item.printAreas
          : null
      }
    } catch (err) {
      console.error('err', err)
      return null
    }
  }).filter(Boolean)
}
