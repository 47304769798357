import { twMerge } from 'tailwind-merge'
import { IconCheckMark } from '../InlineMenu/components'

interface CheckboxPropsInterface {
  className?: string
  checked: boolean
  dataTest?: string
  description?: string
  disabled?: boolean
  id: string
  label?: string
  labelClassName?: string
  readOnly?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export default function Checkbox({
  className = '',
  checked,
  dataTest = 'checkbox-input',
  description,
  disabled = false,
  id,
  label,
  labelClassName,
  readOnly = false,
  onChange
}: CheckboxPropsInterface) {
  return (
    <div className={twMerge('relative flex items-start', className)}>
      <div className="flex items-center justify-center">
        <div className="flex items-center">
          <div
            className={`${
              disabled && 'pointer-events-none opacity-50'
            } group:duration-100 group:ease-in-out group flex h-8 w-8 flex-shrink-0 items-center justify-center border-2 focus-within:outline focus-within:outline-1 focus-within:outline-black  ${
              checked ? 'border-purple-600 bg-purple-600' : 'border-gray-400 bg-white'
            }`}
          >
            <input
              className="absolute z-10 h-8 w-8 cursor-pointer opacity-0 disabled:cursor-default"
              data-test={dataTest}
              disabled={disabled}
              checked={checked}
              id={id}
              readOnly={readOnly}
              type="checkbox"
              onChange={onChange}
            />

            <IconCheckMark className="h-5 w-5 text-white" isVisible={checked} strokeWidth={4} />
          </div>
        </div>
      </div>

      {label && (
        <label
          htmlFor={id}
          className={twMerge(
            `select-none pl-3 text-sm font-normal ${disabled ? 'cursor-default' : 'cursor-pointer'}`,
            labelClassName
          )}
        >
          {label}
          {description && <span className="block text-gray-600">{description}</span>}
        </label>
      )}
    </div>
  )
}
