// @flow
import type { SetRsaaStatus, ResetRsaaStatus } from '../types'
import type { SetRsaaStatusActionProps, AbortController } from '../../types'

export const SET_RSAA_STATUS = 'SET_RSAA_STATUS'
export const RESET_RSAA_STATUS = 'RESET_RSAA_STATUS'
export const SAVE_IN_FLIGHT_REQUEST_CONTROLLER = 'SAVE_IN_FLIGHT_REQUEST_CONTROLLER'
export const HARD_RESET_RSAA_STATUS = 'HARD_RESET_RSAA_STATUS'

export function setRsaaStatus (rsaaActionId: string, status: SetRsaaStatusActionProps): SetRsaaStatus {
  return {
    type: SET_RSAA_STATUS,
    actionId: rsaaActionId,
    status
  }
}

export function resetRsaaStatus (rsaaActionId: string): ResetRsaaStatus {
  return {
    type: RESET_RSAA_STATUS,
    actionId: rsaaActionId
  }
}

export function hardResetRsaaStatus (ref: string): {| ref: string, type: string |} {
  return {
    type: HARD_RESET_RSAA_STATUS,
    ref
  }
}

export function saveInFlightRequestController (requestType: string, abortController: AbortController): {| abortController: AbortController, requestType: string, type: string |} {
  return {
    type: SAVE_IN_FLIGHT_REQUEST_CONTROLLER,
    requestType,
    abortController
  }
}
