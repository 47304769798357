import { ReactNode } from 'react'
import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'

export function DownloadError({ message }: { message?: ReactNode }) {
  const errorBody = (
    <div className="text-center">
      <p className="break-words">{message ?? 'An unknown error occurred'}</p>
      <p>
        Please refresh to try again and <SupportLink>contact us</SupportLink> if the issue persists.
      </p>
    </div>
  )

  return (
    <div className="grid min-h-screen place-content-center">
      <NotFound heading="Download failed" body={errorBody} />
    </div>
  )
}
