// @flow
import { removeDuplicates } from '../../helpers/array'
import { searchProductsBySkuList, fetchPrices } from '../catalogue'
import { selectBasketItems, selectDeliveryCountry } from '../../selectors/manualOrderForm'
import { selectCatalogueItems } from '../../selectors/catalogue'

import type {
  DispatchFunc,
  GetStateFunc,
  CatalogueItem,
  MultiAssetBasketItem,
  Dictionary,
  ThunkAsync
} from '../../types'

export const BASKET_ITEM_LOGGER_ID = 'BASKET_ITEMS'

export function fetchCatalogueDataForAllBasketItems (): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItems = selectBasketItems(getState())
    const allSkus = getUniqueSkus(basketItems)
    const catalogueItems = selectCatalogueItems(getState())
    const skusToSearchFor = filterOutSkusThatHaveCatalogueData(allSkus, catalogueItems)
    const deliveryCountry = selectDeliveryCountry(getState())

    await dispatch(searchProductsBySkuList(skusToSearchFor, deliveryCountry, BASKET_ITEM_LOGGER_ID))
    dispatch(fetchPrices(skusToSearchFor, BASKET_ITEM_LOGGER_ID))
  }
}

function getUniqueSkus (basketItems: MultiAssetBasketItem[]): string[] {
  const allSkus = basketItems.map(item => item.sku)
  return removeDuplicates(allSkus)
}

function filterOutSkusThatHaveCatalogueData (
  skus: string[],
  catalogueItems: Dictionary<CatalogueItem>
): string[] {
  return skus.filter(sku => !catalogueItems[sku])
}
