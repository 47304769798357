import { Link } from 'react-router-dom'
import { ROUTE_PATHS, EXTERNAL_URLS } from '../../../constants'

export function SalesChannelsHeader() {
  return (
    <header>
      <h1 className="mt-0">Sales channels</h1>
      <p className="mt-8">
        Connect with other platforms and have orders flow automatically into Prodigi for fulfilment.
      </p>
      <p className="mt-4">
        You can always{' '}
        <Link className="text-purple-500 underline-offset-1 hover:underline" to={ROUTE_PATHS.CREATE_ORDER}>
          place orders online
        </Link>{' '}
        or via our{' '}
        <a
          className="text-purple-500 underline-offset-1 hover:underline"
          href={EXTERNAL_URLS.PRODIGI.API_DOCS.URL}
          rel="noopener noreferrer"
          target="_blank"
          title="fully featured print API"
        >
          fully featured print API
        </a>
        .
      </p>
    </header>
  )
}
