// @flow
import type { Thunk, DispatchFunc, GetStateFunc, FormattedOrderItem } from '../../types'
import type { InitPrintAreasForCsvItem } from '../types'
import type { Dictionary } from '../../types/dictionary'
import { PRINT_AREA_NAME } from '../../data/printAreaName'
import { values } from '../../helpers/dictionary'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue/catalogue'

export const INIT_PRINT_AREAS_FOR_CSV_ITEM = 'INIT_PRINT_AREAS_FOR_CSV_ITEM'

export function initPrintAreasForCsvItem(
  orderItems: Dictionary<FormattedOrderItem>,
  orderItemsForImageUpload: Dictionary<FormattedOrderItem>
): Thunk<?InitPrintAreasForCsvItem> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    values(orderItems).forEach((orderItem) => {
      const productDetails = getV4ProductDetailsBySku(getState(), orderItem.sku)

      if (!productDetails) {
        console.warn(`Product details not found, ${orderItem.id} - ${orderItem.sku}`)
        return
      }

      const printAreas = productDetails.printAreas

      const printAreaNames = Object.keys(printAreas)
      const firstAvailablePrintAreaName: string = printAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
        ? PRINT_AREA_NAME.DEFAULT
        : printAreaNames[0]

      const printAreaImageUrls = printAreaNames.reduce(
        (printAreaImageUrlsAcc: Dictionary<string | null>, printAreaName) => {
          if (printAreaName === firstAvailablePrintAreaName && orderItemsForImageUpload[orderItem.id]) {
            printAreaImageUrlsAcc = {
              ...printAreaImageUrlsAcc,
              [firstAvailablePrintAreaName]: orderItem.unknownPrintAreaImageUrl
            }
          } else {
            printAreaImageUrlsAcc = {
              ...printAreaImageUrlsAcc,
              [printAreaName]: null
            }
          }

          return printAreaImageUrlsAcc
        },
        {}
      )

      const action: InitPrintAreasForCsvItem = {
        type: INIT_PRINT_AREAS_FOR_CSV_ITEM,
        itemId: orderItem.id,
        selectedPrintArea: firstAvailablePrintAreaName,
        printAreaImageUrls
      }

      return dispatch(action)
    })
  }
}
