// @flow

import React, { Component } from 'react'
import { getPrintAreaNameToDisplay } from '../../helpers/getPrintAreaNameToDisplay'
import Svg from '../svg'
import styles from './PrintAreaSwitcher.module.css'

type Props = {|
  selectedPrintAreaIndex: number,
  selectedPrintArea: string,
  selectedIndexToDisplay: number,
  itemCategory: string,
  numberOfPrintAreas: number,
  handlePrintAreaChange: (printAreaIndexToSelect: number) => void
|}

export default class PrintAreaSwitcherView extends Component<Props> {
  render (): React$Node {
    const { selectedPrintAreaIndex } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.descriptionContainer}>
          <button className={styles.unstyledButton} onClick={() => this.props.handlePrintAreaChange(selectedPrintAreaIndex - 1)}>
            <Svg.ArrowLeft className={styles.arrow}/>
          </button>

          <span className={styles.printAreaName}>
            {getPrintAreaNameToDisplay(this.props.selectedPrintArea, this.props.itemCategory)}
          </span>

          <button className={styles.unstyledButton} onClick={() => this.props.handlePrintAreaChange(selectedPrintAreaIndex + 1)}>
            <Svg.ArrowRight className={styles.arrow}/>
          </button>
        </div>

        <span className={styles.printAreaIndex}>{this.props.selectedIndexToDisplay} of {this.props.numberOfPrintAreas}</span>
      </div>
    )
  }
}
