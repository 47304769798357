import { FetchErrorInterface } from '../../../interfaces'

export function mapErrorResponse(errorResponse?: FetchErrorInterface<{ statusTxt?: string; message?: string }>): {
  expired: boolean
} {
  const errorStatusText = errorResponse?.responseBodyJson?.statusTxt
  if (
    errorResponse?.responseBodyJson?.message?.includes('The request is invalid') ||
    errorStatusText?.includes('Invalid token') ||
    errorStatusText?.includes('The Email field is required') ||
    errorStatusText?.includes('The ResetToken field is required')
  ) {
    return { expired: true }
  }
  return { expired: false }
}
