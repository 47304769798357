import { TemplateServiceSuccessResponseInterface } from '../../../hooks/useTemplateService.hook'
import { generatePigUrlsForArtworkInTemplate } from './generatePigUrlsForArtworkInTemplate.helper'
import { ArtworkTransformationsInterface, ImageEditorPrintAreaArtworkInterface } from '../interfaces'

/**
 * Returns an object with key as print area name and preview and print image URLS as value
 */
export function buildCroppedImageUrls(
  printAreas: Record<string, Pick<ImageEditorPrintAreaArtworkInterface, 'artwork'>>,
  updatedArtworkTransformations: Record<string, ArtworkTransformationsInterface | null | undefined>,
  templates: TemplateServiceSuccessResponseInterface
) {
  return Object.entries(updatedArtworkTransformations).reduce(
    (
      pigUrlAcc: Record<string, { printImageUrl: string; previewImageUrl: string }>,
      [printAreaName, transformations]
    ) => {
      if (!transformations) {
        return pigUrlAcc
      }

      const artwork = printAreas[printAreaName].artwork
      const pigUrls = generatePigUrlsForArtworkInTemplate({ artwork, templates, transformations, printAreaName })

      if (!pigUrls) {
        return pigUrlAcc
      }

      const { printImageUrl, previewImageUrl } = pigUrls

      console.log(printAreaName, 'print image url:', printImageUrl)
      console.log(printAreaName, 'preview image url:', previewImageUrl)

      pigUrlAcc[printAreaName] = { printImageUrl, previewImageUrl }

      return pigUrlAcc
    },
    {}
  )
}
