import { useState } from 'react'
import toast from 'react-hot-toast'

import { createToast } from '../../../../../Toast'
import { StatusEnum } from '../../../../../../enums'
import Button from '../../../../../../components/Button'
import {
  SETTINGS_UPDATE_ERROR_TOAST_ID,
  SETTINGS_UPDATE_SUCCESS_TOAST_ID
} from '../../../../../../components/Settings/constants'
import FormItem from '../../../../../../components/FormItem'
import TextField from '../../../../../../components/TextField'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { FetchErrorInterface } from '../../../../../../interfaces'
import { useMerchantService, useUser } from '../../../../../../hooks'
import { CompanyCustomsInformationInterface, IOSS, OSS, VOEC } from './interfaces'
import { updateTaxIdentifiers } from '../../../../../../components/Settings/helpers'

export function CustomsInformationPanel({ className = '', onSuccess }: { className?: string; onSuccess: () => void }) {
  const { user } = useUser()
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()

  const companyCustomsInformationTypes = [IOSS, OSS, VOEC]

  const [customsDetailsUpdateStatus, setCustomsDetailsUpdateStatus] = useState(StatusEnum.Idle)
  const [customsDetailsFormData, setCustomsDetailsFormData] = useState<CompanyCustomsInformationInterface>({
    [IOSS]: {
      value: merchantDetails?.taxIdentifiers.find((taxIdentifier) => taxIdentifier.type === 'IOSS')?.value ?? ''
    },
    [OSS]: {
      value: merchantDetails?.taxIdentifiers.find((taxIdentifier) => taxIdentifier.type === 'OSS')?.value ?? ''
    },
    [VOEC]: {
      value: merchantDetails?.taxIdentifiers.find((taxIdentifier) => taxIdentifier.type === 'VOEC')?.value ?? ''
    }
  })

  async function handleCustomsDetailsUpdate() {
    if (!merchantDetails || !user) {
      createToast({
        content: `Please try again later`,
        footer: 'Code: NUE',
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      return
    }

    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setCustomsDetailsUpdateStatus(StatusEnum.Loading)

    try {
      // Empty string for countryCode as the countryCode isn't required for IOSS and OSS numbers but the API supports it for future-proofing
      const updateRequests = [
        updateTaxIdentifiers({
          merchantDetails,
          newTaxIdentifier: { countryCode: '', type: 'IOSS', value: customsDetailsFormData.ioss.value },
          user
        }),
        updateTaxIdentifiers({
          merchantDetails,
          newTaxIdentifier: { countryCode: '', type: 'OSS', value: customsDetailsFormData.oss.value },
          user
        }),
        updateTaxIdentifiers({
          merchantDetails,
          newTaxIdentifier: { countryCode: '', type: 'VOEC', value: customsDetailsFormData.voec.value },
          user
        })
      ].filter(Boolean)

      await Promise.all(updateRequests)
      await mutateMerchantDetails()

      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setCustomsDetailsUpdateStatus(StatusEnum.Success)
      onSuccess()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later (${errorResponse.status ?? errorResponse.message})`,
        duration: Infinity,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setCustomsDetailsUpdateStatus(StatusEnum.Error)
    }
  }

  return (
    <SettingsPanel
      className={className}
      title={<h2>Customs information</h2>}
      subtitle={
        <span className="mt-4 max-w-lg text-gray-600">
          Add your unique customs identification numbers to avoid unecessary additional tax charges for international
          deliveries.
        </span>
      }
    >
      <form
        className="max-w-xl"
        onSubmit={(event) => {
          event.preventDefault()
          handleCustomsDetailsUpdate()
        }}
      >
        {companyCustomsInformationTypes.map((customsType) => (
          <FormItem
            key={customsType}
            inputField={
              <TextField
                disabled={customsDetailsUpdateStatus === StatusEnum.Loading}
                maxLength={255}
                type="text"
                value={customsDetailsFormData?.[customsType]?.value}
                onChange={(event) => {
                  setCustomsDetailsFormData({
                    ...customsDetailsFormData,
                    [customsType]: {
                      ...customsDetailsFormData[customsType],
                      value: event.target.value
                    }
                  })
                }}
              />
            }
            labelText={customsType.toUpperCase()}
            validate={false}
          />
        ))}

        <Button
          disabled={customsDetailsUpdateStatus === StatusEnum.Loading}
          isLoading={customsDetailsUpdateStatus === StatusEnum.Loading}
          className="mt-8"
          type="submit"
          variant="primary"
        >
          Update
        </Button>
      </form>
    </SettingsPanel>
  )
}
