// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function PhotobookMakerIcon ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="30" cy="30" r="30" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.4 35.4H38.8V25.5H34.4V35.4ZM21.2 29.9H33.3V25.5H21.2V29.9ZM21.2 35.4H33.3V31H21.2V35.4ZM38.8 20H21.2C19.99 20 19.011 20.99 19.011 22.2L19 35.4C19 36.61 19.99 37.6 21.2 37.6H38.8C40.01 37.6 41 36.61 41 35.4V22.2C41 20.99 40.01 20 38.8 20Z" />
    </svg>
  )
}
