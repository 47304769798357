import SupportLink from '../../SupportLink'
import { useSplitToggle } from '../../../hooks'
import { VOTE_FOR_PLATFORMS } from '../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { RutterPlatformCard } from './RutterPlatformCard.component'
import { VoteForPlatformCard } from './VoteForPlatformCard.component'
import { RutterPlatformEnum, SalesChannelPlatformEnum } from '../../../enums'
import { RUTTER_SUPPORTED_PLATFORMS } from '../constants/rutterSupportedPlatforms.const'

export function ConnectNewSalesChannel({ className = '' }: { className?: string }) {
  // TODO: remove split, option logic and footer logic
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })
  const { splitIsOn: isPlatformVoteSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.SALES_CHANNEL_VOTE_FOR_PLATFORM })

  let options = RUTTER_SUPPORTED_PLATFORMS

  if (isShopifySplitOn) {
    options = [
      {
        name: RutterPlatformEnum.Shopify,
        description: 'Automatically import new orders from your Shopify store.',
        ctaButtonText: 'Connect your Shopify store',
        disclaimer: '',
        prodigiPlatformName: SalesChannelPlatformEnum.Shopify
      }
    ]
  }

  return (
    <section className={className}>
      <h2>Connect a new channel</h2>
      <p className="mt-6">
        {!isPlatformVoteSplitOn && 'We currently integrate with the following ecommerce platforms.'}
        Can&apos;t see your platform? <SupportLink>Let us know</SupportLink>
      </p>

      <ul className="mt-8 grid gap-y-6 lg:grid-cols-3 lg:gap-x-6">
        {options.map((platform) => (
          <RutterPlatformCard key={platform.name} platform={platform} />
        ))}
        {isPlatformVoteSplitOn &&
          VOTE_FOR_PLATFORMS.map((platform) => <VoteForPlatformCard key={platform.name} platform={platform} />)}
      </ul>
    </section>
  )
}
