// @flow

import { RSAA } from 'redux-api-middleware'
import { ABORT_IN_FLIGHT_REQUESTS, LOG_STATUS } from '../../data/rsaa'
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import type { V4OrderSearchResponse, V4OrderSearchErrorResponse, OrderFilterScope, RsaaActionShape, Dictionary } from '../../types'
import { ORDER_FILTER_SCOPES } from '../../data/orderFilters'
import { V4_ORDER_STAGE } from '../../data/orderStatus'
import { convertV4OrderIdToV3OrderId } from '../../helpers/orderId'

export const SEARCH_ORDERS_V4 = 'SEARCH_ORDERS_V4'
export const SEARCH_ORDERS_V4_SUCCESS = 'SEARCH_ORDERS_V4_SUCCESS'
export const SEARCH_ORDERS_V4_ERROR = 'SEARCH_ORDERS_V4_ERROR'

type SearchOrdersV4Rsaa = RsaaActionShape<V4OrderSearchErrorResponse | V4OrderSearchResponse, null, 'SEARCH_ORDERS_V4', 'SEARCH_ORDERS_V4_SUCCESS', 'SEARCH_ORDERS_V4_ERROR'>

export function searchOrdersV4 (
  query: string,
  scope: OrderFilterScope,
  skip: number,
  limit: number
): Dictionary<SearchOrdersV4Rsaa> {
  return {
    [RSAA]: {
      endpoint: buildEndpoint(query, scope, skip, limit),
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        { type: SEARCH_ORDERS_V4, meta: { enhancements: [ ABORT_IN_FLIGHT_REQUESTS, LOG_STATUS ] } },
        {
          type: SEARCH_ORDERS_V4_SUCCESS,
          payload: async (action, state, rawResponse) => {
            const v4OrderSearchResponse: V4OrderSearchResponse = await rawResponse.json()
            return {
              ...v4OrderSearchResponse,
              orders: v4OrderSearchResponse.orders.map(order => ({
                ...order,
                id: convertV4OrderIdToV3OrderId(order.id)
              }))
            }
          }
        },
        {
          type: SEARCH_ORDERS_V4_ERROR,
          meta: {
            query,
            scope,
            skip,
            limit
          }
        }
      ]}
  }
}

function buildEndpoint (
  query: string,
  scope: OrderFilterScope,
  skip: number,
  limit: number
) {
  const mappedStatus = mapV3OrderFilterScopeToV4(scope)
  const statusSearchParam = mappedStatus ? `&status=${mappedStatus}` : ''

  return selectProdigiApiEndpoint() + `/v4.0/orders?skip=${skip}&top=${limit}${statusSearchParam}`
}

function mapV3OrderFilterScopeToV4 (scope: OrderFilterScope): ?string {
  switch (scope) {
    case ORDER_FILTER_SCOPES.AWAITING_PAYMENT:
      return V4_ORDER_STAGE.AWAITING_PAYMENT
    case ORDER_FILTER_SCOPES.SUBMITTED:
      return V4_ORDER_STAGE.IN_PROGRESS
    case ORDER_FILTER_SCOPES.COMPLETE:
      return V4_ORDER_STAGE.COMPLETE
    case ORDER_FILTER_SCOPES.CANCELLED:
      return V4_ORDER_STAGE.CANCELLED
    case ORDER_FILTER_SCOPES.NOT_YET_SUBMITTED:
      return V4_ORDER_STAGE.DRAFT
    case ORDER_FILTER_SCOPES.ORDERS:
      return null
    default:
      throw new Error(`Scope ${scope} is invalid.`)
  }
}
