import { ATTRIBUTE_MAPPINGS } from '../constants'

export function mapV3AttributesToV4(v3Attributes: Record<string, string>) {
  const mappedV4Attributes = Object.entries(v3Attributes).reduce(
    (mappedV4AttributesAcc: Record<string, string>, [v3AttributeName, attributeValue]) => {
      const attributeMapping = ATTRIBUTE_MAPPINGS.find(
        (attributeMapping) => v3AttributeName === attributeMapping.v3AttributeName
      )
      const mappedV4AttributeName = attributeMapping?.v4AttributeName ?? v3AttributeName

      return { ...mappedV4AttributesAcc, [mappedV4AttributeName]: attributeValue }
    },
    {}
  )
  return mappedV4Attributes
}
