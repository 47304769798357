function SquarespaceLogoMarkIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox="0 0 125 102"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
    >
      <g id="squarespace-logo">
        <g>
          <path
            d="M26.1545935,54 L65.6521739,14.0187881 C68.3357,11.3024034 71.9062611,9.80874639 75.7122438,9.80874639 C79.5154239,9.80874639 83.0859849,11.3009849 85.7667084,14.0173697 L88.8440052,17.1323466 L95,10.9009742 L91.9227032,7.78599722 C87.5996338,3.40999358 81.8430149,1 75.7094412,1 C69.5786701,1.00141848 63.8206498,3.41141205 59.4975804,7.78883417 L20,47.7686276 L26.1545935,54 Z"
            id="Fill-1"
            fill="#000000"
          ></path>
          <path
            d="M79,27.2992382 L72.8148815,21.0770968 L33.1318482,60.9990813 C27.5591873,66.605099 18.4919979,66.6065153 12.920745,61.001914 C7.34808412,55.3958963 7.34808412,46.2730161 12.920745,40.6669984 L47.1634646,6.22072503 L40.9783461,0 L6.7370344,34.4462734 C-2.24567813,43.4827547 -2.24567813,58.1875742 6.7370344,67.2226391 C11.0805561,71.5921602 16.8644092,74 23.0241846,74 C29.185368,74 34.9720371,71.5907438 39.3155587,67.2212227 L79,27.2992382 Z"
            id="Fill-3"
            fill="#000000"
          ></path>
          <path
            d="M118.26188,34.7760743 C113.919747,30.4064695 108.135868,28 101.973249,28 C95.812038,28.0014164 90.0253432,30.4078859 85.6818022,34.7789071 L46,74.7002393 L52.1823302,80.9224998 L91.8669483,40.9997512 C97.4410419,35.3936262 106.506864,35.3950426 112.078141,40.9983348 C114.772967,43.709331 116.256952,47.3183271 116.256952,51.1638626 C116.256952,55.0108144 114.772967,58.6212269 112.078141,61.3322232 L77.8366772,95.7791559 L84.0218233,102 L118.26188,67.5544837 C127.24604,58.5178295 127.24604,43.8127285 118.26188,34.7760743 Z"
            id="Fill-5"
            fill="#000000"
          ></path>
          <path
            d="M98.8455215,48 L59.348679,87.9812119 C53.8009604,93.594128 44.7780352,93.596965 39.2331191,87.9826303 L36.1558798,84.8676534 L30,91.0990258 L33.0772393,94.2140028 C37.4002279,98.5900064 43.1567393,101 49.2915997,101 C55.4222563,100.998582 61.1787676,98.5885879 65.5031576,94.2111658 L105,54.2313724 L98.8455215,48 Z"
            id="Fill-7"
            fill="#000000"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export { SquarespaceLogoMarkIcon }
