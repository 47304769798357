// @flow
import { connect } from 'react-redux'
import type { ComponentType } from 'react'
import type { DispatchFunc, FormattedOrderItem, Dictionary } from '../../../../types'
import { copyProductDetails, copyImageDetails, copyShippingMethod } from '../../../../actions/csvUpload'

import CsvActionMenuController from './CsvActionMenuController'

type OwnProps = {|
  items: Dictionary<FormattedOrderItem>,
  orderId: string,
  isIgnored: boolean,
  updateOrderIgnore: () => void
|}

type DispatchProps = {|
  copyProductDetails: (orderItem: FormattedOrderItem) => void,
  copyImageDetails: (orderItem: FormattedOrderItem) => void,
  copyShippingMethod: (orderId: string) => void
|}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    copyProductDetails: (orderItem: FormattedOrderItem) => {
      window.analytics.track('Click copy product details', {label: 'csv uploader'})
      dispatch(copyProductDetails(orderItem))
    },
    copyImageDetails: (orderItem: FormattedOrderItem) => {
      window.analytics.track('Click copy image details', {label: 'csv uploader'})
      dispatch(copyImageDetails(orderItem))
    },
    copyShippingMethod: (orderId: string) => {
      window.analytics.track('Click copy shipping method', {label: 'csv uploader'})
      dispatch(copyShippingMethod(orderId))
    }
  }
}

export default (connect(null, mapDispatchToProps)(CsvActionMenuController): ComponentType<OwnProps>)
