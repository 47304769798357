import { AssetStatusEnum } from '../../../enums'

export function formatAssetStatusName(assetStatus: AssetStatusEnum) {
  switch (assetStatus) {
    case AssetStatusEnum.NotYetDownloaded:
    case AssetStatusEnum.Queued:
    case AssetStatusEnum.Downloading:
      return 'Image pending'
    case AssetStatusEnum.None:
      return 'No image'
    case AssetStatusEnum.Error:
      return 'Error'
    case AssetStatusEnum.Expired:
      return 'Image expired'
    default:
      return assetStatus
  }
}
