import clsx from 'clsx'
import { ReactNode } from 'react'

interface PanelInterface {
  children?: ReactNode
  className?: string
  id?: string
  title?: ReactNode
  subtitle?: ReactNode
}

export function Panel({ children, className = '', id, subtitle, title }: PanelInterface) {
  return (
    <div
      id={id}
      className={clsx(className, 'grid grid-cols-1 gap-y-8 bg-white px-12 py-16 md:gap-x-0 lg:grid-cols-4 lg:gap-y-0')}
    >
      <div className="col-span-4 flex flex-col md:col-span-2">
        {title && title}
        {subtitle && subtitle}
      </div>
      <div className="lg:col-span-full lg:col-start-3">{children}</div>
    </div>
  )
}
