// @flow
import React from 'react'

import styles from './ShippingAndTotal.module.css'

type TaxInfoProps = {|
  deliveryCountry: string,
  taxValue: string,
  toggleUsSalesTax: () => void,
  isUsSalesTaxAlreadyCollected: boolean
|}

export default function TaxInfo ({
  deliveryCountry,
  toggleUsSalesTax,
  isUsSalesTaxAlreadyCollected,
  taxValue
}: TaxInfoProps): React$Node {
  const isDeliveryCountryUnitedStates = deliveryCountry === 'US'

  return (
    <React.Fragment>
      {isDeliveryCountryUnitedStates &&
        <tr>
          <td colSpan={2}>
            <div className={styles.taxContainer}>
              <div className={styles.usTaxInfo}>
                <span className={styles.redText}>*</span> This order may be subject to US sales tax, unless it is from a marketplace (such as Etsy) that has already collected tax.
              </div>
              <div className={styles.taxDeclarationContainer}>
                <input
                  id='sales-tax-toggle'
                  data-test='sales-tax-toggle'
                  type='checkbox'
                  onChange={toggleUsSalesTax}
                  className={styles.salesTaxToggle}
                  checked={isUsSalesTaxAlreadyCollected}
                />
                <label htmlFor='sales-tax-toggle' className={styles.salesTaxLabel}>
                  I declare that sales tax has already been collected on this order.
                </label>
              </div>
            </div>
          </td>
        </tr>
      }

    </React.Fragment>
  )
}
