// @flow

import processFetchResponse from '../processFetchResponse'
import getDefaultFetchOptions from '../getDefaultFetchOptions'
import { PAYMENT_SERVICE_URL } from '../../pages/Apps/constants'
import type { GetStripeConnectLoginLinkType } from '../../types'

export function getStripeConnectLoginLink (
  { abortSignal }: {| abortSignal: AbortSignal |}
): Promise<GetStripeConnectLoginLinkType> {
  return fetch(`${PAYMENT_SERVICE_URL}/payments/stripe/account/login_link`, {
    ...getDefaultFetchOptions(),
    method: 'POST',
    signal: abortSignal
  })
    .then(processFetchResponse)
}
