// @flow

import { values } from './dictionary'

import type { MultiAssetTemplate, MultiAssetTemplates } from '../types'

export function selectFirstAvailableTemplate (
  templates: ?MultiAssetTemplates,
  selectedPrintArea: string
): ?MultiAssetTemplate {
  const templatesByOrientations = templates?.printAreas[selectedPrintArea]?.orientations
  if (!templatesByOrientations) {
    return null
  }
  const firstAvailableTemplate = values(templatesByOrientations)[0]
  return firstAvailableTemplate
}
