export const APPAREL_COLOURS = [
  'apple',
  'army',
  'asphalt',
  'athletic_heather',
  'baby_blue',
  'berry',
  'black',
  'bright_white',
  'brown',
  'butter',
  'candy_pink',
  'candyfloss_pink',
  'cardinal_red',
  'cardinal',
  'cardinal_triblend',
  'charcoal',
  'charcoal_black_triblend',
  'charcoal_heather',
  'chocolate',
  'coral',
  'dark_chocolate',
  'dark_green',
  'dark_grey_heather',
  'dark_heather_grey',
  'dark_heather',
  'deep_royal',
  'denim_heather',
  'forest_green',
  'gold',
  'graphite_heather',
  'grass_green_triblend',
  'grey',
  'heather_grey',
  'heathered_cardinal',
  'heathered_kelly_green',
  'heathered_light_grey',
  'heathered_nickel',
  'ice_blue',
  'kelly_green',
  'light_blue',
  'light_pink',
  'marine_olive',
  'maroon',
  'military_green',
  'navy',
  'navy_triblend',
  'new_navy',
  'new_red',
  'olive',
  'orange',
  'pink',
  'purple',
  'red',
  'royal',
  'royal_blue',
  'royal_heather',
  'sand',
  'sangria',
  'silver',
  'soft_cream',
  'sports_grey',
  'sport_grey',
  'team_purple',
  'true_royal',
  'true_royal_triblend',
  'turf_green',
  'turquoise',
  'white',
  'airforce_blue',
  'arctic_white',
  'ash',
  'athletic_grey',
  'azalea',
  'bottle_green',
  'burgundy',
  'cornsilk',
  'daisy',
  'dark_grey',
  'fire_red',
  'fuschia',
  'hawaiian_blue',
  'heather_green',
  'heather_irish_green',
  'heather_purple',
  'hot_chocolate',
  'hot_pink',
  'irish_green',
  'jade',
  'jet_black',
  'kelly',
  'lime_green',
  'natural',
  'navy_blue',
  'new_french_navy',
  'orange_crush',
  'oxford_navy',
  'plum',
  'red_hot_chilli',
  'sapphire',
  'sapphire_blue',
  'sky_blue',
  'steel_grey',
  'sun_yellow',
  'turquoise_surf',
  'vintage_black',
  'vintage_navy',
  'vintage_red',
  'vintage_royal',
  'black_and_white',
  'white_and_asphalt',
  'white_and_black',
  'white_and_kelly_green',
  'white_and_navy',
  'white_and_red',
  'white_and_true_royal'
]
