// @flow
import { compose } from 'redux'
import { connect } from 'react-redux'

import type { AppState } from '../../types'
import AppsController from './AppsController'
import { getUser } from '../../selectors/user'
import WithUserDetails from '../../components/hoc/WithUserDetails'

function mapStateToProps (state: AppState) {
  return {
    user: getUser(state)
  }
}

export default (compose(
  WithUserDetails,
  connect(mapStateToProps)
)(AppsController): any)
