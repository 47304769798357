import Modal from '../Modal'
import OverlayPortal from '../OverlayPortal'
import SavedItems, { SavedItemsPropsInterface } from '../SavedItems'

interface SavedItemsModalPropsInterface extends SavedItemsPropsInterface {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export default function SavedItemsModal({ open, setOpen, ...props }: SavedItemsModalPropsInterface) {
  return (
    <Modal
      className="w-80vw max-w-[1200px]"
      title="Saved items"
      closeOnEscape={!props.selectingSkuName}
      closeOnInteractionOutside={!props.selectingSkuName}
      open={open}
      setOpen={setOpen}
    >
      <div className="h-60vh overflow-y-auto">
        <SavedItems {...props} />
      </div>

      {Boolean(props.selectingSkuName) && <OverlayPortal />}
    </Modal>
  )
}
