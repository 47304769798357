export function generateMultiSkuProductSearchQuery(skus: string[]) {
  let query = ''

  skus.forEach((sku) => {
    const formattedQuery = encodeURIComponent(sku.trim().toLowerCase() + '*')
    const skuQuery = `"${encodeURIComponent(sku.trim().toUpperCase())}"`

    const combined = `${formattedQuery}|${skuQuery}`

    if (query === '') {
      query = combined
    } else {
      query += `|${combined}`
    }
  })

  return query
}
