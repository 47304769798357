// @flow
import type { Element } from 'react'
import React from 'react'
import styles from './InputUnderlined.module.css'

type Props = {|
  autoComplete?: 'on' | 'off',
  autoFocus?: boolean,
  error?: string,
  inputLabel?: string,
  inputPrefix?: string,
  minLength?: string,
  maxLength?: string,
  onChange?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  placeholder?: string,
  readOnly?: boolean,
  spellCheck?: boolean,
  type?: string,
  value?: string | number
|}

function InputUnderlined ({
  autoComplete = 'off',
  error = '',
  inputLabel = '',
  inputPrefix = '',
  onChange,
  spellCheck = false,
  placeholder = '',
  type = 'text',
  value,
  ...otherProps
}: Props): Element<"div"> {
  return (
    <div className={styles.inputUnderlinedContainer}>
      <div className={styles.inputLabel}>
        <label>
          {inputLabel}
        </label>
      </div>
      <div className={styles.inputContainer}>
        <span className={styles.inputPrefix}>{inputPrefix}</span>
        <input
          className={styles.inputUnderlined}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          spellCheck={spellCheck}
          autoComplete={autoComplete}
          {...otherProps} />
      </div>
      <div className={styles.inputError}>
        {error}
      </div>
    </div>
  )
}

export default InputUnderlined
