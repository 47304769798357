// @flow
import { values } from '../../helpers/dictionary'
import type { Dictionary, FormattedOrderItem, FormattedCsvRow } from '../../types'

export function groupByMerchantOrderId (
  orders: Dictionary<FormattedCsvRow>,
  orderItems: Dictionary<FormattedOrderItem>
): Dictionary<FormattedCsvRow> {
  const ordersByMerchantOrderId = {}

  values(orders).forEach((order, index) => {
    const id = order.merchantOrderId
    const currentOrderId = order.id

    if (!id) {
      ordersByMerchantOrderId[currentOrderId] = order
      return ordersByMerchantOrderId
    }

    const existingOrder = values(ordersByMerchantOrderId).find(item => item.merchantOrderId === id)
    const existingIndex = Object.keys(orders).find(key => orders[key] === existingOrder)

    if (existingOrder && existingIndex) {
      ordersByMerchantOrderId[existingIndex].itemIds = [...ordersByMerchantOrderId[existingIndex].itemIds, ...order.itemIds]
    } else {
      ordersByMerchantOrderId[currentOrderId] = order
    }

    return ordersByMerchantOrderId
  })

  return ordersByMerchantOrderId
}
