function RightPanel() {
  return (
    <div className="sticky top-0 hidden h-screen w-1/2 flex-col items-center bg-products-bg bg-cover bg-bottom bg-no-repeat p-10 lg:flex">
      <div className="mt-44 max-w-4xl text-center text-white">
        <h2 className="mt-0 text-4xl font-normal text-white">The biggest selection of print on demand products</h2>
        <p className="mt-4 px-24 text-lg">From fine art prints and stationery to homeware, apparel and accessories.</p>
      </div>
    </div>
  )
}

export { RightPanel }
