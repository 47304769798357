import LogoProdigi from '../../LogoProdigi'
import { APP_ENVIRONMENTS } from '../../../constants'
import { SalesChannelPlatformEnum } from '../../../enums'
import {
  AdobeCommerceLogoIcon,
  AdobeCommerceLogoMarkIcon,
  BigCommerceLogoIcon,
  BigCommerceLogoMarkIcon,
  EtsyLogoIcon,
  EtsyLogoMarkIcon,
  ShopifyLogoIcon,
  ShopifyLogoMarkIcon,
  SquarespaceLogoIcon,
  SquarespaceLogoMarkIcon,
  WixLogoIcon,
  WixLogoMarkIcon,
  WooCommerceLogoIcon,
  WooCommerceLogoMarkIcon
} from '../../svg'

export function HeaderLogo({ platformName }: { platformName: SalesChannelPlatformEnum }) {
  switch (platformName) {
    case SalesChannelPlatformEnum.Prodigi:
      return <LogoProdigi className="h-9 w-9" />
    case SalesChannelPlatformEnum.Etsy:
      return (
        <>
          <EtsyLogoIcon className="hidden object-contain object-left lg:inline lg:h-12" />
          <EtsyLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    case SalesChannelPlatformEnum.WooCommerce:
      return (
        <>
          <WooCommerceLogoIcon className="mt-2 hidden object-contain object-left lg:inline lg:h-12" />
          <WooCommerceLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    case SalesChannelPlatformEnum.Wix:
      return (
        <>
          <WixLogoIcon className="hidden object-contain object-left lg:inline lg:h-10" />
          <WixLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    case SalesChannelPlatformEnum.AdobeCommerce:
      return (
        <>
          <AdobeCommerceLogoIcon className="ml-1 hidden object-contain object-left lg:inline lg:h-12" />
          <AdobeCommerceLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    case SalesChannelPlatformEnum.BigCommerce:
      return (
        <>
          <BigCommerceLogoIcon className="hidden object-contain object-left lg:inline lg:h-12" />
          <BigCommerceLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    case SalesChannelPlatformEnum.Squarespace:
      return (
        <>
          <SquarespaceLogoIcon className="hidden object-contain object-left lg:inline lg:h-14" />
          <SquarespaceLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    case SalesChannelPlatformEnum.Shopify:
      return (
        <>
          <ShopifyLogoIcon className="hidden object-contain object-left lg:inline lg:h-12" />
          <ShopifyLogoMarkIcon className="h-12 w-auto object-contain object-left lg:hidden" />
        </>
      )
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
