import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { useSplitToggle } from '../../../hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../../constants'
import ProductCatalogueProductTypes from '../../../components/ProductCatalogueProductTypes'

export default function ProductCategoryPage() {
  const { category: categoryParam } = useParams<{ category: string }>()

  const category = decodeURIComponent(categoryParam)
  useEffect(() => {
    document.title = `${category ?? 'Products'} | Prodigi`
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  })

  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  if (isAPCSplitOn) {
    return <Redirect to={ROUTE_PATHS.PRODUCT_CATALOGUE} />
  }

  return (
    <main className="tailwind">
      <ProductCatalogueProductTypes />
    </main>
  )
}
