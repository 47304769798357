// @flow

export type SizeAndScore = {|
  value: string,
  score: number
|}

export type ApparelSizes = SizeAndScore[]

export const CsvApparelSizeMapping = {
  'small': 'S',
  'medium': 'M',
  'large': 'L',
  'extra large': 'XL',
  'extra extra large': 'XXL'
}

export const apparelSizes: ApparelSizes = [
  {
    value: 'newborn',
    score: 0
  },
  {
    value: '3_4',
    score: 1
  },
  {
    value: '3_6m',
    score: 2
  },
  {
    value: '3-6 months',
    score: 2.5
  },
  {
    value: '5_6',
    score: 3
  },
  {
    value: '6 months',
    score: 4
  },
  {
    value: '7_8',
    score: 5
  },
  {
    value: '9_11',
    score: 6
  },
  {
    value: '6-12 months',
    score: 7
  },
  {
    value: '6_12m',
    score: 7
  },
  {
    value: '12 months',
    score: 8
  },
  {
    value: '12_13',
    score: 9
  },
  {
    value: '12_18m',
    score: 10
  },
  {
    value: '12-18 months',
    score: 10
  },
  {
    value: '18 months',
    score: 11
  },
  {
    value: '18-24 months',
    score: 12
  },
  {
    value: '18_24m',
    score: 12
  },
  {
    value: '24m',
    score: 13
  },
  {
    value: '2yr',
    score: 13
  },
  {
    value: '24 months',
    score: 13.5
  },
  {
    value: '2 years',
    score: 13.5
  },
  {
    value: '3-4 years',
    score: 14
  },
  {
    value: '4yr',
    score: 15
  },
  {
    value: '4 years',
    score: 15.5
  },
  {
    value: '5-6 years',
    score: 16
  },
  {
    value: '6yr',
    score: 17
  },
  {
    value: '6 years',
    score: 17.5
  },
  {
    value: '7-8 years',
    score: 18
  },
  {
    value: '8yr',
    score: 19
  },
  {
    value: '8 years',
    score: 19.5
  },
  {
    value: '10yr',
    score: 20
  },
  {
    value: '10 years',
    score: 20.5
  },
  {
    value: '9-11 years',
    score: 21
  },
  {
    value: '12-13 years',
    score: 22
  },
  {
    value: 'xs',
    score: 23
  },
  {
    value: 's',

    score: 24
  },
  {
    value: 'small',
    score: 24
  },
  {
    value: 'm',
    score: 25
  },
  {
    value: 'medium',
    score: 25
  },
  {
    value: 'l',
    score: 26
  },
  {
    value: 'large',
    score: 26
  },
  {
    value: 'xl',
    score: 27
  },
  {
    value: 'xxl',
    score: 28
  },
  {
    value: '2xl',
    score: 28
  },
  {
    value: 'xxxl',
    score: 29
  },
  {
    value: '3xl',
    score: 29
  },
  {
    value: '4xl',
    score: 30
  },
  {
    value: '5xl',
    score: 31
  }
]
