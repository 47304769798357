import { useOs } from '@mantine/hooks'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'

import SearchField from '../../SearchField'
import { QUERY_PARAMS } from '../../../constants'
import { HOTKEYS } from '../../../constants/hotkeys.const'

export function ProductsSearch() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchParam = searchParams.get(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.SEARCH)
  const searchValueFromParam = useMemo(() => searchParam ?? '', [searchParam])

  const [searchValue, setSearchValue] = useState(searchValueFromParam)

  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const searchHotkeyDisplay = isAppleOs
    ? HOTKEYS.SALES_CHANNEL.CONFIGURE.SEARCH.DISPLAY.APPLE
    : HOTKEYS.SALES_CHANNEL.CONFIGURE.SEARCH.DISPLAY.DEFAULT

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSearchValue(searchValueFromParam)
  }, [searchValueFromParam, searchParams])

  const debouncedSearch = useDebouncedCallback(async (searchValue: string) => {
    if (searchValue.trim() === '') {
      searchParams.delete(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.SEARCH)
      history.push(`?${searchParams.toString()}`)
      return
    }

    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.SEARCH, searchValue)
    history.push(`?${newSearchParams.toString()}`)
  }, 500)

  function handleSearch(value: string) {
    setSearchValue(value)
    debouncedSearch(value)
  }

  return (
    <span className="w-full">
      <div className="relative isolate w-full">
        <SearchField
          hotkey={HOTKEYS.SALES_CHANNEL.CONFIGURE.SEARCH.TRIGGER}
          hotkeyDisplay={searchHotkeyDisplay}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => handleSearch(event.target.value)}
          onClearSearch={() => handleSearch('')}
        />
      </div>
    </span>
  )
}
