// @flow
import type { Dimensions } from '../../types'
import { calcPrintDimensionsFitScaleFactor } from '@prodigi-group/components-image-editor'

export function calculateFitScaleFactor (
  printResolutionInPx: Dimensions,
  artworkDimensionsInPx: Dimensions,
  borderInPx: number,
  rotationAngle: number
): number {
  const scaleFactor = calcPrintDimensionsFitScaleFactor(printResolutionInPx, artworkDimensionsInPx, borderInPx, rotationAngle)

  return scaleFactor
}
