// @flow
import React, { Component } from 'react'
import Radium from 'radium'
import mediaQueries from '../../../components/styles/mediaQueries'
import currencyFormat from '../../../helpers/currencyFormat'
import { CURRENCY_CODE } from '../../../data/currencies'

type Props = {|
  currency: string,
  price?: number,
  qty: number,
  zeroPricedItem: boolean,
  quantityChanged: number => void
|}

class PriceAndQuantity extends Component<Props> {
  calcInputFieldWidth = (value: number): string => {
    const valueLength = value?.toString().length || 1
    if (valueLength === 1) {
      return '3.5rem'
    } else {
      return value.toString().length * 1.75 + 'rem'
    }
  }

  handleQtyChange = e => {
    this.props.quantityChanged(e.target.value)
  }

  onBlur: ((e:SyntheticInputEvent<HTMLInputElement>) => void) = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.valueAsNumber < 1) {
      this.props.quantityChanged(1)
    }
  }

  getStyles () {
    return {
      container: {
        display: 'flex',
        justifyContent: 'space-between',
        [mediaQueries.breakpointSmall]: {
          float: 'left',
          textAlign: 'left',
          width: '100%'
        }
      },
      qty: {
        width: '3.5rem',
        textAlign: 'center',
        paddingLeft: '0.75em',
        backgroundColor: 'var(--color-white)',
        borderRadius: '3px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'var(--greyscale-light)',
        ':focus': {
          outline: 'none',
          borderColor: 'var(--accent-blue)'
        }
      },
      times: {
        margin: '0 0.25em'
      },
      total: {
        paddingLeft: '1em'
      },
      loading: {
        fontSize: '1.1em',
        marginRight: '20%'
      }
    }
  }

  get currency (): string {
    if (this.props.zeroPricedItem) {
      return CURRENCY_CODE.GBP
    }
    return this.props.currency
  }

  get nonNullablePrice (): number {
    if (this.props.zeroPricedItem) {
      return 0
    } else if (this.props.price) {
      return this.props.price
    } else {
      throw Error('Price cannot be null.')
    }
  }

  render (): React$Node {
    const { price, qty, zeroPricedItem } = this.props
    const styles = this.getStyles()
    const containsOnlyLetters = /[a-zA-Z]+/
    const displayPrice = (price || zeroPricedItem) && this.currency && containsOnlyLetters.test(this.currency)
    const quantityInput = (
      <input
        disabled={zeroPricedItem}
        type="number"
        value={qty}
        style={{ ...styles.qty, width: this.calcInputFieldWidth(qty) }}
        min="1"
        onBlur={this.onBlur}
        onChange={this.handleQtyChange}
        data-test="item-quantity-input"
      />)

    return (
      <div style={styles.container}>
        <span>
          {
            displayPrice && (
              <React.Fragment>
                {currencyFormat(this.nonNullablePrice, this.currency)}
                <span style={styles.times}>&times;</span>
                {quantityInput}
                <strong
                  style={styles.total}
                  data-test="item-price"
                >
                  {currencyFormat(this.nonNullablePrice * (qty || 1), this.currency)}
                </strong>
              </React.Fragment>
            )
          }
          {
            !displayPrice && (
              <React.Fragment>
                <span style={styles.times}>&times;</span>
                {quantityInput}
                <strong style={styles.total}>TBC</strong>
              </React.Fragment>
            )
          }
        </span>
      </div>
    )
  }
}

export default (Radium(PriceAndQuantity): any)
