import { ATTRIBUTE_MAPPINGS } from '../constants'

export function mapV4AttributesToV3(v4Attributes: Record<string, string>) {
  const mappedV3Attributes = Object.entries(v4Attributes).reduce(
    (mappedV3AttributesAcc: Record<string, string>, [v4AttributeName, attributeValue]) => {
      const attributeMapping = ATTRIBUTE_MAPPINGS.find(
        (attributeMapping) => v4AttributeName === attributeMapping.v4AttributeName
      )
      const mappedV3AttributeName = attributeMapping?.v3AttributeName ?? v4AttributeName

      return { ...mappedV3AttributesAcc, [mappedV3AttributeName]: attributeValue }
    },
    {}
  )
  return mappedV3Attributes
}
