import { useDebouncedCallback } from 'use-debounce'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useCompositeState, Composite, CompositeItem } from 'reakit/Composite'

import { cn } from '../../helpers'
import { IconCheckMark } from './components'
import { CLEAR_ALL_OPTION_VALUE } from '../MultiSelectDropdown/MultiSelectDropdown.component'
export interface InlineMenuOptionInterface {
  content: React.ReactNode
  disabled?: boolean
  hint?: string
  searchLabel: string
  value: string
}

type CommonPropsType = {
  ariaLabel: string
  focusOnMount?: boolean
  className?: string
  listItemClassName?: string
  options: Array<InlineMenuOptionInterface>
  onChange?: (value: string) => void
}

type ConditionalPropsType =
  | {
      multiple: true
      selected: string[]
      updateSelected?: (selected: string[]) => void
    }
  | {
      multiple?: false
      selected: string | null
      updateSelected?: (selected: string) => void
    }

type InlineMenuPropsType = CommonPropsType & ConditionalPropsType

export default function InlineMenu({
  ariaLabel,
  focusOnMount = false,
  className = '',
  listItemClassName = '',
  multiple,
  options,
  selected,
  updateSelected,
  onChange
}: InlineMenuPropsType) {
  const { move, ...compositeProps } = useCompositeState({ orientation: 'vertical', loop: true })
  const [searchTerm, setSearchTerm] = useState('')

  function handleSelection({ value, isValueSelected }: { value: string; isValueSelected: boolean }) {
    onChange?.(value)
    if (!multiple) {
      updateSelected?.(value)
      return
    }

    let updatedSelectedValues: string[] = []
    if (!isValueSelected) {
      updatedSelectedValues = [...selected, value]
    } else {
      updatedSelectedValues = selected.filter((selectedValue) => selectedValue !== value)
    }
    updateSelected?.(updatedSelectedValues)
  }

  const resetSearchTermOnPause = useDebouncedCallback(() => setSearchTerm(''), 250)

  function handleKeyDownOnList(event: KeyboardEvent<HTMLUListElement>) {
    event.persist()

    const key = event.key.toLowerCase()
    if (key.length !== 1) {
      return
    }
    const isLetter = key >= 'a' && key <= 'z'
    const isNumber = key >= '0' && key <= '9'
    if (!isLetter && !isNumber) {
      return
    }

    setSearchTerm((searchTerm) => searchTerm + key)
    resetSearchTermOnPause()
  }

  const focusFirstSelectedOption = useCallback(() => {
    let firstSelectedOption = null
    if (multiple) {
      firstSelectedOption = options.find((option) => selected.includes(option.value))
    } else {
      firstSelectedOption = options.find((option) => option.value === selected)
    }
    if (!firstSelectedOption) {
      move(options[0].searchLabel)
      return
    }
    move(firstSelectedOption.searchLabel)
  }, [move, multiple, options, selected])

  function handleFocusOnList(event: React.FocusEvent<HTMLUListElement>) {
    if (!focusOnMount || event.currentTarget.contains(event.relatedTarget)) {
      return
    }
    // Triggered only when focus enters the list
    // https://reactjs.org/docs/events.html#detecting-focus-entering-and-leaving
    focusFirstSelectedOption()
  }

  useEffect(() => {
    if (focusOnMount) {
      focusFirstSelectedOption()
    }
  }, [focusFirstSelectedOption, focusOnMount, move, options])

  useEffect(() => {
    if (!searchTerm) {
      return
    }

    const option = options.find((option) => option.searchLabel.toLowerCase().startsWith(searchTerm))
    if (option) {
      move(option.searchLabel)
    }
  }, [move, options, searchTerm])

  return (
    <Composite
      {...compositeProps}
      aria-label={ariaLabel}
      aria-multiselectable={Boolean(multiple)}
      as="ul"
      className={className}
      data-test="inline-menu"
      role="listbox"
      onFocus={handleFocusOnList}
      onKeyDown={handleKeyDownOnList}
    >
      {options.map((option) => {
        let isValueSelected = false
        if (multiple) {
          isValueSelected = selected.includes(option.value)
        } else {
          isValueSelected = option.value === selected
        }

        return (
          <CompositeItem
            {...compositeProps}
            aria-selected={isValueSelected}
            as="li"
            className={cn(
              `group relative flex cursor-pointer select-none break-words py-3 pl-10 transition-colors duration-200 ease-in hover:bg-gray-100 hover:duration-100 hover:ease-out focus:outline-none focus-visible:bg-[hsl(0deg,0%,90%)] focus-visible:duration-100 focus-visible:ease-out ${
                option.disabled ? 'opacity-60' : ''
              } ${listItemClassName}`,
              option.value === CLEAR_ALL_OPTION_VALUE && 'sticky bottom-0 bg-white'
            )}
            data-test="inline-menu-option"
            data-test-value={`inline-menu-option-${option.value}`}
            disabled={option.disabled}
            id={option.searchLabel}
            key={option.value}
            role="option"
            onClick={() => handleSelection({ value: option.value, isValueSelected })}
          >
            <span className="absolute inset-0 flex items-center pl-3 pr-2">
              <IconCheckMark dataTest="inline-menu-option-selected" isVisible={isValueSelected} />
            </span>
            {option.content}
            {option.hint && (
              <>
                <span className="sr-only">Shortcut key</span>
                <span className="absolute top-0 bottom-0 right-0 flex items-center pl-2 pr-4 text-xs text-black/50 opacity-0 transition-opacity duration-200 ease-in group-hover:bg-gray-100 group-hover:opacity-100 group-hover:duration-100 group-hover:ease-out group-focus-visible:bg-[hsl(0deg,0%,90%)] group-focus-visible:opacity-100 group-focus-visible:duration-100 group-focus-visible:ease-out">
                  {option.hint}
                </span>
              </>
            )}
          </CompositeItem>
        )
      })}
    </Composite>
  )
}
