import { motion } from 'framer-motion'
import { Link, useLocation, useParams } from 'react-router-dom'

import EmptyState from '../../EmptyState'
import LogoProdigi from '../../LogoProdigi'
import { SalesChannelPlatformEnum } from '../../../enums'
import { ProductsRetrieving } from './ProductsRetrieving.component'
import { APP_ENVIRONMENTS, QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'

interface ProductsEmptyPropsInterface {
  initialProductsDownloaded: boolean
  platformName: SalesChannelPlatformEnum
  salesChannelName: string
}

export function ProductsEmpty({
  initialProductsDownloaded,
  platformName,
  salesChannelName
}: ProductsEmptyPropsInterface) {
  const { search } = useLocation()
  const { salesChannelId } = useParams<{ salesChannelId: string }>()

  const hasFiltersApplied = hasConfigureParamsInSearch(search)

  if (hasFiltersApplied) {
    return (
      <EmptyState>
        <div className="mx-auto max-w-5xl">
          <NoProductsPlatformLogo platformName={platformName} />

          <strong className="mt-4 block">{salesChannelName}</strong>

          <h2 className="mt-16">We could not find any products matching selected filters</h2>

          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: -10, opacity: 0 }}
            className="mt-16"
            transition={{ delay: 0.3 }}
          >
            <Link className="btn btn-primary mx-auto w-fit" to={ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(salesChannelId)}>
              Clear filters
            </Link>
          </motion.div>
        </div>
      </EmptyState>
    )
  }

  if (!initialProductsDownloaded) {
    return <ProductsRetrieving platformName={platformName} />
  }

  return (
    <EmptyState>
      <div className="mx-auto max-w-5xl">
        <NoProductsPlatformLogo platformName={platformName} />

        <strong className="mt-4 block">{salesChannelName}</strong>

        <h2 className="mt-16">We could not find any products in this store</h2>

        <p className="mt-6">Once you add products to your store, they will automatically appear here.</p>

        <motion.div
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -10, opacity: 0 }}
          className="mt-16"
          transition={{ delay: 0.3 }}
        >
          <Link className="btn btn-primary mx-auto w-fit" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
            Back to sales channels
          </Link>
        </motion.div>
      </div>
    </EmptyState>
  )
}

export function hasConfigureParamsInSearch(search: string) {
  const params = [QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.SEARCH, QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS]

  return params.some((param) => search.includes(param))
}

function NoProductsPlatformLogo({ platformName }: { platformName: SalesChannelPlatformEnum }) {
  switch (platformName) {
    case SalesChannelPlatformEnum.Etsy:
      return (
        <img
          alt="Etsy logo"
          src="/img/logo/etsy-orange.png"
          className="mx-auto block h-20 w-36 object-contain object-center"
        />
      )
    case SalesChannelPlatformEnum.WooCommerce:
      return (
        <img
          alt="WooCommerce logo"
          src="/img/logo/woocommerce.svg"
          className="mx-auto block h-20 w-36 object-contain object-center"
        />
      )
    case SalesChannelPlatformEnum.Wix:
      return (
        <img
          alt="Wix logo"
          src="/img/logo/wix.png"
          className="mx-auto block h-20 w-36 object-contain object-center"
          style={{ transform: 'scale(0.7)' }}
        />
      )
    case SalesChannelPlatformEnum.AdobeCommerce:
      return (
        <img
          alt="Adobe Commerce logo"
          src="/img/logo/adobecommerce.svg"
          className="w-42 mx-auto block h-20 object-contain object-center"
        />
      )
    case SalesChannelPlatformEnum.BigCommerce:
      return (
        <img
          alt="BigCommerce logo"
          src="/img/logo/bigcommerce.svg"
          className="w-42 mx-auto block h-20 object-contain object-center"
        />
      )
    case SalesChannelPlatformEnum.Shopify:
      return (
        <img
          alt="Shopify logo"
          src="/img/logo/shopify.svg"
          className="w-42 mx-auto block h-20 object-contain object-center"
        />
      )
    case SalesChannelPlatformEnum.Squarespace:
      return (
        <img
          alt="Squarespace logo"
          src="/img/logo/squarespace.svg"
          className="w-42 mx-auto block h-20 object-contain object-center"
        />
      )
    case SalesChannelPlatformEnum.Prodigi:
      return <LogoProdigi className="mx-auto h-20 w-20" />
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
