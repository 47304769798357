// @flow
import React from 'react'

import {
  DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE,
  STRIPE_CONNECT_NOT_FINISHED_CTA,
  STRIPE_CONNECT_NOT_FINISHED_MESSAGE,
  STRIPE_CONNECT_ONBOARDING_STATES,
  STRIPE_CONNECT_PENDING_VERIFICATION_MESSAGE
} from '../../pages/Apps/constants'
import StripeBannerView from './StripeBannerView'
import { ABORT_ERROR_NAME } from '../../data/constants'
import StripeBannerSlimView from './StripeBannerSlimView'
import type { AbortController, StripeConnectOnboardingState } from '../../types'
import { getStripeConnectSignupLink, processStripeConnectSignup } from '../../helpers/stripeConnect'

type Props = {|
  countryCode: string,
  email: string,
  stripeOnboardingState: StripeConnectOnboardingState
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  hasError: boolean,
  isLoading: boolean,
  stripeBannerErrorMessage: string,
|}

class StripeBannerController extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const abortController = new window.AbortController()
    const abortSignal = abortController.signal

    this.state = {
      abortController,
      abortSignal,
      hasError: false,
      isLoading: false,
      stripeBannerErrorMessage: DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE
    }
  }

  handleStripeConnectNotStarted: (() => Promise<void>) = async () => {
    this.setLoadingState()
    const { abortSignal } = this.state
    const { countryCode, email } = this.props

    try {
      const stripeConnectSignupLinkData = await processStripeConnectSignup({
        abortSignal,
        countryCode,
        email
      })
      this.redirectToStripe(stripeConnectSignupLinkData.url)
    } catch (error) {
      if (error.responseBodyJson && error.responseBodyJson.error && error.responseBodyJson.error.message) {
        this.setState({
          stripeBannerErrorMessage: error.responseBodyJson.error.message
        })
      } else {
        this.setState({
          stripeBannerErrorMessage: DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE
        })
      }
      this.handleError(error)
    }
  }

  handleStripeConnectNotFinished: (() => Promise<void>) = async () => {
    this.setLoadingState()
    const { abortSignal } = this.state
    try {
      const stripeConnectSignupLinkData = await getStripeConnectSignupLink({
        abortSignal
      })
      this.redirectToStripe(stripeConnectSignupLinkData.url)
    } catch (error) {
      this.handleError(error)
    }
  }

  redirectToStripe: ((stripeOnboardingUrl: string) => void) = (stripeOnboardingUrl: string) => {
    window.location.href = stripeOnboardingUrl
  }

  handleError: ((err: Error) => void) = (err: Error) => {
    if (err.name !== ABORT_ERROR_NAME) {
      console.error(err)
      this.setState({
        hasError: true,
        isLoading: false
      })
    }
  }

  setLoadingState: (() => void) = () => {
    this.setState({ isLoading: true, hasError: false })
  }

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    switch (this.props.stripeOnboardingState) {
      case STRIPE_CONNECT_ONBOARDING_STATES.NOT_FINISHED:
        return (
          <StripeBannerSlimView
            backgroundColor="var(--color-banner-1)"
            bannerText={STRIPE_CONNECT_NOT_FINISHED_MESSAGE}
            ctaText={STRIPE_CONNECT_NOT_FINISHED_CTA}
            hasError={this.state.hasError}
            isCtaVisible={true}
            isLoading={this.state.isLoading}
            textColor="var(--color-black)"
            onCtaClick={this.handleStripeConnectNotFinished}
          />
        )
      case STRIPE_CONNECT_ONBOARDING_STATES.PENDING_VERIFICATION:
        return (
          <StripeBannerSlimView
            backgroundColor="var(--color-alert-orange)"
            bannerText={STRIPE_CONNECT_PENDING_VERIFICATION_MESSAGE}
            textColor="var(--color-white)"
            isCtaVisible={false}
          />
        )
      case STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED:
      default:
        return (
          <StripeBannerView
            errorMessage={this.state.stripeBannerErrorMessage}
            onCreateAccountClick={this.handleStripeConnectNotStarted}
            hasError={this.state.hasError}
            isLoading={this.state.isLoading} />
        )
    }
  }
}

export default StripeBannerController
