import React from 'react'
import { Remark } from 'react-remark'

import {
  EcoSolventInks,
  FooterContainer,
  FooterBlock,
  LocalFulfillment,
  NeoPigmentInks,
  Organic,
  PlasticFree,
  Recyclable,
  RecycledContent,
  RecycledPackaging,
  SustainablySourced,
  Vegan,
  VegetableInks,
  WaterBasedInks
} from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function Eco({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <FooterContainer>
      <FooterBlock title="Eco credentials">
        <ul className="flex flex-wrap">
          {productData.eco?.types?.map((type) => (
            <li className="mb-12 w-full md:w-1/2" key={type.abbreviation}>
              <EcoImage abbreviation={type.abbreviation} />
              <h3 className="mt-4 mr-4 text-lg">{type.title}</h3>
              <p className="mr-4">{type.shortDescription}</p>
            </li>
          ))}
        </ul>

        {productData.eco?.notes && (
          <div className="mt-6 text-sm text-gray-600">
            <Remark
              rehypeReactOptions={{
                components: {
                  p(props: JSX.IntrinsicAttributes) {
                    return <React.Fragment {...props} />
                  },
                  a(props: JSX.IntrinsicAttributes) {
                    return <a {...props} className="w-fit text-sm text-gray-600" target="_blank" rel="noreferrer" />
                  }
                }
              }}
            >
              {productData.eco?.notes}
            </Remark>
          </div>
        )}
      </FooterBlock>
    </FooterContainer>
  )
}

function EcoImage({ abbreviation }: { abbreviation: string }) {
  switch (abbreviation) {
    case 'ei':
      return <EcoSolventInks className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'lf':
      return <LocalFulfillment className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'ni':
      return <NeoPigmentInks className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'or':
      return <Organic className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'pf':
      return <PlasticFree className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'rc':
      return <RecycledContent className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'rp':
      return <RecycledPackaging className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'ry':
      return <Recyclable className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'ss':
      return <SustainablySourced className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'vi':
      return <VegetableInks className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'vg':
      return <Vegan className="h-12 w-12 lg:h-16 lg:w-16" />
    case 'wi':
      return <WaterBasedInks className="h-12 w-12 lg:h-16 lg:w-16" />
    default:
      return <></>
  }
}
