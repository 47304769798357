// @flow
import React from 'react'
import type { Element, ChildrenArray } from 'react'
import { flatten } from './array'

export type Path = {|
  value: string,
  isExact: boolean
|}

export function findPaths (children: ChildrenArray<*>): Path[] {
  const paths: Path[][] = React.Children.toArray(children).reduce(toPaths, [])
  return flatten(paths)
}

function toPaths (pathAcc: Path[][], child: Element<*>): Path[][] {
  if (!React.isValidElement(child)) {
    return pathAcc
  }

  if (child.props.path) {
    pathAcc.push([{ value: String(child.props.path), isExact: Boolean(child.props.exact) }])
  } else if (child.props.children) {
    pathAcc.push(findPaths(child.props.children))
  }

  return pathAcc
}
