import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE } from '../../constants'
import ChangePassword from '../../components/ChangePassword'

export default function PasswordChangePage() {
  useEffect(() => {
    document.title = 'Change Password | Prodigi'
    window.analytics.page('Account', 'Password Change')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <ChangePassword />
    </main>
  )
}
