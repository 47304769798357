// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'
import { ADD_RECENT_ITEM_TO_BASKET, SETUP_RECENT_ITEMS_ERROR } from '../../../actions/recentItems'

export function recentItems (action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case SETUP_RECENT_ITEMS_ERROR: {
      return ['Setting up recent items failed', { resp: action.message }, 'error']
    }

    case ADD_RECENT_ITEM_TO_BASKET: {
      return ['Add recent item to basket', { ...action.item }]
    }

    default: {
      return null
    }
  }
}
