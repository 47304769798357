import { CheckIcon } from '@heroicons/react/24/outline'

import NotFound from '../NotFound'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../../constants'

export default function MaintainSalesChannelConnections() {
  return (
    <div className="grid min-h-screen w-full place-content-center px-6">
      <NotFound
        className="mx-auto max-w-xl px-9"
        heading="Connections successfully confirmed"
        icon={<CheckIcon className={`mx-auto mb-10 h-16 w-16 text-green-700`} />}
        body={
          <>
            <p className="text-center">
              You have successfully confirmed that you would like to maintain the connection between Prodigi and your
              sales channels.
            </p>
            <div className="mt-4 text-center">
              Thank you for continuing to work with Prodigi. Any orders that we receive will continue to be processed in
              the usual manner.
            </div>
          </>
        }
      >
        <Link className="btn btn-primary mx-auto mt-8" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
          Back to sales channels
        </Link>
      </NotFound>
    </div>
  )
}
