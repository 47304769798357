// @noflow
export const frameColours = [
  {
    name: 'classic_black',
    hex: '#161616',
    noSwatch: true
  },
  {
    name: 'classic_brown',
    hex: '#693B33'
  },
  {
    name: 'classic_white',
    hex: '#F1F0F5',
    noSwatch: true
  },
  {
    name: 'classic_silver',
    hex: '#D2D0D2'
  },

  {
    name: 'classic_gold',
    hex: '#998519'
  },
  {
    name: 'classic_natural',
    hex: '#CDB795'
  },
  {
    name: 'box_black',
    hex: '#161616'
  },
  {
    name: 'box_brown',
    hex: '#693B33'
  },
  {
    name: 'box_white',
    hex: '#F1F0F5'
  },
  {
    name: 'box_natural',
    hex: '#CDB795'
  },
  {
    name: 'spacer_black',
    hex: '#27262B'
  },
  {
    name: 'spacer_brown',
    hex: '#311411'
  },
  {
    name: 'spacer_white',
    hex: '#F1F0F5'
  },
  {
    name: 'spacer_natural',
    hex: '#CDB795'
  },
  {
    name: 'spacer_silver',
    hex: '#D2D0D2'
  },
  {
    name: 'spacer_gold',
    hex: '#998519'
  },
  {
    name: 'gloss_black',
    hex: '#161616',
    noSwatch: true
  },
  {
    name: 'gloss_white',
    hex: '#F1F0F5',
    noSwatch: true
  },
  {
    name: 'swoop_black',
    hex: '#161616',
    noSwatch: true
  },
  {
    name: 'swoop_white',
    hex: '#F1F0F5',
    noSwatch: true
  },
  {
    name: 'surface_black',
    hex: '#161616',
    noSwatch: true
  },
  {
    name: 'surface_white',
    hex: '#F1F0F5',
    noSwatch: true
  },
  {
    name: 'snow_white',
    hex: '#F5F5F5',
    noSwatch: true
  },
  {
    name: 'off_white',
    hex: '#F7F1EE',
    noSwatch: true
  },
  {
    name: 'black',
    hex: '#000000',
    noSwatch: true
  },
  {
    name: 'white',
    hex: '#FFFFFF',
    noSwatch: true
  }
]
