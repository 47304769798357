// @flow

import { saveUnfinishedOrder } from './saveUnfinishedOrder'
import type { Thunk, DispatchFunc, GetStateFunc } from '../../types'
import type { RemoveBasketItemArtwork } from '../types/manualOrderForm'
import { resetImage } from '../images/resetImage'
import { buildImageId } from '../images'

export const REMOVE_BASKET_ITEM_ARTWORK = 'REMOVE_BASKET_ITEM_ARTWORK'

export function removeBasketItemArtwork (basketItemId: string, printArea: string): Thunk<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const removeBasketItemArtwork: RemoveBasketItemArtwork = {
      type: REMOVE_BASKET_ITEM_ARTWORK,
      basketItemId,
      printArea
    }
    await dispatch(removeBasketItemArtwork)
    const imageId = buildImageId(basketItemId, printArea)
    await dispatch(resetImage(imageId))

    const mof = getState().manualOrderForm
    saveUnfinishedOrder(mof.inProgressOrder, mof.customer, mof.deliveryCountry)
  }
}
