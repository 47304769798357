import { DictionaryInterface } from '../interfaces'

interface PostcodeLabelInterface {
  countryCode: string
  label: string
}

export const POSTCODE_LABELS: DictionaryInterface<PostcodeLabelInterface> = {
  Italy: {
    countryCode: 'IT',
    label: 'CAP'
  },
  Brazil: {
    countryCode: 'BR',
    label: 'CEP'
  },
  Ireland: {
    countryCode: 'IE',
    label: 'Eircode'
  },
  India: {
    countryCode: 'IN',
    label: 'PIN'
  },
  Germany: {
    countryCode: 'DE',
    label: 'PLZ'
  },
  Austria: {
    countryCode: 'AT',
    label: 'PLZ'
  },
  Liechtenstein: {
    countryCode: 'LI',
    label: 'PLZ'
  },
  Canada: {
    countryCode: 'CA',
    label: 'Postal code'
  },
  Ukraine: {
    countryCode: 'UA',
    label: 'Postal index'
  },
  Moldova: {
    countryCode: 'MD',
    label: 'Postal index'
  },
  Belarus: {
    countryCode: 'BY',
    label: 'Postal index'
  },
  Slovakia: {
    countryCode: 'SK',
    label: 'PSČ'
  },
  'Czech Republic': {
    countryCode: 'CZ',
    label: 'PSČ'
  },
  'United States': {
    countryCode: 'US',
    label: 'ZIP Code'
  },
  Philippines: {
    countryCode: 'PH',
    label: 'ZIP Code'
  }
}
