/**
 * Resets user related data in the `beamer_config` window object.
 * Useful for cleaning up stale user specific data, for example, when logging out.
 * User data sent to Beamer is used for segmentation, see https://www.getbeamer.com/advanced-segmentation-guide
 */
export function resetBeamerConfig() {
  window.beamer_config = {
    ...window.beamer_config,
    user_firstname: undefined,
    user_lastname: undefined,
    user_email: undefined,
    user_id: undefined,
    has_ordered_sample_pack: undefined,
    has_ordered_product: undefined,
    register_priority: undefined,
    register_reason: undefined,
    signed_up_at: undefined,
    has_order_in_progress: undefined,
    has_payment_card_setup: undefined
  }
}
