import { AnimatePresence, motion, Variants } from 'framer-motion'

interface PropTypesInterface {
  stepNumber: number
}

export default function RightPanel({ stepNumber }: PropTypesInterface) {
  return (
    <div className="sticky top-0 hidden h-100vh w-1/2 flex-1 lg:block">
      <AnimatePresence initial={false} exitBeforeEnter>
        {stepNumber <= 1 && <Step1 key={stepNumber} />}
        {stepNumber === 2 && <Step2 key={stepNumber} />}
        {stepNumber === 3 && <Step3 key={stepNumber} />}
        {stepNumber >= 4 && <Step4 key={stepNumber} />}
      </AnimatePresence>
    </div>
  )
}

const ANIMATION_VARIANT_NAMES = {
  inactive: 'inactive',
  active: 'active',
  exit: 'exit'
}

const ANIMATION_VARIANTS: Variants = {
  [ANIMATION_VARIANT_NAMES.inactive]: { opacity: 0, x: 10, transition: { type: 'tween' } },
  [ANIMATION_VARIANT_NAMES.active]: { opacity: 1, x: 0, transition: { type: 'tween' } },
  [ANIMATION_VARIANT_NAMES.exit]: { opacity: 0, x: 0, transition: { type: 'tween' } }
}

function Step1() {
  return (
    <motion.div
      className="h-full w-full"
      initial={ANIMATION_VARIANT_NAMES.inactive}
      animate={ANIMATION_VARIANT_NAMES.active}
      exit={ANIMATION_VARIANT_NAMES.exit}
      variants={ANIMATION_VARIANTS}
    >
      <picture>
        <source srcSet="/img/register/tattoos.webp" type="image/webp" />
        <img
          className="pointer-events-none block h-100vh w-full select-none object-cover object-center"
          alt="Prodigi print on demand Tattoos"
          src="/img/register/tattoos.jpg"
        />
      </picture>

      <div className="absolute left-0 right-0 top-0 m-auto flex max-w-xl flex-col items-center text-center">
        <h2 className="mt-12 text-4xl">Premium products</h2>
        <p className="mt-6">Turn digital images into physical products.</p>
        <p className="mt-6">Sell custom products to every consumer on earth. Print locally, scale globally.</p>
      </div>
    </motion.div>
  )
}

function Step2() {
  return (
    <motion.div
      className="h-full w-full"
      initial={ANIMATION_VARIANT_NAMES.inactive}
      animate={ANIMATION_VARIANT_NAMES.active}
      exit={ANIMATION_VARIANT_NAMES.exit}
      variants={ANIMATION_VARIANTS}
    >
      <picture>
        <source srcSet="/img/register/mug.webp" type="image/webp" />
        <img
          className="pointer-events-none block h-100vh w-full select-none object-cover object-center"
          alt="Prodigi print on demand mug"
          src="/img/register/mug.jpg"
        />
      </picture>

      <div className="absolute left-0 right-0 top-0 m-auto flex max-w-xl flex-col items-center text-center">
        <h2 className="mt-12 text-4xl">Unlimited inventory</h2>
        <p className="mt-6">{`We buy stock so you don't have to.`}</p>
        <p className="mt-6">
          Sell your designs on an extensive range of products. Customise fine art prints, canvases, homeware, apparel
          and more.
        </p>
      </div>
    </motion.div>
  )
}

function Step3() {
  return (
    <motion.div
      className="h-full w-full"
      initial={ANIMATION_VARIANT_NAMES.inactive}
      animate={ANIMATION_VARIANT_NAMES.active}
      exit={ANIMATION_VARIANT_NAMES.exit}
      variants={ANIMATION_VARIANTS}
    >
      <picture>
        <source srcSet="/img/register/hanging-framed-print.webp" type="image/webp" />
        <img
          className="pointer-events-none block h-100vh w-full select-none object-cover object-center"
          alt="Hanging a framed print"
          src="/img/register/hanging-framed-print.jpg"
        />
      </picture>

      <div className="absolute left-0 right-0 top-0 m-auto flex max-w-xl flex-col items-center text-center">
        <h2 className="mt-12 text-4xl">Build a global brand</h2>
        <p className="mt-6">{`"You propelled my business into something I could only dream of running."`}</p>
        <img className="mt-12 w-44" src="/img/icons/five-star.svg" alt="Five stars from a users review" />
        <img className="mt-9 w-[103px]" src="/img/logo/magbox-logo.svg" alt="Magnolia Box" />
      </div>
    </motion.div>
  )
}

function Step4() {
  return (
    <motion.div
      className="h-full w-full"
      initial={ANIMATION_VARIANT_NAMES.inactive}
      animate={ANIMATION_VARIANT_NAMES.active}
      exit={ANIMATION_VARIANT_NAMES.exit}
      variants={ANIMATION_VARIANTS}
    >
      <picture>
        <source srcSet="/img/register/customer-service.webp" type="image/webp" />
        <img
          className="pointer-events-none block h-100vh w-full select-none object-cover object-center"
          alt="Hanging a framed print"
          src="/img/register/customer-service.jpg"
        />
      </picture>

      <div className="absolute left-0 right-0 top-0 m-auto flex max-w-xl flex-col items-center text-center">
        <h2 className="mt-12 text-4xl">Real people, real support</h2>
        <p className="mt-6">We don&apos;t do chatbots.</p>
        <p className="mt-6">
          Our international customer support agents offer 24-hour email support. And you can even contact us via live
          chat.
        </p>
      </div>
    </motion.div>
  )
}
