// @flow
import type { DownloadImageAction, ResetDownloadProgress } from '../types'

export const DOWNLOAD_IMAGE = 'DOWNLOAD_IMAGE'
export const DOWNLOAD_IMAGE_SUCCESS = 'DOWNLOAD_IMAGE_SUCCESS'
export const DOWNLOAD_IMAGE_ERROR = 'DOWNLOAD_IMAGE_ERROR'
export const RESET_DOWNLOAD_PROGRESS = 'RESET_DOWNLOAD_PROGRESS'

export function onDownloadImage (id: string): DownloadImageAction {
  window.analytics.track('Download image')

  return {
    type: DOWNLOAD_IMAGE,
    id
  }
}

export function onDownloadImageSuccess (id: string): DownloadImageAction {
  return {
    type: DOWNLOAD_IMAGE_SUCCESS,
    id
  }
}

export function onDownloadImageError (id: string): DownloadImageAction {
  return {
    type: DOWNLOAD_IMAGE_ERROR,
    id
  }
}

export function resetDownloadProgress (ids: string[]): ResetDownloadProgress {
  return {
    type: RESET_DOWNLOAD_PROGRESS,
    ids
  }
}
