import { EmailPreferences, OrderEditWindowPreferences } from './components'
import { useMerchantPreferences } from '../../../../hooks'
import { SettingsHeader } from '../SettingsHeader.component'
import { SettingsContainer } from '../SettingsContainer.component'
import SupportLink from '../../../SupportLink'

export function SettingsPreferences() {
  const { error } = useMerchantPreferences()

  window.analytics.track('Preferences viewed')
  if (error) {
    return (
      <SettingsContainer>
        <SettingsHeader />
        <div className="mt-8 flex w-full justify-center text-red-500">
          <p className="break-words">
            An error occurred while loading your preferences. Please try again later and{' '}
            <SupportLink className="text-red-500 underline">contact us</SupportLink> if this issue persists (Code{' '}
            {error.status ?? '0'})
          </p>
        </div>
      </SettingsContainer>
    )
  }

  return (
    <SettingsContainer>
      <SettingsHeader />
      <OrderEditWindowPreferences />
      <EmailPreferences />
    </SettingsContainer>
  )
}
