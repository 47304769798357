// @flow
import React, { PureComponent } from 'react'
import Modal from '../Modal'
import Svg from '../svg'
import cx from 'classnames'
import styles from './ModalWithHeader.module.css'

type Props = {|
  title: string,
  children: React$Node,
  close: () => void,
  className?: string,
  dataTest?: string,
|}

export default class ModalWithHeader extends PureComponent <Props> {
  componentDidMount () {
    if (document.body) {
      document.body.style.overflow = 'hidden'
    }
  }

  componentWillUnmount () {
    if (document.body) {
      document.body.style.overflow = 'auto'
    }
  }

  render (): React$Node {
    return (
      <Modal className={cx(styles.container, this.props.className)} close={this.props.close} dataTest={this.props.dataTest}>
        <header className={styles.header} data-test="modal-header">
          {this.props.title && <h2>{this.props.title}</h2>}
          <button className={styles.closeButton} onClick={this.props.close} data-test="close-modal-button">
            <Svg.Close className={styles.closeButtonIcon} />
          </button>
        </header>

        {this.props.children}
      </Modal>
    )
  }
}
