import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import InlineMenu, { InlineMenuOptionInterface } from '../../InlineMenu'
import { useDebouncedUrlUpdate } from '../../../hooks/useDebouncedUrlUpdate.hook'

interface ImageLibraryListActionProps {
  title: string
  options: InlineMenuOptionInterface[]
  queryParam: string
}

export function ImageLibraryListAction({ title, options, queryParam }: ImageLibraryListActionProps) {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const selectedParam = searchParams.get(queryParam)
  const selectedDropdownValues = useMemo(() => (selectedParam ? selectedParam.split(',') : []), [selectedParam])
  const [selectedValues, setSelectedValues] = useState(selectedDropdownValues)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedValues(selectedDropdownValues)
  }, [selectedDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  const updateSelected = (selectedValues: string[]) => {
    setSelectedValues(selectedValues)
    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: queryParam,
      routePath: pathname,
      selectedValues
    })
  }

  return (
    <InlineMenu
      className="max-h-96"
      ariaLabel={title}
      data-test="image-library-actions__-filter"
      multiple={true}
      options={options}
      selected={selectedValues}
      updateSelected={updateSelected}
    />
  )
}
