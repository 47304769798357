// @flow
import React, { PureComponent } from 'react'
import type { FormattedCsvRow, Cost } from '../../../../types'
import CsvOrderDetailsView from './CsvOrderDetailsView'
import { currencies } from '../../../../data/currencies'
import { formatCost } from '../../../../helpers/charge/formatCost'

type Props = {|
  order: FormattedCsvRow,
  canBeEdited: boolean,
  isFetchingShipping: boolean,
  orderTotal: ?Cost,
  currencyCode: string,
  packingSlip: string,
  packingSlipErrorMessage: string,
  isUsSalesTaxAlreadyCollected: boolean,
  toggleUsSalesTax: () => void,
  updateCustomsValue: (index: string, customsValue: number) => void,
  updateMerchantOrderId: (index: string, orderId: string) => void
|}

export default class CsvOrderDetailsController extends PureComponent<Props> {
  onCustomsValueChange: (value: number | string) => void = (value: number | string) => {
    const { order, updateCustomsValue } = this.props

    if (value === '') {
      updateCustomsValue(order.id, 0)
    } else {
      const valueAsInt = Math.round(Number(value) * 100)
      updateCustomsValue(order.id, valueAsInt)
    }
  }

  get customsValueAsFloat(): number {
    const customsValue = this.props.order.customsValue / 100

    return !isNaN(customsValue) ? customsValue : 0
  }

  get currencySymbol(): ?string {
    const { currencyCode } = this.props
    return currencyCode ? currencies[currencyCode].symbol : null
  }

  get formattedTotal(): string {
    return this.props.orderTotal ? formatCost(this.props.orderTotal) : ''
  }

  get canDisplayTotal(): boolean {
    return Boolean(this.props.orderTotal && +this.props.orderTotal.amount >= 0)
  }

  render(): React$Node {
    return (
      <CsvOrderDetailsView
        isUsSalesTaxAlreadyCollected={this.props.isUsSalesTaxAlreadyCollected}
        toggleUsSalesTax={this.props.toggleUsSalesTax}
        packingSlip={this.props.packingSlip}
        order={this.props.order}
        canBeEdited={this.props.canBeEdited}
        isFetchingShipping={this.props.isFetchingShipping}
        canDisplayTotal={this.canDisplayTotal}
        formattedTotal={this.formattedTotal}
        currencySymbol={this.currencySymbol}
        onCustomsValueChange={this.onCustomsValueChange}
        customsValueAsFloat={this.customsValueAsFloat}
        updateMerchantOrderId={this.props.updateMerchantOrderId}
      />
    )
  }
}
