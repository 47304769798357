import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import {
  PACKING_SLIP_ALLOWED_FILE_TYPES,
  PackingSlipDetails,
  PackingSlipLoading,
  UploadPackingSlip
} from '../../PackingSlip'
import { createToast } from '../../Toast'
import { fetcher } from '../../../helpers'
import OverlayPortal from '../../OverlayPortal'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { ImageLibraryImageInterface, useOrderDetail, useSplitToggle } from '../../../hooks'
import { FetchErrorInterface, OrderDetailDataErrorInterface, StatusType } from '../../../interfaces'

const PACKING_SLIP_UPLOAD_ERROR_TOAST_ID = 'packingSlipUploadErrorToast'

export function OrderEditPackingSlip() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  const [savePackingSlipStatus, setSavePackingSlipStatus] = useState<StatusType>('idle')
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    }
  }, [])

  if (!orderDetails) {
    throw Error('No order details')
  }

  async function savePackingSlip(packingSlipUrl?: string) {
    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/packingSlipUrl`, {
        body: JSON.stringify(packingSlipUrl),
        method: 'PUT'
      })
      await mutateOrderDetails()
      setSavePackingSlipStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OrderDetailDataErrorInterface>
      const errorCode = errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorCode})`,
        heading: 'An error occurred while updating packing slip',
        id: PACKING_SLIP_UPLOAD_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setSavePackingSlipStatus('error')
    }
  }

  async function handleUploadPackingUpdate(imageLibraryImage: ImageLibraryImageInterface) {
    setIsImageLibraryModalOpen(false)
    toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    setSavePackingSlipStatus('loading')
    window.analytics.track('Order editing - packing slip added')

    if (isPDFSplitOn && !PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      createToast({
        content: `${
          imageLibraryImage.file_type
        } is not supported for packing slips. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: PACKING_SLIP_UPLOAD_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setSavePackingSlipStatus('error')
      return
    }

    if (!isPDFSplitOn && imageLibraryImage.mime_type !== 'image/png') {
      createToast({
        content: `A packing slip needs to be a png`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: PACKING_SLIP_UPLOAD_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setSavePackingSlipStatus('error')
      return
    }
    window.analytics.track('Added custom packing slip: Order')

    await savePackingSlip(imageLibraryImage.url)
  }

  async function removePackingSlip() {
    toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    setSavePackingSlipStatus('loading')
    await savePackingSlip(undefined)
    window.analytics.track('Removed custom packing slip: Order')
  }

  const isChangeLoading = savePackingSlipStatus === 'loading'

  if (!orderDetails?.packingSlipUrl) {
    return (
      <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
        <UploadPackingSlip
          isImageLibraryModalOpen={isImageLibraryModalOpen}
          isLoading={isChangeLoading}
          onOpenImageLibrary={() => window.analytics.track('Image library opened for packing slip: Edit order')}
          handleUploadPackingUpdate={handleUploadPackingUpdate}
          setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
        />
      </PackingSlipContainer>
    )
  }

  if (isChangeLoading) {
    return (
      <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
        <PackingSlipLoading />
      </PackingSlipContainer>
    )
  }

  return (
    <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
      <PackingSlipDetails
        isLoading={isChangeLoading}
        packingSlip={orderDetails?.packingSlipUrl}
        removePackingSlip={removePackingSlip}
      />
    </PackingSlipContainer>
  )
}

function PackingSlipContainer({
  children,
  savePackingSlipStatus
}: {
  children: React.ReactNode
  savePackingSlipStatus: StatusType
}) {
  return (
    <div className="isolate w-full" data-test="order-edit-packing-slip">
      <div className="flex flex-col border bg-white p-6">
        <h2 className="mt-0 text-lg">Packing slip</h2>

        {children}

        <span className="text-sm text-gray-600">
          Included only for global prints and wall art shipped from UK, EU, USA &amp; AU.
          <br />
          Slips are printed black &amp; white on A4 or US letter.
        </span>
      </div>

      {savePackingSlipStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}
