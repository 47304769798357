// @flow
import type { DispatchFunc, ThunkAsync } from '../../types'
import type { UploadImageFromUrlResult } from '../types'
import { RSAA } from 'redux-api-middleware'
import { selectArtworkPipelineUrl } from '../../selectors/appSettings'
import { mapArtworkServiceResponse, mapArtworkServiceErrorResponse } from './mapArtworkServiceResponse'

export const START_IMAGE_UPLOAD_FROM_URL = 'START_IMAGE_UPLOAD_FROM_URL'
export const FINISH_IMAGE_UPLOAD_FROM_URL = 'FINISH_IMAGE_UPLOAD_FROM_URL'
export const FAILED_IMAGE_UPLOAD_FROM_URL = 'FAILED_IMAGE_UPLOAD_FROM_URL'

export function uploadImageFromUrl (publicArtworkUrl: string, ids: string[]): ThunkAsync<UploadImageFromUrlResult> {
  return async (dispatch: DispatchFunc): Promise<UploadImageFromUrlResult> => {
    return dispatch({
      [RSAA]: {
        endpoint: selectArtworkPipelineUrl() + `/artwork/createFromUrl`,
        method: 'POST',
        body: JSON.stringify({ publicArtworkUrl }),
        headers: {
          'Content-type': 'application/json'
        },
        types: [
          {
            type: START_IMAGE_UPLOAD_FROM_URL,
            meta: { ids }
          },
          {
            type: FINISH_IMAGE_UPLOAD_FROM_URL,
            payload: mapArtworkServiceResponse,
            meta: { ids }
          },
          {
            type: FAILED_IMAGE_UPLOAD_FROM_URL,
            meta: { ids },
            payload: mapArtworkServiceErrorResponse
          }
        ]
      }
    })
  }
}
