// @flow
import React from 'react'

import styles from './AppProducts.module.css'
import ErrorView from '../../../Apps/ErrorView'
import type { MatchAppType } from '../../../../types'
import { getAccessToken } from '../../../../actions/auth'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'

type Props = {|
  appTypeSelected: MatchAppType,
  hasError: boolean
|}

type State = {|
  isLoading: boolean
|}

class AppProducts extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = { isLoading: true }
  }

  onLoad: (() => void) = () => {
    this.setState({ isLoading: false })
  }

  render (): React$Node {
    if (this.props.hasError) {
      return (
        <ErrorView message="An error occurred whilst getting products." />
      )
    }

    const token = getAccessToken()
    const url = `${process.env.REACT_APP_KITE_URL || ''}/login/?token=${token || ''}&app_type=${this.props.appTypeSelected.printEngineType}`
    return (
      <section className={styles.appProductsContainer}>
        {this.state.isLoading && <div className={styles.loadingEllipsis}><LoadingEllipsis /></div>}
        <iframe className={styles.productsFrame} src={url} title="App Products and Pricing" onLoad={this.onLoad}></iframe>
      </section>
    )
  }
}

export default AppProducts
