// @flow
import React from 'react'

import styles from './AddDomain.module.css'
import Stepper from '../../../../components/Stepper'
import AddDomainName from './components/AddDomainName'
import VerifyOwnership from './components/VerifyOwnership'
import { ABORT_ERROR_NAME } from '../../../../data/constants'
import type { AbortController, User } from '../../../../types'
import processFetchResponse from '../../../../helpers/processFetchResponse'
import getDefaultFetchOptions from '../../../../helpers/getDefaultFetchOptions'
import { APP_TYPES, PRINT_ENGINE_PARAMS, PRINT_ENGINE_URL } from '../../../Apps/constants'

const {
  APP_TYPE,
  BRAND_NAME,
  DOMAINS,
  MERCHANT_ID
} = PRINT_ENGINE_PARAMS

type Props = {|
  appId: string,
  appType: string,
  user: User,
  onBack: () => void,
  onFinish: (domainAdded: string) => void
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  currentStep: number,
  domainName: {|
    errorText: string,
    isValid: boolean,
    value: string
  |},
  hasError: boolean,
  isLoadingActionButton: boolean,
  loadingButtonText: string,
  totalSteps: number
|}

class AddDomain extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const controller = new window.AbortController()
    const signal = controller.signal

    this.state = {
      abortController: controller,
      abortSignal: signal,
      currentStep: 1,
      domainName: {
        errorText: '',
        isValid: false,
        value: ''
      },
      hasError: false,
      isLoadingActionButton: false,
      loadingButtonText: 'Saving',
      totalSteps: 2
    }
  }

  handleBack: (() => void) = () => {
    if (this.state.currentStep === 1) {
      this.props.onBack()
    } else {
      this.setState({
        currentStep: this.state.currentStep - 1,
        hasError: false
      })
    }
  }

  handleNext: (() => void) = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
      hasError: false
    })
  }

  handleComplete: (() => void) = () => {
    this.setState({
      hasError: false,
      isLoadingActionButton: true
    })

    const {
      appId,
      appType,
      user
    } = this.props

    const printEngineAppType = APP_TYPES[appType].printEngineType

    const url = `${PRINT_ENGINE_URL}/domain/`
    fetch(url, {
      ...getDefaultFetchOptions(),
      method: 'POST',
      body: JSON.stringify({
        [APP_TYPE]: printEngineAppType,
        [BRAND_NAME]: appId,
        [DOMAINS]: [this.state.domainName.value],
        [MERCHANT_ID]: user.merchantUniqueId
      }),
      signal: this.state.abortSignal
    })
      .then(processFetchResponse)
      .then(() => {
        this.setState({ isLoadingActionButton: false })
        this.props.onFinish(this.state.domainName.value)
      })
      .catch((err) => {
        if (err.name !== ABORT_ERROR_NAME) {
          this.setState({
            hasError: true,
            isLoadingActionButton: false
          })
        }
      })
  }

  domainNameChangeHandler: ((event: SyntheticInputEvent<HTMLInputElement>) => void) = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.value
    const domainNameRegex = /^((?!-)[A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,6}$/
    const isValid = domainNameRegex.test(value)
    const errorText = (isValid || !value || !value.includes('.')) ? '' : 'Please enter a valid domain'
    this.setState({
      domainName: {
        errorText,
        isValid,
        value
      }
    })
  }

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    const {
      currentStep,
      domainName,
      hasError,
      isLoadingActionButton,
      loadingButtonText,
      totalSteps
    } = this.state

    const appHostName = APP_TYPES[this.props.appType].hostName

    const allSteps = [
      {
        actionButtonText: 'Next',
        disableActionButton: !domainName.isValid,
        title: 'Add domain name',
        subTitle: 'This is the domain your app will be served from. Please specify a sub-domain',
        content: <AddDomainName value={domainName.value} errorText={domainName.errorText} handleDomainNameChange={this.domainNameChangeHandler} />
      },
      {
        actionButtonText: 'Finish',
        disableActionButton: false,
        title: 'Verify ownership',
        subTitle: 'Add the CNAME record below to your DNS provider for verifying ownership of domain',
        content: <VerifyOwnership appHostName={appHostName} domainName={domainName.value} />
      }
    ]
    const currentStepIndex = currentStep - 1
    const {
      actionButtonText,
      disableActionButton,
      title,
      subTitle,
      content
    } = allSteps[currentStepIndex]

    return (
      <div className={styles.domainsContainer}>
        <Stepper
          actionButtonText={actionButtonText}
          contentTitle={title}
          contentSubtitle={subTitle}
          currentStep={currentStep}
          disableActionButton={disableActionButton}
          hasError={hasError}
          isLoadingActionButton={isLoadingActionButton}
          loadingActionButtonText={loadingButtonText}
          totalSteps={totalSteps}
          stepperTitle="Add domain"
          onBack={this.handleBack}
          onComplete={this.handleComplete}
          onNext={this.handleNext}>
          {content}
        </Stepper>
      </div>
    )
  }
}

export default AddDomain
