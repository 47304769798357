// @flow
import { getProductTemplates } from '../../selectors/images'
import { getBasketItemById } from '../../selectors/manualOrderForm'
import type {
  PrintAreaArtwork,
  ArtworkTransformations,
  Dictionary,
  DispatchFunc,
  GetStateFunc,
  MultiAssetTemplates,
  ImageEditorTransformations,
  Thunk
} from '../../types'
import { buildPigUrl, calcScaleFactorAdjustment, convertPrintPxPositionToUserUnit } from '../../helpers/imageEditor'
import { entries } from '../../helpers/dictionary'
import { SCALE_TYPE } from '@prodigi-group/components-image-editor'
import type { ImageEditorSaveDataType } from '../../types/images'

export const SAVE_TRANSFORMATIONS = 'SAVE_TRANSFORMATIONS'

export function saveTransformations(
  basketItemId: number,
  updatedArtworkTransformations: Dictionary<?ArtworkTransformations>,
  additionalData: ImageEditorSaveDataType
): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItem = getBasketItemById(getState(), basketItemId)

    if (!basketItem) {
      return
    }

    const templates = getProductTemplates(getState(), basketItem.sku, basketItem.selectedAttributes)

    if (!templates) {
      return
    }

    const croppedImageUrls = buildCroppedImageUrls(basketItem.printAreas, updatedArtworkTransformations, templates)

    dispatch({
      type: SAVE_TRANSFORMATIONS,
      updatedArtworkTransformations,
      basketItemId,
      croppedImageUrls,
      transformDataByPrintArea: additionalData.transformDataByPrintArea
    })
  }
}

export function buildCroppedImageUrls(
  itemPrintAreas: Dictionary<PrintAreaArtwork>,
  updatedArtworkTransformations: Dictionary<?ArtworkTransformations>,
  templates: MultiAssetTemplates
): Dictionary<string> {
  return entries(updatedArtworkTransformations).reduce((pigUrlAcc, [printAreaName, transformations]) => {
    if (!transformations) {
      return pigUrlAcc
    }
    const selectedOrientation = transformations.orientation
    const template = templates.printAreas[printAreaName].orientations[selectedOrientation]
    const artwork = itemPrintAreas[printAreaName].artwork

    if (!template || !artwork) {
      return pigUrlAcc
    }

    const artworkDimensionsInPx = {
      height: artwork.artworkHeight,
      width: artwork.artworkWidth
    }

    const scaleFactorAdjustment = calcScaleFactorAdjustment(
      template.printResolution,
      artworkDimensionsInPx,
      template.cropRectangle,
      template.printDpi,
      transformations.borderFactor,
      transformations.borderScale
    )
    const scaleFactorInPigFriendlyFormat = (transformations.scaleFactor * scaleFactorAdjustment) / 100
    const pigPosition = convertPrintPxPositionToUserUnit(
      transformations.position,
      transformations.borderScale,
      template.printResolution,
      artworkDimensionsInPx,
      template.cropRectangle,
      template.printDpi
    )

    const imageEditorTransformations: ImageEditorTransformations = {
      scaleFactor: scaleFactorInPigFriendlyFormat,
      scaleType: SCALE_TYPE.PRINT_SIZE,
      position: pigPosition,
      positionUnit: transformations.borderScale,
      rotationAngle: transformations.rotationAngle,
      borderWidth: transformations.borderFactor,
      borderUnit: transformations.borderScale,
      isTiled: transformations.isTiled
    }

    const pigUrl = buildPigUrl(
      artwork.originalImageUrl,
      imageEditorTransformations,
      artworkDimensionsInPx,
      template.printResolution,
      template.cropRectangle,
      template.printDpi
    )

    console.log(printAreaName, 'image url:', pigUrl)

    pigUrlAcc[printAreaName] = pigUrl

    return pigUrlAcc
  }, {})
}
