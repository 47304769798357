import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { fetcher, mapV3AttributesToV4 } from '../../../helpers'
import { VariantDataInterface } from '../SalesChannelProduct.component'
import { ProductVariantRepresentationInterface } from '../../../hooks/useSalesChannelProduct.hook'

export async function saveVariantsData({
  merchantId,
  newVariantsData,
  productId,
  salesChannelId
}: {
  merchantId: number | null
  newVariantsData: Record<string, VariantDataInterface>
  productId: string
  salesChannelId: string
}) {
  const payload: ProductVariantRepresentationInterface[] = Object.values(newVariantsData).map((variantData) => {
    return {
      ...variantData,
      artworkData:
        variantData.artworkData && Object.keys(variantData.artworkData).length > 0
          ? Object.keys(variantData.artworkData).reduce((artworkDataAcc: PrintAreasArtworkDataType, printAreaName) => {
              if (!variantData.artworkData?.[printAreaName]?.printImageUrl) {
                return {
                  ...artworkDataAcc,
                  [printAreaName]: null
                }
              }

              return {
                ...artworkDataAcc,
                [printAreaName]: variantData.artworkData[printAreaName]
              }
            }, {})
          : null,
      selectedProductData: variantData.selectedProductData
        ? {
            ...variantData.selectedProductData,
            attributes: variantData?.selectedProductData?.attributes
              ? mapV3AttributesToV4(variantData.selectedProductData.attributes)
              : null
          }
        : undefined
    }
  })

  return fetcher(
    `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/products/merchant/${merchantId}/saleschannel/${salesChannelId}/product/${productId}`,
    {
      body: JSON.stringify(payload),
      method: 'PUT'
    }
  )
}
