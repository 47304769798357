// @flow
import type { ResetImage } from '../types'

export const RESET_IMAGE = 'RESET_IMAGE'

export function resetImage (imageId: string): ResetImage {
  return {
    type: RESET_IMAGE,
    imageId
  }
}
