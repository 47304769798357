// @flow
import cx from 'classnames'
import React, { PureComponent } from 'react'
import Button from '../../../../components/Button'
import styles from './CsvUploadOverview.module.css'
import type { FormattedCsvRow, Dictionary } from '../../../../types'
import WhitePanel from '../../../../components/WhitePanel'
import CsvOrder from '../CsvOrder'
import { values } from '../../../../helpers/dictionary'
import CsvSubmissionSuccess from '../CsvSubmission/CsvSubmissionSuccess'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'
import CsvDescription from './CsvDescription'
import CsvMultiselectFilters from '../CsvMultiselectFilters'
import CsvPaginator from './CsvPaginator'

type Props = {|
  submitCsvLoading: boolean,
  submitCsvError: boolean,
  submitCsvSuccess: boolean,
  canSubmit: boolean,
  submitCsvMsg: string,
  totalOrdersToSubmit: number,
  totalIgnoredOrders: number,
  currencyCode: string,
  isFetchingQuotes: boolean,
  selectedFilter: string,
  totalOrders: number,
  numberOfOrdersWithErrors: number,
  numberOfOrdersRequiringAction: number,
  ordersToDisplay: Dictionary<FormattedCsvRow>,
  totalSubmittedOrders: number,
  totalCostWithoutTax: string,
  isUsSalesTaxCollectedForAllUsOrders: boolean,
  isAnyCsvOrderForUnitedStates: boolean,
  isUsSalesTaxAlreadyCollected: boolean,
  toggleUsSalesTax: () => void,
  resetUpload: () => void,
  changeFilter: (newFilter: string) => void,
  submitCsvOrders: () => void
|}

type State = {|
  pageNumber: number,
|}

export default class CsvUploadOverview extends PureComponent <Props, State> {
  itemsPerPage: number = 20
  state: State = {
    pageNumber: 1
  }

  onPageChange: ((newPageNumber: number) => void) = (newPageNumber: number) => {
    if (newPageNumber < 1 || newPageNumber > this.maxPageNumber) {
      return
    }
    this.setState({ pageNumber: newPageNumber })
  }

  resetPageNumber: (() => void) = () => {
    this.onPageChange(1)
  }

  onChangeFilter: ((newFilter: string) => void) = (newFilter: string) => {
    this.resetPageNumber()
    this.props.changeFilter(newFilter)
  }

  onSubmitOrder: (() => void) = () => {
    this.resetPageNumber()
    this.props.submitCsvOrders()
  }

  get ordersToDisplay (): Array<FormattedCsvRow> {
    return values(this.props.ordersToDisplay)
  }

  get maxPageNumber (): number {
    return Math.ceil(this.ordersToDisplay.length / this.itemsPerPage)
  }

  get ordersToDisplayStartIndex (): number {
    const pageNumberAsIndex = (this.state.pageNumber - 1)
    return pageNumberAsIndex * this.itemsPerPage
  }

  get ordersToDisplayEndIndex (): number {
    return this.ordersToDisplayStartIndex + this.itemsPerPage
  }

  render (): React$Node {
    const { totalOrders, submitCsvLoading, totalOrdersToSubmit, totalIgnoredOrders, selectedFilter } = this.props

    const multipleOrders = totalOrders > 1
    const buttonText = submitCsvLoading
      ? 'In progress'
      : `Submit ${totalOrders - totalIgnoredOrders} order${multipleOrders ? 's' : ''}`

    if (this.props.submitCsvSuccess) {
      return <CsvSubmissionSuccess
        submittedOrderCount={this.props.totalSubmittedOrders}
        resetUpload={this.props.resetUpload}
        totalIgnoredOrders={totalIgnoredOrders}/>
    }

    return (
      <React.Fragment>
        <div className={styles.container}>
          <CsvDescription
            toggleUsSalesTax={this.props.toggleUsSalesTax}
            isAnyCsvOrderForUnitedStates={this.props.isAnyCsvOrderForUnitedStates}
            isUsSalesTaxCollectedForAllUsOrders={this.props.isUsSalesTaxCollectedForAllUsOrders}
            numberOfOrdersWithErrors={this.props.numberOfOrdersWithErrors}
            numberOfOrdersRequiringAction={this.props.numberOfOrdersRequiringAction}
            submitCsvMsg={this.props.submitCsvMsg}
            submittedOrderCount={totalOrdersToSubmit - totalOrders}
            submitCsvError={this.props.submitCsvError}
            submitCsvLoading={submitCsvLoading}
            totalOrders={totalOrders}
            multipleOrders={multipleOrders}
            totalIgnoredOrders={totalIgnoredOrders}
            selectedFilter={selectedFilter}
            changeFilter={this.onChangeFilter} />

          <div className={styles.submitButton} data-test="csv-order-submission-container">
            <div className={styles.orderTotal} data-test="csv-order-total">Order total: </div>{this.props.isFetchingQuotes
              ? <LoadingEllipsis className={styles.loadingEllipsis} />
              : !this.props.canSubmit
                ? 'TBC'
                : this.props.totalCostWithoutTax + ' + taxes'
            }
            <Button
              className={styles.submit}
              loading={submitCsvLoading}
              dataTest='submit-csv-button'
              icon='check'
              disabled={!this.props.canSubmit}
              primary
              onClick={this.onSubmitOrder}>
              {buttonText}
            </Button>
            {!this.props.submitCsvLoading &&
              <Button className={styles.reset} destructive onClick={this.props.resetUpload} dataTest="upload-new-csv">
                Upload new file
              </Button>}
          </div>
        </div>

        {!submitCsvLoading && <CsvMultiselectFilters />}

        {!submitCsvLoading && this.maxPageNumber > 1 &&
          <CsvPaginator
            className={styles.paginator}
            currentPageNumber={this.state.pageNumber}
            maxPageNumber={this.maxPageNumber}
            disableNextButton={this.state.pageNumber >= this.maxPageNumber}
            disablePreviousButton={this.state.pageNumber <= 1}
            onPageChange={this.onPageChange}
          />
        }

        <WhitePanel className={styles.orders}>
          <ul>
            {this.ordersToDisplay.slice(this.ordersToDisplayStartIndex, this.ordersToDisplayEndIndex).map((order, index) => {
              return (
                <li className={cx('csv-list-item', styles.listItem)} key={order.id} data-test={`csv-list-item-${index}`}>
                  <CsvOrder order={order} currencyCode={this.props.currencyCode} />
                </li>
              )
            })}
          </ul>
        </WhitePanel>

        {!submitCsvLoading && this.maxPageNumber > 1 &&
          <CsvPaginator
            className={styles.paginator}
            currentPageNumber={this.state.pageNumber}
            maxPageNumber={this.maxPageNumber}
            disableNextButton={this.state.pageNumber >= this.maxPageNumber}
            disablePreviousButton={this.state.pageNumber <= 1}
            onPageChange={this.onPageChange}
          />
        }
      </React.Fragment>
    )
  }
}
