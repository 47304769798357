// @flow
import { sortApparelBySize } from './sortApparelBySize'
import { DEFAULT_ATTRIBUTE_CATEGORIES } from '../data/productAttributes'

export function getDefaultAttribute (attrName: string, category: string): string {
  const defaultOptions = DEFAULT_ATTRIBUTE_CATEGORIES[category]
  if (defaultOptions && attrName in defaultOptions) {
    return defaultOptions[attrName]
  }
  return 'notFound'
}

export function reorderAttributes (name: string, attr: string[], category: string): string[] {
  const defaultAttr = getDefaultAttribute(name, category)
  const attrExists = attr.find(x => x === defaultAttr)
  name === 'size' ? attr.sort(sortApparelBySize) : attr.sort()

  if (!attrExists) {
    return attr
  }

  attr.splice(attr.findIndex(x => x === defaultAttr), 1)
  attr.unshift(defaultAttr)
  return attr
}
