// @flow
import React from 'react'

import styles from './StripeBanner.module.css'
import LoadingEllipsis from '../LoadingEllipsis'

type Props = {|
  backgroundColor: string,
  bannerText: string,
  ctaText?: string,
  hasError?: boolean,
  isCtaVisible: boolean,
  isLoading?: boolean,
  textColor: string,
  onCtaClick?: () => Promise<void>
|}

export default class StripeBannerSlimView extends React.PureComponent<Props> {
  render (): React$Node {
    const {
      ctaText = '',
      isLoading = false,
      hasError = false,
      onCtaClick = () => {}
    } = this.props
    return (
      <section className={styles.stripeBannerSlim} style={{backgroundColor: this.props.backgroundColor}}>
        <div className={styles.content} style={{color: this.props.textColor}} >
          {this.props.bannerText}
          {this.props.isCtaVisible && <>
            &nbsp;&nbsp;
            <button className={styles.stripeBannerAction} disabled={isLoading} onClick={() => onCtaClick()}>
              <span className={styles.stripeBannerActionText} style={{color: this.props.textColor, boxShadow: `0 2px 0 ${this.props.textColor}`, paddingBottom: '3px'}}>
                {ctaText}
              </span>
            </button>
            {isLoading && (
              <div className={styles.loading}>
                <LoadingEllipsis />
              </div>
            )}
            {hasError && (<>
              &nbsp;&nbsp;
              <span className={styles.stripeBannerError} style={{color: this.props.textColor}}>
              (Something went wrong. Please try again later or contact support)
              </span>
            </>)}
          </>}
        </div>
      </section>
    )
  }
}
