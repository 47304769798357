import { useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'

import { fetcher } from '../../../helpers'
import OverlayPortal from '../../OverlayPortal'
import { ROUTE_PATHS } from '../../../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { IMAGE_LIBRARY_TOASTS } from '../../ImageLibrary'
import { createErrorToast, createToast } from '../../Toast'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface DeleteFromImageLibaryModalPropsInterface {
  imageLibraryImage: ImageLibraryImageInterface
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export function DeleteFromImageLibaryModal({
  imageLibraryImage,
  open,
  setOpen
}: DeleteFromImageLibaryModalPropsInterface) {
  const history = useHistory()
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const [imageDeletionStatus, setImageDeletionStatus] = useState<StatusType>('idle')

  async function handleDeleteImage() {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    setImageDeletionStatus('loading')
    window.analytics.track('Image library - clicked: Delete image')

    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryImage.public_id}`, {
        method: 'DELETE'
      })
      await mutateImageLibraryImages()
      createToast({ heading: 'Image deleted', type: 'success' })
      history.push(ROUTE_PATHS.IMAGE_LIBRARY.INDEX)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>
      createErrorToast({
        heading: 'Failed to delete image',
        id: IMAGE_LIBRARY_TOASTS.ERROR,
        errorMessage: errorResponse.responseBodyJson?.detail,
        errorCode: `${imageLibraryImage.public_id}-${errorResponse.status ?? '0'}`
      })
      setImageDeletionStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setImageDeletionStatus('idle')
      toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    }
    setOpen(isOpen)
  }

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Cancel', theme: 'greyscale', variant: 'tertiary' }}
        closeOnEscape={imageDeletionStatus !== 'loading'}
        closeOnInteractionOutside={imageDeletionStatus !== 'loading'}
        continueButton={{ text: 'Delete image', theme: 'danger' }}
        isLoading={imageDeletionStatus === 'loading'}
        title="Delete image?"
        open={open}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleDeleteImage}
      >
        <div>
          <p>This action cannot be undone.</p>
          <p className="pt-8">Any in-progress orders using this image will not be affected.</p>
        </div>
      </ConfirmationModal>

      {imageDeletionStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
