import { useEffect } from 'react'

import Orders from '../../components/Orders'
import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from 'src/v2/constants'

export default function OrdersPage() {
  useEffect(() => {
    document.title = 'Orders | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.ORDERS, 'Orders List')
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <Orders />
    </main>
  )
}
