// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import type { AppState, DispatchFunc } from '../../types'
import type { ComponentType } from 'react'
import { getMaybeUser } from '../../selectors/user'
import { getRsaaStatus } from '../../selectors/rsaa'
import LoadingIndicator from '../LoadingIndicator'
import { REQUEST_USER_DETAILS, setUpUser } from '../../actions/user'
import { ERROR } from '../../data/rsaa'
import cx from 'classnames'

import styles from './WithUserDetails.module.css'
import { FEATURE_NAMES } from '../../split-io/feature-names'
import { SplitTreatments } from '@splitsoftware/splitio-react'

type DispatchProps = {|
  setupUser: () => void
|}

type StateProps = {|
  hasUserDetails: boolean,
  userRsaaStatus: string
|}

type WithUserDetailsProps = {|
  ...DispatchProps,
  ...StateProps
|}

export default function withUserDetails(WrappedComponent: ComponentType<*>): ComponentType<*> {
  class WithUserDetails extends PureComponent<WithUserDetailsProps> {
    componentDidMount() {
      if (!this.props.hasUserDetails) {
        this.props.setupUser()
      }
    }

    render(): React$Node {
      const { hasUserDetails, userRsaaStatus, ...restProps } = this.props

      if (this.props.hasUserDetails) {
        return (
          <>
            <WrappedComponent {...restProps} />
            {/* <SplitTreatments /> requires `names` prop to evaluate isReady which we use here to show a loading screen until Split is ready */}
            <SplitTreatments names={Object.values(FEATURE_NAMES)}>
              {({ treatments, isReady, hasTimedout }) => {
                const isSplitLoading = !hasTimedout && !isReady
                return isSplitLoading ? (
                  <div className={cx(styles.container, styles.splitLoader)}>
                    <LoadingIndicator />
                  </div>
                ) : null
              }}
            </SplitTreatments>
          </>
        )
      }

      if (this.props.userRsaaStatus === ERROR) {
        throw new Error('An error ocurred while setting up the user session.')
      }

      return (
        <div className={styles.container}>
          <LoadingIndicator />
        </div>
      )
    }
  }

  function mapStateToProps(state: AppState): StateProps {
    return {
      userRsaaStatus: getRsaaStatus(state, REQUEST_USER_DETAILS).status,
      hasUserDetails: Boolean(getMaybeUser(state))
    }
  }

  function mapDispatchToProps(dispatch: DispatchFunc): DispatchProps {
    return {
      setupUser: () => dispatch(setUpUser())
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithUserDetails)
}
