// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Support ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.25 21.5H21.75V19.25C21.75 18.0074 20.7426 17 19.5 17H16.5C15.2574 17 14.25 18.0074 14.25 19.25V21.5H12.75V19.25C12.75 17.1789 14.4289 15.5 16.5 15.5H19.5C21.5711 15.5 23.25 17.1789 23.25 19.25V21.5ZM18 8C19.2426 8 20.25 9.00736 20.25 10.25C20.25 11.4926 19.2426 12.5 18 12.5C16.7574 12.5 15.75 11.4926 15.75 10.25C15.75 9.00736 16.7574 8 18 8ZM18 6.5C15.9289 6.5 14.25 8.17893 14.25 10.25C14.25 12.3211 15.9289 14 18 14C20.0711 14 21.75 12.3211 21.75 10.25C21.75 8.17893 20.0711 6.5 18 6.5ZM11.25 15.5H9.75V13.25C9.75 12.0074 8.74264 11 7.5 11H4.5C3.25736 11 2.25 12.0074 2.25 13.25V15.5H0.75V13.25C0.75 11.1789 2.42893 9.5 4.5 9.5H7.5C9.57107 9.5 11.25 11.1789 11.25 13.25V15.5ZM6 2C7.24264 2 8.25 3.00736 8.25 4.25C8.25 5.49264 7.24264 6.5 6 6.5C4.75736 6.5 3.75 5.49264 3.75 4.25C3.75 3.00736 4.75736 2 6 2ZM6 0.5C3.92893 0.5 2.25 2.17893 2.25 4.25C2.25 6.32107 3.92893 8 6 8C8.07107 8 9.75 6.32107 9.75 4.25C9.75 3.25544 9.35491 2.30161 8.65165 1.59835C7.94839 0.895088 6.99456 0.5 6 0.5Z" fill="#565656"/>
    </svg>
  )
}
