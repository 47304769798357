import { EXPORT_OPTIONS } from '../constants'
import { APP_ENVIRONMENTS } from '../../../constants'

export function getOrdersExportRequestTimestamps(createdSinceDateOption: typeof EXPORT_OPTIONS[number]): {
  createdSince: string
  createdBeforeOrOn: string
} {
  const currentDate = new Date()
  switch (createdSinceDateOption) {
    case 'Previous 7 days': {
      const createdSince = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7, 0, 0, 0, 0)
      ).toJSON()
      const createdBeforeOrOn = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0)
      ).toJSON()
      return { createdBeforeOrOn, createdSince }
    }
    case 'Previous 30 days': {
      const createdSince = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30, 0, 0, 0, 0)
      ).toJSON()
      const createdBeforeOrOn = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0)
      ).toJSON()
      return { createdBeforeOrOn, createdSince }
    }
    case 'Last month': {
      const createdSince = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - 1, 1, 0, 0, 0, 0)
      ).toJSON()
      const createdBeforeOrOn = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0)
      ).toJSON()
      return { createdBeforeOrOn, createdSince }
    }
    case 'Previous 12 months': {
      const createdSince = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - 12, 1, 0, 0, 0, 0)
      ).toJSON()
      const createdBeforeOrOn = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 1, 0, 0, 0, 0)
      ).toJSON()
      return { createdBeforeOrOn, createdSince }
    }
    case 'Previous 24 months': {
      const createdSince = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - 24, 1, 0, 0, 0, 0)
      ).toJSON()
      const createdBeforeOrOn = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0)
      ).toJSON()
      return { createdBeforeOrOn, createdSince }
    }
    case 'Last year': {
      const createdSince = new Date(Date.UTC(currentDate.getFullYear() - 1, 0, 1, 0, 0, 0, 0)).toJSON()
      const createdBeforeOrOn = new Date(Date.UTC(currentDate.getFullYear(), 0, 1, 0, 0, 0, 0)).toJSON()
      return { createdBeforeOrOn, createdSince }
    }
    default: {
      const unhandledCreatedSinceDateOption: never = createdSinceDateOption
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unhandled case for createdSinceDateOption ${unhandledCreatedSinceDateOption}. Add a new case to fix this`
        )
      }
      throw Error('Code: UHCO')
    }
  }
}
