import { useState } from 'react'
import toast from 'react-hot-toast'

import { fetcher } from '../../../helpers'
import { ManageActionsValueType } from '.'
import OverlayPortal from '../../OverlayPortal'
import ConfirmationModal from '../../ConfirmationModal'
import { ImageType } from '../../ImageDetail/components'
import { createToast, createErrorToast } from '../../Toast'
import { IMAGE_LIBRARY_FULL_PAGE_TOASTS } from '../constants'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { ImageLibraryImageInterface, ImageLibraryImageType, useImageLibraryImages } from '../../../hooks'

interface UpdateTypeModalPropsInterface {
  actionModalToOpen: ManageActionsValueType | null
  imageLibraryImages: ImageLibraryImageInterface[]
  onSetActionModalToOpen: (isOpen: ManageActionsValueType | null) => void
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
}

export function UpdateTypeModal({
  actionModalToOpen,
  imageLibraryImages,
  onSetActionModalToOpen,
  onSetImageLibraryMode
}: UpdateTypeModalPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const [imageUpdateStatus, setImageUpdateStatus] = useState<StatusType>('idle')
  const [updatedType, setUpdatedType] = useState<ImageLibraryImageType>('artwork')

  async function handleUpdateImages() {
    toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
    setImageUpdateStatus('loading')
    window.analytics.track('Image library - clicked: Update type')

    try {
      const requests = imageLibraryImages.map((image) =>
        fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${image.public_id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            image_type: updatedType
          })
        })
      )
      const result = await Promise.allSettled(requests)
      await mutateImageLibraryImages()

      const rejectedRequests = result.filter((result) => result.status === 'rejected')

      if (rejectedRequests.length > 0) {
        const errors = rejectedRequests.map((error) => {
          const fetchErrorResponse = error as PromiseRejectedResult
          return fetchErrorResponse?.reason?.status ?? '0'
        })
        createErrorToast({
          errorCode: errors.join(', '),
          heading: 'Failed to update all images',
          id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR
        })
        setImageUpdateStatus('error')
        onSetActionModalToOpen(null)
        onSetImageLibraryMode('select')
        return
      }

      createToast({
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.SUCCESS,
        heading: `Image${requests.length > 1 ? 's' : ''} updated`,
        type: 'success'
      })
      setImageUpdateStatus('success')
      onSetActionModalToOpen(null)
      onSetImageLibraryMode('select')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createErrorToast({
        errorMessage: errorResponse.message,
        heading: 'Failed to update images',
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR,
        errorCode: errorResponse.status
      })
      setImageUpdateStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setImageUpdateStatus('idle')
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.SUCCESS)
    }
    onSetActionModalToOpen(null)
  }

  const headerText =
    imageLibraryImages.length > 1 ? `Change type for ${imageLibraryImages.length} images` : 'Change type for image'

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        closeOnEscape={imageUpdateStatus !== 'loading'}
        closeOnInteractionOutside={imageUpdateStatus !== 'loading'}
        continueButton={{ text: 'Change type' }}
        isLoading={imageUpdateStatus === 'loading'}
        title={headerText}
        open={actionModalToOpen === 'change-type'}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleUpdateImages}
      >
        <div>
          <ImageType
            isLoading={imageUpdateStatus === 'loading'}
            value={updatedType}
            onUpdate={(newValue: ImageLibraryImageType) => setUpdatedType(newValue)}
          />
        </div>
      </ConfirmationModal>

      {imageUpdateStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
