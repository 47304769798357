// @flow
import type { ThunkAsync, DispatchFunc, GetStateFunc, Dictionary, PrintArea, MultiAssetBasketItem } from '../../types'
import { selectBasketItems } from '../../selectors/manualOrderForm'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue'
import { getV4ProductDetails, GET_V4_PRODUCT_DETAILS_SUCCESS } from '../catalogue'
import { updatePrintAreas } from './updatePrintAreas'

export function fetchV4ProductDetailsForAllBasketItems (): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItems = selectBasketItems(getState())

    for (const basketItem of basketItems) {
      const v4ProductDetails = getV4ProductDetailsBySku(getState(), basketItem.sku)
      if (v4ProductDetails) {
        updatePrintAreasForBasketItem(basketItem.id, v4ProductDetails.printAreas)
      } else {
        dispatch(fetchProductDetails(basketItem))
      }
    }
  }
}

function fetchProductDetails (basketItem: MultiAssetBasketItem): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const productDetailsResult = await dispatch(getV4ProductDetails(basketItem.sku))
    if (productDetailsResult?.type === GET_V4_PRODUCT_DETAILS_SUCCESS) {
      dispatch(
        updatePrintAreasForBasketItem(basketItem.id, productDetailsResult.payload.product.printAreas)
      )
    }
  }
}

function updatePrintAreasForBasketItem (basketItemId: number, printAreas: Dictionary<PrintArea>): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const productPrintAreas = Object.keys(printAreas)
    dispatch(updatePrintAreas(basketItemId, productPrintAreas))
  }
}
