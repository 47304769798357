// @flow

import processFetchResponse from '../processFetchResponse'
import getDefaultFetchOptions from '../getDefaultFetchOptions'
import { PAYMENT_SERVICE_URL } from '../../pages/Apps/constants'
import type { CreateStripeAccountResponseType } from '../../types'

export function createStripeConnectAccount (
  { abortSignal, countryCode, email }: {| abortSignal: AbortSignal, countryCode: string, email: string |}
): Promise<CreateStripeAccountResponseType> {
  const url = `${PAYMENT_SERVICE_URL}/payments/stripe/account`
  return fetch(url, {
    ...getDefaultFetchOptions(),
    body: JSON.stringify({
      country: countryCode,
      email
    }),
    method: 'POST',
    signal: abortSignal
  }).then(processFetchResponse)
}
