// @flow
import { entries } from '../dictionary'
import type { V4ProductDetails } from '../../types'

export function getRequiredPrintAreaNames (productDetails: V4ProductDetails): string[] {
  return entries(productDetails.printAreas).reduce(
    (printAreaNameAcc, [printAreaName, printAreaProps]) => {
      return printAreaProps.required
        ? [...printAreaNameAcc, printAreaName]
        : printAreaNameAcc
    }, [])
}
