// @flow

function attributeLabelFormat (attribute: string): string {
  if (attribute === 'maxProductDimensionsMm') {
    return 'Size'
  } else if (attribute === 'productAspectRatio') {
    return 'Aspect Ratio'
  } else if (attribute === 'frameColour') {
    return 'Colour'
  } else if (attribute === 'gender') {
    return 'Category'
  } else {
    const addSpaces = attribute.replace(/([A-Z])/g, ' $1')
    return addSpaces.charAt(0).toUpperCase() + addSpaces.slice(1)
  }
}

export default attributeLabelFormat
