import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { useCallback, useEffect, useState, useMemo } from 'react'

import {
  PaymentAuthProcessing,
  PaymentAuthRequest,
  PaymentError,
  PaymentIntentError,
  PaymentSuccess,
  PaymentIntentProcessing
} from '.'
import { FetchErrorInterface } from '../../../interfaces'
import {
  PaymentRequestSuccessInterface,
  PaymentRequestErrorInterface,
  MappedPaymentIntent,
  StripeError
} from '../interfaces'
import { useUser } from '../../../hooks'
import { createToast } from '../../Toast'
import { fetcher } from '../../../helpers'
import SupportLink from '../../SupportLink'
import { StatusEnum } from '../../../enums'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { selectPwintyApiSettings } from '../../../selectors/appSettings'

export function PaymentAuthContent({ fulfilmentId, orderId }: { fulfilmentId: string; orderId: string }) {
  const stripe = useStripe()
  const abortController = useMemo(() => new window.AbortController(), [])
  const { search } = useLocation()
  const { user } = useUser()

  const [authorisePaymentStatus, setAuthorisePaymentStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [paymentIntentStatus, setPaymentIntentStatus] = useState<'loading' | 'success' | 'error'>('loading')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [paymentIntentData, setPaymentIntentData] = useState<MappedPaymentIntent | null>(null)
  const [toastId, setToastId] = useState<string | undefined>()

  let orderDetailLink = ROUTE_PATHS.ORDER_DETAIL(orderId) + `?${QUERY_PARAMS.ORDER_DETAIL.SUCCESS}=true`
  const prevQueryParamValue = new URLSearchParams(search).get(QUERY_PARAMS.ORDER_DETAIL.PREV)
  if (prevQueryParamValue) {
    orderDetailLink += `&${QUERY_PARAMS.ORDER_DETAIL.PREV}=${encodeURIComponent(prevQueryParamValue)}`
  }

  useEffect(() => {
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [toastId])

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [abortController])

  const fetchPaymentIntent = useCallback(async () => {
    await fetcher<PaymentRequestSuccessInterface>(
      `${selectPwintyApiSettings().endpoint}/dashboard/paymentrequest?entityId=${fulfilmentId}&entityType=order`,
      { signal: abortController.signal }
    )
      .then((res) => {
        const response = res.data
        setPaymentIntentData({
          clientSecret: response.clientSecret,
          cost: {
            amount: response.amount.toString(),
            currencyCode: response.currency
          },
          paymentMethodId: response.paymentMethodId
        })
        setPaymentIntentStatus('success')
      })
      .catch((err) => {
        const errorResponse = err as FetchErrorInterface<PaymentRequestErrorInterface>
        setPaymentIntentStatus('error')
        setErrorMessage(errorResponse.message)
      })
  }, [fulfilmentId, abortController])

  useEffect(() => {
    fetchPaymentIntent()
  }, [fetchPaymentIntent])

  async function authorisePayment() {
    if (toastId) {
      toast.dismiss(toastId)
    }
    let newToastId

    if (!paymentIntentData) {
      newToastId = createToastError('HB-AP-1')
      console.error('Cannot authorise payment - "paymentIntentData" is missing.')
      return
    }

    if (!stripe) {
      newToastId = createToastError('HB-AP-2')
      console.error('Cannot authorise payment - stripe is missing.')
      return
    }

    setToastId(newToastId)
    setAuthorisePaymentStatus(StatusEnum.Loading)

    try {
      if (!user?.billing.hasPaymentSetup) {
        throw Error('Please setup a payment method')
      }

      const result = await stripe.confirmPayment({
        clientSecret: paymentIntentData.clientSecret,
        confirmParams: {
          payment_method: paymentIntentData.paymentMethodId,
          return_url: window.location.origin + ROUTE_PATHS.ORDER_DETAIL(orderId) + window.location.search
        }
      })
      if (result.error) {
        throw result.error
      }
    } catch (error) {
      const errorResponse = error as StripeError
      setAuthorisePaymentStatus(StatusEnum.Error)
      setErrorMessage(errorResponse.message)
    }
  }

  function retryPayment() {
    setPaymentIntentStatus('loading')
    fetchPaymentIntent()
  }

  if (paymentIntentStatus === 'loading') {
    return <PaymentIntentProcessing />
  }

  if (authorisePaymentStatus === 'loading') {
    return <PaymentAuthProcessing />
  }

  if (authorisePaymentStatus === 'error') {
    return <PaymentError errorMessage={errorMessage} orderDetailLink={orderDetailLink} />
  }

  if (!paymentIntentData || paymentIntentStatus === 'error') {
    return <PaymentIntentError orderDetailLink={orderDetailLink} retryPayment={retryPayment} />
  }

  if (authorisePaymentStatus === 'success') {
    return <PaymentSuccess orderDetailLink={orderDetailLink} />
  }

  return (
    <PaymentAuthRequest
      cost={paymentIntentData.cost}
      orderDetailLink={orderDetailLink}
      orderId={orderId}
      authorisePayment={authorisePayment}
    />
  )
}

function createToastError(code: string) {
  return createToast({
    content: (
      <>
        An error occurred while. Please <SupportLink>contact support</SupportLink> if this continues to happen.
      </>
    ),
    duration: 10000,
    footer: `Error code: ${code}`,
    type: 'error-with-close',
    heading: 'An error occured'
  })
}
