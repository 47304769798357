import { OrderDetailIssueEnum } from '../../../enums'
import { formatToSentenceCase } from '../../../helpers'
import { splitByPascalCase } from '../../../helpers/splitByPascalCase.helper'

function formatOrderIssue(issue: OrderDetailIssueEnum) {
  switch (issue) {
    case OrderDetailIssueEnum.PaymentRequiresAuthorisation:
      return 'Payment requires authorisation'
    case OrderDetailIssueEnum.DamagedOrder:
      return 'Damaged order'
    case OrderDetailIssueEnum.WrongFrameColour:
      return 'Wrong frame colour'
    case OrderDetailIssueEnum.IncompleteOrder:
      return 'Incomplete order'
    case OrderDetailIssueEnum.LostInPost:
      return 'Lost in post'
    case OrderDetailIssueEnum.IncorrectOrientation:
      return 'Incorrect orientation'
    case OrderDetailIssueEnum.IncorrectPrints:
      return 'Incorrect prints'
    case OrderDetailIssueEnum.PrintDefects:
      return 'Print defects'
    case OrderDetailIssueEnum.SlowArrival:
      return 'Slow arrival'
    case OrderDetailIssueEnum.SlowDispatch:
      return 'Slow dispatch'
    case OrderDetailIssueEnum.SubmissionErrors:
      return 'Submission errors'
    case OrderDetailIssueEnum.WrongAddress:
      return 'Wrong address'
    case OrderDetailIssueEnum.CustomerOrderError:
      return 'Customer order error'
    case OrderDetailIssueEnum.Unspecified:
      return 'Unspecified'
    default:
      const valueSplitByPascalCase = splitByPascalCase(issue)
      return formatToSentenceCase(valueSplitByPascalCase)
  }
}

export default function generateIssueFlagTitle(issues: OrderDetailIssueEnum[]) {
  const formattedIssues = issues.map(formatOrderIssue)
  return formattedIssues.join(', ')
}
