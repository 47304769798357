// @flow

import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import type { SetNewBasketItemId } from '../types'
import { selectLastBasketItemId } from '../../selectors/manualOrderForm'

export const SET_NEW_BASKET_ITEM_ID = 'SET_NEW_BASKET_ITEM_ID'

export function setNewBasketItemId (): Thunk<SetNewBasketItemId> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const lastId = selectLastBasketItemId(getState())
    return dispatch({
      type: SET_NEW_BASKET_ITEM_ID,
      id: lastId + 1
    })
  }
}
