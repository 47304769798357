// @flow
import type { Name } from '../types'

export function splitFullNameIntoFirstAndLastName (fullName: string): Name {
  const nameParts = fullName.split(' ')

  return {
    firstName: nameParts.shift(),
    lastName: nameParts.join(' ')
  }
}
