// @flow
import { RSAA } from 'redux-api-middleware'
import type { StripeShape } from 'react-stripe-elements'
import { fetchUserDetails } from './user/fetchUserDetails'
import type { PaymentIntent, ThunkAsync, Thunk, DispatchFunc, GetStateFunc } from '../types'
import { selectPwintyApiSettings } from '../selectors/appSettings'
import { LOG_STATUS } from '../data/rsaa'
import { resetRsaaStatus } from './rsaa/rsaa'
import { getUser } from '../selectors/user'

export const CREATE_STRIPE_SETUP_INTENT = 'CREATE_STRIPE_SETUP_INTENT'
export const STRIPE_SETUP_INTENT_SUCCESS = 'STRIPE_SETUP_INTENT_SUCCESS'
export const STRIPE_SETUP_INTENT_ERROR = 'STRIPE_SETUP_INTENT_ERROR'
export const SUBMIT_CARD_TO_STRIPE = 'SUBMIT_CARD_TO_STRIPE'
export const SUBMIT_CARD_SUCCESS = 'SUBMIT_CARD_SUCCESS'
export const SUBMIT_CARD_ERROR = 'SUBMIT_CARD_ERROR'
export const SUBMIT_PAYMENT_METHOD_ID = 'SUBMIT_PAYMENT_METHOD_ID'
export const PAYMENT_METHOD_ID_SUBMITTED = 'PAYMENT_METHOD_ID_SUBMITTED'
export const PAYMENT_METHOD_ID_SUBMISSION_ERROR = 'PAYMENT_METHOD_ID_SUBMISSION_ERROR'
export const TAKE_STRIPE_PAYMENT = 'TAKE_STRIPE_PAYMENT'
export const STRIPE_PAYMENT_ERROR = 'STRIPE_PAYMENT_ERROR'
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS'
export const FETCH_PAYMENT_INTENT = 'FETCH_PAYMENT_INTENT'
export const PAYMENT_INTENT_ERROR = 'PAYMENT_INTENT_ERROR'
export const PAYMENT_INTENT_SUCCESS = 'PAYMENT_INTENT_SUCCESS'
export const REMOVE_PAYMENT_DETAILS = 'REMOVE_PAYMENT_DETAILS'
export const PAYMENT_DETAILS_REMOVED = 'PAYMENT_DETAILS_REMOVED'
export const REMOVE_PAYMENT_DETAILS_FAILED = 'REMOVE_PAYMENT_DETAILS_FAILED'
export const UPDATE_MERCHANT_BILLING_DETAILS = 'UPDATE_MERCHANT_BILLING_DETAILS'
export const UPDATE_MERCHANT_BILLING_DETAILS_SUCCESS = 'UPDATE_MERCHANT_BILLING_DETAILS_SUCCESS'
export const UPDATE_MERCHANT_BILLING_DETAILS_ERROR = 'UPDATE_MERCHANT_BILLING_DETAILS_ERROR'

export const ACTION_TYPES__TAKE_STRIPE_PAYMENT = [TAKE_STRIPE_PAYMENT, STRIPE_PAYMENT_SUCCESS, STRIPE_PAYMENT_ERROR]

export const ACTION_TYPES__SUBMIT_CARD_TO_STRIPE = [SUBMIT_CARD_TO_STRIPE, SUBMIT_CARD_SUCCESS, SUBMIT_CARD_ERROR]

type PaymentIntentResponseBody = {|
  id: number,
  amount: number,
  currency: string,
  clientSecret: string,
  paymentMethodId: string,
  paymentEntityId: number,
  paymentEntityType: string,
  merchantId: number,
  status: number
|}

type PaymentIntentRsaaResult = {|
  payload: {|
    data: PaymentIntentResponseBody,
    statusTxt: string,
    statusCode: number,
    debugDetails: ?string
  |},
  type: string
|}

function isPaymentIntentResultValid(paymentIntentResult: PaymentIntentRsaaResult): boolean {
  return (
    paymentIntentResult.type === PAYMENT_INTENT_SUCCESS &&
    Boolean(paymentIntentResult.payload) &&
    Boolean(paymentIntentResult.payload.data)
  )
}

export function mapPaymentIntentResponse(intentResponse: PaymentIntentResponseBody): PaymentIntent {
  return {
    amount: intentResponse.amount,
    currency: intentResponse.currency,
    paymentEntityType: intentResponse.paymentEntityType.toLowerCase(),
    paymentEntityId: intentResponse.paymentEntityId,
    paymentMethodId: intentResponse.paymentMethodId,
    paymentIntentSecret: intentResponse.clientSecret
  }
}

export function fetchPaymentIntentByEntityId(entityId: string, entityType: string): Thunk<?PaymentIntent> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const paymentIntentEndpoint = `/dashboard/paymentrequest?entityId=${entityId}&entityType=${entityType}`

    return dispatch(fetchPaymentIntent(paymentIntentEndpoint))
  }
}

export function fetchPaymentIntentByPaymentId(paymentId: string): Thunk<?PaymentIntent> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const paymentIntentEndpoint = `/dashboard/paymentrequest/${paymentId}`

    return dispatch(fetchPaymentIntent(paymentIntentEndpoint))
  }
}

function fetchPaymentIntent(paymentIntentEndpoint: string): ThunkAsync<?PaymentIntent> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const apiEndpoint = selectPwintyApiSettings().endpoint

    const result = await dispatch({
      [RSAA]: {
        endpoint: apiEndpoint + paymentIntentEndpoint,
        method: 'GET',
        types: [FETCH_PAYMENT_INTENT, PAYMENT_INTENT_SUCCESS, PAYMENT_INTENT_ERROR]
      }
    })

    if (isPaymentIntentResultValid(result)) {
      return mapPaymentIntentResponse(result.payload.data)
    }
    return null
  }
}

export function fulfillStripePaymentIntent(
  stripe: StripeShape,
  paymentIntentSecret: string,
  paymentMethodId: string
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    try {
      dispatch(takeStripePayment())
      const result = await stripe.handleCardPayment(paymentIntentSecret, { payment_method: paymentMethodId })

      if (result && result.error) {
        dispatch(onStripePaymentError(result.error.message))
      } else if (result) {
        dispatch(onStripePaymentSuccess())
      }
    } catch (err) {
      dispatch(onStripePaymentError(err.message))
    }
  }
}

function takeStripePayment() {
  return {
    type: TAKE_STRIPE_PAYMENT
  }
}

function onStripePaymentError(message: string) {
  return {
    type: STRIPE_PAYMENT_ERROR,
    message
  }
}

function onStripePaymentSuccess() {
  return {
    type: STRIPE_PAYMENT_SUCCESS
  }
}

export function setupCard({
  stripe,
  setupIntentId,
  cardElement,
  isCaptureBillingAddressOn,
  billingDetails,
  postcode
}: {|
  stripe: StripeShape,
  setupIntentId: string,
  cardElement: any,
  isCaptureBillingAddressOn: boolean,
  billingDetails: {|
    cardholderName: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    country: string
  |},
  postcode: string
|}): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    try {
      dispatch(submitCardToStripe())
      const user = getUser(getState())
      const { email } = user
      const { cardholderName, addressLine1, addressLine2, city, country } = billingDetails

      const result = await stripe.confirmCardSetup(setupIntentId, {
        payment_method: {
          card: cardElement,
          billing_details: isCaptureBillingAddressOn
            ? {
                address: {
                  city,
                  country,
                  line1: addressLine1,
                  line2: addressLine2
                },
                email,
                name: cardholderName
              }
            : undefined
        }
      })
      if (result.setupIntent) {
        dispatch(onSubmitCardToStripeSuccess())
        if (isCaptureBillingAddressOn) {
          dispatch(
            updateMerchantBillingDetails({
              cardholderName,
              email,
              addressLine1,
              addressLine2,
              city,
              country,
              postcode,
              paymentMethodId: result.setupIntent.payment_method
            })
          )
        } else {
          dispatch(submitPaymentMethodId(result.setupIntent.payment_method))
        }
      } else {
        dispatch(onSubmitCardToStripeError(result.error.message))
      }
    } catch (err) {
      dispatch(onSubmitCardToStripeError(err.message))
    }
  }
}

export function submitCardToStripe(): {| type: 'SUBMIT_CARD_TO_STRIPE' |} {
  return {
    type: SUBMIT_CARD_TO_STRIPE
  }
}

export function onSubmitCardToStripeError(message: string): {| type: 'SUBMIT_CARD_ERROR', message: string |} {
  return {
    type: SUBMIT_CARD_ERROR,
    message
  }
}

export function onSubmitCardToStripeSuccess(): {| type: 'SUBMIT_CARD_SUCCESS' |} {
  return {
    type: SUBMIT_CARD_SUCCESS
  }
}

export function submitPaymentMethodId(paymentMethodId: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint

    const result = await dispatch({
      [RSAA]: {
        headers: { 'Content-Type': 'application/json' },
        endpoint: endpoint + '/dashboard/merchant/updatepayment',
        method: 'POST',
        body: JSON.stringify({ paymentMethodId }),
        types: [
          { type: SUBMIT_PAYMENT_METHOD_ID, meta: { enhancements: [LOG_STATUS] } },
          PAYMENT_METHOD_ID_SUBMITTED,
          PAYMENT_METHOD_ID_SUBMISSION_ERROR
        ]
      }
    })

    if (result.type === PAYMENT_METHOD_ID_SUBMITTED) {
      dispatch(fetchUserDetails())
      dispatch(resetRsaaStatus(SUBMIT_PAYMENT_METHOD_ID))
    }
  }
}

export function updateMerchantBillingDetails({
  cardholderName,
  email,
  addressLine1,
  addressLine2,
  city,
  country,
  postcode,
  paymentMethodId
}: {|
  cardholderName: string,
  email: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  country: string,
  postcode: string,
  paymentMethodId: string
|}): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint

    const result = await dispatch({
      [RSAA]: {
        headers: { 'Content-Type': 'application/json' },
        endpoint: endpoint + '/dashboard/merchant/updatepaymentandbillingdetails',
        method: 'POST',
        body: JSON.stringify({
          Name: cardholderName,
          Email: email,
          Address: {
            Line1: addressLine1,
            Line2: addressLine2,
            City: city,
            Country: country,
            Postcode: postcode
          },
          PaymentMethodId: paymentMethodId
        }),
        types: [
          { type: UPDATE_MERCHANT_BILLING_DETAILS, meta: { enhancements: [LOG_STATUS] } },
          UPDATE_MERCHANT_BILLING_DETAILS_SUCCESS,
          UPDATE_MERCHANT_BILLING_DETAILS_ERROR
        ]
      }
    })

    if (result.type === UPDATE_MERCHANT_BILLING_DETAILS_SUCCESS) {
      dispatch(fetchUserDetails())
      dispatch(resetRsaaStatus(UPDATE_MERCHANT_BILLING_DETAILS))
    }
  }
}

export function createStripeSetupIntent(stripe: StripeShape): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint

    const setupIntentResult = await dispatch({
      [RSAA]: {
        endpoint: endpoint + '/dashboard/merchant/requeststripecardsetupintent',
        method: 'GET',
        types: [
          { type: CREATE_STRIPE_SETUP_INTENT, meta: { enhancements: [LOG_STATUS] } },
          STRIPE_SETUP_INTENT_SUCCESS,
          STRIPE_SETUP_INTENT_ERROR
        ]
      }
    })

    return setupIntentResult
  }
}

export function removePaymentDetails(): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint

    const result = await dispatch({
      [RSAA]: {
        headers: { 'Content-Type': 'application/json' },
        endpoint: endpoint + '/dashboard/merchant/removepayment',
        method: 'POST',
        types: [
          { type: REMOVE_PAYMENT_DETAILS, meta: { enhancements: [LOG_STATUS] } },
          PAYMENT_DETAILS_REMOVED,
          REMOVE_PAYMENT_DETAILS_FAILED
        ]
      }
    })

    if (!result.error) {
      dispatch(fetchUserDetails())
    }
  }
}
