import { PlusIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from '../../../constants'
import { OrdersExport } from './OrdersExport.component'
import { FEATURE_NAMES } from 'src/split-io/feature-names'
import { useSplitToggle } from 'src/v2/hooks'

export function OrdersHeader() {
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })

  return (
    <header className="flex items-center gap-3 md:items-start">
      <h1 className="mt-0 mr-auto" data-test="orders-page-header">
        Orders
      </h1>

      <OrdersExport />

      {!isShopifySplitOn && (
        <Link className="btn btn-primary md:m-win" to={ROUTE_PATHS.CREATE_ORDER}>
          <PlusIcon className="mr-2 hidden w-7 md:block" />
          Create order
        </Link>
      )}
    </header>
  )
}
