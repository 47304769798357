// @flow
import type { DispatchFunc, GetStateFunc, FormattedOrderItem, Dictionary, Thunk } from '../../types'
import { getProductBySku } from '../../selectors/csvUpload'
import getLabImage from '../../helpers/getLabImage'
import { values } from '../../helpers/dictionary'
import { updateCsvItemUrl } from './uploadArtwork'
import { PRINT_AREA_NAME } from '../../data'

export function addDummyImagesForNonImageProducts(orderItems: Dictionary<FormattedOrderItem>): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    values(orderItems).forEach((orderItem) => {
      const productDetails = getProductBySku(getState(), orderItem.sku)

      if (!productDetails || !productDetails.noImageProduct) {
        return
      }

      const colour = orderItem.selectedAttributes.frameColour
      const imageUrl = getLabImage(colour, productDetails.productType)

      if (!imageUrl) {
        return
      }

      dispatch(updateCsvItemUrl(imageUrl, orderItem.id, PRINT_AREA_NAME.DEFAULT, imageUrl))
    })
  }
}
