// @flow

import type { Thunk, DispatchFunc, GetStateFunc } from '../../types'
import type { ChangeSelectedPrintAreaForBasketItem } from '../types/manualOrderForm'

export const CHANGE_SELECTED_PRINT_AREA_FOR_BASKET_ITEM = 'CHANGE_SELECTED_PRINT_AREA_FOR_BASKET_ITEM'

export function changeSelectedPrintAreaForBasketItem (basketItemId: string, selectedPrintArea: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const changeSelectedPrintArea: ChangeSelectedPrintAreaForBasketItem = {
      type: CHANGE_SELECTED_PRINT_AREA_FOR_BASKET_ITEM,
      basketItemId,
      selectedPrintArea
    }
    dispatch(changeSelectedPrintArea)
  }
}
