// @flow
export const ETSY_CSV_HEADERS = [
  'Sale Date',
  'Item Name',
  'Buyer',
  'Quantity',
  'Price',
  'Coupon Code',
  'Coupon Details',
  'Discount Amount',
  'Delivery Discount',
  'Order Delivery',
  'Order Sales Tax',
  'Item Total',
  'Currency',
  'Transaction ID',
  'Listing ID',
  'Date Paid',
  'Date Posted',
  'Delivery Name',
  'Delivery Address1',
  'Delivery Address2',
  'Delivery City',
  'Delivery State',
  'Delivery Zipcode',
  'Delivery Country',
  'Order ID',
  'Variations',
  'Order Type',
  'Listings Type',
  'Payment Type',
  'InPerson Discount',
  'InPerson Location',
  'VAT Paid by Buyer',
  'SKU'
]

export const ETSY_SECOND_FORMAT_CSV_HEADERS = [
  'Sale Date',
  'Order ID',
  'Buyer User ID',
  'Full Name',
  'First Name',
  'Last Name',
  'Number of Items',
  'Payment Method',
  'Date Posted',
  'Street 1',
  'Street 2',
  'Delivery City',
  'Delivery State',
  'Delivery Zipcode',
  'Delivery Country',
  'Currency',
  'Order Value',
  'Coupon Code',
  'Coupon Details',
  'Discount Amount',
  'Delivery Discount',
  'Delivery',
  'Sales tax',
  'Order total',
  'Status',
  'Card Processing Fees',
  'Order Net',
  'Adjusted Order Total',
  'Adjusted Card Processing Fees',
  'Adjusted Net Order Amount',
  'Buyer',
  'Order Type',
  'Payment Type',
  'InPerson Discount',
  'InPerson Location',
  'SKU'
]

export const ETSY_CSV_HEADER_FORMATS = [
  ETSY_CSV_HEADERS,
  ETSY_SECOND_FORMAT_CSV_HEADERS
]
