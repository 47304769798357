import { twMerge } from 'tailwind-merge'
import * as Popover from '@radix-ui/react-popover'
import { useState, useRef, ReactNode } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { motion, AnimatePresence } from 'framer-motion'

import { PORTAL_ID } from '../../constants/portalId.const'
import InlineMenu, { InlineMenuOptionInterface } from '../InlineMenu'

interface SingleSelectDropdownPropsInterface {
  align?: 'start' | 'center' | 'end'
  className?: string
  dataTest?: string
  disabled?: boolean
  dropdownName: string
  triggerContent?: ReactNode
  options: InlineMenuOptionInterface[]
  selected?: string
  updateSelected: (selected: string) => void
}

export default function SingleSelectDropdown({
  align = 'start',
  className = '',
  dataTest = 'singleselect-dropdown__button',
  disabled = false,
  dropdownName,
  triggerContent,
  options,
  selected,
  updateSelected
}: SingleSelectDropdownPropsInterface) {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isAnimatingContent, setIsAnimatingContent] = useState(false)

  return (
    <div className="h-full w-full text-input sm:text-base" data-test="singleselect-dropdown__container" ref={parentRef}>
      <Popover.Root
        onOpenChange={(open) => {
          setIsOpen(open)
        }}
      >
        <Popover.Trigger
          className={twMerge(
            'relative w-full cursor-pointer truncate rounded-lg border border-gray-200 bg-white py-2 px-3 text-left text-lg outline-offset-1 outline-purple-500 sm:text-base',
            className
          )}
          disabled={disabled}
          data-test={dataTest}
          ref={triggerRef}
        >
          {triggerContent ?? (
            <>
              <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                <ChevronUpDownIcon aria-hidden="true" className="h-7 w-7 text-gray-400" />
              </span>
              {dropdownName ? (
                <span className="ml-8 select-none" data-test="singleselect-dropdown__button-text">
                  {dropdownName}
                </span>
              ) : null}
            </>
          )}
        </Popover.Trigger>

        <AnimatePresence>
          {isOpen && (
            <Popover.Portal container={document.getElementById(PORTAL_ID)} forceMount key={dropdownName}>
              <Popover.Content align={align} asChild style={{ perspective: '2000px' }}>
                <motion.div
                  animate="open"
                  className={`mt-1 max-h-96 overflow-auto rounded-lg border border-gray-200 bg-white shadow-lg radix-side-bottom:origin-[center_-45%] radix-side-top:origin-[center_45%]`}
                  data-test="singleselect-dropdown__options"
                  exit="close"
                  initial="close"
                  style={{
                    minWidth: parentRef?.current?.clientWidth ?? '240px'
                  }}
                  transition={{ duration: 0.15 }}
                  variants={{ close: { opacity: 0, rotateX: '-15deg' }, open: { opacity: 1, rotateX: '0deg' } }}
                  onAnimationComplete={() => {
                    setIsAnimatingContent(false)
                  }}
                >
                  <InlineMenu
                    ariaLabel={dropdownName}
                    // Prevents unwanted clicks on options while animating
                    className={`${isAnimatingContent ? 'pointer-events-none' : ''}`}
                    options={options}
                    selected={selected ?? null}
                    onChange={() => {
                      setIsAnimatingContent(true)
                    }}
                    updateSelected={(selected) => {
                      updateSelected(selected)
                      triggerRef.current?.click()
                    }}
                  />
                </motion.div>
              </Popover.Content>
            </Popover.Portal>
          )}
        </AnimatePresence>
      </Popover.Root>
    </div>
  )
}
