import { ImageLibraryImageType } from '../../../hooks'

export function formatFileTypeFilter(typeValues: string, isBrandingSettingsOn: boolean) {
  return (typeValues.split(',') as ImageLibraryImageType[])
    .map((value) => formatImageLibraryTypeName(value, isBrandingSettingsOn))
    .join(', ')
}

function formatImageLibraryTypeName(type: ImageLibraryImageType, isBrandingSettingsOn: boolean) {
  switch (type) {
    case 'artwork':
      return 'Artwork'
    case 'packing':
      return isBrandingSettingsOn ? 'Insert' : 'Packing slip'
    case 'book':
      return 'Book'
    default:
      return type
  }
}
