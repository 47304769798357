// @flow
import {
  MAPPED_CSV_HEADERS,
  SQUARESPACE_IGNORED_HEADERS,
  SQUARESPACE,
  SHOPIFY,
  UNKNOWN_FORMAT,
  SQUARESPACE_DESCRIPTIONS,
  SHOPIFY_IGNORED_HEADERS
} from '../../data/csvUpload'
import type { Dictionary, CsvFileType } from '../../types'

export function mapHeaders (headers: string[], fileType: CsvFileType): Dictionary<string> {
  const mappedHeaders = {}
  const ignoredHeadersByFormat = getIgnoredHeadersByFormat(fileType, headers)

  for (let i = 0; i < headers.length; i++) {
    const header = headers[i]

    if (ignoredHeadersByFormat.includes(header)) {
      continue
    }

    const mappedHeaderIndex = Object.keys(MAPPED_CSV_HEADERS).find(heading => {
      const formattedArrayOfHeaders = formatArray(MAPPED_CSV_HEADERS[heading])
      const formattedCsvHeader = formatHeader(header)

      return formattedArrayOfHeaders.includes(formattedCsvHeader)
    })

    if (mappedHeaderIndex) {
      mappedHeaders[header] = mappedHeaderIndex
    }
  }

  return mappedHeaders
}

export function formatArray (headers: string[]): string[] {
  return headers.map(header => formatHeader(header))
}

export function formatHeader (header: string): string {
  return header.toLowerCase().replace(/\s+/g, '')
}

function getIgnoredHeadersByFormat (fileType: string, headers: string[]): string[] {
  const isSquarespaceOrder = fileType === SQUARESPACE ||
    (fileType === UNKNOWN_FORMAT && SQUARESPACE_DESCRIPTIONS.every(header => headers.includes(header)))

  if (isSquarespaceOrder) {
    return SQUARESPACE_IGNORED_HEADERS
  } else if (fileType === SHOPIFY) {
    return SHOPIFY_IGNORED_HEADERS
  }

  return []
}
