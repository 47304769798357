import { motion } from 'framer-motion'
import { FormEventHandler, useLayoutEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import ToggleHidePasswordButton from '../../../../components/inputs/ToggleHidePasswordButton'
import Button from '../../Button'
import TextField from '../../TextField'

interface LeftPanelPropsInterface {
  autoFocusFormField: boolean
  email: string
  emailErrorMessage: string | null
  errorMessage?: string
  loading: boolean
  password: string
  onSubmitLogin: FormEventHandler<HTMLFormElement>
  setEmail: (val: string) => void
  setPassword: (val: string) => void
}

function LeftPanel({
  autoFocusFormField,
  email,
  emailErrorMessage,
  errorMessage,
  loading,
  password,
  onSubmitLogin,
  setEmail,
  setPassword
}: LeftPanelPropsInterface) {
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitButtonValidationEnabled, setIsSubmitButtonValidationEnabled] = useState(false)

  useLayoutEffect(() => {
    if (autoFocusFormField && emailInputRef.current) {
      emailInputRef.current.focus()
      setIsSubmitButtonValidationEnabled(false)
    } else if (!autoFocusFormField) {
      setIsSubmitButtonValidationEnabled(true)
    }
  }, [autoFocusFormField, emailInputRef])

  return (
    <div className="relative mx-auto flex min-h-screen w-full flex-col p-14 pt-10 lg:w-1/2 lg:p-36 lg:pt-16">
      <a href="https://www.prodigi.com" title="Prodigi" className="mb-16 block w-fit">
        <img
          src="/img/logo/prodigi-logo-dark.svg"
          className="h-[47] w-[120px] lg:h-[60] lg:w-[150px]"
          alt="Prodigi logo"
        />
      </a>

      <h2 className="m-0 mb-2 text-2xl leading-10 tracking-tight text-gray-900">Welcome back!</h2>
      <p className="mt-0">
        Log in to access your personalised dashboard, place and review orders, catch up on the latest updates and more.
      </p>

      <span className="mt-6 text-red-500">
        <motion.span animate={errorMessage ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
          {errorMessage}&nbsp;
        </motion.span>
      </span>

      <form onSubmit={onSubmitLogin} id="login-form" className="relative mt-6 flex flex-col">
        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="email">
            Email
          </label>
        </span>
        <span className="relative">
          <TextField
            dataTest="login-email-input"
            ref={emailInputRef}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            onBlur={() => {
              setIsSubmitButtonValidationEnabled(true)
            }}
            id="email"
            name="email"
            type="text"
            required
          />
        </span>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span animate={emailErrorMessage ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
            {emailErrorMessage}&nbsp;
          </motion.span>
        </span>

        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="password">
            Password
          </label>
        </span>
        <span className="relative">
          <TextField
            className="pr-12"
            dataTest="login-password-input"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            id="password"
            name="password"
            minLength={6}
            type={showPassword ? 'text' : 'password'}
            required
          />
          <ToggleHidePasswordButton
            className="mt-2"
            onShowPasswordChange={setShowPassword}
            showPassword={showPassword}
          />
        </span>
        <div>
          <Link className="text-sm text-purple-500" to={`/password-reset`}>
            Forgotten your password?{' '}
          </Link>
        </div>
        <Button
          type="submit"
          isLoading={loading}
          disabled={loading || (isSubmitButtonValidationEnabled && Boolean(email === '' || password === ''))}
          className="mt-8"
          form="login-form"
        >
          Login
        </Button>
      </form>

      <div className="mt-12 md:mt-24">
        Don&apos;t have an account?{' '}
        <Link className="text-purple-500" to={`/register${window.location.search}`}>
          Sign up
        </Link>
      </div>
    </div>
  )
}

export default LeftPanel
