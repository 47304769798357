// @flow
import type { Dimensions } from '../../types'
import { calcPrintDimensionsFillScaleFactor } from '@prodigi-group/components-image-editor'

export function calculateFillScaleFactor (
  printResolutionInPx: Dimensions,
  artworkDimensionsInPx: Dimensions,
  borderInPx: number,
  rotationAngle: number
): number {
  const scaleFactor = calcPrintDimensionsFillScaleFactor(printResolutionInPx, artworkDimensionsInPx, borderInPx, rotationAngle)

  return scaleFactor * 100
}
