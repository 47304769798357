// @flow
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { AppState, Action } from '../types'
import { startJwtTimerIfNeeded } from '../actions/auth/startJwtTimerIfNeeded'
import { REFRESH_ACCESS_TOKEN_SUCCESS } from '../actions/auth/refreshAccessTokenRsaa'

export function jwtTimerMiddleware (store: MiddlewareAPI<AppState, Action>): ((next: any) => (action: Action) => any) {
  return function (next: Dispatch<Action>) {
    return function (action: Action) {
      if (action.type === REFRESH_ACCESS_TOKEN_SUCCESS) {
        store.dispatch(startJwtTimerIfNeeded())
      }

      return next(action)
    }
  }
}
