// @flow
import type {
  ImageSizing,
  ArtworkTransformations,
  Artwork,
  MultiAssetTemplate,
  MultiAssetTemplates,
  Orientation
} from '../../types'
import type { InitArtworkTransformationsSuccess, InitArtworkTransformationsError, InitArtworkTransformationsResult } from '../types'
import { LANDSCAPE, PORTRAIT, SQUARE } from '../../data/orientation'
import Option from '../../helpers/Option'
import { entries } from '../../helpers/dictionary'
import { calculateFillScaleFactor } from './calculateFillScaleFactor'
import { calculateFitScaleFactor } from './calculateFitScaleFactor.js'
import { PRINT_AREA_NAME } from '../../data'
import { determineImageOrientation, isImageRotationRequired } from '../../helpers/imageEditor'
import { IMAGE_SIZING } from '../../data/imageSizing'

export const INIT_ARTWORK_TRANSFORMATIONS_SUCCESS = 'INIT_ARTWORK_TRANSFORMATIONS_SUCCESS'
export const INIT_ARTWORK_TRANSFORMATIONS_ERROR = 'INIT_ARTWORK_TRANSFORMATIONS_ERROR'

type InitArtworkProps = {|
  artwork: Artwork,
  templates: MultiAssetTemplates,
  itemId: string,
  sizing?: ImageSizing,
  printAreaName?: string
|}

export function initArtworkTransformations ({
  artwork,
  templates,
  itemId,
  sizing = IMAGE_SIZING.FILL,
  printAreaName = PRINT_AREA_NAME.DEFAULT
}: InitArtworkProps): InitArtworkTransformationsResult {
  try {
    return getDefaultTransformations(artwork, templates, sizing, printAreaName)
      .whenSome(transformations => initArtworkTransformationsSuccess(transformations, itemId))
      .whenNone(() => initArtworkTransformationsError(artwork, templates, itemId))
  } catch (e) {
    console.error(e)
    return initArtworkTransformationsError(artwork, templates, itemId)
  }
}

function initArtworkTransformationsSuccess (transformations: ArtworkTransformations, itemId: string): InitArtworkTransformationsSuccess {
  return {
    itemId,
    transformations,
    type: INIT_ARTWORK_TRANSFORMATIONS_SUCCESS
  }
}

function initArtworkTransformationsError (artwork: Artwork, templates: MultiAssetTemplates, itemId: string): InitArtworkTransformationsError {
  return {
    itemId,
    artwork,
    templates,
    type: INIT_ARTWORK_TRANSFORMATIONS_ERROR
  }
}

export function getDefaultTransformations (
  artwork: Artwork,
  templates: MultiAssetTemplates,
  sizing: ImageSizing,
  printAreaName: string
): Option<ArtworkTransformations> {
  const { artworkWidth, artworkHeight } = artwork

  const imageOrientation = determineImageOrientation(artworkWidth, artworkHeight)

  const shouldRotateImage = Boolean(
    sizing === IMAGE_SIZING.FILL &&
    isImageRotationRequired(templates, imageOrientation, printAreaName)
  )

  const preferredTemplateOrientation = shouldRotateImage
    ? rotateOrientation(imageOrientation)
    : imageOrientation

  const templateOrientation = selectTemplateOrientation(templates, preferredTemplateOrientation, printAreaName)

  if (!templateOrientation) {
    return Option.none()
  }

  return getArtworkTransformations(artwork, templates, templateOrientation, shouldRotateImage, sizing, printAreaName)
}

function getArtworkTransformations (
  artwork: Artwork,
  templates: MultiAssetTemplates,
  templateOrientation: Orientation,
  shouldRotateImage: boolean,
  sizing: ImageSizing,
  printAreaName: string
): Option<ArtworkTransformations> {
  const { artworkWidth, artworkHeight } = artwork
  const selectedTemplate = templates.printAreas[printAreaName].orientations[templateOrientation]

  if (!selectedTemplate) {
    return Option.none()
  }

  const rotationAngle = shouldRotateImage
    ? 90
    : 0

  const artworkDimensionsInPx = { width: artworkWidth, height: artworkHeight }

  const scaleFactor = sizing === IMAGE_SIZING.FIT
    ? calculateFitScaleFactor(selectedTemplate.printResolution, artworkDimensionsInPx, 0, rotationAngle)
    : calculateFillScaleFactor(selectedTemplate.printResolution, artworkDimensionsInPx, 0, rotationAngle)

  const transformations = buildArtworkTransformations(selectedTemplate, scaleFactor, rotationAngle, artwork, templateOrientation)

  return Option.some(transformations)
}

function buildArtworkTransformations (
  template: MultiAssetTemplate,
  scaleFactor: number,
  rotationAngle: 0 | 90,
  artwork: Artwork,
  templateOrientation: Orientation
): ArtworkTransformations {
  return {
    orientation: templateOrientation,
    scaleFactor: scaleFactor,
    position: {
      x: 0,
      y: 0
    },
    rotationAngle: rotationAngle,
    borderFactor: 0,
    borderScale: 'cm',
    isTiled: false
  }
}

function rotateOrientation (orientation: Orientation): Orientation {
  if (orientation === SQUARE) {
    return SQUARE
  } else if (orientation === LANDSCAPE) {
    return PORTRAIT
  } else {
    return LANDSCAPE
  }
}

function selectTemplateOrientation (
  templates: MultiAssetTemplates,
  preferredTemplateOrientation: Orientation,
  printAreaName: string
): ?Orientation {
  if (templates.printAreas[printAreaName]?.orientations[preferredTemplateOrientation]) {
    return preferredTemplateOrientation
  }

  const defaultTemplates = templates.printAreas[printAreaName]?.orientations ?? {}

  for (const [orientation, templateData] of entries(defaultTemplates)) {
    if (templateData) {
      const orientationName: Orientation = (orientation: any)
      return orientationName
    }
  }

  return null
}
