import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE } from 'src/v2/constants'
import MaintainSalesChannelConnections from 'src/v2/components/MaintainSalesChannelConnections'

export default function MaintainSalesChannelConnectionsPage() {
  useEffect(() => {
    document.title = 'Sales Channels | Prodigi'
    window.analytics.track('Viewed: sales_channels_clean_up_page')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <MaintainSalesChannelConnections />
    </main>
  )
}
