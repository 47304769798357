import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Elements, useStripe } from '@stripe/react-stripe-js'

import Modal from '../../Modal'
import LoadingSpinner from '../../LoadingSpinner'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { useOrderActions, useOrderDetail } from '../../../hooks'
import { stripePromise } from '../../../helpers/stripePromise.helper'

const STRIPE_PAYMENT_INTENT_REDIRECT_PARAMS = { CLIENT_SECRET: 'payment_intent_client_secret' }

const ORDER_DETAIL_STRIPE_REDIRECT_TOAST_IDS = {
  SUCCESS: 'order-detail-stripe-redirect-success-toast',
  ERROR: 'order-detail-stripe-redirect-error-toast'
}

export function OrderDetailStripeRedirectModal({ orderId }: { orderId: string }) {
  const [isOpen, setIsOpen] = useState(false)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const clientSecret = searchParams.get(STRIPE_PAYMENT_INTENT_REDIRECT_PARAMS.CLIENT_SECRET)

  useEffect(() => {
    return () => {
      toast.dismiss(ORDER_DETAIL_STRIPE_REDIRECT_TOAST_IDS.SUCCESS)
      toast.dismiss(ORDER_DETAIL_STRIPE_REDIRECT_TOAST_IDS.ERROR)
    }
  }, [])

  useEffect(() => {
    if (clientSecret) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [clientSecret])

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      showHeader={false}
      showBorder={false}
      closeOnInteractionOutside={false}
      closeOnEscape={false}
    >
      <div className="max-w-3xl p-8 sm:min-w-[500px] sm:p-12">
        <Elements stripe={stripePromise}>
          <StripeRedirectStatus orderId={orderId} />
        </Elements>
      </div>
    </Modal>
  )
}

function StripeRedirectStatus({ orderId }: { orderId: string }) {
  const { mutateOrderDetails } = useOrderDetail(orderId)
  const { mutateOrderActions } = useOrderActions(orderId)
  const stripe = useStripe()
  const history = useHistory()

  useEffect(() => {
    if (!stripe) {
      return
    }

    function handleClose() {
      const newSearchParams = new URLSearchParams(window.location.search)
      newSearchParams.delete(STRIPE_PAYMENT_INTENT_REDIRECT_PARAMS.CLIENT_SECRET)
      const newPath = window.location.pathname + `?${newSearchParams.toString()}`
      history.replace(newPath)
    }

    function handleError({
      errorCode,
      errorMessage,
      heading = 'Failed to authorise payment',
      id = ORDER_DETAIL_STRIPE_REDIRECT_TOAST_IDS.ERROR
    }: {
      errorCode?: string | number
      errorMessage?: string
      heading?: string
      id?: string
    }) {
      createErrorToast({ errorCode, errorMessage, heading, id })
      handleClose()
    }

    function handleSuccess({
      duration,
      heading,
      message,
      id = ORDER_DETAIL_STRIPE_REDIRECT_TOAST_IDS.SUCCESS,
      type = 'success'
    }: {
      duration?: number
      heading: string
      message?: string
      id?: string
      type?: 'success' | 'default-with-close'
    }) {
      createToast({ duration, content: message, heading, id, type })
      handleClose()
    }

    async function retrievePaymentIntent() {
      try {
        const clientSecret = new URLSearchParams(window.location.search).get(
          STRIPE_PAYMENT_INTENT_REDIRECT_PARAMS.CLIENT_SECRET
        )

        if (!stripe) {
          handleError({ errorCode: 'ST-NO' })
          return
        }

        if (!clientSecret) {
          handleError({ errorCode: 'CS-NO' })
          return
        }
        const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret)

        if (error) {
          handleError({
            errorMessage: error.message,
            errorCode: 'SI'
          })
          return
        }

        if (paymentIntent.status === 'requires_payment_method') {
          handleError({
            errorMessage: paymentIntent.last_payment_error?.message,
            errorCode: 'RPM'
          })
          return
        }

        await mutateOrderDetails()
        await mutateOrderActions()

        if (paymentIntent.status === 'processing') {
          handleSuccess({
            duration: Infinity,
            heading: 'Processing payment',
            message:
              'Your payment is being processed. Please allow some time for processing to complete and check back later',
            type: 'default-with-close'
          })
          return
        }

        handleSuccess({ heading: 'Payment authorised successfully' })
      } catch (error) {
        const errorResponse = error as { message?: string }
        const fetchErrorResponse = error as FetchErrorInterface<{ message?: string }>
        handleError({
          errorMessage: fetchErrorResponse.responseBodyJson?.message ?? errorResponse.message,
          errorCode: `SI-C-${fetchErrorResponse.status ?? '0'}`
        })
      }
    }

    retrievePaymentIntent()
  }, [history, mutateOrderActions, mutateOrderDetails, stripe])

  return (
    <div className="grid place-content-center">
      <div className="flex justify-center">
        <LoadingSpinner className="h-8 w-8" />
      </div>
      <div className="mt-4">Verifying payment</div>
    </div>
  )
}
