import { useCompositeState, Composite } from 'reakit/Composite'
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook'

import InlineMenuWithInfiniteScrollContent from './components/InlineMenuWithInfiniteScrollContent.component'

export interface InlineMenuWithInfiniteScrollOptionInterface {
  content: React.ReactNode
  disabled?: boolean
  searchLabel: string
  value: string
}

export type ConditionalPropsType =
  | {
      multiple: true
      selected: string[]
      updateSelected?: (selected: string[]) => void
    }
  | {
      multiple?: false
      selected: string | null
      updateSelected?: (selected: string) => void
    }

interface MenuProps {
  ariaLabel: string
  className?: string
  header?: React.ReactNode
  fetchError?: {
    status: number | undefined
  }
  isLoadingFirstSetOfData: boolean
  itemsToloadSize: number
  optionPages: InlineMenuWithInfiniteScrollOptionInterface[][]
  pageSize: number
  queryParam: string
  searchingFor: string
  onTriggerScroll: () => void
  paginationTriggerRef: IntersectionObserverHookRefCallback
}

type InlineMenuWithInfiniteScrollPropsType = ConditionalPropsType & MenuProps

export default function InlineMenuWithInfiniteScroll({
  ariaLabel,
  className = '',
  header,
  fetchError,
  isLoadingFirstSetOfData,
  itemsToloadSize,
  optionPages,
  pageSize,
  paginationTriggerRef,
  queryParam,
  searchingFor,
  onTriggerScroll,
  ...conditionalProps
}: InlineMenuWithInfiniteScrollPropsType) {
  const compositeProps = useCompositeState({ orientation: 'vertical' })

  return (
    <Composite
      {...compositeProps}
      aria-label={ariaLabel}
      aria-multiselectable={Boolean(conditionalProps.multiple)}
      as="ul"
      className={className}
      data-test="inline-menu"
      role="listbox"
    >
      {header}
      <InlineMenuWithInfiniteScrollContent
        compositeProps={compositeProps}
        {...conditionalProps}
        fetchError={fetchError}
        isLoadingFirstSetOfData={isLoadingFirstSetOfData}
        itemsToloadSize={itemsToloadSize}
        optionPages={optionPages}
        pageSize={pageSize}
        paginationTriggerRef={paginationTriggerRef}
        queryParam={queryParam}
        searchingFor={searchingFor}
        onTriggerScroll={onTriggerScroll}
      />
    </Composite>
  )
}
