import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers/fetcher'
import { FetchErrorInterface } from '../interfaces'

interface ProductDimensionsInterface {
  width: number
  height: number
  units: string
}

interface PrintAreaSizeInterface {
  horizontalResolution: number
  verticalResolution: number
}

export interface VariantInterface {
  attributes: Record<string, string>
  shipsTo: string[]
  printAreaSizes: Record<string, PrintAreaSizeInterface>
}

export interface ProductDetailPrintAreaInterface {
  required: boolean
}

export interface ProductDetailInterface {
  sku: string
  description: string
  productDimensions: ProductDimensionsInterface
  attributes: Record<string, string[]>
  printAreas: Record<string, ProductDetailPrintAreaInterface>
  variants: VariantInterface[]
}

export interface ProductDetailSuccessResponseInterface {
  outcome: string
  product: ProductDetailInterface
  traceParent: string
}

interface ProductDetailsErrorResponseInterface {
  statusText: string
  statusCode: number
  data: unknown
  traceParent: string
}

export function useProductDetails({ config, sku }: { config?: SWRConfiguration; sku?: string }) {
  const url = sku ? `${process.env.REACT_APP_PRODIGI_API_ENDPOINT}/v4.0/products/${sku}` : null

  const { data, error } = useSWR<
    ProductDetailSuccessResponseInterface,
    FetchErrorInterface<ProductDetailsErrorResponseInterface>
  >(url, productDetailsFetcher, config)
  return {
    productDetails: data,
    isLoading: Boolean(url && !data && !error),
    error
  }
}

async function productDetailsFetcher(url: string): Promise<ProductDetailSuccessResponseInterface> {
  return fetcher<ProductDetailSuccessResponseInterface>(url).then((productDetails) => {
    const isPhotobook = productDetails.product.sku.toUpperCase().startsWith('BOOK-')

    if (!isPhotobook) {
      return productDetails
    }

    const mappedPrintAreas: Record<string, ProductDetailPrintAreaInterface> = {}
    Object.entries(productDetails.product.printAreas ?? {}).forEach(([printAreaName, printAreaData]) => {
      if (printAreaName.toLowerCase() === 'spine') {
        return
      }

      mappedPrintAreas[printAreaName] = printAreaData
    })

    const mappedProductData: ProductDetailSuccessResponseInterface = {
      ...productDetails,
      product: { ...productDetails.product, printAreas: mappedPrintAreas }
    }

    return mappedProductData
  })
}
