// @flow

import type { CsvOrderBrandingType } from '../../types/csvUpload'

export const UPDATE_CSV_INSERTS = 'UPDATE_CSV_INSERTS'

export type UpdateCsvInsertsActionType = {|
  type: 'UPDATE_CSV_INSERTS',
  orderId: string,
  inserts: CsvOrderBrandingType
|}

export function updateCsvInserts({
  orderId,
  inserts
}: {|
  orderId: string,
  inserts: CsvOrderBrandingType
|}): UpdateCsvInsertsActionType {
  return {
    type: UPDATE_CSV_INSERTS,
    orderId,
    inserts
  }
}
