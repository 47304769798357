// @flow
import React, { PureComponent } from 'react'
import ModalWithHeader from '../../../../components/ModalWithHeader'
import styles from './CsvImageModal.module.css'
import MultiAssetImageUpload from '../../../../components/MultiAssetImageUpload'
import type {
  ThumbnailItem,
  Status,
  CatalogueItem,
  PrintAreaEntries,
  FormattedOrderItem,
  MultiAssetTemplates,
  ArtworkTransformations,
  Dictionary,
  PrintAreaArtwork
} from '../../../../types'
import cx from 'classnames'
import { LOADING, ERROR } from '../../../../data/rsaa'
import { canProductBeTiled } from '../../../../helpers/products'
import { displayBorder, isProductMounted } from '../../../../helpers/imageEditor'
import { buildImageId } from '../../../../actions/images'
import type { Artwork, ArtworkProgress, ImageEditorSaveDataType } from '../../../../types/images'

// $FlowFixMe: TypeScript file
import MultiAssetImageEditor from '../../../../v2/components/ImageEditor/components/MultiAssetImageEditor.component'
// $FlowFixMe: TypeScript file
import { default as ModalV2 } from '../../../../v2/components/Modal'

type Props = {|
  item: FormattedOrderItem,
  thumbnailItem: ?ThumbnailItem,
  templates: ?MultiAssetTemplates,
  allArtworkProgress: Dictionary<ArtworkProgress>,
  itemPrintAreas: Dictionary<PrintAreaArtwork>,
  templateStatus: Status,
  imageStatus: Status,
  optimalResolution: ?string,
  catalogueDetails: CatalogueItem,
  editImage: boolean,
  imageUrl: ?string,
  imageUploadFailed: boolean,
  imageErrorMessage: string,
  isTilingEnabled: boolean,
  printAreaEntries: PrintAreaEntries,
  selectedPrintArea: string,
  removeArtwork: (printAreaName: string) => void,
  uploadArtwork?: (event: SyntheticInputEvent<HTMLInputElement>, printAreaName: string) => void,
  toggleTiling: () => void,
  uploadCsvArtworkFromUrl: (url: string) => void,
  uploadCsvArtworkFromFile: (file: File) => void,
  onEditImageClick: () => void,
  onSaveAndClose: () => void,
  closeModal: () => void,
  onSaveCrop: (
    artworkTransformations: Dictionary<?ArtworkTransformations>,
    selectedPrintArea: string,
    additionalData: ImageEditorSaveDataType
  ) => void,
  onCancelCrop: () => void,
  onSelectCsvImageLibraryImage: (artwork: Artwork) => void,
  onSelectCsvImageLibraryImageFromEditor: (selectionData: {|
    artwork: Artwork,
    artworkTransformations: ArtworkTransformations,
    selectedPrintArea: string
  |}) => void
|}

export default class CsvImageModalView extends PureComponent<Props> {
  render(): React$Node {
    const { imageStatus, imageUrl, templates, item } = this.props
    const title = this.props.editImage ? '' : 'Edit image'

    const { description, category, sku } = this.props.catalogueDetails
    const supportsPrintBorders = displayBorder(
      this.props.catalogueDetails.category,
      this.props.catalogueDetails.productType,
      isProductMounted(item.selectedAttributes)
    )

    const supportsTiling = this.props.catalogueDetails
      ? canProductBeTiled(this.props.catalogueDetails.productType)
      : false

    const printAreas = this.props.itemPrintAreas

    return (
      <ModalWithHeader
        title={title}
        className={cx(styles.container, {
          [styles.cropper]: this.props.editImage
        })}
        close={this.props.closeModal}
        dataTest="csv-image-modal"
      >
        {!this.props.editImage ? (
          <div>
            <MultiAssetImageUpload
              toggleTiling={this.props.toggleTiling}
              isTilingEnabled={this.props.isTilingEnabled}
              supportsTiling={canProductBeTiled(this.props.catalogueDetails.productType)}
              templateStatus={this.props.templateStatus}
              onOpenImageLibrary={() => window.analytics.track('Image library opened: CSV')}
              imageStatus={imageStatus}
              thumbnailItem={this.props.thumbnailItem}
              imageUrl={imageUrl}
              imageQuality={null}
              itemCategory={this.props.catalogueDetails.category}
              productType={this.props.catalogueDetails.productType}
              isUploadingImage={imageStatus === LOADING}
              hasImageUploadError={imageStatus === ERROR}
              isLoadingImageContainerClassName={styles.csvImageContainer}
              imageUploadErrorMessage={this.props.imageErrorMessage}
              uploadArtworkFromUrl={this.props.uploadCsvArtworkFromUrl}
              uploadArtworkFromFile={this.props.uploadCsvArtworkFromFile}
              showSaveAndCloseButton={imageUrl !== ''}
              optimalResolution={this.props.optimalResolution}
              saveAndCloseAction={this.props.onSaveAndClose}
              onEditImageClick={this.props.onEditImageClick}
              printAreaEntries={this.props.printAreaEntries}
              selectedPrintArea={this.props.selectedPrintArea}
              onSelectImageLibraryImage={this.props.onSelectCsvImageLibraryImage}
            />
          </div>
        ) : templates ? (
          <div className="tailwind">
            <ModalV2
              className="h-100vh max-h-100vh w-100vw max-w-100vw overflow-auto rounded-none bg-transparent"
              showBorder={false}
              showHeader={false}
              open={true}
              closeOnInteractionOutside={false}
              closeOnEscape={false}
            >
              <MultiAssetImageEditor
                className="h-100vh"
                printAreas={printAreas}
                description={description}
                itemCategory={category}
                productType={this.props.catalogueDetails.productType}
                onOpenImageLibrary={() => window.analytics.track('Image library opened: CSV')}
                productSku={sku}
                selectedAttributes={item.selectedAttributes}
                supportsPrintBorders={supportsPrintBorders}
                supportsTiling={supportsTiling}
                selectedPrintArea={this.props.selectedPrintArea}
                templates={templates}
                artworkUploadProgress={this.props.allArtworkProgress}
                buildImageId={(printAreaName) => buildImageId(this.props.item.id, printAreaName)}
                onSave={this.props.onSaveCrop}
                onCancel={this.props.onCancelCrop}
                resetImage={this.props.removeArtwork}
                onRemoveArtwork={this.props.removeArtwork}
                onSelectImageLibraryImage={this.props.onSelectCsvImageLibraryImageFromEditor}
              />
            </ModalV2>
          </div>
        ) : null}
      </ModalWithHeader>
    )
  }
}
