// @flow
import type { RouterHistory } from 'react-router'

export type AppInsights = {|
  trackException: ({| exception: Error |}) => void
|}

export function initAppInsights (history: RouterHistory): ?AppInsights {
  const { ApplicationInsights } = require('@microsoft/applicationinsights-web')
  const { ReactPlugin } = require('@microsoft/applicationinsights-react-js')

  const reactPlugin = new ReactPlugin()
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history }
      }
    }
  })

  appInsights.loadAppInsights()
  appInsights.trackPageView()

  return appInsights
}
