import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from '../../../constants'
import { CatalogueCategoryInterface } from '../../../hooks'

export function CategoryCard({ categoryData }: { categoryData: CatalogueCategoryInterface }) {
  const subCategoryEntries = Object.entries(categoryData.subCategories)
  const productEntries = Object.entries(categoryData.products)
  const products = Object.values(categoryData.products)

  const categoryHasOnlyOneProduct = subCategoryEntries.length === 0 && products.length === 1 && products[0]?.slug

  const nextCategoryOrProductLink = categoryHasOnlyOneProduct
    ? `${ROUTE_PATHS.PRODUCT_CATALOGUE}/${categoryData.fullSlug}/${products[0]?.slug}`
    : `${ROUTE_PATHS.PRODUCT_CATALOGUE}/${categoryData.fullSlug}`

  return (
    <div className="flex flex-col bg-white">
      <Link
        className="flex-1 p-4"
        to={nextCategoryOrProductLink}
        onClick={() => {
          window.analytics.track('Clicked Product Category Tile', {
            productCategory: categoryData.name
          })
        }}
      >
        <CardImages categoryData={categoryData} />

        <h2 className="mt-6 text-lg">{categoryData.name}</h2>
      </Link>

      {subCategoryEntries.length > 0 && (
        <div className="flex-1">
          <div className="flex flex-wrap gap-2 p-4 pt-2">
            {subCategoryEntries.slice(0, 3).map(([categoryKey, categoryData]) => {
              return categoryData?.fullSlug && categoryData.name ? (
                <CardPill key={categoryKey} fullSlug={categoryData.fullSlug} name={categoryData.name} />
              ) : null
            })}

            {subCategoryEntries.length > 3 && (
              <Link to={nextCategoryOrProductLink} className="py-2 text-sm text-purple-700">
                + {subCategoryEntries.length - 3} more...
              </Link>
            )}
          </div>
        </div>
      )}

      {subCategoryEntries.length === 0 && productEntries.length > 0 && (
        <div className="flex-1">
          <div className="flex flex-wrap gap-2 p-4 pt-2">
            {productEntries.slice(0, 3).map(([productKey, productData]) => {
              return productData?.slug && productData.name ? (
                <CardPill
                  key={productKey}
                  fullSlug={`${categoryData.fullSlug}/${productData.slug}`}
                  name={productData.name}
                />
              ) : null
            })}

            {productEntries.length > 3 && (
              <Link to={nextCategoryOrProductLink} className="py-2 text-sm text-purple-700">
                + {productEntries.length - 3} more...
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function CardImages({ categoryData }: { categoryData: CatalogueCategoryInterface }) {
  if (categoryData.images.length === 0) {
    return <div className="h-[120px] w-full bg-gray-100 md:h-[180px]"></div>
  }

  if (categoryData.images.length === 1) {
    return (
      <div className="grid place-content-center bg-gray-100">
        <img
          alt={categoryData.name}
          className="h-[120px] w-full object-contain object-center md:h-[180px]"
          src={categoryData.images[0]}
        />
      </div>
    )
  }

  return (
    <div className="grid grid-cols-3 grid-rows-1 gap-3 md:grid-cols-[3fr,1fr] md:grid-rows-2">
      <div className="grid place-content-center bg-gray-100 md:row-span-2">
        <img
          className="h-[120px] w-full object-contain object-center md:h-[180px]"
          alt={categoryData.name}
          src={categoryData.images[0]}
        />
      </div>

      {categoryData.images.slice(1, 3).map((image) => (
        <div className={'grid place-content-center bg-gray-100'} key={image}>
          <img
            className={'h-[120px] w-full object-contain object-center md:h-[70px]'}
            alt={categoryData.name}
            src={image}
          />
        </div>
      ))}
    </div>
  )
}

function CardPill({ fullSlug, name }: { fullSlug: string; name: string }) {
  return (
    <Link
      className="rounded bg-purple-100 px-3 py-2 text-sm text-purple-600"
      to={`${ROUTE_PATHS.PRODUCT_CATALOGUE}/${fullSlug}`}
    >
      {name}
    </Link>
  )
}
