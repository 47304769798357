function Vegan({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 60 60"
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
    >
      <title>Vegan-friendly icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 1.93548C14.5258 1.93548 1.93548 14.5248 1.93548 30C1.93548 45.4742 14.5258 58.0645 30 58.0645C45.4742 58.0645 58.0645 45.4742 58.0645 30C58.0645 14.5248 45.4742 1.93548 30 1.93548M30 60C13.4574 60 0 46.5426 0 30C0 13.4584 13.4574 0 30 0C46.5426 0 60 13.4584 60 30C60 46.5426 46.5426 60 30 60"
      />
      <path d="M44.856 16.1436C44.856 16.1436 43.7104 16 42.1352 16C39.7725 16 36.4789 16.359 34.6889 18.2256C32.4693 20.4513 32.4693 24.041 34.6173 26.2667C32.9705 28.5641 31.109 31.9385 30.035 36.3897C29.7486 35.241 29.3906 34.0205 28.961 32.7282C26.455 25.3333 22.0875 19.8769 16.288 17.0051C15.8584 16.7897 15.2856 17.0051 15.0708 17.4359C14.856 17.8667 14.9992 18.441 15.5004 18.6564C28.4598 25.1179 29.319 42.9231 29.319 43.1385C29.319 43.641 29.7486 44 30.1782 44C30.6794 44 31.0374 43.5692 31.0374 43.1385C31.0374 36.0308 33.6149 30.5744 35.9061 27.3436C36.7653 27.8462 37.7677 28.0615 38.6985 28.0615C40.202 28.0615 41.634 27.4872 42.7796 26.3385C45.8584 23.3231 44.856 16.1436 44.856 16.1436ZM41.8488 25.3333C41.0612 26.1231 39.9156 26.6256 38.7701 26.6256C38.0541 26.6256 37.3381 26.4103 36.6937 26.0513C37.3381 25.1179 37.8393 24.4718 40.0588 21.6C40.202 21.4564 40.202 21.241 40.0588 21.0974C39.9156 20.9538 39.7008 20.9538 39.5577 21.0974C36.9801 23.6103 36.4073 24.1128 35.5481 25.1179C34.8321 24.3282 34.4741 23.3231 34.4741 22.2462C34.4741 21.0974 34.9037 20.0205 35.7629 19.159C36.9085 18.0103 39.1997 17.3641 42.2068 17.3641C42.708 17.3641 43.2092 17.3641 43.5672 17.4359C43.7104 19.7333 43.5672 23.6103 41.8488 25.3333Z" />
    </svg>
  )
}

export { Vegan }
