import { ProductCard } from '.'
import { CatalogueProductInterface } from '../../../hooks'

export function ProductCards({ products }: { products: Record<string, CatalogueProductInterface | undefined> }) {
  return (
    <div className="grid max-w-full grid-cols-[repeat(auto-fill,minmax(270px,1fr))] gap-8 overflow-x-auto pt-6">
      {Object.entries(products).map(([productKey, productData]) => {
        return productData ? <ProductCard key={productKey} productData={productData} /> : null
      })}
    </div>
  )
}
