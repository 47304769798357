// @flow
import { RSAA } from 'redux-api-middleware'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import type {
  GetStateFunc,
  DispatchFunc,
  OrderDetailsResponseBody,
  ThunkAsync
} from '../../types'
import { fetchOrderDetailsFromApiV3 } from './fetchOrderDetailsFromApiV3'

export const REQUEST_ORDER_DETAIL = 'REQUEST_ORDER_DETAIL'
export const RECEIVE_ORDER_DETAIL = 'RECEIVE_ORDER_DETAIL'
export const FETCH_ORDER_DETAILS_ERROR = 'FETCH_ORDER_DETAILS_ERROR'

export function fetchOrderDetails (orderId: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint
    const response = await dispatch({
      [RSAA]: {
        endpoint: endpoint + `/dashboard/order/${orderId}?timestamp=${Date.now()}`,
        method: 'GET',
        types: [
          REQUEST_ORDER_DETAIL,
          RECEIVE_ORDER_DETAIL,
          {
            type: FETCH_ORDER_DETAILS_ERROR,
            payload: async (action, state, res) => {
              const json = await res.json()
              return { ...json, orderId }
            }
          }
        ]
      }
    })

    if (response.type === RECEIVE_ORDER_DETAIL && everyOrderItemHasAnId(response.payload.data)) {
      await dispatch(fetchOrderDetailsFromApiV3(orderId))
    }
  }
}

function everyOrderItemHasAnId (order: OrderDetailsResponseBody): boolean {
  return order.items && order.items.every(item => item.id)
}
