export function formatOrientationFilter(typeValues: string) {
  return (typeValues.split(',') as ('portrait' | 'landscape' | 'square')[])
    .map(formatImageLibraryOrientationName)
    .join(', ')
}

function formatImageLibraryOrientationName(type: 'portrait' | 'landscape' | 'square') {
  switch (type) {
    case 'landscape':
      return 'Landscape'
    case 'portrait':
      return 'Portrait'
    case 'square':
      return 'Square'
    default:
      return type
  }
}
