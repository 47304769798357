// @flow
import type { Dictionary } from '../types'

export function flatten<T> (arr: T[][]): T[] {
  return arr.reduce((arrAcc: T[], arr: T[]): T[] => {
    return arrAcc.concat(arr)
  }, [])
}

export function rotate<T> ([firstElement, ...tail]: T[]): T[] {
  if (!firstElement) {
    return []
  }

  return tail.concat(firstElement)
}

export function toDictionary<T> (arr: T[]): Dictionary<T> {
  return Object.entries(arr).reduce((acc, e) => {
    acc[e[0]] = e[1]
    return acc
  }, {})
}

export function removeDuplicates<T> (arr: T[]): T[] {
  return Array.from(new Set(arr))
}

export function split <T> (arr: T[], predicate: (element: T) => boolean): [T[], T[]] {
  const valuesThatSatisfyPredicate = []
  const valuesThatDoNotSatisfyPredicate = []

  for (const element of arr) {
    if (predicate(element)) {
      valuesThatSatisfyPredicate.push(element)
    } else {
      valuesThatDoNotSatisfyPredicate.push(element)
    }
  }

  return [valuesThatSatisfyPredicate, valuesThatDoNotSatisfyPredicate]
}

export function sortStringifiedNumbersDescending (stringifiedNumbers: string[]): string[] {
  return stringifiedNumbers.sort((num1, num2) => Number(num2) - Number(num1))
}
