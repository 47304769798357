// @flow

import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import type {
  ThunkAsync,
  V4ApiServerError,
  AppState,
  Action,
  Response,
  DispatchFunc,
  V4OrderCreationRequest,
  V4OrderCreationResponse
} from '../../types'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { AWAITING_PAYMENT } from '../../data/orderFilters'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'
import { mapApiErrorMessage } from '../../helpers/errorMessages'

export const CREATE_V4_ORDER = 'CREATE_V4_ORDER'
export const CREATE_V4_ORDER_SUCCESS = 'CREATE_V4_ORDER_SUCCESS'
export const CREATE_V4_ORDER_ERROR = 'CREATE_V4_ORDER_ERROR'

export function createV4Order(requestBody: V4OrderCreationRequest, loggerId: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc) => {
    const result = await dispatch(createV4OrderRsaa(requestBody, loggerId))

    if (isPaymentRequired(result.payload)) {
      return dispatch({
        type: CREATE_V4_ORDER_ERROR,
        meta: {
          requestBody,
          loggerId
        },
        payload: result.payload
      })
    }

    return result
  }
}

function isPaymentRequired(response: V4OrderCreationResponse): boolean {
  return Boolean(
    response.outcome === V4_API_ACTION_OUTCOME.CREATED_WITH_ISSUES && response.order.status.stage === AWAITING_PAYMENT
  )
}

function createV4OrderRsaa(requestBody: V4OrderCreationRequest, loggerId: string) {
  return {
    [RSAA]: {
      method: 'POST',
      endpoint: selectProdigiApiEndpoint() + '/v4.0/Orders',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody),
      types: [
        {
          type: CREATE_V4_ORDER,
          meta: {
            enhancements: [LOG_STATUS],
            loggerId
          }
        },
        CREATE_V4_ORDER_SUCCESS,
        {
          type: CREATE_V4_ORDER_ERROR,
          payload: mapErrorResponse,
          meta: {
            requestBody
          }
        }
      ]
    }
  }
}

async function mapErrorResponse(action: Action, state: AppState, response: Response<V4ApiServerError>) {
  try {
    const jsonResponse = await response.json()

    const errorMessage = mapApiErrorMessage(jsonResponse)

    if (errorMessage) {
      return {
        ...jsonResponse,
        message: errorMessage
      }
    }

    return jsonResponse
  } catch (error) {
    console.error(error)
    throw Error(DEFAULT_ERROR_MESSAGE)
  }
}
