// @flow
export const SANDBOX = 'SANDBOX'
export const LIVE = 'LIVE'
export const TEST = 'TEST'
export const PWINTY_ACCESS_TOKEN = 'PWINTY-ACCESS-TOKEN'
export const SUPPORT_EMAIL = 'support@prodigi.com'
export const PWINTY_AUTH_JWT = 'PWINTY_AUTH_JWT'
export const PWINTY_REFRESH_JWT = 'PWINTY_REFRESH_JWT'
export const FIT = 'FIT'
export const FILL = 'FILL'
export const SHOPIFY_URL = 'http://shopify.prodigi.uk/'
export const TEMPLATE_SIZE_IN_PX = 1500
export const INCHES_PER_MM = 0.0393701
export const MM_PER_INCH = 25.4
export const MAX_RECENT_ITEMS = 40
export const ORDERS_PER_PAGE = 25
export const DASHBOARD_DOMAIN = 'www.prodigi.com/'
export const LOADING_INDICATOR_CSS_CLASS_NAME = 'loading'

export const transparentBackground = 'rgba(223, 223, 229, 0.75)'
export const cropperBackground = 'rgb(223, 223, 229)'

export const thumbnailWhiteSpace = 'rgba(255, 255, 255, 0.75)'
export const cornerItemBackground = 'rgba(255, 255, 255, 0.01)'

export const ABORT_ERROR_NAME = 'AbortError'
export const ABORT_ERROR_CODE = 20

export const FETCH_ERROR_NAME = 'FetchError'

export const NOT_FOUND_STATUS_TEXT = 'Not Found'

export const AUTH_HEADER_PREFIX = 'Bearer '

export const MILLISECONDS_PER_MINUTE = 60000

// Analytics

export const ORDER_TIMER_NAME = 'Clicked place order'

export const HEX_REG_EXP: RegExp = /(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i

export const BASE_64_TRANSPARENT_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8UQ8AAnUBeRHU5C8AAAAASUVORK5CYII='

export const ANONYMOUS_ID_LOCAL_STORAGE_KEY = 'prodigiAnonymousId'
export const ANONYMOUS_ID_SEGMENT_LOCAL_STORAGE_KEY = 'ajs_anonymous_id'
