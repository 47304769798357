// @flow
import { resetAppState } from '../global'
import { removeUnfinishedOrderFromLocalStorage } from '../manualOrderForm'
import { removeJwtTokens, getRefreshToken } from './localStorage'
import { clearJwtTimer } from './clearJwtTimer'
import { revokeAccessToken, REVOKE_ACCESS_TOKEN_SUCCESS } from './revokeAccessToken'
import type { Action, DispatchFunc, GetStateFunc, ThunkAsync } from '../../types'
import { kiteLogout, KITE_LOGOUT_SUCCESS } from './kiteLogout'
import { configureRsaaLogging } from '../rsaa/configureRsaaLogging'

export const LOG_OUT_ERROR = 'LOG_OUT_ERROR'

export function logOut(): ThunkAsync<void> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const kiteLogoutRes = await dispatch(kiteLogout())
    if (kiteLogoutRes.type !== KITE_LOGOUT_SUCCESS) {
      return dispatch(onLogOutError())
    }
    const refreshToken = getRefreshToken()

    if (refreshToken) {
      const revokeTokenResult = await dispatch(revokeAccessToken(refreshToken))
      if (revokeTokenResult.type !== REVOKE_ACCESS_TOKEN_SUCCESS) {
        return dispatch(onLogOutError())
      }
    }

    dispatch(clearJwtTimer())
    removeUnfinishedOrderFromLocalStorage()
    removeJwtTokens()
    dispatch(resetAppState())
    dispatch(configureRsaaLogging())

    if (window.zE) {
      window.zE('messenger', 'logoutUser')
    }
  }
}

export function onLogOutError(): Action {
  return {
    type: LOG_OUT_ERROR
  }
}
