import clsx from 'clsx'

export function PrintQuality({ printQualityLabel }: { printQualityLabel?: 'Excellent' | 'Acceptable' | 'Poor' }) {
  if (!printQualityLabel) {
    return null
  }

  return (
    <span
      title="Image quality"
      className={clsx('text-sm', {
        'text-green-700': printQualityLabel === 'Excellent',
        'text-status-orange': printQualityLabel === 'Acceptable',
        'text-red-700': printQualityLabel === 'Poor'
      })}
    >
      {printQualityLabel}
    </span>
  )
}
