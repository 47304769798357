// @flow
import type { Country } from '../../types'
import { CsvApparelSizeMapping } from '../../data/apparelSizes'
import { ATTRIBUTE_FRAME_COLOR, ATTRIBUTE_SIZE } from '../../data/csvUpload'
import { IMAGE_SIZING } from '../../data/imageSizing'
import CsvUploadError from '../../errors/CsvUploadError'

export function formatValue (key: string, value: string, countries: Country[]): string | number {
  let formattedValue = value === '"' ? '' : value

  switch (key) {
    case 'sizing': {
      return formatSizing(value)
    }
    case 'quantity': {
      return parseInt(formattedValue)
    }
    case 'customsValue': {
      return Math.round(parseFloat(value) * 100)
    }
    case 'mobileTelephone': {
      return formattedValue.toString().replace(/"/g, '')
    }
    case 'postcode': {
      return formattedValue.replace(/"/g, '').replace(/'/g, '')
    }
    case 'preferredShippingMethod': {
      return formattedValue.toUpperCase().replace('SHIPPING', '').trim()
    }
    case 'sku': {
      return formattedValue.toUpperCase()
    }
    case 'countryCode': {
      if (formattedValue.length < 3) {
        return formattedValue
      }

      const formatCountry = countries.find(country => country.name.toLowerCase() === formattedValue.toLowerCase())
      return formatCountry && formatCountry.isoCode
        ? formatCountry.isoCode
        : ''
    }
    case ATTRIBUTE_SIZE: {
      const valueInLowerCase = formattedValue.toLowerCase()
      const convertedValue = CsvApparelSizeMapping[valueInLowerCase]
      return convertedValue ?? valueInLowerCase
    }
    case ATTRIBUTE_FRAME_COLOR: {
      return formattedValue.toLowerCase()
    }
    case 'packingSlip': {
      return formattedValue.includes('http') ? formattedValue : ''
    }
    default: {
      return formattedValue
    }
  }
}

export function formatSizing (value: string): string {
  const lowerCaseValue = value.toLowerCase()

  const lowerCaseFitPrintAreaSizing = IMAGE_SIZING.FIT.toLowerCase()
  const lowerCaseFillPrintAreaSizing = IMAGE_SIZING.FILL.toLowerCase()

  if (lowerCaseValue === lowerCaseFitPrintAreaSizing) {
    return IMAGE_SIZING.FIT
  } else if (lowerCaseValue === lowerCaseFillPrintAreaSizing) {
    return IMAGE_SIZING.FILL
  } else {
    throw new CsvUploadError(`Sizing option "${value}" is not currently supported. Valid values are "${IMAGE_SIZING.FILL}", and "${IMAGE_SIZING.FIT}".`)
  }
}
