// @flow
import type { AuthState } from '../types'
import type { AuthActions } from '../actions/types'
import {
  SESSION_EXPIRED,
  REFRESH_ACCESS_TOKEN_SUCCESS,
  LOG_IN,
  LOG_IN_SUCCESS,
  START_JWT_TIMER,
  CLEAR_JWT_TIMER
} from '../actions/auth'

const initialState: AuthState = {
  isUserLoggedIn: false,
  sessionExpired: false,
  jwtTimerId: null
}

function auth (state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case REFRESH_ACCESS_TOKEN_SUCCESS:
    case LOG_IN_SUCCESS: {
      return {
        ...state,
        isUserLoggedIn: true
      }
    }

    case START_JWT_TIMER: {
      return {
        ...state,
        jwtTimerId: action.timerId
      }
    }

    case CLEAR_JWT_TIMER: {
      return {
        ...state,
        jwtTimerId: null
      }
    }

    case SESSION_EXPIRED: {
      return {
        ...state,
        sessionExpired: true
      }
    }

    case LOG_IN: {
      return {
        ...state,
        sessionExpired: false
      }
    }

    default:
      return state
  }
}

export default auth
