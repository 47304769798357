function WooCommerceLogoMarkIcon({ className = '' }: { className?: string }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 700 700">
      <rect width="100%" height="100%" fill="#7F54B3" />
      <path
        fill="#fff"
        transform="scale(4.5)"
        d="M27.79,35.87a8.56,8.56,0,0,1,6.5-3.21q7.91-.55,9.08,7.46,4.88,32.49,10.45,55.16L76.58,52q3.09-5.86,7.81-6.2,6.84-.48,8.55,8.71A193.68,193.68,0,0,0,102.77,90q4.1-39.54,13.69-57.17a8,8,0,0,1,6.86-4.6,9.13,9.13,0,0,1,6.55,2.05,8,8,0,0,1,3.17,6,8.8,8.8,0,0,1-1,5q-6.08,11.28-10,37.51-3.87,25.32-2.86,39.61a12.63,12.63,0,0,1-1.21,6.8,6.53,6.53,0,0,1-5.53,3.75c-2.74.2-5.47-1.09-8.22-3.85q-14.48-14.85-23-44.19-10,20-15,29.92-9.15,17.6-15.68,18.05-4.24.3-7.22-7.12-8-20.37-17.16-78.82A8.88,8.88,0,0,1,27.79,35.87Z"
      />
    </svg>
  )
}

export { WooCommerceLogoMarkIcon }
