import { NO_IMAGE_PRODUCTS } from '../constants'

export function getNoImageProductData({
  attributes,
  productType,
  sku
}: {
  attributes: Record<string, string>
  productType: string
  sku: string
}) {
  const noImageProductsForProductType = NO_IMAGE_PRODUCTS.filter(
    (product) => product.type.toLowerCase() === productType.toLowerCase()
  )
  let product
  if (productType.toUpperCase() === 'SAMPLE PACK' || productType.toUpperCase() === 'PICTURE STANDS') {
    product = noImageProductsForProductType.find((product) => product.sku === sku)
  } else if (productType.toUpperCase() === 'POSTER HANGERS') {
    product = noImageProductsForProductType.find((product) => product.colour === attributes['frameColour'])
  } else if (productType.toUpperCase() === 'ECOCAPS') {
    product = noImageProductsForProductType.find((product) => product.type === productType)
  }
  return product
}
