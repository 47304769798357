import { useEffect } from 'react'
import PasswordReset from '../../components/PasswordReset'
import { DEFAULT_DOCUMENT_TITLE } from 'src/v2/constants'

export default function PasswordResetPage() {
  useEffect(() => {
    document.title = 'Password Reset | Prodigi'
    window.analytics.page('Account', 'Password Reset')
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <PasswordReset />
    </main>
  )
}
