import { fetcher } from '../../../helpers'
import { UserInterface } from '../../../interfaces'

export async function updateUserName({ name, user }: { name: string; user: UserInterface }) {
  return fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/user/${user.id}/update`, {
    body: JSON.stringify({
      id: user.id,
      name,
      permissions: []
    }),
    method: 'POST'
  })
}
