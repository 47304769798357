import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'
import { ImageLibraryImageInterface } from './useImageLibraryImages.hook'

export function useImageLibraryImage({ imageId }: { imageId: string | null }) {
  const {
    data,
    error,
    mutate: mutateImageLibraryImage
  } = useSWR<ImageLibraryImageInterface, FetchErrorInterface<{ detail?: string }>>(
    imageId ? `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageId}` : null,
    fetcher
  )

  return {
    image: data,
    isLoading: !data && !error,
    error,
    mutateImageLibraryImage
  }
}
