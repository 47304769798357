// @flow

export const UPDATE_ARTWORK = 'UPDATE_ARTWORK'

export function updateArtwork(
  pigUrl: string,
  itemId: string,
  transformImageUrl: string
): {| croppedImageUrl: string, itemId: string, type: string, transformImageUrl: string |} {
  return {
    type: UPDATE_ARTWORK,
    croppedImageUrl: pigUrl,
    itemId: itemId,
    transformImageUrl
  }
}
