// @flow

import type { PageAction } from './types'

import * as React from 'react'
import cx from 'classnames'
import PageHeader from './PageHeader'
import PageFooter from './PageFooter'
import styles from './styles.module.css'

type Props = {|
  title: string,
  titleAccessory?: () => React$Node,
  description?: string,
  backAction?: PageAction,
  primaryAction?: PageAction,
  secondaryActions?: Array<PageAction>,
  showActionsInFooter?: boolean,
  fullWidth?: boolean,
  children: React.Node
|}

function Page(props: Props): React$Node {
  return (
    <main
      className={cx(styles.page, {
        [styles.pageContainer]: !props.fullWidth
      })}
      style={{ minHeight: 'unset' }}
    >
      <PageHeader
        title={props.title}
        titleAccessory={props.titleAccessory}
        description={props.description}
        primaryAction={props.primaryAction}
        secondaryActions={props.secondaryActions}
        backAction={props.backAction}
      />

      <div className={styles.content}>{props.children}</div>

      {props.showActionsInFooter && (
        <PageFooter primaryAction={props.primaryAction} secondaryActions={props.secondaryActions} />
      )}
    </main>
  )
}

export default Page
