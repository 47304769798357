// @flow
import type { UpdateCroppedImageUrl } from '../types'

export const UPDATE_CROPPED_IMAGE_URL = 'UPDATE_CROPPED_IMAGE_URL'

export function updateCroppedImageUrl({
  itemId,
  croppedImageUrl,
  selectedPrintArea,
  transformImageUrl
}: {|
  itemId: number,
  croppedImageUrl: string,
  selectedPrintArea: string,
  transformImageUrl: string
|}): UpdateCroppedImageUrl {
  return {
    type: UPDATE_CROPPED_IMAGE_URL,
    croppedImageUrl,
    itemId,
    selectedPrintArea,
    transformImageUrl
  }
}
