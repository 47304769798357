import { useParams } from 'react-router'

import Button from '../../Button'
import Skeleton from '../../Skeleton'
import { ImageDetailsDisplay } from '.'
import SupportLink from '../../SupportLink'
import { useImageLibraryImage } from '../../../hooks'

export function ImageDetails() {
  const { id: imageId } = useParams<{ id: string }>()
  const { image: imageLibraryImage, error, isLoading } = useImageLibraryImage({ imageId })

  if (error) {
    return (
      <div className="flex flex-col border bg-white p-6">
        <h2 className="mt-0 mb-4 text-lg">Image details</h2>
        <div className="mt-8 flex w-full justify-center text-red-500">
          <p className="break-words">
            An error occurred while loading image details. Please try again later and{' '}
            <SupportLink className="text-red-500 underline">contact us</SupportLink> if this issue persists (Code{' '}
            {error.status ?? '0'})
          </p>
        </div>
      </div>
    )
  }

  if (isLoading || !imageLibraryImage) {
    return (
      <div className="flex flex-col border bg-white p-6">
        <h2 className="mt-0 mb-4 text-lg">Image details</h2>
        <ImageDetailsLoading />
      </div>
    )
  }

  return (
    <div className="flex flex-col border bg-white p-6">
      <h2 className="mt-0 mb-4 text-lg">Image details</h2>
      <ImageDetailsDisplay key={imageLibraryImage.image_type + imageLibraryImage.tags.join('-')} />
    </div>
  )
}

function ImageDetailsLoading() {
  return (
    <div>
      <div>
        <span className="mt-4 block pt-2 pb-2">Name</span>
        <Skeleton className="mt-2 h-16 w-full" />
      </div>
      <div>
        <span className="mt-4 block pt-2 pb-2">Type</span>
        <Skeleton className="mt-2 h-16 w-full" />
      </div>
      <div>
        <span className="mt-4 block pt-2 pb-2">Tags</span>
        <Skeleton className="mt-2 h-16 w-full" />
      </div>
      <div className="mt-12 flex gap-4 sm:mb-4">
        <Button disabled={true} type="submit">
          Save details
        </Button>
      </div>
    </div>
  )
}
