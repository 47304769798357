
// @flow
import type { AppState } from '../../../../types'
import { connect } from 'react-redux'
import type { ComponentType } from 'react'
import Breadcrumbs from './Breadcrumbs'
import { isEmailValid, isPostcodeValid, isAddressValid } from '../../../../helpers/address'
import { getAreAllBasketItemsValid } from '../../../../selectors/manualOrderForm'

type OwnProps = {|
  pathname: string
|}

function mapStateToProps (state: AppState) {
  const { inProgressOrder, customer, deliveryCountry } = state.manualOrderForm

  return {
    postcodeIsValid: isPostcodeValid(deliveryCountry, customer.postcode),
    emailIsValid: isEmailValid(customer.email),
    customer: customer.name,
    items: inProgressOrder.itemsToBeCreated.filter(x => x.sku).length,
    deliveryCountry,
    countries: state.manualOrderForm.countries,
    addressIsValid: isAddressValid({...customer, countryCode: deliveryCountry}),
    allItemsValid: getAreAllBasketItemsValid(state)
  }
}

export default (connect(mapStateToProps)(Breadcrumbs): ComponentType<OwnProps>)
