/**
 * Removes the date string that is appended to the beginning of a comment
 * @example
 * // returns 'Issue created'.
 * formatIssueComment('24/11/2022 @ 15:49 : Issue created');
 * @returns {String} The comment without the date string
 */

export function formatIssueComment({ comment }: { comment: string }): string {
  return comment.substring(comment.indexOf(' : ') + 3)
}
