// @flow
import { RSAA_ACTION_NAME } from '../data/rsaa'
import { LOG_LE } from '../actions/logging'
import type { MiddlewareAPI, Dispatch } from 'redux'
import type { RsaaActionShape, AppState, Action } from '../types'
import { getAccessToken } from '../actions/auth'
import { KITE_LOGOUT } from '../actions/auth/kiteLogout'
import { AUTH_HEADER_PREFIX } from '../data/constants'

const actionsToDispatchWithoutAuthHeader = [LOG_LE, KITE_LOGOUT]

type RsaaAction = RsaaActionShape<*, *, *, *, *>

export default function (store: MiddlewareAPI<AppState, Action>): (next: any) => (action: Action) => Promise<any> {
  return function (next: Dispatch<Action>) {
    return async function (action: Action) {
      const rsaaAction = action[RSAA_ACTION_NAME]

      if (rsaaAction) {
        const token = getAccessToken()

        if (!token) {
          return next(action)
        }

        if (isAccessTokenNeeded(rsaaAction)) {
          rsaaAction.headers = {
            ...rsaaAction.headers,
            Authorization: AUTH_HEADER_PREFIX + token || ''
          }
        }
      }

      return next(action)
    }
  }
}

function isAccessTokenNeeded(rsaaAction: RsaaAction): boolean {
  const firstType = rsaaAction.types[0]
  return !(
    actionsToDispatchWithoutAuthHeader.includes(firstType.type) ||
    actionsToDispatchWithoutAuthHeader.includes(firstType)
  )
}
