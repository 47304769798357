// @flow

type Currencies = {
  [key: string]: {|
    name: string,
    symbol: string
  |}
}

export const currencies: Currencies = {
  'GBP': {
    name: 'GB Pounds',
    symbol: '£'
  },
  'USD': {
    name: 'US Dollars',
    symbol: '$'
  },
  'EUR': {
    name: 'Euro',
    symbol: '€'
  }
}

export const CURRENCY_CODE = {
  USD: 'USD',
  GBP: 'GBP',
  EUR: 'EUR'
}
