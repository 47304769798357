import { isCountyOrStateRequired, isPostcodeRequired, isPostcodeValid } from '../../../helpers'

export function areCompanyDetailsValid({
  companyName,
  addressLine1,
  city,
  county,
  postCode,
  countryCode
}: {
  companyName?: string
  addressLine1?: string
  city?: string
  county?: string
  postCode?: string
  countryCode: string
}) {
  const isCompanyNameValid = Boolean(companyName)
  const isAddressLine1Valid = Boolean(addressLine1)
  const isCityValid = Boolean(city)
  const isCountyValid = isCountyOrStateRequired(countryCode) ? Boolean(county) : true
  const isPostcodeValueValid = isPostcodeRequired(countryCode) ? isPostcodeValid(countryCode, postCode ?? '') : true

  return isCompanyNameValid && isAddressLine1Valid && isCityValid && isCountyValid && isPostcodeValueValid
}
