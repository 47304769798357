// @flow
import type { ThunkAsync, DispatchFunc, GetStateFunc, Dictionary } from '../../types'
import { fetchTemplate, FETCH_TEMPLATE_SUCCESS } from '../images'

export const FETCH_PREVIEW_TEMPLATE = 'FETCH_PREVIEW_TEMPLATE'
export const FAILED_PREVIEW_TEMPLATE = 'FAILED_PREVIEW_TEMPLATE'

export function fetchPreviewTemplate (sku: string, itemId: number, attributes: Dictionary<string>): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(fetchTemplateStart(itemId, sku, attributes))

    const actionResult = await dispatch(fetchTemplate(sku, attributes))

    if (actionResult.type !== FETCH_TEMPLATE_SUCCESS) {
      dispatch(fetchTemplateError(itemId, actionResult.payload))
    }
  }
}

function fetchTemplateStart (itemId: number, sku: string, attributes: Dictionary<string>) {
  return {
    sku,
    itemId,
    attributes,
    type: FETCH_PREVIEW_TEMPLATE
  }
}

function fetchTemplateError (itemId: number, errorDetails: Dictionary<string>) {
  return {
    itemId,
    errorDetails,
    type: FAILED_PREVIEW_TEMPLATE
  }
}
