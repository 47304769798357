export function formatCost(
  { amount, currencyCode }: { amount?: string; currencyCode?: string },
  options: Intl.NumberFormatOptions | undefined = {}
) {
  if ((!amount && amount !== '0') || !currencyCode) {
    return null
  }

  const price = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    useGrouping: true,
    currency: currencyCode,
    ...options
  })

  // fix for a bug in Edge - displaying AUD as 'A$' not '$'
  if (currencyCode === 'AUD' && price[0] !== 'A') {
    return 'A' + price
  }

  return price
}
