// @flow
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../../types'
import { NO_SELECTION } from '../../data/catalogue'
import { searchProducts } from './searchProducts'
import { getProductTypes } from './getProductTypes'
import { resetProductSearchResults } from './catalogue'

export const SELECT_ITEM_CATEGORY = 'SELECT_ITEM_CATEGORY'

export function selectItemCategory (category: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const query = getState().catalogue.searchQuery

    await dispatch({ type: SELECT_ITEM_CATEGORY, category })

    if (category !== NO_SELECTION) {
      await dispatch(getProductTypes())
    } else if (category === NO_SELECTION && !query) {
      dispatch(resetProductSearchResults())
    } else if (category === NO_SELECTION && query) {
      await dispatch(searchProducts(query))
    }
  }
}
