import { useState } from 'react'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'

import Button from '../../Button'
import { useMerchantService } from '../../../hooks'
import { SalesChannelSettingsModal } from './SalesChannelSettingsModal.component'

export default function SalesChannelSettingsModalButton({ salesChannelId }: { salesChannelId: string }) {
  const { merchantDetails } = useMerchantService()
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)

  const salesChannel = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)

  if (!merchantDetails) {
    throw new Error('No merchant details')
  }

  if (!salesChannel) {
    throw new Error('No sales channel found')
  }

  return (
    <>
      <Button
        startIcon={<Cog8ToothIcon className="h-7 w-7" />}
        theme="greyscale"
        variant="tertiary"
        onClick={() => setIsSettingsModalOpen(true)}
      >
        Settings
      </Button>

      <SalesChannelSettingsModal
        isSettingsModalOpen={isSettingsModalOpen}
        salesChannel={salesChannel}
        setIsSettingsModalOpen={setIsSettingsModalOpen}
      />
    </>
  )
}
