import { useEffect, useState } from 'react'
import { PencilSquareIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import {
  CopyDetails,
  PrintQuality,
  VariantDataError,
  VariantDataLoading,
  VariantImageEditorModal,
  VariantSelectProductModal
} from '.'
import Button from '../../Button'
import PrintAreaSelector from '../../PrintAreaSelector'
import { calculatePrintQuality } from '../../ImageEditor'
import ImageEditorPreview from '../../ImageEditorPreview'
import { formatPrice, isNoImageProduct } from '../../../helpers'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import ImageLibraryFileTypePill from '../../ImageLibraryFileTypePill'
import { ProductDetailsModalButton } from './ProductDetailsModalButton.component'
import { VariantDataInterface, VariantsSkuDataType } from '../SalesChannelProduct.component'
import { useProductDetails, useProductSearch, useSplitToggle, useTemplateService, useUser } from '../../../hooks'
import { OrderDetailAttributesDisplay } from '../../OrderDetailShipmentDisplay/components/OrderDetailAttributesDisplay.component'

interface VariantDataPropsInterface {
  allVariantsData: Record<string, VariantDataInterface>
  variantData: VariantDataInterface
  variantsSkuData: VariantsSkuDataType
  onChangeVariantData: (newVariantData: VariantDataInterface) => void
  onLoadSkuData: (skuData: { variantId: string; productType: string }) => void
}

export function VariantData({
  allVariantsData,
  variantData,
  variantsSkuData,
  onChangeVariantData,
  onLoadSkuData
}: VariantDataPropsInterface) {
  const { user } = useUser()
  const [variantModalToOpen, setVariantModalToOpen] = useState<'select-product' | 'image-editor' | null>(null)
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })

  const countryCode = user?.company.countryCode

  const {
    searchResults,
    isLoading: isLoadingSearchResults,
    error: searchResultsFetchError
  } = useProductSearch({
    countryCode,
    query: variantData?.selectedProductData?.sku,
    config: { revalidateOnFocus: false }
  })

  const skuSearchResult = searchResults?.find(
    (searchResult) => searchResult.sku.toUpperCase() === variantData?.selectedProductData?.sku.toUpperCase()
  )

  useEffect(() => {
    if (!skuSearchResult?.productType) {
      return
    }
    onLoadSkuData({ variantId: variantData.id, productType: skuSearchResult?.productType })
  }, [onLoadSkuData, skuSearchResult?.productType, variantData.id])

  const {
    productDetails,
    isLoading: isLoadingProductDetails,
    error: productDetailsFetchError
  } = useProductDetails({
    sku: variantData?.selectedProductData?.sku,
    config: { revalidateOnFocus: false }
  })
  const {
    templates,
    isLoading: isLoadingTemplates,
    error: templatesFetchError
  } = useTemplateService({
    sku: variantData?.selectedProductData?.sku,
    attributes: variantData?.selectedProductData?.attributes ?? undefined,
    config: { revalidateOnFocus: false },
    enableNoImageProductCheck: true,
    productType: skuSearchResult?.productType
  })

  if (!user || !countryCode) {
    throw Error('No user')
  }

  const productPrintAreas = Object.keys(productDetails?.product.printAreas ?? {})
  const validSelectedPrintArea =
    variantData?.selectedPrintArea && productPrintAreas.includes(variantData.selectedPrintArea)
      ? variantData.selectedPrintArea
      : productPrintAreas[0]
  const hasProduct = variantData.selectedProductData && Boolean(variantData.selectedProductData)

  const selectedArtwork = validSelectedPrintArea && variantData?.artworkData?.[validSelectedPrintArea]?.artwork
  const selectedArtworkTransformations =
    validSelectedPrintArea && variantData?.artworkData?.[validSelectedPrintArea]?.artworkTransformations
  const printQuality =
    selectedArtwork && validSelectedPrintArea && templates
      ? calculatePrintQuality({
          artwork: selectedArtwork,
          artworkTransformations: selectedArtworkTransformations || undefined,
          category: skuSearchResult?.category,
          printAreaName: validSelectedPrintArea,
          templates
        })
      : null

  const requiredPrintAreasWithoutArtwork = productPrintAreas.filter((printAreaName) => {
    if (!productDetails?.product?.printAreas?.[printAreaName]?.required) {
      return false
    }
    const noArtworkForPrintArea = !variantData?.artworkData?.[printAreaName]?.printImageUrl
    return noArtworkForPrintArea
  })

  const productModals = (
    <>
      <VariantSelectProductModal
        countryCode={countryCode}
        isSelectProductModalOpen={variantModalToOpen === 'select-product'}
        variantData={variantData}
        onSelectProductModalOpenChange={(isOpen) => {
          if (isOpen) {
            setVariantModalToOpen('select-product')
          } else {
            setVariantModalToOpen(null)
          }
        }}
        onSelectProductSuccess={(nextStepToOpen) => {
          setVariantModalToOpen(nextStepToOpen)
        }}
      />

      <VariantImageEditorModal
        countryCode={countryCode}
        isImageEditorModalOpen={variantModalToOpen === 'image-editor'}
        variantData={variantData}
        validSelectedPrintArea={validSelectedPrintArea}
        onImageEditorModalOpenChange={(isOpen) => {
          if (isOpen) {
            setVariantModalToOpen('image-editor')
          } else {
            setVariantModalToOpen(null)
          }
        }}
        onSaveImageSuccess={() => setVariantModalToOpen(null)}
      />
    </>
  )

  function handleChooseProductOnError() {
    setVariantModalToOpen('select-product')
  }

  if (productDetailsFetchError) {
    return (
      <>
        <VariantDataError
          errorCode={`PDFE-${variantData?.selectedProductData?.sku ?? '0'}-${
            productDetailsFetchError.responseBodyJson?.traceParent ??
            productDetailsFetchError.status ??
            productDetailsFetchError.message
          }`}
          onChooseProduct={handleChooseProductOnError}
        />
        {productModals}
      </>
    )
  }

  if (templatesFetchError) {
    return (
      <>
        <VariantDataError
          errorCode={`TSFE-${variantData?.selectedProductData?.sku ?? '0'}-${
            templatesFetchError.status ?? templatesFetchError.message
          }`}
          onChooseProduct={handleChooseProductOnError}
        />
        {productModals}
      </>
    )
  }

  if (searchResultsFetchError || (searchResults && !skuSearchResult)) {
    return (
      <>
        <VariantDataError
          errorCode={`SRFE-${variantData?.selectedProductData?.sku ?? '0'}-${
            searchResultsFetchError?.status ?? searchResultsFetchError?.message ?? '0'
          }`}
          onChooseProduct={handleChooseProductOnError}
        />
        {productModals}
      </>
    )
  }

  const isProductDataLoading = hasProduct && (isLoadingProductDetails || isLoadingTemplates || isLoadingSearchResults)
  if (isProductDataLoading) {
    return (
      <div className="p-8">
        <VariantDataLoading showToggleSkeleton={false} />
        {productModals}
      </div>
    )
  }

  if (!hasProduct) {
    return (
      <div className="bg-[#E3F6FF] p-8">
        <div className="ml-4 md:pl-[36px]">
          <div className="text-center font-medium text-gray-800 md:text-left">
            Add a product and an image to have Prodigi fulfil this item automatically.
          </div>
          <div className="mx-auto mt-6 w-fit bg-white md:mx-0">
            <Button
              size="sm"
              startIcon={<MagnifyingGlassIcon className="h-7 w-7" />}
              variant="secondary"
              onClick={() => setVariantModalToOpen('select-product')}
            >
              Choose product
            </Button>
          </div>
        </div>

        {productModals}
      </div>
    )
  }

  return (
    <div>
      {requiredPrintAreasWithoutArtwork.length > 0 && (
        <div className="bg-[#E3F6FF] p-8">
          <div className="ml-4 md:pl-[36px]">
            <NoArtworkMessage
              productPrintAreas={productPrintAreas}
              requiredPrintAreasWithoutArtwork={requiredPrintAreasWithoutArtwork}
            />
          </div>
        </div>
      )}

      <div className="p-8">
        <div className="ml-4 flex flex-col items-center gap-4 text-center md:flex-row md:items-start md:pl-[36px] md:text-left">
          <div className="flex w-64 flex-col justify-center gap-3">
            <>
              <ImageEditorPreview
                artwork={selectedArtwork || undefined}
                artworkTransformations={selectedArtworkTransformations || undefined}
                attributes={variantData.selectedProductData?.attributes ?? undefined}
                countryCode={countryCode}
                enableImageLibraryThumbnailRefresh={true}
                selectedPrintArea={validSelectedPrintArea}
                sku={variantData.selectedProductData?.sku}
              />

              {skuSearchResult?.productType && !isNoImageProduct(skuSearchResult.productType) && (
                <div>
                  <Button
                    className="mx-auto"
                    size="sm"
                    startIcon={<PencilSquareIcon className="h-6 w-6" />}
                    variant="secondary"
                    onClick={() => setVariantModalToOpen('image-editor')}
                  >
                    Edit
                  </Button>
                </div>
              )}

              <div className="mt-4 flex items-center justify-center gap-4">
                {selectedArtwork && <ImageLibraryFileTypePill fileType={selectedArtwork.fileType} />}
                {printQuality && <PrintQuality printQualityLabel={printQuality.label} />}
              </div>

              {productPrintAreas.length > 1 && validSelectedPrintArea && (
                <PrintAreaSelector
                  className="mt-4 border-t border-gray-200 pt-4"
                  printAreas={productPrintAreas}
                  selectedPrintArea={validSelectedPrintArea}
                  onPrintAreaChange={(newPrintArea) => {
                    onChangeVariantData({
                      ...variantData,
                      selectedPrintArea: newPrintArea
                    })
                  }}
                />
              )}
            </>
          </div>

          <div className="flex flex-col items-center gap-3 md:items-start">
            <div className="text-md max-w-[60ch] text-black">{productDetails?.product.description}</div>
            <div className="text-sm text-gray-600">{variantData?.selectedProductData?.sku}</div>
            {isShopifySplitOn && skuSearchResult && skuSearchResult.basePriceFrom && (
              <div
                className="cursor-help text-sm text-gray-600"
                title="Item price may vary at the point of ordering due to tax, shipping and fulfilment location."
              >
                {formatPrice(skuSearchResult.sku, skuSearchResult.currency, skuSearchResult.basePriceFrom)} *
              </div>
            )}
            {Object.keys(variantData?.selectedProductData?.attributes ?? {}).length > 0 && (
              <OrderDetailAttributesDisplay
                attributes={variantData?.selectedProductData?.attributes ?? {}}
                sku={variantData?.selectedProductData?.sku}
                category={skuSearchResult?.category}
              />
            )}
            <div className="flex items-center gap-4">
              <Button size="sm" variant="text" onClick={() => setVariantModalToOpen('select-product')}>
                Change
              </Button>
              {productDetails && (
                <>
                  <hr className="h-[1em] w-1 border-l border-t-0 border-gray-500" />
                  <ProductDetailsModalButton productDetails={productDetails} />
                </>
              )}
            </div>
          </div>

          {hasProduct && Object.keys(allVariantsData).length > 1 && (
            <div className="md:ml-auto">
              <CopyDetails
                allVariantsData={allVariantsData}
                variantDataToCopy={variantData}
                variantsSkuData={variantsSkuData}
              />
            </div>
          )}
        </div>
      </div>

      {productModals}
    </div>
  )
}

function NoArtworkMessage({
  productPrintAreas,
  requiredPrintAreasWithoutArtwork
}: {
  productPrintAreas: string[]
  requiredPrintAreasWithoutArtwork: string[]
}) {
  const hasAllRequiredImages = requiredPrintAreasWithoutArtwork.length === 0

  if (hasAllRequiredImages) {
    return null
  }

  const productHasOnePrintArea = productPrintAreas.length === 1

  if (productHasOnePrintArea) {
    return <>Add an image to have Prodigi fulfil this item automatically.</>
  }

  const hasLastRemainingPrintAreaWithoutArtwork = requiredPrintAreasWithoutArtwork.length === 1
  if (hasLastRemainingPrintAreaWithoutArtwork) {
    return <>Add an image for {requiredPrintAreasWithoutArtwork[0]} to have Prodigi fulfil this item automatically.</>
  }

  const requirePrintAreaNamesToDisplay = requiredPrintAreasWithoutArtwork.join(', ')

  return <>Add images for {requirePrintAreaNamesToDisplay} to have Prodigi fulfil this item automatically.</>
}
