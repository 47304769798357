// @flow
import type { V4ProductDetails, Dictionary } from '../../types'
import { getRequiredAttributeNames } from '../v4ProductDetails'
import { mapV4AttributeNameToV3 } from './mapV4AttributeNameToV3'

export function mapV3AttributesToV4(
  v3Attributes: Dictionary<string>,
  productDetails: V4ProductDetails,
  costedAttributeKeys?: string[] = []
): Dictionary<string> {
  const requiredAttributeNames = getRequiredAttributeNames(productDetails)

  return requiredAttributeNames.reduce((attributeAcc, requiredAttributeName) => {
    const v3AttributeName = mapV4AttributeNameToV3(requiredAttributeName)
    let attributeValue = v3Attributes[v3AttributeName]

    if (!attributeValue) {
      const costedAttributeMatch = costedAttributeKeys.find(
        (costedAttributeKey) => costedAttributeKey.toLowerCase() === requiredAttributeName.toLowerCase()
      )
      attributeValue = costedAttributeMatch ? v3Attributes[costedAttributeMatch] : attributeValue
    }

    attributeAcc[requiredAttributeName] = attributeValue
    return attributeAcc
  }, {})
}
