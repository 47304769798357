import { useLocalStorage } from '@mantine/hooks'
import { PencilIcon } from '@heroicons/react/24/outline'
import { AnimatePresence, motion } from 'framer-motion'

import Button from '../../Button'
import { UploadingImageInterface } from '../interfaces'
import { UploadOptionValueType } from '../../ImageLibrary'
import { UploadChoiceFullPageDisplay, ManageActions, UploadOptions } from '.'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface ImageLibraryHeaderProps {
  imageLibraryMode: ImageLibraryFullPageModeType
  selectedImages: ImageLibraryImageInterface[]
  onHandleUploadStart: () => void
  onHandleUploadComplete: () => void
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
  onSetUploadingFiles: (files: React.SetStateAction<Record<string, UploadingImageInterface>>) => void
}

export function FullPageHeader({
  imageLibraryMode,
  selectedImages,
  onHandleUploadStart,
  onHandleUploadComplete,
  onSetImageLibraryMode,
  onSetUploadingFiles
}: ImageLibraryHeaderProps) {
  const { imageLibraryPages, isLoadingImageLibraryImages } = useImageLibraryImages()
  const [uploadChoice, setUploadChoice] = useLocalStorage<UploadOptionValueType>({
    key: 'prodigiImageLibraryUploadChoice',
    defaultValue: 'file',
    getInitialValueInEffect: false
  })

  const isImageListEmpty = imageLibraryPages?.[0]?.length === 0
  const selectedImagesCount = selectedImages.length
  const selectedImageText = selectedImagesCount > 1 ? 'images selected' : 'image selected'

  return (
    <header className="flex w-full flex-wrap justify-between gap-8 pt-4 sm:gap-4">
      <div className="flex flex-col flex-wrap gap-6 sm:flex-row sm:items-center">
        <UploadOptions
          manageLibrary={imageLibraryMode === 'manage'}
          uploadChoice={uploadChoice}
          setUploadChoice={setUploadChoice}
        >
          <UploadChoiceFullPageDisplay
            key={uploadChoice}
            type="artwork"
            uploadChoice={uploadChoice}
            onUploadStart={onHandleUploadStart}
            onUploadComplete={onHandleUploadComplete}
            onSetUploadingFiles={onSetUploadingFiles}
          />
        </UploadOptions>
      </div>

      <AnimatePresence>
        {!isImageListEmpty && !isLoadingImageLibraryImages && (
          <motion.div
            className="flex"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {imageLibraryMode === 'select' && (
              <Button
                variant="secondary"
                theme="brand"
                onClick={() => {
                  window.analytics.track('Image library full page - clicked: Select images')
                  onSetImageLibraryMode('manage')
                }}
              >
                <span className="hidden md:inline">Select</span>
                <span className="md:hidden">
                  <PencilIcon className="h-8 w-8" />
                </span>
              </Button>
            )}

            {imageLibraryMode === 'manage' && (
              <div className="flex flex-wrap items-center gap-8">
                {selectedImagesCount > 0 && (
                  <span>
                    {selectedImagesCount} {selectedImageText}
                  </span>
                )}
                <ManageActions selectedImages={selectedImages} onSetImageLibraryMode={onSetImageLibraryMode} />
                <Button
                  variant="tertiary"
                  theme="greyscale"
                  className="hover:border-transparent"
                  onClick={() => onSetImageLibraryMode('select')}
                >
                  Cancel
                </Button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  )
}
