// @flow
import clsx from 'clsx'
import type { ReactRef, PrintAreaEntries, ThumbnailItem, Event } from '../../types'
import React, { PureComponent } from 'react'
import styles from './MultiAssetImageUpload.module.css'
import DropDown from '../DropDown/DropDown'
import DropDownItem from '../DropDown/DropDownItem'
import Button from '../Button'
import DropboxUpload from '../DropboxUpload'
import UploadFromUrl from './UploadFromUrl'
import LoadingEllipsis from '../LoadingEllipsis'
import PrintAreaSwitcher from '../PrintAreaSwitcher'
import cx from 'classnames'
import ToggleSwitch from '../ToggleSwitch'
import type { Artwork } from '../../types/images'

// $FlowFixMe: TypeScript file
import { ImageLibraryButton } from './ImageLibraryButton.component'
// $FlowFixMe: TypeScript file
import ImageEditorPreview from '../../v2/components/ImageEditor/components/ImageEditorPreview.component.tsx'
// $FlowFixMe: TypeScript file
import { ImageLibraryFileTypePill } from '../../v2/components/ImageLibraryFileTypePill/ImageLibraryFileTypePill.component.tsx'

type Props = {|
  isImageEditorAvailable: boolean,
  showEditButton: boolean,
  showRemoveImageButton: boolean,
  hasImageUploadError: boolean,
  imageUploadErrorMessage: string,
  imageUrl: ?string,
  imageQuality: ?number,
  thumbnailItem: ?ThumbnailItem,
  selectedPrintArea: string,
  printAreaEntries: ?PrintAreaEntries,
  fileInputRef: ReactRef<HTMLInputElement>,
  imageEditorKey: string,
  itemCategory: string,
  productType: string,
  printResolutionLabel: string,
  isLoadingPreview: boolean,
  isUploadingImage: boolean,
  isFetchingTemplate: boolean,
  isUrlUploadModalOpen: boolean,
  showSaveAndCloseButton?: boolean,
  noArtworkBackgroundImageStyle?: ?string,
  optimalResolution?: ?string,
  isTilingEnabled: boolean,
  supportsTiling: boolean,
  hasArtworkError: boolean,
  isLoadingImageContainerClassName?: string,
  onOpenImageLibrary?: () => void,
  toggleTiling: () => void,
  saveAndCloseAction?: () => void,
  toggleUrlUploadModal: () => void,
  onEditImageClick: () => void,
  onRemoveImage?: () => void,
  uploadArtworkFromUrl: (url: string) => void,
  openFileUpload: () => void,
  onImageError: () => void,
  onImageLoaded: () => void,
  onLoadArtwork: () => void,
  onLoadOverlay: () => void,
  onArtworkError: () => void,
  onOverlayError: () => void,
  onUnderlayError: () => void,
  onPrintAreaChange?: (newPrintAreaName: string) => void,
  uploadFile: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onSelectImageLibraryImage: (artwork: Artwork) => void
|}

export default class ImageUpload extends PureComponent<Props> {
  stopPropagation: (e: Event) => void = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }

  render(): React$Node {
    const imageEditorPreviewContainerClassName = cx({
      [styles.visible]: !this.props.isLoadingPreview,
      [styles.hidden]: this.props.isLoadingPreview
    })
    return (
      <React.Fragment>
        <div className={styles.container} data-test="image-upload-container">
          <form>
            <input
              key={this.props.imageEditorKey}
              type="file"
              ref={this.props.fileInputRef}
              accept=".png, .jpg, .jpeg"
              className={styles.noDisplay}
              onChange={this.props.uploadFile}
              data-test="image-upload-input"
            />
          </form>

          <div
            className={cx(
              styles.imageContainer,
              this.props.isLoadingPreview ? this.props.isLoadingImageContainerClassName : ''
            )}
            data-test="image-container"
          >
            {this.props.isLoadingPreview && (
              <div className={styles.loading} data-test="image-upload-preview-loading">
                Loading preview
                <LoadingEllipsis className={styles.loadingEllipsis} />
              </div>
            )}
            {this.props.isImageEditorAvailable && !this.props.hasImageUploadError && this.props.thumbnailItem && (
              <>
                <div
                  className={imageEditorPreviewContainerClassName}
                  style={{ display: 'flex', justifyContent: 'center', marginBottom: '4px' }}
                >
                  {this.props.thumbnailItem?.artwork?.mimeType === 'application/pdf' ? (
                    <div className="tailwind">
                      <div className="flex flex-col items-center">
                        <img
                          className="h-[220px] w-[220px] object-contain"
                          src={this.props.thumbnailItem.artwork.mediumImageUrl}
                        />
                      </div>
                    </div>
                  ) : (
                    <ImageEditorPreview
                      key={this.props.imageEditorKey}
                      isPreviewOnly={true}
                      dataTest="image-upload-preview"
                      cropRectangle={this.props.thumbnailItem.cropRectangle}
                      transformations={this.props.thumbnailItem.transformations}
                      printDimensionsInPx={this.props.thumbnailItem.printDimensionsInPx}
                      artworkDimensionsInPx={this.props.thumbnailItem.artworkDimensionsInPx}
                      imageUrls={this.props.thumbnailItem.imageUrls}
                      printDpi={this.props.thumbnailItem.printDpi}
                      onLoadArtwork={this.props.onLoadArtwork}
                      onLoadOverlay={this.props.onLoadOverlay}
                      onArtworkError={this.props.onArtworkError}
                      onOverlayError={this.props.onOverlayError}
                      onUnderlayError={this.props.onUnderlayError}
                    />
                  )}
                </div>
              </>
            )}
          </div>

          {!this.props.isImageEditorAvailable && !this.props.hasArtworkError && (
            <div className={styles.redText}>
              <b>The image editor is currently unavailable.</b>
              <div>Your image will be centred and will cover the entire print area.</div>
            </div>
          )}

          {this.props.hasArtworkError && (
            <div className={styles.redText}>
              <b>The supplied image is not valid.</b>
              <div>Please upload a new image or contact support if the issue persists.</div>
            </div>
          )}

          {!this.props.showRemoveImageButton && (
            <div
              className={cx(styles.addImageContainer, {
                [styles.noHeight]: !this.props.isImageEditorAvailable || !this.props.noArtworkBackgroundImageStyle
              })}
              style={{
                backgroundImage:
                  !this.props.showRemoveImageButton && this.props.noArtworkBackgroundImageStyle
                    ? this.props.noArtworkBackgroundImageStyle
                    : 'unset'
              }}
            >
              {this.props.onSelectImageLibraryImage ? (
                <ImageLibraryButton
                  productType={this.props.productType}
                  onSelectImageLibraryImage={this.props.onSelectImageLibraryImage}
                  onOpenImageLibrary={this.props.onOpenImageLibrary}
                >
                  {!this.props.imageUrl ? 'Add image' : 'Change image'}
                </ImageLibraryButton>
              ) : (
                <DropDown
                  text={!this.props.imageUrl ? 'Add image' : 'Change image'}
                  buttonDataTest={!this.props.imageUrl ? 'add-image-button' : 'change-image-button'}
                  className={styles.addImageButton}
                >
                  <React.Fragment>
                    {this.props.supportsTiling && (
                      <div className={styles.tiling} onClick={this.stopPropagation} data-test="tiling-toggle-switch">
                        <span>Enable tiling</span>
                        <ToggleSwitch
                          ariaLabel="Tiling"
                          onChange={this.props.toggleTiling}
                          checked={this.props.isTilingEnabled}
                        />
                      </div>
                    )}
                    <DropDownItem
                      text="Upload from device"
                      icon="file"
                      onClick={this.props.openFileUpload}
                      dataTest="upload-from-device"
                    />
                    <DropDownItem
                      text="Upload from URL"
                      icon="cloud-download"
                      onClick={this.props.toggleUrlUploadModal}
                      dataTest="upload-from-url"
                    />
                    <DropboxUpload uploadArtworkFromUrl={this.props.uploadArtworkFromUrl} />
                  </React.Fragment>
                </DropDown>
              )}
            </div>
          )}

          <div className={styles.imageActionsContainer}>
            {this.props.showEditButton && (
              <Button
                className={styles.editPositionButton}
                onClick={this.props.onEditImageClick}
                dataTest="image-uploaded-edit-button"
              >
                Edit
              </Button>
            )}

            {this.props.showRemoveImageButton && this.props.onRemoveImage && (
              <Button icon="trash" destructive onClick={this.props.onRemoveImage}></Button>
            )}
          </div>

          {this.props.printAreaEntries && this.props.onPrintAreaChange && this.props.printAreaEntries.length > 1 && (
            <div className={styles.printAreaSwitcherContainer}>
              <PrintAreaSwitcher
                itemCategory={this.props.itemCategory}
                selectedPrintArea={this.props.selectedPrintArea}
                printAreaEntries={this.props.printAreaEntries}
                onPrintAreaChange={this.props.onPrintAreaChange}
              />
            </div>
          )}

          {this.props.hasImageUploadError && (
            <div className={styles.errorContainer}>{this.props.imageUploadErrorMessage}</div>
          )}

          {(this.props.optimalResolution || this.props.showSaveAndCloseButton) && !this.props.thumbnailItem?.artwork && (
            <div className={styles.infoContainer} data-test="image-upload-optimal-resolution">
              <div>
                {this.props.optimalResolution && (
                  <>
                    <div>{this.props.printResolutionLabel}</div>
                    <div>
                      <strong>{this.props.optimalResolution}</strong>
                    </div>
                  </>
                )}
              </div>

              {this.props.showSaveAndCloseButton && (
                <Button dataTest="save-image-and-close" primary size="small" onClick={this.props.saveAndCloseAction}>
                  Save and close
                </Button>
              )}
            </div>
          )}
        </div>

        {(this.props.thumbnailItem?.artwork?.fileType || this.props.imageQuality) && (
          <div className="tailwind">
            <div className="mt-4 flex items-center justify-center gap-4">
              {this.props.thumbnailItem?.artwork?.fileType && (
                <ImageLibraryFileTypePill fileType={this.props.thumbnailItem?.artwork?.fileType} />
              )}
              {this.props.imageQuality && <PrintQuality qualityValue={this.props.imageQuality} />}
            </div>
          </div>
        )}

        {this.props.isUrlUploadModalOpen && (
          <UploadFromUrl
            uploadImage={this.props.uploadArtworkFromUrl}
            imageUrl={this.props.imageUrl}
            closeModal={this.props.toggleUrlUploadModal}
          />
        )}
      </React.Fragment>
    )
  }
}

export function PrintQuality({ qualityValue }: {| qualityValue?: number |}): null | React$Element<'span'> {
  if (!qualityValue) {
    return null
  }

  const printQualityLabel = getQualityLabel(qualityValue)

  return (
    <span
      title="Image quality"
      className={clsx('text-sm', {
        'text-green-700': printQualityLabel === 'Excellent',
        'text-status-orange': printQualityLabel === 'Acceptable',
        'text-red-700': printQualityLabel === 'Poor'
      })}
    >
      {printQualityLabel}
    </span>
  )
}

function getQualityLabel(qualityValue: number): 'Excellent' | 'Acceptable' | 'Poor' {
  if (qualityValue >= 100) {
    return 'Excellent'
  }
  if (qualityValue >= 50) {
    return 'Acceptable'
  }
  return 'Poor'
}
