// @flow

import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import Stack from './layout/Stack'

type Props = {|
  loadingText?: string,
  className?: string,
  stackClassName?: string,
  dataTest?: string
|}

type State = {|
  showSlowConnectionMessage: boolean
|}

class LoadingIndicator extends Component<Props, State> {
  timer: TimeoutID

  state: State = {
    showSlowConnectionMessage: false
  }

  componentDidMount () {
    this.timer = setTimeout(() => {
      this.setState({ showSlowConnectionMessage: true })
    }, 5000)
  }

  componentWillUnmount () {
    clearTimeout(this.timer)
  }

  render (): React$Node {
    return (
      <div style={this.props.className ? {} : styles.wrapper} className={this.props.className ?? ''} data-test={this.props.dataTest ?? 'loading-indicator'} data-testid="loading-indicator">
        <Stack align='center' className={this.props.stackClassName}>
          <React.Fragment>
            <FontAwesome name="circle-o-notch fa-spin" style={styles.icon} />
            <p style={styles.text}>{this.props.loadingText || 'Loading'}...</p>
            {this.state.showSlowConnectionMessage && <p style={styles.text}>{`If you're on a slow connection this might take a while...`}</p>}
          </React.Fragment>
        </Stack>
      </div>
    )
  }
}

const styles = {
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  },
  icon: {
    fontSize: '5.6rem',
    color: 'var(--color-cool-grey-50)'
  },
  text: {
    fontsize: '1.6rem',
    color: 'var(--color-cool-grey-50)',
    marginBottom: 0
  }
}

export default LoadingIndicator
