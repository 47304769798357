import { Dispatch, FormEvent, MouseEvent } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { RegisterReducerActionType } from '../types'

import Step1 from './Step1.component'
import Step2 from './Step2.component'
import Step3 from './Step3.component'
import Step4 from './Step4.component'

import { AnimatePresence } from 'framer-motion'

import { RegisterFormDataInterface } from '../interfaces'

interface PropTypesInterface {
  autoFocusFormField: boolean
  accountCreationLoading: boolean
  accountCreationError: string | null
  formData: RegisterFormDataInterface
  onStepChange: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, stepNumber: number) => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  registerFormDispatch: Dispatch<RegisterReducerActionType>
  stepNumber: number
}

export default function LeftPanel({
  accountCreationError,
  accountCreationLoading,
  autoFocusFormField,
  formData,
  onStepChange,
  onSubmit,
  registerFormDispatch,
  stepNumber
}: PropTypesInterface) {
  return (
    <div className="relative mx-auto flex min-h-screen w-full flex-col p-14 pt-10 lg:w-1/2 lg:p-36 lg:pt-16">
      <a href="https://www.prodigi.com" title="Prodigi" className="mb-16 block w-fit">
        <img
          src="/img/logo/prodigi-logo-dark.svg"
          className="h-[47] w-[120px] lg:h-[60] lg:w-[150px]"
          alt="Prodigi logo"
        />
      </a>

      {stepNumber === 1 && (
        <h2 className="m-0 mb-2 text-2xl leading-10 tracking-tight text-gray-900">Create an account</h2>
      )}

      {PANEL_TEXT[stepNumber - 1] && <p className="mt-0">{PANEL_TEXT[stepNumber - 1].text}</p>}

      <div className="relative mt-12">
        <AnimatePresence exitBeforeEnter>
          {stepNumber === 1 && (
            <Step1
              autoFocusFormField={autoFocusFormField}
              accountCreationError={accountCreationError}
              email={formData.email}
              password={formData.password}
              onStepChange={onStepChange}
              registerFormDispatch={registerFormDispatch}
            >
              <SignInContent />
            </Step1>
          )}

          {stepNumber === 2 && (
            <Step2
              autoFocusFormField={autoFocusFormField}
              fullName={formData.fullName}
              companyName={formData.companyName}
              marketingWhyCreatingAccount={formData?.marketingWhyCreatingAccount}
              onStepChange={onStepChange}
              registerFormDispatch={registerFormDispatch}
            >
              <SignInContent />
            </Step2>
          )}

          {stepNumber === 3 && (
            <Step3
              onStepChange={onStepChange}
              marketingPriorityToday={formData?.marketingPriorityToday}
              registerFormDispatch={registerFormDispatch}
            >
              <SignInContent />
            </Step3>
          )}

          {stepNumber === 4 && (
            <Step4
              accountCreationError={accountCreationError}
              accountCreationLoading={accountCreationLoading}
              agreedTerms={formData.agreedTerms}
              autoFocusFormField={autoFocusFormField}
              billingCurrency={formData.billingCurrency}
              countryName={formData.countryName}
              onStepChange={onStepChange}
              onSubmit={onSubmit}
              registerFormDispatch={registerFormDispatch}
            >
              <SignInContent />
            </Step4>
          )}
        </AnimatePresence>
      </div>

      <div className="mt-auto flex items-center justify-between pt-24">
        <span
          className={cx('w-[20%] bg-gray-100 py-1', {
            'bg-purple-300': stepNumber >= 1
          })}
        />
        <span
          className={cx('w-[20%] bg-gray-100 py-1', {
            'bg-purple-300': stepNumber >= 2
          })}
        />
        <span
          className={cx('w-[20%] bg-gray-100 py-1', {
            'bg-purple-300': stepNumber >= 3
          })}
        />
        <span
          className={cx('w-[20%] bg-gray-100 py-1', {
            'bg-purple-300': stepNumber >= 4
          })}
        />
      </div>
    </div>
  )
}

const PANEL_TEXT = [
  {
    header: 'Create your account',
    text: 'Grow your print on demand business worldwide with Prodigi - no minimum orders or monthly fees.'
  },
  {
    header: 'Print on demand experts',
    text: "Hi, we're Prodigi. And you are?"
  },
  {
    header: 'Dropship worldwide at speed',
    text: `What's your priority today?`
  },
  {
    header: 'Online, all the time',
    text: 'Take your business worldwide from day one. Sign up and get access to our global network of print facilities'
  }
]

function SignInContent() {
  return (
    <div className="mt-12">
      Already have an account?{' '}
      <Link className="text-purple-500" to="/login">
        Sign in
      </Link>
    </div>
  )
}
