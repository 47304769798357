import { useState } from 'react'

import Button from '../../Button'
import { CountryPickerModal } from '../../CountryPickerModal'

export function CountryPickerModalButton({
  className,
  onSelection
}: {
  className?: string
  onSelection: (countryCode: string) => void
}) {
  const [countryPickerModalIsOpen, setCountryPickerModalIsOpen] = useState<boolean>(false)

  return (
    <div className={className}>
      <Button variant="text" size="sm" onClick={() => setCountryPickerModalIsOpen(true)}>
        Change country
      </Button>
      <CountryPickerModal
        open={countryPickerModalIsOpen}
        setOpen={(isOpen: boolean) => setCountryPickerModalIsOpen(isOpen)}
        onCountrySelection={(countryCode) => {
          onSelection(countryCode)
          setCountryPickerModalIsOpen(false)
        }}
      />
    </div>
  )
}
