import { DashboardPromoFields } from '.'

export function DashboardHeader({
  hasPaymentCardSetup,
  hasPlacedOrder,
  hasPlacedSamplePackOrder
}: {
  hasPaymentCardSetup: boolean
  hasPlacedOrder: boolean
  hasPlacedSamplePackOrder: boolean
}) {
  return (
    <header className="flex bg-purple-500 md:bg-gray-100">
      <div className="container mx-auto py-16">
        <h1 className="flex text-white md:text-4xl md:text-black">Welcome to Prodigi</h1>

        <DashboardPromoFields
          hasPlacedOrder={hasPlacedOrder}
          hasPlacedSamplePackOrder={hasPlacedSamplePackOrder}
          hasPaymentCardSetup={hasPaymentCardSetup}
        />
      </div>
    </header>
  )
}
