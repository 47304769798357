// @flow
import type {
  Dimensions,
  CropRectangle,
  ImageEditorTransformations
} from '../../types'
import { selectPigUrl } from '../../selectors/appSettings'
import { convertBorderWidthToPrintPx } from './convertBorderWidthToPrintPx'
import { convertPositionToPrintPx } from './convertPositionToPrintPx'
import { convertPrintSizeScaleToFillScale } from './convertPrintSizeScaleToFillScale'

export function buildPigUrl (
  originalImageUrl: string,
  transformations: ImageEditorTransformations,
  artworkDimensionsInPx: Dimensions,
  printDimensionsInPx: Dimensions,
  cropRectangle: CropRectangle,
  printDpi: number
): string {
  const {
    scaleFactor,
    rotationAngle,
    position,
    borderWidth,
    borderUnit,
    positionUnit
  } = transformations

  const fillScaleFactor = convertPrintSizeScaleToFillScale(
    scaleFactor,
    printDpi,
    borderUnit,
    borderWidth,
    printDimensionsInPx,
    artworkDimensionsInPx,
    cropRectangle
  )

  const borderWidthInPrintPx = convertBorderWidthToPrintPx(
    printDpi,
    borderWidth,
    borderUnit
  )

  const printPxPosition = convertPositionToPrintPx(
    printDimensionsInPx,
    artworkDimensionsInPx,
    cropRectangle,
    printDpi,
    positionUnit,
    position
  )

  return selectPigUrl() +
    `/render/?product_id=edit` +
    `&variant=cover:${printDimensionsInPx.width}x${printDimensionsInPx.height}` +
    '&print_image=true' +
    '&format=png' +
    '&debug=false' +
    `&image=${encodeURIComponent(originalImageUrl)}` +
    `&scale=${fillScaleFactor}` +
    `&rotate=${-rotationAngle}` +
    `&translate=${Math.round(printPxPosition.x)},${Math.round(printPxPosition.y)}` +
    `&border=${Math.round(borderWidthInPrintPx)}px` +
    `&tile=${String(transformations.isTiled)}`
}
