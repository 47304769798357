// @flow
import type { Dimensions, Orientation } from '../../types'
import { LANDSCAPE, PORTRAIT } from '../../data/orientation'

export function getRotatedDimensions (
  orientation: Orientation,
  width: number,
  height: number
): Dimensions {
  const orientationNameCorrectlyDescribesProvidedDimensions = Boolean(
    (orientation === LANDSCAPE && width > height) ||
    (orientation === PORTRAIT && height > width)
  )

  if (orientationNameCorrectlyDescribesProvidedDimensions) {
    return {
      width: width,
      height: height
    }
  } else {
    return {
      width: height,
      height: width
    }
  }
}
