import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/20/solid'

import styles from './Preview.module.css'
import ClipboardCopy from '../ClipboardCopy'
import { NO_IMAGE_PRODUCTS } from '../../constants'
import { buildImageUrls, buildTransformations } from './helpers'
import { LoadingPreview } from './components/LoadingPreview.component'
import { getNoImageProductData, isNoImageProduct } from '../../helpers'
import { PdfThumbnailPreview } from './components/PdfThumbnailPreview.component'
import { useProductDetails, useProductSearch, useTemplateService } from '../../hooks'
import { default as Preview } from '../ImageEditor/components/ImageEditorPreview.component'
import { ArtworkInterface, ArtworkTransformationsInterface } from '../ImageEditor/interfaces'
import { generatePigUrlsForArtworkInTemplate, getDefaultTransformations } from '../ImageEditor/helpers'

interface ImageEditorPreviewPropsInterface {
  artwork?: ArtworkInterface
  artworkTransformations?: ArtworkTransformationsInterface
  attributes?: Record<string, string>
  countryCode?: string
  dataTest?: string
  enableImageLibraryThumbnailRefresh?: boolean
  selectedPrintArea?: string
  sku?: string
}

export default function ImageEditorPreview({
  artwork,
  artworkTransformations,
  attributes,
  countryCode,
  dataTest,
  enableImageLibraryThumbnailRefresh = true,
  selectedPrintArea,
  sku
}: ImageEditorPreviewPropsInterface) {
  const [previewDisplayError, setPreviewDisplayErrorCode] = useState<string | null>(null)
  const [hasArtworkLoaded, setHasArtworkLoaded] = useState(false)
  const [hasOverlayLoaded, setHasOverlayLoaded] = useState(false)

  const {
    searchResults,
    isLoading: isLoadingSearchResults,
    error: searchResultsFetchError
  } = useProductSearch({
    countryCode: countryCode,
    query: sku,
    config: { revalidateOnFocus: false }
  })
  const {
    productDetails,
    isLoading: isLoadingProductDetails,
    error: productDetailsFetchError
  } = useProductDetails({ sku, config: { revalidateOnFocus: false } })
  const {
    templates,
    isLoading: isLoadingTemplates,
    error: templatesFetchError
  } = useTemplateService({
    sku: sku,
    attributes: attributes ?? undefined,
    config: { revalidateOnFocus: false }
  })

  useEffect(() => {
    setPreviewDisplayErrorCode(null)
  }, [artwork, artworkTransformations, attributes, sku, selectedPrintArea])

  if (productDetailsFetchError) {
    const errorCode = `PDFE-${sku ?? '0'}-${
      productDetailsFetchError.responseBodyJson?.traceParent ??
      productDetailsFetchError.status ??
      productDetailsFetchError.message
    }`

    return <ImagePreviewError errorCode={errorCode} />
  }

  if (searchResultsFetchError) {
    const errorCode = `SRFE-${sku ?? '0'}-${searchResultsFetchError?.status ?? searchResultsFetchError?.message ?? '0'}`

    return <ImagePreviewError errorCode={errorCode} />
  }

  if (templatesFetchError) {
    const errorCode = `TSFE-${sku ?? '0'}-${templatesFetchError.status ?? templatesFetchError.message}`

    return <ImagePreviewError errorCode={errorCode} />
  }

  if (previewDisplayError) {
    return <ImagePreviewError errorCode={previewDisplayError} />
  }

  const isLoadingData =
    isLoadingSearchResults ||
    !searchResults ||
    isLoadingProductDetails ||
    !productDetails ||
    isLoadingTemplates ||
    !templates

  if (isLoadingData) {
    return <LoadingPreview />
  }

  if (!sku) {
    return <PlaceholderImagePreview />
  }

  const skuSearchResult = searchResults.find((searchResult) => searchResult.sku.toUpperCase() === sku.toUpperCase())

  if (!skuSearchResult) {
    return <ImagePreviewError errorCode={`SSRE-${sku.toUpperCase()}`} />
  }

  if (skuSearchResult.productType && isNoImageProduct(skuSearchResult.productType)) {
    const noImageProductData = getNoImageProductData({
      attributes: attributes ?? {},
      productType: skuSearchResult.productType,
      sku
    })

    return <NoImageProductPreview noImageProductData={noImageProductData} />
  }

  if (!artwork || !countryCode || !selectedPrintArea) {
    return <PlaceholderImagePreview />
  }

  const artworkTransformationsToUse =
    artworkTransformations ?? getDefaultTransformations(artwork, templates, 'fillPrintArea', selectedPrintArea)

  const orientation = artworkTransformationsToUse?.orientation
  const selectedTemplate = orientation && templates.printAreas?.[selectedPrintArea]?.orientations?.[orientation]

  if (!selectedTemplate) {
    return <ImagePreviewError errorCode={`ST-${sku.toUpperCase()}`} />
  }

  const formattedTransformations = buildTransformations({
    artwork,
    selectedTemplate,
    transformations: artworkTransformationsToUse
  })

  if (!formattedTransformations) {
    return <ImagePreviewError errorCode={`FT-${sku.toUpperCase()}`} />
  }

  const pigUrls = generatePigUrlsForArtworkInTemplate({
    artwork,
    printAreaName: selectedPrintArea,
    templates,
    transformations: artworkTransformationsToUse
  })

  const previewKey =
    artwork.mediumImageUrl +
    sku +
    selectedPrintArea +
    (pigUrls?.previewImageUrl ?? '') +
    (pigUrls?.printImageUrl ?? '') +
    Object.entries(attributes ?? {}).reduce((previousValue, [key, value]) => `${previousValue}-${key}:${value}`, '')

  if (artwork.mimeType === 'application/pdf') {
    return (
      <PdfThumbnailPreview
        key={previewKey}
        artwork={artwork}
        onArtworkError={(e?: CustomEvent<unknown> | string) => {
          const errorCode = typeof e === 'string' ? e ?? '0' : `${artwork.imageLibraryId ?? '0'}`
          setPreviewDisplayErrorCode(`PDF-TS-AF-${errorCode}`)
        }}
      />
    )
  }

  return (
    <div className={clsx(styles.container, 'relative')} data-test={dataTest}>
      <Preview
        key={previewKey}
        cropRectangle={selectedTemplate.cropRectangle}
        enableImageLibraryThumbnailRefresh={enableImageLibraryThumbnailRefresh}
        transformations={formattedTransformations}
        printDimensionsInPx={selectedTemplate.printResolution}
        artworkDimensionsInPx={{ width: artwork.artworkWidth, height: artwork.artworkHeight }}
        imageUrls={buildImageUrls({ artwork, selectedTemplate })}
        imageLibraryId={artwork.imageLibraryId}
        printDpi={selectedTemplate.printDpi}
        isPreviewOnly={true}
        onArtworkError={(e?: CustomEvent<unknown> | string) => {
          const errorCode = typeof e === 'string' ? e ?? '0' : `${artwork.imageLibraryId ?? '0'}`
          setPreviewDisplayErrorCode(`TS-AF-${errorCode}`)
        }}
        onOverlayError={() => setPreviewDisplayErrorCode('TS-OF')}
        onUnderlayError={() => setPreviewDisplayErrorCode('TS-UF')}
        onLoadArtwork={() => setHasArtworkLoaded(true)}
        onLoadOverlay={() => setHasOverlayLoaded(true)}
      />

      {(!hasArtworkLoaded || !hasOverlayLoaded) && <LoadingPreview className="absolute" />}
    </div>
  )
}

function PlaceholderImagePreview() {
  return (
    <div className="grid h-[160px] w-[160px] place-content-center border-4 border-gray-200">
      <PhotoIcon className="h-10 w-10 text-gray-600" />
    </div>
  )
}

function ImagePreviewError({ errorCode }: { errorCode: string }) {
  return (
    <div className="h-[160px] w-[160px] overflow-auto bg-gray-100 p-4">
      <div className="grid place-content-center">
        <PhotoIcon className="mx-auto h-10 w-10 text-gray-400" />
        <div className="mt-2 max-w-[90px] text-center text-sm text-red-800">
          Error loading preview - code: <ClipboardCopy text={errorCode} showText={true} />
        </div>
      </div>
    </div>
  )
}

function NoImageProductPreview({ noImageProductData }: { noImageProductData?: typeof NO_IMAGE_PRODUCTS[number] }) {
  return (
    <div className="h-[160px] w-[160px]">
      {noImageProductData ? (
        <div className="flex h-full w-full flex-col border border-gray-200">
          <img
            key={noImageProductData.labImage}
            alt=""
            className="h-full w-full object-contain"
            src={noImageProductData.labImage}
          />
        </div>
      ) : (
        <div className="grid h-full w-full place-content-center border border-gray-200">No image</div>
      )}
    </div>
  )
}
