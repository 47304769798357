// @flow

export default {
  breakpointLarge: '@media screen and (min-width: 1000px)',
  breakpointMedium: '@media screen and (max-width: 620px)',
  breakpointSmall: '@media screen and (maxWidth: 400px)',
  breakpoint700: '@media screen and (maxWidth: 700px)',
  mobileMaxWidth: '@media screen and (max-width: 899px)',
  wideAnd500High: '@media screen and (min-width: 1000px) and (max-height: 500px)',
  wideAnd350High: '@media screen and (min-width: 1000px) and (max-height: 350px)',
  wideAnd300High: '@media screen and (min-width: 1000px) and (max-height: 300px)'
}
