// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Apps ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 0H1.5C0.671573 0 0 0.671573 0 1.5V6C0 6.82843 0.671573 7.5 1.5 7.5H6C6.82843 7.5 7.5 6.82843 7.5 6V1.5C7.5 0.671573 6.82843 0 6 0ZM6 6H1.5V1.5H6V6ZM16.5 0H12C11.1716 0 10.5 0.671573 10.5 1.5V6C10.5 6.82843 11.1716 7.5 12 7.5H16.5C17.3284 7.5 18 6.82843 18 6V1.5C18 0.671573 17.3284 0 16.5 0ZM16.5 6H12V1.5H16.5V6ZM6 10.5H1.5C0.671573 10.5 0 11.1716 0 12V16.5C0 17.3284 0.671573 18 1.5 18H6C6.82843 18 7.5 17.3284 7.5 16.5V12C7.5 11.1716 6.82843 10.5 6 10.5ZM6 16.5H1.5V12H6V16.5ZM16.5 10.5H12C11.1716 10.5 10.5 11.1716 10.5 12V16.5C10.5 17.3284 11.1716 18 12 18H16.5C17.3284 18 18 17.3284 18 16.5V12C18 11.1716 17.3284 10.5 16.5 10.5ZM16.5 16.5H12V12H16.5V16.5Z" fill="#565656"/>
    </svg>
  )
}
