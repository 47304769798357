// @flow
import { values, entries } from '../../helpers/dictionary'
import { identity, returnSecondArg } from '../helpers'
import { NO_SELECTION } from '../../data/catalogue'
import { createSelector } from 'reselect'
import type { GetV4ProductDetailsBySku, GetV4ProductDetailsBySkus, GetCatalogueItemBySku, GetCatalogueItemBySkus } from './types'
import type { Dictionary, V4ProductDetails, AppState, CatalogueItem, ProductSearchStateProps } from '../../types'

export function selectCatalogueItems (state: AppState): Dictionary<CatalogueItem> {
  return state.catalogue.products
}

export function selectSearching (state: AppState): boolean {
  return state.catalogue.searching
}

export function selectFacetsLoading (state: AppState): boolean {
  return state.catalogue.facetsLoading
}

export function selectFetchingPrices (state: AppState): boolean {
  return state.catalogue.fetchingPrices
}

export function selectSearchQuery (state: AppState): string {
  return state.catalogue.searchQuery
}

export function selectSelectedItemCategory (state: AppState): string {
  return state.catalogue.selectedItemCategory
}

export function selectSearchItems (state: AppState): CatalogueItem[] {
  if (state.catalogue.searchItems) {
    return state.catalogue.searchItems
  }

  return []
}

export function selectHasProductCategoryError (state: AppState): boolean {
  return Boolean(state.catalogue.itemCategoriesError)
}

export function selectHasProductSearchError (state: AppState): boolean {
  return Boolean(state.catalogue.error)
}

export function selectIsSearchInconclusive (isSearching: boolean, searchQuery: string, selectedItemCategory: string, searchItems: ?CatalogueItem[]): boolean {
  return Boolean(
    !isSearching &&
    ((searchQuery && searchQuery.length > 0) || (selectedItemCategory !== NO_SELECTION)) &&
    searchItems &&
    searchItems.length === 0
  )
}

export function selectShowFacets (state: AppState): boolean {
  return values(state.catalogue.facets).some(facet => facet && facet.values.length > 1)
}

export function selectCategories (state: AppState): string[] {
  return state.catalogue.categories
}

export function selectProductTypes (state: AppState): string[] {
  return state.catalogue.productTypes
}

export function selectSelectedProductType (state: AppState): string {
  return state.catalogue.selectedProductType
}

export function selectCatalogueItemBySku (state: AppState, sku: string): ?CatalogueItem {
  return state.catalogue.products[sku]
}

export function selectCatalogueItemBySkus (products: Dictionary<CatalogueItem>, skus: string[]): Dictionary<CatalogueItem> {
  return values(products).reduce((productAcc, product) => {
    if (!skus.includes(product.sku)) {
      return productAcc
    }
    productAcc[product.sku] = product
    return productAcc
  }, {})
}

export function selectListOfProductsBySkus (products: ?CatalogueItem[], skus: string[]): CatalogueItem[] {
  const lowerCaseSkus = skus.map(sku => sku.toLowerCase())
  if (products) {
    return products.filter(product => lowerCaseSkus.includes(product.sku.toLowerCase()))
  }
  return []
}

export function selectV4ProductDetails (state: AppState): Dictionary<V4ProductDetails> {
  return state.catalogue.v4ProductDetails
}

export function selectV4ProductDetailsBySku (
  productDetails: Dictionary<V4ProductDetails>,
  sku: string
): ?V4ProductDetails {
  return productDetails[sku]
}

export function selectV4ProductDetailsBySkus (
  productDetails: Dictionary<V4ProductDetails>,
  sku: string[]
): Dictionary<V4ProductDetails> {
  return entries(productDetails).reduce((productDetailAcc, [sku, details]) => {
    if (productDetails[sku]) {
      productDetailAcc[sku] = details
    }
    return productDetailAcc
  }, {})
}

export function buildProductSearchProps (
  selectedItemCategory: string,
  categories: string[],
  searchQuery: string,
  productTypes: string[],
  selectedProductType: string,
  hasProductCategoryError: boolean,
  hasProductSearchError: boolean
): ProductSearchStateProps {
  return {
    selectedItemCategory,
    categories,
    searchQuery,
    productTypes,
    selectedProductType,
    hasProductCategoryError,
    hasProductSearchError
  }
}

export const getIsSearchInconclusive: any = createSelector(selectSearching, selectSearchQuery, selectSelectedItemCategory, selectSearchItems, selectIsSearchInconclusive)
export const getShowFacets: any = createSelector(selectShowFacets, identity)
export const getProductSearchProps: any = createSelector(
  selectSelectedItemCategory,
  selectCategories,
  selectSearchQuery,
  selectProductTypes,
  selectSelectedProductType,
  selectHasProductCategoryError,
  selectHasProductSearchError,
  buildProductSearchProps
)

export const getSearchItems: any = createSelector(selectSearchItems, identity)
export const getIsSearching: any = createSelector(selectSearching, identity)
export const getV4ProductDetails: any = createSelector(selectV4ProductDetails, identity)
export const getV4ProductDetailsBySku: GetV4ProductDetailsBySku = createSelector(selectV4ProductDetails, returnSecondArg, selectV4ProductDetailsBySku)
export const getV4ProductDetailsBySkus: GetV4ProductDetailsBySkus = createSelector(selectV4ProductDetails, returnSecondArg, selectV4ProductDetailsBySkus)
export const getCatalogueItemBySkus: GetCatalogueItemBySkus = createSelector(selectCatalogueItems, returnSecondArg, selectCatalogueItemBySkus)
export const getCatalogueItemBySku: GetCatalogueItemBySku = createSelector(selectCatalogueItemBySku, identity)
