import { fetcher } from '.'
import { SalesChannelInterface } from '../interfaces'

export async function updateSalesChannelDetails({
  merchantId,
  packingSlipImageId,
  salesChannelId,
  salesChannelName,
  defaultFulfillment
}: {
  merchantId: number
  packingSlipImageId: string | null
  salesChannelId: string
  salesChannelName: string
  defaultFulfillment: boolean
}) {
  return fetcher<{ data: SalesChannelInterface }>(
    `${process.env.REACT_APP_PRODIGI_MERCHANT_SERVICE}/merchants/${merchantId}/saleschannels/${salesChannelId}`,
    {
      body: JSON.stringify({
        description: salesChannelName,
        packingSlipImageId: packingSlipImageId,
        defaultFulfillment: defaultFulfillment
      }),
      method: 'PUT'
    }
  )
}
