// @flow
import React from 'react'

import Svg from '../svg'
import styles from './StripeBanner.module.css'
import LoadingEllipsis from '../LoadingEllipsis'
import { STRIPE_CONNECT_NOT_STARTED_CTA, STRIPE_CONNECT_NOT_STARTED_MESSAGE } from '../../pages/Apps/constants'

type Props = {|
  errorMessage: string;
  hasError: boolean,
  isLoading: boolean,
  onCreateAccountClick: () => Promise<void>,
|}

export default class StripeBannerView extends React.PureComponent<Props> {
  render (): React$Node {
    return (
      <div className={styles.stripeBanner}>
        <Svg.StripeLogo />
        <p className={styles.stripeBannerText}>
          {STRIPE_CONNECT_NOT_STARTED_MESSAGE}
        </p>
        <button className={styles.stripeBannerAction} disabled={this.props.isLoading} onClick={() => this.props.onCreateAccountClick()}>
          <div className={styles.stripeBannerActionText}>
            {STRIPE_CONNECT_NOT_STARTED_CTA}
            <Svg.ArrowRight />
          </div>
        </button>
        {this.props.isLoading && (
          <div className={styles.loading}>
            <LoadingEllipsis />
          </div>
        )}
        {this.props.hasError && (
          <p className={styles.stripeBannerError}>{this.props.errorMessage}</p>
        )}
      </div>
    )
  }
}
