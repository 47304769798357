import { ImageLibraryImageMimeType } from '../../../hooks'

export function getImageExtentionFromImageLibraryMimeType(mimeType: ImageLibraryImageMimeType) {
  switch (mimeType) {
    case 'image/jpeg':
      return 'jpg'
    case 'image/png':
      return 'png'
    case 'application/pdf':
      return 'pdf'
    default:
      return null
  }
}
