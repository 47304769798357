import { OrderCancellationButton } from '.'
import { OrderStatusEnum } from '../../../enums'

interface OrderDetailHeaderActionsInterface {
  cancellationIsAvailable?: boolean
  orderId: string
  status: OrderStatusEnum
  mutateOrderDetails: () => void
}

const VALID_CANCEL_STATUSES = [
  OrderStatusEnum.DRAFT,
  OrderStatusEnum.ON_HOLD,
  OrderStatusEnum.REQUIRES_ATTENTION,
  OrderStatusEnum.IN_PROGRESS
]

export function OrderDetailHeaderActions({
  cancellationIsAvailable,
  orderId,
  status,
  mutateOrderDetails
}: OrderDetailHeaderActionsInterface) {
  const isValidCancelStatus = VALID_CANCEL_STATUSES.includes(status)
  if (!isValidCancelStatus) {
    return null
  }

  return (
    <div className="relative flex w-full gap-6 overflow-x-auto md:justify-end">
      <OrderCancellationButton
        cancellationIsAvailable={Boolean(cancellationIsAvailable)}
        orderId={orderId}
        status={status}
        mutateOrderDetails={mutateOrderDetails}
      />
    </div>
  )
}
