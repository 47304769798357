export const HEADER_NAMES = {
  SIZE: 'Size',
  ORIENTATION: 'Orientation',
  SUBSTRATE_WEIGHT: 'Substrate weight',
  GLAZE: 'Glaze',
  FINISH: 'Finish',
  STYLE: ' Style',
  FRAME_COLOUR: 'Frame colour',
  MOUNT_COLOUR: 'Mount colour',
  COLOURS: 'Colours',
  MOUNT: 'Mount',
  WRAP: 'Wrap',
  FRAME: 'Frame',
  BRAND: 'Brand',
  EDGE: 'Edge',
  PAPER: 'Paper',
  FULFILLED_FROM: 'Fulfilled from',
  SLA: 'Lead time (h)',
  SKU: 'sku',
  PRICE: 'price',
  INFO: 'info',
  ORDER: 'order'
}
