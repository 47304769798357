// @flow
import React, { PureComponent } from 'react'
import CsvItemDetailsView from './CsvItemDetailsView'
import type { FormattedOrderItem, CsvItemProductDetails, Status } from '../../../../types'

type Props = {|
  item: FormattedOrderItem,
  details: ?CsvItemProductDetails,
  isCompleted: boolean,
  idOfOrderItemWithOpenImageModal: ?string,
  orderId: string,
  itemIndex: number,
  numberOfItems: number,
  artworkStatus: Status,
  canBeEdited: boolean,
  openProductSearchModal: (itemId: string) => void,
  closeImageModal: () => void,
  openImageModal: (orderItemId: string) => void
|}

export default class CsvOrderController extends PureComponent<Props> {
  get isImageModalOpen (): boolean {
    return this.props.idOfOrderItemWithOpenImageModal === this.props.item.id
  }

  render (): React$Node {
    return (
      <CsvItemDetailsView
        orderId={this.props.orderId}
        item={this.props.item}
        itemIndex={this.props.itemIndex}
        numberOfItems={this.props.numberOfItems}
        details={this.props.details}
        canBeEdited={this.props.canBeEdited}
        isCompleted={this.props.isCompleted}
        openProductSearchModal={this.props.openProductSearchModal}
        isImageModalOpen={this.isImageModalOpen}
        closeImageModal={this.props.closeImageModal}
        openImageModal={this.props.openImageModal} />
    )
  }
}
