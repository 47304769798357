import { fetcher } from '../../../helpers'

export function updateChannelProductFulfilmentUsingExternalId({
  merchantId,
  salesChannelId,
  salesChannelProductId,
  shouldFulfill
}: {
  merchantId: number | null
  salesChannelId: string
  salesChannelProductId: string | number
  shouldFulfill: boolean
}) {
  return fetcher(
    `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/merchants/${merchantId}/saleschannel/${salesChannelId}/products/${salesChannelProductId}/external`,
    {
      body: JSON.stringify({
        Fulfill: shouldFulfill
      }),
      method: 'PUT'
    }
  )
}
