import { useEffect } from 'react'

import { FetchErrorInterface } from '../../../interfaces'
import { ImageMockupInterface, MockupDetailsInterface, useMockupGeneration } from '../../../hooks'

interface MockupsPollingPropsInterface {
  productSku: string
  transformationId: string
  mockup: MockupDetailsInterface
  onComplete: (details: MockupPollCompleteInterface) => void
}

export interface MockupPollCompleteInterface {
  mockupsData?: ImageMockupInterface
  mockupsFetchError?: FetchErrorInterface<{ detail?: string }>
}

export function MockupsPolling({ productSku, transformationId, mockup, onComplete }: MockupsPollingPropsInterface) {
  const { mockupsData, mockupsFetchError } = useMockupGeneration(
    { productSku, transformationId, mockup },
    {
      config: { refreshInterval: 2000, revalidateOnMount: false, revalidateOnFocus: false }
    }
  )

  useEffect(() => {
    if (mockupsData && mockupsData.url) {
      onComplete({ mockupsData, mockupsFetchError })
    }
    if (mockupsFetchError) {
      onComplete({ mockupsData, mockupsFetchError })
    }
  }, [mockupsData, mockupsFetchError, onComplete])

  return null
}
