import { selectOMSUrl } from '../../../selectors/appSettings'
import { OrdersDataSuccessInterface } from '../../../interfaces'
import { DEFAULT_ORDERS_SORT_ORDER, OMS_ENDPOINTS, QUERY_PARAMS } from '../../../constants'

export function getOrdersKey(pageIndex: number, previousPageData: OrdersDataSuccessInterface) {
  const ordersListURL = `${selectOMSUrl()}/${OMS_ENDPOINTS.ORDERS_LIST}`

  const hasReachedEnd = previousPageData && Boolean(previousPageData?.data?.hasMore) === false
  if (hasReachedEnd) {
    return null
  }

  const searchParams = new URLSearchParams(window.location.search)
  if (!searchParams.has(QUERY_PARAMS.ORDERS.SORT)) {
    searchParams.set(QUERY_PARAMS.ORDERS.SORT, DEFAULT_ORDERS_SORT_ORDER)
  }

  adjustOrderDateFiltersForUTC(searchParams)

  const isFirstPage = pageIndex === 0
  if (isFirstPage) {
    return `${ordersListURL}?${searchParams.toString()}`
  }

  return previousPageData?.data?.nextUrl
}

/**
 * Use to update date params for orders list filter to handle different time zones with the correct date range.
 * The start date is inclusive but the end date is exclusive so 1 day needs to be added.
 * This helper method mutates the searchParams in place
 * @param {URLSearchParams} searchParams - search param filters for orders list
 */
function adjustOrderDateFiltersForUTC(searchParams: URLSearchParams) {
  const startDateParam = searchParams.get(QUERY_PARAMS.ORDERS.START_DATE)
  if (startDateParam) {
    const startDate = new Date(startDateParam)
    const startDateWithAdjustedUTCTime = getStartDateWithAdjustedUTCTime(startDate)
    searchParams.set(QUERY_PARAMS.ORDERS.START_DATE, startDateWithAdjustedUTCTime.toJSON())
  }

  const endDateParam = searchParams.get(QUERY_PARAMS.ORDERS.END_DATE)
  if (endDateParam) {
    const endDate = new Date(endDateParam)
    const endDateWithAdjustedUTCTime = getEndDateWithAdjustedUTCTime(endDate)
    searchParams.set(QUERY_PARAMS.ORDERS.END_DATE, endDateWithAdjustedUTCTime.toJSON())
  }
}

function getStartDateWithAdjustedUTCTime(startDate: Date) {
  const startDateWithAdjustedUTCTime = new Date(
    Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)
  )
  return startDateWithAdjustedUTCTime
}

function getEndDateWithAdjustedUTCTime(endDate: Date) {
  const endDateWithAdjustedUTCTime = new Date(
    Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1, 0, 0, 0, 0)
  )
  return endDateWithAdjustedUTCTime
}
