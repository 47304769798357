import { Row } from '@tanstack/react-table'
import { VariantRowType } from '../../../hooks'

export function buildTableRowContentsAsString(row: Row<VariantRowType>) {
  let rowAsString = `${row.original.sku ?? ''} `

  row.getAllCells().forEach((cell) => {
    if (cell.renderValue() !== '-') {
      rowAsString += `${cell.renderValue() ?? ''} `
    }
  })

  return rowAsString.toLowerCase()
}
