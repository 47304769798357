// @flow
import { RSAA } from 'redux-api-middleware'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import type { FetchUserDetailsResult } from '../types'
import { LOG_STATUS } from '../../data/rsaa'

export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS'
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS'
export const FETCH_USER_DETAILS_ERROR = 'FETCH_USER_DETAILS_ERROR'

export function fetchUserDetails (): Thunk<FetchUserDetailsResult> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint

    return dispatch({
      [RSAA]: {
        endpoint: endpoint + `/dashboard/user`,
        method: 'GET',
        types: [
          { type: REQUEST_USER_DETAILS, meta: { enhancements: [ LOG_STATUS ] } },
          RECEIVE_USER_DETAILS,
          FETCH_USER_DETAILS_ERROR
        ]
      }
    })
  }
}
