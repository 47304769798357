import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export enum BannerTypeEnum {
  Error = 'ERROR',
  Default = 'DEFAULT'
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

interface BannerPropsInterface {
  buttonLink: string
  buttonText: string
  message: string
  type: BannerTypeEnum
  onButtonClick?: () => void
}
export default function Banner({ buttonLink, buttonText, message, type, onButtonClick }: BannerPropsInterface) {
  if (type === BannerTypeEnum.Error) {
    return (
      <ErrorBanner buttonLink={buttonLink} buttonText={buttonText} message={message} onButtonClick={onButtonClick} />
    )
  }

  return <DefaultBanner buttonLink={buttonLink} buttonText={buttonText} message={message} />
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
const containerClassName =
  'p-6 flex justify-between items-center gap-2 w-full flex-col md:flex-row space-y-4 md:space-y-0'

function ErrorBanner({
  buttonLink,
  buttonText,
  message,
  onButtonClick
}: {
  buttonLink: string
  buttonText: string
  message: string
  onButtonClick?: () => void
}) {
  return (
    <div className={`border border-magenta-700 bg-magenta-100 ${containerClassName}`}>
      <div className="inline-flex items-center space-x-6">
        <span className="flex items-center">
          <ExclamationCircleIcon aria-hidden="false" className="h-8 w-8 text-magenta-700" />
        </span>
        <span>{message}</span>
      </div>
      <Link className="btn btn-danger md:m-win whitespace-nowrap" to={buttonLink} onClick={onButtonClick ?? undefined}>
        {buttonText}
      </Link>
    </div>
  )
}

function DefaultBanner({
  buttonLink,
  buttonText,
  message
}: {
  buttonLink: string
  buttonText: string
  message: string
}) {
  return (
    <div className={`border border-gray-200 bg-white  ${containerClassName}`}>
      <span>{message}</span>
      <Link className="btn btn-secondary md:m-win" to={buttonLink}>
        {buttonText}
      </Link>
    </div>
  )
}
