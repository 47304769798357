// @flow

import processFetchResponse from '../processFetchResponse'
import getDefaultFetchOptions from '../getDefaultFetchOptions'
import type { GetStripeConnectSignupLinkType } from '../../types'
import { PAYMENT_SERVICE_URL, STRIPE_REDIRECT, STRIPE_REFRESH } from '../../pages/Apps/constants'

export function getStripeConnectSignupLink (
  { abortSignal }: {| abortSignal: AbortSignal |}
): Promise<GetStripeConnectSignupLinkType> {
  const url = `${PAYMENT_SERVICE_URL}/payments/stripe/account/link`
  return fetch(url, {
    ...getDefaultFetchOptions(),
    method: 'POST',
    body: JSON.stringify({
      return_url: window.location.origin + window.location.pathname + `?${STRIPE_REDIRECT}=true`,
      refresh_url: window.location.origin + window.location.pathname + `?${STRIPE_REFRESH}=true`
    }),
    signal: abortSignal
  }).then(processFetchResponse)
}
