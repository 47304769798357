// @flow
import React from 'react'
import Tippy from '@tippy.js/react'

import ConfigureView from './ConfigureView'
import styles from './../AppSettings.module.css'
import BrandLogo from '../../../../AddNewShop/components/BrandLogo'
import InputUnderlined from '../../../../../components/InputUnderlined'
import BrandColour from '../../../../AddNewShop/components/BrandColour'
import getDefaultFetchOptions from '../../../../../helpers/getDefaultFetchOptions'
import { PRINT_ENGINE_PARAMS, PRINT_ENGINE_URL } from '../../../../Apps/constants'
import { ABORT_ERROR_NAME, HEX_REG_EXP, LIVE } from '../../../../../data/constants'
import type { AbortController, AppSettingsType, Match, MatchAppType, User } from '../../../../../types'

const {
  APP_TYPE,
  BRAND_COLOUR,
  BRAND_LOGO,
  BRAND_NAME,
  FAVICON,
  MERCHANT_ID
} = PRINT_ENGINE_PARAMS

type Props = {|
  appSettings: AppSettingsType,
  appTypeSelected: MatchAppType,
  disableSaveConfig: boolean,
  match: Match,
  user: User,
  onUpdateAppConfig: (updatedConfig: $Shape<AppSettingsType>) => void,
  onDisableSaveConfig: () => void
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  isSavingConfig: boolean,
  saveConfigError: boolean,
|}

export default class ConfigureController extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const controller = new window.AbortController()
    const signal = controller.signal

    this.state = {
      abortController: controller,
      abortSignal: signal,
      isSavingConfig: false,
      saveConfigError: false
    }
  }

  updateAppSettingsState: ((updatedValue: $Shape<AppSettingsType>) => void) = (updatedValue: $Shape<AppSettingsType>) => {
    this.props.onUpdateAppConfig(updatedValue)
  }

  handleColourChange: ((event: SyntheticInputEvent<HTMLInputElement>) => void) = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const isHexValid = HEX_REG_EXP.test(event.target.value)
    const newValue = {
      brandColour: {
        value: event.target.value,
        isHexValid
      }
    }
    this.updateAppSettingsState(newValue)
  }

  handleLogoChange: ((fileName?: string, fileSize?: string, url?: string) => void) = (fileName: string = '', fileSize: string = '', url: string = '') => {
    const newValue = {
      brandLogo: { fileName, fileSize, url }
    }
    this.updateAppSettingsState(newValue)
  }

  handleFaviconChange: ((fileName?: string, fileSize?: string, url?: string) => void) = (fileName: string = '', fileSize: string = '', url: string = '') => {
    const newValue = {
      favIcon: { fileName, fileSize, url }
    }
    this.updateAppSettingsState(newValue)
  }

  handleSave: (() => void) = () => {
    this.setState({
      isSavingConfig: true,
      saveConfigError: false
    })
    const { appTypeSelected, user } = this.props
    const { appId } = this.props.match.params
    const { appSettings } = this.props
    const printEngineAppType = appTypeSelected.printEngineType

    const url = `${PRINT_ENGINE_URL}/config/?${BRAND_NAME}=${appId}&${APP_TYPE}=${printEngineAppType}&${MERCHANT_ID}=${user.merchantUniqueId}`
    fetch(url, {
      ...getDefaultFetchOptions(),
      method: 'PUT',
      body: JSON.stringify({
        [BRAND_LOGO]: appSettings.brandLogo.url,
        [BRAND_COLOUR]: `#${appSettings.brandColour.value}`,
        [FAVICON]: appSettings.favIcon.url
      }),
      signal: this.state.abortSignal
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        this.setState({ isSavingConfig: false })
        this.props.onDisableSaveConfig()
      })
      .catch((err) => {
        if (err.name !== ABORT_ERROR_NAME) {
          console.error(err)
          this.setState({
            isSavingConfig: false,
            saveConfigError: true
          })
        }
      })
  }

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    const {
      saveConfigError
    } = this.state

    const {
      brandName,
      brandLogo,
      brandColour,
      favIcon
    } = this.props.appSettings

    const inputPrefix = this.props.appTypeSelected.hostName + '/'
    const completeUrl = `https://${inputPrefix}${brandName}`
    const commonSettings = [
      {
        title: 'Shop name',
        subTitle: 'Your app is available at the address below',
        content: (<div className={process.env.REACT_APP_ENV === LIVE ? styles.brandName : styles.brandNameWide}>
          <Tippy content="Open app" placement="right">
            <a href={completeUrl} target="_blank" rel="noopener noreferrer">
              <InputUnderlined inputLabel="SHOP NAME" inputPrefix={inputPrefix} value={brandName} readOnly={true} />
            </a>
          </Tippy>
        </div>)
      },
      {
        title: 'App logo',
        subTitle: 'Replace logo here',
        content: (<div className={styles.brandLogo}>
          <BrandLogo autoFocusButton={false} fileName={brandLogo.fileName} fileSize={brandLogo.fileSize} fullImageUrl={brandLogo.url} brandLogoChangeHandler={(fileName?: string, fileSize?: string, fullImageUrl?: string) => this.handleLogoChange(fileName, fileSize, fullImageUrl)} />
        </div>)
      },
      {
        title: 'App Favicon',
        subTitle: favIcon.url ? 'Remove or replace favicon' : 'Add a new favicon',
        content: (<div className={styles.brandLogo}>
          <BrandLogo autoFocusButton={false} defaultText="Add Favicon" fileName={favIcon.fileName} fileSize={favIcon.fileSize} fullImageUrl={favIcon.url} brandLogoChangeHandler={(fileName?: string, fileSize?: string, fullImageUrl?: string) => this.handleFaviconChange(fileName, fileSize, fullImageUrl)} />
        </div>)
      },
      {
        title: 'Button colour',
        subTitle: 'Specify a hex value',
        content: (<div className={styles.brandColour}>
          <BrandColour brandColour={brandColour.value} isHexValid={brandColour.isHexValid} brandColourChangeHandler={this.handleColourChange} />
        </div>)
      }
    ]

    return (
      <ConfigureView
        commonSettings={commonSettings}
        disableSave={this.props.disableSaveConfig}
        isSavingConfig={this.state.isSavingConfig}
        saveConfigError={saveConfigError}
        onSave={this.handleSave} />
    )
  }
}
