export enum OrderHistoryTypeEnum {
  FulfillmentIssueClosed = 'Prodigi.FulfillmentIssue.Closed',
  FulfillmentIssueCreated = 'Prodigi.FulfillmentIssue.Created',
  FulfillmentIssueUpdated = 'Prodigi.FulfillmentIssue.Updated',
  OrderAdditionalCharge = 'Prodigi.Order.AdditionalCharge',
  OrderCreated = 'Prodigi.Order.Created',
  OrderPaused = 'Prodigi.Order.Paused',
  OrderCancelled = 'Prodigi.Order.Cancelled',
  SalesChannelOrderCancelled = 'Prodigi.Order.SalesChannelCancelled',
  OrderItemAssetDownloadFailed = 'Prodigi.OrderItem.Asset.DownloadFailed',
  OrderSubmitted = 'Prodigi.Order.Submitted',
  PackingSlipDownloadFailed = 'Prodigi.PackingSlip.DownloadFailed',
  PackingSlipRemoved = 'Prodigi.PackingSlip.Removed',
  PackingSlipUpdated = 'Prodigi.PackingSlip.Updated',
  PaymentFailed = 'Prodigi.PaymentFailed',
  PaymentReceived = 'Prodigi.Payment.Received',
  RecipientUpdated = 'Prodigi.Recipient.Updated',
  ShipmentCancelled = 'Prodigi.Shipment.Cancelled',
  ShipmentCarrierAndServiceUpdated = 'Prodigi.Shipment.CarrierAndService.Updated',
  ShipmentShipped = 'Prodigi.Shipment.Shipped',
  ShippingMethodUpdated = 'Prodigi.ShippingMethod.Updated',
  OrderFulfillmentFailed = 'Prodigi.Order.Fulfillment.ExceptionFailed',
  ShipmentInformationProvided = 'Prodigi.Shipment.InformationProvided'
}
