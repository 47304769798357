// @flow

import type { Element } from 'react'
import type { CatalogueItem, Dictionary, Event, MultiAssetBasketItem, RsaaStatusProps } from '../../../../types'

import React from 'react'
import Button from '../../../../components/Button'
import styles from './RecentItemList.module.css'
import { isZeroPricedItem } from '../../../../selectors/manualOrderForm'
import ItemPrice from '../ItemPrice/ItemPrice'
import { PRINT_AREA_NAME } from '../../../../data'

type RecentItemListProps = {|
  items: MultiAssetBasketItem[],
  catalogueItems: Dictionary<CatalogueItem>,
  fetchPricesRsaaStatus: RsaaStatusProps,
  invalidImageUrls: string[],
  getUniqueId: (item: MultiAssetBasketItem) => string,
  onImageError: (originalImageUrl: string) => void,
  onSelectRecentItem: (e: Event) => void,
  onClearRecentItems: (e: Event) => void
|}

export default function RecentItemListLayout ({ invalidImageUrls, onImageError, catalogueItems, fetchPricesRsaaStatus, items, onSelectRecentItem, onClearRecentItems, getUniqueId }: RecentItemListProps): Element<"section"> {
  if (!items || items.length === 0) {
    return (
      <section className={styles.wrapper} data-test="no-recently-ordered-items-container">
        <h2 className={styles.headerTitle}>Recently ordered on this device</h2>
        <p>There&#39;s nothing here yet.</p>
        <p>When you place an order, the items you order will appear here.</p>
      </section>
    )
  }

  return (
    <section className={styles.wrapper} data-test="recently-ordered-items-container">
      <header className={styles.header}>
        <h2 className={styles.headerTitle}>Recently ordered on this device</h2>
        <Button theme='primary' onClick={onClearRecentItems} dataTest="clear-recent-items-button">Clear recent items</Button>
      </header>
      <ul className={styles.list}>
        {items.map((item, index) => {
          if (!item.printAreas) {
            return null
          }
          const itemPrintAreas = item.printAreas
          const printAreaNames = Object.keys(item.printAreas)
          const selectedPrintArea = printAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
            ? PRINT_AREA_NAME.DEFAULT
            : printAreaNames[0] ?? null
          if (!selectedPrintArea || !itemPrintAreas[selectedPrintArea]?.artwork) {
            return null
          }
          const artwork = itemPrintAreas[selectedPrintArea].artwork
          const uniqueId = getUniqueId(item)
          const isAvailable = !invalidImageUrls.includes(artwork.originalImageUrl)

          return (
            <li key={uniqueId} className={styles.item} data-test={`recently-ordered-item-${index}`}>
              {isAvailable && (
                <img
                  className={styles.itemImage}
                  src={artwork.smallImageUrl}
                  alt=''
                  data-test="item-image"
                  onError={() => onImageError(artwork.originalImageUrl)}
                />
              )}
              {!isAvailable && (
                <div className={styles.errorMessageContainer}>
                  <p>
                    This item is no longer available
                  </p>
                </div>
              )}
              <div className={styles.itemInner}>
                <div className={styles.itemTextContainer}>
                  <p className={styles.itemTitle} data-test="item-tite">{catalogueItems[item.sku].description}</p>
                  <p className={styles.itemSku} data-test="item-sku">{item.sku}</p>
                  <ItemPrice
                    isZeroPricedItem={isZeroPricedItem(item.sku)}
                    isLoading={fetchPricesRsaaStatus.loading}
                    priceFrom={catalogueItems[item.sku].basePriceFrom}
                    exactPrice={catalogueItems[item.sku].price}
                    currency={catalogueItems[item.sku].currency}
                  />
                </div>

                <Button disabled={!isAvailable} value={uniqueId} onClick={onSelectRecentItem} dataTest="add-to-basket-button">Add to basket</Button>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
