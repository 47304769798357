import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { CheckIcon } from '@heroicons/react/20/solid'

import {
  OMSOrderSubmissionErrorResponseInterface,
  getOrderSubmissionErrorMessage
} from '../../OrderEditDisplay/helpers'
import Button from '../../Button'
import { fetcher } from '../../../helpers'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { ORDER_EDIT_WINDOW_TOASTS } from './OrderPausedWindow.component'
import { useOrderDetail, useOrdersOverview, useUser } from '../../../hooks'

export function SubmitOrderButton() {
  const { id: orderId } = useParams<{ id: string }>()
  const { user } = useUser()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const { mutateOrdersOverview } = useOrdersOverview()

  const [orderSubmissionStatus, setOrderSubmissionStatus] = useState<StatusEnum>(StatusEnum.Idle)

  const orderDetails = orderDetailsResponse?.data.order

  if (!user) {
    throw Error('No user')
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  async function handleSubmitOrder() {
    toast.dismiss(ORDER_EDIT_WINDOW_TOASTS.ERROR)
    setOrderSubmissionStatus(StatusEnum.Loading)

    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/submitHeld`, { method: 'POST' })
      await mutateOrderDetails()
      mutateOrdersOverview()

      createToast({ heading: 'Order submitted successfully', type: 'success' })
      setOrderSubmissionStatus(StatusEnum.Success)
      window.analytics.track('Order pause override', { option: 'sent to production' })
    } catch (error) {
      window.analytics.track('Order editing - paused order submission failed')
      const errorResponse = error as FetchErrorInterface<OMSOrderSubmissionErrorResponseInterface>
      const errorMessage = getOrderSubmissionErrorMessage(errorResponse) ?? undefined

      createErrorToast({
        errorCode: `${errorResponse.status ?? '0'}-${errorResponse.responseBodyJson?.traceParent ?? '0'}`,
        errorMessage,
        heading: 'An error occurred while submitting the order',
        id: ORDER_EDIT_WINDOW_TOASTS.ERROR
      })
      setOrderSubmissionStatus(StatusEnum.Error)
    }
  }

  const userHasToAddBillingDetails = !user.billing.hasPaymentSetup && user.billing.invoiceFrequency === 'PerOrder'

  return (
    <>
      <Button
        disabled={!orderDetails.isReadyForFulfilment || userHasToAddBillingDetails}
        isLoading={orderSubmissionStatus === StatusEnum.Loading}
        startIcon={<CheckIcon className="h-6 w-6" />}
        onClick={handleSubmitOrder}
        size="sm"
        variant="primary"
      >
        Send now
      </Button>

      {orderSubmissionStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
