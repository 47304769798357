import { DictionaryInterface } from '../../../interfaces'
import { ProductDetailInterface } from '../../../hooks'
import { productAttributeFormat, removeDuplicates } from '../../../helpers'
import _ from 'lodash'

/**
 * A function to build a list of any variants (combinations of product attributes) that are not available to order.
 *
 * @prop {ProductDetailInterface} productDetails - The details of the product.
 *
 * @output {string} - A list of missing variants.
 * Each value is created using the user-selectable product attributes and is mapped in the format `attribute / ... / attribute, attribute`
 * where `attribute / ... / ` are grouped attributes and `attribute, attribute` are individual attributes, e.g. 'Charcoal / XL, 3XL'
 */
export function buildListOfMissingVariants({ productDetails }: { productDetails: ProductDetailInterface }) {
  const availableVariants = productDetails.variants.map((variant) => variant.attributes)
  const allPossibleVariants = buildListOfPossibleVariants(productDetails)

  if (availableVariants.length === allPossibleVariants.length) {
    return []
  }

  const unavailableVariants = allPossibleVariants.filter(
    (variant) =>
      !availableVariants.find((variant2) => Object.keys(variant2).every((key) => variant[key] === variant2[key]))
  )

  if (unavailableVariants.length === 0) {
    return []
  }

  const selectableAttributes = Object.keys(productDetails.attributes).filter(
    (key) => productDetails.attributes[key].length > 1
  )

  const filteredUnavailableVariantsToSelectableAttributes = unavailableVariants.map((variant) => {
    const values: Record<string, string> = {}
    Object.entries(variant).map(([name, attribute]) => {
      if (selectableAttributes.includes(name)) {
        values[name] = attribute
      }
    })
    return values
  })

  return groupAndFormatMissingVariants({
    missingVariants: filteredUnavailableVariantsToSelectableAttributes,
    selectableAttributes
  })
}

/**
 * A function to build a list of all possible variants (combinations/permutations of product attributes).
 * For each group/type of attributes:
 *     It creates a copy of the overall list of permutations,
 *     For each attribute in the group:
 *         It creates its own copy of the permutations and adds the attribute to each one
 *         The new permutations are added to the overall list
 *
 * @prop {Record<string, string[]>} attributes - The attributes of the product.
 *
 * @output {Record<string, string>[]} - A list of all possible variants.
 * Each value is created using the product attributes and is mapped into the same format as the product variants list.
 */
function buildListOfPossibleVariants({ attributes }: { attributes: Record<string, string[]> }) {
  let variants: Record<string, string>[] = []

  Object.entries(attributes).map(([name, availableAttributes]) => {
    const copyOfVariants = variants.concat()
    let newVariants: Record<string, string>[] = []

    availableAttributes.forEach((attribute) => {
      const attributeCopyOfVariants = copyOfVariants.concat()

      if (attributeCopyOfVariants.length === 0) {
        newVariants = newVariants.concat({
          [name]: attribute
        })
      } else {
        const newValues: Record<string, string>[] = []
        attributeCopyOfVariants.forEach((variant) => {
          newValues.push({
            ...variant,
            [name]: attribute
          })
        })
        newVariants = newVariants.concat(newValues)
      }
    })

    variants = newVariants
  })

  return variants
}

const SIZE_ATTRIBUTE = 'size'
/**
 * A function to group the list of all missing combinations by matching attributes.
 * Each combination is reduced to user-selectable attributes and then grouped by user-selectable attributes.
 * Each group formatted to a string in the format `attribute / attribute / ... / attribute, attribute`
 *
 * E.g. [{color: 'charcoal', size: 'xl'}, {color: 'azalea', size: '3xl'}, {color: 'charcoal', size: '3xl'}, {color: 'cardinal red', size: '3xl'}]
 * Would be grouped and formatted to ['Charcoal / XL, 3XL', 'Azalea / 3XL', 'Cardinal red / 3XL']
 *
 * @prop {Record<string, string[]>} missingVariants - A list of missing variants.
 * @prop {string[]} selectableAttributes - A list of attributes that can be selected by users.
 *
 * @output {string[]} - A list of all missing variants, formatted to '/' separated strings.
 */
function groupAndFormatMissingVariants({
  missingVariants,
  selectableAttributes
}: {
  missingVariants: Record<string, string>[]
  selectableAttributes: string[]
}) {
  selectableAttributes.push(selectableAttributes.splice(selectableAttributes.indexOf(SIZE_ATTRIBUTE), 1)[0])
  const attributesForGrouping = selectableAttributes.slice(0, selectableAttributes.length - 1)

  const groupVariantsByMatchingKeys: _.Dictionary<Record<string, string>[]> = _.groupBy(missingVariants, (item) => {
    const result: string[] = []

    Object.entries(item).forEach(([key, value]) => {
      if (attributesForGrouping.includes(key)) {
        result.push(productAttributeFormat(value))
      }
    })

    return result.join(' / ')
  })

  const mapGroupedVariants: DictionaryInterface<string[]> = _.mapValues(groupVariantsByMatchingKeys, (group) =>
    group.map((variant) => {
      const filtered = _.omit(variant, attributesForGrouping)
      const values: string[] = Object.values(filtered)
      return removeDuplicates(values).map(productAttributeFormat).join(', ')
    })
  )

  return Object.entries(mapGroupedVariants)
    .map(([group, attributes]: [string, string[]]) => group + ' / ' + removeDuplicates(attributes).join(', '))
    .sort()
}
