import { ProductInterface } from '../../../interfaces'
import { ProductDetailInterface, useSplitToggle } from '../../../hooks'
import ClipboardCopy from '../../ClipboardCopy'
import { entries, productAttributeFormat, formatAttrNameForCategory } from '../../../helpers'
import { ROUTE_PATHS } from 'src/v2/constants'
import Breadcrumbs from '../../Breadcrumbs'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

const GLOBAL_SKU_PREFIX = 'global'

export function ProductDetailsInfo({
  productDetails,
  skuSearchResult
}: {
  productDetails: ProductDetailInterface
  skuSearchResult: ProductInterface
}) {
  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  const isGlobal = productDetails.sku.toLowerCase().includes(GLOBAL_SKU_PREFIX)

  return (
    <>
      <h2 className="mt-0 text-lg md:mt-8 lg:mt-0 lg:font-normal">Product details</h2>
      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
        <div className="flex flex-col gap-2">
          <span className="flex gap-3">
            <strong>SKU:</strong> <ClipboardCopy text={productDetails.sku.toUpperCase()} showText={true} />
          </span>
          {!isAPCSplitOn && (
            <span className="flex gap-3">
              <strong>Category:</strong>{' '}
              <Breadcrumbs
                itemClassName="gap-2"
                listClassName="md:space-x-2"
                pages={createBreadcrumbs({ skuSearchResult })}
              />
            </span>
          )}

          {entries(skuSearchResult.attributes).map(([name, attributes]) => {
            if (attributes.length === 1) {
              return (
                <span className="flex gap-3" key={name}>
                  <strong>{formatAttrNameForCategory(name, skuSearchResult.category)}:</strong>
                  <span>{productAttributeFormat(attributes[0])}</span>
                </span>
              )
            }
          })}

          <span className="flex gap-3">
            <strong>Product size:</strong> {productDetails.productDimensions.width}x
            {productDetails.productDimensions.height}
            {productDetails.productDimensions.units}
          </span>

          {!isGlobal && (
            <span className="flex gap-3">
              <strong>Ships from:</strong>{' '}
              {skuSearchResult.productionCountries.map((country) => (
                <img
                  alt={country}
                  className="inline-block"
                  key={country}
                  src={`/img/flags-24/${country}.png`}
                  title={country}
                />
              ))}
            </span>
          )}
        </div>
        {isGlobal && (
          <figure className="flex">
            <a
              className="h-fit shrink-0"
              href="https://www.prodigi.com/products/global/"
              rel="noreferrer noopener"
              target="_blank"
              title="What is a global product?"
            >
              <img alt="global product icon" className="block h-[55px] w-[55px]" src="/img/global-icon.png" />
            </a>
          </figure>
        )}
      </div>
    </>
  )
}

function createBreadcrumbs({ skuSearchResult }: { skuSearchResult: ProductInterface }) {
  if (skuSearchResult.productType.toLowerCase() !== skuSearchResult.category.toLowerCase()) {
    return [
      {
        href: `${ROUTE_PATHS.PRODUCTS}/${encodeURIComponent(skuSearchResult.category)}`,
        title: skuSearchResult.category
      },
      {
        title: skuSearchResult.productType
      }
    ]
  }

  return [
    {
      title: skuSearchResult.category
    }
  ]
}
