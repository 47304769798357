import { useCallback, useState } from 'react'

import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { Mockup3DPreviewImage, MockupError, MockupsPolling3D } from '.'
import { Mockups3DPreviewLoading } from './Mockups3DPreviewLoading.component'
import { MockupViewInterface, PRODIGI_TO_MOCKUP_ATTRIBUTE_MAPPING } from '../../../hooks'

export function Mockups3DPreviewDisplay({
  attributes,
  orientation,
  productSku,
  transformationId,
  viewData,
  viewKey
}: {
  attributes: Record<string, string>
  orientation: string
  productSku: string
  transformationId: string
  viewData: MockupViewInterface
  viewKey: string
}) {
  const [mockupStatus, setMockupStatus] = useState<StatusType>('loading')
  const [mockupUrl, setMockupUrl] = useState<string>()
  const [errorCode, setErrorCode] = useState<number>()

  const handleMockupPollingComplete = useCallback(
    ({
      mockupsData,
      mockupsFetchError
    }: {
      mockupsData?: { url: string }
      mockupsFetchError?: FetchErrorInterface<{ detail?: string }>
    }) => {
      if (mockupsFetchError) {
        setErrorCode(mockupsFetchError.status ?? 0)
        setMockupStatus('error')
        return
      }

      if (mockupsData) {
        setMockupUrl(mockupsData.url)
        setMockupStatus('success')
        setErrorCode(undefined)
      }
    },
    []
  )

  const filteredAttributes = mapAndFilterAttributes(attributes, orientation, Object.keys(viewData.variants))
  if (mockupStatus === 'loading') {
    return (
      <>
        <Mockups3DPreviewLoading />
        <MockupsPolling3D
          productSku={productSku}
          transformationId={transformationId}
          mockupGenerationData={{
            view_name: viewKey,
            variants: filteredAttributes
          }}
          onComplete={handleMockupPollingComplete}
        />
      </>
    )
  }

  if (mockupStatus === 'success') {
    return <Mockup3DPreviewImage attributes={filteredAttributes} productSku={productSku} url={mockupUrl ?? ''} />
  }

  return <MockupError code={`MPFE-${productSku}-${viewKey}-${errorCode}`} message="Failed to fetch mockup" />
}

function mapAndFilterAttributes(attributes: Record<string, string>, orientation: string, variantOptions: string[]) {
  // Orientation is added to the attributes before they are filtered as not all of the 3d mockups allow orientation as a variant value
  const attributesForMapping = { ...attributes, orientation }

  const mappedAttributes = Object.entries(attributesForMapping).reduce(
    (mappedAttributesAcc: Record<string, string>, [attributeName, attributeValue]) => {
      const mappedAttributeName = PRODIGI_TO_MOCKUP_ATTRIBUTE_MAPPING[attributeName] ?? attributeName

      if (variantOptions.includes(mappedAttributeName)) {
        return { ...mappedAttributesAcc, [mappedAttributeName]: attributeValue.toUpperCase() }
      }

      return mappedAttributesAcc
    },
    {}
  )

  return mappedAttributes
}
