// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import type { DispatchFunc } from '../../types'
import type { ComponentType } from 'react'
import { resetRsaaStatus } from '../../actions/rsaa'

type Props = {|
  resetRsaaStatus: (actionId: string) => void,
  dispatch: DispatchFunc
|}

function withResetRsaaStatus (actionId: string): ((WrappedComp: ComponentType<any>) => any) {
  return (WrappedComp: ComponentType<*>) => {
    class WithResetRsaaStatus extends PureComponent<Props> {
    componentWillUnmount = () => this.props.resetRsaaStatus(actionId)

    render (): React$Node {
      const { resetRsaaStatus, dispatch, ...restProps } = this.props
      return <WrappedComp {...restProps} />
    }
    }
    function mapDispatchToProps (dispatch: DispatchFunc) {
      return {
        resetRsaaStatus: (ref: string) => {
          dispatch(resetRsaaStatus(ref))
        }
      }
    }

    return connect(null, mapDispatchToProps)(WithResetRsaaStatus)
  }
}

export default withResetRsaaStatus
