import { isUrl } from '../helpers'
import { useProduct, useSplitToggle } from '../../../hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS } from '../../../constants'
import { ItemMetaDataInterface, ProductCostedAttributeInterface } from '../../../interfaces'
import { formatAttrNameForCategory, productAttributeFormat } from '../../../helpers'

export function OrderDetailAttributesDisplay({
  attributes,
  metaData,
  category,
  sku
}: {
  attributes: Record<string, string>
  metaData?: ItemMetaDataInterface
  category?: string
  sku?: string
}) {
  const { splitIsOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { product } = useProduct(splitIsOn ? sku : undefined, {
    config: { revalidateOnFocus: false }
  })

  const costedAttributes = product?.pwintyProduct?.costedAttributes ?? {}

  return (
    <ul className="flex flex-wrap gap-4">
      {Object.entries(attributes ?? {}).map(([attributeName, attributeValue]) => {
        const costedAttributeData = costedAttributes[attributeName]

        return (
          <li
            className="rounded-md bg-gray-100 py-2 px-4 text-sm text-black"
            data-test="shipment-item__attributes"
            key={attributeName + attributeValue}
          >
            <span data-test="shipment-item__attribute-name">
              <AttributeKeyDisplay
                attributeKey={attributeName}
                category={category}
                costedAttributeData={costedAttributeData}
              />
              :{' '}
            </span>
            <strong data-test="shipment-item__attribute-value">
              <AttributeValueDisplay attributeValue={attributeValue} costedAttributeData={costedAttributeData} />
            </strong>
          </li>
        )
      })}

      {metaData?.spineText && (
        <li className="rounded-md bg-gray-100 py-2 px-4 text-sm text-black">
          <span>Spine text: </span>
          <strong style={{ wordBreak: 'break-word' }}>{metaData.spineText}</strong>
        </li>
      )}

      {metaData?.spineTextSizePx && (
        <li className="rounded-md bg-gray-100 py-2 px-4 text-sm text-black">
          <span>Spine text size: </span>
          <strong>{metaData.spineTextSizePx}px</strong>
        </li>
      )}

      {metaData?.spineTextFontFamily && (
        <li className="rounded-md bg-gray-100 py-2 px-4 text-sm text-black">
          <span>Spine text font: </span>
          <strong>
            <SpineTextFont selectedValue={metaData.spineTextFontFamily} />
          </strong>
        </li>
      )}

      {metaData?.spineBackgroundColourHexCode && (
        <li className="flex items-center gap-2 rounded-md bg-gray-100 py-2 px-4 text-sm text-black">
          <span>Spine background colour: </span>
          <div className="h-5 w-5 border" style={{ backgroundColor: metaData.spineBackgroundColourHexCode }}></div>
        </li>
      )}

      {metaData?.spineTextColourHexCode && (
        <li className="flex items-center gap-2 rounded-md bg-gray-100 py-2 px-4 text-sm text-black">
          <span>Spine text colour: </span>
          <div className="h-5 w-5 border" style={{ backgroundColor: metaData.spineTextColourHexCode }}></div>
        </li>
      )}
    </ul>
  )
}

function SpineTextFont({ selectedValue }: { selectedValue: string }) {
  const selectedFontOption = PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS.find(
    (fontOption) => fontOption.value === selectedValue
  )

  return <>{selectedFontOption?.name ?? selectedValue}</>
}

function AttributeKeyDisplay({
  attributeKey,
  category,
  costedAttributeData
}: {
  attributeKey: string
  category?: string
  costedAttributeData?: ProductCostedAttributeInterface
}) {
  if (costedAttributeData?.name) {
    return <>{costedAttributeData?.name}</>
  }

  return <>{formatAttrNameForCategory(attributeKey, category ?? '')}</>
}

function AttributeValueDisplay({
  attributeValue,
  costedAttributeData
}: {
  attributeValue: string
  costedAttributeData?: ProductCostedAttributeInterface
}) {
  const selectedCostedAttributeValue = costedAttributeData?.options?.find((option) => option.value === attributeValue)
  if (selectedCostedAttributeValue?.name) {
    return <>{selectedCostedAttributeValue.name}</>
  }

  if (isUrl(attributeValue)) {
    const splitUrl = attributeValue.split('/')
    return (
      <span className="break-words">
        <a href={attributeValue} target="_blank" rel="noreferrer" title="Open URL">
          {'…'}
          {splitUrl[splitUrl.length - 1].split('?')[0]}
        </a>
      </span>
    )
  }

  return <>{productAttributeFormat(attributeValue)}</>
}
