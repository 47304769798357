// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import styles from '../Apps.module.css'
import type { ConnectedAppType } from '../../../types'
import ToggleSwitch from '../../../components/ToggleSwitch'

type Props = {|
  appData: ConnectedAppType,
  handleAppStatusChange: (isOn: boolean) => void
|}

class ConnectedAppCard extends React.Component<Props> {
  render (): React$Node {
    const {
      description,
      disableActiveStatusToggle,
      icon,
      isOn,
      linkText,
      title,
      url
    } = this.props.appData

    const {
      handleAppStatusChange
    } = this.props

    return (
      <div className={styles.connectedAppCard}>
        <div className={styles.appCardIcon}>{icon}</div>
        <div className={styles.appCardTitle}>{title}</div>
        <div className={styles.appCardDescription}>{description}</div>
        <div className={styles.appCardActions}>
          <Link to={url}>{linkText}</Link>
          <span className={styles.spacer}></span>
          <span className={styles.appStatus}>Active</span>
          <ToggleSwitch
            disabled={disableActiveStatusToggle}
            ariaLabel="App status"
            onChange={(isOn, event) => handleAppStatusChange(isOn)}
            checked={isOn} />
        </div>
      </div>
    )
  }
}

export default ConnectedAppCard
