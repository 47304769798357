// @flow
import { SUCCESS } from '../../data/rsaa'
import type { CsvUploadState, CatalogueItem } from '../../types'

export function updateProductDetails (state: CsvUploadState, product: CatalogueItem): CsvUploadState {
  return {
    ...state,
    productDetails: {
      ...state.productDetails,
      [product.sku]: product
    },
    productDetailsProgress: {
      ...state.productDetailsProgress,
      [product.sku]: SUCCESS
    }
  }
}
