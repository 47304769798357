// @flow
import type { Dictionary } from '../../types'

const INVALID_ATTRIBUTES = [
  'gender',
  'brand',
  'style',
  'paperType',
  'substrateWeight',
  'mount',
  'mountColour',
  'quality',
  'glaze',
  'channels',
  'RipOutTab',
  'ripOutTab'
]

export function filterOutInvalidTemplateAttributes(allAttributes: Dictionary<string>): Dictionary<string> {
  return allAttributes
    ? Object.keys(allAttributes).reduce((attributeAcc, attributeName) => {
        if (!INVALID_ATTRIBUTES.includes(attributeName)) {
          attributeAcc[attributeName] = allAttributes[attributeName]
        }

        return attributeAcc
      }, {})
    : {}
}
