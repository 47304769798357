// @flow
import React, { Component } from 'react'
import Radium from 'radium'

type Props = {||}

class LoadingDots extends Component<Props> {
  getStyles () {
    return {
      fetching: {
        fontSize: '1.2em',
        textDecoration: 'none',
        animationName: 'blink',
        animationDuration: ' 1.4s',
        animationIterationCount: 'infinite',
        animationFillMode: 'both'
      },
      secondDot: {
        animationDelay: '.2s'
      },
      thirdDot: {
        animationDelay: '.4s'
      }

    }
  }

  render (): React$Node {
    const styles = this.getStyles()
    return (
      <span>
        <span style={styles.fetching}>.</span>
        <span style={{ ...styles.fetching, ...styles.secondDot }}>.</span>
        <span style={{ ...styles.fetching, ...styles.thirdDot }}>.</span>
      </span>
    )
  }
}

export default (Radium(LoadingDots): any)
