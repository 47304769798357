import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers/fetcher'
import { DictionaryInterface, FetchErrorInterface, QuoteAssetInterface, QuoteInterface } from '../interfaces'

export interface QuoteSuccessResponseInterface {
  outcome: string
  quotes: QuoteInterface[]
  traceParent: string
}

export interface QuoteRequestItemInterface {
  assets: QuoteAssetInterface[]
  attributes: DictionaryInterface<string>
  copies: number
  sku: string
}

interface QuoteErrorResponseInterface {
  data: unknown
  statusCode: number
  statusText: string
  traceParent: string
}

export function useQuotes({
  config,
  countryCode,
  currencyCode,
  items
}: {
  config?: SWRConfiguration
  countryCode?: string
  currencyCode?: string
  items: QuoteRequestItemInterface[] | null
}) {
  const { data: quotesData, error } = useSWR<
    QuoteSuccessResponseInterface,
    FetchErrorInterface<QuoteErrorResponseInterface>
  >(
    currencyCode && countryCode && items && items.length > 0 ? [countryCode, currencyCode, items] : null,
    quotesFetcher,
    config
  )

  return {
    error,
    isLoading: !quotesData && !error,
    quotesData
  }
}

function quotesFetcher(countryCode: string, currencyCode: string, items: QuoteRequestItemInterface[]) {
  return fetcher<QuoteSuccessResponseInterface>(`${process.env.REACT_APP_PRODIGI_API_ENDPOINT}/v4.0/quotes`, {
    method: 'POST',
    body: JSON.stringify({ items, destinationCountryCode: countryCode, currencyCode })
  })
}
