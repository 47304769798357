// @flow

import { mapV4AttributeNameToV3 } from './mapV4AttributeNameToV3'
import { getAttrNameForCategory } from '../getAttrNameForCategory'

export function mapV4AttributeNameToUserFormat (name: string, productCategory: string): string {
  const attributeNameInV4Format = mapV4AttributeNameToV3(name)

  return getAttrNameForCategory(attributeNameInV4Format, productCategory)
}
