export function formatCountry(country: string) {
  switch (country) {
    case 'BRUNEI DARUSSALAM':
      return 'Brunei'
    case 'CONGO':
      return 'Congo, Republic of the'
    case 'CONGO THE DEMOCRATIC REPUBLIC OF THE':
      return 'Congo, Democratic Republic of the'
    case "COTE D'IVOIRE":
      return "Cote D'Ivoire"
    case 'FALKLAND ISLANDS(MALVINAS)':
      return 'Falkland Islands'
    case 'FRENCH SOUTHERN TERRITORIES':
      return 'French Southern and Antarctic Lands'
    case 'HEARD ISLAND AND MCDONALD ISLANDS':
      return 'Heard Island and McDonald Islands'
    case 'IRAN ISLAMIC REPUBLIC OF':
      return 'Iran'
    case "KOREA DEMOCRATIC PEOPLE'S REPUBLIC OF":
      return 'North Korea'
    case 'KOREA REPUBLIC OF':
      return 'South Korea'
    case "LAO PEOPLE'S DEMOCRATIC REPUBLIC":
      return 'Laos'
    case 'LIBYAN ARAB JAMAHIRIYA':
      return 'Libya'
    case 'MACEDONIA THEFORMER YUGOSLAV REPUBLIC OF':
      return 'North Macedonia'
    case 'MICRONESIA FEDERATED STATES OF':
      return 'Micronesia, Federated States of'
    case 'MOLDOVA REPUBLIC OF':
      return 'Moldova'
    case 'Saint Barth?lemy':
      return 'Saint Barthelemy'
    case 'SVALBARD AND JANMAYEN':
      return 'Svalbard and Jan Mayen'
    case 'SYRIAN ARAB REPUBLIC':
      return 'Syria'
    case 'TAIWAN PROVINCE OFCHINA':
      return 'Taiwan'
    case 'TANZANIA UNITED REPUBLIC OF':
      return 'Tanzania'
    case 'TRINIDAD ANDTOBAGO':
      return 'Trinidad and Tobago'
    case 'TURKS AND CAICOSISLANDS':
      return 'Turks and Caicos Islands'
    case 'UNITED ARAB EMIR?ATES':
      return 'United Arab Emirates'
    default:
      return formattedCountryName(country)
  }
}

function formattedCountryName(country: string) {
  // Capitalize the first letter in parentheses
  const up = country.toLowerCase().replace(/\((.*)/, (x) => {
    return x.slice(0, 1) + x.charAt(1).toUpperCase() + x.slice(2)
  })
  // Capitalize the first letter after a dot
  const up2 = up.replace(/\.(.*)/, (x) => {
    return x.slice(0, 1) + x.charAt(1).toUpperCase() + x.slice(2)
  })
  // Capitalize the first letter after a hyphen
  const up3 = up2.replace(/-(.*)/, (x) => {
    return x.slice(0, 1) + x.charAt(1).toUpperCase() + x.slice(2)
  })
  // Capitalize the first letter of each word provided its not 'and', 'the' or 'of'
  return up3
    .split(' ')
    .map((x) => {
      if (x === 'and' || x === 'of' || x === 'the') {
        return x
      }
      return x.charAt(0).toUpperCase() + x.slice(1)
    })
    .join(' ')
}
