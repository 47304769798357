// @flow
import type { Action } from '../../types'

export const RESET_ORDERS_RSAA_STATUS = 'RESET_ORDERS_RSAA_STATUS'

export function resetOrdersRsaaStatus (): Action {
  return {
    type: RESET_ORDERS_RSAA_STATUS
  }
}
