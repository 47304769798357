import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from '../../constants'
import ImageLibraryFullPage from '../../components/ImageLibraryFullPage/ImageLibraryFullPage.component'

export default function ImageLibraryPage() {
  useEffect(() => {
    document.title = 'Image library | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.IMAGE_LIBRARY)
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <ImageLibraryFullPage />
    </main>
  )
}
