// @flow
import { connect } from 'react-redux'
import type {
  FormattedOrderItem,
  CsvItemProductDetails,
  AppState,
  Status,
  ThumbnailItem,
  CatalogueItem,
  V4ProductDetails,
  Artwork
} from '../../../../types'
import CsvItemImageController from './CsvItemImageController'
import { getCsvItemDetails, getCsvItemTemplates } from '../../../../selectors/csvUpload'
import { createThumbnailItemFromCsvItem } from '../../../../helpers/createThumbnailItemFromCsvItem'
import { getArtworkTransformationStatusById, getTemplateStatus, getArtworkStatus, getArtworkByItemId, getArtworkErrorMessage } from '../../../../selectors/images'
import { buildImageId } from '../../../../actions/images'
import type { ComponentType } from 'react'
import { getV4ProductDetailsBySku } from '../../../../selectors/catalogue/catalogue'
import { getArtworkTransformationsById } from '../../../../selectors/images/images'

type OwnProps = {|
  orderId: string,
  details: ?CsvItemProductDetails,
  item: FormattedOrderItem,
  canBeEdited: boolean,
  isImageModalOpen: boolean,
  imageStyle: string,
  openImageModal: (orderItemId: string) => void,
  closeImageModal: () => void
|}

type StateProps = {|
  imageErrorMessage: string,
  imageStatus: Status,
  templateStatus: Status,
  artworkTransformationStatus: Status | null,
  artwork: ?Artwork,
  hasArtworkTransformation: boolean,
  thumbnailItem: ?ThumbnailItem,
  catalogueDetails: ?CatalogueItem,
  v4ProductDetails: ?V4ProductDetails,
|}

function makeMapStateToProps () {
  return (state: AppState, ownProps: OwnProps): StateProps => {
    const v4ProductDetails = getV4ProductDetailsBySku(state, ownProps.item.sku)
    const imageId = buildImageId(ownProps.item.id, ownProps.item.selectedPrintArea)
    const itemProductDetails = getCsvItemDetails(state, ownProps.item.sku)
    const thumbnailArtwork = getArtworkByItemId(state, imageId)
    const templates = getCsvItemTemplates(state, ownProps.item.id)
    const thumbnailArtworkTransformations = getArtworkTransformationsById(state, imageId)

    return {
      catalogueDetails: itemProductDetails,
      v4ProductDetails,
      templateStatus: getTemplateStatus(state, ownProps.item.sku, ownProps.item.selectedAttributes),
      imageStatus: getArtworkStatus(state, imageId),
      imageErrorMessage: getArtworkErrorMessage(state, imageId),
      artworkTransformationStatus: getArtworkTransformationStatusById(state, imageId),
      hasArtworkTransformation: Boolean(thumbnailArtworkTransformations),
      artwork: thumbnailArtwork,
      thumbnailItem: createThumbnailItemFromCsvItem(
        ownProps.item,
        thumbnailArtwork,
        thumbnailArtworkTransformations,
        templates
      )
    }
  }
}

export default (connect(makeMapStateToProps)(CsvItemImageController): ComponentType<OwnProps>)
