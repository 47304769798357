// @flow
import { connect } from 'react-redux'
import { compose } from 'redux'

import AppUser from './AppUser'
import type { AppState, DispatchFunc } from '../../types'
import { getUser } from '../../selectors/user'
import WithUserDetails from '../../components/hoc/WithUserDetails'
import { changeDeliveryCountry } from '../../actions/manualOrderForm'
import { getHasOrderInProgress } from '../../selectors/manualOrderForm'

function mapStateToProps(state: AppState) {
  return {
    user: getUser(state),
    hasOrderInProgress: getHasOrderInProgress(state)
  }
}

function mapDispatchToProps(dispatch: DispatchFunc) {
  return {
    changeDeliveryCountry: (country: string) => dispatch(changeDeliveryCountry(country))
  }
}

export default (compose(WithUserDetails, connect(mapStateToProps, mapDispatchToProps))(AppUser): any)
