import { useParams } from 'react-router'

import { formatCost } from '../../../helpers'
import ClipboardCopy from '../../ClipboardCopy'
import OrderItemSource from '../../OrderItemSource'
import { getCountryName } from '../../../../helpers/countryNames'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { useMerchantService, useOrderDetail } from '../../../hooks'

export function OrderEditSummary() {
  const { id: orderId } = useParams<{ id: string }>()
  const { merchantDetails } = useMerchantService()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  const numberOfItems = orderDetailsResponse?.data.draftCosts?.costSummary.totalQuantityOfItems
  const totalCost = orderDetailsResponse?.data.draftCosts?.costSummary.totalCost

  const salesChannel = merchantDetails.salesChannels.find((channel) => channel.id === orderDetails.salesChannelId)

  return (
    <div className="isolate w-full" data-test="order-edit-summary">
      <div className="flex flex-col border bg-white p-6">
        <h2 className="mt-0 text-lg">Summary</h2>
        <span className="mt-4" data-test="order-edit-summary__recipient-name">
          {orderDetails.recipient.name}
        </span>
        <span data-test="order-edit-summary__country">
          {getCountryName(orderDetails.recipient.address.countryCode)}
        </span>

        <ItemSummary numberOfItems={numberOfItems} totalCost={totalCost} />

        {orderDetails.merchantReference && (
          <div className="mt-4">
            <div className="font-medium">Order reference</div>
            <div data-test="order-edit-summary__reference">
              <ClipboardCopy text={orderDetails.merchantReference} />
            </div>
          </div>
        )}

        {salesChannel && <OrderItemSource className="mt-4" source={salesChannel} />}
      </div>
    </div>
  )
}

function ItemSummary({ numberOfItems, totalCost }: { numberOfItems?: number; totalCost?: CostInterface }) {
  if (numberOfItems === 0) {
    return (
      <span className="mt-4" data-test="order-edit-summary__items">
        0 items
      </span>
    )
  }

  const formattedTotalCost =
    formatCost({
      amount: totalCost?.amount,
      currencyCode: totalCost?.currencyCode
    }) || 'Cost pending'

  if (!numberOfItems) {
    return (
      <span className="mt-4" data-test="order-edit-summary__items">
        {formattedTotalCost}
      </span>
    )
  }

  return (
    <span className="mt-4" data-test="order-edit-summary__items">
      {numberOfItems} {numberOfItems > 1 ? 'items' : 'item'}, {formattedTotalCost}
    </span>
  )
}
