// @flow
export const WOOCOMMERCE_FIRST_NAME_HEADER = 'First Name (Shipping)'
export const WOOCOMMERCE_LAST_NAME_HEADER = 'Last Name (Shipping)'
export const WOOCOMMERCE_NAME_HEADERS = [WOOCOMMERCE_FIRST_NAME_HEADER, WOOCOMMERCE_LAST_NAME_HEADER]

export const WOOCOMMERCE_CSV_HEADERS = [
  'Order Number',
  'Order ID',
  'Order Status',
  'Order Notes',
  'Order Date',
  'Customer Note',
  'First Name (Billing)',
  'Last Name (Billing)',
  'Company (Billing)',
  'Address 1&2 (Billing)',
  'City (Billing)',
  'State Code (Billing)',
  'Postcode (Billing)',
  'Country Code (Billing)',
  'Email (Billing)',
  'Phone (Billing)',
  WOOCOMMERCE_FIRST_NAME_HEADER,
  WOOCOMMERCE_LAST_NAME_HEADER,
  'Address 1&2 (Shipping)',
  'City (Shipping)',
  'State Code (Shipping)',
  'Postcode (Shipping)',
  'Country Code (Shipping)',
  'Payment Method Title',
  'Cart Discount Amount',
  'Order Subtotal Amount',
  'Shipping Method Title',
  'Order Shipping Amount',
  'Order Refund Amount',
  'Order Total Amount',
  'Order Total Tax Amount',
  'SKU',
  'Product Name',
  'Item Name',
  'Product Variation',
  'Category',
  'Type',
  'Item #',
  'Quantity',
  'Image URL',
  'Item Cost',
  'Coupon Code',
  'Discount Amount',
  'Discount Amount Tax'
]

export const WOOCOMMERCE_SECOND_FORMAT_CSV_HEADERS = [
  'Order ID',
  'Recipient Name',
  'Address 1',
  'Address 2',
  'City',
  'State/County',
  'Postcode',
  'Country Code',
  'Shipping',
  'SKU',
  'Product Name',
  'Item Name',
  'Product Variation',
  'Category',
  'Type',
  'Item #',
  'Quantity',
  'FileURL'
]

export const WOOCOMMERCE_CSV_HEADER_FORMATS = [
  WOOCOMMERCE_CSV_HEADERS,
  WOOCOMMERCE_SECOND_FORMAT_CSV_HEADERS
]
