import OrdersListActionFilter from '../OrdersListActionFilter'
import { OrdersListActionSearch, OrdersListActionSort } from './components'

export default function OrdersListActions() {
  return (
    <div className={`sticky top-nav-bar-height z-10 min-w-full bg-gray-100 pt-6 align-middle lg:pt-8`}>
      <div className="flex w-full flex-wrap justify-start gap-2 border bg-white p-4 md:gap-4 xl:justify-end">
        <span className="mr-auto flex w-full items-center justify-self-start sm:w-64 lg:w-96">
          <OrdersListActionSearch />
        </span>
        <span className="flex w-64 flex-grow items-center sm:flex-grow-0">
          <OrdersListActionFilter />
        </span>
        <span className="flex w-64 flex-grow items-center sm:flex-grow-0">
          <OrdersListActionSort />
        </span>
      </div>
    </div>
  )
}
