// @flow
import RecentItemList from './RecentItemList'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { addRecentItemToBasket, setupRecentItems, clearRecentItems, removeRecentItem, RECENT_ITEMS_LOGGER_ID, SETUP_RECENT_ITEMS_SUCCESS } from '../../../../actions/recentItems'
import { FETCH_PRICES } from '../../../../actions/catalogue'
import { getAvailableRecentItems } from '../../../../selectors/manualOrderForm'
import { getRsaaStatusProps } from '../../../../selectors/rsaa'
import WithAsyncDataFetcher from '../../../../components/hoc/WithAsyncDataFetcher'
import WithLoadingIndicator from '../../../../components/hoc//WithLoadingIndicator'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import type { AppState, MultiAssetBasketItem, DispatchFunc, ThunkAsync, CatalogueItem, Dictionary, RsaaStatusProps } from '../../../../types'
import type { ClearRecentItems, AddRecentItemToBasket, SetupRecentItems } from '../../../../actions/types'
import { pushRedirect } from '../../../../actions/router'
import { getCatalogueItemBySkus } from '../../../../selectors/catalogue'
import { buildRsaaActionId } from '../../../../helpers/rsaa'

export type DispatchProps = {|
  addRecentItem: (item: MultiAssetBasketItem) => ThunkAsync<AddRecentItemToBasket>,
  clearRecentItems: () => ClearRecentItems,
  removeRecentItem: (originalImageUrl: string) => void,
  redirect: (path: string) => void
|}

export type StateProps = {|
  availableRecentItems: MultiAssetBasketItem[],
  catalogueItems: Dictionary<CatalogueItem>,
  fetchPricesRsaaStatus: RsaaStatusProps,
|}

export type FetchDataAsync = {|
  fetchDataAsync: () => SetupRecentItems
|}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    addRecentItem: (item: MultiAssetBasketItem) => dispatch(addRecentItemToBasket(item)),
    removeRecentItem: (originalImageUrl: string) => dispatch(removeRecentItem(originalImageUrl)),
    clearRecentItems: () => dispatch(clearRecentItems()),
    redirect: (path: string) => dispatch(pushRedirect(path))
  }
}

function mapStateToProps (state: AppState): StateProps {
  const availableRecentItems = getAvailableRecentItems(state)
  const skus = availableRecentItems.map(availableRecentItem => availableRecentItem.sku)
  const catalogueItems = getCatalogueItemBySkus(state, skus)
  const fetchPricesRsaaActionId = buildRsaaActionId(FETCH_PRICES, RECENT_ITEMS_LOGGER_ID)
  return {
    availableRecentItems,
    catalogueItems,
    fetchPricesRsaaStatus: getRsaaStatusProps(state, fetchPricesRsaaActionId)
  }
}

function wrapFetchDataAsyncWithDispatch (dispatch: DispatchFunc): FetchDataAsync {
  return {
    fetchDataAsync: () => dispatch(setupRecentItems())
  }
}

const WithRecentItemLoader = WithLoadingIndicator('recentItemsLoading')(LoadingSpinner)

export default (compose(
  connect(null, wrapFetchDataAsyncWithDispatch),
  WithAsyncDataFetcher('recentItems', SETUP_RECENT_ITEMS_SUCCESS),
  WithRecentItemLoader,
  connect(mapStateToProps, mapDispatchToProps)
)(RecentItemList): any)
