import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../../v2/components/Button'
import Skeleton from '../../../../v2/components/Skeleton'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { getCsvOrderBranding } from '../../../../selectors/csvUpload'
import { useBrandingDetails, useSplitToggle } from '../../../../v2/hooks'
import { FetchErrorInterface, InsertDataType } from '../../../../v2/interfaces'
import { updateCsvInserts } from '../../../../actions/csvUpload/updateCsvInserts'
import { fetchPricingAndShippingForCsvOrder } from '../../../../actions/csvUpload'
import { BrandedInsertsOrderModal } from '../../../../v2/components/BrandedInsertsOrderModal'

type CsvBrandedInsertsType = {
  orderId: string
}

export function CsvBrandedInserts(props: CsvBrandedInsertsType) {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  if (!isBrandingSettingsOn) {
    return null
  }

  return <CsvBrandedInsertsData {...props} />
}

// TODO: Remove logs and update with real data
function CsvBrandedInsertsData({ orderId }: CsvBrandedInsertsType) {
  const [isOrderBrandedInsertsModalOpen, setIsOrderBrandedInsertsModalOpen] = useState(false)
  const { brandingDetails, brandingDetailsFetchError, isLoadingBrandingDetails } = useBrandingDetails()

  const dispatch = useDispatch()
  const csvOrderBrandedInserts: InsertDataType[] | null = useSelector((state) => getCsvOrderBranding(state, orderId))
  console.log({ csvOrderBrandedInserts })

  const orderBrandedInserts = csvOrderBrandedInserts ?? []
  console.log({ orderBrandedInserts })

  if (brandingDetailsFetchError) {
    return (
      <CsvInsertsError
        brandingDetailsFetchError={brandingDetailsFetchError}
        orderBrandedInserts={orderBrandedInserts}
        onRemove={() => {
          dispatch(updateCsvInserts({ orderId, inserts: [] }))
          dispatch(fetchPricingAndShippingForCsvOrder(orderId))
        }}
      />
    )
  }

  if (isLoadingBrandingDetails || !brandingDetails) {
    return <CsvInsertsLoading />
  }

  const hasInserts = orderBrandedInserts.length > 0

  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        <div className="ml-auto">
          <Button variant="secondary" size="sm" onClick={() => setIsOrderBrandedInsertsModalOpen(true)}>
            {hasInserts ? 'View & edit' : 'Add inserts'}
          </Button>
        </div>
      </div>

      {/* TODO: Pass branding details */}
      {hasInserts ? <HasInsertsContent orderBrandedInserts={orderBrandedInserts} /> : <NoInsertsContent />}

      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={orderBrandedInserts}
        insertSets={undefined}
        open={isOrderBrandedInsertsModalOpen}
        setOpen={setIsOrderBrandedInsertsModalOpen}
        onSave={(newBrandedInserts) => {
          const newBrandedInsertsWithImageUrls = newBrandedInserts.filter((insert) => Boolean(insert.imageUrl))

          console.log({ newBrandedInsertsWithImageUrls })
          dispatch(updateCsvInserts({ orderId, inserts: newBrandedInsertsWithImageUrls }))

          dispatch(fetchPricingAndShippingForCsvOrder(orderId))
          setIsOrderBrandedInsertsModalOpen(false)
        }}
        onClose={() => setIsOrderBrandedInsertsModalOpen(false)}
      />
    </div>
  )
}

function HasInsertsContent({ orderBrandedInserts }: { orderBrandedInserts: InsertDataType[] }) {
  return (
    <div className="mt-6 text-black">
      <div>
        {orderBrandedInserts.length === 1
          ? `${orderBrandedInserts.length} insert`
          : `${orderBrandedInserts.length} inserts`}
        , £TODO
      </div>
      <div className="mt-2 text-gray-700">TODO description</div>
    </div>
  )
}

function NoInsertsContent() {
  return <div className="mt-6 text-black">Add a packing slip, packaging sticker or marketing insert.</div>
}

function CsvInsertsError({
  brandingDetailsFetchError,
  orderBrandedInserts,
  onRemove
}: {
  brandingDetailsFetchError: FetchErrorInterface<unknown>
  orderBrandedInserts: InsertDataType[]
  onRemove: () => void
}) {
  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        {orderBrandedInserts.length > 0 && (
          <div className="ml-auto">
            <Button variant="secondary" size="sm" theme="danger" onClick={onRemove}>
              Remove
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6 text-magenta-800">
        {orderBrandedInserts.length > 0 && (
          <>
            {orderBrandedInserts.length === 1 ? (
              <>{orderBrandedInserts.length} insert</>
            ) : (
              <>{orderBrandedInserts.length} inserts</>
            )}{' '}
            assigned to this order.{' '}
          </>
        )}
        An error occurred while loading inserts (code {brandingDetailsFetchError.status ?? '0'})
      </div>
    </div>
  )
}

function CsvInsertsLoading() {
  return (
    <div className="border bg-white p-6">
      <h2 className="mt-0 text-lg">Inserts</h2>

      <Skeleton className="mt-6 h-16" />
    </div>
  )
}
