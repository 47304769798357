import Countries from 'country-list'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { HEADER_NAMES } from '../constants'
import { formatToSentenceCase } from '../../../helpers'
import { InlineMenuOptionInterface } from '../../InlineMenu'
import { VariantColumnType, VariantRowType } from '../../../hooks'
import { VariantsInfoAction } from '../components/VariantsInfoAction.component'
import { VariantsOrderAction } from '../components/VariantsOrderAction.component'
import { VARIANTS_FILTER_VALUE_SEPARATOR } from '../components/VariantsColumnFilter.component'

const columnHelper = createColumnHelper<VariantRowType>()

export function buildVariantTableColumns(columnData: VariantColumnType, productName: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnsToRender: ColumnDef<VariantRowType, any>[] = []

  if (columnData.size) {
    const name = HEADER_NAMES.SIZE
    columnsToRender.push(
      columnHelper.accessor((row) => row.size?.value.join(', '), {
        enableColumnFilter: columnData.size?.filterType !== 'none',
        id: name,
        enableSorting: columnData.size?.enableSorting ?? false,
        sortingFn: 'alphanumeric',
        header: columnData.size?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.size?.filterType ?? 'none',
          options: columnData.size?.options.sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
          )
        },
        cell: (info) => (
          <>{(info.row.original.size?.value ?? []).length > 0 ? info.row.original.size?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.size?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.orientation) {
    const name = HEADER_NAMES.ORIENTATION
    columnsToRender.push(
      columnHelper.accessor((row) => row.orientation?.value.join(' / '), {
        enableColumnFilter: columnData.orientation?.filterType !== 'none',
        enableSorting: columnData.orientation?.enableSorting ?? false,
        id: name,
        header: columnData.orientation?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.orientation?.filterType ?? 'none',
          options: columnData.orientation?.options
        },
        cell: (info) => (
          <>
            {info.row.original.orientation && info.row.original.orientation.value.length > 0
              ? formatToSentenceCase(info.row.original.orientation.value.join(' / '))
              : '-'}
          </>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.orientation?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.substrateWeight) {
    const name = HEADER_NAMES.SUBSTRATE_WEIGHT
    columnsToRender.push(
      columnHelper.accessor((row) => row.substrateWeight?.value.join(', '), {
        enableColumnFilter: columnData.substrateWeight?.filterType !== 'none',
        enableSorting: columnData.substrateWeight?.enableSorting ?? false,
        id: name,
        header: columnData.substrateWeight?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.substrateWeight?.filterType ?? 'none',
          options: columnData.substrateWeight?.options
        },
        cell: (info) => (
          <>
            {(info.row.original.substrateWeight?.value ?? []).length > 0
              ? info.row.original.substrateWeight?.value.join(', ')
              : '-'}
          </>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.substrateWeight?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.glaze) {
    const name = HEADER_NAMES.GLAZE
    columnsToRender.push(
      columnHelper.accessor((row) => row.glaze?.value.join(', '), {
        enableColumnFilter: columnData.glaze?.filterType !== 'none',
        enableSorting: columnData.glaze?.enableSorting ?? false,
        id: name,
        header: columnData.glaze?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.glaze?.filterType ?? 'none',
          options: columnData.glaze?.options
        },
        cell: (info) => (
          <>{(info.row.original.glaze?.value ?? []).length > 0 ? info.row.original.glaze?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.glaze?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.finish) {
    const name = HEADER_NAMES.FINISH
    columnsToRender.push(
      columnHelper.accessor((row) => row.finish?.value.join(', '), {
        enableColumnFilter: columnData.finish?.filterType !== 'none',
        enableSorting: columnData.finish?.enableSorting ?? false,
        id: name,
        header: columnData.finish?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.finish?.filterType ?? 'none',
          options: columnData.finish?.options
        },
        cell: (info) => (
          <>{(info.row.original.finish?.value ?? []).length > 0 ? info.row.original.finish?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.finish?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.style) {
    const name = HEADER_NAMES.STYLE
    columnsToRender.push(
      columnHelper.accessor((row) => row.style?.value.join(', '), {
        enableColumnFilter: columnData.style?.filterType !== 'none',
        enableSorting: columnData.style?.enableSorting ?? false,
        id: name,
        header: columnData.style?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.style?.filterType ?? 'none',
          options: columnData.style?.options
        },
        cell: (info) => (
          <>{(info.row.original.style?.value ?? []).length > 0 ? info.row.original.style?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.style?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.frameColour) {
    const name = HEADER_NAMES.FRAME_COLOUR
    columnsToRender.push(
      columnHelper.accessor((row) => row.frameColour?.value.map((value) => value.name).join(', '), {
        enableColumnFilter: columnData.frameColour?.filterType !== 'none',
        enableSorting: columnData.frameColour?.enableSorting ?? false,
        id: name,
        header: columnData.frameColour?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.frameColour?.filterType ?? 'none',
          options: columnData.frameColour?.options ? mapUrlColourOptions(columnData.frameColour.options) : undefined
        },
        cell: (info) => <FrameColourSwatches frames={info.row.original.frameColour?.value} />,
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const currentRowNames = row.original.frameColour?.value.map((option) => option.name)
          const cellDataAsString = currentRowNames?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.mountColour) {
    const name = HEADER_NAMES.MOUNT_COLOUR
    columnsToRender.push(
      columnHelper.accessor((row) => row.mountColour?.value.map((value) => value.name).join(', '), {
        enableColumnFilter: columnData.mountColour?.filterType !== 'none',
        enableSorting: columnData.mountColour?.enableSorting ?? false,
        id: name,
        header: columnData.mountColour?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.mountColour?.filterType ?? 'none',
          options: columnData.mountColour?.options ? mapHexColourOptions(columnData.mountColour.options) : undefined
        },
        cell: (info) => <HexColourSwatches colours={info.row.original.mountColour?.value} />,
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const currentRowNames = row.original.mountColour?.value.map((option) => option.name)
          const cellDataAsString = currentRowNames?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.colours) {
    const name = HEADER_NAMES.COLOURS
    columnsToRender.push(
      columnHelper.accessor((row) => row.colours?.value.map((value) => value.name).join(', '), {
        enableColumnFilter: columnData.colours?.filterType !== 'none',
        enableSorting: columnData.colours?.enableSorting ?? false,
        id: name,
        header: columnData.colours?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.colours?.filterType ?? 'none',
          options: columnData.colours?.options ? mapHexColourOptions(columnData.colours.options) : undefined
        },
        cell: (info) => <HexColourSwatches colours={info.row.original.colours?.value} />,
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const currentRowNames = row.original.colours?.value.map((option) => option.name)
          const cellDataAsString = currentRowNames?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.mount) {
    const name = HEADER_NAMES.MOUNT
    columnsToRender.push(
      columnHelper.accessor((row) => row.mount?.value.join(', '), {
        enableColumnFilter: columnData.mount?.filterType !== 'none',
        enableSorting: columnData.mount?.enableSorting ?? false,
        id: name,
        header: columnData.mount?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.mount?.filterType ?? 'none',
          options: columnData.mount?.options
        },
        cell: (info) => (
          <>{(info.row.original.mount?.value ?? []).length > 0 ? info.row.original.mount?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.mount?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.wrap) {
    const name = HEADER_NAMES.WRAP
    columnsToRender.push(
      columnHelper.accessor((row) => row.wrap?.value.join(', '), {
        enableColumnFilter: columnData.wrap?.filterType !== 'none',
        enableSorting: columnData.wrap?.enableSorting ?? false,
        id: name,
        header: columnData.wrap?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.wrap?.filterType ?? 'none',
          options: columnData.wrap?.options
        },
        cell: (info) => (
          <>{(info.row.original.wrap?.value ?? []).length > 0 ? info.row.original.wrap?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.wrap?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.frame) {
    const name = HEADER_NAMES.FRAME
    columnsToRender.push(
      columnHelper.accessor((row) => row.frame?.value.join(', '), {
        enableColumnFilter: columnData.frame?.filterType !== 'none',
        enableSorting: columnData.frame?.enableSorting ?? false,
        id: name,
        header: columnData.frame?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.frame?.filterType ?? 'none',
          options: columnData.frame?.options
        },
        cell: (info) => (
          <>{(info.row.original.frame?.value ?? []).length > 0 ? info.row.original.frame?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.frame?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.brand) {
    const name = HEADER_NAMES.BRAND
    columnsToRender.push(
      columnHelper.accessor((row) => row.brand?.value.join(', '), {
        enableColumnFilter: columnData.brand?.filterType !== 'none',
        enableSorting: columnData.brand?.enableSorting ?? false,
        id: name,
        header: columnData.brand?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.brand?.filterType ?? 'none',
          options: columnData.brand?.options
        },
        cell: (info) => (
          <>{(info.row.original.brand?.value ?? []).length > 0 ? info.row.original.brand?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.brand?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.edge) {
    const name = HEADER_NAMES.EDGE
    columnsToRender.push(
      columnHelper.accessor((row) => row.edge?.value.join(', '), {
        enableColumnFilter: columnData.edge?.filterType !== 'none',
        enableSorting: columnData.edge?.enableSorting ?? false,
        id: name,
        header: columnData.edge?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.edge?.filterType ?? 'none',
          options: columnData.edge?.options
        },
        cell: (info) => (
          <>{(info.row.original.edge?.value ?? []).length > 0 ? info.row.original.edge?.value.join(', ') : '-'}</>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.edge?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.paperType) {
    const name = HEADER_NAMES.PAPER
    columnsToRender.push(
      columnHelper.accessor((row) => row.paperType?.value.join(', '), {
        enableColumnFilter: columnData.paperType?.filterType !== 'none',
        enableSorting: columnData.paperType?.enableSorting ?? false,
        id: name,
        header: columnData.paperType?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.paperType?.filterType ?? 'none',
          options: columnData.paperType?.options
        },
        cell: (info) => (
          <>
            {(info.row.original.paperType?.value ?? []).length > 0
              ? info.row.original.paperType?.value.join(', ')
              : '-'}
          </>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.paperType?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.fulfilledFrom) {
    const name = HEADER_NAMES.FULFILLED_FROM
    columnsToRender.push(
      columnHelper.accessor((row) => row.fulfilledFrom?.value.join(', '), {
        enableColumnFilter: columnData.fulfilledFrom?.filterType !== 'none',
        enableSorting: columnData.fulfilledFrom?.enableSorting ?? false,
        id: name,
        header: columnData.fulfilledFrom?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.fulfilledFrom?.filterType ?? 'none',
          options: mapCountryOptions(columnData.fulfilledFrom?.options)
        },
        cell: (info) => <Flags countryCodes={info.row.original.fulfilledFrom?.value} />,
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.fulfilledFrom?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  if (columnData.sla) {
    const name = HEADER_NAMES.SLA
    columnsToRender.push(
      columnHelper.accessor((row) => row.sla?.value.join(', '), {
        enableColumnFilter: columnData.sla?.filterType !== 'none',
        enableSorting: columnData.sla?.enableSorting ?? false,
        id: name,
        header: columnData.sla?.filterType !== 'none' ? undefined : name,
        meta: {
          filterType: columnData.sla?.filterType ?? 'none',
          options: columnData.sla?.options.sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
          )
        },
        sortingFn: 'alphanumeric',
        cell: (info) => (
          <>
            {(info.row.original.sla?.value ?? []).length > 0
              ? info.row.original.sla?.value
                  .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
                  .join(', ')
              : '-'}
          </>
        ),
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.sla?.value?.join(' ').toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  columnsToRender.push(
    columnHelper.accessor((row) => row.sku, {
      enableColumnFilter: false,
      enableSorting: false,
      id: HEADER_NAMES.SKU,
      header: 'SKU'
    })
  )

  if (columnData.price) {
    columnsToRender.push(
      columnHelper.accessor((row) => row.price, {
        enableColumnFilter: columnData.price?.filterType !== 'none',
        enableSorting: columnData.price?.enableSorting ?? false,
        id: HEADER_NAMES.PRICE,
        header: columnData.price?.filterType !== 'none' ? undefined : 'From',
        meta: {
          filterType: columnData.price?.filterType ?? 'none',
          options: columnData.price?.options
        },
        filterFn: (row, _columnId, filterValue) => {
          if (typeof filterValue !== 'string') {
            return false
          }
          const splitFilterValue = filterValue.toLowerCase().split(VARIANTS_FILTER_VALUE_SEPARATOR).filter(Boolean)

          if (splitFilterValue.length === 0) {
            return true
          }

          const cellDataAsString = row.original.price?.toLowerCase()
          return splitFilterValue.some((filterValue) => cellDataAsString?.includes(filterValue))
        }
      })
    )
  }

  columnsToRender.push(
    columnHelper.display({
      id: HEADER_NAMES.INFO,
      cell: (info) => <VariantsInfoAction info={info} />
    })
  )

  columnsToRender.push(
    columnHelper.display({
      id: HEADER_NAMES.ORDER,
      cell: (info) => <VariantsOrderAction info={info} productName={productName} />
    })
  )

  return columnsToRender
}

function FrameColourSwatches({ frames = [] }: { frames?: { name: string; url: string }[] }) {
  if (frames.length === 0) {
    return <span>-</span>
  }

  return (
    <div className="flex gap-1">
      {frames.map((frame) => (
        <div className="flex items-center gap-2" key={frame.name}>
          <div className="items-center">
            <div
              className="h-7 w-7 border border-gray-200 bg-center"
              style={{ backgroundImage: `url(${frame.url})` }}
              title={frame.name}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

function HexColourSwatches({ colours }: { colours: Array<{ name: string; hex: string }> | undefined }) {
  if (!colours || colours.length === 0) {
    return <span>-</span>
  }

  return (
    <div className="flex gap-1">
      {colours.map((colour) => (
        <div className="flex items-center gap-2" key={colour.name}>
          <div className="items-center">
            <div
              className="h-7 w-7 border border-gray-200 bg-center"
              style={{ backgroundColor: colour.hex }}
              title={colour.name}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

function mapCountryOptions(countryCodes: Array<string>) {
  const mappedCountries = countryCodes.reduce((countriesAcc: InlineMenuOptionInterface[], countryCode: string) => {
    const name = Countries().getName(countryCode) ?? countryCode

    countriesAcc.push({
      content: (
        <div className="flex pr-2">
          <div className="mr-2" key={countryCode}>
            <Flag countryCode={countryCode} />
          </div>
          {name}
        </div>
      ),
      searchLabel: name,
      value: countryCode,
      disabled: false
    })

    return countriesAcc
  }, [])

  mappedCountries.sort((a, b) => a.searchLabel.localeCompare(b.searchLabel))

  return mappedCountries
}

function Flags({ countryCodes }: { countryCodes: Array<string> | undefined }) {
  if (!countryCodes || countryCodes.length === 0) {
    return <span>-</span>
  }

  return (
    <div className="flex items-center gap-1">
      {countryCodes.map((code) => (
        <div
          key={code}
          className="h-8 w-8 rounded-full bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url("/img/flags-100/${code.toLowerCase()}.png")` }}
          title={code}
        ></div>
      ))}
    </div>
  )
}

function Flag({ countryCode }: { countryCode: string }) {
  return (
    <div className="items-center">
      <img
        alt={countryCode}
        className="h-8 w-8 rounded-full object-cover object-center"
        key={countryCode}
        src={`/img/flags-100/${countryCode.toLowerCase()}.png`}
        title={countryCode}
      />
    </div>
  )
}

function mapUrlColourOptions(urlColourOptions: Array<{ name: string; url: string }>): InlineMenuOptionInterface[] {
  const mappedUrlColourOptions: InlineMenuOptionInterface[] = []

  urlColourOptions.forEach((urlColourOption) => {
    mappedUrlColourOptions.push({
      content: (
        <div className="flex items-center gap-3">
          <div
            className="h-7 w-7 border border-gray-200 bg-center"
            style={{ backgroundImage: `url(${urlColourOption.url})` }}
            title={urlColourOption.name}
          ></div>
          <div>{urlColourOption.name}</div>
        </div>
      ),
      searchLabel: urlColourOption.name,
      value: urlColourOption.name
    })
  })

  return mappedUrlColourOptions
}

function mapHexColourOptions(hexColourOptions: Array<{ name: string; hex: string }>): InlineMenuOptionInterface[] {
  const mappedHexColourOptions: InlineMenuOptionInterface[] = []

  hexColourOptions.forEach((hexColourOption) => {
    mappedHexColourOptions.push({
      content: (
        <div className="flex items-center gap-3">
          <div
            className="h-7 w-7 border border-gray-200 bg-center"
            style={{ backgroundColor: hexColourOption.hex }}
            title={hexColourOption.name}
          ></div>
          <div>{hexColourOption.name}</div>
        </div>
      ),
      searchLabel: hexColourOption.name,
      value: hexColourOption.name
    })
  })

  return mappedHexColourOptions
}
