import Skeleton from '../../Skeleton'

export function SkeletonSearchItem() {
  return (
    <li className="flex flex-col gap-4 border-b border-gray-200 bg-white p-8 py-10 last:border-b-0 sm:flex-row sm:gap-6">
      <div className="flex flex-col gap-4">
        <Skeleton className="w-80 sm:w-96 md:w-[350px]" />
        <Skeleton className="w-64" />
      </div>

      <div className="flex flex-shrink-0 flex-col gap-4 sm:ml-auto sm:flex-row">
        <div className="flex flex-col gap-4">
          <div className="flex h-[42px] items-center">
            <Skeleton className="w-[92px]" />
          </div>
          <div className="h-[42px]"></div>
        </div>

        <div className="flex flex-col gap-4">
          <Skeleton className="h-[42px] w-full sm:w-[125px]" />
          <Skeleton className="h-[42px] w-full sm:w-[125px]" />
        </div>
      </div>
    </li>
  )
}
