// @flow
import React, { Component } from 'react'
import type { ComponentType } from 'react'

type Props = {|
  loading: boolean,
  error: boolean,
  success: boolean,
  statusMessage: string
|}

export default function withRsaaNamePrefix (propNamePrefix: string): ((WrappedRsaaHoc: ComponentType<any>) => ComponentType<any>) {
  return function (WrappedRsaaHoc: ComponentType<*>): ComponentType<*> {
    return class WithRsaaNamePrefix extends Component<Props> {
      render (): React$Node {
        const { loading, error, success, statusMessage, ...restProps } = this.props
        const newRsaaPropsNames = {
          [propNamePrefix + 'Loading']: loading,
          [propNamePrefix + 'Error']: error,
          [propNamePrefix + 'Success']: success,
          [propNamePrefix + 'Msg']: statusMessage
        }

        return <WrappedRsaaHoc {...restProps} {...newRsaaPropsNames} />
      }
    }
  }
}
