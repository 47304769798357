import { CatalogueProductInterface } from '../../../hooks'

export function ColourSwatch({ details }: { details: CatalogueProductInterface }) {
  if (details.colours.length > 0) {
    return (
      <div className="flex flex-wrap gap-1">
        {details.colours.map((colour, index) => {
          return <HexColourSwatch hex={colour.hex} name={colour.name} key={index} />
        })}
      </div>
    )
  }

  if (details.frames.length > 0) {
    return (
      <div className="flex flex-wrap gap-1">
        {details.frames.map((frame, index) => {
          return <FrameColourSwatch url={frame.swatchImage} name={frame.name} key={index} />
        })}
      </div>
    )
  }

  return null
}

function HexColourSwatch({ hex, name }: { hex: string; name: string }) {
  return (
    <div className="flex items-center gap-2">
      <div className="items-center">
        <div className="h-7 w-7 border border-gray-200 bg-center" style={{ backgroundColor: hex }} title={name} />
      </div>
    </div>
  )
}

function FrameColourSwatch({ name, url }: { name: string; url: string }) {
  return (
    <div className="flex items-center gap-2">
      <div className="items-center">
        <div
          className="h-7 w-7 border border-gray-200 bg-center"
          style={{ backgroundImage: `url(${url})` }}
          title={name}
        />
      </div>
    </div>
  )
}
