// @flow
import type { MultiAssetBasketItem, DispatchFunc, GetStateFunc, Thunk } from '../../types'
import type { SaveRecentItems } from '../types'
import { selectRecentItems } from '../../selectors/recentItems'
import { MAX_RECENT_ITEMS } from '../../data/constants'
import { hasSameNameAndImageUrl } from './recentItems'
import { mapToEnsureBackwardCompatibility } from './backwardCompatibility'
import { values } from '../../helpers/dictionary'
import type { PrintAreaArtwork } from '../../types/basketItem'

export const SAVE_RECENT_ITEMS = 'SAVE_RECENT_ITEMS'

export function saveRecentItems(itemsToSave: MultiAssetBasketItem[]): Thunk<SaveRecentItems> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const recentItems = selectRecentItems(getState())
    const backwardCompatibleRecentItems = mapToEnsureBackwardCompatibility(recentItems)
    const filteredItemsToSave = filterItemsToSave(itemsToSave, backwardCompatibleRecentItems)

    return dispatch({ type: SAVE_RECENT_ITEMS, items: filteredItemsToSave })
  }
}

function filterItemsToSave(
  itemsToSave: MultiAssetBasketItem[],
  prevSavedItems: MultiAssetBasketItem[]
): MultiAssetBasketItem[] {
  const allItemsInReverseOrder = itemsToSave.reverse().concat(prevSavedItems)
  const itemsWithoutDuplicates = removeDuplicates(allItemsInReverseOrder)

  return takeMax(itemsWithoutDuplicates).filter((recentItem, index) => {
    const hasImageLibraryIdForAllArtworks = values<PrintAreaArtwork>(recentItem.printAreas ?? {}).every(
      (printAreaData) => {
        if (printAreaData.artwork) {
          return Boolean(printAreaData.artwork?.imageLibraryId)
        }

        return true
      }
    )
    return hasImageLibraryIdForAllArtworks
  })
}

function removeDuplicates(items: MultiAssetBasketItem[]): MultiAssetBasketItem[] {
  return items.reduce((checkedItems: MultiAssetBasketItem[], currItem: MultiAssetBasketItem) => {
    const isFirstOccurrence = !checkedItems.find(hasSameNameAndImageUrl(currItem))

    if (isFirstOccurrence) {
      checkedItems.push(currItem)
    }

    return checkedItems
  }, [])
}

function takeMax(items: MultiAssetBasketItem[]): MultiAssetBasketItem[] {
  return items.slice(0, MAX_RECENT_ITEMS)
}
