import { selectAzureSearchSettings } from '../selectors/appSettings'
import { fetcher } from './fetcher'

export function azureSearchFetcher<T>(url: string) {
  const azureSearchSettings = selectAzureSearchSettings()
  return fetcher<T>(url, {
    headers: {
      'Content-Type': 'application/json',
      'api-key': azureSearchSettings.key
    }
  })
}
