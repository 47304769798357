import { isNoImageProduct } from '../../../helpers'
import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { VariantDataInterface, VariantsSkuDataType } from '../SalesChannelProduct.component'
import { getProductFileCompatibilityData } from '../../../helpers/getProductFileCompatibilityData.helper'

export function copyVariantProductDetails({
  allVariantsData,
  variantDataToCopy,
  variantsSkuData
}: {
  allVariantsData: Record<string, VariantDataInterface>
  variantDataToCopy: VariantDataInterface
  variantsSkuData: VariantsSkuDataType
}) {
  return Object.keys(allVariantsData).reduce((variantsAcc: Record<string, VariantDataInterface>, variantKey) => {
    const currentVariantDetails: VariantDataInterface = allVariantsData[variantKey]
    const variantToCopySkuData = variantsSkuData[variantDataToCopy.id]

    if (variantKey === variantDataToCopy.id || !currentVariantDetails.isFulfilled) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    const isSkuDifferent =
      variantDataToCopy?.selectedProductData?.sku !== currentVariantDetails.selectedProductData?.sku
    const areAttributesDifferent = Object.entries(currentVariantDetails.selectedProductData?.attributes ?? {}).some(
      ([attributeName, attributeValue]) =>
        variantDataToCopy?.selectedProductData?.attributes?.[attributeName] !== attributeValue
    )

    if (!isSkuDifferent && !areAttributesDifferent) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    const printAreasToCopy = variantDataToCopy.assetRequirementData
      ? Object.keys(variantDataToCopy.assetRequirementData)
      : []
    const firstAvailablePrintArea = printAreasToCopy[0]

    if (!firstAvailablePrintArea || !variantDataToCopy.selectedProductData || !variantToCopySkuData) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    if (isNoImageProduct(variantToCopySkuData.productType)) {
      return {
        ...variantsAcc,
        [variantKey]: {
          ...currentVariantDetails,
          artworkData: variantDataToCopy.artworkData,
          assetRequirementData: variantDataToCopy.assetRequirementData,
          selectedPrintArea: firstAvailablePrintArea,
          selectedProductData: variantDataToCopy.selectedProductData
        }
      }
    }

    const productFileCompatibilityData = getProductFileCompatibilityData({
      productType: variantToCopySkuData.productType
    })

    const newArtworkData = printAreasToCopy.reduce(
      (updatedArtworkDataAcc: PrintAreasArtworkDataType, printAreaName) => {
        const currentVariantArtwork = currentVariantDetails?.artworkData?.[printAreaName]?.artwork
        if (!currentVariantArtwork) {
          return {
            ...updatedArtworkDataAcc,
            [printAreaName]: null
          }
        }

        const isImageFileType = currentVariantArtwork.fileType
          ? currentVariantArtwork.fileType === 'png' || currentVariantArtwork.fileType === 'jpg'
          : true
        const isCurrentArtworkFileTypeSupportedOnNewProduct = productFileCompatibilityData
          ? productFileCompatibilityData.fileTypesAllowed.includes(currentVariantArtwork.fileType)
          : isImageFileType

        if (!isCurrentArtworkFileTypeSupportedOnNewProduct) {
          return {
            ...updatedArtworkDataAcc,
            [printAreaName]: null
          }
        }

        return {
          ...updatedArtworkDataAcc,
          [printAreaName]: {
            artwork: currentVariantArtwork,
            artworkTransformations: undefined,
            previewImageUrl: currentVariantArtwork.mediumImageUrl,
            printImageUrl: currentVariantArtwork.originalImageUrl,
            transformImageUrl: currentVariantArtwork.originalImageUrl
          }
        }
      },
      {}
    )

    return {
      ...variantsAcc,
      [variantKey]: {
        ...currentVariantDetails,
        artworkData: newArtworkData,
        assetRequirementData: variantDataToCopy.assetRequirementData,
        selectedPrintArea: firstAvailablePrintArea,
        selectedProductData: variantDataToCopy.selectedProductData
      }
    }
  }, {})
}
