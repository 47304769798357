// @flow

import { nanoid } from 'nanoid'
import { ANONYMOUS_ID_LOCAL_STORAGE_KEY, ANONYMOUS_ID_SEGMENT_LOCAL_STORAGE_KEY } from '../data/constants'

export function getAnonymousId(): string {
  return (
    getExistingSegmentAnonymousId() || localStorage.getItem(ANONYMOUS_ID_LOCAL_STORAGE_KEY) || generateAnonymousId()
  )
}

function getExistingSegmentAnonymousId(): ?string {
  const segmentAnonymousId = localStorage.getItem(ANONYMOUS_ID_SEGMENT_LOCAL_STORAGE_KEY)
  let parsedSegmentAnonymousId = null

  try {
    parsedSegmentAnonymousId = segmentAnonymousId && JSON.parse(segmentAnonymousId)
  } catch (error) {
    parsedSegmentAnonymousId = null
  }
  return parsedSegmentAnonymousId
}

function generateAnonymousId(): string {
  const anonymousUserId = nanoid()
  localStorage.setItem(ANONYMOUS_ID_LOCAL_STORAGE_KEY, anonymousUserId)
  return anonymousUserId
}
