// @flow

import { entries } from './dictionary'
import { buildImageId } from '../actions/images'
import { LOADING } from '../data/rsaa'
import type { ArtworkProgress, Dictionary, MultiAssetBasketItem, PrintAreaArtwork, V4ProductDetails } from '../types'

export function selectAllRequiredItemPrintAreasAreValid (
  v4ProductDetails: V4ProductDetails,
  item: MultiAssetBasketItem,
  allArtworkProgress: Dictionary<ArtworkProgress>
): boolean {
  const requiredPrintAreas = entries(v4ProductDetails.printAreas).reduce((printAreasAcc, [printArea, printAreaData]) => {
    if (printAreaData.required) {
      printAreasAcc.push(printArea)
    }
    return printAreasAcc
  }, [])

  if (requiredPrintAreas.length === 0) {
    return false
  }

  return requiredPrintAreas.every(printArea => selectIsArtworkValid(item.id.toString(), printArea, item.printAreas, allArtworkProgress))
}

function selectIsArtworkValid (
  itemId: string,
  printAreaName: string,
  printAreas: ?Dictionary<PrintAreaArtwork>,
  allArtworkProgress: Dictionary<ArtworkProgress>
): boolean {
  const imageId = buildImageId(itemId, printAreaName)
  const imageStatus = allArtworkProgress[imageId]
  const isLoadingImage = imageStatus
    ? imageStatus.status === LOADING
    : false

  return Boolean(
    printAreas &&
    printAreas[printAreaName] &&
    printAreas[printAreaName].artwork &&
    printAreas[printAreaName].artwork.croppedImageUrl &&
    !isLoadingImage
  )
}
