import { twMerge } from 'tailwind-merge'

import Modal, { ModalPropsInterface } from '../Modal'
import Button, { ButtonVariantType } from '../Button'
import { ButtonThemeType } from '../Button/Button.component'

interface ConfirmationButtonInterface {
  text?: string
  variant?: ButtonVariantType
  theme?: ButtonThemeType
  disabled?: boolean
}

interface ConfirmationModalPropsInterface extends ModalPropsInterface {
  contentClassName?: string
  closeButton?: ConfirmationButtonInterface
  continueButton?: ConfirmationButtonInterface
  isLoading?: boolean
  onCancel: () => void
  onContinue: () => void
}

export function ConfirmationModal({
  contentClassName = '',
  className = '',
  closeButton = {},
  closeOnEscape,
  closeOnInteractionOutside,
  children,
  continueButton = {},
  isLoading = false,
  onCancel,
  onContinue,
  ...props
}: ConfirmationModalPropsInterface) {
  return (
    <Modal
      {...props}
      className={className}
      closeOnInteractionOutside={isLoading ? false : closeOnInteractionOutside}
      closeOnEscape={isLoading ? false : closeOnEscape}
    >
      <div className={twMerge('flex h-full max-w-3xl flex-col overflow-y-auto', contentClassName)}>
        {children}

        <div className="mt-12 flex max-w-full flex-col justify-start gap-4 whitespace-nowrap sm:flex-row">
          <Button
            dataTest="confirmation-modal-yes-button"
            disabled={continueButton?.disabled ?? false}
            isLoading={isLoading}
            variant={continueButton?.variant ?? 'primary'}
            theme={continueButton.theme ?? 'brand'}
            onClick={onContinue}
          >
            {continueButton?.text ?? 'Yes'}
          </Button>

          <Button variant={closeButton?.variant ?? 'secondary'} theme={closeButton.theme ?? 'brand'} onClick={onCancel}>
            {closeButton?.text ?? 'No'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
