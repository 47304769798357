import { compareAsc, intervalToDuration } from 'date-fns'

interface TimeRemainingInterface {
  hasIntervalPassed: boolean
  text: string | null
}

export function calculateTimeRemaining(submission: string | null): TimeRemainingInterface | null {
  if (!submission) {
    return null
  }

  const currentDate = new Date()
  const submissionDate = new Date(submission)

  const hasIntervalPassed = compareAsc(currentDate, submissionDate) > 0
  const duration = intervalToDuration({ start: currentDate, end: submissionDate })

  const textToDisplay = []

  let hours = 0

  if (duration.days && duration.days > 0) {
    hours += duration.days * 24
  }

  if (duration.hours && duration.hours > 0) {
    hours += duration.hours
  }

  if (hours > 0) {
    const hoursText = hours + (hours > 1 ? 'hrs' : 'hr')
    textToDisplay.push(hoursText)
  }

  if (duration.minutes) {
    const minutesText = duration.minutes + (duration.minutes > 1 ? 'mins' : 'min')
    textToDisplay.push(minutesText)
  }

  return {
    hasIntervalPassed,
    text: textToDisplay.join(' ')
  }
}
