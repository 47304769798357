// @flow
import Timer from './Timer'

export const MOF = 'MOF'

class Instruments {
  _timers: {
    [string]: Timer
  }

  constructor () {
    this._timers = {}
  }

  timer (name: string): Timer {
    if (!this._timers[name]) {
      this._timers[name] = new Timer(name)
    }
    return this._timers[name]
  }
}

export default (new Instruments(): Instruments)
