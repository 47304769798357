// @flow
import * as Portal from '@radix-ui/react-portal'
import React, { PureComponent } from 'react'
import { Redirect } from 'react-router'
import type {
  ArtworkProgress,
  CatalogueItem,
  Dictionary,
  ArtworkTransformations,
  MultiAssetBasketItem,
  MultiAssetTemplates,
  Artwork
} from '../../../types'
import { displayBorder, isProductMounted } from '../../../helpers/imageEditor'
import { buildImageId } from '../../../actions/images'
import { ERROR } from '../../../data/rsaa'
import { canProductBeTiled } from '../../../helpers/products'

// $FlowFixMe: TypeScript file
import { PORTAL_ID } from '../../../v2/constants/portalId.const'
// $FlowFixMe: TypeScript file
import MultiAssetImageEditor from '../../../v2/components/ImageEditor/components/MultiAssetImageEditor.component'
import type { ImageEditorSaveDataType } from '../../../types/images'

export type MofImageEditorProps = {|
  catalogueItem: ?CatalogueItem,
  basketItem: ?MultiAssetBasketItem,
  templates: ?MultiAssetTemplates,
  artworkUploadProgress: Dictionary<ArtworkProgress>,
  redirect: (address: string) => void,
  saveTransformations: (
    basketItemId: number,
    transformations: Dictionary<?ArtworkTransformations>,
    additionalData: ImageEditorSaveDataType
  ) => void,
  uploadArtworkFromFile?: (basketItemId: number, file: File, printAreaName: string) => void,
  removeBasketItemArtwork: (basketItemId: number, printAreaName: string) => void,
  changeSelectedPrintAreaForBasketItem: (basketItemId: number, printAreaName: string) => void,
  onSelectImageLibraryImage: ({|
    artwork: Artwork,
    basketItemId: number,
    printAreaName: string,
    isTilingEnabled: boolean
  |}) => void
|}

export default class MofImageEditor extends PureComponent<MofImageEditorProps> {
  componentDidMount() {
    window.analytics.page('MOF - Cropper')
  }

  componentWillUnmount() {
    this.resetImagesWithErrors()
  }

  resetImagesWithErrors: () => void = () => {
    Object.keys(this.props.basketItem?.printAreas ?? {})
      .filter(this.hasImageError)
      .forEach(this.removeArtwork)
  }

  hasImageError: (printAreaName: string) => boolean = (printAreaName: string): boolean => {
    const imageId = this.buildImageId(printAreaName)

    return this.props.artworkUploadProgress[imageId]?.status === ERROR
  }

  onSaveMultiAsset: (
    artworkTransformations: Dictionary<?ArtworkTransformations>,
    selectedPrintArea: string,
    additionalData: ImageEditorSaveDataType
  ) => void = (
    artworkTransformations: Dictionary<?ArtworkTransformations>,
    selectedPrintArea: string,
    additionalData: ImageEditorSaveDataType
  ) => {
    const { basketItem, redirect, saveTransformations, changeSelectedPrintAreaForBasketItem } = this.props

    if (!basketItem) {
      return
    }

    saveTransformations(basketItem.id, artworkTransformations, additionalData)
    changeSelectedPrintAreaForBasketItem(basketItem.id, selectedPrintArea)
    redirect('/orders/create/add-image')
  }

  buildImageId: (selectedPrintArea: string) => string = (selectedPrintArea: string): string => {
    if (!this.props.basketItem) {
      throw Error('basketItem cannot be null')
    }
    return buildImageId(this.props.basketItem.id.toString(), selectedPrintArea)
  }

  onSelectImageLibraryImage: (selectionData: {|
    artwork: Artwork,
    artworkTransformations: ArtworkTransformations,
    selectedPrintArea: string
  |}) => void = (selectionData) => {
    if (!this.props.basketItem) {
      return
    }
    this.props.onSelectImageLibraryImage({
      artwork: selectionData.artwork,
      basketItemId: this.props.basketItem.id,
      printAreaName: selectionData.selectedPrintArea,
      isTilingEnabled: false
    })
  }

  removeArtwork: (printAreaName: string) => void = (printAreaName: string) => {
    if (!this.props.basketItem) {
      return
    }
    this.props.removeBasketItemArtwork(this.props.basketItem.id, printAreaName)
  }

  onCancel: () => void = () => this.props.redirect('/orders/create/add-image')

  renderMultiAssetImageEditor: () => React$Node = (): React$Node => {
    if (
      !this.props.basketItem ||
      !this.props.basketItem.printAreas ||
      !this.props.basketItem.selectedPrintArea ||
      !this.props.templates ||
      !this.props.catalogueItem
    ) {
      return <Redirect to="/orders/create/add-image" />
    }

    const nonNullTemplates = this.props.templates
    const nonNullPrintAreas = this.props.basketItem.printAreas
    const nonNullSelectedPrintArea = this.props.basketItem.selectedPrintArea
    const nonNullSelectedAttributes = this.props.basketItem.selectedAttributes
    const { description, category, productType, sku } = this.props.catalogueItem
    const supportsPrintBorders = displayBorder(
      this.props.catalogueItem.category,
      this.props.catalogueItem.productType,
      isProductMounted(nonNullSelectedAttributes)
    )

    const supportsTiling = this.props.catalogueItem ? canProductBeTiled(this.props.catalogueItem.productType) : false

    return (
      <Portal.Root asChild container={document.getElementById(PORTAL_ID)}>
        <div className="fixed inset-0 overflow-auto">
          <MultiAssetImageEditor
            printAreas={nonNullPrintAreas}
            description={description}
            itemCategory={category}
            productType={productType}
            productSku={sku}
            selectedAttributes={nonNullSelectedAttributes}
            supportsPrintBorders={supportsPrintBorders}
            supportsTiling={supportsTiling}
            selectedPrintArea={nonNullSelectedPrintArea}
            templates={nonNullTemplates}
            artworkUploadProgress={this.props.artworkUploadProgress}
            buildImageId={this.buildImageId}
            showRemoveArtworkButton={true}
            onSave={this.onSaveMultiAsset}
            onCancel={this.onCancel}
            onOpenImageLibrary={() => window.analytics.track('Image library opened: Order creation')}
            resetImage={this.removeArtwork}
            onRemoveArtwork={this.removeArtwork}
            onSelectImageLibraryImage={this.onSelectImageLibraryImage}
          />
        </div>
      </Portal.Root>
    )
  }

  render(): React$Element<*> | React$Node {
    return this.renderMultiAssetImageEditor()
  }
}
