// @flow
import { fetchUserDetails } from './fetchUserDetails'
import { RSAA } from 'redux-api-middleware'
import { selectUserSettings, selectCompany, selectUserId } from '../../selectors/user'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import type { ThunkAsync, DispatchFunc, GetStateFunc } from '../../types'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const UPDATE_EMAIL_NOTIFICATION_SETTINGS = 'UPDATE_EMAIL_NOTIFICATION_SETTINGS'
export const UPDATE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS'
export const UPDATE_EMAIL_NOTIFICATION_ERROR = 'UPDATE_EMAIL_NOTIFICATION_ERROR'
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME'
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS'
export const UPDATE_USER_NAME_ERROR = 'UPDATE_USER_NAME_ERROR'

export const ACTION_TYPES__UPDATE_USER = [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR]

export function updateUser(name: string, sendEmailNotifications: boolean): ThunkAsync<*> {
  return async (dispatch: DispatchFunc) => {
    dispatch(updateUserStart())

    const notificationResult = await dispatch(updateEmailNotificationSettings(sendEmailNotifications))

    if (notificationResult.type !== UPDATE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS) {
      return dispatch(updateUserError())
    }

    const userNameResult = await dispatch(updateUserName(name))

    if (userNameResult.type !== UPDATE_USER_NAME_SUCCESS) {
      return dispatch(updateUserError())
    } else {
      return dispatch(updateUserSuccess())
    }
  }
}

function updateEmailNotificationSettings(sendEmailNotifications: boolean) {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const baseEndpoint = selectPwintyApiSettings().endpoint
    const userSettings = selectUserSettings(getState())
    const company = selectCompany(getState())

    const body = {
      company: company,
      settings: {
        ...userSettings,
        sendEmailNotifications: sendEmailNotifications
      }
    }

    return dispatch({
      [RSAA]: {
        endpoint: baseEndpoint + '/dashboard/merchant/update',
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(body),
        types: [
          UPDATE_EMAIL_NOTIFICATION_SETTINGS,
          UPDATE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS,
          UPDATE_EMAIL_NOTIFICATION_ERROR
        ]
      }
    })
  }
}

function updateUserName(name: string) {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const baseEndpoint = selectPwintyApiSettings().endpoint
    const userId = selectUserId(getState())

    const body = {
      id: userId,
      name,
      permissions: []
    }

    return dispatch({
      [RSAA]: {
        endpoint: baseEndpoint + `/dashboard/user/${userId}/update`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        types: [UPDATE_USER_NAME, UPDATE_USER_NAME_SUCCESS, UPDATE_USER_NAME_ERROR]
      }
    })
  }
}

function updateUserStart() {
  return {
    type: UPDATE_USER
  }
}

export function updateUserSuccess(): (dispatch: DispatchFunc) => void {
  return (dispatch: DispatchFunc) => {
    dispatch(fetchUserDetails())
    dispatch({ type: UPDATE_USER_SUCCESS })
  }
}

function updateUserError() {
  return {
    type: UPDATE_USER_ERROR,
    message: DEFAULT_ERROR_MESSAGE
  }
}
