// @noflow
function currencyFormat(value, countryCode) {
  try {
    const price = (value / 100).toLocaleString('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      useGrouping: true,
      currency: countryCode
    })

    // fix for a bug in Edge - displaying AUD as 'A$' not '$'
    if (countryCode === 'AUD' && price[0] !== 'A') {
      return 'A' + price
    }

    return price
  } catch (error) {
    console.error(error)
    return ''
  }
}

export default currencyFormat
