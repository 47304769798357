import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { useUser } from './useUser.hook'
import { selectMerchantService } from '../selectors/appSettings'
import { FetchErrorInterface, MerchantDetailsInterface } from '../interfaces'

interface MerchantDetailsSuccessInterface {
  outcome: string
  data: MerchantDetailsInterface
}

interface MerchantDetailsErrorInterface {
  outcome?: string
  message?: string
}

export function useMerchantService({ config }: { config?: SWRConfiguration } = {}) {
  const { user } = useUser({ config })

  const {
    data: merchantDetails,
    error: merchantDetailsFetchError,
    mutate: mutateMerchantDetails
  } = useSWR<MerchantDetailsInterface, FetchErrorInterface<MerchantDetailsErrorInterface>>(
    user?.merchantUniqueId ? `${selectMerchantService()}/merchants/${user.merchantUniqueId}` : null,
    merchantServiceFetcher,
    config
  )

  return {
    isLoading: !merchantDetails && !merchantDetailsFetchError,
    merchantDetails,
    merchantDetailsFetchError,
    mutateMerchantDetails
  }
}

async function merchantServiceFetcher(url: string) {
  return fetcher<MerchantDetailsSuccessInterface>(url).then(
    (merchantDetailsSuccessResponse) => merchantDetailsSuccessResponse.data
  )
}
