import { useEffect, useState } from 'react'

import { OptionDropdown } from '.'
import SalesLink from '../../SalesLink'
import { useUserV1 } from '../../../hooks'
import GroupedDropdown from '../../GroupedDropdown'
import dateFormat from '../../../helpers/dateFormat'
import { V3CountryInterface } from '../../../interfaces'
import { createCountryDownloadOptions } from '../helpers'
import { PricingInformationInterface } from '../interfaces'
import { useLogToInsightOps } from '../../../hooks/useLogToInsightOps'
import { PRICING_INFO_STORAGE_BASE_URL, PRICING_INFO_STORAGE_DATE } from '../constants'

export function DownloadPricingDisplay({
  pricingInformation,
  countries
}: {
  pricingInformation: PricingInformationInterface
  countries: V3CountryInterface[]
}) {
  const user = useUserV1()
  const { logToInsightOps } = useLogToInsightOps()

  const categories = pricingInformation.payload
  const merchantCountryCode = user?.company.countryCode

  const [category, setCategory] = useState(Object.keys(categories)[0])
  const [subcategory, setSubcategory] = useState(Object.keys(categories[category].subCategories)[0])
  const [selectedShippingTo, setSelectedShippingTo] = useState(
    defaultSelectedCountry(merchantCountryCode, pricingInformation)
  )
  const [downloadUrl, setDownloadUrl] = useState(createDownloadUrl(selectedShippingTo, category, subcategory))

  useEffect(() => {
    const subCategoryIsAvailable = pricingInformation.payload[category].subCategories[subcategory]

    const selectedCountryCodeIsValid =
      subCategoryIsAvailable?.countries.includes(selectedShippingTo) ||
      subCategoryIsAvailable?.regions?.includes(selectedShippingTo)

    if (!subCategoryIsAvailable) {
      const newSubcategory = Object.keys(pricingInformation.payload[category].subCategories)[0]
      const newSubCategoryOptions = pricingInformation.payload[category].subCategories[newSubcategory]
      setSubcategory(newSubcategory)

      const selectedCountryCodeIsAvailable =
        newSubCategoryOptions.countries.indexOf(selectedShippingTo) !== -1 ||
        newSubCategoryOptions.regions?.indexOf(selectedShippingTo) !== -1

      if (!selectedCountryCodeIsAvailable) {
        setSelectedShippingTo(newSubCategoryOptions.countries[0])
      }
    } else if (!selectedCountryCodeIsValid) {
      const subCategoryOptions = pricingInformation.payload[category].subCategories[subcategory]
      setSelectedShippingTo(subCategoryOptions.countries[0])
    }
  }, [subcategory, category, selectedShippingTo, pricingInformation.payload])

  useEffect(() => {
    const url = createDownloadUrl(selectedShippingTo, category, subcategory)
    setDownloadUrl(url)
  }, [subcategory, category, selectedShippingTo])

  function logEvent() {
    logToInsightOps('Product pricing download', {
      props: { countryCode: selectedShippingTo, category, subcategory }
    })
    window.analytics.track('Product pricing download', { countryCode: selectedShippingTo, category, subcategory })
  }

  const groups = createCountryDownloadOptions({
    categories,
    category,
    countries,
    regionData: pricingInformation.regionData,
    subcategory
  })

  return (
    <>
      <div className="grid grid-cols-1 pt-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-4 md:gap-12 md:pt-12">
        <OptionDropdown
          label="Category"
          selected={category}
          options={Object.keys(categories).map(mapCategoryOption)}
          setSelected={setCategory}
        />
        <OptionDropdown
          label="Subcategory"
          options={Object.keys(categories[category].subCategories).map(mapCategoryOption)}
          selected={subcategory}
          setSelected={setSubcategory}
        />
        <div>
          <GroupedDropdown
            label="Shipping to"
            groups={groups}
            selected={selectedShippingTo}
            setSelected={setSelectedShippingTo}
          />
          <div className="pt-2 text-sm text-gray-600">
            We ship worldwide. If your country is not listed, please <SalesLink>contact us</SalesLink> for pricing
            information.
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        <a
          className="btn btn-primary md:m-win mt-8 w-fit cursor-pointer transition-opacity duration-100 sm:mt-0"
          href={downloadUrl}
          rel="noreferrer noopener"
          onClick={logEvent}
        >
          Download price sheet
        </a>
      </div>

      <p className="mt-4 text-sm text-gray-600 md:mt-6">Last updated {dateFormat(pricingInformation.lastUpdated)}</p>
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Helpers                                   */
/* -------------------------------------------------------------------------- */
function defaultSelectedCountry(
  merchantCountryCode: string | undefined,
  pricingInformation: PricingInformationInterface
) {
  const firstCategory = Object.values(pricingInformation.payload)[0]
  const countries = Object.values(firstCategory.subCategories)[0].countries

  const merchantCountry = countries.find((country) => country === merchantCountryCode)

  return merchantCountry || countries[0]
}

function mapCategoryOption(option: string) {
  return {
    value: option,
    name: option
  }
}

function createDownloadUrl(selectedShippingTo: string, category: string, subcategory: string) {
  return (
    PRICING_INFO_STORAGE_BASE_URL +
    '/' +
    PRICING_INFO_STORAGE_DATE +
    '/prodigi-' +
    encodeURIComponent(formatCategory(category)) +
    '-' +
    encodeURIComponent(formatCategory(subcategory)) +
    '-' +
    encodeURIComponent(selectedShippingTo.toLowerCase()) +
    '.csv'
  )
}

function formatCategory(value: string) {
  return value.replace(/ /g, '-').toLowerCase()
}
