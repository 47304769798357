import { DocumentTextIcon, TrashIcon } from '@heroicons/react/24/outline'
import Button from '../../Button'

export function PackingSlipDetails({
  isLoading,
  packingSlip,
  removePackingSlip
}: {
  isLoading: boolean
  packingSlip: string
  removePackingSlip: () => void
}) {
  return (
    <div className="my-6 flex gap-3">
      <a
        className="btn btn-sm btn-secondary"
        href={packingSlip}
        rel="noopener noreferrer"
        target="_blank"
        title="View packing slip"
        data-test="packing-slip__url"
      >
        <span>
          <DocumentTextIcon className="h-6 w-6" aria-hidden="true" />
          <span className="ml-2">View packing slip</span>
        </span>
      </a>
      <Button
        className="mt-0 w-fit"
        data-test="packing-slip__remove"
        isLoading={isLoading}
        size="sm"
        theme="danger"
        variant="tertiary"
        onClick={removePackingSlip}
      >
        <TrashIcon className="h-6 w-6" />
      </Button>
    </div>
  )
}
