import {
  ImageLibraryImageInterface,
  useImageLibraryImage
} from '../../hooks'
import { PackingSlipDetails, PackingSlipError, PackingSlipLoading, UploadPackingSlip } from './components'

interface PackingSlipInterface {
  isImageLibraryModalOpen: boolean
  isLoading: boolean
  packingSlipImageId: string | null
  handleUploadPackingUpdate: (imageLibraryImage: ImageLibraryImageInterface) => Promise<void>
  onOpenImageLibrary?: () => void
  removePackingSlip: () => void
  setIsImageLibraryModalOpen: (status: boolean) => void
}

export default function PackingSlip({
  isImageLibraryModalOpen,
  isLoading,
  packingSlipImageId,
  handleUploadPackingUpdate,
  onOpenImageLibrary,
  removePackingSlip,
  setIsImageLibraryModalOpen
}: PackingSlipInterface) {
  const { image, isLoading: isLoadingImage, error } = useImageLibraryImage({ imageId: packingSlipImageId })

  if (!packingSlipImageId) {
    return (
      <UploadPackingSlip
        isImageLibraryModalOpen={isImageLibraryModalOpen}
        isLoading={isLoading}
        onOpenImageLibrary={onOpenImageLibrary}
        setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
        handleUploadPackingUpdate={handleUploadPackingUpdate}
      />
    )
  }

  if (error) {
    return (
      <PackingSlipError
        errorStatus={error.status}
        onOpenImageLibrary={onOpenImageLibrary}
        isImageLibraryModalOpen={isImageLibraryModalOpen}
        isLoading={isLoading}
        setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
        handleUploadPackingUpdate={handleUploadPackingUpdate}
      />
    )
  }

  if (isLoadingImage || !image) {
    return <PackingSlipLoading />
  }

  return <PackingSlipDetails packingSlip={image.url} isLoading={isLoading} removePackingSlip={removePackingSlip} />
}
