import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import WhitePanel from '../../WhitePanel'

export function PaymentSuccess({ orderDetailLink }: { orderDetailLink: string }) {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="flex h-full w-full flex-col items-center justify-center"
      initial={{ opacity: 0 }}
    >
      <WhitePanel className="max-w-5xl" heading="Payment successful">
        <div className="flex flex-col">
          <p className="m-0">Your payment has been accepted and your order will now be processed.</p>
          <Link className="btn btn-primary mt-12 w-fit" to={orderDetailLink}>
            View order
          </Link>
        </div>
      </WhitePanel>
    </motion.div>
  )
}
