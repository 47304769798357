// @flow
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import Radium from 'radium'
import mediaQueries from '../../../../components/styles/mediaQueries'
import countryFormat from '../../../../helpers/countryFormat'
import { connect } from 'react-redux'
import type { DispatchFunc } from '../../../../types'
import { pushRedirect } from '../../../../actions/router'
import type { ComponentType } from 'react'

type Country = {|
  name: string,
  isoCode: string
|}

type OwnProps = {|
  pathname: string,
  countries: Array<Country>,
  addressIsValid: boolean,
  allItemsValid: boolean,
  postcodeIsValid: boolean,
  emailIsValid: boolean,
  deliveryCountry: string,
  items: number,
  customer: string
|}

type Props = {|
  ...OwnProps,
  redirectAction: (address: string) => void
|}

class Breadcrumbs extends Component<Props> {
  truncateLongNames(name: string) {
    if (name.length > 20) {
      return name.slice(0, 17) + '...'
    }
    return name
  }

  render(): React$Node {
    const {
      postcodeIsValid,
      emailIsValid,
      redirectAction,
      countries,
      addressIsValid,
      allItemsValid,
      deliveryCountry,
      items,
      customer,
      pathname
    } = this.props
    const country = countries.find((c) => c.isoCode === deliveryCountry)
    const deliveryCountryName = country ? country.name : ''
    const RadiumBreadcrumb = Radium(Breadcrumb)

    return (
      <div data-test="breadcrumbs">
        <RadiumBreadcrumb
          redirectAction={redirectAction}
          txt={'Country'}
          bracketTxtCondition={Boolean(deliveryCountry)}
          bracketTxt={this.truncateLongNames(countryFormat(deliveryCountryName))}
          isEnabled={true}
          isCurrentLocation={pathname === '/orders/create/select-country'}
          pathTo={'/orders/create/select-country'}
        />

        <RadiumBreadcrumb
          redirectAction={redirectAction}
          txt={'Products'}
          isEnabled={Boolean(deliveryCountry) && countries.length > 0}
          isCurrentLocation={pathname === '/orders/create/select-item'}
          pathTo={'/orders/create/select-item'}
        />

        <RadiumBreadcrumb
          redirectAction={redirectAction}
          txt={'Basket'}
          bracketTxtCondition={items > 0}
          bracketTxt={items.toString()}
          isEnabled={Boolean(deliveryCountry) && countries.length > 0}
          isCurrentLocation={pathname === '/orders/create/add-image'}
          pathTo={'/orders/create/add-image'}
        />

        <RadiumBreadcrumb
          redirectAction={redirectAction}
          txt={'Delivery'}
          bracketTxtCondition={addressIsValid && customer}
          bracketTxt={this.truncateLongNames(customer)}
          isEnabled={allItemsValid}
          isCurrentLocation={pathname === '/orders/create/address'}
          pathTo={'/orders/create/address'}
        />

        <RadiumBreadcrumb
          redirectAction={redirectAction}
          txt={'Summary'}
          isEnabled={postcodeIsValid && emailIsValid && addressIsValid && allItemsValid}
          isCurrentLocation={pathname === '/orders/create/summary'}
          pathTo={'/orders/create/summary'}
        />

        <RadiumBreadcrumb
          redirectAction={redirectAction}
          isCurrentLocation={false}
          pathTo={'/orders/create/summary'}
          txt={'Complete'}
          isEnabled={false}
        />
      </div>
    )
  }
}

type BreadcrumbProps = {|
  isCurrentLocation: boolean,
  isEnabled: boolean,
  pathTo: string,
  txt: string,
  bracketTxtCondition?: boolean,
  bracketTxt?: string,
  redirectAction: (address: string) => void
|}

type State = {|
  hover: boolean,
  width: number
|}

class Breadcrumb extends Component<BreadcrumbProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
      width: 0
    }
  }

  getStyles() {
    return {
      container: {
        [mediaQueries.breakpointSmall]: {
          fontSize: 'var(--sizes-small)'
        }
      },
      currentLocation: {
        fontFamily: 'var(--font-bold)',
        color: 'var(--color-black)',
        cursor: 'default'
      },
      enabled: {
        cursor: 'pointer'
      },
      disabled: {
        color: 'var(--greyscale-mid)',
        cursor: 'default'
      },
      chevron: {
        marginLeft: '0.4em',
        marginRight: '0.4em',
        [mediaQueries.breakpointSmall]: {
          marginLeft: '0.2em',
          marginRight: '0.2em'
        }
      },
      bracketTxt: {
        cursor: 'default',
        color: 'var(--greyscale-mid)',
        marginLeft: '0.25em',
        [mediaQueries.breakpointMedium]: {
          display: 'none'
        }
      },
      bracketTxtCursor: {
        cursor: 'pointer'
      },
      link: {
        textDecoration: 'underline'
      }
    }
  }

  redirect = () => {
    const { pathTo, redirectAction } = this.props
    redirectAction(pathTo)
  }

  toggleHover = () => {
    const { hover } = this.state
    this.setState({ hover: !hover })
  }

  render(): React$Node {
    const { bracketTxtCondition, bracketTxt, isCurrentLocation, isEnabled, pathTo, txt } = this.props
    const { hover, width } = this.state
    const styles = this.getStyles()
    let currentStyle = styles.disabled
    if (isCurrentLocation) {
      currentStyle = styles.currentLocation
    } else if (isEnabled) {
      currentStyle = styles.enabled
    }
    const breadcrumbTxtWhenDisabled =
      bracketTxt && bracketTxtCondition && width < 620 ? (
        <span>
          {txt}
          <span style={styles.bracketTxt}>({bracketTxt})</span>
        </span>
      ) : (
        txt
      )

    const linkTitle = bracketTxt && bracketTxtCondition ? `${txt}: ${bracketTxt}` : txt

    const navLinkStyle = hover ? { ...currentStyle, ...styles.link } : currentStyle

    return (
      <span style={styles.container} data-test={`breadcrumb-${txt.toLowerCase()}`}>
        {!isCurrentLocation && isEnabled && (
          <span key={txt}>
            <NavLink title={linkTitle} style={navLinkStyle} to={pathTo}>
              {txt}
            </NavLink>
            {bracketTxt && bracketTxtCondition && (
              <span
                title={linkTitle}
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
                onClick={this.redirect}
                style={[styles.bracketTxt, !isCurrentLocation && isEnabled ? styles.bracketTxtCursor : {}]}
              >
                ({bracketTxt})
              </span>
            )}
          </span>
        )}

        {((!isCurrentLocation && !isEnabled) || isCurrentLocation) && (
          <span style={currentStyle}>{breadcrumbTxtWhenDisabled}</span>
        )}

        {txt !== 'Complete' && <FontAwesome style={styles.chevron} name="angle-right" />}
      </span>
    )
  }
}

function mapDispatchToProps(dispatch: DispatchFunc) {
  return {
    redirectAction: (address: string) => {
      dispatch(pushRedirect(address))
    }
  }
}

export default (connect(null, mapDispatchToProps)(Radium(Breadcrumbs)): ComponentType<OwnProps>)
