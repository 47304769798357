// @flow
import React from 'react'
import AppDomains from '../components/AppDomains'

import styles from './../AppSettings.module.css'
import type { User } from '../../../../../types'

type Props = {|
  appId: string,
  appType: string,
  domains: {| value: string, connected: boolean |}[],
  isUpdatingDomains: boolean,
  updateDomainsError: boolean,
  user: User,
  onUpdateDomainStatus: (domainAdded?: string) => void
|}

export default class DomainsView extends React.Component<Props> {
  render (): React$Node {
    const {
      appId,
      appType,
      domains,
      isUpdatingDomains,
      updateDomainsError,
      user,
      onUpdateDomainStatus
    } = this.props

    return (
      <div className={styles.appSettingsBlock}>
        <div className={styles.blockTitle}>Domains</div>
        <div className={styles.blockSubtitle}>Serve this app from your domain{domains.length === 0 && <span>. Click the button below to get started</span>}</div>
        <AppDomains
          appId={appId}
          appType={appType}
          domains={domains}
          user={user}
          isUpdatingDomains={isUpdatingDomains}
          onTestConnection={() => onUpdateDomainStatus()}
          onUpdateDomains={(domainAdded: string) => onUpdateDomainStatus(domainAdded)}
        />
        {updateDomainsError && (
          <div className={styles.updateDomainsError}>An error occurred while updating status. Please try again later or contact support if the issue persists</div>
        )}
      </div>
    )
  }
}
