// @flow
import type { Dictionary } from '../types'

export function removeElement<T> (
  dictionary: Dictionary<T>,
  idToRemove: string
): Dictionary<T> {
  return Object.keys(dictionary).reduce((newDictionary, elementId) => {
    if (elementId !== idToRemove) {
      newDictionary[elementId] = dictionary[elementId]
    }

    return newDictionary
  }, {})
}
