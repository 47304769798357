// @flow
import type { AzureSearchResult, CatalogueItem, Dictionary } from '../../types'
import { ATTRIBUTES } from '../../data/azureSearch'
import { isNoImageProduct } from '../../helpers/isNoImageProduct'

export function mapCatalogueItems(searchResults: AzureSearchResult[]): CatalogueItem[] {
  return searchResults.map((searchResultItem) => {
    return {
      sku: searchResultItem.sku.toUpperCase(),
      productionCountries: searchResultItem.productionCountries,
      productWidthMm: searchResultItem.productWidthMm,
      productHeightMm: searchResultItem.productHeightMm,
      category: searchResultItem.category,
      shortcode: searchResultItem.shortcode,
      basePriceFrom: searchResultItem.basePriceFrom,
      currency: searchResultItem.priceCurrency,
      description: searchResultItem.description,
      productType: searchResultItem.productType,
      attributes: mapAttributes(searchResultItem),
      noImageProduct: isNoImageProduct(searchResultItem.productType)
    }
  })
}

function mapAttributes(searchResultItem: AzureSearchResult): Dictionary<string[]> {
  return Object.entries(searchResultItem).reduce(
    (attributeAcc: Dictionary<string[]>, [searchPropName, searchPropValue]: [string, any]): Dictionary<string[]> => {
      if (Array.isArray(searchPropValue) && isAttribute(searchPropName, searchPropValue)) {
        attributeAcc[searchPropName] = searchPropValue.filter(Boolean)
      }

      return attributeAcc
    },
    {}
  )
}

function isAttribute(searchPropName: string, searchPropValue: any): boolean {
  return Boolean(ATTRIBUTES.includes(searchPropName) && searchPropValue.length && searchPropValue.some(Boolean))
}
