// @flow
import type { DispatchFunc, ThunkAsync } from '../../types'
import type { UploadImageFromFileResult } from '../types'
import { RSAA } from 'redux-api-middleware'
import { selectArtworkPipelineUrl } from '../../selectors/appSettings'
import { mapArtworkServiceResponse, mapArtworkServiceErrorResponse } from './mapArtworkServiceResponse'
import { ABORT_IN_FLIGHT_REQUESTS } from '../../data/rsaa'

export const START_IMAGE_UPLOAD_FROM_FILE = 'START_IMAGE_UPLOAD_FROM_FILE'
export const FINISH_IMAGE_UPLOAD_FROM_FILE = 'FINISH_IMAGE_UPLOAD_FROM_FILE'
export const FAILED_IMAGE_UPLOAD_FROM_FILE = 'FAILED_IMAGE_UPLOAD_FROM_FILE'

type UploadImageFromFileProps = {|
  file: File,
  id: string,
  allowConcurrentRequests?: boolean
|}

export function uploadImageFromFile ({
  file,
  id,
  allowConcurrentRequests = true
}: UploadImageFromFileProps): ThunkAsync<UploadImageFromFileResult> {
  return async (dispatch: DispatchFunc): Promise<UploadImageFromFileResult> => {
    const enhancements = !allowConcurrentRequests
      ? [ ABORT_IN_FLIGHT_REQUESTS ]
      : []

    const data = new window.FormData()
    data.append('artworkFile', file)

    return dispatch({
      [RSAA]: {
        endpoint: selectArtworkPipelineUrl() + `/artwork/createFromFile`,
        method: 'POST',
        body: data,
        types: [
          {
            type: START_IMAGE_UPLOAD_FROM_FILE,
            meta: { id, enhancements }
          },
          {
            type: FINISH_IMAGE_UPLOAD_FROM_FILE,
            meta: { id },
            payload: mapArtworkServiceResponse
          },
          {
            type: FAILED_IMAGE_UPLOAD_FROM_FILE,
            meta: { id },
            payload: mapArtworkServiceErrorResponse
          }
        ]
      }
    })
  }
}
