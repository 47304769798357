// @flow
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import ImportantMessageStrip from '../../../components/ImportantMessageStrip'

type Props = {||}

class PaymentDetailsMessage extends Component<Props> {
  getStyles (): any {
    return {
      message: {
        textAlign: 'left',
        marginBottom: '1rem',
        padding: '1rem 2rem'
      },
      link: {
        color: 'var(--color-blue-70)',
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    }
  }

  render (): React$Node {
    const styles = this.getStyles()
    return (
      <div>
        <ImportantMessageStrip style={styles.message}>
          <span>You will need to <NavLink to='/settings/billing' style={styles.link}>set up payment details</NavLink> before you can submit this order.</span>
        </ImportantMessageStrip>
      </div>
    )
  }
}

export default PaymentDetailsMessage
