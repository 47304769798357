// @flow
import * as React from 'react'

type Props = {|
  className: string
|}

export default function TickCircle ({ className }: Props): React$Node {
  return (
    <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14" stroke="#105023" />
      <path d="M13.0889 17.9108L20.833 10.1667L22.0115 11.3453L13.0889 20.2678L8.33301 15.5119L9.51152 14.3334L13.0889 17.9108Z" />
    </svg>
  )
}
