// @flow
import type { Quote } from '../../../../types'
import React, { PureComponent } from 'react'
import SelectField from '../../../../components/inputs/SelectField'
import styles from './ShippingAndTotal.module.css'
import { formatCost } from '../../../../helpers/charge/formatCost'
import TaxInfo from './TaxInfo'

type Props = {|
  deliveryCountry: string,
  quotes: Quote[],
  selectedShippingMethodName: ?string,
  isUsSalesTaxAlreadyCollected: boolean,
  changeShippingMethod: (methodName: string) => void,
  toggleUsSalesTax: () => void
|}

export default class ShippingAndTotalV4 extends PureComponent<Props> {
  onShippingMethodChange: ((event: SyntheticEvent<any>) => void) = (event: SyntheticEvent<*>) => {
    this.props.changeShippingMethod(event.currentTarget.value)
  }

  formatShippingLabel (quote: Quote): string {
    const price = formatCost(quote.costSummary.shipping)

    return `${quote.shipmentMethod}: ${price}`
  }

  get selectedQuote (): Quote {
    const quote = this.props.quotes.find(
      quote => quote.shipmentMethod === this.props.selectedShippingMethodName
    )

    if (!quote) {
      throw Error('Selected quote cannot be null')
    }

    return quote
  }

  get totalItemPriceExcludingTax (): string {
    return formatCost(this.selectedQuote.costSummary.items)
  }

  get totalCostWithoutTax (): string {
    const currency = this.selectedQuote.costSummary.totalCost.currency
    const totalAmountWithTax = this.selectedQuote.costSummary.totalCost.amount
    const taxAmount = this.selectedQuote.costSummary.totalTax.amount

    const totalAmountWithoutTax = Number(totalAmountWithTax) - Number(taxAmount)

    const totalCostWithoutTax = {
      amount: totalAmountWithoutTax.toString(),
      currency
    }

    return formatCost(totalCostWithoutTax)
  }

  get totalTax (): string {
    const showUsSalesTaxDetails = this.isDeliveryCountryUnitedStates
    const currency = this.selectedQuote.costSummary.totalTax.currency

    if (showUsSalesTaxDetails && this.props.isUsSalesTaxAlreadyCollected) {
      return formatCost({
        currency,
        amount: '0.00'
      })
    } else if (showUsSalesTaxDetails && !this.props.isUsSalesTaxAlreadyCollected) {
      return 'TBC'
    } else {
      return formatCost(this.selectedQuote.costSummary.totalTax)
    }
  }

  get isDeliveryCountryUnitedStates (): boolean {
    return this.props.deliveryCountry === 'US'
  }

  get total (): string {
    return formatCost(this.selectedQuote.costSummary.totalCost)
  }

  render (): React$Node {
    if (!this.props.selectedShippingMethodName) {
      throw Error('Shipping method name cannot be null')
    }

    return (
      <table className={styles.table} data-test="summary-pricing-table">
        <tbody>
          <tr>
            <td className={styles.label}>Items:</td>
            <td className={styles.value} data-test="items-value">
              {this.totalItemPriceExcludingTax}
            </td>
          </tr>
          <tr className={styles.shipping}>
            <td className={styles.label}>Shipping:</td>
            <td className={styles.value}>
              <SelectField
                name='shipping'
                onChange={this.onShippingMethodChange}
                value={this.props.selectedShippingMethodName}
                dataTest="shipping-select"
              >
                {this.props.quotes.map(quote => (
                  <option value={quote.shipmentMethod} key={quote.shipmentMethod} data-test="shipping-option">
                    {this.formatShippingLabel(quote)}
                  </option>
                ))}
              </SelectField>
            </td>
          </tr>

          <tr>
            <td className={styles.label}>
            Tax:
            </td>
            <td className={styles.value} data-test="vat-value">
              {this.isDeliveryCountryUnitedStates && (
                <span className={styles.redText}>*&nbsp;</span>
              )}
              {this.totalTax}
            </td>
          </tr>

          <tr className={styles.total}>
            <td className={styles.label}>Order total:</td>
            <td className={styles.value} data-test="order-total">
              {this.isDeliveryCountryUnitedStates ? this.totalCostWithoutTax : this.total}
            </td>
          </tr>

          <TaxInfo
            deliveryCountry={this.props.deliveryCountry}
            toggleUsSalesTax={this.props.toggleUsSalesTax}
            isUsSalesTaxAlreadyCollected={this.props.isUsSalesTaxAlreadyCollected}
            taxValue={this.totalTax}
          />
        </tbody>
      </table>
    )
  }
}
