// @flow
import React from 'react'

import {
  DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE,
  STRIPE_CONNECT_COMPLETE_CTA,
  STRIPE_CONNECT_COMPLETE_MESSAGE,
  STRIPE_CONNECT_NOT_FINISHED_CTA,
  STRIPE_CONNECT_NOT_FINISHED_MESSAGE,
  STRIPE_CONNECT_NOT_STARTED_CTA,
  STRIPE_CONNECT_NOT_STARTED_MESSAGE,
  STRIPE_CONNECT_ONBOARDING_STATES,
  STRIPE_CONNECT_PENDING_VERIFICATION_MESSAGE
} from '../../../../Apps/constants'
import AccountView from './AccountView'
import { ABORT_ERROR_NAME } from '../../../../../data/constants'
import type { AbortController, StripeConnectOnboardingState, User } from '../../../../../types'
import { getStripeConnectLoginLink, getStripeConnectSignupLink, processStripeConnectSignup } from '../../../../../helpers/stripeConnect'

type Props = {|
  stripeOnboardingState: StripeConnectOnboardingState,
  user: User
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  ctaText: string,
  errorMessage: string,
  hasError: boolean,
  isCtaVisible: boolean,
  isLoading: boolean,
  message: string
|}

export default class AccountController extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const abortController = new window.AbortController()
    const abortSignal = abortController.signal
    let ctaText = ''
    let isCtaVisible = true
    let message = ''
    switch (this.props.stripeOnboardingState) {
      case STRIPE_CONNECT_ONBOARDING_STATES.NOT_FINISHED:
        ctaText = STRIPE_CONNECT_NOT_FINISHED_CTA
        message = STRIPE_CONNECT_NOT_FINISHED_MESSAGE
        break
      case STRIPE_CONNECT_ONBOARDING_STATES.PENDING_VERIFICATION:
        isCtaVisible = false
        message = STRIPE_CONNECT_PENDING_VERIFICATION_MESSAGE
        break
      case STRIPE_CONNECT_ONBOARDING_STATES.COMPLETE:
        ctaText = STRIPE_CONNECT_COMPLETE_CTA
        message = STRIPE_CONNECT_COMPLETE_MESSAGE
        break
      case STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED:
      default:
        ctaText = STRIPE_CONNECT_NOT_STARTED_CTA
        message = STRIPE_CONNECT_NOT_STARTED_MESSAGE
        break
    }

    this.state = {
      abortController,
      abortSignal,
      ctaText,
      errorMessage: DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE,
      isCtaVisible,
      isLoading: false,
      hasError: false,
      message
    }
  }

  handleStripeAccountCta: (() => void) = () => {
    this.setState({ hasError: false, isLoading: true })

    switch (this.props.stripeOnboardingState) {
      case STRIPE_CONNECT_ONBOARDING_STATES.NOT_FINISHED:
        this.handleStripeConnectNotFinished()
        break
      case STRIPE_CONNECT_ONBOARDING_STATES.COMPLETE:
        this.handleStripeConnectLogin()
        break
      case STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED:
      default:
        this.handleStripeConnectNotStarted()
        break
    }
  }

  handleStripeConnectNotStarted: (() => Promise<void>) = async () => {
    const { user } = this.props
    const { abortSignal } = this.state
    try {
      const stripeConnectSignupLinkData = await processStripeConnectSignup({
        abortSignal,
        countryCode: user.company.countryCode,
        email: user.email
      })
      this.redirectToStripe({
        url: stripeConnectSignupLinkData.url
      })
    } catch (error) {
      if (error.responseBodyJson && error.responseBodyJson.error && error.responseBodyJson.error.message) {
        this.setState({
          errorMessage: error.responseBodyJson.error.message
        })
      } else {
        this.setState({
          errorMessage: DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE
        })
      }
      this.onError(error)
    }
  }

  handleStripeConnectNotFinished: (() => Promise<void>) = async () => {
    const { abortSignal } = this.state
    try {
      const stripeConnectSignupLinkData = await getStripeConnectSignupLink({
        abortSignal
      })
      this.redirectToStripe({
        url: stripeConnectSignupLinkData.url
      })
    } catch (error) {
      this.onError(error)
    }
  }

  handleStripeConnectLogin: (() => Promise<void>) = async () => {
    const { abortSignal } = this.state
    try {
      const stripeConnectLoginLinkData = await getStripeConnectLoginLink({
        abortSignal
      })
      this.redirectToStripe({
        url: stripeConnectLoginLinkData.url,
        target: '_blank'
      })
      this.onSuccess()
    } catch (error) {
      this.onError(error)
    }
  }

  onSuccess: (() => void) = () => {
    this.setState({ hasError: false, isLoading: false })
  }

  onError: ((err: Error) => void) = (err: Error) => {
    if (err.name !== ABORT_ERROR_NAME) {
      console.error(err)
      this.setState({ hasError: true, isLoading: false })
    }
  }

  redirectToStripe: (({| target?: string, url: string |}) => void) = (
    { url, target = '_self' }: {| url: string, target?: string |}
  ) => {
    window.open(url, target)
  }

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    return (
      <AccountView
        ctaText={this.state.ctaText}
        hasError={this.state.hasError}
        errorMessage={this.state.errorMessage}
        isCtaVisible={this.state.isCtaVisible}
        isLoading={this.state.isLoading}
        message={this.state.message}
        onAccountCtaClick={this.handleStripeAccountCta} />
    )
  }
}
