export const ATTRIBUTE_MAPPINGS = [
  {
    v3AttributeName: 'mountColour',
    v4AttributeName: 'mountColor'
  },
  {
    v3AttributeName: 'frameColour',
    v4AttributeName: 'color'
  }
]
