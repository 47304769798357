import useSWRInfinite from 'swr/infinite'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import {
  OrdersContainer,
  OrdersHeader,
  NoOrders,
  OrdersListPaginationAllLoadedMessage,
  OrdersListPaginationFailedToLoad,
  OrdersListAppliedFilters,
  OrdersNotificationBanner
} from './components'
import OrdersList from '../OrdersList'
import { fetcher } from '../../helpers'
import { getOrdersKey } from './helpers'
import ErrorScreen from '../ErrorScreen'
import { useMerchantService } from '../../hooks'
import OrdersListActions from '../OrdersListActions'
import { QUERY_PARAMS } from '../../constants/queryParams.const'
import { FetchErrorInterface, OrdersDataErrorInterface, OrdersDataSuccessInterface } from '../../interfaces'

export default function Orders() {
  const location = useLocation()
  const { isLoading: isLoadingMerchantDetails, merchantDetailsFetchError } = useMerchantService()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchParamsCopy = new URLSearchParams(searchParams)
  // Sort param isn't a filter and shouldn't factor into hasFilters
  searchParamsCopy.delete(QUERY_PARAMS.ORDERS.SORT)
  const hasFilters = searchParamsCopy.toString() !== ''

  useEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0)
    })
  }, [searchParams])

  const {
    data: orderPages,
    error,
    size,
    setSize,
    isValidating
  } = useSWRInfinite<OrdersDataSuccessInterface, FetchErrorInterface<OrdersDataErrorInterface>>(getOrdersKey, fetcher)

  const lastFetchedOrdersPage = orderPages?.[size - 1]
  const hasNextPage = Boolean(lastFetchedOrdersPage?.data?.hasMore && lastFetchedOrdersPage?.data?.nextUrl)

  const isLoadingFirstPageOrders = Boolean(!orderPages && size <= 1)
  const errorLoadingFirstPage = Boolean(size <= 1 && !hasNextPage && error)

  const isLoadingNextPage = Boolean(size > 1 && isValidating)
  const errorLoadingNextPage = Boolean(size > 1 && error)

  const hasZeroOrdersWithAppliedFilters = Boolean(
    !isLoadingFirstPageOrders && orderPages && orderPages.length === 1 && orderPages[0].data.orderSummaries.length === 0
  )
  const hasLoadedAllPages = Boolean(
    size > 1 &&
      !hasNextPage &&
      !isLoadingNextPage &&
      !errorLoadingNextPage &&
      !hasZeroOrdersWithAppliedFilters &&
      !isLoadingMerchantDetails &&
      !merchantDetailsFetchError
  )

  const userHasNoOrders = !hasFilters && orderPages?.[0]?.data.orderSummaries.length === 0

  const [paginationTriggerRef] = useInfiniteScroll({
    loading: isLoadingNextPage,
    hasNextPage,
    onLoadMore: useDebouncedCallback(() => {
      setSize((size) => size + 1)
    }, 500),
    disabled: Boolean(error || isLoadingNextPage)
  })

  if (merchantDetailsFetchError) {
    const errorMessage = merchantDetailsFetchError.responseBodyJson?.outcome
      ? `An error occurred while loading orders. Code - MDF-${merchantDetailsFetchError.responseBodyJson?.outcome}`
      : 'An unknown error occurred. Please try again later. Code - MDF'

    return (
      <OrdersContainer verticallyCenter={true}>
        <ErrorScreen errorMessage={errorMessage} toastMessage="Failed to get orders" />
      </OrdersContainer>
    )
  }

  if (error && errorLoadingFirstPage) {
    const errorMessage = error.responseBodyJson?.traceParent
      ? `An error occurred with code ${error.responseBodyJson?.traceParent}`
      : 'An unknown error occurred. Please try again later'

    return (
      <OrdersContainer verticallyCenter={true}>
        <ErrorScreen errorMessage={errorMessage} toastMessage="Failed to get orders" />
      </OrdersContainer>
    )
  }

  if (userHasNoOrders) {
    return (
      <OrdersContainer>
        <NoOrders />
      </OrdersContainer>
    )
  }

  return (
    <OrdersContainer>
      <OrdersHeader />
      <OrdersNotificationBanner />
      <OrdersListActions />
      <OrdersListAppliedFilters />

      <OrdersList
        hasZeroOrdersWithAppliedFilters={hasZeroOrdersWithAppliedFilters}
        isLoadingFirstPageOrders={isLoadingFirstPageOrders}
        isLoadingNextPageOrders={isLoadingNextPage}
        key={searchParams.toString()}
        orderPages={orderPages}
      />
      {hasLoadedAllPages && <OrdersListPaginationAllLoadedMessage />}
      {errorLoadingNextPage && (
        <OrdersListPaginationFailedToLoad error={error} toastMessage={'Failed to load the next page of orders'} />
      )}
      <div ref={paginationTriggerRef} className="invisible h-20"></div>
    </OrdersContainer>
  )
}
