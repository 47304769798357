// @flow
import { createSelector } from 'reselect'
import { identity } from '../helpers'
import type { GetV4SortedOrders } from './types'
import type {
  V4Order,
  OrderActions,
  Dictionary,
  AppState,
  Order,
  OrderWithDetails,
  Items as Orders,
  OrderFilterScope
} from '../../types'
import { convertV4OrderIdToV3OrderId } from '../../helpers/orderId'

export function selectOrders(state: AppState): Orders {
  return state.orders.items ?? {}
}

export function selectV4Orders(state: AppState): Dictionary<V4Order> {
  return state.orders.orderDetailsV4
}

export function selectCurrentView(state: AppState): string[] {
  return state.orders.currentView
}

export function selectOrderById(state: AppState, orderId: string): Order {
  return state.orders.items[orderId]
}

export function filterOrders(allKeys: string[], items: Orders): Orders {
  return allKeys.reduce((obj, key) => {
    obj[key] = items[key]
    return obj
  }, {})
}

export function filterOrdersV4(allKeys: string[], orders: Dictionary<V4Order>): V4Order[] {
  return allKeys.map((key) => orders[key])
}

export function sortOrdersV4(orders: V4Order[]): V4Order[] {
  return orders.sort((order1, order2) => Number(order2.id) - Number(order1.id))
}

export function sortOrders(orders: OrderWithDetails): OrderWithDetails {
  return Object.keys(orders)
    .sort((a, b) => {
      return Number(convertV4OrderIdToV3OrderId(b)) - Number(convertV4OrderIdToV3OrderId(a))
    })
    .map((key) => orders[key])
}

export function selectOrderFilterScope(state: AppState): OrderFilterScope {
  return state.orders.filters.scope
}

export function selectOrderFilterQuery(state: AppState): string {
  return state.orders.filters.query
}

export function selectOrderFilterSkip(state: AppState): number {
  return state.orders.filters.skip
}

export function selectOrderFilterLimit(state: AppState): number {
  return state.orders.filters.limit
}

export function selectPages(state: AppState): Dictionary<string[]> {
  return state.orders.pages
}

export function selectHasOrdered(state: AppState): boolean {
  return Object.keys(state.orders.items).length > 0
}

export function selectOrderActionsById(state: AppState, orderId: string): ?OrderActions {
  return state.orders.orderActions[orderId]
}

export function selectOrderDetailsV4ById(state: AppState, orderId: string): ?V4Order {
  return state.orders.orderDetailsV4[orderId]
}

export function selectAssetIdsByOrderId(state: AppState, orderId: string): string[] {
  const orderDetails = state.orders.orderDetailsV4[orderId]
  if (!orderDetails) {
    return []
  }

  const allAssets = orderDetails.items.reduce((assetAcc, orderItem) => assetAcc.concat(...orderItem.assets), [])

  return allAssets.map((asset) => asset.id)
}

export const getFilteredOrders: any = createSelector(selectCurrentView, selectOrders, filterOrders)
export const getV4FilteredOrders: any = createSelector(selectCurrentView, selectV4Orders, filterOrdersV4)
export const getSortedOrders: any = createSelector(getFilteredOrders, sortOrders)
export const getV4SortedOrders: GetV4SortedOrders = createSelector(getV4FilteredOrders, sortOrdersV4)
export const getOrderFilterScope: any = createSelector(selectOrderFilterScope, identity)
export const getOrderFilterQuery: any = createSelector(selectOrderFilterQuery, identity)
export const getHasOrdered: any = createSelector(selectHasOrdered, identity)
