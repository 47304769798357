// @flow
import React from 'react'
import type { Element } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import Product from './Product'
import styles from './SelectProduct.module.css'
import type { CatalogueItem, Event } from '../../../../types'
import { clearProductSearch } from '../../../../actions/catalogue/catalogue'

// $FlowFixMe: TypeScript file
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../../v2/constants'

type ProductSearchResultsProps = {|
  fetchingPrices: boolean,
  isSearchInconclusive: boolean,
  searchItems: ?(CatalogueItem[]),
  onSelectProduct: (e: Event) => void
|}

export default function ProductSearchResults({
  fetchingPrices,
  isSearchInconclusive,
  searchItems,
  onSelectProduct
}: ProductSearchResultsProps): Element<'div'> {
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const categoryParam = searchParams.get(QUERY_PARAMS.MOF.SEARCH.CATEGORY)
  const productTypeParam = searchParams.get(QUERY_PARAMS.MOF.SEARCH.PRODUCT_TYPE)

  return (
    <div data-test="product-search-results" className={styles.searchResultContainer}>
      {isSearchInconclusive ? (
        <>
          <div>We weren&#39;t able to find any matching products.</div>

          {Boolean(categoryParam || productTypeParam) && (
            <div className="tailwind" style={{ marginTop: '8px' }}>
              Please try{' '}
              <button
                className="text-purple-500"
                onClick={() => {
                  history.replace(ROUTE_PATHS.MOF.SEARCH)
                  dispatch(clearProductSearch())
                }}
              >
                clearing the search
              </button>{' '}
              and entering the product name again.
            </div>
          )}
        </>
      ) : (
        searchItems &&
        searchItems.map((item) => (
          <Product key={item.sku} item={item} onSelectProduct={onSelectProduct} fetchingPrices={fetchingPrices} />
        ))
      )}
    </div>
  )
}
