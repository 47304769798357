// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function LogoMark ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width='26' height='36' viewBox='0 0 28 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0 39.8333H14V25.8065H28V0.166626H0V39.8333ZM28 39.8333V25.8065L14 39.8333H28Z' />
    </svg>
  )
}
