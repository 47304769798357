import { getAccessToken } from '../selectors/localStorage'

export function getDefaultFetchOptions(): { headers: { Authorization: string; 'Content-Type': string } } {
  return {
    headers: {
      Authorization: 'Bearer ' + (getAccessToken() || ''),
      'Content-Type': 'application/json'
    }
  }
}
