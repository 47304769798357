function PlasticFree({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 60 60"
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
    >
      <title>Plastic-free icon</title>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <path d="M30,0 C46.5425806,0 60,13.4583871 60,30 C60,46.5416129 46.5425806,60 30,60 C13.4574194,60 0,46.5416129 0,30 C0,13.4583871 13.4574194,0 30,0 Z M50.7241935,11.0970968 L11.0970968,50.7241935 C16.0887097,55.2812903 22.7245161,58.0645161 30,58.0645161 C45.4741935,58.0645161 58.0645161,45.4751613 58.0645161,30 C58.0645161,22.7245161 55.2812903,16.0887097 50.7241935,11.0970968 L50.7241935,11.0970968 Z M30,1.93548387 C14.5258065,1.93548387 1.93548387,14.5248387 1.93548387,30 C1.93548387,37.5048387 4.90258065,44.3264516 9.71806452,49.3664516 L9.71806452,49.3664516 L49.3674194,9.71806452 C44.3264516,4.90258065 37.5048387,1.93548387 30,1.93548387 Z M42.546871,21.8773548 L42.8894516,24.9228387 L42.8894516,46.7599355 L17.6642903,46.7599355 L19.5997742,44.8244516 L40.9539677,44.8244516 L40.9539677,24.9770323 L40.8010645,23.6231613 L42.546871,21.8773548 Z M38.3864516,38.4082258 C38.3864516,40.4830645 36.6987097,42.1708065 34.6229032,42.1708065 L34.6229032,42.1708065 L34.6229032,41.2030645 C36.1645161,41.2030645 37.4187097,39.9498387 37.4187097,38.4082258 L37.4187097,38.4082258 Z M23.5405161,13.8144194 L23.5405161,23.8644194 L32.6179355,23.8644194 L30.6824516,25.7999032 L21.6050323,25.7999032 L21.6050323,15.7499032 L19.8495484,15.7499032 L18.8024516,25.0837742 L18.8024516,37.6789355 L16.8689032,39.6124839 L16.8727742,24.9221613 L18.1172903,13.8144194 L23.5405161,13.8144194 Z M41.6384516,13.8144194 L41.742,14.7395806 L39.9932903,16.4892581 L39.9090968,15.7499032 L38.1516774,15.7499032 L38.1516774,18.3299032 L36.2161935,20.2653871 L36.2161935,13.8144194 L41.6384516,13.8144194 Z"></path>
      </g>
    </svg>
  )
}

export { PlasticFree }
