// @flow
import type { ImagesState } from '../../types'
import type {
  ResetDownloadProgress,
  DownloadImageAction,
  InitArtworkTransformationsError,
  CopyArtworkDetails,
  ImageActions,
  StartImageUploadFromFile,
  StartImageUploadFromUrl,
  FinishImageUploadFromFile,
  FailedImageUploadFromFile,
  FinishImageUploadFromUrl,
  FailedImageUploadFromUrl,
  FetchTemplateSuccess,
  ResetImage
} from '../../actions/types/images'
import {
  START_IMAGE_UPLOAD_FROM_FILE,
  FINISH_IMAGE_UPLOAD_FROM_FILE,
  FAILED_IMAGE_UPLOAD_FROM_FILE,
  START_IMAGE_UPLOAD_FROM_URL,
  FINISH_IMAGE_UPLOAD_FROM_URL,
  FAILED_IMAGE_UPLOAD_FROM_URL,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERROR,
  INIT_ARTWORK_TRANSFORMATIONS_SUCCESS,
  INIT_ARTWORK_TRANSFORMATIONS_ERROR,
  UPDATE_ARTWORK,
  RESET_IMAGE_STATE,
  UPDATE_ARTWORK_TRANSFORMATIONS,
  COPY_ARTWORK_DETAILS,
  DOWNLOAD_IMAGE,
  DOWNLOAD_IMAGE_ERROR,
  DOWNLOAD_IMAGE_SUCCESS,
  RESET_DOWNLOAD_PROGRESS
} from '../../actions/images'
import type { UpdateArtworkTransformations } from '../../actions/types'
import { LOADING, SUCCESS, ERROR, IDLE } from '../../data/rsaa'
import { entries } from '../../helpers/dictionary'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'
import { RESET_IMAGE } from '../../actions/images/resetImage'
import type { FinishSelectImageLibraryImageActionType } from '../../actions/images/finishSelectImageLibraryImage'
import { FINISH_SELECT_IMAGE_LIBRARY_IMAGE } from '../../actions/images/finishSelectImageLibraryImage'

const initialState = {
  templateProgress: {},
  templates: {},
  artworks: {},
  artworkProgress: {},
  artworkTransformations: {},
  artworkTransformationStatus: {},
  downloadProgress: {}
}

export default function images(state: ImagesState = initialState, action: ImageActions): ImagesState {
  switch (action.type) {
    case COPY_ARTWORK_DETAILS: {
      const currentAction: CopyArtworkDetails = action
      return {
        ...state,
        artworks: {
          ...state.artworks,
          [currentAction.id]: currentAction.artwork
        },
        artworkProgress: {
          ...state.artworkProgress,
          [currentAction.id]: {
            status: SUCCESS,
            errorMessage: ''
          }
        }
      }
    }

    case UPDATE_ARTWORK_TRANSFORMATIONS: {
      const currentAction: UpdateArtworkTransformations = action
      return {
        ...state,
        artworkTransformations: {
          ...state.artworkTransformations,
          [currentAction.id]: currentAction.transformations
        }
      }
    }

    case UPDATE_ARTWORK: {
      return {
        ...state,
        artworks: {
          ...state.artworks,
          [action.itemId]: {
            ...state.artworks[action.itemId],
            croppedImageUrl: action.croppedImageUrl,
            transformImageUrl: action.transformImageUrl ?? action.croppedImageUrl
          }
        }
      }
    }

    case RESET_IMAGE_STATE: {
      return initialState
    }

    case RESET_IMAGE: {
      const currentAction: ResetImage = action
      return {
        ...state,
        artworks: entries(state.artworks).reduce((artworkAcc, [id, artwork]) => {
          if (currentAction.imageId !== id) {
            artworkAcc[id] = artwork
          }

          return artworkAcc
        }, {}),
        artworkTransformations: entries(state.artworkTransformations).reduce(
          (artworkTransformationsAcc, [id, artworkTransformations]) => {
            if (currentAction.imageId !== id) {
              artworkTransformationsAcc[id] = artworkTransformations
            }

            return artworkTransformationsAcc
          },
          {}
        ),
        artworkTransformationStatus: {
          ...state.artworkTransformationStatus,
          [currentAction.imageId]: IDLE
        },
        artworkProgress: {
          ...state.artworkProgress,
          [currentAction.imageId]: {
            status: IDLE,
            errorMessage: ''
          }
        }
      }
    }

    case INIT_ARTWORK_TRANSFORMATIONS_SUCCESS: {
      return {
        ...state,
        artworkTransformations: {
          ...state.artworkTransformations,
          [action.itemId]: action.transformations
        },
        artworkTransformationStatus: {
          ...state.artworkTransformationStatus,
          [action.itemId]: SUCCESS
        }
      }
    }

    case INIT_ARTWORK_TRANSFORMATIONS_ERROR: {
      const currentAction: InitArtworkTransformationsError = action
      return {
        ...state,
        artworkTransformationStatus: {
          ...state.artworkTransformationStatus,
          [currentAction.itemId]: ERROR
        }
      }
    }

    case START_IMAGE_UPLOAD_FROM_FILE: {
      const currentAction: StartImageUploadFromFile = action
      return {
        ...state,
        artworkProgress: {
          ...state.artworkProgress,
          [currentAction.meta.id]: {
            status: LOADING,
            errorMessage: ''
          }
        },
        artworks: Object.keys(state.artworks).reduce((artworksAcc, artworkId) => {
          if (artworkId !== currentAction.meta.id) {
            artworksAcc[artworkId] = state.artworks[artworkId]
          }
          return artworksAcc
        }, {}),
        artworkTransformations: Object.keys(state.artworks).reduce((artworkTransformationAcc, artworkId) => {
          if (artworkId !== currentAction.meta.id) {
            artworkTransformationAcc[artworkId] = state.artworkTransformations[artworkId]
          }
          return artworkTransformationAcc
        }, {})
      }
    }

    case FINISH_IMAGE_UPLOAD_FROM_FILE: {
      const currentAction: FinishImageUploadFromFile = action

      return {
        ...state,
        artworks: {
          ...state.artworks,
          [currentAction.meta.id]: currentAction.payload
        },
        artworkProgress: {
          ...state.artworkProgress,
          [currentAction.meta.id]: {
            status: SUCCESS,
            errorMessage: ''
          }
        }
      }
    }

    case FINISH_SELECT_IMAGE_LIBRARY_IMAGE: {
      const currentAction: FinishSelectImageLibraryImageActionType = action

      return {
        ...state,
        artworks: {
          ...state.artworks,
          [currentAction.payload.imageId]: {
            ...currentAction.payload.artwork,
            transformImageUrl: currentAction.payload.artwork.croppedImageUrl
          }
        },
        artworkProgress: {
          ...state.artworkProgress,
          [currentAction.payload.imageId]: {
            status: SUCCESS,
            errorMessage: ''
          }
        }
      }
    }

    case FAILED_IMAGE_UPLOAD_FROM_FILE: {
      const currentAction: FailedImageUploadFromFile = action

      return {
        ...state,
        artworkProgress: {
          ...state.artworkProgress,
          [currentAction.meta.id]: {
            status: ERROR,
            errorMessage: currentAction.payload.errorMessage ?? DEFAULT_ERROR_MESSAGE
          }
        }
      }
    }

    case START_IMAGE_UPLOAD_FROM_URL: {
      const currentAction: StartImageUploadFromUrl = action

      const updatedArtworkProgress = currentAction.meta.ids.reduce((progressAcc, id) => {
        progressAcc[id] = {
          status: LOADING,
          errorMessage: ''
        }
        return progressAcc
      }, {})

      const artworks = entries(state.artworks).reduce((artworksAcc, [artworkId, artwork]) => {
        if (!currentAction.meta.ids.includes(artworkId)) {
          artworksAcc[artworkId] = state.artworks[artworkId]
        }
        return artworksAcc
      }, {})

      const artworkTransformations = entries(state.artworks).reduce(
        (artworkTransformationAcc, [artworkId, transformations]) => {
          if (!currentAction.meta.ids.includes(artworkId)) {
            artworkTransformationAcc[artworkId] = state.artworkTransformations[artworkId]
          }
          return artworkTransformationAcc
        },
        {}
      )

      return {
        ...state,
        artworkProgress: {
          ...state.artworkProgress,
          ...updatedArtworkProgress
        },
        artworks,
        artworkTransformations
      }
    }

    case FINISH_IMAGE_UPLOAD_FROM_URL: {
      const currentAction: FinishImageUploadFromUrl = action

      const updatedArtworks = currentAction.meta.ids.reduce((artworkAcc, id) => {
        artworkAcc[id] = currentAction.payload
        return artworkAcc
      }, {})

      const updatedArtworkProgress = currentAction.meta.ids.reduce((artworkProgressAcc, id) => {
        artworkProgressAcc[id] = {
          status: SUCCESS,
          errorMessage: ''
        }
        return artworkProgressAcc
      }, {})

      return {
        ...state,
        artworks: {
          ...state.artworks,
          ...updatedArtworks
        },
        artworkProgress: {
          ...state.artworkProgress,
          ...updatedArtworkProgress
        }
      }
    }

    case FAILED_IMAGE_UPLOAD_FROM_URL: {
      const currentAction: FailedImageUploadFromUrl = action

      const updatedArtworkProgress = currentAction.meta.ids.reduce((artworkProgressAcc, id) => {
        artworkProgressAcc[id] = {
          status: ERROR,
          errorMessage: currentAction.payload.errorMessage
        }
        return artworkProgressAcc
      }, {})

      return {
        ...state,
        artworkProgress: {
          ...state.artworkProgress,
          ...updatedArtworkProgress
        }
      }
    }

    case FETCH_TEMPLATE: {
      return {
        ...state,
        templateProgress: {
          ...state.templateProgress,
          [action.meta.id]: LOADING
        }
      }
    }

    case FETCH_TEMPLATE_SUCCESS: {
      const currentAction: FetchTemplateSuccess = action
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.meta.id]: currentAction.payload
        },
        templateProgress: {
          ...state.templateProgress,
          [action.meta.id]: SUCCESS
        }
      }
    }

    case FETCH_TEMPLATE_ERROR: {
      return {
        ...state,
        templateProgress: {
          ...state.templateProgress,
          [action.meta.id]: ERROR
        }
      }
    }

    case DOWNLOAD_IMAGE: {
      const currentAction: DownloadImageAction = action

      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [currentAction.id]: LOADING
        }
      }
    }

    case DOWNLOAD_IMAGE_ERROR: {
      const currentAction: DownloadImageAction = action

      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [currentAction.id]: ERROR
        }
      }
    }

    case DOWNLOAD_IMAGE_SUCCESS: {
      const currentAction: DownloadImageAction = action

      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          [currentAction.id]: SUCCESS
        }
      }
    }

    case RESET_DOWNLOAD_PROGRESS: {
      const currentAction: ResetDownloadProgress = action

      const updatedProgress = entries(state.downloadProgress).reduce((downloadProgressAcc, [id, status]) => {
        if (!currentAction.ids.includes(id)) {
          return downloadProgressAcc
        }

        downloadProgressAcc[id] = IDLE
        return downloadProgressAcc
      }, {})

      return {
        ...state,
        downloadProgress: {
          ...state.downloadProgress,
          ...updatedProgress
        }
      }
    }

    default: {
      return state
    }
  }
}
