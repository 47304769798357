import { OrderStatusEnum, OrderDetailIssueEnum } from '../../enums'
import dateFormat from '../../helpers/dateFormat'
import timeFormat from '../../helpers/timeFormat'
import ClipboardCopy from '../ClipboardCopy'
import OrderStatusFlag from '../OrderStatusFlag'
import { OrderDetailHeaderActions } from './components'
import OrderIssueFlag from '../OrderIssueFlag'

interface OrderDetailHeaderInterface {
  cancellationIsAvailable?: boolean
  createdDate: string
  orderId: string
  recipientName: string
  status: OrderStatusEnum
  unresolvedIssues: OrderDetailIssueEnum[]
  mutateOrderDetails: () => void
}

export default function OrderDetailHeader({
  cancellationIsAvailable,
  createdDate,
  orderId,
  recipientName,
  status,
  unresolvedIssues,
  mutateOrderDetails
}: OrderDetailHeaderInterface) {
  return (
    <div className="isolate grid w-full grid-cols-1 items-start gap-4 md:grid-cols-3 xl:gap-8">
      <div className="col-span-2">
        {unresolvedIssues.length > 0 && (
          <OrderIssueFlag className="mb-2 w-fit" condensed={false} unresolvedIssues={unresolvedIssues} />
        )}
        <div className="flex flex-col sm:flex-row">
          <h1 data-test="order-detail-header__orderId" data-test-id={orderId}>
            <span className="hidden md:inline">Order </span>
            <ClipboardCopy text={orderId} />
          </h1>
        </div>

        <div className="mt-4 flex flex-col items-start md:mt-2 md:flex-row">
          <OrderStatusFlag statusText={status} />
          <span className="hidden px-3 md:inline">&middot;</span>
          <span className="mt-2 whitespace-nowrap md:mt-0" data-test="order-detail-header__recipient-name">
            {recipientName}
          </span>
          <span className="hidden px-3 md:inline">&middot;</span>
          <span className="mt-2 md:mt-0" data-test="order-detail-header__created-date">
            <span className="whitespace-nowrap">{dateFormat(createdDate)}</span> at{' '}
            <span className="whitespace-nowrap">{timeFormat(createdDate)}</span>
          </span>
        </div>
      </div>

      <OrderDetailHeaderActions
        cancellationIsAvailable={cancellationIsAvailable}
        orderId={orderId}
        status={status}
        mutateOrderDetails={mutateOrderDetails}
      />
    </div>
  )
}
