import { useParams } from 'react-router'
import { useEffect, useRef } from 'react'
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'

import { useSalesChannelProducts } from '../../../hooks'
import { ProductsSearch } from './ProductsSearch.component'
import { ProductsFilter } from './ProductsFilter.component'
import { ProductActions } from './ProductActions.component'

export function ConfigureActions() {
  const { salesChannelId } = useParams<{ salesChannelId: string }>()
  const { isValidatingSalesChannelProducts } = useSalesChannelProducts(salesChannelId)

  const ref = useRef<LoadingBarRef>(null)

  useEffect(() => {
    if (isValidatingSalesChannelProducts) {
      ref.current?.continuousStart(30)
    } else {
      ref.current?.complete()
    }
  }, [isValidatingSalesChannelProducts])

  return (
    <div className={'sticky top-nav-bar-height z-10 min-w-full bg-gray-100 align-middle'}>
      <LoadingBar
        ref={ref}
        color="#4630d4"
        className="will-change-transform"
        containerStyle={{ position: 'absolute' }}
        shadow={false}
      />

      <div className="flex w-full flex-wrap justify-start gap-2 border bg-white p-4 sm:p-6 md:gap-4 xl:justify-end">
        <span className="mr-auto flex w-full items-center justify-self-start sm:w-64 lg:w-96">
          <ProductsSearch />
        </span>
        <span className="flex w-64 flex-grow items-center sm:flex-grow-0">
          <ProductsFilter />
        </span>
        <span className="flex w-64 flex-grow items-center sm:flex-grow-0">
          <ProductActions />
        </span>
      </div>
    </div>
  )
}
