// @flow
export const WIX_VARIANT_HEADER = 'Variant'

export const WIX_CSV_HEADERS = [
  'Order no.',
  'Date',
  'Time',
  'Total order quantity',
  'Contact email',
  'Note from customer',
  'Additional checkout info',
  'Item',
  WIX_VARIANT_HEADER,
  'SKU',
  'Qty',
  'Qty refunded',
  'Price',
  'Weight',
  'Custom text',
  'Delivery method',
  'Delivery time',
  'Recipient name',
  'Recipient phone no.',
  'Recipient company name',
  'Delivery country',
  'Delivery state',
  'Delivery city',
  'Delivery address',
  'Delivery ZIP/postal code',
  'Billing name',
  'Billing phone no.',
  'Billing company name',
  'Billing country',
  'Billing state',
  'Billing city',
  'Billing address',
  'Billing ZIP/postal code',
  'Payment status',
  'Payment method',
  'Coupon code',
  'Gift card amount',
  'Shipping rate',
  'Tax',
  'Total',
  'Currency',
  'Refunded amount',
  'Total after refund',
  'Fulfillment status',
  'Tracking no.',
  'Fulfillment service',
  'Shipping label'
]
