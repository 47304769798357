// @flow
import processFetchResponse from './processFetchResponse'
import type { PigUploadResponse } from '../types/pigUploadResponse'

function uploadImageToPig (formData: FormData): Promise<PigUploadResponse> {
  const url = `${process.env.REACT_APP_PIG_URL || ''}/upload/`
  return fetch(url, {
    method: 'POST',
    body: formData
  })
    .then(processFetchResponse)
    .catch((err) => {
      throw new Error(`Failed upload to PIG: ${err}`)
    })
}

export default uploadImageToPig
