// @flow
import type {
  Dimensions,
  CmOrInch,
  CropRectangle
} from '../../types'
import {
  calcPrintDimensionsFillScaleFactor,
  calcPreviewBorder
} from '@prodigi-group/components-image-editor'

export function convertFillScaleToPrintSizeScale (
  fillScaleFactor: number,
  printDpi: number,
  borderUnit: CmOrInch,
  borderWidth: number,
  printDimensionsInPx: Dimensions,
  artworkDimensionsInPx: Dimensions,
  cropRectangle: CropRectangle
): number {
  const scaledBorderInPx = calcPreviewBorder(
    printDpi,
    borderUnit,
    borderWidth,
    printDimensionsInPx,
    cropRectangle
  )
  const printDimensionScaleFactor = calcPrintDimensionsFillScaleFactor(printDimensionsInPx, artworkDimensionsInPx, scaledBorderInPx, 0)

  return fillScaleFactor * printDimensionScaleFactor
}
