import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { DEFAULT_DOCUMENT_TITLE, QUERY_PARAMS, ANALYTICS_PAGE_CATEGORIES } from '../../constants'
import PaymentAuth from '../../components/PaymentAuth'

export default function PaymentAuthPage() {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const orderId = params.get(QUERY_PARAMS.PAYMENT.ORDER_ID)

  useEffect(() => {
    if (!orderId) {
      return
    }
    document.title = `Order ${orderId} - Authorise Payment | Prodigi`
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.PAYMENT, 'Authorise payment', {
      paymentId: orderId
    })
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [orderId])

  return (
    <main className="tailwind">
      <PaymentAuth />
    </main>
  )
}
