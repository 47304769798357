import { useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory, useParams } from 'react-router-dom'

import {
  OrderDetailItemInterface,
  StatusType,
  FetchErrorInterface,
  OMSErrorResponseInterface
} from '../../../interfaces'
import Checkbox from '../../Checkbox'
import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { ROUTE_PATHS } from '../../../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { updateIgnoreLineItemStatus } from '../helpers'
import { SalesChannelPlatformEnum } from '../../../enums'
import { useMerchantService, useOrderDetail } from '../../../hooks'
import { updateChannelProductFulfilmentUsingExternalId } from '../../SalesChannelConfigure'

const IGNORE_PRODUCT_TOASTS = {
  ERROR: 'prodigiOrderLineItemIgnoreToast'
}

export function IgnoreProductModal({
  isOpen,
  isLastLineItemToBeFulfilled,
  lineItem,
  setIsOpen
}: {
  isOpen: boolean
  isLastLineItemToBeFulfilled: boolean
  lineItem: OrderDetailItemInterface
  setIsOpen: (isOpen: boolean) => void
}) {
  const history = useHistory()
  const { merchantDetails } = useMerchantService()
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  const [ignoreItemStatus, setIgnoreItemStatus] = useState<StatusType>('idle')
  const [isIgnoreFutureItemsChecked, setIsIgnoreFutureItemsChecked] = useState(false)

  async function handleIgnoreItem() {
    setIgnoreItemStatus('loading')
    toast.dismiss(IGNORE_PRODUCT_TOASTS.ERROR)

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }

      if (!orderDetails) {
        throw Error('No order details')
      }

      const updateIgnoreStatusFetchRequests = [
        updateIgnoreLineItemStatus({ lineItemId: lineItem.id, orderId, shouldIgnore: true })
      ]
      if (isIgnoreFutureItemsChecked) {
        updateIgnoreStatusFetchRequests.push(
          updateChannelProductFulfilmentUsingExternalId({
            merchantId: merchantDetails.id,
            salesChannelId: orderDetails.salesChannelId,
            salesChannelProductId: lineItem.salesChannelProductDetails.productId,
            shouldFulfill: false
          })
        )
      }
      await Promise.all(updateIgnoreStatusFetchRequests)
      if (isLastLineItemToBeFulfilled) {
        createToast({ heading: 'Order removed successfully', type: 'success' })
        history.push(ROUTE_PATHS.ORDERS)
        return
      }
      await mutateOrderDetails()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'An error occurred while removing item',
        id: IGNORE_PRODUCT_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setIgnoreItemStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setIgnoreItemStatus('idle')
      setIsIgnoreFutureItemsChecked(false)
      toast.dismiss(IGNORE_PRODUCT_TOASTS.ERROR)
    }
    setIsOpen(isOpen)
  }

  const isLoading = ignoreItemStatus === 'loading'

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  const salesChannel = merchantDetails.salesChannels.find(
    (salesChannel) => salesChannel.id === orderDetails.salesChannelId
  )
  const isSalesChannelOrder = Boolean(salesChannel && salesChannel.platform !== SalesChannelPlatformEnum.Prodigi)

  return (
    <>
      <ConfirmationModal
        continueButton={{ text: 'Remove this item' }}
        closeButton={{ text: 'Cancel', theme: 'greyscale', variant: 'tertiary' }}
        closeOnEscape={!isLoading}
        closeOnInteractionOutside={!isLoading}
        open={isOpen}
        isLoading={isLoading}
        title="Remove this item?"
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleIgnoreItem}
      >
        <>
          <div>
            <span>Prodigi will not fulfil this item. </span>
            {salesChannel && <>This does not change the original order in {salesChannel.platform}.</>}
          </div>

          <div className="mt-8">
            <strong>{lineItem.salesChannelProductDetails.title}</strong>
          </div>

          <div>
            <span className="text-gray-600">{lineItem.salesChannelProductDetails.productType}</span>
          </div>

          {isSalesChannelOrder && (
            <div className="mt-8">
              <Checkbox
                checked={isIgnoreFutureItemsChecked}
                id="lineItemIgnoreCheckbox"
                label={`Also ignore all future orders for ${lineItem.salesChannelProductDetails.title}`}
                onChange={(event) => setIsIgnoreFutureItemsChecked(event.target.checked)}
              />
            </div>
          )}

          {isLastLineItemToBeFulfilled && (
            <div className="mt-8 text-magenta-800">
              If you remove this item, there will be no remaining items for Prodigi to fulfil and this order will be
              removed from your dashboard. This cannot be undone.
            </div>
          )}
        </>
      </ConfirmationModal>

      {isLoading && <OverlayPortal />}
    </>
  )
}
