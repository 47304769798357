// @flow
import type { MultiAssetBasketItem } from '../../types'

export const ADD_MULTIPLE_BASKET_ITEMS = 'ADD_MULTIPLE_BASKET_ITEMS'

export type AddMultipleBasketItemsType = {|
  type: 'ADD_MULTIPLE_BASKET_ITEMS',
  basketItems: MultiAssetBasketItem[],
  lastBasketItemId: number
|}

export function addMultipleBasketItems ({
  basketItems,
  lastBasketItemId
}: {|
  basketItems: MultiAssetBasketItem[],
  lastBasketItemId: number
|}): AddMultipleBasketItemsType {
  return {
    type: ADD_MULTIPLE_BASKET_ITEMS,
    basketItems,
    lastBasketItemId
  }
}
