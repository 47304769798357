// @noflow

function timeFormat (datetime:string) {
  return new Date(datetime).toLocaleTimeString(
    'en-gb', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    }
  )
}

export default timeFormat
