import { OrderDetailShipmentDisplayItem } from '.'
import { OrderDetailItemInterface } from '../../../interfaces'

export function OrderDetailUnallocatedDisplayItems({ items }: { items: OrderDetailItemInterface[] }) {
  return (
    <div className="border-l border-r bg-white p-6">
      <table className="block w-full md:table" data-test="shipment-display__items">
        <thead className="hidden text-sm md:table-header-group">
          <tr>
            <th></th>
            <th></th>
            <th className="px-2 pb-4 text-right">Cost</th>
            <th className="px-2 pb-4 text-right">Qty</th>
            <th className="px-2 pb-4 text-right">Total</th>
          </tr>
        </thead>
        <tbody className="block space-y-8 md:table-row-group">
          {items.map((item) => (
            <OrderDetailShipmentDisplayItem
              item={item}
              itemCost={item.itemCost}
              key={`item__${item.id}`}
              totalCost={item.totalCost}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
