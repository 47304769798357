import { calcPreviewBorder, calcPrintDimensionsFillScaleFactor } from '@prodigi-group/components-image-editor'

import { CropRectangleInterface } from '../../../hooks'
import { BorderUnitType, DimensionsInterface } from '../interfaces'

export function convertFillScaleToPrintSizeScale(
  fillScaleFactor: number,
  printDpi: number,
  borderUnit: BorderUnitType,
  borderWidth: number,
  printDimensionsInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  cropRectangle: CropRectangleInterface
): number {
  const scaledBorderInPx = calcPreviewBorder(printDpi, borderUnit, borderWidth, printDimensionsInPx, cropRectangle)
  const printDimensionScaleFactor = calcPrintDimensionsFillScaleFactor(
    printDimensionsInPx,
    artworkDimensionsInPx,
    scaledBorderInPx,
    0
  )

  return fillScaleFactor * printDimensionScaleFactor
}
