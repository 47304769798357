// @flow
import React, { Component } from 'react'
import type { ChildrenArray } from 'react'
import type { Style } from '../types'

type Props = {|
  children: ChildrenArray<*>,
  style?: Style
|}

class PanelTitle extends Component<Props> {
  getStyles (): any {
    return {
      title: {
        fontSize: 'var(--sizes-large)',
        paddingBottom: '0.75em',
        borderBottom: `1px solid var(--greyscale-light)`,
        marginBottom: '1.5em'
      }
    }
  }

  render (): React$Node {
    const { style, children } = this.props
    const styles = this.getStyles()
    return (
      <p style={{...styles.title, ...style}} >
        {children}
      </p>
    )
  }
}

export default PanelTitle
