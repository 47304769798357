// @flow

export function formatShipmentDescription (description: string): string {
  if (description === 'Royal Mail 24.0') {
    return 'Royal Mail 24'
  } else if (description === 'Royal Mail 48.0') {
    return 'Royal Mail 48'
  } else if (description === 'Royal Mail Royal Mail RM24') {
    return 'Royal Mail RM24'
  } else {
    return description
  }
}
