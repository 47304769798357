// @flow
import type { V4OrderCreationErrorResponse, V4OrderCreationResponse } from '../types'
import { V4_API_ACTION_OUTCOME } from '../data/v4ApiActionOutcome'
import { AWAITING_PAYMENT } from '../data/orderFilters'

export function isPaymentRequired (response: V4OrderCreationResponse | V4OrderCreationErrorResponse): boolean {
  return Boolean(
    response.outcome === V4_API_ACTION_OUTCOME.CREATED_WITH_ISSUES &&
    response.order &&
    response.order.status.stage === AWAITING_PAYMENT
  )
}
