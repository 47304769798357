import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface, ProductDataInterface } from '../interfaces'

export function useProduct(sku?: string, { config }: { config?: SWRConfiguration } = {}) {
  const url = sku ? `${process.env.REACT_APP_PRODUCT_API}/products/${sku}` : null

  const { data, error } = useSWR<ProductDataInterface, FetchErrorInterface<{ message?: string }>>(url, fetcher, config)

  return {
    product: data,
    isLoadingProduct: Boolean(url && !data && !error),
    productFetchError: error
  }
}
