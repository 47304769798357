import toast from 'react-hot-toast'
import { LoadingBarRef } from 'react-top-loading-bar'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useState, useEffect, ReactNode, useCallback } from 'react'

import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { UploadingImageInterface } from '../interfaces'
import { FetchErrorInterface } from '../../../interfaces'
import { IMAGE_LIBRARY_FULL_PAGE_TOASTS } from '../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { useImageLibraryImages, useSplitToggle } from '../../../hooks'
import { CONTENT_CONTAINER_ID } from '../../Navigation/Navigation.component'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { uploadFileToImageLibrary, getDefaultImageLibraryErrorMessage } from '../../../helpers'

export function DropzoneUploader({
  children,
  imageLibraryMode,
  loadingBarRef,
  onSetImageLibraryMode,
  onSetUploadingFiles
}: {
  children: ReactNode
  imageLibraryMode: ImageLibraryFullPageModeType
  loadingBarRef: React.RefObject<LoadingBarRef>
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
  onSetUploadingFiles: (files: React.SetStateAction<Record<string, UploadingImageInterface>>) => void
}) {
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false)
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  const onDropRejected = useCallback(
    async (rejectedRequests: FileRejection[]) => {
      function handleUploadError({
        errorCode,
        errorMessage = null
      }: {
        errorCode: string | number
        errorMessage?: string | null
      }) {
        createErrorToast({
          heading: 'Upload failed',
          id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR,
          errorMessage: errorMessage ?? '',
          errorCode: errorCode ?? '0'
        })
        loadingBarRef.current?.complete()
        setIsUploadingFiles(false)
      }

      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)

      rejectedRequests.forEach((request) => {
        const invalidFile = request.errors.some((error) => error.code === 'file-invalid-type')
        if (invalidFile) {
          handleUploadError({ errorCode: '0', errorMessage: 'Image must be a png or jpeg' })
        }
      })
    },
    [loadingBarRef]
  )

  const onDropAccepted = useCallback(
    async (files: File[]) => {
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
      loadingBarRef.current?.continuousStart()
      setIsUploadingFiles(true)
      document.getElementById(CONTENT_CONTAINER_ID)?.scrollTo(0, 0)

      const fileObject: Record<string, UploadingImageInterface> = {}
      files.forEach((file) => {
        fileObject[file.name] = { status: 'loading', file, name: file.name }
      })

      onSetUploadingFiles((filesState) => ({
        ...filesState,
        ...fileObject
      }))

      const errorCodes = []
      for (const [key, value] of Object.entries(fileObject)) {
        try {
          const response = await uploadFileToImageLibrary({
            file: value.file,
            shouldSaveToLibrary: true,
            type: 'artwork'
          })
          onSetUploadingFiles((filesState) => ({
            ...filesState,
            [key]: { ...filesState[key], status: 'success', imageLibraryImage: response }
          }))
        } catch (error) {
          const errorResponse = error as FetchErrorInterface<{ detail?: string }>
          const errorMessage = getDefaultImageLibraryErrorMessage(errorResponse) ?? ''
          const errorCode = errorResponse.status?.toString() ?? '0'
          onSetUploadingFiles((filesState) => ({
            ...filesState,
            [key]: { ...filesState[key], status: 'error', errorCode, errorMessage }
          }))
          errorCodes.push(errorCode)
        }
      }

      await mutateImageLibraryImages()

      if (errorCodes.length > 0) {
        createErrorToast({
          heading: 'Upload failed',
          id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR,
          errorMessage: 'One or more images failed to upload',
          errorCode: errorCodes.join(', ')
        })
      }

      onSetUploadingFiles((filesState) =>
        Object.fromEntries(Object.entries(filesState).filter(([, v]) => v.status === 'error'))
      )

      setIsUploadingFiles(false)
      loadingBarRef.current?.complete()
    },
    [loadingBarRef, mutateImageLibraryImages, onSetUploadingFiles]
  )

  const fileTypes: { [key: string]: string[] } = isPDFSplitOn
    ? { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'], 'application/pdf': ['.pdf'] }
    : { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }

  const { getRootProps, isDragActive } = useDropzone({
    accept: fileTypes,
    noClick: true,
    noDrag: isUploadingFiles,
    onDropAccepted,
    onDropRejected
  })

  useEffect(() => {
    if (isDragActive) {
      onSetImageLibraryMode('dropzone')
    } else {
      onSetImageLibraryMode('select')
    }
  }, [isDragActive, onSetImageLibraryMode])

  const isDropzoneMode = imageLibraryMode === 'dropzone'

  return (
    <>
      <div {...getRootProps()} className="flex min-h-[50vh] flex-col pb-12" tabIndex={-1}>
        {isDropzoneMode && (
          <div className="sticky top-[300px] z-10 h-50vh w-full items-center justify-center text-center">
            <div className="flex h-full w-full flex-col items-center justify-center border-4 border-dashed border-gray-500 text-center">
              <ArrowUpTrayIcon className="mb-16 h-20 w-20 text-gray-200" />
              <p>Drag images to upload to your library</p>
            </div>
          </div>
        )}

        <div className={isDropzoneMode ? 'z-0 opacity-0' : ''}>{children}</div>
      </div>
      {isUploadingFiles && <OverlayPortal />}
    </>
  )
}
