// @flow
import React from 'react'
import Button from '../../../../components/Button'
import ProductionCountries from '../ProductionCountries'
import AvailableOptions from './AvailableOptions'
import type { CatalogueItem, Event } from '../../../../types'
import styles from './SelectProduct.module.css'
import Price from './Price'

// $FlowFixMe: TypeScript file
import ProductDetailsModalButton from './ProductDetailsModalButton'
// $FlowFixMe: TypeScript file
import { SaveItemToggle } from './SaveItemToggle.component'

type Props = {|
  item: CatalogueItem,
  fetchingPrices: boolean,
  onSelectProduct: (e: Event) => void
|}

export default function Product(props: Props): React$Node {
  const { sku, description, shortcode, attributes, category, productionCountries } = props.item

  return (
    <div data-test="product-search-result-item" className={styles.product}>
      <div className={styles.productDescriptionBox}>
        <div data-test="product-description" className={styles.productDescription}>
          <div className="tailwind">
            <span className="text-lg font-medium">{description}</span>
            <SaveItemToggle sku={sku} />
          </div>
        </div>
        <p className={styles.productDescriptionSubtitle}>
          {shortcode}
          <span data-test="product-sku" className={styles.sku}>
            {shortcode && ' ('}
            {sku}
            {shortcode && ')'}
          </span>
        </p>
        <AvailableOptions attributes={attributes} category={category} />
        <ProductionCountries productionCountries={productionCountries} />
      </div>

      <div className={styles.actions} data-test="product-price-container">
        <Price
          fetchingPrices={props.fetchingPrices}
          price={props.item.price}
          currency={props.item.currency}
          basePriceFrom={props.item.basePriceFrom}
          sku={sku}
          basePriceCurrency={props.item.currency}
        />
        <div className={styles.actionButtons}>
          <Button primary dataTest={`add-to-basket-${sku}`} onClick={props.onSelectProduct} value={sku}>
            Add to basket
          </Button>
          <ProductDetailsModalButton description={description} sku={sku} showCountryPicker={false} />
        </div>
      </div>
    </div>
  )
}
