import { motion } from 'framer-motion'
import { FormEvent, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Button from '../../Button'
import TextField from '../../TextField'
import { createToast } from '../../Toast'
import { fetcher } from '../../../helpers'
import { StatusEnum } from '../../../enums'
import { mapErrorResponse } from '../helpers'
import { FetchErrorInterface } from '../../../interfaces'
import { PASSWORD_MIN_LENGTH, QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import ToggleHidePasswordButton from '../../../../components/inputs/ToggleHidePasswordButton'

export function LeftPanel() {
  const [changePasswordStatus, setChangePasswordStatus] = useState(StatusEnum.Idle)
  const [passwordFormData, setPasswordFormData] = useState({
    password: { error: '', showPassword: false, value: '' },
    confirmPassword: { error: '', showPassword: false, value: '' }
  })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [hasChangePasswordLinkExpired, setHasChangePasswordLinkExpired] = useState(false)

  const history = useHistory()
  const { search } = useLocation()

  async function onChangePassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    setErrorMessage(null)
    setPasswordFormData((existingFormData) => ({
      ...existingFormData,
      password: {
        ...existingFormData.password,
        error: ''
      },
      confirmPassword: {
        ...existingFormData.confirmPassword,
        error: ''
      }
    }))

    if (passwordFormData.confirmPassword.value !== passwordFormData.password.value) {
      setPasswordFormData((existingFormData) => ({
        ...existingFormData,
        confirmPassword: {
          ...existingFormData.confirmPassword,
          error: 'Passwords do not match'
        }
      }))
      return
    }

    setChangePasswordStatus(StatusEnum.Loading)
    window.analytics.track('Password Reset')

    try {
      const searchParams = new URLSearchParams(search)
      const email = searchParams.get(QUERY_PARAMS.PASSWORD_CHANGE.EMAIL) || ''
      const token = searchParams.get(QUERY_PARAMS.PASSWORD_CHANGE.TOKEN) || ''

      await fetcher(`${process.env.REACT_APP_ENDPOINT}/api/Account/ResetPassword`, {
        body: `email=${encodeURIComponent(email)}&resetToken=${encodeURIComponent(
          token
        )}&newPassword=${encodeURIComponent(passwordFormData.password.value)}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST'
      })
      createToast({ heading: 'Password changed successfully', type: 'success', duration: 4000 })
      setChangePasswordStatus(StatusEnum.Success)
      history.push(ROUTE_PATHS.LOGIN)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ statusTxt?: string; message?: string }>
      const { expired } = mapErrorResponse(errorResponse)
      setHasChangePasswordLinkExpired(expired)
      setErrorMessage('An error occurred. Please try again later.')
      setChangePasswordStatus(StatusEnum.Error)
    }
  }

  return (
    <div className="relative mx-auto flex min-h-screen w-full flex-col p-14 pt-10 lg:w-1/2 lg:p-36 lg:pt-16">
      <a href="https://www.prodigi.com" title="Prodigi" className="mb-16 block w-fit">
        <img
          src="/img/logo/prodigi-logo-dark.svg"
          className="h-[47] w-[120px] lg:h-[60] lg:w-[150px]"
          alt="Prodigi logo"
        />
      </a>

      <h2 className="m-0 mb-2 text-2xl leading-10 tracking-tight text-gray-900">Change password</h2>

      <span className="mt-6  text-red-500">
        <motion.span
          animate={changePasswordStatus === StatusEnum.Error ? { opacity: 1 } : { opacity: 0 }}
          initial={{ opacity: 0 }}
        >
          {hasChangePasswordLinkExpired && (
            <div className="text-red-500">
              Your reset link has expired. Please request a new one from the{' '}
              <Link to={ROUTE_PATHS.PASSWORD_RESET} className="underline">
                Forgotten Password
              </Link>{' '}
              page.
            </div>
          )}
          {!hasChangePasswordLinkExpired && <>{errorMessage} &nbsp;</>}
        </motion.span>
      </span>

      <form onSubmit={onChangePassword} id="change-password-form" className="relative mt-6 flex flex-col">
        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="password">
            Password
          </label>
        </span>
        <span className="relative">
          <TextField
            value={passwordFormData.password.value}
            onChange={(e) => {
              e.persist()
              setPasswordFormData((existingFormData) => ({
                ...existingFormData,
                password: {
                  ...existingFormData.password,
                  value: e.target.value
                }
              }))
            }}
            id="password"
            name="password"
            minLength={PASSWORD_MIN_LENGTH}
            type={passwordFormData.password.showPassword ? 'text' : 'password'}
            required
          />
          <ToggleHidePasswordButton
            className="mt-2"
            onShowPasswordChange={() => {
              setPasswordFormData((existingFormData) => ({
                ...existingFormData,
                password: {
                  ...existingFormData.password,
                  showPassword: !existingFormData.password.showPassword
                }
              }))
            }}
            showPassword={passwordFormData.password.showPassword}
          />
        </span>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span
            animate={passwordFormData.password.error ? { opacity: 1 } : { opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            {passwordFormData.password.error}&nbsp;
          </motion.span>
        </span>

        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="confirmPassword">
            Confirm password
          </label>
        </span>
        <span className="relative">
          <TextField
            className="pr-12"
            value={passwordFormData?.confirmPassword?.value}
            onChange={(e) => {
              e.persist()
              setPasswordFormData((existingFormData) => ({
                ...existingFormData,
                confirmPassword: {
                  ...existingFormData.confirmPassword,
                  value: e.target.value
                }
              }))
            }}
            id="confirmPassword"
            name="confirmPassword"
            minLength={PASSWORD_MIN_LENGTH}
            type={passwordFormData.confirmPassword.showPassword ? 'text' : 'password'}
            required
          />
          <ToggleHidePasswordButton
            className="mt-2"
            onShowPasswordChange={() => {
              setPasswordFormData((existingFormData) => ({
                ...existingFormData,
                confirmPassword: {
                  ...existingFormData.confirmPassword,
                  showPassword: !existingFormData.confirmPassword.showPassword
                }
              }))
            }}
            showPassword={passwordFormData.confirmPassword.showPassword}
          />
        </span>
        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span
            animate={passwordFormData.confirmPassword.error ? { opacity: 1 } : { opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            {passwordFormData.confirmPassword.error}&nbsp;
          </motion.span>
        </span>

        <div className="mt-1">
          Remembered your password?{' '}
          <Link className="text-purple-500" to={ROUTE_PATHS.LOGIN}>
            Sign in
          </Link>
        </div>

        <Button
          type="submit"
          isLoading={changePasswordStatus === StatusEnum.Loading}
          disabled={passwordFormData.password.value === '' || passwordFormData.confirmPassword.value === ''}
          className="mt-10"
        >
          Save new password
        </Button>
      </form>
    </div>
  )
}
