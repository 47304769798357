// @flow
import { RSAA } from 'redux-api-middleware'
import * as ct from '../data/constants'
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../types'
import { selectEnvironmentName } from '../selectors/appSettings'
import { selectMaybeUserId } from '../selectors/user'

export const LOG_LE = 'LOG_LE'
export const LOG_SUCCESS = 'LOG_SUCCESS'
export const LOG_ERROR = 'LOG__ERROR'
export const LOG_LEVEL = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning'
}

export function logLe (msg: string, props: any = {}, level: string = LOG_LEVEL.INFO): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const token = process.env.REACT_APP_LOGENTRIES_TOKEN
    const endpoint = `https://webhook.logentries.com/noformat/logs/${token || ''}`
    const environmentName = selectEnvironmentName()
    const userId = selectMaybeUserId(getState())
    const body = { [level]: { msg: msg, ...props } }

    if (userId) {
      body[level].userId = userId
    }

    // prevent logging from localhost in test mode
    if (environmentName === ct.TEST && !window.location.origin.includes('localhost')) {
      submit(body)
    } else {
      if (environmentName === ct.LIVE) {
        submit({ LIVE: body })
      } else if (environmentName === ct.SANDBOX) {
        submit({ SANDBOX: body })
      }
    }

    function submit (body) {
      dispatch({
        [RSAA]: {
          endpoint,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body),
          types: [
            {
              type: LOG_LE,
              payload: body
            },
            LOG_SUCCESS,
            LOG_ERROR
          ]
        }
      })
    }
  }
}
