import toast from 'react-hot-toast'
import { ReactNode, useEffect, useState } from 'react'

import {
  MerchantPreferencesErrorInterface,
  MerchantPreferencesInterface,
  useMerchantPreferences,
  useMerchantService,
  useUser
} from '../../../../../hooks'
import Checkbox from '../../../../Checkbox'
import { updateUserSettings } from '../../../helpers'
import {
  SETTINGS_UPDATE_ERROR_TOAST_ID,
  SETTINGS_UPDATE_SUCCESS_TOAST_ID
} from '../../../../../components/Settings/constants'
import Button from '../../../../../components/Button'
import Skeleton from '../../../../..//components/Skeleton'
import { SettingsPanel } from '../../SettingsPanel.component'
import { updateMerchantPreferences } from '../../../../../helpers'
import OverlayPortal from '../../../../../components/OverlayPortal'
import { createErrorToast, createToast } from '../../../../../components/Toast'
import { FetchErrorInterface, StatusType, UserInterface } from '../../../../../interfaces'

export function EmailPreferences() {
  const { merchantPreferences, isLoadingMerchantPreferences } = useMerchantPreferences()
  const { user, isLoading: isUserLoading } = useUser()

  useEffect(() => {
    return () => {
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
  }, [])

  if (isUserLoading || isLoadingMerchantPreferences || !merchantPreferences || !user) {
    return <LoadingState />
  }

  return <LoadedEmailPreferences merchantPreferences={merchantPreferences} user={user} />
}

function LoadingState() {
  return (
    <PanelContainer>
      <div className="flex w-full flex-col">
        <div className="flex flex-col -space-y-px">
          <div className="space-x-5 pb-6">
            <Skeleton className="h-[21px] w-full p-0 sm:w-1/2" />
          </div>

          <div className="space-x-5 pb-6">
            <Skeleton className="h-[42px] w-full p-0 sm:w-1/2" />
          </div>

          <div className="space-x-5 pb-6">
            <Skeleton className="h-[42px] w-full p-0 sm:w-1/2" />
          </div>
        </div>
      </div>

      <Button className="mt-8" disabled={true}>
        Save
      </Button>
    </PanelContainer>
  )
}

interface EmailPreferences {
  ordersThatRequireAttention: boolean
  orderEmails: boolean
}
export function LoadedEmailPreferences({
  merchantPreferences,
  user
}: {
  merchantPreferences: MerchantPreferencesInterface
  user: UserInterface
}) {
  const { merchantDetails } = useMerchantService()
  const { mutateMerchantPreferences } = useMerchantPreferences()
  const { mutateUser } = useUser()

  const [selectedEmails, setSelectedEmails] = useState<EmailPreferences>({
    ordersThatRequireAttention: merchantPreferences.emails.ordersThatRequireAttention,
    orderEmails: user.settings.sendEmailNotifications
  })
  const [emailUpdateProgress, setEmailUpdateProgress] = useState<StatusType>('idle')

  async function handleUpdateMerchantPreferences() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)

    try {
      if (!merchantPreferences || !user) {
        throw Error('No merchant preferences')
      }
      setEmailUpdateProgress('loading')

      const newUserSettings: UserInterface = {
        ...user,
        settings: {
          ...user.settings,
          sendEmailNotifications: selectedEmails.orderEmails ?? false
        }
      }

      await Promise.all([
        updateMerchantPreferences(
          {
            ...merchantPreferences,
            emails: {
              ordersThatRequireAttention: selectedEmails.ordersThatRequireAttention ?? true
            }
          },
          merchantDetails?.id
        ),
        updateUserSettings(newUserSettings)
      ])

      await mutateMerchantPreferences()
      mutateUser(newUserSettings)
      setEmailUpdateProgress('success')
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<MerchantPreferencesErrorInterface>

      createErrorToast({
        errorCode: errorResponse.responseBodyJson?.traceParent ?? errorResponse?.status ?? '0',
        heading: 'An error occurred while updating email preferences',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setEmailUpdateProgress('error')
    }
  }

  return (
    <PanelContainer>
      <div className="flex min-h-full w-fit flex-col">
        <EmailPreference
          checked={selectedEmails.orderEmails}
          disabled={emailUpdateProgress === 'loading'}
          id="orderEmails"
          title="Order notifications"
          subtitle="New order and shipment confirmations."
          onSelectionUpdate={(selection) =>
            setSelectedEmails({
              ...selectedEmails,
              orderEmails: selection
            })
          }
        />

        <EmailPreference
          checked={selectedEmails.ordersThatRequireAttention}
          disabled={emailUpdateProgress === 'loading'}
          id="requiresAttention"
          subtitle="Daily summary of any unfulfilled orders that are awaiting further information or have been paused indefinitely."
          title="Orders that require my attention"
          onSelectionUpdate={(selection) =>
            setSelectedEmails({
              ...selectedEmails,
              ordersThatRequireAttention: selection
            })
          }
        />

        <Button
          className="mt-8 w-fit"
          isLoading={emailUpdateProgress === 'loading'}
          type="submit"
          onClick={(event) => {
            event.preventDefault()
            handleUpdateMerchantPreferences()
          }}
        >
          Save
        </Button>
      </div>

      {emailUpdateProgress === 'loading' && <OverlayPortal className="cursor-wait" />}
    </PanelContainer>
  )
}

function PanelContainer({ children }: { children?: ReactNode }) {
  return (
    <SettingsPanel
      className="border-x border-b"
      subtitle={
        <>
          <span className="mt-4 max-w-lg text-gray-600">Never miss an order.</span>
        </>
      }
      title={<h2>Email notifications</h2>}
    >
      {children}
    </SettingsPanel>
  )
}

function EmailPreference({
  checked,
  disabled,
  id,
  subtitle,
  title,
  onSelectionUpdate
}: {
  checked: boolean
  disabled: boolean
  id: string
  subtitle?: string
  title: string
  onSelectionUpdate: (selection: boolean) => void
}) {
  return (
    <div className="mb-6 flex flex-row gap-x-4">
      <Checkbox
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={(event) => onSelectionUpdate(event.target.checked)}
      />
      <div className="flex flex-col sm:max-w-[75%]">
        <label htmlFor={id} className="mb-1">
          {title}
        </label>
        {subtitle && <span className="text-sm text-gray-600">{subtitle}</span>}
      </div>
    </div>
  )
}
