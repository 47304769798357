import { useState } from 'react'

import Button from '../../Button'
import ProductDetailsModal from '../../ProductDetailsModal'
import { ProductDetailSuccessResponseInterface, useUser } from '../../../hooks'

interface ProductDetailsModalButtonInterface {
  productDetails: ProductDetailSuccessResponseInterface
}

export function ProductDetailsModalButton({ productDetails }: ProductDetailsModalButtonInterface) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const { user } = useUser()

  if (!user) {
    throw Error('No user')
  }

  const countryCode = user.company.countryCode
  const currencyCode = user.company.currencyCode

  return (
    <>
      <Button size="sm" variant="text" onClick={() => setModalIsOpen(true)}>
        See prices & shipping
      </Button>
      <ProductDetailsModal
        actions={<></>}
        countryCode={countryCode}
        currencyCode={currencyCode}
        description={productDetails.product.description}
        open={modalIsOpen}
        showCountryPicker={true}
        sku={productDetails.product.sku}
        setOpen={setModalIsOpen}
      />
    </>
  )
}
