// @flow
import {
  RECEIVE_ORDER_DETAIL,
  REQUEST_ORDER_DETAIL,
  REQUEST_ORDER_SEARCH,
  RECEIVE_ORDER_SEARCH,
  ORDER_SEARCH_ERROR,
  FETCH_ORDER_DETAILS_ERROR,
  RESET_ORDER_LIST_STATE,
  UPDATE_SKIP_VALUE,
  UPDATE_LIMIT_VALUE,
  UPDATE_CURRENT_VIEW,
  SAVE_ORDER_LIST_PAGE,
  SET_ORDER_FILTERS,
  REQUEST_ORDER_DETAIL_V3,
  RECEIVE_ORDER_DETAIL_V3,
  V3_FETCH_ORDER_DETAILS_ERROR,
  RESET_ORDERS_RSAA_STATUS,
  CHANGE_ORDER_FILTER_SCOPE,
  CHANGE_ORDER_FILTER_QUERY,
  DISCARD_SAVED_ORDER_LIST_PAGES,
  GET_ORDER_ACTIONS_SUCCESS,
  GET_ORDER_DETAILS_V4_SUCCESS,
  SEARCH_ORDERS_V4_SUCCESS,
  CREATE_V4_ORDER_SUCCESS
} from '../actions/orders'
import type {
  CreateV4OrderSuccess,
  GetOrderActionsSuccess,
  GetOrderDetailsV4Success,
  SearchOrdersV4Success
} from '../actions/types'
import { ORDERS_PER_PAGE } from '../data/constants'
import { ORDERS } from '../data/orderFilters'
import type { OrdersState, Action, OrderFilters, Dictionary, V4Order } from '../types'
import { ACTION_CAPABILITY } from '../data/actionCapability'
import { convertV4OrderIdToV3OrderId } from '../helpers/orderId'

const BLANK_ORDER_FILTERS: OrderFilters = {
  query: '',
  scope: ORDERS,
  limit: ORDERS_PER_PAGE,
  skip: 0
}

const defaultState: OrdersState = {
  currentView: [],
  error: false,
  errorMessage: '',
  filters: BLANK_ORDER_FILTERS,
  errorStatusCode: 0,
  isFetching: false,
  items: {},
  orderId: null,
  pages: {},
  orderActions: {},
  orderDetailsV4: {}
}

const defaultErrorMessage = 'Something went wrong.'
function orders (state: OrdersState = defaultState, action: Action): OrdersState {
  switch (action.type) {
    case SET_ORDER_FILTERS:
      return {
        ...state,
        filters: {
          limit: action.limit,
          skip: action.skip,
          scope: action.scope,
          query: action.query
        }
      }

    case REQUEST_ORDER_SEARCH: {
      return {
        ...state,
        isFetching: true
      }
    }

    case SEARCH_ORDERS_V4_SUCCESS: {
      const searchOrdersV4SuccessAction: SearchOrdersV4Success = action

      const ordersById = searchOrdersV4SuccessAction.payload.orders.reduce(
        (ordersAcc: Dictionary<V4Order>, order: V4Order): Dictionary<V4Order> => {
          return {
            ...ordersAcc,
            [order.id]: order
          }
        }, {}
      )

      return {
        ...state,
        orderDetailsV4: {
          ...state.orderDetailsV4,
          ...ordersById
        }
      }
    }

    case RECEIVE_ORDER_SEARCH: {
      const itemsCopy = JSON.parse(JSON.stringify(state.items))
      action.payload.data.orders.forEach(order => {
        itemsCopy[order.id] = Object.assign({}, itemsCopy[order.id], order)
      })

      return {
        ...state,
        isFetching: false,
        items: itemsCopy,
        currentView: Object.keys(itemsCopy)
      }
    }

    case CHANGE_ORDER_FILTER_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.filterQuery
        }
      }
    }

    case V3_FETCH_ORDER_DETAILS_ERROR:
    case ORDER_SEARCH_ERROR: {
      return {
        ...state,
        isFetching: false
      }
    }

    case FETCH_ORDER_DETAILS_ERROR: {
      let message = defaultErrorMessage
      if (action.payload && action.payload && action.payload.statusTxt) {
        message = action.payload.statusTxt
      }
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: message,
        errorStatusCode: action.payload.statusCode
      }
    }

    case RESET_ORDER_LIST_STATE: {
      return {
        ...state,
        items: {},
        pages: {},
        currentView: [],
        filters: BLANK_ORDER_FILTERS
      }
    }

    case REQUEST_ORDER_DETAIL: {
      return {
        ...state,
        orderId: action.orderId,
        isFetching: true
      }
    }

    case RECEIVE_ORDER_DETAIL: {
      const { id } = action.payload.data

      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          [id]: Object.assign({}, state.items[id], action.payload.data)
        }
      }
    }

    case REQUEST_ORDER_DETAIL_V3: {
      return {
        ...state,
        orderId: action.orderId,
        isFetching: true
      }
    }

    case RECEIVE_ORDER_DETAIL_V3: {
      let id = 0

      if (action.payload.data && action.payload.data.id) {
        id = action.payload.data.id
        const itemAttrAndSkus = action.payload.data.images.map(image => {
          return {
            id: image.id,
            attributes: image.attributes,
            sku: image.sku,
            price: image.price / image.copies,
            thumbnailUrl: image.thumbnailUrl
          }
        })

        let orderItemsFromState = state.items[id].items
          ? JSON.parse(JSON.stringify(state.items[id].items))
          : {}
        itemAttrAndSkus.forEach(itemFromV3Response => {
          let itemToUpdate = orderItemsFromState.find(itemFromState => itemFromState.id === itemFromV3Response.id)
          if (itemToUpdate && Object.keys(itemToUpdate).length > 0) {
            itemToUpdate.attributes = itemFromV3Response.attributes
            itemToUpdate.sku = itemFromV3Response.sku
            itemToUpdate.price = itemFromV3Response.price

            if (itemFromV3Response.thumbnailUrl) {
              itemToUpdate.originalImageUrl = itemFromV3Response.thumbnailUrl
            }
          }
        })

        return {
          ...state,
          isFetching: false,
          items: {
            ...state.items,
            [id]: {
              ...state.items[id],
              canCancel: action.payload.data.canCancel,
              canUpdateShipping: action.payload.data.canUpdateShipping,
              merchantOrderId: action.payload.data.merchantOrderId,
              items: orderItemsFromState,
              customer: {
                ...state.items[id].customer,
                mobileTelephone: action.payload.data.mobileTelephone
              },
              packingSlipUrl: action.payload.data.packingSlipUrl
            }
          }
        }
      } else {
        return {
          ...state,
          isFetching: false
        }
      }
    }

    case SAVE_ORDER_LIST_PAGE: {
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.pageNumber]: action.ids
        }
      }
    }

    case UPDATE_SKIP_VALUE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          skip: action.skip
        }
      }
    }

    case UPDATE_LIMIT_VALUE: {
      return { ...state, filters: { ...state.filters, limit: action.limit } }
    }

    case UPDATE_CURRENT_VIEW: {
      return { ...state, currentView: action.currentView }
    }

    case RESET_ORDERS_RSAA_STATUS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: ''
      }
    }

    case CHANGE_ORDER_FILTER_SCOPE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          scope: action.scope
        }
      }
    }

    case DISCARD_SAVED_ORDER_LIST_PAGES: {
      return {
        ...state,
        pages: {},
        currentView: []
      }
    }

    case GET_ORDER_ACTIONS_SUCCESS: {
      const currentAction: GetOrderActionsSuccess = action
      const {
        cancel,
        changeMetaData,
        changeRecipientDetails,
        changeShippingMethod
      } = action.payload

      return {
        ...state,
        orderActions: {
          ...state.orderActions,
          [currentAction.meta.orderId]: {
            canCancel: cancel.isAvailable !== ACTION_CAPABILITY.NO,
            canChangeMetaData: changeMetaData.isAvailable !== ACTION_CAPABILITY.NO,
            canChangeRecipientDetails: changeRecipientDetails.isAvailable !== ACTION_CAPABILITY.NO,
            canChangeShippingMethod: changeShippingMethod.isAvailable !== ACTION_CAPABILITY.NO
          }
        }
      }
    }

    case CREATE_V4_ORDER_SUCCESS: {
      const currentAction: CreateV4OrderSuccess = action
      const orderIdWithoutPrefix = convertV4OrderIdToV3OrderId(currentAction.payload.order.id)

      return {
        ...state,
        orderDetailsV4: {
          ...state.orderDetailsV4,
          [orderIdWithoutPrefix]: {
            ...currentAction.payload.order,
            id: orderIdWithoutPrefix
          }
        }
      }
    }

    case GET_ORDER_DETAILS_V4_SUCCESS: {
      const currentAction: GetOrderDetailsV4Success = action

      return {
        ...state,
        orderDetailsV4: {
          ...state.orderDetailsV4,
          [currentAction.meta.orderId]: currentAction.payload.order
        }
      }
    }

    default: {
      return state
    }
  }
}

export default orders
