import { LineItemEditorDataType } from '../components'
import { fetcher, mapV3AttributesToV4 } from '../../../helpers'

export function saveLineItemData({
  lineItemId,
  newLineItemData,
  orderId
}: {
  lineItemId: string
  newLineItemData: LineItemEditorDataType
  orderId: string
}) {
  return fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/items/${lineItemId}`, {
    body: JSON.stringify({
      prodigiSkuDetails: {
        skuName: newLineItemData?.selectedProductData?.sku,
        attributes: newLineItemData?.selectedProductData?.attributes
          ? mapV3AttributesToV4(newLineItemData?.selectedProductData?.attributes)
          : null,
        metadata: newLineItemData?.selectedProductData?.metaData,
        assets: newLineItemData?.assetRequirementData,
        productType: newLineItemData?.selectedProductData?.productType
      },
      printAreasArtwork: newLineItemData?.artworkData
        ? Object.entries(newLineItemData.artworkData).reduce(
            (printAreasArtworkAcc, [printAreaName, printAreasArtworkData]) => {
              return {
                ...printAreasArtworkAcc,
                [printAreaName]: {
                  printReadyUrl: printAreasArtworkData?.printImageUrl,
                  additionalUiDetailVersion: '1.0',
                  additionalUiDetail: printAreasArtworkData
                }
              }
            },
            {}
          )
        : null
    }),
    method: 'PUT'
  })
}
