// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Country, AppState } from '../../../types'
import countryFormat from '../../../helpers/countryFormat'
import type { ComponentType } from 'react'

type OwnProps = {|
  productionCountries: string[],
  showOnSummaryPage?: ?boolean,
  productionCountryCode?: string
|}

type StateProps = {|
  countries: Country[]
|}

type Props = {|
  ...OwnProps,
  ...StateProps
|}

class ProductionCountries extends Component<Props> {
  getStyles () {
    return {
      flag: {
        display: 'inline-block'
      },
      flagsContainer: {
        display: 'flex',
        alignItems: 'center'
      },
      title: {
        marginRight: '0.4em',
        fontSize: 'var(--sizes-small)'
      },
      titleOnSummaryPage: {
        marginRight: '0.4em'
      }
    }
  }

  render (): React$Node {
    const {
      productionCountryCode,
      showOnSummaryPage,
      productionCountries,
      countries } = this.props
    const styles = this.getStyles()
    const confirmedProductionCountry = productionCountryCode ? countries.find(x => x.isoCode === productionCountryCode) : null
    let confirmedProductionCountryFlag
    if (productionCountryCode && confirmedProductionCountry) {
      const countryName = countryFormat(confirmedProductionCountry.name)
      confirmedProductionCountryFlag = (
        <img
          data-test='production-country-flag'
          style={styles.flag}
          src={`/img/flags-24/${productionCountryCode}.png`}
          title={countryName}
          alt={countryName} />
      )
    }

    return (
      <div>
        <div style={styles.flagsContainer}>
          {showOnSummaryPage
            ? <span style={styles.titleOnSummaryPage} >
              {productionCountries.length === 1 || productionCountryCode
                ? 'Ships from: '
                : 'Ships from (tbc): '}
            </span>
            : <span style={styles.title} >Ships from: </span>}
          {productionCountryCode
            ? confirmedProductionCountryFlag
            : productionCountries.map((country, idx) => {
              const currentCountry = countries.find(x => x.isoCode === country)
              let currentCountryName = ''
              if (currentCountry) {
                currentCountryName = countryFormat(currentCountry.name)
              }

              return (
                <img
                  data-test='country-flag'
                  style={styles.flag}
                  key={idx}
                  src={`/img/flags-24/${country}.png`}
                  title={currentCountryName}
                  alt={currentCountryName} />
              )
            })}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state: AppState): StateProps {
  return {
    countries: state.manualOrderForm.countries
  }
}

export default (connect(mapStateToProps)(ProductionCountries): ComponentType<OwnProps>)
