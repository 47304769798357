// @flow
import { createSelector } from 'reselect'
import { mapRsaaStateToProps } from '../helpers/mapRsaaStateToProps'
import { returnSecondArg, identity } from './helpers'
import type { InFlightRequestControllers, AppState, RsaaStatus, AbortController } from '../types'
import { IDLE } from '../data/rsaa'

export function selectRsaaStatus (state: AppState, actionId: string): RsaaStatus {
  return state.rsaa[actionId]
    ? state.rsaa[actionId]
    : {
      status: IDLE,
      statusMessage: '',
      ignore: false,
      statusCode: null,
      errorPayload: null
    }
}

export function selectInFlightRequestControllers (state: AppState): InFlightRequestControllers {
  return state.rsaa.inFlightRequestControllers
}

export function selectInFlightRequestController (controllers: InFlightRequestControllers, actionType: string): ?AbortController {
  return controllers[actionType]
}

export const getRsaaStatus: any = createSelector(selectRsaaStatus, identity)
export const getRsaaStatusProps: any = createSelector(selectRsaaStatus, mapRsaaStateToProps)
export const getInFlightRequestController: any = createSelector(selectInFlightRequestControllers, returnSecondArg, selectInFlightRequestController)
