import { useState } from 'react'

import { AppliedTags } from '.'
import Button from '../../Button'
import FormItem from '../../FormItem'
import TextField from '../../TextField'

export function Tags({
  isLoading = false,
  tags,
  onUpdate
}: {
  isLoading?: boolean
  tags: string[]
  onUpdate: (tags: string[]) => void
}) {
  const [newTag, setNewTag] = useState<string>('')

  function onAddNewTags() {
    const newList = [...tags]
    const newTags = newTag.split(',')

    newTags.forEach((tag) => {
      const formattedTag = tag.trim()
      const tagAlreadyExists = newList?.map((a) => a.toLowerCase()).includes(formattedTag.toLowerCase())

      if (!tagAlreadyExists && formattedTag !== '') {
        newList?.push(formattedTag)
      }
    })

    setNewTag('')
    onUpdate(newList)
  }

  function onRemoveTag(tag: string) {
    const newList = [...tags]
    const updated = newList?.filter((value) => value !== tag)
    onUpdate(updated)
  }

  return (
    <>
      <form
        className="flex w-full flex-row justify-between gap-4"
        onSubmit={(e) => {
          e.preventDefault()
          onAddNewTags()
        }}
      >
        <FormItem
          className="grow"
          key="image-details-add_tag"
          inputField={<TextField onChange={(event) => setNewTag(event.target.value)} value={newTag} />}
          labelText="Tags"
        />
        <Button
          className="mb-2 flex h-16 self-end"
          variant="secondary"
          disabled={!newTag}
          isLoading={isLoading}
          type="submit"
        >
          Add
        </Button>
      </form>
      <span className="text-sm text-gray-600">Comma-separated lists will be split into separate tags</span>
      <div className="flex-grow overflow-auto">
        <AppliedTags tags={tags} onRemoveTag={onRemoveTag} />
      </div>
    </>
  )
}
