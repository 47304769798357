// @flow
import type { Thunk, ThunkAsync, Dictionary, DispatchFunc, GetStateFunc, CatalogueItem } from '../../types'

import { reorderAttributes } from '../../helpers/reorderAttributes'
import { saveUnfinishedOrder } from './saveUnfinishedOrder'
import { fetchPreviewTemplate } from './fetchPreviewTemplate'
import { setNewBasketItemId } from './setNewBasketItemId'
import { fetchPrices, getV4ProductDetails, GET_V4_PRODUCT_DETAILS_SUCCESS, searchProductsBySkuList } from '../catalogue'
import { imageUrlUpdatedNoUpload } from '../../actions/manualOrderForm/uploadArtwork'
import getLabImage from '../../helpers/getLabImage'
import { selectAttribute } from './manualOrderForm'
import { NONE_ATTRIBUTE, FRAME_COLOUR } from '../../data/productAttributes'
import { entries } from '../../helpers/dictionary'
import { isDefaultArtworkRequired, selectDeliveryCountry } from '../../selectors/manualOrderForm'
import { initPrintAreasForBasketItem } from './initPrintAreasForBasketItem'
import { PRINT_AREA_NAME } from '../../data'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue'
import { BASKET_ITEM_LOGGER_ID } from './fetchCatalogueDataForAllBasketItems'

export const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM'

export function addBasketItem(sku: string, defaultSelectedAttributes?: Dictionary<string> | null): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItemId = (await dispatch(setNewBasketItemId())).id
    const products = getState().catalogue.products
    let product = products[sku]

    if (!product) {
      const selectedCountry = selectDeliveryCountry(getState())
      await dispatch(searchProductsBySkuList([sku], selectedCountry, BASKET_ITEM_LOGGER_ID))
      const updatedProducts = getState().catalogue.products
      product = updatedProducts[sku]
    }

    dispatch({
      type: ADD_BASKET_ITEM,
      sku,
      appVersion: parseInt(process.env.REACT_APP_VERSION, 10),
      id: basketItemId
    })

    if (defaultSelectedAttributes) {
      // Ensures costed attributes for example from saved items modal are set
      entries(defaultSelectedAttributes ?? {}).forEach(([name, value]) => {
        dispatch(selectAttribute(name, value, basketItemId))
      })
    }

    const selectedAttributes = dispatch(selectDefaultAttributes(basketItemId, product, defaultSelectedAttributes))

    await dispatch(fetchPrices([sku]))

    const v4ProductDetails = getV4ProductDetailsBySku(getState(), sku)
    if (v4ProductDetails) {
      await dispatch(initPrintAreasForBasketItem(basketItemId, v4ProductDetails.printAreas))
    } else {
      const v4ProductDetailsResponseResult = await dispatch(getV4ProductDetails(sku))

      if (v4ProductDetailsResponseResult.type === GET_V4_PRODUCT_DETAILS_SUCCESS) {
        await dispatch(
          initPrintAreasForBasketItem(basketItemId, v4ProductDetailsResponseResult.payload.product.printAreas)
        )
      }
    }

    if (isDefaultArtworkRequired(product.productType)) {
      dispatch(addImagesForNoImageProducts(basketItemId, selectedAttributes, product))
    }

    await dispatch(fetchPreviewTemplate(sku, basketItemId, selectedAttributes))

    const mof = getState().manualOrderForm
    saveUnfinishedOrder(mof.inProgressOrder, mof.customer, mof.deliveryCountry)
  }
}

function selectDefaultAttributes(
  basketItemId: number,
  product: CatalogueItem,
  defaultSelectedAttributes?: Dictionary<string> | null
): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    if (!product.attributes && Object.keys(product.attributes).length === 0) {
      return
    }

    const defaultAttributes = getDefaultAttributes(product.attributes, product.category, defaultSelectedAttributes)

    entries(defaultAttributes).forEach(([name, value]) => {
      dispatch(selectAttribute(name, value, basketItemId))
    })

    return defaultAttributes
  }
}

function getDefaultAttributes(
  allAttributes: Dictionary<string[]>,
  productCategory: string,
  defaultSelectedAttributes?: Dictionary<string> | null
): Dictionary<string> {
  return Object.keys(allAttributes).reduce(
    (defaultAttributeAcc: Dictionary<string>, attributeName): Dictionary<string> => {
      const defaultAttributeValue = reorderAttributes(attributeName, allAttributes[attributeName], productCategory)[0]

      if (defaultAttributeValue.toLowerCase() === NONE_ATTRIBUTE) {
        return defaultAttributeAcc
      }

      return {
        ...defaultAttributeAcc,
        [attributeName]: defaultSelectedAttributes?.[attributeName] ?? defaultAttributeValue
      }
    },
    {}
  )
}

function addImagesForNoImageProducts(
  basketItemId: number,
  selectedAttributes: Dictionary<string>,
  product: CatalogueItem
) {
  return async (dispatch: DispatchFunc) => {
    const color = selectedAttributes[FRAME_COLOUR] ?? ''

    const imageUrl = getLabImage(color, product.productType, product.sku)

    if (imageUrl) {
      dispatch(imageUrlUpdatedNoUpload(basketItemId, imageUrl, PRINT_AREA_NAME.DEFAULT))
    }
  }
}
