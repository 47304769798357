import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'

import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import LoadingSpinner from '../../LoadingSpinner'
import { useMerchantService } from '../../../hooks'
import {
  SalesChannelProductSuccessResponseInterface,
  useSalesChannelProduct
} from '../../../hooks/useSalesChannelProduct.hook'
import { formatToSentenceCase } from '../../../helpers'
import { SalesChannelPlatformEnum } from '../../../enums'
import { saveVariantsData } from '../helpers/saveVariantsData.helper'
import { VariantDataInterface } from '../SalesChannelProduct.component'
import { SALES_CHANANEL_PRODUCT_TOAST_IDS } from '../constants/toastIds.const'
import { FetchErrorInterface, OMSErrorResponseInterface, SalesChannelInterface, StatusType } from '../../../interfaces'

interface ProductShippingSelectorPropsInterface {
  className: string
  salesChannel: SalesChannelInterface
  salesChannelProduct: SalesChannelProductSuccessResponseInterface
  variantsData: Record<string, VariantDataInterface> | null
}

const ETSY_SHIPPING_OPTIONS = ['budget', 'standard', 'express']
const OTHER_SHIPPING_OPTIONS = ['auto-select', 'budget', 'standard', 'express']

export function ProductShippingSelector({
  className,
  salesChannel,
  salesChannelProduct,
  variantsData
}: ProductShippingSelectorPropsInterface) {
  const { merchantDetails } = useMerchantService()
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { mutateSalesChannelProduct } = useSalesChannelProduct({ productId, salesChannelId })

  const [updateShippingStatus, setUpdateShippingStatus] = useState<StatusType>('idle')

  async function setSelectedShipping(shippingMethod: string) {
    toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)

    if (!variantsData) {
      return
    }

    setUpdateShippingStatus('loading')

    try {
      const newVariantsData = Object.keys(variantsData).reduce(
        (variantsAcc: Record<string, VariantDataInterface>, variantKey) => {
          const currentVariantDetails: VariantDataInterface = variantsData[variantKey]
          return {
            ...variantsAcc,
            [variantKey]: {
              ...currentVariantDetails,
              preferredShippingMethod: shippingMethod
            }
          }
        },
        {}
      )

      await saveVariantsData({
        merchantId: merchantDetails?.id ?? null,
        newVariantsData,
        productId,
        salesChannelId
      })
      await mutateSalesChannelProduct()
      setUpdateShippingStatus('success')
      window.analytics.track('Sales channels - configured shipping method')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${productId}-${errorResponse.responseBodyJson?.traceParent ?? '0'}-${
        errorResponse.status ?? '0'
      }`
      createErrorToast({
        errorCode,
        heading: 'Failed to update',
        id: SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR
      })
      setUpdateShippingStatus('error')
    }
  }

  if (salesChannelProduct.data.variantsData.length === 0) {
    return null
  }

  const isEtsySalesChannel = salesChannel.platform === SalesChannelPlatformEnum.Etsy

  const shippingOptions = isEtsySalesChannel ? ETSY_SHIPPING_OPTIONS : OTHER_SHIPPING_OPTIONS

  // We use the first variant's shipping method as the overall product-level shipping method as all variants have the same value
  const preferredShippingMethod =
    salesChannelProduct.data.variantsData.length > 0 && salesChannelProduct.data.variantsData[0].preferredShippingMethod

  let selectedShipping = preferredShippingMethod || (isEtsySalesChannel ? 'standard' : 'auto-select')

  if (isEtsySalesChannel && selectedShipping.toLowerCase() === 'auto-select') {
    // BE uses auto-select as the default for all variants regardless of sales channel platform
    selectedShipping = 'standard'
  }

  return (
    <div className={className}>
      <div className="mb-6 w-full max-w-full border border-gray-200 bg-gray-100 antialiased lg:w-[300px]">
        <div className="flex flex-col bg-white p-5">
          <div className="break-words font-medium line-clamp-2">Shipping</div>

          <FormItem
            key="shipping_selector"
            inputField={
              <div className="flex items-center gap-4">
                <SelectField
                  className="w-full"
                  disabled={updateShippingStatus === 'loading'}
                  value={selectedShipping.toLowerCase()}
                  onChange={(event) => {
                    setSelectedShipping(event.target.value)
                  }}
                >
                  {shippingOptions.map((shippingOption) => (
                    <option key={shippingOption.toLowerCase()} value={shippingOption}>
                      {formatToSentenceCase(shippingOption)}
                    </option>
                  ))}
                </SelectField>

                {<LoadingSpinner className={clsx('h-8 w-8', { invisible: updateShippingStatus !== 'loading' })} />}
              </div>
            }
          />

          <Footer isEtsySalesChannel={isEtsySalesChannel} />
        </div>
      </div>
      {updateShippingStatus === 'loading' && <OverlayPortal className="cursor-wait" />}
    </div>
  )
}

function Footer({ isEtsySalesChannel }: { isEtsySalesChannel: boolean }) {
  const text = isEtsySalesChannel
    ? 'All Etsy products use Standard shipping unless set otherwise here.'
    : 'Auto-select matches the shipping method in the original order where possible.'

  return (
    <span className="break-words pt-1 text-sm text-gray-600">
      {text}{' '}
      <a
        className="text-purple-500 hover:underline"
        href="https://www.prodigi.com/faq/shipping/"
        rel="noreferrer"
        target="_blank"
        title="Shipping FAQ."
      >
        Shipping FAQ.
      </a>
    </span>
  )
}
