import { useParams } from 'react-router-dom'

import { formatCost } from '../../../helpers'
import { useOrderDetail } from '../../../hooks'
import { OrderDetailItemInterface } from '../../../interfaces'

export function LineItemCostData({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  const lineItemCostData = orderDetailsResponse?.data.draftCosts?.items[lineItem.id]
  const itemCost = formatCost({
    amount: lineItemCostData?.itemCost.amount,
    currencyCode: lineItemCostData?.itemCost.currencyCode
  })

  const totalCost = formatCost({
    amount: lineItemCostData?.totalCost?.amount,
    currencyCode: lineItemCostData?.totalCost?.currencyCode
  })

  return (
    <>
      <td
        className="col-start-1 whitespace-nowrap pt-2 pl-2 align-top text-sm sm:col-start-2 md:p-2 md:text-right md:text-base"
        data-test="shipment-item__cost"
      >
        {itemCost}
      </td>

      <td
        className="col-start-2 whitespace-nowrap pt-2 align-top text-sm sm:col-start-3 md:p-2 md:text-right md:text-base"
        data-test="shipment-item__quantity"
      >
        <span className="px-2 md:hidden">x</span>
        {lineItem.quantity}
        <span className="pl-2 md:hidden">qty</span>
      </td>

      {totalCost && (
        <td
          className="col-start-3 whitespace-nowrap pt-2 align-top text-sm sm:col-start-4 md:p-2 md:text-right md:text-base"
          data-test="shipment-item__total"
        >
          <span className="px-2 md:hidden">=</span>
          {totalCost}
        </td>
      )}
    </>
  )
}
