// @flow

const MESSAGE_PREFIX = 'Upload failed. '
const MESSAGE_SUFFIX = ' Please check, and re-upload your file with the necessary corrections.'

export default class CsvUploadError extends Error {
  csvUploadErrorMessage: string

  constructor (message: string) {
    super(message)
    this.csvUploadErrorMessage = MESSAGE_PREFIX + message + MESSAGE_SUFFIX
  }
}
