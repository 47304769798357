import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { DatePicker } from './components'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../constants'
import { formatDateToString } from '../../helpers'

export default function OrdersListActionDates() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  /* ------------------------------- Start date ------------------------------- */
  const selectedStartDateParam = searchParams.get(QUERY_PARAMS.ORDERS.START_DATE)
  const selectedStartDateValue = useMemo(() => selectedStartDateParam, [selectedStartDateParam])
  const [selectedStartDateOption, setSelectedStartDateOption] = useState<Date | null>(
    selectedStartDateValue ? new Date(selectedStartDateValue) : null
  )

  /* ------------------------------- End date ------------------------------- */
  const selectedEndDateParam = searchParams.get(QUERY_PARAMS.ORDERS.END_DATE)
  const selectedEndDateValue = useMemo(() => selectedEndDateParam, [selectedEndDateParam])
  const [selectedEndDateOption, setSelectedEndDateOption] = useState<Date | null>(
    selectedEndDateValue ? new Date(selectedEndDateValue) : null
  )

  function updateSelectedSortOption(startDate: Date | null, endDate: Date | null) {
    const newSearchParams = new URLSearchParams(searchParams)
    if (startDate) {
      setSelectedStartDateOption(startDate)
      newSearchParams.set(QUERY_PARAMS.ORDERS.START_DATE, startDate.toJSON())
    } else {
      setSelectedStartDateOption(null)
      newSearchParams.delete(QUERY_PARAMS.ORDERS.START_DATE)
    }

    if (endDate) {
      setSelectedEndDateOption(endDate)
      newSearchParams.set(QUERY_PARAMS.ORDERS.END_DATE, endDate.toJSON())
    } else {
      setSelectedEndDateOption(null)
      newSearchParams.delete(QUERY_PARAMS.ORDERS.END_DATE)
    }

    const newOrdersUrl = `${ROUTE_PATHS.ORDERS}?${newSearchParams.toString()}`
    history.push(newOrdersUrl)
  }

  // Ensures browser navigation changes and clear filters action are captured
  useEffect(() => {
    setSelectedStartDateOption(selectedStartDateValue ? new Date(selectedStartDateValue) : null)
  }, [searchParams, selectedStartDateValue])
  useEffect(() => {
    setSelectedEndDateOption(selectedEndDateValue ? new Date(selectedEndDateValue) : null)
  }, [searchParams, selectedEndDateValue])

  return (
    <div className="relative flex min-w-[240px] items-center">
      <DatePicker
        endDate={selectedEndDateOption}
        formatDateToString={formatDateToString}
        startDate={selectedStartDateOption}
        updateSelectedSortOption={updateSelectedSortOption}
      />
    </div>
  )
}
