// @flow

import * as React from 'react'
import styles from './CenterFill.module.css'

type Props = {|
  children: React$Node
|}

function CenterFill ({ children }: Props): React$Node {
  return (
    <div className={styles.centerFill}>
      <div className={styles.centerFillTop} />
      {children}
      <div className={styles.centerFillBottom} />
    </div>
  )
}

export default CenterFill
