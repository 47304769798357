import { Link } from 'react-router-dom'

export function QuickLinks() {
  return (
    <div className="w-full">
      <div className="container mx-auto pb-11">
        <div className="border-t border-gray-600" />
        <h2 className="mt-16 pb-12 text-black">Quick links</h2>

        <div className="flex flex-wrap">
          <QuickLink
            eventText="Clicked - View pricing quick link"
            hrefLink="/pricing/"
            isExternal={false}
            sendEvent={true}
            text="Price sheet downloads"
          />
          <QuickLink
            hrefLink="https://www.prodigi.com/download/product-range/prodigi-portfolio.pdf"
            text="Download product portfolio (pdf)"
          />
          <QuickLink hrefLink="https://www.prodigi.com/downloads/" text="Free downloadable assets" />
          <QuickLink hrefLink="https://www.prodigi.com/print-api/docs/" text="View API documentation" />
          <QuickLink
            eventText="Clicked - network status quick link"
            hrefLink="https://www.prodigi.com/network-status/"
            sendEvent={true}
            text="Network status updates"
          />
          <QuickLink
            eventText="Clicked - blog quick link"
            hrefLink="https://www.prodigi.com/blog/"
            sendEvent={true}
            text="Blog"
          />
        </div>
      </div>
    </div>
  )
}

function QuickLink({
  eventText,
  hrefLink,
  isExternal = true,
  sendEvent = false,
  text
}: {
  eventText?: string
  hrefLink: string
  isExternal?: boolean
  sendEvent?: boolean
  text: string
}) {
  if (!isExternal) {
    return (
      <span className="mb-8 w-full sm:w-1/2">
        <Link
          className="text-purple-600"
          to={hrefLink}
          onClick={() => {
            if (sendEvent && eventText) {
              window.analytics.track(eventText)
            }
          }}
        >
          {text}
        </Link>
      </span>
    )
  }

  return (
    <span className="mb-8 w-full sm:w-1/2">
      <a
        className="text-purple-600"
        href={hrefLink}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => {
          if (sendEvent && eventText) {
            window.analytics.track(eventText)
          }
        }}
      >
        {text}
      </a>
    </span>
  )
}
