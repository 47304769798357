// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function PrintShopIcon ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="30" cy="30" r="30" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24 43H18C16.8954 43 16 42.1046 16 41V29C16 27.8954 16.8954 27 18 27H24C25.1046 27 26 27.8954 26 29V41C26 42.1046 25.1046 43 24 43ZM18 29V41H24V29H18ZM42 17H20C18.8954 17 18 17.8954 18 19V25H20V19H42V33H28V35H30V39H28V41H37V39H32V35H42C43.1046 35 44 34.1046 44 33V19C44 17.8954 43.1046 17 42 17Z" />
    </svg>
  )
}
