// @flow
import {
  UPDATE_ADDRESS,
  SELECT_ATTRIBUTE,
  RESET_ATTRIBUTES,
  CHANGE_QUANTITY,
  REMOVE_BASKET_ITEM,
  CLEAR_ORDER_DATA,
  FETCH_COUNTRIES,
  RECEIVE_COUNTRIES,
  FETCH_COUNTRIES_FAILED,
  CHANGE_DELIVERY_COUNTRY,
  ADD_BASKET_ITEM,
  INIT_PRINT_AREAS_FOR_BASKET_ITEM,
  SET_NEW_BASKET_ITEM_ID,
  SELECT_MOBILE_FACET_SCREEN,
  IMAGE_URL_UPDATED_NO_UPLOAD,
  START_MOF_IMAGE_UPLOAD,
  FINISH_MOF_IMAGE_UPLOAD,
  SET_UP_UNFINISHED_ORDER,
  INIT_IMAGE,
  MERCHANT_ORDER_ID_CHANGE,
  UPDATE_PRICE_TO_USER,
  UPDATE_CROPPED_IMAGE_URL,
  V4_MOF_ORDER_CREATION_SUCCESS,
  CHANGE_SELECTED_PRINT_AREA_FOR_BASKET_ITEM,
  REMOVE_BASKET_ITEM_ARTWORK,
  SAVE_TRANSFORMATIONS,
  UPDATE_PRINT_AREAS,
  ADD_MULTIPLE_BASKET_ITEMS,
  SET_CUSTOMER_ADDRESS,
  TOGGLE_US_SALES_TAX,
  SET_COSTED_ATTRIBUTE_KEYS
} from '../actions/manualOrderForm'
import type { AddMultipleBasketItemsType } from '../actions/manualOrderForm/addMultipleBasketItems'
import type { SetCustomerAddress } from '../actions/manualOrderForm/setCustomerAddress'
import {
  ADD_RECENT_ITEM_TO_BASKET,
  SETUP_RECENT_ITEMS_SUCCESS,
  CLEAR_RECENT_ITEMS,
  UPDATE_RECENT_ITEM_PRINT_AREAS
} from '../actions/recentItems'
import { PACKING_SLIP_UPLOAD_SUCCESS, REMOVE_PACKING_SLIP } from '../actions/packingSlip'
import { RECEIVE_PRICES } from '../actions/catalogue'
import type { Action, MultiAssetBasketItem, InProgressOrder, CustomerAddress, ManualOrderFormState } from '../types'
import type {
  InitImage,
  SelectMofShippingMethod,
  UpdatePriceToUser,
  StartMofImageUpload,
  FinishMofImageUpload,
  UpdateCroppedImageUrl,
  RemoveBasketItemArtwork,
  OnV4MofOrderCreationSuccess,
  InitPrintAreasForBasketItemAction,
  ChangeSelectedPrintAreaForBasketItem,
  SaveTransformations,
  UpdatePrintAreas,
  SetCostedAttributeKeysAction,
  UpdateBasketItemMetaDataActionType
} from '../actions/types/manualOrderForm'
import type { UpdateRecentItemPrintAreas } from '../actions/types/recentItems'
import { SELECT_MOF_SHIPPING_METHOD } from '../actions/manualOrderForm/selectMofShippingMethod'
import { entries } from '../helpers/dictionary'
import { PRINT_AREA_NAME } from '../data'
import { UPDATE_BASKET_ITEM_META_DATA } from '../actions/manualOrderForm/manualOrderForm'

export const BLANK_BASKET_ITEM: MultiAssetBasketItem = {
  printAreas: null,
  appVersion: 0,
  costedAttributeKeys: { initialised: false, value: [] },
  id: -1,
  metaData: undefined,
  selectedPrintArea: null,
  selectedAttributes: {},
  quantity: 1,
  priceToUserAsInt: 0,
  sku: ''
}

const DEFAULT_COUNTRY_CODE = 'GB'

const BLANK_CUSTOMER: CustomerAddress = {
  address1: '',
  address2: '',
  countryCode: DEFAULT_COUNTRY_CODE,
  county: '',
  email: '',
  mobileTelephone: '',
  name: '',
  postcode: '',
  town: ''
}

const BLANK_ORDER_STATE: InProgressOrder = {
  id: '',
  merchantOrderId: '',
  itemsToBeCreated: [],
  status: 'None',
  submitting: false,
  submitted: false,
  orderError: false
}

export const initialState: ManualOrderFormState = {
  countries: [],
  deliveryCountry: DEFAULT_COUNTRY_CODE,
  customer: BLANK_CUSTOMER,
  tag: 'source:MOF',
  isUsSalesTaxAlreadyCollected: false,
  fetching: false,
  fetchSuccess: false,
  fetchError: false,
  errorMessage: '',
  inProgressOrder: BLANK_ORDER_STATE,
  selectedShippingMethodName: null,
  lastBasketItemId: -1,
  selectedMobileFacetScreen: '',
  availableRecentItems: [],
  packingSlip: null
}

function manualOrderForm(state: ManualOrderFormState = initialState, action: Action): ManualOrderFormState {
  switch (action.type) {
    case CHANGE_DELIVERY_COUNTRY: {
      return {
        ...state,
        deliveryCountry: action.country,
        customer: {
          ...state.customer,
          countryCode: action.country
        }
      }
    }

    case FETCH_COUNTRIES: {
      return {
        ...state,
        fetching: true,
        fetchSuccess: false,
        fetchError: false
      }
    }

    case FETCH_COUNTRIES_FAILED: {
      return {
        ...state,
        fetching: false,
        fetchError: true
      }
    }

    case RECEIVE_COUNTRIES: {
      if (action.payload && action.payload.data) {
        const withoutDummy = action.payload.data.filter((x) => x.name !== 'V1DUMMY')
        return {
          ...state,
          fetching: false,
          fetchSuccess: true,
          countries: withoutDummy
        }
      } else {
        return state
      }
    }

    case IMAGE_URL_UPDATED_NO_UPLOAD: {
      const itemId = action.id
      const newItemsArray: MultiAssetBasketItem[] = state.inProgressOrder.itemsToBeCreated.map((item, index) => {
        if (itemId.toString() === item.id.toString()) {
          return {
            ...item,
            printAreas: {
              ...item.printAreas,
              [action.printAreaName]: {
                ...item.printAreas?.[action.printAreaName],
                artwork: {
                  ...item.printAreas?.[action.printAreaName].artwork,
                  originalImageUrl: action.url,
                  croppedImageUrl: action.url,
                  smallImageUrl: action.url,
                  transformImageUrl: action.url
                }
              }
            }
          }
        } else {
          return item
        }
      })

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: newItemsArray
        }
      }
    }

    case UPDATE_BASKET_ITEM_META_DATA: {
      const updateBasketItemMetaDataAction: UpdateBasketItemMetaDataActionType = action

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (item.id.toString() === updateBasketItemMetaDataAction.id.toString()) {
              return {
                ...item,
                metaData: {
                  ...item.metaData,
                  ...updateBasketItemMetaDataAction.newMetaData
                }
              }
            } else {
              return item
            }
          })
        }
      }
    }

    case SET_COSTED_ATTRIBUTE_KEYS: {
      const setCostedAttributeKeysAction: SetCostedAttributeKeysAction = action

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (item.id.toString() === setCostedAttributeKeysAction.id.toString()) {
              return {
                ...item,
                costedAttributeKeys: {
                  ...item.costedAttributeKeys,
                  initialised: true,
                  value: setCostedAttributeKeysAction.costedAttributeKeys ?? []
                }
              }
            } else {
              return item
            }
          })
        }
      }
    }

    case SELECT_ATTRIBUTE: {
      const attrName = action.name
      const itemId = action.id

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item, index, arr) => {
            if (item.id.toString() === itemId.toString()) {
              return {
                ...item,
                selectedAttributes: {
                  ...item.selectedAttributes,
                  [attrName]: action.value
                }
              }
            } else {
              return item
            }
          })
        }
      }
    }

    case RESET_ATTRIBUTES: {
      const itemId = action.id
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item, index, arr) => {
            if (item.id.toString() === itemId.toString()) {
              return { ...item, selectedAttributes: {} }
            } else {
              return item
            }
          })
        }
      }
    }

    case CHANGE_QUANTITY: {
      const itemId = action.id
      const newItemsArray = state.inProgressOrder.itemsToBeCreated.map((item, index) => {
        if (itemId.toString() === item.id.toString()) {
          return { ...item, quantity: parseInt(action.qty, 10) }
        }
        return item
      })

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: newItemsArray
        }
      }
    }

    case ADD_BASKET_ITEM: {
      const mappedProduct: MultiAssetBasketItem = {
        ...BLANK_BASKET_ITEM,
        sku: action.sku,
        id: action.id,
        appVersion: action.appVersion
      }

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: [...state.inProgressOrder.itemsToBeCreated, mappedProduct]
        }
      }
    }

    case INIT_PRINT_AREAS_FOR_BASKET_ITEM: {
      const currentAction: InitPrintAreasForBasketItemAction = action

      const newItemsArray: MultiAssetBasketItem[] = state.inProgressOrder.itemsToBeCreated.map((item, index) => {
        if (currentAction.basketItemId.toString() === item.id.toString()) {
          const printAreas = entries(currentAction.printAreas).reduce((printAreaAcc, [printArea]) => {
            printAreaAcc[printArea] = {
              artworkTransformations: null,
              artwork: null
            }

            return printAreaAcc
          }, {})
          const printAreaNames = Object.keys(printAreas)
          const firstAvailablePrintArea = printAreaNames[0]
          const selectedPrintArea = firstAvailablePrintArea

          return {
            ...item,
            printAreas,
            selectedPrintArea
          }
        }
        return item
      })

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: newItemsArray
        }
      }
    }

    case UPDATE_PRINT_AREAS: {
      const currentAction: UpdatePrintAreas = action

      const newItemsArray = state.inProgressOrder.itemsToBeCreated.map((item, index) => {
        if (currentAction.basketItemId.toString() === item.id.toString()) {
          const printAreas = currentAction.printAreaNames.reduce((printAreaAcc, printAreaName) => {
            if (item.printAreas?.[printAreaName]) {
              printAreaAcc[printAreaName] = item.printAreas[printAreaName]
              return printAreaAcc
            }

            printAreaAcc[printAreaName] = {
              artworkTransformations: null,
              artwork: null
            }

            return printAreaAcc
          }, {})

          return {
            ...item,
            printAreas,
            selectedPrintArea: currentAction.printAreaNames.includes(item.selectedPrintArea)
              ? item.selectedPrintArea
              : currentAction.printAreaNames[0] ?? PRINT_AREA_NAME.DEFAULT
          }
        }
        return item
      })

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: newItemsArray
        }
      }
    }

    case CHANGE_SELECTED_PRINT_AREA_FOR_BASKET_ITEM: {
      const currentAction: ChangeSelectedPrintAreaForBasketItem = action
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (currentAction.basketItemId.toString() === item.id.toString()) {
              return {
                ...item,
                selectedPrintArea: currentAction.selectedPrintArea
              }
            }
            return item
          })
        }
      }
    }

    case REMOVE_BASKET_ITEM_ARTWORK: {
      const currentAction: RemoveBasketItemArtwork = action
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (currentAction.basketItemId.toString() === item.id.toString()) {
              return {
                ...item,
                printAreas: {
                  ...item.printAreas,
                  [currentAction.printArea]: {
                    ...item.printAreas?.[currentAction.printArea],
                    artwork: null,
                    artworkTransformations: null
                  }
                }
              }
            }
            return item
          })
        }
      }
    }

    case REMOVE_BASKET_ITEM: {
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.filter(
            (element, index) => element.id.toString() !== action.id.toString()
          )
        }
      }
    }

    case ADD_RECENT_ITEM_TO_BASKET: {
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: [...state.inProgressOrder.itemsToBeCreated, action.item]
        }
      }
    }

    case ADD_MULTIPLE_BASKET_ITEMS: {
      const currentAction: AddMultipleBasketItemsType = action
      return {
        ...state,
        lastBasketItemId: currentAction.lastBasketItemId,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: [...state.inProgressOrder.itemsToBeCreated, ...currentAction.basketItems]
        }
      }
    }

    case UPDATE_RECENT_ITEM_PRINT_AREAS: {
      const currentAction: UpdateRecentItemPrintAreas = action
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (action.item.id.toString() === item.id.toString()) {
              return {
                ...item,
                printAreas: currentAction.item.printAreas,
                selectedPrintArea: currentAction.item.selectedPrintArea
              }
            }
            return item
          })
        }
      }
    }

    case SETUP_RECENT_ITEMS_SUCCESS: {
      return {
        ...state,
        availableRecentItems: action.availableRecentItems
      }
    }

    case UPDATE_ADDRESS: {
      let updatedAddress = {}
      updatedAddress[action.field] = action.value
      return {
        ...state,
        customer: Object.assign({}, state.customer, updatedAddress)
      }
    }

    case SET_CUSTOMER_ADDRESS: {
      const currentAction: SetCustomerAddress = action
      return {
        ...state,
        customer: currentAction.customerAddress,
        deliveryCountry: currentAction.customerAddress.countryCode
      }
    }

    case V4_MOF_ORDER_CREATION_SUCCESS: {
      const currentAction: OnV4MofOrderCreationSuccess = action
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          id: currentAction.orderId
        }
      }
    }

    case CLEAR_ORDER_DATA: {
      return {
        ...initialState,
        countries: state.countries
      }
    }

    case SET_NEW_BASKET_ITEM_ID: {
      return {
        ...state,
        lastBasketItemId: action.id
      }
    }

    case SELECT_MOBILE_FACET_SCREEN: {
      return {
        ...state,
        selectedMobileFacetScreen: action.facet
      }
    }

    case START_MOF_IMAGE_UPLOAD: {
      const currentAction: StartMofImageUpload = action
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (currentAction.meta.itemId.toString() === item.id.toString()) {
              return {
                ...item,
                printAreas: {
                  ...item.printAreas,
                  [currentAction.meta.printAreaName]: {
                    ...item.printAreas?.[currentAction.meta.printAreaName],
                    artwork: null,
                    artworkTransformations: null
                  }
                }
              }
            }
            return item
          })
        }
      }
    }

    case FINISH_MOF_IMAGE_UPLOAD: {
      const currentAction: FinishMofImageUpload = action

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (currentAction.itemId.toString() === item.id.toString()) {
              return {
                ...item,
                printAreas: {
                  ...item.printAreas,
                  [currentAction.printAreaName]: {
                    ...item.printAreas?.[currentAction.printAreaName],
                    artwork: { ...currentAction.artwork, transformImageUrl: currentAction.artwork.croppedImageUrl }
                  }
                }
              }
            }
            return item
          })
        }
      }
    }

    case INIT_IMAGE: {
      const currentAction: InitImage = action
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (item.id.toString() === currentAction.basketItemId.toString()) {
              return {
                ...item,
                printAreas: {
                  ...item.printAreas,
                  [currentAction.printAreaName]: {
                    ...item.printAreas?.[currentAction.printAreaName],
                    artworkTransformations: currentAction.artworkTransformations
                  }
                }
              }
            }
            return item
          })
        }
      }
    }

    case UPDATE_CROPPED_IMAGE_URL: {
      const currentAction: UpdateCroppedImageUrl = action

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (currentAction.itemId.toString() === item.id.toString()) {
              return {
                ...item,
                printAreas: {
                  ...item.printAreas,
                  [currentAction.selectedPrintArea]: {
                    ...item.printAreas?.[currentAction.selectedPrintArea],
                    artwork: {
                      ...item.printAreas?.[currentAction.selectedPrintArea].artwork,
                      croppedImageUrl: currentAction.croppedImageUrl,
                      transformImageUrl: currentAction.transformImageUrl
                    }
                  }
                }
              }
            }
            return item
          })
        }
      }
    }

    case SAVE_TRANSFORMATIONS: {
      const currentAction: SaveTransformations = action
      const croppedImageUrls = currentAction.croppedImageUrls
      const basketItems = state.inProgressOrder.itemsToBeCreated
      const basketItemId = currentAction.basketItemId.toString()

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: basketItems.map((item) => {
            if (basketItemId !== item.id.toString()) {
              return item
            }

            return {
              ...item,
              printAreas:
                item.printAreas &&
                entries(item.printAreas).reduce((printAreaAcc, [printAreaName, printAreaArtworkData]) => {
                  if (!croppedImageUrls[printAreaName]) {
                    printAreaAcc[printAreaName] = printAreaArtworkData
                    return printAreaAcc
                  }

                  const prevTransformations = printAreaArtworkData.artworkTransformations

                  const updatedTransformations = currentAction.updatedArtworkTransformations[printAreaName]

                  let newArtworkData = {
                    ...printAreaArtworkData.artwork,
                    croppedImageUrl: croppedImageUrls[printAreaName],
                    transformImageUrl: currentAction.transformDataByPrintArea?.[printAreaName]?.url
                  }

                  if (printAreaArtworkData?.artwork?.mimeType === 'application/pdf') {
                    newArtworkData = {
                      ...printAreaArtworkData.artwork,
                      croppedImageUrl: printAreaArtworkData.artwork.originalImageUrl,
                      transformImageUrl: printAreaArtworkData.artwork.originalImageUrl
                    }
                  }

                  printAreaAcc[printAreaName] = {
                    ...printAreaArtworkData,
                    artwork: newArtworkData,
                    artworkTransformations:
                      prevTransformations && updatedTransformations
                        ? { ...prevTransformations, ...updatedTransformations }
                        : updatedTransformations
                  }

                  return printAreaAcc
                }, {})
            }
          })
        }
      }
    }

    case SET_UP_UNFINISHED_ORDER: {
      return {
        ...state,
        inProgressOrder: action.order,
        deliveryCountry: action.deliveryCountry,
        customer: {
          ...action.customer,
          countryCode: action.deliveryCountry
        }
      }
    }

    case RECEIVE_PRICES: {
      const prices = action.payload.prices
      const itemsToBeCreated: MultiAssetBasketItem[] = JSON.parse(
        JSON.stringify(state.inProgressOrder.itemsToBeCreated)
      )

      for (let item of itemsToBeCreated) {
        for (let price of prices) {
          if (item.sku === price.sku) {
            item.currency = price.currency
            item.price = price.price
          }
        }
      }

      return {
        ...state,
        inProgressOrder: { ...state.inProgressOrder, itemsToBeCreated }
      }
    }

    case MERCHANT_ORDER_ID_CHANGE: {
      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          merchantOrderId: action.merchantOrderId
        }
      }
    }

    case CLEAR_RECENT_ITEMS: {
      return {
        ...state,
        availableRecentItems: []
      }
    }

    case PACKING_SLIP_UPLOAD_SUCCESS: {
      return {
        ...state,
        packingSlip: {
          url: action.payload.packingSlipUrl,
          fileName: action.payload.fileName
        }
      }
    }

    case REMOVE_PACKING_SLIP: {
      return {
        ...state,
        packingSlip: null
      }
    }

    case UPDATE_PRICE_TO_USER: {
      const currentAction: UpdatePriceToUser = action

      return {
        ...state,
        inProgressOrder: {
          ...state.inProgressOrder,
          itemsToBeCreated: state.inProgressOrder.itemsToBeCreated.map((item) => {
            if (item.id.toString() === currentAction.basketItemId.toString()) {
              return {
                ...item,
                priceToUserAsInt: currentAction.priceToUserAsInt
              }
            }

            return item
          })
        }
      }
    }

    case SELECT_MOF_SHIPPING_METHOD: {
      const currentAction: SelectMofShippingMethod = action

      return {
        ...state,
        selectedShippingMethodName: currentAction.shippingMethodName
      }
    }

    case TOGGLE_US_SALES_TAX: {
      return {
        ...state,
        isUsSalesTaxAlreadyCollected: !state.isUsSalesTaxAlreadyCollected
      }
    }

    default: {
      return state
    }
  }
}

export default manualOrderForm
