// @flow
import React from 'react'
import type { ChildrenArray } from 'react'

import Button from '../Button'
import styles from './Stepper.module.css'

type Props = {|
  actionButtonText?: string,
  backText?: string,
  children: ChildrenArray<*>,
  contentTitle: string,
  contentSubtitle: string,
  currentStep: number,
  disableActionButton?: boolean,
  errorText?: string,
  hasError?: boolean,
  isLoadingActionButton?: boolean,
  loadingActionButtonText?: string,
  stepperTitle: string,
  totalSteps: number,
  onBack: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onNext: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onComplete: (event: SyntheticEvent<HTMLButtonElement>) => void
|}

class Stepper extends React.Component<Props> {
  render (): React$Node {
    const {
      actionButtonText = 'Next',
      backText = 'Back',
      contentTitle,
      contentSubtitle,
      currentStep,
      disableActionButton = false,
      errorText,
      hasError = false,
      isLoadingActionButton = false,
      loadingActionButtonText,
      onBack,
      onNext,
      onComplete,
      stepperTitle,
      totalSteps
    } = this.props

    return (
      <section className={styles.stepper}>
        <button className={styles.stepperBack} onClick={onBack}>&lt; &nbsp; {backText}</button>
        <h1 className={styles.stepperTitle}>{stepperTitle}</h1>
        <div className={styles.stepperStepStatus}>Step {currentStep} of {totalSteps}</div>
        <div className={styles.stepperCard}>
          <div className={styles.stepperContentTitle}>{contentTitle}</div>
          <div className={styles.stepperContentSubtitle}>{contentSubtitle}</div>
          {this.props.children}
          <div className={styles.stepperAction}>
            <Button
              primary
              className={styles.stepperActionButton}
              loading={isLoadingActionButton}
              disabled={disableActionButton}
              onClick={currentStep !== totalSteps ? onNext : onComplete}>
              {isLoadingActionButton ? (loadingActionButtonText || '') : actionButtonText}
            </Button>
          </div>
          {hasError &&
            <div className={styles.stepperAction}>
              <div className={styles.stepperActionError}>
                {errorText || 'An error occurred, please try again later or contact support if the issue persists'}
              </div>
            </div>}
        </div>
      </section>
    )
  }
}

export default Stepper
