// @flow
import { createSelector } from 'reselect'
import { identity } from './helpers'

import type { BasketItem, AppState, MultiAssetBasketItem } from '../types'

export function selectRecentItems (state: AppState): Array<BasketItem | MultiAssetBasketItem> {
  return state.recentItems.items
}

export const getRecentItems: any = createSelector(selectRecentItems, identity)
