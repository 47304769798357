import { SettingsPanel } from '../SettingsPanel.component'

export function IncompleteCompanyDetailsPanel() {
  return (
    <SettingsPanel
      title={<h2>Payment method</h2>}
      subtitle={
        <p className="my-6 text-gray-600">
          Please complete the billing information above before adding a payment method.
        </p>
      }
      className="border border-t-0"
    />
  )
}
