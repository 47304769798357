import { fetcher } from '../../../helpers'
import { UserInterface } from '../../../interfaces'

export function updateUserSettings(newUserSettings: UserInterface) {
  window.analytics.track('Update user details')

  return fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/update`, {
    body: JSON.stringify({
      company: newUserSettings.company,
      settings: newUserSettings.settings
    }),
    method: 'POST'
  })
}
