import React, { useRef, useLayoutEffect } from 'react'
import { AlignmentPointType } from '../interfaces/AlignmentPoint.type'

type ImageEditorAlignmentToolsProps = {
  onAlignmentPointChange: (event: CustomEvent<AlignmentPointType>) => void
  onFit: (event: CustomEvent<void>) => void
  onFill: (event: CustomEvent<void>) => void
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'image-editor-alignment-tools': any
    }
  }
}

function ImageEditorAlignmentToolsWrapper({ onAlignmentPointChange, onFit, onFill }: ImageEditorAlignmentToolsProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const alignmentToolsRef = useRef<any>(null)

  useLayoutEffect(() => {
    if (!alignmentToolsRef.current) {
      return
    }

    alignmentToolsRef.current.addEventListener('alignmentPointChange', onAlignmentPointChange)
    alignmentToolsRef.current.addEventListener('fit', onFit)
    alignmentToolsRef.current.addEventListener('fill', onFill)

    return () => {
      alignmentToolsRef.current.removeEventListener('alignmentPointChange', onAlignmentPointChange)
      alignmentToolsRef.current.removeEventListener('fit', onFit)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      alignmentToolsRef.current.removeEventListener('fill', onFill)
    }
  }, [onAlignmentPointChange, onFill, onFit])

  return <image-editor-alignment-tools ref={alignmentToolsRef} />
}

const ImageEditorAlignmentTools = React.memo(ImageEditorAlignmentToolsWrapper)

export default ImageEditorAlignmentTools
