import { useState } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'

import Button from '../../Button'
import SavedItemsModal from '../../SavedItemsModal'
import { SavedItemsPropsInterface } from '../../SavedItems'

export function SelectProductSavedItemsModalButton(props: SavedItemsPropsInterface) {
  const [isSavedItemsModalOpen, setIsSavedItemsModalOpen] = useState(false)

  return (
    <>
      <Button
        className="whitespace-nowrap"
        variant="tertiary"
        theme="greyscale"
        size="sm"
        startIcon={<StarIcon className="h-8 w-8 text-[#FFD907]" />}
        onClick={() => {
          window.analytics.track('Saved items: Opened modal')
          setIsSavedItemsModalOpen(true)
        }}
      >
        Saved items
      </Button>

      <SavedItemsModal {...props} open={isSavedItemsModalOpen} setOpen={setIsSavedItemsModalOpen} />
    </>
  )
}
