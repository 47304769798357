// @flow
import cloneDeep from 'lodash/cloneDeep'
import { setNewBasketItemId } from './setNewBasketItemId'
import { removeUnfinishedOrderFromLocalStorage, getOrderFromLocalStorage } from './localStorage'
import type { Thunk, DispatchFunc, LocalStorageOrder, InProgressOrder } from '../../types'

export const SET_UP_UNFINISHED_ORDER = 'SET_UP_UNFINISHED_ORDER'
export const GET_UNFINISHED_ORDER_SKIPPED = 'GET_UNFINISHED_ORDER_SKIPPED'

export function getUnfinishedOrder (): Thunk<*> {
  return (dispatch: DispatchFunc) =>
    dispatch(
      getOrderFromLocalStorage()
        .whenSome(setUpOrderFromLocalStorage)
        .whenNone(getUnfinishedOrderSkipped)
    )
}

function setUpOrderFromLocalStorage (order: LocalStorageOrder) {
  return (dispatch: DispatchFunc) =>
    shouldDiscard(order)
      ? dispatch(getUnfinishedOrderSkipped())
      : dispatch(setUpUnfinishedOrder(order))
}

function shouldDiscard (order: LocalStorageOrder): boolean {
  const fortyEightHoursInMs = 172800000
  const isExpired = Date.now() - order.timestamp > fortyEightHoursInMs
  const hasInvalidVersion = !order.version || order.version !== process.env.REACT_APP_VERSION

  return isExpired || hasInvalidVersion
}

function getUnfinishedOrderSkipped () {
  return (dispatch: DispatchFunc) => {
    removeUnfinishedOrderFromLocalStorage()
    dispatch({ type: GET_UNFINISHED_ORDER_SKIPPED })
  }
}

function setUpUnfinishedOrder (localStorageOrder: LocalStorageOrder) {
  return async (dispatch: DispatchFunc) =>
    dispatch({
      order: await dispatch(mapToInProgressOrder(localStorageOrder)),
      customer: localStorageOrder.customer,
      deliveryCountry: localStorageOrder.deliveryCountry,
      type: SET_UP_UNFINISHED_ORDER
    })
}

function mapToInProgressOrder (order: LocalStorageOrder) {
  return async (dispatch: DispatchFunc): Promise<InProgressOrder> => {
    const itemsToBeCreated = cloneDeep(order.itemsToBeCreated)

    for (const item of itemsToBeCreated) {
      item.id = (await dispatch(setNewBasketItemId())).id
    }

    return {
      id: order.id,
      merchantOrderId: order.merchantOrderId,
      itemsToBeCreated,
      status: 'None',
      orderError: false,
      submitting: false,
      submitted: false
    }
  }
}
