import { useState } from 'react'
import toast from 'react-hot-toast'

import { ManageActionsValueType } from '.'
import { fetcher } from '../../../helpers'
import OverlayPortal from '../../OverlayPortal'
import ConfirmationModal from '../../ConfirmationModal'
import { createErrorToast, createToast } from '../../Toast'
import { IMAGE_LIBRARY_FULL_PAGE_TOASTS } from '../constants'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface DeleteImagesModalPropsInterface {
  actionModalToOpen: ManageActionsValueType | null
  imageLibraryImages: ImageLibraryImageInterface[]
  onSetActionModalToOpen: (isOpen: ManageActionsValueType | null) => void
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
}

export function DeleteImagesModal({
  actionModalToOpen,
  imageLibraryImages,
  onSetActionModalToOpen,
  onSetImageLibraryMode
}: DeleteImagesModalPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const [imageDeletionStatus, setImageDeletionStatus] = useState<StatusType>('idle')

  async function handleDeleteImages() {
    toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
    setImageDeletionStatus('loading')
    window.analytics.track('Image library - clicked: Delete images')

    try {
      const requests = imageLibraryImages.map((image) =>
        fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${image.public_id}`, {
          method: 'DELETE'
        })
      )
      const result = await Promise.allSettled(requests)
      await mutateImageLibraryImages()

      const rejectedRequests = result.filter((result) => result.status === 'rejected')

      if (rejectedRequests.length > 0) {
        const errors = rejectedRequests.map((error) => {
          const fetchErrorResponse = error as PromiseRejectedResult
          return fetchErrorResponse?.reason?.status ?? '0'
        })

        setImageDeletionStatus('error')
        createErrorToast({
          errorCode: errors.join(', '),
          heading: 'Failed to delete all images',
          id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR
        })
        onSetActionModalToOpen(null)
        onSetImageLibraryMode('select')
        return
      }

      setImageDeletionStatus('success')
      createToast({
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.SUCCESS,
        heading: `Image${requests.length > 1 ? 's' : ''} deleted`,
        type: 'success'
      })
      onSetActionModalToOpen(null)
      onSetImageLibraryMode('select')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createErrorToast({
        errorMessage: errorResponse.message,
        heading: 'Failed to delete all images',
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR,
        errorCode: errorResponse.status
      })
      setImageDeletionStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setImageDeletionStatus('idle')
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.SUCCESS)
    }
    onSetActionModalToOpen(null)
  }

  const hasMoreThanOneImageSelected = imageLibraryImages.length > 1

  const headerText = hasMoreThanOneImageSelected ? `Delete ${imageLibraryImages.length} images` : 'Delete image'
  const messageText = hasMoreThanOneImageSelected ? 'these images' : 'this image'
  const continueButtonText = hasMoreThanOneImageSelected ? 'Delete images' : 'Delete image'

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Cancel', theme: 'greyscale', variant: 'tertiary' }}
        closeOnEscape={imageDeletionStatus !== 'loading'}
        closeOnInteractionOutside={imageDeletionStatus !== 'loading'}
        continueButton={{ text: continueButtonText, theme: 'danger' }}
        isLoading={imageDeletionStatus === 'loading'}
        title={headerText}
        open={actionModalToOpen === 'delete'}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleDeleteImages}
      >
        <div>
          <p>This action cannot be undone.</p>
          <p className="pt-8">Any in-progress orders using {messageText} will not be affected.</p>
        </div>
      </ConfirmationModal>

      {imageDeletionStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
