// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, GetStateFunc, ThunkAsync } from '../../types'
import { selectPwintyApiSettings } from '../../selectors/appSettings'

export const REQUEST_CREATE_KITE_ACCOUNT = 'REQUEST_CREATE_KITE_ACCOUNT'
export const CREATE_KITE_ACCOUNT_SUCCESS = 'CREATE_KITE_ACCOUNT_SUCCESS'
export const CREATE_KITE_ACCOUNT_ERROR = 'CREATE_KITE_ACCOUNT_ERROR'

export function createKiteAccount (): ThunkAsync<void> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint

    await dispatch({
      [RSAA]: {
        endpoint: endpoint + `/api/Account/Kite`,
        method: 'POST',
        types: [REQUEST_CREATE_KITE_ACCOUNT, CREATE_KITE_ACCOUNT_SUCCESS, CREATE_KITE_ACCOUNT_ERROR]
      }
    })
  }
}
