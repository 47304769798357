// @flow
import { apparelSizes } from '../data/apparelSizes'
import type { SizeAndScore } from '../data/apparelSizes'
import type { FacetValue } from '../types'

export type SizeFacet = {|
  value: string,
  count: number,
  selected: boolean,
  enabled: boolean,
|}

type SizeElement = SizeFacet | string

export function sortApparelBySize (prev: SizeElement | FacetValue, next: SizeElement | FacetValue): number {
  const firstSizeAndScore = getSizeAndScore(prev)
  const secondSizeAndScore = getSizeAndScore(next)

  if (firstSizeAndScore && secondSizeAndScore) {
    return parseInt(firstSizeAndScore.score) - parseInt(secondSizeAndScore.score)
  }
  return 0
}

function getSizeAndScore (element: SizeElement | FacetValue): ?SizeAndScore {
  if (typeof element !== 'string' && element.value && typeof element.value === 'string') {
    return findSizeAndScore(element.value.toLowerCase())
  } else if (typeof element === 'string') {
    return findSizeAndScore(element.toLowerCase())
  }
  return null
}

function findSizeAndScore (sizeToCheck: string): ?SizeAndScore {
  return apparelSizes.find(size => size.value === sizeToCheck)
}
