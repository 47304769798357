import NotFound from '../NotFound'
import SupportLink from '../SupportLink'
import ClipboardCopy from '../ClipboardCopy'
import LoadingSpinner from '../LoadingSpinner'
import { Mockups3DPreview } from './components'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { DictionaryInterface, StatusType } from '../../interfaces'
import { ImageMockupInterface, useMockups, useSplitToggle } from '../../hooks'

interface MockupsPreviewInterface {
  attributes: DictionaryInterface<string>
  mockupErrorState: { message: string; code: string } | null
  mockupGenerationStatus: StatusType
  mockupData: ImageMockupInterface | null
  orientation: string
  orientationSelectionAvailable: boolean
  productSku: string
  selectedMockupView: string | null
  transformationId: string | null
}

export default function MockupPreview({
  attributes,
  mockupErrorState,
  mockupGenerationStatus,
  mockupData,
  orientation,
  orientationSelectionAvailable,
  productSku,
  selectedMockupView,
  transformationId
}: MockupsPreviewInterface) {
  const {
    isLoading,
    error: hasError,
    mockups,
    mockup
  } = useMockups({ attributes, productSku, orientation, orientationSelectionAvailable })

  const { splitIsOn: is3DMockupsOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_3D_MOCKUPS })

  if (is3DMockupsOn) {
    return (
      <Mockups3DPreview
        attributes={attributes}
        mockupErrorState={mockupErrorState}
        mockupGenerationStatus={mockupGenerationStatus}
        orientation={orientation}
        productSku={productSku}
        transformationId={transformationId}
        viewKey={selectedMockupView}
      />
    )
  }

  if (mockupGenerationStatus === 'error') {
    return <MockupError code={mockupErrorState?.code ?? '0'} message={mockupErrorState?.message ?? 'MGSE'} />
  }

  if (hasError) {
    return <MockupError code="UMS" message={hasError.message} />
  }

  if (mockupGenerationStatus === 'loading' || isLoading || !mockups || !mockupData) {
    return (
      <div className="relative block w-auto">
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
          <LoadingSpinner className="h-16 w-16" />
        </div>
      </div>
    )
  }

  if (!mockup) {
    return <MockupError code="NMA" message="no mockup" />
  }

  return (
    <div className="relative flex w-auto items-center justify-center">
      <div className="absolute">
        <LoadingSpinner className="h-16 w-16" />
      </div>
      <img className="relative h-full w-full max-w-none object-contain" id={productSku} src={mockupData.url} />
    </div>
  )
}

function MockupError({ code, message }: { code: string | number; message: string }) {
  return (
    <div className="mt-12 flex w-full justify-center">
      <NotFound
        heading="Failed to load mockup"
        body={
          <p className="break-words text-center">
            An error occurred while loading the mockup. Please try again later and <SupportLink>contact us</SupportLink>{' '}
            if this issue persists ({message} - code {code} <ClipboardCopy showText={false} text={code.toString()} />)
          </p>
        }
      >
        <></>
      </NotFound>
    </div>
  )
}
