// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function StripeLogo ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="82" height="34" viewBox="0 0 82 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M81.7092 17.5674C81.7092 11.757 78.8947 7.17224 73.5156 7.17224C68.1137 7.17224 64.8453 11.757 64.8453 17.522C64.8453 24.3538 68.7038 27.8038 74.2419 27.8038C76.9428 27.8038 78.9855 27.1909 80.5289 26.3285V21.7891C78.9855 22.5608 77.2152 23.0374 74.9682 23.0374C72.7666 23.0374 70.8146 22.2657 70.565 19.5875H81.6638C81.6638 19.2924 81.7092 18.1122 81.7092 17.5674ZM70.4969 15.4112C70.4969 12.8465 72.063 11.7797 73.4929 11.7797C74.8774 11.7797 76.3527 12.8465 76.3527 15.4112H70.4969Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M56.0844 7.17224C53.8601 7.17224 52.4302 8.2163 51.6358 8.9426L51.3407 7.53539H46.3474V34L52.0216 32.7971L52.0443 26.3738C52.8614 26.964 54.0644 27.8038 56.0617 27.8038C60.1244 27.8038 63.824 24.5354 63.824 17.3405C63.8014 10.7584 60.0564 7.17224 56.0844 7.17224ZM54.7226 22.8104C53.3835 22.8104 52.5891 22.3338 52.0443 21.7437L52.0216 13.3231C52.6118 12.6649 53.4289 12.211 54.7226 12.211C56.788 12.211 58.2179 14.526 58.2179 17.4993C58.2179 20.5407 56.8107 22.8104 54.7226 22.8104Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M38.5391 5.83311L44.236 4.60748V0L38.5391 1.20294V5.83311Z" fill="white"/>
      <path d="M44.236 7.55798H38.5391V27.4178H44.236V7.55798Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.4341 9.23779L32.071 7.55822H27.1685V27.418H32.8427V13.9588C34.1818 12.2111 36.4515 12.5288 37.1551 12.7785V7.55822C36.4288 7.28585 33.7733 6.78652 32.4341 9.23779Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.0856 2.63281L15.5476 3.81305L15.5249 21.9933C15.5249 25.3524 18.0443 27.8264 21.4034 27.8264C23.2646 27.8264 24.6264 27.486 25.3754 27.0774V22.4699C24.6491 22.765 21.063 23.8091 21.063 20.4499V12.3925H25.3754V7.55805H21.063L21.0856 2.63281Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.74232 13.3231C5.74232 12.4379 6.46863 12.0975 7.67156 12.0975C9.39653 12.0975 11.5754 12.6195 13.3004 13.5501V8.2163C11.4166 7.4673 9.55541 7.17224 7.67156 7.17224C3.06409 7.17224 0 9.57812 0 13.5955C0 19.8598 8.62483 18.8612 8.62483 21.5621C8.62483 22.6062 7.71696 22.9466 6.44593 22.9466C4.56208 22.9466 2.15621 22.1749 0.249666 21.1309V26.5327C2.36048 27.4406 4.49399 27.8265 6.44593 27.8265C11.1669 27.8265 14.4126 25.4887 14.4126 21.4259C14.3899 14.6622 5.74232 15.8652 5.74232 13.3231Z" fill="white"/>
    </svg>
  )
}
