// @flow
import { RSAA } from 'redux-api-middleware'
import type {
  GetStateFunc,
  DispatchFunc,
  ProductPrice,
  ThunkAsync } from '../../types'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import { LOG_STATUS } from '../../data/rsaa'
import { removeDuplicates } from '../../helpers/array'

export const FETCH_PRICES = 'FETCH_PRICES'
export const RECEIVE_PRICES = 'RECEIVE_PRICES'
export const FETCH_PRICES_ERROR = 'FETCH_PRICES_ERROR'

export function fetchPrices (skus: string[], loggerId?: string): ThunkAsync<?ProductPrice[]> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    if (!skus || skus.length === 0) {
      return null
    }

    const optionalMeta = loggerId ? { loggerId } : {}

    const result = await dispatch({
      [RSAA]: {
        endpoint: buildUrl(getState),
        body: JSON.stringify({ skus: removeDuplicates(skus) }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        types: [
          { type: FETCH_PRICES, meta: { enhancements: [ LOG_STATUS ], ...optionalMeta } },
          RECEIVE_PRICES,
          FETCH_PRICES_ERROR
        ]
      }
    })

    if (result.type === RECEIVE_PRICES) {
      return result.payload.prices
    }
  }
}

function buildUrl (getState: GetStateFunc) {
  const deliveryCountry = getState().manualOrderForm.deliveryCountry
  const endpoint = selectPwintyApiSettings().endpoint

  return `${endpoint}/v3.0/Catalogue/Prodigi Direct/destination/${deliveryCountry}/Prices`
}
