import Countries from 'country-list'
import useSWR, { SWRConfiguration } from 'swr'

import { fetcher, formatToSentenceCase } from '../helpers'
import { FetchErrorInterface, V3CountryInterface } from '../interfaces'

type CountryErrorType = unknown

interface CountriesSuccessInterface {
  outcome: string
  data: V3CountryInterface[]
}

export function useCountries(config?: SWRConfiguration) {
  const countriesUrl = `${process.env.REACT_APP_ENDPOINT}/v3.0/countries`

  const { data: countriesData, error } = useSWR<V3CountryInterface[], FetchErrorInterface<CountryErrorType>>(
    countriesUrl,
    countriesFetcher,
    config
  )

  const formattedCountries: V3CountryInterface[] | undefined = countriesData
    ?.map((countryData) => ({
      name: Countries().getName(countryData.isoCode) ?? formatToSentenceCase(countryData.name) ?? countryData.isoCode,
      isoCode: countryData.isoCode
    }))
    ?.filter((countryData) => {
      if (countryData.name.toUpperCase() === 'V1DUMMY') {
        return false
      }

      return Boolean(countryData.name)
    })
    ?.sort((a, b) => a.name.localeCompare(b.name))

  return {
    countries: formattedCountries,
    isLoading: !countriesData && !error,
    error
  }
}

async function countriesFetcher(url: string) {
  return fetcher<CountriesSuccessInterface>(url).then((successResponse) => successResponse.data)
}
