// @flow
import type { Element } from 'react'
import React from 'react'
import cx from 'classnames'
import styles from './LoadingEllipsis.module.css'

type Props = {|
  className?: string
|}

export default function LoadingEllipsis({ className }: Props): Element<'div'> {
  return (
    <div className={className} data-testid="loading-ellipsis">
      <span className={styles.loading}>.</span>
      <span className={cx(styles.loading, styles.secondDot)}>.</span>
      <span className={cx(styles.loading, styles.thirdDot)}>.</span>
    </div>
  )
}
