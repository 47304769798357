import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from '../../constants'
import { useMerchantService } from '../../hooks'
import { OrdersListItem, LoadingOrderItem } from './components'
import type { OrdersDataSuccessInterface, OrdersListItemInterface } from '../../interfaces'

interface OrdersListInterface {
  hasZeroOrdersWithAppliedFilters: boolean
  isLoadingFirstPageOrders: boolean
  isLoadingNextPageOrders: boolean
  orderPages?: OrdersDataSuccessInterface[]
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export default function OrdersList({
  hasZeroOrdersWithAppliedFilters,
  isLoadingFirstPageOrders,
  isLoadingNextPageOrders,
  orderPages
}: OrdersListInterface) {
  const { merchantDetails, isLoading: isLoadingMerchantDetails } = useMerchantService()

  if (isLoadingFirstPageOrders || !orderPages || isLoadingMerchantDetails || !merchantDetails) {
    return (
      <OrdersListContainer>
        <LoadingOrders />
      </OrdersListContainer>
    )
  } else if (hasZeroOrdersWithAppliedFilters) {
    return (
      <OrdersListContainer>
        <NoOrdersFoundWithAppliedFilters />
      </OrdersListContainer>
    )
  }

  return (
    <OrdersListContainer>
      <OrdersListTable
        orders={orderPages
          .map((orderResponse) => {
            return orderResponse.data.orderSummaries.map((order, index) => ({
              ...order,
              animationDelay: index * 0.05
            }))
          })
          .flat()}
        isLoadingNextPageOrders={isLoadingNextPageOrders}
      />
    </OrdersListContainer>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
function OrdersListContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col font-body">
      <div className="-my-2 -mx-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full p-2 pb-0 align-middle sm:p-6 sm:pb-0 lg:p-8 lg:pb-0">
          <div className="overflow-hidden border">
            <table className="h-full min-w-full bg-white">
              <thead className="hidden border-b text-left text-sm font-medium sm:table-header-group">
                <tr>
                  <th scope="col" className="py-6 pl-8 pr-4">
                    Order
                  </th>
                  <th scope="col" className="px-4 py-6">
                    Customer
                  </th>
                  <th scope="col" className="px-4 py-6">
                    Date
                  </th>
                  <th scope="col" className="px-4 py-6">
                    Source
                  </th>
                  <th scope="col" className="py-6 pl-4 pr-8 text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="font-normal">{children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function LoadingOrders({ numLoadingOrders = 5 }: { numLoadingOrders?: number }) {
  return (
    <>
      {[...Array(numLoadingOrders)].map((_, i) => (
        <LoadingOrderItem dataTest="loading-order-item-skeleton" key={i} />
      ))}
    </>
  )
}

function OrdersListTable({
  isLoadingNextPageOrders,
  orders
}: {
  isLoadingNextPageOrders: boolean
  orders: OrdersListItemInterface[]
}) {
  return (
    <>
      {orders.map((order, index) => (
        <OrdersListItem key={order.id} ordersListItem={order} index={index} />
      ))}
      {isLoadingNextPageOrders && <LoadingOrders />}
    </>
  )
}

function NoOrdersFoundWithAppliedFilters() {
  const numberOfColumns = 5

  return (
    <motion.tr initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }}>
      <td colSpan={numberOfColumns}>
        <div
          className="flex flex-col items-center justify-center gap-y-6 py-8"
          data-test="orders-list__no-orders-found-with-filters"
        >
          <span>No orders found using selected filters.</span>
          <Link
            className="btn btn-secondary"
            to={ROUTE_PATHS.ORDERS}
            data-test="orders-list__no-orders-found-with-filters-clear"
          >
            Clear filters
          </Link>
        </div>
      </td>
    </motion.tr>
  )
}
