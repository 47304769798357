// @flow
import countries from 'country-list'

export function getCountryName (iso2: string): string {
  if (iso2 === 'XK') {
    return 'Kosovo'
  } else if (iso2 === 'AN') {
    return 'Netherlands Antilles'
  }

  return countries().getName(iso2)
}
