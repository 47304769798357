import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import {
  formatCountyLabel,
  formatPostcodeLabel,
  isCountyOrStateRequired,
  isPostcodeRequired,
  isPostcodeValid
} from '../../../../helpers'
import Button from '../../../Button'
import FormItem from '../../../FormItem'
import TextField from '../../../TextField'
import { useUser } from '../../../../hooks'
import { createToast } from '../../../Toast'
import { StatusEnum } from '../../../../enums'
import { updateUserSuccess } from '../../../../../actions/user'
import { CompanyDetailsFormDataInterface } from '../../interfaces'
import { areCompanyDetailsValid, updateUserSettings } from '../../helpers'
import { FetchErrorInterface, UserInterface } from '../../../../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../constants'

export function BillingInformationForm({ onSuccess }: { onSuccess?: () => void }) {
  const { user, mutateUser } = useUser()
  const dispatch = useDispatch()

  const [companyDetailsUpdateStatus, setCompanyDetailsUpdateStatus] = useState(StatusEnum.Idle)
  const [companyDetailsFormData, setCompanyDetailsFormData] = useState<CompanyDetailsFormDataInterface>({
    companyName: {
      value: user?.company.name ?? user?.fullName ?? ''
    },
    addressLine1: {
      value: user?.company.address1 ?? ''
    },
    addressLine2: {
      value: user?.company.address2 ?? ''
    },
    city: {
      value: user?.company.townCity ?? ''
    },
    county: {
      value: user?.company.countyState ?? ''
    },
    postCode: {
      value: user?.company.postcode ?? ''
    }
  })

  async function handleCompanyDetailsUpdate() {
    if (!user) {
      createToast({
        content: `Please try again later`,
        footer: 'Code: NUE',
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      return
    }

    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setCompanyDetailsUpdateStatus(StatusEnum.Loading)

    const { companyName, addressLine1, addressLine2, city, county, postCode } = companyDetailsFormData

    try {
      const newUserSettings: UserInterface = {
        ...user,
        company: {
          ...user.company,
          name: companyName.value,
          address1: addressLine1.value,
          address2: addressLine2.value,
          townCity: city.value,
          countyState: county.value,
          postcode: postCode.value
        }
      }

      await updateUserSettings(newUserSettings)

      mutateUser(newUserSettings)
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setCompanyDetailsUpdateStatus(StatusEnum.Success)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())

      onSuccess?.()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later (${errorResponse.status ?? errorResponse.name})`,
        duration: Infinity,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setCompanyDetailsUpdateStatus(StatusEnum.Error)
    }
  }

  if (!user) {
    throw Error('No user')
  }

  const isCompanyDetailsFormValid = areCompanyDetailsValid({
    companyName: companyDetailsFormData.companyName.value,
    addressLine1: companyDetailsFormData.addressLine1.value,
    city: companyDetailsFormData.city.value,
    county: companyDetailsFormData.county.value,
    postCode: companyDetailsFormData.postCode.value,
    countryCode: user.company.countryCode
  })
  const isCountyOrStateInputRequired = isCountyOrStateRequired(user.company.countryCode)
  const isPostcodeInputRequired = isPostcodeRequired(user.company.countryCode)
  const isPostcodeInputValid = isPostcodeValid(user.company.countryCode, companyDetailsFormData.postCode.value)

  return (
    <form
      className="max-w-xl"
      onSubmit={(event) => {
        event.preventDefault()
        handleCompanyDetailsUpdate()
      }}
    >
      <FormItem
        inputField={
          <TextField
            required
            type="text"
            value={companyDetailsFormData.companyName.value}
            onChange={(event) => {
              setCompanyDetailsFormData({
                ...companyDetailsFormData,
                companyName: {
                  ...companyDetailsFormData.companyName,
                  value: event.target.value
                }
              })
            }}
          />
        }
        labelText="Billing contact name"
        required
        valid={Boolean(companyDetailsFormData.companyName.value)}
        validate={Boolean(companyDetailsFormData.companyName.value)}
      />

      <FormItem
        inputField={
          <TextField
            required
            type="text"
            value={companyDetailsFormData.addressLine1.value}
            onChange={(event) => {
              setCompanyDetailsFormData({
                ...companyDetailsFormData,
                addressLine1: {
                  ...companyDetailsFormData.addressLine1,
                  value: event.target.value
                }
              })
            }}
          />
        }
        labelText="Address"
        required
        valid={Boolean(companyDetailsFormData.addressLine1.value)}
        validate={Boolean(companyDetailsFormData.addressLine1.value)}
      />

      <FormItem
        labelClassName="mt-0 pt-0"
        inputField={
          <TextField
            type="text"
            value={companyDetailsFormData.addressLine2.value}
            onChange={(event) => {
              setCompanyDetailsFormData({
                ...companyDetailsFormData,
                addressLine2: {
                  ...companyDetailsFormData.addressLine2,
                  value: event.target.value
                }
              })
            }}
          />
        }
        validate={false}
      />

      <FormItem
        inputField={
          <TextField
            required
            type="text"
            value={companyDetailsFormData.city.value}
            onChange={(event) => {
              setCompanyDetailsFormData({
                ...companyDetailsFormData,
                city: {
                  ...companyDetailsFormData.city,
                  value: event.target.value
                }
              })
            }}
          />
        }
        labelText="Town/City"
        required
        valid={Boolean(companyDetailsFormData.city.value)}
        validate={Boolean(companyDetailsFormData.city.value)}
      />

      <FormItem
        inputField={
          <TextField
            required={isCountyOrStateInputRequired}
            type="text"
            value={companyDetailsFormData.county.value}
            onChange={(event) => {
              setCompanyDetailsFormData({
                ...companyDetailsFormData,
                county: {
                  ...companyDetailsFormData.county,
                  value: event.target.value
                }
              })
            }}
          />
        }
        labelText={formatCountyLabel(user.company.countryCode)}
        required={isCountyOrStateInputRequired}
        valid={isCountyOrStateInputRequired ? Boolean(companyDetailsFormData.county.value) : true}
        validate={Boolean(isCountyOrStateInputRequired && companyDetailsFormData.county.value)}
      />

      <FormItem
        inputField={
          <TextField
            required={isPostcodeInputRequired}
            type="text"
            value={companyDetailsFormData.postCode.value}
            onChange={(event) => {
              setCompanyDetailsFormData({
                ...companyDetailsFormData,
                postCode: {
                  ...companyDetailsFormData.postCode,
                  value: event.target.value
                }
              })
            }}
          />
        }
        labelText={formatPostcodeLabel(user.company.countryCode)}
        required={isPostcodeInputRequired}
        valid={isPostcodeInputValid}
        validate={Boolean(isPostcodeInputRequired && companyDetailsFormData.postCode.value)}
      />

      <Button
        className="mt-8"
        disabled={!isCompanyDetailsFormValid}
        isLoading={companyDetailsUpdateStatus === StatusEnum.Loading}
        type="submit"
        variant="primary"
      >
        Update details
      </Button>
    </form>
  )
}
