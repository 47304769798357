// @flow
import React from 'react'
import type { ChildrenArray } from 'react'

import styles from '../AppSettings.module.css'

type Props = {|
  children: ChildrenArray<*>,
  subTitle: string,
  title: string
|}

class BrandSettingBlock extends React.Component<Props> {
  render (): React$Node {
    return (
      <section className={styles.brandBlock}>
        <div className={styles.brandBlockTitle}>{this.props.title}</div>
        <div className={styles.brandBlockSubtitle}>{this.props.subTitle}</div>
        {this.props.children}
      </section>
    )
  }
}

export default BrandSettingBlock
