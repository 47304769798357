import { PhotoIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import Button from '../../Button'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { LineItemChannelInfo } from './LineItemChannelInfo.component'
import { OrderDetailItemInterface } from '../../../interfaces'

export function LineItemError({
  errorCode,
  lineItem,
  onChooseProduct
}: {
  errorCode: string
  lineItem: OrderDetailItemInterface
  onChooseProduct: () => void
}) {
  return (
    <>
      <td className="col-span-3 row-span-5 m-2 mr-4 w-[160px] align-top sm:col-span-1 md:p-2 md:pb-12 md:pr-4 xl:pr-8">
        <div className="grid h-[160px] w-[160px] place-content-center border-4 border-gray-200">
          <PhotoIcon className="h-10 w-10 text-gray-600" />
        </div>
      </td>

      <td className="col-span-3 p-2 align-top sm:col-start-2 md:table-cell">
        <LineItemChannelInfo lineItem={lineItem} />

        <div className="mt-6 max-w-[60ch] break-words text-red-500" style={{ wordBreak: 'break-word' }}>
          An error occurred while fetching product info, please try again later or{' '}
          <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists. Code:{' '}
          {errorCode} <ClipboardCopy className="align-top" text={errorCode} showText={false} />
        </div>

        <Button
          className="mt-6"
          startIcon={<MagnifyingGlassIcon className="h-7 w-7" />}
          variant="secondary"
          onClick={onChooseProduct}
        >
          Choose product
        </Button>
      </td>
    </>
  )
}
