// @flow
import type {
  DispatchFunc,
  Dictionary,
  Action,
  AppState,
  Response,
  MultiAssetTemplates,
  MultiAssetTemplate,
  Template,
  GetStateFunc,
  Orientation,
  Thunk
} from '../../types'
import { RSAA } from 'redux-api-middleware'
import { selectTemplateServiceUrl } from '../../selectors/appSettings'
import { getProductTemplates } from '../../selectors/images'
import { buildTemplateId } from './buildTemplateId'
import { filterOutInvalidTemplateAttributes } from './filterOutInvalidTemplateAttributes'
import { getRotatedDimensions } from '../../helpers/imageEditor'
import { TEMPLATE_SIZE_IN_PX } from '../../data/constants'
import { convertMmToRoundedPx } from '../../helpers/unitConversions/convertMmToRoundedPx'
import { entries } from '../../helpers/dictionary'

export const FETCH_TEMPLATE = 'FETCH_TEMPLATE'
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS'
export const FETCH_TEMPLATE_ERROR = 'FETCH_TEMPLATE_ERROR'

export function fetchTemplate (sku: string, attributes: Dictionary<string>): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const filteredAttributes = filterOutInvalidTemplateAttributes(attributes)
    const templateId = buildTemplateId(sku, attributes)

    const productTemplates = getProductTemplates(getState(), sku, attributes)
    if (productTemplates) {
      return dispatch({ type: FETCH_TEMPLATE_SUCCESS, meta: { id: templateId }, payload: productTemplates })
    }

    return dispatch({
      [RSAA]: {
        endpoint: buildMultiAssetEndpoint(sku, filteredAttributes),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'GET',
        types: [
          { type: FETCH_TEMPLATE, meta: { id: templateId } },
          {
            type: FETCH_TEMPLATE_SUCCESS,
            meta: { id: templateId },
            payload: mapMultiAssetTemplateServiceResponse
          },
          { type: FETCH_TEMPLATE_ERROR, meta: { id: templateId } }
        ]
      }
    })
  }
}

export async function mapMultiAssetTemplateServiceResponse (_: Action, __: AppState, rawResponse: Response<MultiAssetTemplates>): Promise<MultiAssetTemplates> {
  const response = await rawResponse.json()

  const mappedTemplateServiceData = entries(response.printAreas).reduce((printAreasAcc, [printArea, printAreaTemplatesData]) => {
    const orientations: Dictionary<?MultiAssetTemplate> = printAreaTemplatesData.orientations
    printAreasAcc[printArea] = {
      orientations: mapMultiAssetOrientationResponse(orientations)
    }
    return printAreasAcc
  }, {})

  return {
    printAreas: mappedTemplateServiceData
  }
}

function mapMultiAssetOrientationResponse (orientations: Dictionary<?MultiAssetTemplate>): Dictionary<MultiAssetTemplate> {
  return entries(orientations).reduce((orientationsAcc, [orientation, orientationData]) => {
    if (!orientationData) {
      return orientationsAcc
    }

    const lowerCaseOrientationName: Orientation = (orientation.toLowerCase(): any)

    const rotatedPrintResolution = getRotatedDimensions(
      lowerCaseOrientationName,
      orientationData.printResolution.width,
      orientationData.printResolution.height
    )

    const rotatedCropRectangleDimensions = getRotatedDimensions(
      lowerCaseOrientationName,
      orientationData.cropRectangle.width,
      orientationData.cropRectangle.height
    )

    const templateOrientationDataWithCorrectDimensions = {
      ...orientationData,
      printResolution: rotatedPrintResolution,
      cropRectangle: {
        ...orientationData.cropRectangle,
        ...rotatedCropRectangleDimensions
      }
    }

    orientationsAcc[lowerCaseOrientationName] = templateOrientationDataWithCorrectDimensions

    return orientationsAcc
  }, {})
}

export function setTemplateProps (template: ?Template, orientation: Orientation): ?MultiAssetTemplate {
  if (!template || !template.outputDpi) {
    return null
  }

  const printDpi = template.outputDpi
  const printDimensionsRotatedToMatchOrientation = getRotatedDimensions(
    orientation,
    template.printedAreaDimensions.width,
    template.printedAreaDimensions.height
  )

  return {
    cropRectangle: template.cropRectangle,
    imageUrls: {
      overlay: template.overlayUrl,
      underlay: template.underlayUrl
    },
    printDpi,
    printResolution: {
      height: convertMmToRoundedPx(printDimensionsRotatedToMatchOrientation.height, printDpi),
      width: convertMmToRoundedPx(printDimensionsRotatedToMatchOrientation.width, printDpi)
    },
    templateResolution: {
      height: TEMPLATE_SIZE_IN_PX,
      width: TEMPLATE_SIZE_IN_PX
    }
  }
}

function buildMultiAssetEndpoint (sku: string, attributes: Dictionary<string>): string {
  const baseEndpoint = `${selectTemplateServiceUrl()}/MultiArea/${sku}`

  const attrDictAsJsonString = encodeURIComponent(JSON.stringify(attributes))

  const attrSearchQuery = Object.keys(attributes).length
    ? `?attrs=${attrDictAsJsonString}`
    : ''

  return encodeURI(baseEndpoint + `${attrSearchQuery}`)
}
