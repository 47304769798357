import Modal from '../Modal'
import ImageEditor, { ImageEditorPropsInterface } from '../ImageEditor'

export interface ImageEditorModalPropsInterface extends ImageEditorPropsInterface {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export default function ImageEditorModal({ open, setOpen, ...props }: ImageEditorModalPropsInterface) {
  return (
    <Modal
      className="full-viewport-height max-h-[unset] w-100vw overflow-auto rounded-none bg-transparent"
      showBorder={false}
      showHeader={false}
      open={open}
      setOpen={setOpen}
      closeOnInteractionOutside={false}
      closeOnEscape={false}
    >
      <ImageEditor {...props} />
    </Modal>
  )
}
