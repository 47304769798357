// @flow
type VoidFunc = () => void

export type Cancel = VoidFunc

export type CancellablePromise<T> = {|
  run: (onStart?: VoidFunc) => Promise<T>,
  cancel: Cancel
 |}

export const PROMISE_CANCELLED_MESSAGE = 'Operation cancelled'

export function makeCancellable<T> (wrappedPromise: () => Promise<T>): CancellablePromise<?T> {
  let hasCancelled = false

  return {
    run: (onStart?: VoidFunc) => {
      return new Promise(async (resolve, reject) => {
        onStart && onStart()

        try {
          const result = await wrappedPromise()
          if (!hasCancelled) {
            resolve(result)
          } else {
            reject(new Error(PROMISE_CANCELLED_MESSAGE))
          }
        } catch (error) {
          reject(new Error(error))
        }
      })
    },
    cancel: () => { hasCancelled = true }
  }
}

export function sleep<T> (timeout: number, result: ?T): Promise<?T> {
  return new Promise(resolve => {
    setTimeout(() => { resolve(result) }, timeout)
  })
}
