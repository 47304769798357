// @flow

export function identity<T> (x: T): T {
  return x
}

export function returnFirstArg<T1> (firstArg: T1): T1 {
  return firstArg
}

export function returnSecondArg<T1, T2> (_: T1, secondArg: T2): T2 {
  return secondArg
}

export function returnThirdArg<T1, T2, T3> (_: T1, _2: T2, thirdArg: T3): T3 {
  return thirdArg
}

export function returnFourthArg<T1, T2, T3, T4> (_: T1, _2: T2, _3: T3, fourthArg: T4): T4 {
  return fourthArg
}
