import { formatCost } from '../../../helpers/formatCost'
import {
  FulfillmentIssueInterface,
  OrderAdditionalChargeInterface,
  OrderFulfillmentFailedInterface,
  OrderHistoryDataType,
  OrderPausedInformationInterface,
  OrderSubmittedInterface,
  PackingSlipDownloadFailedInterface,
  PackingSlipUpdatedInterface,
  PaymentReceivedInterface,
  RecipientUpdatedInterface,
  ShipmentCarrierAndServiceUpdatedInterface,
  ShipmentInformationProvidedInterface,
  ShipmentShippedInterface,
  ShippingMethodUpdatedInterface
} from '../interfaces'
import { OrderHistoryTypeEnum } from '../enums'
import { formatIssueComment } from './formatIssueComment.helper'
import ClipboardCopy from '../../ClipboardCopy'

export function formatOrderHistoryData(orderHistoryType: OrderHistoryTypeEnum, data: OrderHistoryDataType) {
  if (!data) {
    return undefined
  }

  switch (orderHistoryType) {
    case OrderHistoryTypeEnum.PaymentReceived:
      return <span>{formatCost((data as PaymentReceivedInterface).cost)}</span>
    case OrderHistoryTypeEnum.RecipientUpdated:
      return <FormatRecipientUpdated addressData={data as RecipientUpdatedInterface} />
    case OrderHistoryTypeEnum.ShippingMethodUpdated:
      const methodUpdate = data as ShippingMethodUpdatedInterface
      return <ComparisonFormat oldValue={methodUpdate.oldShippingMethod} newValue={methodUpdate.newShippingMethod} />
    case OrderHistoryTypeEnum.PackingSlipUpdated:
      const update = data as PackingSlipUpdatedInterface
      return <ComparisonFormat oldValue={update.oldPackingSlipUrl} newValue={update.newPackingSlipUrl} />
    case OrderHistoryTypeEnum.PackingSlipDownloadFailed:
      return <PackingSlipDownloadFailed data={data as PackingSlipDownloadFailedInterface} />
    case OrderHistoryTypeEnum.OrderAdditionalCharge:
      return <AdditionalChargeData chargeData={data as OrderAdditionalChargeInterface} />
    case OrderHistoryTypeEnum.ShipmentShipped:
      return <FormatShipmentShipped shippingData={data as ShipmentShippedInterface} />
    case OrderHistoryTypeEnum.ShipmentCarrierAndServiceUpdated:
      const carrier = data as ShipmentCarrierAndServiceUpdatedInterface
      return <ComparisonFormat oldValue={carrier.oldCarrierService} newValue={carrier.newCarrierService} />
    case OrderHistoryTypeEnum.FulfillmentIssueCreated:
      const issueCreatedData = data as FulfillmentIssueInterface
      return <FulfillmentIssueEvent issueData={issueCreatedData} />
    case OrderHistoryTypeEnum.FulfillmentIssueUpdated:
      const issueUpdatedData = data as FulfillmentIssueInterface
      return <FulfillmentIssueEvent issueData={issueUpdatedData} />
    case OrderHistoryTypeEnum.OrderFulfillmentFailed:
      const issueData = data as OrderFulfillmentFailedInterface
      return <OrderFulfillmentErrorEvent issueData={issueData} />
    case OrderHistoryTypeEnum.FulfillmentIssueClosed:
      const issueClosedData = data as FulfillmentIssueInterface
      return <FulfillmentIssueEvent issueData={issueClosedData} />
    case OrderHistoryTypeEnum.ShipmentInformationProvided:
      const shipmentInformation = data as ShipmentInformationProvidedInterface
      return <span>{shipmentInformation.informationText}</span>
    case OrderHistoryTypeEnum.OrderPaused:
      const pausedOrderInformation = data as OrderPausedInformationInterface
      return <OrderPausedEvent pausedOrderInformation={pausedOrderInformation} />
    case OrderHistoryTypeEnum.OrderSubmitted:
      const { message } = data as OrderSubmittedInterface
      if (message && message !== '') {
        return <span>{message}</span>
      }
      return undefined
    default:
      return undefined
  }
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

function OrderPausedEvent({ pausedOrderInformation }: { pausedOrderInformation: OrderPausedInformationInterface }) {
  if (pausedOrderInformation.submissionDateTime === '') {
    return <span>Order paused indefinitely</span>
  }

  if (!pausedOrderInformation.orderWindow) {
    return <span>Order paused</span>
  }

  return <span>Order edit window commenced for {pausedOrderInformation.orderWindow}</span>
}

function FormatRecipientUpdated({ addressData }: { addressData: RecipientUpdatedInterface }) {
  const { newAddress, oldAddress } = addressData

  return (
    <div className="grid grid-cols-1 gap-4 md:flex md:flex-wrap">
      <div className="grid grid-cols-[40px_max-content] gap-4">
        <span>From</span>
        <span className="flex w-fit flex-col">
          <span>{oldAddress.name}</span>
          <span>{oldAddress.address.line1}</span>
          <span>{oldAddress.address.line2}</span>
          <span>{oldAddress.address.townOrCity}</span>
          <span>{oldAddress.address.stateOrCounty}</span>
          <span>{oldAddress.address.postcodeOrZipCode}</span>
          <span>{oldAddress.address.countryCode}</span>
          <span>{oldAddress.phone}</span>
          <span>{oldAddress.email}</span>
        </span>
      </div>

      <div className="grid grid-cols-[40px_max-content] gap-4">
        <span className="sm:text-center sm:align-middle">to</span>
        <span className="flex w-fit flex-col">
          <span>{newAddress.name}</span>
          <span>{newAddress.address.line1}</span>
          <span>{newAddress.address.line2}</span>
          <span>{newAddress.address.townOrCity}</span>
          <span>{newAddress.address.stateOrCounty}</span>
          <span>{newAddress.address.postcodeOrZipCode}</span>
          <span>{newAddress.address.countryCode}</span>
          <span>{newAddress.phone}</span>
          <span>{newAddress.email}</span>
        </span>
      </div>
    </div>
  )
}

function AdditionalChargeData({ chargeData }: { chargeData: OrderAdditionalChargeInterface }) {
  return (
    <span>
      {formatCost(chargeData.totalCost, {
        signDisplay: chargeData.type.toLowerCase() === 'refund' ? 'never' : 'auto'
      })}
      : {chargeData.description}
    </span>
  )
}

function ComparisonFormat({ oldValue, newValue }: { oldValue: string; newValue: string }) {
  return (
    <span>
      From {oldValue || 'N/A'} to {newValue || 'N/A'}
    </span>
  )
}

function FormatShipmentShipped({ shippingData }: { shippingData: ShipmentShippedInterface }) {
  return (
    <span>
      Tracking ID{' '}
      <OrderHistoryLink
        content={shippingData.trackingNumber}
        url={shippingData.trackingUrl}
        title="Track shipment on the carrier site"
      />
    </span>
  )
}

function PackingSlipDownloadFailed({ data }: { data: PackingSlipDownloadFailedInterface }) {
  return (
    <div className="block max-w-[30ch] truncate sm:max-w-[50ch]">
      <span>
        Attempted URL: <ClipboardCopy className="align-text-bottom" text={data.packingSlipUrl} showText={false} />{' '}
        <span title={data.packingSlipUrl}>{data.packingSlipUrl}</span>
      </span>
    </div>
  )
}

function OrderHistoryLink({ content, url, title }: { content: string; url: string; title: string }) {
  return (
    <a
      className={`row-start-1 inline-flex cursor-pointer items-center space-x-1 truncate text-purple-600 underline-offset-1 hover:underline md:col-start-2 md:justify-end`}
      href={url}
      rel="noreferrer noopener"
      target="_blank"
      title={title}
    >
      <span>{content}</span>
    </a>
  )
}

function FulfillmentIssueEvent({ issueData }: { issueData: FulfillmentIssueInterface }) {
  const { actionType, actionDetail, comments, issueDetail } = issueData
  const latestComment = comments[comments.length - 1]
  return (
    <span className="flex flex-col space-y-2 pb-12">
      {issueDetail && (
        <span>
          <span className="text-gray-600">Detail: </span>
          {issueDetail}
        </span>
      )}
      {actionType && (
        <span>
          <span className="text-gray-600">Action taken: </span>
          {actionType}
          {actionDetail && <span> ({actionDetail})</span>}
        </span>
      )}
      {latestComment && (
        <span>
          <span className="text-gray-600">Comment added: </span>
          {formatIssueComment({ comment: latestComment })}
        </span>
      )}
    </span>
  )
}

function OrderFulfillmentErrorEvent({ issueData }: { issueData: OrderFulfillmentFailedInterface }) {
  const { message } = issueData

  return (
    <span className="flex flex-col space-y-2 pb-12">
      <span className="text-gray-600">Detail: </span>
      {message}
    </span>
  )
}
