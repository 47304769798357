// @flow
import { RSAA } from 'redux-api-middleware'
import { selectAuthServiceUrl } from '../../selectors/appSettings'
import type { GetJwtResult } from '../types'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'

export const REQUEST_JWT = 'REQUEST_JWT'
export const JWT_SUCCESS = 'JWT_SUCCESS'
const JWT_ERROR = 'JWT_ERROR'

export function getJwt(username: string, password: string): Thunk<GetJwtResult> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    return dispatch({
      [RSAA]: {
        endpoint: selectAuthServiceUrl() + '/api/auth',
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
        types: [REQUEST_JWT, JWT_SUCCESS, JWT_ERROR]
      }
    })
  }
}
