// @flow
import type { Action } from '../types'

export const PACKING_SLIP_UPLOAD_SUCCESS = 'PACKING_SLIP_UPLOAD_SUCCESS'
export const REMOVE_PACKING_SLIP = 'REMOVE_PACKING_SLIP'

export function removePackingSlip(): Action {
  return {
    type: REMOVE_PACKING_SLIP
  }
}

export function uploadPackingSlipV2(packingSlipUrl: string, fileName: string): Action {
  return {
    type: PACKING_SLIP_UPLOAD_SUCCESS,
    payload: {
      fileName,
      packingSlipUrl
    }
  }
}
