// @flow
import type { UpdatePrintAreas } from '../types'

export const UPDATE_PRINT_AREAS = 'UPDATE_PRINT_AREAS'

export function updatePrintAreas (
  basketItemId: number,
  printAreaNames: string[]
): UpdatePrintAreas {
  return {
    type: UPDATE_PRINT_AREAS,
    printAreaNames,
    basketItemId
  }
}
