import { History } from 'history'

import { useDebouncedCallback } from 'use-debounce'

export function useDebouncedUrlUpdate({ history, searchParams }: { history: History; searchParams: URLSearchParams }) {
  return useDebouncedCallback(
    ({
      selectedValues,
      queryParam,
      routePath,
      paramJoin
    }: {
      selectedValues: string[]
      queryParam: string
      routePath: string
      paramJoin: string
    }) => {
      const newSearchParams = new URLSearchParams(searchParams)

      if (selectedValues.length === 0) {
        newSearchParams.delete(queryParam)
      } else {
        const newStatusFilter = selectedValues.join(paramJoin)
        newSearchParams.set(queryParam, newStatusFilter)
      }

      const newUrl = `${routePath}?${newSearchParams.toString()}`
      if (newSearchParams.toString() === searchParams.toString()) {
        history.replace(newUrl)
      } else {
        history.push(newUrl)
      }
    },
    500
  )
}
