// @flow
import { values } from '../../helpers/dictionary'
import type { FormattedOrderItem, Dictionary } from '../../types'

export function updateOrderItemSkus (
  orderItems: Dictionary<FormattedOrderItem>,
  sku: string
): Dictionary<FormattedOrderItem> {
  return values(orderItems).reduce((orderItemsAcc, item) => {
    if (item.sku === sku) {
      orderItemsAcc[item.id] = {
        ...item,
        sku: ''
      }
    } else {
      orderItemsAcc[item.id] = item
    }

    return orderItemsAcc
  }, {})
}
