// @flow
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import type { ThunkAsync, DispatchFunc, GetStateFunc } from '../../types'

export const REQUEST_ORDER_DETAIL_V3 = 'REQUEST_ORDER_DETAIL_V3'
export const RECEIVE_ORDER_DETAIL_V3 = 'RECEIVE_ORDER_DETAIL_V3'
export const V3_FETCH_ORDER_DETAILS_ERROR = 'V3_FETCH_ORDER_DETAILS_ERROR'

export function fetchOrderDetailsFromApiV3 (orderId: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint
    await dispatch({
      [RSAA]: {
        endpoint: endpoint + `/v3.0/orders/${orderId}?timestamp=${Date.now()}`,
        method: 'GET',
        types: [REQUEST_ORDER_DETAIL_V3, RECEIVE_ORDER_DETAIL_V3, V3_FETCH_ORDER_DETAILS_ERROR]
      }
    })
  }
}
