import Skeleton from '../../Skeleton'

export function CountryPickerOptionSkeleton() {
  return (
    <li className="border-t border-gray-200 py-4 last:border-b">
      <div className="flex w-full items-center p-4">
        <Skeleton className="h-[32px] w-[56px]" />
        <Skeleton className="ml-12 h-[32px] w-full" />
      </div>
    </li>
  )
}
