// @flow
import type { Action } from '../../types'
import type { AddIgnoredOrder, RemoveIgnoredOrder } from '../types'
export const ADD_IGNORED_ORDER = 'ADD_IGNORED_ORDER'
export const REMOVE_IGNORED_ORDER = 'REMOVE_IGNORED_ORDER'
export const IGNORE_ALL_ORDERS = 'IGNORE_ALL_ORDERS'
export const SELECT_ALL_ORDERS = 'SELECT_ALL_ORDERS'

export function addIgnoredOrder (index: string): AddIgnoredOrder {
  return {
    type: ADD_IGNORED_ORDER,
    index
  }
}

export function removeIgnoredOrder (index: string): RemoveIgnoredOrder {
  return {
    type: REMOVE_IGNORED_ORDER,
    index
  }
}

export function ignoreAllOrders (): Action {
  return {
    type: IGNORE_ALL_ORDERS
  }
}

export function selectAllOrders (): Action {
  return {
    type: SELECT_ALL_ORDERS
  }
}
