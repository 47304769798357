import useSWR, { SWRConfiguration } from 'swr'
import { fetcher, removeDuplicates } from '../helpers'
import { FetchErrorInterface, ProductPriceInterface } from '../interfaces'

type PricingSuccessResponseType = { prices: ProductPriceInterface[] }
// TODO: Strong type after asking BE team what the error response might look like
type PricingErrorResponseType = unknown

export function usePricing({
  countryCode,
  skus,
  config
}: {
  countryCode?: string
  skus?: string[]
  config?: SWRConfiguration
}) {
  const uniqueSkus = skus ? removeDuplicates(skus) : null

  const { data: priceResults, error } = useSWR<
    PricingSuccessResponseType,
    FetchErrorInterface<PricingErrorResponseType>
  >(countryCode && uniqueSkus ? [countryCode, uniqueSkus] : null, pricingFetcher, config)

  return {
    priceResults,
    isLoading: !priceResults && !error,
    error
  }
}

function pricingFetcher(countryCode: string, skus: string[]) {
  return fetcher<PricingSuccessResponseType>(
    `${process.env.REACT_APP_ENDPOINT}/v3.0/Catalogue/Prodigi Direct/destination/${countryCode}/Prices`,
    {
      method: 'POST',
      body: JSON.stringify({ skus })
    }
  )
}
