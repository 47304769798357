// @flow
import type { ToggleIsUsSalesTaxCollectedByOrderId } from '../types'

export const TOGGLE_IS_US_SALES_TAX_COLLECTED_BY_ORDER_ID = 'TOGGLE_IS_US_SALES_TAX_COLLECTED_BY_ORDER_ID'

export function toggleIsUsSalesTaxCollectedByOrderId (orderId: string): ToggleIsUsSalesTaxCollectedByOrderId {
  return {
    type: TOGGLE_IS_US_SALES_TAX_COLLECTED_BY_ORDER_ID,
    orderId
  }
}
