import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import Button from '../Button'
import Skeleton from '../Skeleton'
import { ROUTE_PATHS } from '../../constants'
import ProductDetails from '../ProductDetails'
import { useProductDetails, useUser } from '../../hooks'
import { addBasketItem } from '../../../actions/manualOrderForm'
import { getDeliveryCountry } from '../../../selectors/manualOrderForm'
import { CountrySelection } from './components/CountrySelection.component'

export default function ProductSkuDetail() {
  const { user } = useUser()
  const { sku } = useParams<{ sku: string }>()
  const deliveryCountry: string = useSelector(getDeliveryCountry)

  const selectedCountryCode = deliveryCountry ?? user?.company.countryCode

  if (!user) {
    return <>No user - try refreshing the page or log in again</>
  }

  return (
    <div className="px-6">
      <header className="mb-10 flex flex-wrap items-center gap-3">
        <SkuDetailHeader />
      </header>

      <div className="mb-10 max-w-full items-center overflow-auto border border-gray-200 bg-white p-4 sm:flex">
        <CountrySelection />
      </div>

      <div className="p-2">
        <ProductDetails countryCode={selectedCountryCode} currencyCode={user.company.currencyCode} sku={sku} />
      </div>
    </div>
  )
}

function SkuDetailHeader() {
  const { sku } = useParams<{ sku: string }>()
  const { productDetails, isLoading: isLoadingProductDetails } = useProductDetails({ sku })
  const history = useHistory()
  const dispatch = useDispatch()

  function addToBasket() {
    dispatch(addBasketItem(sku))
    history.push(ROUTE_PATHS.MOF.BASKET)
  }

  return (
    <>
      {isLoadingProductDetails ? (
        <Skeleton className="h-7 w-48" />
      ) : (
        <h1 className="flex-1">{productDetails?.product.description}</h1>
      )}

      <div className="ml-auto">
        <Button onClick={addToBasket}>Add to basket</Button>
      </div>
    </>
  )
}
