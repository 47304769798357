import { motion } from 'framer-motion'
import { CATALOGUE_PRODUCTS } from '../../../constants'

export function ProductCategoriesList({ onSelect }: { onSelect: (category: string) => void }) {
  return (
    <ul className="grid list-none grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
      {Object.entries(CATALOGUE_PRODUCTS).map(([category, details]) => (
        <motion.li key={category} whileHover="hover">
          <button className="flex w-full cursor-pointer flex-col gap-4" onClick={() => onSelect(category)}>
            <div className="aspect-square w-full overflow-hidden rounded border border-gray-200">
              <motion.img
                alt={category}
                className="w-full object-contain object-center"
                src={details?.image}
                variants={{
                  hover: {
                    scale: 1.05
                  }
                }}
              />
            </div>

            <span className="w-full text-center text-black">{category}</span>
          </button>
        </motion.li>
      ))}
    </ul>
  )
}
