import { MerchantPreferencesInterface } from '../../../hooks'

export function generateNewSavedItems({
  merchantPreferences,
  sku,
  shouldFavourite
}: {
  merchantPreferences: MerchantPreferencesInterface
  sku: string
  shouldFavourite: boolean
}) {
  let newStarredProducts = [...merchantPreferences.starredProducts]
  if (shouldFavourite) {
    newStarredProducts.push({ skuName: sku })
  } else {
    newStarredProducts = [...merchantPreferences.starredProducts].filter(
      (starredProduct) => starredProduct.skuName !== sku
    )
  }
  return newStarredProducts
}
