// @flow

import React, { Component } from 'react'
import PrintAreaSwitcherView from './PrintAreaSwitcherView'
import { setDefaultPrintAreaEntryAsFirst } from '../../helpers/setDefaultPrintAreaEntryAsFirst'

type State = {|
  orderedPrintAreaEntries: [string, *][]
|}

type Props = {|
  selectedPrintArea: string,
  itemCategory: string,
  printAreaEntries: [string, *][],
  onPrintAreaChange: (newPrintAreaName: string) => void
|}

export default class PrintAreaSwitcherController extends Component<Props, State> {
  state: State = {
    orderedPrintAreaEntries: this.props.printAreaEntries
  }

  componentDidMount () {
    this.makeSureDefaultPrintAreaIsFirst()
  }

  makeSureDefaultPrintAreaIsFirst () {
    const orderedPrintAreaEntries = setDefaultPrintAreaEntryAsFirst(this.props.printAreaEntries)
    this.setState({ orderedPrintAreaEntries })
  }

  get numberOfPrintAreas (): number {
    return this.state.orderedPrintAreaEntries.length
  }

  get selectedPrintAreaIndex (): number {
    const selectedIndex = this.state.orderedPrintAreaEntries.findIndex((printAreaEntry) => {
      const printAreaName = printAreaEntry[0]
      return this.props.selectedPrintArea === printAreaName
    })

    return selectedIndex
  }

  get selectedIndexToDisplay (): number {
    return this.selectedPrintAreaIndex + 1
  }

  getNewPrintAreaIndex: ((printAreaIndexToSelect: number) => number) = (printAreaIndexToSelect: number) => {
    let newPrintAreaIndex = printAreaIndexToSelect
    if (newPrintAreaIndex < 0) {
      newPrintAreaIndex = this.state.orderedPrintAreaEntries.length - 1
    } else if (newPrintAreaIndex >= this.state.orderedPrintAreaEntries.length) {
      newPrintAreaIndex = 0
    }
    return newPrintAreaIndex
  }

  handlePrintAreaChange: ((printAreaIndexToSelect: number) => void) = (printAreaIndexToSelect: number) => {
    const newPrintAreaIndex = this.getNewPrintAreaIndex(printAreaIndexToSelect)
    const newPrintAreaEntry = this.state.orderedPrintAreaEntries.find((printAreaEntry, index) => {
      return index === newPrintAreaIndex
    })

    if (!newPrintAreaEntry) {
      return
    }
    const newPrintAreaName = newPrintAreaEntry[0]
    this.props.onPrintAreaChange(newPrintAreaName)
  }

  render (): React$Node {
    return <PrintAreaSwitcherView
      itemCategory={this.props.itemCategory}
      handlePrintAreaChange={this.handlePrintAreaChange}
      selectedPrintAreaIndex={this.selectedPrintAreaIndex}
      selectedPrintArea={this.props.selectedPrintArea}
      selectedIndexToDisplay={this.selectedIndexToDisplay}
      numberOfPrintAreas={this.numberOfPrintAreas} />
  }
}
