import { Link } from 'react-router-dom'

import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { ROUTE_PATHS } from '../../../constants'

export function SalesChannelProductError({ errorCode }: { errorCode: string }) {
  return (
    <div className="w-full pt-36">
      <NotFound
        className="mx-auto px-9"
        heading="Failed to load product"
        body={
          <>
            <p className="text-center">
              Please try refreshing the page or <SupportLink>contact us</SupportLink> if the issue persists.
            </p>
            <div className="mt-4 break-words text-center text-gray-600">
              Error code: {errorCode}
              <ClipboardCopy className="align-middle" text={errorCode.toString()} showText={false} />
            </div>
          </>
        }
      >
        <Link className="btn btn-primary mx-auto mt-8" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
          Back to sales channels
        </Link>
      </NotFound>
    </div>
  )
}
