// @flow
import type { Dictionary } from '../../types'
import { filterOutInvalidTemplateAttributes } from './filterOutInvalidTemplateAttributes'

export function buildTemplateId (sku: string, attributes: Dictionary<string>): string {
  const filteredAttributes = filterOutInvalidTemplateAttributes(attributes)
  const sortedKeys = Object.keys(filteredAttributes).sort()

  const stringifiedAttributes = sortedKeys.reduce((acc, attributeName) => {
    acc += attributeName + '=' + attributes[attributeName] + '/'
    return acc
  }, '/').slice(0, -1)

  return sku + stringifiedAttributes
}
