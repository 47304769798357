import clsx from 'clsx'
import { forwardRef, LegacyRef } from 'react'

import Skeleton from '../../Skeleton'

export const ImageTileSkeleton = forwardRef(
  ({ className }: { className?: string }, ref: LegacyRef<HTMLDivElement> | undefined) => {
    return (
      <div ref={ref} className={clsx('w-full', className)}>
        <Skeleton className="aspect-square w-full" />
        <Skeleton className="mt-2 h-[20px]" />
        <Skeleton className="mt-2 h-[20px]" />
      </div>
    )
  }
)
ImageTileSkeleton.displayName = 'ImageTileSkeleton'
