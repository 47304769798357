/* eslint-disable indent */
import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers/fetcher'
import { isNoImageProduct } from '../helpers'
import { LANDSCAPE, PORTRAIT } from '../constants'
import { FetchErrorInterface } from '../interfaces'
import { PrintAreaNameType } from '../interfaces/PrintAreaName.type'

/* -------------------------------------------------------------------------- */
/*                                    Types                                   */
/* -------------------------------------------------------------------------- */

export interface CropRectangleInterface {
  x: number
  y: number
  width: number
  height: number
}

interface ResolutionInterface {
  width: number
  height: number
}

export interface TemplateDataInterface {
  imageUrls: {
    overlay: string
    underlay?: string
  }
  templateResolution: ResolutionInterface
  cropRectangle: CropRectangleInterface
  printResolution: ResolutionInterface
  printDpi: number
}

export type TemplateOrientationType = 'landscape' | 'portrait' | 'square'

type TemplatesByOrientationType = Record<TemplateOrientationType, TemplateDataInterface | undefined>

interface TemplatesByPrintAreasInterface {
  orientations: TemplatesByOrientationType
}

type TemplatesByPrintAreasType = Record<PrintAreaNameType, TemplatesByPrintAreasInterface>

export interface TemplateServiceSuccessResponseInterface {
  printAreas: TemplatesByPrintAreasType
}

// TODO: Strong type after checking with BE team
type TemplateServiceErrorResponseInterface = unknown

/* -------------------------------------------------------------------------- */
/*                                    Hook                                    */
/* -------------------------------------------------------------------------- */

interface UseTemplateServiceArgsInterface {
  sku?: string
  attributes?: Record<string, string>
  config?: SWRConfiguration
  enableNoImageProductCheck?: boolean
  productType?: string
}

export function useTemplateService({
  sku,
  attributes = {},
  enableNoImageProductCheck = false,
  productType,
  config
}: UseTemplateServiceArgsInterface) {
  const filteredAttributes = filterOutInvalidTemplateAttributes(attributes)
  const skipRequest = enableNoImageProductCheck ? !productType || isNoImageProduct(productType) : false

  const url = sku && !skipRequest ? buildMultiAssetEndpoint(sku, filteredAttributes) : null
  const { data, error } = useSWR<
    TemplateServiceSuccessResponseInterface,
    FetchErrorInterface<TemplateServiceErrorResponseInterface>
  >(url, fetcher, config)

  const templates = data && mapTemplateServiceResponse(data)

  return {
    templates,
    isLoading: Boolean(url && !data && !error),
    error,
    url
  }
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

function filterOutInvalidTemplateAttributes(allAttributes: Record<string, string>) {
  return allAttributes
    ? Object.keys(allAttributes).reduce((attributeAcc: Record<string, string>, attributeName) => {
        if (!INVALID_ATTRIBUTES.includes(attributeName)) {
          attributeAcc[attributeName] = allAttributes[attributeName]
        }
        return attributeAcc
      }, {})
    : {}
}

function buildMultiAssetEndpoint(sku: string, attributes: Record<string, string>): string {
  const baseEndpoint = `${process.env.REACT_APP_TEMPLATE_SERVICE}/MultiArea/${sku}`
  const attrDictAsJsonString = encodeURIComponent(JSON.stringify(attributes))
  const attrSearchQuery = Object.keys(attributes).length ? `?attrs=${attrDictAsJsonString}` : ''
  return encodeURI(baseEndpoint + `${attrSearchQuery}`)
}

function mapTemplateServiceResponse(
  templateServiceResponse: TemplateServiceSuccessResponseInterface
): TemplateServiceSuccessResponseInterface {
  const mappedTemplateServiceData = Object.entries(templateServiceResponse.printAreas).reduce(
    (printAreasAcc: TemplatesByPrintAreasType, [printArea, printAreaTemplatesData]) => {
      const orientations = printAreaTemplatesData.orientations
      printAreasAcc[printArea] = {
        orientations: mapMultiAssetOrientationResponse(orientations) as TemplatesByOrientationType
      }
      return printAreasAcc
    },
    {}
  )

  return {
    printAreas: mappedTemplateServiceData
  }
}

function mapMultiAssetOrientationResponse(orientations: TemplatesByOrientationType) {
  return Object.entries(orientations).reduce(
    (orientationsAcc: Record<string, TemplateDataInterface>, [orientation, orientationData]) => {
      if (!orientationData) {
        return orientationsAcc
      }

      const lowerCaseOrientationName = orientation.toLowerCase()

      const rotatedPrintResolution = getRotatedDimensions(
        lowerCaseOrientationName,
        orientationData.printResolution.width,
        orientationData.printResolution.height
      )

      const rotatedCropRectangleDimensions = getRotatedDimensions(
        lowerCaseOrientationName,
        orientationData.cropRectangle.width,
        orientationData.cropRectangle.height
      )

      const templateOrientationDataWithCorrectDimensions = {
        ...orientationData,
        printResolution: rotatedPrintResolution,
        cropRectangle: {
          ...orientationData.cropRectangle,
          ...rotatedCropRectangleDimensions
        }
      }

      orientationsAcc[lowerCaseOrientationName] = templateOrientationDataWithCorrectDimensions

      return orientationsAcc
    },
    {}
  )
}

export function getRotatedDimensions(orientation: string, width: number, height: number) {
  const orientationNameCorrectlyDescribesProvidedDimensions = Boolean(
    (orientation === LANDSCAPE && width > height) || (orientation === PORTRAIT && height > width)
  )

  if (orientationNameCorrectlyDescribesProvidedDimensions) {
    return {
      width: width,
      height: height
    }
  } else {
    return {
      width: height,
      height: width
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                  Constants                                 */
/* -------------------------------------------------------------------------- */

const INVALID_ATTRIBUTES = [
  'gender',
  'brand',
  'style',
  'paperType',
  'substrateWeight',
  'mount',
  'mountColour',
  'quality',
  'glaze',
  'channels',
  'RipOutTab',
  'ripOutTab'
]
