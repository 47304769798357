import { APP_ENVIRONMENTS } from '../../../constants'
import { VariantDataInterface } from '../SalesChannelProduct.component'
import { CopyDetailsValueType } from '../types'

export function copyWillOverwriteExistingData({
  allVariantsData,
  copyType,
  variantDataToCopy
}: {
  allVariantsData: Record<string, VariantDataInterface>
  copyType: CopyDetailsValueType
  variantDataToCopy: VariantDataInterface
}) {
  switch (copyType) {
    case 'copy-all': {
      return (
        willOverwriteArtwork({ allVariantsData, variantDataToCopy }) ||
        willOverwriteProduct({ allVariantsData, variantDataToCopy })
      )
    }
    case 'copy-image': {
      return willOverwriteArtwork({ allVariantsData, variantDataToCopy })
    }
    case 'copy-product': {
      return willOverwriteProduct({ allVariantsData, variantDataToCopy })
    }
    default: {
      const copyUnhandledCase: never = copyType
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unable to copy details for type ${copyUnhandledCase}. Add a new case to fix this.`)
      }
      return false
    }
  }
}

function willOverwriteArtwork({
  allVariantsData,
  variantDataToCopy
}: {
  allVariantsData: Record<string, VariantDataInterface>
  variantDataToCopy: VariantDataInterface
}) {
  return Object.values(allVariantsData).some(
    (variant) =>
      variant.id !== variantDataToCopy.id &&
      variant.isFulfilled &&
      variant.artworkData &&
      Object.values(variant.artworkData).some((artwork) => artwork)
  )
}

function willOverwriteProduct({
  allVariantsData,
  variantDataToCopy
}: {
  allVariantsData: Record<string, VariantDataInterface>
  variantDataToCopy: VariantDataInterface
}) {
  return Object.values(allVariantsData).some(
    (variant) => variant.id !== variantDataToCopy.id && variant.isFulfilled && variant.selectedProductData
  )
}
