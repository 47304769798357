export const NO_IMAGE_PRODUCT_TYPES = ['sample pack', 'poster hangers', 'picture stands', 'ecocaps']

export const NO_IMAGE_PRODUCTS = [
  {
    type: 'Sample pack',
    category: 'Sample Packs',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/samplepack.jpg?st=2020-07-20T14%3A08%3A49Z&se=2076-07-21T14%3A08%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=DbgC95H4ve2F0zVGKORIbJkQqwFft6ygvekCa6yAlxo%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/samplepack.png?st=2020-07-20T14%3A07%3A26Z&se=2082-07-21T14%3A07%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=wY84VecUn0hs5FzpPij9OoUGyOdGgj1JKnMBcqhkF5Y%3D'
  },
  {
    type: 'Sample pack',
    category: 'Sample Pack',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK-CLASSIC',
    zeroCost: false,
    displayImage: 'https://s3.amazonaws.com/kiteshopify/c885c776-436e-4649-92c1-144414356fe8.png',
    labImage: 'https://s3.amazonaws.com/kiteshopify/c885c776-436e-4649-92c1-144414356fe8.png'
  },
  {
    type: 'Sample pack',
    category: 'Sample Pack',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK-BOX',
    zeroCost: false,
    displayImage: 'https://s3.amazonaws.com/kiteshopify/95e88c93-15f3-4f8d-99ab-2ce5cf06332e.png',
    labImage: 'https://s3.amazonaws.com/kiteshopify/95e88c93-15f3-4f8d-99ab-2ce5cf06332e.png'
  },
  {
    type: 'Sample pack',
    category: 'Sample Pack',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK-FLOAT',
    zeroCost: false,
    displayImage: 'https://s3.amazonaws.com/kiteshopify/4fc72a43-2531-4a87-a35d-db2cef032557.png',
    labImage: 'https://s3.amazonaws.com/kiteshopify/4fc72a43-2531-4a87-a35d-db2cef032557.png'
  },
  {
    type: 'Sample pack',
    category: 'Sample Pack',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK-ALL-PACKS',
    zeroCost: false,
    displayImage: 'https://s3.amazonaws.com/kiteshopify/2bb014e6-eb7d-4c48-8fff-8349f68a32f5.png',
    labImage: 'https://s3.amazonaws.com/kiteshopify/2bb014e6-eb7d-4c48-8fff-8349f68a32f5.png'
  },
  {
    type: 'Sample pack',
    category: 'Sample Pack',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK-FRAMES',
    zeroCost: false,
    displayImage: 'https://s3.amazonaws.com/kiteshopify/eed16636-d48d-4b7a-9e87-4855deab32d9.png',
    labImage: 'https://s3.amazonaws.com/kiteshopify/eed16636-d48d-4b7a-9e87-4855deab32d9.png'
  },
  {
    type: 'Sample pack',
    category: 'Sample Packs Shopify',
    colour: '',
    sku: 'PRODIGI-SAMPLE-PACK-SHOPIFY',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/samplepackshopify.jpg?st=2020-07-20T14%3A09%3A13Z&se=2087-07-21T14%3A09%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=%2FkjiM0BlzhCeGYVZeJk%2FVo4%2F7cBi9AYeKrrp2McB%2BQc%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/samplepackshopify.png?st=2020-07-20T14%3A07%3A53Z&se=2081-07-21T14%3A07%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=ToAMqGvSHJ3e12Vy8%2BGCH09Th%2FgyNz2YZra1JZUO3Gs%3D'
  },
  {
    type: 'Poster Hangers',
    category: 'Prints & posters',
    colour: 'black',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/blackhangingkit.jpg?st=2020-07-24T15%3A36%3A05Z&se=2082-07-25T15%3A36%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=3JcNX8CwyHllVCD3SxMZEum6ow%2FcZYM%2FYC8wCPYzw%2B0%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/blackhangingkit.jpg?st=2020-07-23T14%3A34%3A03Z&se=2087-07-24T14%3A34%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=rOJRHiBo79DhK8viyrJfIxYOyarovO8NUwozlnLSZU0%3D'
  },
  {
    type: 'Poster Hangers',
    category: 'Prints & posters',
    colour: 'white',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/whitehangingkit.jpg?st=2020-07-24T15%3A38%3A31Z&se=2080-07-25T15%3A38%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=tofznjmDbzEREHcsGpT%2FjzIJXTPSf0OJKWhkRTvBz7M%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/whitehangingkit.jpg?st=2020-07-23T16%3A16%3A16Z&se=2087-07-24T16%3A16%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=xgL2Gd%2F51axpkxdFiM59FK7JbPCGpKbH5NnYMvGaQbU%3D'
  },
  {
    type: 'Poster Hangers',
    category: 'Prints & posters',
    colour: 'natural',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/brownhangingkit.jpg?st=2020-07-24T15%3A37%3A38Z&se=2077-07-25T15%3A37%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=HejNivrricfTvqb5K6tJEw%2FRC4yfXY592XSQoXptmIs%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/brownhangingkit.jpg?st=2020-07-23T16%3A15%3A17Z&se=2080-07-24T16%3A15%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=153cTiQTwhfhbfaYT0oaz9GKJ%2Bn36sfbq55Bk7ZQ5Sc%3D'
  },
  {
    type: 'Picture Stands',
    category: 'Photo Gifts',
    colour: 'natural',
    sku: 'STAND-1',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/stand1.jpg?st=2020-09-29T10%3A29%3A23Z&se=2073-09-30T10%3A29%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=uj7NhucUmFkxO27RI7k9XMRkL8VcAItcjxnZM%2Br1TWc%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/stand1.jpg?st=2020-09-29T10%3A28%3A41Z&se=2066-09-30T10%3A28%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=VAdMnboSNy5IXQWEwAeXNhhAWgu6CxG3qwD7BWEONbQ%3D'
  },
  {
    type: 'Picture Stands',
    category: 'Photo Gifts',
    colour: 'natural',
    sku: 'STAND-2',
    zeroCost: false,
    displayImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/display/stand2.jpg?st=2020-09-29T10%3A29%3A45Z&se=2076-09-30T10%3A29%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=PDqXfPUMaksLBbQ2eRsq%2FtkJPCdLVng8wLzW%2FX8FKlY%3D',
    labImage:
      'https://pwintyimages.blob.core.windows.net/noimageassets/lab/stand2.jpg?st=2020-09-29T10%3A29%3A01Z&se=2075-09-30T10%3A29%3A00Z&sp=rl&sv=2018-03-28&sr=b&sig=gyl9Tsn%2BH3dnfjcsSUmOC1pVaxhdfPrx7zISM4iyy24%3D'
  },
  {
    type: 'Ecocaps',
    category: 'Packaging & Boxes',
    colour: '',
    zeroCost: false,
    displayImage: 'https://s3.amazonaws.com/kiteshopify/03ea76be-577b-408b-ac56-41d855f23774.jpeg',
    labImage: 'https://s3.amazonaws.com/kiteshopify/03ea76be-577b-408b-ac56-41d855f23774.jpeg'
  }
]
