// @flow
import React, { PureComponent } from 'react'
import CsvOrderShippingDetailsView from './CsvOrderShippingDetailsView'
import type { Quote, Event, CustomerAddress, RsaaStatus } from '../../../../types'
import Option from '../../../../helpers/Option'
import { LOADING } from '../../../../data/rsaa'
import { formatCost } from '../../../../helpers/charge/formatCost'

type Props = {|
  orderId: string,
  canBeEdited: boolean,
  address: CustomerAddress,
  currencyCode: string,
  preferredShippingMethod: string,
  quoteRsaaStatus: RsaaStatus,
  quotes: Quote[],
  updateShippingMethod: (orderId: string, shippingMethod: string) => void
|}

type State = {|
  isAddressModalOpen: boolean,
  originalPreferredShippingMethod: string
|}

export default class CsvOrderShippingDetailsController extends PureComponent<Props, State> {
  state: State = {
    isAddressModalOpen: false,
    originalPreferredShippingMethod: this.props.preferredShippingMethod
  }

  toggleAddressModal: (() => void) = () => this.setState(state => ({ isAddressModalOpen: !state.isAddressModalOpen }))

  onShippingMethodChange: ((event: Event) => void) = (event: Event) => this.props.updateShippingMethod(this.props.orderId, event.currentTarget.value)

  formatShippingMethodFromQuote: ((quote: Quote) => string) = (quote: Quote): string => {
    const price = formatCost(quote.costSummary.shipping)
    return `${quote.shipmentMethod}: ${price}`
  }

  isShippingMethodValid (methodName: string): boolean {
    return this.props.quotes.some(({ shipmentMethod }) =>
      shipmentMethod.toUpperCase() === methodName.toUpperCase()
    )
  }

  get preferredButUnavailableShippingMethod (): Option<string> {
    const { originalPreferredShippingMethod } = this.state

    return originalPreferredShippingMethod && !this.isShippingMethodValid(originalPreferredShippingMethod)
      ? Option.some(originalPreferredShippingMethod)
      : Option.none()
  }

  get isShipmentSelectorEnabled (): boolean {
    return Boolean(this.props.quotes.length && this.props.canBeEdited)
  }

  get isLoading (): boolean {
    return this.props.quoteRsaaStatus.status === LOADING
  }

  render (): React$Node {
    return (
      <CsvOrderShippingDetailsView
        quotes={this.props.quotes}
        isLoading={this.isLoading}
        formatShippingMethodFromQuote={this.formatShippingMethodFromQuote}
        isAddressModalOpen={this.state.isAddressModalOpen}
        toggleAddressModal={this.toggleAddressModal}
        orderId={this.props.orderId}
        address={this.props.address}
        canBeEdited={this.props.canBeEdited}
        selectedShippingMethodName={this.props.preferredShippingMethod}
        preferredButUnavailableShippingMethod={this.preferredButUnavailableShippingMethod}
        isShipmentSelectorEnabled={this.isShipmentSelectorEnabled}
        isSelectedShippingMethodValid={this.isShippingMethodValid(this.props.preferredShippingMethod)}
        onShippingMethodChange={this.onShippingMethodChange} />
    )
  }
}
