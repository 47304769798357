// @flow
import type { Event, FormattedCsvRow, Dictionary } from '../../types'
import React from 'react'
import Page from '../../components/Page'
import styles from './CsvUpload.module.css'
import GetStarted from './components/GetStarted'
import FileUpload from '../../components/FileUpload'
import CsvUploadOverview from './components/CsvUploadOverview'

type Props = {|
  isUploading: boolean,
  uploadStarted: boolean,
  uploadComplete: boolean,
  uploadFailed: boolean,
  uploadSuccess: boolean,
  errorMessage: string,
  uploadedOrders: Dictionary<FormattedCsvRow>,
  onUpload: (e: Event) => void,
  onUploadRejected: (event: File[]) => void
|}

const UPLOAD_TEXT = 'Drag and drop your file or click here to browse'

export default function CsvUploadView(props: Props): React$Node {
  return (
    <Page title="Import orders">
      <div className={!props.uploadSuccess ? styles.fullWidth : styles.hidden}>
        <GetStarted />
      </div>

      <div className={!props.uploadSuccess ? styles.container : styles.fullWidth}>
        <FileUpload
          multiple={false}
          fileType={{ 'text/csv': ['.csv'] }}
          uploadText={UPLOAD_TEXT}
          uploadFile={(e) => props.onUpload(e)}
          isUploading={props.isUploading}
          uploadStarted={props.uploadStarted}
          uploadComplete={props.uploadComplete}
          uploadFailed={props.uploadFailed}
          onUploadRejected={props.onUploadRejected}
          errorMessage={props.errorMessage}
          isAlert={true}
        >
          <CsvUploadOverview />
        </FileUpload>
      </div>
    </Page>
  )
}
