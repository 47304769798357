// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Resources ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0.5C5.20101 0.5 0.5 5.20101 0.5 11C0.5 16.799 5.20101 21.5 11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 8.21523 20.3938 5.54451 18.4246 3.57538C16.4555 1.60625 13.7848 0.5 11 0.5ZM20 10.25H15.5C15.4125 7.48781 14.6968 4.78186 13.4075 2.3375C17.0461 3.33279 19.6779 6.49155 20 10.25ZM11 20C10.8327 20.0112 10.6648 20.0112 10.4975 20C8.94372 17.5221 8.08138 14.6736 8 11.75H14C13.9254 14.6715 13.0709 17.5199 11.525 20C11.3502 20.0123 11.1748 20.0123 11 20ZM8 10.25C8.07461 7.32851 8.92913 4.48012 10.475 2C10.8089 1.96247 11.1461 1.96247 11.48 2C13.0417 4.4756 13.9119 7.3243 14 10.25H8ZM8.57 2.3375C7.28847 4.78373 6.58059 7.48959 6.5 10.25H2C2.32214 6.49155 4.95394 3.33279 8.5925 2.3375H8.57ZM2.0375 11.75H6.5375C6.61577 14.5098 7.32108 17.2156 8.6 19.6625C4.97324 18.6566 2.35537 15.5002 2.0375 11.75ZM13.4075 19.6625C14.6968 17.2181 15.4125 14.5122 15.5 11.75H20C19.6779 15.5084 17.0461 18.6672 13.4075 19.6625Z" fill="#565656"/>
    </svg>
  )
}
