import { useState } from 'react'
import toast from 'react-hot-toast'

import Button from '../../Button'
import FormItem from '../../FormItem'
import TextField from '../../TextField'
import { Panel } from './Panel.component'
import { createToast } from '../../Toast'
import RadioGroup from '../../RadioGroup'
import { StatusEnum } from '../../../enums'
import { useMerchantService } from '../../../hooks'
import { updateSalesChannelDetails } from '../../../helpers'
import { FetchErrorInterface, SalesChannelInterface } from '../../../interfaces'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../../SalesChannelConfigure/constants'

interface SalesChannelPreferencesSettingPropsInterface {
  isLoading: boolean
  salesChannel: SalesChannelInterface
  onCancel: () => void
  setPreferencesUpdateStatus: (status: StatusEnum) => void
}

interface PreferencesInterface {
  defaultFulfillment: boolean
  name: string
}

const PRODUCT_FULFILLMENT = [
  { value: 'true', label: 'Automatically fulfill new products with Prodigi' },
  { value: 'false', label: 'Ignore new products (do not fulfill with Prodigi)' }
]

export function SalesChannelPreferencesSetting({
  isLoading,
  salesChannel,
  onCancel,
  setPreferencesUpdateStatus
}: SalesChannelPreferencesSettingPropsInterface) {
  const [newSalesChannelPreferences, setNewSalesChannelPreferences] = useState<PreferencesInterface>({
    defaultFulfillment: salesChannel.defaultFulfillment,
    name: salesChannel.description
  })
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()

  async function handleSalesChannelPreferencesUpdate(newPreferences: PreferencesInterface) {
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS)
    setPreferencesUpdateStatus(StatusEnum.Loading)

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: salesChannel.packingSlipImageId,
        salesChannelId: salesChannel.id,
        salesChannelName: newPreferences.name,
        defaultFulfillment: newPreferences.defaultFulfillment
      })
      await mutateMerchantDetails()
      createToast({
        heading: 'Updated successfully',
        id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS,
        type: 'success'
      })
      setPreferencesUpdateStatus(StatusEnum.Success)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to update sales channel preferences',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setPreferencesUpdateStatus(StatusEnum.Error)
    }
  }

  return (
    <Panel
      className="px-0 first:pt-0 "
      id="channel-preferences"
      title={<h3 className="mt-0">Preferences</h3>}
      subtitle={
        <span className="mt-4 max-w-lg text-base text-gray-600">Control how this channel appears and behaves.</span>
      }
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleSalesChannelPreferencesUpdate(newSalesChannelPreferences)
        }}
      >
        <FormItem
          className="-mt-4 max-w-xl pb-12"
          inputField={
            <TextField
              disabled={isLoading}
              required
              onChange={(event) => {
                setNewSalesChannelPreferences({
                  ...newSalesChannelPreferences,
                  name: event.target.value
                })
              }}
              value={newSalesChannelPreferences.name}
            />
          }
          labelText="Name"
        />

        <div className="text-gray-600">When a new product is added to this channel:</div>
        <FormItem
          className="-mt-4 pb-12"
          inputField={
            <RadioGroup
              ariaLabel="Sales channel product fulfillment config"
              className="mt-2"
              items={PRODUCT_FULFILLMENT}
              radioGroupItemClassName="text-black items-center"
              selectedValue={newSalesChannelPreferences.defaultFulfillment.toString()}
              onChange={(selection) => {
                setNewSalesChannelPreferences({
                  ...newSalesChannelPreferences,
                  defaultFulfillment: selection === 'true'
                })
              }}
            />
          }
        />

        <div className="-mt-4 flex gap-4 sm:mb-4">
          <Button disabled={!newSalesChannelPreferences.name} isLoading={isLoading} type="submit">
            Save
          </Button>

          <Button variant="tertiary" theme="greyscale" type="button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Panel>
  )
}
