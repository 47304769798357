import clsx from 'clsx'
import { forwardRef, LegacyRef } from 'react'

import Skeleton from '../../Skeleton'

export const ProductTileSkeleton = forwardRef(
  ({ className }: { className?: string }, ref: LegacyRef<HTMLDivElement> | undefined) => (
    <div
      ref={ref}
      className={clsx(
        'mx-auto flex min-h-[360px] w-[250px] flex-col justify-between border-2 border-purple-100',
        className
      )}
    >
      <div className="w-full flex-1 pb-4">
        <figure>
          <Skeleton className="block h-[230px] w-full object-cover object-center" />
          <Skeleton className="text-md h-8 px-4 pt-4" />
        </figure>
        <div className="px-4 pt-2">
          <Skeleton className="mt-2 h-8" />
          <Skeleton className="mt-2 h-4 w-1/2" />
        </div>
      </div>

      <div className="flex items-center justify-between border-t-2 border-purple-100 p-4">
        <Skeleton className="h-8 w-1/2" />
        <Skeleton className="h-5 w-[36px] rounded-full" />
      </div>
    </div>
  )
)

ProductTileSkeleton.displayName = 'ProductTileSkeleton'
