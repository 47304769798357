// @flow
import { RSAA } from 'redux-api-middleware'
import type {
  GetStateFunc,
  DispatchFunc,
  ThunkAsync,
  AppState } from '../../types'
import type {
  SearchProductsBySkuList,
  SearchProductsBySkuListSuccessResponse
} from '../types'
import { selectAzureSearchSettings } from '../../selectors/appSettings'
import { LOG_STATUS } from '../../data/rsaa'
import { mapCatalogueItems } from './mapCatalogueItems'

export const SEARCH_PRODUCTS_BY_SKU_LIST = 'SEARCH_PRODUCTS_BY_SKU_LIST'
export const SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS = 'SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS'
export const SEARCH_PRODUCTS_BY_SKU_LIST_ERROR = 'SEARCH_PRODUCTS_BY_SKU_LIST_ERROR'

export function searchProductsBySkuList (
  skus: string[],
  countryCode: string,
  loggerId: string
): ThunkAsync<SearchProductsBySkuList> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const azureEndpointAndKey = selectAzureSearchSettings()
    const endpoint = buildSearchProductsBySkuListUrl(azureEndpointAndKey.endpoint, skus, countryCode)

    const searchResult = await dispatch({
      [RSAA]: {
        endpoint,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': azureEndpointAndKey.key
        },
        types: [
          { type: SEARCH_PRODUCTS_BY_SKU_LIST, meta: { enhancements: [ LOG_STATUS ], loggerId } },
          {
            type: SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS,
            payload: async (action: SearchProductsBySkuList, state: AppState, res: SearchProductsBySkuListSuccessResponse) => {
              const json = await res.json()
              return mapCatalogueItems(json.value)
            }
          },
          SEARCH_PRODUCTS_BY_SKU_LIST_ERROR
        ]
      }
    })

    return searchResult
  }
}

function buildSearchProductsBySkuListUrl (searchEndpoint: string, skus: string[], countryCode: string): string {
  const mappedSkus = skus.reduce((acc, sku) => acc + sku + '*|', '').slice(0, -1)
  return `${searchEndpoint}&search=${mappedSkus}&$filter=destinationCountries/any(c: c eq '${countryCode}')`
}
