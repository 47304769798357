// @flow
import type { AzureSearchResult } from '../../types'

export function sortSearchResultsByScore (searchResult: AzureSearchResult[], query: string): AzureSearchResult[] {
  const searchResultCopy = JSON.parse(JSON.stringify(searchResult))

  const sortedResultsWithExactMatch = searchResultCopy
    .filter(result => query.split(' ').every(queryWord => result.description.toLowerCase().indexOf(queryWord) > -1))
    .sort((prevEl, nextEl) => nextEl['@search.score'] - prevEl['@search.score'])

  const sortedResultsWithoutExactMatch = searchResultCopy
    .filter(result => query.split(' ').some(queryWord => result.description.toLowerCase().indexOf(queryWord) === -1))
    .sort((prevEl, nextEl) => nextEl['@search.score'] - prevEl['@search.score'])

  return sortedResultsWithExactMatch.concat(sortedResultsWithoutExactMatch)
}
