// @flow
import React, { PureComponent } from 'react'
import styles from './CsvOrder.module.css'
import type { FormattedCsvRow, FormattedOrderItem, Dictionary, CsvItemProductDetails, Status } from '../../../../types'
import cx from 'classnames'
import Alert from '../../../../components/Alert'
import CsvItems from '../CsvItems'
import CsvOrderShippingDetails from '../CsvOrderShippingDetails'
import CsvActionMenu from '../CsvActionMenu'
import Container from './Container'
import CsvOrderDetails from '../CsvOrderDetails'

type Props = {|
  order: FormattedCsvRow,
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CsvItemProductDetails>,
  canBeEdited: boolean,
  artworkStatus: Dictionary<Status>,
  isFetchingShipping: boolean,
  isFetchingProductDetails: boolean,
  isCompleted: boolean,
  everyOrderItemHasSku: boolean,
  errorMessage: ?string,
  orderItemIdForEdit: ?string,
  isIgnored: boolean,
  currencyCode: string,
  shippingDetailsKey: string,
  idOfOrderItemWithOpenImageModal: ?string,
  isMultiselectModeOn: boolean,
  openProductSearchModal: (itemId: string) => void,
  closeProductSearchModal: () => void,
  onProductSearchError: (errorMessage: string) => void,
  updateOrderIgnore: () => void,
  closeImageModal: () => void,
  openImageModal: (orderItemId: string) => void
|}

export default class CsvOrder extends PureComponent <Props> {
  render (): React$Node {
    const { order, errorMessage, isCompleted, isIgnored, canBeEdited } = this.props
    const backgroundColor = isIgnored
      ? styles.ignored
      : (this.props.everyOrderItemHasSku && this.props.isFetchingProductDetails) || isCompleted
        ? styles.completed
        : styles.missing

    return (
      <React.Fragment>
        <CsvActionMenu
          orderId={order.id}
          isIgnored={isIgnored}
          updateOrderIgnore={this.props.updateOrderIgnore}
          items={this.props.orderItems} />

        <Container
          className={cx(styles.order, backgroundColor)}
          isMultiselectModeOn={this.props.isMultiselectModeOn}
          onClick={this.props.updateOrderIgnore}
          dataTest="csv-item-container-button"
        >

          {errorMessage && !isIgnored && <Alert className={styles.alert}>{errorMessage}</Alert>}

          <CsvOrderDetails
            order={order}
            canBeEdited={canBeEdited}
            isFetchingShipping={this.props.isFetchingShipping}
            currencyCode={this.props.currencyCode} />

          <CsvItems
            onProductSearchError={this.props.onProductSearchError}
            isCompleted={isCompleted}
            orderId={order.id}
            orderItems={this.props.orderItems}
            canBeEdited={canBeEdited}
            productDetails={this.props.productDetails}
            orderItemIdForEdit={this.props.orderItemIdForEdit}
            openProductSearchModal={this.props.openProductSearchModal}
            closeProductSearchModal={this.props.closeProductSearchModal}
            artworkStatus={this.props.artworkStatus}
            idOfOrderItemWithOpenImageModal={this.props.idOfOrderItemWithOpenImageModal}
            closeImageModal={this.props.closeImageModal}
            openImageModal={this.props.openImageModal} />

          <CsvOrderShippingDetails
            key={this.props.shippingDetailsKey}
            canBeEdited={canBeEdited}
            address={order.customer}
            preferredShippingMethod={order.preferredShippingMethod}
            orderId={order.id}
            currencyCode={this.props.currencyCode} />
        </Container>
      </React.Fragment>
    )
  }
}
