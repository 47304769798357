// @flow
import { createSelector } from 'reselect'
import { returnThirdArg, returnSecondArg, identity } from '../helpers'
import { buildTemplateId } from '../../actions/images/buildTemplateId'
import type {
  Status,
  AppState,
  MultiAssetTemplates,
  Dictionary,
  Artwork,
  ArtworkTransformations,
  ArtworkProgress
} from '../../types'
import type {
  GetAllTemplateStatus,
  GetArtworkTransformationStatusById,
  GetArtworkProgress,
  GetAllArtworkStatus,
  GetArtworkStatusByIds,
  GetArtworkByItemId,
  GetArtworkTransformationsById,
  GetProductTemplates,
  GetTemplateStatus,
  GetArtworkStatus,
  GetArtworkErrorMessage,
  GetAllArtworks,
  GetTemplateStatusByIds
} from './types'
import { IDLE } from '../../data/rsaa'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'

export function selectTemplates(state: AppState): Dictionary<MultiAssetTemplates> {
  return state.images.templates
}

function selectProductTemplates(
  allTemplates: Dictionary<MultiAssetTemplates>,
  sku: ?string,
  attributes: Dictionary<string>
): ?MultiAssetTemplates {
  if (!sku) {
    return null
  }

  const templateId = buildTemplateId(sku, attributes)

  return allTemplates[templateId]
}

export function selectTemplateStatus(state: AppState, sku: string, attributes: Dictionary<string>): Status {
  const templateId = buildTemplateId(sku, attributes)

  return state.images.templateProgress[templateId] ? state.images.templateProgress[templateId] : IDLE
}

function selectArtworkStatus(allArtworkProgress: Dictionary<ArtworkProgress>, id: string): Status {
  return allArtworkProgress[id] ? allArtworkProgress[id].status : IDLE
}

function selectArtworkErrorMessage(allArtworkProgress: Dictionary<ArtworkProgress>, id: string): string {
  return allArtworkProgress[id] ? allArtworkProgress[id].errorMessage : DEFAULT_ERROR_MESSAGE
}

export function selectAllTemplateStatus(state: AppState): Dictionary<Status> {
  return state.images.templateProgress
}

export function selectArtworkProgress(state: AppState): Dictionary<ArtworkProgress> {
  return state.images.artworkProgress
}

function selectArtworks(state: AppState): Dictionary<Artwork> {
  return state.images.artworks
}

function selectArtworkByItemId(artworks: Dictionary<Artwork>, imageId: string): Artwork {
  return artworks[imageId]
}

function selectArtworkTransformations(state: AppState): Dictionary<ArtworkTransformations> {
  return state.images.artworkTransformations
}

function selectArtworkTransformationStatus(state: AppState): Dictionary<Status> {
  return state.images.artworkTransformationStatus
}

function selectArtworkTransformationsById(
  artworkTransformations: Dictionary<ArtworkTransformations>,
  itemId: string
): ArtworkTransformations {
  return artworkTransformations[itemId]
}

function selectAllArtworkStatus(allArtworkProgress: Dictionary<ArtworkProgress>): Dictionary<Status> {
  return Object.keys(allArtworkProgress).reduce((statusAcc, artworkId) => {
    statusAcc[artworkId] = allArtworkProgress[artworkId].status
    return statusAcc
  }, {})
}

function selectArtworkStatusByIds(
  allArtworkProgress: Dictionary<ArtworkProgress>,
  idsToSelect: string[]
): Dictionary<Status> {
  return Object.keys(allArtworkProgress).reduce((statusAcc, artworkId) => {
    if (idsToSelect.includes(artworkId)) {
      statusAcc[artworkId] = allArtworkProgress[artworkId].status
    }
    return statusAcc
  }, {})
}

function selectArtworkTransformationStatusById(
  artworkTransformationStatus: Dictionary<Status>,
  id: string
): Status | null {
  return artworkTransformationStatus[id]
}

export function selectImageDownloadProgress(state: AppState): Dictionary<Status> {
  return state.images.downloadProgress
}

function selectTemplateStatusByIds(status: Dictionary<Status>, templateIds: string[]): Dictionary<Status> {
  return Object.keys(status).reduce((templateStatusAcc, templateId) => {
    if (templateIds.includes(templateId)) {
      templateStatusAcc[templateId] = status[templateId]
    }
    return templateStatusAcc
  }, {})
}

export const getArtworkTransformationStatusById: GetArtworkTransformationStatusById = createSelector(
  selectArtworkTransformationStatus,
  returnSecondArg,
  selectArtworkTransformationStatusById
)
export const getProductTemplates: GetProductTemplates = createSelector(
  selectTemplates,
  returnSecondArg,
  returnThirdArg,
  selectProductTemplates
)
export const getTemplateStatus: GetTemplateStatus = createSelector(selectTemplateStatus, identity)
export const getArtworkStatus: GetArtworkStatus = createSelector(
  selectArtworkProgress,
  returnSecondArg,
  selectArtworkStatus
)
export const getArtworkErrorMessage: GetArtworkErrorMessage = createSelector(
  selectArtworkProgress,
  returnSecondArg,
  selectArtworkErrorMessage
)
export const getAllTemplateStatus: GetAllTemplateStatus = createSelector(selectAllTemplateStatus, identity)
export const getArtworkProgress: GetArtworkProgress = createSelector(selectArtworkProgress, identity)
export const getArtworkByItemId: GetArtworkByItemId = createSelector(
  selectArtworks,
  returnSecondArg,
  selectArtworkByItemId
)
export const getArtworkTransformationsById: GetArtworkTransformationsById = createSelector(
  selectArtworkTransformations,
  returnSecondArg,
  selectArtworkTransformationsById
)
export const getAllArtworks: GetAllArtworks = createSelector(selectArtworks, identity)
export const getAllArtworkTransformations: (state: AppState) => Dictionary<ArtworkTransformations> = createSelector(
  selectArtworkTransformations,
  identity
)
export const getAllArtworkStatus: GetAllArtworkStatus = createSelector(selectArtworkProgress, selectAllArtworkStatus)
export const getArtworkStatusByIds: GetArtworkStatusByIds = createSelector(
  selectArtworkProgress,
  returnSecondArg,
  selectArtworkStatusByIds
)

export const getTemplateStatusByIds: GetTemplateStatusByIds = createSelector(
  getAllTemplateStatus,
  returnSecondArg,
  selectTemplateStatusByIds
)
