// @flow

import { fetchPreviewTemplate } from '.'
import type { Thunk, DispatchFunc, GetStateFunc, MultiAssetBasketItem } from '../../types'
import { selectBasketItems, getArtworkByBasketItemId, getArtworkTransformationsByBasketItemId } from '../../selectors/manualOrderForm'
import { getProductTemplates } from '../../selectors/images'
import { changeSelectedPrintAreaForBasketItem } from './changeSelectedPrintAreaForBasketItem'
import { initImage } from './uploadArtwork'

export function fetchPreviewTemplatesForAllBasketItems (): Thunk<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItems = selectBasketItems(getState())

    for (const basketItem of basketItems) {
      if (getProductTemplates(getState(), basketItem.sku, basketItem.selectedAttributes)) {
        continue
      }

      await dispatch(fetchPreviewTemplate(basketItem.sku, basketItem.id, basketItem.selectedAttributes))

      const artworkTransformations = getArtworkTransformationsByBasketItemId(getState(), basketItem.id)
      if (!artworkTransformations) {
        dispatch(addMissingArtworkTransformations(basketItem))
      }
    }
  }
}

function addMissingArtworkTransformations (basketItem: MultiAssetBasketItem) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const artwork = getArtworkByBasketItemId(getState(), basketItem.id)
    const templates = getProductTemplates(getState(), basketItem.sku, basketItem.selectedAttributes)

    if (!templates || !artwork) {
      return
    }

    const availablePrintAreas = Object.keys(templates.printAreas)
    const selectedPrintArea = basketItem.selectedPrintArea && availablePrintAreas.includes(basketItem.selectedPrintArea)
      ? basketItem.selectedPrintArea
      : availablePrintAreas[0]

    dispatch(changeSelectedPrintAreaForBasketItem(basketItem.id.toString(), selectedPrintArea))
    dispatch(initImage(basketItem.id, artwork, selectedPrintArea))
  }
}
