// @flow
import React from 'react'

import Button from '../Button'
import styles from './ImageUploadToPig.module.css'
import { SUPPORT_EMAIL } from '../../data/constants'

type Props = {|
  autoFocusButton?: boolean,
  defaultText?: string,
  errorText?: string,
  fileUploadKey?: string,
  hasError?: boolean,
  isUploading?: boolean,
  isHovering?: boolean,
  uploadingText?: string,
  onImageSelected?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  toggleHoverState?: (isHovering: boolean) => void
|}

export default class ImageUploadToPigView extends React.PureComponent<Props> {
  render (): React$Node {
    const {
      autoFocusButton = false,
      defaultText,
      errorText,
      fileUploadKey,
      hasError = false,
      isHovering = false,
      isUploading = false,
      uploadingText,
      onImageSelected,
      toggleHoverState
    } = this.props

    const fileInputRef = React.createRef()

    return (
      <div className={styles.uploadImageContainer}>
        <Button
          autoFocus={autoFocusButton}
          outline={!isHovering}
          primary={isHovering}
          destructive={hasError}
          className={styles.uploadImageButton}
          type="button"
          loading={isUploading}
          onClick={() => { fileInputRef.current && fileInputRef.current.click() }}
          onMouseOver={() => toggleHoverState && toggleHoverState(true)}
          onMouseLeave={() => toggleHoverState && toggleHoverState(false)}>
          {isUploading ? (uploadingText ?? 'Uploading') : (defaultText ?? 'Upload image')}
        </Button>
        {hasError && (errorText
          ? <div className={styles.errorText}>{errorText}</div>
          : <div className={styles.errorText}>Failed to upload image, please try again later or <a href={`mailto:${SUPPORT_EMAIL}`}>contact support</a> if the problem persists</div>)}
        <input
          key={fileUploadKey}
          className={styles.noDisplay}
          multiple={false}
          accept="image/png,image/jpeg"
          type="file"
          ref={fileInputRef}
          onChange={onImageSelected}></input>
      </div>
    )
  }
}
