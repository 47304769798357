function AdobeCommerceLogoMarkIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="271"
      height="240"
      viewBox="0 0 271 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.5 0L270.8 239.3V0H170.5ZM0 0V239.3L100.3 0H0ZM91.7 190.5H137.5L157.6 239.2H199.1L134.7 87.4L91.7 190.5Z"
        fill="#ED2224"
      />
    </svg>
  )
}

export { AdobeCommerceLogoMarkIcon }
