
// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'
import isZeroCostProduct from '../../../helpers/isZeroCostProduct'

import {
  RECEIVE_PRICES,
  FETCH_PRICES_ERROR,
  REQUEST_PRODUCT_SEARCH,
  PRODUCT_SEARCH_FAILED,
  FAILED_ITEM_CATEGORIES,
  SELECT_ITEM_CATEGORY,
  GET_V4_PRODUCT_DETAILS_ERROR
} from '../../../actions/catalogue'

export function catalogue (action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  const searchQuery = state.catalogue.searchQuery
  switch (action.type) {
    case SELECT_ITEM_CATEGORY: {
      return ['Category selected', { category: action.category }]
    }

    case REQUEST_PRODUCT_SEARCH: {
      if (action.error) {
        return ['Product search failed', { searchQuery: searchQuery, resp: { ...action.payload } }, 'error']
      } else {
        return null
      }
    }

    case RECEIVE_PRICES: {
      const itemsWithoutPrice = action.payload.prices.filter(x => !x.price && !isZeroCostProduct(x.sku, x.price))
      if (itemsWithoutPrice.length > 0) {
        return ['Fetching prices failed', { items: { ...itemsWithoutPrice } }, 'error']
      } else {
        return null
      }
    }

    case FETCH_PRICES_ERROR: {
      const searchItems = state.catalogue.searchItems

      if (searchItems && searchItems.length) {
        return ['Fetching prices failed', { skus: searchItems.map(x => x.sku).join(), resp: { ...action.payload } }, 'error']
      }
      return ['Fetching prices failed']
    }

    case PRODUCT_SEARCH_FAILED: {
      return ['Product search failed', { searchQuery: searchQuery, resp: { ...action.payload } }, 'error']
    }

    case FAILED_ITEM_CATEGORIES: {
      return ['Fetching product categories failed', { resp: { ...action.payload } }, 'error']
    }

    case GET_V4_PRODUCT_DETAILS_ERROR: {
      const { type, ...rest } = action
      return ['Fetching v4 product details failed', { ...rest }, 'error']
    }

    default: {
      return null
    }
  }
}
