import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../constants'

export default function Settings() {
  const location = useLocation()
  const history = useHistory()
  const settingsIndexRouteMatch = useRouteMatch(ROUTE_PATHS.SETTINGS.INDEX)

  const isSandboxEnvironment = process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX

  useEffect(() => {
    if (!settingsIndexRouteMatch?.isExact) {
      return
    }

    if (isSandboxEnvironment) {
      history.replace(ROUTE_PATHS.SETTINGS.API)
      return
    }

    history.replace(ROUTE_PATHS.SETTINGS.ACCOUNT)
  }, [history, isSandboxEnvironment, location.pathname, settingsIndexRouteMatch?.isExact])

  return null
}
