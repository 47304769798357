import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import SupportLink from '../../SupportLink'
import { ROUTE_PATHS } from '../../../constants'
import { FullPageImageTile, ImageTileSkeleton } from '.'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface ImageListLoadedPropsInterface {
  imageLibraryMode: ImageLibraryFullPageModeType
  selectedImages: ImageLibraryImageInterface[]
  setSelectedImages: (selected: ImageLibraryImageInterface[]) => void
}

export function FullPageImageListLoaded({
  imageLibraryMode,
  selectedImages,
  setSelectedImages
}: ImageListLoadedPropsInterface) {
  const { imageLibraryPages, imageLibraryFetchError, imageLibraryPagesToLoadSize, setImageLibraryPagesToLoadSize } =
    useImageLibraryImages()

  const loadedImageLibraryPagesSize = imageLibraryPages?.length ?? 1
  const hasNextPage = Boolean(imageLibraryPages && imageLibraryPages?.[loadedImageLibraryPagesSize - 1]?.length > 0)
  const isLoadingNextPageOfImageLibrary = hasNextPage && loadedImageLibraryPagesSize < imageLibraryPagesToLoadSize

  const [paginationTriggerRef] = useInfiniteScroll({
    disabled: Boolean(imageLibraryFetchError),
    loading: isLoadingNextPageOfImageLibrary,
    delayInMs: 200,
    hasNextPage,
    onLoadMore: () => {
      setImageLibraryPagesToLoadSize(imageLibraryPagesToLoadSize + 1)
    }
  })

  if (!imageLibraryPages) {
    throw Error('No image library image pages')
  }

  const showNextPageLoadingSkeletons = !imageLibraryFetchError && (isLoadingNextPageOfImageLibrary || hasNextPage)
  const showAllImagesLoadedMessage = imageLibraryPages.length > 2 && !hasNextPage

  return (
    <>
      {imageLibraryPages.map((imageLibraryImages) =>
        imageLibraryImages.map((imageLibraryImage, index) => (
          <ImageLibraryListItemLink
            className="h-full"
            key={imageLibraryImage.public_id}
            imageId={imageLibraryImage.public_id}
            imageLibraryMode={imageLibraryMode}
          >
            <FullPageImageTile
              key={imageLibraryImage.public_id}
              imageLibraryImage={imageLibraryImage}
              imageLibraryMode={imageLibraryMode}
              index={index}
              selectedImages={[...selectedImages]}
              onImageSelectionUpdate={setSelectedImages}
            />
          </ImageLibraryListItemLink>
        ))
      )}

      {imageLibraryFetchError && (
        <div className="col-span-full mx-auto max-w-2xl pt-6 text-center text-red-500">
          An error occurred while loading the next page of images. Please try again later or{' '}
          <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists (Code{' '}
          {imageLibraryFetchError.status ?? '0'})
        </div>
      )}

      {showAllImagesLoadedMessage && (
        <div className="col-span-full mx-auto max-w-[250px] pt-6 text-center text-gray-600">All images loaded</div>
      )}

      {showNextPageLoadingSkeletons &&
        Array.from({ length: 2 }, (_, i) => i).map((value) => (
          <ImageTileSkeleton key={value} ref={paginationTriggerRef} />
        ))}
    </>
  )
}

function ImageLibraryListItemLink({
  className,
  children,
  imageId,
  imageLibraryMode
}: {
  className?: string
  children: ReactNode
  imageId: string
  imageLibraryMode: ImageLibraryFullPageModeType
}) {
  if (imageLibraryMode === 'manage') {
    return <div className={`block focus-visible:outline-none ${className}`}>{children}</div>
  }

  return (
    <Link
      className={`block ${className}`}
      to={ROUTE_PATHS.IMAGE_DETAIL(imageId)}
      onClick={() => window.analytics.track('Image Library - image selected')}
    >
      {children}
    </Link>
  )
}
