export const countriesWithoutPostcodes = [
  { name: 'Angola', code: 'AO' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, the Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: "Cote d'Ivoire", code: 'CI' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Macao', code: 'MO' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Mali', code: 'ML' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'Niue', code: 'NU' },
  { name: 'North Korea', code: 'KP' },
  { name: 'Panama', code: 'PA' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Syria', code: 'SY' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zimbabwe', code: 'ZW' },
  { name: 'Samoa', code: 'WS' }
]
