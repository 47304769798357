import { calcPrintDimensionsFillScaleFactor } from '@prodigi-group/components-image-editor'

import { DimensionsInterface } from '../interfaces'

export function calculateFillScaleFactor(
  printResolutionInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  borderInPx: number,
  rotationAngle: number
): number {
  const scaleFactor = calcPrintDimensionsFillScaleFactor(
    printResolutionInPx,
    artworkDimensionsInPx,
    borderInPx,
    rotationAngle
  )

  return scaleFactor * 100
}
