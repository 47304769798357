// @flow
import React from 'react'

import styles from './sharedStyles.module.css'
import ImageInfo from '../../../components/ImageInfo'
import ImageUploadToPig from '../../../components/ImageUploadToPig'

type Props = {|
  autoFocusButton?: boolean,
  defaultText?: string,
  fileName: string,
  fileSize: string,
  fullImageUrl: string,
  removeTooltipPosition?: string,
  brandLogoChangeHandler: (fileName?: string, fileSize?: string, fullImageUrl?: string) => void
|}

type State = {|
  imageUrl: string | null
|}

class BrandLogo extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      imageUrl: null
    }
  }

  componentDidMount () {
    if (this.props.fullImageUrl) {
      this.setState({
        imageUrl: this.props.fullImageUrl
      })
    }
  }

  handleImageUpload: ((
  fileName: string,
  fileSize: string,
  previewImageUrl: string,
  fullImageUrl: string
) => void) = (fileName: string, fileSize: string, previewImageUrl: string, fullImageUrl: string) => {
  this.setState({
    imageUrl: previewImageUrl
  })
  this.props.brandLogoChangeHandler(fileName, fileSize, fullImageUrl)
}

  handleClearImage: (() => void) = () => {
    this.setState({
      imageUrl: null
    })
    this.props.brandLogoChangeHandler()
  }

  render (): React$Node {
    const {
      imageUrl
    } = this.state

    const {
      autoFocusButton = true,
      defaultText = 'Add Logo',
      fileName,
      fileSize,
      removeTooltipPosition = 'right'
    } = this.props

    const content = imageUrl
      ? (<div className={styles.logoInfoContainer}>
        <ImageInfo
          fileName={fileName}
          fileSize={fileSize}
          imageAltText="Uploaded logo"
          imageUrl={imageUrl}
          showClearImageButton={true}
          tooltipPlacement={removeTooltipPosition}
          onClearImage={this.handleClearImage} />
      </div>)
      : (<div className={styles.logoUploadButtonContainer}>
        <ImageUploadToPig
          autoFocusButton={autoFocusButton}
          defaultText={defaultText}
          onUploadComplete={(fileName, fileSize, previewImageUrl, fullImageUrl) => this.handleImageUpload(fileName, fileSize, previewImageUrl, fullImageUrl)} />
      </div>)

    return (
      <div className={styles.stepBrandLogo}>
        {content}
      </div>
    )
  }
}

export default BrandLogo
