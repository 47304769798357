import useSWR from 'swr'

import { FetchErrorInterface } from '../interfaces'
import { fetcher, getDefaultFetchOptions } from '../helpers'

interface ZendeskAuthInterface {
  accessToken: string
}

interface ZendeskAuthFailureInteface {
  message: string
  name: string
  status: number
  statusText: string
  response: null
}

export function useZendeskAuth(merchantId?: string) {
  const url = process.env.REACT_APP_AUTH_SERVICE + '/api/auth/zendesk'

  const MILLISECONDS_PER_MINUTE = 60000
  const jwtIntervalInMinutes = parseInt(process.env.REACT_APP_JWT_INTERVAL_IN_MINUTES ?? '0', 10)
  const jwtIntervalInMilliseconds = jwtIntervalInMinutes * MILLISECONDS_PER_MINUTE

  const { data } = useSWR<ZendeskAuthInterface, FetchErrorInterface<ZendeskAuthFailureInteface>>(
    merchantId ? [url, merchantId] : null,
    authFetcher,
    {
      refreshInterval: jwtIntervalInMilliseconds,
      revalidateOnFocus: false
    }
  )

  return {
    jwt: data?.accessToken
  }
}

function authFetcher(url: string, merchantId: string) {
  return fetcher<ZendeskAuthInterface>(url, {
    headers: {
      Authorization: getDefaultFetchOptions().headers.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ merchantId }),
    method: 'POST'
  })
}
