// @flow
import type { OrderFilters } from '../../types'
import type { SetOrderFilters } from '../types'

export const SET_ORDER_FILTERS = 'SET_ORDER_FILTERS'

export function setOrderFilters (filters: OrderFilters): SetOrderFilters {
  return {
    type: SET_ORDER_FILTERS,
    query: filters.query,
    scope: filters.scope,
    skip: filters.skip,
    limit: filters.limit
  }
}
