// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'
import { GET_QUOTES, GET_QUOTES_ERROR } from '../../../actions/quotes'

export function quotes (action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case GET_QUOTES: {
      return ['Fetching quotes', { id: action.meta.loggerId }]
    }

    case GET_QUOTES_ERROR: {
      return ['Fetching quotes failed', {
        ...action.payload,
        id: action.meta.loggerId,
        skus: action.meta.skus
      }, 'error']
    }

    default: {
      return null
    }
  }
}
