// @flow

export function selectAppVersion(): string {
  return readRequiredString('REACT_APP_VERSION')
}

export function selectEnvironmentName(): string {
  return readRequiredString('REACT_APP_ENV')
}

export function selectProdigiApiEndpoint(): string {
  return readRequiredString('REACT_APP_PRODIGI_API_ENDPOINT')
}

export function selectJwtIssuer(): string {
  return readRequiredString('REACT_APP_JWT_ISSUER')
}

export function selectJwtIntervalInMinutes(): number {
  const intervalAsString = readRequiredString('REACT_APP_JWT_INTERVAL_IN_MINUTES')
  return parseInt(intervalAsString, 10)
}

export function selectGoogleAnalyticsSettings(): {| enabled: boolean, id: string |} | {| enabled: boolean |} {
  const id = readOptionalString('REACT_APP_GA_ID')

  return id ? { enabled: true, id } : { enabled: false }
}

export function selectPigUrl(): string {
  return readRequiredString('REACT_APP_PIG_URL')
}

export function selectImagePipelineUrl(): string {
  return readRequiredString('REACT_APP_IMAGE_PIPELINE')
}

export function selectArtworkPipelineUrl(): string {
  return readRequiredString('REACT_APP_ARTWORK_CATALOGUE')
}

export function selectTemplateServiceUrl(): string {
  return readRequiredString('REACT_APP_TEMPLATE_SERVICE')
}

export function selectPrintEngineUrl(): string {
  return readRequiredString('REACT_APP_PRINT_ENGINE')
}

export function selectPwintyApiSettings(): {| documentationUri: string, endpoint: string |} {
  return {
    endpoint: readRequiredString('REACT_APP_ENDPOINT'),
    documentationUri: readRequiredString('REACT_APP_PWINTY_API_DOCUMENTATION_URI')
  }
}

export function selectAuthServiceUrl(): string {
  return readRequiredString('REACT_APP_AUTH_SERVICE')
}

export function selectAzureSearchSettings(): {| endpoint: string, key: string |} {
  return {
    endpoint: readRequiredString('REACT_APP_SEARCH_ENDPOINT'),
    key: readRequiredString('REACT_APP_SEARCH_KEY')
  }
}

export function selectPhotobookHostname(): string {
  return readRequiredString('REACT_APP_PHOTOBOOK_HOSTNAME')
}

export function selectPrintShopHostname(): string {
  return readRequiredString('REACT_APP_PRINT_SHOP_HOSTNAME')
}

function readOptionalString(settingName: string): ?string {
  return process.env[settingName]
}

function readRequiredString(settingName: string): string {
  const value = readOptionalString(settingName)

  if (!value) {
    throw new Error(`Setting ${settingName} has not been provided`)
  }

  return value
}
