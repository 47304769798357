// @flow
import { createSelector } from 'reselect'
import type { AppState } from '../types'
import { identity } from './helpers'

export function selectIsUserLoggedIn (state: AppState): boolean {
  return state.auth.isUserLoggedIn
}

export function selectJwtTimerId (state: AppState): ?IntervalID {
  return state.auth.jwtTimerId
}

export function selectSessionExpired (state: AppState): boolean {
  return state.auth.sessionExpired
}

export const getSessionExpired: any = createSelector(selectSessionExpired, identity)
export const getIsUserLoggedIn: any = createSelector(selectIsUserLoggedIn, identity)
