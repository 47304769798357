import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { generateIssueFlagTitle } from './helpers'
import { OrderDetailIssueEnum } from '../../enums'

interface OrderIssueFlagPropsInterface {
  className?: string
  condensed?: boolean
  unresolvedIssues: OrderDetailIssueEnum[]
}

function OrderIssueFlag({ className = '', condensed = false, unresolvedIssues = [] }: OrderIssueFlagPropsInterface) {
  const flagTitle = generateIssueFlagTitle(unresolvedIssues)

  if (condensed) {
    return (
      <span
        className={className}
        title={flagTitle}
        onClick={() => {
          window.analytics.track('Clicked CS issue order summary')
        }}
      >
        <ExclamationTriangleIcon className="h-8 w-8 text-magenta-500" />
      </span>
    )
  }
  return (
    <span
      className={clsx(className, 'flex space-x-1 md:items-center')}
      onClick={() => {
        window.analytics.track('Clicked CS issue order details')
      }}
    >
      <ExclamationTriangleIcon className="h-8 w-8 flex-shrink-0 text-magenta-500" />
      <span className="font-medium text-magenta-500">{flagTitle}</span>
    </span>
  )
}

export default OrderIssueFlag
