import { useMemo } from 'react'
import { motion } from 'framer-motion'
import { useHistory, useLocation } from 'react-router-dom'

import Button from '../../Button'

export function VariantsNotFoundWithAppliedFilters() {
  const { search, pathname } = useLocation()
  const history = useHistory()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function handleClearAll() {
    const paramsToRemove: string[] = []
    searchParams.forEach((_, param) => paramsToRemove.push(param))
    paramsToRemove.forEach((param) => searchParams.delete(param))
    history.replace({ pathname, search: searchParams.toString() })
  }

  return (
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }}>
      <div className="flex flex-col items-center justify-center gap-y-6 py-8">
        <span>No variants found using selected filters.</span>
        <Button variant="secondary" onClick={handleClearAll}>
          Clear filters
        </Button>
      </div>
    </motion.div>
  )
}
