// @flow
import React, { Component } from 'react'
import FormItem from '../../../components/form/FormItem'
import TextField from '../../../components/inputs/TextField'
import mediaQueries from '../../../components/styles/mediaQueries'
import Radium from 'radium'

type Props = {|
  merchantOrderId: string,
  changeMerchantOrderId: (merchantOrderId: string) => void
|}

class MerchantOrderIdInputField extends Component<Props> {
  getStyles () {
    return {
      formItem: {
        [mediaQueries.breakpointLarge]: {
          width: '100%'
        }
      }
    }
  }

  render (): React$Node {
    const {
      merchantOrderId,
      changeMerchantOrderId
    } = this.props
    const styles = this.getStyles()

    return (
      <div>
        <FormItem
          inputStyle={styles.formItem}
          inputField={
            <TextField
              value={merchantOrderId}
              type='text'
              onChange={(e) => changeMerchantOrderId(e.target.value)}
              id='merchantOrderId'
              name='merchantOrderId'
              dataTest="order-reference"
            />} />
      </div>
    )
  }
}

export default (Radium(MerchantOrderIdInputField): any)
