import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { BellIcon, ShoppingCartIcon } from '@heroicons/react/24/outline'

import Button from '../../Button'
import { ROUTE_PATHS } from '../../../constants'
import { AccountNavItem } from './AccountNavItem.component'
import { getNumberOfItemsInBasket } from '../../../../selectors/manualOrderForm'
import { FEATURE_NAMES } from 'src/split-io/feature-names'
import { useSplitToggle } from 'src/v2/hooks'

export function DesktopTopNav() {
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })
  return (
    <div className="flex h-full items-center border-b border-gray-200">
      <div className="pl-6">
        <BackButton />
      </div>

      <ul className="ml-auto flex text-gray-600">
        <li>
          <NotificationsNavItem />
        </li>

        {!isShopifySplitOn && (
          <li className="border-l-2 border-gray-100">
            <BasketNavItem />
          </li>
        )}

        <li className="border-l-2 border-gray-100">
          <AccountNavItem />
        </li>
      </ul>
    </div>
  )
}

function BackButton() {
  const history = useHistory()

  return (
    <Button
      variant="text"
      theme="greyscale"
      className="opacity-80"
      startIcon={<ArrowLeftIcon className="h-6 w-6" />}
      onClick={() => history.goBack()}
    >
      Back
    </Button>
  )
}

function NotificationsNavItem() {
  return (
    <button className="flex items-center justify-center p-6 hover:opacity-100" id="beamer-navigation-trigger">
      <BellIcon className="h-9 w-9" />
      <span className="sr-only">News and updates</span>
    </button>
  )
}

function BasketNavItem() {
  const numberOfItemsInBasket: number = useSelector(getNumberOfItemsInBasket)
  const numberOfItemsInBasketToDisplay = numberOfItemsInBasket > 99 ? '99+' : numberOfItemsInBasket

  return (
    <Link className="relative flex items-center justify-center p-6" to={ROUTE_PATHS.MOF.BASKET}>
      <ShoppingCartIcon className="h-9 w-9" />
      <AnimatePresence>
        {Boolean(numberOfItemsInBasketToDisplay) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-[3px] right-[3px] flex h-[20px] w-[20px] items-center justify-center rounded-full bg-purple-600 text-[10px] font-medium text-white"
          >
            {numberOfItemsInBasketToDisplay}
          </motion.div>
        )}
      </AnimatePresence>
      <span className="sr-only">Basket</span>
    </Link>
  )
}
