import { TemplateDataInterface, TemplateServiceSuccessResponseInterface } from '../../../hooks'

export function selectFirstAvailableTemplate(
  templates: TemplateServiceSuccessResponseInterface | undefined,
  selectedPrintArea: string
): TemplateDataInterface | null | undefined {
  const templatesByOrientations = templates?.printAreas[selectedPrintArea]?.orientations
  if (!templatesByOrientations) {
    return null
  }
  const firstAvailableTemplate = Object.values(templatesByOrientations)[0]
  return firstAvailableTemplate
}
