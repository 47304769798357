import { useState } from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router'
import { TrashIcon } from '@heroicons/react/24/outline'

import Button from '../../Button'
import { DeleteFromImageLibaryModal } from '.'
import { useImageLibraryImage } from '../../../hooks'
import { ImageLibraryFileTypePill } from '../../ImageLibraryFileTypePill/ImageLibraryFileTypePill.component'

export function ImageFooter() {
  const { id: imageId } = useParams<{ id: string }>()
  const { image: imageLibraryImage } = useImageLibraryImage({ imageId })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  if (!imageLibraryImage) {
    return null
  }

  const creationDate = new Date(imageLibraryImage.date_created)
  const updateDate = new Date(imageLibraryImage.date_last_modified)

  return (
    <div className="flex w-full flex-col-reverse justify-between gap-y-12 text-sm text-gray-700 sm:flex-row">
      <div className="space-y-4">
        <p className="flex items-center gap-4">
          <ImageLibraryFileTypePill className="flex" fileType={imageLibraryImage.file_type} />
          {imageLibraryImage.pixel_width && imageLibraryImage.pixel_height && (
            <span>
              {imageLibraryImage.pixel_width} x {imageLibraryImage.pixel_height}px
            </span>
          )}
          {imageLibraryImage.pdf_page_count > 0 && (
            <span>
              {imageLibraryImage.pdf_page_count} page{imageLibraryImage.pdf_page_count > 1 && 's'}
            </span>
          )}
        </p>
        <p>
          Added {format(creationDate, 'dd MMMM YYY')}. Last updated {format(updateDate, 'dd MMMM YYY')}.
        </p>
      </div>

      <div className="">
        <Button
          variant="primary"
          theme="danger"
          startIcon={<TrashIcon className="h-7 w-7" />}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete
        </Button>

        <DeleteFromImageLibaryModal
          imageLibraryImage={imageLibraryImage}
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
        />
      </div>
    </div>
  )
}
