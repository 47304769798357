// @flow
import React, { PureComponent } from 'react'
import MultiAssetImageUpload from './MultiAssetImageUpload'
import type { ReactRef, ThumbnailItem, PrintAreaEntries, Status, Artwork } from '../../types'
import { ERROR, LOADING } from '../../data/rsaa'
import { canProductBeTiled } from '../../helpers/products'

type Props = {|
  imageStatus: Status,
  templateStatus: Status,
  thumbnailItem: ?ThumbnailItem,
  imageUrl: ?string,
  imageQuality: ?number,
  showRemoveImageButton?: boolean,
  hasImageUploadError: boolean,
  isUploadingImage: boolean,
  imageUploadErrorMessage: string,
  showSaveAndCloseButton?: boolean,
  selectedPrintArea: string,
  itemCategory: string,
  productType: string,
  isLoadingImageContainerClassName?: string,
  noArtworkBackgroundImageStyle?: ?string,
  optimalResolution?: ?string,
  printAreaEntries: ?PrintAreaEntries,
  isTilingEnabled: boolean,
  supportsTiling: boolean,
  toggleTiling: () => void,
  onOpenImageLibrary?: () => void,
  saveAndCloseAction?: () => void,
  onPrintAreaChange?: (newPrintAreaName: string) => void,
  uploadArtworkFromFile: (file: File) => void,
  uploadArtworkFromUrl: (url: string) => void,
  onEditImageClick: () => void,
  onRemoveImage?: () => void,
  onSelectImageLibraryImage: (artwork: Artwork) => void
|}

type State = {|
  hasArtworkError: boolean,
  hasOverlayError: boolean,
  hasUnderlayError: boolean,
  isOverlayLoading: boolean,
  isArtworkLoading: boolean,
  hasImageError: boolean,
  isUrlUploadModalOpen: boolean,
  fileInputRef: ReactRef<HTMLInputElement>
|}

export default class MultiAssetImageUploadController extends PureComponent<Props, State> {
  state: State = {
    hasArtworkError: false,
    hasOverlayError: false,
    hasUnderlayError: false,
    isOverlayLoading: true,
    isArtworkLoading: true,
    isUrlUploadModalOpen: false,
    fileInputRef: React.createRef(),
    hasImageError: false
  }

  toggleUrlUploadModal: () => void = () =>
    this.setState((state) => ({ isUrlUploadModalOpen: !state.isUrlUploadModalOpen }))

  onImageError: () => void = () => this.setState({ hasImageError: true })

  onImageLoaded: () => void = () => this.setState({ hasImageError: false })

  uploadFile: (e: SyntheticInputEvent<HTMLInputElement>) => void = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ hasArtworkError: false })
    if (e.target.files.length > 0) {
      const file = e.target.files[0]
      this.props.uploadArtworkFromFile(file)
    }
  }

  openFileUpload: () => void = () => {
    if (this.state.fileInputRef.current) {
      this.state.fileInputRef.current.click()
    }
  }

  get isImageEditorAvailable(): boolean {
    return Boolean(
      this.props.templateStatus !== ERROR &&
        !this.state.hasImageError &&
        !this.state.hasArtworkError &&
        !this.state.hasOverlayError &&
        !this.state.hasUnderlayError
    )
  }

  get showEditButton(): boolean {
    return Boolean(this.props.imageUrl && !this.props.isUploadingImage && this.isImageEditorAvailable)
  }

  get showSaveAndCloseButton(): boolean {
    return Boolean(this.props.showSaveAndCloseButton) && !this.props.hasImageUploadError
  }

  get isLoadingPreview(): boolean {
    return (
      this.isImageEditorAvailable &&
      this.props.thumbnailItem?.artwork?.mimeType !== 'application/pdf' &&
      Boolean(this.props.imageUrl) &&
      Boolean(
        this.props.imageStatus === LOADING ||
          this.props.templateStatus === LOADING ||
          (!this.props.hasImageUploadError && (this.state.isOverlayLoading || this.state.isArtworkLoading))
      )
    )
  }

  onLoadOverlay: () => void = () => this.setState({ isOverlayLoading: false })

  onLoadArtwork: () => void = () => this.setState({ isArtworkLoading: false })

  onArtworkError: () => void = () => {
    this.setState({ hasArtworkError: true })
    this.props.onRemoveImage?.()
  }

  onOverlayError: () => void = () => this.setState({ hasOverlayError: true })

  onUnderlayError: () => void = () => this.setState({ hasUnderlayError: true })

  get imageEditorKey(): string {
    return 'image-preview-' + this.props.templateStatus + '-' + this.props.imageStatus + (this.props.imageUrl ?? '')
  }

  get printResolutionLabel(): string {
    return canProductBeTiled(this.props.productType) ? 'Full product resolution:' : 'Recommended size:'
  }

  render(): React$Node {
    return (
      <MultiAssetImageUpload
        isLoadingImageContainerClassName={this.props.isLoadingImageContainerClassName}
        isTilingEnabled={this.props.isTilingEnabled}
        toggleTiling={this.props.toggleTiling}
        supportsTiling={this.props.supportsTiling}
        printResolutionLabel={this.printResolutionLabel}
        onOpenImageLibrary={this.props.onOpenImageLibrary}
        showSaveAndCloseButton={this.showSaveAndCloseButton}
        showRemoveImageButton={this.props.showRemoveImageButton ?? false}
        saveAndCloseAction={this.props.saveAndCloseAction}
        isImageEditorAvailable={this.isImageEditorAvailable}
        imageUrl={this.props.imageUrl}
        imageQuality={this.props.imageQuality}
        itemCategory={this.props.itemCategory}
        productType={this.props.productType}
        hasArtworkError={this.state.hasArtworkError}
        isUrlUploadModalOpen={this.state.isUrlUploadModalOpen}
        isUploadingImage={this.props.imageStatus === LOADING}
        isFetchingTemplate={this.props.templateStatus === LOADING}
        hasImageUploadError={this.props.hasImageUploadError}
        isLoadingPreview={this.isLoadingPreview}
        imageUploadErrorMessage={this.props.imageUploadErrorMessage}
        showEditButton={this.showEditButton}
        optimalResolution={this.props.optimalResolution}
        uploadArtworkFromUrl={this.props.uploadArtworkFromUrl}
        uploadFile={this.uploadFile}
        noArtworkBackgroundImageStyle={this.props.noArtworkBackgroundImageStyle}
        printAreaEntries={this.props.printAreaEntries}
        selectedPrintArea={this.props.selectedPrintArea}
        onEditImageClick={this.props.onEditImageClick}
        fileInputRef={this.state.fileInputRef}
        imageEditorKey={this.imageEditorKey}
        thumbnailItem={this.props.thumbnailItem}
        onLoadOverlay={this.onLoadOverlay}
        onLoadArtwork={this.onLoadArtwork}
        onPrintAreaChange={this.props.onPrintAreaChange}
        onRemoveImage={this.props.onRemoveImage}
        toggleUrlUploadModal={this.toggleUrlUploadModal}
        onImageError={this.onImageError}
        onImageLoaded={this.onImageLoaded}
        onArtworkError={this.onArtworkError}
        onOverlayError={this.onOverlayError}
        onUnderlayError={this.onUnderlayError}
        openFileUpload={this.openFileUpload}
        onSelectImageLibraryImage={this.props.onSelectImageLibraryImage}
      />
    )
  }
}
