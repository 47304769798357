// @flow
import { LENGTH_UNIT } from '@prodigi-group/components-image-editor'
import { MM_PER_INCH } from '../../data/constants'
import type { CmOrInch } from '../../types'

export function convertBorderWidthToPrintPx (
  printDpi: number,
  borderWidth: number,
  borderUnit: CmOrInch
): number {
  if (borderUnit === LENGTH_UNIT.IN) {
    return borderWidth * printDpi
  } else if (borderUnit === LENGTH_UNIT.CM) {
    const borderWidthInInch = borderWidth / MM_PER_INCH * 10
    return borderWidthInInch * printDpi
  } else {
    throw Error(`borderUnit ${borderUnit} is invalid.`)
  }
}
