// @flow
import React, { PureComponent } from 'react'
import CsvOrder from './CsvOrder'
import type { Quote, RsaaStatus, FormattedCsvRow, Dictionary, CsvItemProductDetails, CsvOrderUploadProgress, Status, FormattedOrderItem } from '../../../../types'
import { LOADING, IDLE, ERROR } from '../../../../data/rsaa'
import { values, entries } from '../../../../helpers/dictionary'
import { isOrderCompleted, createOrderErrorMessage } from '../../../../helpers/csvUpload'
import { DEFAULT_CSV_ERROR_MESSAGE } from '../../../../data/errorMessages'
import { NOT_FOUND, BAD_REQUEST } from '../../../../data/statusCodes'
import { V4_API_ACTION_OUTCOME } from '../../../../data/v4ApiActionOutcome'
import { removeDuplicates } from '../../../../helpers/array'

type Props = {|
  order: FormattedCsvRow,
  orderItems: Dictionary<FormattedOrderItem>,
  uploadProgress: ?CsvOrderUploadProgress,
  artworkStatus: Dictionary<Status>,
  productDetails: Dictionary<CsvItemProductDetails>,
  productDetailsProgress: Dictionary<Status>,
  v4ProductDetailRsaaStatuses: Dictionary<RsaaStatus>,
  quotes: Quote[],
  quoteRsaaStatus: RsaaStatus,
  isIgnored: boolean,
  currencyCode: string,
  isMultiselectModeOn: boolean,
  addIgnoredOrder: (index: string) => void,
  removeIgnoredOrder: (index: string) => void
|}

type State = {|
  orderItemIdForEdit: ?string,
  productSearchErrorMessage: ?string,
  isImageModalOpen: boolean,
  idOfOrderItemWithOpenImageModal: ?string
|}

export default class CsvOrderController extends PureComponent<Props, State> {
  state: State = {
    orderItemIdForEdit: null,
    productSearchErrorMessage: null,
    isImageModalOpen: false,
    idOfOrderItemWithOpenImageModal: null
  }

  openProductSearchModal: ((itemId: string) => void) = (itemId: string) => this.setState(state => ({ orderItemIdForEdit: itemId }))

  closeProductSearchModal: (() => void) = () => this.setState(state => ({ orderItemIdForEdit: null }))

  openImageModal: ((idOfOrderItemWithOpenImageModal: string) => void) = (idOfOrderItemWithOpenImageModal: string) => this.setState({ idOfOrderItemWithOpenImageModal })

  closeImageModal: (() => void) = () => this.setState({ idOfOrderItemWithOpenImageModal: null })

  onProductSearchError: ((errorMessage: string) => void) = (errorMessage: string) => this.setState({ productSearchErrorMessage: errorMessage })

  updateOrderIgnore: (() => void) = () => {
    if (this.props.isIgnored) {
      this.props.removeIgnoredOrder(this.props.order.id)
    } else {
      this.props.addIgnoredOrder(this.props.order.id)
    }
  }

  get everyOrderItemHasSku (): boolean {
    return values(this.props.orderItems).every(item => item.sku)
  }

  get isFetchingProductDetails (): boolean {
    return values(this.props.productDetailsProgress).some(itemProgress => itemProgress === LOADING || itemProgress === IDLE)
  }

  get isFetchingShipping (): boolean {
    return this.props.quoteRsaaStatus.status === LOADING
  }

  get isCompleted (): boolean {
    return isOrderCompleted(
      this.props.order,
      this.props.orderItems,
      this.props.productDetails,
      this.props.artworkStatus,
      this.props.quotes
    )
  }

  get csvOrderErrorMessage (): ?string {
    return this.canDisplayErrorMessage
      ? createOrderErrorMessage(
        this.props.order,
        this.props.orderItems,
        this.props.productDetails,
        this.props.artworkStatus,
        this.props.quotes,
        this.props.quoteRsaaStatus
      )
      : null
  }

  get hasQuotesErrorThatCantBeFixed (): boolean {
    return Boolean(
      this.props.quoteRsaaStatus.status === ERROR &&
      this.props.quotes.length === 0 &&
      this.props.quoteRsaaStatus.statusCode !== BAD_REQUEST &&
      this.props.quoteRsaaStatus.statusMessage !== V4_API_ACTION_OUTCOME.NOT_AVAILABLE
    )
  }

  get canDisplayErrorMessage (): boolean {
    return Boolean(
      !this.isFetchingProductDetails &&
      !this.isFetchingShipping &&
      this.hasInitializedPrintAreasForAllOrderItems
    )
  }

  get hasInitializedPrintAreasForAllOrderItems (): boolean {
    return values(this.props.orderItems).every(item => !this.props.productDetails[item.sku] || (item.printAreaImageUrls && Object.keys(item.printAreaImageUrls).length > 0))
  }

  get errorMessage (): ?string {
    if (this.isFetchingProductDetails || this.isFetchingShipping) {
      return null
    } else if (this.state.productSearchErrorMessage) {
      return this.state.productSearchErrorMessage
    } else if (this.props.uploadProgress && this.props.uploadProgress.errorMessage) {
      return this.props.uploadProgress.errorMessage
    } else if (this.hasV4ProductDetailErrorThatCantBeFixed || this.hasQuotesErrorThatCantBeFixed) {
      return DEFAULT_CSV_ERROR_MESSAGE
    } else if (this.getUnavailableProducts.length > 0) {
      return this.unavailableProductsErrorMessage
    } else if (this.hasOutOfStockProducts) {
      return this.outOfStockErrorMessage
    } else if (!this.isCompleted) {
      return this.csvOrderErrorMessage
    } else {
      return null
    }
  }

  get shippingDetailsKey (): string {
    const { id, customer } = this.props.order
    return id + customer.countryCode + values(this.props.orderItems).map(item => item.sku).join()
  }

  get canBeEdited (): boolean {
    return !this.props.isIgnored && !this.props.isMultiselectModeOn
  }

  get hasV4ProductDetailErrorThatCantBeFixed (): boolean {
    return entries(this.props.v4ProductDetailRsaaStatuses).some(
      ([orderItemId, rsaa]) => Boolean(
        rsaa.status === ERROR &&
        rsaa.statusCode !== NOT_FOUND
      )
    )
  }

  get getUnavailableProducts (): string[] {
    const notFoundProducts = entries(this.props.v4ProductDetailRsaaStatuses).reduce(
      (unavailableSkuAcc, [orderItemId, rsaa]) => {
        const sku = this.props.orderItems[orderItemId].sku
        if (rsaa.status === ERROR && rsaa.statusCode === NOT_FOUND && sku) {
          return unavailableSkuAcc.concat(sku)
        }
        return unavailableSkuAcc
      }, []
    )
    return removeDuplicates(notFoundProducts)
  }

  get hasOutOfStockProducts (): boolean {
    return this.props.quoteRsaaStatus.statusMessage === V4_API_ACTION_OUTCOME.NOT_AVAILABLE
  }

  get outOfStockErrorMessage (): string {
    return 'Sorry, there are no shipment options available to the selected destination for these products.'
  }

  get unavailableProductsErrorMessage (): string {
    return `Please select and review the required product SKU and attributes. ` +
    `Following products are not currently available: ${this.getUnavailableProducts.join(', ')}.`
  }

  render (): React$Node {
    return (
      <CsvOrder
        canBeEdited={this.canBeEdited}
        isFetchingShipping={this.isFetchingShipping}
        isMultiselectModeOn={this.props.isMultiselectModeOn}
        order={this.props.order}
        orderItems={this.props.orderItems}
        onProductSearchError={this.onProductSearchError}
        orderItemIdForEdit={this.state.orderItemIdForEdit}
        productDetails={this.props.productDetails}
        errorMessage={this.errorMessage}
        openProductSearchModal={this.openProductSearchModal}
        closeProductSearchModal={this.closeProductSearchModal}
        isFetchingProductDetails={this.isFetchingProductDetails}
        everyOrderItemHasSku={this.everyOrderItemHasSku}
        isCompleted={this.isCompleted}
        isIgnored={this.props.isIgnored}
        artworkStatus={this.props.artworkStatus}
        updateOrderIgnore={this.updateOrderIgnore}
        currencyCode={this.props.currencyCode}
        shippingDetailsKey={this.shippingDetailsKey}
        idOfOrderItemWithOpenImageModal={this.state.idOfOrderItemWithOpenImageModal}
        closeImageModal={this.closeImageModal}
        openImageModal={this.openImageModal}
      />
    )
  }
}
