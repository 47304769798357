import Skeleton from '../../Skeleton'

export function VariantDataLoading({ showToggleSkeleton = true }: { showToggleSkeleton?: boolean }) {
  return (
    <div>
      {showToggleSkeleton && (
        <div className="flex items-center">
          <Skeleton className="mr-4 h-5 w-[36px] rounded-full" />
          <Skeleton className="w-64" />
        </div>
      )}

      <div
        className={`ml-4 flex flex-col items-center gap-4 md:flex-row md:items-start md:pl-[36px] ${
          showToggleSkeleton ? 'mt-6' : ''
        }`}
      >
        <Skeleton className="h-60 w-60" />

        <div className="flex flex-col items-center gap-2 md:items-start">
          <Skeleton className="w-80 max-w-full md:w-96" />
          <Skeleton className="w-64" />
          <Skeleton className="w-48" />
        </div>
      </div>
    </div>
  )
}
