import clsx from 'clsx'
import { NoSymbolIcon, CheckCircleIcon } from '@heroicons/react/20/solid'

import { VariantDataInterface } from '../SalesChannelProduct.component'
import { VariantFulfilmentToggle } from './VariantFulfilmentToggle.component'

interface VariantDataHeaderPropsInterface {
  variantData: VariantDataInterface
}

export function VariantDataHeader({ variantData }: VariantDataHeaderPropsInterface) {
  return (
    <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
      <VariantFulfilmentToggle className="flex-shrink-0 md:mr-2" variantData={variantData} />
      <div
        className={clsx('min-w-[60px] max-w-full flex-1 overflow-auto text-center md:text-left', {
          'text-black': variantData.isFulfilled,
          'text-gray-700': !variantData.isFulfilled
        })}
      >
        {variantData.title}
      </div>
      <FulfilmentIndicator variantData={variantData} />
    </div>
  )
}

function FulfilmentIndicator({ variantData }: { variantData: VariantDataInterface }) {
  if (variantData.isAutoFulfilled) {
    return (
      <div className="flex items-center gap-2 whitespace-nowrap rounded-full bg-[#D1FADD80] px-4 py-2 text-sm font-medium text-green-700">
        <CheckCircleIcon className="h-8 w-8 flex-shrink-0 text-green-700" />
        Fulfilled by Prodigi automatically
      </div>
    )
  }

  if (variantData.isFulfilled) {
    return (
      <div className="flex items-center gap-2 whitespace-nowrap rounded-full bg-cyan-100 px-4 py-2 text-sm font-medium text-cyan-700">
        <CheckCircleIcon className="h-8 w-8 flex-shrink-0 text-cyan-700" />
        Fulfilled by Prodigi
      </div>
    )
  }

  return (
    <div className="flex items-center gap-2 whitespace-nowrap rounded-full bg-gray-100 px-4 py-2 text-sm font-medium text-gray-600">
      <NoSymbolIcon className="h-8 w-8 flex-shrink-0 text-gray-600" />
      Not fulfilled by Prodigi
    </div>
  )
}
