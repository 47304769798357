// @flow
import * as React from 'react'
import type { Element } from 'react'
type Props = {|
  className: string
|}

export default function ExclamationCircle ({ className }: Props): Element<*> {
  return (
    <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14" stroke="#DA1E28" />
      <path d="M13.6 8.36H16L15.808 16.936H13.808L13.6 8.36ZM14.8 21.096C14.4053 21.096 14.08 20.9733 13.824 20.728C13.568 20.472 13.44 20.152 13.44 19.768C13.44 19.3947 13.568 19.0853 13.824 18.84C14.08 18.5947 14.4053 18.472 14.8 18.472C15.1947 18.472 15.52 18.5947 15.776 18.84C16.0427 19.0747 16.176 19.384 16.176 19.768C16.176 20.152 16.048 20.472 15.792 20.728C15.536 20.9733 15.2053 21.096 14.8 21.096Z"/>
    </svg>
  )
}
