// @flow

export function buildImageId (basketItemId: string, printAreaName: ?string): string {
  if (!printAreaName) {
    return basketItemId
  }
  return basketItemId?.includes(printAreaName)
    ? basketItemId
    : `${basketItemId}/${printAreaName}`
}
