// @flow
import React from 'react'
import { NavLink, Route } from 'react-router-dom'

import {
  APP_SETTING_URLS,
  APP_TYPES,
  PAYMENT_SERVICE_URL,
  PRINT_ENGINE_PARAMS,
  PRINT_ENGINE_URL,
  STRIPE_CONNECT_ONBOARDING_STATES,
  STRIPE_REDIRECT,
  STRIPE_REFRESH
} from '../Apps/constants'
import type {
  AbortController,
  Action,
  AppSettingsType,
  AppSettingsResponseType,
  Match,
  RsaaStatusProps,
  StripeConnectAccountDetailsType,
  StripeConnectOnboardingState,
  User
} from '../../types'
import type { RouterHistory } from 'react-router'
import styles from './ConfigureApp.module.css'
import AppSettings from './components/AppSettings'
import AppProducts from './components/AppProducts'
import { ABORT_ERROR_NAME } from '../../data/constants'
import StripeBanner from '../../components/StripeBanner'
import LoadingIndicator from '../../components/LoadingIndicator'
import processFetchResponse from '../../helpers/processFetchResponse'
import { getStripeConnectSignupLink } from '../../helpers/stripeConnect'
import getDefaultFetchOptions from '../../helpers/getDefaultFetchOptions'
const {
  APP_TYPE,
  BRAND_COLOUR,
  BRAND_LOGO,
  BRAND_NAME,
  DOMAINS_ADDED,
  FAVICON,
  IS_VALIDATED,
  HOST_NAME,
  MERCHANT_ID
} = PRINT_ENGINE_PARAMS

type Props = {|
  match: Match,
  user: User,
  history: RouterHistory,
  kiteLogout: () => Action,
  kiteLogoutRsaaStatus: RsaaStatusProps
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  appSettings: AppSettingsType,
  disableSaveConfig: boolean,
  getAppsError: boolean,
  isLoadingSettings: boolean,
  stripeOnboardingState: StripeConnectOnboardingState
|}

class ConfigureApp extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const controller = new window.AbortController()
    const signal = controller.signal

    this.state = {
      abortController: controller,
      abortSignal: signal,
      appSettings: {
        brandName: '',
        brandColour: {
          value: '',
          isHexValid: false
        },
        brandLogo: {
          fileName: '',
          fileSize: '',
          url: ''
        },
        domains: [],
        favIcon: {
          fileName: '',
          fileSize: '',
          url: ''
        }
      },
      disableSaveConfig: true,
      getAppsError: false,
      isLoadingSettings: true,
      stripeOnboardingState: STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED
    }
  }

  componentDidMount () {
    if (this.props.match.isExact) {
      this.props.history.replace(`${this.props.match.url}/${APP_SETTING_URLS.DEFAULT}`)
    }
    this.props.kiteLogout()
    this.getAppSettings()
  }

  prepareFetch: ((url: string, method?: string) => Promise<Response>) = (url: string, method: string = 'GET') => {
    return fetch(url, {
      ...getDefaultFetchOptions(),
      method,
      signal: this.state.abortSignal
    })
  }

  getAppSettings () {
    const { abortSignal } = this.state

    Promise.all([
      this.getAppSettingsFetch(),
      this.getStripeDetailsFetch()
    ])
      .then(async ([appSettingsData, stripeDetailsResponse]: [AppSettingsResponseType, Response]) => {
        await this.processStripeOnboardingDetails(stripeDetailsResponse)
        if (this.redirectToStripeOnboarding()) {
          const stripeConnectSignupLinkData = await getStripeConnectSignupLink({
            abortSignal
          })
          window.location.href = stripeConnectSignupLinkData.url
        } else {
          this.processAppSettings(appSettingsData)
        }
      })
      .catch((err) => {
        if (err.name !== ABORT_ERROR_NAME) {
          console.error(err)
          this.setState({
            getAppsError: true,
            isLoadingSettings: false
          })
        }
      })
  }

  getAppSettingsFetch: (() => Promise<any>) = () => {
    const appTypeSelected = APP_TYPES[this.props.match.params.appType]
    const { user } = this.props
    const { appId } = this.props.match.params
    const printEngineAppType = appTypeSelected.printEngineType

    const getAppSettingsUrl = `${PRINT_ENGINE_URL}/get-app-settings/?${APP_TYPE}=${printEngineAppType}&${BRAND_NAME}=${appId}&${MERCHANT_ID}=${user.merchantUniqueId}`
    return this.prepareFetch(getAppSettingsUrl).then(processFetchResponse)
  }

  getStripeDetailsFetch: (() => Promise<Response>) = () => {
    const urlForStripeDetailsInPaymentService = `${PAYMENT_SERVICE_URL}/payments/stripe/account`
    const urlForStripeDetailsRetrievedFromStripe = `${PAYMENT_SERVICE_URL}/payments/stripe/account/retrieve`

    const stripeRedirectQueryParam = new URLSearchParams(window.location.search).get(STRIPE_REDIRECT)
    return stripeRedirectQueryParam
      ? this.prepareFetch(urlForStripeDetailsRetrievedFromStripe, 'POST')
      : this.prepareFetch(urlForStripeDetailsInPaymentService)
  }

  processStripeOnboardingDetails: ((stripeDetailsResponse: Response) => Promise<void>) = async (stripeDetailsResponse: Response) => {
    if (stripeDetailsResponse.ok) {
      await this.setStripeAccountState(stripeDetailsResponse)
    } else if (stripeDetailsResponse.status === 404) {
      this.setState({ stripeOnboardingState: STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED })
    } else {
      throw new Error(stripeDetailsResponse.statusText)
    }
  }

  setStripeAccountState: ((stripeDetailsResponse: Response) => Promise<void>) = async (stripeDetailsResponse: Response) => {
    const stripeDetails: StripeConnectAccountDetailsType = await stripeDetailsResponse.json()
    const stripeRequirements = stripeDetails.requirements

    const hasPendingRequirements = (stripeRequirements.currently_due.length > 0 || stripeRequirements.eventually_due.length > 0)
    const hasPendingVerification = stripeRequirements.pending_verification.length > 0
    const hasPayoutsDisabled = !stripeDetails.payouts_enabled

    if (hasPendingRequirements) {
      this.setState({ stripeOnboardingState: STRIPE_CONNECT_ONBOARDING_STATES.NOT_FINISHED })
    } else if (hasPendingVerification) {
      this.setState({ stripeOnboardingState: STRIPE_CONNECT_ONBOARDING_STATES.PENDING_VERIFICATION })
    } else if (hasPayoutsDisabled) {
      this.setState({ stripeOnboardingState: STRIPE_CONNECT_ONBOARDING_STATES.NOT_FINISHED })
    } else {
      this.setState({ stripeOnboardingState: STRIPE_CONNECT_ONBOARDING_STATES.COMPLETE })
    }
  }

  redirectToStripeOnboarding: (() => null | string | boolean) = () => {
    const stripeRefreshQueryParam = new URLSearchParams(window.location.search).get(STRIPE_REFRESH)
    return (this.state.stripeOnboardingState === STRIPE_CONNECT_ONBOARDING_STATES.NOT_FINISHED && stripeRefreshQueryParam)
  }

  processAppSettings: ((appSettings: AppSettingsResponseType) => void) = (appSettings: AppSettingsResponseType) => {
    const { appId } = this.props.match.params
    const brandColorValueWithoutHash = appSettings[BRAND_COLOUR].slice(1)
    this.setState({
      isLoadingSettings: false,
      appSettings: {
        brandName: appId,
        brandColour: {
          value: brandColorValueWithoutHash,
          isHexValid: true
        },
        brandLogo: {
          fileName: '',
          fileSize: '',
          url: appSettings[BRAND_LOGO]
        },
        domains: appSettings[DOMAINS_ADDED].map((domain) => ({ value: domain[HOST_NAME], connected: domain[IS_VALIDATED] })),
        favIcon: {
          fileName: '',
          fileSize: '',
          url: appSettings[FAVICON]
        }
      }
    })
  }

  handleUpdateAppConfig (updatedConfig: $Shape<AppSettingsType>) {
    this.setState(state => {
      const appSettings = {
        ...this.state.appSettings,
        ...updatedConfig
      }

      return {
        appSettings,
        disableSaveConfig: (!appSettings.brandColour.isHexValid || !appSettings.brandLogo.url)
      }
    })
  }

  handleDisableSaveConfig () {
    this.setState({ disableSaveConfig: true })
  }

  handleAddDomain: ((domainName: string) => void) = (domainName: string) => {
    const domainToAdd = { value: domainName, connected: false }
    this.setState(state => {
      const domains = state.appSettings.domains.concat(domainToAdd)
      return {
        appSettings: {
          ...state.appSettings,
          domains
        }
      }
    })
  }

  handleUpdateDomains: ((domainsStatus: { [key: string]: {| is_validated: boolean |} }) => void) = (domainsStatus: {| [key: string]: {| is_validated: boolean |} |}) => {
    this.setState(state => {
      return {
        appSettings: {
          ...state.appSettings,
          domains: (state.appSettings.domains).map(domain => {
            const connected = domainsStatus[domain.value].is_validated
            return {
              ...domain,
              connected
            }
          })
        }
      }
    })
  }

  render (): React$Node {
    const { user } = this.props
    const {
      appSettings,
      disableSaveConfig,
      isLoadingSettings,
      getAppsError,
      stripeOnboardingState
    } = this.state

    const appTypeSelected = APP_TYPES[this.props.match.params.appType]
    const navItems = [
      { title: 'Products & Pricing', path: `${this.props.match.url}/products` },
      { title: 'Settings', path: `${this.props.match.url}/${APP_SETTING_URLS.DEFAULT}` }
    ]

    if (isLoadingSettings) {
      return (<div className={styles.loadingContainer}><LoadingIndicator /></div>)
    }

    const showStripeBanner = Boolean(
      stripeOnboardingState !== STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED &&
      stripeOnboardingState !== STRIPE_CONNECT_ONBOARDING_STATES.COMPLETE
    )

    return (
      <>
        {showStripeBanner && (
          <StripeBanner
            email={user.email}
            countryCode={user.company.countryCode}
            stripeOnboardingState={stripeOnboardingState}
          />)
        }
        <main className={styles.configureAppContainer}>
          <button className={styles.backButton} onClick={() => this.props.history.push('/apps')}>&lt; &nbsp; Back</button>
          <section className={styles.configureAppNav}>
            <h4 className={styles.configureAppTitle}>{appTypeSelected.title}</h4>
            <span className={styles.spacer}></span>
            {navItems.map((navItem) => (
              <NavLink key={navItem.title} className={styles.navItem} activeClassName={styles.navItemActive} to={navItem.path}>
                {navItem.title}
              </NavLink>
            ))}
          </section>
          <Route
            path={`${this.props.match.url}/${APP_SETTING_URLS.DEFAULT}`}
            render={() => (
              <AppSettings
                appSettings={appSettings}
                appTypeSelected={appTypeSelected}
                disableSaveConfig={disableSaveConfig}
                hasError={getAppsError}
                match={this.props.match}
                stripeOnboardingState={stripeOnboardingState}
                user={this.props.user}
                onDisableSaveConfig={() => this.handleDisableSaveConfig()}
                onUpdateAppConfig={(updatedConfig: $Shape<AppSettingsType>) => this.handleUpdateAppConfig(updatedConfig)}
                onUpdateDomains={(domains: {| [key: string]: {| is_validated: boolean |} |}) => this.handleUpdateDomains(domains)}
                onAddDomain={(domain: string) => this.handleAddDomain(domain)}
              />
            )} />
          <Route
            path={`${this.props.match.url}/products`}
            render={() => <AppProducts appTypeSelected={appTypeSelected} hasError={this.props.kiteLogoutRsaaStatus.error} />} />
        </main>
      </>
    )
  }
}

export default ConfigureApp
