// @flow

import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function CrossCircle ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" strokeWidth="2"/>
      <rect x="15.8284" y="8.75732" width="2" height="10" transform="rotate(45 15.8284 8.75732)" />
      <rect x="10.1716" y="8.75732" width="10" height="2" transform="rotate(45 10.1716 8.75732)" />
    </svg>
  )
}
