// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function LogOut ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 20H6V18H2V2H6V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20ZM12.7071 14.2929L9.41421 11H20V9.00001H9.41421L12.7071 5.70712L11.2929 4.29291L5.58579 10L11.2929 15.7071L12.7071 14.2929Z" fill="#565656"/>
    </svg>
  )
}
