// @flow
import { RSAA } from 'redux-api-middleware'
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../../types'
import { selectAzureSearchSettings } from '../../selectors/appSettings'
import { getProductTypes } from './getProductTypes'
import { filterOutUnavailableCategories } from './filterOutUnavailableProducts'

export const FETCH_ITEM_CATEGORIES = 'FETCH_ITEM_CATEGORIES'
export const RECEIVE_ITEM_CATEGORIES = 'RECEIVE_ITEM_CATEGORIES'
export const FAILED_ITEM_CATEGORIES = 'ERROR_ITEM_CATEGORIES'

export function getItemCategories (): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const azureEndpointAndKey = selectAzureSearchSettings()
    const countryCode = getState().manualOrderForm.deliveryCountry

    await dispatch({
      [RSAA]: {
        endpoint: buildUrl(azureEndpointAndKey.endpoint, countryCode),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': azureEndpointAndKey.key
        },
        types: [
          FETCH_ITEM_CATEGORIES,
          {
            type: RECEIVE_ITEM_CATEGORIES,
            payload: async (_, _2, res) => filterOutUnavailableCategories(await res.json())
          },
          FAILED_ITEM_CATEGORIES
        ]
      }
    })

    if (getState().catalogue.searchQuery) {
      await dispatch(getProductTypes())
    }
  }
}

function buildUrl (searchEndpoint: string, countryCode: string): string {
  return `${searchEndpoint}&$filter=destinationCountries/any(c: c eq '${countryCode}')&search=*&$top=0&facet=category,count:100`
}
