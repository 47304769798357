import { formatOrderHistoryData, formatOrderHistoryTitle } from '.'
import {
  FormattedOrderHistoryDateEventsInterface,
  FormattedOrderHistoryEventInterface,
  OrderDetailHistoryInterface
} from '../interfaces'

export function formatOrderHistoryResponse(history: OrderDetailHistoryInterface[]) {
  const groupedByDate = groupEventsByDate(history)

  return sortEventsToReverseChronologicalOrder(groupedByDate)
}

function groupEventsByDate(history: OrderDetailHistoryInterface[]) {
  return history.reduce(
    (groupedEvents: FormattedOrderHistoryDateEventsInterface[], event: OrderDetailHistoryInterface) => {
      const formattedEvent = formatEvent(event)
      const dateExists = groupedEvents.findIndex((group) => group.date === formattedEvent.date)

      if (dateExists > -1) {
        groupedEvents[dateExists].events.push(formattedEvent)
        return groupedEvents
      }

      groupedEvents.push({
        date: formattedEvent.date,
        events: [formattedEvent],
        timestamp: new Date(event.date).getTime()
      })

      return groupedEvents
    },
    []
  )
}

function formatEvent(event: OrderDetailHistoryInterface) {
  const { date, time } = convertDateTimeToLocalStrings(event.date)

  const formattedEvent: FormattedOrderHistoryEventInterface = {
    date: date,
    time: time,
    title: formatOrderHistoryTitle(event.orderHistoryType, event.data),
    timestamp: new Date(event.date).getTime()
  }

  if (event.data) {
    formattedEvent.content = formatOrderHistoryData(event.orderHistoryType, event.data)
  }

  return formattedEvent
}

function convertDateTimeToLocalStrings(date: string) {
  const dateInLocal = new Date(date).toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })

  const timeInLocal = new Date(date).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })

  return {
    time: timeInLocal,
    date: dateInLocal
  }
}

function sortEventsToReverseChronologicalOrder(eventsGroupedByDate: FormattedOrderHistoryDateEventsInterface[]) {
  const eventsGroupedByDateCopy = [...eventsGroupedByDate]

  eventsGroupedByDateCopy.sort((a, b) => b.timestamp - a.timestamp)
  eventsGroupedByDateCopy.forEach((eventsForDate) => eventsForDate.events.sort((a, b) => b.timestamp - a.timestamp))

  return eventsGroupedByDateCopy
}
