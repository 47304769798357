// @flow
import React from 'react'
import cx from 'classnames'
import styles from './Inputs.module.css'
import Svg from '../svg'

// NOTE: When not passing className, ensure parent div has "position: relative" so
// the icon is placed inside and on the right of the text field

type Props = {|
  className?: string,
  showPassword: boolean,
  onShowPasswordChange: (val: boolean) => void
|}

export default function ToggleHidePasswordButton ({
  className,
  showPassword,
  onShowPasswordChange
}: Props): React$Node {
  const passwordToggleRef = React.createRef()

  return (
    <span className={cx(styles.toggleHidePassword, className)}>
      <input
        ref={passwordToggleRef}
        onChange={e => {
          onShowPasswordChange(e.target.checked)
        }}
        type="checkbox"
        name="toggleShowPassword"
        id="toggleShowPasswordCheckbox"
        style={{ display: 'none' }}
      />
      <button
        className={styles.passwordToggleButton}
        type="button"
        onClick={() =>
          passwordToggleRef.current && passwordToggleRef.current.click()
        }
      >
        {showPassword ? (
          <Svg.EyeHide className={styles.passwordToggleIcon} />
        ) : (
          <Svg.EyeShow className={styles.passwordToggleIcon} />
        )}
      </button>
    </span>
  )
}
