// @flow
import type { Element } from 'react'
import React from 'react'
import styles from './LoadingSpinner.module.css'
import FontAwesome from 'react-fontawesome'

export default function LoadingSpinner (): Element<"div"> {
  return (
    <div className={styles.container} data-test='loading-spinner'>
      <FontAwesome name="circle-o-notch fa-spin" className={styles.icon} />
      <p><em>Loading</em></p>
    </div>
  )
}
