import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

import {
  OrderEditAddress,
  OrderEditHeader,
  OrderEditSummary,
  OrderEditPackingSlip,
  OrderEditShippingSelector,
  OrderEditCostSummary
} from './components'
import { useOrderDetail, useSplitToggle } from '../../hooks'
import OrderEditLineItem from '../OrderEditLineItem'
import { OrderDetailOrderHistory } from '../OrderDetail/components'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { OrderEditBrandedInserts } from './components/OrderEditBrandedInserts.component'

export default function OrderEditDisplay() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  const orderHistory = orderDetails.history ?? []

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="container mx-auto space-y-4 pt-8 pb-36 xl:space-y-8"
    >
      <OrderEditHeader />

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:gap-8">
        <div className="row-span-full space-y-4 lg:col-span-2 lg:row-auto xl:space-y-8">
          {orderDetails.items.map((lineItem) => (
            <OrderEditLineItem key={lineItem.id} lineItem={lineItem} />
          ))}
        </div>

        <div className="space-y-4 lg:row-span-3 xl:space-y-8">
          <OrderEditSummary />
          <OrderEditAddress />
          <OrderEditShippingSelector />
          {isBrandingSettingsOn ? <OrderEditBrandedInserts /> : <OrderEditPackingSlip />}
        </div>

        <div className="lg:col-span-2">
          <OrderEditCostSummary />
        </div>

        <div className="lg:col-span-2">
          <OrderDetailOrderHistory history={orderHistory} />
        </div>
      </div>
    </motion.div>
  )
}
