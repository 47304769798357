import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { StarIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../Button'
import { ROUTE_PATHS } from '../../constants'
import SavedItemsModal from '../SavedItemsModal'
import { SelectedProductInterface } from '../SelectProduct'
import { addBasketItem } from '../../../actions/manualOrderForm'
import { selectDeliveryCountry } from '../../../selectors/manualOrderForm'

export default function SavedItemsModalButton() {
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)
  const dispatch = useDispatch()
  const history = useHistory()

  const [isSavedItemsModalOpen, setIsSavedItemsModalOpen] = useState(false)

  function handleAddToBasket(selectedProduct: SelectedProductInterface) {
    window.analytics.track('Saved item: added to basket')
    dispatch(addBasketItem(selectedProduct.sku, selectedProduct.selectedAttributes))
    history.push(ROUTE_PATHS.MOF.BASKET)
  }

  return (
    <>
      <Button
        variant="tertiary"
        theme="greyscale"
        startIcon={<StarIcon className="h-8 w-8 text-[#FFD907]" />}
        onClick={() => {
          window.analytics.track('Saved items: Opened modal')
          setIsSavedItemsModalOpen(true)
        }}
      >
        Saved items
      </Button>

      <SavedItemsModal
        countryCode={selectedDeliveryCountry}
        primaryActionText="Add to basket"
        open={isSavedItemsModalOpen}
        setOpen={setIsSavedItemsModalOpen}
        onSelectProduct={(selectedProduct) => handleAddToBasket(selectedProduct)}
      />
    </>
  )
}
