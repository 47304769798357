import { twMerge } from 'tailwind-merge'
import { ReactNode, useRef, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { PORTAL_ID } from '../../constants/portalId.const'
import InlineMenu, { InlineMenuOptionInterface } from '../InlineMenu'

interface MultiSelectDropdownInterface {
  className?: string
  dropdownName: string
  dropdownIcon?: ReactNode
  options: InlineMenuOptionInterface[]
  selected: string[]
  onClear: () => void
  updateSelected: (selected: string[]) => void
}

export const CLEAR_ALL_OPTION_VALUE = 'PRODIGI_MULTI_SELECT_DROPDOWN_CLEAR_ALL'

const CLEAR_ALL_OPTION: InlineMenuOptionInterface = {
  content: (
    <span className="text-purple-600" data-test="multi-select-clear-all">
      Clear
    </span>
  ),
  value: CLEAR_ALL_OPTION_VALUE,
  searchLabel: 'Clear'
}

export default function MultiSelectDropdown({
  className = '',
  dropdownName,
  dropdownIcon,
  options,
  selected,
  updateSelected,
  onClear
}: MultiSelectDropdownInterface) {
  const parentRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLButtonElement | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isAnimatingContent, setIsAnimatingContent] = useState(false)

  return (
    <div ref={parentRef} className="w-full text-input sm:text-base" data-test="multiselect-dropdown__container">
      <Popover.Root
        onOpenChange={(open) => {
          setIsOpen(open)
        }}
      >
        <Popover.Trigger
          className={
            'relative w-full cursor-pointer truncate rounded-lg border border-gray-200 bg-white py-2 px-3 text-left text-lg outline-offset-1 outline-purple-500 sm:text-base'
          }
          data-test="multiselect-dropdown__button"
          ref={triggerRef}
        >
          <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
            {dropdownIcon ?? <ChevronDownIcon aria-hidden="true" className="h-7 w-7 text-gray-400" />}
          </span>
          <span className="ml-8 mr-10" data-test="multiselect-dropdown__button-text">
            {dropdownName}
          </span>
          <SelectedCountDisplay selectedCount={selected.length} />
        </Popover.Trigger>

        <AnimatePresence>
          {isOpen && (
            <Popover.Portal container={document.getElementById(PORTAL_ID)} forceMount key={dropdownName}>
              <Popover.Content align="start" asChild style={{ perspective: '2000px' }}>
                <motion.div
                  animate="open"
                  className={twMerge(
                    'mt-1 max-h-96 overflow-auto rounded-lg border border-gray-200 bg-white shadow-lg radix-side-bottom:origin-[center_-45%] radix-side-top:origin-[center_45%]',
                    className
                  )}
                  data-test="multiselect-dropdown__options"
                  exit="close"
                  initial="close"
                  onAnimationComplete={() => {
                    setIsAnimatingContent(false)
                  }}
                  style={{
                    minWidth: parentRef?.current?.clientWidth ?? '240px'
                  }}
                  transition={{ duration: 0.15 }}
                  variants={{ close: { opacity: 0, rotateX: '-15deg' }, open: { opacity: 1, rotateX: '0deg' } }}
                >
                  <InlineMenu
                    ariaLabel={dropdownName}
                    // Prevents unwanted clicks on options while animating
                    className={`${isAnimatingContent ? 'pointer-events-none' : ''}`}
                    multiple
                    onChange={(value: string) => {
                      if (value !== CLEAR_ALL_OPTION_VALUE) {
                        return
                      }
                      setIsAnimatingContent(true)
                    }}
                    options={[...options, CLEAR_ALL_OPTION]}
                    selected={selected}
                    updateSelected={(selected: string[]) => {
                      if (selected.includes(CLEAR_ALL_OPTION_VALUE)) {
                        onClear()
                        triggerRef.current?.click()
                      } else {
                        updateSelected(selected)
                      }
                    }}
                  />
                </motion.div>
              </Popover.Content>
            </Popover.Portal>
          )}
        </AnimatePresence>
      </Popover.Root>
    </div>
  )
}

function SelectedCountDisplay({ selectedCount }: { selectedCount: number }) {
  return (
    <AnimatePresence>
      {selectedCount > 0 && (
        <div className="absolute inset-y-0 right-0 mr-2 flex items-center pl-2">
          <motion.span
            animate={{ scale: 1 }}
            className={twMerge(
              'm-auto flex aspect-square h-[18px] w-[18px] items-center justify-center rounded-full bg-gray-700',
              selectedCount > 99 && 'w-[20px]'
            )}
            exit={{ scale: 0 }}
            initial={{ scale: 0.5 }}
          >
            <motion.span
              animate={{ opacity: 1 }}
              className={twMerge('text-[10px] text-white', selectedCount > 99 && 'text-[9px]')}
              data-test="multiselect-dropdown__button_numberSelected"
              initial={{ opacity: 0 }}
              key={selectedCount}
            >
              {selectedCount > 99 ? '99+' : selectedCount}
            </motion.span>
          </motion.span>
        </div>
      )}
    </AnimatePresence>
  )
}
