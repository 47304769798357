export enum RegisterReducerActionTypeEnum {
  SetEmail = 'SET_EMAIL',
  SetEmailAvailability = 'SET_EMAIL_AVAILABILITY',
  SetPassword = 'SET_PASSWORD',
  SetFullName = 'SET_FULL_NAME',
  SetMarketingWhyCreatingAccount = 'SET_MARKETING_WHY_CREATING_ACCOUNT',
  SetMarketingPriorityToday = 'SET_MARKETING_PRIORITY_TODAY',
  SetCompanyName = 'SET_COMPANY_NAME',
  SetCountryName = 'SET_COUNTRY_NAME',
  SetBillingCurrency = 'SET_BILLING_CURRENCY',
  SetAgreedTerms = 'SET_AGREED_TERMS'
}
