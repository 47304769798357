import { AnimatePresence, motion } from 'framer-motion'

export function OrderFilterActiveCount({ activeFilterCount }: { activeFilterCount: number }) {
  return (
    <AnimatePresence>
      {activeFilterCount > 0 && (
        <div className="absolute inset-y-0 right-0 mr-2 flex items-center pl-2">
          <motion.span
            animate={{ scale: 1 }}
            className="relative flex h-7 w-7 items-center justify-center rounded-full bg-gray-700"
            exit={{ scale: 0 }}
            initial={{ scale: 0.5 }}
          >
            <motion.span
              animate={{ opacity: 1 }}
              className="relative text-xs text-white"
              data-test="order-filter__active-filter-count"
              key={activeFilterCount}
              initial={{ opacity: 0 }}
            >
              {activeFilterCount}
            </motion.span>
          </motion.span>
        </div>
      )}
    </AnimatePresence>
  )
}
