import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useSplitToggle } from '../../../hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import ProductCatalogue from '../../../components/ProductCatalogue'
import { ANALYTICS_PAGE_CATEGORIES, DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../../constants'

export default function ProductCataloguePage() {
  useEffect(() => {
    document.title = `Products | Prodigi`
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.PRODUCT_CATALOGUE)
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  })

  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  if (isAPCSplitOn) {
    return <Redirect to={ROUTE_PATHS.PRODUCT_CATALOGUE} />
  }

  return (
    <main className="tailwind">
      <ProductCatalogue />
    </main>
  )
}
