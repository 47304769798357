// @flow

export function mapV4AttributeNameToV3 (v4AttributeName: string): string {
  if (v4AttributeName === 'mountColor') {
    return 'mountColour'
  } else if (v4AttributeName === 'color') {
    return 'frameColour'
  } else {
    return v4AttributeName
  }
}
