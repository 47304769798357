// @flow
import type { Dictionary, FileType } from '../types'

export const FILE_TYPE: Dictionary<FileType> = {
  PNG: '.png',
  CSV: '.csv'
}

export const POSSIBLE_INCORRECT_FILE_TYPE_MESSAGE = 'Possible wrong image format. Error in ImageMagick library. '
export const INCORRECT_FILE_MESSAGE = 'Incorrect file type. Please upload a .png, .jpg or .jpeg file that is less than 300MB in size'
