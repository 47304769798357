// @flow
import { IDLE } from '../../data/rsaa'
import type { AddRsaaAction } from '../types/rsaa'

export const ADD_RSAA_ACTION = 'ADD_RSAA_ACTION'

export function addRsaaAction (rsaaActionTypes: string[], loggerId: ?string): AddRsaaAction {
  return {
    type: ADD_RSAA_ACTION,
    loggerId,
    types: rsaaActionTypes,
    initialStatus: {
      status: IDLE,
      statusMessage: '',
      ignore: false,
      statusCode: null,
      errorPayload: null
    }
  }
}
