import useSWR, { SWRConfiguration } from 'swr'

import { formatSkuForMockup } from './useMockups.hook'
import { fetcher, getDefaultFetchOptions } from '../helpers'
import { DictionaryInterface, FetchErrorInterface } from '../interfaces'

export interface ImageMockupInterface {
  url: string
}

export interface MockupDetailsInterface {
  view_name: string
  variants: DictionaryInterface<string>
}

export function useMockupGeneration(
  {
    productSku,
    transformationId,
    mockup
  }: {
    productSku: string
    transformationId: string
    mockup: MockupDetailsInterface
  },
  { config = {} }: { config?: SWRConfiguration } = {}
) {
  const { data, error } = useSWR<ImageMockupInterface, FetchErrorInterface<{ detail?: string }>>(
    [productSku, mockup, transformationId],
    mockupGenerationFetcher,
    config
  )

  return {
    mockupsData: data,
    isLoadingMockups: !data && !error,
    mockupsFetchError: error
  }
}

function mockupGenerationFetcher(productSku: string, mockup: MockupDetailsInterface, transformationId: string) {
  const url = `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/mockups/${formatSkuForMockup(productSku)}/view/${
    mockup.view_name
  }/transform/${transformationId}`
  return fetcher<ImageMockupInterface>(url, {
    headers: {
      Authorization: getDefaultFetchOptions().headers.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(mockup),
    method: 'POST'
  })
}
