// @flow
import type { Node } from 'react'
import React from 'react'

import Svg from '../../components/svg'
import styles from './Apps.module.css'
import type { AvailableAppType } from '../../types'
import { selectPhotobookHostname, selectPrintShopHostname } from '../../selectors/appSettings'

export const STRIPE_REDIRECT = 'stripeRedirect'
export const STRIPE_REFRESH = 'stripeRefresh'
export const PRINT_SHOP_TITLE = 'Print shop'
export const PHOTOBOOK_MAKER_TITLE = 'Photo book maker'
export const APP_SETTING_URLS = {
  DEFAULT: 'settings',
  DOMAINS: 'settings/domains',
  ACCOUNT: 'settings/account'
}

export const APPS_AVAILABLE: AvailableAppType[] = [
  // Commented out print-shop and photobook-maker as part of https://prodigiuk.atlassian.net/browse/HUB-1029
  // {
  //   description: 'An end-to-end photo book retail experience',
  //   gradient: 'linear-gradient(349.24deg, #4630D4 13.54%, rgba(70, 48, 212, 0) 173.19%)',
  //   icon: <Svg.PhotobookMakerIcon className={styles.photobookMakerIcon} />,
  //   imageUrl: '/img/apps/photobook-maker.png',
  //   imageHeightPx: 203,
  //   imagePlacement: {
  //     left: '52.13%',
  //     right: '-5.53%',
  //     top: '17.33%',
  //     bottom: '15%'
  //   },
  //   imageWidthPx: 251,
  //   linkText: 'Connect',
  //   title: PHOTOBOOK_MAKER_TITLE,
  //   url: '/apps/connect/photobook-maker'
  // },
  // {
  //   description: 'A fully functioning print on demand website',
  //   gradient: 'linear-gradient(180deg, #F3EBE8 0%, #E9DCDA 100%)',
  //   icon: <Svg.PrintShopIcon className={styles.printShopIcon} />,
  //   imageUrl: '/img/apps/print-shop.png',
  //   imageHeightPx: 283.5,
  //   imagePlacement: {
  //     left: '43.83%',
  //     right: '-4.36%',
  //     top: '2.67%',
  //     bottom: '2.83%'
  //   },
  //   imageWidthPx: 284.5,
  //   linkText: 'Connect',
  //   title: PRINT_SHOP_TITLE,
  //   url: '/apps/connect/print-shop'
  // },
  // {
  //   description: 'Sell custom products without needing a developer',
  //   gradient: 'linear-gradient(168.82deg, #1191E6 2.16%, #003D73 119.85%)',
  //   icon: <Svg.BigCommerceIcon className={styles.bigCommerceIcon} />,
  //   imageUrl: '/img/apps/big-commerce.png',
  //   imageHeightPx: 298,
  //   imagePlacement: {
  //     left: '48%',
  //     right: '20.85%',
  //     top: '9.33%',
  //     bottom: '24%'
  //   },
  //   imageWidthPx: 356,
  //   isExternal: true,
  //   linkText: 'Connect',
  //   title: 'Big Commerce',
  //   url: 'https://www.bigcommerce.co.uk/apps/kite/'
  // },
  {
    description: 'Turn your designs into print on demand products',
    gradient: 'linear-gradient(174.06deg, #00BAB6 1.99%, #037A78 95.29%)',
    icon: <Svg.ShopifyIcon className={styles.shopifyIcon} />,
    imageUrl: '/img/apps/shopify.png',
    imageHeightPx: 234,
    imagePlacement: {
      left: '48.51%',
      right: '-22.77%',
      top: '11%',
      bottom: '11%'
    },
    imageWidthPx: 349,
    isExternal: true,
    linkText: 'Connect',
    title: 'Shopify',
    url: 'https://apps.shopify.com/prodigi-print-on-demand'
  },
  {
    description: 'A powerful API for integrating print on demand',
    gradient: 'linear-gradient(352.45deg, #470073 1.88%, #00549E 96.96%)',
    icon: <Svg.ApiIcon className={styles.apiIcon} />,
    imageUrl: '/img/apps/print-api.png',
    imageHeightPx: 183,
    imagePlacement: {
      left: '51.91%',
      right: '-5.96%',
      top: '21.33%',
      bottom: '17.67%'
    },
    imageWidthPx: 254,
    isExternal: true,
    linkText: 'Connect',
    title: 'Print API',
    url: 'https://www.prodigi.com/print-api/docs/'
  },
  {
    description: 'A bulk order import tool for high transaction volumes',
    icon: <Svg.CsvUploaderIcon className={styles.csvUploaderIcon} />,
    gradient: 'linear-gradient(177.09deg, #EBF3E8 -0.5%, #C1DBAC 97.58%)',
    imageUrl: '/img/apps/csv-uploader.png',
    imageHeightPx: 216,
    imagePlacement: {
      left: '46.81%',
      right: '-7.45%',
      top: '18.33%',
      bottom: '9.67%'
    },
    imageWidthPx: 285,
    linkText: 'Connect',
    title: 'CSV Uploader',
    url: '/orders/bulk-upload'
  }
]

export const PRINT_ENGINE_MAPPING = {
  shop: {
    configureUrl: '/apps/configure/print-shop',
    icon: (<Svg.PrintShopIcon className={styles.printShopIcon} />: Node),
    title: 'Print shop'
  },
  photobook: {
    configureUrl: '/apps/configure/photobook-maker',
    icon: (<Svg.PhotobookMakerIcon className={styles.photobookMakerIcon} />: Node),
    title: 'Photo book maker'
  }
}

export const PRINT_ENGINE_URL: string = process.env.REACT_APP_PRINT_ENGINE || ''

export const PAYMENT_SERVICE_URL: string = process.env.REACT_APP_PAYMENT_SERVICE_URL || ''

export const PRINT_ENGINE_PARAMS = {
  APP_TYPE: 'app_type',
  BRAND_COLOUR: 'brand_colour',
  BRAND_LOGO: 'brand_logo',
  BRAND_NAME: 'brand_name',
  DOMAINS: 'domains',
  DOMAINS_ADDED: 'domains_added',
  FAVICON: 'favicon',
  HOST_NAME: 'host_name',
  IS_ACTIVE: 'is_active',
  IS_VALIDATED: 'is_validated',
  MERCHANT_ID: 'merchant_id'
}

export const APP_TYPES = {
  'print-shop': {
    connectTitle: 'Connect print shop',
    title: 'Print Shop',
    hostName: (selectPrintShopHostname(): string),
    printEngineType: 'shop'
  },
  'photobook-maker': {
    connectTitle: 'Connect photobook maker',
    title: 'Photo book maker',
    hostName: (selectPhotobookHostname(): string),
    printEngineType: 'photobook'
  }
}

export const STRIPE_CONNECT_ONBOARDING_STATES = {
  NOT_STARTED: 'NOT_STARTED',
  NOT_FINISHED: 'NOT_FINISHED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  COMPLETE: 'COMPLETE'
}

export const STRIPE_CONNECT_NOT_STARTED_MESSAGE =
  'We use Stripe to make sure you get paid on time and keep your personal and bank details secure. Connect account to use one of our apps.'
export const STRIPE_CONNECT_NOT_STARTED_CTA = 'Create Stripe Account'

export const STRIPE_CONNECT_NOT_FINISHED_MESSAGE = 'Stripe signup incomplete. Please provide more information.'
export const STRIPE_CONNECT_NOT_FINISHED_CTA = 'Complete signup'

export const STRIPE_CONNECT_PENDING_VERIFICATION_MESSAGE = 'Stripe is verifying your account details.'

export const STRIPE_CONNECT_COMPLETE_MESSAGE =
  'We use Stripe to make sure you get paid on time and keep your personal and bank details secure.'
export const STRIPE_CONNECT_COMPLETE_CTA = 'Edit Stripe account'

export const DEFAULT_STRIPE_CONNECT_ERROR_MESSAGE = 'Something went wrong. Please try again later or contact support'
