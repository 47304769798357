import { useEffect } from 'react'

import { ANALYTICS_PAGE_CATEGORIES, DEFAULT_DOCUMENT_TITLE } from '../../../constants'
import { SettingsAccount } from '../../../components/Settings/components/SettingsAccount'

export default function SettingsAccountPage() {
  useEffect(() => {
    document.title = 'Account Settings | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.SETTINGS, 'Account')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <SettingsAccount />
    </main>
  )
}
