// @flow

export const APP_VERSION = {
  WHICH_INTRODUCED: {
    SCALE_BASED_ON_PRINT_AREA: 9,
    IMAGE_EDITOR_LIBRARY: 10,
    NEW_IMAGE_EDITOR_UNITS: 11,
    MULTI_ASSET_SUPPORT: 12
  }
}
