// @flow
import type { Element } from 'react'
import React from 'react'
import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import { Link } from 'react-router-dom'
import styles from './CsvSubmission.module.css'

type Props = {|
  submittedOrderCount: number,
  totalIgnoredOrders: number,
  resetUpload: () => void
|}

export default function CsvSubmissionSuccess ({submittedOrderCount, resetUpload}: Props): Element<"div"> {
  return (
    <div>
      <Alert type='success' className={styles.submissionStatusHeading}>The CSV order submission was processed successfully.</Alert>
      <p>
        {submittedOrderCount} new order(s) are now being fulfilled and can be&nbsp;
        <Link to={'/orders'}>viewed in your account.</Link>
      </p>
      <Button dataTest='upload-new-file-button' className={styles.reset} destructive onClick={resetUpload}>
        Upload new file
      </Button>
    </div>
  )
}
