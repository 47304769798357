// @flow
import { PRODUCT_CATEGORIES_WITH_BORDERS, PRODUCT_TYPES_WITH_BORDERS } from '../../data/productCategories'
import type { Dictionary } from '../../types'

export function displayBorder (category: string, productType: string, hasMount: boolean): boolean {
  return Boolean(
    (PRODUCT_CATEGORIES_WITH_BORDERS.includes(category) && !hasMount) ||
    PRODUCT_TYPES_WITH_BORDERS.includes(productType)
  )
}

export function isProductMounted (attr: Dictionary<*>): boolean {
  const hasMount = attr.mount && attr.mount !== 'no mount/mat'
  const hasMountInStyle = attr.style && !attr.style.toString().includes('non-mounted')
  return Boolean(hasMount || hasMountInStyle)
}
