import { groupBy } from 'lodash-es'
import React, { useState } from 'react'

import {
  ColourProfileInterface,
  ImageLibraryFileType,
  ImageSpecDimensionsInterface,
  ProductCatalogueProductInterface,
  TemplateInterface
} from '../../../hooks'
import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import ImageLibraryFileTypePill from '../../ImageLibraryFileTypePill'
import GroupedDropdown from '../../GroupedDropdown'
import {
  GroupedDropdownGroupInterface,
  GroupedDropdownOptionInterface
} from '../../GroupedDropdown/GroupedDropdown.component'
import { FooterContainer, FooterBlock } from '.'

export function FileRequirements({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <FooterContainer>
      {productData.imageSpec && (
        <FooterBlock title="File requirements">
          <p className="mb-4">
            <span className="pr-2 font-medium">Supported file formats:</span>
            {productData.imageSpec.fileTypes.map((fileType) => {
              if (['png', 'jpg', 'pdf (multi-page)', 'pdf (single page)'].includes(fileType)) {
                return <ImageLibraryFileTypePill key={fileType} fileType={fileType as ImageLibraryFileType} />
              }
            })}
          </p>
          <p className="mb-4">
            <span className="font-medium">Optimal DPI:</span> {productData.imageSpec.dpi}dpi
          </p>
          {productData.imageSpec.dimensions.length > 0 && <Dimensions dimensions={productData.imageSpec.dimensions} />}
          {productData.imageSpec.notes && <p className="mt-2">{productData.imageSpec.notes}</p>}
        </FooterBlock>
      )}

      {productData.templates && productData.templates.length > 0 && (
        <FooterBlock title="Print file templates">
          <Templates productSingularName={productData.singularName} templates={productData.templates} />
        </FooterBlock>
      )}

      {productData.colourProfiles && productData.colourProfiles.length > 0 && (
        <FooterBlock title="ICC colour profiles">
          <ColourProfiles profiles={productData.colourProfiles} productSingularName={productData.singularName} />
        </FooterBlock>
      )}
    </FooterContainer>
  )
}

function Dimensions({ dimensions }: { dimensions: ImageSpecDimensionsInterface[] }) {
  return (
    <div className="mb-4">
      <span className="mt-2 font-medium">File dimensions:</span>
      <ul className="inline">
        {dimensions.map((dim) => (
          <li className="mt-2 ml-8" key={dim.variants.join('-')}>
            {dim.variants.join(', ')}:{' '}
            {dim.printAreas.map((a) => (
              <span key={a.area}>
                <span>{a.dimensions.join('x')}px</span>
                {a.area !== 'default' && <span>{a.area}</span>}
              </span>
            ))}
          </li>
        ))}
      </ul>
    </div>
  )
}

function Templates({
  productSingularName,
  templates
}: {
  productSingularName: string
  templates: TemplateInterface[]
}) {
  const blurb =
    templates.length > 1
      ? `The following print templates are available for free download, to help ensure your artwork is reproduced on your ${productSingularName} as expected.`
      : `Download our free ${productSingularName} print file template and ensure your design is printed precisely as expected`

  const groupedTemplates = groupBy(templates, ({ brand }) => brand) ?? []

  const groups = Object.entries(groupedTemplates).reduce(
    (groupsAcc: GroupedDropdownGroupInterface[], [brand, templateList]) => {
      const options =
        templateList?.map<GroupedDropdownOptionInterface>((template) => {
          return {
            value: template.url,
            name: template.name
          }
        }) ?? []

      groupsAcc.push({ label: brand, options })

      return groupsAcc
    },
    []
  )

  const [selectedTemplate, setSelectedTemplate] = useState(templates[0].url)

  const templatesCanBeGrouped = templates.every((x) => x.brand !== '')
  return (
    <div>
      <p>{blurb}</p>
      {templatesCanBeGrouped ? (
        <GroupedDropdown
          classname="w-fit"
          groups={groups}
          label=""
          selected={selectedTemplate}
          setSelected={setSelectedTemplate}
        />
      ) : (
        <FormItem
          className="w-fit"
          key="profiles"
          inputField={
            <SelectField
              className="w-full"
              value={selectedTemplate}
              onChange={(event) => setSelectedTemplate(event.target.value)}
            >
              {templates.map((template) => (
                <option key={template.name} value={template.url}>
                  {template.name}
                </option>
              ))}
            </SelectField>
          }
        />
      )}
      <a
        className="btn btn-secondary mt-4 w-fit cursor-pointer"
        href={selectedTemplate}
        rel="noreferrer noopener"
        target="_blank"
      >
        Download{templates.length === 1 && ' template'}
      </a>
    </div>
  )
}

function ColourProfiles({
  productSingularName,
  profiles
}: {
  productSingularName: string
  profiles: ColourProfileInterface[]
}) {
  const [selectedProfile, setSelectedProfile] = useState(profiles[0].urlSlug)

  return (
    <div>
      <p>
        Download a dedicated ICC custom colour profile for precise colour matching when preparing your{' '}
        {productSingularName} artwork file.
      </p>
      {profiles.length > 1 && (
        <FormItem
          className="w-fit"
          key="profiles"
          inputField={
            <SelectField
              className="w-full"
              value={selectedProfile}
              onChange={(event) => setSelectedProfile(event.target.value)}
            >
              {profiles.map((profile) => (
                <option key={profile.id} value={profile.urlSlug}>
                  {profile.name}
                </option>
              ))}
            </SelectField>
          }
        />
      )}
      <a
        className="btn btn-secondary mt-4 w-fit cursor-pointer"
        href={selectedProfile}
        rel="noreferrer noopener"
        target="_blank"
      >
        Download{profiles.length === 1 && ' ICC profile'}
      </a>
    </div>
  )
}
