import { useState } from 'react'

import Button from '../Button'
import { formatCost } from '../../helpers'
import OverlayPortal from '../OverlayPortal'
import Modal, { ModalPropsInterface } from '../Modal'
import { BrandedInsertItem } from '../BrandedInsertItem'
import { InsertsPanel } from './components/InsertsPanel.component'
import { InsertDataType, InsertSetType, StatusType } from '../../interfaces'
import { InsertSetSettingsType } from './components/InsertsSettingsPanel.component'
import { BrandedInsertIdType, BrandingDataInterface, BrandingDetailsType } from '../../hooks'

interface BrandedInsertsSetModalPropsIntereface
  extends BrandedInsertsSetPropsIntereface,
    Pick<ModalPropsInterface, 'open' | 'closeOnEscape' | 'closeOnInteractionOutside' | 'title' | 'setOpen'> {}

export function BrandedInsertsSetModal({
  open,
  closeOnEscape,
  closeOnInteractionOutside,
  saveStatus,
  title = 'Add insert set',
  setOpen,
  ...props
}: BrandedInsertsSetModalPropsIntereface) {
  return (
    <Modal
      className="w-90vw max-w-[1000px]"
      closeOnEscape={saveStatus === 'loading' ? false : closeOnEscape}
      closeOnInteractionOutside={saveStatus === 'loading' ? false : closeOnInteractionOutside}
      title={title}
      open={open}
      setOpen={setOpen}
    >
      <div className="h-60vh overflow-y-auto">
        <BrandedInsertsSet {...props} saveStatus={saveStatus} />
      </div>
    </Modal>
  )
}

interface BrandedInsertsSetPropsIntereface {
  brandingDetails: BrandingDataInterface
  insertSet?: InsertSetType
  saveStatus?: StatusType
  onSave: (savedInsertSetData: Omit<InsertSetType, 'id'>) => void
  onCancel?: () => void
}

function BrandedInsertsSet({
  brandingDetails,
  insertSet,
  saveStatus = 'idle',
  onCancel,
  onSave
}: BrandedInsertsSetPropsIntereface) {
  const [allInsertsData, setAllInsertsData] = useState(insertSet?.inserts ?? [])
  // TODO: Revisit default state
  const [insertSetSettings, setInsertSetSettings] = useState<InsertSetSettingsType>({
    name: insertSet?.name ?? 'My insert set',
    salesChannels: insertSet?.salesChannels ?? []
  })

  function handleChangeInsertData({
    newInsertId,
    newInsertData
  }: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) {
    const doesInsertExist = allInsertsData.some((insertData) => insertData.id === newInsertId)

    let newAllInsertsData = allInsertsData
    if (doesInsertExist) {
      newAllInsertsData = allInsertsData.map((insertData) => {
        if (insertData.id !== newInsertId) {
          return insertData
        }

        return { ...insertData, id: newInsertId, ...newInsertData }
      })
    } else {
      newAllInsertsData = [...allInsertsData, { id: newInsertId, ...newInsertData }]
    }

    setAllInsertsData(newAllInsertsData)
  }

  const brandingDetailsWithImage: BrandingDetailsType[] = []
  const brandingDetailsWithoutImage: BrandingDetailsType[] = []

  brandingDetails?.branding.forEach((brandingDetail) => {
    const insertDataForBrandingDetail = allInsertsData.find((insertData) => insertData.id === brandingDetail.id)

    if (insertDataForBrandingDetail?.imageLibraryId || insertDataForBrandingDetail?.imageUrl) {
      brandingDetailsWithImage.push(brandingDetail)
    } else {
      brandingDetailsWithoutImage.push(brandingDetail)
    }
  })

  const numberOfInsertsWithImage = brandingDetailsWithImage.length

  const totalCost = {
    amount: brandingDetailsWithImage.reduce((amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount, 0),
    currency: brandingDetailsWithImage[0]?.cost.currency
  }

  return (
    <div className="flex h-full flex-col text-black">
      <div className="flex-1">
        {numberOfInsertsWithImage > 0 && (
          <div className="mb-6 flex items-center justify-end">
            <div className="rounded-full bg-purple-100 px-6 py-2">
              {numberOfInsertsWithImage} {numberOfInsertsWithImage === 1 ? 'insert' : 'inserts'}, total cost:{' '}
              {formatCost({ amount: totalCost.amount.toString(), currencyCode: totalCost.currency })}
            </div>
          </div>
        )}

        {brandingDetailsWithImage.length > 0 && (
          <ul className="mb-6">
            {brandingDetailsWithImage.map((brandingDetail) => {
              const insertDataForItem = allInsertsData.find((insertData) => insertData.id === brandingDetail.id)

              return (
                <BrandedInsertItem
                  key={brandingDetail.id}
                  brandingDetail={brandingDetail}
                  insertData={insertDataForItem}
                  onChangeInsertData={(newInsertData) =>
                    handleChangeInsertData({ newInsertId: brandingDetail.id, newInsertData })
                  }
                />
              )
            })}
          </ul>
        )}

        <InsertsPanel
          brandingDetailsWithoutImage={brandingDetailsWithoutImage}
          insertSetSettings={insertSetSettings}
          onChangeInsertSetSettings={setInsertSetSettings}
          onChangeInsertData={handleChangeInsertData}
        />
      </div>

      <div className="sticky bottom-0 flex flex-wrap-reverse items-center gap-2 bg-white pt-10">
        <div className="flex gap-2">
          <Button
            isLoading={saveStatus === 'loading'}
            variant="primary"
            onClick={() =>
              onSave({
                inserts: allInsertsData.filter((insert) => Boolean(insert.imageLibraryId)),
                name: insertSetSettings.name,
                salesChannels: insertSetSettings.salesChannels
              })
            }
          >
            Save
          </Button>

          {onCancel && (
            <Button variant="tertiary" theme="greyscale" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
      </div>

      {saveStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}
