// @flow
import type { FacetValue } from '../../types'

export function getRangeQuery (facetName: string, selectedFacetRanges: FacetValue[]): string {
  let resultQuery = ''

  if (selectedFacetRanges) {
    if (selectedFacetRanges.length > 1) {
      const allConditions: string[] = []
      for (let range of selectedFacetRanges) {
        if (range.from !== undefined && range.to !== undefined) {
          const query = `(${facetName} ge ${range.from} and ${facetName} lt ${range.to})`
          allConditions.push(query)
        }
      }
      resultQuery = ` and (${allConditions.join(' or ')})`
    } else if (selectedFacetRanges.length === 1) {
      const range = selectedFacetRanges[0]
      if (range.from !== undefined && range.to !== undefined) {
        resultQuery = ` and (${facetName} ge ${range.from} and ${facetName} lt ${range.to})`
      }
    }
  }

  return resultQuery
}
