import { useState } from 'react'
import toast from 'react-hot-toast'

import Button from '../../../../Button'
import { BRANDING_SETTINGS_TOAST_ID } from '../constants'
import ConfirmationModal from '../../../../ConfirmationModal'
import { createErrorToast, createToast } from '../../../../Toast'
import { updateMerchantBranding } from '../helpers/updateMerchantBranding.helper'
import { FetchErrorInterface, InsertSetType, StatusType } from '../../../../../interfaces'
import { MerchantBrandingType, useMerchantBranding, useMerchantService } from '../../../../../hooks'

export function DeleteInsertSet({
  merchantBranding,
  selectedInsertSet
}: {
  merchantBranding: MerchantBrandingType
  selectedInsertSet: InsertSetType
}) {
  const { merchantDetails } = useMerchantService()
  const { mutateMerchantBranding } = useMerchantBranding()

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isDeleteSetModalOpen, setIsDeleteSetModalOpen] = useState(false)

  async function handleDeleteInsertSet() {
    toast.dismiss(BRANDING_SETTINGS_TOAST_ID)
    setSaveStatus('loading')

    try {
      const newInsertSets = merchantBranding.insertSets.filter((insertSet) => insertSet.id !== selectedInsertSet.id)

      await updateMerchantBranding({
        merchantId: merchantDetails?.id,
        newMerchantBranding: {
          ...merchantBranding,
          insertSets: newInsertSets
        }
      })
      await mutateMerchantBranding()
      setSaveStatus('success')
      handleOpenChange(false)
      createToast({ type: 'success', heading: 'Insert set deleted' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ traceParent?: string }>
      createErrorToast({
        errorCode: `${errorResponse.responseBodyJson?.traceParent ?? errorResponse.message ?? '0'}-${
          errorResponse.status ?? '0'
        }`,
        heading: 'Failed to delete insert set',
        id: BRANDING_SETTINGS_TOAST_ID
      })
      setSaveStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setSaveStatus('idle')
      toast.dismiss(BRANDING_SETTINGS_TOAST_ID)
    }
    setIsDeleteSetModalOpen(isOpen)
  }

  return (
    <>
      <Button variant="secondary" theme="danger" size="sm" onClick={() => handleOpenChange(true)}>
        Delete
      </Button>

      <ConfirmationModal
        className="w-90vw max-w-[500px]"
        open={isDeleteSetModalOpen}
        title="Delete insert set"
        continueButton={{ theme: 'danger', text: 'Delete' }}
        closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'Cancel' }}
        isLoading={saveStatus === 'loading'}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleDeleteInsertSet}
      >
        <div>Are you sure you want to delete {selectedInsertSet.name}?</div>
      </ConfirmationModal>
    </>
  )
}
