import { useEffect } from 'react'

import Download from '../../components/Download'
import { DEFAULT_DOCUMENT_TITLE } from '../../constants'

export default function DownloadPage() {
  useEffect(() => {
    document.title = 'File Download | Prodigi'
    window.analytics.page('Download')
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <Download />
    </main>
  )
}
