// @flow
import React from 'react'

import styles from '../AddDomain.module.css'
import InputUnderlined from '../../../../../components/InputUnderlined'

type Props = {|
  value: string,
  errorText: string,
  handleDomainNameChange: (event: SyntheticInputEvent<HTMLInputElement>) => void
|}

class AddDomainName extends React.Component<Props> {
  render (): React$Node {
    return (
      <div className={styles.domainNameStep}>
        <div className={styles.domainNameInputContainer}>
          <InputUnderlined
            autoFocus={true}
            error={this.props.errorText}
            inputLabel="DOMAIN NAME"
            placeholder="www.yourdomain.com"
            value={this.props.value}
            onChange={this.props.handleDomainNameChange} />
        </div>
      </div>
    )
  }
}

export default AddDomainName
