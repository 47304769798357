import { OrderStatusTheme, OrderStatusEnum } from '../../../enums'

export function getOrderStatusThemeColor(orderStatus: OrderStatusEnum): OrderStatusTheme {
  switch (orderStatus) {
    case OrderStatusEnum.COMPLETE:
      return OrderStatusTheme.GREEN
    case OrderStatusEnum.ON_HOLD:
    case OrderStatusEnum.IN_PROGRESS:
      return OrderStatusTheme.ORANGE
    case OrderStatusEnum.REQUIRES_ATTENTION:
    case OrderStatusEnum.DRAFT:
      return OrderStatusTheme.BLUE
    default:
      return OrderStatusTheme.DEFAULT
  }
}
