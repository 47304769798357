import { useState } from 'react'

import {
  BrandingDataInterface,
  BrandingDetailsType,
  MerchantBrandingType,
  useMerchantService
} from '../../../../../hooks'
import Button from '../../../../Button'
import { formatCost } from '../../../../../helpers'
import { EditInsertSet } from './EditInsertSet.component'
import { AddNewInsertSet } from './AddNewInsertSet.component'
import { DeleteInsertSet } from './DeleteInsertSet.component'
import { InsertDataType, InsertSetType } from '../../../../../interfaces'
import { BrandedInsertsOrderModal } from '../../../../BrandedInsertsOrderModal'

const DUMMY_BRANDED_INSERTS: InsertDataType[] = [
  {
    id: 'flyer',
    imageLibraryId: '602f415b-9c21-4602-a08a-339c2c4b3faf'
  },
  {
    id: 'postcard',
    imageUrl:
      'https://dev-services.pwinty.com/image-library/transform/6578060e5f5f40a5ceaaba2f/image?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXRoIjoiL3RyYW5zZm9ybS82NTc4MDYwZTVmNWY0MGE1Y2VhYWJhMmYvaW1hZ2UiLCJleHAiOjE3MzIzNDc4NTB9.48iPFYO6zja2O2EQGbMVt-Vy6aFmWL3DR_pP3h2vREM&preview=false'
  }
]

const DUMMY_INSERT_SETS: InsertSetType[] = [
  {
    name: 'Set A',
    id: '1',
    inserts: [
      {
        id: 'flyer',
        imageLibraryId: '602f415b-9c21-4602-a08a-339c2c4b3faf'
      },
      {
        id: 'postcard',
        imageLibraryId: '602f415b-9c21-4602-a08a-339c2c4b3faf'
      }
    ],
    salesChannels: [{ id: '1685558f-4c3c-4f67-4b06-08db92726b67' }]
  },
  {
    name: 'Set B',
    id: '2',
    inserts: [
      {
        id: 'flyer',
        imageLibraryId: '602f415b-9c21-4602-a08a-339c2c4b3faf'
      }
    ],
    salesChannels: []
  },
  {
    name: 'Set C',
    id: '3',
    inserts: [],
    salesChannels: []
  }
]

export function BrandedInsertsSetting({
  brandingDetails,
  merchantBranding
}: {
  brandingDetails: BrandingDataInterface
  merchantBranding: MerchantBrandingType
}) {
  return (
    <div>
      {merchantBranding.insertSets.length > 0 && (
        <ul className="mb-16 flex flex-col gap-16">
          {merchantBranding.insertSets.map((insertSet) => (
            <InsertSet
              key={insertSet.id}
              brandingDetails={brandingDetails}
              merchantBranding={merchantBranding}
              insertSet={insertSet}
            />
          ))}
        </ul>
      )}

      <AddNewInsertSet brandingDetails={brandingDetails} merchantBranding={merchantBranding} />

      <hr className="my-16" />

      <div className="">
        <TestOrderUI brandingDetails={brandingDetails} />
      </div>
      <div className="mt-16">
        <TestOrderSetsUI brandingDetails={brandingDetails} />
      </div>
    </div>
  )
}

function InsertSet({
  brandingDetails,
  insertSet,
  merchantBranding
}: {
  brandingDetails: BrandingDataInterface
  insertSet: InsertSetType
  merchantBranding: MerchantBrandingType
}) {
  const { merchantDetails } = useMerchantService()

  const brandingDetailsForInsertSet: BrandingDetailsType[] = []

  insertSet.inserts.forEach((insert) => {
    const brandingDetailForInsert = brandingDetails.branding.find((brandingDetail) => brandingDetail.id === insert.id)
    if (!brandingDetailForInsert) {
      return
    }
    brandingDetailsForInsertSet.push(brandingDetailForInsert)
  })

  const insertSetCost = {
    amount: brandingDetailsForInsertSet.reduce(
      (amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount,
      0
    ),
    currency: brandingDetailsForInsertSet[0]?.cost?.currency
  }

  const formattedInsertSetCost = formatCost({
    amount: insertSetCost.amount.toString(),
    currencyCode: insertSetCost.currency
  })

  const salesChannelNamesForInsertSet =
    merchantDetails && insertSet.salesChannels.length > 0
      ? insertSet.salesChannels
          .map((insertSetSalesChannel) => {
            const salesChannelData = merchantDetails.salesChannels.find(
              (salesChannel) => salesChannel.id === insertSetSalesChannel.id
            )

            if (!salesChannelData) {
              return undefined
            }

            return salesChannelData.description
          })
          .filter(Boolean)
      : []

  return (
    <li className="flex flex-col text-black">
      <div className="text-lg font-medium">
        {insertSet.name} {formattedInsertSetCost && <span className="font-normal">({formattedInsertSetCost})</span>}
      </div>

      <div className="mt-2">
        {brandingDetailsForInsertSet.length > 0 ? (
          <span>{brandingDetailsForInsertSet.map((brandingDetail) => brandingDetail.name).join(', ')}</span>
        ) : (
          <span>No inserts in this set</span>
        )}
      </div>
      {salesChannelNamesForInsertSet.length > 0 && (
        <div className="text-sm text-gray-600">Default for: {salesChannelNamesForInsertSet.join(', ')}</div>
      )}

      <div className="mt-4 flex gap-3">
        <EditInsertSet
          brandingDetails={brandingDetails}
          merchantBranding={merchantBranding}
          selectedInsertSet={insertSet}
        />

        <DeleteInsertSet merchantBranding={merchantBranding} selectedInsertSet={insertSet} />
      </div>
    </li>
  )
}

function TestOrderUI({ brandingDetails }: { brandingDetails: BrandingDataInterface }) {
  const [isOrderInsertsModalOpen, setIsOrderInsertsModalOpen] = useState(false)
  const [isOrderInsertsNoImageModalOpen, setIsOrderInsertsNoImageModalOpen] = useState(false)

  return (
    <div className="flex w-fit flex-col gap-4">
      <Button variant="secondary" onClick={() => setIsOrderInsertsModalOpen(true)}>
        Has images, no sets
      </Button>
      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={DUMMY_BRANDED_INSERTS}
        insertSets={undefined}
        open={isOrderInsertsModalOpen}
        setOpen={setIsOrderInsertsModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsModalOpen(false)}
      />

      <Button variant="secondary" onClick={() => setIsOrderInsertsNoImageModalOpen(true)}>
        No images, no sets
      </Button>
      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={undefined}
        insertSets={undefined}
        open={isOrderInsertsNoImageModalOpen}
        setOpen={setIsOrderInsertsNoImageModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsNoImageModalOpen(false)}
      />
    </div>
  )
}

function TestOrderSetsUI({ brandingDetails }: { brandingDetails: BrandingDataInterface }) {
  const [isOrderInsertsModalOpen, setIsOrderInsertsModalOpen] = useState(false)
  const [isOrderInsertsNoImageModalOpen, setIsOrderInsertsNoImageModalOpen] = useState(false)

  return (
    <div className="flex w-fit flex-col gap-4">
      <Button variant="secondary" onClick={() => setIsOrderInsertsModalOpen(true)}>
        Has images, has sets
      </Button>
      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={DUMMY_BRANDED_INSERTS}
        insertSets={DUMMY_INSERT_SETS}
        open={isOrderInsertsModalOpen}
        setOpen={setIsOrderInsertsModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsModalOpen(false)}
      />

      <Button variant="secondary" onClick={() => setIsOrderInsertsNoImageModalOpen(true)}>
        No images, has sets
      </Button>
      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        insertSets={DUMMY_INSERT_SETS}
        open={isOrderInsertsNoImageModalOpen}
        setOpen={setIsOrderInsertsNoImageModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsNoImageModalOpen(false)}
      />
    </div>
  )
}
