import { useParams } from 'react-router'

import Skeleton from '../Skeleton'
import { useImageLibraryImage } from '../../hooks'
import { ImageContainer, ImageDetails, ImageFooter } from './components'

export default function ImageDetail() {
  const { id: imageId } = useParams<{ id: string }>()
  const { image: imageLibraryImage } = useImageLibraryImage({ imageId })

  return (
    <div className="container mx-auto space-y-4 py-6 lg:py-8">
      {imageLibraryImage?.name ? (
        <h1 className="mt-0 mr-auto max-w-full overflow-x-auto pb-8" data-test="image-library-page-header">
          {imageLibraryImage.name}
        </h1>
      ) : (
        <Skeleton className="mt-0 mr-auto h-[48px] w-1/2 pb-8" />
      )}

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-5 xl:gap-12">
        <div className="row-span-full space-y-4 lg:col-span-3 lg:row-auto xl:space-y-8">
          <ImageContainer imageLibraryImage={imageLibraryImage} />
        </div>

        <div className="space-y-4 lg:col-span-2 lg:row-span-3 xl:space-y-8">
          <ImageDetails />
        </div>
      </div>

      <div className="pb-16">
        <hr className="my-16" />
        <ImageFooter />
      </div>
    </div>
  )
}
