import { PhotoIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

import { SalesChannelInterface } from '../../../interfaces'
import { SalesChannelProductSuccessResponseInterface } from '../../../hooks/useSalesChannelProduct.hook'

interface ProductPreviewPropsInterface {
  salesChannel: SalesChannelInterface
  salesChannelProduct: SalesChannelProductSuccessResponseInterface
}

export function ProductPreview({ salesChannel, salesChannelProduct }: ProductPreviewPropsInterface) {
  const imageUrl = salesChannelProduct.data.productData.imageUrls?.[0]

  return (
    <div className="mb-6 w-full max-w-full border border-gray-200 bg-gray-100 antialiased lg:w-[300px]">
      <div className="flex gap-6 bg-white p-5 lg:flex-col">
        <figure className="flex-shrink-0">
          {imageUrl ? (
            <img
              alt={salesChannelProduct.data.productData.name}
              className="block w-[100px] object-cover object-center lg:h-[300px] lg:w-[300px]"
              src={imageUrl}
            />
          ) : (
            <div className="grid h-[100px] w-[100px] place-content-center bg-gray-100 lg:h-[300px] lg:w-full">
              <PhotoIcon className="h-10 w-10 text-gray-400" />
            </div>
          )}
        </figure>
        <div>
          <div className="break-words font-medium line-clamp-2">{salesChannelProduct.data.productData.name}</div>

          <div className="break-words pt-1 text-sm line-clamp-2">{salesChannel.description}</div>

          <div className="break-words pt-1 text-sm text-gray-600">
            {salesChannelProduct.data.productData.platformId}
          </div>

          <div className="pt-2">
            <a
              className="flex w-fit items-center text-sm text-purple-500 hover:underline"
              href={salesChannelProduct.data.productData.platformUrl}
              rel="noreferrer"
              target="_blank"
              title={`View on ${salesChannel.platform}`}
            >
              View on {salesChannel.platform} <ArrowTopRightOnSquareIcon className="ml-2 h-7 w-7" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
