import { useState } from 'react'

import { SettingsHeader } from '../SettingsHeader.component'
import { SettingsContainer } from '../SettingsContainer.component'
import { CustomsInformationPanel, ProfilePanel, ResetPasswordPanel } from './components'

type SettingsAccountType = 'company' | 'password' | 'profile' | 'customs'

export function SettingsAccount() {
  const [settingsKeys, setSettingsKeys] = useState<Record<SettingsAccountType, number>>({
    company: Math.random(),
    password: Math.random(),
    profile: Math.random(),
    customs: Math.random()
  })

  function handleSettingsRefresh(type: SettingsAccountType) {
    setSettingsKeys({ ...settingsKeys, [type]: Math.random() })
  }

  return (
    <SettingsContainer>
      <SettingsHeader />
      <ProfilePanel
        className="border-x border-t"
        key={settingsKeys.profile}
        onSuccess={() => handleSettingsRefresh('profile')}
      />
      <ResetPasswordPanel
        className="border"
        key={settingsKeys.password}
        onSuccess={() => handleSettingsRefresh('password')}
      />
      <CustomsInformationPanel
        className="border-x border-b"
        key={settingsKeys.customs}
        onSuccess={() => handleSettingsRefresh('customs')}
      />
    </SettingsContainer>
  )
}
