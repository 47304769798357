import { useEffect } from 'react'
import { FetchErrorInterface, OrdersDataErrorInterface } from '../../../interfaces'
import { createToast } from '../../Toast'

const ERROR_SUFFIX = 'Please try refreshing the page or contact support.'
export function OrdersListPaginationFailedToLoad({
  error,
  toastMessage = null
}: {
  error?: FetchErrorInterface<OrdersDataErrorInterface>
  toastMessage: string | null
}) {
  useEffect(() => {
    const message = toastMessage || 'An unknown error occured'
    createToast({ heading: message, type: 'error' })
  }, [toastMessage])

  return (
    <>
      <div className="flex flex-col items-center justify-center p-6 pt-4 text-center text-red-500">
        <span className="mb-2">Failed to load the next page of orders</span>
        <span>
          {error?.responseBodyJson?.traceParent ? (
            <span data-test="orders-list-pagination-error-with-code">
              An error occurred with code {error.responseBodyJson.traceParent} and status {error.status}.
            </span>
          ) : (
            <span data-test="orders-list-pagination-unknown-error">
              An unknown error occurred{error?.status && ` with status ${error.status}`}.
            </span>
          )}
          &nbsp;
          {ERROR_SUFFIX}
        </span>
      </div>
    </>
  )
}
