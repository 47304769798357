import { useEffect } from 'react'
import { useParams } from 'react-router'

import { useMerchantService } from '../../../hooks'
import { DEFAULT_DOCUMENT_TITLE } from 'src/v2/constants'
import SalesChannelProduct from '../../../components/SalesChannelProduct'

export default function SalesChannelProductPage() {
  const { salesChannelId } = useParams<{ salesChannelId: string }>()
  const { merchantDetails } = useMerchantService()

  const salesChannel = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)

  useEffect(() => {
    document.title = salesChannel?.description
      ? `Configure ${salesChannel.description} | Prodigi`
      : 'Configure Sales Channel | Prodigi'

    window.analytics.page('Configure Sales Channel')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [salesChannel?.description])

  return (
    <main className="tailwind">
      <div className="container mx-auto pb-8">
        <SalesChannelProduct />
      </div>
    </main>
  )
}
