// @flow
import {
  SQUARESPACE_CSV_HEADERS,
  WIX_CSV_HEADERS,
  PRODIGI,
  ETSY,
  WOOCOMMERCE,
  SQUARESPACE,
  WIX,
  UNKNOWN_FORMAT,
  SHOPIFY,
  PRODIGI_CSV_HEADER_FORMATS,
  ETSY_CSV_HEADER_FORMATS,
  WOOCOMMERCE_CSV_HEADER_FORMATS,
  SHOPIFY_CSV_HEADER_FORMATS
} from '../../data/csvUpload'
import type { CsvFileType } from '../../types'

export function getCsvUploadFileTypeFromHeaders (csvHeaders: string[]): CsvFileType {
  if (PRODIGI_CSV_HEADER_FORMATS.some(headerVersion => checkHeadersMatch(csvHeaders, headerVersion))) {
    return PRODIGI
  } else if (ETSY_CSV_HEADER_FORMATS.some(headerVersion => checkHeadersMatch(csvHeaders, headerVersion))) {
    return ETSY
  } else if (WOOCOMMERCE_CSV_HEADER_FORMATS.some(headerVersion => checkHeadersMatch(csvHeaders, headerVersion))) {
    return WOOCOMMERCE
  } else if (checkHeadersMatch(csvHeaders, SQUARESPACE_CSV_HEADERS)) {
    return SQUARESPACE
  } else if (checkHeadersMatch(csvHeaders, WIX_CSV_HEADERS)) {
    return WIX
  } else if (SHOPIFY_CSV_HEADER_FORMATS.some(headerVersion => checkHeadersMatch(csvHeaders, headerVersion))) {
    return SHOPIFY
  }

  return UNKNOWN_FORMAT
}

function checkHeadersMatch (csvUploadHeaders: string[], formatHeaders: string[]): boolean {
  return csvUploadHeaders.every(header => formatHeaders.includes(header)) &&
    formatHeaders.every(header => csvUploadHeaders.includes(header))
}
