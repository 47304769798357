import toast from 'react-hot-toast'
import { ReactNode, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, NoSymbolIcon } from '@heroicons/react/24/outline'

import Skeleton from '../Skeleton'
import { formatCost } from '../../helpers'
import { createErrorToast } from '../Toast'
import { QUERY_PARAMS } from '../../constants'
import { InsertDataType } from '../../interfaces'
import ImageLibraryModal from '../ImageLibraryModal'
import SingleSelectDropdown from '../SingleSelectDropdown'
import { PACKING_SLIP_ALLOWED_FILE_TYPES } from '../PackingSlip'
import { BrandingDetailsType, ImageLibraryImageInterface, useImageLibraryThumbnailGeneration } from '../../hooks'

type InsertOptionValueType = 'change-image' | 'remove-image'

export const BRANDED_INSERT_ERROR_TOAST_ID = 'prodigiBrandedInsertImageErrorToast'

const INSERT_OPTIONS: Array<{ content: ReactNode; searchLabel: string; value: InsertOptionValueType }> = [
  {
    content: (
      <span className="flex items-center gap-2 pr-10 text-purple-600">
        <PencilSquareIcon className="text-inherit h-6 w-6 " />
        <span>Change image</span>
      </span>
    ),
    searchLabel: 'Change image',
    value: 'change-image'
  },
  {
    content: (
      <span className="flex items-center gap-2 pr-10 text-magenta-700">
        <NoSymbolIcon className="text-inherit h-6 w-6" />
        Remove
      </span>
    ),
    searchLabel: 'Remove',
    value: 'remove-image'
  }
]

export function BrandedInsertItem({
  brandingDetail,
  insertData,
  onChangeInsertData
}: {
  brandingDetail: BrandingDetailsType
  insertData?: InsertDataType
  onChangeInsertData: (newInsertData: Omit<InsertDataType, 'id'>) => void
}) {
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function handleSelectImage(imageLibraryImage: ImageLibraryImageInterface) {
    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)

    if (!PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      handleOpenImageLibraryChange(false)
      createErrorToast({
        errorMessage: `${
          imageLibraryImage.file_type
        } is not supported for inserts. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        heading: 'Failed to update insert',
        id: BRANDED_INSERT_ERROR_TOAST_ID
      })
      return
    }

    onChangeInsertData({
      ...insertData,
      imageLibraryId: imageLibraryImage.public_id,
      imageUrl: imageLibraryImage.url
    })
    handleOpenImageLibraryChange(false)
  }

  function handleDeleteImage() {
    onChangeInsertData({ ...insertData, imageLibraryId: undefined, imageUrl: undefined })
  }

  function handleOpenImageLibraryChange(open: boolean) {
    const newSearchParams = new URLSearchParams(searchParams)

    if (open) {
      newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
      newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))
    } else {
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    }

    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)
    history.replace({ search: newSearchParams.toString() })
    setIsImageLibraryModalOpen(open)
  }

  function handleSelectInsertOption(value: InsertOptionValueType) {
    if (value === 'change-image') {
      handleOpenImageLibraryChange(true)
    } else if (value === 'remove-image') {
      handleDeleteImage()
    }
  }

  return (
    <li className="flex gap-6 p-8 lg:gap-8 lg:p-10">
      <div className="flex flex-shrink-0 items-start justify-start">
        <InsertImage
          key={`${insertData?.imageLibraryId ?? ''}-${insertData?.imageUrl ?? ''}`}
          insertData={insertData}
        />
      </div>

      <div className="flex-1">
        <div className="font-medium">{brandingDetail.name}</div>
        <div className="text-sm" style={{ wordBreak: 'break-word' }}>
          {brandingDetail.description}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="flex pt-2">
          {formatCost({ amount: brandingDetail.cost.amount.toString(), currencyCode: brandingDetail.cost.currency })}
        </div>
      </div>

      <div>
        <SingleSelectDropdown
          align="end"
          className="relative aspect-square border-gray-300"
          dropdownName="Options"
          triggerContent={
            <>
              <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500" />
              <span className="sr-only">Options</span>
            </>
          }
          options={INSERT_OPTIONS}
          updateSelected={(value) => {
            handleSelectInsertOption(value as InsertOptionValueType)
          }}
        />
      </div>

      <ImageLibraryModal
        config={{ forceSaveToLibrary: true }}
        open={isImageLibraryModalOpen}
        type="packing"
        setOpen={handleOpenImageLibraryChange}
        onSelectImage={handleSelectImage}
      />
    </li>
  )
}

function InsertImage({ insertData }: { insertData?: InsertDataType }) {
  if (insertData?.imageLibraryId) {
    return <InsertImageWithId imageLibraryId={insertData.imageLibraryId} />
  }

  return <InsertImageRender src={insertData?.imageUrl} />
}

function InsertImageWithId({ imageLibraryId }: { imageLibraryId: string }) {
  const { imageLibraryImage, imageLibraryImageFetchError, isLoadingImageLibraryImage } =
    useImageLibraryThumbnailGeneration(imageLibraryId, {
      touch: false,
      config: { revalidateOnFocus: false }
    })

  if (imageLibraryImageFetchError) {
    return (
      <div className="h-[75px] w-[75px] overflow-auto text-center text-sm text-red-500">
        Error {imageLibraryImageFetchError?.status ?? '0'} loading image
      </div>
    )
  }

  if (isLoadingImageLibraryImage) {
    return <Skeleton className="h-[75px] w-[75px]"></Skeleton>
  }

  return <InsertImageRender src={imageLibraryImage?.thumbnail_urls.small} />
}

function InsertImageRender({ src }: { src?: string }) {
  return <img className="h-[75px] w-[75px] object-contain" src={src} alt="" />
}
