// @flow
import type { Node } from 'react'
import React from 'react'
import styles from './FileUpload.module.css'
import LoadingIndicator from '../../components/LoadingIndicator'
import DropzoneUploader from './DropzoneUploader'
import type { DropEvent } from 'react-dropzone'
import Alert from '../../components/Alert'

type FileUploadProps = {|
  uploadStarted: boolean,
  isUploading: boolean,
  uploadComplete: boolean,
  uploadFailed: boolean,
  errorMessage: string,
  uploadText: string,
  fileType: {
    [key: string]: string[]
  },
  children: Node,
  multiple?: boolean,
  uploadFile: (e: DropEvent) => void,
  onUploadRejected: (e: File[]) => void,
  isAlert?: boolean
|}

export default function FileUpload(props: FileUploadProps): React$Node | null {
  const uploader = (
    <DropzoneUploader
      multiple={props.multiple}
      fileType={props.fileType}
      uploadText={props.uploadText}
      uploadFile={props.uploadFile}
      onUploadRejected={props.onUploadRejected}
    />
  )

  const spinner = (
    <div className={styles.container} data-test="file-upload-loading-container">
      <LoadingIndicator loadingText="Uploading your file" />
    </div>
  )

  const fileDetails = props.children

  const uploadError = (
    <React.Fragment>
      {props.isAlert && (
        <Alert className={styles.alert} dataTest="file-upload-alert">
          {props.errorMessage}
        </Alert>
      )}
      {!props.isAlert && (
        <p className={styles.failed} data-test="file-upload-error-message">
          {props.errorMessage}
        </p>
      )}

      {uploader}
    </React.Fragment>
  )

  if (!props.uploadStarted) {
    return uploader
  } else if (props.isUploading) {
    return spinner
  } else if (props.uploadComplete && !props.uploadFailed) {
    return fileDetails
  } else if (props.uploadFailed) {
    return uploadError
  } else {
    return null
  }
}
