// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'

import {
  RECEIVE_ORDER_DETAIL,
  RECEIVE_ORDER_SEARCH,
  ORDER_SEARCH_ERROR,
  FETCH_ORDER_DETAILS_ERROR,
  RECEIVE_ORDER_DETAIL_V3,
  V3_FETCH_ORDER_DETAILS_ERROR,
  CREATE_V4_ORDER_ERROR,
  CANCEL_ORDER_ERROR,
  UPDATE_SHIPPING_ADDRESS_ERROR,
  GET_ORDER_ACTIONS_ERROR,
  GET_ORDER_DETAILS_V4_ERROR,
  GET_ORDER_DETAILS_V4,
  SEARCH_ORDERS_V4_ERROR
} from '../../../actions/orders'

export function orders (action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case SEARCH_ORDERS_V4_ERROR: {
      return ['Searching orders failed (V4)', { ...action.meta, ...action.payload }, 'error']
    }

    case GET_ORDER_DETAILS_V4: {
      return ['Fetching order details (V4)', { orderId: action.meta.orderId }]
    }

    case GET_ORDER_DETAILS_V4_ERROR: {
      return ['Fetching order details failed (V4)', { orderId: action.meta.orderId, ...action.payload }, 'error']
    }

    case GET_ORDER_ACTIONS_ERROR: {
      return ['Fetching order actions failed', { ...action.payload }, 'error']
    }

    case UPDATE_SHIPPING_ADDRESS_ERROR: {
      return ['Update shipping address failed', { ...action.payload }, 'error']
    }

    case CANCEL_ORDER_ERROR: {
      return ['Cancel order failed (V4)', { ...action.payload }, 'error']
    }

    case RECEIVE_ORDER_DETAIL: {
      return [
        'Order details received',
        {
          orderId: action.payload.data.id,
          status: action.payload.data.statusText,
          isHeld: action.payload.data.isHeld,
          canCancel: action.payload.data.canCancel,
          canHold: action.payload.data.canHold,
          canRaiseIssue: action.payload.data.canRaiseIssue,
          canReorder: action.payload.data.canReorder,
          canUpdateShipping: action.payload.data.canUpdateShipping
        }
      ]
    }

    case RECEIVE_ORDER_DETAIL_V3: {
      return ['Order details received (V3 endpoint)']
    }

    case FETCH_ORDER_DETAILS_ERROR: {
      return ['Fetching order details failed', { resp: { ...action.payload } }, 'error']
    }

    case V3_FETCH_ORDER_DETAILS_ERROR: {
      return ['Fetching order details failed  (V3 endpoint)', { resp: { ...action.payload.response } }, 'error']
    }

    case RECEIVE_ORDER_SEARCH: {
      const searchQuery = state.orders.filters.query
      if (action.payload.data.count === 0) {
        return ['Unsuccessful search for orders', { searchQuery: searchQuery }]
      } else {
        return null
      }
    }

    case ORDER_SEARCH_ERROR: {
      const searchQuery = state.orders.filters.query
      return ['Searching orders failed', { searchQuery: searchQuery, resp: { ...action.payload } }, 'error']
    }

    case CREATE_V4_ORDER_ERROR: {
      return ['Creating v4 order failed', { ...action.payload }, 'error']
    }

    default: {
      return null
    }
  }
}
