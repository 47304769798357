// @flow
import type { Dictionary, PrintArea } from '../../types'
import type { InitPrintAreasForBasketItemAction } from '../types'

export const INIT_PRINT_AREAS_FOR_BASKET_ITEM = 'INIT_PRINT_AREAS_FOR_BASKET_ITEM'

export function initPrintAreasForBasketItem (
  basketItemId: number,
  printAreas: Dictionary<PrintArea>
): InitPrintAreasForBasketItemAction {
  return {
    type: INIT_PRINT_AREAS_FOR_BASKET_ITEM,
    basketItemId,
    printAreas
  }
}
