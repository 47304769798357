import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import InlineMenu from '../../InlineMenu'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { useDebouncedUrlUpdate } from '../../../hooks/useDebouncedUrlUpdate.hook'
import { useMerchantService } from '../../../hooks'
import { SalesChannelInterface } from '../../../interfaces'
import OrderItemSource from '../../OrderItemSource'

export function OrdersListActionSource() {
  const { merchantDetails } = useMerchantService()

  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedSourceParam = searchParams.get(QUERY_PARAMS.ORDERS.SOURCE)
  const selectedSourceDropdownValues = useMemo(
    () => (selectedSourceParam ? selectedSourceParam.split(',') : []),
    [selectedSourceParam]
  )
  const [selectedSourceValues, setSelectedSourceValues] = useState(selectedSourceDropdownValues)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedSourceValues(selectedSourceDropdownValues)
  }, [selectedSourceDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  const updateSelectedSource = (selectedValues: string[]) => {
    setSelectedSourceValues(selectedValues)
    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: QUERY_PARAMS.ORDERS.SOURCE,
      routePath: ROUTE_PATHS.ORDERS,
      selectedValues
    })
  }

  const options =
    merchantDetails?.salesChannels.map((channel: SalesChannelInterface) => ({
      content: <OrderItemSource className="max-w-[240px] break-words pr-10" source={channel} />,
      searchLabel: channel.description + channel.id,
      value: channel.id
    })) || []

  return (
    <InlineMenu
      className="max-h-96"
      ariaLabel="Source"
      data-test="orders-list-actions__source-filter"
      multiple={true}
      options={options}
      selected={selectedSourceValues}
      updateSelected={updateSelectedSource}
    />
  )
}
