// @flow
import React from 'react'

import {
  APPS_AVAILABLE,
  APP_TYPES,
  PRINT_ENGINE_MAPPING,
  PRINT_ENGINE_PARAMS,
  PRINT_ENGINE_URL
} from './constants'
import AppsView from './AppsView'
import ErrorView from './ErrorView'
import styles from './Apps.module.css'
import LoadingIndicator from '../../components/LoadingIndicator'
import processFetchResponse from '../../helpers/processFetchResponse'
import getDefaultFetchOptions from '../../helpers/getDefaultFetchOptions'
import { ABORT_ERROR_NAME, NOT_FOUND_STATUS_TEXT } from '../../data/constants'
import type { AbortController, AvailableAppType, ConnectedAppType, GetAppsResponseType, User } from '../../types'
import type { RouterHistory } from 'react-router'

type Props = {|
  user: User,
  history: RouterHistory
|}

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  appsAvailable: AvailableAppType[],
  connectedApps: {[key: string]: ConnectedAppType},
  errorMessage?: string,
  hasKiteAccount: boolean,
  hasError: boolean,
  hasPhotobookMaker: boolean,
  hasPrintShop: boolean,
  isLoadingApps: boolean,
  willErrorFixByWaiting: boolean
|}

class AppsController extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const controller = new window.AbortController()
    const signal = controller.signal
    this.state = {
      abortController: controller,
      abortSignal: signal,
      appsAvailable: APPS_AVAILABLE,
      connectedApps: {},
      hasKiteAccount: this.props.user.hasKiteAccount,
      hasError: false,
      hasPhotobookMaker: false,
      hasPrintShop: false,
      isLoadingApps: true,
      willErrorFixByWaiting: false
    }
  }

  componentDidMount () {
    const { user } = this.props

    if (user.hasKiteAccount === true) {
      const url = `${PRINT_ENGINE_URL}/get-apps/?${PRINT_ENGINE_PARAMS.MERCHANT_ID}=${user.merchantUniqueId}`
      fetch(url, {
        ...getDefaultFetchOptions(),
        signal: this.state.abortSignal
      })
        .then(processFetchResponse)
        .then((json: GetAppsResponseType[]) => {
          this.setConnectedApps(json)
          this.onLoaded()
        })
        .catch((err) => {
          if (err.name !== ABORT_ERROR_NAME) {
            console.error(err)
            if (err.message === NOT_FOUND_STATUS_TEXT) {
              this.setErrorState('', true)
            } else {
              this.setErrorState('An error occurred whilst getting apps.')
            }
            this.onLoaded()
          }
        })
    }
  }

  onLoaded: (() => void) = () => {
    this.setState({
      hasKiteAccount: true,
      isLoadingApps: false
    })
  }

  setConnectedApps: ((apps: Array<GetAppsResponseType>) => void) = (apps: GetAppsResponseType[]) => {
    const connectedApps = {}
    if (apps) {
      for (const app of apps) {
        const appType = app[PRINT_ENGINE_PARAMS.APP_TYPE]
        const brandName = app[PRINT_ENGINE_PARAMS.BRAND_NAME]
        const isOn = app[PRINT_ENGINE_PARAMS.IS_ACTIVE]
        const mappedPrintEngineAppType = PRINT_ENGINE_MAPPING[appType]
        const id = `${appType}-${brandName}`

        // To be removed when more than 1 app per type is supported in print-engine
        if (appType === APP_TYPES['print-shop'].printEngineType) {
          this.setState({ hasPrintShop: true })
        } else if (appType === APP_TYPES['photobook-maker'].printEngineType) {
          this.setState({ hasPhotobookMaker: true })
        }

        connectedApps[id] = {
          appType,
          brandName,
          description: mappedPrintEngineAppType.title,
          disableActiveStatusToggle: false,
          icon: mappedPrintEngineAppType.icon,
          id,
          isOn,
          linkText: 'Configure',
          title: brandName,
          url: `${mappedPrintEngineAppType.configureUrl}/${brandName}`
        }
      }
    }
    this.setState({ connectedApps })
  }

  setErrorState: ((errorMessage: string, willErrorFixByWaiting?: boolean) => void) = (errorMessage: string, willErrorFixByWaiting: boolean = false) => {
    this.setState({
      errorMessage,
      hasError: true,
      willErrorFixByWaiting
    })
  }

  handleAppStatusChange: ((isOn: boolean, appToUpdate: ConnectedAppType) => void) = (isOn: boolean, appToUpdate: ConnectedAppType) => {
    const {
      APP_TYPE,
      BRAND_NAME,
      IS_ACTIVE,
      MERCHANT_ID
    } = PRINT_ENGINE_PARAMS

    this.setAppStatus(!isOn, appToUpdate, true)

    const url = `${PRINT_ENGINE_URL}/config/?${BRAND_NAME}=${appToUpdate.brandName}&${APP_TYPE}=${appToUpdate.appType}&${MERCHANT_ID}=${this.props.user.merchantUniqueId}`
    fetch(url, {
      ...getDefaultFetchOptions(),
      method: 'PUT',
      body: JSON.stringify({
        [IS_ACTIVE]: isOn
      }),
      signal: this.state.abortSignal
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        this.setAppStatus(isOn, appToUpdate, false)
      })
      .catch((err) => {
        if (err.name !== ABORT_ERROR_NAME) {
          console.error(err)
          this.setErrorState('An error occurred while updating app active status.')
        }
      })
  }

  setAppStatus: ((
  isOn: boolean,
  appToUpdate: ConnectedAppType,
  disableActiveStatusToggle: boolean
) => void) = (isOn: boolean, appToUpdate: ConnectedAppType, disableActiveStatusToggle: boolean) => {
  this.setState(state => ({
    connectedApps: {
      ...state.connectedApps,
      [appToUpdate.id]: {
        ...state.connectedApps[appToUpdate.id],
        disableActiveStatusToggle,
        isOn
      }
    }
  }))
}

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    const {
      connectedApps,
      errorMessage,
      hasError,
      hasKiteAccount,
      hasPhotobookMaker,
      hasPrintShop,
      isLoadingApps,
      willErrorFixByWaiting
    } = this.state

    if (isLoadingApps && hasKiteAccount) {
      return (
        <main className={styles.loadingContainer}>
          <LoadingIndicator />
        </main>
      )
    }
    const connectedAppsArray: any[] = Object.values(connectedApps)

    return (
      <div>
        {(hasKiteAccount && !hasError)
          ? <AppsView
            history={this.props.history}
            appsAvailable={APPS_AVAILABLE}
            connectedApps={connectedAppsArray}
            hasPhotobookMaker={hasPhotobookMaker}
            hasPrintShop={hasPrintShop}
            onConnectedAppStatusChange={(event, appToUpdate) => this.handleAppStatusChange(event, appToUpdate)} />
          : <ErrorView message={errorMessage} willErrorFixByWaiting={willErrorFixByWaiting} />}
      </div>
    )
  }
}

export default AppsController
