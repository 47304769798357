import { useState } from 'react'
import toast from 'react-hot-toast'

import {
  FetchErrorInterface,
  OrderDetailRecipientInterface,
  OMSResponseInterface,
  OMSErrorResponseInterface
} from '../../../interfaces'
import { StatusEnum } from '../../../enums'
import FormItem from '../../FormItem'
import TextField from '../../TextField'
import { fetcher } from '../../../helpers'
import { selectOMSUrl } from '../../../selectors/appSettings'
import OrderAddressFormModal from '../../OrderAddressFormModal'
import { getCountryName } from '../../../../helpers/countryNames'
import { OMS_ENDPOINTS } from '../../../constants'
import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'

interface OrderDetailEditAddressModalPropsInterface {
  open: boolean
  orderId: string
  orderRecipient: OrderDetailRecipientInterface
  mutateOrderDetails: () => void
  setOpen: (open: boolean) => void
}

const SUCCESS_TOAST_ID = 'order-detail-update-address-toast-success'
const ERROR_TOAST_ID = 'order-detail-update-address-toast-error'

enum UpdateRecipientOutcomeEnum {
  Updated = 'Updated',
  PartiallyUpdated = 'PartiallyUpdated',
  FailedToUpdate = 'FailedToUpdate',
  ActionNotAvailable = 'NotAvailable'
}

interface UpdateRecipientResponseInterface extends OMSResponseInterface {
  outcome: UpdateRecipientOutcomeEnum
}

export function OrderDetailEditAddressModal({
  open,
  orderId,
  orderRecipient,
  mutateOrderDetails,
  setOpen
}: OrderDetailEditAddressModalPropsInterface) {
  const [saveAddressStatus, setSaveAddressStatus] = useState<StatusEnum>(StatusEnum.Idle)

  function handleSave(updatedAddress: OrderDetailRecipientInterface) {
    toast.dismiss(ERROR_TOAST_ID)
    setSaveAddressStatus(StatusEnum.Loading)

    const updateAddressUrl = `${selectOMSUrl()}/${OMS_ENDPOINTS.ORDER_DETAIL}/${orderId}/${
      OMS_ENDPOINTS.UPDATE_RECIPIENT
    }`
    fetcher<UpdateRecipientResponseInterface>(updateAddressUrl, {
      body: JSON.stringify({
        ...updatedAddress,
        address: {
          ...updatedAddress.address,
          countryCode: updatedAddress.address.countryCode.trim() || undefined,
          line1: updatedAddress.address.line1.trim() || undefined,
          line2: updatedAddress.address.line2.trim() || undefined,
          postcodeOrZipCode: updatedAddress.address.postcodeOrZipCode.trim() || undefined,
          stateOrCounty: updatedAddress.address.stateOrCounty.trim() || undefined,
          townOrCity: updatedAddress.address.townOrCity.trim() || undefined
        },
        email: updatedAddress.email.trim() || undefined,
        name: updatedAddress.name.trim() || undefined,
        phone: updatedAddress.phone.trim() || undefined
      }),
      method: 'PUT'
    })
      .then((response) => {
        if (
          response.outcome === UpdateRecipientOutcomeEnum.Updated ||
          response.outcome === UpdateRecipientOutcomeEnum.PartiallyUpdated
        ) {
          createToast({ heading: 'Successfully updated', id: SUCCESS_TOAST_ID, type: 'success' })
          setSaveAddressStatus(StatusEnum.Success)
          mutateOrderDetails()
          setOpen(false)
        } else {
          throw Error(`Unknown error (code: SER ${response.traceParent})`)
        }
      })
      .catch((error) => {
        const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
        let errorMessage
        let footer

        if (errorResponse.responseBodyJson?.outcome === UpdateRecipientOutcomeEnum.FailedToUpdate) {
          errorMessage = 'We are no longer able to update the address'
          footer = 'code: FTU'
        } else if (errorResponse.responseBodyJson?.outcome === UpdateRecipientOutcomeEnum.ActionNotAvailable) {
          errorMessage = 'We are no longer able to update the address'
          footer = 'code: ANA'
        } else if (errorResponse.responseBodyJson?.traceParent) {
          errorMessage = `${errorResponse.responseBodyJson?.data?.message ?? 'Unknown error'}`
          footer = <span className="break-all">code: {errorResponse.responseBodyJson.traceParent}</span>
        } else {
          errorMessage = error.message ?? 'Unknown error'
        }

        createToast({
          content: errorMessage,
          duration: Infinity,
          footer: footer,
          type: 'error-with-close',
          heading: 'Failed to update address',
          id: ERROR_TOAST_ID
        })
        setSaveAddressStatus(StatusEnum.Error)
      })
  }

  function handleOpenChange(open: boolean) {
    if (!open) {
      setSaveAddressStatus(StatusEnum.Idle)
      toast.dismiss(ERROR_TOAST_ID)
    }
    setOpen(open)
  }

  return (
    <>
      <OrderAddressFormModal
        closeOnInteractionOutside={saveAddressStatus !== StatusEnum.Loading}
        formattingCountryCode={orderRecipient.address.countryCode}
        isSaving={saveAddressStatus === StatusEnum.Loading}
        open={open}
        orderRecipient={orderRecipient}
        onCancel={() => handleOpenChange(false)}
        onSave={handleSave}
        setOpen={handleOpenChange}
      >
        <FormItem
          dataTest="customer-country-form-item"
          inputField={
            <TextField
              dataTest="recipient-country"
              disabled
              id="recipient_country_field"
              type="text"
              value={getCountryName(orderRecipient.address.countryCode)}
            />
          }
          labelText="Country"
        />
      </OrderAddressFormModal>

      {/* Blocks any clicks while the form is loading */}
      {saveAddressStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
