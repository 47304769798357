import { useLocation } from 'react-router-dom'

import { useImageLibraryImages } from '../../hooks'
import { hasImageFilterParamsInSearch } from './helpers'
import { ImageLibraryFilter, ImageLibrarySearch, ImageLibrarySort } from './components'

export default function ImageLibraryActions() {
  const { search } = useLocation()
  const { imageLibraryPages, isLoadingImageLibraryImages } = useImageLibraryImages()

  const hasFiltersApplied = hasImageFilterParamsInSearch(search)
  const hasImages = Boolean(imageLibraryPages && imageLibraryPages?.[0].length > 0)

  const showActions = hasImages || hasFiltersApplied || isLoadingImageLibraryImages

  if (!showActions) {
    return null
  }

  return (
    <div className="bg-inherit flex w-full flex-wrap justify-between gap-2 pt-4 md:gap-4 md:pt-8 xl:justify-end">
      <span className="mr-auto flex w-full items-center justify-self-start sm:w-64 lg:w-96">
        <ImageLibrarySearch />
      </span>

      <span className="flex w-64 flex-grow items-center sm:flex-grow-0">
        <ImageLibraryFilter />
      </span>

      <span className="flex w-64 flex-grow items-center sm:flex-grow-0">
        <ImageLibrarySort />
      </span>
    </div>
  )
}
