// @flow
import { connect } from 'react-redux'
import CsvOrderShippingDetailsController from './CsvOrderShippingDetailsController'
import { getCsvOrderQuotesByOrderId } from '../../../../selectors/csvUpload'
import { updateShippingMethod } from '../../../../actions/csvUpload'
import type { Quote, AppState, DispatchFunc, CustomerAddress, RsaaStatus } from '../../../../types'
import { getCsvOrderQuoteRsaaStatusByOrderId } from '../../../../selectors/quotes'
import type { ComponentType } from 'react'

type OwnProps = {|
  orderId: string,
  canBeEdited: boolean,
  address: CustomerAddress,
  preferredShippingMethod: string,
  currencyCode: string
|}

type StateProps = {|
  quoteRsaaStatus: RsaaStatus,
  quotes: Quote[]
|}

type DispatchProps = {|
  updateShippingMethod: (orderId: string, shippingMethod: string) => void
|}

function mapStateToProps (state: AppState, ownProps: OwnProps): StateProps {
  return {
    quoteRsaaStatus: getCsvOrderQuoteRsaaStatusByOrderId(state, ownProps.orderId),
    quotes: getCsvOrderQuotesByOrderId(state, ownProps.orderId)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    updateShippingMethod: (orderId: string, shippingMethod: string) => dispatch(updateShippingMethod(orderId, shippingMethod))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CsvOrderShippingDetailsController): ComponentType<OwnProps>)
