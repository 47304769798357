import { useState } from 'react'
import toast from 'react-hot-toast'
import { useUser } from '../../../../../../hooks'
import { useDispatch } from 'react-redux'

import Button from '../../../../../Button'
import FormItem from '../../../../../FormItem'
import TextField from '../../../../../TextField'
import { createToast } from '../../../../../Toast'
import { StatusEnum } from '../../../../../../enums'
import { updateUserName } from '../../../../helpers'
import SupportLink from '../../../../../SupportLink'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { FetchErrorInterface, UserInterface } from '../../../../../../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'

export function ProfilePanel({ className = '', onSuccess }: { className?: string; onSuccess: () => void }) {
  const { user, mutateUser } = useUser()
  const dispatch = useDispatch()

  const [accountProfileFormData, setAccountProfileFormData] = useState({
    fullName: { isValid: true, value: user?.fullName ?? '' }
  })
  const [accountProfileUpdateStatus, setAccountProfileUpdateStatus] = useState(StatusEnum.Idle)

  async function handleAccountProfileUpdate() {
    if (!user) {
      createToast({
        content: `Please try again later`,
        footer: 'Code: NUE',
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      return
    }

    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setAccountProfileUpdateStatus(StatusEnum.Loading)

    try {
      const newUserSettings: UserInterface = {
        ...user,
        fullName: accountProfileFormData.fullName.value
      }

      await updateUserName({ name: accountProfileFormData.fullName.value, user })
      mutateUser(newUserSettings)
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setAccountProfileUpdateStatus(StatusEnum.Success)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())

      onSuccess()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later (${errorResponse.status ?? errorResponse.name})`,
        duration: Infinity,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setAccountProfileUpdateStatus(StatusEnum.Error)
    }
  }

  if (!user) {
    throw new Error('No user')
  }

  return (
    <SettingsPanel
      className={className}
      title={<h2>Profile</h2>}
      subtitle={
        <span className="mt-4 text-gray-600">
          Please <SupportLink>contact us</SupportLink> if you need to change your email address.
        </span>
      }
    >
      <form
        className="max-w-xl"
        onSubmit={(event) => {
          event.preventDefault()
          handleAccountProfileUpdate()
        }}
      >
        <FormItem
          inputField={
            <TextField
              required
              type="text"
              value={accountProfileFormData.fullName.value}
              onChange={(event) => {
                setAccountProfileFormData({
                  ...accountProfileFormData,
                  fullName: {
                    ...accountProfileFormData.fullName,
                    isValid: event.target.validity.valid,
                    value: event.target.value
                  }
                })
              }}
            />
          }
          labelText="Full name"
          required
        />

        <FormItem inputField={<TextField disabled value={user.email} type="text" />} labelText="Email" />

        <FormItem
          inputField={<TextField className="" disabled value={user.merchantUniqueId} type="text" />}
          labelText="Merchant ID"
        />

        <Button
          className="mt-8"
          disabled={!accountProfileFormData.fullName.isValid}
          isLoading={accountProfileUpdateStatus === StatusEnum.Loading}
          type="submit"
          variant="primary"
        >
          Update
        </Button>
      </form>
    </SettingsPanel>
  )
}
