import { fetcher } from '../../../helpers'

export function updateIgnoreLineItemStatus({
  lineItemId,
  orderId,
  shouldIgnore
}: {
  lineItemId: string
  orderId: string
  shouldIgnore: boolean
}) {
  return fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/items/${lineItemId}/ignore`, {
    body: JSON.stringify(shouldIgnore),
    method: 'PUT'
  })
}
