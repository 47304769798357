// @flow
import type { Action } from '../../types'

export const TOGGLE_MULTISELECT_MODE = 'TOGGLE_MULTISELECT_MODE'

export function toggleMultiselectMode (): Action {
  return {
    type: TOGGLE_MULTISELECT_MODE
  }
}
