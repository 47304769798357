import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'

export type BrandedInsertIdType =
  | 'postcard'
  | 'flyer'
  | 'sticker_exterior_round'
  | 'sticker_exterior_rectangle'
  | 'sticker_interior_round'
  | 'sticker_interior_rectangle'
  | 'packing_slip_color'
  | 'packing_slip_bw'

export type BrandedInsertLocationType = 'InPackage' | 'OnPackage' | 'OnProduct'

export type BrandingDetailsType = {
  cost: { amount: number; currency: string }
  description: string
  dimensionsDescription: string
  fileRequirementsDescription: string
  id: BrandedInsertIdType
  location: BrandedInsertLocationType
  name: string
}

export interface BrandingDataInterface {
  branding: BrandingDetailsType[]
}

export function useBrandingDetails() {
  const { data, error } = useSWR<BrandingDataInterface, FetchErrorInterface<unknown>>(
    `${process.env.REACT_APP_BRANDING_SERVICE}/branding`,
    fetcher
  )

  return {
    brandingDetails: data,
    brandingDetailsFetchError: data ? undefined : error,
    isLoadingBrandingDetails: !data && !error
  }
}
