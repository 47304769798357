// @flow
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import SearchActions from './SearchActions'
import type { Event } from '../../../../types'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../../v2/constants'
import TextField from '../../../../v2/components/TextField'
import CountrySelection from './CountrySelection.component'
import { ALL, NO_SELECTION } from '../../../../data/catalogue'
import DropdownFilters from '../../../../components/DropdownFilters'

type ProductSearchLayoutProps = {
  searchQuery: string
  selectedItemCategory: string
  categories: string[]
  productTypes: string[]
  selectedProductType: string
  viewBasket: () => void
  clearSearch: () => void
  onQueryChange: (e: Event) => void
  onSelectItemCategory: (category: string) => void
  onSelectProductType: (productType: string) => void
}

export default function ProductSearchLayout(props: ProductSearchLayoutProps) {
  const history = useHistory()

  return (
    <div className="tailwind">
      <div className="relative mb-8 flex flex-row flex-wrap items-center justify-start gap-4">
        <div className="w-full md:w-[300px]">
          <label htmlFor="searchProducts">Search all products</label>
          <TextField
            dataTest="product-search-input"
            type="search"
            id="searchProducts"
            value={props.searchQuery}
            onChange={props.onQueryChange}
            placeholder="Search by product name or SKU"
          />
        </div>

        {props.categories.length <= 0 && <span className="relative top-[14px]">Loading categories...</span>}
        {props.categories.length > 0 && <CategoryFilters {...props} />}

        <SearchActions
          onClickViewBasket={props.viewBasket}
          onClickClearSearch={() => {
            history.replace(ROUTE_PATHS.MOF.SEARCH)
            props.clearSearch()
          }}
        />
      </div>
      <CountrySelection />
    </div>
  )
}

function CategoryFilters({
  categories,
  productTypes,
  selectedProductType,
  selectedItemCategory,
  onSelectItemCategory,
  onSelectProductType
}: ProductSearchLayoutProps) {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const categoryParam = searchParams.get(QUERY_PARAMS.MOF.SEARCH.CATEGORY)
  const productTypeParam = searchParams.get(QUERY_PARAMS.MOF.SEARCH.PRODUCT_TYPE)

  useEffect(() => {
    const userHasSelectedCategory = selectedItemCategory !== NO_SELECTION
    const userHasSelectedProductType = selectedProductType !== ALL

    if (userHasSelectedCategory || userHasSelectedProductType) {
      return
    }
    if (categoryParam) {
      onSelectItemCategory(categoryParam)
    }
    if (productTypeParam) {
      onSelectProductType(productTypeParam)
    }
  }, [
    categoryParam,
    onSelectItemCategory,
    onSelectProductType,
    productTypeParam,
    selectedItemCategory,
    selectedProductType
  ])

  return (
    <DropdownFilters
      categories={categories}
      productTypes={productTypes}
      selectedProductType={selectedProductType}
      selectedItemCategory={selectedItemCategory}
      onSelectProductType={(e: React.ChangeEvent) => onSelectProductType((e.currentTarget as HTMLSelectElement).value)}
      onSelectItemCategory={(e: React.ChangeEvent) =>
        onSelectItemCategory((e.currentTarget as HTMLSelectElement).value)
      }
    />
  )
}
