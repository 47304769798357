// @flow

import * as React from 'react'
import styles from './Alert.module.css'
import cx from 'classnames'

type Props = {|
  children: React$Node,
  type: 'success' | 'error',
  className?: string,
  dataTest?: string
|}

class Alert extends React.Component<Props> {
  static defaultProps: {| type: 'success' | 'error' |} = { type: 'error' }

  render(): React$Node {
    return (
      <div
        data-test={this.props.dataTest ?? 'alert'}
        className={cx(styles.alert, this.props.className, {
          [styles.success]: this.props.type === 'success'
        })}
        role="alert"
        data-testid="alert"
      >
        {this.props.children}
      </div>
    )
  }
}

export default Alert
