// @flow
import React, { PureComponent } from 'react'
import UploadFromUrlView from './UploadFromUrlView'

type Props = {|
  imageUrl: ?string,
  closeModal: () => void,
  uploadImage: (imageUrl: string) => void
|}

type State = {|
  imageUrl: string
|}

export default class UploadFromUrlController extends PureComponent<Props, State> {
  state: State = {
    imageUrl: this.props.imageUrl ?? ''
  }

  onChangeImageUrl: ((event: SyntheticKeyboardEvent<any>) => void) = (event: SyntheticKeyboardEvent<*>) => this.setState({ imageUrl: event.currentTarget.value })

  onClickUpload: (() => void) = () => {
    this.props.closeModal()

    if (this.state.imageUrl && this.state.imageUrl !== this.props.imageUrl) {
      this.props.uploadImage(this.state.imageUrl)
    }
  }

  render (): React$Node {
    return (
      <UploadFromUrlView
        onClickUpload={this.onClickUpload}
        imageUrl={this.state.imageUrl}
        closeModal={this.props.closeModal}
        onChangeImageUrl={this.onChangeImageUrl} />)
  }
}
