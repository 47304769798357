// @flow
import type { FormattedCsvRow, Dictionary, Country } from '../../types'
import type { DropEvent } from 'react-dropzone'
import React, { Component } from 'react'
import CsvUploadView from './CsvUploadView'
import { FILE_TYPE } from '../../data/fileType'
import { getSingleFileFromDropzoneEvent, getFileTypeFromFileName } from '../../helpers/fileUpload'
import { MULTIPLE_FILES_ERROR_MESSAGE } from '../../data/csvUpload'
import { LOADING_INDICATOR_CSS_CLASS_NAME } from '../../data/constants'

const DEFAULT_ERROR_MESSAGE = 'Upload failed. Please try again.'

type Props = {|
  merchantId: string,
  hasCsvUploadedSuccessfully: boolean,
  uploadedOrders: Dictionary<FormattedCsvRow>,
  csvLoading: boolean,
  csvError: boolean,
  csvMsg: string,
  isFetchingQuotes: boolean,
  isFetchingV4ProductDetails: boolean,
  isFetchingV3ProductDetails: boolean,
  isUploadingImages: boolean,
  isFetchingTemplates: boolean,
  countries: Country[],
  uploadCsv: (file: File, countries: Country[]) => void,
  getItemCategories: () => void,
  fetchCountries: () => void
|}

type State = {|
  hasValidationError: boolean,
  validationErrorMessage: ?string,
  fileName: ?string
|}

const initialState: State = {
  hasValidationError: false,
  validationErrorMessage: null,
  fileName: null
}

export default class CsvUploadController extends Component<Props, State> {
  state: State = { ...initialState }

  componentDidMount() {
    window.analytics.page('MOF', 'CSV Upload')
    this.props.getItemCategories()
    if (this.props.countries && this.props.countries.length === 0) {
      this.props.fetchCountries()
    }
  }

  componentDidUpdate() {
    if (document.body && this.isLoading) {
      document.body.classList.add(LOADING_INDICATOR_CSS_CLASS_NAME)
    } else if (document.body && !this.isLoading) {
      document.body.classList.remove(LOADING_INDICATOR_CSS_CLASS_NAME)
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.classList.remove(LOADING_INDICATOR_CSS_CLASS_NAME)
    }
  }

  onUpload: (e: DropEvent) => void = (e: DropEvent) => this.handleFileUpload(getSingleFileFromDropzoneEvent(e))

  handleFileUpload: (file: ?File) => void = (file: ?File) => {
    this.clearValidationError()
    this.validate(file, this.upload)
  }

  validate: (file: ?File, successCallback?: (file: File) => void) => void = (
    file: ?File,
    successCallback?: (file: File) => void
  ) => {
    if (!file) {
      this.setValidationError(DEFAULT_ERROR_MESSAGE)
    } else if (!this.isFileTypeValid(file)) {
      this.setValidationError(`File type ${getFileTypeFromFileName(file.name).slice(1)} is not supported.`)
    } else if (successCallback) {
      successCallback(file)
    }
  }

  upload: (file: File) => void = (file: File) => {
    this.setState({ fileName: file.name })
    this.props.uploadCsv(file, this.props.countries)
  }

  onUploadRejected: (e: File[]) => void = (e: File[]) => {
    if (e.length > 1) {
      this.setValidationError(MULTIPLE_FILES_ERROR_MESSAGE)
    } else {
      this.validate(getSingleFileFromDropzoneEvent(e))
    }
  }

  // we need to get the file type from the file name - file.type isn't reliable due to OS differences
  isFileTypeValid: (file: File) => boolean = (file: File): boolean =>
    getFileTypeFromFileName(file.name) === FILE_TYPE.CSV

  clearValidationError: () => void = () => this.setState({ hasValidationError: false, validationErrorMessage: null })

  setValidationError: (validationErrorMessage: string) => void = (validationErrorMessage: string) =>
    this.setState({ hasValidationError: true, validationErrorMessage })

  get uploadStarted(): boolean {
    return (
      this.state.hasValidationError ||
      this.props.csvLoading ||
      this.props.csvError ||
      this.props.hasCsvUploadedSuccessfully
    )
  }

  get uploadFailed(): boolean {
    return this.props.csvError || this.state.hasValidationError
  }

  get errorMessage(): string {
    if (this.state.hasValidationError && this.state.validationErrorMessage) {
      return this.state.validationErrorMessage
    } else if (this.props.csvError && this.props.csvMsg) {
      return this.props.csvMsg
    } else {
      return DEFAULT_ERROR_MESSAGE
    }
  }

  get isLoading(): boolean {
    return Boolean(
      this.props.isFetchingQuotes ||
        this.props.isFetchingV4ProductDetails ||
        this.props.isFetchingV3ProductDetails ||
        this.props.isUploadingImages ||
        this.props.isFetchingTemplates
    )
  }

  render(): React$Node {
    return (
      <CsvUploadView
        isUploading={this.props.csvLoading}
        uploadStarted={this.uploadStarted}
        uploadComplete={true}
        uploadFailed={this.uploadFailed}
        errorMessage={this.errorMessage}
        uploadSuccess={this.props.hasCsvUploadedSuccessfully}
        onUpload={this.onUpload}
        uploadedOrders={this.props.uploadedOrders}
        onUploadRejected={this.onUploadRejected}
      />
    )
  }
}
