// @flow
import type { Thunk, DispatchFunc } from '../../types'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { convertOrderIdToV4 } from '../../helpers/orderId'

export const GET_ORDER_ACTIONS = 'GET_ORDER_ACTIONS'
export const GET_ORDER_ACTIONS_SUCCESS = 'GET_ORDER_ACTIONS_SUCCESS'
export const GET_ORDER_ACTIONS_ERROR = 'GET_ORDER_ACTIONS_ERROR'

export function getOrderActions (orderId: string): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const v4OrderId = convertOrderIdToV4(orderId)

    dispatch({
      [RSAA]: {
        method: 'GET',
        endpoint: buildEndpoint(v4OrderId),
        types: [
          {
            type: GET_ORDER_ACTIONS,
            meta: {
              enhancements: [ LOG_STATUS ]
            }
          },
          {
            type: GET_ORDER_ACTIONS_SUCCESS,
            meta: {
              orderId
            }
          },
          GET_ORDER_ACTIONS_ERROR
        ]
      }
    })
  }
}

function buildEndpoint (orderId: string): string {
  return selectProdigiApiEndpoint() + '/v4.0/Orders/' + orderId + '/actions'
}
