import { fetcher } from '../../../../../helpers'
import { MerchantBrandingType } from '../../../../../hooks'

export function updateMerchantBranding({
  merchantId,
  newMerchantBranding
}: {
  merchantId?: number
  newMerchantBranding: MerchantBrandingType
}) {
  return fetcher(`${process.env.REACT_APP_OMS_MERCHANT_PREFERENCE_SERVICE}/merchant/${merchantId}/branding`, {
    method: 'PUT',
    body: JSON.stringify(newMerchantBranding)
  })
}
