import { HeaderLogo } from './HeaderLogo.component'
import { useSalesChannelProducts } from '../../../hooks'
import { SalesChannelInterface } from '../../../interfaces'
import SalesChannelSettings from '../../SalesChannelSettings'

export function ConfigureHeader({
  salesChannel,
  showSettings = true
}: {
  salesChannel: SalesChannelInterface
  showSettings?: boolean
}) {
  const { isLoadingSalesChannelProducts, salesChannelProductsFetchError, salesChannelProductPagesToLoadSize } =
    useSalesChannelProducts(salesChannel.id)

  const hasErrorLoadingFirstPage = Boolean(
    salesChannelProductsFetchError && salesChannelProductPagesToLoadSize <= 1 && salesChannel.numberOfProducts > 0
  )

  const showSubTitle =
    !hasErrorLoadingFirstPage &&
    !isLoadingSalesChannelProducts &&
    salesChannel.numberOfProducts > 0 &&
    salesChannel.initialProductsDownloaded

  return (
    <header className="flex flex-col">
      <div className="flex items-center gap-2">
        <HeaderLogo platformName={salesChannel.platform} />
        <h1 className="ml-2 truncate">{salesChannel.description}</h1>

        {showSettings && (
          <div className="ml-auto">
            <SalesChannelSettings salesChannelId={salesChannel.id} />
          </div>
        )}
      </div>

      {showSubTitle && (
        <>
          <p className="mt-4">Here are all of your products from {salesChannel.description}.</p>
          <p className="mt-2">Choose which products you&apos;d like us to fulfil by using the toggle switches below.</p>
        </>
      )}
    </header>
  )
}
