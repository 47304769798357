// @flow
import React, { Component } from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import FontAwesome from 'react-fontawesome'
import Radium from 'radium'
import type { Style } from '../types'

type Props = {|
  uploadArtworkFromUrl: (url: string) => void,
  style?: Style
|}

type DropboxFile = {|
  bytes: number,
  ixon: string,
  id: string,
  isDir: boolean,
  link: string,
  name: string,
  thumbnailLink: string
|}

class DropboxUpload extends Component<Props> {
  getStyles () {
    return {
      container: {
        padding: ' 0.5em 1em',
        margin: '0px auto',
        cursor: 'pointer',
        backgroundColor: 'var(--color-white)',
        ':hover': {
          backgroundColor: 'var(--greyscale-lightest)'
        }
      },
      icon: {
        color: 'var(--greyscale-dark)',
        maxWidth: '13px',
        marginRight: '0.7em'
      }
    }
  }

  onSuccess = (files: DropboxFile[]) => {
    this.props.uploadArtworkFromUrl(files[0].link)
  }

  render (): React$Node {
    const { style } = this.props
    const styles = this.getStyles()
    return (
      <DropboxChooser
        appKey={'d9y4l8302hspgq5'}
        success={files => this.onSuccess(files)}
        linkType="direct"
        multiselect={false}
        extensions={['.jpg', '.jpeg', '.png', '.gif']}
      >
        <p id='dropbox_upload_btn' style={{ ...styles.container, ...style }} data-test="upload-from-dropbox">
          <FontAwesome name="dropbox" style={styles.icon} />
          Upload from Dropbox
        </p>
      </DropboxChooser>
    )
  }
}

export default (Radium(DropboxUpload): any)
