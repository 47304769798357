import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import SelectProductModal from '../../SelectProductModal'
import { SelectedProductInterface } from '../../SelectProduct'
import { LINE_ITEM_TOASTS } from '../constants/lineItemToasts.const'
import { saveLineItemData } from '../helpers/saveLineItemData.helper'
import { LineItemEditorDataType } from './LineItemProductData.component'
import { isNoImageProduct, getNoImageProductData } from '../../../helpers'
import { useOrderDetail, ProductDetailSuccessResponseInterface } from '../../../hooks'
import { generateDefaultArtworkData } from '../helpers/generateDefaultArtworkData.helper'
import { ProductAdditionalDataInterface } from '../../SelectProduct/SelectProduct.component'
import { FetchErrorInterface, OMSErrorResponseInterface, OrderDetailItemInterface } from '../../../interfaces'

export function LineItemSelectProductModal({
  isSelectProductModalOpen,
  lineItem,
  lineItemEditorData,
  onLineItemEditorDataChange,
  onSelectProductModalOpenChange,
  onSelectProductSuccess
}: {
  isSelectProductModalOpen: boolean
  lineItem: OrderDetailItemInterface
  lineItemEditorData: LineItemEditorDataType
  onLineItemEditorDataChange: (newLineItemData: LineItemEditorDataType) => void
  onSelectProductModalOpenChange: (isOpen: boolean) => void
  onSelectProductSuccess: (nextStepToOpen: 'image-editor' | null) => void
}) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  const [selectingSkuName, setSelectingSkuName] = useState<string>()

  useEffect(() => {
    if (!isSelectProductModalOpen) {
      setSelectingSkuName(undefined)
      toast.dismiss(LINE_ITEM_TOASTS.ERROR)
    }
  }, [isSelectProductModalOpen])

  async function saveProductData(
    newLineItemData: LineItemEditorDataType,
    { openImageEditorOnSuccess }: { openImageEditorOnSuccess: boolean }
  ) {
    setSelectingSkuName(newLineItemData?.selectedProductData?.sku)
    toast.dismiss(LINE_ITEM_TOASTS.ERROR)

    try {
      await saveLineItemData({ lineItemId: lineItem.id, newLineItemData, orderId })
      await mutateOrderDetails()
      setSelectingSkuName(undefined)
      onLineItemEditorDataChange(newLineItemData)
      if (openImageEditorOnSuccess) {
        onSelectProductSuccess('image-editor')
      } else {
        onSelectProductSuccess(null)
      }
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code OMS-${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'An error occurred while selecting product',
        id: LINE_ITEM_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setSelectingSkuName(undefined)
    }
  }

  async function handleNoImageProductSelection(
    newSelectedProduct: SelectedProductInterface,
    {
      assetRequirementData,
      productDetails
    }: {
      assetRequirementData: Record<string, { required: boolean }>
      productDetails: ProductDetailSuccessResponseInterface
    }
  ) {
    const noImageProductData = getNoImageProductData({
      attributes: newSelectedProduct.selectedAttributes ?? {},
      productType: newSelectedProduct.searchResultData.productType,
      sku: newSelectedProduct.sku
    })
    const printAreas = Object.keys(productDetails.product.printAreas)
    const firstAvailablePrintArea = printAreas[0]

    const costInPounds = newSelectedProduct.searchResultData.basePriceFrom / 100
    const price = Number(costInPounds).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      useGrouping: true,
      currency: newSelectedProduct.searchResultData.currency
    })

    const newLineItemData = {
      ...lineItemEditorData,
      assetRequirementData,
      artworkData: {
        [firstAvailablePrintArea]: {
          artwork: undefined,
          artworkTransformations: undefined,
          previewImageUrl: noImageProductData?.labImage,
          printImageUrl: noImageProductData?.labImage,
          transformImageUrl: noImageProductData?.labImage
        }
      },
      selectedPrintArea: firstAvailablePrintArea,
      selectedProductData: {
        attributes: newSelectedProduct.selectedAttributes,
        metaData: newSelectedProduct.selectedMetaData,
        sku: newSelectedProduct.sku,
        productType: newSelectedProduct.searchResultData.productType,
        price
      }
    }

    await saveProductData(newLineItemData, { openImageEditorOnSuccess: false })
  }

  async function handleSelectProduct(
    newSelectedProduct: SelectedProductInterface,
    { productDetails, templates }: ProductAdditionalDataInterface
  ) {
    const assetRequirementData = Object.entries(productDetails.product.printAreas).reduce(
      (
        assetRequirementDataAcc: Record<string, { required: boolean }>,
        [productPrintAreaName, productPrintAreaData]
      ) => {
        return {
          ...assetRequirementDataAcc,
          [productPrintAreaName]: { required: productPrintAreaData.required }
        }
      },
      {}
    )

    if (isNoImageProduct(newSelectedProduct.searchResultData.productType)) {
      handleNoImageProductSelection(newSelectedProduct, { assetRequirementData, productDetails })
      return
    }

    const hasSkuChanged = lineItemEditorData?.selectedProductData?.sku !== newSelectedProduct.sku
    const haveAttributesChanged = Object.entries(newSelectedProduct.selectedAttributes ?? {}).some(
      ([attributeName, attributeValue]) =>
        lineItemEditorData?.selectedProductData?.attributes?.[attributeName] !== attributeValue
    )

    const printAreas = Object.keys(productDetails.product.printAreas)
    const firstAvailablePrintArea = printAreas[0]

    let newArtworkData = lineItemEditorData?.artworkData

    const shouldResetArtworkTransformations = hasSkuChanged || haveAttributesChanged
    if (shouldResetArtworkTransformations) {
      newArtworkData = generateDefaultArtworkData({
        lineItemArtworkData: lineItemEditorData?.artworkData,
        printAreas,
        templates,
        newSelectedProduct
      })
    }

    const costInPounds = newSelectedProduct.searchResultData.basePriceFrom / 100
    const price = Number(costInPounds).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      useGrouping: true,
      currency: newSelectedProduct.searchResultData.currency
    })

    const newLineItemData: LineItemEditorDataType = {
      ...lineItemEditorData,
      assetRequirementData,
      artworkData: newArtworkData,
      selectedPrintArea: firstAvailablePrintArea,
      selectedProductData: {
        attributes: newSelectedProduct.selectedAttributes,
        metaData: newSelectedProduct.selectedMetaData,
        sku: newSelectedProduct.sku,
        productType: newSelectedProduct.searchResultData.productType,
        price
      }
    }
    await saveProductData(newLineItemData, { openImageEditorOnSuccess: true })
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  return (
    <>
      <SelectProductModal
        countryCode={orderDetails.recipient.address.countryCode}
        closeOnEscape={!selectingSkuName}
        closeOnInteractionOutside={!selectingSkuName}
        defaultSearchQuery={lineItemEditorData?.selectedProductData?.sku}
        defaultSelectedAttributes={lineItemEditorData?.selectedProductData?.attributes ?? undefined}
        defaultSelectedMetaData={lineItemEditorData?.selectedProductData?.metaData ?? undefined}
        selectingSkuName={selectingSkuName}
        open={isSelectProductModalOpen}
        showCountryPicker={false}
        showMetaData={true}
        setOpen={onSelectProductModalOpenChange}
        onSelectProductWithAdditionalData={handleSelectProduct}
      />

      {Boolean(selectingSkuName) && <OverlayPortal />}
    </>
  )
}
