import { ReactNode } from 'react'

export function ToastContent({
  content,
  footer,
  heading
}: {
  content?: ReactNode
  footer?: ReactNode
  heading: string
}) {
  return (
    <div className="tailwind">
      <div className="flex max-w-[200px] flex-1 flex-col break-words text-sm sm:max-w-[260px]">
        <span className="font-medium">{heading}</span>
        {content && <span className="mt-2">{content}</span>}
        {footer && <span className="mt-4 text-gray-700">{footer}</span>}
      </div>
    </div>
  )
}
