// @flow
import OrderAddressController from './OrderAddressController'
import { connect } from 'react-redux'
import { updateAddress } from '../../../actions/manualOrderForm'
import { getOrderAddressStateProps } from '../../../selectors/manualOrderForm'
import type { OrderAddressStateProps, AppState, DispatchFunc } from '../../../types'
import { pushRedirect } from '../../../actions/router'
import type { ComponentType } from 'react'

type DispatchProps = {|
  onAddressChange: (field: string, value: string) => void,
  pushRedirect: (pathname: string) => void
|}

function mapStateToProps (state: AppState): OrderAddressStateProps {
  return getOrderAddressStateProps(state)
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    onAddressChange: (field: string, value: string) => dispatch(updateAddress(field, value)),
    pushRedirect: (pathname: string) => {
      dispatch(pushRedirect(pathname))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(OrderAddressController): ComponentType<{||}>)
