// @flow
import SelectProduct from './SelectProduct'
import { connect } from 'react-redux'
import { addBasketItem } from '../../../../actions/manualOrderForm'
import { getSelectProductStateProps } from '../../../../selectors/manualOrderForm'
import type { DispatchFunc, CatalogueItem, RsaaStatusProps } from '../../../../types'
import type { SelectProductDispatchProps } from './types'
import { pushRedirect } from '../../../../actions/router'
import type { ComponentType } from 'react'
import type { Location, RouterHistory } from 'react-router'

type OwnProps = {|
  searching: boolean,
  fetchingPrices: boolean,
  showFacets: boolean,
  isSearchInconclusive: boolean,
  facetsLoading: boolean,
  selectedMobileFacetScreen: string,
  searchItems: ?CatalogueItem[],
  basketItemCount: number,
  searchProductsBySkuListRsaaStatus: RsaaStatusProps,
  location: Location,
  history: RouterHistory
|}

function mapDispatchToProps (dispatch: DispatchFunc): SelectProductDispatchProps {
  return {
    addBasketItem: (sku: string) => dispatch(addBasketItem(sku)),
    redirect: (path: string) => dispatch(pushRedirect(path))
  }
}

export default (connect(getSelectProductStateProps, mapDispatchToProps)(SelectProduct): ComponentType<OwnProps>)
