import clsx from 'clsx'

import { FooterContainer, FooterBlock } from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function Devices({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <FooterContainer>
      <FooterBlock title="Devices">
        <div className={clsx('grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-8')}>
          {productData.devices.map((device) => (
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-8" key={device.brand}>
              <h4 className="mt-0 text-lg">{device.brand}</h4>
              <ul className="text-sm leading-tight">
                {device.models?.map((model) => (
                  <li className="mb-1" key={model}>
                    {model}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </FooterBlock>
    </FooterContainer>
  )
}
