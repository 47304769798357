// @flow

import React from 'react'
import { withRouter } from 'react-router-dom'

type Props = {|
  location: any,
  match: any,
  history: any
|}

class ScrollToTop extends React.Component<Props> {
  componentDidUpdate (prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0)
    }
  }

  render (): null {
    return null
  }
}

export default (withRouter(ScrollToTop): any)
