import { SCALE_TYPE, ScaleType } from '@prodigi-group/components-image-editor'

import {
  ArtworkInterface,
  ArtworkTransformationsInterface,
  ImageEditorTransformationsInterface
} from '../../ImageEditor/interfaces'
import { TemplateDataInterface } from '../../../hooks'
import { calculateScaleFactorAdjustment } from '../../ImageEditor/helpers'
import { convertPrintPxPositionToUserUnit } from '../../../../helpers/imageEditor'

export function buildTransformations({
  artwork,
  selectedTemplate,
  transformations
}: {
  artwork: ArtworkInterface | undefined | null
  selectedTemplate?: TemplateDataInterface
  transformations: ArtworkTransformationsInterface | null | undefined
}) {
  if (!transformations || !selectedTemplate || !artwork) {
    return null
  }

  const artworkDimensionsInPx = {
    height: artwork.artworkHeight,
    width: artwork.artworkWidth
  }

  const scaleFactorAdjustment = calculateScaleFactorAdjustment(
    selectedTemplate.printResolution,
    artworkDimensionsInPx,
    selectedTemplate.cropRectangle,
    selectedTemplate.printDpi,
    transformations.borderFactor,
    transformations.borderScale
  )
  const scaleFactorInPigFriendlyFormat = (transformations.scaleFactor * scaleFactorAdjustment) / 100
  const pigPosition = convertPrintPxPositionToUserUnit(
    transformations.position,
    transformations.borderScale,
    selectedTemplate.printResolution,
    artworkDimensionsInPx,
    selectedTemplate.cropRectangle,
    selectedTemplate.printDpi
  )

  const imageEditorTransformations: ImageEditorTransformationsInterface = {
    scaleFactor: scaleFactorInPigFriendlyFormat,
    scaleType: SCALE_TYPE.PRINT_SIZE as ScaleType,
    position: pigPosition,
    positionUnit: transformations.borderScale,
    rotationAngle: transformations.rotationAngle,
    borderWidth: transformations.borderFactor,
    borderUnit: transformations.borderScale,
    isTiled: transformations.isTiled
  }

  return imageEditorTransformations
}
