// @flow
import user from './user'
import auth from './auth'
import orders from './orders'
import catalogue from './catalogue'
import rsaa from './rsaa'
import manualOrderForm from './manualOrderForm'
import recentItems from './recentItems'
import csvUpload from './csvUpload'
import images from './images'
import routerReducer from './router'
import quotes from './quotes'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { RESET_APP_STATE } from '../actions/global'
import type { CombinedReducer } from 'redux'
import type { AppState, Action } from '../types'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['recentItems']
}

const reducers = {
  // solution to the problem with circular imports - Jest bug
  user: user || (() => {}),
  auth: auth || (() => {}),
  orders,
  manualOrderForm: manualOrderForm || (() => {}),
  catalogue: catalogue || (() => {}),
  rsaa: rsaa || (() => {}),
  csvUpload: csvUpload || (() => {}),
  images: images || (() => {}),
  quotes: quotes || (() => {}),
  router: routerReducer,
  recentItems
}

const combinedReducers: CombinedReducer<AppState, Action> = persistCombineReducers(persistConfig, reducers)

export default function rootReducer(state: AppState, action: Action): any {
  if (action.type === RESET_APP_STATE) {
    const { recentItems, router } = state
    return combinedReducers({ recentItems, router }, action)
  }

  return combinedReducers(state, action)
}
