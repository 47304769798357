// @flow
import {
  getSubmissionOrders,
  selectCsvOrderItems,
  getIsCsvV4OrderSubmissionSuccessful,
  getCsvFileType
} from '../../selectors/csvUpload'
import type {
  ThunkAsync,
  DispatchFunc,
  FormattedOrderItem,
  Dictionary,
  GetStateFunc,
  FormattedCsvRow,
  Action
} from '../../types'
import type { SubmitCsvOrdersError } from '../types'
import * as ACTIONS from './orderSubmissionActions'

import { values, entries } from '../../helpers/dictionary'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'
import { submitCsvV4Orders } from './submitCsvV4Orders'
import { selectCurrencyCode } from '../../selectors/user'
import { getV4ProductDetailsBySkus } from '../../selectors/catalogue'

export function submitCsvOrders(isTransformUrlsOn?: boolean = false): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    try {
      dispatch(submitCsvOrdersStart())

      const ordersToSubmit = values(getSubmissionOrders(getState()))
      const allOrderItems = selectCsvOrderItems(getState())
      const orderItemsByOrderId = groupOrderItemsByOrderIds(ordersToSubmit, allOrderItems)
      const csvFileType = getCsvFileType(getState())

      const allSkus = getAllSkus(orderItemsByOrderId)
      const productDetails = getV4ProductDetailsBySkus(getState(), allSkus)
      const userCurrency = selectCurrencyCode(getState())

      await dispatch(
        submitCsvV4Orders(
          csvFileType,
          ordersToSubmit,
          orderItemsByOrderId,
          productDetails,
          userCurrency,
          isTransformUrlsOn
        )
      )

      dispatch(finalizeOrderSubmission())
    } catch (error) {
      dispatch(submitCsvOrdersError(DEFAULT_ERROR_MESSAGE, error.message))
    }
  }
}

function getAllSkus(orderItems: Dictionary<FormattedOrderItem[]>): string[] {
  return entries(orderItems).reduce((skuAcc, [orderId, orderItems]) => {
    const orderItemSkus = orderItems.map((item) => item.sku)

    return skuAcc.concat(orderItemSkus)
  }, [])
}

function groupOrderItemsByOrderIds(
  orders: FormattedCsvRow[],
  orderItems: Dictionary<FormattedOrderItem>
): Dictionary<FormattedOrderItem[]> {
  return orders.reduce((orderItemAcc, order) => {
    orderItemAcc[order.id] = order.itemIds.map((itemId) => orderItems[itemId])

    return orderItemAcc
  }, {})
}

function finalizeOrderSubmission(): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const isSubmissionSuccessful = getIsCsvV4OrderSubmissionSuccessful(getState())

    if (isSubmissionSuccessful) {
      dispatch(submitCsvOrdersSuccess())
    } else {
      dispatch(submitCsvOrdersError(DEFAULT_ERROR_MESSAGE))
    }
  }
}

function submitCsvOrdersStart(): Action {
  return {
    type: ACTIONS.SUBMIT_CSV_ORDERS
  }
}

function submitCsvOrdersSuccess(): Action {
  return {
    type: ACTIONS.SUBMIT_CSV_ORDERS_SUCCESS
  }
}

function submitCsvOrdersError(friendlyErrorMessage: string, debugMessage?: string): SubmitCsvOrdersError {
  return {
    type: ACTIONS.SUBMIT_CSV_ORDERS_ERROR,
    message: friendlyErrorMessage,
    debugMessage
  }
}
