export enum OrderDetailIssueEnum {
  PaymentRequiresAuthorisation = 'PaymentRequiresAuthorisation',
  DamagedOrder = 'DamagedOrder',
  WrongFrameColour = 'WrongFrameColour',
  IncompleteOrder = 'IncompleteOrder',
  LostInPost = 'LostInPost',
  IncorrectOrientation = 'IncorrectOrientation',
  IncorrectPrints = 'IncorrectPrints',
  PrintDefects = 'PrintDefects',
  SlowArrival = 'SlowArrival',
  SlowDispatch = 'SlowDispatch',
  SubmissionErrors = 'SubmissionErrors',
  WrongAddress = 'WrongAddress',
  CustomerOrderError = 'CustomerOrderError',
  Unspecified = 'Unspecified'
}
