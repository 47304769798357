import LoadingIndicator from '../../../components/LoadingIndicator'

// TODO: Update with v2 LoadingSpinner when v1 components are removed
export default function V1LoadingIndicatorFullPage() {
  return (
    <div className="tailwind">
      <div className="grid min-h-screen w-full place-content-center">
        <LoadingIndicator stackClassName="gap-6" />
      </div>
    </div>
  )
}
