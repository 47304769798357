// @flow
import type {
  MultiAssetBasketItem,
  DispatchFunc,
  GetStateFunc,
  Dictionary,
  PrintArea,
  ThunkAsync,
  V4ProductDetails
} from '../../types'
import { setNewBasketItemId } from '../manualOrderForm/setNewBasketItemId'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue'
import { getV4ProductDetails, GET_V4_PRODUCT_DETAILS_SUCCESS } from '../catalogue'
import { getProductTemplates } from '../../selectors/images'
import { fetchPreviewTemplate } from '../manualOrderForm'
import { PRINT_AREA_NAME } from '../../data'
import { saveUnfinishedOrder } from '../manualOrderForm/saveUnfinishedOrder'
import { selectCustomer, selectDeliveryCountry, selectInProgressOrder } from '../../selectors/manualOrderForm'
import type { MultiAssetTemplates } from '../../types/templates'
import type { PrintAreaArtwork } from '../../types/basketItem'
import { entries } from '../../helpers/dictionary'
import type { Artwork, ArtworkTransformations } from '../../types/images'

// $FlowFixMe: TypeScript file
import { transformImage } from '../../v2/helpers/transformImage.helper'

export const ADD_RECENT_ITEM_TO_BASKET = 'ADD_RECENT_ITEM_TO_BASKET'
export const UPDATE_RECENT_ITEM_PRINT_AREAS = 'UPDATE_RECENT_ITEM_PRINT_AREAS'

export function addRecentItemToBasket(itemWithIncorrectId: MultiAssetBasketItem): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const itemWithCorrectId = await assignId(itemWithIncorrectId, dispatch)
    dispatch({ type: ADD_RECENT_ITEM_TO_BASKET, item: itemWithCorrectId })

    let v4ProductDetails: ?V4ProductDetails = getV4ProductDetailsBySku(getState(), itemWithCorrectId.sku)

    if (!v4ProductDetails) {
      const v4ProductDetailsResponseResult = await dispatch(getV4ProductDetails(itemWithCorrectId.sku))
      if (v4ProductDetailsResponseResult.type === GET_V4_PRODUCT_DETAILS_SUCCESS) {
        v4ProductDetails = v4ProductDetailsResponseResult.payload.product
      }
    }

    if (!getProductTemplates(getState(), itemWithCorrectId.sku, itemWithCorrectId.selectedAttributes)) {
      await dispatch(
        fetchPreviewTemplate(itemWithCorrectId.sku, itemWithCorrectId.id, itemWithCorrectId.selectedAttributes)
      )
    }

    const templates = getProductTemplates(getState(), itemWithCorrectId.sku, itemWithCorrectId.selectedAttributes)

    const printAreaNames = Object.keys(v4ProductDetails?.printAreas ?? {})

    const mappedPrintAreasData = await mapArtworkDataForPrintAreas({
      item: itemWithCorrectId,
      productPrintAreas: v4ProductDetails?.printAreas,
      templates
    })

    const mappedItem = {
      ...mappedPrintAreasData,
      selectedPrintArea:
        printAreaNames.length === 0 || printAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
          ? PRINT_AREA_NAME.DEFAULT
          : printAreaNames[0]
    }

    dispatch({ type: UPDATE_RECENT_ITEM_PRINT_AREAS, item: mappedItem })
    saveUnfinishedOrder(
      selectInProgressOrder(getState()),
      selectCustomer(getState()),
      selectDeliveryCountry(getState())
    )
  }
}

async function assignId(item: MultiAssetBasketItem, dispatch: DispatchFunc): Promise<MultiAssetBasketItem> {
  const itemCopy = JSON.parse(JSON.stringify(item))
  itemCopy.id = (await dispatch(setNewBasketItemId())).id

  return itemCopy
}

async function mapArtworkDataForPrintAreas({
  item,
  productPrintAreas,
  templates
}: {|
  item: MultiAssetBasketItem,
  productPrintAreas: ?Dictionary<PrintArea>,
  templates: ?MultiAssetTemplates
|}): Promise<MultiAssetBasketItem> {
  const itemPrintAreas = item.printAreas
  if (!productPrintAreas || !itemPrintAreas) {
    return {
      ...item,
      printAreas: null
    }
  }

  const mappedPrintAreas = Object.keys(productPrintAreas).reduce((printAreasAcc, printArea) => {
    const itemPrintArea = itemPrintAreas[printArea]
    const hasArtworkForPrintArea =
      itemPrintArea && itemPrintArea.artwork && Object.keys(itemPrintArea.artwork).length > 0
    const hasArtworkTransformationsForPrintArea =
      itemPrintArea &&
      itemPrintArea.artworkTransformations &&
      Object.keys(itemPrintArea.artworkTransformations).length > 0

    if (itemPrintArea && hasArtworkForPrintArea && hasArtworkTransformationsForPrintArea) {
      printAreasAcc[printArea] = itemPrintAreas[printArea]
    } else {
      printAreasAcc[printArea] = {
        artwork: null,
        artworkTransformations: null
      }
    }

    return printAreasAcc
  }, {})

  const mappedPrintAreasWithTransformData = await updateTransformUrls({ printAreas: mappedPrintAreas, templates })

  return {
    ...item,
    printAreas: mappedPrintAreasWithTransformData
  }
}

type TransformDataResponseType = {| url: string |}
type TransformDataType = {| transformImageUrl: string |}

async function updateTransformUrls({
  printAreas,
  templates
}: {|
  printAreas: ?Dictionary<PrintAreaArtwork>,
  templates: ?MultiAssetTemplates
|}) {
  if (!printAreas) {
    return
  }

  const transformationRequests: Promise<TransformDataResponseType>[] = []
  const printAreasForTransformationRequests: string[] = []

  entries<PrintAreaArtwork>(printAreas).forEach(([printAreaName, printAreaData]) => {
    if (!printAreaData.artwork || !printAreaData.artworkTransformations) {
      return
    }

    const artwork = printAreaData.artwork
    const artworkTransformations = printAreaData.artworkTransformations
    const template = templates?.printAreas[printAreaName].orientations[artworkTransformations.orientation]

    if (artwork.mimeType === 'application/pdf') {
      return
    }

    if (!template) {
      return
    }

    transformationRequests.push(
      transformImage({
        artwork,
        imageLibraryId: artwork.imageLibraryId ?? '',
        template,
        transformations: artworkTransformations,
        preview: false
      })
    )
    printAreasForTransformationRequests.push(printAreaName)
  })

  const printAreasWithTransformUrls: Dictionary<TransformDataType> = {}

  try {
    const transformedImagesData = await Promise.all(transformationRequests)

    printAreasForTransformationRequests.forEach((printArea, index) => {
      printAreasWithTransformUrls[printArea] = {
        transformImageUrl: transformedImagesData[index].url
      }
    })
  } catch (error) {
    console.error(error)
  }

  const mappedPrintAreasWithTransformData: Dictionary<PrintAreaArtwork> = {}

  entries<PrintAreaArtwork>(printAreas).forEach(([printAreaName, printAreaData]) => {
    let newArtwork: Artwork | null = null

    if (printAreaData.artwork && printAreasWithTransformUrls[printAreaName]) {
      newArtwork = {
        ...printAreaData.artwork,
        transformImageUrl: printAreasWithTransformUrls[printAreaName].transformImageUrl
      }
    }

    let newArtworkTransformations: ArtworkTransformations | null = null

    if (printAreaData.artworkTransformations && printAreasWithTransformUrls[printAreaName]) {
      newArtworkTransformations = {
        ...printAreaData.artworkTransformations
      }
    }

    if (printAreaData.artwork?.mimeType === 'application/pdf') {
      // If we have the mimeType for PDF, we will have transformUrl too
      newArtwork = printAreaData.artwork || null
      newArtworkTransformations = printAreaData.artworkTransformations || null
    }

    mappedPrintAreasWithTransformData[printAreaName] = {
      artwork: newArtwork || null,
      artworkTransformations: newArtworkTransformations || null
    }
  })

  return mappedPrintAreasWithTransformData
}
