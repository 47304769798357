import { PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS } from '../../../../v2/constants'
import styles from './SummaryItemList.module.css'

export function MetaDataDisplay({
  metaData
}: {
  metaData?: {
    spineText?: string
    spineBackgroundColourHexCode?: string
    spineTextColourHexCode?: string
    spineTextSizePx?: number
    spineTextFontFamily?: string
  }
}) {
  return (
    <>
      {Boolean(metaData?.spineText) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Text:</td>
          <td className={styles.meta} style={{ wordBreak: 'break-word' }}>
            {metaData?.spineText}
          </td>
        </tr>
      )}

      {Boolean(metaData?.spineTextSizePx) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Text Size:</td>
          <td className={styles.meta}>{metaData?.spineTextSizePx}px</td>
        </tr>
      )}

      {Boolean(metaData?.spineTextFontFamily) && metaData?.spineTextFontFamily && (
        <tr>
          <td className={styles.attributeTitle}>Spine Text Font:</td>
          <td className={styles.meta}>
            <SpineTextFont selectedValue={metaData.spineTextFontFamily} />
          </td>
        </tr>
      )}

      {Boolean(metaData?.spineBackgroundColourHexCode) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Background colour:</td>
          <td className={styles.meta}>
            <div className="tailwind">
              <div className="h-7 w-7 border" style={{ backgroundColor: metaData?.spineBackgroundColourHexCode }}></div>
            </div>
          </td>
        </tr>
      )}

      {Boolean(metaData?.spineTextColourHexCode) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Text colour:</td>
          <td className={styles.meta}>
            <div className="tailwind">
              <div className="h-7 w-7 border" style={{ backgroundColor: metaData?.spineTextColourHexCode }}></div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

function SpineTextFont({ selectedValue }: { selectedValue: string }) {
  const selectedFontOption = PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS.find(
    (fontOption) => fontOption.value === selectedValue
  )

  return <>{selectedFontOption?.name ?? selectedValue}</>
}
