// @flow

import type { CustomerAddress } from '../../types'

export const SET_CUSTOMER_ADDRESS = 'SET_CUSTOMER_ADDRESS'

export type SetCustomerAddress = {|
  type: 'SET_CUSTOMER_ADDRESS',
  customerAddress: CustomerAddress
|}

export function setCustomerAddress (customerAddress: CustomerAddress): SetCustomerAddress {
  return {
    type: SET_CUSTOMER_ADDRESS,
    customerAddress
  }
}
