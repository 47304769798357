// @flow
import type { Country } from '../types'
import countryFormat from './countryFormat'

export function sortAndFormatCountryNames (countries: Country[]): Country[] {
  const formattedCountries = countries.map(function (country, key) {
    const formattedName = countryFormat(country.name)
    return [formattedName, { name: formattedName, isoCode: country.isoCode }]
  })

  formattedCountries.sort()

  const sortedCountries = []
  formattedCountries.forEach(function (country) {
    const index = formattedCountries.indexOf(country)
    sortedCountries[index] = formattedCountries[index][1]
  })

  return sortedCountries
}
