// @flow
import type { InProgressOrder, CustomerAddress, LocalStorageOrder } from '../../types'
import { selectAppVersion } from '../../selectors/appSettings'
import { saveOrderInLocalStorage } from './localStorage'

export function saveUnfinishedOrder (order: InProgressOrder, customer: CustomerAddress, deliveryCountry: string) {
  const orderToSave = buildLocalStorageOrder(order, customer, deliveryCountry)

  saveOrderInLocalStorage(orderToSave)
}

function buildLocalStorageOrder (inProgressOrder: InProgressOrder, customer: CustomerAddress, deliveryCountry: string): LocalStorageOrder {
  return {
    customer,
    deliveryCountry,
    id: inProgressOrder.id,
    merchantOrderId: inProgressOrder.merchantOrderId,
    itemsToBeCreated: inProgressOrder.itemsToBeCreated,
    timestamp: Date.now(),
    version: selectAppVersion()
  }
}
