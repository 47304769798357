// @flow

import type {
  MultiAssetBasketItem as BasketItemType,
  ChangeSelectedAttributeData,
  DispatchFunc,
  AppState
} from '../../../../types'

import Radium from 'radium'
import { connect } from 'react-redux'
import {
  BASKET_ITEM_LOGGER_ID,
  uploadMOFArtworkFromUrl,
  removeBasketItem,
  changeSelectedAttribute,
  changeQuantity,
  setCurrentPreviewItem,
  uploadMOFArtworkFromFile,
  changeSelectedPrintAreaForBasketItem,
  removeBasketItemArtwork,
  onSelectMOFImageLibraryImage
} from '../../../../actions/manualOrderForm'

import {
  getArtworkStatus,
  getTemplateStatus,
  getArtworkErrorMessage,
  getArtworkTransformationStatusById,
  getProductTemplates
} from '../../../../selectors/images'
import MultiAssetBasketItemController from './MultiAssetBasketItemController'
import type {
  MultiAssetBasketItemOwnProps,
  MultiAssetBasketItemDispatchProps,
  MultiAssetBasketItemStateProps
} from './MultiAssetBasketItemProps'
import { getRsaaStatusProps } from '../../../../selectors/rsaa'
import { GET_V4_PRODUCT_DETAILS, SEARCH_PRODUCTS_BY_SKU_LIST } from '../../../../actions/catalogue'
import { buildRsaaActionId } from '../../../../helpers/rsaa'
import { pushRedirect } from '../../../../actions/router'
import { getV4ProductDetailsBySku, selectCatalogueItemBySku } from '../../../../selectors/catalogue'
import { buildImageId } from '../../../../actions/images'
import { getSelectedPrintAreaForBasketItem } from '../../../../selectors/manualOrderForm'
import type { Artwork } from '../../../../types/images'

function mapDispatchToProps(dispatch: DispatchFunc): MultiAssetBasketItemDispatchProps {
  return {
    removeBasketItem: (id: number) => {
      dispatch(removeBasketItem(id))
    },
    removeBasketItemArtwork: (basketItemId: number, printArea: string) => {
      dispatch(removeBasketItemArtwork(basketItemId.toString(), printArea))
    },
    changeSelectedAttribute: ({ basketItem, catalogueItem, name, value }: ChangeSelectedAttributeData) => {
      dispatch(changeSelectedAttribute({ basketItem, catalogueItem, name, value }))
    },
    changeQuantity: (quantity: number, basketItemId: number) => {
      dispatch(changeQuantity(quantity, basketItemId))
    },
    redirect: (address: string) => {
      dispatch(pushRedirect(address))
    },
    changeSelectedPrintAreaForBasketItem: (itemId: number, selectedPrintArea: string) => {
      dispatch(changeSelectedPrintAreaForBasketItem(itemId.toString(), selectedPrintArea))
    },
    setCurrentPreviewItem: (item: BasketItemType) => {
      dispatch(setCurrentPreviewItem(item))
    },
    uploadArtworkFromFile: (itemId: number, file: File, printAreaName: string, isTilingEnabled: boolean) => {
      dispatch(uploadMOFArtworkFromFile({ file, itemId, printAreaName, isTilingEnabled }))
    },
    uploadArtworkFromUrl: (itemId: number, url: string, printAreaName: string, isTilingEnabled: boolean) => {
      dispatch(uploadMOFArtworkFromUrl(url, itemId, printAreaName, isTilingEnabled))
    },
    onSelectImageLibraryImage: ({
      artwork,
      basketItemId,
      printAreaName,
      isTilingEnabled
    }: {|
      artwork: Artwork,
      basketItemId: number,
      printAreaName: string,
      isTilingEnabled: boolean
    |}) => {
      dispatch(onSelectMOFImageLibraryImage({ artwork, basketItemId, printAreaName, isTilingEnabled }))
    }
  }
}

function mapStateToProps(state: AppState, ownProps: MultiAssetBasketItemOwnProps): MultiAssetBasketItemStateProps {
  const productDetailsActionId = buildRsaaActionId(GET_V4_PRODUCT_DETAILS, ownProps.item.sku)
  const catalogueDataActionId = buildRsaaActionId(SEARCH_PRODUCTS_BY_SKU_LIST, BASKET_ITEM_LOGGER_ID)
  const imageId = buildImageId(ownProps.item.id.toString(), ownProps.item.selectedPrintArea)
  return {
    catalogueItem: selectCatalogueItemBySku(state, ownProps.item.sku),
    templateStatus: getTemplateStatus(state, ownProps.item.sku, ownProps.item.selectedAttributes),
    templates: getProductTemplates(state, ownProps.item.sku, ownProps.item.selectedAttributes),
    imageStatus: getArtworkStatus(state, imageId),
    artworkTransformationStatus: getArtworkTransformationStatusById(state, imageId),
    selectedPrintArea: getSelectedPrintAreaForBasketItem(state, ownProps.item.id),
    artworkErrorMessage: getArtworkErrorMessage(state, imageId),
    v4ProductDetailsStatus: getRsaaStatusProps(state, productDetailsActionId),
    v4ProductDetails: getV4ProductDetailsBySku(state, ownProps.item.sku),
    catalogueDataRsaaStatus: getRsaaStatusProps(state, catalogueDataActionId)
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Radium(MultiAssetBasketItemController)): any)
