// @flow

import { getCsvItemTemplates } from '../../selectors/csvUpload'
import { getArtworkByItemId } from '../../selectors/images'
import { fetchPricingAndShippingForCsvOrder } from './fetchPricingAndShippingForCsvOrder'
import { buildImageId, fetchTemplate, initArtworkTransformations } from '../images'
import { getV4ProductDetails } from '../catalogue'
import { updateCsvItemUrl } from './uploadArtwork'
import { getCsvOrderItem, getProductBySku } from '../../selectors/csvUpload/csvUpload'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue/catalogue'
import { updateCsvItemPrintAreas } from './updateCsvItemPrintAreas'

import type {
  Dictionary,
  DispatchFunc,
  GetStateFunc,
  FormattedCsvRow,
  FormattedOrderItem,
  ThunkAsync
} from '../../types'
import { addDummyImagesForNonImageProducts } from './addDummyImagesForNonImageProducts'

export function onChangeProductDetails(
  updatedOrder: FormattedCsvRow,
  orderItemBeforeUpdate: FormattedOrderItem,
  newSku: string,
  newAttributes: Dictionary<string>
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const { id: itemId, sku: prevSku, selectedAttributes: prevAttributes } = orderItemBeforeUpdate

    if (prevSku !== newSku) {
      await dispatch(getV4ProductDetails(newSku))
    }

    const orderItemAfterUpdate = getCsvOrderItem(getState(), itemId)
    const newSkuProductDetails = getV4ProductDetailsBySku(getState(), newSku)
    const newSkuCatalogueDetails = getProductBySku(getState(), newSku)

    if (!orderItemAfterUpdate || !newSkuProductDetails || !newSkuCatalogueDetails) {
      return
    }

    if (newSkuCatalogueDetails.noImageProduct) {
      return dispatch(addDummyImagesForNonImageProducts({ [orderItemAfterUpdate.id]: orderItemAfterUpdate }))
    }

    dispatch(updateCsvItemPrintAreas(orderItemAfterUpdate, newSkuProductDetails))

    const isNewTemplateNeeded = prevSku !== newSku || prevAttributes !== newAttributes

    if (isNewTemplateNeeded && orderItemAfterUpdate && orderItemAfterUpdate.printAreaImageUrls) {
      dispatch(fetchPricingAndShippingForCsvOrder(updatedOrder.id))
      Object.keys(newSkuProductDetails.printAreas).forEach((printAreaName) => {
        dispatch(
          updateOrderItemImages({
            orderId: updatedOrder.id,
            itemId,
            sku: newSku,
            selectedAttributes: newAttributes,
            printAreaName
          })
        )
      })
    }
  }
}

type UpdateOrderItemImagesProps = {|
  orderId: string,
  itemId: string,
  sku: string,
  selectedAttributes: Dictionary<string>,
  printAreaName: string
|}

function updateOrderItemImages({
  orderId,
  itemId,
  sku,
  selectedAttributes,
  printAreaName
}: UpdateOrderItemImagesProps): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    await dispatch(fetchTemplate(sku, selectedAttributes))

    const imageId = buildImageId(itemId, printAreaName)
    const templates = getCsvItemTemplates(getState(), itemId)
    const artwork = getArtworkByItemId(getState(), imageId)

    if (templates && artwork) {
      dispatch(initArtworkTransformations({ artwork, templates, itemId: imageId, printAreaName }))
      dispatch(updateCsvItemUrl(artwork.originalImageUrl, itemId, printAreaName, artwork.originalImageUrl))
    }
  }
}
