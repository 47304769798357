import { RegisterReducerActionTypeEnum } from '../enums'
import { RegisterFormDataInterface } from '../interfaces'
import { RegisterReducerActionType } from '../types'

export function registerReducer(
  state: RegisterFormDataInterface,
  action: RegisterReducerActionType
): RegisterFormDataInterface {
  switch (action.type) {
    case RegisterReducerActionTypeEnum.SetEmail:
      return {
        ...state,
        email: {
          ...state.email,
          value: action?.payload?.email ?? '',
          dirty: true,
          error: action?.payload?.validity?.valid ? null : 'Please enter a valid email',
          availability: null,
          valid: action?.payload?.validity?.valid
        }
      }
    case RegisterReducerActionTypeEnum.SetEmailAvailability:
      return {
        ...state,
        email: {
          ...state.email,
          availability: action.payload.emailAvailability ?? null,
          error: action.payload.error
        }
      }
    case RegisterReducerActionTypeEnum.SetPassword:
      const trimmedPassword = action?.payload?.password?.trim() ?? ''
      return {
        ...state,
        password: {
          ...state.password,
          value: trimmedPassword,
          dirty: true,
          error:
            action?.payload?.validity?.valid && trimmedPassword !== ''
              ? null
              : 'Please enter a password with more than 6 characters'
        }
      }
    case RegisterReducerActionTypeEnum.SetFullName:
      const trimmedFullName = action?.payload?.fullName?.trim() ?? ''
      return {
        ...state,
        fullName: {
          ...state.fullName,
          value: trimmedFullName === '' ? '' : action?.payload?.fullName ?? '',
          dirty: true,
          error: action?.payload?.validity?.valid && trimmedFullName !== '' ? null : 'Please enter your name'
        }
      }
    case RegisterReducerActionTypeEnum.SetCompanyName:
      return {
        ...state,
        companyName: {
          ...state.companyName,
          value: action.payload.companyName ?? '',
          dirty: true,
          error: null
        }
      }
    case RegisterReducerActionTypeEnum.SetMarketingWhyCreatingAccount:
      return {
        ...state,
        marketingWhyCreatingAccount: {
          ...state.marketingWhyCreatingAccount,
          value: action.payload.marketingWhyCreatingAccount ?? '',
          dirty: true,
          error: action.payload.error ?? null
        }
      }
    case RegisterReducerActionTypeEnum.SetMarketingPriorityToday:
      return {
        ...state,
        marketingPriorityToday: {
          ...state.marketingPriorityToday,
          value: action.payload.marketingPriorityToday ?? '',
          dirty: true,
          error: action.payload.error ?? null
        }
      }
    case RegisterReducerActionTypeEnum.SetCountryName:
      return {
        ...state,
        countryName: {
          ...state.countryName,
          value: action?.payload?.countryName ?? '',
          dirty: true,
          error: action?.payload?.validity?.valid ? null : 'Please select a country'
        }
      }
    case RegisterReducerActionTypeEnum.SetBillingCurrency:
      return {
        ...state,
        billingCurrency: {
          ...state.billingCurrency,
          value: action.payload.billingCurrency ?? '',
          dirty: true,
          error: action?.payload?.validity?.valid ? null : 'Please select a currency'
        }
      }
    case RegisterReducerActionTypeEnum.SetAgreedTerms:
      return {
        ...state,
        agreedTerms: {
          ...state.agreedTerms,
          value: action?.payload?.agreedTerms ?? false,
          dirty: true,
          error: action?.payload?.agreedTerms ? null : 'Please agree to the terms of use'
        }
      }
    default:
      throw new Error('Unhandled action type in register reducer')
  }
}
