import React, { useState } from 'react'
import toast from 'react-hot-toast'

import { Panel } from './Panel.component'
import { createToast } from '../../Toast'
import { StatusEnum } from '../../../enums'
import { updateSalesChannelDetails } from '../../../helpers'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import PackingSlip, { PACKING_SLIP_ALLOWED_FILE_TYPES } from '../../PackingSlip'
import { FetchErrorInterface, SalesChannelInterface } from '../../../interfaces'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../../SalesChannelConfigure/constants'
import { ImageLibraryImageInterface, useMerchantService, useSplitToggle } from '../../../hooks'

interface SalesChannelPackingSlipSettingPropsInterface {
  isLoading: boolean
  salesChannel: SalesChannelInterface
  setPackingSlipUpdateStatus: (status: StatusEnum) => void
}

export function SalesChannelPackingSlipSetting({
  isLoading,
  salesChannel,
  setPackingSlipUpdateStatus
}: SalesChannelPackingSlipSettingPropsInterface) {
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  async function handleUploadPackingUpdate(imageLibraryImage: ImageLibraryImageInterface) {
    setIsImageLibraryModalOpen(false)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS)
    setPackingSlipUpdateStatus(StatusEnum.Loading)

    if (isPDFSplitOn && !PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      createToast({
        content: `${
          imageLibraryImage.file_type
        } is not supported for packing slips. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setPackingSlipUpdateStatus(StatusEnum.Error)
      return
    }

    if (!isPDFSplitOn && imageLibraryImage.mime_type !== 'image/png') {
      createToast({
        content: `A packing slip needs to be a png`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setPackingSlipUpdateStatus(StatusEnum.Error)
      return
    }

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: imageLibraryImage.public_id,
        salesChannelId: salesChannel.id,
        salesChannelName: salesChannel.description,
        defaultFulfillment: salesChannel.defaultFulfillment
      })
      await mutateMerchantDetails()
      createToast({
        heading: 'Updated successfully',
        id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS,
        type: 'success'
      })
      setPackingSlipUpdateStatus(StatusEnum.Success)
      window.analytics.track('Added default packing slip: config')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to update packing slip',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setPackingSlipUpdateStatus(StatusEnum.Error)
    }
  }

  async function removePackingSlip() {
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS)
    setPackingSlipUpdateStatus(StatusEnum.Loading)

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: null,
        salesChannelId: salesChannel.id,
        salesChannelName: salesChannel.description,
        defaultFulfillment: salesChannel.defaultFulfillment
      })
      await mutateMerchantDetails()
      createToast({
        heading: 'Updated successfully',
        id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS,
        type: 'success'
      })
      setPackingSlipUpdateStatus(StatusEnum.Success)
      window.analytics.track('Removed default packing slip: config')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to remove packing slip',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setPackingSlipUpdateStatus(StatusEnum.Error)
    }
  }

  return (
    <PackingSlipPanel>
      <PackingSlip
        isImageLibraryModalOpen={isImageLibraryModalOpen}
        isLoading={isLoading}
        packingSlipImageId={salesChannel.packingSlipImageId}
        onOpenImageLibrary={() => window.analytics.track('Image library opened for packing slip: Sales channel config')}
        handleUploadPackingUpdate={handleUploadPackingUpdate}
        removePackingSlip={removePackingSlip}
        setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
      />
    </PackingSlipPanel>
  )
}

function PackingSlipPanel({ children }: { children: React.ReactNode }) {
  return (
    <Panel
      id="channel-packing-slip"
      className="border-t px-0"
      title={<h3 className="mt-0">Default packing slip</h3>}
      subtitle={
        <span className="mt-4 max-w-lg text-gray-600">
          Included only for global prints and wall art shipped from UK, EU, US &amp; AU.
          <br />
          Slips are printed black &amp; white on A4 or US letter.
        </span>
      }
    >
      {children}
    </Panel>
  )
}
