import { APP_ENVIRONMENTS } from '../../../constants'
import { CopyDetailsValueType } from '../types'
import ConfirmationModal from '../../ConfirmationModal'

interface CopyDetailsModalInterface {
  copyOption: CopyDetailsValueType | null
  open: boolean
  onClose: () => void
  onContinue: (option: CopyDetailsValueType | null) => void
}

export function CopyDetailsModal({ copyOption, open, onClose, onContinue }: CopyDetailsModalInterface) {
  function onOpenChange(status: boolean) {
    if (!status) {
      onClose()
    }
  }

  function formatSelectedOption(copyOption: CopyDetailsValueType | null) {
    if (!copyOption) {
      return ''
    }

    switch (copyOption) {
      case 'copy-all':
        return 'image and product'
      case 'copy-image':
        return 'image'
      case 'copy-product':
        return 'product'
      default:
        const copyUnhandledCase: never = copyOption
        if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
          console.warn(`Unable to format ${copyUnhandledCase}. Add a new case to fix this.`)
        }
        return null
    }
  }

  const formattedOption = formatSelectedOption(copyOption)

  return (
    <ConfirmationModal
      open={open}
      setOpen={onOpenChange}
      title={`Copy ${formattedOption} to other variants`}
      closeButton={{ text: 'Cancel' }}
      continueButton={{ text: 'Copy to all variants' }}
      onCancel={() => onOpenChange(false)}
      onContinue={() => onContinue(copyOption)}
    >
      <p>This will replace any currently selected {formattedOption} on the other variants.</p>
    </ConfirmationModal>
  )
}
