function WooCommerceLogoIcon({ className = 'w-8' }: { className?: string }) {
  return (
    <svg
      className={className}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 780 180"
    >
      <path
        fill="#7d57a4"
        fillRule="evenodd"
        d="M38.66,14.44H245.44A23.66,23.66,0,0,1,269.1,38.11V117a23.65,23.65,0,0,1-23.66,23.65H171.28l10.15,24.95-44.8-24.95h-98A23.66,23.66,0,0,1,15,117V38.11A23.67,23.67,0,0,1,38.66,14.44Z"
      />
      <path
        fill="#fff"
        d="M27.79,35.87a8.56,8.56,0,0,1,6.5-3.21q7.91-.55,9.08,7.46,4.88,32.49,10.45,55.16L76.58,52q3.09-5.86,7.81-6.2,6.84-.48,8.55,8.71A193.68,193.68,0,0,0,102.77,90q4.1-39.54,13.69-57.17a8,8,0,0,1,6.86-4.6,9.13,9.13,0,0,1,6.55,2.05,8,8,0,0,1,3.17,6,8.8,8.8,0,0,1-1,5q-6.08,11.28-10,37.51-3.87,25.32-2.86,39.61a12.63,12.63,0,0,1-1.21,6.8,6.53,6.53,0,0,1-5.53,3.75c-2.74.2-5.47-1.09-8.22-3.85q-14.48-14.85-23-44.19-10,20-15,29.92-9.15,17.6-15.68,18.05-4.24.3-7.22-7.12-8-20.37-17.16-78.82A8.88,8.88,0,0,1,27.79,35.87Z"
      />
      <path
        fill="#fff"
        d="M251,52.19a24.1,24.1,0,0,0-16.6-12,27.76,27.76,0,0,0-5.64-.6q-14.92,0-24.38,15.53a55.18,55.18,0,0,0-8.07,29.25q0,12,5,20.57a24.05,24.05,0,0,0,16.61,12,26.93,26.93,0,0,0,5.63.61q15.07,0,24.37-15.53A55.88,55.88,0,0,0,256,72.6q0-12-5-20.41ZM237.9,81Q235.77,91.2,230,96.37q-4.57,4.11-8.38,3.35T215.51,93a26.82,26.82,0,0,1-1.83-9.45,37.16,37.16,0,0,1,.76-7.62,34.26,34.26,0,0,1,5.48-12.34q5.18-7.61,10.82-6.39,3.81.77,6.1,6.7a26.79,26.79,0,0,1,1.82,9.44A37.09,37.09,0,0,1,237.9,81Z"
      />
      <path
        fill="#fff"
        d="M185.92,52.19a24.1,24.1,0,0,0-16.6-12,27.76,27.76,0,0,0-5.64-.6q-14.93,0-24.37,15.53a55.1,55.1,0,0,0-8.08,29.25q0,12,5,20.57a24,24,0,0,0,16.61,12,26.93,26.93,0,0,0,5.63.61q15.08,0,24.38-15.53A56,56,0,0,0,191,72.6q0-12-5-20.41ZM172.82,81q-2.13,10.22-7.92,15.39-4.58,4.11-8.38,3.35T150.43,93a26.82,26.82,0,0,1-1.83-9.45,37.16,37.16,0,0,1,.76-7.62,34.6,34.6,0,0,1,5.48-12.34Q160,56,165.66,57.22q3.81.77,6.09,6.7a26.81,26.81,0,0,1,1.83,9.44,37.09,37.09,0,0,1-.76,7.62Z"
      />
      <path d="M295.37,51.3Q285,61.61,285,77.43,285,94.5,295.28,105t26.89,10.46A42.13,42.13,0,0,0,333,113.77V97a34.34,34.34,0,0,1-9.46,1.59q-8.25,0-13.2-5.54T305.4,78q0-8.83,4.91-14.56a15.63,15.63,0,0,1,12.36-5.72A38.19,38.19,0,0,1,333,59.22V42.37A44.41,44.41,0,0,0,321.66,41Q305.77,41,295.37,51.3ZM367.45,41Q353.08,41,345,50.56t-8,26.66q0,18.54,8,28.36t23.26,9.83q14.75,0,22.72-9.83T399,78q0-17.79-8.1-27.38T367.45,41Zm8.23,54a9.24,9.24,0,0,1-8.23,4.4,8.34,8.34,0,0,1-7.6-4.4q-2.61-4.38-2.61-17.53Q357.24,57,367.57,57q11,0,11,20.71Q378.54,90.57,375.68,95Zm74.69-52-3.72,15.88q-1.45,6.1-2.71,12.5l-2.08,11q-3-16.47-8.13-39.35H409.58l-9.07,70.7H418.6l4.92-48.72,12.35,48.72H448.8L460.58,65l5.11,48.62H484.6L475,43Zm86.52,0-3.72,15.88q-1.45,6.1-2.71,12.5l-2.08,11q-3-16.47-8.14-39.35H496.1L487,113.66h18.09L510,64.94l12.36,48.72h12.92L547.1,65l5.11,48.62h18.91L561.54,43ZM595.6,85.7h17V71h-17V58h19.54V43H576.5v70.7h38.77V98.57H595.6Zm73.47-11.15a19.27,19.27,0,0,0-4.86-25.77Q656.4,43,642.91,43H620.65v70.7h19.11V81.46h.31l15.44,32.2h20.18L660.43,81.78A18.42,18.42,0,0,0,669.07,74.55Zm-29.31-1.14V56.62q6.79.16,9.73,2.28t2.94,7Q652.43,73.2,639.76,73.41ZM683.34,51.3q-10.4,10.31-10.4,26.13,0,17.07,10.3,27.52t26.89,10.46A42.14,42.14,0,0,0,721,113.77V97a34.34,34.34,0,0,1-9.46,1.59q-8.26,0-13.21-5.54T693.36,78q0-8.83,4.92-14.56a15.63,15.63,0,0,1,12.36-5.72A38.24,38.24,0,0,1,721,59.22V42.37A44.41,44.41,0,0,0,709.63,41Q693.74,41,683.34,51.3Zm62,47.27V85.7h17V71h-17V58h19.54V43H726.23v70.7H765V98.57Z" />
    </svg>
  )
}

export { WooCommerceLogoIcon }
