// @flow
import type { SelectMofShippingMethod } from '../types'
export const SELECT_MOF_SHIPPING_METHOD = 'SELECT_MOF_SHIPPING_METHOD'

export function selectMofShippingMethod (shippingMethodName: ?string): SelectMofShippingMethod {
  return {
    type: SELECT_MOF_SHIPPING_METHOD,
    shippingMethodName
  }
}
