// @flow

import type {
  RsaaStatus,
  InProgressOrder,
  Country,
  AppState,
  MultiAssetBasketItem,
  CustomerAddress,
  DispatchFunc
} from '../../types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { RouterHistory } from 'react-router'
import { Route, Redirect, Switch } from 'react-router-dom'
import * as ct from '../../data/constants'
import PaymentDetailsMessage from './components/PaymentDetailsMessage'
import SelectProduct from './components/SelectProduct'
import OrderSelectCountry from './OrderSelectCountry'
import OrderBasket from './OrderBasket'
import OrderAddress from './OrderAddress'
import OrderSummary from './OrderSummary'
import { isEmailValid, isPostcodeValid, isPostcodeRequired, isAddressValid } from '../../helpers/address'
import { getItemCategories } from '../../actions/catalogue'
import instruments from '../../helpers/instruments'
import {
  saveUnfinishedOrder,
  removeUnfinishedOrderFromLocalStorage,
  fetchV4ProductDetailsForAllBasketItems,
  getUnfinishedOrder,
  clearOrderData,
  fetchCountries,
  MOF_ORDER_LOGGER_ID
} from '../../actions/manualOrderForm'
import { getUserHasToSetUpCard, getMaybeUser } from '../../selectors/user'
import Breadcrumbs from './components/Breadcrumbs'
import { Container } from '../../components/layout'
import { getAreAllBasketItemsValid } from '../../selectors/manualOrderForm'
import { buildRsaaActionId } from '../../helpers/rsaa'
import { getRsaaStatus } from '../../selectors/rsaa'
import { CREATE_V4_ORDER } from '../../actions/orders'
import { IDLE } from '../../data/rsaa'
import { resetRsaaStatus } from '../../actions/rsaa'
import { selectRouterHistory } from '../../selectors/router'
import { fetchPreviewTemplatesForAllBasketItems } from '../../actions/manualOrderForm/fetchPreviewTemplatesForAllBasketItems'
import { fetchCatalogueDataForAllBasketItems } from '../../actions/manualOrderForm/fetchCatalogueDataForAllBasketItems'
import type { ComponentType } from 'react'

type Props = {|
  allItemsValid: boolean,
  hasToSetUpCard: boolean,
  addressIsValid: boolean,
  postcodeIsValid: boolean,
  emailIsValid: boolean,
  itemsToBeCreated: MultiAssetBasketItem[],
  customer: CustomerAddress,
  deliveryCountry: string,
  location: Location,
  countries: Country[],
  inProgressOrder: InProgressOrder,
  saveUnfinishedOrder: typeof saveUnfinishedOrder,
  orderCreationStatus: RsaaStatus,
  history: RouterHistory,
  resetOrderCreationStatus: () => void,
  clearOrderData: () => void,
  fetchCountries: () => void,
  getItemCategories: () => void,
  getUnfinishedOrder: () => void,
  fetchCatalogueDataForAllBasketItems: () => void,
  fetchPreviewTemplatesForAllBasketItems: () => void,
  fetchV4ProductDetailsForAllBasketItems: () => void
|}

class ManualOrderForm extends Component<Props> {
  async componentDidMount() {
    instruments.timer(ct.ORDER_TIMER_NAME).addCategory('MOF')
    instruments.timer(ct.ORDER_TIMER_NAME).start()

    if (this.props.itemsToBeCreated.length === 0) {
      await this.props.getUnfinishedOrder()
    }

    if (this.props.countries.length === 0) {
      this.props.fetchCountries()
    }

    this.props.getItemCategories()

    this.props.fetchPreviewTemplatesForAllBasketItems()
    this.props.fetchV4ProductDetailsForAllBasketItems()
    this.props.fetchCatalogueDataForAllBasketItems()

    if (!this.isLocationValid()) {
      this.redirectToCountrySelection()
    }

    window.onpopstate = this.onBrowserHistoryChange
    window.addEventListener('beforeunload', this.handleUnloadEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnloadEvent)
    this.handleUnloadEvent()
  }

  handleUnloadEvent = () => {
    const { inProgressOrder, customer, deliveryCountry } = this.props

    if (this.props.orderCreationStatus.status === IDLE && inProgressOrder.itemsToBeCreated.length > 0) {
      saveUnfinishedOrder(inProgressOrder, customer, deliveryCountry)
    }

    this.props.resetOrderCreationStatus()
  }

  onBrowserHistoryChange = () => {
    if (!this.isLocationValid()) {
      removeUnfinishedOrderFromLocalStorage()
      this.props.clearOrderData()
      this.redirectToCountrySelection()
      this.props.resetOrderCreationStatus()
    }
  }

  redirectToCountrySelection = () => this.props.history.replace('/orders/create/select-country')

  isLocationValid = (): boolean => {
    const { deliveryCountry, allItemsValid, postcodeIsValid, emailIsValid, addressIsValid, location } = this.props

    if (this.props.orderCreationStatus.status !== IDLE && !location.pathname.includes('summary')) {
      return false
    }

    if (location.pathname.indexOf('select-item') > -1) {
      return Boolean(deliveryCountry)
    } else if (location.pathname.indexOf('add-image') > -1) {
      return Boolean(deliveryCountry)
    } else if (location.pathname.indexOf('address') > -1) {
      return Boolean(deliveryCountry) && allItemsValid
    } else if (location.pathname.indexOf('summary') > -1) {
      return allItemsValid && postcodeIsValid && emailIsValid && addressIsValid
    }

    return true
  }

  get showBreadcrumbs(): boolean {
    return this.props.orderCreationStatus.status === IDLE
  }

  get isCropperVisible(): boolean {
    return this.props.location.pathname.includes('/orders/create/image-preview')
  }

  render(): React$Node {
    return (
      <div style={{ paddingTop: 'var(--size-md)' }}>
        <Container size={this.isCropperVisible ? 'full' : 'xl'}>
          {this.props.hasToSetUpCard && <PaymentDetailsMessage />}
          {this.showBreadcrumbs && <Breadcrumbs pathname={this.props.location.pathname} />}
        </Container>

        <Switch>
          <Route path="/orders/create/add-image" exact component={OrderBasket} />
          <Route path="/orders/create/select-country" exact component={OrderSelectCountry} />
          <Route path="/orders/create/select-item" exact component={SelectProduct} />
          <Route path="/orders/create/address" exact component={OrderAddress} />
          <Route path="/orders/create/summary" exact component={OrderSummary} />
          <Redirect to="/orders/create/select-country" exact />
        </Switch>
      </div>
    )
  }
}

function mapStateToProps(state: AppState) {
  const { inProgressOrder, customer, deliveryCountry } = state.manualOrderForm

  const allItemsValid = getAreAllBasketItemsValid(state)
  const postcodeIsRequired = isPostcodeRequired(deliveryCountry)

  const emailIsValid = isEmailValid(customer.email)
  const postcodeIsValid = postcodeIsRequired ? isPostcodeValid(deliveryCountry, customer.postcode) : true

  const maybeUser = getMaybeUser(state)

  const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
  return {
    customer,
    countries: state.manualOrderForm.countries,
    itemsToBeCreated: inProgressOrder.itemsToBeCreated,
    orderFormState: state.manualOrderForm,
    orderError: inProgressOrder.orderError,
    deliveryCountry: state.manualOrderForm.deliveryCountry,
    allItemsValid,
    postcodeIsValid,
    emailIsValid,
    addressIsValid: isAddressValid(state.manualOrderForm.customer),
    hasToSetUpCard: maybeUser ? getUserHasToSetUpCard(state) : false,
    inProgressOrder,
    orderCreationStatus: getRsaaStatus(state, createOrderActionId),
    history: selectRouterHistory(state)
  }
}

function mapDispatchToProps(dispatch: DispatchFunc) {
  return {
    clearOrderData: () => dispatch(clearOrderData()),
    fetchCountries: () => dispatch(fetchCountries()),
    getItemCategories: () => dispatch(getItemCategories()),
    getUnfinishedOrder: () => dispatch(getUnfinishedOrder()),
    fetchCatalogueDataForAllBasketItems: () => dispatch(fetchCatalogueDataForAllBasketItems()),
    fetchPreviewTemplatesForAllBasketItems: () => dispatch(fetchPreviewTemplatesForAllBasketItems()),
    fetchV4ProductDetailsForAllBasketItems: () => dispatch(fetchV4ProductDetailsForAllBasketItems()),
    resetOrderCreationStatus: () => {
      const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
      dispatch(resetRsaaStatus(createOrderActionId))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ManualOrderFormSplit): ComponentType<{||}>)

function ManualOrderFormSplit(props: Props) {
  return <ManualOrderForm {...props} />
}
