import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Button from '../../Button'
import Skeleton from '../../Skeleton'
import { InsertDataType } from '../../../../types'
import { createErrorToast, createToast } from '../../Toast'
import { useBrandingDetails, useOrderDetail } from '../../../hooks'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { BrandedInsertsOrderModal } from '../../BrandedInsertsOrderModal'

const ORDER_EDIT_INSERT_TOAST_ID = 'prodigiOrderEditBrandedInsertToast'

// TODO: Also add to src/v2/components/OrderEditDisplay/OrderEditDisplay.component.tsx
// TODO: Update all components with real data
export function OrderEditBrandedInserts() {
  const { id: orderId } = useParams<{ id: string }>()
  const { mutateOrderDetails } = useOrderDetail(orderId)
  const { brandingDetails, brandingDetailsFetchError, isLoadingBrandingDetails } = useBrandingDetails()

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isOrderBrandedInsertsModalOpen, setIsOrderBrandedInsertsModalOpen] = useState(false)

  if (brandingDetailsFetchError) {
    return <OrderEditInsertsError brandingDetailsFetchError={brandingDetailsFetchError} />
  }

  if (isLoadingBrandingDetails || !brandingDetails) {
    return <OrderEditInsertsLoading />
  }

  const hasInserts = true

  async function handleSaveBrandedInserts(newInserts: InsertDataType[]) {
    console.log({ newInserts })
    toast.dismiss(ORDER_EDIT_INSERT_TOAST_ID)
    setSaveStatus('loading')

    try {
      await fetch('https://jsonplaceholder.typicode.com/todos/1')
      await mutateOrderDetails()
      setSaveStatus('success')
      handleOpenChange(false)
      createToast({ type: 'success', heading: 'Inserts updated' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createErrorToast({
        errorCode: errorResponse.status ?? 0,
        heading: 'Failed to update inserts',
        id: ORDER_EDIT_INSERT_TOAST_ID
      })
      setSaveStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setSaveStatus('idle')
      toast.dismiss(ORDER_EDIT_INSERT_TOAST_ID)
    }
    setIsOrderBrandedInsertsModalOpen(isOpen)
  }

  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        <div className="ml-auto">
          <Button variant="secondary" size="sm" onClick={() => handleOpenChange(true)}>
            {hasInserts ? 'View & edit' : 'Add inserts'}
          </Button>
        </div>
      </div>

      {/* TODO: Pass branding details to display content */}
      {hasInserts ? <HasInsertsContent /> : <NoInsertsContent />}

      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={[
          {
            id: 'postcard',
            imageUrl:
              'https://dev-services.pwinty.com/image-library/transform/6578060e5f5f40a5ceaaba2f/image?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXRoIjoiL3RyYW5zZm9ybS82NTc4MDYwZTVmNWY0MGE1Y2VhYWJhMmYvaW1hZ2UiLCJleHAiOjE3MzIzNDc4NTB9.48iPFYO6zja2O2EQGbMVt-Vy6aFmWL3DR_pP3h2vREM&preview=false'
          }
        ]}
        insertSets={undefined}
        saveStatus={saveStatus}
        open={isOrderBrandedInsertsModalOpen}
        setOpen={handleOpenChange}
        onSave={handleSaveBrandedInserts}
        onClose={() => handleOpenChange(false)}
      />
    </div>
  )
}

// Depending on OMS response, we might need to add useBrandingDetails here and a loading/error state
function HasInsertsContent() {
  return (
    <div className="mt-6 text-black">
      <div>4 inserts, £2.50</div>
      <div className="mt-2 text-gray-700">
        Round packaging sticker, Rectangular product sticker, Postcard, Packing slip (black & white)
      </div>
    </div>
  )
}

function NoInsertsContent() {
  return <div className="mt-6 text-black">Add a packing slip, packaging sticker or marketing insert.</div>
}

function OrderEditInsertsError({
  brandingDetailsFetchError
}: {
  brandingDetailsFetchError: FetchErrorInterface<unknown>
}) {
  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        {/* {orderBrandedInserts.length > 0 && ( */}
        {/* <div className="ml-auto">
          <Button variant="secondary" size="sm" theme="danger">
            Remove
          </Button>
        </div> */}
        {/* )} */}
      </div>

      <div className="mt-6 text-magenta-800">
        {/* {orderBrandedInserts.length > 0 && (
          <>
            {orderBrandedInserts.length === 1 ? (
              <>{orderBrandedInserts.length} insert</>
            ) : (
              <>{orderBrandedInserts.length} inserts</>
            )}{' '}
            assigned to this order.{' '}
          </>
        )} */}
        An error occurred while loading inserts (code {brandingDetailsFetchError.status ?? '0'})
      </div>
    </div>
  )
}

function OrderEditInsertsLoading() {
  return (
    <div className="border bg-white p-6">
      <h2 className="mt-0 text-lg">Inserts</h2>

      <Skeleton className="mt-6 h-16" />
    </div>
  )
}
