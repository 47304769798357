// @flow
import { SCALE_TYPE } from '@prodigi-group/components-image-editor'
import {
  uploadImageFromUrl,
  FINISH_IMAGE_UPLOAD_FROM_URL,
  uploadImageFromFile,
  FINISH_IMAGE_UPLOAD_FROM_FILE,
  initArtworkTransformations,
  INIT_ARTWORK_TRANSFORMATIONS_SUCCESS,
  buildImageId
} from '../images'
import type { DispatchFunc, GetStateFunc, Artwork, ThunkAsync } from '../../types'
import type { StartMofImageUpload, FinishMofImageUpload } from '../types'
import { saveUnfinishedOrder } from './saveUnfinishedOrder'
import { selectBasketItemById } from '../../selectors/manualOrderForm'
import { getProductTemplates } from '../../selectors/images'
import type { ArtworkTransformations } from '../../types/images'
import type { MultiAssetTemplates } from '../../types/templates'
import { calcScaleFactorAdjustment } from '../../helpers/imageEditor/calcScaleFactorAdjustment'
import { convertPrintPxPositionToUserUnit } from '../../helpers/imageEditor/convertPrintPxPositionToUserUnit'
import { buildPigUrl } from '../../helpers/imageEditor/buildPigUrl'
import { IMAGE_SIZING } from '../../data/imageSizing'
import { updateCroppedImageUrl } from './updateCroppedImageUrl'
import { getCatalogueItemBySku } from '../../selectors/catalogue/catalogue'
import { logLe, LOG_LEVEL } from '../logging'
import { PRINT_AREA_NAME } from '../../data/printAreaName'
import type { ImageSizing } from '../../types/imageSizing'
import { finishSelectImageLibraryImage } from '../images/finishSelectImageLibraryImage'

// $FlowFixMe: TypeScript file
import { shouldFitImageInTemplate, transformImage } from '../../v2/helpers'

export const INIT_IMAGE = 'INIT_IMAGE'
export const IMAGE_URL_UPDATED_NO_UPLOAD = 'IMAGE_URL_UPDATED_NO_UPLOAD'
export const START_MOF_IMAGE_UPLOAD = 'START_MOF_IMAGE_UPLOAD'
export const FINISH_MOF_IMAGE_UPLOAD = 'FINISH_MOF_IMAGE_UPLOAD'

type UploadMOFArtworkFromFileProps = {|
  file: File,
  itemId: number,
  printAreaName: string,
  isTilingEnabled: boolean,
  allowConcurrentRequests?: boolean
|}

export function uploadMOFArtworkFromUrl(
  url: string,
  itemId: number,
  printAreaName: string,
  isTilingEnabled: boolean
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({ type: START_MOF_IMAGE_UPLOAD, meta: { itemId, printAreaName } })
    const imageId = buildImageId(itemId.toString(), printAreaName)
    const result = await dispatch(uploadImageFromUrl(url, [imageId]))

    if (result.type === FINISH_IMAGE_UPLOAD_FROM_URL) {
      dispatch(onSuccessfulUpload(result.payload, itemId, printAreaName, isTilingEnabled))
    }
  }
}

export function uploadMOFArtworkFromFile({
  file,
  itemId,
  printAreaName,
  isTilingEnabled,
  allowConcurrentRequests = true
}: UploadMOFArtworkFromFileProps): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(startMofImageUpload(itemId, printAreaName))

    const imageId = buildImageId(itemId.toString(), printAreaName)
    const result = await dispatch(uploadImageFromFile({ file, id: imageId, allowConcurrentRequests }))

    if (result.type === FINISH_IMAGE_UPLOAD_FROM_FILE) {
      dispatch(onSuccessfulUpload(result.payload, itemId, printAreaName, isTilingEnabled))
    }
  }
}

export function onSelectMOFImageLibraryImage({
  artwork,
  basketItemId,
  printAreaName,
  isTilingEnabled
}: {|
  artwork: Artwork,
  basketItemId: number,
  printAreaName: string,
  isTilingEnabled: boolean
|}): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const imageId = buildImageId(basketItemId.toString(), printAreaName)
    dispatch(finishSelectImageLibraryImage({ artwork, imageId }))
    dispatch(onSuccessfulUpload(artwork, basketItemId, printAreaName, isTilingEnabled))
  }
}

function onSuccessfulUpload(
  artwork: Artwork,
  itemId: number,
  printAreaName: string,
  isTilingEnabled: boolean
): (dispatch: DispatchFunc, getState: GetStateFunc) => Promise<void> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(finishMofImageUpload(itemId, artwork, printAreaName))

    await dispatch(initImage(itemId, artwork, printAreaName))

    const mof = getState().manualOrderForm

    saveUnfinishedOrder(mof.inProgressOrder, mof.customer, mof.deliveryCountry)
  }
}

function startMofImageUpload(itemId: number, printAreaName: string): StartMofImageUpload {
  return {
    type: START_MOF_IMAGE_UPLOAD,
    meta: {
      itemId,
      printAreaName
    }
  }
}

function finishMofImageUpload(itemId: number, artwork: Artwork, printAreaName: string): FinishMofImageUpload {
  return {
    type: FINISH_MOF_IMAGE_UPLOAD,
    itemId,
    artwork,
    printAreaName
  }
}

export function initImage(itemId: number, artwork: Artwork, printAreaName: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const basketItem = selectBasketItemById(getState(), itemId)
    if (!basketItem) {
      // the item could have been removed from the basket
      return
    }

    const templates = getProductTemplates(getState(), basketItem.sku, basketItem.selectedAttributes)

    if (!templates) {
      return
    }

    const imageId = buildImageId(itemId.toString(), printAreaName)

    const catalogueItem = getCatalogueItemBySku(getState(), basketItem.sku)
    const shouldFitImage = Boolean(catalogueItem?.category && shouldFitImageInTemplate(catalogueItem.category))
    const imageSizingForCategory = shouldFitImage ? IMAGE_SIZING.FIT : IMAGE_SIZING.FILL

    const transformationsActionResult = await dispatch(
      initMofArtworkTransformations({
        artwork,
        basketItemId: basketItem.id.toString(),
        templates,
        imageId,
        printAreaName,
        sizing: imageSizingForCategory
      })
    )

    const shouldAttemptFitSizing =
      transformationsActionResult.type === INIT_ARTWORK_TRANSFORMATIONS_SUCCESS &&
      imageSizingForCategory === IMAGE_SIZING.FIT &&
      artwork.mimeType !== 'application/pdf'

    if (shouldAttemptFitSizing) {
      // At this point, the artwork transformations have been generated according to IMAGE_SIZING.FIT but
      // the croppedImageUrl would be according to IMAGE_SIZING.FIT, this call attempts to fix that
      await dispatch(
        attemptFitSizingImageUrlGeneration({
          artwork,
          transformations: transformationsActionResult.transformations,
          imageId,
          itemId,
          printAreaName,
          templates,
          basketItemId: basketItem.id.toString(),
          sku: basketItem.sku
        })
      )
    }

    const mof = getState().manualOrderForm
    saveUnfinishedOrder(mof.inProgressOrder, mof.customer, mof.deliveryCountry)
  }
}

type InitMofArtworkProps = {|
  artwork: Artwork,
  templates: MultiAssetTemplates,
  basketItemId: string,
  imageId: string,
  sizing?: ImageSizing,
  printAreaName?: string
|}

function initMofArtworkTransformations({
  artwork,
  templates,
  basketItemId,
  imageId,
  sizing = IMAGE_SIZING.FILL,
  printAreaName = PRINT_AREA_NAME.DEFAULT
}: InitMofArtworkProps) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const transformationsActionResult = await dispatch(
      initArtworkTransformations({ artwork, templates, itemId: imageId, printAreaName, sizing })
    )
    if (transformationsActionResult.type === INIT_ARTWORK_TRANSFORMATIONS_SUCCESS) {
      await dispatch({
        type: INIT_IMAGE,
        basketItemId,
        artworkTransformations: transformationsActionResult.transformations,
        printAreaName
      })
    }
    return transformationsActionResult
  }
}

function attemptFitSizingImageUrlGeneration({
  basketItemId,
  sku,
  templates,
  transformations,
  itemId,
  imageId,
  artwork,
  printAreaName
}: {|
  basketItemId: string,
  sku: string,
  templates: MultiAssetTemplates,
  transformations: ArtworkTransformations,
  itemId: number,
  imageId: string,
  artwork: Artwork,
  printAreaName: string
|}) {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    try {
      const pigUrlForFitSizing = generatePigUrl({
        artwork,
        originalImageUrl: artwork.originalImageUrl,
        printAreaName,
        templates,
        transformations
      })

      if (!artwork.imageLibraryId) {
        throw Error('SKIPPED DEFAULT FIT SIZING: no image library ID')
      }
      const imageLibraryId = artwork.imageLibraryId

      if (!templates.printAreas[printAreaName].orientations[transformations.orientation]) {
        throw Error('SKIPPED DEFAULT FIT SIZING: no template')
      }
      const template = templates.printAreas[printAreaName].orientations[transformations.orientation]
      const transformedImage = await transformImage({
        artwork,
        imageLibraryId,
        template,
        transformations,
        preview: false
      })

      if (!pigUrlForFitSizing) {
        const errorMessage = `SKIPPED DEFAULT FIT SIZING: Could not generate pigUrl for 'fit' sizing for basketItem ${basketItemId}, SKU ${sku}`
        throw Error(errorMessage)
      }
      // Makes sure basket item has the updated pigUrlForFitSizing with the fit image that is submitted when creating an order
      dispatch(
        updateCroppedImageUrl({
          itemId,
          croppedImageUrl: pigUrlForFitSizing,
          selectedPrintArea: printAreaName,
          transformImageUrl: transformedImage.url
        })
      )
    } catch (error) {
      console.warn(error)
      dispatch(logLe(error.message, null, LOG_LEVEL.ERROR))

      // We use IMAGE_SIZING.FILL as a fail-safe since MOF sets sizing to fillPrintArea when creating order at the time of writing
      // See https://github.com/Prodigi-Group/pwinty-dashboard-react/blob/develop/docs/rotation.md
      const imageSizingIfFitFails = IMAGE_SIZING.FILL
      // Resets artwork transformations to use IMAGE_SIZING.FILL sizing since fitting failed
      // This case shouldn't really happen
      await dispatch(
        initMofArtworkTransformations({
          artwork,
          templates,
          basketItemId,
          imageId,
          printAreaName,
          sizing: imageSizingIfFitFails
        })
      )
    }
  }
}

function generatePigUrl({
  artwork,
  transformations,
  templates,
  printAreaName,
  originalImageUrl
}: {|
  artwork: Artwork,
  transformations: ArtworkTransformations,
  templates: MultiAssetTemplates,
  printAreaName: string,
  originalImageUrl: string
|}) {
  const template = templates.printAreas[printAreaName]?.orientations[transformations.orientation]

  if (!template) {
    return
  }

  const artworkDimensionsInPx = {
    width: artwork.artworkWidth,
    height: artwork.artworkHeight
  }

  const scaleFactorAdjustment = calcScaleFactorAdjustment(
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    template.printDpi,
    transformations.borderFactor,
    transformations.borderScale
  )
  const scaleFactorInPigFriendlyFormat = (transformations.scaleFactor * scaleFactorAdjustment) / 100
  const pigPosition = convertPrintPxPositionToUserUnit(
    transformations.position,
    transformations.borderScale,
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    template.printDpi
  )

  const imageEditorTransformations = {
    scaleFactor: scaleFactorInPigFriendlyFormat,
    scaleType: SCALE_TYPE.PRINT_SIZE,
    position: pigPosition,
    positionUnit: transformations.borderScale,
    rotationAngle: transformations.rotationAngle,
    borderWidth: transformations.borderFactor,
    borderUnit: transformations.borderScale,
    isTiled: transformations.isTiled
  }

  return buildPigUrl(
    originalImageUrl,
    imageEditorTransformations,
    artworkDimensionsInPx,
    template.printResolution,
    template.cropRectangle,
    template.printDpi
  )
}

export function imageUrlUpdatedNoUpload(
  id: number,
  url: string,
  printAreaName: string
): {| id: number, printAreaName: string, type: string, url: string |} {
  return {
    type: IMAGE_URL_UPDATED_NO_UPLOAD,
    id,
    url,
    printAreaName
  }
}
