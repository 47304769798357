import Skeleton from '../../Skeleton'

export function LoadingOrderItem({ className = '', dataTest }: { className?: string; dataTest?: string }) {
  return (
    <tr
      className={`block border-collapse border-t border-solid first:border-none sm:table-row ${className} `}
      data-test={dataTest}
    >
      <td className="block pl-4 pt-6 sm:table-cell sm:pl-8 sm:pt-6 sm:pb-5">
        <Skeleton className="w-1/5 sm:w-1/2" />
        <Skeleton className="mt-2 hidden w-1/5 sm:block sm:w-1/2" />
      </td>
      <td className="block pl-4 pt-2 sm:table-cell sm:pt-6 sm:pb-5">
        <Skeleton className="w-1/2 sm:w-3/4" />
        <Skeleton className="mt-2 hidden w-3/12 sm:block sm:w-1/2" />
      </td>
      <td className="block pl-4 pt-2 sm:table-cell sm:pt-6 sm:pb-5">
        <Skeleton className="w-2/5 sm:w-3/4" />
        <Skeleton className="mt-2 hidden w-3/12 sm:block sm:w-1/3" />
      </td>
      <td className="block pl-4 pr-8 pt-2 pb-6 align-top sm:table-cell sm:pt-6 sm:pb-5">
        <Skeleton className="w-1/2 sm:w-3/4" />
      </td>
      <td className="block pl-4 pr-8 pt-2 pb-6 align-top sm:table-cell sm:pt-6 sm:pb-5 sm:text-right">
        <Skeleton className="w-1/5 sm:ml-[50%] sm:w-1/2" />
      </td>
    </tr>
  )
}
