export function TileComponent({
  content,
  heading,
  icon,
  linkAddress,
  linkText
}: {
  content: string
  heading: string
  icon?: React.ReactNode
  linkAddress: string
  linkText: string
}) {
  return (
    <div className="col-span-1 flex flex-col justify-between border bg-white p-8">
      <div>
        {icon && <div className="mb-8">{icon}</div>}
        <h2 className="mb-4 mt-0">{heading}</h2>
        <p className="mb-12">{content}</p>
      </div>
      <a className="btn btn-secondary md:m-win w-fit" rel="noreferrer noopener" target="_blank" href={linkAddress}>
        {linkText}
      </a>
    </div>
  )
}
