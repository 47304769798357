// @flow
import { connect } from 'react-redux'
import type { ComponentType } from 'react'
import { getFormattedCountries } from '../../../../selectors/manualOrderForm'
import { updateCsvOrderAddress } from '../../../../actions/csvUpload'
import CsvAddressModalController from './CsvAddressModalController'
import type { AppState, DispatchFunc, Country, CustomerAddress } from '../../../../types'

type StateProps = {|
  countries: Country[]
|}

type OwnProps = {|
  orderId: string,
  address: CustomerAddress,
  closeModal: () => void
|}

type DispatchProps = {|
  onSave: (address: CustomerAddress) => void
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    countries: getFormattedCountries(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc, ownProps: OwnProps): DispatchProps {
  return {
    onSave: (address: CustomerAddress) => {
      window.analytics.track('Update address - csv uploader', {label: 'csv uploader'})
      dispatch(updateCsvOrderAddress(address, ownProps.orderId))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CsvAddressModalController): ComponentType<OwnProps>)
