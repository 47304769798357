import { ReactNode } from 'react'

import { cn } from '../../../helpers'
import FormItem from '../../FormItem'
import Checkbox from '../../Checkbox'
import TextField from '../../TextField'
import { useMerchantService } from '../../../hooks'
import { InsertSetSalesChannelType } from '../../../interfaces'

export type InsertSetSettingsType = {
  name: string
  salesChannels: InsertSetSalesChannelType[]
}

export function InsertsSettingsPanel({
  insertSetSettings,
  onChangeInsertSetSettings
}: {
  insertSetSettings: InsertSetSettingsType
  onChangeInsertSetSettings: (newInsertSetSettings: InsertSetSettingsType) => void
}) {
  const { merchantDetails } = useMerchantService()

  return (
    <div className="flex flex-1 flex-col gap-16">
      <InsertSettingsSection
        className="py-0"
        title="Name of set"
        description="Name this set of inserts for easy reference when ordering."
      >
        <FormItem
          className="max-w-[350px]"
          labelClassName="py-0 mt-0"
          labelText="Name"
          inputField={
            <TextField
              maxLength={250}
              required
              value={insertSetSettings.name}
              onChange={(event) => {
                onChangeInsertSetSettings({ ...insertSetSettings, name: event.target.value })
              }}
            />
          }
        />
      </InsertSettingsSection>

      {merchantDetails && merchantDetails.salesChannels.length > 0 && (
        <InsertSettingsSection
          className="overflow-x-auto"
          title="Usage"
          description={
            <>
              <p>Select which orders should include these inserts by default.</p>
              <p className="mt-4">
                You can always add, remove or change individual inserts on a per order basis if required.
              </p>
            </>
          }
        >
          <ul className="flex flex-col gap-6">
            {merchantDetails.salesChannels.map((salesChannel) => {
              const isSalesChannelInSet = insertSetSettings.salesChannels.some(
                (insertSetSalesChannel) => insertSetSalesChannel.id === salesChannel.id
              )

              return (
                <li key={salesChannel.id}>
                  <Checkbox
                    checked={isSalesChannelInSet}
                    id={salesChannel.id}
                    label={salesChannel.description}
                    labelClassName="text-black text-base"
                    onChange={(event) => {
                      const shouldIncludeSalesChannel = event.target.checked

                      if (shouldIncludeSalesChannel) {
                        onChangeInsertSetSettings({
                          ...insertSetSettings,
                          salesChannels: [...insertSetSettings.salesChannels, { id: salesChannel.id }]
                        })
                      } else {
                        onChangeInsertSetSettings({
                          ...insertSetSettings,
                          salesChannels: insertSetSettings.salesChannels.filter(
                            (insertSetSalesChannel) => salesChannel.id !== insertSetSalesChannel.id
                          )
                        })
                      }
                    }}
                  />
                </li>
              )
            })}
          </ul>
        </InsertSettingsSection>
      )}
    </div>
  )
}

function InsertSettingsSection({
  className,
  title,
  description,
  children
}: {
  className?: string
  title: ReactNode
  description: ReactNode
  children: ReactNode
}) {
  return (
    <div className={cn('flex flex-col gap-8 py-16 md:flex-row md:gap-16', className)}>
      <div className="max-w-[275px] shrink-0">
        <div className="text-lg font-medium">{title}</div>
        <div className="mt-4 text-gray-600">{description}</div>
      </div>

      <div className="flex-1">{children}</div>
    </div>
  )
}
