import { HEADER_NAMES } from '../constants'
import { DictionaryInterface } from '../../../interfaces'

export function buildVariantTableColumnVisibility(
  rotateable: boolean,
  parentCategory: { slug: string; title: string }
) {
  const columnVisibility: DictionaryInterface<boolean> = {}

  if (!rotateable) {
    columnVisibility[HEADER_NAMES.ORIENTATION] = false
  }

  if (
    ['wall-art', 'technology', 'mens-clothing', 'womens-clothing', 'kids-clothing', 'accessories/bags'].some((prefix) =>
      parentCategory.slug.startsWith(prefix)
    )
  ) {
    columnVisibility[HEADER_NAMES.STYLE] = false
  }

  return columnVisibility
}
