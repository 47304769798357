import { Link, useLocation } from 'react-router-dom'

import { OrderStatusEnum } from '../../../enums'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { SANDBOX } from '../../../constants/environments.const'
import { useOrdersOverview } from '../../../hooks'

export function OrdersNotificationBanner() {
  const { pathname, search } = useLocation()
  const { orderOverviewResponse } = useOrdersOverview()

  const numOrders = orderOverviewResponse?.data?.requiresAttention ?? 0
  const requiresAttentionSearchParams = new URLSearchParams()
  const statuses = [OrderStatusEnum.REQUIRES_ATTENTION, OrderStatusEnum.DRAFT]
  requiresAttentionSearchParams.set(QUERY_PARAMS.ORDERS.STATUS, statuses.join(','))

  const currentUrl = pathname + search
  const urlForOrdersRequiringAttention = `${ROUTE_PATHS.ORDERS}?${requiresAttentionSearchParams.toString()}`
  const hasRequiresAttentionFilterApplied = currentUrl === urlForOrdersRequiringAttention

  if (process.env.REACT_APP_ENV === SANDBOX || hasRequiresAttentionFilterApplied || numOrders < 1) {
    return null
  }

  return (
    <div
      className={`flex w-full flex-col items-center justify-between gap-2 space-y-4 border border-cyan-600 bg-cyan-100 p-6 md:flex-row md:space-y-0`}
    >
      <div className="items-center text-cyan-600">
        <p className="pb-4 font-medium">
          You have {numOrders} pending order{numOrders > 1 && 's'} that require{numOrders < 2 && 's'} your attention
        </p>
        <p>Some details need to be completed before we can fulfil {numOrders > 1 ? 'these orders' : 'this order'}.</p>
      </div>

      <Link to={urlForOrdersRequiringAttention} className="btn btn-alert btn-secondary md:m-win whitespace-nowrap">
        Show pending order{numOrders > 1 && 's'}
      </Link>
    </div>
  )
}
