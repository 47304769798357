import { useDispatch } from 'react-redux'
import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { APP_ENVIRONMENTS } from '../../constants'
import { MobileNav } from './components/MobileNav.component'
import { DesktopTopNav } from './components/DesktopTopNav.component'
import { getUnfinishedOrder } from '../../../actions/manualOrderForm'
import { DesktopSideNav } from './components/DesktopSideNav.component'

import styles from './Navigation.module.css'

export const CONTENT_CONTAINER_ID = 'content-container'

export default function Navigation({ children }: { children: ReactNode }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUnfinishedOrder())
  }, [dispatch])

  return (
    <div className={styles.outerContainer} style={{ display: 'flex', isolation: 'isolate' }}>
      <div className="tailwind" style={{ height: '100%', position: 'sticky', top: 0 }}>
        <nav className="hidden h-full md:block">
          <DesktopSideNav />
        </nav>
      </div>
      <div
        id={CONTENT_CONTAINER_ID}
        className={styles.mainContentContainer}
        data-test="content-container"
        style={{
          flexGrow: '1',
          backgroundColor: 'var(--color-grey-10)',
          width: '100%',
          height: '100%'
        }}
      >
        <ScrollContentToTop />
        <div
          className="tailwind"
          style={{
            position: 'sticky',
            isolation: 'isolate',
            zIndex: 30,
            top: 0,
            backgroundColor: '#FFFFFF',
            height: 'var(--top-nav-height)'
          }}
        >
          <nav className="h-full md:hidden">
            <MobileNav />
          </nav>
          <nav className="hidden h-full md:block">
            <DesktopTopNav />
          </nav>
        </div>

        {process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX && (
          <div className="tailwind">
            <div className="bg-purple-200 p-6 text-center text-black">
              <strong>Sandbox environment</strong> - For development and testing purposes only, orders will not be
              processed.
            </div>
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

function ScrollContentToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    document.getElementById(CONTENT_CONTAINER_ID)?.scrollTo(0, 0)
  }, [pathname])

  return null
}
