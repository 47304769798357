import { useDispatch, useSelector } from 'react-redux'

import { isNoImageProduct } from '../../../../v2/helpers'
import { getCsvOrderByOrderItemId } from '../../../../selectors/csvUpload'
import SelectProductModal from '../../../../v2/components/SelectProductModal'
import { selectDeliveryCountry } from '../../../../selectors/manualOrderForm'
import { removeCsvItemArtwork, selectProductForCsvOrder } from '../../../../actions/csvUpload'
import { getProductFileCompatibilityData } from '../../../../v2/helpers/getProductFileCompatibilityData.helper'
import { ProductAdditionalDataInterface, SelectedProductInterface } from '../../../../v2/components/SelectProduct'

interface CsvProductSearchV2PropsInterface {
  orderId: string
  orderItemId: string
  sku: string
  defaultAttributes: Record<string, string>
  isModalOpen: boolean
  closeModal: () => void
  onError: (message: string) => void
  openImageModal: (orderItemId: string) => void
}

export function CsvProductSearchV2({
  orderId,
  orderItemId,
  sku,
  defaultAttributes,
  isModalOpen,
  closeModal,
  openImageModal
}: CsvProductSearchV2PropsInterface) {
  const dispatch = useDispatch()

  const mofSelectedDeliveryCountry: string = useSelector(selectDeliveryCountry)
  const csvSelectedDeliveryCountry: string = useSelector((state) => getCsvOrderByOrderItemId(state, orderItemId))
    ?.customer?.countryCode
  const countryCode = csvSelectedDeliveryCountry || mofSelectedDeliveryCountry

  function handleSelectProduct(
    selectedProduct: SelectedProductInterface,
    { productDetails }: ProductAdditionalDataInterface
  ) {
    const productFileCompatibilityData = getProductFileCompatibilityData({
      productType: selectedProduct.searchResultData.productType
    })

    if (productFileCompatibilityData) {
      /**
       * Since product has compatibility data, it might mean that the current artwork
       * may not be supported on the newly selected product, so remove the artwork and
       * let the user upload again
       */
      Object.keys(productDetails.product.printAreas).forEach((printArea) => {
        dispatch(removeCsvItemArtwork(orderItemId, printArea))
      })
    }

    dispatch(
      selectProductForCsvOrder(
        orderId,
        orderItemId,
        selectedProduct.searchResultData,
        selectedProduct.selectedAttributes || {}
      )
    )
    closeModal()

    if (!isNoImageProduct(selectedProduct.searchResultData.productType)) {
      openImageModal(orderItemId)
    }
  }

  return (
    <SelectProductModal
      countryCode={countryCode}
      defaultSearchQuery={sku}
      defaultSelectedAttributes={defaultAttributes}
      open={isModalOpen}
      setOpen={(isOpen) => {
        if (!isOpen) {
          closeModal()
        }
      }}
      onSelectProductWithAdditionalData={handleSelectProduct}
    />
  )
}
