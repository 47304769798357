// @flow
import {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR
} from '../data/rsaa'
import {
  SET_RSAA_STATUS,
  RESET_RSAA_STATUS,
  SAVE_IN_FLIGHT_REQUEST_CONTROLLER,
  HARD_RESET_RSAA_STATUS,
  ADD_RSAA_ACTION
} from '../actions/rsaa'
import type {
  ResetRsaaStatus,
  SetRsaaStatus,
  AddRsaaAction
} from '../actions/types/rsaa'

import type { RsaaState, Action } from '../types'
import { buildRsaaActionId } from '../helpers/rsaa'

const defaultStatus = {
  status: IDLE,
  statusMessage: '',
  ignore: false,
  statusCode: null,
  errorPayload: null
}

const initialState = {
  config: {},
  inFlightRequestControllers: {}
}

function rsaa (state: RsaaState = initialState, action: Action): RsaaState {
  switch (action.type) {
    case ADD_RSAA_ACTION: {
      const currentAction: AddRsaaAction = action
      const firstActionId = buildRsaaActionId(currentAction.types[0], currentAction.loggerId)
      const secondActionId = buildRsaaActionId(currentAction.types[1], currentAction.loggerId)
      const thirdActionId = buildRsaaActionId(currentAction.types[2], currentAction.loggerId)

      return {
        ...state,
        config: {
          ...state.config,
          [firstActionId]: {
            actionId: firstActionId,
            relatedStatus: LOADING
          },
          [secondActionId]: {
            actionId: firstActionId,
            relatedStatus: SUCCESS
          },
          [thirdActionId]: {
            actionId: firstActionId,
            relatedStatus: ERROR
          }
        },
        [firstActionId]: currentAction.initialStatus
      }
    }

    case SET_RSAA_STATUS: {
      const currentAction: SetRsaaStatus = action
      return {
        ...state,
        [currentAction.actionId]: { ...action.status }
      }
    }

    case RESET_RSAA_STATUS: {
      const currentAction: ResetRsaaStatus = action

      if (!state[currentAction.actionId]) {
        return state
      }

      const currentStatus = state[currentAction.actionId].status

      if (currentStatus === LOADING) {
        return {
          ...state,
          [currentAction.actionId]: {
            ...state[currentAction.actionId],
            ignore: true
          }
        }
      }

      return {
        ...state,
        [currentAction.actionId]: defaultStatus
      }
    }

    case HARD_RESET_RSAA_STATUS: {
      if (state[action.ref]) {
        return {
          ...state,
          [action.ref]: defaultStatus
        }
      }
      return state
    }

    case SAVE_IN_FLIGHT_REQUEST_CONTROLLER: {
      return {
        ...state,
        inFlightRequestControllers: {
          ...state.inFlightRequestControllers,
          [action.requestType]: action.abortController
        }
      }
    }

    default: {
      return state
    }
  }
}

export default rsaa
