// @flow

import type { ThunkAsync, DispatchFunc, GetStateFunc, FormattedCsvRow, Dictionary, Action } from '../../types'
import type { RemoveCsvPackingSlip } from '../types'
import { values } from '../../helpers/dictionary'
// $FlowFixMe: TypeScript file
import { uploadURLToImageLibrary } from '../../v2/helpers/uploadURLToImageLibrary.helper'
// $FlowFixMe: TypeScript file
import { FetchErrorInterface } from '../../v2/interfaces'

export const CSV_PACKING_SLIP_UPLOAD = 'CSV_PACKING_SLIP_UPLOAD'
export const CSV_PACKING_SLIP_UPLOAD_SUCCESS = 'CSV_PACKING_SLIP_UPLOAD_SUCCESS'
export const CSV_PACKING_SLIP_UPLOAD_ERROR = 'CSV_PACKING_SLIP_UPLOAD_ERROR'
export const REMOVE_CSV_PACKING_SLIP = 'REMOVE_CSV_PACKING_SLIP'

export function uploadPackingSlipsFromUrlsV2(formattedOrders: Dictionary<FormattedCsvRow>): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    values(formattedOrders).forEach(async (order) => {
      if (order.packingSlip) {
        const isIncorrectFileType = order.packingSlip.includes('.jpeg') || order.packingSlip.includes('.jpg')

        if (isIncorrectFileType) {
          return
        }

        dispatch({
          type: CSV_PACKING_SLIP_UPLOAD,
          payload: {
            index: order.id
          }
        })

        try {
          const result = await uploadURLToImageLibrary({
            url: order.packingSlip,
            shouldSaveToLibrary: false,
            type: 'packing'
          })

          if (result.upload_status !== 'success' || result.mime_type !== 'image/png') {
            dispatch({
              type: CSV_PACKING_SLIP_UPLOAD_ERROR,
              meta: {
                orderId: order.id
              },
              payload: {
                errorMessage: 'Failed to upload'
              }
            })
            return
          }

          dispatch({
            type: CSV_PACKING_SLIP_UPLOAD_SUCCESS,
            payload: {
              index: order.id,
              packingSlipUrl: result.url
            }
          })
        } catch (error) {
          const errorResponse: FetchErrorInterface<{| detail?: string, imageLibraryId?: string |}> = error
          const errorMessage = errorResponse.responseBodyJson?.detail ?? ''
          const errorCode = `${errorResponse.status ?? '0'}-${errorResponse.responseBodyJson?.imageLibraryId ?? '0'}`

          dispatch({
            type: CSV_PACKING_SLIP_UPLOAD_ERROR,
            meta: {
              orderId: order.id
            },
            payload: {
              errorMessage: errorMessage + '-' + errorCode
            }
          })
        }
      }
    })
  }
}

export function removeCsvPackingSlip(index: string): RemoveCsvPackingSlip {
  return {
    type: REMOVE_CSV_PACKING_SLIP,
    index
  }
}

export function uploadCsvPackingSlipFromUrlV2(packingSlipUrl: string, orderId: string): Action {
  return {
    type: CSV_PACKING_SLIP_UPLOAD_SUCCESS,
    payload: {
      index: orderId,
      packingSlipUrl
    }
  }
}
