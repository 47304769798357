import { useState } from 'react'

import {
  BrandingDataInterface,
  BrandingDetailsType,
  useBrandingDetails,
  useMerchantBranding,
  useSplitToggle
} from '../../../v2/hooks'
import { formatCost } from '../../../v2/helpers'
import Button from '../../../v2/components/Button'
import Skeleton from '../../../v2/components/Skeleton'
import { InsertDataType } from '../../../v2/interfaces'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { BrandedInsertsOrderModal } from '../../../v2/components/BrandedInsertsOrderModal'

type OrderSummaryBrandedInsertsPropsType = {
  orderBrandedInserts: InsertDataType[]
  onChangeOrderBrandedInserts: (newBrandedInserts: InsertDataType[]) => void
}

export function OrderSummaryBrandedInserts({
  orderBrandedInserts,
  onChangeOrderBrandedInserts
}: OrderSummaryBrandedInsertsPropsType) {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })
  const { brandingDetails, isLoadingBrandingDetails, brandingDetailsFetchError } = useBrandingDetails()
  const { merchantBrandingResponse, isLoadingMerchantBranding, merchantBrandingFetchError } = useMerchantBranding()

  const [isOrderBrandedInsertsModalOpen, setIsOrderBrandedInsertsModalOpen] = useState(false)

  if (!isBrandingSettingsOn) {
    return null
  }

  if (brandingDetailsFetchError) {
    return (
      <OrderSummaryInsertsError
        code={`BDFE-${brandingDetailsFetchError.status ?? 0}`}
        orderBrandedInserts={orderBrandedInserts}
        onRemove={() => onChangeOrderBrandedInserts([])}
      />
    )
  }

  if (merchantBrandingFetchError) {
    return (
      <OrderSummaryInsertsError
        code={`MBFE-${merchantBrandingFetchError.status ?? 0}`}
        orderBrandedInserts={orderBrandedInserts}
        onRemove={() => onChangeOrderBrandedInserts([])}
      />
    )
  }

  if (isLoadingBrandingDetails || !brandingDetails || isLoadingMerchantBranding || !merchantBrandingResponse) {
    return <OrderSummaryInsertsLoading />
  }

  const hasInserts = orderBrandedInserts.length > 0
  const merchantBranding = merchantBrandingResponse.data

  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        <div className="ml-auto">
          <Button variant="secondary" size="sm" onClick={() => setIsOrderBrandedInsertsModalOpen(true)}>
            {hasInserts ? 'View & edit' : 'Add inserts'}
          </Button>
        </div>
      </div>

      {hasInserts ? (
        <HasInsertsContent brandingDetails={brandingDetails} orderBrandedInserts={orderBrandedInserts} />
      ) : (
        <NoInsertsContent />
      )}

      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={orderBrandedInserts}
        insertSets={merchantBranding.insertSets}
        open={isOrderBrandedInsertsModalOpen}
        setOpen={setIsOrderBrandedInsertsModalOpen}
        onSave={(newBrandedInserts) => {
          const brandedInsertsWithImageIds = newBrandedInserts.filter((insert) => Boolean(insert.imageLibraryId))
          onChangeOrderBrandedInserts(brandedInsertsWithImageIds)
          setIsOrderBrandedInsertsModalOpen(false)
        }}
        onClose={() => setIsOrderBrandedInsertsModalOpen(false)}
      />
    </div>
  )
}

function HasInsertsContent({
  brandingDetails,
  orderBrandedInserts
}: {
  brandingDetails: BrandingDataInterface
  orderBrandedInserts: InsertDataType[]
}) {
  const brandingDetailsForOrderInserts: BrandingDetailsType[] = []

  orderBrandedInserts.forEach((insert) => {
    const brandingDetailForInsert = brandingDetails.branding.find((brandingDetail) => brandingDetail.id === insert.id)
    if (!brandingDetailForInsert) {
      return
    }
    brandingDetailsForOrderInserts.push(brandingDetailForInsert)
  })

  const insertSetCost = {
    amount: brandingDetailsForOrderInserts.reduce(
      (amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount,
      0
    ),
    currency: brandingDetailsForOrderInserts[0]?.cost?.currency
  }

  const formattedInsertSetCost = formatCost({
    amount: insertSetCost.amount.toString(),
    currencyCode: insertSetCost.currency
  })

  return (
    <div className="mt-6 text-black">
      <div>
        {orderBrandedInserts.length === 1
          ? `${orderBrandedInserts.length} insert`
          : `${orderBrandedInserts.length} inserts`}
        {formattedInsertSetCost ? `, ${formattedInsertSetCost}` : null}
      </div>
      <div className="mt-2 text-gray-700">
        {brandingDetailsForOrderInserts.length > 0 ? (
          <span>{brandingDetailsForOrderInserts.map((brandingDetail) => brandingDetail.name).join(', ')}</span>
        ) : (
          <span>Branding details not found</span>
        )}
      </div>
    </div>
  )
}

function NoInsertsContent() {
  return <div className="mt-6 text-black">Add a packing slip, packaging sticker or marketing insert.</div>
}

function OrderSummaryInsertsError({
  code,
  orderBrandedInserts,
  onRemove
}: {
  code: string
  orderBrandedInserts: InsertDataType[]
  onRemove: () => void
}) {
  return (
    <div className="border bg-white p-6">
      <div className="flex items-center gap-2">
        <h2 className="mt-0 text-lg">Inserts</h2>

        {orderBrandedInserts.length > 0 && (
          <div className="ml-auto">
            <Button variant="secondary" size="sm" theme="danger" onClick={onRemove}>
              Remove
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6 text-magenta-800">
        {orderBrandedInserts.length > 0 && (
          <>
            {orderBrandedInserts.length === 1 ? (
              <>{orderBrandedInserts.length} insert</>
            ) : (
              <>{orderBrandedInserts.length} inserts</>
            )}{' '}
            assigned to this order.{' '}
          </>
        )}
        An error occurred while loading inserts (code {code})
      </div>
    </div>
  )
}

function OrderSummaryInsertsLoading() {
  return (
    <div className="border bg-white p-6">
      <h2 className="mt-0 text-lg">Inserts</h2>

      <Skeleton className="mt-6 h-16" />
    </div>
  )
}
