// @flow
import React, { Component } from 'react'
import Radium from 'radium'
import Button from '../../../components/Button'
import mediaQueries from '../../../components/styles/mediaQueries'
import FontAwesome from 'react-fontawesome'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { Link } from 'react-router-dom'

type Props = {|
  inProgress: boolean,
  error: boolean,
  back: any
|}

class FetchingShippingOptions extends Component<Props> {
  getStyles () {
    return {
      button: {
        minWidth: '200px',
        marginTop: '15px',
        marginRight: '15px',
        marginBottom: '15px',
        marginLeft: '0',
        [mediaQueries.breakpointLarge]: {
          margin: '0 auto 15px auto',
          display: 'block'
        }
      },
      box: {
        textAlign: 'center',
        marginTop: '12%',
        marginBottom: '12%'
      },
      status: {
        fontSize: 'var(--sizes-large)',
        fontFamily: 'var(--font-thin)',
        marginTop: '15px',
        marginRight: '0',
        marginBottom: '25px',
        marginLeft: '0'
      },
      alert: {
        color: 'var(--accent-red)',
        fontFamily: 'var(--font-bold)',
        marginBottom: '1em'
      },
      process: {
        marginTop: '1%'
      },
      icon: {
        fontSize: '10rem',
        color: 'var(--accent-blue)'
      },
      link: {
        display: 'flex',
        width: 'min-content',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }
  }

  render (): React$Node {
    const styles = this.getStyles()
    const {
      back,
      inProgress,
      error
    } = this.props

    return (
      <div style={styles.box} >
        {!inProgress
          ? (
            <div>
              {error &&
              <div>
                <FontAwesome name='times-circle-o' style={{ ...styles.icon, color: 'var(--color-alert-pink)' }} />
                <p style={{ ...styles.alert, ...styles.status }}>
                  Sorry, there are no shipment options available to the selected destination for these products.
                </p>
                <Link to={back} style={styles.link}>
                  <Button primary icon='chevron-left'>Choose another destination</Button>
                </Link>
              </div>}
            </div>
          ) : (
            <LoadingIndicator />
          )}
      </div>
    )
  }
}

export default (Radium(FetchingShippingOptions): any)
