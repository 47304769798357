import { FRAMED, FRAMED_PRINTS } from './productCategories.const'
import { DictionaryInterface } from '../interfaces'

export const NO_SELECTION = 'NO_SELECTION'

export const UNAVAILABLE_PRODUCT_TYPES = {
  PHOTOBOOK_HARD_COVER: 'Photobook Hard Cover',
  PHOTOBOOK_SOFT_COVER: 'Photobook Soft Cover',
  HIDE_IN_MOF: 'Hide in MOF'
}

export const ALL = 'ALL'
export const FRAME_COLOUR = 'frameColour'
export const GLAZE = 'glaze'
export const MOUNT_COLOUR = 'mountColour'

export const BLACK = 'black'

export const DEFAULT_ATTRIBUTE_CATEGORIES: DictionaryInterface<DictionaryInterface<string>> = {
  [FRAMED]: {
    [FRAME_COLOUR]: BLACK
  },
  [FRAMED_PRINTS]: {
    [FRAME_COLOUR]: BLACK
  }
}
