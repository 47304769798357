// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Import ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="20" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.125 14.6552V21.1708C22.125 21.6877 21.7044 22.1083 21.1875 22.1083H2.8125C2.29556 22.1083 1.875 21.6877 1.875 21.1708V14.6552H0V21.1708C0 22.7216 1.26169 23.9833 2.8125 23.9833H21.1875C22.7383 23.9833 24 22.7216 24 21.1708V14.6552H22.125Z" fill="#565656"/>
      <path d="M11.9996 0.0167847L6.17383 5.8426L7.49964 7.16841L11.0621 3.60591V18.2176H12.9371V3.60591L16.4996 7.16841L17.8255 5.8426L11.9996 0.0167847Z" fill="#565656"/>
    </svg>
  )
}
