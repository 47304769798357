// @flow
import { noImageProductData, PICTURE_STANDS, SAMPLE_PACK } from '../data/noImageProducts'

function getLabImage(colour: ?string, type: string, sku: ?string): ?string {
  const databyType = noImageProductData.filter((x) => x.type.toLowerCase() === type.toLowerCase())
  let product = null
  if (type === SAMPLE_PACK || type === PICTURE_STANDS) {
    product = databyType.find((x) => x.sku === sku)
  } else if (type.toUpperCase() === 'POSTER HANGERS') {
    product = databyType.find((x) => x.colour === colour)
  } else if (type.toUpperCase() === 'ECOCAPS') {
    product = databyType.find((x) => x.type === type)
  }
  return product ? product.labImage : null
}

export default getLabImage
