import { useDebouncedCallback } from 'use-debounce'
import { forwardRef, Ref, useRef, useState, useImperativeHandle } from 'react'

import SearchField from '../../SearchField'

export type DebouncedSearchInputRefType = { clearSearchInput: () => void; focus: () => void }

interface DebouncedSearchInputPropsInterface {
  category: string | null
  defaultValue: string
  productType: string | null
  onChange: (value: string) => void
}

// With React 18, we can update this to use useDeferredValue for optimising performance
export const DebouncedSearchInput = forwardRef(
  (
    { category, defaultValue, productType, onChange }: DebouncedSearchInputPropsInterface,
    ref: Ref<DebouncedSearchInputRefType> | undefined
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [searchValue, setSearchValue] = useState(defaultValue)

    useImperativeHandle(
      ref,
      (): DebouncedSearchInputRefType => ({
        clearSearchInput() {
          setSearchValue('')
          inputRef.current?.focus()
        },
        focus() {
          inputRef.current?.focus()
        }
      }),
      []
    )

    const debouncedSearch = useDebouncedCallback((value: string) => {
      onChange(value)
    }, 450)

    function handleChange(value: string) {
      setSearchValue(value)
      debouncedSearch(value)
    }

    return (
      <SearchField
        autoFocus={window.matchMedia('(hover: hover)').matches}
        dataTest="select-product-search-input"
        ref={inputRef}
        placeholder={getSearchPlaceholder({ category, productType })}
        value={searchValue}
        onChange={(event) => handleChange(event.target.value)}
        onClearSearch={() => {
          handleChange('')
          inputRef.current?.focus()
        }}
      />
    )
  }
)
DebouncedSearchInput.displayName = 'DebouncedSearchInput'

function getSearchPlaceholder({ category, productType }: { category: string | null; productType: string | null }) {
  if (productType) {
    return `Search ${productType}`
  }

  if (category) {
    return `Search ${category}`
  }

  return 'Product name or SKU'
}
