// @flow
import { createSelector } from 'reselect'
import { isEqual } from 'lodash'
import type {
  Quote,
  RsaaStatus,
  Status,
  Dictionary,
  CatalogueItem,
  CsvItemProductDetails,
  FormattedCsvRow,
  CsvOrderUploadProgress,
  AppState,
  FormattedOrderItem,
  PackingSlipsProgress,
  Cost,
  ArtworkProgress,
  CsvFileType,
  V4ProductDetails
} from '../../types'
import { identity, returnFirstArg, returnThirdArg, returnSecondArg } from '../helpers'
import { createListOfCsvSkus } from '../../helpers/csvUpload/getItemDetails'
import { isOrderCompleted } from '../../helpers/csvUpload/checkCsvItem'
import { selectListOfProductsBySkus, selectV4ProductDetails } from '../catalogue'
import { values, entries } from '../../helpers/dictionary'
import {
  CREATE_CSV_ORDER_ERROR,
  ADD_IMAGES_TO_CSV_ORDER_ERROR,
  SUBMIT_CSV_ORDER_ERROR,
  SUBMIT_CSV_ORDER_SUCCESS
} from '../../actions/csvUpload/orderSubmissionActions'
import { IDLE, SUCCESS, LOADING } from '../../data/rsaa'
import { getProductTemplates, getAllArtworkStatus, getArtworkProgress, getTemplateStatusByIds } from '../images/images'
import type {
  GetCsvFileType,
  GetIsFetchingV3ProductDetailsForCsvOrders,
  GetV4OrderTotal,
  GetIsFetchingV4ProductDetailsForCsvOrders,
  GetIsFetchingCsvOrderQuotes,
  GetCsvOrderQuotesByOrderId,
  GetSelectedArtworkTransformationIds,
  GetIds,
  GetCsvItemDetails,
  GetCsvOrderItemsByOrderId,
  GetIsMultiselectModeOn,
  GetSelectedOrderShippingMethod,
  GetCsvItemTemplates,
  GetCsvItemProductDetails,
  GetCsvItemDetailsBySku,
  GetOrdersWithErrors,
  GetCsvOrderItem,
  GetCsvItemSku,
  GetCsvItemAttributes,
  GetCsvOrderItemsByItemIds,
  GetCsvOrderByOrderItemId,
  GetCsvOrderWithItems,
  GetCsvOrderPackingSlip,
  GetCsvOrderPackingSlipProgress,
  GetTotalSubmittedOrders,
  GetV4ProductDetailRsaaStatusesForCsvOrder,
  GetIsCsvV4OrderSubmissionSuccessful,
  GetCsvOrderItemsToSubmit,
  GetHasV4ProductDetailsForAllCsvItemsToSubmit,
  GetV4ProductDetailRsaaStatusesForCsvItemsToSubmit,
  GetV4OrderTotalByOrderId,
  GetIsUploadingImages,
  GetIsFetchingTemplates,
  GetIsUsSalesTaxCollectedByOrderId,
  GetCsvOrderBranding
} from './types'
import { selectRsaaStatus } from '../rsaa'
import { buildRsaaActionId } from '../../helpers/rsaa'
import { GET_V4_PRODUCT_DETAILS } from '../../actions/catalogue'
import { CREATE_V4_ORDER } from '../../actions/orders'
import { selectQuotes, selectCsvOrderQuoteRsaaStatusByOrderId } from '../quotes'
import { selectCurrencyCode } from '../user'
import { combineCosts } from '../../helpers/charge'
import { buildImageId, buildTemplateId } from '../../actions/images'
import type { CsvOrderBrandingType } from '../../types/csvUpload'

export function getSubmissionOrders(state: AppState): Dictionary<FormattedCsvRow> {
  const orders = values(selectUploadedOrders(state))
  const ignoredOrders = getIgnoredOrders(state)

  const reducedOrders = orders.reduce((orderAcc, order) => {
    const isIgnored = ignoredOrders.includes(order.id)

    if (!isIgnored) {
      orderAcc[order.id] = order
    }

    return orderAcc
  }, {})

  return reducedOrders
}

function selectCsvItemProductDetails(
  allProducts: Dictionary<CatalogueItem>,
  v4ProductDetails: Dictionary<V4ProductDetails>,
  orderItems: Dictionary<FormattedOrderItem>
): Dictionary<CsvItemProductDetails> {
  const skus = createListOfCsvSkus(orderItems)
  const products = selectListOfProductsBySkus(values(allProducts), skus)

  return products.reduce((productDetailAcc, product) => {
    productDetailAcc[product.sku] = {
      description: product.description,
      noImageProduct: product.noImageProduct,
      sku: product.sku,
      category: product.category,
      requiredAttributes: Object.keys(product.attributes).filter(
        (attributeName) => product.attributes[attributeName].length > 1
      ),
      printAreas: v4ProductDetails?.[product.sku]?.printAreas ?? {}
    }
    return productDetailAcc
  }, {})
}

function selectPreferredShippingMethodByOrderId(state: AppState, orderId: string): string {
  const order = state.csvUpload.uploadedOrders[orderId]

  return order ? order.preferredShippingMethod : ''
}

function selectOrderShippingMethod(order: FormattedCsvRow): string {
  return order.preferredShippingMethod
}

export function selectCsvOrder(state: AppState, orderId: string): FormattedCsvRow {
  return state.csvUpload.uploadedOrders[orderId]
}

export function selectCsvOrderItems(state: AppState): Dictionary<FormattedOrderItem> {
  return state.csvUpload.orderItems
}

export function selectOrderItemsByItemIds(
  orderItems: Dictionary<FormattedOrderItem>,
  itemIds: string[]
): Dictionary<FormattedOrderItem> {
  return values(orderItems).reduce((itemsAcc, item) => {
    const isExists = itemIds.includes(item.id)

    if (isExists) {
      itemsAcc[item.id] = item
    }
    return itemsAcc
  }, {})
}

export function selectCsvOrderItem(
  orderItems: Dictionary<FormattedOrderItem>,
  orderItemId: string
): ?FormattedOrderItem {
  return orderItems[orderItemId]
}

export function selectUploadedOrders(state: AppState): Dictionary<FormattedCsvRow> {
  return state.csvUpload.uploadedOrders
}

export function selectCsvUploadProgress(state: AppState): Dictionary<CsvOrderUploadProgress> {
  return state.csvUpload.progress
}

export function selectCsvOrderUploadProgress(
  progress: Dictionary<CsvOrderUploadProgress>,
  orderId: string
): ?CsvOrderUploadProgress {
  return progress[orderId]
}

export function selectIsCsvOrderSubmissionSuccessful(
  progress: Dictionary<CsvOrderUploadProgress>,
  ignoredOrderIds: string[]
): boolean {
  return (
    Object.keys(progress).length > 0 &&
    values(progress).every(
      (order) => order.lastActionType === SUBMIT_CSV_ORDER_SUCCESS || ignoredOrderIds.includes(order.id)
    )
  )
}

function selectIsCsvV4OrderSubmissionSuccessful(state: AppState, orders: Dictionary<FormattedCsvRow>): boolean {
  const rsaaStatuses = Object.keys(orders).map((orderId) => {
    const rsaaActionId = buildRsaaActionId(CREATE_V4_ORDER, orderId)
    return selectRsaaStatus(state, rsaaActionId)
  })
  return rsaaStatuses.every((rsaa) => rsaa.status === SUCCESS)
}

function selectOrdersWithErrors(
  allOrders: Dictionary<FormattedCsvRow>,
  progress: Dictionary<CsvOrderUploadProgress>
): Dictionary<FormattedCsvRow> {
  const errorActionTypes = [CREATE_CSV_ORDER_ERROR, ADD_IMAGES_TO_CSV_ORDER_ERROR, SUBMIT_CSV_ORDER_ERROR]

  return values(progress).reduce((orderAcc, orderProgress) => {
    if (errorActionTypes.includes(orderProgress.lastActionType)) {
      orderAcc[orderProgress.id] = allOrders[orderProgress.id]
    }

    return orderAcc
  }, {})
}

export function selectIsEveryOrderCompleted(
  uploadedOrders: Dictionary<FormattedCsvRow>,
  allProducts: Dictionary<CatalogueItem>,
  v4ProductDetails: Dictionary<V4ProductDetails>,
  artworkProgress: Dictionary<Status>,
  allOrderItems: Dictionary<FormattedOrderItem>,
  quotes: Dictionary<Quote[]>
): boolean {
  return values(uploadedOrders).every((order) => {
    if (!allProducts) {
      return false
    }

    const orderItems = selectOrderItemsByItemIds(allOrderItems, order.itemIds)
    const itemProductDetails = selectCsvItemProductDetails(allProducts, v4ProductDetails, orderItems)
    const orderQuotes = quotes[order.id] ?? []

    return isOrderCompleted(order, orderItems, itemProductDetails, artworkProgress, orderQuotes)
  })
}

export function selectProductDetails(state: AppState): Dictionary<CatalogueItem> {
  return state.csvUpload.productDetails
}

export function selectProductBySku(searchItems: Dictionary<CatalogueItem>, sku: string): ?CatalogueItem {
  return values(searchItems).find((searchItem) => searchItem.sku.toUpperCase() === sku.toUpperCase())
}

export function selectProductDetailsProgress(state: AppState): Dictionary<Status> {
  return state.csvUpload.productDetailsProgress
}

export function selectIsFetchingV3ProductDetailsForCsvOrders(productDetailsProgress: Dictionary<Status>): boolean {
  return values(productDetailsProgress).some((status) => status === LOADING)
}

export function selectProductDetailsProgressBySkus(
  productDetailsProgress: Dictionary<Status>,
  skus: string[]
): Dictionary<Status> {
  return skus.reduce((progressAcc, sku) => {
    if (sku) {
      const skuProgress = productDetailsProgress[sku]
      if (skuProgress) {
        progressAcc[sku] = skuProgress
      } else {
        progressAcc[sku] = IDLE
      }
    }

    return progressAcc
  }, {})
}

export function selectHasCsvUploadedSuccessfully(state: AppState): boolean {
  return state.csvUpload.hasCsvUploadedSuccessfully
}

export function selectProductDescriptions(state: AppState): Dictionary<string> {
  return state.csvUpload.productDescriptions
}

export function selectProductDescriptionByItemId(descriptions: Dictionary<string>, itemId: string): ?string {
  const keyLocation = Object.keys(descriptions).find((id) => id === itemId)
  return keyLocation ? descriptions[keyLocation] : null
}

export function selectIgnoredOrders(state: AppState): string[] {
  return state.csvUpload.ignoredOrders
}

export function selectIsOrderIgnored(ignoredOrders: string[], orderId: string): boolean {
  return ignoredOrders.includes(orderId)
}

export function selectTotalIgnoredOrders(ignoredOrders: string[]): number {
  return ignoredOrders.length
}

export function selectOrdersThatAreNotIgnored(
  uploadedOrders: Dictionary<FormattedCsvRow>,
  ignoredOrders: string[]
): Dictionary<FormattedCsvRow> {
  const notIgnored = values(uploadedOrders).reduce((orderAcc, order) => {
    if (!ignoredOrders.find((orderIndex) => orderIndex === order.id)) {
      const nextIndex = Object.keys(orderAcc).length
      orderAcc[nextIndex] = order
    }

    return orderAcc
  }, {})

  return notIgnored
}

export function selectOrdersRequiringAction(
  submissionOrders: Dictionary<FormattedCsvRow>,
  productDetails: Dictionary<CatalogueItem>,
  v4ProductDetails: Dictionary<V4ProductDetails>,
  allOrderItems: Dictionary<FormattedOrderItem>,
  artworkStatus: Dictionary<Status>,
  v4ProductDetailRsaaStatuses: Dictionary<RsaaStatus>,
  quotes: Dictionary<Quote[]>
): Dictionary<FormattedCsvRow> {
  if (Object.keys(productDetails).length === 0 || Object.keys(quotes).length === 0) {
    return submissionOrders
  } else {
    return values(submissionOrders).reduce((orderAcc, order) => {
      const csvItemProductDetails = selectCsvItemProductDetails(productDetails, v4ProductDetails, allOrderItems)
      const orderItems = selectOrderItemsByItemIds(allOrderItems, order.itemIds)
      const hasAllV4ProductDetails = Object.keys(orderItems).every(
        (itemId) => v4ProductDetailRsaaStatuses[itemId].status === SUCCESS
      )
      const orderQuotes = quotes[order.id] ?? []

      const orderCompleted = isOrderCompleted(order, orderItems, csvItemProductDetails, artworkStatus, orderQuotes)
      if (!orderCompleted || !hasAllV4ProductDetails) {
        orderAcc[order.id] = order
      }

      return orderAcc
    }, {})
  }
}

export function selectV4OrderTotal(quotes: Quote[], selectedMethod: string): ?Cost {
  const methodDetails = quotes.find((quote) => quote.shipmentMethod.toUpperCase() === selectedMethod.toUpperCase())
  if (!methodDetails) {
    return null
  }

  const totalCostWithoutTax = +methodDetails.costSummary.totalCost.amount - +methodDetails.costSummary.totalTax.amount
  return {
    amount: totalCostWithoutTax.toString(),
    currency: methodDetails.costSummary.totalCost.currency
  }
}

export function selectV4TotalCost(state: AppState): Cost {
  const orders = getSubmissionOrders(state)
  const currency = selectCurrencyCode(state)

  const costs = values(orders)
    .map(({ preferredShippingMethod, id }) => {
      const orderQuotes = getCsvOrderQuotesByOrderId(state, id) ?? []
      return selectV4OrderTotal(orderQuotes, preferredShippingMethod)
    })
    .filter(Boolean)

  return {
    amount: combineCosts(costs).toString(),
    currency
  }
}

function selectCsvItemDetails(allProducts: Dictionary<CatalogueItem>, sku: string): ?CatalogueItem {
  return allProducts[sku]
}

function selectCsvItemDetailsByItem(item: ?FormattedOrderItem, allProducts: Dictionary<CatalogueItem>): ?CatalogueItem {
  return item ? allProducts[item.sku] : null
}

function selectCsvItemDetailsBySku(allProducts: Dictionary<CatalogueItem>, sku: string): ?CatalogueItem {
  return allProducts[sku]
}

export function selectIsMultiselectModeOn(state: AppState): boolean {
  return state.csvUpload.isMultiselectModeOn
}

export function selectCsvOrderItemsForOrder(
  allOrderItems: Dictionary<FormattedOrderItem>,
  order: FormattedCsvRow
): Dictionary<FormattedOrderItem> {
  return order?.itemIds.reduce((orderItemAcc, itemId) => {
    if (allOrderItems[itemId]) {
      orderItemAcc[itemId] = allOrderItems[itemId]
    }

    return orderItemAcc
  }, {})
}

function selectOrderItemIds(orders: Dictionary<FormattedCsvRow>): string[] {
  return values(orders).reduce((itemIdAcc, order) => [...itemIdAcc, ...order.itemIds], [])
}

function selectOrderItemIdsWithImages(
  itemIds: string[],
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CatalogueItem>
): string[] {
  return itemIds.reduce((itemIdAcc, id) => {
    const orderItem = orderItems[id]
    const details = productDetails[orderItem.sku]

    if (details && !details.noImageProduct) {
      itemIdAcc.push(id)
    }

    return itemIdAcc
  }, [])
}

function selectOrderIds(orders: Dictionary<FormattedCsvRow>): string[] {
  return values(orders).reduce((orderIdAcc, order) => [...orderIdAcc, order.id], [])
}

function selectMatchingOrderItemIds(
  orderItemIds: string[],
  orderItems: Dictionary<FormattedOrderItem>,
  sku: string,
  selectedAttributes: Dictionary<string>
): string[] {
  return orderItemIds.reduce((orderIdsAcc, orderItemId) => {
    const orderItemDetails = orderItems[orderItemId]

    const matchesAttributes = isEqual(orderItemDetails.selectedAttributes, selectedAttributes)
    const matchesSku = orderItemDetails.sku === sku

    if (matchesAttributes && matchesSku) {
      orderIdsAcc.push(orderItemId)
    }

    return orderIdsAcc
  }, [])
}

function selectOrderByOrderItemId(orders: Dictionary<FormattedCsvRow>, orderItemId: string): ?FormattedCsvRow {
  return values(orders).find((order) => order.itemIds.includes(orderItemId))
}

function selectPackingSlips(state: AppState): Dictionary<string> {
  return state.csvUpload.packingSlips
}

function selectPackingSlipsProgress(state: AppState): Dictionary<PackingSlipsProgress> {
  return state.csvUpload.packingSlipsProgress
}

function selectCsvOrderPackingSlip(packingSlips: Dictionary<string>, orderId: string): string {
  return packingSlips[orderId]
}

function selectBranding(state: AppState): Dictionary<CsvOrderBrandingType | null> {
  return state.csvUpload.branding
}

function selectCsvOrderBranding(
  branding: Dictionary<CsvOrderBrandingType | null>,
  orderId: string
): CsvOrderBrandingType | null {
  return branding?.[orderId]
}

function selectCsvOrderPackingSlipProgress(
  progress: Dictionary<PackingSlipsProgress>,
  orderId: string
): PackingSlipsProgress {
  const orderProgress = progress[orderId]
  return orderProgress ?? { status: IDLE, errorMessage: '' }
}

function selectTotalSubmittedOrders(state: AppState): number {
  return state.csvUpload.totalSubmittedOrders
}

function selectV4ProductDetailRsaaStatusesForOrderItems(
  state: AppState,
  orderItems: Dictionary<FormattedOrderItem>
): Dictionary<RsaaStatus> {
  return entries(orderItems).reduce((rsaaStatusAcc, [orderItemId, orderItem]) => {
    const rsaaActionId = buildRsaaActionId(GET_V4_PRODUCT_DETAILS, orderItem.sku)
    rsaaStatusAcc[orderItemId] = selectRsaaStatus(state, rsaaActionId)

    return rsaaStatusAcc
  }, {})
}

function selectHasV4ProductDetailsForAllCsvItems(state: AppState, orderItems: Dictionary<FormattedOrderItem>): boolean {
  const allRsaaStatues = selectV4ProductDetailRsaaStatusesForOrderItems(state, orderItems)

  return values(allRsaaStatues).every((rsaa) => rsaa.status === SUCCESS)
}

function selectCsvOrderQuotesByOrderIdFromState(state: AppState, orderId: string): Quote[] {
  return state.quotes[orderId] ?? []
}

function selectCsvOrderQuotesByOrderId(quotes: Dictionary<Quote[]>, orderId: string): Quote[] {
  return quotes[orderId] ?? []
}

function selectIsFetchingCsvOrderQuotes(state: AppState, orders: Dictionary<FormattedCsvRow>): boolean {
  const orderIds = Object.keys(orders)
  return orderIds.some((orderId) => {
    const orderQuoteStatus = selectCsvOrderQuoteRsaaStatusByOrderId(state, orderId)
    return orderQuoteStatus.status === LOADING
  })
}

function selectIsUploadingImages(
  orderItems: Dictionary<FormattedOrderItem>,
  uploadProgress: Dictionary<ArtworkProgress>
): boolean {
  const orderItemImageIds = values(orderItems).reduce((imageArtworkIdAcc, orderItem) => {
    const idsToAdd = Object.keys(orderItem.printAreaImageUrls).map((printAreaName) =>
      buildImageId(orderItem.id, printAreaName)
    )
    return [...imageArtworkIdAcc, ...idsToAdd]
  }, [])

  return entries(uploadProgress).some(([imageId, uploadProgress]) => {
    if (!orderItemImageIds.includes(imageId)) {
      return false
    }

    return uploadProgress.status === LOADING
  })
}

function selectIsFetchingV4ProductDetailsForCsvOrders(
  state: AppState,
  orderItems: Dictionary<FormattedOrderItem>
): boolean {
  const allRsaaStatues = selectV4ProductDetailRsaaStatusesForOrderItems(state, orderItems)

  return values(allRsaaStatues).some((rsaa) => rsaa.status === LOADING)
}

function selectIsFetchingTemplates(state: AppState, orderItems: Dictionary<FormattedOrderItem>): boolean {
  const templateIds = values(orderItems).map((orderItem) => {
    return buildTemplateId(orderItem.sku, orderItem.selectedAttributes)
  })

  const statuses = getTemplateStatusByIds(state, templateIds)

  return Object.values(statuses).some((status) => status === LOADING)
}

function selectCsvFileType(state: AppState): ?CsvFileType {
  return state.csvUpload.csvFileType
}

function selectIsUsSalesTaxCollected(order: FormattedCsvRow): boolean {
  return order.isUsSalesTaxCollected
}

function selectIsAnyCsvOrderForUnitedStates(orders: Dictionary<FormattedCsvRow>): boolean {
  const isAnyOrderForUnitedStates = values(orders).some((order) => {
    return order.customer.countryCode === 'US'
  })
  return isAnyOrderForUnitedStates
}

function selectIsUsSalesTaxCollectedForAllUsOrders(orders: Dictionary<FormattedCsvRow>): boolean {
  const isUsSalesTaxCollectedForAllUsOrders = values(orders)
    .filter((order) => order.customer.countryCode === 'US')
    .every((order) => order.isUsSalesTaxCollected)
  return isUsSalesTaxCollectedForAllUsOrders
}

export const getCsvFileType: GetCsvFileType = createSelector(selectCsvFileType, identity)
export const getCsvOrderItemsByOrderId: GetCsvOrderItemsByOrderId = createSelector(
  selectCsvOrderItems,
  selectCsvOrder,
  selectCsvOrderItemsForOrder
)
export const getCsvItemDetails: GetCsvItemDetails = createSelector(
  selectProductDetails,
  returnSecondArg,
  selectCsvItemDetails
)
export const getIsCsvV4OrderSubmissionSuccessful: GetIsCsvV4OrderSubmissionSuccessful = createSelector(
  returnFirstArg,
  getSubmissionOrders,
  selectIsCsvV4OrderSubmissionSuccessful
)
export const getCsvOrderUploadProgress: any = createSelector(
  selectCsvUploadProgress,
  returnSecondArg,
  selectCsvOrderUploadProgress
)
export const getCsvItemProductDetails: GetCsvItemProductDetails = createSelector(
  selectProductDetails,
  selectV4ProductDetails,
  getCsvOrderItemsByOrderId,
  selectCsvItemProductDetails
)
export const getOrdersThatAreNotIgnored: any = createSelector(
  selectUploadedOrders,
  selectIgnoredOrders,
  selectOrdersThatAreNotIgnored
)
export const getIsEveryOrderCompleted: any = createSelector(
  getOrdersThatAreNotIgnored,
  selectProductDetails,
  selectV4ProductDetails,
  getAllArtworkStatus,
  selectCsvOrderItems,
  selectQuotes,
  selectIsEveryOrderCompleted
)
export const getCsvOrderItem: GetCsvOrderItem = createSelector(selectCsvOrderItems, returnSecondArg, selectCsvOrderItem)
export const getProductBySku: any = createSelector(selectProductDetails, returnSecondArg, selectProductBySku)
export const getProductDetailsProgressBySkus: any = createSelector(
  selectProductDetailsProgress,
  returnSecondArg,
  selectProductDetailsProgressBySkus
)
export const getUploadedOrders: any = createSelector(selectUploadedOrders, identity)
export const getHasCsvUploadedSuccessfully: any = createSelector(selectHasCsvUploadedSuccessfully, identity)
export const getProductDescriptionByItemId: any = createSelector(
  selectProductDescriptions,
  returnSecondArg,
  selectProductDescriptionByItemId
)
export const getIgnoredOrders: any = createSelector(selectIgnoredOrders, identity)
export const getIsOrderIgnored: any = createSelector(selectIgnoredOrders, returnSecondArg, selectIsOrderIgnored)
export const getNumberOfIgnoredOrders: any = createSelector(selectIgnoredOrders, selectTotalIgnoredOrders)
export const getCsvOrderQuotesByOrderId: GetCsvOrderQuotesByOrderId = createSelector(
  selectQuotes,
  returnSecondArg,
  selectCsvOrderQuotesByOrderId
)
export const getV4OrderTotal: GetV4OrderTotal = createSelector(selectV4TotalCost, identity)
export const getCsvItemDetailsById: any = createSelector(
  getCsvOrderItem,
  selectProductDetails,
  selectCsvItemDetailsByItem
)
export const getCsvItemDetailsBySku: GetCsvItemDetailsBySku = createSelector(
  selectProductDetails,
  returnSecondArg,
  selectCsvItemDetailsBySku
)
export const getCsvItemAttributes: GetCsvItemAttributes = createSelector(getCsvOrderItem, (item) =>
  item ? item.selectedAttributes : {}
)
export const getCsvItemSku: GetCsvItemSku = createSelector(getCsvOrderItem, (item) => (item ? item.sku : null))
export const getCsvItemTemplates: GetCsvItemTemplates = createSelector(
  returnFirstArg,
  getCsvItemSku,
  getCsvItemAttributes,
  getProductTemplates
)
export const getOrdersWithErrors: GetOrdersWithErrors = createSelector(
  selectUploadedOrders,
  selectCsvUploadProgress,
  selectOrdersWithErrors
)
export const getIsMultiselectModeOn: GetIsMultiselectModeOn = createSelector(selectIsMultiselectModeOn, identity)
export const getSelectedOrderShippingMethod: GetSelectedOrderShippingMethod = createSelector(
  selectCsvOrder,
  selectOrderShippingMethod
)
export const getSelectedOrderItemIds: GetIds = createSelector(getOrdersThatAreNotIgnored, selectOrderItemIds)
export const getSelectedOrderIds: GetIds = createSelector(getOrdersThatAreNotIgnored, selectOrderIds)
export const getSelectedArtworkOrderIds: GetIds = createSelector(
  getSelectedOrderItemIds,
  selectCsvOrderItems,
  selectProductDetails,
  selectOrderItemIdsWithImages
)
export const getSelectedArtworkTransformationIds: GetSelectedArtworkTransformationIds = createSelector(
  getSelectedArtworkOrderIds,
  selectCsvOrderItems,
  returnSecondArg,
  returnThirdArg,
  selectMatchingOrderItemIds
)
export const getCsvOrderItemsByItemIds: GetCsvOrderItemsByItemIds = createSelector(
  selectCsvOrderItems,
  returnSecondArg,
  selectOrderItemsByItemIds
)
export const getCsvOrderByOrderItemId: GetCsvOrderByOrderItemId = createSelector(
  selectUploadedOrders,
  returnSecondArg,
  selectOrderByOrderItemId
)
export const getCsvOrderWithItems: GetCsvOrderWithItems = createSelector(
  selectCsvOrder,
  getCsvOrderItemsByOrderId,
  (order, items) => [order, items]
)
export const getCsvOrderPackingSlip: GetCsvOrderPackingSlip = createSelector(
  selectPackingSlips,
  returnSecondArg,
  selectCsvOrderPackingSlip
)
export const getCsvOrderBranding: GetCsvOrderBranding = createSelector(
  selectBranding,
  returnSecondArg,
  selectCsvOrderBranding
)
export const getCsvOrderPackingSlipProgress: GetCsvOrderPackingSlipProgress = createSelector(
  selectPackingSlipsProgress,
  returnSecondArg,
  selectCsvOrderPackingSlipProgress
)
export const getTotalSubmittedOrders: GetTotalSubmittedOrders = createSelector(selectTotalSubmittedOrders, identity)

export const getIsUsSalesTaxCollectedByOrderId: GetIsUsSalesTaxCollectedByOrderId = createSelector(
  selectCsvOrder,
  selectIsUsSalesTaxCollected
)

export const getIsAnyCsvOrderForUnitedStates: (state: AppState) => boolean = createSelector(
  getSubmissionOrders,
  selectIsAnyCsvOrderForUnitedStates
)

export const getIsUsSalesTaxCollectedForAllUsOrders: (state: AppState) => boolean = createSelector(
  getSubmissionOrders,
  selectIsUsSalesTaxCollectedForAllUsOrders
)

export const getIsFetchingV3ProductDetailsForCsvOrders: GetIsFetchingV3ProductDetailsForCsvOrders = createSelector(
  selectProductDetailsProgress,
  selectIsFetchingV3ProductDetailsForCsvOrders
)

export const getV4OrderTotalByOrderId: GetV4OrderTotalByOrderId = createSelector(
  selectCsvOrderQuotesByOrderIdFromState,
  selectPreferredShippingMethodByOrderId,
  selectV4OrderTotal
)

export const getIsFetchingCsvOrderQuotes: GetIsFetchingCsvOrderQuotes = createSelector(
  returnFirstArg,
  getUploadedOrders,
  selectIsFetchingCsvOrderQuotes
)

export const getIsUploadingImages: GetIsUploadingImages = createSelector(
  selectCsvOrderItems,
  getArtworkProgress,
  selectIsUploadingImages
)

export const getIsFetchingTemplates: GetIsFetchingTemplates = createSelector(
  returnFirstArg,
  selectCsvOrderItems,
  selectIsFetchingTemplates
)

export const getIsFetchingV4ProductDetailsForCsvOrders: GetIsFetchingV4ProductDetailsForCsvOrders = createSelector(
  returnFirstArg,
  selectCsvOrderItems,
  selectIsFetchingV4ProductDetailsForCsvOrders
)

export const getV4ProductDetailRsaaStatusesForCsvOrder: GetV4ProductDetailRsaaStatusesForCsvOrder = createSelector(
  returnFirstArg,
  getCsvOrderItemsByOrderId,
  selectV4ProductDetailRsaaStatusesForOrderItems
)

export const getCsvOrderItemsToSubmit: GetCsvOrderItemsToSubmit = createSelector(
  returnFirstArg,
  getSelectedOrderItemIds,
  getCsvOrderItemsByItemIds
)

export const getHasV4ProductDetailsForAllCsvItemsToSubmit: GetHasV4ProductDetailsForAllCsvItemsToSubmit =
  createSelector(returnFirstArg, getCsvOrderItemsToSubmit, selectHasV4ProductDetailsForAllCsvItems)

export const getV4ProductDetailRsaaStatusesForCsvItemsToSubmit: GetV4ProductDetailRsaaStatusesForCsvItemsToSubmit =
  createSelector(returnFirstArg, getCsvOrderItemsToSubmit, selectV4ProductDetailRsaaStatusesForOrderItems)

export const getOrdersRequiringAction: any = createSelector(
  getSubmissionOrders,
  selectProductDetails,
  selectV4ProductDetails,
  selectCsvOrderItems,
  getAllArtworkStatus,
  getV4ProductDetailRsaaStatusesForCsvItemsToSubmit,
  selectQuotes,
  selectOrdersRequiringAction
)
