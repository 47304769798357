import { ReactNode } from 'react'
import toast, { Toast as ToastType } from 'react-hot-toast'
import { ToastContent } from '.'

export function ToastWithCloseButton({
  content,
  footer,
  heading,
  toastRef
}: {
  content?: ReactNode
  footer?: ReactNode
  heading: string
  toastRef: ToastType
}) {
  return (
    <div className="tailwind">
      <div className="flex gap-1 rounded-md bg-gray-100">
        <div className="rounded-l-md bg-white pr-3">
          <ToastContent content={content} footer={footer} heading={heading} />
        </div>

        <div className="flex rounded-r-md bg-white text-sm">
          <button className="ml-6" onClick={() => toast.dismiss(toastRef.id)}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
