// @flow

import { getStripeConnectSignupLink } from './getStripeConnectSignupLink'
import { createStripeConnectAccount } from './createStripeConnectAccount'
import type { GetStripeConnectSignupLinkType } from '../../types'

export async function processStripeConnectSignup (
  { abortSignal, countryCode, email }: {| abortSignal: AbortSignal, countryCode: string, email: string |}
): Promise<GetStripeConnectSignupLinkType> {
  await createStripeConnectAccount({
    abortSignal,
    countryCode,
    email
  })
  return getStripeConnectSignupLink({
    abortSignal
  })
}
