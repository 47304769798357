// @flow
import type { DispatchFunc, Thunk } from '../../types'
import type { RemoveCsvItemArtwork } from '../types'
import { buildImageId, resetImage } from '../images'

export const REMOVE_CSV_ITEM_ARTWORK = 'REMOVE_CSV_ITEM_ARTWORK'

export function removeCsvItemArtwork (orderItemId: string, printArea: string): Thunk<RemoveCsvItemArtwork> {
  return (dispatch: DispatchFunc) => {
    const artworkId = buildImageId(orderItemId, printArea)

    dispatch(resetImage(artworkId))

    const removeCsvItemArtworkAction: RemoveCsvItemArtwork = {
      type: REMOVE_CSV_ITEM_ARTWORK,
      orderItemId,
      printArea,
      artworkId
    }
    return dispatch(removeCsvItemArtworkAction)
  }
}
