// @flow

import React from 'react'

import type { Dictionary, FormattedCsvRow, Country } from '../../types'
import CsvUploadController from './CsvUploadController'

type Props = {|
  hasCsvUploadedSuccessfully: boolean,
  uploadedOrders: Dictionary<FormattedCsvRow>,
  csvLoading: boolean,
  csvError: boolean,
  csvMsg: string,
  isFetchingQuotes: boolean,
  isFetchingV4ProductDetails: boolean,
  isFetchingV3ProductDetails: boolean,
  isUploadingImages: boolean,
  isFetchingTemplates: boolean,
  countries: Country[],
  merchantId: string,
  uploadCsv: (file: File, countries: Country[]) => void,
  getItemCategories: () => void,
  fetchCountries: () => void
|}
export default function CsvUploadSplit(props: Props): React$Node {
  return <CsvUploadController {...props} />
}
