// @flow
import ErrorBoundary from './ErrorBoundary'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { logLe, LOG_LEVEL } from '../../actions/logging'
import type { DispatchFunc, Dictionary } from '../../types'

export type DispatchProps = {|
  logLe: (message: string, details: Dictionary<string>) => void
|}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    logLe: (message: string, details: Dictionary<string>) => dispatch(logLe(message, details, LOG_LEVEL.ERROR))
  }
}

export default (compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(ErrorBoundary): any)
