import { ImageLibraryFileType } from '../../../hooks'

export function formatFileFormatFilter(formatValues: string) {
  return (formatValues.split(',') as ('image/jpeg' | 'image/png' | 'application/pdf')[])
    .map(formatImageLibraryFileFormatName)
    .join(', ')
}

function formatImageLibraryFileFormatName(type: 'image/png' | 'image/jpeg' | 'application/pdf') {
  switch (type) {
    case 'image/png':
      return 'png'
    case 'image/jpeg':
      return 'jpg'
    case 'application/pdf':
      return 'pdf'
    default:
      return type
  }
}

export function formatFileFormatTypeFilter(formatValues: string) {
  return (formatValues.split(',') as ImageLibraryFileType[]).join(', ')
}
