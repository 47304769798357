// @flow
import type { MultiAssetBasketItem } from '../../types'
import type { ClearRecentItems, RemoveRecentItem } from '../types'
import { PRINT_AREA_NAME } from '../../data'

export const CLEAR_RECENT_ITEMS = 'CLEAR_RECENT_ITEMS'
export const REMOVE_RECENT_ITEM = 'REMOVE_RECENT_ITEM'

export function clearRecentItems (): ClearRecentItems {
  return {
    type: CLEAR_RECENT_ITEMS
  }
}

export function hasSameNameAndImageUrl (firstItem: MultiAssetBasketItem): ((secondItem: MultiAssetBasketItem) => boolean) {
  return function (secondItem: MultiAssetBasketItem): boolean {
    const firstItemPrintAreas = firstItem.printAreas
    const secondItemPrintAreas = secondItem.printAreas
    if (!firstItemPrintAreas || !secondItemPrintAreas) {
      return false
    }
    const firstItemPrintAreaNames = Object.keys(firstItemPrintAreas)
    const firstItemPrintAreaName = firstItemPrintAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
      ? PRINT_AREA_NAME.DEFAULT
      : firstItemPrintAreaNames[0] ?? null

    const secondItemPrintAreaNames = Object.keys(secondItemPrintAreas)
    const secondItemPrintAreaName = secondItemPrintAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
      ? PRINT_AREA_NAME.DEFAULT
      : secondItemPrintAreaNames[0] ?? null

    const firstArtwork = firstItemPrintAreas[firstItemPrintAreaName]?.artwork
    const secondArtwork = secondItemPrintAreas[secondItemPrintAreaName]?.artwork

    if (!firstArtwork || !secondArtwork) {
      return false
    }

    return Boolean(
      secondItem.sku === firstItem.sku &&
      firstArtwork.originalImageUrl === secondArtwork.originalImageUrl
    )
  }
}

export function removeRecentItem (originalImageUrl: string): RemoveRecentItem {
  return {
    type: REMOVE_RECENT_ITEM,
    originalImageUrl
  }
}
