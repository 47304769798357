import { OrderStatusEnum, StatusEnum } from '../../../enums'
import ConfirmationModal from '../../ConfirmationModal'
import toast from 'react-hot-toast'
import { fetcher } from '../../../helpers'
import { FetchErrorInterface, OMSErrorResponseInterface, OMSResponseInterface } from '../../../interfaces'
import { createToast } from '../../Toast'
import { selectOMSUrl } from '../../../selectors/appSettings'
import { OMS_ENDPOINTS } from '../../../constants'
import { useState } from 'react'
import OverlayPortal from '../../OverlayPortal'

interface OrderCancellationModalPropsInterface {
  open: boolean
  orderId: string
  status: OrderStatusEnum
  mutateOrderDetails: () => void
  setOpen: (open: boolean) => void
}

const SUCCESS_TOAST_ID = 'order-detail-cancel-order-toast-success'
const ERROR_TOAST_ID = 'order-detail-cancel-order-toast-error'

enum CancelOrderOutcomeEnum {
  Cancelled = 'Cancelled',
  FailedToCancel = 'FailedToCancel',
  ActionNotAvailable = 'NotAvailable'
}

interface CancelOrderResponseInterface extends OMSResponseInterface {
  outcome: CancelOrderOutcomeEnum
}

export function OrderCancellationModal({
  open,
  orderId,
  status,
  setOpen,
  mutateOrderDetails
}: OrderCancellationModalPropsInterface) {
  const [cancelOrderStatus, setCancelOrderStatus] = useState<StatusEnum>(StatusEnum.Idle)

  function cancelOrder() {
    toast.dismiss(ERROR_TOAST_ID)
    setCancelOrderStatus(StatusEnum.Loading)

    const url = `${selectOMSUrl()}/${OMS_ENDPOINTS.ORDER_DETAIL}/${orderId}/${OMS_ENDPOINTS.ORDER_ACTIONS}/${
      OMS_ENDPOINTS.CANCEL_ORDER
    }`
    fetcher<CancelOrderResponseInterface>(url, {
      method: 'PUT'
    })
      .then((response) => {
        if (response.outcome === CancelOrderOutcomeEnum.Cancelled) {
          createToast({ heading: 'Order successfully cancelled', id: SUCCESS_TOAST_ID, type: 'success' })
          setCancelOrderStatus(StatusEnum.Success)
          mutateOrderDetails()
          setOpen(false)
        } else {
          throw Error(`Unknown error (code: CER ${response.traceParent})`)
        }
      })
      .catch((error) => {
        const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
        let errorMessage
        let footer

        if (errorResponse.responseBodyJson?.outcome === CancelOrderOutcomeEnum.FailedToCancel) {
          errorMessage = 'We are no longer able to cancel the order'
          footer = 'code: FTC'
        } else if (errorResponse.responseBodyJson?.outcome === CancelOrderOutcomeEnum.ActionNotAvailable) {
          errorMessage = 'We are no longer able to cancel the order'
          footer = 'code: ANA'
        } else if (errorResponse.responseBodyJson?.traceParent) {
          errorMessage = `${errorResponse.responseBodyJson?.data?.message ?? 'Unknown error'}`
          footer = <span className="break-all">code: {errorResponse.responseBodyJson.traceParent}</span>
        } else {
          errorMessage = error.message ?? 'Unknown error'
        }

        window.analytics.track('Error Cancellation Unsuccessful', { orderId })
        createToast({
          content: errorMessage,
          duration: Infinity,
          footer: footer,
          heading: 'Failed to cancel order',
          id: ERROR_TOAST_ID,
          type: 'error-with-close'
        })
        setCancelOrderStatus(StatusEnum.Error)
        setOpen(false)
      })
  }

  function handleOpenChange(open: boolean) {
    if (!open) {
      setCancelOrderStatus(StatusEnum.Idle)
      toast.dismiss(ERROR_TOAST_ID)
    }
    setOpen(open)
  }

  function onCancelButtonClick() {
    handleOpenChange(false)
    window.analytics.track('Clicked Do Not Cancel', { orderId })
  }

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Do not cancel' }}
        closeOnInteractionOutside={cancelOrderStatus !== StatusEnum.Loading}
        continueButton={{ text: 'Yes, cancel order' }}
        isLoading={cancelOrderStatus === StatusEnum.Loading}
        onCancel={onCancelButtonClick}
        onContinue={cancelOrder}
        open={open}
        setOpen={handleOpenChange}
        title="Cancel this order?"
      >
        <div>
          {status === OrderStatusEnum.IN_PROGRESS && (
            <p className="mb-8 font-medium">
              Your order is in production. You can still cancel the order, but the cost of your product at this stage is
              non-refundable. Only the cost of shipping will be refunded to you.
            </p>
          )}
          <p className="font-medium">Order {orderId}</p>
        </div>
      </ConfirmationModal>

      {/* Blocks any clicks while the form is loading */}
      {cancelOrderStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
