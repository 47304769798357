import useInfiniteScroll from 'react-infinite-scroll-hook'

import SupportLink from '../../SupportLink'
import { ImageTile } from './ImageTile.component'
import { ImageLibraryModeType } from '../ImageLibrary.component'
import { ImageTileSkeleton } from './ImageTileSkeleton.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface ImageListLoadedPropsInterface {
  imageLibraryMode: ImageLibraryModeType
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function ImageListLoaded({ imageLibraryMode, onSelectImage }: ImageListLoadedPropsInterface) {
  const { imageLibraryPages, imageLibraryFetchError, imageLibraryPagesToLoadSize, setImageLibraryPagesToLoadSize } =
    useImageLibraryImages()

  const loadedImageLibraryPagesSize = imageLibraryPages?.length ?? 1
  const hasNextPage = Boolean(imageLibraryPages && imageLibraryPages?.[loadedImageLibraryPagesSize - 1]?.length > 0)
  const isLoadingNextPageOfImageLibrary = hasNextPage && loadedImageLibraryPagesSize < imageLibraryPagesToLoadSize

  const [paginationTriggerRef] = useInfiniteScroll({
    disabled: Boolean(imageLibraryFetchError),
    loading: isLoadingNextPageOfImageLibrary,
    delayInMs: 200,
    hasNextPage,
    onLoadMore: () => {
      setImageLibraryPagesToLoadSize(imageLibraryPagesToLoadSize + 1)
    }
  })

  if (!imageLibraryPages) {
    throw Error('No image library image pages')
  }

  const showNextPageLoadingSkeletons = !imageLibraryFetchError && (isLoadingNextPageOfImageLibrary || hasNextPage)

  const showAllImagesLoadedMessage = imageLibraryPages.length > 2 && !hasNextPage

  return (
    <div className="grid max-w-full grid-cols-2 gap-8 overflow-x-auto pt-8 pb-12 md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
      {imageLibraryPages.map((imageLibraryImages) =>
        imageLibraryImages.map((imageLibraryImage, index) => (
          <ImageTile
            key={imageLibraryImage.public_id}
            imageLibraryImage={imageLibraryImage}
            imageLibraryMode={imageLibraryMode}
            index={index}
            onSelectImage={onSelectImage}
          />
        ))
      )}

      {imageLibraryFetchError && (
        <div className="col-span-full mx-auto max-w-2xl pt-6 text-center text-red-500">
          An error occurred while loading the next page of images. Please try again later or{' '}
          <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists (Code{' '}
          {imageLibraryFetchError.status ?? '0'})
        </div>
      )}

      {showAllImagesLoadedMessage && (
        <div className="col-span-full mx-auto max-w-[250px] pt-6 text-center text-gray-600">All images loaded</div>
      )}

      {showNextPageLoadingSkeletons &&
        Array.from({ length: 2 }, (_, i) => i).map((value) => (
          <ImageTileSkeleton key={value} ref={paginationTriggerRef} />
        ))}
    </div>
  )
}
