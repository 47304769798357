export function formatDateToString(date: Date | null) {
  if (!date) {
    return ''
  }

  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear()
  const day = ('0' + date.getDate()).slice(-2)

  return [year, month, day].join('-')
}
