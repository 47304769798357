// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function CsvUploaderIcon ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="30" cy="30" r="30" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="18" y="17" width="24" height="24">
        <path d="M40 39H20V19H40V39ZM19.2 17C18.5333 17 18 17.5333 18 18.2V39.8C18 40.3333 18.5333 41 19.2 41H40.8C41.3333 41 42 40.3333 42 39.8V18.2C42 17.5333 41.3333 17 40.8 17H19.2ZM29 22H37V24H29V22ZM29 28H37V30H29V28ZM29 34H37V36H29V34ZM23 34H25V36H23V34ZM23 28H25V30H23V28ZM23 22H25V24H23V22Z" />
      </mask>
      <g mask="url(#mask0)">
        <path d="M40 39H20V19H40V39ZM19.2 17C18.5333 17 18 17.5333 18 18.2V39.8C18 40.3333 18.5333 41 19.2 41H40.8C41.3333 41 42 40.3333 42 39.8V18.2C42 17.5333 41.3333 17 40.8 17H19.2ZM29 22H37V24H29V22ZM29 28H37V30H29V28ZM29 34H37V36H29V34ZM23 34H25V36H23V34ZM23 28H25V30H23V28ZM23 22H25V24H23V22Z" />
      </g>
    </svg>
  )
}
