import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE } from 'src/v2/constants'
import { ConnectSalesChannelFromRedirect } from 'src/v2/components/SalesChannels/components'

export default function ConnectSalesChannelFromRedirectPage() {
  useEffect(() => {
    document.title = 'Sales Channels | Prodigi'
    window.analytics.page('Sales Channels - Redirect')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <ConnectSalesChannelFromRedirect />
    </main>
  )
}
