import {
  AssetStatusEnum,
  OrderDetailIssueEnum,
  OrderDetailIssueStatusEnum,
  OrderDetailIssueTypeEnum,
  OrderStatusEnum,
  PackingSlipDownloadStatus,
  ShipmentStatus
} from '../enums'
import { CostInterface } from './Cost.interface'
import { ImageLibraryImageMimeType } from '../hooks'
import { OrderDetailHistoryInterface } from '../components/OrderDetail/interfaces'
import { ImageEditorPrintAreaArtworkInterface } from '../components/ImageEditor/interfaces'

interface OrderDetailResponseInterface {
  outcome: string
  traceParent: string
}

export interface OrderDetailDataErrorInterface extends OrderDetailResponseInterface {
  data: {
    message: string
  }
}

export interface OrderDetailDataSuccessInterface extends OrderDetailResponseInterface {
  data: {
    draftCosts?: {
      costSummary: {
        numberOfItems: number
        totalQuantityOfItems: number
        totalCost?: CostInterface
        items?: CostInterface
        shipping?: CostInterface
        tax?: CostInterface
      }
      shipping: Record<string, CostInterface>
      items: Record<string, OrderDetailEditItemsCostInterface>
    }
    order: OrderDetailInterface
  }
}

export enum DelayedSubmissionStatusInterface {
  NotDelayed = 'NotDelayed',
  Delayed = 'Delayed'
}

export interface OrderDetailInterface {
  billingCurrency: string
  costSummary: {
    items: CostInterface
    numberOfItems: number
    shipping: CostInterface
    tax: CostInterface
    totalQuantityOfItems: number
    totalCost: CostInterface
  }
  createdDate: string
  fulfilmentId: string
  id: string
  items: OrderDetailItemInterface[]
  merchantId: string
  merchantReference: string
  packingSlipUrl: string
  packingSlipStatus: PackingSlipDownloadStatus
  preferredShippingMethod: string
  recipient: OrderDetailRecipientInterface
  shipments: OrderDetailShipmentInterface[]
  status: OrderStatusEnum
  unallocatedItems: string[]
  usSalesTaxCollected: boolean
  issues: OrderDetailIssuesInterface[]
  history: OrderDetailHistoryInterface[]
  salesChannelId: string
  isReadyForFulfilment: boolean
  delayedSubmissionStatus: DelayedSubmissionStatusInterface
  delayedSubmissionDateTime: string | null
}

interface OrderDetailEditItemsCostInterface {
  itemId: string
  quantity: number
  itemCost: CostInterface
  totalCost: CostInterface
  taxCost: CostInterface
}

export interface OrderDetailShippingCostsInterface {
  type: string
  cost: {
    currencyCode: string
    amount: string
  }
}

export interface OrderDetailIssuesInterface {
  type: OrderDetailIssueTypeEnum
  data: PaymentRequiresAuthorisationInterface | OrderDetailCSIssueInterface
}

export interface PaymentRequiresAuthorisationInterface {
  amount: string
  currency: string
  paymentIdentifier: string
  invoiceNumber: number
  reason: string
  otherDescription: string
}

export interface OrderDetailCSIssueInterface {
  id: number
  createdDate: string
  issueType: OrderDetailIssueEnum
  issueDetail: string
  actionType: string
  actionDetail: string
  status: OrderDetailIssueStatusEnum
  comments: string[]
  labIdOrder: string | null
}

export interface OrderDetailRecipientInterface {
  address: OrderDetailRecipientAddressInterface
  email: string
  name: string
  phone: string
}

export interface OrderDetailShipmentItemsInterface {
  itemId: string
  quantity: number
  itemCost: CostInterface
  totalCost: CostInterface
  taxCost: CostInterface
}

export interface ItemMetaDataInterface {
  spineText?: string
  spineBackgroundColourHexCode?: string
  spineTextColourHexCode?: string
  spineTextSizePx?: number
  spineTextFontFamily?: string
}

export interface OrderDetailItemInterface {
  assets: OrderDetailItemAssetInterface[]
  attributes: Record<string, string>
  id: string
  ignore: boolean
  isReadyForFulfilment: boolean
  metadata?: ItemMetaDataInterface
  prodigiSku: string
  prodigiSkuDescription: string
  productType: string
  quantity: number
  salesChannelProductDetails: SalesChannelProductDetailsInterface
  itemCost: CostInterface
  totalCost: CostInterface
  taxCost: CostInterface
}

interface SalesChannelProductDetailsInterface {
  variantId: string
  productId: number
  title: string
  description: string
  optionValues: string[]
  totalPrice: string
  unitPrice: string
  productType: string
}

export interface OrderDetailItemAssetInterface {
  additionalUiDetail: ImageEditorPrintAreaArtworkInterface
  additionalUiDetailVersion: string
  assetMimeType?: ImageLibraryImageMimeType
  assetStatus: AssetStatusEnum
  assetThumbnailUrl: string
  assetUrl: string
  id: string
  printAreaName: string
  required: boolean
}

export interface OrderDetailRecipientAddressInterface {
  countryCode: string
  line1: string
  line2: string
  postcodeOrZipCode: string
  stateOrCounty: string
  townOrCity: string
}

export interface OrderDetailShipmentInterface {
  allocationDate?: string
  carrier: string
  carrierService: string
  dispatchDate: string
  id: string
  itemsInShipment: OrderDetailShipmentItemsInterface[]
  shippedFrom: { countryCode: string }
  shippingCost: CostInterface
  taxCost: CostInterface
  trackingNumber: string
  trackingUrl: string
  status: ShipmentStatus
}
