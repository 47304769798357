// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'

import {
  STRIPE_SETUP_INTENT_ERROR,
  SUBMIT_CARD_ERROR,
  PAYMENT_METHOD_ID_SUBMISSION_ERROR,
  PAYMENT_INTENT_ERROR,
  STRIPE_PAYMENT_ERROR,
  REMOVE_PAYMENT_DETAILS_FAILED
} from '../../../actions/payments'

export function payments(action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case REMOVE_PAYMENT_DETAILS_FAILED: {
      return ['Request to remove payment details failed', { resp: { ...action.payload } }, 'error']
    }

    case STRIPE_SETUP_INTENT_ERROR: {
      return ['Request stripe setup intent', { resp: { ...action.payload } }, 'error']
    }

    case PAYMENT_INTENT_ERROR: {
      return ['Payment intent', { resp: { ...action.payload } }, 'error']
    }

    case SUBMIT_CARD_ERROR: {
      return ['Submit card to stripe', { resp: action.message }, 'error']
    }

    case STRIPE_PAYMENT_ERROR: {
      return ['Stripe payment', { resp: action.message }, 'error']
    }

    case PAYMENT_METHOD_ID_SUBMISSION_ERROR: {
      return ['Payment method id submission error', { resp: { ...action.payload } }, 'error']
    }
    default: {
      return null
    }
  }
}
