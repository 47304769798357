import { useState } from 'react'
import { useOs } from '@mantine/hooks'
import { useDebouncedCallback } from 'use-debounce'

import SearchField from '../SearchField'
import SupportLink from '../SupportLink'
import ClipboardCopy from '../ClipboardCopy'
import { buildDefaultCountries } from './helpers'
import { useCountries, useUser } from '../../hooks'
import { V3CountryInterface } from '../../interfaces'
import { HOTKEYS } from '../../constants/hotkeys.const'
import { CountryPickerOption, CountryPickerOptionSkeleton } from './components'

interface CountryPickerPropsInterface {
  onCountrySelection: (countryCode: string) => void
}

export function CountryPicker({ onCountrySelection }: CountryPickerPropsInterface) {
  const { user } = useUser()
  const { countries, isLoading: loadingCountries, error: countriesError } = useCountries({ revalidateOnFocus: false })
  const defaultCountries = buildDefaultCountries({ countries, selectedCountryCode: user?.company.countryCode })

  if (countriesError) {
    const errorCode = `FCE-0-${countriesError.status ?? countriesError.message}`
    return (
      <div>
        <div className="flex flex-col gap-4 pt-8 md:flex-row">
          <div className="max-w-[60ch] break-words text-red-500" style={{ wordBreak: 'break-word' }}>
            An error occurred while fetching country info, please try again later or{' '}
            <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists. Code:{' '}
            {errorCode} <ClipboardCopy className="pt-1 align-top" text={errorCode} showText={false} />
          </div>
        </div>
      </div>
    )
  }

  if (loadingCountries) {
    return (
      <div>
        <ul className="pt-8">
          {Array.from({ length: 4 }, (_, i) => i).map((value) => (
            <CountryPickerOptionSkeleton key={value} />
          ))}
        </ul>
      </div>
    )
  }

  return (
    <CountryPickerComponent
      countries={countries}
      defaultCountries={defaultCountries}
      onCountrySelection={onCountrySelection}
    />
  )
}

interface ComponentPropsInterface {
  countries: V3CountryInterface[] | undefined
  defaultCountries: V3CountryInterface[]
  onCountrySelection: (countryCode: string) => void
}
function CountryPickerComponent({ countries, defaultCountries, onCountrySelection }: ComponentPropsInterface) {
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredCountries, setFilteredCountries] = useState<V3CountryInterface[]>(defaultCountries)

  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const searchHotkeyDisplay = isAppleOs
    ? HOTKEYS.COUNTRY_PICKER.SEARCH.DISPLAY.APPLE
    : HOTKEYS.COUNTRY_PICKER.SEARCH.DISPLAY.DEFAULT

  const debouncedSearch = useDebouncedCallback(async (searchValue: string | null) => {
    const query = searchValue?.toLowerCase().trim()
    if (!query) {
      setFilteredCountries(defaultCountries)
      return
    }

    const filteredCountries = countries?.filter(
      (country: V3CountryInterface) =>
        country.name.toLowerCase().startsWith(query) || country.isoCode.toLowerCase() === query
    )
    setFilteredCountries(filteredCountries ?? [])
  }, 100)

  function handleSearch(value: string) {
    setSearchValue(value)
    debouncedSearch(value)
  }

  return (
    <div>
      <div className="pb-8">
        <SearchField
          hotkey={HOTKEYS.COUNTRY_PICKER.SEARCH.TRIGGER}
          hotkeyDisplay={searchHotkeyDisplay}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => {
            const newValue = event.target.value
            handleSearch(newValue)
          }}
          onClearSearch={() => handleSearch('')}
        />
      </div>
      <div className="h-[300px] overflow-y-scroll">
        {filteredCountries.length > 0 ? (
          <ul>
            {filteredCountries.map((country) => (
              <CountryPickerOption key={country.isoCode} country={country} onCountrySelection={onCountrySelection} />
            ))}
          </ul>
        ) : (
          <div className="text-gray-600">No matches found</div>
        )}
      </div>
    </div>
  )
}
