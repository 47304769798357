import { TemplateDataInterface } from '../../../hooks'
import { BASE_64_TRANSPARENT_IMAGE } from '../../../constants'
import { ArtworkInterface } from '../../ImageEditor/interfaces'

export function buildImageUrls({
  artwork,
  selectedTemplate
}: {
  artwork: ArtworkInterface
  selectedTemplate: TemplateDataInterface
}) {
  return {
    artwork: artwork.mediumImageUrl || BASE_64_TRANSPARENT_IMAGE,
    overlay: selectedTemplate.imageUrls.overlay,
    underlay: selectedTemplate.imageUrls.underlay
  }
}
