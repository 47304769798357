import NotFound from '../NotFound'
import SupportLink from '../SupportLink'
import ClipboardCopy from '../ClipboardCopy'
import LoadingSpinner from '../LoadingSpinner'
import { ProductDetailsDisplay } from './components'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { useProduct, useProductDetails, useProductSearch, useSplitToggle } from '../../hooks'

export interface ProductDetailsPropsInterface {
  currencyCode: string
  countryCode: string
  footer?: JSX.Element
  loadingConfig?: ProductDetailsLoadingPropsInterface
  sku: string
  showCountryPicker?: boolean
}

export default function ProductDetails({
  countryCode,
  currencyCode,
  footer,
  loadingConfig = {},
  sku,
  showCountryPicker = false
}: ProductDetailsPropsInterface) {
  const {
    searchResults,
    isLoading: isLoadingProductSearch,
    error: productSearchFetchError
  } = useProductSearch({ config: { revalidateOnFocus: false }, countryCode, query: sku })

  const {
    productDetails,
    isLoading: isLoadingProductDetails,
    error: productDetailsFetchError
  } = useProductDetails({ config: { revalidateOnFocus: false }, sku })

  const { splitIsOn: isCostedAttributesOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { product, productFetchError, isLoadingProduct } = useProduct(isCostedAttributesOn ? sku : undefined, {
    config: { revalidateOnFocus: false }
  })

  if (productSearchFetchError) {
    return <ProductDetailsError code={`PSFE-${sku}`} />
  }

  if (productFetchError) {
    return <ProductDetailsError code={`PAP-${sku}-${productFetchError.status ?? '0'}`} />
  }

  if (productDetailsFetchError) {
    const code = `PDFE-${sku}-${
      productDetailsFetchError.responseBodyJson?.traceParent ||
      productDetailsFetchError.status ||
      productDetailsFetchError.name
    }`
    return <ProductDetailsError code={code} />
  }

  const isLoadingData =
    isLoadingProductDetails || !productDetails || isLoadingProductSearch || !searchResults || isLoadingProduct

  if (isLoadingData) {
    return <ProductDetailsLoading {...loadingConfig} />
  }

  const skuSearchResult = searchResults.find((result) => result.sku === sku)
  if (!skuSearchResult) {
    return <ProductDetailsError code={`SSRE-${sku}`} />
  }

  return (
    <ProductDetailsDisplay
      countryCode={countryCode}
      currencyCode={currencyCode}
      footer={footer}
      product={product}
      productDetails={productDetails.product}
      skuSearchResult={skuSearchResult}
      showCountryPicker={showCountryPicker}
    />
  )
}

/* -------------------------------------------------------------------------- */
/*                              Helper components                             */
/* -------------------------------------------------------------------------- */
interface ProductDetailsLoadingPropsInterface {
  className?: string
}

function ProductDetailsLoading({ className = '' }: ProductDetailsLoadingPropsInterface) {
  return (
    <div className={`grid h-full place-content-center ${className}`}>
      <LoadingSpinner className="h-12 w-12 text-gray-600" />
    </div>
  )
}

function ProductDetailsError({ code }: { code: string }) {
  return (
    <div className="grid h-full place-content-center">
      <NotFound
        heading="Failed to load product details"
        body={
          <p className="break-words text-center">
            An error occurred while loading product details. Please try again later and{' '}
            <SupportLink>contact us</SupportLink> if this issue persists (code {code}{' '}
            <ClipboardCopy showText={false} text={code.toString()} />)
          </p>
        }
      >
        <></>
      </NotFound>
    </div>
  )
}
