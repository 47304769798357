import { useEffect, useRef, useState } from 'react'
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'

import { UploadingImageInterface } from './interfaces'
import ImageLibraryActions from '../ImageLibraryActions'
import ImageLibraryAppliedFilters from '../ImageLibraryAppliedFilters'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../hooks'
import { FullPageImageList, FullPageHeader, DropzoneUploader } from './components'

export type ImageLibraryFullPageModeType = 'select' | 'manage' | 'dropzone'

export default function ImageLibraryFullPage() {
  const { imageLibraryPages } = useImageLibraryImages()
  const [imageLibraryMode, setImageLibraryMode] = useState<ImageLibraryFullPageModeType>('select')
  const [selectedImages, setSelectedImages] = useState<ImageLibraryImageInterface[]>([])
  const [uploadingFiles, setUploadingFiles] = useState<Record<string, UploadingImageInterface>>({})

  useEffect(() => {
    if (imageLibraryMode === 'select') {
      setSelectedImages([])
    }
  }, [imageLibraryMode])

  const loadingBarRef = useRef<LoadingBarRef>(null)
  const isImageListEmpty = imageLibraryPages?.[0]?.length === 0

  useEffect(() => {
    if (isImageListEmpty) {
      setImageLibraryMode('select')
    }
  }, [isImageListEmpty])

  function handleUploadStart() {
    loadingBarRef.current?.continuousStart()
  }

  function handleUploadComplete() {
    loadingBarRef.current?.complete()
  }

  return (
    <div className="container mx-auto space-y-4 py-6 lg:py-8 xl:max-w-full">
      <h1 className="mt-0 mr-auto pb-8" data-test="image-library-page-header">
        Image library
      </h1>

      <div className="sticky top-nav-bar-height z-10 bg-gray-100 pt-2 pb-8 ">
        <FullPageHeader
          imageLibraryMode={imageLibraryMode}
          selectedImages={selectedImages}
          onHandleUploadStart={handleUploadStart}
          onHandleUploadComplete={handleUploadComplete}
          onSetImageLibraryMode={setImageLibraryMode}
          onSetUploadingFiles={setUploadingFiles}
        />

        <div className="relative mt-4 h-2 bg-gray-200 md:mt-8">
          <LoadingBar
            ref={loadingBarRef}
            color="#866FF0"
            className="h-2 will-change-transform"
            containerStyle={{ position: 'absolute', height: 'inherit' }}
            shadow={false}
          />
        </div>

        <div className={imageLibraryMode === 'manage' ? 'pointer-events-none opacity-50' : ''}>
          <ImageLibraryActions />
          <ImageLibraryAppliedFilters />
        </div>
      </div>

      <DropzoneUploader
        imageLibraryMode={imageLibraryMode}
        loadingBarRef={loadingBarRef}
        onSetImageLibraryMode={setImageLibraryMode}
        onSetUploadingFiles={setUploadingFiles}
      >
        <FullPageImageList
          imageLibraryMode={imageLibraryMode}
          selectedImages={selectedImages}
          uploadingFiles={{ ...uploadingFiles }}
          setSelectedImages={setSelectedImages}
        />
      </DropzoneUploader>
    </div>
  )
}
