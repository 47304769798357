import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import * as RadixRadioGroup from '@radix-ui/react-radio-group'
import TextField from '../TextField'
import { twMerge } from 'tailwind-merge'

interface RadioGroupItemInterface {
  label: string
  value: string
  additionalData?: Record<string, string | number>
}

interface RadioGroupPropsInterface {
  ariaLabel: string
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  id?: string
  items: RadioGroupItemInterface[]
  otherOptionConfig?: {
    className?: string
    label?: string
    max?: string | number
    maxLength?: number
    min?: string | number
    placeholder?: string
    type?: 'text' | 'number'
  }
  radioGroupItemClassName?: string
  indicatorContainerClassName?: string
  selectedValue: string
  showOtherOptionInput?: boolean
  onChange: (value: string, error?: string | null) => void
  renderLabel?: (item: RadioGroupItemInterface) => React.ReactNode
  renderOtherOption?: () => React.ReactNode
}

const OTHER_OPTION_VALUE = 'Other'

/**
 * A radio group component that allows the user to select one option from a list of options.
 *
 * Built using Radix RadioGroup (https://www.radix-ui.com/docs/primitives/components/radio-group).
 * @prop {string} ariaLabel - The aria label for the radio group.
 * @prop {boolean} autoFocus - Autofocus radio group on mount.
 * @prop {string} [className] - The class name to apply to the radio group.
 * @prop {{label: string, value: string}[], additionalData?: <Record, string | number>} items - The items to display in the radio group.
 * @prop {boolean} showOtherOptionTextInput - Whether or not to show a text input when the user selects the "Other" option.
 * @prop {string} otherOptionConfig - Config for the "Other" option. Includes the placeholder text and max length for the text input.
 * @prop {(value: string) => void} onChange - The function to call when the user selects an item.
 */
export default function RadioGroup({
  ariaLabel,
  autoFocus = false,
  className = '',
  disabled = false,
  items,
  radioGroupItemClassName = 'items-start',
  indicatorContainerClassName = '',
  selectedValue,
  showOtherOptionInput = false,
  otherOptionConfig = {},
  onChange,
  renderLabel,
  renderOtherOption
}: RadioGroupPropsInterface) {
  const otherInputRef = useRef<HTMLInputElement>(null)
  const [otherSelected, setOtherSelected] = useState(false)

  function onValueChange(value: string) {
    if (value === OTHER_OPTION_VALUE) {
      setOtherSelected(true)
      onChange('Other')
      return
    }
    if (otherSelected) {
      setOtherSelected(false)
    }
    onChange(value)
  }

  useLayoutEffect(() => {
    if (otherSelected && otherInputRef.current) {
      otherInputRef?.current?.focus()
    }
  }, [otherSelected, otherInputRef])

  useEffect(() => {
    if (!selectedValue) {
      return
    }
    if (!items.some((item) => item.value === selectedValue)) {
      setOtherSelected(true)
    }
  }, [items, selectedValue, setOtherSelected])

  return (
    <>
      <RadixRadioGroup.Root
        aria-label={ariaLabel}
        onValueChange={onValueChange}
        className="tailwind"
        disabled={disabled}
      >
        <div className={twMerge(`flex flex-col -space-y-px`, className)}>
          {items.map((radioItem) => (
            <RadixRadioGroup.Item
              autoFocus={Boolean(radioItem.value === selectedValue && autoFocus)}
              checked={!otherSelected && selectedValue === radioItem.value}
              className={twMerge(
                'group flex w-full space-x-5 py-2 duration-150 group-hover:cursor-pointer radix-state-checked:z-10 radix-state-checked:text-black',
                radioGroupItemClassName
              )}
              id={radioItem.value}
              key={radioItem.value}
              value={radioItem.value}
            >
              <div
                className={twMerge(
                  'mt-1 h-6 w-6 flex-shrink-0 rounded-full border group-radix-state-checked:border-transparent group-radix-state-checked:bg-purple-400 group-radix-state-unchecked:border-gray-400',
                  indicatorContainerClassName
                )}
              >
                <RadixRadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-2 after:w-2 after:rounded-lg after:bg-white" />
              </div>
              {renderLabel ? (
                renderLabel(radioItem)
              ) : (
                <label className="cursor-pointer break-words text-left" htmlFor={radioItem.value}>
                  {radioItem.label}
                </label>
              )}
            </RadixRadioGroup.Item>
          ))}

          {showOtherOptionInput && (
            <RadixRadioGroup.Item
              checked={otherSelected}
              className="group flex w-full items-center space-x-5 py-2 duration-150 group-hover:cursor-pointer radix-state-checked:z-10 radix-state-checked:text-black"
              id={OTHER_OPTION_VALUE}
              key={OTHER_OPTION_VALUE}
              value={OTHER_OPTION_VALUE}
            >
              <div className="mt-1 h-6 w-6 flex-shrink-0 rounded-full border group-radix-state-checked:border-transparent group-radix-state-checked:bg-purple-400 group-radix-state-unchecked:border-gray-400">
                <RadixRadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-2 after:w-2 after:rounded-lg after:bg-white" />
              </div>

              <label className="cursor-pointer break-words text-left" htmlFor={OTHER_OPTION_VALUE}>
                {otherOptionConfig.label ?? 'Other'}
              </label>
            </RadixRadioGroup.Item>
          )}
        </div>
      </RadixRadioGroup.Root>

      {otherSelected &&
        showOtherOptionInput &&
        (renderOtherOption ? (
          renderOtherOption()
        ) : (
          <TextField
            dataTest="radioGroupOther"
            {...otherOptionConfig}
            hasError={Boolean(
              otherSelected &&
                otherOptionConfig.maxLength &&
                selectedValue &&
                selectedValue.length > otherOptionConfig.maxLength
            )}
            ref={otherInputRef}
            value={selectedValue === 'Other' && otherSelected ? '' : selectedValue}
            onChange={(e) => {
              const { value } = e.target
              if (value === '') {
                onChange('Other')
                return
              }
              if (otherOptionConfig.maxLength && value.length > otherOptionConfig.maxLength) {
                onChange(value, `Must be ${otherOptionConfig.maxLength} characters or less`)
                return
              }
              onChange(e.target.value)
            }}
          />
        ))}
    </>
  )
}
