import { DimensionsInterface } from '../interfaces'

type Props = {
  printDpi: number
  imageQuality: number
  supportsTiling: boolean
  artworkDimensionsInPx: DimensionsInterface
  printDimensionsInPx: DimensionsInterface
}

export function RecommendedResolution(props: Props) {
  const { printDpi, artworkDimensionsInPx, printDimensionsInPx } = props

  const hasArtwork = artworkDimensionsInPx.width !== 0 && artworkDimensionsInPx.height !== 0

  return (
    <div className="grid max-w-[320px] grid-cols-2 gap-2 text-sm text-gray-300">
      {hasArtwork && (
        <>
          <div>Uploaded image:</div>
          <div>
            {artworkDimensionsInPx.width}x{artworkDimensionsInPx.height} px
          </div>
        </>
      )}

      <span>{props.supportsTiling ? 'Full product resolution:' : 'Recommended:'}</span>
      <div className="flex flex-col">
        <p>
          {printDimensionsInPx.width}x{printDimensionsInPx.height} px
        </p>
        <p>({printDpi} dpi)</p>
      </div>

      {hasArtwork && (
        <>
          <div>Print quality: </div>
          {props.imageQuality >= 100 ? (
            <div className="text-green-600">Excellent</div>
          ) : props.imageQuality >= 50 ? (
            <div className="text-status-orange">Acceptable</div>
          ) : (
            <div className="text-red-500">Poor</div>
          )}
        </>
      )}
    </div>
  )
}
