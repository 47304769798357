import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ReactNode, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import { ModalHeader } from './components'
import { PRODIGI_TOAST_CLASSNAME } from '../../constants'
import { PORTAL_ID } from '../../constants/portalId.const'

export interface ModalPropsInterface {
  children: ReactNode
  className?: string
  closeOnEscape?: boolean
  closeOnInteractionOutside?: boolean
  open: boolean
  showBorder?: boolean
  showHeader?: boolean
  subtitle?: string | null | React.ReactNode
  title?: string | null
  setOpen?: (isOpen: boolean) => void
}

export default function Modal({
  children,
  className = '',
  closeOnInteractionOutside = true,
  closeOnEscape = true,
  open,
  showBorder = true,
  showHeader = true,
  subtitle = null,
  title = null,
  setOpen
}: ModalPropsInterface) {
  const [triggerElement, setTriggerElement] = useState<Element | null>(null)

  return (
    <DialogPrimitive.Root open={open} onOpenChange={setOpen}>
      <AnimatePresence>
        {open && (
          <DialogPrimitive.Portal container={document.getElementById(PORTAL_ID)} forceMount key="modal">
            <motion.div
              animate={{ opacity: 0.9 }}
              className="fixed inset-0 bg-gray-900"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key="overlay"
            />
            <div className="fixed inset-0 flex items-center justify-center">
              <div className={clsx(showBorder ? 'max-h-[95vh] max-w-[95%]' : '')}>
                <DialogPrimitive.Content
                  asChild
                  onCloseAutoFocus={() => {
                    // prettier-ignore
                    (triggerElement as HTMLElement | null)?.focus?.()
                    document.body.style.overflow = 'unset'
                  }}
                  onEscapeKeyDown={(event) => {
                    if (!closeOnEscape) {
                      event.preventDefault()
                    }
                  }}
                  onInteractOutside={(event) => {
                    if (!closeOnInteractionOutside) {
                      event.preventDefault()
                      return
                    }

                    const toastElements = document.getElementsByClassName(PRODIGI_TOAST_CLASSNAME)
                    for (let i = 0; i < toastElements.length; i++) {
                      const interactionWasOnToast = toastElements[i].contains(event.target as Node)
                      if (interactionWasOnToast) {
                        event.preventDefault()
                        break
                      }
                    }
                  }}
                  onOpenAutoFocus={() => {
                    document.body.style.overflow = 'hidden'
                    setTriggerElement(document.activeElement)
                  }}
                >
                  <motion.div
                    animate={{ opacity: 1, y: 0 }}
                    className={twMerge(
                      clsx(
                        'max-h-95vh overflow-hidden rounded-lg bg-white shadow-xl',
                        showBorder ? 'px-8 pb-8 md:px-12 md:pb-12' : ''
                      ),
                      className
                    )}
                    exit={{ opacity: 0, y: 10 }}
                    initial={{ opacity: 0, y: 10 }}
                    key="content"
                    transition={{ duration: 0.25 }}
                  >
                    {showHeader && <ModalHeader subtitle={subtitle} title={title} onClose={() => setOpen?.(false)} />}
                    {children}
                  </motion.div>
                </DialogPrimitive.Content>
              </div>
            </div>
          </DialogPrimitive.Portal>
        )}
      </AnimatePresence>
    </DialogPrimitive.Root>
  )
}
