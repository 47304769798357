// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function ApiIcon ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="30" cy="30" r="30" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17 28.5948L22.2431 33.8378L23.5907 32.4902L19.6953 28.5948L23.5907 24.6993L22.2431 23.3517L17 28.5948Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.0435 24.6995L39.9389 28.5949L36.0435 32.4899L37.3911 33.838L42.6342 28.5949L37.3911 23.3518L36.0435 24.6995Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3385 17.9999L25.4763 38.5864L27.2849 39.1895L34.1471 18.603L32.3385 17.9999Z" />
    </svg>
  )
}
