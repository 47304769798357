// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'
import {
  SUBMIT_CSV_ORDERS,
  SUBMIT_CSV_ORDERS_SUCCESS,
  SUBMIT_CSV_ORDERS_ERROR,
  CREATE_CSV_ORDER_SUCCESS,
  CREATE_CSV_ORDER_ERROR,
  SUBMIT_CSV_ORDER_SUCCESS,
  SUBMIT_CSV_ORDER_ERROR,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_ERROR,
  ADD_IMAGES_TO_CSV_ORDER_SUCCESS,
  ADD_IMAGES_TO_CSV_ORDER_ERROR,
  SELECT_PRODUCT_FOR_CSV_ORDER,
  COPY_SHIPPING_METHOD,
  COPY_PRODUCT_DETAILS
} from '../../../actions/csvUpload'
import {
  CSV_PACKING_SLIP_UPLOAD_SUCCESS,
  CSV_PACKING_SLIP_UPLOAD_ERROR
} from '../../../actions/csvUpload/uploadPackingSlip'

export function csvUpload (action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case UPLOAD_CSV_SUCCESS: {
      return ['Upload csv success', { resp: { ...action.payload } }]
    }

    case UPLOAD_CSV_ERROR: {
      return ['Upload csv error', { resp: { ...action.payload } }, 'error']
    }

    case SUBMIT_CSV_ORDERS: {
      return ['Submit csv orders']
    }

    case SUBMIT_CSV_ORDERS_SUCCESS: {
      return ['Submit csv orders success']
    }

    case SUBMIT_CSV_ORDERS_ERROR: {
      const {type, ...props} = action
      return ['Submit csv orders failed', { ...props }, 'error']
    }

    case CREATE_CSV_ORDER_SUCCESS: {
      return ['Create csv order success', { pwintyOrderId: action.pwintyOrderId }]
    }

    case CREATE_CSV_ORDER_ERROR: {
      const { errorMessage, statusCode } = action
      return ['Create csv order error', { errorMessage, statusCode }, 'error']
    }

    case ADD_IMAGES_TO_CSV_ORDER_SUCCESS: {
      return ['Add images to csv order success', { pwintyOrderId: action.pwintyOrderId }]
    }

    case ADD_IMAGES_TO_CSV_ORDER_ERROR: {
      const { errorMessage, statusCode, pwintyOrderId } = action
      return ['Add images to csv order error', { errorMessage, statusCode, pwintyOrderId }, 'error']
    }

    case SUBMIT_CSV_ORDER_SUCCESS: {
      return ['Submit csv order success', { pwintyOrderId: action.pwintyOrderId }]
    }

    case SUBMIT_CSV_ORDER_ERROR: {
      const { errorMessage, statusCode, pwintyOrderId } = action
      return ['Submit csv order failed', { errorMessage, statusCode, pwintyOrderId }, 'error']
    }

    case SELECT_PRODUCT_FOR_CSV_ORDER: {
      const { product, selectedAttributes } = action
      return ['Select product - csv uploader', { sku: product.sku, selectedAttributes }, 'info']
    }

    case COPY_PRODUCT_DETAILS: {
      const { type, ...restProps } = action
      return ['Copy product details - csv uploader', { ...restProps }, 'info']
    }

    case COPY_SHIPPING_METHOD: {
      const { type, ...restProps } = action
      return ['Copy shipping method - csv uploader', { ...restProps }, 'info']
    }

    case CSV_PACKING_SLIP_UPLOAD_SUCCESS: {
      return ['Upload csv packing slip success', { resp: { ...action.payload } }]
    }

    case CSV_PACKING_SLIP_UPLOAD_ERROR: {
      return ['Upload csv packing slip error', { resp: { ...action.payload.errorMessage } }, 'error']
    }

    default: {
      return null
    }
  }
}
