// @flow

import type { RsaaStatusProps, CatalogueItem, ThunkAsync, Event } from '../../../../types'
import type { Location, HistoryAction, RouterHistory } from 'react-router'

import React, { PureComponent } from 'react'
import SelectProductLayout from './SelectProductLayout'
import instruments, { MOF } from '../../../../helpers/instruments'

const SELECT_TIMER_NAME = 'Select a product'
const EXIT_TIMER_NAME = 'Exit product selection'
const ADD_IMAGE_PAGE_URL = '/orders/create/add-image'

export type SelectProductProps = {|
  searching: boolean,
  fetchingPrices: boolean,
  showFacets: boolean,
  isSearchInconclusive: boolean,
  facetsLoading: boolean,
  selectedMobileFacetScreen: string,
  searchItems: ?(CatalogueItem[]),
  basketItemCount: number,
  addBasketItem: (sku: string) => ThunkAsync<void>,
  redirect: (path: string) => void,
  searchProductsBySkuListRsaaStatus: RsaaStatusProps,
  location: Location,
  history: RouterHistory
|}

type SelectProductState = {|
  removeHistoryListener: ?() => void
|}

export default class SelectProduct extends PureComponent<SelectProductProps, SelectProductState> {
  state: SelectProductState = { removeHistoryListener: null }

  componentDidMount() {
    window.analytics.page('MOF', 'Search')

    this.setupTimers()
    this.addHistoryListener()
  }

  componentWillUnmount() {
    this.state.removeHistoryListener && this.state.removeHistoryListener()
  }

  addHistoryListener: () => void = () => {
    const removeHistoryListener = this.props.history.listen(this.onHistoryChanged)
    this.setState({ removeHistoryListener })
  }

  setupTimers() {
    instruments.timer(SELECT_TIMER_NAME).addCategory(MOF)
    instruments.timer(SELECT_TIMER_NAME).start()
    instruments.timer(EXIT_TIMER_NAME).addCategory(MOF)
    instruments.timer(EXIT_TIMER_NAME).start()
  }

  onHistoryChanged: (location: Location, action: HistoryAction) => void = (
    location: Location,
    action: HistoryAction
  ) => {
    if (!location.pathname.includes('/orders/create')) {
      instruments.timer(EXIT_TIMER_NAME).complete()
    }
  }

  onSelectProduct: (e: Event) => void = (e: Event) => {
    instruments.timer(SELECT_TIMER_NAME).complete()
    this.props.addBasketItem(e.currentTarget.value)
    this.props.redirect(ADD_IMAGE_PAGE_URL)
  }

  onClickBasket: () => void = () => {
    this.props.history.push('/orders/create/add-image')
  }

  onClickBack: () => void = () => {
    this.props.history.replace('/orders/create/select-country')
  }

  getShowLoading: () => boolean = (): boolean =>
    this.props.searchProductsBySkuListRsaaStatus.loading || this.props.facetsLoading || this.props.searching

  getShowRecentItems: () => boolean = (): boolean =>
    !(this.props.isSearchInconclusive || (this.props.searchItems && this.props.searchItems.length > 0))

  render(): React$Node {
    return (
      <SelectProductLayout
        selectedMobileFacetScreen={this.props.selectedMobileFacetScreen}
        searchItems={this.props.searchItems}
        basketItemCount={this.props.basketItemCount}
        fetchingPrices={this.props.fetchingPrices}
        showLoading={this.getShowLoading()}
        showRecentItems={this.getShowRecentItems()}
        showFacets={this.props.showFacets}
        isSearchInconclusive={this.props.isSearchInconclusive}
        onSelectProduct={this.onSelectProduct}
        onClickBasket={this.onClickBasket}
        onClickBack={this.onClickBack}
        pathname={this.props.location.pathname}
        search={this.props.location.search}
      />
    )
  }
}
