import { CountryPicker } from '../CountryPicker'
import Modal from '../Modal'

interface CountryPickerModalPropsInterface {
  open: boolean
  setOpen: (open: boolean) => void
  onCountrySelection: (countryCode: string) => void
}

export function CountryPickerModal({ open, setOpen, onCountrySelection }: CountryPickerModalPropsInterface) {
  return (
    <Modal open={open} title="Choose a country" setOpen={setOpen}>
      <div className="max-w-3xl sm:min-w-[500px]">
        <CountryPicker onCountrySelection={onCountrySelection} />
      </div>
    </Modal>
  )
}
