// @flow
import React from 'react'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import ProductSearchResults from './ProductSearchResults'
import RecentItemList from '../RecentItemList'
import FacetNavigation from '../FacetNavigation'
import ProductSearch from '../ProductSearch'
import type { CatalogueItem, Event } from '../../../../types'
import styles from './SelectProduct.module.css'
import Page from '../../../../components/Page'

// $FlowFixMe: TypeScript file
import ProductImage from './ProductImage'

type SelectProductLayoutProps = {|
  fetchingPrices: boolean,
  showLoading: boolean,
  showFacets: boolean,
  showRecentItems: boolean,
  isSearchInconclusive: boolean,
  selectedMobileFacetScreen: string,
  searchItems: ?(CatalogueItem[]),
  basketItemCount: number,
  onSelectProduct: (e: Event) => void,
  onClickBasket: () => void,
  onClickBack: () => void,
  pathname: string,
  search: string
|}

export default function SelectProductLayout(props: SelectProductLayoutProps): React$Node {
  if (props.selectedMobileFacetScreen) {
    return <FacetNavigation />
  }

  const { basketItemCount } = props

  return (
    <Page
      title="Products"
      primaryAction={{
        title: `View basket - ${basketItemCount} ${basketItemCount === 1 ? 'item' : 'items'}`,
        onClick: props.onClickBasket,
        disabled: basketItemCount === 0
      }}
      showActionsInFooter={true}
    >
      <ProductSearch />

      <div className={props.showFacets ? styles.mainGrid : styles.mainGridNoFacets}>
        <div>
          <ProductImage />
          <FacetNavigation />
        </div>

        {props.showLoading ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            {props.showRecentItems && <RecentItemList />}

            <ProductSearchResults
              fetchingPrices={props.fetchingPrices}
              isSearchInconclusive={props.isSearchInconclusive}
              searchItems={props.searchItems}
              onSelectProduct={props.onSelectProduct}
            />
          </React.Fragment>
        )}
      </div>
    </Page>
  )
}
