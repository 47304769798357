// @flow
import { SQUARE, LANDSCAPE, PORTRAIT } from '../../data/orientation'
import type { Orientation } from '../../types'

export function determineImageOrientation (imageWidth: number, imageHeight: number): Orientation {
  const imageAspectRatio = imageWidth / imageHeight

  if (imageAspectRatio === 1) {
    return SQUARE
  } else if (imageAspectRatio > 1) {
    return LANDSCAPE
  } else {
    return PORTRAIT
  }
}
