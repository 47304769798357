// @flow
import { calcScaleFactorAdjustment } from './calcScaleFactorAdjustment'
import { SCALE_TYPE } from '@prodigi-group/components-image-editor'
import { convertFillScaleToPrintSizeScale } from './convertFillScaleToPrintSizeScale'
import type {
  ImageEditorTransformations,
  Dimensions,
  CropRectangle
} from '../../types'

export function calcQuality (
  transformations: ImageEditorTransformations,
  artworkDimensionsInPx: Dimensions,
  printDimensionsInPx: Dimensions,
  cropRectangle: CropRectangle,
  printDpi: number
): number {
  const { scaleFactor, borderUnit, borderWidth, scaleType } = transformations

  const printSizeScaleFactor = scaleType === SCALE_TYPE.PRINT_SIZE
    ? scaleFactor
    : convertFillScaleToPrintSizeScale(
      scaleFactor,
      printDpi,
      borderUnit,
      borderWidth,
      printDimensionsInPx,
      artworkDimensionsInPx,
      cropRectangle
    )

  const scaleFactorAdjustment = calcScaleFactorAdjustment(
    printDimensionsInPx,
    artworkDimensionsInPx,
    cropRectangle,
    printDpi,
    borderWidth,
    borderUnit
  )

  const adjustedScaleFactor = printSizeScaleFactor / scaleFactorAdjustment

  const imageDpi = Math.min(printDpi / adjustedScaleFactor, printDpi)

  return Math.round(imageDpi / printDpi * 100)
}
