// @flow
import type { Action, Thunk, DispatchFunc, GetStateFunc } from '../../types'
import type { UpdateCurrentView, SaveOrderListPage } from '../types'
import { selectV4Orders, selectOrderFilterLimit, selectOrders } from '../../selectors/orders'
import { sortStringifiedNumbersDescending } from '../../helpers/array'
import { useV4SearchOrderEndpoint } from '../../helpers/featureFlags'

export const DISCARD_SAVED_ORDER_LIST_PAGES = 'DISCARD_SAVED_ORDER_LIST_PAGES'
export const UPDATE_CURRENT_VIEW = 'UPDATE_CURRENT_VIEW'
export const SAVE_ORDER_LIST_PAGE = 'SAVE_ORDER_LIST_PAGE'

export function saveOrderIds (orderIds: string[], page: number): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    dispatch(updateCurrentView(orderIds))
    dispatch(saveOrderListPage(parseInt(page, 10), orderIds))
  }
}

export function updateCurrentView (keys: string[]): UpdateCurrentView {
  return {
    type: UPDATE_CURRENT_VIEW,
    currentView: keys
  }
}

export function saveOrderListPage (pageNumber: number, ids: string[]): SaveOrderListPage {
  return {
    type: SAVE_ORDER_LIST_PAGE,
    ids,
    pageNumber
  }
}

export function discardSavedOrderListPages (): Action {
  return {
    type: DISCARD_SAVED_ORDER_LIST_PAGES
  }
}

export function refreshFirstOrderListPage (): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const limit = selectOrderFilterLimit(getState())
    const allOrderIds = useV4SearchOrderEndpoint()
      ? Object.keys(selectV4Orders(getState()))
      : Object.keys(selectOrders(getState()))
    const sortedIds = sortStringifiedNumbersDescending(allOrderIds)
    const firstPageOrderIds = sortedIds.slice(0, limit)

    dispatch(saveOrderIds(firstPageOrderIds, 0))
  }
}
