// @flow

import type { CustomerAddress } from '../../types'
import { isEmailValid } from './isEmailValid'
import { isPostcodeValid } from './isPostcodeValid'
import { countriesWithRequiredCounty } from '../../data'
import { isPostcodeRequired } from './isPostcodeRequired'

const COUNTRIES_WHERE_PHONE_NUMBER_IS_REQUIRED = [
  'SA'
]

export function isAddressValid (address: CustomerAddress): boolean {
  return Boolean(
    areAllRequiredFieldsCompleted(address) &&
    isEmailValid(address.email) &&
    (!isPostcodeRequired(address.countryCode) || isPostcodeValid(address.countryCode, address.postcode))
  )
}

export function areAllRequiredFieldsCompleted ({
  address1,
  name,
  town,
  county,
  countryCode,
  postcode,
  mobileTelephone,
  email
}: CustomerAddress): boolean {
  return Boolean(
    address1 &&
    name &&
    town &&
    (!isPhoneNumberRequired(countryCode) || mobileTelephone) &&
    (!isCountyOrStateRequired(countryCode) || county) &&
    (!isPostcodeRequired(countryCode) || postcode)
  )
}

export function isPhoneNumberRequired (countryCode: string): boolean {
  return COUNTRIES_WHERE_PHONE_NUMBER_IS_REQUIRED.includes(countryCode.toUpperCase())
}

export function isCountyOrStateRequired (countryCode: string): boolean {
  return countriesWithRequiredCounty.includes(countryCode)
}
