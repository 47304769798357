import { DictionaryInterface } from '../interfaces'

interface CountyLabelInterface {
  countryCode: string
  label: string
}

export const COUNTY_LABELS: DictionaryInterface<CountyLabelInterface> = {
  Croatia: {
    countryCode: 'HR',
    label: 'County'
  },
  Ireland: {
    countryCode: 'IE',
    label: 'County'
  },
  Norway: {
    countryCode: 'NO',
    label: 'County'
  },
  Sweden: {
    countryCode: 'SE',
    label: 'County'
  },
  UK: {
    countryCode: 'GB',
    label: 'County'
  },
  Canada: {
    countryCode: 'CA',
    label: 'Province'
  },
  China: {
    countryCode: 'CN',
    label: 'Province'
  },
  Netherlands: {
    countryCode: 'NL',
    label: 'Province'
  },
  Turkey: {
    countryCode: 'TR',
    label: 'Province'
  },
  Portugal: {
    countryCode: 'PT',
    label: 'District'
  },
  Belgium: {
    countryCode: 'BE',
    label: 'Region'
  },
  'Czech Republic': {
    countryCode: 'CZ',
    label: 'Region'
  },
  Denmark: {
    countryCode: 'DK',
    label: 'Region'
  },
  Finland: {
    countryCode: 'FI',
    label: 'Region'
  },
  France: {
    countryCode: 'FR',
    label: 'Region'
  },
  Italy: {
    countryCode: 'IT',
    label: 'Region'
  }
}
