import { isNoImageProduct } from './isNoImageProduct.helper'
import { ATTRIBUTES, UNAVAILABLE_PRODUCT_TYPES } from '../constants'
import { AzureSearchResultInterface, CatalogueItemInterface, DictionaryInterface } from '../interfaces'

export function sortAndFilterSearchResults(
  searchResult: AzureSearchResultInterface[],
  searchQuery: string
): CatalogueItemInterface[] {
  const validProducts = removeMisconfiguredProducts(searchResult)
  const filtered = filterOutUnavailableProducts(validProducts)
  const sorted = sortSearchResultsByScore(filtered, searchQuery)
  return mapCatalogueItems(sorted)
}

function removeMisconfiguredProducts(searchResult: AzureSearchResultInterface[]): AzureSearchResultInterface[] {
  return searchResult.filter((product) => product.description)
}

function filterOutUnavailableProducts(searchResult: AzureSearchResultInterface[]): AzureSearchResultInterface[] {
  return searchResult.filter((entry: AzureSearchResultInterface) => isAvailableProduct(entry.productType))
}

function isAvailableProduct(productType: string): boolean {
  return !Object.values(UNAVAILABLE_PRODUCT_TYPES).includes(productType)
}

function sortSearchResultsByScore(
  searchResult: AzureSearchResultInterface[],
  query: string
): AzureSearchResultInterface[] {
  const searchResultCopy = JSON.parse(JSON.stringify(searchResult))

  const sortedResultsWithExactMatch = searchResultCopy
    .filter((result: { description: string }) =>
      query.split(' ').every((queryWord) => result.description.toLowerCase().indexOf(queryWord) > -1)
    )
    .sort(
      (prevEl: { [x: string]: number }, nextEl: { [x: string]: number }) =>
        nextEl['@search.score'] - prevEl['@search.score']
    )

  const sortedResultsWithoutExactMatch = searchResultCopy
    .filter((result: { description: string }) =>
      query.split(' ').some((queryWord) => result.description.toLowerCase().indexOf(queryWord) === -1)
    )
    .sort(
      (prevEl: { [x: string]: number }, nextEl: { [x: string]: number }) =>
        nextEl['@search.score'] - prevEl['@search.score']
    )

  return sortedResultsWithExactMatch.concat(sortedResultsWithoutExactMatch)
}

export function mapCatalogueItems(searchResults: AzureSearchResultInterface[]): CatalogueItemInterface[] {
  return searchResults.map((searchResultItem) => {
    return {
      sku: searchResultItem.sku.toUpperCase(),
      productionCountries: searchResultItem.productionCountries,
      productWidthMm: searchResultItem.productWidthMm,
      productHeightMm: searchResultItem.productHeightMm,
      category: searchResultItem.category,
      shortcode: searchResultItem.shortcode,
      basePriceFrom: searchResultItem.basePriceFrom,
      currency: searchResultItem.priceCurrency,
      description: searchResultItem.description,
      productType: searchResultItem.productType,
      attributes: mapAttributes(searchResultItem),
      noImageProduct: isNoImageProduct(searchResultItem.productType),
      optimumDpi: searchResultItem.optimumDpi
    }
  })
}

function mapAttributes(searchResultItem: AzureSearchResultInterface): DictionaryInterface<string[]> {
  return Object.entries(searchResultItem).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (
      attributeAcc: DictionaryInterface<string[]>,
      [searchPropName, searchPropValue]: [string, unknown]
    ): DictionaryInterface<string[]> => {
      if (Array.isArray(searchPropValue) && isAttribute(searchPropName, searchPropValue)) {
        attributeAcc[searchPropName] = searchPropValue.filter(Boolean)
      }

      return attributeAcc
    },
    {}
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAttribute(searchPropName: string, searchPropValue: any): boolean {
  return Boolean(ATTRIBUTES.includes(searchPropName) && searchPropValue.length && searchPropValue.some(Boolean))
}
