// @flow
import React, { PureComponent } from 'react'
import styles from './CsvOpenModalButton.module.css'
import Button from '../../../../components/Button'
import LinkButton from '../../../../components/LinkButton'
import cx from 'classnames'

type Props = {|
  text: string,
  returnValue?: string,
  isCompleted: boolean,
  dataTest?: string,
  onClick: (returnValue?: string) => void
|}

export default class CsvOpenModalButton extends PureComponent <Props> {
  render (): React$Node {
    const { text, returnValue, onClick, isCompleted, dataTest } = this.props
    return isCompleted
      ? (
        <LinkButton
          className={cx(styles.modalButton, styles.linkButton)}
          dataTest={dataTest}
          onClick={() => onClick(returnValue)}>
          {text}
        </LinkButton>
      )
      : (
        <Button
          className={styles.modalButton}
          dataTest={dataTest}
          onClick={() => onClick(returnValue)}>
          {text}
        </Button>
      )
  }
}
