// @flow
import type { MultiAssetTemplates, Orientation } from '../../types'
import { SQUARE } from '../../data/orientation'
import { values } from '../dictionary'

export function isImageRotationRequired (templates: MultiAssetTemplates, imageOrientation: Orientation, printAreaName: string): boolean {
  return (
    imageOrientation !== SQUARE &&
    !isProductSquare(templates, printAreaName) &&
    !hasTemplateWithMatchingOrientation(templates, imageOrientation, printAreaName)
  )
}

function hasTemplateWithMatchingOrientation (templates: MultiAssetTemplates, orientation: Orientation, printAreaName: string): boolean {
  return Boolean(templates.printAreas[printAreaName].orientations[orientation])
}

function isProductSquare (templates: MultiAssetTemplates, printAreaName: string): boolean {
  const templatesByOrientation = templates.printAreas[printAreaName].orientations
  const templatesAsArray = values(templatesByOrientation)
  const firstAvailableTemplate = templatesAsArray.find((template) => template !== null)

  return Boolean(
    firstAvailableTemplate &&
    firstAvailableTemplate.printResolution.height === firstAvailableTemplate.printResolution.width
  )
}
