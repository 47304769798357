// @flow

import React from 'react'
import type { Node } from 'react'
import { SplitFactory } from '@splitsoftware/splitio-react'

// $FlowFixMe: TypeScript file
import { getSplitIOConfig } from '../../split-io/config'

type Props = {|
  children: React$Node
|}

export default function SplitWrapper({ children }: Props): Node {
  return (
    <SplitFactory config={getSplitIOConfig()} updateOnSdkTimedout={true}>
      {children}
    </SplitFactory>
  )
}
