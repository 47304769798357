import {
  OrderDetailShipmentDisplayHeader,
  OrderDetailShipmentDisplayItems,
  OrderDetailUnallocatedDisplayItems
} from './components'
import { OrderStatusEnum, ShipmentStatus } from '../../enums'
import { ShipmentStatusTracker } from './components/ShipmentStatusTracker.component'
import { OrderDetailShipmentInterface, OrderDetailItemInterface } from '../../interfaces'

interface OrderDetailShipmentDisplayPropsInterface {
  countryCode: string
  items: OrderDetailItemInterface[]
  shipments: OrderDetailShipmentInterface[]
  orderStatus: OrderStatusEnum
  unallocatedItems: OrderDetailItemInterface[]
}

export default function OrderDetailShipmentDisplay({
  countryCode,
  items,
  orderStatus,
  shipments,
  unallocatedItems
}: OrderDetailShipmentDisplayPropsInterface) {
  return (
    <div>
      {shipments.map((shipment, index) => {
        const filteredItemsInShipment = items.filter((item) =>
          shipment.itemsInShipment.map(({ itemId }) => itemId).includes(item.id)
        )
        const skusInShipment = filteredItemsInShipment.map((item) => item.prodigiSku)

        return (
          <div
            className={`${index === shipments.length - 1 && !unallocatedItems?.length ? 'border-b' : ''}`}
            key={shipment.id}
            data-test="shipment-display"
          >
            <OrderDetailShipmentDisplayHeader
              countryCode={countryCode}
              hasUnallocated={unallocatedItems?.length !== 0}
              orderAllocationDateString={shipment.allocationDate}
              shipment={shipment}
              shipmentCount={shipments.length}
              shipmentIndex={index}
              skusInShipment={skusInShipment}
            />

            {orderStatus === OrderStatusEnum.IN_PROGRESS &&
              shipment.status === ShipmentStatus.IN_PROGRESS &&
              shipment.allocationDate && (
                <div className="flex gap-3 border-l border-r border-b bg-gray-50 p-6">
                  <ShipmentStatusTracker
                    countryCode={countryCode}
                    orderAllocationDateString={shipment.allocationDate}
                    skusInShipment={skusInShipment}
                  />
                </div>
              )}

            <OrderDetailShipmentDisplayItems
              items={filteredItemsInShipment}
              itemsInShipment={shipment.itemsInShipment}
            />
          </div>
        )
      })}

      {unallocatedItems?.length > 0 && (
        <div className="border-b" data-test="unallocated-display">
          <header className="bg-white">
            <div className="flex w-full justify-between border border-purple-200 bg-purple-100 p-6">
              <h2 className="mt-0 text-lg text-gray-700" data-test="shipment-header__awaiting">
                Awaiting shipment allocation
              </h2>
            </div>
          </header>
          <OrderDetailUnallocatedDisplayItems items={unallocatedItems} />
        </div>
      )}
    </div>
  )
}
