// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function ArrowRight ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0L10.9 1.1L16.9 7.2H0L0 8.8H16.9L10.9 14.9L12 16L20 8L12 0Z" />
    </svg>
  )
}
