// @flow
import React from 'react'

import type { Cancel } from '../../helpers/promise'
import { makeCancellable } from '../../helpers/promise'
import ImageUploadToPigView from './ImageUploadToPigView'
import readableFileSize from '../../helpers/readableFileSize'
import uploadImageToPig from '../../helpers/uploadImageToPig'
import type { PigUploadResponse } from '../../types/pigUploadResponse'

type Props = {|
  autoFocusButton?: boolean,
  defaultText?: string,
  errorText?: string,
  uploadingText?: string,
  onUploadComplete: (fileName: string, fileSize: string, previewImageUrl: string, fullImageUrl: string) => void
|}

type State = {|
  cancel: ?Cancel,
  fileName: string,
  fileSize: string,
  fullImageUrl: string,
  fileUploadKey: string,
  hasError: boolean,
  isHovering: boolean,
  isUploading: boolean,
  previewImageUrl: string,
|}

class ImageUploadToPigController extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      cancel: null,
      fileName: '',
      fileSize: '',
      previewImageUrl: '',
      fullImageUrl: '',
      fileUploadKey: Math.random().toString(),
      hasError: false,
      isHovering: false,
      isUploading: false
    }
  }

  updateStatus: ((uploadingStatus: boolean, errorStatus: boolean) => void) = (uploadingStatus: boolean, errorStatus: boolean) => {
    this.setState({
      hasError: errorStatus,
      isUploading: uploadingStatus
    })
  }

  handleImageUpload: ((event: SyntheticInputEvent<HTMLInputElement>) => void) = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const fileSelected = event.target.files[0]
    if (!fileSelected) return

    const formData = new FormData()
    formData.append('file', fileSelected)
    this.setState({
      fileName: fileSelected.name,
      fileSize: readableFileSize(fileSelected.size)
    })

    const cancellablePromise = makeCancellable(() => uploadImageToPig(formData))

    this.setState({cancel: cancellablePromise.cancel})
    cancellablePromise
      .run(this.updateStatus(true, false))
      .then(this.processResult)
      .catch((err) => {
        console.error(err)
        this.onError()
      })
  }

  processResult: ((uploadedImage: ?PigUploadResponse) => void) = (uploadedImage: ?PigUploadResponse) => {
    if (uploadedImage && uploadedImage.preview && uploadedImage.full) {
      this.setState({
        previewImageUrl: uploadedImage.preview,
        fullImageUrl: uploadedImage.full
      })
      this.onSuccess()
    } else {
      this.onError()
    }
  }

  onSuccess: (() => void) = () => {
    const {
      fileName,
      fileSize,
      previewImageUrl,
      fullImageUrl
    } = this.state
    this.updateStatus(false, false)
    this.setState({
      cancel: null
    })
    this.props.onUploadComplete(fileName, fileSize, previewImageUrl, fullImageUrl)
  }

  onError: (() => void) = () => {
    this.updateStatus(false, true)
    this.setState({
      cancel: null,
      fileUploadKey: Math.random().toString()
    })
  }

  toggleHoverState: ((isHovering: boolean) => void) = (isHovering: boolean) => {
    this.setState({ isHovering })
  }

  componentWillUnmount () {
    this.state.cancel && this.state.cancel()
  }

  render (): React$Node {
    return (
      <ImageUploadToPigView
        autoFocusButton={this.props.autoFocusButton}
        defaultText={this.props.defaultText}
        errorText={this.props.errorText}
        fileUploadKey={this.state.fileUploadKey}
        hasError={this.state.hasError}
        isHovering={this.state.isHovering}
        isUploading={this.state.isUploading}
        uploadingText={this.props.uploadingText}
        onImageSelected={this.handleImageUpload}
        toggleHoverState={this.toggleHoverState} />
    )
  }
}

export default ImageUploadToPigController
