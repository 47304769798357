// @flow
import React, { PureComponent } from 'react'
import Button from '../../../../components/Button'
import WhitePanel from '../../../../components/WhitePanel'
import Alert from '../../../../components/Alert'
import styles from './MultiAssetBasketItem.module.css'

type Props = {|
  message?: string,
  removeBasketItem: () => void
|}

export default class MultiAssetBasketItemError extends PureComponent<Props> {
  render(): React$Node {
    return (
      <WhitePanel>
        <div className={styles.error}>
          <Alert>
            {this.props.message ??
              'This item is not currently available. Please reload the page, try again later, or contact support if the issue persists.'}
          </Alert>
          <Button destructive icon="trash" size="small" title="Remove item" onClick={this.props.removeBasketItem}>
            Remove
          </Button>
        </div>
      </WhitePanel>
    )
  }
}
