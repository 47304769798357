// @flow
import React, { PureComponent } from 'react'
import type { Element } from 'react'
import Button from '../../../../components/Button'
import type { CustomerAddress, Country } from '../../../../types'
import ModalWithHeader from '../../../../components/ModalWithHeader'
import AddressForm from '../../../../components/AddressForm'
import FormItem from '../../../../components/form/FormItem'
import styles from './CsvAddressModal.module.css'
import SelectField from '../../../../components/inputs/SelectField'

type Props = {|
  isAddressValid: boolean,
  address: CustomerAddress,
  countries: Country[],
  onChange: (field: string, value: string) => void,
  closeModal: () => void,
  onSave: () => void,
  onIsAddressValidChange: (isAddressValid: boolean) => void
|}

export default class CsvAddressModalView extends PureComponent<Props> {
  render (): React$Node {
    return (
      <ModalWithHeader title='Edit address' className={styles.container} close={this.props.closeModal} dataTest="csv-edit-address-modal">
        <React.Fragment>
          <AddressForm
            address={this.props.address}
            onAddressChange={this.props.onChange}
            onIsAddressValidChange={this.props.onIsAddressValidChange}>
            <FormItem
              labelText='Country'
              inputField={
                <SelectField
                  className={styles.selectCountry}
                  value={this.props.address.countryCode}
                  onChange={(e) => this.props.onChange('countryCode', e.target.value)}>
                  {this.props.countries.map(toSelectCountryOption)}
                </SelectField>
              }
            />
          </AddressForm>

          <Button primary className={styles.saveButton} disabled={!this.props.isAddressValid} onClick={this.props.onSave} dataTest="save-address-button">Save and close</Button>
        </React.Fragment>
      </ModalWithHeader>
    )
  }
}

function toSelectCountryOption (country: Country): Element<*> {
  return <option key={country.name} value={country.isoCode}>{country.name}</option>
}
