// @flow

import type { MultiAssetTemplate } from '../types'

export function buildBackgroundImageStyleFromTemplate (template: MultiAssetTemplate): string {
  if (!template.imageUrls.underlay) {
    return `url(${template.imageUrls.overlay})`
  }
  return `url(${template.imageUrls.underlay}), url(${template.imageUrls.overlay})`
}
