import {
  generatePigUrlsForArtworkInTemplate,
  getDefaultTransformations,
  PrintAreasArtworkDataType
} from '../../ImageEditor'
import { SelectedProductInterface } from '../../SelectProduct'
import { TemplateServiceSuccessResponseInterface } from '../../../hooks'
import { getProductFileCompatibilityData } from '../../../helpers/getProductFileCompatibilityData.helper'

export function generateDefaultArtworkData({
  lineItemArtworkData,
  printAreas,
  templates,
  newSelectedProduct
}: {
  lineItemArtworkData?: PrintAreasArtworkDataType | null
  printAreas: string[]
  templates: TemplateServiceSuccessResponseInterface
  newSelectedProduct: SelectedProductInterface
}) {
  if (!lineItemArtworkData) {
    return null
  }

  const productFileCompatibilityData = getProductFileCompatibilityData({
    productType: newSelectedProduct.searchResultData.productType
  })

  const updatedArtworkData = printAreas.reduce((updatedArtworkDataAcc: PrintAreasArtworkDataType, printAreaName) => {
    const artworkData = lineItemArtworkData[printAreaName]
    if (!artworkData || !artworkData.artwork) {
      return {
        ...updatedArtworkDataAcc,
        [printAreaName]: null
      }
    }

    const isImageFileType = artworkData.artwork.fileType
      ? artworkData.artwork.fileType === 'png' || artworkData.artwork.fileType === 'jpg'
      : true
    const isArtworkFileTypeSupportedOnNewProduct = productFileCompatibilityData
      ? productFileCompatibilityData.fileTypesAllowed.includes(artworkData.artwork.fileType)
      : isImageFileType

    if (!isArtworkFileTypeSupportedOnNewProduct) {
      return {
        ...updatedArtworkDataAcc,
        [printAreaName]: null
      }
    }

    const defaultArtworkTransformations = getDefaultTransformations(
      artworkData.artwork,
      templates,
      'fillPrintArea',
      printAreaName
    )

    if (!defaultArtworkTransformations) {
      return { ...updatedArtworkDataAcc, [printAreaName]: null }
    }

    const pigUrls = generatePigUrlsForArtworkInTemplate({
      artwork: artworkData.artwork,
      transformations: defaultArtworkTransformations,
      printAreaName,
      templates
    })

    if (!pigUrls) {
      return { ...updatedArtworkDataAcc, [printAreaName]: null }
    }

    const { previewImageUrl, printImageUrl } = pigUrls
    return {
      ...updatedArtworkDataAcc,
      [printAreaName]: {
        artwork: artworkData.artwork,
        artworkTransformations: defaultArtworkTransformations,
        previewImageUrl,
        printImageUrl,
        transformImageUrl: artworkData.artwork.originalImageUrl
      }
    }
  }, {})

  return updatedArtworkData
}
