import FormItem from '../FormItem'
import SelectField from '../SelectField'

export interface GroupedDropdownGroupInterface {
  label: string
  options: GroupedDropdownOptionInterface[]
}

export interface GroupedDropdownOptionInterface {
  value: string
  name: string
}

export default function GroupedDropdown({
  classname,
  label,
  groups,
  selected,
  setSelected
}: {
  classname?: string
  label: string
  groups: GroupedDropdownGroupInterface[]
  selected: string
  setSelected: (selected: string) => void
}) {
  return (
    <FormItem
      className={classname}
      labelText={label}
      inputField={<BuildSelectField label={label} groups={groups} selected={selected} setSelected={setSelected} />}
    />
  )
}

function BuildSelectField({
  label,
  groups,
  selected,
  setSelected
}: {
  label: string
  groups: GroupedDropdownGroupInterface[]
  selected: string
  setSelected: (selected: string) => void
}) {
  const availableGroups = groups.filter((group) => group.options.length > 0)
  return (
    <SelectField
      dataTest={`option-dropdown-${label}`}
      id={label}
      value={selected}
      onChange={(e) => setSelected(e.target.value)}
    >
      {availableGroups.map(({ options, label }) => (
        <optgroup key={label} label={label}>
          {options.map((item) => (
            <Option key={item.name} value={item} />
          ))}
        </optgroup>
      ))}
    </SelectField>
  )
}

function Option({ value }: { value: GroupedDropdownOptionInterface }) {
  return (
    <option key={value.name} value={value.value}>
      {value.name}
    </option>
  )
}
