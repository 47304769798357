import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import FormItem from '../../FormItem'
import { createToast } from '../../Toast'
import SelectField from '../../SelectField'
import { useOrderDetail } from '../../../hooks'
import OverlayPortal from '../../OverlayPortal'
import { formatCurrencySymbol } from '../helpers'
import LoadingSpinner from '../../LoadingSpinner'
import { getCountryName } from '../../../../helpers/countryNames'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { fetcher, formatCost, formatToSentenceCase } from '../../../helpers'
import { FetchErrorInterface, OrderDetailDataErrorInterface, StatusType } from 'src/v2/interfaces'

const ORDER_SHIPPING_TOASTS = {
  ERROR: 'prodigiOrderEditShippingErrorToast'
}

export function OrderEditShippingSelector() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  const [updateShippingStatus, setUpdateShippingStatus] = useState<StatusType>('idle')

  useEffect(() => {
    return () => {
      toast.dismiss(ORDER_SHIPPING_TOASTS.ERROR)
    }
  }, [])

  async function handleChangeShipping(shippingMethod: string) {
    toast.dismiss(ORDER_SHIPPING_TOASTS.ERROR)
    setUpdateShippingStatus('loading')
    window.analytics.track('Order editing - shipping option changed')
    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/shipping`, {
        body: JSON.stringify(shippingMethod),
        method: 'PUT'
      })
      await mutateOrderDetails()
      setUpdateShippingStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OrderDetailDataErrorInterface>
      const errorCode = errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorCode})`,
        heading: 'An error occurred while updating shipping',
        id: ORDER_SHIPPING_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setUpdateShippingStatus('error')
    }
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  const shippingOptions = Object.entries(orderDetailsResponse?.data.draftCosts?.shipping ?? {})
  const hasShippingOptionsAvailable = shippingOptions?.length > 0

  if (!hasShippingOptionsAvailable) {
    const currencySymbol = formatCurrencySymbol({
      currencyCode:
        orderDetailsResponse?.data?.draftCosts?.shipping?.[orderDetails.preferredShippingMethod]?.currencyCode
    })
    return (
      <div className="isolate w-full" data-test="order-edit-shipping">
        <div className="flex flex-col border bg-white p-6">
          <h2 className="mt-0 text-lg">Shipping</h2>
          <span className="mt-4" data-test="order-edit-shipping_destination">
            To {getCountryName(orderDetails.recipient.address.countryCode)}
          </span>

          <FormItem
            key="order-edit-shipping_selector"
            inputField={
              <SelectField className="w-fit" disabled={true} value="">
                <option value="" disabled>
                  {formatToSentenceCase(orderDetails.preferredShippingMethod)} (
                  {currencySymbol ? `${currencySymbol} ` : ''}tbc)
                </option>
              </SelectField>
            }
          />
        </div>
      </div>
    )
  }

  const hasPreferredShippingMethod = orderDetails.preferredShippingMethod && orderDetails.preferredShippingMethod !== ''

  return (
    <div className="isolate w-full" data-test="order-edit-shipping">
      <div className="flex flex-col border bg-white p-6">
        <h2 className="mt-0 text-lg">Shipping</h2>
        <span className="mt-4" data-test="order-edit-shipping_destination">
          To {getCountryName(orderDetails.recipient.address.countryCode)}
        </span>

        <FormItem
          key="order-edit-shipping_selector"
          inputField={
            <div className="flex items-center gap-4">
              <SelectField
                className="w-fit"
                disabled={updateShippingStatus === 'loading'}
                value={orderDetails.preferredShippingMethod.toLowerCase()}
                onChange={(event) => {
                  handleChangeShipping(event.target.value)
                }}
              >
                {!hasPreferredShippingMethod && (
                  <option value="" disabled>
                    Select shipping
                  </option>
                )}

                {shippingOptions.map(([optionName, optionCost]) => (
                  <option key={optionName.toLowerCase()} value={optionName}>
                    {formatShippingMethod({ optionName, optionCost })}
                  </option>
                ))}
              </SelectField>

              {<LoadingSpinner className={clsx('h-8 w-8', { invisible: updateShippingStatus !== 'loading' })} />}
            </div>
          }
        />
      </div>

      {updateShippingStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}

function formatShippingMethod({ optionName, optionCost }: { optionName: string; optionCost: CostInterface }) {
  const price = formatCost({
    amount: optionCost.amount,
    currencyCode: optionCost.currencyCode
  })
  return `${formatToSentenceCase(optionName)}: ${price}`
}
