// @flow
import React, { PureComponent } from 'react'
import type { CustomerAddress, Country } from '../../../../types'
import CsvAddressModalView from './CsvAddressModalView'
import Option from '../../../../helpers/Option'
import { isString } from 'lodash'

type Props = {|
  countries: Country[],
  address: CustomerAddress,
  closeModal: () => void,
  onSave: (address: CustomerAddress) => void
|}

type State = {|
  address: CustomerAddress,
  isAddressValid: boolean
|}

export default class CsvAddressModalController extends PureComponent<Props, State> {
  state: State = {
    isAddressValid: false,
    address: this.props.address
  }

  onIsAddressValidChange: ((isAddressValid: boolean) => void) = (isAddressValid: boolean) => this.setState({ isAddressValid })

  onChange: ((field: string, value: string) => void) = (field: string, value: string) => {
    const fieldIsString = Boolean(isString(this.state.address[field]))

    this.setState(state => ({
      address: {
        ...state.address,
        [field]: fieldIsString ? value : Option.some(value)
      }
    }))
  }

  onSave: (() => void) = () => {
    this.props.onSave(this.state.address)
    this.props.closeModal()
  }

  render (): React$Node {
    return (
      <CsvAddressModalView
        countries={this.props.countries}
        onIsAddressValidChange={this.onIsAddressValidChange}
        isAddressValid={this.state.isAddressValid}
        closeModal={this.props.closeModal}
        address={this.state.address}
        onChange={this.onChange}
        onSave={this.onSave} />
    )
  }
}
