import { useDispatch, useSelector } from 'react-redux'

import { selectCurrencyCode } from '../../../../selectors/user'
import { selectDeliveryCountry } from '../../../../selectors/manualOrderForm'
import ProductDetailsModal from '../../../../v2/components/ProductDetailsModal'
import Button from '../../../../v2/components/Button'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { ROUTE_PATHS } from '../../../../v2/constants'
import { addBasketItem } from '../../../../actions/manualOrderForm'

export default function ProductDetailsModalButton({
  description,
  sku,
  showCountryPicker
}: {
  description: string
  sku: string
  showCountryPicker: boolean
  onSelectProduct: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const currencyCode = useSelector(selectCurrencyCode)
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)

  const [open, setOpen] = useState(false)

  function addToBasket() {
    dispatch(addBasketItem(sku))
    history.push(ROUTE_PATHS.MOF.BASKET)
    window.analytics.track('Add to basket SKU modal', { sku })
  }

  const productDetailsModalActions = (
    <div className="sticky bottom-0 flex max-w-full bg-white pb-6 pt-8 md:pb-10 md:pt-12">
      <div className="flex max-w-full justify-start gap-4 md:ml-[200px]">
        <Button onClick={addToBasket}>Add to basket</Button>
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </div>
  )

  return (
    <div className="tailwind">
      <Button
        className="w-full"
        variant="secondary"
        onClick={() => {
          window.analytics.track('Show product details modal', { sku })
          setOpen(true)
        }}
      >
        View details
      </Button>
      <ProductDetailsModal
        actions={productDetailsModalActions}
        countryCode={selectedDeliveryCountry}
        currencyCode={currencyCode}
        description={description}
        open={open}
        sku={sku}
        showCountryPicker={showCountryPicker}
        setOpen={setOpen}
      />
    </div>
  )
}
