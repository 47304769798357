// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Chevron ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width='14.5' height='14.5' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M8 9.5L0.5 2L1.55 0.949997L8 7.4L14.45 0.949997L15.5 2L8 9.5Z' />
    </svg>
  )
}
