// @flow
import { Component } from 'react'
import type { ChildrenArray } from 'react'

import type { User } from '../../types'

export type AppUserProps = {|
  children: ChildrenArray<*>,
  hasOrderInProgress: boolean,
  user: User,
  changeDeliveryCountry: (country: string) => void,
  onAppUserMount: (merchantId: string) => void,
  onAppUserUnmount?: () => void
|}

export default class AppUser extends Component<AppUserProps> {
  componentDidMount() {
    this.props.onAppUserMount(this.props.user.merchantUniqueId)
    if (!this.props.hasOrderInProgress) {
      this.props.changeDeliveryCountry(this.props.user.company.countryCode)
    }
  }

  componentWillUnmount() {
    this.props.onAppUserUnmount?.()
  }

  render(): ChildrenArray<*> {
    return this.props.children
  }
}
