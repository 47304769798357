import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import {
  SALES_CHANNELS_STORE_NAME_UPDATE_ERROR_TOAST_ID,
  SALES_CHANNELS_STORE_NAME_UPDATE_SUCCESS_TOAST_ID
} from '../constants'
import {
  EtsyLogoIcon,
  WooCommerceLogoIcon,
  WixLogoIcon,
  SquarespaceLogoMarkIcon,
  AdobeCommerceLogoIcon,
  BigCommerceLogoIcon,
  ShopifyLogoIcon
} from '../../../components/svg'
import LogoProdigi from '../../LogoProdigi'
import Button from '../../../components/Button'
import OverlayPortal from '../../OverlayPortal'
import FormItem from '../../../components/FormItem'
import { useMerchantService } from '../../../hooks'
import TextField from '../../../components/TextField'
import { APP_ENVIRONMENTS } from '../../../constants'
import { createToast } from '../../../components/Toast'
import { FetchErrorInterface } from '../../../interfaces'
import { updateSalesChannelDetails } from '../../../helpers'
import { SalesChannelPlatformEnum, StatusEnum } from '../../../enums'

export function UpdateSalesChannelNameStep({
  newSalesChannelId,
  onStepAdvance
}: {
  newSalesChannelId: string
  onStepAdvance: () => void
}) {
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()

  const newSalesChannel = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === newSalesChannelId)

  const [updateSalesChannelNameStatus, setUpdateSalesChannelNameStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [salesChannelName, setSalesChannelName] = useState({
    value: newSalesChannel?.description ?? '',
    isValid: Boolean(newSalesChannel?.description)
  })

  useEffect(() => {
    return () => {
      toast.dismiss(SALES_CHANNELS_STORE_NAME_UPDATE_ERROR_TOAST_ID)
    }
  }, [])

  async function handleSubmit() {
    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      if (!newSalesChannel) {
        throw Error('No new sales channel')
      }

      if (salesChannelName.value === newSalesChannel.description) {
        onStepAdvance()
        return
      }

      toast.dismiss(SALES_CHANNELS_STORE_NAME_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SALES_CHANNELS_STORE_NAME_UPDATE_SUCCESS_TOAST_ID)
      setUpdateSalesChannelNameStatus(StatusEnum.Loading)

      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: null,
        salesChannelId: newSalesChannelId,
        salesChannelName: salesChannelName.value,
        defaultFulfillment: newSalesChannel.defaultFulfillment
      })
      await mutateMerchantDetails()

      setUpdateSalesChannelNameStatus(StatusEnum.Success)

      window.analytics.track('Store setup name added')
      onStepAdvance()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to update',
        id: SALES_CHANNELS_STORE_NAME_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setUpdateSalesChannelNameStatus(StatusEnum.Error)
    }
  }

  if (!newSalesChannel) {
    throw Error('New sales channel not found')
  }

  return (
    <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <form
        className="flex min-h-[150px] flex-col"
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <div className="flex items-center gap-4">
          <PlatformLogo platformName={newSalesChannel.platform} />

          <FormItem
            className="w-full"
            labelClassName="pb-2"
            inputField={
              <TextField
                autoFocus
                dataTest="sales-channel-name"
                id="sales-channel-name-field"
                required
                value={salesChannelName.value}
                onChange={(event) => {
                  setSalesChannelName({
                    ...salesChannelName,
                    value: event.target.value,
                    isValid: event.target.validity.valid
                  })
                }}
              />
            }
          />
        </div>

        <div className="mt-auto flex justify-end pt-2">
          <Button
            disabled={!salesChannelName.isValid}
            isLoading={updateSalesChannelNameStatus === StatusEnum.Loading}
            type="submit"
          >
            Next
          </Button>
        </div>
      </form>

      {updateSalesChannelNameStatus === StatusEnum.Loading && <OverlayPortal />}
    </motion.div>
  )
}

function PlatformLogo({ platformName }: { platformName: SalesChannelPlatformEnum }) {
  switch (platformName) {
    case SalesChannelPlatformEnum.Etsy:
      return <EtsyLogoIcon className="h-12" />
    case SalesChannelPlatformEnum.WooCommerce:
      return <WooCommerceLogoIcon className="h-12" />
    case SalesChannelPlatformEnum.Wix:
      return <WixLogoIcon className="h-12" />
    case SalesChannelPlatformEnum.Squarespace:
      return <SquarespaceLogoMarkIcon className="h-12" />
    case SalesChannelPlatformEnum.BigCommerce:
      return <BigCommerceLogoIcon className="h-12" />
    case SalesChannelPlatformEnum.AdobeCommerce:
      return <AdobeCommerceLogoIcon className="h-12" />
    case SalesChannelPlatformEnum.Shopify:
      return <ShopifyLogoIcon className="h-12" />
    case SalesChannelPlatformEnum.Prodigi:
      return <LogoProdigi className="h-12" />
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
