// @flow

import { FETCH_ERROR_NAME } from '../data/constants'

class FetchError extends Error {
  responseBodyJson

  constructor ({ message, name, responseBodyJson }) {
    super(message)
    this.name = name
    this.message = message
    this.responseBodyJson = responseBodyJson
  }
}

export default async function processFetchResponse (response: Response): Promise<any> {
  const responseBodyJson = await response.json()
  if (!response.ok) {
    throw new FetchError({
      message: response.statusText,
      responseBodyJson,
      name: FETCH_ERROR_NAME
    })
  }
  return responseBodyJson
}
