import { ImageLibraryImageInterface, ImageLibraryImageType } from '../hooks'
import { fetcher, getDefaultFetchOptions, requestImageLibraryThumbnailGeneration } from '.'

export async function uploadFileToImageLibrary({
  file,
  shouldSaveToLibrary,
  type = 'artwork'
}: {
  file: File
  shouldSaveToLibrary: boolean
  type: ImageLibraryImageType
}) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('is_temporary', JSON.stringify(!shouldSaveToLibrary))
  formData.append('image_type', type)

  return fetcher<ImageLibraryImageInterface>(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/`, {
    body: formData,
    headers: {
      Authorization: getDefaultFetchOptions().headers.Authorization
    },
    method: 'POST'
  }).then((imageLibraryImage) => requestImageLibraryThumbnailGeneration(imageLibraryImage.public_id))
}
