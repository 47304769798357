import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { createToast } from '../../Toast'
import SupportLink from '../../SupportLink'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { ROUTE_PATHS } from '../../../constants'
import { ProductTile } from './ProductTile.component'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../constants'
import { updateChannelProductFulfilment } from '../helpers'
import { ProductTileSkeleton } from './ProductTileSkeleton.component'
import { ProductsListContainer } from './ProductsListContainer.component'
import { useSalesChannelProductsOverview } from '../../SalesChannels/hooks'
import { FetchErrorInterface, OMSResponseInterface, SalesChannelInterface } from '../../../interfaces'
import { SalesChannelProductInterface, useMerchantService, useSalesChannelProducts } from '../../../hooks'

export function ProductsList({ salesChannel }: { salesChannel: SalesChannelInterface }) {
  const {
    salesChannelProductPages,
    salesChannelProductPagesToLoadSize,
    salesChannelProductsFetchError,
    setSalesChannelProductPagesToLoadSize
  } = useSalesChannelProducts(salesChannel.id)

  const loadedProductPagesSize = salesChannelProductPages?.length ?? 1
  const hasNextPage = Boolean(salesChannelProductPages?.[loadedProductPagesSize - 1].data.hasMore)
  const isLoadingNextPageOfSalesChannelProducts =
    hasNextPage && loadedProductPagesSize < salesChannelProductPagesToLoadSize

  const [paginationTriggerRef] = useInfiniteScroll({
    disabled: Boolean(salesChannelProductsFetchError),
    loading: isLoadingNextPageOfSalesChannelProducts,
    delayInMs: 200,
    hasNextPage,
    onLoadMore: () => {
      setSalesChannelProductPagesToLoadSize(salesChannelProductPagesToLoadSize + 1)
    }
  })

  if (!salesChannelProductPages) {
    throw Error('No products for sales channel')
  }

  const showNextPageLoadingSkeletons =
    !salesChannelProductsFetchError && (isLoadingNextPageOfSalesChannelProducts || hasNextPage)

  const showAllProductsLoadedMessage = salesChannelProductPages.length > 1 && !hasNextPage

  return (
    <ProductsListContainer>
      {salesChannelProductPages.map((salesChannelProductPage) =>
        salesChannelProductPage.data.products.map((salesChannelProduct) => (
          <ChannelProductTile
            key={salesChannelProduct.id + salesChannelProduct.fulfill.toString()}
            salesChannel={salesChannel}
            salesChannelProduct={salesChannelProduct}
          />
        ))
      )}

      {salesChannelProductsFetchError && (
        <div className="col-span-full mx-auto max-w-2xl pt-10 text-center text-red-500">
          An error occurred while loading the next page of products. Please try again later or{' '}
          <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists (Code{' '}
          {salesChannelProductsFetchError.status ?? '0'})
        </div>
      )}

      {showAllProductsLoadedMessage && (
        <div className="col-span-full mx-auto max-w-[250px] pt-10 text-center">All products loaded</div>
      )}

      {showNextPageLoadingSkeletons && (
        <>
          {Array.from({ length: 5 }, (_, i) => i).map((value) => (
            <ProductTileSkeleton ref={paginationTriggerRef} key={value} />
          ))}
        </>
      )}
    </ProductsListContainer>
  )
}

function ChannelProductTile({
  salesChannel,
  salesChannelProduct
}: {
  salesChannel: SalesChannelInterface
  salesChannelProduct: SalesChannelProductInterface
}) {
  const { salesChannelProductPages, clearPagesCache, mutateSalesChannelProducts } = useSalesChannelProducts(
    salesChannel.id
  )
  const { merchantDetails } = useMerchantService()
  const { mutateSalesChannelProductsOverview } = useSalesChannelProductsOverview({ salesChannelId: salesChannel.id })
  const history = useHistory()
  const { search } = useLocation()
  const hasFiltersApplied = Boolean(search)

  const [isFulfilled, setIsFulfilled] = useState(salesChannelProduct.fulfill)
  const [changeFulfilmentStatus, setChangeFulfilmentStatus] = useState(StatusEnum.Idle)

  useEffect(() => {
    return () => {
      toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    }
  }, [])

  const shouldClearFiltersOnSuccess = salesChannelProductPages?.[0].data.products.length === 1

  async function handleChangeFulfilment(shouldFulfill: boolean) {
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    setChangeFulfilmentStatus(StatusEnum.Loading)

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }

      await updateChannelProductFulfilment({
        merchantId: merchantDetails.id,
        salesChannelId: salesChannel.id,
        salesChannelProductId: salesChannelProduct.id,
        shouldFulfill
      })
      setIsFulfilled(shouldFulfill)
      await mutateSalesChannelProducts()
      setChangeFulfilmentStatus(StatusEnum.Success)

      if (shouldClearFiltersOnSuccess) {
        history.push(ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(salesChannel.id))
        window.scroll(0, 0)
      }

      clearPagesCache()
      if (hasFiltersApplied) {
        mutateSalesChannelProducts()
        mutateSalesChannelProductsOverview()
      }
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSResponseInterface>
      createToast({
        content: 'Please try again later and contact support if the issue persists',
        duration: Infinity,
        footer: `${errorResponse.responseBodyJson?.outcome ?? errorResponse.message} (Code ${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'Failed to update',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setChangeFulfilmentStatus(StatusEnum.Error)
    }
  }

  return (
    <>
      <ProductTile
        className={clsx(changeFulfilmentStatus === StatusEnum.Loading ? 'cursor-wait' : '')}
        disabled={changeFulfilmentStatus === StatusEnum.Loading}
        isFulfilled={isFulfilled}
        onChangeFulfilment={handleChangeFulfilment}
        salesChannel={salesChannel}
        salesChannelProduct={salesChannelProduct}
      />

      {changeFulfilmentStatus === StatusEnum.Loading && <OverlayPortal className="cursor-wait" />}
    </>
  )
}
