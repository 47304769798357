// @flow
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { getSelectedOrderShippingMethod } from '../../selectors/csvUpload'
import { selectQuotes } from '../../selectors/quotes'
import { createListOfOrderIds } from '../../helpers/createListOfIds'
import { entries } from '../../helpers/dictionary'

export const COPY_SHIPPING_METHOD = 'COPY_SHIPPING_METHOD'

export function copyShippingMethod (orderId: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const shippingMethod = getSelectedOrderShippingMethod(getState(), orderId)
    const orderIdsToCheck = createListOfOrderIds(getState(), orderId)
    const quotes = selectQuotes(getState())

    const idsOfOrdersThatSupportSelectedShippingMethod = entries(quotes).reduce(
      (orderIdAcc, [orderId, quote]) => {
        if (!orderIdsToCheck.includes(orderId)) {
          return orderIdAcc
        }

        const availableShippingMethodsInLowercase = quote.map(
          shipment => shipment.shipmentMethod.toLowerCase()
        )
        if (availableShippingMethodsInLowercase.includes(shippingMethod.toLowerCase())) {
          return orderIdAcc.concat(orderId)
        }
        return orderIdAcc
      }, [])

    dispatch({
      type: COPY_SHIPPING_METHOD,
      idsOfOrdersToUpdate: idsOfOrdersThatSupportSelectedShippingMethod,
      shippingMethod
    })
  }
}
