// @flow

import React, { useRef, useLayoutEffect } from 'react'
import { DimensionsInterface, LengthUnitType } from '../interfaces'

type ImageEditorBorderControlProps = {
  style?: Record<string, string>
  printDimensionsInPx: DimensionsInterface
  printDpi: number
  borderWidth: number
  borderUnit: LengthUnitType
  onBorderChange: (event: CustomEvent<number>) => void
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'image-editor-border-controls': any
    }
  }
}

function ImageEditorBorderControlWrapper({
  style,
  printDimensionsInPx,
  printDpi,
  borderWidth,
  borderUnit,
  onBorderChange
}: ImageEditorBorderControlProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const borderControlRef = useRef<any>(null)

  useLayoutEffect(() => {
    if (!borderControlRef.current) {
      return
    }

    borderControlRef.current.borderWidth = borderWidth
    borderControlRef.current.borderUnit = borderUnit
    borderControlRef.current.printDimensionsInPx = printDimensionsInPx
    borderControlRef.current.printDpi = printDpi
    borderControlRef.current.inlineStyles = style || {}
    borderControlRef.current.addEventListener('borderChange', onBorderChange)

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      borderControlRef.current.removeEventListener('borderChange', onBorderChange)
    }
  }, [style, printDimensionsInPx, printDpi, borderWidth, borderUnit, onBorderChange])

  return <image-editor-border-controls ref={borderControlRef} />
}

const ImageEditorBorderControl = React.memo(ImageEditorBorderControlWrapper)

export default ImageEditorBorderControl
