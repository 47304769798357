import { useState } from 'react'
import cx from 'classnames'

export function CustomInputs({
  endDate,
  formatDateToString,
  startDate,
  updateSelectedSortOption
}: {
  endDate: Date | null
  formatDateToString: (date: Date | null) => string
  startDate: Date | null
  updateSelectedSortOption: (startDate: Date | null, endDate: Date | null) => void
}) {
  const [manualStartDate, setManualStartDate] = useState<string | undefined>(formatDateToString(startDate))
  const [manualEndDate, setManualEndDate] = useState<string | undefined>(formatDateToString(endDate))
  const [startDateIsInvalid, setStartDateIsInvalid] = useState<boolean>(false)
  const [endDateIsInvalid, setEndDateIsInvalid] = useState<boolean>(false)

  function updateStartDate(date: string | undefined) {
    setManualStartDate(date)

    if (!date) {
      return
    }

    const formattedDate = getFormattedDate(date)
    const correctOrder = areDatesInTheRightOrder(formattedDate, endDate)

    if (formattedDate && correctOrder) {
      updateSelectedSortOption(formattedDate, endDate)
      setStartDateIsInvalid(false)
    } else {
      setStartDateIsInvalid(true)
    }
  }

  function updateEndDate(date: string | undefined) {
    setManualEndDate(date)

    if (!date) {
      return
    }

    const formattedDate = getFormattedDate(date)
    const correctOrder = areDatesInTheRightOrder(startDate, formattedDate)

    if (formattedDate && correctOrder) {
      updateSelectedSortOption(startDate, formattedDate)
      setEndDateIsInvalid(false)
    } else {
      setEndDateIsInvalid(true)
    }
  }

  function getFormattedDate(date: string): Date | null {
    const [year, month, day] = date.split('-')
    const formattedDate: Date | null = new Date(`${year}-${month}-${day}`)

    const isValidDate = formattedDate.toString() !== 'Invalid Date'
    const inCorrectFormat = year && year.length === 4 && month && month.length === 2 && day && day.length === 2

    return isValidDate && inCorrectFormat ? formattedDate : null
  }

  function areDatesInTheRightOrder(startDate: Date | null, endDate: Date | null): boolean {
    if (!startDate || !endDate) {
      return true
    }

    return startDate <= endDate
  }

  return (
    <div className="flex flex-col items-center space-x-2 sm:flex-row">
      <input
        autoCapitalize="off"
        autoComplete="off"
        className={cx(
          `relative rounded-lg border border-gray-200 bg-white py-2 px-3 text-left text-input focus:outline-none focus-visible:outline-purple-500 sm:w-48 sm:text-base`,
          startDateIsInvalid && 'border-red-500'
        )}
        onChange={(e) => updateStartDate(e.target.value)}
        placeholder="YYYY-MM-DD"
        type="text"
        value={manualStartDate}
      />
      <span className="px-2 text-sm">to</span>
      <input
        autoCapitalize="off"
        autoComplete="off"
        className={cx(
          `relative rounded-lg border border-gray-200 bg-white py-2 px-3 text-left text-input focus:outline-none focus-visible:outline-purple-500 sm:w-48 sm:text-base`,
          endDateIsInvalid && 'border-red-500'
        )}
        onChange={(e) => updateEndDate(e.target.value)}
        placeholder="YYYY-MM-DD"
        type="text"
        value={manualEndDate}
      />
    </div>
  )
}
