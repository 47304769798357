// @flow
import { RSAA } from 'redux-api-middleware'
import { ABORT_IN_FLIGHT_REQUESTS, LOG_STATUS } from '../../data/rsaa'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import type {
  Thunk,
  GetStateFunc,
  DispatchFunc,
  OrderFilterScope
} from '../../types'
import type { SearchOrdersRsaa, DashboardSearchRsaaResult } from '../types'

export const REQUEST_ORDER_SEARCH = 'REQUEST_ORDER_SEARCH'
export const RECEIVE_ORDER_SEARCH = 'RECEIVE_ORDER_SEARCH'
export const ORDER_SEARCH_ERROR = 'ORDER_SEARCH_ERROR'

export function searchOrders (
  query: string,
  scope: OrderFilterScope,
  skip: number,
  limit: number
): Thunk<?DashboardSearchRsaaResult> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const searchOrdersAction: SearchOrdersRsaa = {
      [RSAA]: {
        endpoint: selectPwintyApiSettings().endpoint + `/dashboard/search?start=${skip}&limit=${limit}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ scope: scope || '', criteria: query || '' }),
        types: [
          { type: REQUEST_ORDER_SEARCH, meta: { enhancements: [ ABORT_IN_FLIGHT_REQUESTS, LOG_STATUS ] } },
          RECEIVE_ORDER_SEARCH,
          ORDER_SEARCH_ERROR
        ]}
    }

    return dispatch(searchOrdersAction)
  }
}
