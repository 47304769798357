// @flow
import ImagePipelineBuilder from './imagePipelineBuilder/imagePipelineBuilder'
import { selectImagePipelineUrl } from '../selectors/appSettings'

export function generateArtworkUrl (artworkName: string): any {
  const pipelineUrl = selectImagePipelineUrl()

  return ImagePipelineBuilder
    .fromArtwork(artworkName)
    .toUrl(pipelineUrl, false)
}

export function generateResizedArtworkUrl (size: number, artworkName: string): any {
  const pipelineUrl = selectImagePipelineUrl()

  return ImagePipelineBuilder
    .fromArtwork(artworkName)
    .resize(size, size)
    .toUrl(pipelineUrl, false)
}
