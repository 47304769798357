// @flow
import React, { PureComponent } from 'react'
import CsvMultiselectFiltersView from './CsvMultiselectFiltersView'

type Props = {|
  isMultiselectModeOn: boolean,
  ignoreAllOrders: () => void,
  selectAllOrders: () => void,
  toggleMultiselectMode: () => void
|}

export default class CsvMultiselectFiltersController extends PureComponent<Props> {
  onClickMultiselect: (() => void) = () => {
    this.props.ignoreAllOrders()
    this.props.toggleMultiselectMode()
  }

  onClickClearSelection: (() => void) = () => {
    this.props.selectAllOrders()
    this.props.toggleMultiselectMode()
  }

  onClickSelectAll: (() => void) = () => this.props.selectAllOrders()

  onClickSelectNone: (() => void) = () => this.props.ignoreAllOrders()

  render (): React$Node {
    return (
      <CsvMultiselectFiltersView
        onClickSelectAll={this.onClickSelectAll}
        onClickSelectNone={this.onClickSelectNone}
        onClickClearSelection={this.onClickClearSelection}
        onClickMultiselect={this.onClickMultiselect}
        isMultiselectModeOn={this.props.isMultiselectModeOn} />
    )
  }
}
