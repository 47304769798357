/* eslint-disable indent */
import { OrderStatusEnum } from '../../enums'
import { formatOrderStatusName } from '../../helpers'
import { getOrderStatusThemeColor } from './helpers'

interface OrderStatusFlagPropsInterface {
  className?: string
  reverseOnMobile?: boolean
  statusText?: OrderStatusEnum
}

export default function OrderStatusFlag({
  className,
  reverseOnMobile = false,
  statusText
}: OrderStatusFlagPropsInterface) {
  if (!statusText) {
    return null
  }

  const theme = getOrderStatusThemeColor(statusText)
  const text = formatOrderStatusName(statusText)

  return (
    <span
      className={`
      inline-flex items-center whitespace-nowrap no-underline before:h-5 before:w-5 before:rounded-md before:content-['']
      ${
        reverseOnMobile
          ? ' flex-row-reverse before:ml-2 before:mr-0 sm:flex-row before:sm:ml-0 sm:before:mr-2'
          : 'before:mr-2'
      }
      ${theme}
      ${className}
    `}
      data-test="order-status-flag"
    >
      {text}
    </span>
  )
}
