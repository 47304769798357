import { sortApparelBySize } from '.'
import { formatProductAttributeString } from '../components/OrderDetailShipmentDisplay/helpers'
import { NO_SELECTION, ALL, FRAME_COLOUR, DEFAULT_ATTRIBUTE_CATEGORIES, GLAZE, MOUNT_COLOUR } from '../constants'

export function getDefaultAttribute(attrName: string, category: string): string {
  const defaultOptions = DEFAULT_ATTRIBUTE_CATEGORIES[category]
  if (defaultOptions && attrName in defaultOptions) {
    return defaultOptions[attrName]
  }
  return 'notFound'
}

export function reorderAttributes(name: string, attr: string[], category: string): string[] {
  const defaultAttr = getDefaultAttribute(name, category)
  const attrExists = attr.find((x) => x === defaultAttr)
  name === 'size' ? attr.sort(sortApparelBySize) : attr.sort()

  if (!attrExists) {
    return attr
  }

  attr.splice(
    attr.findIndex((x) => x === defaultAttr),
    1
  )
  attr.unshift(defaultAttr)
  return attr
}

export function productAttributeFormat(attribute: string): string {
  switch (attribute && attribute.toLowerCase()) {
    case 'floatglass':
      return 'Float Glass'
    case 'truviewmuseumglass':
      return 'Tru View Museum Glass'
    case 'bap':
      return 'Budget Art Paper'
    case 'bpp':
      return 'Budget Photo Paper'
    case 'cpp':
      return 'Classic Poster Paper'
    case 'cpwp':
      return 'Cold Press Watercolour Paper'
    case 'ema':
      return 'Enhanced Matte Art Paper'
    case 'hfap':
      return 'Hahnemühle Fine Art Pearl'
    case 'hge':
      return 'Hahnemühle German Etching'
    case 'hpg':
      return 'Hahnemühle Photo Glossy'
    case 'hpl':
      return 'Hahnemühle Photo Lustre'
    case 'hpr':
      return 'Hahnemühle Photo Rag'
    case 'lpp':
      return 'Lustre Photo Paper'
    case 'mfa':
      return 'Museum Fine Art Paper'
    case 'mg':
      return 'Metallic Gloss Paper'
    case 'sap':
      return 'Smooth Art Paper'
    case 'spr':
      return 'Smooth Photo Rag'
    case 'hmc':
      return 'Hahnemühle Monet Canvas'
    case 'sc':
      return 'Standard Canvas'
    case 'mc':
      return 'Metallic Canvas'
    case 'pc':
      return 'Polycanvas'
    case '4xl':
      return '4XL'
    case '3_4':
      return '3 to 4 months'
    case '4_5':
      return '4 to 5 months'
    case '5_6':
      return '5 to 6 months'
    case '7_8':
      return '7 to 8 months'
    case '3_6m':
      return '3 to 6 months'
    case '2yr':
      return 'Under 2 years'
    case '9_11':
      return '9 to 11 months'
    case '12_13':
      return '12 to 13 months'
    case '6_12m':
      return '6 to 12 months'
    case '12_18m':
      return '12 to 18 months'
    case '18_24m':
      return '18 to 24 months'
    case 'vneck':
      return 'V neck'
    case 'unisex':
      return 'Unisex'
    case 'mens':
      return 'Mens'
    case 'xxl':
      return 'XXL'
    case 'xl':
      return 'XL'
    case '2xl':
      return '2XL'
    case '3xl':
      return '3XL'
    case '5xl':
      return '5XL'
    case 'xxxl':
      return 'XXXL'
    case '4yr':
      return 'Under 4 years'
    case '6yr':
      return 'Under 6 years'
    case '8yr':
      return 'Under 8 years'
    case '10yr':
      return 'Under 10 years'
    case '24m':
      return 'Under 24 months'
    case 'xs':
      return 'XS'
    case '0-300':
      return 'Under 30cm'
    case '300-500':
      return '30cm-49cm'
    case '500-700':
      return '50cm-69cm'
    case '700-1000':
      return '80cm-99cm'
    case '1000-1500':
      return '100cm-149cm'
    case '1500-100000':
      return 'Over 150cm'
    case '0-95':
      return 'Portrait'
    case '95-105':
      return 'Square'
    case '105-100000':
      return 'Landscape'
    case 'imagewrap':
      return 'Image wrap'
    case 'mirrorwrap':
      return 'Mirror wrap'
    case 'off-white': {
      return 'Off White'
    }
    case 'white_asphalt':
      return 'White Asphalt'
    case 'black_white':
      return 'Black White'
    case 'airforceblue':
      return 'Airforce blue'
    case 'brightwhite':
      return 'Bright white'
    case 'charcoalheather':
      return 'Charcoal heather'
    case 'dark Heathergrey':
      return 'Dark heather grey'
    case 'darkchocolate':
      return 'Dark chocolate'
    case 'darkgreen':
      return 'Dark green'
    case 'darkgreyheather':
      return 'Dark grey heather'
    case 'darkheather':
      return 'Dark heather'
    case 'deeproyal':
      return 'Deep royal'
    case 'denimheather':
      return 'Denim heather'
    case 'firered':
      return 'Fire red'
    case 'forestgreen':
      return 'Forest green'
    case 'graphiteheather':
      return 'Graphite heather'
    case 'hawaiianblue':
      return 'Hawaiian blue'
    case 'heatheredcardinal':
      return 'Heathered cardinal'
    case 'heatheredkellygreen':
      return 'Heathered kelly green'
    case 'heatheredlightgrey':
      return 'Heathered light grey'
    case 'heatherednickel':
      return 'Heathered nickel'
    case 'heatherpurple':
      return 'Heather purple'
    case 'hotchocolate':
      return 'Hot chocolate'
    case 'hotpink':
      return 'Hot pink'
    case 'iceblue':
      return 'Ice blue'
    case 'irishgreen':
      return 'Irish green'
    case 'jetblack':
      return 'Jet black'
    case 'kellygreen':
      return 'Kelly green'
    case 'lightblue':
      return 'Light blue'
    case 'limegreen':
      return 'Lime green'
    case 'militarygreen':
      return 'Military green'
    case 'navyblue':
      return 'Navy blue'
    case 'newnavy':
      return 'New navy'
    case 'oxfordnavy':
      return 'Oxford navy'
    case 'royalheather':
      return 'Royal heather'
    case 'skyblue':
      return 'Sky blue'
    case 'sportsgrey':
      return 'Sports grey'
    case 'steelgrey':
      return 'Steel grey'
    case 'teampurple':
      return 'Team purple'
    case 'arcticwhite':
      return 'Arctic white'
    case 'athleticheather':
      return 'Athletic heather'
    case 'athleticgrey':
      return 'Athletic grey'
    case 'babyblue':
      return 'Baby blue'
    case 'bottlegreen':
      return 'Bottle green'
    case 'candypink':
      return 'Candy pink'
    case 'cardinalred':
      return 'Cardinal red'
    case 'darkgrey':
      return 'Dark grey'
    case 'darkheathergrey':
      return 'Dark heather grey'
    case 'heathergrey':
      return 'Heather grey'
    case 'lightpink':
      return 'Light pink'
    case 'royalblue':
      return 'Royal blue'
    case 'sunyellow':
      return 'Sun yellow'
    case 'trueroyal':
      return 'True royal'
    case 'newfrenchnavy':
      return 'New french navy'
    case 'redhotchilli':
      return 'Red hot chilli'
    case 'sapphireblue':
      return 'Sapphire blue'
    case 'orangecrush':
      return 'Orange crush'
    case 'turquoisesurf':
      return 'Turquoise surf'
    default:
      if (attribute) {
        return formatProductAttributeString(attribute)
      } else {
        return ''
      }
  }
}

const APPAREL = 'Apparel'
const HOMEWARES = 'Homewares'
const ACCESSORIES = 'Accessories'
export function formatAttrNameForCategory(attrName: string, category: string): string {
  if (
    attrName === FRAME_COLOUR &&
    category !== APPAREL &&
    category !== HOMEWARES &&
    category !== ACCESSORIES &&
    category !== NO_SELECTION &&
    category !== ALL
  ) {
    return 'Frame colour'
  } else if (attrName === GLAZE && category === ALL) {
    return 'Glaze / Coating'
  } else if (attrName === GLAZE && category.toLowerCase().includes('canvas')) {
    return 'Coating'
  } else if (attrName === MOUNT_COLOUR && category.toLowerCase().includes('framed')) {
    return 'Mount colour'
  }

  return attributeLabelFormat(attrName)
}

function attributeLabelFormat(attribute: string): string {
  if (attribute === 'maxProductDimensionsMm') {
    return 'Size'
  } else if (attribute === 'productAspectRatio') {
    return 'Aspect Ratio'
  } else if (attribute === 'frameColour') {
    return 'Colour'
  } else if (attribute === 'gender') {
    return 'Category'
  } else {
    const addSpaces = attribute.replace(/([A-Z])/g, ' $1')
    return addSpaces.charAt(0).toUpperCase() + addSpaces.slice(1)
  }
}
