// @flow
import type { Thunk, GetStateFunc, DispatchFunc } from '../../types'
import { selectRouterHistory } from '../../selectors/router'

export function pushRedirect (pathname: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const routerHistory = selectRouterHistory(getState())
    routerHistory.push(pathname)
  }
}

export function replaceRedirect (pathname: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const routerHistory = selectRouterHistory(getState())
    routerHistory.replace(pathname)
  }
}
