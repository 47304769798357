// @flow
import React, { PureComponent } from 'react'
import TextField from '../../inputs/TextField'
import Button from '../../Button'
import Modal from '../../Modal'
import FormItem from '../../form/FormItem'
import styles from './UploadFromUrl.module.css'
import { Flex } from '../../layout'

type Props = {|
  onChangeImageUrl: (event: SyntheticKeyboardEvent<*>) => void,
  onClickUpload: () => void,
  closeModal: () => void,
  imageUrl: string
|}

export default class UploadFromUrlView extends PureComponent<Props> {
  render (): React$Node {
    return (
      <Modal className={styles.modalContainer} close={this.props.closeModal} backgroundClassName={styles.modalBackground} dataTest="upload-from-url-modal">
        <FormItem
          labelStyle={{ marginTop: 0 }}
          labelText='Image URL'
          inputField={
            <TextField
              placeholder='https://'
              className={styles.textField}
              type='text'
              name='imageUrl'
              autoCorrect='off'
              autoCapitalize='off'
              value={this.props.imageUrl}
              onChange={this.props.onChangeImageUrl}
              dataTest="upload-from-url-input"
            />
          } />

        <Flex.Row justifyFlexStart className={styles.buttonHolder}>
          <React.Fragment>
            <Button disabled={!this.props.imageUrl} primary className={styles.fetchButton} onClick={this.props.onClickUpload} dataTest="upload-from-url-upload-button">Upload</Button>
            <Button onClick={this.props.closeModal} dataTest="upload-from-url-cancel-button">Cancel</Button>
          </React.Fragment>
        </Flex.Row>
      </Modal>
    )
  }
}
