import { useEffect } from 'react'

import { DEFAULT_DOCUMENT_TITLE } from 'src/v2/constants'
import SalesChannels from '../../components/SalesChannels'

export default function SalesChannelsPage() {
  useEffect(() => {
    document.title = 'Sales Channels | Prodigi'
    window.analytics.page('Sales Channels')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <SalesChannels />
    </main>
  )
}
