// @flow
import React, { Component } from 'react'
import Radium from 'radium'
import FontAwesome from 'react-fontawesome'

type Props = {|
  errorMessage: string,
  error: boolean
|}

class Loading extends Component<Props> {
  getStyles () {
    return {
      box: {
        textAlign: 'center'
      },
      status: {
        fontSize: 'var(--sizes-large)',
        fontFamily: 'var(--font-thin)',
        marginTop: '15px',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0'
      },
      alert: {
        color: 'var(--accent-red)',
        fontFamily: 'var(--font-bold)',
        marginBottom: '1em'
      },
      success: {
        color: 'var(--accent-blue)',
        fontFamily: 'var(--font-bold)',
        marginBottom: '1em',
        marginTop: '1em'
      },
      processing: {
        textAlign: 'center'
      },
      process: {
        marginTop: '1%'
      },
      icon: {
        fontSize: '10rem',
        color: 'var(--accent-blue)'
      }
    }
  }

  render (): React$Node {
    const styles = this.getStyles()
    const {
      errorMessage,
      error
    } = this.props

    return (
      <div style={styles.box} >
        {error
          ? <div>
            <FontAwesome name='times-circle-o' style={{ ...styles.icon, color: 'var(--accent-red)' }} />
            <p style={{ ...styles.alert, ...styles.status }}>{errorMessage}</p>
          </div>
          : <div style={styles.processing}>
            <FontAwesome name='circle-o-notch fa-spin' style={styles.icon} />
            <p style={styles.process}><em>Loading</em></p>
          </div>}
      </div>
    )
  }
}

export default (Radium(Loading): any)
