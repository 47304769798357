import { useState } from 'react'
import toast from 'react-hot-toast'

import Button from '../../../../../Button'
import FormItem from '../../../../../FormItem'
import TextField from '../../../../../TextField'
import { createToast } from '../../../../../Toast'
import { StatusEnum } from '../../../../../../enums'
import { PASSWORD_MIN_LENGTH } from '../../../../../../constants'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { FetchErrorInterface } from '../../../../../../interfaces'
import { fetcher, getDefaultFetchOptions } from '../../../../../../helpers'
import ToggleHidePasswordButton from '../../../../../ToggleHidePasswordButton'
import { SETTINGS_UPDATE_SUCCESS_TOAST_ID, SETTINGS_UPDATE_ERROR_TOAST_ID } from '../../../../constants'

export function ResetPasswordPanel({ className = '', onSuccess }: { className?: string; onSuccess: () => void }) {
  const [accountPasswordFormData, setAccountPasswordFormData] = useState({
    confirmPassword: { isValid: false, showPassword: false, value: '' },
    newPassword: { isValid: false, showPassword: false, value: '' },
    oldPassword: { isValid: false, showPassword: false, value: '' }
  })
  const [accountPasswordUpdateStatus, setAccountPasswordUpdateStatus] = useState(StatusEnum.Idle)

  async function handleAccountPasswordUpdate() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setAccountPasswordUpdateStatus(StatusEnum.Loading)

    try {
      await fetcher(`${process.env.REACT_APP_ENDPOINT}/api/Account/ChangePassword`, {
        body: `OldPassword=${encodeURIComponent(
          accountPasswordFormData.oldPassword.value
        )}&NewPassword=${encodeURIComponent(
          accountPasswordFormData.newPassword.value
        )}&ConfirmPassword=${encodeURIComponent(accountPasswordFormData.confirmPassword.value)}`,
        headers: {
          ...getDefaultFetchOptions().headers,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST'
      })
      setAccountPasswordUpdateStatus(StatusEnum.Success)
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      onSuccess()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{
        message?: string
        modelState?: Record<string, string | undefined>
      }>

      if (
        errorResponse.responseBodyJson?.modelState &&
        JSON.stringify(errorResponse.responseBodyJson.modelState).includes('Incorrect password')
      ) {
        createToast({
          heading: 'Failed to update password',
          content: 'The old password entered was incorrect',
          duration: Infinity,
          id: SETTINGS_UPDATE_ERROR_TOAST_ID,
          type: 'error-with-close'
        })
      } else {
        createToast({
          heading: 'Failed to update password',
          content: `An unexpected error occurred. Please try again later (${
            errorResponse.status ?? errorResponse.name
          })`,
          duration: Infinity,
          id: SETTINGS_UPDATE_ERROR_TOAST_ID,
          type: 'error-with-close'
        })
      }
      setAccountPasswordUpdateStatus(StatusEnum.Error)
    }
  }

  return (
    <SettingsPanel
      className={className}
      title={<h2>Reset password</h2>}
      subtitle={
        <span className="mt-4 max-w-lg text-gray-600">
          We need you to confirm your existing password before creating a new one.
        </span>
      }
    >
      <form
        className="max-w-xl"
        onSubmit={(event) => {
          event.preventDefault()
          handleAccountPasswordUpdate()
        }}
      >
        <FormItem
          inputField={
            <PasswordInputField
              value={accountPasswordFormData.oldPassword.value}
              showPassword={accountPasswordFormData.oldPassword.showPassword}
              onChange={(event) => {
                setAccountPasswordFormData({
                  ...accountPasswordFormData,
                  oldPassword: {
                    ...accountPasswordFormData.oldPassword,
                    isValid: event.target.validity.valid,
                    value: event.target.value
                  }
                })
              }}
              onShowPasswordChange={(isVisible) => {
                setAccountPasswordFormData({
                  ...accountPasswordFormData,
                  oldPassword: {
                    ...accountPasswordFormData.oldPassword,
                    showPassword: isVisible
                  }
                })
              }}
            />
          }
          labelText="Existing password"
          required
          valid={accountPasswordFormData.oldPassword.isValid}
          validate={Boolean(accountPasswordFormData.oldPassword.value)}
        />

        <FormItem
          inputField={
            <PasswordInputField
              value={accountPasswordFormData.newPassword.value}
              showPassword={accountPasswordFormData.newPassword.showPassword}
              onChange={(event) => {
                setAccountPasswordFormData({
                  ...accountPasswordFormData,
                  newPassword: {
                    ...accountPasswordFormData.newPassword,
                    isValid: event.target.validity.valid,
                    value: event.target.value
                  }
                })
              }}
              onShowPasswordChange={(isVisible) => {
                setAccountPasswordFormData({
                  ...accountPasswordFormData,
                  newPassword: {
                    ...accountPasswordFormData.newPassword,
                    showPassword: isVisible
                  }
                })
              }}
            />
          }
          labelText="New password"
          required
          valid={accountPasswordFormData.newPassword.isValid}
          validate={Boolean(accountPasswordFormData.newPassword.value)}
        />

        <FormItem
          inputField={
            <PasswordInputField
              value={accountPasswordFormData.confirmPassword.value}
              showPassword={accountPasswordFormData.confirmPassword.showPassword}
              onChange={(event) => {
                setAccountPasswordFormData({
                  ...accountPasswordFormData,
                  confirmPassword: {
                    ...accountPasswordFormData.confirmPassword,
                    isValid: event.target.validity.valid,
                    value: event.target.value
                  }
                })
              }}
              onShowPasswordChange={(isVisible) => {
                setAccountPasswordFormData({
                  ...accountPasswordFormData,
                  confirmPassword: {
                    ...accountPasswordFormData.confirmPassword,
                    showPassword: isVisible
                  }
                })
              }}
            />
          }
          labelText="Confirm new password"
          required
          valid={
            accountPasswordFormData.confirmPassword.isValid &&
            accountPasswordFormData.newPassword.value === accountPasswordFormData.confirmPassword.value
          }
          validate={Boolean(accountPasswordFormData.confirmPassword.value)}
        />

        <Button
          className="mt-8"
          disabled={
            !accountPasswordFormData.oldPassword.isValid ||
            !accountPasswordFormData.newPassword.isValid ||
            !accountPasswordFormData.confirmPassword.isValid ||
            accountPasswordFormData.newPassword.value !== accountPasswordFormData.confirmPassword.value
          }
          isLoading={accountPasswordUpdateStatus === StatusEnum.Loading}
          type="submit"
          variant="primary"
        >
          Change password
        </Button>
      </form>
    </SettingsPanel>
  )
}

interface PasswordInputFieldPropsInterface {
  showPassword: boolean
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onShowPasswordChange: (isVisible: boolean) => void
}

function PasswordInputField({ showPassword, value, onChange, onShowPasswordChange }: PasswordInputFieldPropsInterface) {
  return (
    <span className="relative">
      <TextField
        minLength={PASSWORD_MIN_LENGTH}
        required
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
      />
      {Boolean(value) && (
        <ToggleHidePasswordButton onShowPasswordChange={onShowPasswordChange} showPassword={showPassword} />
      )}
    </span>
  )
}
