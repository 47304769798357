// @flow
import React, { PureComponent } from 'react'
import FormItem from '../form/FormItem'
import TextField from '../inputs/TextField'
import styles from './AddressForm.module.css'
import Alert from '../Alert'
import Svg from '../svg'
import type { Node } from 'react'

type Props = {|
  validationErrorMessage: ?string,
  isAddressValid: boolean,
  isPostcodeValid: boolean,
  isPostcodeRequired: boolean,
  isCountyOrStateRequired: boolean,
  isPhoneNumberRequired: boolean,
  isEmailValid: boolean,
  children?: Node,
  name: string,
  address1: string,
  address2: string,
  town: string,
  county: string,
  postcode: string,
  email: ?string,
  mobileTelephone: ?string,
  onChange: (field: string, value: string) => void
|}

const DELIVERY_CONTACT_MESSAGE = `Where customer contact information can be provided, it will help to ensure that there are no delays for packages passing through international borders and customs checks.`

export default class AddressFormView extends PureComponent<Props> {
  render (): React$Node {
    const {
      name,
      address1,
      address2,
      town,
      county,
      postcode,
      onChange,
      isPostcodeValid,
      isEmailValid,
      isCountyOrStateRequired,
      isPostcodeRequired,
      isAddressValid,
      validationErrorMessage,
      email,
      mobileTelephone,
      isPhoneNumberRequired
    } = this.props

    return (
      <form className={styles.form}>
        <FormItem
          required
          validate={Boolean(name)}
          valid={Boolean(name)}
          labelText='Customer Name'
          dataTest="customer-name-form-item"
          inputField={
            <TextField
              type='text'
              name='customerName'
              autoCorrect='off'
              autoCapitalize='off'
              value={name}
              dataTest="customer-name"
              onChange={(e) => onChange('name', e.target.value)} />
          }
        />

        <FormItem
          required
          validate={Boolean(address1)}
          valid={Boolean(address1)}
          labelText='Address'
          dataTest="address-line-1-form-item"
          inputField={
            <TextField
              type='text'
              autoCorrect='off'
              autoCapitalize='off'
              name='address1'
              value={address1}
              dataTest="address-line-1"
              onChange={(e) => onChange('address1', e.target.value)} />
          }
        />

        <FormItem
          required
          inputStyle={{ marginTop: '1rem' }}
          dataTest="address-line-2-form-item"
          inputField={
            <TextField
              type='text'
              autoCorrect='off'
              autoCapitalize='off'
              name='address2'
              value={address2}
              dataTest="address-line-2"
              onChange={(e) => onChange('address2', e.target.value)} />
          }
        />

        <FormItem
          required
          validate={Boolean(town)}
          valid={Boolean(town)}
          labelText='Town/City'
          dataTest="town-form-item"
          inputField={
            <TextField
              type='text'
              autoCorrect='off'
              autoCapitalize='off'
              name='town'
              value={town}
              dataTest="town"
              onChange={(e) => onChange('town', e.target.value)} />
          }
        />

        <FormItem
          required={isCountyOrStateRequired}
          validate={Boolean(isCountyOrStateRequired && county)}
          valid={Boolean(county)}
          labelText='County/State'
          dataTest="state-form-item"
          inputField={
            <TextField
              type='text'
              autoCorrect='off'
              autoCapitalize='off'
              name='stateOrCounty'
              value={county}
              dataTest="state"
              onChange={(e) => onChange('county', e.target.value)} />
          }
        />

        <FormItem
          valid={isPostcodeValid}
          validate={Boolean(isPostcodeRequired && postcode)}
          required={isPostcodeRequired}
          labelText='Postcode'
          dataTest="postcode-form-item"
          inputField={
            <TextField
              uppercase
              type='text'
              autoCorrect='off'
              autoCapitalize='off'
              name='postcode'
              value={postcode}
              dataTest="postcode"
              onChange={(e) => onChange('postcode', e.target.value)} />
          }
        />

        <div className={styles.contactMessageContainer} data-test="address-contact-message-container">
          <div className={styles.contactMessage}>
            <Svg.Information />
            <p>{DELIVERY_CONTACT_MESSAGE}</p>
          </div>

          <FormItem
            validate={Boolean(email)}
            valid={isEmailValid}
            labelText='Email'
            dataTest="email-form-item"
            inputField={
              <TextField
                type='email'
                autoCorrect='off'
                autoCapitalize='off'
                name='email'
                value={email ?? ''}
                dataTest="email"
                onChange={(e) => onChange('email', e.target.value)} />
            }
          />

          <FormItem
            valid={Boolean(mobileTelephone)}
            validate={Boolean(isPhoneNumberRequired && mobileTelephone)}
            required={isPhoneNumberRequired}
            labelText='Phone'
            dataTest="phone-form-item"
            inputField={
              <TextField
                type='tel'
                autoCorrect='off'
                autoCapitalize='off'
                name='mobileTelephone'
                value={mobileTelephone ?? ''}
                dataTest="phone"
                onChange={(e) => onChange('mobileTelephone', e.target.value)} />
            }
          />
        </div>

        {this.props.children}

        {!isAddressValid && validationErrorMessage && (
          <div className={styles.errorMessage} data-test="address-alert-panel">
            <Alert>{validationErrorMessage}</Alert>
          </div>
        )}

      </form>
    )
  }
}
