import cx from 'classnames'

export function OrdersContainer({
  children,
  verticallyCenter = false
}: {
  children: React.ReactNode
  verticallyCenter?: boolean
}) {
  return (
    <div className={cx(`container mx-auto space-y-4`, verticallyCenter && 'grid min-h-screen place-content-center')}>
      {children}
    </div>
  )
}
