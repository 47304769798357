import { useMerchantService } from '../../../hooks'
import OrderItemSource from '../../OrderItemSource'

interface OrderListItemSourcePropsInterface {
  salesChannelId?: string
}

export function OrderListItemSource({ salesChannelId }: OrderListItemSourcePropsInterface) {
  const { merchantDetails } = useMerchantService()

  const salesChannelData = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)

  if (!salesChannelData) {
    return <>-</>
  }

  return <OrderItemSource className="sm:flex-wrap lg:flex-nowrap" source={salesChannelData} />
}
