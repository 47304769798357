interface WhitePanelProps {
  children: React.ReactNode
  className?: string
  dataTest?: string
  heading?: string
  headingStyle?: string
  id?: string
}

export default function WhitePanel({ children, className, dataTest, heading, headingStyle, id }: WhitePanelProps) {
  return (
    <div
      className={`relative box-border w-fit bg-white p-10 shadow-sm sm:p-12 ${className}`}
      data-test={dataTest}
      id={id}
    >
      {heading && <PanelTitle style={headingStyle}>{heading}</PanelTitle>}
      {children}
    </div>
  )
}

function PanelTitle({ children, style }: { children: React.ReactNode; style?: string }) {
  return <h2 className={`mt-0 mb-8 ${style}`}>{children}</h2>
}
