// @flow
import { logLe } from '../actions/logging'
import { user } from '../reducers/logging/logentries/user'
import { catalogue } from '../reducers/logging/logentries/catalogue'
import { orders } from '../reducers/logging/logentries/orders'
import { manualOrderForm } from '../reducers/logging/logentries/manualOrderForm'
import { payments } from '../reducers/logging/logentries/payments'
import { recentItems } from '../reducers/logging/logentries/recentItems'
import { csvUpload } from '../reducers/logging/logentries/csvUpload'
import { images } from '../reducers/logging/logentries/images'
import { quotes } from '../reducers/logging/logentries/quotes'

import type { MiddlewareAPI, Dispatch } from 'redux'
import type { AppState, Action } from '../types'

export default (store: MiddlewareAPI<AppState, Action>): ((next: any) => (action: Action) => any) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const dispatch = store.dispatch
    const state = store.getState()

    const msg = reduce(
      action,
      state,
      user,
      manualOrderForm,
      catalogue,
      orders,
      payments,
      recentItems,
      csvUpload,
      images,
      quotes
    )

    if (msg && msg.length > 0) {
      const text = msg[0].toString()
      const props = typeof msg[1] !== 'string' ? msg[1] : null
      const level = msg[2] ? msg[2].toString() : 'info'

      dispatch(logLe(text, props, level))
    }
    return next(action)
  }

function reduce(action, state, ...reducers) {
  return reducers.map((x) => x(action, state)).reduce((x, y) => (!x ? y : x))
}
