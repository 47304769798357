// @flow
import React from 'react'
import Tippy from '@tippy.js/react'

import styles from '../AddDomain.module.css'

type Props = {|
  appHostName: string,
  domainName: string
|}

type State = {|
  tooltipText: string,
  tooltipZIndex: number
|}

class VerifyOwnership extends React.Component<Props, State> {
  tooltipTimeoutId: null | TimeoutID = null

  constructor (props: Props) {
    super(props)
    const computedStyle = document.documentElement
      ? getComputedStyle(document.documentElement)
      : null

    if (!computedStyle) {
      throw new Error('computedStyle cannot be null')
    }

    this.state = {
      tooltipText: 'Copy value',
      tooltipZIndex: +computedStyle.getPropertyValue('--navigation-ZIndex') + 300
    }
  }

  onCopy: (() => void) = () => {
    navigator.clipboard.writeText(this.props.appHostName).then(() => {
      this.setState({ tooltipText: 'Copied!' })
      this.tooltipTimeoutId = setTimeout(() => {
        this.setState({ tooltipText: 'Copy value' })
      }, 3000)
    }).catch((err) => console.error(err))
  }

  componentWillUnmount () {
    if (this.tooltipTimeoutId) {
      clearTimeout(this.tooltipTimeoutId)
    }
  }

  render (): React$Node {
    return (
      <div className={styles.ownershipStep}>
        <table className={styles.dnsValuesTable}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Host</th>
              <th>Points To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CNAME</td>
              <td>{this.props.domainName}</td>
              <td>
                {this.props.appHostName}
                {navigator.clipboard &&
                <Tippy content={this.state.tooltipText} hideOnClick={false} zIndex={this.state.tooltipZIndex}>
                  <button className={styles.copyButton} onClick={this.onCopy}><i className="fa fa-clone"></i></button>
                </Tippy>}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default VerifyOwnership
