import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { APP_ENVIRONMENTS } from '../../constants'
import { ImageLibraryFileType } from '../../hooks'

interface FileTypePillPropsInterface {
  fileType?: ImageLibraryFileType
  className?: string
}

export function ImageLibraryFileTypePill({ className = '', fileType }: FileTypePillPropsInterface) {
  if (!fileType) {
    return null
  }

  return (
    <span
      className={clsx(
        twMerge('w-fit rounded-full px-4 py-1 text-center text-xs', getFileTypeThemeColor(fileType), className)
      )}
    >
      {getFileTypeLabel(fileType)}
    </span>
  )
}

function getFileTypeThemeColor(fileType: ImageLibraryFileType) {
  switch (fileType) {
    case 'jpg':
      return 'bg-cyan-400/10'
    case 'png':
      return 'bg-purple-200/50'
    case 'pdf (single page)':
    case 'pdf (multi-page)':
      return 'bg-status-red/10'
    default:
      const unhandledFileType: never = fileType
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled file_type ${unhandledFileType}. Add a new case to fix this.`)
      }
      return 'bg-gray-300'
  }
}

function getFileTypeLabel(fileType: ImageLibraryFileType) {
  switch (fileType) {
    case 'jpg':
      return 'jpg'
    case 'png':
      return 'png'
    case 'pdf (single page)':
    case 'pdf (multi-page)':
      return 'pdf'
    default:
      const unhandledFileType: never = fileType
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled file_type ${unhandledFileType}. Add a new case to fix this.`)
      }
      return ''
  }
}
