// @flow
import type {
  Point,
  LengthUnit,
  Dimensions,
  CropRectangle
} from '../../types'
import { PositionUnitConverter, LENGTH_UNIT } from '@prodigi-group/components-image-editor'

export function convertPositionToPrintPx (
  printDimensionsInPx: Dimensions,
  artworkDimensionsInPx: Dimensions,
  cropRectangle: CropRectangle,
  printDpi: number,
  positionUnit: LengthUnit,
  position: Point
): any | Point {
  const converter = new PositionUnitConverter(
    printDimensionsInPx,
    artworkDimensionsInPx,
    cropRectangle,
    printDpi
  )

  if (positionUnit === LENGTH_UNIT.PRINT_PX) {
    return position
  } else if (positionUnit === LENGTH_UNIT.ARTWORK_PX) {
    return converter.artworkPxToPrintPx(position)
  } else if (positionUnit === LENGTH_UNIT.IN) {
    return converter.inchToPrintPx(position)
  } else if (positionUnit === LENGTH_UNIT.CM) {
    return converter.cmToPrintPx(position)
  } else {
    throw new Error(`Position unit: ${positionUnit} is invalid.`)
  }
}
