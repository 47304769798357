import toast from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import Button from '../../v2/components/Button'
import { useSplitToggle } from '../../v2/hooks'
import { QUERY_PARAMS } from '../../v2/constants'
import { FEATURE_NAMES } from '../../split-io/feature-names'
import { createErrorToast } from '../../v2/components/Toast'
import { V1DashboardArtworkInterface } from '../../v2/interfaces'
import ImageLibraryModal from '../../v2/components/ImageLibraryModal'
import { generateArtworkDataForImageLibraryImage } from '../../v2/components/ImageEditor/helpers/generateArtworkDataForImageLibraryImage.helper'
import { generateImageLibraryFilterParamsForArtwork } from '../../v2/helpers/generateImageLibraryFilterParamsForArtwork.helper'

interface ImageLibraryButtonPropsInterface {
  children: ReactNode
  productType: string
  onOpenImageLibrary?: () => void
  onSelectImageLibraryImage: (artwork: V1DashboardArtworkInterface) => void
}

const IMAGE_LIBRARY_BUTTON_ERROR_TOAST_ID = 'prodigiImageLibraryButtonErrorToast'

export function ImageLibraryButton({
  children,
  productType,
  onOpenImageLibrary,
  onSelectImageLibraryImage
}: ImageLibraryButtonPropsInterface) {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  useEffect(() => {
    if (!isImageLibraryModalOpen) {
      toast.dismiss(IMAGE_LIBRARY_BUTTON_ERROR_TOAST_ID)
    }
  }, [isImageLibraryModalOpen])

  function onSetIsImageLibraryModalOpen(open: boolean) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpen(open)
  }

  return (
    <div className="tailwind" style={{ width: '100%' }}>
      <div className="bg-white">
        <Button
          className="w-full"
          dataTest="add-image-library-button"
          variant="secondary"
          onClick={() => {
            const newSearchParams = generateImageLibraryFilterParamsForArtwork({ searchParams, productType })
            if (!isPDFSplitOn) {
              newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
            }

            history.replace({ pathname, search: newSearchParams.toString() })

            setIsImageLibraryModalOpen(true)
            if (onOpenImageLibrary) {
              onOpenImageLibrary()
            }
          }}
        >
          {children}
        </Button>
      </div>

      <ImageLibraryModal
        open={isImageLibraryModalOpen}
        type="artwork"
        setOpen={onSetIsImageLibraryModalOpen}
        onSelectImage={(imageLibraryImage) => {
          const { artwork, error } = generateArtworkDataForImageLibraryImage({ imageLibraryImage, productType })

          if (!artwork) {
            onSetIsImageLibraryModalOpen(false)
            createErrorToast({
              heading: 'An error occurred while selecting file',
              errorMessage: error?.message,
              id: IMAGE_LIBRARY_BUTTON_ERROR_TOAST_ID
            })
            return
          }

          onSetIsImageLibraryModalOpen(false)
          onSelectImageLibraryImage(artwork)
        }}
      />
    </div>
  )
}
