// @flow
import type { Cost } from '../../types'

export function formatCost (cost: Cost): string {
  return (+cost.amount).toLocaleString('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    useGrouping: true,
    currency: cost.currency
  })
}
