import Checkbox from '../../Checkbox'
import { formatCost } from '../../../helpers'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { OrderHistoryTypeEnum } from '../../OrderDetail/enums'
import { OrderAdditionalChargeInterface, OrderDetailHistoryInterface } from '../interfaces'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export interface AdditionalChargeInterface extends OrderDetailHistoryInterface {
  data: OrderAdditionalChargeInterface
  orderHistoryType: OrderHistoryTypeEnum.OrderAdditionalCharge
}

interface OrderDetailCostSummaryPropsInterface {
  additionalCharges: Array<AdditionalChargeInterface>
  hasUnallocated: boolean
  itemCost?: CostInterface
  orderId: string
  shippingCost?: CostInterface
  taxCost?: CostInterface
  totalCost?: CostInterface
  usSalesTaxCollected: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export function OrderDetailCostSummary({
  additionalCharges,
  orderId,
  usSalesTaxCollected,
  itemCost,
  shippingCost,
  taxCost,
  totalCost,
  hasUnallocated
}: OrderDetailCostSummaryPropsInterface) {
  return (
    <div className="border bg-white p-6" aria-labelledby="cost-summary-heading">
      <h2 id="cost-summary-heading" className="mt-0 text-lg">
        Cost summary
      </h2>
      <div className="mt-4 flex flex-col justify-between lg:flex-row lg:space-x-8">
        <CostBreakdown
          itemCost={itemCost}
          shippingCost={shippingCost}
          taxCost={taxCost}
          totalCost={totalCost}
          hasUnallocated={hasUnallocated}
          additionalCharges={additionalCharges}
        />
        <div className="md:w-1/3">
          <SalesTaxCheckbox usSalesTaxCollected={usSalesTaxCollected} orderId={orderId} />
          {hasUnallocated && <TotalsIncomplete />}
        </div>
      </div>
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

function CostBreakdown({
  additionalCharges,
  itemCost,
  shippingCost,
  taxCost,
  totalCost,
  hasUnallocated
}: {
  additionalCharges: Array<AdditionalChargeInterface>
  itemCost?: CostInterface
  shippingCost?: CostInterface
  taxCost?: CostInterface
  totalCost?: CostInterface
  hasUnallocated: boolean
}) {
  return (
    <div className="max-w-xl md:w-1/2" data-test="order-detail-cost-summary-breakdown">
      <table className="w-full" title="Cost breakdown">
        <tbody>
          {itemCost && (
            <tr>
              <td className="py-2 pr-2">Items</td>
              <td className="py-2 text-right" data-test="order-detail-cost-summary__item-cost">
                {formatCost({
                  amount: itemCost?.amount,
                  currencyCode: itemCost?.currencyCode
                })}
              </td>
            </tr>
          )}
          {shippingCost && (
            <tr>
              <td className="py-2 pr-2">Shipping</td>
              <td className="relative py-2 text-right" data-test="order-detail-cost-summary__shipping-cost">
                {formatCost({
                  amount: shippingCost?.amount,
                  currencyCode: shippingCost?.currencyCode
                })}
                {hasUnallocated && <span className="absolute pl-1 text-status-red">*</span>}
              </td>
            </tr>
          )}
          {taxCost && (
            <tr>
              <td className="py-2 pr-2">Tax</td>
              <td className="relative pt-2 pb-4 text-right" data-test="order-detail-cost-summary__tax-cost">
                {formatCost({
                  amount: taxCost?.amount,
                  currencyCode: taxCost?.currencyCode
                })}
                {hasUnallocated && <span className="absolute pl-1 text-status-red">*</span>}
              </td>
            </tr>
          )}
          <tr>
            <td className="py-4 pr-2 font-medium">Total</td>
            <td className="relative py-4 text-right font-medium" data-test="order-detail-cost-summary__total-cost">
              {formatCost({
                amount: totalCost?.amount,
                currencyCode: totalCost?.currencyCode
              }) || 'Pending'}
              {hasUnallocated && <span className="absolute pl-1 text-status-red">*</span>}
            </td>
          </tr>
        </tbody>
      </table>

      {additionalCharges?.length > 0 && (
        <>
          <hr className="my-3 border-gray-500" />
          <AdditionalCharges additionalCharges={additionalCharges} />
        </>
      )}
    </div>
  )
}

function AdditionalCharges({ additionalCharges }: { additionalCharges: Array<AdditionalChargeInterface> }) {
  return (
    <table className="w-full">
      <tbody>
        {additionalCharges.map((additionalCharge) => (
          <tr key={additionalCharge.date}>
            <td className="py-2 pr-2">
              {additionalCharge.data.type.toLowerCase() === 'charge' && <span>Extra charge</span>}
              {additionalCharge.data.type.toLowerCase() === 'refund' && <span>Refund</span>}

              {additionalCharge.data.description && (
                <div className="text-sm text-gray-600">{additionalCharge.data.description}</div>
              )}
            </td>

            <td className="py-2 text-right">
              <span className="whitespace-nowrap">
                {formatCost({
                  amount: additionalCharge.data.totalCost.amount,
                  currencyCode: additionalCharge.data.totalCost.currencyCode
                })}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

function SalesTaxCheckbox({ usSalesTaxCollected, orderId }: { usSalesTaxCollected: boolean; orderId: string }) {
  if (!usSalesTaxCollected) {
    return null
  }

  return (
    <div data-test="order-detail-cost-summary__tax-declaration">
      <h3 className="mt-3 mb-4">Tax summary</h3>
      <div className="relative">
        <Checkbox
          disabled={true}
          id="taxCollectedCheckbox"
          checked={usSalesTaxCollected}
          label="I declare that this order has already had sales tax collected."
        />
        <div
          className="absolute inset-0 w-full"
          onClick={() => {
            window.analytics.track('Clicked Tax Declaration', { orderId })
          }}
        />
      </div>
    </div>
  )
}

function TotalsIncomplete() {
  return (
    <div className="mt-8">
      <span className="pr-1 text-status-red">*</span>
      <span className="text-sm">Excludes any unallocated items.</span>
    </div>
  )
}
