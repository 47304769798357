import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { DEFAULT_DOCUMENT_TITLE } from '../../../constants'
import ProductSkuDetail from '../../../components/ProductSkuDetail'

export default function ProductSkuDetailPage() {
  const { sku } = useParams<{ sku: string }>()

  useEffect(() => {
    document.title = `${sku} | Prodigi`
    window.analytics.page('Product Details', { sku })

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [sku])

  return (
    <main className="tailwind">
      <div className="container">
        <ProductSkuDetail />
      </div>
    </main>
  )
}
