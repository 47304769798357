import { useSWRConfig } from 'swr'
import { FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { StatusEnum } from '../../enums'
import { QUERY_PARAMS } from '../../constants'
import { isEmailValid } from '../../../helpers'
import { fetcher, saveJwtTokens } from '../../helpers'
import LeftPanel from './components/LeftPanel.component'
import RightPanel from './components/RightPanel.component'
import { AuthTokensInterface, FetchErrorInterface } from '../../interfaces'
import { getIsUserLoggedIn, getSessionExpired } from '../../../selectors/auth'
import { startJwtTimerIfNeeded } from '../../../actions/auth/startJwtTimerIfNeeded'
import { getLogInErrorMessage, logInError, logInStart, logInSuccess } from '../../actions'

export default function Login() {
  const { search } = useLocation()
  const history = useHistory()
  const isUserLoggedIn: boolean = useSelector((state) => getIsUserLoggedIn(state))
  const sessionExpired: boolean = useSelector((state) => getSessionExpired(state))
  const { cache } = useSWRConfig()
  const dispatch = useDispatch()

  const [loginStatus, setLoginStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | undefined>()
  const [email, setEmail] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null)
  const [password, setPassword] = useState('')
  const [autoFocusFormField, setAutoFocusFormField] = useState(false)

  const errorMessage = sessionExpired ? 'Your session has expired.' : loginErrorMessage

  useEffect(() => {
    setAutoFocusFormField(window.matchMedia('(hover: hover)').matches)
  }, [])

  async function handleLogin(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setEmailErrorMessage(null)
    if (!isEmailValid(email)) {
      setEmailErrorMessage('Please enter a valid email address.')
      return
    }

    // SWR v1.3.0 supports this as per the docs but is not typed
    // See https://swr.vercel.app/docs/advanced/cache#access-to-the-cache
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cache as any)?.clear?.()

    setLoginStatus(StatusEnum.Loading)
    dispatch(logInStart())
    try {
      const authTokens = await fetcher<AuthTokensInterface>(process.env.REACT_APP_AUTH_SERVICE + '/api/auth', {
        body: JSON.stringify({ username: email, password }),
        method: 'POST'
      })
      saveJwtTokens(authTokens)
      dispatch(startJwtTimerIfNeeded())
      dispatch(logInSuccess())
      setLoginStatus(StatusEnum.Success)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      setLoginErrorMessage(getLogInErrorMessage(errorResponse.status))
      dispatch(logInError(errorResponse.status))
      setLoginStatus(StatusEnum.Error)
    }
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      const searchParams = new URLSearchParams(search)
      const redirectPath = searchParams.get(QUERY_PARAMS.LOGIN.REDIRECT) || '/'
      history.replace(redirectPath)
    }
  }, [history, isUserLoggedIn, search])

  return (
    <div className="max-w-[1440px] mx-auto flex min-h-screen text-lg">
      <LeftPanel
        onSubmitLogin={handleLogin}
        emailErrorMessage={emailErrorMessage}
        loading={loginStatus === StatusEnum.Loading}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        errorMessage={errorMessage}
        autoFocusFormField={autoFocusFormField}
      />
      <RightPanel />
    </div>
  )
}
