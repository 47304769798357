// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Product ({ className }: Props): Element<"svg"> {
  return (
  <svg className={className} width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
        <path d="M325.606,4.393C322.794,1.58,318.978,0,315,0L15,0.004c-8.284,0-15,6.716-15,15V95c0,8.284,6.716,15,15,15h5v205
          c0,8.284,6.716,15,15,15h260c8.284,0,15-6.716,15-15V110h5c8.284,0,15-6.716,15-15V15C330,11.021,328.42,7.207,325.606,4.393z
          M280,300H50V110.013l230-0.012V300z M300,80H30V30.004L300,30V80z"/>
        <path d="M125,170.008h80c8.284,0,15-6.716,15-15s-6.716-15-15-15h-80c-8.284,0-15,6.716-15,15S116.716,170.008,125,170.008z"/>
  </svg>
  )
}
