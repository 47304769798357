// @flow
import React, { PureComponent } from 'react'
import styles from './CsvItemDetails.module.css'
import type { CsvItemProductDetails, FormattedOrderItem } from '../../../../types'
import cx from 'classnames'
import CsvItemImage from '../CsvItemImage'
import CsvItemProduct from '../CsvItemProduct'
import Heading from '../Heading'

type Props = {|
  item: FormattedOrderItem,
  itemIndex: number,
  numberOfItems: number,
  details: ?CsvItemProductDetails,
  isCompleted: boolean,
  canBeEdited: boolean,
  isImageModalOpen: boolean,
  orderId: string,
  openProductSearchModal: (itemId: string) => void,
  closeImageModal: () => void,
  openImageModal: (orderItemId: string) => void
|}

export default class CsvItemDetailsView extends PureComponent <Props> {
  render (): React$Node {
    const { orderId, details, item, isCompleted, canBeEdited, itemIndex, numberOfItems } = this.props
    const imageStyle = isCompleted ? cx(styles.preview, styles.hide) : styles.preview

    return (
      <div className={cx(styles.detailsGrid, styles.orderItem)}>
        <div className={styles.product} data-test="product-details">
          <Heading>Product {numberOfItems > 1 && <span>({itemIndex + 1} of {numberOfItems})</span>}</Heading>
          <CsvItemProduct
            openProductSearchModal={this.props.openProductSearchModal}
            orderId={orderId}
            canBeEdited={canBeEdited}
            item={item}
            productDetails={details} />
        </div>

        <CsvItemImage
          item={item}
          details={details}
          canBeEdited={canBeEdited}
          imageStyle={imageStyle}
          closeImageModal={this.props.closeImageModal}
          openImageModal={this.props.openImageModal}
          isImageModalOpen={this.props.isImageModalOpen}
          orderId={this.props.orderId} />
      </div>
    )
  }
}
