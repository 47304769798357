// @flow
import type { Action } from '../../types'

export const RESET_ORDER_LIST_STATE = 'RESET_ORDER_LIST_STATE'

export function resetOrderListState (): Action {
  return {
    type: RESET_ORDER_LIST_STATE
  }
}
