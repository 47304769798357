import { DictionaryInterface } from '../interfaces'
import { MockupInterface } from '../hooks'

export function getMockupDetailsForSelectedAttributes({
  attributes: attributesProp,
  mockups,
  orientation
}: {
  attributes: DictionaryInterface<string>
  mockups: MockupInterface | undefined
  orientation: string
  orientationSelectionAvailable: boolean
}) {
  if (!mockups || !mockups?.views) {
    return null
  }

  // Only use cover mockups until blender ones are ready
  const firstAvailableViewId = 'cover'
  // const firstAvailableViewId = orientationSelectionAvailable
  //   ? Object.keys(mockups.views).find((view) => Object.keys(mockups.views[view].variants).length > 0)
  //   : Object.keys(mockups.views)[0]

  if (!firstAvailableViewId) {
    return null
  }

  const mockup = mockups.views[firstAvailableViewId]

  if (!mockup) {
    return null
  }

  const attributes = { ...attributesProp }
  attributes['orientation'] = orientation

  const filteredAttributes = mapAndFilterAttributes(attributes, Object.keys(mockup.variants))

  const doesMockupExistForSelectedAttributes = Object.entries(filteredAttributes).every(
    ([key, value]) => mockup.variants[key] && mockup.variants[key].includes(value)
  )

  if (!doesMockupExistForSelectedAttributes) {
    return null
  }

  return {
    view_name: firstAvailableViewId,
    variants: filteredAttributes
  }
}

const ATTRIBUTE_MAPPINGS = [
  {
    prodigi: 'mountColour',
    mockup: 'mountColor'
  },
  {
    prodigi: 'frameColour',
    mockup: 'frameColor'
  }
]

function mapAndFilterAttributes(attributes: DictionaryInterface<string>, variantOptions: string[]) {
  const mappedAttributes = Object.entries(attributes).reduce(
    (mappedAttributesAcc: DictionaryInterface<string>, [attributeName, attributeValue]) => {
      const attributeMapping = ATTRIBUTE_MAPPINGS.find((attributeMapping) => attributeName === attributeMapping.prodigi)
      let mappedAttributeName = attributeMapping?.mockup ?? attributeName

      // Image library mockups endpoint changed frameColor to color, so we have this check
      // Once image library changes are released to live, we can remove this check and
      // update mapping { prodigi: 'frameColour', mockup: 'frameColor' } to { prodigi: 'frameColour', mockup: 'color' }9
      if (mappedAttributeName === 'frameColor' && variantOptions?.includes('color')) {
        mappedAttributeName = 'color'
      }

      if (variantOptions.includes(mappedAttributeName)) {
        return { ...mappedAttributesAcc, [mappedAttributeName]: attributeValue }
      }

      return mappedAttributesAcc
    },
    {}
  )

  return mappedAttributes
}
