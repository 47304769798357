// @flow
import CsvFilterOrdersView from './CsvFilterOrdersView'
import React, { PureComponent } from 'react'
import { ALL_ORDERS, REQUIRE_INFO, ORDERS_WITH_ERRORS } from '../../../../data/csvUpload'

type Props = {|
  submitCsvLoading: boolean,
  submitCsvError: boolean,
  totalOrders: number,
  totalIgnoredOrders: number,
  selectedFilter: string,
  numberOfOrdersRequiringAction: number,
  numberOfOrdersWithErrors: number,
  changeFilter: (newFilter: string) => void
|}

export default class CsvFilterOrdersController extends PureComponent <Props> {
  componentDidUpdate () {
    if (!this.isSelectedFilterValid) {
      this.props.changeFilter(ALL_ORDERS)
    }
  }

  get isSelectedFilterValid (): boolean {
    const { selectedFilter, numberOfOrdersRequiringAction, numberOfOrdersWithErrors } = this.props

    if (selectedFilter === REQUIRE_INFO) {
      return numberOfOrdersRequiringAction > 0
    } else if (selectedFilter === ORDERS_WITH_ERRORS) {
      return numberOfOrdersWithErrors > 0
    } else {
      return true
    }
  }

  render (): React$Node {
    return <CsvFilterOrdersView {...this.props} />
  }
}
