import { useParams } from 'react-router-dom'

import TextField from '../../TextField'
import { formatCost } from '../../../helpers'
import { useOrderDetail } from '../../../hooks'
import { OrderDetailItemInterface } from '../../../interfaces'

export function LineItemChannelInfo({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  const formattedCost = formatCost({
    amount: lineItem.salesChannelProductDetails.totalPrice,
    currencyCode: orderDetails.billingCurrency
  })

  return (
    <div className="flex flex-col gap-2">
      {lineItem.salesChannelProductDetails.title && (
        <div className="text-xs uppercase tracking-wider text-gray-600">Item</div>
      )}
      <div className="text-md font-medium text-black">{lineItem.salesChannelProductDetails.title}</div>
      <div className="text-sm text-gray-600">{lineItem.salesChannelProductDetails.productType}</div>

      {formattedCost && (
        <div className="flex flex-col items-start gap-1">
          <span className="text-sm">Selling price</span>
          <div>
            <TextField
              className="mt-0 h-auto px-2 py-2 text-sm"
              readOnly
              disabled
              size={formattedCost.length}
              value={formattedCost}
            />
          </div>
        </div>
      )}
    </div>
  )
}
