// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function ArrowLeft ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20L11.4 18.6L3.9 11L25 11L25 9L3.9 9L11.4 1.4L10 1.31134e-06L-8.74228e-07 10L10 20Z" fill="black"/>
    </svg>

  )
}
