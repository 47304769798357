// @flow

import * as React from 'react'
import classNames from 'classnames'
import styles from './Stack.module.css'

type Props = {|
  spacing?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl',
  align?: 'start' | 'center' | 'end' | 'stretch',
  direction?: 'row' | 'column',
  className? : string,
  children: React$Node
|}

function Stack ({ spacing, align, direction = 'column', children, className }: Props): React$Node {
  const classes = classNames(
    className,
    styles.stack, {
      [styles.spacingNone]: spacing === 'none',
      [styles.spacingSm]: spacing === 'sm',
      [styles.spacingMd]: spacing === 'md',
      [styles.spacingLg]: spacing === 'lg',
      [styles.spacingXl]: spacing === 'xl',
      [styles.spacing2Xl]: spacing === '2xl',
      [styles.stackRow]: direction === 'row',
      [styles.alignStart]: align === 'start',
      [styles.alignCenter]: align === 'center',
      [styles.alignEnd]: align === 'end',
      [styles.alignStretch]: align === 'stretch'
    })

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Stack
