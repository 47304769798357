import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'
import { useMerchantService } from './useMerchantService.hook'

export type OrderEditWindowType = 'Immediate' | 'TwoHours' | 'SixHours' | 'TwentyFourHours' | 'Indefinite' | 'Custom'

export interface MerchantPreferencesEmailsInterface {
  ordersThatRequireAttention: boolean
}

export interface MerchantPreferencesInterface {
  starredProducts: Array<{ skuName: string }>
  orderEditCustomWindow?: number | null
  orderEditWindow: OrderEditWindowType
  emails: MerchantPreferencesEmailsInterface
}

interface MerchantPreferencesResponseInterface {
  data: MerchantPreferencesInterface
  traceParent: string
}

export interface MerchantPreferencesErrorInterface {
  outcome?: string
  failures?: Record<string, Array<{ code?: string; providedValue?: string }>>
  traceParent?: string
}

export function useMerchantPreferences({ config }: { config?: SWRConfiguration } = {}) {
  const { merchantDetails } = useMerchantService()

  const url = `${process.env.REACT_APP_OMS_MERCHANT_PREFERENCE_SERVICE}/merchant/${merchantDetails?.id}`

  const {
    data: merchantPreferencesResponse,
    isValidating: isValidatingMerchantPreferences,
    mutate: mutateMerchantPreferences,
    error
  } = useSWR<MerchantPreferencesResponseInterface, FetchErrorInterface<MerchantPreferencesErrorInterface>>(
    url,
    fetcher,
    config
  )

  return {
    isLoadingMerchantPreferences: !merchantPreferencesResponse && !error,
    isValidatingMerchantPreferences,
    merchantPreferences: merchantPreferencesResponse?.data,
    merchantPreferencesResponse,
    error,
    mutateMerchantPreferences
  }
}
