// @flow
import React, { Component } from 'react'
import LogOutErrorModalView from './LogOutErrorModalView'

type Props = {|
  kiteLogoutError: boolean,
  revokeTokenError: boolean,
  resetRsaaStatus: () => void,
  logOut: () => void
|}

export default class LogOutErrorModalController extends Component<Props> {
  render (): React$Node {
    if (this.props.revokeTokenError || this.props.kiteLogoutError) {
      return <LogOutErrorModalView close={this.props.resetRsaaStatus} retry={this.props.logOut} />
    }

    return null
  }
}
