// @flow
export const OK = 200
export const PAYMENT_REQUIRED = 402
export const UNAUTHORIZED = 401
export const FORBIDDEN = 403
export const NOT_FOUND = 404
export const GONE = 410
export const SERVER_ERROR = 500
export const BAD_REQUEST = 400
export const PAYLOAD_TOO_LARGE = 413

export const STATUS_CODES = {
  OK,
  BAD_REQUEST,
  UNAUTHORIZED,
  FORBIDDEN,
  PAYMENT_REQUIRED,
  NOT_FOUND,
  GONE,
  SERVER_ERROR,
  PAYLOAD_TOO_LARGE
}
