import { Redirect } from 'react-router'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { FormEvent, MouseEvent, useEffect, useReducer, useState } from 'react'

import { registerReducer } from './reducers'
import { LOG_IN } from '../../../actions/auth'
import { REDIRECT } from '../../../data/searchParams'
import { ERRORS } from '../../constants/errors.const'
import { resetRsaaStatus } from '../../../actions/rsaa'
import LeftPanel from './components/LeftPanel.component'
import RightPanel from './components/RightPanel.component'
import { RsaaStatusPropsInterface } from '../../interfaces'
import { getIsUserLoggedIn } from '../../../selectors/auth'
import { getRsaaStatusProps } from '../../../selectors/rsaa'
import { selectAuthServiceUrl } from '../../../selectors/appSettings'
import { GENERIC_ERROR_MESSAGE } from './constants/errorMessage.const'
import { INITIAL_REGISTER_FORM_DATA } from './constants/initialFormData.const'
import { EmailAvailabilityStatusEnum, RegisterReducerActionTypeEnum } from './enums'
import { processEmailAvailabilityResponse, registerUser } from './helpers'
import { REQUEST_REGISTER_USER } from '../../constants/registerUser.const'

export default function Register() {
  const [mounted, setMounted] = useState(false)
  const [emailAvailabilityAbortController, setEmailAvailabilityAbortController] = useState<AbortController | null>(null)
  const [registerFormData, registerFormDispatch] = useReducer(registerReducer, INITIAL_REGISTER_FORM_DATA)

  const history = useHistory()
  const dispatch = useDispatch()
  const { stepNumber: stepNumberParam } = useParams<{ stepNumber: string }>()
  const isUserLoggedIn: boolean = useSelector((state) => getIsUserLoggedIn(state))
  const requestRegisterUserRsaaStatusProps: RsaaStatusPropsInterface = useSelector((state) =>
    getRsaaStatusProps(state, REQUEST_REGISTER_USER)
  )
  const loginRsaaStatusProps: RsaaStatusPropsInterface = useSelector((state) => getRsaaStatusProps(state, LOG_IN))

  const currentStepNumber = Number(stepNumberParam)

  useEffect(() => {
    const isPathIncorrect = Boolean(!currentStepNumber || currentStepNumber !== 1)
    if (!mounted && isPathIncorrect) {
      history.replace(`/register/1` + window.location.search)
    }
    setMounted(true)
  }, [mounted, currentStepNumber, history])

  useEffect(() => {
    if (currentStepNumber !== 1) {
      dispatch(resetRsaaStatus(REQUEST_REGISTER_USER))
    }
  }, [currentStepNumber, dispatch])

  const checkEmailAvailability = useDebouncedCallback((email: string) => {
    if (emailAvailabilityAbortController) {
      emailAvailabilityAbortController.abort()
    }
    const newController = new window.AbortController()
    setEmailAvailabilityAbortController(newController)
    window.analytics.track('Registration started')
    const url = `${selectAuthServiceUrl()}/api/auth/validate`
    fetch(url, {
      signal: newController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      })
    })
      .then(processEmailAvailabilityResponse)
      .then((result) => {
        registerFormDispatch({
          type: RegisterReducerActionTypeEnum.SetEmailAvailability,
          payload: {
            emailAvailability: result,
            error: result.error ?? null
          }
        })
      })
      .catch((error) => {
        if (error.name === ERRORS.ABORT.NAME) {
          return
        }
        registerFormDispatch({
          type: RegisterReducerActionTypeEnum.SetEmailAvailability,
          payload: {
            emailAvailability: {
              availability: EmailAvailabilityStatusEnum.Error
            },
            error: GENERIC_ERROR_MESSAGE
          }
        })
      })
  }, 200)

  useEffect(() => {
    if (registerFormData?.email?.value.trim() !== '' && registerFormData?.email?.valid) {
      registerFormDispatch({
        type: RegisterReducerActionTypeEnum.SetEmailAvailability,
        payload: {
          emailAvailability: {
            availability: EmailAvailabilityStatusEnum.Loading
          },
          error: null
        }
      })
      checkEmailAvailability(registerFormData?.email?.value)
    }
  }, [registerFormData?.email?.value, checkEmailAvailability, registerFormData?.email?.valid])

  function onStepChange(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, newStepNumber: number) {
    event.preventDefault()
    trackStepChanges(currentStepNumber, newStepNumber)
    history.push(`/register/${newStepNumber}` + window.location.search)
  }

  function trackStepChanges(currentStepNumber: number, newStepNumber: number) {
    if (currentStepNumber && newStepNumber && newStepNumber > currentStepNumber) {
      window.analytics.track(`Register step ${currentStepNumber} complete`)
    }
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const userDetails = {
      email: registerFormData.email.value.trim(),
      password: registerFormData.password.value,
      confirmPassword: registerFormData.password.value,
      fullName: registerFormData.fullName.value,
      companyName: registerFormData.companyName.value,
      countryName: registerFormData.countryName.value,
      billingCurrency: registerFormData.billingCurrency.value
    }
    const marketingData = {
      marketingPriorityToday: registerFormData.marketingPriorityToday?.value ?? null,
      marketingWhyCreatingAccount: registerFormData.marketingWhyCreatingAccount?.value ?? null
    }
    dispatch(registerUser(userDetails, marketingData))
  }

  function getRedirectPath() {
    const params = window && new window.URLSearchParams(window.location.search)
    return (params && params.get(REDIRECT)) || '/'
  }

  if (isUserLoggedIn) {
    return <Redirect to={getRedirectPath()} />
  }

  if (!mounted) {
    return null
  }

  return (
    <main className="mx-auto flex min-h-screen max-w-[1440px] text-lg">
      <LeftPanel
        accountCreationError={
          requestRegisterUserRsaaStatusProps.error ? requestRegisterUserRsaaStatusProps.statusMessage : null
        }
        accountCreationLoading={requestRegisterUserRsaaStatusProps.loading || loginRsaaStatusProps.loading}
        autoFocusFormField={window.matchMedia('(hover: hover)').matches}
        formData={registerFormData}
        stepNumber={currentStepNumber}
        onStepChange={onStepChange}
        onSubmit={onSubmit}
        registerFormDispatch={registerFormDispatch}
      />
      <RightPanel stepNumber={currentStepNumber} />
    </main>
  )
}
