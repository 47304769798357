import { FetchError, getDefaultFetchOptions } from '../../../helpers'

export async function mockupPIGImageFetcher(url: string): Promise<number> {
  return fetch(url, { ...getDefaultFetchOptions() }).then(async (response) => {
    if (!response.ok) {
      throw new FetchError({
        message: response.statusText,
        name: 'FETCH_ERROR',
        status: response.status
      })
    }
    return response.status
  })
}
