// @flow
import { ORDER_FILTERS } from '../../data/orderFilters'
import type {
  GetStateFunc,
  DispatchFunc,
  OrderFilterScope,
  Thunk
} from '../../types'
import { updateUrlSearchParam } from './updateUrlSearchParam'
import { discardSavedOrderListPages } from './orderPages'
import { getOrders } from './getOrders'
import { ORDERS_PER_PAGE } from '../../data/constants'

export const UPDATE_SKIP_VALUE = 'UPDATE_SKIP_VALUE'
export const UPDATE_LIMIT_VALUE = 'UPDATE_LIMIT_VALUE'
export const CHANGE_ORDER_FILTER_QUERY = 'CHANGE_ORDER_FILTER_QUERY'
export const CHANGE_ORDER_FILTER_SCOPE = 'CHANGE_ORDER_FILTER_SCOPE'

export function updateSkipValue (skip: number): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({ type: UPDATE_SKIP_VALUE, skip })
    dispatch(updateUrlSearchParam(ORDER_FILTERS.SKIP, skip.toString()))
  }
}

export function updateLimitValue (limit: number): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({ type: UPDATE_LIMIT_VALUE, limit })
    dispatch(updateUrlSearchParam(ORDER_FILTERS.LIMIT, limit.toString()))
  }
}

export function changeOrderFilterQuery (filterQuery: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({ type: CHANGE_ORDER_FILTER_QUERY, filterQuery })
    dispatch(updateUrlSearchParam(ORDER_FILTERS.QUERY, filterQuery))
    dispatch(discardSavedOrderListPages())
    dispatch(resetSkipAndLimitFilters())
    dispatch(getOrders())
  }
}

export function changeOrderFilterScope (scope: OrderFilterScope): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({ type: CHANGE_ORDER_FILTER_SCOPE, scope })
    dispatch(updateUrlSearchParam(ORDER_FILTERS.SCOPE, scope))
    dispatch(discardSavedOrderListPages())
    dispatch(resetSkipAndLimitFilters())
    dispatch(getOrders())
  }
}

export function resetSkipAndLimitFilters (): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(updateSkipValue(0))
    dispatch(updateLimitValue(ORDERS_PER_PAGE))
  }
}
