// @flow

import { PAYMENT_AUTH_ERROR_MESSAGE } from '../../data/errorMessages'

export const PAYMENT_ERROR_MESSAGE_PART = `Sorry, we aren't able to process your payment at this moment in time.`

export function mapApiPaymentErrorMessage (paymentErrorDescription: ?string): string {
  if (!paymentErrorDescription) {
    return PAYMENT_AUTH_ERROR_MESSAGE
  }

  const isErrorDescriptionExposingDetailsAboutPaymentProvider = Boolean(
    paymentErrorDescription.toLowerCase().includes('stripe')
  )

  return !isErrorDescriptionExposingDetailsAboutPaymentProvider
    ? PAYMENT_ERROR_MESSAGE_PART + ' ' + paymentErrorDescription
    : PAYMENT_AUTH_ERROR_MESSAGE
}
