// @flow
import type { Dictionary, ValidationFailure } from '../../../types'
import { entries } from '../../dictionary'

const EXCEEDED_ALLOWED_QUANTITY_CODE = 'ExceededAllowedQuantity'

const ADDRESS_FAILURE_CODE_PART = 'recipient.address'
const RECIPIENT_FAILURE_CODE_PART = 'recipient'
const ITEM_FAILURE_CODE_PART = 'items'
const VALIDATION_ERROR_MESSAGE_PART_1 = `The order couldn't be submitted due to a validation error.`

const ADDRESS_PROP_NAME_MAP = {
  line1: 'First line of the address',
  line2: 'Second line of the address',
  postalOrZipCode: 'Postal or ZIP code',
  stateOrCounty: 'State or county',
  countryCode: 'Country code',
  townOrCity: 'Town or city',
  name: 'Name',
  email: 'Email',
  phoneNumber: 'Phone number'
}

const FAILURE_CODE_MAP = {
  'Required': 'is required',
  'MustNotBeEmptyOrWhitespace': 'must not be empty',
  'MustNotBeEmptyArray': 'must not be empty',
  'MustBeValidEmailAddress': 'is not valid',
  'MustBeGreaterThan': 'must be greater than',
  'MustBeGreaterThanOrEqualTo': 'must be greater than or equal to',
  'MustBeLessThan': 'must be less than',
  'MustBeLessThanOrEqualTo': 'must be less than or equal to',
  'MustBeBetweenExclusive': 'must be between (exclusive)',
  'MustBeBetweenInclusive': 'must be between (inclusive)',
  'MustBeValidHttpUrl': 'is not valid',
  'MustNotExceedMaximumLength': 'exceeded maximum length',
  'MustMeetMinimumLength': 'is to short',
  'MustBeInAllowedValues': 'is not valid',
  'MustBeASupportedCurencyCode': 'is not supported',
  'SkuNotFound': 'is not valid',
  'MissingRequiredAssets': 'is missing required assets',
  'UnexpectedAssets': 'contains unsupported assets',
  'MissingRequiredAttributes': 'is missing required attributes',
  'UnexpectedAttributes': 'contains unsupported attributes',
  'MustBeATwoDigitISOCountryCode': 'is not valid',
  'MustBeValidOrderId': 'is not valid',
  'MustBeAValidUSZipCodeFormat': 'is not valid'
}

export function mapValidationErrorMessage (failures: Dictionary<ValidationFailure[]>): string {
  console.error(JSON.stringify(failures))

  const addressValidationFailures = filterValidationFailuresByFailureCodePart(failures, ADDRESS_FAILURE_CODE_PART)
  const recipientValidationFailures = filterRecipientValidationFailures(failures)
  const itemValidationFailures = filterValidationFailuresByFailureCodePart(failures, ITEM_FAILURE_CODE_PART)

  let fullErrorMessage = VALIDATION_ERROR_MESSAGE_PART_1
  fullErrorMessage = addMessagePart(fullErrorMessage, recipientValidationFailures, mapRecipientValidationErrorMessage)
  fullErrorMessage = addMessagePart(fullErrorMessage, addressValidationFailures, mapAddressValidationErrorMessage)
  fullErrorMessage = addMessagePart(fullErrorMessage, itemValidationFailures, mapItemValidationErrorMessage)

  if (fullErrorMessage.trim() === VALIDATION_ERROR_MESSAGE_PART_1 && Object.keys(failures).length > 0) {
    return fullErrorMessage.trim() + ' ' + JSON.stringify(failures)
  }

  return fullErrorMessage
}

function addMessagePart (
  fullErrorMessage: string,
  failures: Dictionary<ValidationFailure[]>,
  mappingFunc: (failures: Dictionary<ValidationFailure[]>) => string
): string {
  if (Object.keys(failures).length === 0) {
    return fullErrorMessage
  }

  return fullErrorMessage + ' ' + mappingFunc(failures)
}

function filterValidationFailuresByFailureCodePart (
  failures: Dictionary<ValidationFailure[]>,
  failureCodePart: string
): Dictionary<ValidationFailure[]> {
  return entries(failures).reduce((filteredFailures, [key, description]) => {
    if (!key.includes(failureCodePart)) {
      return filteredFailures
    }

    filteredFailures[key] = description

    return filteredFailures
  }, {})
}

function filterRecipientValidationFailures (
  failures: Dictionary<ValidationFailure[]>
): Dictionary<ValidationFailure[]> {
  return entries(failures).reduce((filteredFailures, [key, description]) => {
    if (key.includes(RECIPIENT_FAILURE_CODE_PART) && !key.includes(ADDRESS_FAILURE_CODE_PART)) {
      filteredFailures[key] = description
    }

    return filteredFailures
  }, {})
}

function mapAddressValidationErrorMessage (failures: Dictionary<ValidationFailure[]>): string {
  return buildAddressValidationErrorMessage(failures, ADDRESS_FAILURE_CODE_PART)
}

function mapRecipientValidationErrorMessage (failures: Dictionary<ValidationFailure[]>): string {
  return buildAddressValidationErrorMessage(failures, RECIPIENT_FAILURE_CODE_PART)
}

function mapItemValidationErrorMessage (failures: Dictionary<ValidationFailure[]>) {
  return entries(failures).reduce((fullMessage, [failureCode, descriptions]) => {
    const itemFailureMessagePart = descriptions.map(({ code, providedValue }) => {
      if (code === EXCEEDED_ALLOWED_QUANTITY_CODE) {
        return providedValue + ' is restricted to 1 per customer. Please contact customer service for more information.'
      }
    })

    return fullMessage + ' ' + itemFailureMessagePart.join(' ')
  }, '').trim()
}

function buildAddressValidationErrorMessage (
  failures: Dictionary<ValidationFailure[]>,
  failureCodePart: string
): string {
  return entries(failures).reduce((fullMessage, [failureCode, description]) => {
    const affectedProperty = failureCode.replace(failureCodePart, '').replace(/\./g, '')
    const failureDescriptionPart1 = ADDRESS_PROP_NAME_MAP[affectedProperty]
    const failureDescriptionPart2 = FAILURE_CODE_MAP[description?.[0]?.code]

    if (!failureDescriptionPart1 || !failureDescriptionPart2) {
      return fullMessage
    }

    const messagePart = failureDescriptionPart1 + ' ' + failureDescriptionPart2 + '.'

    return fullMessage + ' ' + messagePart
  }, '').trim()
}
