import {
  OrderHistoryDataType,
  ShipmentShippedInterface,
  ShipmentCancelledInterface,
  ShipmentCarrierAndServiceUpdatedInterface,
  OrderAdditionalChargeInterface,
  FulfillmentIssueInterface,
  ShipmentInformationProvidedInterface
} from '../interfaces'
import { OrderHistoryTypeEnum } from '../enums'
import { formatToSentenceCase, splitByPascalCase } from '../../../helpers'

export function formatOrderHistoryTitle(orderHistoryType: OrderHistoryTypeEnum, data: OrderHistoryDataType) {
  switch (orderHistoryType) {
    case OrderHistoryTypeEnum.ShippingMethodUpdated:
      return 'Shipping method changed'
    case OrderHistoryTypeEnum.SalesChannelOrderCancelled:
      return 'Order cancelled on sales channel but not on Prodigi'
    case OrderHistoryTypeEnum.OrderAdditionalCharge:
      const chargeData = data as OrderAdditionalChargeInterface
      return chargeData.type.toLowerCase() === 'charge' ? 'Extra charge' : 'Refund issued'
    case OrderHistoryTypeEnum.RecipientUpdated:
      return 'Shipping address changed'
    case OrderHistoryTypeEnum.PackingSlipUpdated:
      return 'Packing slip changed'
    case OrderHistoryTypeEnum.ShipmentShipped:
      const shipmentData = data as ShipmentShippedInterface
      return `Shipment ${shipmentData.shipmentId} shipped via ${shipmentData.carrierService}`
    case OrderHistoryTypeEnum.ShipmentInformationProvided:
      const shipmentInformation = data as ShipmentInformationProvidedInterface
      return `Shipment ${shipmentInformation.shipmentId} updated`
    case OrderHistoryTypeEnum.ShipmentCarrierAndServiceUpdated:
      const newShipment = data as ShipmentCarrierAndServiceUpdatedInterface
      return `Shipment ${newShipment.shipmentId} carrier service changed`
    case OrderHistoryTypeEnum.ShipmentCancelled:
      const cancelledData = data as ShipmentCancelledInterface
      return `Shipment ${cancelledData.shipmentId} cancelled`
    case OrderHistoryTypeEnum.OrderItemAssetDownloadFailed:
      return 'Order item asset failed to download'
    case OrderHistoryTypeEnum.OrderFulfillmentFailed:
      return 'Order submission failed'
    case OrderHistoryTypeEnum.PaymentReceived:
      return 'Payment received'
    case OrderHistoryTypeEnum.FulfillmentIssueCreated:
      const issueCreatedData = data as FulfillmentIssueInterface
      return `Issue raised: ${formatToSentenceCase(splitByPascalCase(issueCreatedData.issueType))}`
    case OrderHistoryTypeEnum.FulfillmentIssueUpdated:
      const issueUpdatedData = data as FulfillmentIssueInterface
      return `Issue updated: ${formatToSentenceCase(splitByPascalCase(issueUpdatedData.issueType))}`
    case OrderHistoryTypeEnum.FulfillmentIssueClosed:
      const issueClosedData = data as FulfillmentIssueInterface
      return `Issue closed: ${formatToSentenceCase(splitByPascalCase(issueClosedData.issueType))}`
    default:
      return defaultTitleFormatting(orderHistoryType as string)
  }
}

function defaultTitleFormatting(value: string) {
  const type = value.replace('Prodigi.', '')
  const splitByFullStop = type
    .split('.')
    .map((value) => value.replace(/([A-Z])/g, ' $1').trim())
    .join(' ')

  return splitByFullStop.charAt(0).toUpperCase() + splitByFullStop.slice(1).toLowerCase()
}
