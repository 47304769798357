// @flow
import type { Dimensions, CropRectangle, Point, CmOrInch } from '../../types'
import { convertBorderWidthToPrintPx } from './convertBorderWidthToPrintPx'

export function convertScaleBasedPositionToPrintPx (
  printDimensionsInPx: Dimensions,
  scaleBasedCropRectangleDimensions: CropRectangle,
  scaleBasedPositionRelativeToTemplateSize: Point,
  borderWidth: number,
  borderUnit: CmOrInch,
  printDpi: number
): Point {
  const { width: printWidthInPx, height: printHeightInPx } = printDimensionsInPx
  const {
    x: scaleBasedXRelativeToTemplateWidth,
    y: scaleBasedYRelativeToTemplateHeight
  } = scaleBasedPositionRelativeToTemplateSize

  const scaleBasedXRelativeToCropArea = scaleBasedXRelativeToTemplateWidth / scaleBasedCropRectangleDimensions.width
  const scaleBasedYRelativeToCropArea = scaleBasedYRelativeToTemplateHeight / scaleBasedCropRectangleDimensions.height

  const bottomRightCornerX = printWidthInPx * scaleBasedXRelativeToCropArea
  const bottomRightCornerY = printHeightInPx * scaleBasedYRelativeToCropArea

  const centerX = bottomRightCornerX - printWidthInPx / 2
  const centerY = bottomRightCornerY - printHeightInPx / 2

  const borderWidthInPrintPx = borderWidth > 0
    ? convertBorderWidthToPrintPx(printDpi, borderWidth, borderUnit)
    : 0

  return {
    x: centerX + borderWidthInPrintPx,
    y: centerY + borderWidthInPrintPx
  }
}
