// @flow
import type { AppState, DispatchFunc, Quote } from '../../../../types'
import { connect } from 'react-redux'
import { selectMofShippingMethod, toggleUsSalesTax } from '../../../../actions/manualOrderForm'
import ShippingAndTotalV4 from './ShippingAndTotalV4'
import { selectMofQuotes } from '../../../../selectors/quotes'
import { selectSelectedShippingMethodName, selectIsUsSalesTaxAlreadyCollected, selectDeliveryCountry } from '../../../../selectors/manualOrderForm'

type DispatchProps = {|
  changeShippingMethod: (method: string) => void,
  toggleUsSalesTax: () => void
|}

type StateProps = {|
  deliveryCountry: string,
  quotes: Quote[],
  selectedShippingMethodName: ?string,
  isUsSalesTaxAlreadyCollected: boolean
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    quotes: selectMofQuotes(state),
    selectedShippingMethodName: selectSelectedShippingMethodName(state),
    isUsSalesTaxAlreadyCollected: selectIsUsSalesTaxAlreadyCollected(state),
    deliveryCountry: selectDeliveryCountry(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    toggleUsSalesTax: () => {
      dispatch(toggleUsSalesTax())
    },
    changeShippingMethod: (method: string) => {
      dispatch(selectMofShippingMethod(method))
    }
  }
}

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingAndTotalV4): any)
