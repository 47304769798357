// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Clock ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM4.5 45C4.5 67.3675 22.6325 85.5 45 85.5C67.3675 85.5 85.5 67.3675 85.5 45C85.5 22.6325 67.3675 4.5 45 4.5C22.6325 4.5 4.5 22.6325 4.5 45Z" fill="black"/>
      <line x1="47.5" y1="18.5" x2="46.5" y2="47.5" stroke="#4352FF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35 36.5L47 48" stroke="#4352FF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
