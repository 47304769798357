// @flow

import * as React from 'react'
import FontAwesome from 'react-fontawesome'
import { getButtonStyles } from '../../helpers/button'
import styles from './Button.module.css'

// eslint-disable-next-line flowtype/require-exact-type
type Props = {
  primary?: boolean,
  destructive?: boolean,
  plain?: boolean,
  secondary?: boolean,
  outline?: boolean,
  rollover?: boolean,
  loading?: boolean,
  disabled?: boolean,
  icon?: string,
  alert?: boolean,
  type?: string,
  iconPosition?: 'left' | 'right',
  size?: 'small' | 'medium' | 'large',
  children?: React$Node,
  className?: string,
  svg?: boolean,
  dataTest?: string,
  onClick?: any
}

function Button({
  primary,
  destructive,
  plain,
  loading,
  disabled,
  secondary,
  outline,
  rollover,
  icon,
  iconPosition = 'left',
  size = 'medium',
  className,
  children,
  svg,
  dataTest,
  ...otherProps
}: Props): React$Node {
  const classNames = getButtonStyles(primary, secondary, outline, rollover, destructive, plain, className, size)

  const iconName = loading ? 'circle-o-notch fa-spin' : icon

  const dataProps = dataTest ? { 'data-test': dataTest } : null

  return (
    <button {...otherProps} {...dataProps} className={`${classNames}`} disabled={loading ? true : disabled}>
      {iconName && svg && <img className={styles.logoCompact} src={iconName} alt="" />}
      {iconName && iconPosition === 'left' && <FontAwesome name={iconName} />}
      {children && <span>{children}</span>}
      {iconName && iconPosition === 'right' && <FontAwesome name={iconName} />}
    </button>
  )
}

export default Button
