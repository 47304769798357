import { formatToSentenceCase } from '../../../helpers'

export function formatProductAttributeString(value: string) {
  const removeUnderscores = value.replace(/_/g, ' ').trim()

  if (removeUnderscores.includes('/')) {
    const splitValues = removeUnderscores.split(' / ')
    const upperCaseEachOption = splitValues.map((option) => formatToSentenceCase(option))
    return upperCaseEachOption.join(' / ')
  }

  if (checkIfValueContainsUppercasedWords(value)) {
    return formatValueWithUppercasedWords(value)
  }

  const addSpaces = removeUnderscores.replace(/([A-Z])/g, ' $1').trim()
  return formatToSentenceCase(addSpaces)
}

function checkIfValueContainsUppercasedWords(value: string) {
  const capitalisationRegex = new RegExp(/^[A-Z]+$/)
  const valueSplitBySpaces = value.split(' ')

  return valueSplitBySpaces.some((substring) => capitalisationRegex.test(substring))
}

function formatValueWithUppercasedWords(value: string) {
  const valueSplitBySpaces = value.split(' ')
  const firstWord = formatToSentenceCase(valueSplitBySpaces[0])
  valueSplitBySpaces.shift()

  const remainingWords = valueSplitBySpaces.map((word) => word.toLowerCase()).join(' ')

  return (firstWord + ' ' + remainingWords).trim()
}
