import { LeftPanel, RightPanel } from './components'

function ChangePassword() {
  return (
    <div className="mx-auto flex min-h-screen max-w-[1440px] text-lg">
      <LeftPanel />
      <RightPanel />
    </div>
  )
}

export default ChangePassword
