import { useEffect } from 'react'
import { motion } from 'framer-motion'

import ErrorMessage from '../ErrorMessage'
import { createToast } from '../Toast'

export default function ErrorScreen({
  errorMessage = null,
  toastMessage = null
}: {
  errorMessage: string | null
  toastMessage: string | null
}) {
  useEffect(() => {
    const message = toastMessage || 'An unknown error occured'
    createToast({ heading: message, type: 'error' })
  }, [toastMessage])
  return (
    <motion.div
      animate={{ scale: 1, opacity: 1 }}
      className="mx-auto flex flex-col items-center justify-center"
      initial={{ scale: 0.8, opacity: 0 }}
      transition={{ type: 'tween' }}
    >
      <ErrorMessage message={errorMessage || 'An unknown error occured'} />
    </motion.div>
  )
}
