// @flow
import type { UpdateCsvOrderItemQuantity } from '../types'
import type { DispatchFunc, Thunk } from '../../types'
import { fetchPricingAndShippingForCsvOrder } from './fetchPricingAndShippingForCsvOrder'
export const UPDATE_CSV_ORDER_ITEM_QUANTITY = 'UPDATE_CSV_ORDER_ITEM_QUANTITY'

export function updateOrderItemQuantity (quantity: number, orderId: string, itemId: string): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    dispatch(createQuantityChangeAction(quantity, itemId))
    dispatch(fetchPricingAndShippingForCsvOrder(orderId))
  }
}

function createQuantityChangeAction (quantity: number, itemId: string): UpdateCsvOrderItemQuantity {
  return {
    type: UPDATE_CSV_ORDER_ITEM_QUANTITY,
    quantity,
    itemId
  }
}
