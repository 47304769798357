// @flow
export const QUERY = 'query'
export const SKIP = 'skip'
export const LIMIT = 'limit'
export const SCOPE = 'scope'

export const ORDER_FILTERS = {
  QUERY,
  SKIP,
  LIMIT,
  SCOPE
}

export const HELD_ORDERS = 'HeldOrders'
export const ORDERS = 'Orders'
export const AWAITING_PAYMENT = 'AwaitingPayment'
export const SUBMITTED = 'Submitted'
export const COMPLETE = 'Complete'
export const CANCELLED = 'Cancelled'
export const NOT_YET_SUBMITTED = 'NotYetSubmitted'

export const ORDER_FILTER_SCOPES = {
  HELD_ORDERS,
  ORDERS,
  AWAITING_PAYMENT,
  SUBMITTED,
  COMPLETE,
  CANCELLED,
  NOT_YET_SUBMITTED
}
