import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface SupportLinkPropsInterface {
  children: ReactNode
  className?: string
}

export const SUPPORT_EMAIL = 'support@prodigi.com'

export default function SupportLink({ children, className }: SupportLinkPropsInterface) {
  return (
    <a href={`mailto:${SUPPORT_EMAIL}`} className={twMerge('text-purple-500', className)}>
      {children}
    </a>
  )
}
