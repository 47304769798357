// @flow
import { LOG_STATUS, RSAA_ACTION_NAME } from '../data/rsaa'
import { addRsaaAction } from '../actions/rsaa'
import type { AppState, Action } from '../types'
import type { MiddlewareAPI, Dispatch } from 'redux'
import { buildRsaaActionId } from '../helpers/rsaa'

export default function (store: MiddlewareAPI<AppState, Action>): ((next: any) => (action: Action) => Promise<any>) {
  return function (next: Dispatch<Action>) {
    return async function (action: Action) {
      if (!isRsaaActionToLog(action)) {
        return next(action)
      }

      const currentActionTypes = getActionTypes(action)
      const actionId = getRsaaActionId(action, currentActionTypes)
      const state = store.getState()
      const hasRsaaConfig = Object.keys(state.rsaa.config).includes(actionId)
      const loggerId = action[RSAA_ACTION_NAME].types[0].meta.loggerId

      if (!hasRsaaConfig) {
        store.dispatch(addRsaaAction(currentActionTypes, loggerId))
      }
      const actionWithLoggerId = assignLoggerIdToAllActionTypes(action, loggerId)

      return next(actionWithLoggerId)
    }
  }
}

function isRsaaActionToLog (action: Action): boolean {
  return Boolean(
    action &&
    action[RSAA_ACTION_NAME] &&
    action[RSAA_ACTION_NAME].types &&
    action[RSAA_ACTION_NAME].types[0].meta &&
    action[RSAA_ACTION_NAME].types[0].meta.enhancements &&
    action[RSAA_ACTION_NAME].types[0].meta.enhancements.includes(LOG_STATUS)
  )
}

function getActionTypes (action: Action): string[] {
  const allTypes = action[RSAA_ACTION_NAME].types

  const firstType = typeof allTypes[0] === 'object'
    ? allTypes[0].type
    : allTypes[0]

  const secondType = typeof allTypes[1] === 'object'
    ? allTypes[1].type
    : allTypes[1]

  const thirdType = typeof allTypes[2] === 'object'
    ? allTypes[2].type
    : allTypes[2]

  return [firstType, secondType, thirdType]
}

function assignLoggerIdToAllActionTypes (action: Action, loggerId: ?string): Action {
  if (!loggerId) {
    return action
  }

  return {
    ...action,
    [RSAA_ACTION_NAME]: {
      ...action[RSAA_ACTION_NAME],
      types: action[RSAA_ACTION_NAME].types.map(assignLoggerIdToActionType(loggerId))
    }
  }
}

function assignLoggerIdToActionType (loggerId: string) {
  return (rsaaActionType: Object | string): Object | string => {
    if (rsaaActionType.meta && rsaaActionType.meta.loggerId) {
      return rsaaActionType
    } else if (typeof rsaaActionType === 'object') {
      return {
        ...rsaaActionType,
        meta: {
          ...rsaaActionType.meta,
          loggerId
        }
      }
    } else {
      return {
        type: rsaaActionType,
        meta: {
          loggerId
        }
      }
    }
  }
}

function getRsaaActionId (action: Action, actionTypes: string[]): string {
  const loggerId = action[RSAA_ACTION_NAME].types[0].meta.loggerId

  return buildRsaaActionId(actionTypes[0], loggerId)
}
