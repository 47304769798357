import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import Button from '../../Button'
import WhitePanel from '../../WhitePanel'
import { PAYMENT_AUTH_ERROR_MESSAGE } from '../constants'

export function PaymentIntentError({
  orderDetailLink,
  retryPayment
}: {
  orderDetailLink: string
  retryPayment: () => void
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex h-full w-full flex-col items-center justify-center"
    >
      <WhitePanel className="max-w-5xl" heading="Payment failed">
        <div className="flex flex-col space-y-6">
          <p className="m-0">{PAYMENT_AUTH_ERROR_MESSAGE}</p>
          <a
            className="text-purple-500"
            href={'https://www.prodigi.com/about/#contact'}
            rel="noreferrer"
            target="_blank"
          >
            View our contact details
          </a>
        </div>

        <div className="mt-10 flex w-full flex-col gap-3 sm:flex-row">
          <Button variant="primary" onClick={retryPayment}>
            Try again now
          </Button>
          <Link className="btn btn-secondary" to={orderDetailLink}>
            I&apos;ll do it later
          </Link>
        </div>
      </WhitePanel>
    </motion.div>
  )
}
