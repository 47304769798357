// @flow
import type { Cost } from '../../types'

export function combineCosts (costs: Cost[]): number {
  if (costs.length === 0) {
    return 0
  }

  return costs.reduce(
    (totalCost, costToAdd) => totalCost + Number(costToAdd.amount), 0
  )
}
