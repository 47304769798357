import { useOs } from '@mantine/hooks'
import { motion } from 'framer-motion'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

import { HOTKEYS } from '../../../constants/hotkeys.const'
import InlineMenu, { InlineMenuOptionInterface } from '../../InlineMenu'
import { OnChangeFilterType, OrderFilterType } from '../types'

export function OrderFilterSelection({
  isSalesChannelsAvailable,
  onChangeFilter,
  selectedFilters
}: {
  isSalesChannelsAvailable: boolean
  onChangeFilter: OnChangeFilterType
  selectedFilters: OrderFilterType[]
}) {
  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const filterOptions: Array<InlineMenuOptionInterface> = [
    {
      content: <OrderFilterItem label="Status" />,
      value: 'status',
      searchLabel: 'status',
      hint: isAppleOs ? HOTKEYS.ORDERS.STATUS.DISPLAY.APPLE : HOTKEYS.ORDERS.STATUS.DISPLAY.DEFAULT
    },
    {
      content: <OrderFilterItem label="Date" />,
      value: 'date',
      searchLabel: 'date',
      hint: isAppleOs ? HOTKEYS.ORDERS.DATE.DISPLAY.APPLE : HOTKEYS.ORDERS.DATE.DISPLAY.DEFAULT
    }
  ]

  if (isSalesChannelsAvailable) {
    filterOptions.push({
      content: <OrderFilterItem label="Source" />,
      value: 'source',
      searchLabel: 'source',
      hint: isAppleOs ? HOTKEYS.ORDERS.SOURCE.DISPLAY.APPLE : HOTKEYS.ORDERS.SOURCE.DISPLAY.DEFAULT
    })
  }

  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      data-test="order-filters__filter-menu"
      exit={{ opacity: 0, x: -5 }}
      initial={{ opacity: 0, x: -5 }}
      key="filters"
      transition={{ type: 'tween', duration: 0.1 }}
    >
      <InlineMenu
        ariaLabel="Order Filters"
        focusOnMount
        multiple
        options={filterOptions}
        selected={selectedFilters as string[]}
        onChange={(value) => {
          onChangeFilter(value as OrderFilterType)
        }}
      />
    </motion.div>
  )
}

function OrderFilterItem({ label }: { label: string }) {
  return (
    <span className="flex w-full items-center justify-between">
      <span>{label}</span>
      <ChevronRightIcon className="mx-6 w-4 flex-shrink-0 text-gray-600" />
    </span>
  )
}
