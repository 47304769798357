/* eslint-disable no-use-before-define */
// @flow

export default class Option<T> {
  _value: T | null

  constructor (value: T | null) {
    this._value = value
  }

  static some (value: T): Option<T> {
    return new Option<T>(value)
  }

  static none (): Option<T> {
    return new Option<T>(null)
  }

  whenSome<T2> (transform: (value: T) => T2): Option<T2> {
    return this._value !== null
      ? Option.some(transform(this._value))
      : Option.none()
  }

  whenNone (supplyReturnValue: () => T): T {
    return this._value !== null
      ? this._value
      : supplyReturnValue()
  }

  whenSomeCall (sideEffect: (value: T) => void): Option<T> {
    if (this._value !== null) {
      sideEffect(this._value)
    }
    return this
  }

  whenNoneCall (sideEffect: () => void): Option<T> {
    if (this._value === null) {
      sideEffect()
    }
    return this
  }
}

export function returnValue<T> (value: T): T {
  return value
}
