import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface, OrderDetailDataErrorInterface, OrderDetailDataSuccessInterface } from '../interfaces'

export function useOrderDetail(orderId: string) {
  const {
    data: orderDetailsResponse,
    mutate: mutateOrderDetails,
    isValidating: isValidatingOrderDetails,
    error
  } = useSWR<OrderDetailDataSuccessInterface, FetchErrorInterface<OrderDetailDataErrorInterface>>(
    `${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}`,
    fetcher
  )

  return {
    isValidatingOrderDetails,
    orderDetailsResponse,
    error,
    mutateOrderDetails
  }
}
