import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import Button from '../../Button'
import { createToast } from '../../Toast'
import { fetcher } from '../../../helpers'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import OverlayPortal from '../../OverlayPortal'
import { ROUTE_PATHS } from '../../../constants'
import OrderStatusFlag from '../../OrderStatusFlag'
import dateFormat from '../../../helpers/dateFormat'
import timeFormat from '../../../helpers/timeFormat'
import Banner, { BannerTypeEnum } from '../../Banner'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { useLogToInsightOps } from '../../../hooks/useLogToInsightOps'
import { OrderCancellationButton } from '../../OrderDetailHeader/components'
import { useOrderActions, useOrderDetail, useOrdersOverview, useUser } from '../../../hooks'
import { getOrderSubmissionErrorMessage, OMSOrderSubmissionErrorResponseInterface } from '../helpers'

const ORDER_HEADER_TOASTS = {
  ERROR: 'prodigiOrderEditHeaderErrorToast'
}

export function OrderEditHeader() {
  const { id: orderId } = useParams<{ id: string }>()
  const { logToInsightOps } = useLogToInsightOps()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const { orderActions, orderActionsFetchError, mutateOrderActions } = useOrderActions(orderId)
  const { mutateOrdersOverview } = useOrdersOverview()
  const { user } = useUser()
  const orderDetails = orderDetailsResponse?.data.order

  const [orderSubmissionStatus, setOrderSubmissionStatus] = useState<StatusType>('idle')

  useEffect(() => {
    if (orderActionsFetchError && orderId) {
      logToInsightOps('Failed to fetch order actions', {
        props: {
          orderId,
          traceparent: orderActionsFetchError.responseBodyJson?.traceParent
        },
        level: 'error'
      })
    }
  }, [logToInsightOps, orderActionsFetchError, orderId])

  useEffect(() => {
    return () => {
      toast.dismiss(ORDER_HEADER_TOASTS.ERROR)
    }
  }, [])

  async function handleSubmitOrder() {
    toast.dismiss(ORDER_HEADER_TOASTS.ERROR)
    setOrderSubmissionStatus('loading')
    window.analytics.track('Order editing - clicked Submit order')
    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}`, { method: 'POST' })
      await mutateOrderDetails()
      await mutateOrderActions()
      mutateOrdersOverview()
      createToast({ heading: 'Order submitted successfully', type: 'success' })
      setOrderSubmissionStatus('success')
    } catch (error) {
      window.analytics.track('Order editing - order submit failed')
      const errorResponse = error as FetchErrorInterface<OMSOrderSubmissionErrorResponseInterface>
      const errorMessage = getOrderSubmissionErrorMessage(errorResponse)
      const errorCode = `${errorResponse.status ?? '0'}-${errorResponse.responseBodyJson?.traceParent ?? '0'}`
      createToast({
        content: (
          <>
            {errorMessage && <p className="mb-2">{errorMessage}</p>}
            <p>
              Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
            </p>
          </>
        ),
        duration: Infinity,
        footer: (
          <span className="text-sm text-gray-600">
            Code: {errorCode} <ClipboardCopy showText={false} text={errorCode} />
          </span>
        ),
        heading: 'An error occurred',
        id: ORDER_HEADER_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setOrderSubmissionStatus('error')
    }
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  if (!user) {
    throw Error('No user')
  }

  const userHasToAddBillingDetails = !user.billing.hasPaymentSetup && user.billing.invoiceFrequency === 'PerOrder'
  const cancellationIsAvailable = Boolean(orderActions?.data.cancel.isAvailable)

  return (
    <>
      {userHasToAddBillingDetails && (
        <Banner
          type={BannerTypeEnum.Error}
          buttonLink={ROUTE_PATHS.SETTINGS.BILLING}
          buttonText="Add billing details"
          message="You will need to set up billing details before you can submit this order."
        />
      )}

      <div className="isolate grid w-full grid-cols-1 items-start gap-4 md:grid-cols-3 xl:gap-8">
        <div className="col-span-2">
          <div className="flex flex-col sm:flex-row">
            <h1 data-test="order-detail-header__orderId" data-test-id={orderId}>
              <span className="hidden md:inline">Order </span>
              <ClipboardCopy text={orderId} />
            </h1>
          </div>

          <div className="mt-4 flex flex-col items-start md:mt-2 md:flex-row">
            <OrderStatusFlag statusText={orderDetails.status} />
            <span className="hidden px-3 md:inline">&middot;</span>
            <span className="mt-2 whitespace-nowrap md:mt-0" data-test="order-detail-header__recipient-name">
              {orderDetails.recipient.name}
            </span>
            <span className="hidden px-3 md:inline">&middot;</span>
            <span className="mt-2 md:mt-0" data-test="order-detail-header__created-date">
              <span className="whitespace-nowrap">{dateFormat(orderDetails.createdDate)}</span> at{' '}
              <span className="whitespace-nowrap">{timeFormat(orderDetails.createdDate)}</span>
            </span>
          </div>
        </div>

        <div className="relative flex w-full gap-6 md:justify-end">
          <OrderCancellationButton
            cancellationIsAvailable={Boolean(cancellationIsAvailable)}
            orderId={orderId}
            status={orderDetails.status}
            mutateOrderDetails={mutateOrderDetails}
          />

          <Button
            dataTest="submit-draft-order-button"
            disabled={!orderDetails.isReadyForFulfilment || userHasToAddBillingDetails}
            isLoading={orderSubmissionStatus === 'loading'}
            onClick={handleSubmitOrder}
          >
            Submit
          </Button>
        </div>

        {orderSubmissionStatus === 'loading' && <OverlayPortal />}
      </div>
    </>
  )
}
