import { fetcher } from '../../../helpers'
import { ImageLibraryImageInterface } from '../../../hooks'

export function addTagsToImageInLibrary({
  imageLibraryImage,
  tags
}: {
  imageLibraryImage: ImageLibraryImageInterface
  tags: string[]
}) {
  const newListOfTags = [...imageLibraryImage.tags]

  tags.forEach((tag) => {
    const tagAlreadyExists = newListOfTags?.map((a) => a.toLowerCase()).includes(tag.toLowerCase())

    if (!tagAlreadyExists) {
      newListOfTags.push(tag)
    }
  })

  return fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryImage.public_id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      tags: newListOfTags
    })
  })
}
