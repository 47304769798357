import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import { useUser } from 'src/v2/hooks'
import { StatusEnum } from 'src/v2/enums'
import { updateUserSuccess } from 'src/actions/user'
import { createToast } from 'src/v2/components/Toast'
import { FetchErrorInterface } from 'src/v2/interfaces'
import OverlayPortal from 'src/v2/components/OverlayPortal'
import ConfirmationModal from 'src/v2/components/ConfirmationModal'
import { resetUserApiKeys } from 'src/v2/components/Settings/helpers'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from 'src/v2/components/Settings/constants'

export default function ConfirmRegenerateApiKeyModal({
  open,
  setOpen
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) {
  const dispatch = useDispatch()
  const { mutateUser } = useUser()

  const [regenerateApiKeysStatus, setRegenerateApiKeysStatus] = useState<StatusEnum>(StatusEnum.Idle)

  async function onConfirmResetApiKeys() {
    setRegenerateApiKeysStatus(StatusEnum.Loading)
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)

    try {
      await resetUserApiKeys()
      await mutateUser()

      setRegenerateApiKeysStatus(StatusEnum.Success)
      createToast({ heading: 'API keys reset successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      handleOpenChange(false)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later (${errorResponse.status ?? errorResponse.message})`,
        duration: Infinity,
        heading: 'Failed to reset API keys',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setRegenerateApiKeysStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setRegenerateApiKeysStatus(StatusEnum.Idle)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
    setOpen(isOpen)
  }

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        continueButton={{ text: 'Regenerate', variant: 'primary', theme: 'danger' }}
        isLoading={regenerateApiKeysStatus === StatusEnum.Loading}
        open={open}
        setOpen={handleOpenChange}
        title="Regenerate API keys"
        onCancel={() => handleOpenChange(false)}
        onContinue={onConfirmResetApiKeys}
      >
        <div>
          <p>Your existing API credentials will be invalidated immediately.</p>
          <p className="mt-4 font-medium">Are you sure you want to regenerate your API key?</p>
        </div>
      </ConfirmationModal>

      {regenerateApiKeysStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
