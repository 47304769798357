// @flow
import type { ThunkAsync, DispatchFunc, GetStateFunc, Dictionary, FormattedCsvRow, CatalogueItem, FormattedOrderItem } from '../../types'
import type {
  FindProductDetails,
  FindProductDetailsSuccess,
  FindProductDetailsError,
  AddProductDescriptions,
  UpdateShippingMethod,
  UpdateCustomsValue,
  UpdateMerchantOrderId
} from '../types'
import { findProduct, mapCatalogueItems } from '../catalogue'
import { createListOfCsvSkus } from '../../helpers/csvUpload'
import { sleep } from '../../helpers/promise'
import { fetchPricingAndShippingForCsvOrder } from './fetchPricingAndShippingForCsvOrder'

export const FIND_PRODUCT_DETAILS = 'FIND_PRODUCT_DETAILS'
export const FIND_PRODUCT_DETAILS_SUCCESS = 'FIND_PRODUCT_DETAILS_SUCCESS'
export const FIND_PRODUCT_DETAILS_ERROR = 'FIND_PRODUCT_DETAILS_ERROR'
export const UPDATE_SHIPPING_METHOD = 'UPDATE_SHIPPING_METHOD'
export const UPDATE_MERCHANT_ORDER_ID = 'UPDATE_MERCHANT_ORDER_ID'
export const ADD_PRODUCT_DESCRIPTIONS = 'ADD_PRODUCT_DESCRIPTIONS'
export const UPDATE_CUSTOMS_VALUE = 'UPDATE_CUSTOMS_VALUE'

export function addProductDescriptions (descriptions: Dictionary<string>): AddProductDescriptions {
  return {
    type: ADD_PRODUCT_DESCRIPTIONS,
    descriptions
  }
}

export function updateShippingMethod (index: string, shippingMethod: string): UpdateShippingMethod {
  return {
    type: UPDATE_SHIPPING_METHOD,
    shippingMethod,
    index
  }
}

export function updateCustomsValue (index: string, customsValue: number): UpdateCustomsValue {
  return {
    type: UPDATE_CUSTOMS_VALUE,
    index,
    customsValue
  }
}

export function updateMerchantOrderId (index: string, orderId: string): UpdateMerchantOrderId {
  return {
    type: UPDATE_MERCHANT_ORDER_ID,
    orderId,
    index
  }
}

export function getOrderItemDetails (orders: Dictionary<FormattedCsvRow>, allOrderItems: Dictionary<FormattedOrderItem>): ThunkAsync<void> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const orderIds = Object.keys(orders)

    return new Promise(async (resolve, reject) => {
      try {
        for (const orderId of orderIds) {
          dispatch(fetchPricingAndShippingForCsvOrder(orderId))
          await sleep(500)
        }

        const skus = createListOfCsvSkus(allOrderItems)

        for (const sku of skus) {
          await dispatch(fetchProductDetails(sku))
          await sleep(50)
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
}

export function fetchProductDetails (sku: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch(findProductDetails(sku))
    const productDetails = await dispatch(findProduct(sku))

    if (productDetails && productDetails.product) {
      const [mappedProduct] = mapCatalogueItems([productDetails.product])
      dispatch(findProductDetailsSuccess(mappedProduct))
    } else {
      dispatch(findProductDetailsError(sku))
    }
  }
}

export function findProductDetails (sku: string): FindProductDetails {
  return {
    type: FIND_PRODUCT_DETAILS,
    sku
  }
}

export function findProductDetailsSuccess (product: CatalogueItem): FindProductDetailsSuccess {
  return {
    type: FIND_PRODUCT_DETAILS_SUCCESS,
    product
  }
}

export function findProductDetailsError (sku: string): FindProductDetailsError {
  return {
    type: FIND_PRODUCT_DETAILS_ERROR,
    sku
  }
}
