// @flow
import type { CropRectangle } from '../../types'
import { TEMPLATE_SIZE_IN_PX } from '../../data/constants'

export function getCropRectangleRelativeToTemplateSize (
  cropRectangle: CropRectangle
): CropRectangle {
  return {
    x: cropRectangle.x / TEMPLATE_SIZE_IN_PX,
    y: cropRectangle.y / TEMPLATE_SIZE_IN_PX,
    width: cropRectangle.width / TEMPLATE_SIZE_IN_PX,
    height: cropRectangle.height / TEMPLATE_SIZE_IN_PX
  }
}
