// @flow
import {
  calcFillScaleFactor,
  calcPreviewBorder,
  calcPrintDimensionsFillScaleFactor
} from '@prodigi-group/components-image-editor'

import type { Dimensions, CropRectangle } from '../../types'

// In the new image editor adding a border affects the scale.
// This function calculates a value required to revert that change.
export function calcScaleFactorAdjustment(
  printDimensionsInPx: Dimensions,
  artworkDimensionsInPx: Dimensions,
  cropRectangle: CropRectangle,
  printDpi: number,
  borderWidth: number,
  borderUnit: string
): number {
  const previewBorder = calcPreviewBorder(printDpi, borderUnit, borderWidth, printDimensionsInPx, cropRectangle)
  const printDimensionsWithBorder = calcPrintDimensionsFillScaleFactor(
    printDimensionsInPx,
    artworkDimensionsInPx,
    previewBorder,
    0
  )
  const printDimensionsWithoutBorder = calcPrintDimensionsFillScaleFactor(
    printDimensionsInPx,
    artworkDimensionsInPx,
    0,
    0
  )
  const printDimensionsDifference = printDimensionsWithoutBorder / printDimensionsWithBorder

  const fillScaleFactorWithBorder = calcFillScaleFactor(artworkDimensionsInPx, cropRectangle, previewBorder)
  const fillScaleFactorWithoutBorder = calcFillScaleFactor(artworkDimensionsInPx, cropRectangle, 0)
  const fillScaleFactorDifference = fillScaleFactorWithoutBorder / fillScaleFactorWithBorder

  return fillScaleFactorDifference / printDimensionsDifference
}
