import { useEffect } from 'react'
import toast from 'react-hot-toast'

import { createErrorToast, createToast } from '../../Toast'
import ImageLibraryModal from '../../ImageLibraryModal'
import { shouldFitImageInTemplate } from '../../../helpers'
import { ArtworkTransformationsInterface } from '../interfaces'
import { V1DashboardArtworkInterface } from '../../../interfaces'
import { ImageSizingType, getDefaultTransformations } from '../helpers'
import { IMAGE_EDITOR_ERROR_TOAST_ID } from './MultiAssetImageEditor.component'
import { ImageLibraryConfigPropInterface } from '../../ImageLibrary/ImageLibrary.component'
import { ImageLibraryImageInterface, TemplateServiceSuccessResponseInterface } from '../../../hooks'
import { generateArtworkDataForImageLibraryImage } from '../helpers/generateArtworkDataForImageLibraryImage.helper'

interface EditorImageLibraryModalPropsInterface {
  open: boolean
  imageLibraryConfig?: ImageLibraryConfigPropInterface
  itemCategory: string
  productType: string
  printAreaName: string
  templates: TemplateServiceSuccessResponseInterface
  setOpen: (isOpen: boolean) => void
  onSelectImageLibraryImage: (artworkData: {
    artwork: V1DashboardArtworkInterface
    artworkTransformations: ArtworkTransformationsInterface
    selectedPrintArea: string
  }) => void
  onTogglePreviewMode: (isPreviewOnly: boolean) => void
}

export function EditorImageLibraryModal({
  open,
  imageLibraryConfig,
  itemCategory,
  productType,
  printAreaName,
  templates,
  setOpen,
  onSelectImageLibraryImage,
  onTogglePreviewMode
}: EditorImageLibraryModalPropsInterface) {
  useEffect(() => {
    if (open) {
      onTogglePreviewMode(true)
    } else {
      onTogglePreviewMode(false)
    }
  }, [onTogglePreviewMode, open])

  function handleSelectImage(imageLibraryImage: ImageLibraryImageInterface) {
    toast.dismiss(IMAGE_EDITOR_ERROR_TOAST_ID)

    const { artwork, error } = generateArtworkDataForImageLibraryImage({ imageLibraryImage, productType })

    if (!artwork) {
      setOpen(false)
      createErrorToast({
        heading: 'An error occurred while selecting file',
        errorMessage: error?.message,
        id: IMAGE_EDITOR_ERROR_TOAST_ID
      })
      return
    }

    const imageSizing: ImageSizingType = shouldFitImageInTemplate(itemCategory) ? 'fitPrintArea' : 'fillPrintArea'
    const artworkTransformations = getDefaultTransformations(artwork, templates, imageSizing, printAreaName)

    if (!artworkTransformations) {
      setOpen(false)
      createToast({
        content: `Please try re-uploading or contact support if the issue persists`,
        duration: Infinity,
        footer: 'Template not found',
        heading: 'An error occurred while selecting image',
        id: IMAGE_EDITOR_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      return
    }

    setOpen(false)
    onSelectImageLibraryImage({ artwork, artworkTransformations, selectedPrintArea: printAreaName })
  }

  return (
    <ImageLibraryModal
      config={imageLibraryConfig}
      open={open}
      type="artwork"
      setOpen={setOpen}
      onSelectImage={handleSelectImage}
    />
  )
}
