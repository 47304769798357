import { useEffect } from 'react'

import { SettingsBilling } from '../../../components/Settings'
import { ANALYTICS_PAGE_CATEGORIES, DEFAULT_DOCUMENT_TITLE } from '../../../constants'

export default function SettingsBillingPage() {
  useEffect(() => {
    document.title = 'Billing Settings | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.SETTINGS, 'Billing')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <SettingsBilling />
    </main>
  )
}
