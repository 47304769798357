// @flow
import React, { PureComponent } from 'react'
import styles from './CsvActionMenu.module.css'
import WhitePanel from '../../../../components/WhitePanel'
import CsvActionButton from './CsvActionButton'
import Button from '../../../../components/Button'
import cx from 'classnames'

type Props = {|
  orderItemIds: string[],
  isIgnored: boolean,
  isPanelDisplayed: boolean,
  onClick: (SyntheticMouseEvent<*>) => void,
  updateOrderIgnore: () => void,
  copyProductDetails: (orderItemId: string) => void,
  copyImageDetails: (orderItemId: string) => void,
  copyShippingMethod: (orderItemId: string) => void
|}

export default class CsvActionMenuView extends PureComponent <Props> {
  render (): React$Node {
    return (
      <div className={styles.container}>
        <button
          data-test='order-actions-button'
          className={cx(styles.actionContainer, { [styles.panelActiveContainer]: this.props.isPanelDisplayed })}
          onClick={this.props.onClick}>
          <p className={styles.dots}>...</p>
        </button>

        <WhitePanel dataTest='csv-action-menu' className={cx(styles.panel, { [styles.panelActive]: this.props.isPanelDisplayed })}>
          <Button
            dataTest='ignore-order-toggle'
            primary
            className={styles.button}
            onClick={this.props.updateOrderIgnore} >
            {this.props.isIgnored ? 'Include this order' : 'Ignore this order'}
          </Button>

          <div className={styles.divider} />

          <CsvActionButton
            dataTest='copy-product-details-button'
            orderItemIds={this.props.orderItemIds}
            onClick={this.props.copyProductDetails}
            text={'Copy product details'} />

          <CsvActionButton
            dataTest='copy-image-details-button'
            orderItemIds={this.props.orderItemIds}
            onClick={this.props.copyImageDetails}
            text={'Copy image details'} />

          <CsvActionButton
            dataTest='copy-shipping-method-button'
            onClick={this.props.copyShippingMethod}
            text={'Copy shipping method'} />

        </WhitePanel>
      </div>
    )
  }
}
