import { V3CountryInterface } from '../../../interfaces'

const DEFAULT_COUNTRIES: V3CountryInterface[] = [
  {
    name: 'United Kingdom',
    isoCode: 'GB'
  },
  {
    name: 'United States',
    isoCode: 'US'
  },
  {
    name: 'Australia',
    isoCode: 'AU'
  }
]
const DEFAULT_COUNTRY_CODES = ['GB', 'US', 'AU']
const CANADA = { name: 'Canada', isoCode: 'CA' }

export function buildDefaultCountries({
  countries,
  selectedCountryCode
}: {
  countries: V3CountryInterface[] | undefined
  selectedCountryCode?: string
}) {
  if (!countries || countries.length < 1 || !selectedCountryCode) {
    return [...DEFAULT_COUNTRIES, CANADA]
  }

  const currentCountry = countries.find((country) => country.isoCode === selectedCountryCode)
  const isDefaultCountry = DEFAULT_COUNTRIES.some((country) => country.isoCode === selectedCountryCode)

  if (!currentCountry || isDefaultCountry) {
    const otherCountries = countries.filter(
      (country) => country.isoCode !== CANADA.isoCode && !DEFAULT_COUNTRY_CODES.includes(country.isoCode)
    )

    return [...DEFAULT_COUNTRIES, CANADA, ...otherCountries]
  }

  const otherCountries = countries.filter(
    (country) => country.isoCode !== currentCountry.isoCode && !DEFAULT_COUNTRY_CODES.includes(country.isoCode)
  )
  return [currentCountry, ...DEFAULT_COUNTRIES, ...otherCountries]
}
