// @flow

import { resetRsaaStatus } from '../rsaa'
import { SUBMIT_CSV_ORDERS } from './orderSubmissionActions'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { UPLOAD_CSV } from './uploadCsv'

export const RESET_CSV_UPLOAD_STATE = 'RESET_CSV_UPLOAD_STATE'

export function resetCsvUploadState (): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({
      type: RESET_CSV_UPLOAD_STATE
    })

    dispatch(resetRsaaStatus(UPLOAD_CSV))
    dispatch(resetRsaaStatus(SUBMIT_CSV_ORDERS))
  }
}
