// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'
import { INIT_ARTWORK_TRANSFORMATIONS_ERROR, DOWNLOAD_IMAGE_ERROR } from '../../../actions/images'

export function images (action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case INIT_ARTWORK_TRANSFORMATIONS_ERROR: {
      return ['Init artwork transformations error', {
        artwork: JSON.stringify(action.artwork),
        templates: JSON.stringify(action.templates)
      }, 'error']
    }

    case DOWNLOAD_IMAGE_ERROR: {
      return ['Download image error', { assetId: action.id }, 'error']
    }

    default: {
      return null
    }
  }
}
