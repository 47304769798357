import { useEffect } from 'react'
import Login from '../../components/Login'
import { DEFAULT_DOCUMENT_TITLE } from 'src/v2/constants'

export default function LoginPage() {
  useEffect(() => {
    document.title = 'Login | Prodigi'
    window.analytics.page('Account', 'Login')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <Login />
    </main>
  )
}
