import { motion } from 'framer-motion'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { clearProductSearch } from '../../../actions/catalogue'

import { CATALOGUE_PRODUCTS, QUERY_PARAMS, ROUTE_PATHS } from '../../constants'
import { CatalogueProductDetailsInterface } from '../../interfaces/catalogueProducts.interface'
import Breadcrumbs from '../Breadcrumbs'

const BREADCRUMBS = [
  {
    href: ROUTE_PATHS.PRODUCT_INFO,
    title: 'Product info'
  },
  {
    title: 'Product catalogue'
  }
]

export default function ProductCatalogue() {
  return (
    <div className="container mx-auto py-6 lg:py-8">
      <Breadcrumbs className="mb-8 gap-3" pages={BREADCRUMBS} />

      <h1 className="mt-0 mr-auto mb-6 lg:mb-8">Product catalogue</h1>

      <ul className="mt-10 grid list-none grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
        {Object.entries(CATALOGUE_PRODUCTS).map(([category, details]) => (
          <ProductCategory key={category} category={category} details={details} />
        ))}
      </ul>
    </div>
  )
}

function ProductCategory({
  category,
  details
}: {
  category: string
  details: CatalogueProductDetailsInterface | undefined
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <motion.li key={category} whileHover="hover">
      <button
        className="flex w-full cursor-pointer flex-col gap-4"
        onClick={() => {
          window.analytics.track('Clicked Product Catalogue Tile', {
            productCategory: category
          })
          const categoryHasProductTypes = details?.productTypes && Object.keys(details.productTypes).length > 0
          if (categoryHasProductTypes) {
            history.push(`${ROUTE_PATHS.PRODUCTS}/${encodeURIComponent(category)}`)
          } else {
            dispatch(clearProductSearch())
            history.push(
              `${ROUTE_PATHS.MOF.SEARCH}?${QUERY_PARAMS.MOF.SEARCH.CATEGORY}=${encodeURIComponent(category)}`
            )
          }
        }}
      >
        <div className="aspect-square w-full overflow-hidden rounded border border-gray-200">
          <motion.img
            alt={category}
            className="w-full object-contain object-center"
            src={details?.image}
            variants={{
              hover: {
                scale: 1.05
              }
            }}
          />
        </div>

        <span className="text-left text-black">{category}</span>
      </button>
    </motion.li>
  )
}
