import { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearProductSearch,
  getItemCategories,
  resetProductSearchResults,
  selectItemCategory,
  selectProductType
} from '../../../../actions/catalogue'
import Skeleton from '../../Skeleton'
import SelectField from '../../SelectField'
import { useCountries } from '../../../hooks'
import { ALL, NO_SELECTION } from '../../../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { resetRsaaStatus } from '../../../../actions/rsaa'
import { buildRsaaActionId } from '../../../../helpers/rsaa'
import { CREATE_V4_ORDER } from '../../../../actions/orders'
import { setupRecentItems } from '../../../../actions/recentItems'
import { getHasOrderInProgress, selectDeliveryCountry } from '../../../../selectors/manualOrderForm'
import { selectSelectedItemCategory, selectSelectedProductType } from '../../../../selectors/catalogue'
import {
  MOF_ORDER_LOGGER_ID,
  changeDeliveryCountry,
  clearOrderData,
  removeUnfinishedOrderFromLocalStorage
} from '../../../../actions/manualOrderForm'

export function CountrySelection() {
  const dispatch = useDispatch()
  const selectedItemCategory = useSelector(selectSelectedItemCategory)
  const selectedProductType = useSelector(selectSelectedProductType)
  const hasOrderInProgress = useSelector(getHasOrderInProgress)
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)
  const {
    countries,
    isLoading: isLoadingCountries,
    error: countriesFetchError
  } = useCountries({ revalidateOnFocus: false })

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [shippingCountryCode, setShippingCountryCode] = useState<string | undefined>()

  function onResetSearch(countryCode: string) {
    removeUnfinishedOrderFromLocalStorage()
    dispatch(clearOrderData())
    const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
    dispatch(resetRsaaStatus(createOrderActionId))
    dispatch(resetProductSearchResults())
    dispatch(selectItemCategory(NO_SELECTION))
    dispatch(changeDeliveryCountry(countryCode))
    dispatch(getItemCategories())
    dispatch(clearProductSearch())

    if (selectedItemCategory !== NO_SELECTION) {
      dispatch(selectItemCategory(selectedItemCategory))
    }
    if (selectedItemCategory !== NO_SELECTION && selectedProductType !== ALL) {
      dispatch(selectProductType(selectedProductType))
    }
    dispatch(setupRecentItems())
  }

  function onChangeDeliveryCountry(event: ChangeEvent<HTMLSelectElement>) {
    const countryCode = event.target.value
    if (selectedDeliveryCountry === countryCode) {
      return
    }
    setShippingCountryCode(countryCode)

    if (hasOrderInProgress) {
      setShowConfirmationModal(true)
      return
    }

    onResetSearch(countryCode)
  }

  if (countriesFetchError) {
    return (
      <div className="flex min-h-[40px] items-center">
        Unable to load country selector (code: {countriesFetchError.status ?? '0'})
      </div>
    )
  }

  if (isLoadingCountries || !countries) {
    return (
      <div className="flex min-h-[40px] items-center">
        <label htmlFor="countrySelection" className="mr-6 whitespace-nowrap">
          Shipping to
        </label>
        <Skeleton className="h-8 w-48 lg:w-60" />
      </div>
    )
  }

  return (
    <>
      <label htmlFor="countrySelection" className="mr-6 whitespace-nowrap">
        Shipping to
      </label>
      <SelectField
        id="countrySelection"
        onChange={onChangeDeliveryCountry}
        value={selectedDeliveryCountry}
        className="sm:mt-0 sm:w-auto"
      >
        {countries.map((country) => (
          <option key={country.isoCode} value={country.isoCode}>
            {country.name}
          </option>
        ))}
      </SelectField>

      <CountryChangeConfirmationModal
        open={showConfirmationModal}
        countryName={countries.find((country) => country.isoCode === shippingCountryCode)?.name}
        setOpen={setShowConfirmationModal}
        onCancel={() => {
          setShowConfirmationModal(false)
        }}
        onContinue={() => {
          if (shippingCountryCode) {
            onResetSearch(shippingCountryCode)
          }
          setShowConfirmationModal(false)
        }}
      />
    </>
  )
}

function CountryChangeConfirmationModal({
  open,
  countryName,
  setOpen,
  onContinue,
  onCancel
}: {
  open: boolean
  countryName: string | undefined
  setOpen: (isOpen: boolean) => void
  onContinue: () => void
  onCancel: () => void
}) {
  const title = countryName ? `Change country to ${countryName}` : 'Change country'

  return (
    <ConfirmationModal
      open={open}
      continueButton={{ text: 'Yes' }}
      title={title}
      closeButton={{ text: 'No' }}
      setOpen={setOpen}
      onContinue={onContinue}
      onCancel={onCancel}
    >
      <p>Changing the shipping country will empty your basket. Do you want to continue?</p>
    </ConfirmationModal>
  )
}
