import { fetcher } from '../../../helpers'
import { PaymentMethod } from '@stripe/stripe-js'

export function savePaymentMethod(paymentMethod: string | PaymentMethod | null) {
  let paymentMethodId = null
  if (typeof paymentMethod === 'string') {
    paymentMethodId = paymentMethod
  } else {
    paymentMethodId = paymentMethod?.id
  }

  return fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/updatepaymentdetails`, {
    body: JSON.stringify({
      PaymentMethodId: paymentMethodId
    }),
    method: 'POST'
  })
}
