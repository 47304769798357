// @flow

import type { AppState, Dictionary, Facet, DispatchFunc, Event } from '../../../../types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeFacetSelection } from '../../../../actions/catalogue'
import { selectMobileFacetScreen } from '../../../../actions/manualOrderForm'
import { values } from '../../../../helpers/dictionary'
import Radium from 'radium'

import Button from '../../../../components/Button'
import mediaQueries from '../../../../components/styles/mediaQueries'
import FacetPanel from './FacetPanel'
import FacetButton from './FacetButton'
import type { ComponentType } from 'react'

type Props = {|
  facets: Dictionary<Facet>,
  selectedMobileFacetScreen: string,
  selectMobileFacetScreen: (?string) => void,
  changeFacetSelection: (string, string) => void
|}

class FacetNavigation extends Component<Props> {
  getStyles() {
    return {
      container: {
        width: '100%'
      },
      panel: {
        backgroundColor: 'var(--color-white)',
        padding: '0.8em',
        margin: '0.5em 0 1em 0'
      },
      facetTitle: {
        textTransform: 'uppercase'
      },
      facetValue: {
        textTransform: 'capitalize'
      },
      mobileViewButton: {
        width: '100%',
        display: 'none',
        [mediaQueries.breakpointMedium]: {
          display: 'inline-block'
        }
      },
      facetPanelContainer: {
        width: '100%',
        [mediaQueries.mobileMaxWidth]: {
          display: 'none'
        }
      },
      mobileFacetButtonContainer: {
        display: 'none'
      }
    }
  }

  onViewClicked = (e: Event) => this.props.selectMobileFacetScreen(null)

  onFacetSelectionChanged = (e) => {
    const facetNameAndValue = e.target.value.split('|')
    const facetName = facetNameAndValue[0]
    const facetValue = facetNameAndValue[1]
    this.props.changeFacetSelection(facetName, facetValue)
  }

  render(): React$Node {
    const styles = this.getStyles()
    const { facets, selectedMobileFacetScreen } = this.props
    const facetPanels = []

    if (!facets) {
      return null
    }

    const containsProductType = facets['productType'] !== undefined

    if (containsProductType && (!selectedMobileFacetScreen || selectedMobileFacetScreen === 'productType')) {
      facetPanels.push({
        facet: 'productType',
        values: facets['productType'].values,
        expanded: facets['productType'].expanded
      })
    }

    for (let facet of Object.keys(facets)) {
      if ((facet === selectedMobileFacetScreen || !selectedMobileFacetScreen) && facet !== 'productType') {
        facetPanels.push({
          facet,
          values: facets[facet].values,
          expanded: facets[facet].expanded
        })
      }
    }

    return (
      <div data-test="search-filters" style={styles.container}>
        {!selectedMobileFacetScreen && (
          <div style={styles.mobileFacetButtonContainer}>
            {values(facets).map((facet) => (
              <FacetButton key={facet.facet} facet={facet} onClickFacet={this.props.selectMobileFacetScreen} />
            ))}
          </div>
        )}

        <div style={styles.facetPanelContainer}>
          {!selectedMobileFacetScreen &&
            facetPanels &&
            facetPanels.map(
              (f) =>
                f.values &&
                f.values.length > 1 && (
                  <FacetPanel dataTest={`facet-panel-${f.facet.toLowerCase()}`} key={`panel_${f.facet}`} facet={f} />
                )
            )}
        </div>
        <div>
          {selectedMobileFacetScreen &&
            facetPanels &&
            facetPanels.map(
              (f) => f.values && f.values.length > 1 && <FacetPanel key={`mobile_${f.facet}`} facet={f} />
            )}
        </div>

        {selectedMobileFacetScreen && (
          <Button style={styles.mobileViewButton} onClick={this.onViewClicked}>
            Close
          </Button>
        )}
      </div>
    )
  }
}

function mapStateToProps(state: AppState) {
  return {
    facets: state.catalogue.facets,
    selectedMobileFacetScreen: state.manualOrderForm.selectedMobileFacetScreen
  }
}

function mapDispatchToProps(dispatch: DispatchFunc) {
  return {
    changeFacetSelection: (facetName: string, facetValue: string) => {
      dispatch(changeFacetSelection(facetName, facetValue))
    },
    selectMobileFacetScreen: (facet: any) => {
      dispatch(selectMobileFacetScreen(facet))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Radium(FacetNavigation)): ComponentType<{||}>)
