// @flow
import React, { Component } from 'react'
import Radium from 'radium'
import { apparelColours } from '../../../data/apparelColours'
import { frameColours } from '../../../data/frameColours'
import type { Style } from '../../../types'
import FontAwesome from 'react-fontawesome'
import { ALL } from '../../../data/catalogue'

type Props = {|
  colour: string,
  style: Style,
  className?: string,
  checked?: boolean,
  value?: string,
  selectedItemCategory: string,
  frame?: string[]
|}

class ColourSwatch extends Component<Props> {
  getStyles () {
    return {
      box: {
        border: '1px solid var(--color-grey-50)'
      },
      check: {
        display: 'block'
      },
      hexColourSwatch: {
        border: '1px solid var(--color-grey-50)',
        width: 'calc(4rem - 2px)',
        height: 'calc(4rem - 2px)'
      }
    }
  }

  getCheckIconColour (colour: string) {
    const blackCheckColours = [
      'arctic_white',
      'ash',
      'butter',
      'cornsilk',
      'daisy',
      'natural',
      'white',
      'white_and_asphalt',
      'snow white',
      'off-white',
      'silver'
    ]
    let checkColour = {
      color: 'var(--color-white)'
    }

    if (blackCheckColours.includes(colour)) {
      checkColour.color = 'var(--color-black)'
    }

    return checkColour
  }

  addFrameTypePrefix = (colour: string) => {
    const { frame } = this.props
    if (frame && frame.length === 1) {
      const frameType = frame[0]

      if (frameType.indexOf('classic') > -1) {
        return 'classic_' + colour
      } else if (frameType.indexOf('surface') > -1) {
        return 'surface_' + colour
      } else {
        return frameType + '_' + colour
      }
    }

    return colour
  }

  getFolderName = (isApparelColour: boolean, isFrameColourWithPng: boolean) => {
    const { selectedItemCategory } = this.props

    if (selectedItemCategory === 'Apparel') {
      return 'apparel'
    } else if (selectedItemCategory === ALL) {
      if (isFrameColourWithPng && !isApparelColour) {
        return 'frames'
      } else if (isApparelColour) {
        return 'apparel'
      }
    }
    return 'frames'
  }

  render (): React$Node {
    const { colour, style, className, checked, value } = this.props
    const styles = this.getStyles()

    if (colour) {
      const formattedColour = colour.replace(' ', '_').toLowerCase()
      const formattedMixedColours = formattedColour.includes('/') ? formattedColour.replace('/', '_and_') : formattedColour
      const colourNameWithoutSpaces = formattedMixedColours.replace(/\s+/g, '_')
      const colourNameWithFrameType = this.addFrameTypePrefix(colourNameWithoutSpaces)
      const isApparelColour = Boolean(apparelColours.find(colour => colour.name === colourNameWithFrameType))
      const isFrameColourWithPng = Boolean(
        frameColours.find(colour => colour.name === colourNameWithFrameType && !colour.noSwatch)
      )
      const folderName = this.getFolderName(isApparelColour, isFrameColourWithPng)

      if (isApparelColour || isFrameColourWithPng) {
        return (
          <div
            value={value}
            className={className}
            style={{
              ...styles.box,
              backgroundImage: `url('/img/colours/${folderName}/${colourNameWithFrameType}.png')`,
              ...style
            }}
          >
            {checked && (
              <FontAwesome
                name="check"
                style={{ ...styles.check, ...this.getCheckIconColour(colourNameWithoutSpaces) }}
              />
            )}
          </div>
        )
      } else {
        const frameWithoutPng = frameColours.find(colour => colour.name === colourNameWithFrameType)
        if (frameWithoutPng) {
          return <div className={className} style={{ ...styles.box, ...styles.hexColourSwatch, backgroundColor: frameWithoutPng.hex, ...style }} />
        }
        return null
      }
    } else {
      return <span>No colour.</span>
    }
  }
}

export default (Radium(ColourSwatch): any)
