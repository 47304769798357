// @flow
import React from 'react'
import styles from './Inputs.module.css'
import cx from 'classnames'

type Props = {|
  className: string,
  id: string,
  min: string,
  value?: number,
  defaultValue?: number,
  step?: string,
  tabIndex?: string,
  disabled?: boolean,
  dataTest?: string,
  onChange: (any) => void
|}

type State = {|
  id: string
|}

class NumberField extends React.Component<Props, State> {
  inputRef: {| current: null | HTMLInputElement |} = React.createRef()

  onChange: ((event: SyntheticEvent<any>) => void) = (event: SyntheticEvent<*>) => {
    const value = event.currentTarget.value
    const currentInputRef = this.inputRef.current
    const startsWithZero = value[0] === '0'
    const containsDot = value.includes('.')

    if (startsWithZero && !containsDot && currentInputRef) {
      // remove leading 0
      currentInputRef.value = value.slice(1)
    }

    this.props.onChange(event)
  }

  render (): React$Node {
    const { className, dataTest, ...otherProps } = this.props
    return <input
      ref={this.inputRef}
      id={this.props.id}
      spellCheck={false}
      autoCorrect='off'
      autoCapitalize='off'
      type='number'
      min={this.props.min}
      className={cx(styles.primary, className)}
      data-test={dataTest}
      {...otherProps}
      onChange={this.onChange} />
  }
}
export default NumberField
