import Skeleton from '../../Skeleton'
import { VariantDataLoading } from './VariantDataLoading.component'

export function SalesChannelProductLoading() {
  return (
    <>
      <div className="flex flex-col justify-between gap-4 pt-8">
        <Skeleton className="w-96 max-w-full" />
        <Skeleton className="w-80 max-w-full" />
        <Skeleton className="w-80 max-w-full" />
        <Skeleton className="w-80 max-w-full" />
      </div>

      <div className="gap-6 pt-12 lg:flex lg:flex-wrap">
        <div className="mb-12 w-full max-w-full border border-gray-200 bg-gray-100 lg:w-[300px]">
          <div className="flex gap-6 bg-white p-5 lg:flex-col">
            <Skeleton className="grid h-[100px] w-[100px] place-content-center lg:h-[300px] lg:w-full" />
          </div>

          <Skeleton className="w-full" />
          <Skeleton className="mt-1 w-full" />
          <Skeleton className="mt-1 w-full" />
          <Skeleton className="mt-2 w-full" />
        </div>

        <div className="flex max-w-full flex-1 flex-col gap-6">
          <div className="border border-gray-200 bg-white p-8">
            <VariantDataLoading />
          </div>
          <div className="border border-gray-200 bg-white p-8">
            <VariantDataLoading />
          </div>
        </div>
      </div>
    </>
  )
}
