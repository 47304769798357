import Breadcrumbs from '../../Breadcrumbs'
import { ROUTE_PATHS } from '../../../constants'
import { ProductCatalogueProductInterface } from '../../../hooks'
import { BreadcrumbInterface } from '../../Breadcrumbs/Breadcrumbs.component'

export function ProductBreadcrumbs({ productData }: { productData: ProductCatalogueProductInterface }) {
  const formattedBreadcrumbs: BreadcrumbInterface[] = [
    {
      href: ROUTE_PATHS.PRODUCT_CATALOGUE,
      title: 'Products'
    }
  ]

  productData.breadcrumbs.forEach((crumb) => {
    formattedBreadcrumbs.push({ title: crumb.name, href: ROUTE_PATHS.PRODUCT_CATALOGUE + crumb.slug })
  })

  return <Breadcrumbs className="" pages={formattedBreadcrumbs} />
}
