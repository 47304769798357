import { Bars3Icon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useDebouncedUrlUpdate } from '../../../hooks'
import MultiSelectDropdown from '../../MultiSelectDropdown'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'

const OPTIONS = [
  {
    content: <span>Fulfilled</span>,
    searchLabel: 'Fulfilled',
    value: 'Fulfilled',
    disabled: false
  },
  {
    content: <span>Ignored</span>,
    searchLabel: 'Ignored',
    value: 'Ignored',
    disabled: false
  }
]

export function ProductsFilter() {
  const location = useLocation()
  const history = useHistory()
  const { salesChannelId } = useParams<{ salesChannelId: string }>()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedStatusParam = searchParams.get(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS)
  const selectedStatusDropdownValues = useMemo(
    () => (selectedStatusParam ? selectedStatusParam.split(',') : []),
    [selectedStatusParam]
  )

  const [selectedStatusFilters, setSelectedStatusFilters] = useState(selectedStatusDropdownValues)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedStatusFilters(selectedStatusDropdownValues)
  }, [selectedStatusDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  function handleUpdateSelectedValues(selectedValues: string[]) {
    setSelectedStatusFilters(selectedValues)
    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS,
      routePath: ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(salesChannelId),
      selectedValues
    })
  }

  function handleClear() {
    searchParams.delete(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS)
    history.push(`?${searchParams.toString()}`)
  }

  return (
    <MultiSelectDropdown
      dropdownName="Filter by"
      dropdownIcon={<Bars3Icon className="h-7 w-7 text-gray-500" aria-hidden="true" />}
      options={OPTIONS}
      selected={selectedStatusFilters}
      updateSelected={handleUpdateSelectedValues}
      onClear={handleClear}
    />
  )
}
