import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { useMerchantService } from '.'
import { FetchErrorInterface } from '../interfaces'
import { ArtworkInterface, ArtworkTransformationsInterface } from '../components/ImageEditor/interfaces'

interface ProductRepresentationInterface {
  name: string
  imageUrls: string[]
  isFulfilled: boolean
  platformId: string
  platformUrl: string
  numberOfFulfilledVariants: number
  totalNumberOfVariants: number
}

export interface ProductVariantRepresentationInterface {
  id: string
  isAutoFulfilled: boolean
  isFulfilled: boolean
  selectedProductData?: VariantSelectedProductDataInterface
  selectedPrintArea?: string | null
  title: string
  preferredShippingMethod: string | null
  artworkData?: Record<string, VariantSelectArtworkDataInterface | null | undefined> | null
}

interface VariantSelectedProductDataInterface {
  attributes?: Record<string, string> | null
  sku: string
  productType: string
}

export interface VariantSelectArtworkDataInterface {
  previewImageUrl?: string
  printImageUrl?: string
  artworkTransformations?: ArtworkTransformationsInterface
  artwork?: ArtworkInterface
  transformImageUrl?: string
}

export interface SalesChannelProductSuccessResponseInterface {
  data: {
    productData: ProductRepresentationInterface
    variantsData: ProductVariantRepresentationInterface[]
  }
}

interface SalesChannelProductErrorResponseInterface {
  outcome?: string
  traceParent?: string
  failures?: string[]
}

export function useSalesChannelProduct({
  productId,
  salesChannelId,
  config
}: {
  productId: string
  salesChannelId: string
  config?: SWRConfiguration
}) {
  const { merchantDetails } = useMerchantService()

  const url = `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/merchants/${merchantDetails?.id}/saleschannel/${salesChannelId}/products/${productId}/details`

  const { data, error, mutate } = useSWR<
    SalesChannelProductSuccessResponseInterface,
    FetchErrorInterface<SalesChannelProductErrorResponseInterface>
  >(url, fetcher, config)

  return {
    salesChannelProduct: data,
    isLoadingSalesChannelProduct: !data && !error,
    salesChannelProductFetchError: error,
    mutateSalesChannelProduct: mutate
  }
}
