import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import NotFound from '../NotFound'
import { clearProductSearch } from '../../../actions/catalogue'
import { CATALOGUE_PRODUCTS, QUERY_PARAMS, ROUTE_PATHS } from '../../constants'
import Breadcrumbs from '../Breadcrumbs'

export default function ProductCatalogueProductTypes() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { category: categoryParam } = useParams<{ category: string }>()

  const category = decodeURIComponent(categoryParam)
  const productTypes = CATALOGUE_PRODUCTS[category]?.productTypes

  if (!productTypes) {
    return (
      <div className="grid min-h-screen w-full place-content-center">
        <NotFound />
      </div>
    )
  }

  const breadcrumbs = [
    {
      href: ROUTE_PATHS.PRODUCT_INFO,
      title: 'Product info'
    },
    {
      href: ROUTE_PATHS.PRODUCTS,
      title: 'Product catalogue'
    },
    { title: category }
  ]

  return (
    <div className="container mx-auto py-6 lg:py-8">
      <Breadcrumbs className="mb-8 gap-3" pages={breadcrumbs} />
      <h1 className="mt-0 mr-auto mb-6 lg:mb-8">{category}</h1>

      <ul className="mt-10 grid list-none grid-cols-2 gap-10 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
        {Object.entries(productTypes).map(([productType, details]) => (
          <motion.li whileHover="hover" className="w-full" key={productType}>
            <button
              className="flex w-full cursor-pointer flex-col gap-4"
              onClick={() => {
                window.analytics.track('Clicked Product Sub-Category Tile', {
                  productName: productType
                })
                dispatch(clearProductSearch())
                history.push(
                  `${ROUTE_PATHS.MOF.SEARCH}?${QUERY_PARAMS.MOF.SEARCH.CATEGORY}=${encodeURIComponent(category)}&${
                    QUERY_PARAMS.MOF.SEARCH.PRODUCT_TYPE
                  }=${encodeURIComponent(productType)}`
                )
              }}
            >
              <div className="aspect-square w-full overflow-hidden rounded border border-gray-200">
                <motion.img
                  alt={productType}
                  className="w-full object-contain object-center"
                  src={details?.image}
                  variants={{
                    hover: {
                      scale: 1.05
                    }
                  }}
                />
              </div>

              <span className="text-left text-black">{productType}</span>
            </button>
          </motion.li>
        ))}
      </ul>
    </div>
  )
}
