import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'
import { formatSkuForMockup, MockupInterface } from './useMockups.hook'

export const PRODIGI_TO_MOCKUP_ATTRIBUTE_MAPPING: Record<string, string | undefined> = {
  mountColour: 'mountColor',
  frameColour: 'color'
}

export function useMockups3D({
  attributes,
  orientation,
  productSku
}: {
  attributes: Record<string, string>
  orientation: string
  productSku: string
}) {
  const formattedSku = formatSkuForMockup(productSku)

  const url = new URL(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/mockups/${formattedSku}`)

  Object.entries(attributes).forEach(([attributeName, attributeValue]) => {
    url.searchParams.set(
      PRODIGI_TO_MOCKUP_ATTRIBUTE_MAPPING[attributeName] ?? attributeName,
      attributeValue.toUpperCase()
    )
  })

  if (orientation && orientation.toUpperCase() !== 'SQUARE') {
    url.searchParams.set('orientation', orientation.toUpperCase())
  }

  const { data, error } = useSWR<MockupInterface, FetchErrorInterface<unknown>>(
    productSku ? url.toString() : null,
    fetcher
  )

  return {
    isLoadingMockups: !data && !error,
    mockups: data,
    mockupsFetchError: error
  }
}
