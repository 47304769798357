// @flow
import type { GetProductCategoriesResponse, AzureSearchResult, SearchFacet, GetProductTypesResponse } from '../../types'
import { UNAVAILABLE_PRODUCT_TYPES } from '../../data/catalogue'

export function filterOutUnavailableProducts (searchResult: AzureSearchResult[]): AzureSearchResult[] {
  return searchResult.filter((entry: AzureSearchResult) => isAvailableProduct(entry.productType))
}

export function filterOutUnavailableProductTypesFromFacets (facets: SearchFacet[]): SearchFacet[] {
  return facets.filter((facet) => isAvailableProduct(facet.value))
}

export function isAvailableProduct (productType: string): boolean {
  return !Object.values(UNAVAILABLE_PRODUCT_TYPES).includes(productType)
}

export function filterOutUnavailableCategories (getCategoriesResponse: GetProductCategoriesResponse): GetProductCategoriesResponse {
  const availableCategories = filterOutUnavailableProductTypesFromFacets(getCategoriesResponse['@search.facets'].category)

  getCategoriesResponse['@search.facets'].category = availableCategories

  return getCategoriesResponse
}

export function filterOutUnavailableProductTypes (productTypeResponse: GetProductTypesResponse): GetProductTypesResponse {
  const availableProductTypes = filterOutUnavailableProductTypesFromFacets(productTypeResponse['@search.facets'].productType)

  productTypeResponse['@search.facets'].productType = availableProductTypes

  return productTypeResponse
}
