import LogoProdigi from '../LogoProdigi'
import { APP_ENVIRONMENTS } from '../../constants'
import { SalesChannelPlatformEnum } from '../../enums'
import { SalesChannelInterface } from '../../interfaces'
import {
  EtsyLogoMarkIcon,
  WooCommerceLogoMarkIcon,
  WixLogoMarkIcon,
  SquarespaceLogoMarkIcon,
  AdobeCommerceLogoMarkIcon,
  BigCommerceLogoMarkIcon,
  ShopifyLogoMarkIcon
} from '../svg'

export interface PlatformLogoPropsInterface {
  platformName: SalesChannelPlatformEnum
}

export default function OrderItemSource({
  className = '',
  source
}: {
  className?: string
  source: SalesChannelInterface
}) {
  return (
    <span className={`flex ${className}`} data-test="order-item-source">
      <span className="flex-shrink-0">
        <PlatformLogo platformName={source.platform} />
      </span>
      {source.description}
    </span>
  )
}

function PlatformLogo({ platformName }: PlatformLogoPropsInterface) {
  switch (platformName) {
    case SalesChannelPlatformEnum.Prodigi:
      return <LogoProdigi className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.Etsy:
      return <EtsyLogoMarkIcon className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.WooCommerce:
      return <WooCommerceLogoMarkIcon className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.Wix:
      return <WixLogoMarkIcon className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.Squarespace:
      return <SquarespaceLogoMarkIcon className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.AdobeCommerce:
      return <AdobeCommerceLogoMarkIcon className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.BigCommerce:
      return <BigCommerceLogoMarkIcon className="mr-2 h-8 w-8" />
    case SalesChannelPlatformEnum.Shopify:
      return <ShopifyLogoMarkIcon className="mr-2 h-8 w-8" />
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
