// @flow
import type { Dictionary } from '../types'

/** Utility function mimicking Object.value whilst maintaining type annotations */
export function values<T> (dictionary: Dictionary<T>): T[] {
  // $FlowFixMe[incompatible-return]
  return Object.values(dictionary)
}

export function entries<T> (dictionary: Dictionary<T>): [string, T][] {
  // $FlowFixMe[incompatible-return]
  return Object.entries(dictionary)
}

export function filterOutEmptyElements (dict: Dictionary<string>): [string, string][] {
  return entries(dict).filter(([key, value]) => Boolean(value))
}
