import useSWR from 'swr'

import { fetcher } from '../../../helpers'
import { FetchErrorInterface } from '../../../interfaces'
import { useMerchantService } from '../../../hooks'

interface SalesChannelProductsOverviewSuccessInterface {
  outcome: string
  traceParent: string
  data: {
    salesChannelId: string
    fulfilledProducts: number
    ignoredProducts: number
  }
}
type SalesChannelProductsOverviewErrorResponseInterface = {
  identifier: string
  message: string
  outcome: string
  traceParent: string
}

export function useSalesChannelProductsOverview({ salesChannelId }: { salesChannelId?: string }) {
  const { merchantDetails } = useMerchantService()
  const url = `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/merchants/${merchantDetails?.id}/saleschannel/${salesChannelId}/products/overview`

  // https://swr.vercel.app/docs/conditional-fetching
  const {
    data,
    mutate: mutateSalesChannelProductsOverview,
    error
  } = useSWR<
    SalesChannelProductsOverviewSuccessInterface,
    FetchErrorInterface<SalesChannelProductsOverviewErrorResponseInterface>
  >(salesChannelId && merchantDetails?.id ? url : null, fetcher)

  return {
    productsOverview: data?.data,
    mutateSalesChannelProductsOverview,
    isLoading: !data && !error,
    error
  }
}
