import { VoteForPlatformEnum } from '../enums'
import { VoteForPlatformInterface } from '../interfaces'

export const VOTE_FOR_PLATFORMS: VoteForPlatformInterface[] = [
  {
    name: VoteForPlatformEnum.Amazon,
    description: 'Automatically import new orders from your Amazon store.',
    ctaButtonText: 'Request this sales channel',
    disclaimer: ''
  },
  {
    name: VoteForPlatformEnum.eBay,
    description: 'Automatically import new orders from your eBay store.',
    ctaButtonText: 'Request this sales channel',
    disclaimer: ''
  },
  {
    name: VoteForPlatformEnum.TikTok,
    description: 'Automatically import new orders from your TikTok store.',
    ctaButtonText: 'Request this sales channel',
    disclaimer: ''
  },
  {
    name: VoteForPlatformEnum.EKM,
    description: 'Automatically import new orders from your EKM store.',
    ctaButtonText: 'Request this sales channel',
    disclaimer: ''
  }
]
