import { APP_ENVIRONMENTS } from '../../../constants'
import { ImageLibraryImageInterface } from '../../../hooks'
import { V1DashboardArtworkInterface } from '../../../interfaces'
import { getProductFileCompatibilityData } from '../../../helpers/getProductFileCompatibilityData.helper'

export function generateArtworkDataForImageLibraryImage({
  productType,
  imageLibraryImage
}: {
  productType: string
  imageLibraryImage: ImageLibraryImageInterface
}): {
  artwork: V1DashboardArtworkInterface | null
  error: { message: string } | null
} {
  const productFileCompatibilityData = getProductFileCompatibilityData({ productType })

  if (!productFileCompatibilityData) {
    return generateArtworkDataForImage(imageLibraryImage)
  }

  const canFileBeConvertedToImage =
    productFileCompatibilityData.fileTypesAllowed.includes('jpg') && imageLibraryImage.file_type === 'pdf (single page)'

  if (canFileBeConvertedToImage) {
    return generateArtworkDataForImage(imageLibraryImage)
  }

  const isSelectedFileTypeAllowedOnProduct = productFileCompatibilityData.fileTypesAllowed.includes(
    imageLibraryImage.file_type
  )

  if (!isSelectedFileTypeAllowedOnProduct) {
    return {
      artwork: null,
      error: {
        message: `${
          imageLibraryImage.file_type
        } not supported for this product. Please select one of ${productFileCompatibilityData.fileTypesAllowed.join(
          ' or '
        )}`
      }
    }
  }

  switch (imageLibraryImage.file_type) {
    case 'jpg':
    case 'png': {
      return generateArtworkDataForImage(imageLibraryImage)
    }
    case 'pdf (single page)': {
      return generateArtworkDataForSinglePagePDF(imageLibraryImage)
    }
    case 'pdf (multi-page)': {
      return generateArtworkDataForMultiPagePDF(imageLibraryImage)
    }
    default: {
      const unhandledFileType: never = imageLibraryImage.file_type
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled file_type ${unhandledFileType}. Add a new case to fix this.`)
      }
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
  }
}

function generateArtworkDataForImage(imageLibraryImage: ImageLibraryImageInterface): {
  artwork: V1DashboardArtworkInterface | null
  error: { message: string } | null
} {
  if (
    !imageLibraryImage.pixel_height ||
    !imageLibraryImage.pixel_width ||
    !imageLibraryImage.thumbnail_urls.small ||
    !imageLibraryImage.thumbnail_urls.large
  ) {
    return { artwork: null, error: { message: 'Image data not found' } }
  }

  switch (imageLibraryImage.file_type) {
    case 'jpg':
    case 'png': {
      return {
        artwork: {
          croppedImageUrl: imageLibraryImage.url,
          smallImageUrl: imageLibraryImage.thumbnail_urls.small,
          mediumImageUrl: imageLibraryImage.thumbnail_urls.large,
          originalImageUrl: imageLibraryImage.url,
          artworkHeight: imageLibraryImage.pixel_height,
          artworkWidth: imageLibraryImage.pixel_width,
          imageLibraryId: imageLibraryImage.public_id,
          mimeType: imageLibraryImage.mime_type,
          fileType: imageLibraryImage.file_type
        },
        error: null
      }
    }
    case 'pdf (single page)': {
      return {
        artwork: {
          croppedImageUrl: imageLibraryImage.url,
          smallImageUrl: imageLibraryImage.thumbnail_urls.small,
          mediumImageUrl: imageLibraryImage.thumbnail_urls.large,
          originalImageUrl: imageLibraryImage.url,
          artworkHeight: imageLibraryImage.pixel_height,
          artworkWidth: imageLibraryImage.pixel_width,
          imageLibraryId: imageLibraryImage.public_id,
          mimeType: 'image/jpeg',
          fileType: 'jpg'
        },
        error: null
      }
    }
    case 'pdf (multi-page)': {
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
    default: {
      const unhandledFileType: never = imageLibraryImage.file_type
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled file_type ${unhandledFileType}. Add a new case to fix this.`)
      }
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
  }
}

function generateArtworkDataForSinglePagePDF(imageLibraryImage: ImageLibraryImageInterface): {
  artwork: V1DashboardArtworkInterface | null
  error: { message: string } | null
} {
  if (
    !imageLibraryImage.pixel_height ||
    !imageLibraryImage.pixel_width ||
    !imageLibraryImage.thumbnail_urls.small ||
    !imageLibraryImage.thumbnail_urls.large
  ) {
    return { artwork: null, error: { message: 'Image data not found' } }
  }

  switch (imageLibraryImage.file_type) {
    case 'jpg':
    case 'png': {
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
    case 'pdf (single page)': {
      return {
        artwork: {
          croppedImageUrl: imageLibraryImage.original_url,
          smallImageUrl: imageLibraryImage.thumbnail_urls.small,
          mediumImageUrl: imageLibraryImage.thumbnail_urls.large,
          originalImageUrl: imageLibraryImage.original_url,
          artworkHeight: imageLibraryImage.pixel_height,
          artworkWidth: imageLibraryImage.pixel_width,
          imageLibraryId: imageLibraryImage.public_id,
          mimeType: imageLibraryImage.mime_type,
          fileType: imageLibraryImage.file_type
        },
        error: null
      }
    }
    case 'pdf (multi-page)': {
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
    default: {
      const unhandledFileType: never = imageLibraryImage.file_type
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled file_type ${unhandledFileType}. Add a new case to fix this.`)
      }
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
  }
}

function generateArtworkDataForMultiPagePDF(imageLibraryImage: ImageLibraryImageInterface): {
  artwork: V1DashboardArtworkInterface | null
  error: { message: string } | null
} {
  if (
    !imageLibraryImage.pixel_height ||
    !imageLibraryImage.pixel_width ||
    !imageLibraryImage.thumbnail_urls.small ||
    !imageLibraryImage.thumbnail_urls.large
  ) {
    return { artwork: null, error: { message: 'Image data not found' } }
  }

  switch (imageLibraryImage.file_type) {
    case 'jpg':
    case 'png': {
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
    case 'pdf (single page)': {
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
    case 'pdf (multi-page)': {
      return {
        artwork: {
          croppedImageUrl: imageLibraryImage.original_url,
          smallImageUrl: imageLibraryImage.thumbnail_urls.small,
          mediumImageUrl: imageLibraryImage.thumbnail_urls.large,
          originalImageUrl: imageLibraryImage.original_url,
          artworkHeight: imageLibraryImage.pixel_height,
          artworkWidth: imageLibraryImage.pixel_width,
          imageLibraryId: imageLibraryImage.public_id,
          mimeType: imageLibraryImage.mime_type,
          fileType: imageLibraryImage.file_type
        },
        error: null
      }
    }
    default: {
      const unhandledFileType: never = imageLibraryImage.file_type
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled file_type ${unhandledFileType}. Add a new case to fix this.`)
      }
      return { artwork: null, error: { message: `${imageLibraryImage.file_type} not supported for this product` } }
    }
  }
}
