import { useState } from 'react'
import toast from 'react-hot-toast'

import { Tags } from '../../ImageDetail'
import { ManageActionsValueType } from '.'
import OverlayPortal from '../../OverlayPortal'
import ConfirmationModal from '../../ConfirmationModal'
import { removeTagsFromImageInLibrary } from '../helpers'
import { createErrorToast, createToast } from '../../Toast'
import { IMAGE_LIBRARY_FULL_PAGE_TOASTS } from '../constants'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface RemoveTagsModalPropsInterface {
  actionModalToOpen: ManageActionsValueType | null
  imageLibraryImages: ImageLibraryImageInterface[]
  onSetActionModalToOpen: (isOpen: ManageActionsValueType | null) => void
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
}

export function RemoveTagsModal({
  actionModalToOpen,
  imageLibraryImages,
  onSetActionModalToOpen,
  onSetImageLibraryMode
}: RemoveTagsModalPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const [imageUpdateStatus, setImageUpdateStatus] = useState<StatusType>('idle')
  const [listOfTags, setListOfTags] = useState<string[]>([])

  async function handleUpdateImages() {
    toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
    setImageUpdateStatus('loading')
    window.analytics.track('Image library - clicked: Remove tags')

    try {
      const requests = imageLibraryImages.map((image) =>
        removeTagsFromImageInLibrary({ imageLibraryImage: image, tags: listOfTags })
      )
      const result = await Promise.allSettled(requests)
      await mutateImageLibraryImages()

      const rejectedRequests = result.filter((result) => result.status === 'rejected')

      if (rejectedRequests.length > 0) {
        const errors = rejectedRequests.map((error) => {
          const fetchErrorResponse = error as PromiseRejectedResult
          return fetchErrorResponse?.reason?.status ?? '0'
        })
        createErrorToast({
          errorCode: errors.join(', '),
          heading: 'Failed to update all images',
          id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR
        })
        setImageUpdateStatus('error')
        onSetActionModalToOpen(null)
        onSetImageLibraryMode('select')
        return
      }

      setImageUpdateStatus('success')
      createToast({
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.SUCCESS,
        heading: `Image${requests.length > 1 ? 's' : ''} updated`,
        type: 'success'
      })
      onSetActionModalToOpen(null)
      setListOfTags([])
      onSetImageLibraryMode('select')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createErrorToast({
        errorMessage: errorResponse.message,
        heading: 'Failed to update images',
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR,
        errorCode: errorResponse.status
      })
      setImageUpdateStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setImageUpdateStatus('idle')
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.SUCCESS)
    }
    setListOfTags([])
    onSetActionModalToOpen(null)
  }

  const headerText =
    imageLibraryImages.length > 1 ? `Remove tags from ${imageLibraryImages.length} images` : 'Remove tags from image'

  return (
    <>
      <ConfirmationModal
        contentClassName="max-w-none"
        className="flex max-h-70vh w-90vw max-w-5xl flex-col md:w-70vw lg:w-50vw"
        closeButton={{ text: 'Cancel' }}
        closeOnEscape={imageUpdateStatus !== 'loading'}
        closeOnInteractionOutside={imageUpdateStatus !== 'loading'}
        continueButton={{ text: 'Remove these tags', disabled: listOfTags.length === 0 }}
        isLoading={imageUpdateStatus === 'loading'}
        title={headerText}
        open={actionModalToOpen === 'remove-tags'}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleUpdateImages}
      >
        <div className="flex-grow">
          <Tags tags={listOfTags} onUpdate={(tags) => setListOfTags(tags)} />
        </div>
      </ConfirmationModal>

      {imageUpdateStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
