// @flow
import React, { Component } from 'react'
import Radium from 'radium'
import { changeFacetSelection, toggleFacetExpansion } from '../../../../actions/catalogue'
import { connect } from 'react-redux'
import productAttributeFormat from '../../../../helpers/productAttributeFormat'
import { getAttrNameForCategory } from '../../../../helpers/getAttrNameForCategory'
import type { AppState, DispatchFunc, Facet } from '../../../../types'
import ColourSwatch from '../ColourSwatch'

type Props = {|
  facet: Facet,
  toggleFacetExpansion: string => void,
  changeFacetSelection: (string, string) => void,
  selectedItemCategory: string,
  dataTest: string
|}

const MAX_FACET_COUNT_WHEN_COLLAPSED = 7

class FacetPanel extends Component<Props> {
  getStyles () {
    return {
      container: {
        width: '100%'
      },
      panel: {
        margin: '2.4rem 0 0'
      },
      facetValue: {
        position: 'relative',
        textTransform: 'capitalize'
      },
      expandLink: {
        color: 'var(--accent-blue-dark)',
        cursor: 'pointer',
        marginLeft: '20px',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        ':hover': {
          textDecoration: 'underline'
        },
        ':focus': {
          outline: 'none'
        }
      },
      swatch: {
        marginRight: '0.3em',
        float: 'left',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '18px',
        height: '18px',
        backgroundSize: 'cover'
      }
    }
  }

  onToggleExpansion = e => {
    e.preventDefault()
    this.props.toggleFacetExpansion(this.props.facet.facet)
  }

  onFacetSelectionChanged = e => {
    const facetNameAndValue = e.target.value.split('|')
    const facetName = facetNameAndValue[0]
    const facetValue = facetNameAndValue[1]
    this.props.changeFacetSelection(facetName, facetValue)
  }

  selectColour = e => {
    const facetNameAndValue = e.currentTarget.getAttribute('value').split('|')
    const facetName = facetNameAndValue[0]
    const facetValue = facetNameAndValue[1]
    this.props.changeFacetSelection(facetName, facetValue)
  }

  filterByIndex = (_, index: number) => {
    if (this.props.facet.expanded) {
      return true
    }
    return index < MAX_FACET_COUNT_WHEN_COLLAPSED
  }

  render (): React$Node {
    const styles = this.getStyles()
    const { facet, selectedItemCategory, dataTest } = this.props
    const showExpand = facet.values.length > MAX_FACET_COUNT_WHEN_COLLAPSED
    const selectedValuesCount = facet.values.filter(v => v.selected).length

    return (
      <div data-test={dataTest} key={facet.facet} style={styles.panel}>
        <strong>
          {getAttrNameForCategory(facet.facet, selectedItemCategory)}
          {selectedValuesCount > 0 && <span>&nbsp;({selectedValuesCount})</span>}
        </strong>
        {facet.values.filter(this.filterByIndex).map(facetItem => (
          <div style={styles.facetValue} key={facet.facet + '|' + facetItem.value}>
            {facet.facet === 'frameColour' && selectedItemCategory !== 'Homewares' ? (
              <span onClick={this.selectColour} value={facet.facet + '|' + facetItem.value}>
                <ColourSwatch
                  selectedItemCategory={selectedItemCategory}
                  value={facet.facet + '|' + facetItem.value}
                  colour={productAttributeFormat(facetItem.value.toString())}
                  style={styles.swatch}
                  checked={facetItem.selected}
                />
                <span style={{ cursor: 'default', ...(facetItem.enabled ? null : { color: 'var(--color-grey-40)' }) }}>
                  {facetItem.value ? productAttributeFormat(facetItem.value.toString()) : 'None'}
                </span>
              </span>
            ) : (
              <span>
                <input
                  id={`select_${facet.facet}_${escape(facetItem.value.toString())}`}
                  type="checkbox"
                  onChange={this.onFacetSelectionChanged}
                  value={facet.facet + '|' + facetItem.value}
                  checked={facetItem.selected}
                />
                <label
                  htmlFor={`select_${facet.facet}_${escape(facetItem.value.toString())}`}
                  style={facetItem.enabled ? null : { color: 'var(--color-grey-40)' }}
                >
                  {facetItem.value ? productAttributeFormat(facetItem.value.toString()) : 'None'}
                </label>
              </span>
            )}
          </div>
        ))}

        {showExpand && (
          <select>
            <option>Option</option>
          </select>
        ) && (
          <button style={styles.expandLink} onClick={this.onToggleExpansion}>
            {facet.expanded ? 'Show fewer...' : 'Show more...'}
          </button>
        )}
      </div>
    )
  }
}

function mapStateToProps (state: AppState) {
  return {
    facets: state.catalogue.facets,
    selectedItemCategory: state.catalogue.selectedItemCategory
  }
}

function mapDispatchToProps (dispatch: DispatchFunc) {
  return {
    changeFacetSelection: (facetName: string, facetValue: string) => {
      dispatch(changeFacetSelection(facetName, facetValue))
    },
    toggleFacetExpansion: (facetName: string) => {
      dispatch(toggleFacetExpansion(facetName))
    }
  }
}

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Radium(FacetPanel)): any)
