import { postcodeRegex } from '../constants'

export function isPostcodeValid(country: string, postcode: string): boolean {
  const countryData = postcodeRegex.find((x) => x.iso === country)

  if (!countryData) {
    return false
  }

  if (countryData.iso === 'GB' && isPostcodeInvalidCountryForUK(postcode.trim().toUpperCase())) {
    return false
  }

  const regex = new RegExp(countryData.regex)
  return regex.test(postcode.replace(/\s+/gi, ''))
}

/**
 * Check if postcode format is invalid for UK even though it might fit into generic GB format.
 * Countries like Jersey should be selected as the order destination country for correct shipping rates.
 * See https://prodigiuk.atlassian.net/browse/HB-545 for more info.
 * @param {string} postcode - postcode to check
 */
function isPostcodeInvalidCountryForUK(postcode: string) {
  if (postcode.startsWith('JE') || postcode.startsWith('GY')) {
    return true
  }
  return false
}
