import useSWR from 'swr'
import { fetcher, getMockupDetailsForSelectedAttributes } from '../helpers'
import { DictionaryInterface, FetchErrorInterface } from '../interfaces'

export interface MockupViewInterface {
  variants: DictionaryInterface<string>
  thumbnail_image: string | null
  renderer: 'PIG' | 'PorcusLardum'
}

export interface MockupInterface {
  views: DictionaryInterface<MockupViewInterface>
}
type MockupsErrorType = unknown

export interface SelectedMockupInterface {
  view_name: string
  variants: DictionaryInterface<string>
}

export function useMockups({
  attributes,
  productSku,
  orientation,
  orientationSelectionAvailable
}: {
  attributes: DictionaryInterface<string>
  productSku?: string
  orientation: string
  orientationSelectionAvailable: boolean
}) {
  const formattedSku = formatSkuForMockup(productSku)
  const url = `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/mockups/${formattedSku}`

  const { data, error } = useSWR<MockupInterface, FetchErrorInterface<MockupsErrorType>>(
    productSku ? url : null,
    fetcher
  )

  const mockup = getMockupDetailsForSelectedAttributes({
    attributes,
    mockups: data,
    orientation,
    orientationSelectionAvailable
  })

  return {
    mockup,
    mockups: data,
    isLoading: Boolean(!data && !error),
    error
  }
}

// the image library expects skus to be in the format GLOBAL-TEST-8x12 but the FE uses GLOBAL-TEST-8X12
// this function lowercases the x in the size
export function formatSkuForMockup(sku?: string) {
  if (!sku) {
    return null
  }

  const elements = sku.split('-')

  const sizeQuery = /^\d+X\d+$/i
  const updatedElements = elements.map((x) => {
    if (!x.match(sizeQuery)) {
      return x
    }

    return x.toLowerCase()
  })

  return updatedElements.join('-')
}
