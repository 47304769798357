// @flow

import React, { Component } from 'react'

import { SUCCESS, LOADING } from '../../../../data/rsaa'
import { entries } from '../../../../helpers/dictionary'
import { buildTemplateId } from '../../../../actions/images'
import getDisplayImage from '../../../../helpers/getDisplayImage'
import MultiAssetBasketItemView from './MultiAssetBasketItemView'
import { selectTemplate } from '../../../../helpers/selectTemplate'
import { calcQuality } from '../../../../helpers/imageEditor/calcQuality'
import type { MultiAssetBasketItemControllerProps } from './MultiAssetBasketItemProps'
import { selectFirstAvailableTemplate } from '../../../../helpers/selectFirstAvailableTemplate'
import { setDefaultPrintAreaEntryAsFirst } from '../../../../helpers/setDefaultPrintAreaEntryAsFirst'
import { createThumbnailItemFromBasketItem } from '../../../../helpers/createThumbnailItemFromBasketItem'
import type { Status, MultiAssetTemplate, ThumbnailItem, PrintAreaEntries, Artwork } from '../../../../types'
import { buildBackgroundImageStyleFromTemplate } from '../../../../helpers/buildBackgroundImageStyleFromTemplate'

type State = {|
  isTilingEnabled: boolean
|}

export default class MultiAssetBasketItemController extends Component<MultiAssetBasketItemControllerProps, State> {
  state: State = {
    isTilingEnabled: false
  }

  onEditImageClick: () => void = () => {
    if (document.body) {
      document.body.scrollTop = 0
    }

    if (document.documentElement) {
      document.documentElement.scrollTop = 0
    }

    this.props.setCurrentPreviewItem(this.props.item)
    this.props.redirect('/image-editor/' + this.props.item.id)
  }

  uploadArtworkFromFile: (file: File) => void = (file: File) => {
    if (!this.props.item.selectedPrintArea) {
      return
    }
    this.props.uploadArtworkFromFile(
      this.props.item.id,
      file,
      this.props.item.selectedPrintArea,
      this.state.isTilingEnabled
    )
  }

  uploadArtworkFromUrl: (url: string) => void = (url: string) => {
    if (!this.props.item.selectedPrintArea) {
      return
    }
    this.props.uploadArtworkFromUrl(
      this.props.item.id,
      url,
      this.props.item.selectedPrintArea,
      this.state.isTilingEnabled
    )
  }

  onSelectImageLibraryImage: (artwork: Artwork) => void = (artwork) => {
    if (!this.props.item.selectedPrintArea) {
      return
    }
    this.props.onSelectImageLibraryImage({
      artwork,
      basketItemId: this.props.item.id,
      printAreaName: this.props.item.selectedPrintArea,
      isTilingEnabled: this.state.isTilingEnabled
    })
  }

  removeBasketItem: () => void = () => this.props.removeBasketItem(this.props.item.id)

  onPrintAreaChange: (newPrintAreaName: string) => void = (newPrintAreaName: string) => {
    this.props.changeSelectedPrintAreaForBasketItem(this.props.item.id, newPrintAreaName)
  }

  onRemoveArtwork: () => void = () => {
    if (!this.props.item.selectedPrintArea) {
      return
    }
    this.props.removeBasketItemArtwork(this.props.item.id, this.props.item.selectedPrintArea)
  }

  toggleTiling: () => void = () => {
    this.setState((state) => ({ isTilingEnabled: !state.isTilingEnabled }))
  }

  get templateStatus(): Status {
    if (this.template) {
      return SUCCESS
    } else {
      return this.props.templateStatus
    }
  }

  get thumbnailItem(): ?ThumbnailItem {
    if (!this.props.item.printAreas || !this.props.selectedPrintArea) {
      return null
    }
    return createThumbnailItemFromBasketItem(
      this.props.item,
      this.props.item.printAreas[this.props.selectedPrintArea],
      this.template
    )
  }

  get template(): ?MultiAssetTemplate {
    if (!this.props.item.printAreas || !this.props.selectedPrintArea) {
      return null
    }
    const artworkTransformations = this.props.item.printAreas[this.props.selectedPrintArea]?.artworkTransformations

    const template = this.props.templates
      ? selectTemplate(this.props.templates, this.props.selectedPrintArea, artworkTransformations?.orientation)
      : null
    return template
  }

  get noArtworkBackgroundImageStyle(): ?string {
    const { selectedPrintArea, templates } = this.props
    const firstAvailableTemplate = selectFirstAvailableTemplate(templates, selectedPrintArea)
    if (!firstAvailableTemplate) {
      return null
    }
    return buildBackgroundImageStyleFromTemplate(firstAvailableTemplate)
  }

  get loadingMessage(): string {
    const { item, imageStatus } = this.props
    const uploadingImage =
      (!item.printAreas ||
        !item.printAreas[this.props.selectedPrintArea] ||
        !item.printAreas[this.props.selectedPrintArea].artwork ||
        !item.printAreas[this.props.selectedPrintArea].artwork.originalImageUrl) &&
      imageStatus === LOADING

    const updatingImage =
      item.printAreas &&
      this.props.selectedPrintArea &&
      item.printAreas[this.props.selectedPrintArea] &&
      item.printAreas[this.props.selectedPrintArea].artwork &&
      item.printAreas[this.props.selectedPrintArea].artwork.originalImageUrl &&
      imageStatus === LOADING

    if (!this.template && this.templateStatus === LOADING) {
      return ' Please wait...'
    } else if (uploadingImage) {
      return ' Uploading image...'
    } else if (updatingImage) {
      return ' Updating image...'
    } else {
      return ' Please wait...'
    }
  }

  get substituteImageSource(): ?string {
    const { catalogueItem, item } = this.props

    if (!catalogueItem) {
      return null
    }

    const type = catalogueItem.productType
    const colour = item.selectedAttributes.frameColour
    const sku = item.sku

    return getDisplayImage(colour, type, sku)
  }

  get isLoading(): boolean {
    return Boolean(
      this.templateStatus === LOADING ||
        this.props.imageStatus === LOADING ||
        !this.props.v4ProductDetailsStatus.success ||
        this.props.catalogueDataRsaaStatus.loading
    )
  }

  get hasError(): boolean {
    return this.props.v4ProductDetailsStatus.error
  }

  get printAreaEntries(): ?PrintAreaEntries {
    if (!this.props.v4ProductDetails || !this.props.v4ProductDetails.printAreas) {
      return null
    }
    return setDefaultPrintAreaEntryAsFirst(entries(this.props.v4ProductDetails.printAreas))
  }

  get requiredPrintAreaNames(): string[] {
    if (!this.printAreaEntries) {
      return []
    }

    return this.printAreaEntries
      .filter(([printAreaName, { required }]) => Boolean(required))
      .map(([printAreaName]) => printAreaName)
  }

  get imagePreviewKey(): string {
    const { item, selectedPrintArea } = this.props
    return `${selectedPrintArea}-${buildTemplateId(item.sku, item.selectedAttributes)}`
  }

  get optimalResolution(): ?string {
    const template = this.template ?? selectFirstAvailableTemplate(this.props.templates, this.props.selectedPrintArea)
    if (!template) {
      return null
    }

    const { printDpi, printResolution } = template
    return `${printResolution.width}x${printResolution.height}px (${printDpi}dpi)`
  }

  get imageQuality(): ?number {
    if (!this.thumbnailItem) {
      return null
    }

    return calcQuality(
      this.thumbnailItem.transformations,
      this.thumbnailItem.artworkDimensionsInPx,
      this.thumbnailItem.printDimensionsInPx,
      this.thumbnailItem.cropRectangle,
      this.thumbnailItem.printDpi
    )
  }

  renderContent(): React$Node {
    return (
      <MultiAssetBasketItemView
        isTilingEnabled={this.state.isTilingEnabled}
        toggleTiling={this.toggleTiling}
        catalogueItem={this.props.catalogueItem}
        isLoading={this.isLoading}
        imagePreviewKey={this.imagePreviewKey}
        hasError={this.hasError}
        item={this.props.item}
        index={this.props.index}
        imageQuality={this.imageQuality}
        printAreaEntries={this.printAreaEntries}
        requiredPrintAreaNames={this.requiredPrintAreaNames}
        onPrintAreaChange={this.onPrintAreaChange}
        selectedPrintArea={this.props.selectedPrintArea}
        substituteImageSource={this.substituteImageSource}
        isLastItem={this.props.isLastItem}
        changeSelectedAttribute={(changeSelectedAttributeData) =>
          this.props.changeSelectedAttribute(changeSelectedAttributeData)
        }
        removeBasketItem={this.removeBasketItem}
        changeQuantity={this.props.changeQuantity}
        artworkErrorMessage={this.props.artworkErrorMessage}
        imageStatus={this.props.imageStatus}
        templateStatus={this.templateStatus}
        optimalResolution={this.optimalResolution}
        noArtworkBackgroundImageStyle={this.noArtworkBackgroundImageStyle}
        artworkTransformationStatus={this.props.artworkTransformationStatus}
        thumbnailItem={this.thumbnailItem}
        uploadArtworkFromUrl={(url) => this.uploadArtworkFromUrl(url)}
        uploadArtworkFromFile={(file) => this.uploadArtworkFromFile(file)}
        onEditImageClick={this.onEditImageClick}
        onRemoveArtwork={this.onRemoveArtwork}
        loadingMessage={this.loadingMessage}
        onSelectImageLibraryImage={this.onSelectImageLibraryImage}
      />
    )
  }

  render(): React$Node {
    return this.renderContent()
  }
}
