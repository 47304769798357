// @flow
import React, { PureComponent } from 'react'
import styles from './CsvItemImage.module.css'
import type { CatalogueItem, FormattedOrderItem, Status, ThumbnailItem, V4ProductDetails } from '../../../../types'
import CsvOpenModalButton from '../CsvOpenModalButton'
import CsvImageModal from '../CsvImageModal'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'
import Heading from '../Heading'
import cx from 'classnames'
import { PRINT_AREA_NAME } from '../../../../data'

// $FlowFixMe: TypeScript file
import ImageEditorPreview from '../../../../v2/components/ImageEditor/components/ImageEditorPreview.component'

type Props = {|
  catalogueDetails: ?CatalogueItem,
  item: FormattedOrderItem,
  isImageModalOpen: boolean,
  imageUploadButtonText: string,
  isLoadingPreview: boolean,
  imageEditorKey: string,
  orderId: string,
  templateStatus: Status,
  imageStatus: Status,
  thumbnailItem: ?ThumbnailItem,
  imageStyle: string,
  canDisplayThumbnail: boolean,
  canEdit: boolean,
  hasImageError: boolean,
  isImageComplete: boolean,
  imageErrorMessage: string,
  v4ProductDetails: ?V4ProductDetails,
  closeImageModal: () => void,
  openImageModal: () => void,
  onArtworkRenderError: () => void,
  onLoadArtwork: () => void,
  onLoadOverlay: () => void
|}

export default class CsvItemImageView extends PureComponent<Props> {
  render(): React$Node {
    const imageEditorPreviewContainerClassName = cx({
      [styles.visible]: !this.props.isLoadingPreview,
      [styles.hidden]: this.props.isLoadingPreview
    })
    const { catalogueDetails, canDisplayThumbnail } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <Heading>Print image</Heading>
          {catalogueDetails?.noImageProduct && (
            <img
              className={styles.noImageProduct}
              alt="product"
              src={this.props.item.printAreaImageUrls[PRINT_AREA_NAME.DEFAULT]}
            />
          )}

          {this.props.isLoadingPreview && !catalogueDetails?.noImageProduct && (
            <div data-test="loading-csv-item-preview" className={styles.loading}>
              Loading Preview
              <LoadingEllipsis className={styles.loadingEllipsis} />
            </div>
          )}

          {this.props.thumbnailItem?.artwork.mimeType === 'application/pdf' && (
            <div className="tailwind">
              <div className="flex flex-col items-center">
                <img
                  className="h-[220px] w-[220px] object-contain"
                  src={this.props.thumbnailItem?.artwork?.mediumImageUrl}
                />
              </div>
            </div>
          )}

          {canDisplayThumbnail &&
            this.props.thumbnailItem &&
            this.props.thumbnailItem.artwork.mimeType !== 'application/pdf' &&
            !catalogueDetails?.noImageProduct && (
              <div className={imageEditorPreviewContainerClassName}>
                <ImageEditorPreview
                  key={this.props.imageEditorKey}
                  isPreviewOnly={true}
                  cropRectangle={this.props.thumbnailItem.cropRectangle}
                  transformations={this.props.thumbnailItem.transformations}
                  printDimensionsInPx={this.props.thumbnailItem.printDimensionsInPx}
                  artworkDimensionsInPx={this.props.thumbnailItem.artworkDimensionsInPx}
                  imageUrls={this.props.thumbnailItem.imageUrls}
                  printDpi={this.props.thumbnailItem.printDpi}
                  onArtworkError={this.props.onArtworkRenderError}
                  onLoadArtwork={this.props.onLoadArtwork}
                  onLoadOverlay={this.props.onLoadOverlay}
                />
              </div>
            )}
        </div>
        {this.props.canEdit && (
          <div className={styles.action}>
            <CsvOpenModalButton
              dataTest="csv-open-image-modal-button"
              onClick={this.props.openImageModal}
              isCompleted={this.props.isImageComplete}
              text={this.props.imageUploadButtonText}
            />
          </div>
        )}

        {this.props.isImageModalOpen &&
          catalogueDetails &&
          this.props.v4ProductDetails &&
          Object.keys(this.props.item.printAreaImageUrls).length > 0 && (
            <CsvImageModal
              catalogueDetails={catalogueDetails}
              imageErrorMessage={this.props.imageErrorMessage}
              thumbnailItem={this.props.thumbnailItem}
              templateStatus={this.props.templateStatus}
              imageStatus={this.props.imageStatus}
              orderId={this.props.orderId}
              closeModal={this.props.closeImageModal}
              item={this.props.item}
              v4ProductDetails={this.props.v4ProductDetails}
            />
          )}
      </div>
    )
  }
}
