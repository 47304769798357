import { buildPigTransformations } from '../components/ImageEditor/helpers'
import { ArtworkInterface } from '../components/ImageEditor/interfaces'
import { TemplateDataInterface } from '../hooks'
import { FetchErrorInterface } from '../interfaces'
import { ArtworkTransformations } from '../../types'
import { FetchError, fetcher, getDefaultFetchOptions } from '.'

export interface ImageLibraryTransformInterface {
  expiry: string
  transformation_api_url: string
  transformation_id: string
  url: string
}

/**
 * Get transform data for images uploaded in the Image Library
 *
 * `transformation_api_url` does not return an accessible image and is meant for backend storage purposes,
 * so they can communicate with the Image Library to get fresh transformed image URLs
 *
 * `url` is a short lived transform URL which returns an acessible image with the requested transformations
 */
export async function transformImage({
  artwork,
  imageLibraryId,
  template,
  transformations,
  preview
}: {
  artwork: ArtworkInterface
  imageLibraryId: string
  template: TemplateDataInterface
  transformations: ArtworkTransformations
  preview: boolean
}) {
  const pigTransformations = buildPigTransformations({ artwork, transformations, template })

  return fetcher<ImageLibraryTransformInterface>(
    `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/transform/image/${imageLibraryId}?preview=${preview}`,
    {
      body: JSON.stringify({
        provider: 'PorcusLardum',
        transformation: pigTransformations
      }),
      headers: {
        Authorization: getDefaultFetchOptions().headers.Authorization,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }
  ).catch((error) => {
    const errorResponse = error as FetchErrorInterface<{ detail?: string }>
    throw new FetchError({
      message: errorResponse?.message,
      responseBodyJson: errorResponse.responseBodyJson
        ? {
            ...errorResponse.responseBodyJson,
            imageLibraryId
          }
        : { imageLibraryId },
      name: 'FETCH_ERROR',
      status: error?.status
    })
  })
}
