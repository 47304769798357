// @flow

export const LANDSCAPE = 'landscape'
export const PORTRAIT = 'portrait'
export const SQUARE = 'square'

export const ORIENTATION = {
  LANDSCAPE,
  PORTRAIT,
  SQUARE
}
