import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CellContext } from '@tanstack/react-table'

import Button from '../../Button'
import { VariantRowType } from '../../../hooks'
import ProductDetailsModal from '../../ProductDetailsModal'
import { selectCurrencyCode } from '../../../../selectors/user'
import { selectDeliveryCountry } from '../../../../selectors/manualOrderForm'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export function VariantsInfoAction({ info }: { info: CellContext<VariantRowType, unknown> }) {
  const [open, setOpen] = useState(false)
  const currencyCode = useSelector(selectCurrencyCode)
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)

  const sku = info.row.original.sku
  const description = info.row.original.description

  return (
    <div className="flex justify-end">
      <Button
        variant="none"
        className="p-4 text-purple-700"
        onClick={() => {
          window.analytics.track('Show product details modal', { sku })
          setOpen(true)
        }}
      >
        <InformationCircleIcon className="h-10 w-10" />
      </Button>

      <ProductDetailsModal
        actions={<></>}
        countryCode={selectedDeliveryCountry}
        currencyCode={currencyCode}
        description={description}
        open={open}
        sku={sku}
        showCountryPicker={true}
        setOpen={setOpen}
      />
    </div>
  )
}
