import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

interface ErrorMessagePropsInterface {
  children?: React.ReactNode
  className?: string
  showHeading?: boolean
  message: string
}
export default function ErrorMessage({
  children,
  className = '',
  message,
  showHeading = true
}: ErrorMessagePropsInterface) {
  return (
    <div className={`${className} bg-red-100`} data-test="error-message">
      <div className="flex p-6">
        <div className="flex flex-shrink-0 items-center justify-center">
          <ExclamationCircleIcon aria-hidden="true" className="h-7 w-7 text-red-400" />
        </div>
        <div className="ml-3 max-w-lg break-words">
          {showHeading && <h3 className="mt-0 font-medium text-red-800">Error</h3>}
          <p className={`${showHeading && 'mt-2'} text-sm text-red-700`}>{message}</p>
          {children}
        </div>
      </div>
    </div>
  )
}
