// @flow
import { RSAA } from 'redux-api-middleware'
import type { DispatchFunc, Thunk } from '../../types'
import { LOG_STATUS } from '../../data/rsaa'

export const KITE_LOGOUT = 'KITE_LOGOUT'
export const KITE_LOGOUT_SUCCESS = 'KITE_LOGOUT_SUCCESS'
export const KITE_LOGOUT_ERROR = 'KITE_LOGOUT_ERROR'
export const KITE_LOGOUT_ERROR_WITH_MODAL = 'KITE_LOGOUT_ERROR_WITH_MODAL'

export function kiteLogout (shouldDisplayModalOnError: boolean = true): Thunk<void> {
  return (dispatch: DispatchFunc) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_KITE_DASHBOARD_BACKEND || ''}/auth/logout/`,
        method: 'GET',
        credentials: 'include',
        types: [
          { type: KITE_LOGOUT, meta: { enhancements: [ LOG_STATUS ] } },
          KITE_LOGOUT_SUCCESS,
          {
            type: KITE_LOGOUT_ERROR,
            payload: {
              message: shouldDisplayModalOnError ? 'showModal' : null
            }
          }
        ]
      }
    })
  }
}
