// @flow
import React from 'react'
import Radium from 'radium'
import FontAwesome from 'react-fontawesome'
import type { ComponentType } from 'react'

type Props = {|
  labelText?: string,
  labelStyle?: Object,
  inputStyle?: Object,
  inputField?: Object,
  required?: boolean,
  valid?: boolean,
  validate?: boolean,
  msg?: string,
  className?: string,
  dataTest?: string,
  htmlFor?: string
|}

class FormItem extends React.Component<Props> {
  getStyles() {
    return {
      labelStyle: {
        marginTop: '15px',
        padding: '0.25em 0.5em 0.5em 0',
        display: 'block'
      },
      labelTxtAndIcon: {
        alignItems: 'center',
        display: 'flex'
      },
      required: {
        color: 'var(--accent-red)',
        marginLeft: '0.5em',
        fontSize: '0.75rem' // typography.sizes.small
      },
      valid: {
        marginLeft: '0.5em',
        color: 'var(--accent-blue-dark)',
        fontSize: 'var(--sizes-small)'
      },
      invalid: {
        marginLeft: '0.5em',
        color: 'var(--accent-red)',
        fontSize: 'var(--sizes-small)'
      },
      msg: {
        marginLeft: '0.5em',
        color: 'var(--accent-red)',
        fontSize: 'var(--sizes-small)'
      }
    }
  }

  render(): React$Node {
    const styles = this.getStyles()
    const {
      valid,
      msg,
      validate,
      required,
      labelText,
      inputField,
      labelStyle,
      className,
      dataTest,
      inputStyle,
      htmlFor
    } = this.props

    return (
      <div data-test={dataTest}>
        {labelText && (
          <label style={[styles.labelStyle, labelStyle]} htmlFor={htmlFor}>
            <span style={styles.labelTxtAndIcon}>
              {labelText}
              {required && !validate && <FontAwesome name={'asterisk'} style={styles.required} />}
              {validate && (
                <FontAwesome name={valid ? `check` : `times`} style={valid ? styles.valid : styles.invalid} />
              )}
              {validate && !valid && msg && <span style={styles.msg}>{msg}</span>}
            </span>
          </label>
        )}
        <div style={inputStyle} className={className}>
          {inputField}
        </div>
      </div>
    )
  }
}

export default (Radium(FormItem): ComponentType<Props>)
