// @flow
import React from 'react'
import { NavLink, Route } from 'react-router-dom'

import DomainsController from './Domains'
import AccountController from './Account'
import ConfigureController from './Configure'
import styles from './AppSettings.module.css'
import ErrorView from '../../../Apps/ErrorView'
import StripeBanner from '../../../../components/StripeBanner'
import configureAppStyles from '../../ConfigureApp.module.css'
import { APP_SETTING_URLS, STRIPE_CONNECT_ONBOARDING_STATES } from '../../../Apps/constants'
import type { AppSettingsType, Match, MatchAppType, StripeConnectOnboardingState, User } from '../../../../types'

type Props = {|
  appSettings: AppSettingsType,
  appTypeSelected: MatchAppType,
  disableSaveConfig: boolean,
  hasError: boolean,
  match: Match,
  stripeOnboardingState: StripeConnectOnboardingState,
  user: User,
  onAddDomain: (domain: string) => void,
  onDisableSaveConfig: () => void,
  onUpdateAppConfig: (updatedConfig: $Shape<AppSettingsType>) => void,
  onUpdateDomains: (domains: {| [key: string]: {| is_validated: boolean |} |}) => void
|}

class AppSettings extends React.Component<Props> {
  render (): React$Node {
    const {
      appSettings,
      appTypeSelected,
      disableSaveConfig,
      hasError,
      match,
      stripeOnboardingState,
      user
    } = this.props

    if (hasError) {
      return <ErrorView message="An error occurred whilst getting app settings." />
    }

    const navItems = [
      { title: 'Configure', path: `${this.props.match.url}/${APP_SETTING_URLS.DEFAULT}` },
      { title: 'DNS Settings', path: `${this.props.match.url}/${APP_SETTING_URLS.DOMAINS}` },
      { title: 'Account & Payment', path: `${this.props.match.url}/${APP_SETTING_URLS.ACCOUNT}` }
    ]

    const showStripeBanner = stripeOnboardingState === STRIPE_CONNECT_ONBOARDING_STATES.NOT_STARTED

    const content = (
      <>
        {
          showStripeBanner &&
            <StripeBanner
              countryCode={user.company.countryCode}
              email={user.email}
              stripeOnboardingState={stripeOnboardingState}
            />
        }
        <Route
          exact
          path={`${this.props.match.url}/${APP_SETTING_URLS.DEFAULT}`}
          render={() => (
            <ConfigureController
              appSettings={appSettings}
              appTypeSelected={appTypeSelected}
              disableSaveConfig={disableSaveConfig}
              match={match}
              user={user}
              onDisableSaveConfig={() => this.props.onDisableSaveConfig()}
              onUpdateAppConfig={(updatedConfig) => this.props.onUpdateAppConfig(updatedConfig)} />
          )} />
        <Route
          path={`${this.props.match.url}/${APP_SETTING_URLS.DOMAINS}`}
          render={() => (
            <DomainsController
              appSettings={appSettings}
              appTypeSelected={appTypeSelected}
              match={match}
              user={user}
              onAddDomain={(domain: string) => this.props.onAddDomain(domain)}
              onUpdateDomains={(domains: {| [key: string]: {| is_validated: boolean |} |}) => this.props.onUpdateDomains(domains)} />
          )} />
        <Route
          path={`${this.props.match.url}/${APP_SETTING_URLS.ACCOUNT}`}
          render={() => (
            <AccountController
              stripeOnboardingState={stripeOnboardingState}
              user={user} />
          )} />
      </>
    )

    return (
      <>
        <section className={styles.configureAppContainer}>
          <div className={styles.settingsContainer}>
            <div>
              <div className={styles.settingsNavContainer}>
                {navItems.map((navItem) => (
                  <NavLink exact key={navItem.title} className={configureAppStyles.navItem} activeClassName={configureAppStyles.navItemActive} to={navItem.path}>
                    {navItem.title}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className={styles.settingsSpacer}></div>
            <div className={styles.appSettingsContainer}>
              {content}
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default AppSettings
