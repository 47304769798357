export const LOG_IN = 'LOG_IN'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_ERROR = 'LOG_IN_ERROR'

export const ACTION_TYPES__LOG_IN = [LOG_IN, LOG_IN_SUCCESS, LOG_IN_ERROR]

export function logInStart() {
  return {
    type: LOG_IN
  }
}

export function logInSuccess() {
  return {
    type: LOG_IN_SUCCESS
  }
}

export function logInError(statusCode?: number) {
  return {
    type: LOG_IN_ERROR,
    message: getLogInErrorMessage(statusCode),
    statusCode
  }
}

export function getLogInErrorMessage(statusCode?: number) {
  if (statusCode === 401) {
    return 'The user name or password is incorrect.'
  }
  return 'Error communicating with server - please try again.'
}
