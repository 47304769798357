import FormItem from '../../FormItem'
import SelectField from '../../SelectField'

interface SelectFieldOption {
  value: string
  name: string
}

export function OptionDropdown({
  label,
  options,
  selected,
  setSelected
}: {
  label: string
  options: SelectFieldOption[]
  selected: string
  setSelected: (selected: string) => void
}) {
  return (
    <FormItem
      labelText={label}
      inputField={<BuildSelectField label={label} selected={selected} options={options} setSelected={setSelected} />}
    />
  )
}

function BuildSelectField({
  label,
  selected,
  options,
  setSelected
}: {
  label: string
  selected: string
  options: SelectFieldOption[]
  setSelected: (value: string) => void
}) {
  return (
    <SelectField
      dataTest={`option-dropdown-${label}`}
      id={label}
      value={selected}
      onChange={(e) => setSelected(e.target.value)}
    >
      {options.map((item) => (
        <SelectFieldOption key={item.name} value={item} />
      ))}
    </SelectField>
  )
}

function SelectFieldOption({ value }: { value: SelectFieldOption }) {
  return (
    <option key={value.name} value={value.value}>
      {value.name}
    </option>
  )
}
