import { useEffect, useRef } from 'react'
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'

import NotFound from '../NotFound'
import EmptyState from '../EmptyState'
import SupportLink from '../SupportLink'
import ClipboardCopy from '../ClipboardCopy'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { SearchResultItem, SkeletonSearchItem } from '../SelectProduct/components'
import { ProductAdditionalDataInterface, SelectedProductInterface } from '../SelectProduct'
import { useProductSearch, useMerchantPreferences, useProducts, useSplitToggle } from '../../hooks'
import { generateMultiSkuProductSearchQuery } from '../../helpers/generateMultiSkuProductSearchQuery.helper'

export interface SavedItemsPropsInterface {
  countryCode: string
  primaryActionText?: string
  selectingSkuName?: string
  showCountryPicker?: boolean
  showMetaData?: boolean
  onSelectProduct?: (selectedProduct: SelectedProductInterface) => void
  onSelectProductWithAdditionalData?: (
    selectedProduct: SelectedProductInterface,
    additionalProductData: ProductAdditionalDataInterface
  ) => void
}

export default function SavedItems(props: SavedItemsPropsInterface) {
  const { merchantPreferences, isLoadingMerchantPreferences, error } = useMerchantPreferences()

  if (error) {
    return (
      <SavedItemsError
        code={`${error.responseBodyJson?.traceParent ?? 0}-${error.status ?? 0}`}
        message={error.message}
      />
    )
  }

  if (isLoadingMerchantPreferences || !merchantPreferences) {
    return <SavedItemsLoading />
  }

  return <SearchResults {...props} />
}

function SearchResults({
  countryCode,
  primaryActionText,
  selectingSkuName,
  showCountryPicker,
  showMetaData,
  onSelectProduct,
  onSelectProductWithAdditionalData
}: SavedItemsPropsInterface) {
  const { merchantPreferences } = useMerchantPreferences()

  if (!merchantPreferences) {
    throw Error('No merchant preferences')
  }

  const starredSkus = merchantPreferences.starredProducts.map((starredProduct) => starredProduct.skuName)

  const {
    searchResults,
    isLoading: isLoadingSearchResults,
    error: searchResultsFetchError
  } = useProductSearch({
    countryCode,
    query: generateMultiSkuProductSearchQuery(starredSkus)
  })
  const ref = useRef<LoadingBarRef>(null)
  const searchResultSkus = searchResults?.map((searchResult) => searchResult.sku)
  const { isValidatingMerchantPreferences } = useMerchantPreferences()
  const { splitIsOn: isCostedAttributesOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { products, isLoadingProducts, productsFetchError } = useProducts(
    isCostedAttributesOn ? searchResultSkus : undefined,
    {
      config: { revalidateOnFocus: false }
    }
  )

  useEffect(() => {
    if (isValidatingMerchantPreferences) {
      ref.current?.continuousStart(30)
    } else {
      ref.current?.complete()
    }
  }, [isValidatingMerchantPreferences])

  if (searchResultsFetchError) {
    return <SavedItemsError code={searchResultsFetchError.status ?? 0} message={searchResultsFetchError.message} />
  }

  if (productsFetchError) {
    return <SavedItemsError code={`CSRFE-${productsFetchError.status ?? '0'}`} message={productsFetchError.message} />
  }

  if (starredSkus.length === 0) {
    return <EmptyState className="h-full">Items you save will appear here</EmptyState>
  }

  if (isLoadingSearchResults || !searchResults || isLoadingProducts) {
    return <SavedItemsLoading />
  }

  if (searchResults?.length === 0 || !searchResultSkus) {
    return <EmptyState className="h-full">Items you save will appear here</EmptyState>
  }

  const filteredSearchResults = searchResults.filter((searchResult) => starredSkus.includes(searchResult.sku))

  return (
    <div className="relative">
      <LoadingBar
        ref={ref}
        color="#4630d4"
        className="will-change-transform"
        containerStyle={{ position: 'sticky', top: 0 }}
        shadow={false}
      />

      <ul className="border border-gray-200">
        {filteredSearchResults.map((searchResult) => {
          const product = products?.[searchResult.sku]

          return (
            <SearchResultItem
              key={searchResult.sku}
              countryCode={countryCode}
              product={product}
              primaryActionText={primaryActionText}
              searchResult={searchResult}
              searchResultSkus={searchResultSkus}
              selectingSkuName={selectingSkuName}
              showCountryPicker={showCountryPicker}
              showMetaData={showMetaData}
              onSelectProduct={onSelectProduct}
              onSelectProductWithAdditionalData={onSelectProductWithAdditionalData}
            />
          )
        })}
      </ul>
    </div>
  )
}

function SavedItemsError({ code, message }: { code: string | number; message: string }) {
  return (
    <div className="mt-12 flex w-full justify-center">
      <NotFound
        heading="Failed to load saved items"
        body={
          <p className="break-words text-center">
            An error occurred while loading saved items. Please try again later and{' '}
            <SupportLink>contact us</SupportLink> if this issue persists ({message} - code {code}{' '}
            <ClipboardCopy showText={false} text={code.toString()} />)
          </p>
        }
      >
        <></>
      </NotFound>
    </div>
  )
}

function SavedItemsLoading() {
  return (
    <ul className="border border-gray-200">
      {Array.from({ length: 3 }, (_, i) => i).map((value) => (
        <SkeletonSearchItem key={value} />
      ))}
    </ul>
  )
}
