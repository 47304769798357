import { useEffect } from 'react'

import ImageDetail from '../../components/ImageDetail'
import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from '../../constants'

export default function ImageDetailPage() {
  useEffect(() => {
    document.title = 'Image Detail | Prodigi'
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  useEffect(() => {
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.IMAGE_LIBRARY, 'Image Detail')
  }, [])

  return (
    <main className="tailwind">
      <ImageDetail />
    </main>
  )
}
