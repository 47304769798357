// @flow
import type { ChildrenArray } from 'react'
import React, { Component } from 'react'
import { appInsights } from '../../configureStore'
import type { ErrorInfo, Dictionary } from '../../types'
import type { RouterHistory, Match, Location, HistoryAction } from 'react-router'

import styles from './ErrorBoundary.module.css'

type ErrorBoundaryProps = {|
  match: Match,
  location: Location,
  children: ChildrenArray<*>,
  history: RouterHistory,
  logLe: (message: string, details: Dictionary<string>) => void
|}

type ErrorBoundaryState = {|
  hasError: boolean,
  removeHistoryListener: ?() => void
|}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, removeHistoryListener: null }

  componentDidMount () {
    this.addHistoryListener()
  }

  componentWillUnmount () {
    this.state.removeHistoryListener && this.state.removeHistoryListener()
  }

  addHistoryListener: (() => void) = () => {
    const removeHistoryListener = this.props.history.listen(this.resetErrorStatus)
    this.setState({ removeHistoryListener })
  }

  resetErrorStatus: ((location: any, action: any) => void) = (location: Location, action: HistoryAction) => {
    this.setState({ hasError: false })
  }

  static getDerivedStateFromError (_: Error): {| hasError: boolean |} {
    return { hasError: true }
  }

  componentDidCatch (error: Error, info: ErrorInfo) {
    console.error(error)
    this.trackExceptionWithAppInsights(error, info)
    this.props.logLe(error.message, {
      location: window.location.href,
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      info: JSON.stringify(info)
    })
  }

  trackExceptionWithAppInsights: ((error: Error, info: ErrorInfo) => void) = (error: Error, info: ErrorInfo) => {
    if (appInsights) {
      error.message = `${error.message}, ${JSON.stringify(info)}`
      appInsights.trackException({exception: error})
    }
  }

  render (): ChildrenArray<*> {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <h3>An error occurred. Please refresh the page to try again, and <a href='https://www.prodigi.com/about/#contact'> let us know </a> if it continues to happen</h3>
        </div>
      )
    }

    return this.props.children
  }
}
