// @flow
import type { V4OrderCreationErrorResponse, V4OrderCreationResponse, CreationFailure, Dictionary } from '../../types'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { DEFAULT_CSV_ERROR_MESSAGE, ORDER_CREATION_ERROR_CODE, PAYMENT_AUTH_ERROR_MESSAGE } from '../../data/errorMessages'
import { values, entries } from '../dictionary'
import { BAD_REQUEST } from '../../data/statusCodes'
import { mapV4AttributeNameToUserFormat } from '../attributes'
import { toTitleCase } from '../string'
import productAttributeFormat from '../productAttributeFormat'
import { isPaymentRequired } from '../isPaymentRequired'

export function mapV4OrderCreationErrorMessage (
  apiResponse: V4OrderCreationErrorResponse | V4OrderCreationResponse,
  productCategoriesBySku: Dictionary<string>,
  skusInSubmissionOrder: string[],
  statusCode?: ?number
): string {
  try {
    if (isPaymentRequired(apiResponse)) {
      return PAYMENT_AUTH_ERROR_MESSAGE
    } else if (apiResponse.outcome === V4_API_ACTION_OUTCOME.VALIDATION_FAILED) {
      const errorResponse: V4OrderCreationErrorResponse = (apiResponse: any)

      return mapValidationFailureMessage(errorResponse.failures, productCategoriesBySku, skusInSubmissionOrder)
    } else if (statusCode === BAD_REQUEST && apiResponse.failures && values(apiResponse.failures).length) {
      return values(apiResponse.failures)[0].code
    } else {
      return DEFAULT_CSV_ERROR_MESSAGE
    }
  } catch (error) {
    console.error(error)
    return DEFAULT_CSV_ERROR_MESSAGE
  }
}

function mapValidationFailureMessage (
  allFailures: Dictionary<CreationFailure[]>,
  productCategoriesBySku: Dictionary<string>,
  skusInSubmissionOrder: string[]
): string {
  return entries(allFailures).reduce((fullMessage, [failureId, orderItemFailures]) => {
    const failure = orderItemFailures[0]
    const orderItemCategory = getOrderItemCategory(
      failureId,
      productCategoriesBySku,
      skusInSubmissionOrder
    )

    if (failure.code === ORDER_CREATION_ERROR_CODE.MUST_BE_IN_ALLOWED_VALUES) {
      return mapInvalidAttributeError(failure, fullMessage, orderItemCategory)
    }

    return fullMessage
  }, '')
}

function getOrderItemCategory (
  failureId: string,
  productCategoriesBySku: Dictionary<string>,
  skusInSubmissionOrder: string[]
) {
  const orderItemIdRegexMatch: any = failureId.match(/items\[(?<id>\d+)\]/)
  const orderItemIndex = orderItemIdRegexMatch.groups.id
  const orderItemSku = skusInSubmissionOrder[orderItemIndex]

  return productCategoriesBySku[orderItemSku]
}

function mapInvalidAttributeError (
  failure: CreationFailure,
  previousErrorMessage: string,
  productCategory: string
): string {
  const providedAttributeValue = failure.providedValue.value
  const providedAttributeName = failure.providedValue.key
  const mappedAttributeName = mapV4AttributeNameToUserFormat(providedAttributeName, productCategory)
  const allowedValues = failure.allowedValues.values

  const suppliedAttributeMessagePart = buildSuppliedAttributeMessagePart(providedAttributeValue, mappedAttributeName)
  const validValuesMessagePart = buildValidAttributeValuesMessagePart(allowedValues)
  const fullInvalidAttributeErrorMessage = suppliedAttributeMessagePart + ' ' + validValuesMessagePart

  return previousErrorMessage
    ? previousErrorMessage + ' ' + fullInvalidAttributeErrorMessage
    : fullInvalidAttributeErrorMessage
}

function buildSuppliedAttributeMessagePart (attributeName: string, attributeValue: string): string {
  const capitalizedAttributeName = toTitleCase(attributeName)
  return `Value of '${capitalizedAttributeName}' supplied for attribute '${attributeValue}' isn't in the list of supported values.`
}

function buildValidAttributeValuesMessagePart (values: string[]): string {
  return 'Valid values are: ' + values.reduce((validValueAcc, validValue, index) => {
    const capitalizedAttributeValue = productAttributeFormat(validValue)

    const delimiter = index === values.length - 2
      ? ', and '
      : ', '
    return validValueAcc + capitalizedAttributeValue + delimiter
  }, '').slice(0, -2) + '.'
}
