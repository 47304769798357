import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FetchErrorInterface,
  OMSErrorResponseInterface,
  OrderDetailItemInterface,
  StatusType
} from '../../../interfaces'
import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { ProductDetailPrintAreaInterface, useOrderDetail } from '../../../hooks'
import ImageEditorModal from '../../ImageEditorModal'
import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { LINE_ITEM_TOASTS } from '../constants/lineItemToasts.const'
import { saveLineItemData } from '../helpers/saveLineItemData.helper'
import { LineItemEditorDataType } from './LineItemProductData.component'

interface LineItemImageEditorModalPropsInterface {
  isImageEditorModalOpen: boolean
  lineItem: OrderDetailItemInterface
  lineItemEditorData: LineItemEditorDataType
  printAreas?: Record<string, ProductDetailPrintAreaInterface>
  validSelectedPrintArea: string
  onImageEditorModalOpenChange: (isOpen: boolean) => void
  onLineItemEditorDataChange: (newLineItemData: LineItemEditorDataType) => void
  onSaveImageEditsSuccess: () => void
}

export function LineItemImageEditorModal({
  isImageEditorModalOpen,
  lineItem,
  lineItemEditorData,
  printAreas,
  validSelectedPrintArea,
  onImageEditorModalOpenChange,
  onLineItemEditorDataChange,
  onSaveImageEditsSuccess
}: LineItemImageEditorModalPropsInterface) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  const [saveImageEditStatus, setSaveImageEditStatus] = useState<StatusType>('idle')

  useEffect(() => {
    if (!isImageEditorModalOpen) {
      setSaveImageEditStatus('idle')
      toast.dismiss(LINE_ITEM_TOASTS.ERROR)
    }
  }, [isImageEditorModalOpen])

  async function handleSaveImageEdits(printAreasArtworkData: PrintAreasArtworkDataType, newSelectedPrintArea: string) {
    setSaveImageEditStatus('loading')
    toast.dismiss(LINE_ITEM_TOASTS.ERROR)

    try {
      const newAssetRequirementData =
        printAreas &&
        Object.entries(printAreas).reduce(
          (
            assetRequirementDataAcc: Record<string, { required: boolean }>,
            [productPrintAreaName, productPrintAreaData]
          ) => {
            return {
              ...assetRequirementDataAcc,
              [productPrintAreaName]: { required: productPrintAreaData.required }
            }
          },
          {}
        )

      const newLineItemData: LineItemEditorDataType = {
        ...lineItemEditorData,
        assetRequirementData: newAssetRequirementData || lineItemEditorData?.assetRequirementData,
        artworkData: printAreasArtworkData,
        selectedPrintArea: newSelectedPrintArea
      }

      await saveLineItemData({ lineItemId: lineItem.id, newLineItemData, orderId })
      await mutateOrderDetails()

      onLineItemEditorDataChange(newLineItemData)
      setSaveImageEditStatus('success')
      onSaveImageEditsSuccess()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code OMS-${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'An error occurred while saving image',
        id: LINE_ITEM_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setSaveImageEditStatus('error')
    }
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  return (
    <>
      <ImageEditorModal
        attributes={lineItemEditorData?.selectedProductData?.attributes ?? undefined}
        countryCode={orderDetails.recipient.address.countryCode}
        isSaving={saveImageEditStatus === 'loading'}
        open={isImageEditorModalOpen}
        enableImageLibraryThumbnailRefresh={true}
        printAreasArtworkData={lineItemEditorData?.artworkData ?? undefined}
        selectedPrintArea={validSelectedPrintArea ?? undefined}
        sku={lineItemEditorData?.selectedProductData?.sku as string}
        setOpen={onImageEditorModalOpenChange}
        onCancel={() => onImageEditorModalOpenChange(false)}
        onOpenImageLibrary={() => window.analytics.track('Image library opened: Edit order')}
        onSave={handleSaveImageEdits}
      />

      {saveImageEditStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
