import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export default function EmptyState({ children, className = '' }: { children: ReactNode; className?: string }) {
  return (
    <div
      className={twMerge(
        'border bg-white bg-cover bg-center py-16 px-8 text-center text-sm md:py-32 md:text-base',
        className
      )}
      style={{ backgroundImage: `url(/img/backgrounds/empty-state.svg)` }}
    >
      {children}
    </div>
  )
}
