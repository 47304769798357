// @flow
import type { Element } from 'react'
import React from 'react'
import Alert from '../../../../components/Alert'
import styles from './CsvSubmission.module.css'
import { DEFAULT_ERROR_MESSAGE } from '../../../../data/errorMessages'

type Props = {|
  className: string,
  submittedOrderCount: number,
  message: string
|}

export default function CsvSubmissionError ({submittedOrderCount, className, message}: Props): Element<"div"> {
  return (
    <div className={className}>
      <Alert className={styles.submissionStatusHeading}>The CSV order submission failed.</Alert>
      {message && message !== DEFAULT_ERROR_MESSAGE
        ? <p>{message}</p>
        : (
          <p>
            {submittedOrderCount === 0 ? 'No' : submittedOrderCount} {submittedOrderCount === 1 ? 'order has' : 'orders have'} been submitted at this time.
            <span> Please review the details below and re-submit your order with the necessary corrections.</span>
          </p>
        )}
    </div>
  )
}
