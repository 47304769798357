import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import {
  ProductsDataInterface,
  useMerchantPreferences,
  usePricing,
  useProductDetails,
  useSplitToggle,
  useTemplateService,
  useUser
} from '../../../hooks'
import Button from '../../Button'
import FormItem from '../../FormItem'
import Skeleton from '../../Skeleton'
import { createToast } from '../../Toast'
import SelectField from '../../SelectField'
import SupportLink from '../../SupportLink'
import OverlayPortal from '../../OverlayPortal'
import ClipboardCopy from '../../ClipboardCopy'
import { SaveItemToggle } from '../../SavedItems'
import ProductDetailsModal from '../../ProductDetailsModal'
import { CostedAttributes } from './CostedAttributes.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { SearchResultMetaData } from './SearchResultMetaData.component'
import { ItemMetaDataInterface, ProductInterface, StatusType } from '../../../interfaces'
import { formatAttrNameForCategory, formatPrice, productAttributeFormat } from '../../../helpers'
import { ProductAdditionalDataInterface, SelectedProductInterface } from '../SelectProduct.component'

const SELECT_PRODUCT_ERROR_TOAST_ID = 'prodigiSelectProductWithDataError'

export function SearchResultItem({
  countryCode,
  defaultSelectedAttributes = {},
  defaultSelectedMetaData,
  primaryActionText,
  product,
  searchResult,
  searchResultSkus,
  selectingSkuName,
  showCountryPicker = false,
  showMetaData = false,
  onSelectProduct,
  onSelectProductWithAdditionalData
}: {
  countryCode: string
  defaultSelectedAttributes?: Record<string, string>
  defaultSelectedMetaData?: ItemMetaDataInterface
  primaryActionText?: string
  // TODO: Make product prop required when removing split COSTED_ATTRIBUTES: 'dashboard_costed_attributes'
  product?: ProductsDataInterface[string] | null
  searchResult: ProductInterface
  searchResultSkus: string[]
  selectingSkuName?: string
  showCountryPicker?: boolean
  showMetaData?: boolean
  onSelectProduct?: (selectedProduct: SelectedProductInterface) => void
  onSelectProductWithAdditionalData?: (
    selectedProduct: SelectedProductInterface,
    additionalProductData: ProductAdditionalDataInterface
  ) => void
}) {
  const { user } = useUser()
  const { isLoading: isLoadingPricing, priceResults } = usePricing({
    countryCode,
    skus: searchResultSkus,
    config: { revalidateOnFocus: false }
  })
  const { splitIsOn: isCostedAttributesOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { splitIsOn: isPhotobookMetaDataSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.PHOTOBOOK_METADATA })

  const costedAttributes = product?.costedAttributes ?? {}

  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false)
  const [selectedAttributes, setSelectedAttributes] = useState(() => {
    const validatedDefaultAttributes: Record<string, string> = {}
    Object.entries(defaultSelectedAttributes).forEach(([attributeName, attributeValue]) => {
      const isValidAttribute = searchResult.attributes?.[attributeName]?.includes(attributeValue)
      const costedAttributeValues = costedAttributes[attributeName]?.options.map(
        (costedAttributeOption) => costedAttributeOption.value
      )
      const isValidCostedAttribute = costedAttributeValues?.includes(attributeValue)

      if (isValidAttribute || isValidCostedAttribute) {
        validatedDefaultAttributes[attributeName] = attributeValue
      }
    })
    return validatedDefaultAttributes
  })

  const [selectedMetaData, setSelectedMetaData] = useState<ItemMetaDataInterface | undefined>(() => {
    if (!isPhotobookMetaDataSplitOn || !showMetaData) {
      return undefined
    }

    return defaultSelectedMetaData
  })

  useEffect(() => {
    return () => {
      toast.dismiss(SELECT_PRODUCT_ERROR_TOAST_ID)
    }
  }, [])

  if (!user) {
    throw Error('No user')
  }

  const selectableAttributes = Object.entries(searchResult.attributes).filter(
    ([, attributeOptions]) => attributeOptions.length > 1
  )
  const selectableCostedAttributes = Object.entries(costedAttributes)

  const hasSelectableAttributes =
    Object.keys(selectableAttributes).length > 0 || Object.keys(costedAttributes).length > 0

  const hasSelectedAllAttributes = selectableAttributes.every(([attributeName]) =>
    Boolean(selectedAttributes[attributeName])
  )
  const hasSelectedAllCostedAttributes = selectableCostedAttributes.every(([costedAttributeName]) =>
    Boolean(selectedAttributes[costedAttributeName])
  )
  const hasSelectedAllRequiredAttributes = hasSelectedAllAttributes && hasSelectedAllCostedAttributes

  const priceData = priceResults?.prices.find(
    (priceDetail) => priceDetail.sku.toUpperCase() === searchResult.sku.toUpperCase()
  )

  function handleSelectProduct() {
    const sku = searchResult.sku.toUpperCase()
    if (hasSelectableAttributes) {
      onSelectProduct?.({ searchResultData: searchResult, selectedAttributes, selectedMetaData, sku })
    } else {
      onSelectProduct?.({
        searchResultData: searchResult,
        selectedAttributes: null,
        selectedMetaData,
        sku
      })
    }
  }

  return (
    <li
      className="group flex flex-col gap-4 border-b border-gray-200 bg-white p-8 py-10 last:border-b-0 sm:flex-row sm:gap-6"
      key={searchResult.sku}
    >
      <div className="flex flex-col gap-4">
        <div className="max-w-[60ch] lg:text-lg">
          <strong data-test="product-name">{searchResult.name}</strong>{' '}
          <SaveItemToggleContainer sku={searchResult.sku} />
        </div>

        <div className="mb-2 text-sm text-gray-700" data-test="product-sku">
          <ClipboardCopy text={searchResult.sku} />
        </div>

        <div className="mt-2 flex flex-wrap gap-6">
          {selectableAttributes.length > 0 && (
            <>
              {selectableAttributes.map(([attributeName, attributeOptions]) => (
                <FormItem
                  labelText={formatAttrNameForCategory(attributeName, searchResult.category)}
                  labelClassName="mt-0"
                  key={`${searchResult.sku}-${attributeName}`}
                  inputField={
                    <SelectField
                      dataTest={`attribute-select-${attributeName}`}
                      value={selectedAttributes[attributeName] ?? ''}
                      onChange={(event) =>
                        setSelectedAttributes({ ...selectedAttributes, [attributeName]: event.target.value })
                      }
                    >
                      <option disabled value="">
                        Select {formatAttrNameForCategory(attributeName, searchResult.category).toLowerCase()}
                      </option>

                      {attributeOptions.map((attributeOption) => {
                        return (
                          <option key={attributeOption} value={attributeOption}>
                            {productAttributeFormat(attributeOption)}
                          </option>
                        )
                      })}
                    </SelectField>
                  }
                />
              ))}
            </>
          )}

          {selectableCostedAttributes.length > 0 && (
            <CostedAttributes
              selectedAttributes={selectedAttributes}
              selectableCostedAttributes={selectableCostedAttributes}
              sku={searchResult.sku}
              onChangeCostedAttribute={({ costedAttributeKey, costedAttributeValue }) =>
                setSelectedAttributes({ ...selectedAttributes, [costedAttributeKey]: costedAttributeValue })
              }
            />
          )}

          {isPhotobookMetaDataSplitOn && showMetaData && (
            <SearchResultMetaData
              selectedMetaData={selectedMetaData}
              searchResult={searchResult}
              onChangeMetaData={(newMetaData) => {
                setSelectedMetaData({ ...selectedMetaData, ...newMetaData })
              }}
            />
          )}
        </div>

        {!product && isCostedAttributesOn && (
          <div className="text-red-500">
            Could not retrieve details. Try again later or{' '}
            <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists.
          </div>
        )}
      </div>

      <div className="flex flex-shrink-0 flex-col gap-4 sm:ml-auto sm:flex-row">
        <div className="whitespace-nowrap py-4" data-test="product-price">
          {isLoadingPricing ? (
            <div>
              <Skeleton className="w-[92px]" />
            </div>
          ) : (
            <div>
              {priceData
                ? formatPrice(priceData.sku, priceData.currency, priceData.price)
                : searchResult.formattedPrice}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4">
          {Boolean(onSelectProduct) && !onSelectProductWithAdditionalData && (
            <Button
              dataTest="select-button"
              isLoading={selectingSkuName === searchResult.sku}
              disabled={!hasSelectedAllRequiredAttributes || (isCostedAttributesOn ? !product : false)}
              onClick={handleSelectProduct}
            >
              {primaryActionText ?? 'Select'}
            </Button>
          )}

          {Boolean(onSelectProductWithAdditionalData) && (
            <SelectProductWithDataButton
              disabled={isCostedAttributesOn ? !product : false}
              hasSelectableAttributes={hasSelectableAttributes}
              hasSelectedAllRequiredAttributes={hasSelectedAllRequiredAttributes}
              primaryActionText={primaryActionText}
              searchResult={searchResult}
              selectedAttributes={selectedAttributes}
              selectedMetaData={selectedMetaData}
              selectingSkuName={selectingSkuName}
              sku={searchResult.sku}
              onSelectProductWithAdditionalData={onSelectProductWithAdditionalData}
            />
          )}

          <Button variant="secondary" onClick={() => setIsProductDetailsModalOpen(true)}>
            View details
          </Button>
        </div>
      </div>

      <ProductDetailsModal
        actions={<></>}
        countryCode={countryCode}
        currencyCode={user.company.currencyCode}
        description={searchResult.name}
        sku={searchResult.sku}
        open={isProductDetailsModalOpen}
        showCountryPicker={showCountryPicker}
        setOpen={setIsProductDetailsModalOpen}
      />
    </li>
  )
}

function SaveItemToggleContainer({ sku }: { sku: string }) {
  const { merchantPreferences, merchantPreferencesResponse, isLoadingMerchantPreferences } = useMerchantPreferences()

  if (isLoadingMerchantPreferences || !merchantPreferences || !merchantPreferencesResponse) {
    return null
  }

  return (
    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <SaveItemToggle key={merchantPreferencesResponse.traceParent} sku={sku} />
    </motion.span>
  )
}

function SelectProductWithDataButton({
  disabled,
  hasSelectableAttributes,
  hasSelectedAllRequiredAttributes,
  primaryActionText,
  searchResult,
  selectedAttributes,
  selectedMetaData,
  selectingSkuName,
  sku,
  onSelectProductWithAdditionalData
}: {
  disabled: boolean
  hasSelectableAttributes: boolean
  hasSelectedAllRequiredAttributes: boolean
  primaryActionText?: string
  searchResult: ProductInterface
  selectedAttributes: Record<string, string>
  selectedMetaData?: ItemMetaDataInterface
  selectingSkuName?: string
  sku: string
  onSelectProductWithAdditionalData?: (
    selectedProduct: SelectedProductInterface,
    additionalProductData: ProductAdditionalDataInterface
  ) => void
}) {
  const [fetchDetailsStatus, setFetchDetailsStatus] = useState<StatusType>('idle')

  const { productDetails, error: productDetailsFetchError } = useProductDetails({
    sku: fetchDetailsStatus === 'loading' ? sku : undefined,
    config: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  })
  const {
    templates,
    error: templatesFetchError,
    url: templatesRequestUrl
  } = useTemplateService({
    sku: fetchDetailsStatus === 'loading' ? sku : undefined,
    attributes: selectedAttributes,
    config: { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  })

  useEffect(() => {
    if (!productDetails || !templates) {
      return
    }

    const productDetailsSku = productDetails.product.sku.toUpperCase()
    if (productDetailsSku !== sku || !templatesRequestUrl?.toUpperCase().includes(sku)) {
      return
    }

    if (hasSelectableAttributes) {
      onSelectProductWithAdditionalData?.(
        { searchResultData: searchResult, selectedAttributes, selectedMetaData, sku: productDetailsSku },
        { productDetails, templates }
      )
    } else {
      onSelectProductWithAdditionalData?.(
        {
          searchResultData: searchResult,
          selectedAttributes: null,
          selectedMetaData,
          sku: productDetailsSku
        },
        { productDetails, templates }
      )
    }
    setFetchDetailsStatus('success')
  }, [
    hasSelectableAttributes,
    productDetails,
    searchResult,
    selectedAttributes,
    selectedMetaData,
    sku,
    templates,
    templatesRequestUrl,
    onSelectProductWithAdditionalData
  ])

  useEffect(() => {
    if (!templatesFetchError) {
      return
    }
    createToast({
      id: SELECT_PRODUCT_ERROR_TOAST_ID,
      heading: 'An error occurred while selecting product',
      content: 'Please try again later or contact support if the issue persists',
      footer: (
        <span className="break-words" style={{ wordBreak: 'break-word' }}>
          TSFE-{sku}-{templatesFetchError.status ?? '0'}
        </span>
      ),
      duration: Infinity,
      type: 'error-with-close'
    })
    setFetchDetailsStatus('error')
  }, [templatesFetchError, sku])

  useEffect(() => {
    if (!productDetailsFetchError) {
      return
    }
    createToast({
      id: SELECT_PRODUCT_ERROR_TOAST_ID,
      heading: 'An error occurred while selecting product',
      content: 'Please try again later or contact support if the issue persists',
      footer: (
        <span className="break-words" style={{ wordBreak: 'break-word' }}>
          {productDetailsFetchError.responseBodyJson?.traceParent ?? 'PDFE'}-{sku}-
          {productDetailsFetchError.status ?? '0'}
        </span>
      ),
      duration: Infinity,
      type: 'error-with-close'
    })
    setFetchDetailsStatus('error')
  }, [productDetailsFetchError, sku])

  return (
    <>
      <Button
        dataTest={`select-product-with-data-button-${sku}`}
        isLoading={fetchDetailsStatus === 'loading' || selectingSkuName === searchResult.sku}
        disabled={!hasSelectedAllRequiredAttributes || disabled}
        onClick={() => {
          toast.dismiss(SELECT_PRODUCT_ERROR_TOAST_ID)
          setFetchDetailsStatus('loading')
        }}
      >
        {primaryActionText ?? 'Select'}
      </Button>

      {fetchDetailsStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
