// @flow
import type { CustomerAddress } from '../../../types'
import React, { PureComponent } from 'react'
import countryFormat from '../../../helpers/countryFormat'
import Page from '../../../components/Page'
import styles from './OrderAddress.module.css'
import AddressForm from '../../../components/AddressForm'
import Button from '../../../components/Button'

type Props = {|
  canClickNext: boolean,
  address: CustomerAddress,
  deliveryCountryCode: string,
  deliveryCountryName: string,
  onClickBack: () => void,
  onClickNext: () => void,
  onIsAddressValidChange: (isAddressValid: boolean) => void,
  onAddressChange: (field: string, value: string) => void
|}

export default class OrderAddressView extends PureComponent<Props> {
  render (): React$Node {
    return (
      <Page title="Customer address" showActionsInFooter={true}>
        {this.props.deliveryCountryName && (
          <p className={styles.country}>Delivering to
            <strong> {countryFormat(this.props.deliveryCountryName)}</strong>
          </p>
        )}

        <AddressForm
          address={this.props.address}
          onIsAddressValidChange={this.props.onIsAddressValidChange}
          onAddressChange={this.props.onAddressChange} />

        <div className={styles.actions}>
          <Button onClick={this.props.onClickBack} dataTest="address-back-button">Back</Button>
          <Button primary onClick={this.props.onClickNext} disabled={!this.props.canClickNext} dataTest="address-continue-button">Continue</Button>
        </div>
      </Page>
    )
  }
}
