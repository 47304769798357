import { useMemo } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useHistory, useLocation } from 'react-router'

import Button from '../../Button'
import { QUERY_PARAMS } from 'src/v2/constants'
import ImageLibraryModal from '../../ImageLibraryModal'
import { ImageLibraryImageInterface, useSplitToggle } from '../../../hooks'
import { PACKING_SLIP_ALLOWED_FILE_TYPES } from './UploadPackingSlip.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

export function PackingSlipError({
  className,
  errorStatus,
  isImageLibraryModalOpen,
  isLoading,
  handleUploadPackingUpdate,
  onOpenImageLibrary,
  setIsImageLibraryModalOpen
}: {
  className?: string
  errorStatus?: number
  isImageLibraryModalOpen: boolean
  isLoading: boolean
  handleUploadPackingUpdate: (imageLibraryImage: ImageLibraryImageInterface) => Promise<void>
  onOpenImageLibrary?: () => void
  setIsImageLibraryModalOpen: (open: boolean) => void
}) {
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function onSetIsImageLibraryModalOpen(open: boolean) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpen(open)
  }

  return (
    <div className="my-6 flex-col">
      <p className="w-max-full pb-8 text-red-500">An unexpected error occured. Code {errorStatus ?? '0'}</p>

      <Button
        className={`${className} min-w-[186px]`}
        dataTest="add-packing-slip-button"
        isLoading={isLoading}
        startIcon={<PlusIcon className="h-8 w-8" />}
        onClick={() => {
          const newSearchParams = new URLSearchParams(searchParams)
          newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
          if (isPDFSplitOn) {
            newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))
          }
          history.replace({ pathname, search: newSearchParams.toString() })

          window.analytics.track('Clicked re-upload packing slip')
          setIsImageLibraryModalOpen(true)
          if (onOpenImageLibrary) {
            onOpenImageLibrary()
          }
        }}
      >
        Re-upload
      </Button>

      <ImageLibraryModal
        config={{ forceSaveToLibrary: false }}
        open={isImageLibraryModalOpen}
        type="packing"
        setOpen={onSetIsImageLibraryModalOpen}
        onSelectImage={handleUploadPackingUpdate}
      />
    </div>
  )
}
