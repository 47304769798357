// @flow
import type { Element } from 'react'
import React from 'react'
import Tippy from '@tippy.js/react'

import Svg from '../svg'
import styles from './ImageInfo.module.css'

type Props = {|
  fileName: string,
  fileSize: string,
  imageUrl: string,
  imageAltText: string,
  showClearImageButton?: boolean,
  tooltipPlacement?: string,
  onClearImage?: () => void,
|}

function ImageInfo ({
  fileName,
  fileSize,
  imageUrl,
  imageAltText,
  showClearImageButton = true,
  tooltipPlacement = 'right',
  onClearImage
}: Props): Element<"div"> {
  return (
    <div className={styles.imageInfoContainer}>
      <img
        className={styles.image}
        src={imageUrl}
        alt={imageAltText} />
      <div className={styles.imageDetails}>
        <span className={styles.fileName}>{fileName}</span>
        {showClearImageButton &&
          <Tippy content="Remove" placement={tooltipPlacement}>
            <button
              style={{marginLeft: fileName === '' ? '0px' : '22px'}}
              className={styles.clearImageButton}
              type="button"
              onClick={onClearImage}>
              <Svg.Cross className={styles.crossSvg} />
            </button>
          </Tippy>
        }
        <div className={styles.fileSize}>{fileSize}</div>
      </div>
    </div>
  )
}

export default ImageInfo
