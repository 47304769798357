import { ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearProductSearch,
  getItemCategories,
  resetProductSearchResults,
  selectItemCategory,
  selectProductType
} from '../../../../actions/catalogue'
import {
  getFormattedCountries,
  getHasOrderInProgress,
  selectDeliveryCountry
} from '../../../../selectors/manualOrderForm'
import { ROUTE_PATHS } from '../../../../v2/constants'
import { resetRsaaStatus } from '../../../../actions/rsaa'
import { CREATE_V4_ORDER } from '../../../../actions/orders'
import { buildRsaaActionId } from '../../../../helpers/rsaa'
import { ALL, NO_SELECTION } from '../../../../data/catalogue'
import SelectField from '../../../../v2/components/SelectField'
import { setupRecentItems } from '../../../../actions/recentItems'
import ConfirmationModal from '../../../../v2/components/ConfirmationModal'
import { selectSelectedItemCategory, selectSelectedProductType } from '../../../../selectors/catalogue'
import { changeDeliveryCountry, clearOrderData, MOF_ORDER_LOGGER_ID } from '../../../../actions/manualOrderForm'

type CountriesType = Array<{ name: string; isoCode: string }>

export default function CountrySelection() {
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedItemCategory = useSelector(selectSelectedItemCategory)
  const selectedProductType = useSelector(selectSelectedProductType)
  const hasOrderInProgress = useSelector(getHasOrderInProgress)

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [shippingCountryCode, setShippingCountryCode] = useState<string | undefined>()

  const countries: CountriesType = useSelector(getFormattedCountries)
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)

  function onResetSearch(countryCode: string) {
    history.replace(ROUTE_PATHS.MOF.SEARCH)
    dispatch(clearOrderData())
    const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
    dispatch(resetRsaaStatus(createOrderActionId))
    dispatch(resetProductSearchResults())
    dispatch(selectItemCategory(NO_SELECTION))
    dispatch(changeDeliveryCountry(countryCode))
    dispatch(getItemCategories())
    dispatch(clearProductSearch())

    if (selectedItemCategory !== NO_SELECTION) {
      dispatch(selectItemCategory(selectedItemCategory))
    }
    if (selectedItemCategory !== NO_SELECTION && selectedProductType !== ALL) {
      dispatch(selectProductType(selectedProductType))
    }
    dispatch(setupRecentItems())
  }

  function onChangeDeliveryCountry(event: ChangeEvent<HTMLSelectElement>) {
    const countryCode = event.target.value
    if (selectedDeliveryCountry === countryCode) {
      return
    }
    setShippingCountryCode(countryCode)

    if (hasOrderInProgress) {
      setShowConfirmationModal(true)
      return
    }

    onResetSearch(countryCode)
  }

  return (
    <div className="mb-6 items-center border border-gray-200 bg-white p-4 sm:flex">
      <label htmlFor="countrySelection" className="mr-6 whitespace-nowrap">
        Shipping to
      </label>
      <SelectField
        id="countrySelection"
        onChange={onChangeDeliveryCountry}
        value={selectedDeliveryCountry}
        className="sm:mt-0 sm:w-auto"
      >
        {countries.map((country) => (
          <option key={country.isoCode} value={country.isoCode}>
            {country.name}
          </option>
        ))}
      </SelectField>

      <CountryChangeConfirmationModal
        open={showConfirmationModal}
        countryName={countries.find((country) => country.isoCode === shippingCountryCode)?.name}
        setOpen={setShowConfirmationModal}
        onCancel={() => {
          setShowConfirmationModal(false)
        }}
        onContinue={() => {
          if (shippingCountryCode) {
            onResetSearch(shippingCountryCode)
          }
          setShowConfirmationModal(false)
        }}
      />
    </div>
  )
}

function CountryChangeConfirmationModal({
  open,
  countryName,
  setOpen,
  onContinue,
  onCancel
}: {
  open: boolean
  countryName: string | undefined
  setOpen: (isOpen: boolean) => void
  onContinue: () => void
  onCancel: () => void
}) {
  const title = countryName ? `Change country to ${countryName}` : 'Change country'

  return (
    <ConfirmationModal
      open={open}
      continueButton={{ text: 'Yes' }}
      title={title}
      closeButton={{ text: 'No' }}
      setOpen={setOpen}
      onContinue={onContinue}
      onCancel={onCancel}
    >
      <p>Changing the shipping country will empty your basket. Do you want to continue?</p>
    </ConfirmationModal>
  )
}
