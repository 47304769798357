// @flow

import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function PlusCircle ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.9999" cy="11.9989" r="10.9091" strokeWidth="2"/>
      <rect x="11" y="6.99902" width="2" height="10" />
      <rect x="7" y="10.999" width="10" height="2" />
    </svg>
  )
}
