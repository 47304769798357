// @flow
import type { MultiAssetBasketItem, BasketItem, Point } from '../../../types'
import { APP_VERSION } from '../../../data/appVersion'
import { selectTemplate } from '../../../helpers/selectTemplate'
import { getCropRectangleRelativeToTemplateSize } from '../getCropRectangleRelativeToTemplateSize'
import { convertScaleBasedPositionToPrintPx } from '../../../helpers/imageEditor'
import { PRINT_AREA_NAME } from '../../../data'
import { setTemplateProps } from '../../images'
import { LANDSCAPE, PORTRAIT, SQUARE } from '../../../data/orientation'

export function updateToVersion11(itemToUpdate: BasketItem): MultiAssetBasketItem {
  if (!itemToUpdate.templateOrientation) {
    throw Error('Template orientation cannot be null')
  }
  const orientation = itemToUpdate.templateOrientation

  return {
    appVersion: APP_VERSION.WHICH_INTRODUCED.NEW_IMAGE_EDITOR_UNITS,
    costedAttributeKeys: { initialised: false, value: [] },
    id: itemToUpdate.id,
    printAreas: {
      [PRINT_AREA_NAME.DEFAULT]: {
        artworkTransformations: {
          borderFactor: itemToUpdate.borderFactor,
          borderScale: itemToUpdate.borderScale,
          position: calculatePrintPxPosition(itemToUpdate),
          orientation,
          rotationAngle: itemToUpdate.rotationAngle,
          scaleFactor: itemToUpdate.scaleFactor,
          isTiled: false
        },
        artwork: {
          croppedImageUrl: itemToUpdate.croppedImageUrl,
          mediumImageUrl: itemToUpdate.mediumImageUrl,
          smallImageUrl: itemToUpdate.smallImageUrl,
          originalImageUrl: itemToUpdate.originalImageUrl,
          artworkWidth: itemToUpdate.originalImageWidth,
          artworkHeight: itemToUpdate.originalImageHeight
        }
      }
    },
    selectedPrintArea: PRINT_AREA_NAME.DEFAULT,
    selectedAttributes: itemToUpdate.selectedAttributes,
    priceToUserAsInt: itemToUpdate.priceToUserAsInt,
    quantity: itemToUpdate.quantity,
    sku: itemToUpdate.sku
  }
}

function calculatePrintPxPosition(itemToUpdate: BasketItem): Point {
  const templates = {
    printAreas: {
      [PRINT_AREA_NAME.DEFAULT]: {
        orientations: {
          landscape: setTemplateProps(itemToUpdate.landscapeTemplate, LANDSCAPE),
          portrait: setTemplateProps(itemToUpdate.portraitTemplate, PORTRAIT),
          square: setTemplateProps(itemToUpdate.squareTemplate, SQUARE)
        }
      }
    }
  }

  const template = selectTemplate(templates, PRINT_AREA_NAME.DEFAULT, itemToUpdate.templateOrientation)

  if (!itemToUpdate.templateOrientation || !template || !template.printDpi) {
    return {
      x: 0,
      y: 0
    }
  }
  const printDpi = template.printDpi

  const scaleBasedCropRectangleDimensions = getCropRectangleRelativeToTemplateSize(template.cropRectangle)

  const scaleBasedPositionRelativeToTemplateSize = {
    x: itemToUpdate.imageX,
    y: itemToUpdate.imageY
  }

  return convertScaleBasedPositionToPrintPx(
    template.printResolution,
    scaleBasedCropRectangleDimensions,
    scaleBasedPositionRelativeToTemplateSize,
    itemToUpdate.borderFactor,
    itemToUpdate.borderScale,
    printDpi
  )
}
