import { formatToSentenceCase } from '../../../helpers'

type PrintAreaToggleProps = {
  printAreaNames: string[]
  selectedPrintArea: string
  onChangePrintArea: (selectedPrintArea: string) => void
}

export function PrintAreaToggle({ printAreaNames, onChangePrintArea, selectedPrintArea }: PrintAreaToggleProps) {
  if (printAreaNames.length < 2) {
    return null
  }

  return (
    <div className="mx-auto flex">
      {printAreaNames.map((printAreaName) => (
        <button
          className={`min-w-[120px] border-b border-b-white py-3 capitalize text-white ${
            printAreaName === selectedPrintArea ? 'border-opacity-100' : 'border-opacity-20'
          }`}
          onClick={() => onChangePrintArea(printAreaName)}
          key={printAreaName}
        >
          {formatToSentenceCase(printAreaName)}
        </button>
      ))}
    </div>
  )
}
