import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { ROUTE_PATHS } from '../../../constants'

// Kept for backwards compatibility, redirects to new route
export default function SettingsIntegrationsPage() {
  const history = useHistory()

  useEffect(() => {
    history.replace(ROUTE_PATHS.SETTINGS.API)
  }, [history])

  return null
}
