import { ReactNode } from 'react'
import toast, { ToastOptions } from 'react-hot-toast'
import { ToastContent, ToastWithCloseButton } from '../components'

export interface ToastInterface extends ToastOptions {
  content?: ReactNode
  footer?: ReactNode
  heading: string
  type?: 'success' | 'success-with-close' | 'error' | 'error-with-close' | 'default' | 'default-with-close'
}

export function createToast({ heading, content, footer, type = 'default', ...args }: ToastInterface) {
  switch (type) {
    case 'success':
      return toast.success(<ToastContent heading={heading} content={content} footer={footer} />, { ...args })
    case 'success-with-close':
      return toast.success(
        (toastRef) =>
          ToastWithCloseButton({
            toastRef,
            content,
            heading,
            footer
          }),
        { ...args }
      )
    case 'error':
      return toast.error(<ToastContent heading={heading} content={content} footer={footer} />, { ...args })
    case 'error-with-close':
      return toast.error(
        (toastRef) =>
          ToastWithCloseButton({
            toastRef,
            content,
            heading,
            footer
          }),
        { ...args }
      )
    case 'default-with-close':
      return toast(
        (toastRef) =>
          ToastWithCloseButton({
            toastRef,
            content,
            heading,
            footer
          }),
        { ...args }
      )
    default:
      return toast(<ToastContent heading={heading} content={content} footer={footer} />, { ...args })
  }
}
