// @flow
import { getAccessToken, getRefreshToken, saveJwtTokens } from './localStorage'
import type { DispatchFunc, GetStateFunc, ThunkAsync } from '../../types'
import type { RefreshAccessTokenResult } from '../types'
import { refreshAccessTokenRsaa, REFRESH_ACCESS_TOKEN_SUCCESS } from './refreshAccessTokenRsaa'

export const REFRESH_ACCESS_TOKEN_SKIPPED = 'REFRESH_ACCESS_TOKEN_SKIPPED'

export function refreshAccessToken (): ThunkAsync<RefreshAccessTokenResult> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()

    if (!(accessToken && refreshToken)) {
      return dispatch(refreshAccessTokenSkipped())
    }

    const result = await dispatch(refreshAccessTokenRsaa(accessToken, refreshToken))

    if (result.type === REFRESH_ACCESS_TOKEN_SUCCESS) {
      saveJwtTokens(result.payload)
    }

    return result
  }
}

function refreshAccessTokenSkipped () {
  return {
    type: REFRESH_ACCESS_TOKEN_SKIPPED
  }
}
