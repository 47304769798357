// @flow
import { RSAA } from 'redux-api-middleware'
import type { GetStateFunc, DispatchFunc, AzureSearchResult, CatalogueItem, ThunkAsync } from '../../types'
import { selectAzureSearchSettings } from '../../selectors/appSettings'
import { resetProductSearchResults } from './catalogue'
import { filterOutUnavailableProducts } from './filterOutUnavailableProducts'
import { NO_SELECTION, ALL } from '../../data/catalogue'
import { RETRIEVABLE_PROPERTIES } from '../../data/azureSearch'
import { fetchPrices } from './fetchPrices'
import { getFacetQuery } from './getFacetQuery'
import { mapCatalogueItems } from './mapCatalogueItems'
import { sortSearchResultsByScore } from './sortSearchResultsByScore'
import { reverseSizeSearchQuery } from './reverseSizeSearchQuery'

export const REQUEST_PRODUCT_SEARCH = 'REQUEST_PRODUCT_SEARCH'
export const RECEIVE_PRODUCT_SEARCH = 'RECEIVE_PRODUCT_SEARCH'
export const PRODUCT_SEARCH_FAILED = 'PRODUCT_SEARCH_FAILED'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export function searchProducts(query: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const selectedCategory = getState().catalogue.selectedItemCategory
    const azureSearchSettings = selectAzureSearchSettings()

    await dispatch({ type: SET_SEARCH_QUERY, query })

    if (query !== '' || selectedCategory !== NO_SELECTION) {
      await dispatch({
        [RSAA]: {
          endpoint: getSearchItemsUrl(getState, query, azureSearchSettings.endpoint),
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'api-key': azureSearchSettings.key
          },
          types: [
            REQUEST_PRODUCT_SEARCH,
            {
              type: RECEIVE_PRODUCT_SEARCH,
              payload: async (action, state, res) => {
                try {
                  const json = await res.json()
                  return sortAndFilterSearchResults(json.value, query)
                } catch {
                  dispatch({ type: PRODUCT_SEARCH_FAILED })
                  return []
                }
              }
            },
            PRODUCT_SEARCH_FAILED
          ]
        }
      })

      let searchItemsSkus = []
      if (getState().catalogue.searchItems) {
        searchItemsSkus = getState().catalogue.searchItems.map((item) => item.sku)
      }
      await dispatch(fetchPrices(searchItemsSkus))
    } else {
      await dispatch(resetProductSearchResults())
    }
  }
}

export function getSearchItemsUrl(getState: GetStateFunc, query: string, azureSearchEndpoint: string): string {
  const formattedQuery = encodeURIComponent(query.trim().toLowerCase() + '*')
  const skuQuery = `"${encodeURIComponent(query.trim().toUpperCase())}"`
  const sizeSearchQuery = reverseSizeSearchQuery(query.trim().toLowerCase())
  const countryCode = getState().manualOrderForm.deliveryCountry
  const productType = getState().catalogue.selectedProductType

  const category = getState().catalogue.selectedItemCategory

  let productTypeQuery = ''
  let categoryQuery = ''

  if (category !== NO_SELECTION && category !== ALL) {
    categoryQuery = `and category eq '${encodeURIComponent(category)}'`
  }

  if (productType !== ALL) {
    productTypeQuery = `and productType eq '${encodeURIComponent(productType)}'`
  }

  const generatedQuery = getFacetQuery(getState)

  let endpoint =
    `${azureSearchEndpoint}&search=${formattedQuery}` +
    `&$count=true&$top=50&%24filter=destinationCountries/any(c: c eq '${countryCode}')`

  if (category) {
    if (query && query !== '*') {
      if (sizeSearchQuery) {
        endpoint =
          `${azureSearchEndpoint}&search=${formattedQuery}|${sizeSearchQuery}` +
          `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}')${generatedQuery} ${categoryQuery} ${productTypeQuery} &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}`
      } else {
        endpoint =
          `${azureSearchEndpoint}&search=${formattedQuery}|${skuQuery}` +
          `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}')${generatedQuery} ${categoryQuery} ${productTypeQuery} &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}`
      }
    } else {
      endpoint =
        `${azureSearchEndpoint}&search=${formattedQuery}` +
        `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}')${generatedQuery} ${categoryQuery} ${productTypeQuery} &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}`
    }
  }

  endpoint += `&$select=${RETRIEVABLE_PROPERTIES.join()}`

  return endpoint
}

export function sortAndFilterSearchResults(searchResult: AzureSearchResult[], searchQuery: string): CatalogueItem[] {
  const validProducts = removeMisconfiguredProducts(searchResult)
  const filtered = filterOutUnavailableProducts(validProducts)
  const sorted = sortSearchResultsByScore(filtered, searchQuery)
  return mapCatalogueItems(sorted)
}

function removeMisconfiguredProducts(searchResult: AzureSearchResult[]): AzureSearchResult[] {
  return searchResult.filter((product) => product.description)
}
