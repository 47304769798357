// @flow
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { selectJwtTimerId } from '../../selectors/auth'
import { startJwtTimer } from './startJwtTimer'

export const START_JWT_TIMER_SKIPPED = 'START_JWT_TIMER_SKIPPED'

export function startJwtTimerIfNeeded (): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const timerId = selectJwtTimerId(getState())

    if (!timerId) {
      return dispatch(startJwtTimer())
    }

    return dispatch({ type: START_JWT_TIMER_SKIPPED })
  }
}
