import styles from './AttributeSelector.module.css'
import { formatCost } from '../../../../v2/helpers'
import SelectField from '../../../../components/inputs/SelectField'
import { ProductCostedAttributeInterface } from '../../../../v2/interfaces'

export function BasketItemCostedAttributes({
  productCostedAttributes,
  disabled,
  selectedAttributes,
  onAttributeChange
}: {
  productCostedAttributes: Record<string, ProductCostedAttributeInterface>
  disabled: boolean
  selectedAttributes: Record<string, string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAttributeChange: (name: string, e: any) => void
}) {
  return (
    <>
      {Object.entries(productCostedAttributes).map(([costedAttributeKey, costedAttributeData]) => {
        if (!selectedAttributes?.[costedAttributeKey]) {
          return null
        }

        return (
          <tr className={styles.row} key={costedAttributeKey}>
            <td className={styles.label}>{costedAttributeData.name}:</td>
            <td className={styles.value}>
              {
                <SelectField
                  disabled={disabled}
                  value={selectedAttributes[costedAttributeKey]}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  event={(name: string, e: any) => {
                    window.analytics?.track?.(`Costed attribute changed: ${costedAttributeKey}`, { option: e })
                    onAttributeChange(name, e)
                  }}
                  name={costedAttributeKey}
                  eventNeedsTwoArgs
                >
                  {costedAttributeData.options.map((option) => {
                    const price =
                      option.price.amount > 0
                        ? formatCost({ amount: option.price.amount.toString(), currencyCode: option.price.currency })
                        : null

                    return (
                      <option value={option.value} key={option.value}>
                        {price ? `${option.name} (${price})` : option.name}
                      </option>
                    )
                  })}
                </SelectField>
              }
            </td>
          </tr>
        )
      })}
    </>
  )
}
