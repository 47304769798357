import NotFound from '../NotFound'
import Skeleton from '../Skeleton'
import SupportLink from '../SupportLink'
import ClipboardCopy from '../ClipboardCopy'
import { ProductDetailsLoaded } from './components'
import { useProductCatalogueProduct } from '../../hooks'

export default function BrowseProduct({ slug }: { slug: string }) {
  const {
    catalogueProduct: productData,
    isLoadingCatalogueProduct,
    catalogueProductFetchError
  } = useProductCatalogueProduct(slug)

  if (catalogueProductFetchError) {
    const errorCode = `${slug ?? '0'}-${catalogueProductFetchError.status ?? '0'}`
    return (
      <div className="w-full pt-12 sm:pt-24">
        <NotFound
          className="mx-auto px-9"
          body={
            <>
              <p className="text-center">
                Please try again later or <SupportLink>contact us</SupportLink> if the issue persists.
              </p>
              {catalogueProductFetchError.responseBodyJson?.message && (
                <p className="mt-2 break-words text-center">{catalogueProductFetchError.responseBodyJson?.message}</p>
              )}
              <div className="mt-4 break-words text-center text-gray-600">
                Error code: {errorCode}
                <ClipboardCopy className="align-middle" text={errorCode} showText={false} />
              </div>
            </>
          }
          heading="Failed to load product"
        >
          <></>
        </NotFound>
      </div>
    )
  }

  if (isLoadingCatalogueProduct || !productData) {
    return (
      <>
        <div className="flex h-full flex-col justify-between gap-3 lg:flex-row lg:gap-6">
          <div className="lg:w-3/5 xl:w-1/2">
            <Skeleton className="h-16 bg-purple-200/50" />
            <Skeleton className="mt-10 h-[350px] bg-purple-200/50" />
          </div>
          <div className="lg:w-2/5 xl:w-1/2">
            <Skeleton className="h-full w-full bg-purple-200/50 md:block lg:col-span-1" />
          </div>
        </div>

        <Skeleton className="mt-24 h-[500px] w-full bg-purple-200/50" />

        <Skeleton className="mt-24 h-[500px] w-full bg-purple-200/50" />
      </>
    )
  }

  return <ProductDetailsLoaded productData={productData} />
}
