import { useLocation } from 'react-router'
import { ReactNode, useEffect, useState } from 'react'

import { StatusEnum } from '../../enums'
import ClipboardCopy from '../ClipboardCopy'
import { requestOrdersExportDownload } from './helpers'
import { DownloadError, DownloadSuccess } from './components'
import FullPageLoadingSpinner from '../FullPageLoadingSpinner'
import { APP_ENVIRONMENTS, QUERY_PARAMS } from '../../constants'
import { FetchErrorInterface, OMSErrorResponseInterface } from '../../interfaces'

export default function Download() {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const fileId = searchParams.get(QUERY_PARAMS.DOWNLOAD.FILE_ID)

  const [fileDownloadStatus, setFileDownloadStatus] = useState(StatusEnum.Loading)
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string | undefined>()
  const [fileDownloadErrorMessage, setFileDownloadErrorMessage] = useState<ReactNode | undefined>()

  useEffect(() => {
    if (!fileId) {
      setFileDownloadErrorMessage('An error occurred with code FNF.')
      setFileDownloadStatus(StatusEnum.Error)
      return
    }

    requestOrdersExportDownload(fileId)
      .then((response) => {
        setFileDownloadUrl(response.data.url)
        setFileDownloadStatus(StatusEnum.Success)
      })
      .catch((error) => {
        const errorMessage = getOrdersExportErrorMessage(error as FetchErrorInterface<OMSErrorResponseInterface>)
        setFileDownloadErrorMessage(errorMessage)
        setFileDownloadStatus(StatusEnum.Error)
      })
  }, [fileId])

  switch (fileDownloadStatus) {
    case StatusEnum.Idle:
    case StatusEnum.Loading:
      return <FullPageLoadingSpinner />
    case StatusEnum.Success:
      if (!fileDownloadUrl) {
        return <DownloadError message="An error occurred with code SFE." />
      }
      return <DownloadSuccess url={fileDownloadUrl} />
    case StatusEnum.Error:
      return <DownloadError message={fileDownloadErrorMessage} />
    default:
      const unhandledStatus: never = fileDownloadStatus
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled case ${unhandledStatus} found. Add a new case to fix this`)
      }
      throw Error('Unhandled download status')
  }
}

function getOrdersExportErrorMessage(errorResponseOMS: FetchErrorInterface<OMSErrorResponseInterface>) {
  let errorCode = <>{errorResponseOMS.name}</>
  if (errorResponseOMS.responseBodyJson?.traceParent) {
    errorCode = (
      <>
        {errorResponseOMS.responseBodyJson?.traceParent} ({errorResponseOMS.status ?? ''})
        <span className="inline-block" style={{ transform: 'translateY(-1px)' }}>
          <ClipboardCopy showText={false} text={errorResponseOMS.responseBodyJson?.traceParent} />
        </span>
      </>
    )
  }
  const errorMessage = <>An error occurred with code {errorCode}</>
  return errorMessage
}
