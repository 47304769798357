// @flow
import type { Artwork } from '../../types'
import type { CopyArtworkDetails } from '../types'
import { buildImageId } from './buildImageId'

export const COPY_ARTWORK_DETAILS = 'COPY_ARTWORK_DETAILS'

export function copyArtworkDetails (id: string, artwork: Artwork, selectedPrintArea: string): CopyArtworkDetails {
  const imageId = buildImageId(id, selectedPrintArea)

  return {
    type: COPY_ARTWORK_DETAILS,
    id: imageId,
    artwork
  }
}
