// @flow
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import { convertOrderIdToV4 } from '../../helpers/orderId'
import { fetchOrderDetails } from './fetchOrderDetails'
import type { ThunkAsync, DispatchFunc, V4ApiActionOutcome } from '../../types'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'
import { getOrderDetailsV4 } from './getOrderDetailsV4'

export const CANCEL_ORDER = 'CANCEL_ORDER'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR'

const ACTION_NOT_AVAILABLE_MESSAGE = 'Sorry, this order can no longer be cancelled.'

export function cancelOrder (orderId: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc) => {
    window.analytics.track('Cancel order')

    const v4OrderId = convertOrderIdToV4(orderId)

    const actionResult = await dispatch(cancelOrderRsaa(v4OrderId))

    const isRequestSuccessful = Boolean(
      actionResult.type === CANCEL_ORDER_SUCCESS &&
      actionResult.payload &&
      actionResult.payload.outcome === V4_API_ACTION_OUTCOME.CANCELLED
    )

    if (isRequestSuccessful) {
      dispatch(getOrderDetailsV4(orderId))
      dispatch(fetchOrderDetails(orderId))
    } else if (actionResult.type === CANCEL_ORDER_SUCCESS && !isRequestSuccessful) {
      // The API returns success status codes even when the request fails

      const errorMessage = getErrorMessage(actionResult.payload.outcome)

      dispatch(onOrderCancellationError(errorMessage))
    }
  }
}

function getErrorMessage (actionOutcome: V4ApiActionOutcome): string {
  const isCancellationAvailable = Boolean(
    actionOutcome !== V4_API_ACTION_OUTCOME.ACTION_NOT_AVAILABLE &&
    actionOutcome !== V4_API_ACTION_OUTCOME.FAILED_TO_CANCEL
  )

  return isCancellationAvailable
    ? DEFAULT_ERROR_MESSAGE
    : ACTION_NOT_AVAILABLE_MESSAGE
}

function cancelOrderRsaa (orderId: string) {
  const endpoint = selectProdigiApiEndpoint() + `/v4.0/orders/${orderId}/actions/cancel`
  return {
    [RSAA]: {
      method: 'POST',
      endpoint,
      types: [
        {
          type: CANCEL_ORDER,
          meta: {
            enhancements: [ LOG_STATUS ]
          }
        },
        CANCEL_ORDER_SUCCESS,
        {
          type: CANCEL_ORDER_ERROR,
          meta: {
            message: DEFAULT_ERROR_MESSAGE
          }
        }
      ]
    }
  }
}

function onOrderCancellationError (message: string) {
  return {
    type: CANCEL_ORDER_ERROR,
    message
  }
}
