// @flow

import React, { useEffect } from 'react'
import cx from 'classnames'
import Button from '../../../../components/Button'
import FontAwesome from 'react-fontawesome'
import MultiAssetImageUpload from '../../../../components/MultiAssetImageUpload'
import AttributeSelector from '../AttributeSelector'
import PriceAndQuantity from '../PriceAndQuantity'
import WhitePanel from '../../../../components/WhitePanel'
import styles from './MultiAssetBasketItem.module.css'
import type { MultiAssetBasketItemViewProps } from './MultiAssetBasketItemProps'
import MultiAssetBasketItemError from './MultiAssetBasketItemError'
import { isDefaultArtworkRequired, isZeroPricedItem } from '../../../../selectors/manualOrderForm'
import { ERROR, LOADING } from '../../../../data/rsaa'
import { getPrintAreaNameToDisplay } from '../../../../helpers/getPrintAreaNameToDisplay'
import { canProductBeTiled } from '../../../../helpers/products'

// $FlowFixMe: TypeScript file
import { useProduct, useSplitToggle } from '../../../../v2/hooks'
// $FlowFixMe: TypeScript file
import { BasketItemCostedAttributes } from '../AttributeSelector/BasketItemCostedAttributes.component'
import { BasketItemMetaData } from './BasketItemMetaData'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { useDispatch } from 'react-redux'
import { setCostedAttributeKeys } from '../../../../actions/manualOrderForm/manualOrderForm'

export default function MultiAssetBasketItemView(
  props: MultiAssetBasketItemViewProps
): React$Element<typeof WhitePanel> | React$Element<typeof MultiAssetBasketItemError> {
  const {
    isLoading: isLoadingProp,
    hasError,
    artworkTransformationStatus,
    templateStatus,
    imageStatus,
    changeSelectedAttribute,
    item,
    imageQuality,
    index,
    noArtworkBackgroundImageStyle,
    removeBasketItem,
    changeQuantity,
    catalogueItem,
    printAreaEntries,
    requiredPrintAreaNames,
    onRemoveArtwork,
    onPrintAreaChange,
    imagePreviewKey,
    selectedPrintArea,
    optimalResolution,
    isTilingEnabled,
    toggleTiling,
    onSelectImageLibraryImage
  } = props

  const { splitIsOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { product, isLoadingProduct, productFetchError } = useProduct(splitIsOn ? item.sku : undefined, {
    config: { revalidateOnFocus: false }
  })
  const dispatch = useDispatch()
  const pwintyProduct = product?.pwintyProduct

  useEffect(() => {
    if (!item?.costedAttributeKeys?.initialised && !isLoadingProduct) {
      dispatch(setCostedAttributeKeys(item.id, Object.keys(pwintyProduct?.costedAttributes ?? {})))
    }

    Object.entries(pwintyProduct?.costedAttributes ?? {}).forEach(([costedAttributeKey, costedAttributeData]) => {
      // $FlowFixMe: TypeScript type
      const costedAttributeOptions = costedAttributeData?.options

      if (costedAttributeOptions?.length === 0) {
        return
      }

      const itemHasCostedAttributeInitialised =
        Object.keys(item.selectedAttributes ?? {}).includes(costedAttributeKey) &&
        costedAttributeOptions.some((option) => option.value === item?.selectedAttributes?.[costedAttributeKey ?? ''])

      if (!itemHasCostedAttributeInitialised && catalogueItem) {
        const defaultCostedAttributeOption =
          costedAttributeOptions.find((costedAttributeOption) => costedAttributeOption.isDefault === true) ??
          costedAttributeOptions[0]

        // In case the costed attribute keys stored in localStorage is stale, also set the keys here
        dispatch(setCostedAttributeKeys(item.id, Object.keys(pwintyProduct?.costedAttributes ?? {})))

        changeSelectedAttribute({
          basketItem: item,
          catalogueItem,
          name: costedAttributeKey,
          value: defaultCostedAttributeOption.value
        })
      }
    })
  }, [catalogueItem, changeSelectedAttribute, dispatch, isLoadingProduct, item, pwintyProduct?.costedAttributes])

  if (productFetchError) {
    return (
      <MultiAssetBasketItemError
        message={`An error occurred while fetching product details, please try again later or contact support if the issue persists. (${
          item.sku ?? '0'
        } ${productFetchError.responseBodyJson?.message ?? productFetchError.message ?? '0'} ${
          productFetchError.status
        })`}
        removeBasketItem={removeBasketItem}
      />
    )
  }

  if (hasError) {
    return <MultiAssetBasketItemError removeBasketItem={removeBasketItem} />
  }

  const isLoading = isLoadingProp || isLoadingProduct
  const artwork = item.printAreas && item.printAreas[selectedPrintArea]?.artwork

  return (
    <WhitePanel dataTest="basket-item-panel">
      <div className={styles.grid} data-test={`basket-item-${index}`}>
        <div className={styles.imageContainer}>
          {catalogueItem && (
            <React.Fragment>
              {isDefaultArtworkRequired(catalogueItem.productType) ? (
                <div className={styles.noImagePreview}>
                  <img
                    src={props.substituteImageSource}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    alt="product"
                  />
                </div>
              ) : (
                <div
                  className={cx({
                    [styles.addImageContainer]: !artwork
                  })}
                >
                  <MultiAssetImageUpload
                    key={imagePreviewKey}
                    imageStatus={props.imageStatus}
                    templateStatus={props.templateStatus}
                    thumbnailItem={props.thumbnailItem}
                    productType={catalogueItem.productType}
                    itemCategory={catalogueItem.category}
                    onOpenImageLibrary={() => window.analytics.track('Image library opened: Order creation')}
                    imageQuality={imageQuality}
                    showRemoveImageButton={Boolean(artwork)}
                    noArtworkBackgroundImageStyle={noArtworkBackgroundImageStyle}
                    printAreaEntries={printAreaEntries}
                    selectedPrintArea={selectedPrintArea}
                    optimalResolution={optimalResolution}
                    imageUrl={artwork ? artwork.originalImageUrl : null}
                    isUploadingImage={imageStatus === LOADING}
                    hasImageUploadError={imageStatus === ERROR || artworkTransformationStatus === ERROR}
                    imageUploadErrorMessage={props.artworkErrorMessage}
                    uploadArtworkFromUrl={props.uploadArtworkFromUrl}
                    uploadArtworkFromFile={props.uploadArtworkFromFile}
                    isTilingEnabled={isTilingEnabled}
                    toggleTiling={toggleTiling}
                    supportsTiling={canProductBeTiled(catalogueItem.productType)}
                    onEditImageClick={props.onEditImageClick}
                    onRemoveImage={onRemoveArtwork}
                    onPrintAreaChange={onPrintAreaChange}
                    onSelectImageLibraryImage={onSelectImageLibraryImage}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={styles.titleContainer} data-test="basket-item-title-container">
          <h2 className={styles.title} data-test={`basket-item-description-${props.item.id}`}>
            {catalogueItem?.description}
          </h2>
          {catalogueItem?.sku && (
            <div className="tailwind">
              <div className="mt-2 text-sm text-gray-500">{catalogueItem.sku}</div>
            </div>
          )}
        </div>
        <div className={styles.attributesContainer}>
          <table className={styles.table} data-test="basket-attribute-table">
            <tbody>
              {catalogueItem && Object.keys(catalogueItem.attributes).length > 0 && (
                <AttributeSelector
                  disabled={templateStatus === LOADING || imageStatus === LOADING}
                  category={catalogueItem.category}
                  attributeChanged={(name, value) =>
                    changeSelectedAttribute({ basketItem: item, catalogueItem, name, value })
                  }
                  availableAttributes={catalogueItem.attributes}
                  selectedAttributes={item.selectedAttributes}
                />
              )}

              {catalogueItem &&
                pwintyProduct?.costedAttributes &&
                Object.keys(pwintyProduct?.costedAttributes).length > 0 && (
                  <BasketItemCostedAttributes
                    productCostedAttributes={pwintyProduct?.costedAttributes ?? {}}
                    selectedAttributes={item.selectedAttributes}
                    disabled={templateStatus === LOADING || imageStatus === LOADING}
                    onAttributeChange={(name, value) =>
                      changeSelectedAttribute({ basketItem: item, catalogueItem, name, value })
                    }
                  />
                )}

              {catalogueItem && <BasketItemMetaData basketItem={item} catalogueItem={catalogueItem} />}
            </tbody>
          </table>
          {catalogueItem && printAreaEntries && printAreaEntries.length > 1 && (
            <div className={styles.requiredPrintAreaNamesContainer}>
              {requiredPrintAreaNames.length > 1 && <strong>Required print areas: </strong>}
              {requiredPrintAreaNames.length === 1 && <strong>Required print area: </strong>}
              {requiredPrintAreaNames.map((printAreaName) => (
                <span className={styles.requiredPrintAreaName} key={printAreaName}>
                  {getPrintAreaNameToDisplay(printAreaName, catalogueItem.category)}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={styles.costContainer}>
          {catalogueItem && (
            <PriceAndQuantity
              currency={catalogueItem.currency}
              price={catalogueItem.price}
              qty={item.quantity}
              zeroPricedItem={isZeroPricedItem(item.sku)}
              quantityChanged={(qty) => changeQuantity(qty, item.id)}
            />
          )}
          <Button
            destructive
            icon="trash"
            size="small"
            title="Remove item"
            onClick={removeBasketItem}
            dataTest="remove-item-button"
          >
            Remove
          </Button>
        </div>
        {isLoading && (
          <div className={styles.loading} data-test="basket-item-loading">
            <div>
              <FontAwesome name="circle-o-notch fa-spin" />
              {props.loadingMessage}
            </div>
          </div>
        )}
      </div>
    </WhitePanel>
  )
}
