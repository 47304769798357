import useSWRInfinite from 'swr/infinite'
import { useLocation } from 'react-router'

import { fetcher } from '../helpers'
import { QUERY_PARAMS } from '../constants'
import { FetchErrorInterface } from '../interfaces'

export const IMAGE_LIBRARY_TAG_PAGE_SIZE = 25

export function useImageLibraryImageTags() {
  const baseUrl = `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/tags/`
  const { search } = useLocation()

  const { data, error, mutate, size, setSize } = useSWRInfinite<string[], FetchErrorInterface<{ detail?: string }>>(
    (pageIndex: number, previousPageData: string[]) => {
      const hasReachedEnd = previousPageData && previousPageData.length === 0
      if (hasReachedEnd) {
        return null
      }

      const windowSearchParams = new URLSearchParams(search)
      const imageLibraryRequestParams = new URLSearchParams()
      imageLibraryRequestParams.append('limit', IMAGE_LIBRARY_TAG_PAGE_SIZE.toString())
      imageLibraryRequestParams.append('skip', (pageIndex * IMAGE_LIBRARY_TAG_PAGE_SIZE).toString())

      const imageLibrarySearchParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TAG_SEARCH)
      if (imageLibrarySearchParam) {
        imageLibraryRequestParams.append('filter', '^' + imageLibrarySearchParam)
      }

      return baseUrl + `?${imageLibraryRequestParams.toString()}`
    },
    fetcher,
    { revalidateAll: true }
  )

  return {
    imageLibraryFetchError: error,
    imageLibraryTagPages: data,
    imageLibraryTagPagesToLoadSize: size,
    isLoadingImageLibraryTagPages: !data && !error,
    mutateImageLibraryTagPages: mutate,
    setImageLibraryTagPagesToLoadSize: setSize
  }
}
