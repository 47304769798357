// @flow
import type { Element, Node } from 'react'
import React from 'react'
import styles from './LinkButton.module.css'
import type { Event } from '../../types'
import cx from 'classnames'

type Props = {|
  children?: Node,
  className?: string,
  disabled?: boolean,
  dataTest?: string,
  onClick: (e: Event) => void
|}

export function LinkButton(props: Props): Element<'button'> {
  const { dataTest, ...restProps } = props
  return (
    <button
      {...restProps}
      className={cx(styles.linkButton, props.className)}
      data-test={dataTest}
      data-testid="link-button"
    >
      {props.children}
    </button>
  )
}
