// @flow
import { identity } from './helpers'
import { createSelector } from 'reselect'
import type { AppState } from '../types'

export function selectBasketItemCount (state: AppState): number {
  return state.manualOrderForm.inProgressOrder.itemsToBeCreated.length
}

export const getBasketItemCount: any = createSelector(selectBasketItemCount, identity)
