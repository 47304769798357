import { ChevronRightIcon } from '@heroicons/react/24/outline'

import { formatCountry } from '../../../helpers'
import { V3CountryInterface } from '../../../interfaces'

export function CountryPickerOption({
  country,
  onCountrySelection
}: {
  country: V3CountryInterface
  onCountrySelection: (countryCode: string) => void
}) {
  return (
    <li
      key={country.isoCode}
      className="border-t border-gray-200 transition-colors duration-75 last:border-b hover:cursor-pointer hover:bg-gray-100"
    >
      <button
        className="group flex w-full items-center p-4 py-8"
        data-test={`country-list-item-${country.isoCode.toLowerCase()}`}
        onClick={() => onCountrySelection(country.isoCode)}
      >
        <div className="h-10 w-20">
          <img
            alt="flag"
            loading="lazy"
            className="max-h-full max-w-full"
            src={`/img/flags-100/${country.isoCode.toLowerCase()}.png`}
          />
        </div>
        <p className="pl-12" data-test="country-name">
          {formatCountry(country.name)}
        </p>
        <ChevronRightIcon
          className="ml-auto h-8 w-8 text-gray-800 opacity-0 transition-opacity duration-75 ease-in group-hover:opacity-100"
          aria-hidden="true"
        />
      </button>
    </li>
  )
}
