// @flow
import type { AppState, User, Billing, Company, UserSettings } from '../types'
import { identity } from './helpers'
import { createSelector } from 'reselect'
import { INVOICE_FREQUENCY } from '../data/billing'
import { splitFullNameIntoFirstAndLastName } from '../helpers/splitFullNameIntoFirstAndLastName'

export function selectIsUserInitialised (state: AppState): boolean {
  return state.user.isInitialised
}

export function selectCompany (state: AppState): Company {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.company
}

export function selectCurrencyCode (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.company.currencyCode
}

export function selectCountryCode (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.company.countryCode
}

export function selectInvoiceFrequency (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.billing.invoiceFrequency
}

export function selectUserSettings (state: AppState): UserSettings {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.settings
}

export function selectHasPaymentCardSetup (state: AppState): boolean {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return Boolean(state.user.details.billing && state.user.details.billing.hasPaymentCardSetup)
}

export function selectMaybeUser (state: AppState): ?User {
  return state.user.details
}

export function selectUser (state: AppState): User {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }

  return state.user.details
}

export function selectUserName (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.name
}

export function selectBillingDetails (state: AppState): Billing {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  return state.user.details.billing
}

export function selectUserId (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }

  return state.user.details.id
}

export function selectMaybeUserId (state: AppState): ?string {
  if (state.user.details) {
    return state.user.details.id
  }
  return null
}

export function selectMerchantUniqueId (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }

  return state.user.details.merchantUniqueId
}

export function userHasToSetUpCard (hasPaymentCardSetup: boolean, invoiceFrequency: string): boolean {
  return !hasPaymentCardSetup && invoiceFrequency === INVOICE_FREQUENCY.PER_ORDER
}

export function selectUserFirstName (state: AppState): string {
  if (!state.user.details) {
    throw new Error('User details missing.')
  }
  var name = splitFullNameIntoFirstAndLastName(state.user.details.fullName)
  return name.firstName
}

export const getMaybeUser: (state: AppState) => ?User = createSelector(selectMaybeUser, identity)
export const getUser: (state: AppState) => User = createSelector(selectUser, identity)
export const getUserName: any = createSelector(selectUserName, identity)
export const getBillingDetails: any = createSelector(selectBillingDetails, identity)
export const getUserId: any = createSelector(selectUserId, identity)
export const getCurrencyCode: any = createSelector(selectCurrencyCode, identity)
export const getInvoiceFrequency: any = createSelector(selectInvoiceFrequency, identity)
export const getMerchantUniqueId: any = createSelector(selectMerchantUniqueId, identity)
export const getCompany: any = createSelector(selectCompany, identity)
export const getCountryCode: any = createSelector(selectCountryCode, identity)
export const getUserSettings: any = createSelector(selectUserSettings, identity)
export const getIsUserInitialised: any = createSelector(selectIsUserInitialised, identity)
export const getUserHasToSetUpCard: any = createSelector(selectHasPaymentCardSetup, selectInvoiceFrequency, userHasToSetUpCard)
export const getUserFirstName: any = createSelector(selectUserFirstName, identity)
