// @flow
import React, { PureComponent } from 'react'
import CsvActionMenuView from './CsvActionMenuView'
import { values } from '../../../../helpers/dictionary'
import type { Dictionary, FormattedOrderItem } from '../../../../types'

type Props = {|
  items: Dictionary<FormattedOrderItem>,
  orderId: string,
  isIgnored: boolean,
  updateOrderIgnore: () => void,
  copyProductDetails: (orderItem: FormattedOrderItem) => void,
  copyImageDetails: (orderItem: FormattedOrderItem) => void,
  copyShippingMethod: (orderId: string) => void
|}

type State = {|
  isPanelDisplayed: boolean
|}

export default class CsvActionMenuController extends PureComponent<Props, State> {
  state: State = {
    isPanelDisplayed: false
  }

  componentDidMount () {
    window.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick: ((event: Event) => void) = (event: Event) => {
    this.setState({ isPanelDisplayed: false })
  }

  onClick: ((event: SyntheticMouseEvent<any>) => void) = (event: SyntheticMouseEvent<*>) => {
    event.stopPropagation()
    if (this.state.isPanelDisplayed) {
      this.hidePanel()
    } else if (event.target instanceof window.Node) {
      this.displayPanel()
    }
  }

  hidePanel: (() => void) = () => this.setState({isPanelDisplayed: false})

  displayPanel: (() => void) = () => this.setState({ isPanelDisplayed: true })

  copyProductDetails: ((orderItemId: string) => void) = (orderItemId: string) => this.props.copyProductDetails(this.props.items[orderItemId])

  copyImageDetails: ((orderItemId: string) => void) = (orderItemId: string) => {
    this.props.copyImageDetails(this.props.items[orderItemId])
  }

  copyShippingMethod: (() => void) = () => {
    this.props.copyShippingMethod(this.props.orderId)
  }

  get orderItemIds (): string[] {
    return values(this.props.items).map(item => item.id)
  }

  render (): React$Node {
    return <CsvActionMenuView
      copyProductDetails={this.copyProductDetails}
      copyImageDetails={this.copyImageDetails}
      copyShippingMethod={this.copyShippingMethod}
      orderItemIds={this.orderItemIds}
      isIgnored={this.props.isIgnored}
      isPanelDisplayed={this.state.isPanelDisplayed}
      onClick={this.onClick}
      updateOrderIgnore={this.props.updateOrderIgnore} />
  }
}
