import clsx from 'clsx'
import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import { differenceInHours } from 'date-fns'

import Skeleton from '../../Skeleton'
import AnimatedCheckIcon from '../../AnimatedCheckIcon'
import { findLongestSLA } from '../helpers/findLongestSLA.helper'
import { useProductSlas } from '../hooks'

type ShipmentStateType = {
  label: string
  weighting: number
}

export const SHIPMENT_STATES: ShipmentStateType[] = [
  {
    label: 'Allocated',
    weighting: 0.001
  },
  {
    label: 'Printing',
    weighting: 0.5
  },
  {
    label: 'Quality control',
    weighting: 0.3
  },
  {
    label: 'Packing',
    weighting: 0.199
  }
]

export function ShipmentStatusTracker({
  countryCode,
  orderAllocationDateString,
  skusInShipment
}: {
  countryCode: string
  orderAllocationDateString: string
  skusInShipment: string[]
}) {
  const orderAllocationDate = new Date(orderAllocationDateString)
  const currentDate = new Date()

  const { isLoading, error: hasError, productsSlas } = useProductSlas({ countryCode, productSkus: skusInShipment })

  if (hasError) {
    return <ErrorShipmentStatus />
  }

  if (isLoading || !productsSlas) {
    return <LoadingShipmentStatus />
  }

  if (Object.keys(productsSlas).length === 0) {
    return <ErrorShipmentStatus />
  }

  const slaInHours = findLongestSLA({ slaList: productsSlas })
  const hoursSinceAllocation = differenceInHours(currentDate, orderAllocationDate)

  return (
    <>
      {SHIPMENT_STATES.map((shipmentState, shipmentStateIndex) => {
        let status = getShipmentStatus({ hoursSinceAllocation, slaInHours, shipmentState, shipmentStateIndex })

        const isLastStepMarkedDoneAccordingToSla =
          shipmentStateIndex + 1 === SHIPMENT_STATES.length && status === 'done'
        if (isLastStepMarkedDoneAccordingToSla) {
          status = 'active'
        }

        return (
          <ShipmentStatusItem status={status} shipmentStateIndex={shipmentStateIndex} key={shipmentState.label}>
            {shipmentState.label}
          </ShipmentStatusItem>
        )
      })}
    </>
  )
}

type ShipmentStatusType = 'done' | 'active' | 'pending'

function ShipmentStatusItem({
  children,
  shipmentStateIndex,
  status
}: {
  children: ReactNode
  shipmentStateIndex: number
  status: ShipmentStatusType
}) {
  return (
    <div
      className={clsx(
        twMerge(
          'hidden flex-1 flex-col items-start gap-2 text-purple-600 sm:flex',
          status === 'pending' && 'text-purple-300',
          status === 'active' && 'flex'
        )
      )}
    >
      <div className="flex flex-row-reverse items-center gap-2 text-sm sm:flex-row">
        <span className="line-clamp-1">{children}</span>
        <AnimatedCheckIcon
          className="h-7 w-7 flex-shrink-0 text-green-600"
          isVisible={status === 'done'}
          initial={true}
          transition={{ delay: 0.2 * shipmentStateIndex }}
        />
        {status === 'active' && (
          <span className="relative flex h-3 w-3 flex-shrink-0">
            <span className="absolute inline-flex h-full w-full flex-shrink-0 animate-[ping_1s_cubic-bezier(0,0,0.2,1)_6] rounded-full bg-green-500 opacity-75"></span>
            <span className="relative inline-flex h-3 w-3 flex-shrink-0 rounded-full bg-green-500"></span>
          </span>
        )}
      </div>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ delay: 0.1 * shipmentStateIndex }}
        className={clsx(twMerge('mt-auto hidden h-2 bg-current sm:block', status === 'pending' && 'bg-purple-200'))}
      ></motion.div>
    </div>
  )
}

export function getShipmentStatus({
  hoursSinceAllocation,
  slaInHours,
  shipmentState,
  shipmentStateIndex
}: {
  hoursSinceAllocation: number
  slaInHours: number
  shipmentState: ShipmentStateType
  shipmentStateIndex: number
}): ShipmentStatusType {
  let totalWeightingForPreviousShipmentStates = 0

  SHIPMENT_STATES.slice(0, shipmentStateIndex).forEach((shipmentState) => {
    totalWeightingForPreviousShipmentStates = totalWeightingForPreviousShipmentStates + shipmentState.weighting
  })

  const hoursForStartOfShipmentState = totalWeightingForPreviousShipmentStates * slaInHours
  const hoursForCompletionOfShipmentState = hoursForStartOfShipmentState + shipmentState.weighting * slaInHours

  if (hoursSinceAllocation < hoursForStartOfShipmentState) {
    return 'pending'
  } else if (
    hoursSinceAllocation >= hoursForStartOfShipmentState &&
    hoursSinceAllocation < hoursForCompletionOfShipmentState
  ) {
    return 'active'
  } else {
    return 'done'
  }
}

function LoadingShipmentStatus() {
  return (
    <>
      <div className="hidden flex-1 flex-col items-start gap-2 text-purple-200 sm:flex">
        <span className="invisible line-clamp-1">Loading</span>
        <div className="mt-auto hidden h-2 w-full animate-pulse-slow bg-current py-0 sm:block" />
      </div>

      <div className="flex items-center gap-2 sm:hidden">
        <div className="h-3 w-3 flex-shrink-0 rounded-full bg-green-600"></div>
        <Skeleton className="h-8 w-32 bg-purple-200 py-0" />
      </div>
    </>
  )
}

function ErrorShipmentStatus() {
  const fallbackStatus = 'In production'

  return (
    <>
      <div className="hidden flex-1 flex-col items-start gap-2 text-sm text-purple-600 sm:flex">
        <span className="line-clamp-1">{fallbackStatus}</span>
        <div className="mt-auto hidden h-2 w-full bg-current py-0 sm:block" />
      </div>

      <div
        className="
      flex items-center gap-2 text-sm sm:hidden"
      >
        <div className="h-3 w-3 flex-shrink-0 rounded-full bg-green-600"></div>
        <span>{fallbackStatus}</span>
      </div>
    </>
  )
}
