import Modal, { ModalPropsInterface } from '../Modal'
import SelectProduct, { SelectProductPropsInterface } from '../SelectProduct'

type SelectProductModalPropsType = Omit<ModalPropsInterface, 'children'> & SelectProductPropsInterface

export default function SelectProductModal({
  countryCode,
  defaultSearchQuery,
  defaultSelectedAttributes,
  defaultSelectedMetaData,
  selectingSkuName,
  showCountryPicker,
  showMetaData,
  onSelectProduct,
  onSelectProductWithAdditionalData,
  ...props
}: SelectProductModalPropsType) {
  return (
    <Modal className="w-90vw max-w-[1400px] bg-gray-100" title="Select product" {...props}>
      <div className="h-80vh overflow-y-auto" data-test="select-product-modal">
        <SelectProduct
          countryCode={countryCode}
          defaultSearchQuery={defaultSearchQuery}
          defaultSelectedAttributes={defaultSelectedAttributes}
          defaultSelectedMetaData={defaultSelectedMetaData}
          selectingSkuName={selectingSkuName}
          showCountryPicker={showCountryPicker}
          showMetaData={showMetaData}
          onSelectProduct={onSelectProduct}
          onSelectProductWithAdditionalData={onSelectProductWithAdditionalData}
        />
      </div>
    </Modal>
  )
}
