// @flow
import type { Quote, Dictionary, Action } from '../../types'
import type { GetQuotesSuccess, GetQuotes } from '../../actions/types'
import { GET_QUOTES_SUCCESS, GET_QUOTES } from '../../actions/quotes'

export default function quotesReducer(
  state: Dictionary<Quote> = {},
  action: Action | GetQuotesSuccess
): Dictionary<Quote> {
  switch (action.type) {
    case GET_QUOTES_SUCCESS: {
      const currentAction: GetQuotesSuccess = action

      return {
        ...state,
        [currentAction.meta.requestId]: currentAction.payload.quotes
      }
    }

    case GET_QUOTES: {
      const currentAction: GetQuotes = action
      const requestId = currentAction.meta.requestId

      if (!state[requestId]) {
        return state
      }

      return Object.entries(state).reduce((filteredQuotes, [quoteId, quote]) => {
        if (requestId !== quoteId) {
          filteredQuotes[quoteId] = quote
        }
        return filteredQuotes
      }, {})
    }

    default: {
      return state
    }
  }
}
