// @flow
import React, { PureComponent } from 'react'
import type { ComponentType } from 'react'

export default function withProp<PropToAdd> (propToAdd: PropToAdd): ((WrappedComponent: ComponentType<any>) => ComponentType<any>) {
  return function (WrappedComponent: ComponentType<*>): ComponentType<*> {
    return class WithProp extends PureComponent<PropToAdd> {
      render (): React$Node {
        return <WrappedComponent {...this.props} {...propToAdd} />
      }
    }
  }
}
