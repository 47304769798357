import { fetcher } from '../../../helpers'

export async function deleteSalesChannel({
  merchantId,
  salesChannelId
}: {
  merchantId: number
  salesChannelId: string
}) {
  return fetcher(
    `${process.env.REACT_APP_PRODIGI_MERCHANT_SERVICE}/merchants/${merchantId}/saleschannels/${salesChannelId}`,
    {
      method: 'DELETE'
    }
  )
}
