import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { Elements, useStripe } from '@stripe/react-stripe-js'

import Modal from '../../../Modal'
import { useUser } from '../../../../hooks'
import { ROUTE_PATHS } from '../../../../constants'
import LoadingSpinner from '../../../LoadingSpinner'
import { FetchErrorInterface } from '../../../../interfaces'
import { createErrorToast, createToast } from '../../../Toast'
import { updateUserSuccess } from '../../../../../actions/user'
import { stripePromise } from '../../../../helpers/stripePromise.helper'
import { savePaymentMethod } from '../../helpers/savePaymentMethod.helper'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../constants'

export const STRIPE_SETUP_INTENT_REDIRECT_PARAMS = {
  CLIENT_SECRET: 'setup_intent_client_secret',
  PM_TYPE: 'prodigi_pm_type'
}

export function BillingStripeRedirectModal() {
  const [isOpen, setIsOpen] = useState(false)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const clientSecret = searchParams.get(STRIPE_SETUP_INTENT_REDIRECT_PARAMS.CLIENT_SECRET)

  useEffect(() => {
    return () => {
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
  }, [])

  useEffect(() => {
    if (clientSecret) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [clientSecret])

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      showHeader={false}
      showBorder={false}
      closeOnInteractionOutside={false}
      closeOnEscape={false}
    >
      <div className="max-w-3xl p-8 sm:min-w-[500px] sm:p-12">
        <Elements stripe={stripePromise}>
          <StripeRedirectStatus />
        </Elements>
      </div>
    </Modal>
  )
}

// Based on https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements#submit-payment-details
function StripeRedirectStatus() {
  const { mutateUser } = useUser()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const history = useHistory()

  useEffect(() => {
    if (!stripe) {
      return
    }

    function handleClose() {
      history.replace(ROUTE_PATHS.SETTINGS.BILLING)
    }

    function handleError({
      errorCode,
      errorMessage,
      heading = 'Failed to setup payment method',
      id = SETTINGS_UPDATE_ERROR_TOAST_ID
    }: {
      errorCode?: string | number
      errorMessage?: string
      heading?: string
      id?: string
    }) {
      createErrorToast({ errorCode, errorMessage, heading, id })
      const paymentMethodType = new URLSearchParams(window.location.search).get(
        STRIPE_SETUP_INTENT_REDIRECT_PARAMS.PM_TYPE
      )
      window.analytics.track('Payment method setup error', { type: paymentMethodType })

      handleClose()
    }

    function handleSuccess({
      duration,
      heading,
      message,
      id = SETTINGS_UPDATE_SUCCESS_TOAST_ID,
      type = 'success'
    }: {
      duration?: number
      heading: string
      message?: string
      id?: string
      type?: 'success' | 'default-with-close'
    }) {
      createToast({ duration, content: message, heading, id, type })
      const paymentMethodType = new URLSearchParams(window.location.search).get(
        STRIPE_SETUP_INTENT_REDIRECT_PARAMS.PM_TYPE
      )
      window.analytics.track('Payment method setup success', { type: paymentMethodType })

      handleClose()
    }

    async function retrieveSetupIntent() {
      try {
        const clientSecret = new URLSearchParams(window.location.search).get(
          STRIPE_SETUP_INTENT_REDIRECT_PARAMS.CLIENT_SECRET
        )

        if (!stripe) {
          handleError({ errorCode: 'ST-NO' })
          return
        }

        if (!clientSecret) {
          handleError({ errorCode: 'CS-NO' })
          return
        }
        const { setupIntent, error } = await stripe.retrieveSetupIntent(clientSecret)

        if (error) {
          handleError({
            errorMessage: error.message,
            errorCode: 'SI'
          })
          return
        }

        if (setupIntent.status === 'requires_payment_method') {
          handleError({
            errorMessage: setupIntent.last_setup_error?.message,
            errorCode: 'RPM'
          })
          return
        }

        await savePaymentMethod(setupIntent.payment_method)
        await mutateUser()

        // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
        dispatch(updateUserSuccess())

        if (setupIntent.status === 'processing') {
          handleSuccess({
            duration: Infinity,
            heading: 'Processing payment method',
            message:
              'Your payment method setup is being processed. Please allow some time for processing to complete and check back later',
            type: 'default-with-close'
          })
          return
        }

        handleSuccess({ heading: 'Payment method added successfully' })
      } catch (error) {
        const errorResponse = error as { message?: string }
        const fetchErrorResponse = error as FetchErrorInterface<{ message?: string }>
        handleError({
          errorMessage: fetchErrorResponse.responseBodyJson?.message ?? errorResponse.message,
          errorCode: `SI-C-${fetchErrorResponse.status ?? '0'}`
        })
      }
    }

    retrieveSetupIntent()
  }, [dispatch, history, mutateUser, stripe])

  return (
    <div className="grid place-content-center">
      <div className="flex justify-center">
        <LoadingSpinner className="h-8 w-8" />
      </div>
      <div className="mt-4">Verifying payment method setup</div>
    </div>
  )
}
