/* eslint-disable indent */
import Modal from '../Modal'
import OrderAddressForm, { OrderAddressFormPropsInterface } from '../OrderAddressForm'

interface OrderAddressFormModalPropsInterface extends OrderAddressFormPropsInterface {
  closeOnInteractionOutside?: boolean
  formattingCountryCode: string
  open: boolean
  setOpen: (open: boolean) => void
}

export default function OrderAddressFormModal({
  children,
  closeOnInteractionOutside = true,
  open,
  setOpen,
  ...props
}: OrderAddressFormModalPropsInterface) {
  return (
    <Modal closeOnInteractionOutside={closeOnInteractionOutside} open={open} title="Edit address" setOpen={setOpen}>
      <div className="max-h-70vh overflow-y-auto sm:max-w-[600px]">
        <OrderAddressForm formClassName="md:pr-8" {...props}>
          {children}
        </OrderAddressForm>
      </div>
    </Modal>
  )
}
