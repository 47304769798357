// @flow
import {
  RESET_PRODUCT_SEARCH_RESULTS,
  RECEIVE_PRICES,
  FETCH_PRICES_ERROR,
  REQUEST_PRODUCT_SEARCH,
  RECEIVE_PRODUCT_SEARCH,
  PRODUCT_SEARCH_FAILED,
  FETCH_ITEM_CATEGORIES,
  RECEIVE_ITEM_CATEGORIES,
  FAILED_ITEM_CATEGORIES,
  SELECT_ITEM_CATEGORY,
  FETCH_FACETS,
  RECEIVE_FACETS,
  FAILED_FACETS,
  CHANGE_FACET_SELECTION,
  SET_SEARCH_QUERY,
  RECEIVE_FACETS_ENABLED_STATUS,
  FETCH_PRICES,
  TOGGLE_FACET_EXPANSION,
  FETCH_PRODUCT_TYPES,
  RECEIVE_PRODUCT_TYPES,
  FAILED_PRODUCT_TYPES,
  SELECT_PRODUCT_TYPE,
  CLEAR_PRODUCT_SEARCH,
  GET_V4_PRODUCT_DETAILS_SUCCESS,
  SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS
} from '../actions/catalogue'
import type { GetV4ProductDetailsSuccessResult, SearchProductsBySkuListSuccess } from '../actions/types'
import { NO_SELECTION, ALL, MAX_INTERVAL_VALUE } from '../data/catalogue'
import { sortApparelBySize } from '../helpers/sortApparelBySize'
import productAttributeFormat from '../helpers/productAttributeFormat'
import type { Action, CatalogueState, Facet, FacetValue, ProductPrice } from '../types'
import { FIND_PRODUCT_DETAILS_SUCCESS } from '../actions/csvUpload'

const initialState: CatalogueState = {
  searchQuery: '',
  productTypes: [],
  fetchingPrices: false,
  products: {},
  // As a byproduct of not having a preselected category, when selecting one, the application was very briefly
  // displaying "No items found". Changed this by intializing searchItems to null instead of [].
  searchItems: null,
  error: false,
  searching: false,
  categories: [],
  itemCategoriesLoading: false,
  itemCategoriesError: false,
  selectedItemCategory: NO_SELECTION,
  selectedProductType: ALL,
  facets: {},
  facetsLoading: false,
  facetsError: false,
  productTypesError: false,
  productTypesLoading: false,
  v4ProductDetails: {}
}

function catalogue(state: CatalogueState = initialState, action: Action): CatalogueState {
  switch (action.type) {
    case FIND_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.product.sku]: action.product
        }
      }
    }

    case GET_V4_PRODUCT_DETAILS_SUCCESS: {
      const currentAction: GetV4ProductDetailsSuccessResult = action

      return {
        ...state,
        v4ProductDetails: {
          ...state.v4ProductDetails,
          [currentAction.payload.product.sku]: currentAction.payload.product
        }
      }
    }

    case REQUEST_PRODUCT_SEARCH: {
      return {
        ...state,
        searching: true,
        error: false,
        searchItems: [],
        fetchingPrices: false
      }
    }

    case RECEIVE_PRODUCT_SEARCH: {
      return {
        ...state,
        searching: false,
        searchItems: action.payload,
        products: {
          ...state.products,
          ...getProducts(action.payload)
        }
      }
    }

    case PRODUCT_SEARCH_FAILED: {
      return {
        ...state,
        searching: false,
        error: true
      }
    }

    case FETCH_PRICES: {
      return {
        ...state,
        fetchingPrices: true
      }
    }

    case RECEIVE_PRICES: {
      const prices: Array<ProductPrice> = action.payload.prices
      const productPriceDictionary = prices.reduce((priceAcc, price) => {
        priceAcc[price.sku] = price
        return priceAcc
      }, {})

      return {
        ...state,
        fetchingPrices: false,
        products: {
          ...Object.keys(state.products).reduce((productAcc, sku) => {
            if (!Object.keys(productPriceDictionary).includes(sku)) {
              productAcc[sku] = state.products[sku]
              return productAcc
            }

            productAcc[sku] = {
              ...state.products[sku],
              price: productPriceDictionary[sku].price,
              currency: productPriceDictionary[sku].currency
            }

            return productAcc
          }, {})
        },
        searchItems: state.searchItems
          ? state.searchItems.map((searchItem) => {
              if (!Object.keys(productPriceDictionary).includes(searchItem.sku)) {
                return searchItem
              }
              return {
                ...searchItem,
                price: productPriceDictionary[searchItem.sku].price,
                currency: productPriceDictionary[searchItem.sku].currency
              }
            })
          : null
      }
    }

    case FETCH_PRICES_ERROR: {
      return { ...state, fetchingPrices: false, error: true }
    }

    case FETCH_ITEM_CATEGORIES: {
      return {
        ...state,
        itemCategoriesLoading: true,
        itemCategoriesError: false,
        fetchingPrices: false
      }
    }

    case RECEIVE_ITEM_CATEGORIES: {
      const categories: Array<string> = action.payload['@search.facets'].category.map((x) => x.value).sort()

      return {
        ...state,
        categories: categories,
        itemCategoriesLoading: false,
        itemCategoriesError: false,
        fetchingPrices: false
      }
    }

    case FAILED_ITEM_CATEGORIES: {
      return {
        ...state,
        itemCategoriesLoading: false,
        itemCategoriesError: true,
        fetchingPrices: false
      }
    }

    case SELECT_ITEM_CATEGORY: {
      return {
        ...state,
        selectedItemCategory: action.category,
        selectedProductType: ALL,
        fetchingPrices: false,
        facets: {}
      }
    }

    case SELECT_PRODUCT_TYPE: {
      return {
        ...state,
        selectedProductType: action.productType,
        fetchingPrices: false
      }
    }

    case FETCH_FACETS: {
      return {
        ...state,
        facetsLoading: true,
        facetsError: false
      }
    }

    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.query
      }
    }

    case RECEIVE_FACETS: {
      const frame: FacetValue[] = action.payload['@search.facets'].frame
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const glaze: FacetValue[] = action.payload['@search.facets'].glaze
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const finish: FacetValue[] = action.payload['@search.facets'].finish
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const edge: FacetValue[] = action.payload['@search.facets'].edge
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const brand: FacetValue[] = action.payload['@search.facets'].brand
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const mount: FacetValue[] = action.payload['@search.facets'].mount
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const mountColour: FacetValue[] = action.payload['@search.facets'].mountColour
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const paperType: FacetValue[] = action.payload['@search.facets'].paperType
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const size: FacetValue[] = action.payload['@search.facets'].size
        .map((f) => {
          if (f.value) {
            return { ...f, selected: false, enabled: true }
          }
          return null
        })
        .filter((x) => x)
        .sort(sortApparelBySize)

      const gender: FacetValue[] = action.payload['@search.facets'].gender
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const frameColour: FacetValue[] = action.payload['@search.facets'].frameColour
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .sort(compare)

      const maxProductDimensionsMm: FacetValue[] = action.payload['@search.facets'].maxProductDimensionsMm
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .filter((f) => f.count === undefined || (f.count !== undefined && f.count > 0))
        .sort(compare)

      const productAspectRatio: FacetValue[] = action.payload['@search.facets'].productAspectRatio
        .map((f) => ({ ...f, selected: false, enabled: true }))
        .filter((f) => f.count === undefined || (f.count !== undefined && f.count > 0))
        .sort(compare)

      for (const f of maxProductDimensionsMm) {
        if (f.from === undefined) f.from = 0
        if (f.to === undefined) f.to = MAX_INTERVAL_VALUE
        f.value = f.from + '-' + f.to
      }

      for (const f of productAspectRatio) {
        if (f.from === undefined) f.from = 0
        if (f.to === undefined) f.to = MAX_INTERVAL_VALUE
        f.value = f.from + '-' + f.to
      }

      const facets = {
        frame: { facet: 'frame', expanded: false, values: frame },
        glaze: { facet: 'glaze', expanded: false, values: glaze },
        finish: { facet: 'finish', expanded: false, values: finish },
        edge: { facet: 'edge', expanded: false, values: edge },
        brand: { facet: 'brand', expanded: false, values: brand },
        mount: { facet: 'mount', expanded: false, values: mount },
        mountColour: { facet: 'mountColour', expanded: false, values: mountColour },
        paperType: { facet: 'paperType', expanded: false, values: paperType },
        size: { facet: 'size', expanded: false, values: size },
        gender: { facet: 'gender', expanded: false, values: gender },
        frameColour: { facet: 'frameColour', expanded: false, values: frameColour },
        maxProductDimensionsMm: { facet: 'maxProductDimensionsMm', expanded: false, values: maxProductDimensionsMm },
        productAspectRatio: { facet: 'productAspectRatio', expanded: false, values: productAspectRatio }
      }

      return { ...state, facets, facetsLoading: false, facetsError: false }
    }

    case FAILED_FACETS: {
      return { ...state, facetsLoading: false, facetsError: true }
    }

    case FETCH_PRODUCT_TYPES: {
      return {
        ...state,
        productTypes: [],
        productTypesLoading: true,
        productTypesError: false
      }
    }

    case RECEIVE_PRODUCT_TYPES: {
      const productTypes = action.payload['@search.facets'].productType.map((x) => x.value).sort()
      return { ...state, productTypes, productTypesLoading: false, productTypesError: false, fetchingPrices: false }
    }

    case FAILED_PRODUCT_TYPES: {
      return { ...state, productTypes: [], productTypesLoading: false, productTypesError: true }
    }

    case CHANGE_FACET_SELECTION: {
      const newFacets = { ...state.facets }
      const facetValues = newFacets[action.facetName].values
      const facetValue = facetValues.filter((fv) => fv.value.toString() === action.facetValue)[0]
      facetValue.selected = !facetValue.selected

      if (action.facetName === 'size') {
        newFacets[action.facetName].values.sort(sortApparelBySize)
      }

      return { ...state, facets: newFacets }
    }

    case RECEIVE_FACETS_ENABLED_STATUS: {
      const facets = { ...state.facets }
      const facetNames = [
        'frame',
        'glaze',
        'finish',
        'edge',
        'brand',
        'mount',
        'mountColour',
        'paperType',
        'size',
        'gender',
        'frameColour',
        'maxProductDimensionsMm',
        'productAspectRatio'
      ]

      const payloadFacets = action.payload['@search.facets']

      for (const facetName of facetNames) {
        const existingFacet: Facet = facets[facetName]
        const payloadFacetValues = payloadFacets[facetName]

        if (facetName === 'maxProductDimensionsMm' || facetName === 'productAspectRatio') {
          for (let facet of payloadFacetValues) {
            if (facet.from === undefined) facet.from = 0
            if (facet.to === undefined) facet.to = MAX_INTERVAL_VALUE
            facet.value = facet.from + '-' + facet.to
          }
        } else if (facetName === 'size') {
          facets[facetName].values.sort(sortApparelBySize)
        }

        if (existingFacet) {
          const existingFacetValues: FacetValue[] = existingFacet.values
          if (existingFacetValues && payloadFacetValues) {
            for (const existingFacetValue of existingFacetValues) {
              if (facetName === 'maxProductDimensionsMm' || facetName === 'productAspectRatio') {
                const payloadValue = payloadFacetValues.filter(
                  (v) => v.value === existingFacetValue.value && v.count > 0
                )[0]
                const enabled = payloadValue !== undefined
                existingFacetValue.enabled = enabled
              } else {
                const payloadValue = payloadFacetValues.filter((v) => v.value === existingFacetValue.value)[0]
                const enabled = payloadValue !== undefined
                existingFacetValue.enabled = enabled
              }
            }
          }
        }
      }

      return { ...state, facets }
    }

    case TOGGLE_FACET_EXPANSION: {
      const newFacets = JSON.parse(JSON.stringify(state.facets))
      const facet = newFacets[action.facet]
      facet.expanded = !facet.expanded

      if (action.facet === 'size') {
        facet.values.sort(sortApparelBySize)
      }

      return { ...state, facets: newFacets }
    }

    case RESET_PRODUCT_SEARCH_RESULTS: {
      return {
        ...state,
        searchItems: []
      }
    }

    case CLEAR_PRODUCT_SEARCH: {
      return {
        ...state,
        facets: {},
        searchItems: [],
        searchQuery: '',
        selectedItemCategory: NO_SELECTION,
        selectedProductType: ALL
      }
    }

    case SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS: {
      const currentAction: SearchProductsBySkuListSuccess = action

      return {
        ...state,
        products: {
          ...state.products,
          ...currentAction.payload.reduce((productAcc, product) => {
            productAcc[product.sku] = {
              ...state.products[product.sku],
              ...product
            }
            return productAcc
          }, {})
        }
      }
    }

    default: {
      return state
    }
  }
}

function compare(s1, s2): number {
  if (typeof s1.value === 'string' && typeof s2.value === 'string') {
    let v1: string = s1.value.toString()
    let v2: string = s2.value.toString()

    if (v1) v1 = productAttributeFormat(v1).toString()
    if (v2) v2 = productAttributeFormat(v2).toString()

    return v1.localeCompare(v2)
  } else if (typeof s1.value === 'number' && typeof s2.value === 'number') {
    return s2.value - s1.value
  }
  return 0
}

function getProducts(items) {
  let products = {}

  items.forEach((item) => {
    const keys = Object.keys(item)
    keys.forEach((itemKey) => {
      products[item.sku] = { ...products[item.sku], [itemKey]: item[itemKey] }
    })
  })

  return products
}

export default catalogue
