import {
  calcPreviewBorder,
  calcPrintDimensionsFillScaleFactor,
  LENGTH_UNIT,
  MM_PER_INCH,
  PositionUnitConverter,
  ScaleType,
  SCALE_TYPE
} from '@prodigi-group/components-image-editor'

import {
  DimensionsInterface,
  BorderUnitType,
  ArtworkTransformationsInterface,
  ArtworkInterface,
  ImageEditorTransformationsInterface,
  LengthUnitType,
  PointInterface
} from '../interfaces'
import { calculateScaleFactorAdjustment } from '.'
import { convertPrintPxPositionToUserUnit } from '../../../../helpers/imageEditor'
import { CropRectangleInterface, TemplateServiceSuccessResponseInterface } from '../../../hooks/useTemplateService.hook'

/**
 * Generates preview and print image URLs from the artwork, print area and template using PIG
 */
export function generatePigUrlsForArtworkInTemplate({
  artwork,
  transformations,
  printAreaName,
  templates
}: {
  artwork?: ArtworkInterface | null
  transformations: ArtworkTransformationsInterface
  printAreaName: string
  templates: TemplateServiceSuccessResponseInterface
}) {
  const selectedOrientation = transformations.orientation
  const template = templates.printAreas[printAreaName].orientations[selectedOrientation]

  if (!template || !artwork) {
    return null
  }

  const artworkDimensionsInPx = {
    height: artwork.artworkHeight,
    width: artwork.artworkWidth
  }

  const scaleFactorAdjustment = calculateScaleFactorAdjustment(
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    template.printDpi,
    transformations.borderFactor,
    transformations.borderScale
  )
  const scaleFactorInPigFriendlyFormat = (transformations.scaleFactor * scaleFactorAdjustment) / 100
  const pigPosition = convertPrintPxPositionToUserUnit(
    transformations.position,
    transformations.borderScale,
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    template.printDpi
  )

  const imageEditorTransformations: ImageEditorTransformationsInterface = {
    scaleFactor: scaleFactorInPigFriendlyFormat,
    scaleType: SCALE_TYPE.PRINT_SIZE as ScaleType,
    position: pigPosition,
    positionUnit: transformations.borderScale,
    rotationAngle: transformations.rotationAngle,
    borderWidth: transformations.borderFactor,
    borderUnit: transformations.borderScale,
    isTiled: transformations.isTiled
  }

  return buildPigUrl(
    artwork.originalImageUrl,
    imageEditorTransformations,
    artworkDimensionsInPx,
    template.printResolution,
    template.cropRectangle,
    template.printDpi,
    artwork.mediumImageUrl
  )
}

function buildPigUrl(
  originalImageUrl: string,
  transformations: ImageEditorTransformationsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  printDimensionsInPx: DimensionsInterface,
  cropRectangle: CropRectangleInterface,
  printDpi: number,
  mediumImageUrl: string
) {
  const { scaleFactor, rotationAngle, position, borderWidth, borderUnit, positionUnit } = transformations

  const fillScaleFactor = convertPrintSizeScaleToFillScale(
    scaleFactor,
    printDpi,
    borderUnit,
    borderWidth,
    printDimensionsInPx,
    artworkDimensionsInPx,
    cropRectangle
  )

  const borderWidthInPrintPx = convertBorderWidthToPrintPx(printDpi, borderWidth, borderUnit)

  const printPxPosition = convertPositionToPrintPx(
    printDimensionsInPx,
    artworkDimensionsInPx,
    cropRectangle,
    printDpi,
    positionUnit,
    position
  )

  const printImageUrl =
    process.env.REACT_APP_PIG_URL +
    `/render/?product_id=edit` +
    `&variant=cover:${printDimensionsInPx.width}x${printDimensionsInPx.height}` +
    '&print_image=true' +
    '&format=png' +
    '&debug=false' +
    `&image=${encodeURIComponent(originalImageUrl)}` +
    `&scale=${fillScaleFactor}` +
    `&rotate=${-rotationAngle}` +
    `&translate=${Math.round(printPxPosition.x)},${Math.round(printPxPosition.y)}` +
    `&border=${Math.round(borderWidthInPrintPx)}px` +
    `&tile=${String(transformations.isTiled)}`

  const downScalingFactor = 10
  const mininmumDimensionForDownscaling = 150

  const requiresPreviewDownScaling =
    Math.round(printDimensionsInPx.width / downScalingFactor) > mininmumDimensionForDownscaling ||
    Math.round(printDimensionsInPx.height / downScalingFactor) > mininmumDimensionForDownscaling

  if (!requiresPreviewDownScaling) {
    const previewImageUrl =
      process.env.REACT_APP_PIG_URL +
      `/render/?product_id=edit` +
      `&variant=cover:${printDimensionsInPx.width}x${printDimensionsInPx.height}` +
      '&print_image=true' +
      '&format=png' +
      '&debug=false' +
      `&image=${encodeURIComponent(mediumImageUrl)}` +
      `&scale=${fillScaleFactor}` +
      `&rotate=${-rotationAngle}` +
      `&translate=${Math.round(printPxPosition.x)},${Math.round(printPxPosition.y)}` +
      `&border=${Math.round(borderWidthInPrintPx)}px` +
      `&tile=${String(transformations.isTiled)}`

    return { printImageUrl, previewImageUrl }
  }

  const downScaledDimensions = {
    width: Math.round(printDimensionsInPx.width / downScalingFactor),
    height: Math.round(printDimensionsInPx.height / downScalingFactor)
  }
  const downScaledTranslations = {
    x:
      Math.round(printPxPosition.x) === 0
        ? Math.round(printPxPosition.x)
        : Math.round(printPxPosition.x / downScalingFactor),
    y:
      Math.round(printPxPosition.y) === 0
        ? Math.round(printPxPosition.y)
        : Math.round(printPxPosition.y / downScalingFactor)
  }
  const downScaledBorder =
    Math.round(borderWidthInPrintPx) === 0 ? Math.round(borderWidthInPrintPx) : Math.round(borderWidthInPrintPx / 10)

  const previewImageUrl =
    process.env.REACT_APP_PIG_URL +
    `/render/?product_id=edit` +
    `&variant=cover:${downScaledDimensions.width}x${downScaledDimensions.height}` +
    '&print_image=true' +
    '&format=png' +
    '&debug=false' +
    `&image=${encodeURIComponent(mediumImageUrl)}` +
    `&scale=${fillScaleFactor}` +
    `&rotate=${-rotationAngle}` +
    `&translate=${downScaledTranslations.x},${downScaledTranslations.y}` +
    `&border=${downScaledBorder}px` +
    `&tile=${String(transformations.isTiled)}`

  return { printImageUrl, previewImageUrl }
}

export function convertPrintSizeScaleToFillScale(
  printSizeScaleFactor: number,
  printDpi: number,
  borderUnit: BorderUnitType,
  borderWidth: number,
  printDimensionsInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  cropRectangle: CropRectangleInterface
): number {
  const scaledBorderInPx = calcPreviewBorder(printDpi, borderUnit, borderWidth, printDimensionsInPx, cropRectangle)
  const printDimensionScaleFactor = calcPrintDimensionsFillScaleFactor(
    printDimensionsInPx,
    artworkDimensionsInPx,
    scaledBorderInPx,
    0
  )

  return printSizeScaleFactor / printDimensionScaleFactor
}

export function convertBorderWidthToPrintPx(printDpi: number, borderWidth: number, borderUnit: BorderUnitType): number {
  if (borderUnit === LENGTH_UNIT.IN) {
    return borderWidth * printDpi
  } else if (borderUnit === LENGTH_UNIT.CM) {
    const borderWidthInInch = (borderWidth / MM_PER_INCH) * 10
    return borderWidthInInch * printDpi
  } else {
    throw Error(`borderUnit ${borderUnit} is invalid.`)
  }
}

export function convertPositionToPrintPx(
  printDimensionsInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  cropRectangle: CropRectangleInterface,
  printDpi: number,
  positionUnit: LengthUnitType,
  position: PointInterface
) {
  const converter = new PositionUnitConverter(printDimensionsInPx, artworkDimensionsInPx, cropRectangle, printDpi)

  if (positionUnit === LENGTH_UNIT.PRINT_PX) {
    return position
  } else if (positionUnit === LENGTH_UNIT.ARTWORK_PX) {
    return converter.artworkPxToPrintPx(position)
  } else if (positionUnit === LENGTH_UNIT.IN) {
    return converter.inchToPrintPx(position)
  } else if (positionUnit === LENGTH_UNIT.CM) {
    return converter.cmToPrintPx(position)
  } else {
    throw new Error(`Position unit: ${positionUnit} is invalid.`)
  }
}
