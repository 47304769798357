import getDefaultFetchOptions from '../../helpers/getDefaultFetchOptions'
import { FetchErrorInterface } from '../interfaces'

export class FetchError<T> extends Error {
  responseBodyJson
  status

  constructor({ message, name, responseBodyJson, status }: FetchErrorInterface<T>) {
    super(message)
    this.name = name
    this.message = message
    this.responseBodyJson = responseBodyJson
    this.status = status
  }
}

export async function fetcher<T>(url: string, options?: RequestInit | undefined): Promise<T> {
  return fetch(url, { ...getDefaultFetchOptions(), ...options }).then(async (response) => {
    let responseBodyJson = null

    if (
      response.headers.get('content-type')?.includes('application/json') ||
      response.headers.get('content-type')?.includes('application/problem+json')
    ) {
      responseBodyJson = await response.json()
    }

    if (!response.ok) {
      throw new FetchError({
        message: response.statusText,
        responseBodyJson,
        name: 'FETCH_ERROR',
        status: response.status
      })
    }
    return responseBodyJson
  })
}
