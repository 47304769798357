import getDefaultFetchOptions from '../../helpers/getDefaultFetchOptions'
import { FetchErrorInterface } from '../interfaces'

class FetchError<T> extends Error {
  responseBodyJson
  status
  constructor({ message, name, responseBodyJson, status }: FetchErrorInterface<T>) {
    super(message)
    this.name = name
    this.message = message
    this.responseBodyJson = responseBodyJson
    this.status = status
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function mockFetcher(url: string, mockData: any, method: string) {
  const returnData = {
    ...getDefaultFetchOptions(),
    body: JSON.stringify({ data: mockData }),
    method
  }

  return fetch(url, returnData).then(async (response) => {
    const responseBodyJson = await response.json()

    if (!response.ok) {
      throw new FetchError({
        message: response.statusText,
        responseBodyJson,
        name: 'FETCH_ERROR',
        status: response.status
      })
    }

    return responseBodyJson
  })
}
