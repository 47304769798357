// @flow
import type {
  Thunk,
  FormattedCsvRow,
  FormattedOrderItem,
  GetStateFunc,
  DispatchFunc,
  QuoteRequestItem,
  V4ProductDetails,
  Dictionary
} from '../../types'
import { getCsvOrderWithItems } from '../../selectors/csvUpload'
import { getRequiredPrintAreaNames } from '../../helpers/v4ProductDetails'
import { mapV3AttributesToV4 } from '../../helpers/attributes'
import { getV4ProductDetailsBySkus } from '../../selectors/catalogue'
import { values } from '../../helpers/dictionary'
import { getQuotes } from '../quotes'
// import { getCsvOrderBranding } from '../../selectors/csvUpload/csvUpload'

export function fetchPricingAndShippingForCsvOrder(orderId: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const [order, orderItems] = getCsvOrderWithItems(getState(), orderId)

    const hasEnoughDetailsToCreateQuote = Boolean(
      order && order.itemIds.length > 0 && order.customer && order.customer.countryCode
    )

    if (!hasEnoughDetailsToCreateQuote) {
      return null
    }

    // TODO: Pass branded inserts when fetching quotes and also show the insert quote prices returned in the UI
    // We might need to get the dashboard_branding_settings Split value here and only add inserts to quote/order if it's on
    // console.log('Getting quote for', orderId, { inserts: getCsvOrderBranding(getState(), orderId) })
    return dispatch(getCsvOrderQuote(order, orderItems))
  }
}

function getCsvOrderQuote(order: FormattedCsvRow, orderItems: Dictionary<FormattedOrderItem>): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const allSkus = values(orderItems)
      .map((item) => item.sku)
      .filter(Boolean)
    const v4ProductDetails = getV4ProductDetailsBySkus(getState(), allSkus)
    const quoteItems = mapCsvOrderItemsToQuoteRequestItems(orderItems, v4ProductDetails)

    if (quoteItems.length === 0) {
      return null
    }

    return dispatch(getQuotes(order.id, quoteItems, order.customer.countryCode))
  }
}

export function mapCsvOrderItemsToQuoteRequestItems(
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<V4ProductDetails>
): QuoteRequestItem[] {
  return values(orderItems)
    .map((orderItem) =>
      orderItem.sku && productDetails[orderItem.sku]
        ? mapCsvOrderItemToQuoteRequestItem(orderItem, productDetails[orderItem.sku])
        : null
    )
    .filter(Boolean)
}

export function mapCsvOrderItemToQuoteRequestItem(
  orderItem: FormattedOrderItem,
  productDetails: V4ProductDetails
): QuoteRequestItem {
  const requiredPrintAreaNames = getRequiredPrintAreaNames(productDetails)

  return {
    sku: orderItem.sku,
    copies: orderItem.quantity,
    attributes: mapV3AttributesToV4(orderItem.selectedAttributes, productDetails),
    assets: requiredPrintAreaNames.map((requiredPrintArea) => ({
      printArea: requiredPrintArea
    }))
  }
}
