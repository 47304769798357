// @flow
import React, { PureComponent } from 'react'
import { debounce } from 'lodash'
import type { Event, ThunkAsync } from '../../../../types'
import type { ClearProductSearch } from '../../../../actions/types'

// $FlowFixMe: TypeScript component
import ProductSearchLayout from './ProductSearchLayout'

type ProductSearchProps = {|
  selectedItemCategory: string,
  categories: string[],
  searchQuery: string,
  productTypes: string[],
  selectedProductType: string,
  search: (query: string) => ThunkAsync<void>,
  selectProductType: (productType: string) => ThunkAsync<void>,
  selectItemCategory: (category: string) => ThunkAsync<void>,
  clearProductSearch: () => ClearProductSearch,
  redirect: (pathname: string) => void
|}

type ProductSearchState = {|
  searchQuery: string,
  debouncedResponsePending: boolean
|}

const ADD_IMAGE_PAGE_PATH = '/orders/create/add-image'

export default class ProductSearch extends PureComponent<ProductSearchProps, ProductSearchState> {
  state: ProductSearchState = {
    searchQuery: this.props.searchQuery,
    debouncedResponsePending: false
  }

  debouncedQueryChanged: any = debounce((query: string) => {
    this.props.search(query)
    this.setState({ debouncedResponsePending: false })
  }, 500)

  setSearchQuery: (query: string) => void = (query: string) => {
    this.setState({ searchQuery: query, debouncedResponsePending: true })
    this.debouncedQueryChanged(query)
  }

  onQueryChange: (e: Event) => void = (e: Event) => {
    this.setSearchQuery(e.currentTarget.value)
  }

  onSelectItemCategory: (category: string) => void = (category: string) => {
    this.props.selectItemCategory(category)
  }

  onSelectProductType: (productType: string) => void = (productType: string) => {
    this.props.selectProductType(productType)
  }

  clearSearch: () => void = () => {
    this.setSearchQuery('')
    this.props.clearProductSearch()
  }

  viewBasket: () => void = () => this.props.redirect(ADD_IMAGE_PAGE_PATH)

  render(): React$Node {
    return (
      <ProductSearchLayout
        searchQuery={this.state.searchQuery}
        onQueryChange={this.onQueryChange}
        viewBasket={this.viewBasket}
        selectedItemCategory={this.props.selectedItemCategory}
        onSelectItemCategory={this.onSelectItemCategory}
        onSelectProductType={this.onSelectProductType}
        categories={this.props.categories}
        productTypes={this.props.productTypes}
        selectedProductType={this.props.selectedProductType}
        clearSearch={this.clearSearch}
      />
    )
  }
}
