// @flow
import { getAccessToken } from '../actions/auth'

export default function getDefaultFetchOptions (): {| headers: {| Authorization: string, "Content-Type": string |} |} {
  return {
    headers: {
      Authorization: 'Bearer ' + (getAccessToken() || ''),
      'Content-Type': 'application/json'
    }
  }
}
