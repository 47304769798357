// @flow
import type { DropEvent } from 'react-dropzone'

export function getSingleFileFromDropzoneEvent (e: DropEvent): ?File {
  return e.length > 0 ? e[0] : null
}

export function getFileTypeFromFileName (fileName: string): string {
  return fileName.slice(fileName.toLowerCase().lastIndexOf('.'))
}
