import { VariantProps } from 'class-variance-authority'
import { ButtonHTMLAttributes, forwardRef } from 'react'

import { buttonStyles } from './cva'
import LoadingSpinner from '../LoadingSpinner'

export type ButtonVariantType = VariantProps<typeof buttonStyles>['variant']
export type ButtonThemeType = VariantProps<typeof buttonStyles>['theme']
export type ButtonSizeType = VariantProps<typeof buttonStyles>['size']

type ButtonPropsType = ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  className?: string
  children?: React.ReactNode
  dataTest?: string
  disabled?: boolean
  endIcon?: React.ReactElement
  isLoading?: boolean
  size?: ButtonSizeType
  theme?: ButtonThemeType
  type?: 'button' | 'reset' | 'submit'
  startIcon?: React.ReactElement
  variant?: ButtonVariantType
}

export const Button = forwardRef<HTMLButtonElement, ButtonPropsType>(
  (
    {
      className = '',
      children,
      dataTest,
      disabled,
      endIcon,
      isLoading = false,
      startIcon,
      size = 'md',
      theme = 'brand',
      type = 'button',
      variant = 'primary',
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={buttonStyles({ className, variant, size, theme })}
        data-test={dataTest}
        disabled={disabled || isLoading}
        ref={ref}
        type={type}
        {...props}
      >
        {isLoading && <LoadingSpinner className="h-5 w-5" />}
        {!isLoading && startIcon}
        <span>
          <>{children}</>
        </span>
        {!isLoading && endIcon}
      </button>
    )
  }
)

Button.displayName = 'Button'
