// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import type { AppState, RsaaStatusProps } from '../../types'
import type { ComponentType } from 'react'
import { getRsaaStatusProps } from '../../selectors/rsaa'

function withRsaaProps (rsaaActionId: string): ((WrappedComp: ComponentType<any>) => any) {
  return (WrappedComp: ComponentType<*>) => {
    class WithRsaaProps extends PureComponent<*> {
      render (): React$Node {
        const { dispatch, ...restProps } = this.props
        return <WrappedComp {...restProps} />
      }
    }

    function mapStateToProps (state: AppState): RsaaStatusProps { return getRsaaStatusProps(state, rsaaActionId) }
    return connect(mapStateToProps)(WithRsaaProps)
  }
}

export default withRsaaProps
