import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE ?? '')

export const DEFAULT_STRIPE_OPTIONS: StripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://use.typekit.net/yrx6oxu.css'
    }
  ]
}
