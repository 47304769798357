import { useOs } from '@mantine/hooks'
import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'

import SearchField from '../../SearchField'
import { QUERY_PARAMS } from '../../../constants'
import { HOTKEYS } from '../../../constants/hotkeys.const'

export function ProductCatalogueSearch() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchParam = searchParams.get(QUERY_PARAMS.PRODUCT_CATALOGUE.SEARCH)
  const searchValueFromParam = useMemo(() => searchParam ?? '', [searchParam])

  const [searchValue, setSearchValue] = useState(searchValueFromParam)

  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const searchHotkeyDisplay = isAppleOs
    ? HOTKEYS.PRODUCT_CATALOGUE.SEARCH.DISPLAY.APPLE
    : HOTKEYS.PRODUCT_CATALOGUE.SEARCH.DISPLAY.DEFAULT

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSearchValue(searchValueFromParam)
  }, [searchValueFromParam, searchParams])

  const debouncedSearch = useDebouncedCallback((searchValue: string) => {
    const newSearchParams = new URLSearchParams(searchParams)

    const searchTooSmall = searchValue?.trim().length < 3

    if (searchTooSmall) {
      newSearchParams.delete(QUERY_PARAMS.PRODUCT_CATALOGUE.SEARCH)
      history.push({ search: newSearchParams.toString() })
      setSearchValue(searchValue)
      return
    }

    if (!searchValue?.trim()) {
      newSearchParams.delete(QUERY_PARAMS.PRODUCT_CATALOGUE.SEARCH)
      history.push({ search: newSearchParams.toString() })
      return
    }

    newSearchParams.set(QUERY_PARAMS.PRODUCT_CATALOGUE.SEARCH, searchValue)
    history.push({ search: newSearchParams.toString() })
  }, 200)

  function handleSearch(value: string) {
    setSearchValue(value)
    debouncedSearch(value)
  }

  return (
    <span className="w-full">
      <div className="relative isolate w-full">
        <SearchField
          hotkey={HOTKEYS.PRODUCT_CATALOGUE.SEARCH.TRIGGER}
          hotkeyDisplay={searchHotkeyDisplay}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => handleSearch(event.target.value)}
          onClearSearch={() => handleSearch('')}
        />
      </div>
    </span>
  )
}
