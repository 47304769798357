import { memo, useLayoutEffect, useRef } from 'react'
import { BorderUnitType, LengthUnitType, PointInterface } from '../interfaces'

interface ImageEditorControlsProps {
  position: PointInterface
  positionUnit: LengthUnitType
  rotationAngle: number
  scaleFactor: number
  onPositionChange: (event: CustomEvent<PointInterface>) => void
  onScaleChange: (event: CustomEvent<number>) => void
  onRotationAngleChange: (event: CustomEvent<number>) => void
  onPositionUnitChange: (event: CustomEvent<BorderUnitType>) => void
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'image-editor-controls': any
    }
  }
}

function ImageEditorControlsWrapper({
  position,
  positionUnit,
  rotationAngle,
  scaleFactor,
  onPositionChange,
  onScaleChange,
  onRotationAngleChange,
  onPositionUnitChange
}: ImageEditorControlsProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorControlsRef = useRef<any>(null)

  useLayoutEffect(() => {
    if (!editorControlsRef.current) {
      return
    }

    const editorControls = editorControlsRef.current

    editorControls.position = position
    editorControls.positionUnit = positionUnit
    editorControls.rotationAngle = rotationAngle
    editorControls.scaleFactor = scaleFactor
    editorControls.addEventListener('positionChange', onPositionChange)
    editorControls.addEventListener('scaleChange', onScaleChange)
    editorControls.addEventListener('rotationAngleChange', onRotationAngleChange)
    editorControls.addEventListener('positionUnitChange', onPositionUnitChange)

    return () => {
      editorControls.removeEventListener('positionChange', onPositionChange)
      editorControls.removeEventListener('scaleChange', onScaleChange)
      editorControls.removeEventListener('rotationAngleChange', onRotationAngleChange)
      editorControls.removeEventListener('positionUnitChange', onPositionUnitChange)
    }
  }, [
    position,
    positionUnit,
    rotationAngle,
    scaleFactor,
    onPositionChange,
    onScaleChange,
    onRotationAngleChange,
    onPositionUnitChange
  ])

  return <image-editor-controls ref={editorControlsRef} style={{ isolation: 'isolate' }} />
}

const ImageEditorControls = memo(ImageEditorControlsWrapper)

export default ImageEditorControls
