import { CatalogueLoaded } from '.'
import Skeleton from '../../Skeleton'
import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { useProductCatalogue } from '../../../hooks'

export function Catalogue() {
  const { productCatalogue, isLoading, error } = useProductCatalogue()

  if (error && !isLoading) {
    const errorCode = `${error.status ?? '0'}`
    return (
      <div className="w-full pt-12 sm:pt-24">
        <NotFound
          className="mx-auto px-9"
          heading="Failed to load products"
          body={
            <>
              <p className="text-center">
                Please try again later or <SupportLink>contact us</SupportLink> if the issue persists.
              </p>
              {error.responseBodyJson?.message && (
                <p className="mt-2 break-words text-center">{error.responseBodyJson?.message}</p>
              )}
              <div className="mt-4 break-words text-center text-gray-600">
                Error code: {errorCode}
                <ClipboardCopy className="align-middle" text={errorCode} showText={false} />
              </div>
            </>
          }
        >
          <></>
        </NotFound>
      </div>
    )
  }

  if (isLoading || !productCatalogue) {
    return (
      <div className="grid max-w-full grid-cols-1 gap-8 overflow-x-auto pt-6 sm:grid-cols-2 md:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] lg:pt-8">
        {Array.from({ length: 12 }, (_, i) => i).map((value) => (
          <Skeleton key={value} className="h-[286px] w-full bg-purple-200/50 p-0" />
        ))}
      </div>
    )
  }

  return <CatalogueLoaded productCatalogue={productCatalogue} />
}
