// @flow

import { SCALE_TYPE, LENGTH_UNIT } from '@prodigi-group/components-image-editor'
import { calcScaleFactorAdjustment } from '../helpers/imageEditor'
import type { ThumbnailItem, MultiAssetBasketItem, MultiAssetTemplate, PrintAreaArtwork } from '../types'

export function createThumbnailItemFromBasketItem(
  item: MultiAssetBasketItem,
  selectedPrintAreaArtwork: ?PrintAreaArtwork,
  template: ?MultiAssetTemplate
): ?ThumbnailItem {
  if (
    !selectedPrintAreaArtwork ||
    !selectedPrintAreaArtwork.artworkTransformations ||
    !template ||
    !template.printDpi ||
    !selectedPrintAreaArtwork.artwork
  ) {
    return null
  }
  const artwork = selectedPrintAreaArtwork.artwork
  const artworkTransformations = selectedPrintAreaArtwork.artworkTransformations
  const printDpi = template.printDpi
  const artworkDimensionsInPx = {
    height: artwork.artworkHeight,
    width: artwork.artworkWidth
  }

  const scaleFactorAdjustment = calcScaleFactorAdjustment(
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    printDpi,
    artworkTransformations.borderFactor,
    artworkTransformations.borderScale
  )

  return {
    id: item.id,
    artwork,
    cropRectangle: template.cropRectangle,
    transformations: {
      borderUnit: artworkTransformations.borderScale,
      borderWidth: artworkTransformations.borderFactor,
      rotationAngle: artworkTransformations.rotationAngle,
      scaleFactor: (artworkTransformations.scaleFactor * scaleFactorAdjustment) / 100,
      scaleType: SCALE_TYPE.PRINT_SIZE,
      position: artworkTransformations.position,
      positionUnit: LENGTH_UNIT.PRINT_PX,
      isTiled: artworkTransformations.isTiled
    },
    printDimensionsInPx: template.printResolution,
    artworkDimensionsInPx,
    imageUrls: {
      overlay: template.imageUrls.overlay,
      underlay: template.imageUrls.underlay,
      artwork: artwork.smallImageUrl
    },
    printDpi
  }
}
