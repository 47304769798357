// @flow
import type { Artwork } from '../../types/images'

export const FINISH_SELECT_IMAGE_LIBRARY_IMAGE = 'FINISH_SELECT_IMAGE_LIBRARY_IMAGE'

export type FinishSelectImageLibraryImageActionType = {|
  payload: {|
    artwork: Artwork,
    imageId: string
  |},
  type: 'FINISH_SELECT_IMAGE_LIBRARY_IMAGE'
|}

export function finishSelectImageLibraryImage({
  artwork,
  imageId
}: {|
  artwork: Artwork,
  imageId: string
|}): FinishSelectImageLibraryImageActionType {
  return {
    type: FINISH_SELECT_IMAGE_LIBRARY_IMAGE,
    payload: {
      artwork,
      imageId
    }
  }
}
