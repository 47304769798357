// @flow
import { RSAA } from 'redux-api-middleware'
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../../types'
import { searchProducts } from './searchProducts'
import { getFacetQuery } from './getFacetQuery'
import { selectAzureSearchSettings } from '../../selectors/appSettings'
import { NO_SELECTION, ALL } from '../../data/catalogue'

export const CHANGE_FACET_SELECTION = 'CHANGE_FACET_SELECTION'
export const FETCH_FACETS_ENABLED_STATUS = 'FETCH_FACETS_ENABLED_STATUS'
export const RECEIVE_FACETS_ENABLED_STATUS = 'RECEIVE_FACETS_ENABLED_STATUS'
export const FAILED_FACETS_ENABLED_STATUS = 'FAILED_FACETS_ENABLED_STATUS'

export function changeFacetSelection(facetName: string, facetValue: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    await dispatch({ type: CHANGE_FACET_SELECTION, facetName, facetValue })
    const azureSearchSettings = selectAzureSearchSettings()

    await dispatch({
      [RSAA]: {
        endpoint: getChangeFacetSelectionUrl(getState, azureSearchSettings.endpoint),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': azureSearchSettings.key
        },
        types: [FETCH_FACETS_ENABLED_STATUS, RECEIVE_FACETS_ENABLED_STATUS, FAILED_FACETS_ENABLED_STATUS]
      }
    })

    const searchQuery = getState().catalogue.searchQuery
    await dispatch(searchProducts(searchQuery))
  }
}

export function getChangeFacetSelectionUrl(getState: GetStateFunc, azureSearchEndpoint: string): string {
  const category = getState().catalogue.selectedItemCategory
  const productType = getState().catalogue.selectedProductType
  const query = getFacetQuery(getState)
  const countryCode = getState().manualOrderForm.deliveryCountry

  let productTypeQuery = ''
  let categoryQuery = ''

  if (category !== NO_SELECTION && category !== ALL) {
    categoryQuery = `and category eq '${encodeURIComponent(category)}'`
  }

  if (productType !== ALL) {
    productTypeQuery = `and productType eq '${encodeURIComponent(productType)}'`
  }

  return (
    `${azureSearchEndpoint}` +
    `&search=*&$filter=destinationCountries/any(c: c eq '${countryCode}') ${categoryQuery} ${productTypeQuery} ${query}` +
    `&$top=0&facet=glaze,count:100&facet=frame,count:100&facet=finish,count:100&facet=style,count:100` +
    `&facet=category&facet=edge&facet=brand&facet=mount&facet=mountColour&facet=paperType,count:100&facet=size,count:100&facet=gender&facet=frameColour,count:100` +
    `&facet=maxProductDimensionsMm,values:300|500|700|1000|1500&facet=productAspectRatio,values:95|105`
  )
}
