// @flow
import React, { PureComponent } from 'react'
import LinkButton from '../../../../components/LinkButton'
import { Flex } from '../../../../components/layout'
import styles from './CsvMultiselectFilters.module.css'
import cx from 'classnames'

type Props = {|
  isMultiselectModeOn: boolean,
  onClickSelectAll: () => void,
  onClickSelectNone: () => void,
  onClickClearSelection: () => void,
  onClickMultiselect: () => void
|}

export default class CsvMultiselectFiltersView extends PureComponent <Props> {
  render (): React$Node {
    if (this.props.isMultiselectModeOn) {
      return (
        <Flex.Row unsetSize justifyFlexStart className={cx(styles.container, styles.filters)}>
          <LinkButton onClick={this.props.onClickSelectAll} dataTest="select-all">Select all</LinkButton> |
          <LinkButton onClick={this.props.onClickSelectNone} dataTest="select-none">Select none</LinkButton> |
          <LinkButton onClick={this.props.onClickClearSelection} dataTest="clear-selection">Clear selection</LinkButton>
        </Flex.Row>
      )
    } else {
      return (
        <div className={styles.container}>
          <LinkButton onClick={this.props.onClickMultiselect} dataTest="csv-select-multiple-orders">Select multiple orders</LinkButton>
        </div>
      )
    }
  }
}
