// @flow
import React, { Component } from 'react'
import type { CustomerAddress } from '../types'
const countries = require('country-list')()

type Props = {|
  destinationCountry?: string,
  isNormalWeight?: boolean,
  address: CustomerAddress
|}

class Address extends Component<Props> {
  getStyles(): {| separator: {| display: string, marginTop: string |} |} {
    return {
      separator: {
        display: 'block',
        marginTop: '0.5em'
      }
    }
  }

  render(): React$Node {
    const styles = this.getStyles()
    const { name, address1, address2, county, postcode, town, countryCode, mobileTelephone, email } = this.props.address
    const countryName = countryCode
      ? countries.getName(countryCode)
      : this.props.destinationCountry
      ? countries.getName(this.props.destinationCountry)
      : null

    return (
      <address data-test="address-container">
        {name && this.props.isNormalWeight && (
          <span>
            {name}
            <br />
          </span>
        )}
        {name && !this.props.isNormalWeight && (
          <strong data-testid="address__name">
            {name}
            <br />
          </strong>
        )}
        {address1 && (
          <span data-testid="address__line1">
            {address1}
            <br />
          </span>
        )}
        {address2 && (
          <span>
            {address2}
            <br />
          </span>
        )}
        {town && (
          <span>
            {town}
            <br />
          </span>
        )}
        {county && (
          <span>
            {county}
            <br />
          </span>
        )}
        {postcode && (
          <span>
            {postcode}
            <br />
          </span>
        )}
        {countryName && (
          <span>
            {countryName}
            <br />
          </span>
        )}
        {(mobileTelephone || email) && <span style={styles.separator} />}
        {mobileTelephone && (
          <span>
            {mobileTelephone}
            <br />
          </span>
        )}
        {email && (
          <span>
            {email}
            <br />
          </span>
        )}
      </address>
    )
  }
}

export default Address
