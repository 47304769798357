// @flow
import type { Node } from 'react'
import React from 'react'
import Button from '../../../../components/Button'
import DropDown from '../../../../components/DropDown/DropDown'
import DropDownItem from '../../../../components/DropDown/DropDownItem'
import styles from './CsvActionMenu.module.css'

type Props = {|
  dataTest?: string,
  orderItemIds?: string[],
  text: string,
  onClick: (orderItemId: string) => void
|}

export default function CsvActionButton ({dataTest, onClick, text, orderItemIds = []}: Props): Node {
  return orderItemIds.length > 1
    ? (
      <DropDown dataTest={dataTest ?? 'csv-action-dropdown'} className={styles.button} text={text}>
        {orderItemIds.map((id, index) =>
          <DropDownItem
            dataTest={`${dataTest ?? 'csv-action-dropdown'}-item-${index}`}
            text={`Order Item ${index + 1}`}
            icon=''
            onClick={() => onClick(id)}
            key={id} />
        )}
      </DropDown>
    )
    : (
      <Button dataTest={dataTest ?? 'csv-action-button'} className={styles.button} onClick={() => onClick(orderItemIds[0])} >
        {text}
      </Button>
    )
}
