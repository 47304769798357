import { FooterContainer, FooterBlock } from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function ColourOptions({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <FooterContainer>
      {productData.colours.colours.length > 0 && (
        <FooterBlock title="Colour options">
          <ul className="grid max-w-full grid-cols-[repeat(auto-fill,minmax(160px,1fr))] gap-4 md:gap-8">
            {productData.colours.colours.map((colour) => (
              <li className="mt-4 flex flex-nowrap items-center justify-start" key={colour.name}>
                <div
                  className="h-16 w-16 shrink-0 rounded-full border border-gray-200 bg-center"
                  style={{ backgroundColor: colour.hex }}
                  title={colour.name}
                />
                <div className="ml-4 text-sm">{colour.name}</div>
              </li>
            ))}
          </ul>
          <p className="mt-8 text-sm text-gray-600">{productData.colours.notes}</p>
        </FooterBlock>
      )}
    </FooterContainer>
  )
}
