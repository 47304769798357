import { ScaleType, SCALE_TYPE } from '@prodigi-group/components-image-editor'

import {
  calculateScaleFactorAdjustment,
  convertBorderWidthToPrintPx,
  convertPositionToPrintPx,
  convertPrintSizeScaleToFillScale
} from '.'
import { TemplateDataInterface } from '../../../hooks/useTemplateService.hook'
import { convertPrintPxPositionToUserUnit } from './convertPrintPxPositionToUserUnit.helper'
import { ArtworkTransformationsInterface, ArtworkInterface, ImageEditorTransformationsInterface } from '../interfaces'

export function buildPigTransformations({
  artwork,
  transformations,
  template
}: {
  artwork: ArtworkInterface
  transformations: ArtworkTransformationsInterface
  template: TemplateDataInterface
}) {
  const artworkDimensionsInPx = {
    height: artwork.artworkHeight,
    width: artwork.artworkWidth
  }

  const scaleFactorAdjustment = calculateScaleFactorAdjustment(
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    template.printDpi,
    transformations.borderFactor,
    transformations.borderScale
  )
  const scaleFactorInPigFriendlyFormat = (transformations.scaleFactor * scaleFactorAdjustment) / 100
  const pigPosition = convertPrintPxPositionToUserUnit(
    transformations.position,
    transformations.borderScale,
    template.printResolution,
    artworkDimensionsInPx,
    template.cropRectangle,
    template.printDpi
  )

  const imageEditorTransformations: ImageEditorTransformationsInterface = {
    scaleFactor: scaleFactorInPigFriendlyFormat,
    scaleType: SCALE_TYPE.PRINT_SIZE as ScaleType,
    position: pigPosition,
    positionUnit: transformations.borderScale,
    rotationAngle: transformations.rotationAngle,
    borderWidth: transformations.borderFactor,
    borderUnit: transformations.borderScale,
    isTiled: transformations.isTiled
  }

  const { scaleFactor, rotationAngle, position, borderWidth, borderUnit, positionUnit } = imageEditorTransformations
  const printDimensionsInPx = template.printResolution
  const printDpi = template.printDpi

  const fillScaleFactor = convertPrintSizeScaleToFillScale(
    scaleFactor,
    printDpi,
    borderUnit,
    borderWidth,
    printDimensionsInPx,
    artworkDimensionsInPx,
    template.cropRectangle
  )

  const borderWidthInPrintPx = convertBorderWidthToPrintPx(printDpi, borderWidth, borderUnit)

  const printPxPosition = convertPositionToPrintPx(
    printDimensionsInPx,
    artworkDimensionsInPx,
    template.cropRectangle,
    printDpi,
    positionUnit,
    position
  )

  return {
    variant: `cover:${printDimensionsInPx.width}x${printDimensionsInPx.height}`,
    scale: String(fillScaleFactor),
    rotate: `${-rotationAngle}`,
    translate: `${Math.round(printPxPosition.x)},${Math.round(printPxPosition.y)}`,
    border: `${Math.round(borderWidthInPrintPx)}px`,
    tile: String(transformations.isTiled)
  }
}
