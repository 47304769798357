import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { fetcher, mapV3AttributesToV4 } from '../../../helpers'
import { ProductVariantRepresentationInterface } from '../../../hooks/useSalesChannelProduct.hook'
import { VariantDataInterface } from '../../SalesChannelProduct/SalesChannelProduct.component'

export async function saveLineItemVariantData({
  merchantId,
  newVariantData,
  productId,
  salesChannelId
}: {
  merchantId: number | null
  newVariantData: Omit<VariantDataInterface, 'title'>
  productId: string
  salesChannelId: string
}) {
  const payload: Omit<ProductVariantRepresentationInterface, 'title'> = {
    ...newVariantData,
    artworkData:
      newVariantData.artworkData && Object.keys(newVariantData.artworkData).length > 0
        ? Object.keys(newVariantData.artworkData).reduce((artworkDataAcc: PrintAreasArtworkDataType, printAreaName) => {
            if (!newVariantData.artworkData?.[printAreaName]?.printImageUrl) {
              return {
                ...artworkDataAcc,
                [printAreaName]: null
              }
            }

            return {
              ...artworkDataAcc,
              [printAreaName]: newVariantData.artworkData[printAreaName]
            }
          }, {})
        : null,
    selectedProductData: newVariantData.selectedProductData
      ? {
          ...newVariantData.selectedProductData,
          attributes: newVariantData?.selectedProductData?.attributes
            ? mapV3AttributesToV4(newVariantData.selectedProductData.attributes)
            : null
        }
      : undefined
  }

  return fetcher(
    `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/products/merchant/${merchantId}/saleschannel/${salesChannelId}/product/${productId}/variant/${newVariantData.id}/external`,
    { body: JSON.stringify(payload), method: 'PUT' }
  )
}
