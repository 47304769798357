// @flow
import React from 'react'
import type { Cost, AppState, DispatchFunc, Dictionary, FormattedCsvRow } from '../../../../types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  resetCsvUploadState,
  submitCsvOrders,
  toggleIsUsSalesTaxCollectedForAllOrders
} from '../../../../actions/csvUpload'
import {
  getV4OrderTotal,
  getOrdersRequiringAction,
  getOrdersWithErrors,
  getIsEveryOrderCompleted,
  getNumberOfIgnoredOrders,
  getTotalSubmittedOrders,
  getUploadedOrders,
  getHasV4ProductDetailsForAllCsvItemsToSubmit,
  getIsFetchingCsvOrderQuotes
} from '../../../../selectors/csvUpload'
import WithRsaa from '../../../../components/hoc/WithRsaa'
import WithUserDetails from '../../../../components/hoc/WithUserDetails'
import { getCurrencyCode } from '../../../../selectors/user'
import CsvUploadOverviewController from './CsvUploadOverviewController'
import { SUBMIT_CSV_ORDERS } from '../../../../actions/csvUpload/orderSubmissionActions'
import {
  getIsAnyCsvOrderForUnitedStates,
  getIsUsSalesTaxCollectedForAllUsOrders
} from '../../../../selectors/csvUpload/csvUpload'
import type { Props } from './CsvUploadOverviewController'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

// $FlowFixMe: TypeScript file
import { useSplitToggle } from '../../../../v2/hooks'

type DispatchProps = {|
  resetCsvUploadState: () => void,
  submitCsvOrders: () => void,
  toggleUsSalesTax: () => void
|}

type StateProps = {|
  totalSubmittedOrders: number,
  currencyCode: string,
  ordersRequiringAction: Dictionary<FormattedCsvRow>,
  isEveryOrderCompleted: boolean,
  totalIgnoredOrders: number,
  uploadedOrders: Dictionary<FormattedCsvRow>,
  ordersWithErrors: Dictionary<FormattedCsvRow>,
  hasAllV4ProductDetails: boolean,
  isFetchingQuotes: boolean,
  isUsSalesTaxCollectedForAllUsOrders: boolean,
  isAnyCsvOrderForUnitedStates: boolean,
  totalCsvCost: Cost
|}

function mapStateToProps(state: AppState): StateProps {
  return {
    totalCsvCost: getV4OrderTotal(state),
    isAnyCsvOrderForUnitedStates: getIsAnyCsvOrderForUnitedStates(state),
    isUsSalesTaxCollectedForAllUsOrders: getIsUsSalesTaxCollectedForAllUsOrders(state),
    ordersRequiringAction: getOrdersRequiringAction(state),
    ordersWithErrors: getOrdersWithErrors(state),
    isEveryOrderCompleted: getIsEveryOrderCompleted(state),
    totalIgnoredOrders: getNumberOfIgnoredOrders(state),
    uploadedOrders: getUploadedOrders(state),
    totalSubmittedOrders: getTotalSubmittedOrders(state),
    currencyCode: getCurrencyCode(state),
    hasAllV4ProductDetails: getHasV4ProductDetailsForAllCsvItemsToSubmit(state),
    isFetchingQuotes: getIsFetchingCsvOrderQuotes(state)
  }
}

function mapDispatchToProps(dispatch: DispatchFunc): DispatchProps {
  return {
    toggleUsSalesTax: () => {
      dispatch(toggleIsUsSalesTaxCollectedForAllOrders())
    },
    resetCsvUploadState: () => dispatch(resetCsvUploadState()),
    submitCsvOrders: (isTransformUrlsOn?: boolean) => {
      window.analytics.track('Submit csv order', { label: 'csv uploader' })
      dispatch(submitCsvOrders(isTransformUrlsOn))
    }
  }
}

export default (compose(
  WithUserDetails,
  WithRsaa(SUBMIT_CSV_ORDERS, 'submitCsv', false),
  connect(mapStateToProps, mapDispatchToProps)
)(CsvUploadOverviewSplit): any)

function CsvUploadOverviewSplit(props: Props) {
  const { splitIsOn: isTransformUrlsOn } = useSplitToggle({ toggle: FEATURE_NAMES.TRANSORM_URLS })

  return <CsvUploadOverviewController {...props} isTransformUrlsOn={isTransformUrlsOn} />
}
