// @flow
import { connect } from 'react-redux'
import CsvOrderController from './CsvOrderController'
import type { Quote, RsaaStatus, FormattedOrderItem, Status, AppState, DispatchFunc, FormattedCsvRow, Dictionary, CsvItemProductDetails, CsvOrderUploadProgress } from '../../../../types'
import { addIgnoredOrder, removeIgnoredOrder } from '../../../../actions/csvUpload'
import {
  getIsMultiselectModeOn,
  getCsvItemProductDetails,
  getCsvOrderUploadProgress,
  getProductDetailsProgressBySkus,
  getIsOrderIgnored,
  getCsvOrderItemsByOrderId,
  getV4ProductDetailRsaaStatusesForCsvOrder,
  getCsvOrderQuotesByOrderId
} from '../../../../selectors/csvUpload'
import { getCurrencyCode } from '../../../../selectors/user'
import { values } from '../../../../helpers/dictionary'
import { removeDuplicates } from '../../../../helpers/array'
import { getArtworkStatusByIds } from '../../../../selectors/images'
import { getCsvOrderQuoteRsaaStatusByOrderId } from '../../../../selectors/quotes'
import { buildImageId } from '../../../../actions/images'
import type { ComponentType } from 'react'

type OwnProps = {|
  order: FormattedCsvRow,
  currencyCode: string
|}

type StateProps = {|
  productDetails: Dictionary<CsvItemProductDetails>,
  uploadProgress: ?CsvOrderUploadProgress,
  productDetailsProgress: Dictionary<Status>,
  artworkStatus: Dictionary<Status>,
  isIgnored: boolean,
  currencyCode: string,
  quotes: Quote[],
  isMultiselectModeOn: boolean,
  orderItems: Dictionary<FormattedOrderItem>,
  v4ProductDetailRsaaStatuses: Dictionary<RsaaStatus>,
  quoteRsaaStatus: RsaaStatus
|}

type DispatchProps = {|
  addIgnoredOrder: (index: string) => void,
  removeIgnoredOrder: (index: string) => void
|}

function mapStateToProps (state: AppState, ownProps: OwnProps): StateProps {
  const orderItems = getCsvOrderItemsByOrderId(state, ownProps.order.id)
  const allSkus = removeDuplicates(values(orderItems).map(item => item.sku))
  const artworkIds = values(orderItems).reduce((artworkIdAcc, orderItem) => {
    const itemArtworkIds = Object.keys(orderItem.printAreaImageUrls)
      .map(printAreaName => buildImageId(orderItem.id, printAreaName))

    return [...artworkIdAcc, ...itemArtworkIds]
  }, [])

  return {
    isMultiselectModeOn: getIsMultiselectModeOn(state),
    productDetails: getCsvItemProductDetails(state, ownProps.order.id),
    uploadProgress: getCsvOrderUploadProgress(state, ownProps.order.id),
    productDetailsProgress: getProductDetailsProgressBySkus(state, allSkus),
    isIgnored: getIsOrderIgnored(state, ownProps.order.id),
    currencyCode: getCurrencyCode(state),
    quotes: getCsvOrderQuotesByOrderId(state, ownProps.order.id),
    quoteRsaaStatus: getCsvOrderQuoteRsaaStatusByOrderId(state, ownProps.order.id),
    artworkStatus: getArtworkStatusByIds(state, artworkIds),
    v4ProductDetailRsaaStatuses: getV4ProductDetailRsaaStatusesForCsvOrder(state, ownProps.order.id),
    orderItems
  }
}

function mapDispatchToProps (dispatch: DispatchFunc, ownProps: OwnProps): DispatchProps {
  return {
    addIgnoredOrder: (index: string) => dispatch(addIgnoredOrder(index)),
    removeIgnoredOrder: (index: string) => dispatch(removeIgnoredOrder(index))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CsvOrderController): ComponentType<OwnProps>)
