import { areAllRequiredAddressFieldsFilledIn } from '.'
import { OrderDetailRecipientInterface } from '../../../interfaces'
import { isPostcodeRequired, isPostcodeValid } from '../../../helpers'

export function isAddressValid(recipient: OrderDetailRecipientInterface, checkContactInfo: boolean): boolean {
  const postcodeValid = isPostcodeRequired(recipient.address.countryCode)
    ? isPostcodeValid(recipient.address.countryCode, recipient.address.postcodeOrZipCode)
    : true
  const allRequiredAddressFieldsFilledIn = areAllRequiredAddressFieldsFilledIn(recipient, checkContactInfo)

  return Boolean(allRequiredAddressFieldsFilledIn && postcodeValid)
}
