import { useSelector } from 'react-redux'

import {
  getIsSearchInconclusive,
  selectSelectedItemCategory,
  selectSelectedProductType
} from '../../../../selectors/catalogue'
import { CATALOGUE_PRODUCTS } from '../../../../v2/constants'

export default function ProductImage() {
  const selectedItemCategory = useSelector(selectSelectedItemCategory)
  const selectedProductType = useSelector(selectSelectedProductType)
  const isSearchInconclusive = useSelector(getIsSearchInconclusive)

  const categoryImage = CATALOGUE_PRODUCTS[selectedItemCategory]?.image
  const productTypeImage = CATALOGUE_PRODUCTS[selectedItemCategory]?.productTypes[selectedProductType]?.image

  const noProductImageFound = !categoryImage && !productTypeImage

  if (noProductImageFound || isSearchInconclusive) {
    return null
  }

  return (
    <div className="tailwind">
      <img
        alt={selectedProductType || selectedItemCategory}
        src={productTypeImage || categoryImage}
        className="h-72 w-72 object-contain object-center"
      />
    </div>
  )
}
