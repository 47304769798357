// @flow
import type { ThunkAsync, DispatchFunc, FindProductResponseBody, AzureSearchResult, RequestOptions } from '../../types'
import { selectAzureSearchSettings } from '../../selectors/appSettings'
import { logLe, LOG_LEVEL } from '../logging'
import { getOwnProperties } from '../../helpers/error'
import Option, { returnValue } from '../../helpers/Option'

export type AzureSearchProduct = {|
  product: AzureSearchResult
|}

export function findProduct (sku: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc): Promise<AzureSearchProduct | null> => {
    const [url, requestOptions] = configureRequest(sku)

    try {
      return (await fetchProduct(sku, url, requestOptions))
        .whenSome(returnValue)
        .whenNone(() => {
          dispatch(logLe(`Product ${sku} not found`, { url }, LOG_LEVEL.WARNING))
          return null
        })
    } catch (error) {
      dispatch(logLe('Find product failed', { ...getOwnProperties(error), sku, url }, LOG_LEVEL.ERROR))
      return null
    }
  }
}

function configureRequest (sku: string): [string, RequestOptions] {
  const searchSettings = selectAzureSearchSettings()
  const url = searchSettings.endpoint + `&search="${sku.toLowerCase()}"`
  const requestOptions = {method: 'GET', headers: { 'api-key': searchSettings.key }}

  return [url, requestOptions]
}

function fetchProduct (sku: string, url: string, requestOptions: RequestOptions): Promise<Option<AzureSearchProduct>> {
  return window.fetch(url, requestOptions)
    .then(response => response.json())
    .then(jsonResponse => mapResponse(jsonResponse, sku))
}

function mapResponse (response: FindProductResponseBody, sku: string): Option<AzureSearchProduct> {
  return response.value
    ? findProductWithExactMatch(response.value, sku)
    : Option.none()
}

function findProductWithExactMatch (searchResult: AzureSearchResult[], sku: string): Option<AzureSearchProduct> {
  const searchResultItem = searchResult.find(searchResultItem => searchResultItem.sku.toLowerCase() === sku.toLowerCase())

  return searchResultItem
    ? Option.some({ product: searchResultItem })
    : Option.none()
}
