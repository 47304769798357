// @flow

import * as React from 'react'
import classNames from 'classnames'
import styles from './Container.module.css'

type Props = {|
  className?: string,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
  children: React$Node
|}

function Container ({ size, children, className }: Props): React$Node {
  const fullClassName = classNames(styles.container, className, {
    [styles.containerXs]: size === 'xs',
    [styles.containerSm]: size === 'sm',
    [styles.containerMd]: size === 'md',
    [styles.containerLg]: size === 'lg',
    [styles.containerXl]: size === 'xl',
    [styles.containerFull]: size === 'full'
  })

  return (
    <div className={fullClassName}>
      {children}
    </div>
  )
}

export default Container
