import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'
import { ImageLibraryImageInterface } from './useImageLibraryImages.hook'

export function useImageLibraryThumbnailGeneration(
  imageLibraryId?: string,
  { config = {}, touch = true }: { config?: SWRConfiguration; touch?: boolean } = {}
) {
  const { data, error } = useSWR<ImageLibraryImageInterface, FetchErrorInterface<{ detail?: string }>>(
    imageLibraryId
      ? `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryId}/thumbnail?touch=${touch}`
      : null,
    fetcher,
    config
  )

  return {
    imageLibraryImage: data,
    isLoadingImageLibraryImage: imageLibraryId ? !data && !error : false,
    imageLibraryImageFetchError: error
  }
}
