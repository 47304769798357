// @flow
import React, { PureComponent } from 'react'
import CsvImageModalView from './CsvImageModalView'
import type {
  Dictionary,
  ArtworkTransformations,
  ThumbnailItem,
  Status,
  FormattedOrderItem,
  CatalogueItem,
  V4ProductDetails,
  PrintAreaEntries,
  MultiAssetTemplates,
  Artwork,
  PrintAreaArtwork,
  ArtworkProgress
} from '../../../../types'
import { buildImageId } from '../../../../actions/images'
import { ERROR } from '../../../../data/rsaa'
import { setDefaultPrintAreaEntryAsFirst } from '../../../../helpers/setDefaultPrintAreaEntryAsFirst'
import { entries } from '../../../../helpers/dictionary'
import { buildCroppedImageUrls } from '../../../../actions/manualOrderForm'
import type { ImageEditorSaveDataType } from '../../../../types/images'

type Props = {|
  thumbnailItem: ?ThumbnailItem,
  templateStatus: Status,
  templates: ?MultiAssetTemplates,
  artworks: Dictionary<Artwork>,
  allArtworkProgress: Dictionary<ArtworkProgress>,
  artworkTransformations: Dictionary<ArtworkTransformations>,
  imageStatus: Status,
  item: FormattedOrderItem,
  catalogueDetails: CatalogueItem,
  orderId: string,
  imageErrorMessage: string,
  v4ProductDetails: V4ProductDetails,
  closeModal: () => void,
  removeArtwork: (orderItemId: string, printAreaName: string) => void,
  uploadCsvArtworkFromUrl: (itemId: string, url: string, isTilingEnabled: boolean) => void,
  uploadCsvArtworkFromFile: (itemId: string, file: File, isTilingEnabled: boolean, printAreaName: string) => void,
  onSelectCsvImageLibraryImage: (selectionData: {|
    artwork: Artwork,
    itemId: string,
    isTilingEnabled: boolean,
    printAreaName: string
  |}) => void,
  updateCsvItemArtwork: (
    transformations: ArtworkTransformations,
    pigUrl: string,
    id: string,
    printAreaName: string,
    transformImageUrl: string
  ) => void
|}

type State = {|
  editImage: boolean,
  isTilingEnabled: boolean
|}

export default class CsvImageModalController extends PureComponent<Props, State> {
  state: State = {
    editImage: false,
    isTilingEnabled: false
  }

  uploadCsvArtworkFromFile: (file: File) => void = (file: File) => {
    if (!this.props.item.selectedPrintArea) {
      return
    }
    this.props.uploadCsvArtworkFromFile(
      this.props.item.id,
      file,
      this.state.isTilingEnabled,
      this.props.item.selectedPrintArea
    )
  }

  uploadCsvArtworkFromUrl: (url: string) => void = (url: string) =>
    this.props.uploadCsvArtworkFromUrl(this.props.item.id, url, this.state.isTilingEnabled)

  uploadArtwork: (event: SyntheticInputEvent<HTMLInputElement>, printAreaName: string) => void = (
    event: SyntheticInputEvent<HTMLInputElement>,
    printAreaName: string
  ) => {
    const fileSelected = event.target.files[0]
    if (!fileSelected) {
      return
    }
    this.props.uploadCsvArtworkFromFile(this.props.item.id, fileSelected, this.state.isTilingEnabled, printAreaName)
  }

  onSelectCsvImageLibraryImage: (artwork: Artwork) => void = (artwork) => {
    if (!this.props.item.selectedPrintArea) {
      return
    }
    this.props.onSelectCsvImageLibraryImage({
      artwork,
      isTilingEnabled: this.state.isTilingEnabled,
      itemId: this.props.item.id,
      printAreaName: this.props.item.selectedPrintArea
    })
  }

  onSelectCsvImageLibraryImageFromEditor: (selectionData: {|
    artwork: Artwork,
    artworkTransformations: ArtworkTransformations,
    selectedPrintArea: string
  |}) => void = (selectionData) => {
    this.props.onSelectCsvImageLibraryImage({
      artwork: selectionData.artwork,
      isTilingEnabled: this.state.isTilingEnabled,
      itemId: this.props.item.id,
      printAreaName: selectionData.selectedPrintArea
    })
  }

  onEditImageClick: () => void = () => {
    if (document.body) {
      document.body.scrollTop = 0
    }

    if (document.documentElement) {
      document.documentElement.scrollTop = 0
    }

    this.setState({ editImage: true })
  }

  onSaveCrop: (
    artworkTransformations: Dictionary<?ArtworkTransformations>,
    selectedPrintArea: string,
    additionalData: ImageEditorSaveDataType
  ) => void = (
    artworkTransformations: Dictionary<?ArtworkTransformations>,
    selectedPrintArea: string,
    additionalData: ImageEditorSaveDataType
  ) => {
    if (!this.props.templates) {
      return
    }

    const pigUrl = buildCroppedImageUrls(this.itemPrintAreas, artworkTransformations, this.props.templates)

    Object.keys(this.itemPrintAreas).forEach((printArea) => {
      const artwork = this.itemPrintAreas[printArea].artwork
      if (!artworkTransformations[printArea] || !additionalData.transformDataByPrintArea[printArea]?.url) {
        return
      }
      const transformations = artworkTransformations[printArea]
      let croppedPigUrl = pigUrl[printArea]
      let transformImageUrl = additionalData.transformDataByPrintArea[printArea]?.url

      if (artwork && artwork?.mimeType === 'application/pdf') {
        croppedPigUrl = artwork.originalImageUrl
        transformImageUrl = artwork.originalImageUrl
      }

      this.props.updateCsvItemArtwork(transformations, croppedPigUrl, this.props.item.id, printArea, transformImageUrl)
    })

    this.setState({ editImage: false })
  }

  removeArtwork: (printAreaName: string) => void = (printAreaName: string) => {
    this.props.removeArtwork(this.props.item.id, printAreaName)
  }

  onSaveAndClose: () => void = () => this.props.closeModal()

  onCancelCrop: () => void = () => this.setState({ editImage: false })

  toggleTiling: () => void = () => this.setState((state) => ({ isTilingEnabled: !state.isTilingEnabled }))

  get imageUploadFailed(): boolean {
    return this.props.imageStatus === ERROR
  }

  get printAreaEntries(): PrintAreaEntries {
    return setDefaultPrintAreaEntryAsFirst(entries(this.props.v4ProductDetails.printAreas))
  }

  get selectedPrintArea(): string {
    const printAreaName = this.props.item.selectedPrintArea

    if (!printAreaName) {
      throw new Error(`selectedPrintArea cannot be null, itemId: ${this.props.item.id}, sku - ${this.props.item.sku}`)
    }

    return printAreaName
  }

  get itemPrintAreas(): Dictionary<PrintAreaArtwork> {
    const itemPrintAreas = Object.keys(this.props.v4ProductDetails.printAreas).reduce(
      (itemPrintAreasAcc: Dictionary<PrintAreaArtwork>, printAreaName) => {
        const imageId = buildImageId(this.props.item.id, printAreaName)
        return {
          ...itemPrintAreasAcc,
          [printAreaName]: {
            artwork: this.props.artworks[imageId] ?? null,
            artworkTransformations: this.props.artworkTransformations[imageId] ?? null
          }
        }
      },
      {}
    )
    return itemPrintAreas
  }

  get optimalResolution(): ?string {
    try {
      const selectedPrintArea = this.props.item.selectedPrintArea ?? ''
      const template = this.props.templates?.printAreas?.[selectedPrintArea]
      const imageId = buildImageId(this.props.item.id, selectedPrintArea)
      const orientation = this.props.artworkTransformations[imageId].orientation
      const { printDpi, printResolution } = template?.orientations[orientation] ?? {}

      return `${printResolution.width}x${printResolution.height}px (${printDpi}dpi)`
    } catch {
      return null
    }
  }

  renderContent(): React$Node {
    return (
      <CsvImageModalView
        allArtworkProgress={this.props.allArtworkProgress}
        templates={this.props.templates}
        item={this.props.item}
        itemPrintAreas={this.itemPrintAreas}
        thumbnailItem={this.props.thumbnailItem}
        toggleTiling={this.toggleTiling}
        isTilingEnabled={this.state.isTilingEnabled}
        catalogueDetails={this.props.catalogueDetails}
        uploadCsvArtworkFromFile={this.uploadCsvArtworkFromFile}
        uploadCsvArtworkFromUrl={this.uploadCsvArtworkFromUrl}
        onEditImageClick={this.onEditImageClick}
        editImage={this.state.editImage}
        closeModal={this.props.closeModal}
        imageUrl={this.props.item.printAreaImageUrls[this.props.item?.selectedPrintArea ?? ''] ?? null}
        templateStatus={this.props.templateStatus}
        imageStatus={this.props.imageStatus}
        imageUploadFailed={this.imageUploadFailed}
        onSaveCrop={this.onSaveCrop}
        onSaveAndClose={this.onSaveAndClose}
        onCancelCrop={this.onCancelCrop}
        imageErrorMessage={this.props.imageErrorMessage}
        printAreaEntries={this.printAreaEntries}
        selectedPrintArea={this.selectedPrintArea}
        uploadArtwork={this.uploadArtwork}
        removeArtwork={this.removeArtwork}
        optimalResolution={this.optimalResolution}
        onSelectCsvImageLibraryImage={this.onSelectCsvImageLibraryImage}
        onSelectCsvImageLibraryImageFromEditor={this.onSelectCsvImageLibraryImageFromEditor}
      />
    )
  }

  render(): React$Node {
    return this.renderContent()
  }
}
