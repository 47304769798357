// @flow
import { mapHeaders, splitCsvColumnsIfNecessary } from '.'
import {
  WOOCOMMERCE_NAME_HEADERS,
  WOOCOMMERCE_LAST_NAME_HEADER,
  WOOCOMMERCE,
  DESCRIPTION,
  UNKNOWN_FORMAT
} from '../../data/csvUpload'
import type { Dictionary, CsvFileType } from '../../types'

export function formatCsvRows(csvRows: Dictionary<string>[], fileType: CsvFileType): Dictionary<string>[] {
  const csvRowsSplitIfNecessary = splitCsvColumnsIfNecessary(csvRows, fileType)

  const formattedHeaders = mapHeaders(Object.keys(csvRowsSplitIfNecessary[0]), fileType)
  const requiredFields = []

  csvRowsSplitIfNecessary.forEach((row) => {
    if (!row) {
      return
    }

    const reduced = Object.keys(row).reduce((orderAcc, key) => {
      if (!formattedHeaders[key] || !row[key]) {
        return orderAcc
      }

      if (formattedHeaders[key] === DESCRIPTION && orderAcc[formattedHeaders[key]]) {
        orderAcc[formattedHeaders[key]] += ', ' + row[key]
      } else if ((fileType === WOOCOMMERCE || fileType === UNKNOWN_FORMAT) && WOOCOMMERCE_NAME_HEADERS.includes(key)) {
        const mappedName = mapWooCommerceName(key, row[key], orderAcc[formattedHeaders[key]])
        orderAcc[formattedHeaders[key]] = mappedName
      } else {
        orderAcc[formattedHeaders[key]] = row[key]
      }

      return orderAcc
    }, {})

    requiredFields.push(reduced)
  })

  return requiredFields
}

export function mapWooCommerceName(key: string, value: string, currentValue: ?string): string {
  if (!currentValue || currentValue === '') {
    return value
  } else if (key === WOOCOMMERCE_LAST_NAME_HEADER) {
    return currentValue + ' ' + value
  } else {
    return value + ' ' + currentValue
  }
}
