// @noflow

import * as React from 'react'
import type { Style } from '../../../types'
import cx from 'classnames'
import styles from './Inputs.module.css'

type Props = {|
  children: React.Node,
  event: () => mixed,
  value: any,
  name: string,
  style: Style,
  eventNeedsTwoArgs: boolean,
  className?: string,
  dataTest?: string
|}

type State = {|
  id: string
|}

function getId ({ name, placeholder, type, value, ...rest }: Props): string {
  return `${(name || placeholder || `default_value:${value}` || 'no_name').toLowerCase().replace(/\s+/g, '_')}_${type || 'select'}_field`
}

class SelectField extends React.Component<Props, State> {
  state = {
    id: getId(this.props)
  }

  handleChange = (e) => {
    const { event, name, eventNeedsTwoArgs } = this.props

    if (eventNeedsTwoArgs) {
      event(name, e.target.value)
    } else {
      event(e.target.value)
    }
  }

  render (): React.Node {
    const { className, style, value, event, eventNeedsTwoArgs, dataTest, ...otherProps } = this.props
    return (
      <select
        data-test={dataTest ?? 'select-field'}
        id={this.state.id}
        value={value}
        onChange={this.handleChange}
        className={cx(styles.base, className)}
        style={style}
        {...otherProps}
      >
        {this.props.children}
      </select>
    )
  }
}

export default SelectField
