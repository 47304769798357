// @flow
import { compose } from 'redux'
import { connect } from 'react-redux'

import AddNewShop from './AddNewShop'
import type { AppState } from '../../types'
import { getUser } from '../../selectors/user'
import WithUserDetails from '../../components/hoc/WithUserDetails'

function mapStateToProps (state: AppState) {
  return {
    user: getUser(state)
  }
}

export default (compose(
  WithUserDetails,
  connect(mapStateToProps)
)(AddNewShop): any)
