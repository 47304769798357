// @flow
import productAttributeFormat from '../../helpers/productAttributeFormat'
import { getRangeQuery } from './getRangeQuery'
import type { GetStateFunc, FacetValue } from '../../types'

export function getFacetQuery(getState: GetStateFunc): string {
  const facets = getState().catalogue.facets
  let query = ''

  if (facets) {
    for (let facet of Object.keys(facets)) {
      const values = facets[facet].values.sort((s1, s2) => {
        if (typeof s1.value === 'string' && typeof s2.value === 'string') {
          let v1: string = s1.value.toString()
          let v2: string = s2.value.toString()

          if (v1) {
            v1 = productAttributeFormat(v1).toString()
          }

          if (v2) {
            v2 = productAttributeFormat(v2).toString()
          }

          return v1.localeCompare(v2)
        } else if (typeof s1.value === 'number' && typeof s2.value === 'number') {
          return s2.value - s1.value
        }

        return 0
      })

      const selectedFacetValues: FacetValue[] = values.filter((v) => v.selected)
      const emptyValue = selectedFacetValues.filter((v) => v.value === '')[0]
      const noneValue = selectedFacetValues.filter((v) => v.value === 'none')[0]
      const containsEmptyValue = emptyValue !== undefined
      const containsNoneValue = noneValue !== undefined

      if (containsEmptyValue && !containsNoneValue) {
        const newValue = { ...noneValue, value: '' }
        selectedFacetValues.push(newValue)
      }

      if (containsNoneValue && !containsEmptyValue) {
        const newValue = { ...emptyValue, value: 'none' }
        selectedFacetValues.push(newValue)
      }

      let valuesString = selectedFacetValues
        .filter((v: any) => v !== '' && v !== 'none')
        .map((v) => `${encodeURIComponent(v.value.toString())}`)
        .join('|')

      if (facet === 'maxProductDimensionsMm' || facet === 'productAspectRatio') {
        query += getRangeQuery(facet, selectedFacetValues)
      } else {
        if (values.length > 1 && selectedFacetValues.length > 0) {
          if (containsEmptyValue) {
            query += ` and (${facet}/any(t: search.in(t, '${valuesString}', '|')) or ${facet}/any(t: t eq ''))`
          } else {
            query += ` and ${facet}/any(t: search.in(t, '${valuesString}', '|'))`
          }
        }
      }
    }
  }

  return query
}
