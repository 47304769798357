import { calcPrintDimensionsFitScaleFactor } from '@prodigi-group/components-image-editor'

import { DimensionsInterface } from '../interfaces'

export function calculateFitScaleFactor(
  printResolutionInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  borderInPx: number,
  rotationAngle: number
): number {
  const scaleFactor = calcPrintDimensionsFitScaleFactor(
    printResolutionInPx,
    artworkDimensionsInPx,
    borderInPx,
    rotationAngle
  )

  return scaleFactor
}
