// @flow
import React, { PureComponent } from 'react'
import CsvItemImageView from './CsvItemImageView'
import type {
  CatalogueItem,
  Status,
  ThumbnailItem,
  CsvItemProductDetails,
  FormattedOrderItem,
  V4ProductDetails,
  Artwork
} from '../../../../types'
import { hasAllRequiredAttributes, hasImage } from '../../../../helpers/csvUpload'
import { LOADING, ERROR } from '../../../../data/rsaa'

type Props = {|
  artworkTransformationStatus: Status | null,
  artwork: ?Artwork,
  templateStatus: Status,
  imageStatus: Status,
  catalogueDetails: ?CatalogueItem,
  thumbnailItem: ?ThumbnailItem,
  isImageModalOpen: boolean,
  canBeEdited: boolean,
  orderId: string,
  imageStyle: string,
  item: FormattedOrderItem,
  details: ?CsvItemProductDetails,
  hasArtworkTransformation: boolean,
  v4ProductDetails: ?V4ProductDetails,
  imageErrorMessage: string,
  openImageModal: (orderItemId: string) => void,
  closeImageModal: () => void
|}

type State = {|
  hasImageError: boolean,
  hasArtworkRenderError: boolean,
  isArtworkLoading: boolean,
  isOverlayLoading: boolean
|}

export default class CsvItemImageController extends PureComponent<Props, State> {
  state: State = {
    isArtworkLoading: true,
    isOverlayLoading: true,
    hasArtworkRenderError: false,
    hasImageError: false
  }

  componentDidUpdate(previousProps: Props) {
    if (this.props.imageStatus !== previousProps.imageStatus && this.props.imageStatus === LOADING) {
      this.setState({ hasImageError: false })
    } else if (this.props.artworkTransformationStatus === ERROR) {
      this.setState({ hasImageError: true })
    }
  }

  get selectedPrintAreaImageUrl(): ?string {
    return this.props.item.printAreaImageUrls[this.props.item?.selectedPrintArea ?? ''] ?? null
  }

  get canDisplayThumbnail(): boolean {
    return Boolean(
      !this.state.hasImageError &&
        this.selectedPrintAreaImageUrl &&
        this.props.thumbnailItem &&
        this.props.hasArtworkTransformation &&
        this.props.artworkTransformationStatus !== ERROR
    )
  }

  get imageUploadButtonText(): string {
    if (!this.selectedPrintAreaImageUrl) {
      return 'Upload image'
    } else if (this.props.hasArtworkTransformation) {
      return 'Edit image'
    } else {
      return 'Change image'
    }
  }

  openImageModal: () => void = () => this.props.openImageModal(this.props.item.id)

  get canEdit(): boolean {
    return Boolean(
      this.props.canBeEdited &&
        this.props.catalogueDetails &&
        this.props.v4ProductDetails &&
        this.props.details &&
        !this.props.details.noImageProduct &&
        hasAllRequiredAttributes(this.props.item.selectedAttributes, this.props.details.requiredAttributes) &&
        this.props.artworkTransformationStatus !== ERROR
    )
  }

  get isLoadingPreview(): boolean {
    return (
      this.props.imageStatus === LOADING ||
      Boolean(
        this.selectedPrintAreaImageUrl &&
          !this.hasImageError &&
          this.props.catalogueDetails &&
          this.props.thumbnailItem?.artwork?.mimeType !== 'application/pdf' &&
          !this.props.catalogueDetails.noImageProduct &&
          this.props.hasArtworkTransformation &&
          (this.state.isOverlayLoading || this.state.isArtworkLoading)
      )
    )
  }

  get hasImageError(): boolean {
    return this.state.hasImageError && this.state.hasArtworkRenderError
  }

  onArtworkRenderError: () => void = () => this.setState({ hasArtworkRenderError: true })

  onLoadOverlay: () => void = () => this.setState({ isOverlayLoading: false })

  onLoadArtwork: () => void = () => this.setState({ isArtworkLoading: false, hasArtworkRenderError: false })

  get imageEditorKey(): string {
    return [
      'image-preview',
      this.props.item.id,
      this.props.templateStatus,
      this.props.imageStatus,
      this.props.thumbnailItem?.imageUrls.artwork || '',
      this.props.thumbnailItem?.imageUrls.overlay || ''
    ].join('-')
  }

  get isImageComplete(): boolean {
    const thumbnailArtworkUrl = this.props.artwork?.croppedImageUrl
    return this.props.details && thumbnailArtworkUrl
      ? hasImage(this.props.details, thumbnailArtworkUrl, this.props.imageStatus) && this.props.hasArtworkTransformation
      : false
  }

  render(): React$Node {
    return (
      <CsvItemImageView
        catalogueDetails={this.props.catalogueDetails}
        templateStatus={this.props.templateStatus}
        openImageModal={this.openImageModal}
        closeImageModal={this.props.closeImageModal}
        imageStatus={this.props.imageStatus}
        isLoadingPreview={this.isLoadingPreview}
        isImageModalOpen={this.props.isImageModalOpen}
        orderId={this.props.orderId}
        item={this.props.item}
        thumbnailItem={this.props.thumbnailItem}
        canDisplayThumbnail={this.canDisplayThumbnail}
        isImageComplete={this.isImageComplete}
        canEdit={this.canEdit}
        imageUploadButtonText={this.imageUploadButtonText}
        hasImageError={this.state.hasImageError}
        imageErrorMessage={this.props.imageErrorMessage}
        imageStyle={this.props.imageStyle}
        imageEditorKey={this.imageEditorKey}
        v4ProductDetails={this.props.v4ProductDetails}
        onArtworkRenderError={this.onArtworkRenderError}
        onLoadArtwork={this.onLoadArtwork}
        onLoadOverlay={this.onLoadOverlay}
      />
    )
  }
}
