// @flow
import type { Quote, FormattedCsvRow, FormattedOrderItem, CsvItemProductDetails, Dictionary, Status } from '../../types'
import { values, entries } from '../../helpers/dictionary'
import { isAddressValid } from '../../helpers'
import { SUCCESS } from '../../data/rsaa'
import { buildImageId } from '../../actions/images'

export function isOrderCompleted (
  order: FormattedCsvRow,
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CsvItemProductDetails>,
  artworkStatus: Dictionary<Status>,
  quotes: Quote[]
): boolean {
  return Boolean(
    isShippingMethodValid(order.preferredShippingMethod, quotes) &&
    isOrderAddressValid(order) &&
    Object.keys(orderItems).length &&
    isEveryItemCompleted(orderItems, productDetails, artworkStatus)
  )
}

export function hasAllRequiredAttributes (itemAttributes: Dictionary<string>, requiredAttributes: string[]): boolean {
  const itemAttributeNames = Object.keys(itemAttributes)
  return requiredAttributes.every(requiredAttribute => itemAttributeNames.includes(requiredAttribute))
}

export function everyItemHasDetails (orderItems: Dictionary<FormattedOrderItem>, productDetails: Dictionary<CsvItemProductDetails>): boolean {
  return values(orderItems).every(item => hasAllProductDetails(item, productDetails[item.sku]))
}

export function everyItemHasImages (orderItems: Dictionary<FormattedOrderItem>, productDetails: Dictionary<CsvItemProductDetails>, artworkStatus: Dictionary<Status>): boolean {
  return getPrintAreasWithoutImages(orderItems, productDetails, artworkStatus).length === 0
}

export function getPrintAreasWithoutImages (
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CsvItemProductDetails>,
  artworkStatus: Dictionary<Status>
): Array<[string, string, string]> {
  return values(orderItems).reduce((printAreaAcc, item) => {
    const requiredPrintAreas = entries(productDetails[item.sku].printAreas).reduce((printAreaNameAcc, [printAreaName, { required }]) => {
      if (printAreaName) {
        return printAreaNameAcc.concat(printAreaName)
      }
      return printAreaNameAcc
    }, [])

    const imageIdsAndPrintAreaNames = requiredPrintAreas.map(printAreaName => {
      return [buildImageId(item.id, printAreaName), printAreaName, item.sku]
    })

    const itemsWithMissingImages = imageIdsAndPrintAreaNames.filter(([imageId, printAreaName]) => {
      return !hasImage(productDetails[item.sku], item.printAreaImageUrls[printAreaName], artworkStatus[imageId])
    })

    return [...printAreaAcc, ...itemsWithMissingImages]
  }, [])
}

export function isShippingMethodValid (
  preferredShippingMethod: string,
  quotes: Quote[]
): boolean {
  return quotes.some(quote => quote.shipmentMethod.toUpperCase() === preferredShippingMethod.toUpperCase())
}

export function isOrderAddressValid (order: FormattedCsvRow): boolean {
  return isAddressValid(order.customer)
}

function isEveryItemCompleted (orderItems: Dictionary<FormattedOrderItem>, productDetails: Dictionary<CsvItemProductDetails>, artworkStatus: Dictionary<Status>): boolean {
  return values(orderItems).every(item => {
    const requiredPrintAreas = entries(productDetails[item.sku]?.printAreas ?? {})
      .reduce((printAreaNameAcc, [printAreaName, { required }]) => {
        if (required) {
          return printAreaNameAcc.concat(printAreaName)
        }
        return printAreaNameAcc
      }, [])

    if (productDetails[item.sku]?.noImageProduct) {
      return hasAllProductDetails(item, productDetails[item.sku])
    }

    return requiredPrintAreas.length > 0 && requiredPrintAreas.every(requiredPrintAreaName => {
      const imageId = buildImageId(item.id, requiredPrintAreaName)
      return isItemCompleted(item, productDetails[item.sku], artworkStatus[imageId], requiredPrintAreaName)
    })
  })
}

function isItemCompleted (
  item: FormattedOrderItem,
  productDetails: CsvItemProductDetails,
  imageUploadStatus: Status,
  printAreaName: string
): boolean {
  return (
    hasAllProductDetails(item, productDetails) &&
    hasImage(productDetails, item.printAreaImageUrls[printAreaName], imageUploadStatus)
  )
}

export function hasAllProductDetails (item: FormattedOrderItem, productDetails: CsvItemProductDetails): boolean {
  return (
    productDetails &&
    Boolean(item.sku) &&
    item.quantity > 0 &&
    hasAllRequiredAttributes(item.selectedAttributes, productDetails.requiredAttributes)
  )
}

export function hasImage (productDetails: CsvItemProductDetails, url: ?string, imageUploadStatus: Status): boolean {
  return productDetails.noImageProduct || Boolean(url && imageUploadStatus === SUCCESS)
}
