// @flow
import type { CustomerAddress, V4OrderRecipient } from '../../types'

export function mapCustomerAddressToRecipient(source: CustomerAddress): V4OrderRecipient {
  return {
    address: {
      line1: source.address1,
      line2: source.address2 && source.address2.trim() ? source.address2 : null,
      postalOrZipCode: source.postcode ? source.postcode.trim() : null,
      stateOrCounty: source.county ? source.county : null,
      countryCode: source.countryCode,
      townOrCity: source.town
    },
    name: source.name,
    email: source.email ? source.email : null,
    phoneNumber: source.mobileTelephone ? source.mobileTelephone : null
  }
}
