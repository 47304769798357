import { getCountryName } from '../../../../helpers/countryNames'
import ClipboardCopy from '../../ClipboardCopy'
import timeFormat from '../../../helpers/timeFormat'
import { formatCost } from '../../../helpers'
import dateFormat from '../../../helpers/dateFormat'
import { OrderDetailShipmentInterface } from '../../../interfaces'
import { OrderDetailShipmentStatus } from './OrderDetailShipmentStatus.component'
import { ShipmentStatus } from '../../../enums'

interface OrderDetailShipmentDisplayHeaderPropsInterface {
  countryCode: string
  hasUnallocated: boolean
  orderAllocationDateString?: string
  shipment: OrderDetailShipmentInterface
  shipmentCount: number
  shipmentIndex: number
  skusInShipment: string[]
}

export function OrderDetailShipmentDisplayHeader({
  countryCode,
  hasUnallocated,
  orderAllocationDateString,
  shipment,
  shipmentCount,
  shipmentIndex,
  skusInShipment
}: OrderDetailShipmentDisplayHeaderPropsInterface) {
  return (
    <header className="bg-white">
      <div className="flex w-full flex-wrap justify-between gap-2 border border-purple-200 bg-purple-100 p-6">
        <h2 className="mt-0 text-lg" data-test="shipment-header__shipment-id">
          Shipment {shipmentIndex + 1} of {hasUnallocated ? 'multiple' : shipmentCount}{' '}
          <span className="ml-0 block sm:ml-2 sm:inline">
            <ClipboardCopy text={shipment.id} className="text-sm font-normal text-gray-700" />
          </span>
        </h2>
        <div className="text-right">
          <OrderDetailShipmentStatus
            countryCode={countryCode}
            orderAllocationDateString={orderAllocationDateString}
            status={shipment.status}
            skusInShipment={skusInShipment}
            trackingUrl={shipment.trackingUrl}
          />
        </div>
      </div>
      <div className="w-full justify-between border-l border-r border-b px-6 py-3 sm:flex">
        <div>
          {shipment.status === ShipmentStatus.SHIPPED && (
            <div data-test="shipment-header__shipment-date">
              Shipped {dateFormat(shipment.dispatchDate)} at {timeFormat(shipment.dispatchDate)}{' '}
              <span data-test="shipment-header__shipped-from">
                from {getCountryName(shipment.shippedFrom.countryCode)}
              </span>
            </div>
          )}
          <div className="mt-4 sm:mt-1" data-test="shipment-header__shipment-cost">
            Shipping cost{' '}
            {formatCost({
              amount: shipment.shippingCost.amount,
              currencyCode: shipment.shippingCost.currencyCode
            })}
          </div>
        </div>
        <div className="mt-4 sm:mt-0 sm:text-right">
          <div data-test="shipment-header__shipment-carrier">{shipment.carrierService}</div>
          {shipment.status === ShipmentStatus.SHIPPED && shipment.trackingNumber && (
            <ClipboardCopy
              text={shipment.trackingNumber.toString()}
              className="mt-1 text-gray-600 md:flex-row-reverse"
            />
          )}
        </div>
      </div>
    </header>
  )
}
