// @flow
import type { Action } from '../../types'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'

const FAILED_TO_FETCH_MESSAGE = 'Failed to fetch'

export default function getErrorMessageFromAction (action: Action): string {
  let message = DEFAULT_ERROR_MESSAGE

  if (action.message) {
    return action.message
  } else if (action.meta && action.meta.message) {
    return action.meta.message
  }

  if (action.payload && action.payload.hasOwnProperty('response') && action.payload.response) {
    if (action.payload.response.hasOwnProperty('statusTxt')) {
      message = action.payload.response.statusTxt
    } else if (action.payload.response.hasOwnProperty('message')) {
      message = action.payload.response.message.includes('Bad Request for url')
        ? 'Bad url request'
        : action.payload.response.message
    }
  } else if (action.payload && action.payload.hasOwnProperty('message')) {
    message = action.payload.message
  }

  if (message === FAILED_TO_FETCH_MESSAGE) {
    return DEFAULT_ERROR_MESSAGE
  }

  return message
}
