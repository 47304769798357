import { motion } from 'framer-motion'
import { useLocation, useHistory } from 'react-router'
import { useRef, useMemo, useLayoutEffect } from 'react'

import { OrderFilterHeader } from '.'
import { OnChangeFilterType } from '../types'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import OrdersListActionDates from '../../OrdersListActionDates'

export function OrderFilterDate({ onChangeFilter }: { onChangeFilter: OnChangeFilterType }) {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useLayoutEffect(() => {
    window.analytics.track('Clicked Filter By Date')
    buttonRef.current?.focus()
  }, [])

  function handleClear() {
    searchParams.delete(QUERY_PARAMS.ORDERS.START_DATE)
    searchParams.delete(QUERY_PARAMS.ORDERS.END_DATE)
    history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
  }

  return (
    <motion.div
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 5 }}
      initial={{ opacity: 0, x: 5 }}
      key="date"
      transition={{ type: 'tween', duration: 0.1 }}
    >
      <OrderFilterHeader
        showClearAction={
          searchParams.has(QUERY_PARAMS.ORDERS.START_DATE) || searchParams.has(QUERY_PARAMS.ORDERS.END_DATE)
        }
        title="Date"
        onBack={() => onChangeFilter(null)}
        onClear={handleClear}
      />
      <OrdersListActionDates />
    </motion.div>
  )
}
