import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface, ProductCostedAttributeInterface } from '../interfaces'

export interface ProductsDataInterface {
  [sku: string]: {
    costedAttributes?: Record<string, ProductCostedAttributeInterface | undefined>
  }
}

export function useProducts(skus?: string[], { config }: { config?: SWRConfiguration } = {}) {
  const url = skus ? `${process.env.REACT_APP_PRODUCT_API}/products` : null

  const { data, error } = useSWR<ProductsDataInterface | undefined, FetchErrorInterface<{ message?: string }>>(
    url && skus ? [url, skus] : null,
    productsFetcher,
    config
  )

  return {
    products: data,
    isLoadingProducts: Boolean(url && !data && !error),
    productsFetchError: error
  }
}

function productsFetcher(url: string, skus: string[]) {
  return fetcher<ProductsDataInterface>(url, {
    body: JSON.stringify({
      pwintySkus: skus
    }),
    method: 'POST'
  })
}
