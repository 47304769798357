// @flow
import { RECEIVE_USER_DETAILS, SET_UP_USER_SUCCESS, CREATE_KITE_ACCOUNT_SUCCESS } from '../actions/user'
import type { FetchUserDetailsSuccess, SetUpUserSuccess, CreateKiteAccountSuccess } from '../actions/types'
import type { UserState, Action } from '../types'

const initialState: UserState = {
  details: null,
  isInitialised: false
}

const BLANK_COMPANY = {
  name: '',
  address1: '',
  address2: '',
  townCity: '',
  countyState: '',
  postcode: '',
  countryCode: '',
  currencyCode: '',
  googleTrackingId: '',
  vatNumber: ''
}
type Actions = SetUpUserSuccess | FetchUserDetailsSuccess | CreateKiteAccountSuccess | Action

function user(state: UserState = initialState, action: Actions): UserState {
  switch (action.type) {
    case RECEIVE_USER_DETAILS: {
      const user = action.payload.data

      const currentDetails = !state.details ? BLANK_COMPANY : state.details.company

      return {
        ...state,
        details: {
          id: user.id,
          merchantUniqueId: user.merchantUniqueId,
          name: user.name,
          fullName: user.fullName,
          email: user.email,
          settings: user.settings,
          company: {
            ...currentDetails,
            ...user.company
          },
          billing: user.billing,
          hasKiteAccount: user.hasKiteAccount
        }
      }
    }
    case CREATE_KITE_ACCOUNT_SUCCESS: {
      if (!state.details) {
        return state
      }
      return {
        ...state,
        details: {
          ...state.details,
          hasKiteAccount: true
        }
      }
    }
    case SET_UP_USER_SUCCESS: {
      return {
        ...state,
        isInitialised: true
      }
    }

    default: {
      return state
    }
  }
}
export default user
