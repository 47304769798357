// @flow

import type {
  AppState,
  InProgressOrder,
  MultiAssetBasketItem as BasketItemType,
  CustomerAddress,
  RsaaStatusProps
} from '../../../types'
import type { RouterHistory } from 'react-router'
import React from 'react'
import { connect } from 'react-redux'
import Loading from '../components/Loading'
import MultiAssetBasketItem from '../components/MultiAssetBasketItem'
import Page from '../../../components/Page'
import styles from './OrderBasket.module.css'
import { getAreAllBasketItemsValid } from '../../../selectors/manualOrderForm'
import { getRsaaStatusProps } from '../../../selectors/rsaa'
import { buildRsaaActionId } from '../../../helpers/rsaa'
import { SEARCH_PRODUCTS_BY_SKU_LIST } from '../../../actions/catalogue'
import { BASKET_ITEM_LOGGER_ID } from '../../../actions/manualOrderForm'
import { DEFAULT_ERROR_MESSAGE } from '../../../data/errorMessages'

type Props = {|
  allItemsValid: boolean,
  catalogueDataRsaaStatus: RsaaStatusProps,
  customer: CustomerAddress,
  deliveryCountry: string,
  inProgressOrder: InProgressOrder,
  itemsToBeCreated: BasketItemType[],
  location: Location,
  history: RouterHistory
|}

class OrderBasket extends React.Component<Props> {
  componentDidMount () {
    window.analytics.page('MOF', 'Basket')
  }

  getBasketItems = (basketItems) => {
    if (basketItems.length === 0) {
      return <p className={styles.empty} data-test="basket-empty-message">Your basket is empty</p>
    }
    return basketItems.map((item, i) => {
      return <MultiAssetBasketItem isLastItem={basketItems.length === 1} key={item.id} item={item} index={i} />
    })
  }

  render (): React$Node {
    const {
      catalogueDataRsaaStatus,
      itemsToBeCreated,
      allItemsValid,
      history
    } = this.props

    return (
      <Page
        title="Basket"
        primaryAction={{ title: 'Continue', onClick: () => history.push('/orders/create/address'), disabled: !allItemsValid }}
        secondaryActions={[
          { title: 'Add another product', icon: 'plus', onClick: () => history.push('/orders/create/select-item') }
        ]}
        showActionsInFooter={true}
      >
        {catalogueDataRsaaStatus.error
          ? (
            <div className={styles.loadingContainer}>
              <Loading
                error={catalogueDataRsaaStatus.error}
                errorMessage={DEFAULT_ERROR_MESSAGE}
              />
            </div>
          )
          : (
            <section className={styles.itemList}>
              {this.getBasketItems(itemsToBeCreated)}
            </section>
          )}
      </Page>
    )
  }
}

function mapStateToProps (state: AppState) {
  const allItemsValid = getAreAllBasketItemsValid(state)
  const catalogueDataActionId = buildRsaaActionId(SEARCH_PRODUCTS_BY_SKU_LIST, BASKET_ITEM_LOGGER_ID)

  return {
    allItemsValid,
    catalogueDataRsaaStatus: getRsaaStatusProps(state, catalogueDataActionId),
    customer: state.manualOrderForm.customer,
    deliveryCountry: state.manualOrderForm.deliveryCountry,
    inProgressOrder: state.manualOrderForm.inProgressOrder,
    itemsToBeCreated: state.manualOrderForm.inProgressOrder.itemsToBeCreated
  }
}

export default (connect(
  mapStateToProps
)(OrderBasket): any)
