import { ProductDetailInterface } from '../../../hooks'
import { NO_IMAGE_PRODUCT_TYPES } from '../../../constants'
import { ProductDataInterface, ProductInterface } from '../../../interfaces'
import { Variants, PricingAndShipping, ProductDetailsInfo, ImageRequirements } from '.'

export interface ProductDetailsDisplayPropsInterface {
  countryCode: string
  currencyCode: string
  footer?: JSX.Element
  // TODO: Make required prop when removing split COSTED_ATTRIBUTES: 'dashboard_costed_attributes'
  product?: ProductDataInterface
  productDetails: ProductDetailInterface
  showCountryPicker: boolean
  skuSearchResult: ProductInterface
}

export function ProductDetailsDisplay({
  countryCode,
  currencyCode,
  footer,
  product,
  productDetails,
  showCountryPicker,
  skuSearchResult
}: ProductDetailsDisplayPropsInterface) {
  const hasAttributes = Object.keys(skuSearchResult.attributes).length > 0
  const isNoImageProduct = Object.values(NO_IMAGE_PRODUCT_TYPES).includes(skuSearchResult.productType.toLowerCase())

  return (
    <>
      <div className="grid grid-cols-1 gap-x-4 gap-y-10 pr-4 lg:grid-cols-[200px_minmax(0,_1fr)] lg:gap-y-20">
        <ProductDetailsInfo productDetails={productDetails} skuSearchResult={skuSearchResult} />
        <PricingAndShipping
          key={countryCode}
          countryCode={countryCode}
          currencyCode={currencyCode}
          product={product}
          productDetails={productDetails}
          showCountryPicker={showCountryPicker}
        />
        {hasAttributes && (
          <Variants product={product} productDetails={productDetails} skuSearchResult={skuSearchResult} />
        )}
        {!isNoImageProduct && <ImageRequirements productDetails={productDetails} skuSearchResult={skuSearchResult} />}
      </div>
      {footer}
    </>
  )
}
