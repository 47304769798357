// @flow
import type { Node, Element, ChildrenArray } from 'react'
import React from 'react'
import BareButton from '../../../../components/BareButton'

type ContainerProps = {|
  isMultiselectModeOn: boolean,
  children: $ReadOnlyArray<ChildrenArray<*>>,
  className: string,
  dataTest?: string,
  onClick: () => void
|}

export default function Container ({isMultiselectModeOn, onClick, children, className, dataTest}: ContainerProps): Element<"div"> | Node {
  return isMultiselectModeOn
    ? (
      <BareButton onClick={onClick} className={className} dataTest={dataTest}>
        {children}
      </BareButton>
    )
    : (
      <div className={className} data-test='csv-item-container'>
        {children}
      </div>
    )
}
