// @flow
import * as React from 'react'

type Props = {|
  className: string
|}

export default function InformationCircle ({ className }: Props): React$Node {
  return (
    <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14" stroke="#565656" />
      <path d="M15.75 19.5V13.5H12.75V15H14.25V19.5H12V21H18V19.5H15.75Z"/>
      <path d="M15 9C14.7775 9 14.56 9.06598 14.375 9.1896C14.19 9.31321 14.0458 9.48891 13.9606 9.69448C13.8755 9.90005 13.8532 10.1262 13.8966 10.3445C13.94 10.5627 14.0472 10.7632 14.2045 10.9205C14.3618 11.0778 14.5623 11.185 14.7805 11.2284C14.9988 11.2718 15.225 11.2495 15.4305 11.1644C15.6361 11.0792 15.8118 10.935 15.9354 10.75C16.059 10.565 16.125 10.3475 16.125 10.125C16.125 9.82663 16.0065 9.54048 15.7955 9.3295C15.5845 9.11853 15.2984 9 15 9Z"/>
    </svg>
  )
}
