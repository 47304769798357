// @flow
import React, { PureComponent } from 'react'
import type {
  ThunkAsync,
  MultiAssetBasketItem,
  Event,
  CatalogueItem,
  Dictionary,
  RsaaStatusProps
} from '../../../../types'
import RecentItemListLayout from './RecentItemListLayout'
import type { ClearRecentItems, AddRecentItemToBasket } from '../../../../actions/types'
import { PRINT_AREA_NAME } from '../../../../data'

const ADD_IMAGE_PAGE_URL = '/orders/create/add-image'

type RecentItemListProps = {|
  productSearchLoading: boolean,
  productSearchSuccess: boolean,
  productSearchError: boolean,
  availableRecentItems: MultiAssetBasketItem[],
  catalogueItems: Dictionary<CatalogueItem>,
  fetchPricesRsaaStatus: RsaaStatusProps,
  addRecentItem: (item: MultiAssetBasketItem) => ThunkAsync<AddRecentItemToBasket>,
  clearRecentItems: () => ClearRecentItems,
  redirect: (path: string) => void,
  removeRecentItem: (originalImageUrl: string) => void
|}

type State = {|
  invalidImageUrls: string[]
|}

export default class RecentItemList extends PureComponent<RecentItemListProps, State> {
  state: State = {
    invalidImageUrls: []
  }

  onClearRecentItems: (e: Event) => void = (e: Event) => {
    this.props.clearRecentItems()
  }

  onImageError: (imageUrl: string) => void = (imageUrl: string) => {
    this.setState((state) => ({
      invalidImageUrls: [...state.invalidImageUrls, imageUrl]
    }))

    this.props.removeRecentItem(imageUrl)
  }

  onSelectRecentItem: (e: Event) => void = (e: Event) => {
    const itemToAdd = this.findRecentItemById(e.currentTarget.value)
    window.analytics.track('Added Recent Item To Basket')
    this.props.addRecentItem(itemToAdd)
    this.props.redirect(ADD_IMAGE_PAGE_URL)
  }

  findRecentItemById: (id: string) => MultiAssetBasketItem = (id: string): MultiAssetBasketItem => {
    const item = this.props.availableRecentItems.find(this.hasSameId(id))

    if (!item) {
      throw new Error(`Cannot find recently ordered item with id: ${id}`)
    }

    return item
  }

  hasSameId: (id: string) => (item: MultiAssetBasketItem) => boolean =
    (id: string) =>
    (item: MultiAssetBasketItem): boolean =>
      this.getUniqueId(item) === id

  getUniqueId: (item: MultiAssetBasketItem) => string = (item: MultiAssetBasketItem): string => {
    if (!item.printAreas) {
      return item.sku
    }
    const itemPrintAreas = item.printAreas
    const printAreaNames = Object.keys(itemPrintAreas)
    const selectedPrintArea = printAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
      ? PRINT_AREA_NAME.DEFAULT
      : printAreaNames[0]
    const artwork = itemPrintAreas[selectedPrintArea]?.artwork
    if (!artwork) {
      return item.sku
    }
    return item.sku + artwork.originalImageUrl
  }

  render(): React$Node {
    return (
      <RecentItemListLayout
        onImageError={this.onImageError}
        items={this.props.availableRecentItems}
        invalidImageUrls={this.state.invalidImageUrls}
        catalogueItems={this.props.catalogueItems}
        fetchPricesRsaaStatus={this.props.fetchPricesRsaaStatus}
        onSelectRecentItem={this.onSelectRecentItem}
        onClearRecentItems={this.onClearRecentItems}
        getUniqueId={this.getUniqueId}
      />
    )
  }
}
