// @flow
import type { MultiAssetBasketItem, BasketItem, Template, MultiAssetTemplates, Orientation } from '../../../types'
import { TEMPLATE_SIZE_IN_PX } from '../../../data/constants'
import { APP_VERSION } from '../../../data/appVersion'
import { getRotatedDimensions } from '../../../helpers/imageEditor'
import { LANDSCAPE, PORTRAIT, SQUARE } from '../../../data/orientation'
import { split } from '../../../helpers/array'
import { calculateFillScaleFactor } from '../../images/calculateFillScaleFactor'
import { updateToVersion11 } from './updateToVersion11'
import { updateToVersion12 } from './updateToVersion12'
import { setTemplateProps } from '../../images'
import { PRINT_AREA_NAME } from '../../../data/printAreaName'
import type { MultiAssetTemplate } from '../../../types/templates'

type Items = [MultiAssetBasketItem[], BasketItem[]]

export function mapToEnsureBackwardCompatibility (basketItems: Array<BasketItem | MultiAssetBasketItem>): MultiAssetBasketItem[] {
  const [multiAssetBasketItems, singleAssetBasketItems] = ((split(basketItems, isVersion11OrNewer): any): Items)

  const itemsCompatibleWithVersion11 = mapSingleAssetBasketItems(singleAssetBasketItems).concat(multiAssetBasketItems)
  const itemsCompatibleWithVersion12 = updateToVersion12(itemsCompatibleWithVersion11)
  return itemsCompatibleWithVersion12
}

function isVersion11OrNewer (item: BasketItem | MultiAssetBasketItem): boolean {
  return item.appVersion >= APP_VERSION.WHICH_INTRODUCED.NEW_IMAGE_EDITOR_UNITS
}

function mapSingleAssetBasketItems (itemToUpdate: BasketItem[]): MultiAssetBasketItem[] {
  return itemToUpdate.map(item => {
    try {
      const itemWithSku = assignSku(item)
      const itemWithOutputDpi = assignOutputDpi(itemWithSku)
      const itemCompatibleWithVersion9 = updateToVersion9(itemWithOutputDpi)
      const itemCompatibleWithVersion10 = updateToVersion10(itemCompatibleWithVersion9)
      const itemCompatibleWithVersion11 = updateToVersion11(itemCompatibleWithVersion10)

      return itemCompatibleWithVersion11
    } catch (err) {
      console.error('err', err)
      return null
    }
  }).filter(Boolean)
}

function assignSku (item: BasketItem): BasketItem {
  if (item.sku) {
    return item
  }

  if (!item.name) {
    throw new Error('Basket item name cannot be null.')
  }

  return {
    ...item,
    sku: item.name
  }
}

function assignOutputDpi (item: BasketItem): BasketItem {
  return {
    ...item,
    landscapeTemplate: assignOutputDpiToTemplate(item.landscapeTemplate, item.optimumDpi),
    portraitTemplate: assignOutputDpiToTemplate(item.portraitTemplate, item.optimumDpi),
    squareTemplate: assignOutputDpiToTemplate(item.squareTemplate, item.optimumDpi)
  }
}

function assignOutputDpiToTemplate (template: ?Template, dpi: number): ?Template {
  if (!template) {
    return null
  }

  if (template.outputDpi) {
    return template
  }

  return {
    ...template,
    outputDpi: dpi
  }
}

function updateToVersion9 (item: BasketItem): BasketItem {
  return item.appVersion >= APP_VERSION.WHICH_INTRODUCED.SCALE_BASED_ON_PRINT_AREA
    ? item
    : convertImageCropperScale(item)
}

function convertImageCropperScale (item: BasketItem): BasketItem {
  const template = getSelectedTemplateFromBasketItem(item)

  if (!template) {
    throw new Error('Template cannot be null.')
  }

  if (![0, 90, 180, 270].includes(item.rotationAngle)) {
    throw Error(`Unsupported rotation angle: ${item.rotationAngle}`)
  }

  const artworkDimensionsInPx = {
    width: item.originalImageWidth,
    height: item.originalImageHeight
  }

  const newScaleFactor = calculateFillScaleFactor(
    template.printResolution,
    artworkDimensionsInPx,
    item.borderFactor,
    item.rotationAngle
  )

  return {
    ...item,
    appVersion: APP_VERSION.WHICH_INTRODUCED.SCALE_BASED_ON_PRINT_AREA,
    scaleFactor: newScaleFactor
  }
}

function getSelectedTemplateFromBasketItem (item: BasketItem): ?MultiAssetTemplate {
  // This function is used only in the single-asset image editor
  const printAreaName = PRINT_AREA_NAME.DEFAULT

  const templates = {
    printAreas: {
      [printAreaName]: {
        orientations: {
          square: setTemplateProps(item.squareTemplate, SQUARE),
          landscape: setTemplateProps(item.landscapeTemplate, LANDSCAPE),
          portrait: setTemplateProps(item.portraitTemplate, PORTRAIT)
        }
      }
    }
  }

  return item.templateOrientation
    ? selectTemplateByOrientation(templates, item.templateOrientation, printAreaName)
    : null
}

function updateToVersion10 (item: BasketItem): BasketItem {
  if (item.appVersion >= APP_VERSION.WHICH_INTRODUCED.IMAGE_EDITOR_LIBRARY) {
    return item
  }
  const itemWithCorrectedCropRectangleDimensions = correctCropRectangleDimensions(item)
  const itemWithCorrectedPrintDimensions = correctPrintDimensions(itemWithCorrectedCropRectangleDimensions)

  return {
    ...itemWithCorrectedPrintDimensions,
    appVersion: APP_VERSION.WHICH_INTRODUCED.IMAGE_EDITOR_LIBRARY
  }
}

function correctCropRectangleDimensions (item: BasketItem): BasketItem {
  const { landscapeTemplate, portraitTemplate, squareTemplate } = item

  return {
    ...item,
    landscapeTemplate: multiplyCropRectangleValuesByTemplateSize(landscapeTemplate),
    portraitTemplate: multiplyCropRectangleValuesByTemplateSize(portraitTemplate),
    squareTemplate: multiplyCropRectangleValuesByTemplateSize(squareTemplate)
  }
}

function multiplyCropRectangleValuesByTemplateSize (template: ?Template): ?Template {
  if (!template) {
    return
  }

  return {
    ...template,
    cropRectangle: {
      x: template.cropRectangle.x * TEMPLATE_SIZE_IN_PX,
      y: template.cropRectangle.y * TEMPLATE_SIZE_IN_PX,
      width: template.cropRectangle.width * TEMPLATE_SIZE_IN_PX,
      height: template.cropRectangle.height * TEMPLATE_SIZE_IN_PX
    }
  }
}

function correctPrintDimensions (item: BasketItem): BasketItem {
  const { landscapeTemplate, portraitTemplate, squareTemplate } = item

  return {
    ...item,
    landscapeTemplate: correctPrintDimensionsToMatchOrientation(landscapeTemplate),
    portraitTemplate: correctPrintDimensionsToMatchOrientation(portraitTemplate),
    squareTemplate: correctPrintDimensionsToMatchOrientation(squareTemplate)
  }
}

function correctPrintDimensionsToMatchOrientation (template: ?Template): ?Template {
  if (!template) {
    return
  }

  const printDimensionsRotatedToMatchOrientation = getRotatedDimensions(
    template.orientation,
    template.printedAreaDimensions.width,
    template.printedAreaDimensions.height
  )

  return {
    ...template,
    printedAreaDimensions: {
      width: printDimensionsRotatedToMatchOrientation.width,
      height: printDimensionsRotatedToMatchOrientation.height,
      isLandscape: template.orientation === LANDSCAPE,
      isPortrait: template.orientation === PORTRAIT,
      isSquare: template.orientation === SQUARE
    }
  }
}

export function selectTemplateByOrientation (
  templates: MultiAssetTemplates,
  orientation: Orientation,
  printAreaName: string
): ?MultiAssetTemplate {
  const templatesByOrientation = templates.printAreas[printAreaName]?.orientations
  const lowerCaseOrientation = orientation.toLowerCase()

  return templatesByOrientation?.[lowerCaseOrientation] ?? null
}
