// @flow
import React from 'react'
import Close from './Close'
import LogoMark from './logoMark'
import Chevron from './chevron'
import Cross from './cross'
import TickMark from './tickMark'
import type { AbstractComponent } from 'react'
import cx from 'classnames'
import styles from './Svg.module.css'
import ApiIcon from './apiIcon'
import BigCommerceIcon from './bigCommerceIcon'
import CsvUploaderIcon from './csvUploaderIcon'
import PhotobookMakerIcon from './photobookMakerIcon'
import PrintShopIcon from './printShopIcon'
import ShopifyIcon from './shopifyIcon'
import Analytics from './analytics'
import Apps from './apps'
import ErrorLogo from './errorLogo'
import EyeShow from './eye-show'
import EyeHide from './eye-hide'
import Home from './home'
import Import from './import'
import LogOut from './logOut'
import Orders from './orders'
import Resources from './resources'
import Product from './product'
import Settings from './settings'
import Support from './support'
import Information from './Information'
import Clock from './clock'
import StripeLogo from './stripeLogo'
import ArrowRight from './arrowRight'
import ArrowLeft from './arrowLeft'
import PlusCircle from './plusCircle'
import CrossCircle from './crossCircle'
import ExclamationCircle from './ExclamationCircle'
import TickCircle from './TickCircle'
import InformationCircle from './InformationCircle'
import CloseIcon from './CloseIcon'
import Bell from './bell'
import BuildingOffice from './buildingOffice'

type SvgHocProps = {|
  className?: string
|}

type SvgComponentProps = {|
  className: string
|}

function svg<Config>(
  WrappedSvgComponent: AbstractComponent<SvgComponentProps>
): AbstractComponent<{| ...Config, ...SvgComponentProps |}> {
  return function WrapperComponent(props: SvgComponentProps) {
    return <WrappedSvgComponent {...props} className={cx(styles.default, props.className)} />
  }
}

export default {
  EyeShow: (svg(EyeShow): AbstractComponent<SvgHocProps>),
  EyeHide: (svg(EyeHide): AbstractComponent<SvgHocProps>),
  Bell: (svg(Bell): AbstractComponent<SvgHocProps>),
  Close: (svg(Close): AbstractComponent<SvgHocProps>),
  LogoMark: (svg(LogoMark): AbstractComponent<SvgHocProps>),
  Chevron: (svg(Chevron): AbstractComponent<SvgHocProps>),
  Clock: (svg(Clock): AbstractComponent<SvgHocProps>),
  Cross: (svg(Cross): AbstractComponent<SvgHocProps>),
  ErrorLogo: (svg(ErrorLogo): AbstractComponent<SvgHocProps>),
  TickMark: (svg(TickMark): AbstractComponent<SvgHocProps>),
  ApiIcon: (svg(ApiIcon): AbstractComponent<SvgHocProps>),
  BigCommerceIcon: (svg(BigCommerceIcon): AbstractComponent<SvgHocProps>),
  CsvUploaderIcon: (svg(CsvUploaderIcon): AbstractComponent<SvgHocProps>),
  PhotobookMakerIcon: (svg(PhotobookMakerIcon): AbstractComponent<SvgHocProps>),
  PrintShopIcon: (svg(PrintShopIcon): AbstractComponent<SvgHocProps>),
  ShopifyIcon: (svg(ShopifyIcon): AbstractComponent<SvgHocProps>),
  Analytics: (svg(Analytics): AbstractComponent<SvgHocProps>),
  ArrowRight: (svg(ArrowRight): AbstractComponent<SvgHocProps>),
  ArrowLeft: (svg(ArrowLeft): AbstractComponent<SvgHocProps>),
  Apps: (svg(Apps): AbstractComponent<SvgHocProps>),
  Home: (svg(Home): AbstractComponent<SvgHocProps>),
  Import: (svg(Import): AbstractComponent<SvgHocProps>),
  LogOut: (svg(LogOut): AbstractComponent<SvgHocProps>),
  Orders: (svg(Orders): AbstractComponent<SvgHocProps>),
  Resources: (svg(Resources): AbstractComponent<SvgHocProps>),
  Product: (svg(Product): AbstractComponent<SvgHocProps>),
  Settings: (svg(Settings): AbstractComponent<SvgHocProps>),
  StripeLogo: (svg(StripeLogo): AbstractComponent<SvgHocProps>),
  Support: (svg(Support): AbstractComponent<SvgHocProps>),
  Information: (svg(Information): AbstractComponent<SvgHocProps>),
  PlusCircle: (svg(PlusCircle): AbstractComponent<SvgHocProps>),
  CrossCircle: (svg(CrossCircle): AbstractComponent<SvgHocProps>),
  ExclamationCircle: (svg(ExclamationCircle): AbstractComponent<SvgHocProps>),
  CloseIcon: (svg(CloseIcon): AbstractComponent<SvgHocProps>),
  TickCircle: (svg(TickCircle): AbstractComponent<SvgHocProps>),
  InformationCircle: (svg(InformationCircle): AbstractComponent<SvgHocProps>),
  BuildingOffice: (svg(BuildingOffice): AbstractComponent<SvgHocProps>)
}
