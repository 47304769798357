// @flow
import type { ThunkAsync, DispatchFunc, GetStateFunc } from '../../types'
import { selectRouterHistory } from '../../selectors/router'
import { changeDeliveryCountry } from './manualOrderForm'
import { addBasketItem } from './addBasketItem'
import { searchProducts, clearProductSearch } from '../catalogue'
import { logLe, LOG_LEVEL } from '../logging'
import { getOwnProperties } from '../../helpers/error'

export const ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET'
export const ADD_PRODUCT_TO_BASKET_SUCCESS = 'ADD_PRODUCT_TO_BASKET_SUCCESS'
export const ADD_PRODUCT_TO_BASKET_ERROR = 'ADD_PRODUCT_TO_BASKET_ERROR'

export const ACTION_TYPES__ADD_PRODUCT_TO_BASKET = [
  ADD_PRODUCT_TO_BASKET,
  ADD_PRODUCT_TO_BASKET_SUCCESS,
  ADD_PRODUCT_TO_BASKET_ERROR
]

export function addProductToBasket (sku: string, deliveryCountry: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    try {
      const history = selectRouterHistory(getState())
      const upperCaseSku = sku.toUpperCase()

      dispatch({type: ADD_PRODUCT_TO_BASKET})
      dispatch(clearProductSearch())
      await dispatch(searchProducts(upperCaseSku))
      await dispatch(addBasketItem(upperCaseSku))
      dispatch(changeDeliveryCountry(deliveryCountry))
      dispatch(clearProductSearch())
      history.push(`/orders/create/add-image`)
      window.analytics.track('Add product to basket', {sku, deliveryCountry})
      dispatch({type: ADD_PRODUCT_TO_BASKET_SUCCESS})
    } catch (error) {
      dispatch({type: ADD_PRODUCT_TO_BASKET_ERROR, message: error.message})
      dispatch(logLe(`Add product to basket failed`, { ...getOwnProperties(error), sku, deliveryCountry }, LOG_LEVEL.ERROR))
    }
  }
}
