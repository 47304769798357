import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'

import Button from '../../Button'
import { ImageType, Tags } from '.'
import FormItem from '../../FormItem'
import TextField from '../../TextField'
import { fetcher } from '../../../helpers'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { FetchErrorInterface } from '../../../interfaces'
import { IMAGE_LIBRARY_TOASTS } from '../../ImageLibrary'
import { createErrorToast, createToast } from '../../Toast'
import { useImageLibraryImage, ImageLibraryImageType } from '../../../hooks'

interface ImageDetailsInterface {
  name: string
  image_type: ImageLibraryImageType
  tags: string[]
}

export function ImageDetailsDisplay() {
  const { id: imageId } = useParams<{ id: string }>()
  const { image: imageLibraryImage, mutateImageLibraryImage } = useImageLibraryImage({ imageId })

  if (!imageLibraryImage) {
    throw Error('No image')
  }

  const [detailsUpdateStatus, setDetailsUpdateStatus] = useState(StatusEnum.Idle)
  const [newImageDetails, setNewImageDetails] = useState<ImageDetailsInterface>({
    name: imageLibraryImage.name,
    image_type: imageLibraryImage.image_type,
    tags: imageLibraryImage.tags
  })

  async function handleUpdate(newDetails: ImageDetailsInterface) {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    try {
      if (!imageLibraryImage) {
        return
      }
      setDetailsUpdateStatus(StatusEnum.Loading)
      await fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryImage.public_id}`, {
        method: 'PATCH',
        body: JSON.stringify(newDetails)
      })
      await mutateImageLibraryImage()
      setDetailsUpdateStatus(StatusEnum.Success)
      createToast({ heading: 'Image updated', type: 'success' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createErrorToast({
        heading: 'Failed to update image',
        id: IMAGE_LIBRARY_TOASTS.ERROR,
        errorMessage: errorResponse.message,
        errorCode: `${errorResponse.status ?? 0}`
      })
      setDetailsUpdateStatus(StatusEnum.Error)
    }
  }

  return (
    <>
      <FormItem
        className="w-full"
        key="image-details-name"
        inputField={
          <TextField
            required
            onChange={(event) => {
              setNewImageDetails({
                ...newImageDetails,
                name: event.target.value
              })
            }}
            value={newImageDetails.name}
          />
        }
        labelText="Name"
      />

      <ImageType
        isLoading={false}
        value={newImageDetails.image_type}
        onUpdate={(newValue: string) => {
          if (['artwork', 'packing', 'book'].includes(newValue)) {
            setNewImageDetails({
              ...newImageDetails,
              image_type: newValue as ImageLibraryImageType
            })
          }
        }}
      />

      <Tags
        isLoading={false}
        tags={newImageDetails.tags ?? []}
        onUpdate={(tags) =>
          setNewImageDetails({
            ...newImageDetails,
            tags
          })
        }
      />

      <div className="mt-12 flex gap-4 sm:mb-4">
        <Button
          disabled={!newImageDetails.name.trim()}
          isLoading={detailsUpdateStatus === StatusEnum.Loading}
          onClick={(event) => {
            event.preventDefault()
            handleUpdate(newImageDetails)
          }}
        >
          Save details
        </Button>
      </div>
      {detailsUpdateStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
