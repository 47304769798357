// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Home ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4575 0.65752C12.1849 0.442122 11.8001 0.442122 11.5275 0.65752L0.75 9.06502L1.68 10.25L3 9.21502V18.5C3 19.3284 3.67157 20 4.5 20H19.5C20.3284 20 21 19.3284 21 18.5V9.22252L22.32 10.25L23.25 9.07252L12.4575 0.65752ZM13.5 18.5H10.5V12.5H13.5V18.5ZM15 18.5V12.5C15 11.6716 14.3284 11 13.5 11H10.5C9.67157 11 9 11.6716 9 12.5V18.5H4.5V8.05252L12 2.20252L19.5 8.05252V18.5H15Z" fill="#565656"/>
    </svg>
  )
}
