import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { isNoImageProduct } from '../../../../helpers/isNoImageProduct'
import { ImageEditorPrintAreaArtworkInterface } from '../../ImageEditor/interfaces'
import { VariantDataInterface, VariantsSkuDataType } from '../SalesChannelProduct.component'
import { getProductFileCompatibilityData } from '../../../helpers/getProductFileCompatibilityData.helper'

export function copyVariantImageDetails({
  allVariantsData,
  variantDataToCopy,
  variantsSkuData
}: {
  allVariantsData: Record<string, VariantDataInterface>
  variantDataToCopy: VariantDataInterface
  variantsSkuData: VariantsSkuDataType
}) {
  return Object.keys(allVariantsData).reduce((variantsAcc: Record<string, VariantDataInterface>, variantKey) => {
    const currentVariantDetails: VariantDataInterface = allVariantsData[variantKey]
    const currentVariantSkuData = variantsSkuData[variantKey]

    if (variantKey === variantDataToCopy.id || !currentVariantDetails.isFulfilled) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    const printAreas = currentVariantDetails.assetRequirementData
      ? Object.keys(currentVariantDetails.assetRequirementData)
      : []
    const firstAvailablePrintArea = printAreas[0]

    if (!firstAvailablePrintArea || !currentVariantDetails.selectedProductData || !currentVariantSkuData) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    if (isNoImageProduct(currentVariantSkuData.productType)) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    const isSkuDifferent =
      variantDataToCopy?.selectedProductData?.sku !== currentVariantDetails.selectedProductData?.sku
    const areAttributesDifferent = Object.entries(currentVariantDetails.selectedProductData?.attributes ?? {}).some(
      ([attributeName, attributeValue]) =>
        variantDataToCopy?.selectedProductData?.attributes?.[attributeName] !== attributeValue
    )

    if (!isSkuDifferent && !areAttributesDifferent) {
      return {
        ...variantsAcc,
        [variantKey]: {
          ...currentVariantDetails,
          artworkData: variantDataToCopy.artworkData,
          selectedPrintArea: firstAvailablePrintArea
        }
      }
    }

    const productFileCompatibilityData = getProductFileCompatibilityData({
      productType: currentVariantSkuData.productType
    })

    const newArtworkData = printAreas.reduce((updatedArtworkDataAcc: PrintAreasArtworkDataType, printAreaName) => {
      const currentPrintAreaArtwork = currentVariantDetails?.artworkData?.[printAreaName]
      const newPrintAreaArtwork: ImageEditorPrintAreaArtworkInterface | null | undefined =
        variantDataToCopy?.artworkData?.[printAreaName]

      if (!newPrintAreaArtwork?.artwork) {
        return {
          ...updatedArtworkDataAcc,
          [printAreaName]: {
            ...currentPrintAreaArtwork
          }
        }
      }

      const isImageFileType = newPrintAreaArtwork.artwork.fileType
        ? newPrintAreaArtwork.artwork.fileType === 'png' || newPrintAreaArtwork.artwork.fileType === 'jpg'
        : true
      const isNewArtworkFileTypeSupportedOnCurrentProduct = productFileCompatibilityData
        ? productFileCompatibilityData.fileTypesAllowed.includes(newPrintAreaArtwork.artwork.fileType)
        : isImageFileType

      if (!isNewArtworkFileTypeSupportedOnCurrentProduct) {
        return {
          ...updatedArtworkDataAcc,
          [printAreaName]: {
            ...currentPrintAreaArtwork
          }
        }
      }

      return {
        ...updatedArtworkDataAcc,
        [printAreaName]: {
          artwork: newPrintAreaArtwork.artwork,
          artworkTransformations: undefined,
          previewImageUrl: newPrintAreaArtwork.artwork.mediumImageUrl,
          printImageUrl: newPrintAreaArtwork.artwork.originalImageUrl,
          transformImageUrl: newPrintAreaArtwork.artwork.originalImageUrl
        }
      }
    }, {})

    return {
      ...variantsAcc,
      [variantKey]: {
        ...currentVariantDetails,
        artworkData: newArtworkData,
        selectedPrintArea: firstAvailablePrintArea
      }
    }
  }, {})
}
