// @flow
import React, { Component } from 'react'
import type { Style } from '../types'

type Props = {|
  children: React$Node,
  style?: Style,
  className?: string
|}

class ImportantMessageStrip extends Component<Props> {
  getStyles (): {| container: {| backgroundColor: string, padding: string |} |} {
    return {
      container: {
        padding: 'var(--size-sm)',
        backgroundColor: 'var(--color-blue-10)'
      }
    }
  }

  render (): React$Node {
    const { children, style, className } = this.props
    const styles = this.getStyles()
    return (
      <div style={{ ...styles.container, ...style }} className={className}>
        {children}
      </ div>
    )
  }
}

export default ImportantMessageStrip
