import { useEffect } from 'react'

import { MockupViewInterface } from '../../../hooks'

export function MockupPigViewDisplay({
  selectedMockupView,
  viewsPig,
  onSelectMockupView
}: {
  selectedMockupView: string | null
  viewsPig: Record<string, MockupViewInterface>
  onSelectMockupView: (view: string) => void
}) {
  useEffect(() => {
    if (!selectedMockupView) {
      onSelectMockupView(Object.keys(viewsPig)[0])
    }
  }, [selectedMockupView, onSelectMockupView, viewsPig])

  return (
    <>
      <p className="mt-12 font-normal text-gray-200">Our mockup files are png format, 1200x1200px</p>
    </>
  )
}
