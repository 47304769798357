import React from 'react'

import styles from './SummaryItemList.module.css'
import { useProduct, useSplitToggle } from '../../../../v2/hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import productAttributeFormat from '../../../../helpers/productAttributeFormat'
import { getAttrNameForCategory } from '../../../../helpers/getAttrNameForCategory'

export function AttributesDisplay({
  category,
  selectedAttributes,
  sku
}: {
  category: string
  selectedAttributes: Record<string, string>
  sku: string
}) {
  const { splitIsOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { product } = useProduct(splitIsOn ? sku : undefined, {
    config: { revalidateOnFocus: false }
  })

  const costedAttributes = product?.pwintyProduct?.costedAttributes ?? {}
  const costedAttributeKeys = Object.keys(costedAttributes)

  return (
    <>
      {Object.keys(selectedAttributes).map((attributeKey) => {
        const isCostedAttribute = costedAttributeKeys.includes(attributeKey)

        if (!isCostedAttribute) {
          return (
            <tr key={attributeKey}>
              <td className={styles.attributeTitle}>{getAttrNameForCategory(attributeKey, category)}:</td>
              <td className={styles.meta}>{productAttributeFormat(selectedAttributes[attributeKey])}</td>
            </tr>
          )
        }

        let costedAttributeKeyToDisplay = attributeKey
        let costedAttributeValueToDisplay = selectedAttributes[attributeKey]

        const costedAttributeData = costedAttributes[attributeKey]
        if (costedAttributeData?.name) {
          costedAttributeKeyToDisplay = costedAttributeData.name
        }

        const selectedCostedAttributeValue = costedAttributeData?.options?.find(
          (option) => option.value === selectedAttributes[attributeKey]
        )
        if (selectedCostedAttributeValue?.name) {
          costedAttributeValueToDisplay = selectedCostedAttributeValue.name
        }

        return (
          <tr key={attributeKey}>
            <td className={styles.attributeTitle}>{costedAttributeKeyToDisplay}:</td>
            <td className={styles.meta}>{costedAttributeValueToDisplay}</td>
          </tr>
        )
      })}
    </>
  )
}
