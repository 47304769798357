import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import {
  SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID,
  SALES_CHANNELS_STORE_CONNECTED_SUCCESS_TOAST_ID
} from '../constants'
import { StatusEnum } from '../../../enums'
import { useMerchantService } from '../../../hooks'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { saveTikTokSalesChannelCode } from '../helpers/saveTikTokSalesChannelCode.helper'
import { postNewRutterSalesChannel, NewRutterSalesChannelErrorInterface } from '../helpers'

export function ConnectSalesChannelFromRedirect() {
  const history = useHistory()
  const { search } = useLocation()

  const [authenticationStatus, setAuthenticationStatus] = useState(StatusEnum.Idle)

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const token = searchParams.get(QUERY_PARAMS.SALES_CHANNEL.CONNECT_REDIRECT.TOKEN)
  const tokenValueFromParam = useMemo(() => token ?? '', [token])

  const code = searchParams.get(QUERY_PARAMS.SALES_CHANNEL.CONNECT_REDIRECT.CODE)
  const codeValueFromParam = useMemo(() => code ?? '', [code])

  const { merchantDetails, mutateMerchantDetails } = useMerchantService()

  useEffect(() => {
    async function fetchData() {
      if (codeValueFromParam || !tokenValueFromParam || authenticationStatus !== StatusEnum.Idle) {
        return
      }

      try {
        if (!merchantDetails) {
          throw Error('No merchant details')
        }

        setAuthenticationStatus(StatusEnum.Loading)
        const { data } = await postNewRutterSalesChannel({
          merchantId: merchantDetails.id,
          publicToken: tokenValueFromParam
        })
        await mutateMerchantDetails()

        createToast({
          content: 'We are currently retrieving your products from your store.',
          duration: 4000,
          heading: `${data?.platform} store connected`,
          id: SALES_CHANNELS_STORE_CONNECTED_SUCCESS_TOAST_ID,
          type: 'success'
        })
        setAuthenticationStatus(StatusEnum.Success)
        window.analytics.track('Sales Channel Connected via Platform Redirect', { platformName: data?.platform })
        history.push(ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(data?.id))
      } catch (error) {
        setAuthenticationStatus(StatusEnum.Error)
        const errorResponse = error as FetchErrorInterface<NewRutterSalesChannelErrorInterface>
        const failures = errorResponse.responseBodyJson?.failures ?? {}
        const failureDoesNotRequireToast = failures['SalesChannel']?.find(
          (failure) => failure?.code === 'AlreadyExists'
        )

        if (!failureDoesNotRequireToast) {
          createErrorToast({
            errorCode: `${errorResponse.status}-${tokenValueFromParam}`,
            heading: 'Failed to connect store',
            id: SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID
          })
          window.analytics.track('Sales Channel Connection Failed via Platform Redirect')
        }

        history.replace(ROUTE_PATHS.SALES_CHANNELS.INDEX)
      }
    }
    fetchData()
  }, [
    merchantDetails,
    tokenValueFromParam,
    searchParams,
    history,
    authenticationStatus,
    codeValueFromParam,
    mutateMerchantDetails
  ])

  useEffect(() => {
    async function saveCode() {
      if (!codeValueFromParam || tokenValueFromParam || authenticationStatus !== StatusEnum.Idle) {
        return
      }

      try {
        if (!merchantDetails) {
          throw Error('No merchant details')
        }

        setAuthenticationStatus(StatusEnum.Loading)

        const data = await saveTikTokSalesChannelCode(codeValueFromParam)

        await mutateMerchantDetails()

        createToast({
          content: 'We are currently retrieving your products from your store.',
          duration: 4000,
          heading: `${data?.platform} store connected`,
          id: SALES_CHANNELS_STORE_CONNECTED_SUCCESS_TOAST_ID,
          type: 'success'
        })
        setAuthenticationStatus(StatusEnum.Success)
        window.analytics.track('Sales Channel Connected via Platform Redirect', { platformName: data?.platform })
        history.push(ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(data?.id))
      } catch (error) {
        setAuthenticationStatus(StatusEnum.Error)
        const errorResponse = error as FetchErrorInterface<NewRutterSalesChannelErrorInterface | undefined>
        const failures = errorResponse?.responseBodyJson?.failures ?? {}
        const failureDoesNotRequireToast = failures['SalesChannel']?.find(
          (failure) => failure?.code === 'AlreadyExists'
        )

        if (!failureDoesNotRequireToast) {
          createErrorToast({
            errorCode: `${errorResponse.status}-${codeValueFromParam}`,
            heading: 'Failed to connect store',
            id: SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID
          })
          window.analytics.track('Sales Channel Connection Failed via Platform Redirect')
        }

        history.replace(ROUTE_PATHS.SALES_CHANNELS.INDEX)
      }
    }

    saveCode()
  }, [authenticationStatus, codeValueFromParam, history, merchantDetails, tokenValueFromParam, mutateMerchantDetails])

  return (
    <div className="grid min-h-screen w-full place-content-center">
      <p className="text-xl">Connecting to sales channel...</p>
    </div>
  )
}
