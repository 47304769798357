// @flow

export const ALL = 'ALL'
export const NO_SELECTION = 'NO_SELECTION'
export const MAX_INTERVAL_VALUE = 100000

export const UNAVAILABLE_PRODUCT_TYPES = {
  PHOTOBOOK_HARD_COVER: 'Photobook Hard Cover',
  PHOTOBOOK_SOFT_COVER: 'Photobook Soft Cover',
  HIDE_IN_MOF: 'Hide in MOF'
}
