import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { PackingSlipDownloadStatus } from '../../../enums'

interface OrderDetailPackingSlipPropsInterface {
  packingSlipUrl?: string
  packingSlipStatus: PackingSlipDownloadStatus
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function OrderDetailPackingSlip({ packingSlipUrl, packingSlipStatus }: OrderDetailPackingSlipPropsInterface) {
  return (
    <div className="isolate w-full border bg-white p-6" data-test="order-detail-packing-slip">
      <div className="flex flex-col font-normal">
        <h2 className="mt-0 pb-6 text-lg">Packing slip</h2>
        <BuildPackingSlip packingSlipUrl={packingSlipUrl} packingSlipStatus={packingSlipStatus} />
      </div>
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Helpers                                   */
/* -------------------------------------------------------------------------- */
function BuildPackingSlip({ packingSlipUrl, packingSlipStatus }: OrderDetailPackingSlipPropsInterface) {
  if (packingSlipStatus === PackingSlipDownloadStatus.NONE) {
    return <NoPackingSlipAvailable text={'No packing slip'} />
  }

  switch (packingSlipStatus) {
    case PackingSlipDownloadStatus.ERROR:
      return <NoPackingSlipAvailable text={'Unknown error occured'} />
    case PackingSlipDownloadStatus.EXPIRED:
      return <NoPackingSlipAvailable text={'Packing slip expired'} />
    case PackingSlipDownloadStatus.NOT_YET_DOWNLOADED:
    case PackingSlipDownloadStatus.QUEUED:
    case PackingSlipDownloadStatus.DOWNLOADING:
      return <NoPackingSlipAvailable text={'Packing slip downloading'} />
    default:
      return <PackingSlip packingSlipUrl={packingSlipUrl ?? ''} />
  }
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
function PackingSlip({ packingSlipUrl }: { packingSlipUrl: string }) {
  return (
    <>
      <a
        href={packingSlipUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center text-purple-500 underline-offset-1 hover:underline"
        title="View packing slip"
        data-test="packing-slip__url"
      >
        <DocumentTextIcon className="h-8 w-8" aria-hidden="true" />
        <span className="ml-2">View packing slip</span>
      </a>
    </>
  )
}

function NoPackingSlipAvailable({ text }: { text: string }) {
  return (
    <>
      <div className="flex items-center text-gray-600">
        <DocumentTextIcon className="h-8 w-8" aria-hidden="true" />
        <span className="ml-2">{text}</span>
      </div>
    </>
  )
}
