import { useParams } from 'react-router'

import { useOrderActions, useOrderDetail } from '../../../hooks'
import { OrderDetailAddress } from '../../OrderDetail/components'

export function OrderEditAddress() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const { orderActions } = useOrderActions(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  const editAvailable = Boolean(orderActions?.data.updateRecipient.isAvailable)

  return (
    <OrderDetailAddress
      mutateOrderDetails={mutateOrderDetails}
      orderId={orderDetails.id}
      orderRecipient={orderDetails.recipient}
      showEditButton={editAvailable}
    />
  )
}
