import { fetcher } from '../../../helpers'
import { ImageLibraryImageInterface } from '../../../hooks'

export function removeTagsFromImageInLibrary({
  imageLibraryImage,
  tags
}: {
  imageLibraryImage: ImageLibraryImageInterface
  tags: string[]
}) {
  const newListOfTags = [...imageLibraryImage.tags]

  tags.forEach((tag) => {
    const tagExists = newListOfTags?.map((a) => a.toLowerCase()).indexOf(tag.toLowerCase())

    if (tagExists > -1) {
      newListOfTags.splice(tagExists, 1)
    }
  })

  return fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryImage.public_id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      tags: newListOfTags
    })
  })
}
