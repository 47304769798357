// @flow
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue'
import type { ThunkAsync, AppState, DispatchFunc, Response } from '../../types'
import type { GetV4ProductDetailsSuccessResult } from '../types/catalogue'
import type { V4ProductDetailsResponse } from '../../types/catalogue/V4ProductDetails'

export const GET_V4_PRODUCT_DETAILS = 'GET_V4_PRODUCT_DETAILS'
export const GET_V4_PRODUCT_DETAILS_SUCCESS = 'GET_V4_PRODUCT_DETAILS_SUCCESS'
export const GET_V4_PRODUCT_DETAILS_ERROR = 'GET_V4_PRODUCT_DETAILS_ERROR'

export function getV4ProductDetails(sku: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc): Promise<GetV4ProductDetailsSuccessResult> => {
    return dispatch({
      [RSAA]: {
        method: 'GET',
        endpoint: selectProdigiApiEndpoint() + '/v4.0/products/' + sku,
        bailout: shouldBailout(sku),
        types: [
          {
            type: GET_V4_PRODUCT_DETAILS,
            meta: {
              enhancements: [LOG_STATUS],
              loggerId: sku
            }
          },
          {
            type: GET_V4_PRODUCT_DETAILS_SUCCESS,
            payload: async (action: any, state: AppState, res: Response<V4ProductDetailsResponse>) => {
              const productJson: V4ProductDetailsResponse = await res?.json?.()

              const isPhotobook = productJson?.product?.sku?.toUpperCase?.().startsWith?.('BOOK-')
              if (!isPhotobook) {
                return productJson
              }

              const mappedPrintAreas = {}
              Object.entries(productJson?.product?.printAreas ?? {}).forEach(([printAreaName, printAreaData]) => {
                if (printAreaName?.toLowerCase() === 'spine') {
                  return
                }

                mappedPrintAreas[printAreaName] = printAreaData
              })

              const mappedProductData: V4ProductDetailsResponse = {
                ...productJson,
                product: { ...productJson.product, printAreas: mappedPrintAreas }
              }

              return mappedProductData
            }
          },
          GET_V4_PRODUCT_DETAILS_ERROR
        ]
      }
    })
  }
}

function shouldBailout(sku: string) {
  return (state: AppState): boolean => {
    const productDetails = getV4ProductDetailsBySku(state, sku)
    const hasProductDetails = Boolean(productDetails)

    return hasProductDetails
  }
}
