// @flow

import type { Node, Element } from 'react'
import React from 'react'
import cx from 'classnames'
import styles from './Title.module.css'

type Props = {|
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  dataTest?: string,
  children: string,
  className?: any
|}

function Title (props: Props): Element<string> | Node {
  const Element = props.element || 'h1'
  return (
    <Element data-test={props.dataTest ?? 'title-element'} className={cx(styles.base, props.className)}>{props.children}</Element>
  )
}

export default Title
