// @flow
import type { Element } from 'react'
import React from 'react'
import currencyFormat from '../../../../helpers/currencyFormat'
import styles from './ItemPrice.module.css'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'

type ItemPriceProps = {|
  isLoading: boolean,
  isZeroPricedItem: boolean,
  priceFrom: number,
  exactPrice?: number,
  currency: string
|}

export default function ItemPrice ({
  isLoading,
  isZeroPricedItem,
  exactPrice,
  priceFrom,
  currency
}: ItemPriceProps): Element<'p' | 'span'> {
  if (isZeroPricedItem) {
    return <p className={styles.price}>Free</p>
  } else if (isLoading) {
    return <span>Loading price<LoadingEllipsis className={styles.loadingEllipsis}/></span>
  } else if (exactPrice) {
    return (
      <p className={styles.price} data-test="item-price">
        {currencyFormat(exactPrice, currency)}
      </p>
    )
  } else {
    return (
      <p className={styles.price} data-test="item-price">
        From {currencyFormat(priceFrom, currency)}
      </p>
    )
  }
}
