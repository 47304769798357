import { useLocation, useParams } from 'react-router-dom'

import Banner, { BannerTypeEnum } from '../../Banner'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { useOrderDetail } from '../../../hooks'

export function PaymentAuthBanner() {
  const { search } = useLocation()
  const { id: orderId } = useParams<{ id: string }>()

  const { orderDetailsResponse } = useOrderDetail(orderId)

  if (!orderDetailsResponse) {
    throw Error('No order details')
  }

  const searchParams = new URLSearchParams(search)
  const fulfilmentId = orderDetailsResponse.data.order.fulfilmentId.replaceAll('ord_', '')

  let paymentAuthUrl = `${ROUTE_PATHS.PAYMENT_AUTHORISATION}?${QUERY_PARAMS.PAYMENT.ORDER_ID}=${orderId}&${QUERY_PARAMS.PAYMENT.FULFILMENT_ID}=${fulfilmentId}`
  const prevParam = searchParams.get(QUERY_PARAMS.ORDER_DETAIL.PREV)
  if (prevParam) {
    paymentAuthUrl += `&${QUERY_PARAMS.ORDER_DETAIL.PREV}=${encodeURIComponent(prevParam)}`
  }

  return (
    <Banner
      buttonText="Authorise now"
      buttonLink={paymentAuthUrl}
      message="This order is on hold because your payment requires authorisation."
      type={BannerTypeEnum.Error}
      onButtonClick={() => {
        window.analytics.track('Clicked Authorise Now', { orderId })
      }}
    />
  )
}
