// @flow
import React from 'react'

import styles from '../AppSettings.module.css'
import type { User } from '../../../../../types'
import AddDomain from '../../AddDomain/AddDomain'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'
import { APP_TYPES } from '../../../../Apps/constants'

type Props = {|
  appId: string,
  appType: string,
  domains: {|
    value: string,
    connected: boolean
  |}[],
  isUpdatingDomains: boolean,
  user: User,
  onTestConnection: () => void,
  onUpdateDomains: (domainAdded: string) => void
|}

type State = {|
  showAddDomainModal: boolean
|}

class AppDomains extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      showAddDomainModal: false
    }
  }

  toggleAddDomainModal: ((shouldDisplay: boolean) => void) = (shouldDisplay: boolean) => {
    this.setState({ showAddDomainModal: shouldDisplay })
  }

  onUpdateDomains: ((domainAdded: string) => void) = (domainAdded: string) => {
    this.props.onUpdateDomains(domainAdded)
    this.toggleAddDomainModal(false)
  }

  render (): React$Node {
    const {
      appId,
      appType,
      domains,
      user,
      isUpdatingDomains,
      onTestConnection
    } = this.props
    const appUrl = APP_TYPES[appType].hostName

    return (
      <div className={styles.appDomainsContainer}>
        {domains.length > 0 &&
        <div className={styles.domainsTableContainer}>
          <table className={styles.domainsTable}>
            <thead>
              <tr>
                <th>Domain</th>
                <th>CNAME</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {domains.map((domain) => (
                <tr key={domain.value}>
                  <td>{domain.value}</td>
                  <td>{appUrl}</td>
                  {domain.connected
                    ? <td className={styles.completeStatus}>Connected</td>
                    : <td className={styles.pendingStatus}>Pending</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
        <div className={styles.domainButtonsContainer}>
          <Button className={styles.addDomainButton} onClick={() => this.toggleAddDomainModal(true)}>Add domain</Button>
          {domains.length > 0 &&
            <Button className={styles.testConnectionButton} loading={isUpdatingDomains} onClick={onTestConnection}>
              {isUpdatingDomains ? 'Updating' : 'Refresh status'}
            </Button>}
        </div>
        {this.state.showAddDomainModal &&
          <Modal className={styles.modalContainer} close={() => this.toggleAddDomainModal(false)}>
            <AddDomain
              appId={appId}
              appType={appType}
              user={user}
              onBack={() => this.toggleAddDomainModal(false)}
              onFinish={(domainAdded) => this.onUpdateDomains(domainAdded)} />
          </Modal>}
      </div>
    )
  }
}

export default AppDomains
