// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function Analytics ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 14.75H18.5V18.5H17V14.75ZM14 11H15.5V18.5H14V11ZM7.25 18.5C5.17996 18.4975 3.50248 16.82 3.5 14.75H5C5 15.9926 6.00736 17 7.25 17C8.49264 17 9.5 15.9926 9.5 14.75C9.5 13.5074 8.49264 12.5 7.25 12.5V11C9.32107 11 11 12.6789 11 14.75C11 16.8211 9.32107 18.5 7.25 18.5ZM20 0.5H2C1.17192 0.500827 0.500827 1.17192 0.5 2V20C0.500827 20.8281 1.17192 21.4992 2 21.5H20C20.8279 21.4988 21.4988 20.8279 21.5 20V2C21.4992 1.17192 20.8281 0.500827 20 0.5ZM20 7.25H9.5V2H20V7.25ZM8 2V7.25H2V2H8ZM2 20V8.75H20L20.0015 20H2Z" fill="#565656"/>
    </svg>
  )
}
