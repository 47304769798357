// @flow
import { PositionUnitConverter } from '@prodigi-group/components-image-editor'
import type { Dimensions, CropRectangle, LengthUnit, Point } from '../../types'

export function convertPrintPxPositionToUserUnit(
  positionInPrintPx: Point,
  userUnit: LengthUnit,
  printDimensionsInPx: Dimensions,
  artworkDimensionsInPx: Dimensions,
  cropRectangle: CropRectangle,
  printDpi: number
): Point {
  const converter = new PositionUnitConverter(printDimensionsInPx, artworkDimensionsInPx, cropRectangle, printDpi)

  const cropRectanglePosition = converter.printPxToCropRectanglePosition(positionInPrintPx)

  return converter.cropRectanglePositionToPositionUnit(cropRectanglePosition, userUnit)
}
