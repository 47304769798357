import toast from 'react-hot-toast'
import { useState, useRef, useEffect } from 'react'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'

import {
  ImageLibraryImageInterface,
  ImageLibraryImageType,
  useImageLibraryImages,
  useSplitToggle
} from '../../../hooks'
import Button from '../../Button'
import { createToast } from '../../Toast'
import SupportLink from '../../SupportLink'
import OverlayPortal from '../../OverlayPortal'
import ClipboardCopy from '../../ClipboardCopy'
import { IMAGE_LIBRARY_TOASTS } from '../constants'
import { IconCheckMark } from '../../InlineMenu/components'
import { ThumbnailPolling } from './ThumbnailPolling.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { StatusType, FetchErrorInterface } from '../../../interfaces'
import { uploadFileToImageLibrary, getDefaultImageLibraryErrorMessage } from '../../../helpers'

interface UploadFromFilePropsInterface {
  isSaveToLibraryChecked: boolean
  type: ImageLibraryImageType
  onUploadStart: () => void
  onUploadComplete: () => void
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function UploadFromFile({
  isSaveToLibraryChecked,
  type,
  onUploadStart,
  onUploadComplete,
  onSelectImage
}: UploadFromFilePropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  const [fileUploadStatus, setFileUploadStatus] = useState<StatusType>('idle')
  const [pollForThumbnailImageId, setPollForThumbnailImageId] = useState<string | null>(null)

  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return () => {
      toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (fileUploadStatus === 'success') {
      timeoutId = setTimeout(() => {
        setFileUploadStatus('idle')
      }, 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [fileUploadStatus])

  async function handleUploadSuccess(imageData: ImageLibraryImageInterface) {
    if (isSaveToLibraryChecked) {
      await mutateImageLibraryImages()
    }
    setFileUploadStatus('success')
    onUploadComplete()

    if (onSelectImage) {
      onSelectImage(imageData)
    }
  }

  function handleUploadError({
    errorCode,
    errorMessage = null
  }: {
    errorCode: string | number
    errorMessage?: string | null
  }) {
    window.analytics.track('Image library - image upload failed', { type: 'file' })
    createToast({
      content: (
        <>
          {errorMessage && <p className="mb-2">{errorMessage}</p>}
          <p>
            Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
          </p>
        </>
      ),
      duration: Infinity,
      footer: (
        <span className="text-sm text-gray-600">
          Code: {errorCode} <ClipboardCopy showText={false} text={errorCode.toString()} />
        </span>
      ),
      heading: 'Image upload failed',
      id: IMAGE_LIBRARY_TOASTS.ERROR,
      type: 'error-with-close'
    })
    setFileUploadStatus('error')
    onUploadComplete()
  }

  async function handleFileUpload(file: File) {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    setFileUploadStatus('loading')
    window.analytics.track('Image library - clicked: Upload an image', { type: 'file' })
    onUploadStart()
    try {
      const imageData = await uploadFileToImageLibrary({ file, shouldSaveToLibrary: isSaveToLibraryChecked, type })

      if (imageData.upload_status !== 'success') {
        setPollForThumbnailImageId(imageData.public_id)
        return
      }

      await handleUploadSuccess(imageData)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>
      const errorMessage = getDefaultImageLibraryErrorMessage(errorResponse)
      const errorCode = errorResponse.status ?? '0'
      handleUploadError({ errorCode, errorMessage })
    }
  }

  let fileTypes = '.png, .jpg, .jpeg'
  if (isPDFSplitOn) {
    fileTypes += ', .pdf'
  }

  return (
    <>
      <Button
        disabled={fileUploadStatus === 'success'}
        isLoading={fileUploadStatus === 'loading'}
        startIcon={fileUploadStatus === 'success' ? undefined : <CloudArrowUpIcon className="h-7 w-7" />}
        onClick={() => fileInputRef.current?.click()}
      >
        <UploadButtonContent fileUploadStatus={fileUploadStatus} />
      </Button>
      <input
        accept={fileTypes}
        data-test="image-library-upload-from-file-input"
        hidden
        key={fileUploadStatus}
        ref={fileInputRef}
        type="file"
        onChange={(event) => {
          if (!event.target.files) {
            return
          }
          handleFileUpload(event.target.files[0])
        }}
      />

      {pollForThumbnailImageId && (
        <ThumbnailPolling
          imageLibraryId={pollForThumbnailImageId}
          onComplete={async ({ imageLibraryImage, imageLibraryImageFetchError }) => {
            setPollForThumbnailImageId(null)

            if (imageLibraryImageFetchError) {
              const errorMessage = getDefaultImageLibraryErrorMessage(imageLibraryImageFetchError)
              const errorCode = `P-${imageLibraryImageFetchError?.status ?? '0'}-${pollForThumbnailImageId}`
              handleUploadError({ errorCode, errorMessage })
              return
            }

            if (!imageLibraryImage || imageLibraryImage.upload_status !== 'success') {
              const errorCode = `P-${imageLibraryImage?.upload_status ?? 'P'}-${pollForThumbnailImageId}`
              handleUploadError({ errorCode })
              return
            }

            handleUploadSuccess(imageLibraryImage)
          }}
        />
      )}

      {fileUploadStatus === 'loading' && <OverlayPortal />}
    </>
  )
}

function UploadButtonContent({ fileUploadStatus }: { fileUploadStatus: StatusType }) {
  switch (fileUploadStatus) {
    case 'loading':
      return <>Uploading...</>
    case 'success':
      return (
        <span className="flex items-center gap-2">
          <IconCheckMark isVisible={true} initial={true} transition={{ duration: 0.5 }} />
          Uploaded
        </span>
      )
    default:
      return (
        <span>
          Upload<span className="hidden sm:inline"> a new image</span>
        </span>
      )
  }
}
