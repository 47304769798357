import Modal from '../Modal'
import LoadingSpinner from '../LoadingSpinner'

export default function OverlayLoadingSpinner({ isVisible }: { isVisible: boolean }) {
  return (
    <Modal
      className="h-100vh max-h-100vh w-100vw max-w-100vw overflow-auto rounded-none bg-transparent"
      closeOnEscape={false}
      closeOnInteractionOutside={false}
      open={isVisible}
      showBorder={false}
      showHeader={false}
    >
      <div className="grid h-screen place-content-center">
        <LoadingSpinner className="h-12 w-12 text-white" />
      </div>
    </Modal>
  )
}
