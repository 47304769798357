// @flow
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import type { Thunk, ThunkAsync, V4OrderRecipient, DispatchFunc, CustomerAddress, V4ApiActionOutcome } from '../../types'
import { convertOrderIdToV4 } from '../../helpers/orderId'
import { mapCustomerAddressToRecipient } from '../../helpers'
import { fetchOrderDetails } from './fetchOrderDetails'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { DEFAULT_ERROR_MESSAGE } from '../../data/errorMessages'
import { getOrderDetailsV4 } from './getOrderDetailsV4'

export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS'
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS'
export const UPDATE_SHIPPING_ADDRESS_ERROR = 'UPDATE_SHIPPING_ADDRESS_ERROR'

const ACTION_NOT_AVAILABLE_MESSAGE = 'Sorry, the address can no longer be changed.'

export function updateShippingAddress (
  orderId: string,
  customerAddress: CustomerAddress
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc) => {
    window.analytics.track('Update shipping address')

    const recipient = mapCustomerAddressToRecipient(customerAddress)
    const v4OrderId = convertOrderIdToV4(orderId)

    const actionResult = await dispatch(updateShippingAddressRsaa(v4OrderId, recipient))

    const isRequestSuccessful = Boolean(
      actionResult.type === UPDATE_SHIPPING_ADDRESS_SUCCESS &&
      actionResult.payload &&
      actionResult.payload.outcome === V4_API_ACTION_OUTCOME.UPDATED
    )

    if (isRequestSuccessful) {
      dispatch(getOrderDetailsV4(orderId))
      dispatch(fetchOrderDetails(orderId))
    } else if (actionResult.type === UPDATE_SHIPPING_ADDRESS_SUCCESS && !isRequestSuccessful) {
      // The API returns success status codes even when the request fails

      const errorMessage = getErrorMessage(actionResult.payload.outcome)

      dispatch(onAddressUpdateError(errorMessage))
    }
  }
}

function getErrorMessage (actionOutcome: V4ApiActionOutcome): string {
  const isActionAvailable = Boolean(
    actionOutcome !== V4_API_ACTION_OUTCOME.ACTION_NOT_AVAILABLE &&
    actionOutcome !== V4_API_ACTION_OUTCOME.FAILED_TO_UPDATE
  )

  return isActionAvailable
    ? DEFAULT_ERROR_MESSAGE
    : ACTION_NOT_AVAILABLE_MESSAGE
}

function updateShippingAddressRsaa (orderId: string, recipient: V4OrderRecipient): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const endpoint = selectProdigiApiEndpoint() + `/v4.0/orders/${orderId}/actions/updateRecipient`

    return dispatch({
      [RSAA]: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        endpoint,
        body: JSON.stringify(recipient),
        types: [
          {
            type: UPDATE_SHIPPING_ADDRESS,
            meta: {
              enhancements: [ LOG_STATUS ]
            }
          },
          UPDATE_SHIPPING_ADDRESS_SUCCESS,
          UPDATE_SHIPPING_ADDRESS_ERROR
        ]
      }
    })
  }
}

function onAddressUpdateError (message: string) {
  return {
    type: UPDATE_SHIPPING_ADDRESS_ERROR,
    message
  }
}
