import { useOs } from '@mantine/hooks'
import { useDebouncedCallback } from 'use-debounce'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import SearchField from '../../SearchField'
import { QUERY_PARAMS } from '../../../constants'
import { HOTKEYS } from '../../../constants/hotkeys.const'

export function ImageLibraryListActionTagSearchBar() {
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const searchParam = searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TAG_SEARCH)
  const searchValueFromParam = useMemo(() => searchParam ?? '', [searchParam])

  const [searchValue, setSearchValue] = useState<string>(searchValueFromParam)

  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const searchHotkeyDisplay = isAppleOs
    ? HOTKEYS.IMAGE_LIBRARY.TAG_SEARCH.DISPLAY.APPLE
    : HOTKEYS.IMAGE_LIBRARY.TAG_SEARCH.DISPLAY.DEFAULT

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSearchValue(searchValueFromParam)
  }, [searchValueFromParam, searchParams])

  const debouncedSearch = useDebouncedCallback(async (searchValue: string) => {
    if (searchValue.trim() === '') {
      searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TAG_SEARCH)
      history.push({ pathname: location.pathname, search: searchParams.toString() })
      return
    }

    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TAG_SEARCH, searchValue)
    history.push({ pathname: location.pathname, search: newSearchParams.toString() })
  }, 500)

  function handleSearch(value: string) {
    setSearchValue(value)
    debouncedSearch(value)
  }

  return (
    <div className="sticky top-0 z-10 w-full bg-white px-3" data-test="inline-menu-scroll__search">
      <div className="py-4" data-test="inline-menu-scroll__search">
        <SearchField
          hotkey={HOTKEYS.IMAGE_LIBRARY.TAG_SEARCH.TRIGGER}
          hotkeyDisplay={searchHotkeyDisplay}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => {
            const newValue = event.target.value
            handleSearch(newValue)
          }}
          onClearSearch={() => handleSearch('')}
        />
      </div>
    </div>
  )
}
