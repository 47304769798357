import { OrderDetailRecipientInterface } from '../../../interfaces'
import { isCountyOrStateRequired, isPhoneNumberRequired, isPostcodeRequired } from '../../../helpers'

export function areAllRequiredAddressFieldsFilledIn(
  { address, name, phone }: OrderDetailRecipientInterface,
  checkContactInfo: boolean
): boolean {
  const { countryCode, line1, postcodeOrZipCode, stateOrCounty, townOrCity } = address
  const hasPhoneNumberIfRequired = checkContactInfo && isPhoneNumberRequired(countryCode) ? Boolean(phone) : true
  const hasCountyOrStateIfRequired = isCountyOrStateRequired(countryCode) ? Boolean(stateOrCounty) : true
  const hasPostcodeIfRequired = isPostcodeRequired(countryCode) ? Boolean(postcodeOrZipCode) : true

  return Boolean(
    line1 && name && townOrCity && hasPhoneNumberIfRequired && hasCountyOrStateIfRequired && hasPostcodeIfRequired
  )
}
