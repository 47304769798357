// @flow
import { LOADING, ERROR, SUCCESS } from '../data/rsaa'
import type { RsaaStatusProps, RsaaStatus } from '../types'

export function mapRsaaStateToProps (rsaa: RsaaStatus): RsaaStatusProps {
  return {
    loading: rsaa.status === LOADING,
    error: rsaa.status === ERROR,
    success: rsaa.status === SUCCESS,
    statusMessage: rsaa.statusMessage || '',
    statusCode: rsaa.statusCode,
    errorPayload: rsaa.errorPayload
  }
}
