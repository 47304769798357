// @flow
import React from 'react'
import type { Element } from 'react'
import cx from 'classnames'
import styles from './BareButton.module.css'

type BareButtonProps = {|
  children: React$Node,
  className?: string,
  dataTest?: string,
  onClick: () => void
|}

export default function BareButton ({onClick, className, children, dataTest}: BareButtonProps): Element<*> {
  return (
    <button className={cx(styles.bareButton, className)} onClick={onClick} data-test={dataTest}>
      {children}
    </button>
  )
}
