// @flow
import type { Dictionary, FormattedOrderItem } from '../../types'
import { values } from '../../helpers/dictionary'

export function updateOrderItemProductDetails (
  orderItems: Dictionary<FormattedOrderItem>,
  idsOfItemsToUpdate: string[],
  sku: string,
  selectedAttributes: Dictionary<string>
): Dictionary<FormattedOrderItem> {
  return values(orderItems).reduce((orderItemAcc, orderItem) => {
    const shouldUpdateItem = idsOfItemsToUpdate.includes(orderItem.id)

    if (shouldUpdateItem) {
      orderItemAcc[orderItem.id] = {
        ...orderItem,
        sku: sku,
        selectedAttributes: selectedAttributes
      }
    } else {
      orderItemAcc[orderItem.id] = orderItem
    }

    return orderItemAcc
  }, {})
}
