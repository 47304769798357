import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { useSplitToggle } from './useSplitToggle'
import { FEATURE_NAMES } from '../../split-io/feature-names'
import { useMerchantService } from './useMerchantService.hook'
import { FetchErrorInterface, InsertSetType } from '../interfaces'

export type MerchantBrandingType = { insertSets: InsertSetType[] }

interface MerchantBrandingResponseInterface {
  data: MerchantBrandingType
  traceParent: string
}

export interface MerchantBrandingErrorInterface {
  outcome?: string
  traceParent?: string
}

export function useMerchantBranding({ config }: { config?: SWRConfiguration } = {}) {
  const { merchantDetails } = useMerchantService()
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  const url = merchantDetails?.id
    ? isBrandingSettingsOn &&
      `${process.env.REACT_APP_OMS_MERCHANT_PREFERENCE_SERVICE}/merchant/${merchantDetails.id}/branding`
    : null

  const { data, mutate, error } = useSWR<
    MerchantBrandingResponseInterface,
    FetchErrorInterface<MerchantBrandingErrorInterface>
  >(url, fetcher, config)

  return {
    merchantBrandingResponse: data,
    merchantBrandingFetchError: error,
    isLoadingMerchantBranding: url ? !data && !error : false,
    mutateMerchantBranding: mutate
  }
}
