import { RecommendedProductInterface } from '../interfaces'

export const RECOMMENDED_PRODUCTS_CATALOGUE: RecommendedProductInterface[] = [
  {
    category: 'Wall art',
    image: '/img/v2/product-catalogue/canvases.jpg',
    label: 'Stretched canvas',
    productType: 'Stretched canvas'
  },
  {
    category: 'Wall art',
    image: '/img/v2/product-catalogue/framed-prints_instagram-frames.jpg',
    label: 'Framed prints',
    productType: 'Framed prints'
  },
  {
    category: 'Stationery',
    image: '/img/v2/product-catalogue/stationery_greetings-cards.jpg',
    label: 'Greetings cards',
    productType: 'Greetings Cards'
  },
  {
    productType: 'Unisex Hoodies',
    label: 'Hoodies',
    category: 'Apparel'
  },
  {
    category: 'Stationery',
    label: 'Journals',
    productType: 'Journals'
  },
  {
    category: 'Drinkware',
    image: '/img/v2/product-catalogue/home-and-living.jpg',
    label: 'Mugs',
    productType: 'Mugs'
  },
  {
    category: 'Device Cases',
    image: '/img/v2/product-catalogue/technology.jpg',
    label: 'Phone cases',
    productType: 'Phone Case'
  },
  {
    category: 'Tattoos',
    label: 'Tattoos'
  }
]
