import Skeleton from '../../Skeleton'

export function LoadingOrderDetails() {
  return (
    <div className="container mx-auto space-y-4 py-8 xl:space-y-8" data-test="loading-order-details">
      <div className="h-32 w-1/2 lg:w-2/5">
        <Skeleton className="h-full w-full" />
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:gap-8">
        <div className="h-80 lg:col-span-2 lg:h-auto">
          <Skeleton className="h-full w-full" />
        </div>
        <div className="col-span-1 space-y-4 lg:row-span-1 xl:space-y-8">
          <div className="h-80">
            <Skeleton className="h-full w-full" />
          </div>
          <div className="h-80">
            <Skeleton className="h-full w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}
