import { useEffect } from 'react'

import { ArtworkInterface } from '../interfaces'
import LoadingSpinner from '../../LoadingSpinner'
import { useImageLibraryThumbnailGeneration } from '../../../hooks'
import { IMAGE_LIBRARY_URL_EXPIRY_DATE_QUERY_PARAM } from './ImageEditorPreview.component'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

export function PdfPreview({
  artwork,
  onArtworkError
}: {
  artwork: ArtworkInterface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onArtworkError?: (e?: CustomEvent<any> | string) => void
}) {
  const imageUrlExpiryParam = new URL(artwork.originalImageUrl).searchParams.get(
    IMAGE_LIBRARY_URL_EXPIRY_DATE_QUERY_PARAM
  )
  const imageExpiryDate = imageUrlExpiryParam ? new Date(imageUrlExpiryParam) : null
  const currentDate = new Date()
  const hasImageUrlExpired = imageExpiryDate ? currentDate > imageExpiryDate : false

  const { imageLibraryImage, imageLibraryImageFetchError, isLoadingImageLibraryImage } =
    useImageLibraryThumbnailGeneration(hasImageUrlExpired ? artwork.imageLibraryId : undefined, {
      touch: false,
      config: { revalidateOnFocus: false }
    })

  useEffect(() => {
    if (imageLibraryImageFetchError) {
      onArtworkError?.(`PDF-${imageLibraryImageFetchError.status ?? '0'}-${artwork.imageLibraryId ?? '0'}`)
    }
  }, [artwork.imageLibraryId, imageLibraryImageFetchError, onArtworkError])

  if (isLoadingImageLibraryImage) {
    return (
      <div className="grid place-content-center">
        <LoadingSpinner className="h-16 w-16 text-white" />
      </div>
    )
  }

  if (imageLibraryImageFetchError) {
    return null
  }

  return (
    <div className="relative h-[75vh] w-90vw md:h-[90%] md:w-[80%] xl:w-[60%]">
      <div className="pointer-events-none absolute inset-0 grid place-content-center">
        <LoadingSpinner className="h-16 w-16 text-white" />
      </div>
      <object
        type="application/pdf"
        className="relative h-full w-full"
        data={imageLibraryImage?.original_url ?? artwork.originalImageUrl}
      >
        <div className="absolute inset-0 grid place-content-center bg-gray-800 text-gray-100">
          {(artwork.smallImageUrl || imageLibraryImage?.thumbnail_urls?.small) && (
            <img src={imageLibraryImage?.thumbnail_urls.small ?? artwork.smallImageUrl} className="mx-auto mb-4 w-28" />
          )}
          <p className="text-center">File preview cannot be shown</p>
          <a
            className="btn btn-sm mx-auto mt-4 w-fit border-gray-400 text-gray-200 hover:border-gray-300"
            href={imageLibraryImage?.original_url ?? artwork.originalImageUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span>View pdf</span>
            <ArrowTopRightOnSquareIcon className="h-6 w-6" />
          </a>
        </div>
      </object>
    </div>
  )
}
