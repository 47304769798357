// @flow
import React from 'react'
import type { Element } from 'react'

import type { Event } from '../../types'
import { ALL, NO_SELECTION } from '../../data/catalogue'

// $FlowFixMe: TypeScript component
import SelectField from '../../v2/components/SelectField'

type DropdownFiltersProps = {|
  onSelectItemCategory: (e: Event) => void,
  onSelectProductType: (e: Event) => void,
  selectedItemCategory: string,
  categories: string[],
  productTypes: string[],
  selectedProductType: string,
  className?: string
|}

export default function DropdownFilters({
  className = '',
  selectedItemCategory,
  onSelectItemCategory,
  categories,
  productTypes,
  selectedProductType,
  onSelectProductType
}: DropdownFiltersProps): Element<*> | null {
  function getAllProductTypesLabel(): string {
    return selectedItemCategory !== ALL ? ` ${selectedItemCategory.toLowerCase()}` : ' Product Types'
  }

  const hasCategoriesToShow = categories && categories.length > 0
  const showProductTypeFilter = selectedItemCategory !== NO_SELECTION && productTypes && productTypes.length > 1

  if (!hasCategoriesToShow) {
    return null
  }

  return (
    <>
      <div className="w-full md:w-[300px]">
        <label htmlFor="searchCategory">Category</label>
        <SelectField
          id="searchCategory"
          dataTest="category-input"
          value={selectedItemCategory}
          onChange={onSelectItemCategory}
        >
          <option value={NO_SELECTION} disabled>
            Please choose...
          </option>
          <option value={ALL}>All Categories</option>
          {categories.map(toSelectFieldOption)}
        </SelectField>
      </div>

      {showProductTypeFilter && (
        <div className="w-full md:w-[300px]">
          <label id="searchProductType">Product type</label>
          <SelectField
            id="searchProductType"
            dataTest="product-type-input"
            value={selectedProductType}
            onChange={onSelectProductType}
          >
            <option value={ALL}>All {getAllProductTypesLabel()}</option>
            {productTypes.map(toSelectFieldOption)}
          </SelectField>
        </div>
      )}
    </>
  )
}

function toSelectFieldOption(value: string): Element<*> {
  return (
    <option key={value} value={value}>
      {value}
    </option>
  )
}
