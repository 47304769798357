import { useUser } from '../../hooks'
import DownloadPricing from '../DownloadPricing'

export default function Pricing() {
  return (
    <div className="container mx-auto flex flex-col">
      <h1 className="mr-auto mt-0 pb-8">Pricing</h1>
      <DownloadPricing showTitle={false} />
      <DownloadPricingUS />
    </div>
  )
}

function DownloadPricingUS() {
  const { user } = useUser()

  const isUserCountryUS = user?.company.countryCode === 'US'

  if (!isUserCountryUS) {
    return null
  }

  return (
    <div className="mt-12 flex w-full flex-col justify-between gap-4 border bg-white p-8 lg:flex-row lg:items-center lg:gap-8">
      <img
        alt="US"
        className="h-12 w-12 rounded-full object-cover object-center"
        src={`/img/flags-100/us.png`}
        title="US"
      />

      <div className="w-full">
        <h2 className="text-lg">Looking for US products?</h2>
        <p className="mt-2 text-base">Download a pricing CSV of our products made and shipped from the USA.</p>
      </div>

      <a
        className="btn btn-secondary mt-8 w-fit whitespace-nowrap lg:mt-0"
        href="https://prodigipricingpublic.blob.core.windows.net/public/ustest/prodigi-madeinusa.csv"
        rel="noreferrer"
        target="_blank"
        onClick={() => window.analytics.track('Clicked - Download US pricing CSV')}
      >
        Download USA product pricing
      </a>
    </div>
  )
}
