// @flow
import type { AppInsights } from './scripts/appInsights'
import { createStore, applyMiddleware, type Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import leMiddleware from './middleware/leMiddleware'
import rsaaMiddleware from './middleware/rsaaMiddleware'
import authMiddleware from './middleware/authMiddleware'
import handleRefreshTokenErrors from './middleware/handleRefreshTokenErrors'
import retryUnauthorisedRequests from './middleware/retryUnauthorisedRequests'
import abortInFlightRequests from './middleware/abortInFlightRequests'
import rootReducer from './reducers/rootReducer'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxLoggerMidware from './middleware/reduxLoggerMidware'
import { TEST } from './data/constants'
import { persistStore } from 'redux-persist'
import { initAppInsights } from './scripts/appInsights'
import type { AppState, Action } from './types'
import { configureRsaaLogging } from './actions/rsaa/configureRsaaLogging'
import configureRsaaLoggerMiddleware from './middleware/configureRsaaLoggerMiddleware'
import { jwtTimerMiddleware } from './middleware/jwtTimerMiddleware'

const history = createBrowserHistory()
export const appInsights: ?AppInsights = initAppInsights(history)

export default function configureStore (): Store<AppState, Action> {
  const testMode = process.env.REACT_APP_ENV === TEST
  const useReduxLogger = Boolean(process.env.REACT_APP_USE_REDUX_LOGGER)

  const middleware = [
    thunkMiddleware,
    configureRsaaLoggerMiddleware,
    abortInFlightRequests,
    retryUnauthorisedRequests,
    handleRefreshTokenErrors,
    authMiddleware,
    apiMiddleware,
    leMiddleware,
    rsaaMiddleware,
    testMode && useReduxLogger ? reduxLoggerMidware : null,
    jwtTimerMiddleware
  ]

  let storeEnhancer = applyMiddleware(...middleware.filter(Boolean).filter(m => m !== null))

  if (testMode) {
    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

    storeEnhancer = composeEnhancers(
      applyMiddleware(...middleware.filter(Boolean).filter(m => m !== null))
    )
  }

  const preloadedState: AppState = ({
    router: { history }
  }: any)

  const store = createStore(rootReducer, preloadedState, storeEnhancer)
  persistStore(store)

  store.dispatch(configureRsaaLogging())
  return store
}
