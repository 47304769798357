// @flow
import { countriesWithoutPostcodes } from '../../data'

export function isPostcodeRequired (country: string): boolean {
  const result = countriesWithoutPostcodes.find(x => x.code === country)
  if (result && Object.keys(result).length > 0) {
    return false
  }
  return true
}
