// @flow
import type { AppState, Dictionary } from '../types'
import {
  getSelectedOrderItemIds,
  getSelectedOrderIds,
  getSelectedArtworkOrderIds,
  getSelectedArtworkTransformationIds
} from '../selectors/csvUpload'

export function createListOfOrderItemIds (state: AppState, ignoredId: string): string[] {
  const selectedOrderItemIds = getSelectedOrderItemIds(state)

  return reduceIdList(selectedOrderItemIds, ignoredId)
}

export function createListOfOrderIds (state: AppState, ignoredId: string): string[] {
  const selectedOrdersIds = getSelectedOrderIds(state)

  return reduceIdList(selectedOrdersIds, ignoredId)
}

export function createListOfArtworkIds (state: AppState, ignoredId: string): string[] {
  const idsOfArtworkToUpdate = getSelectedArtworkOrderIds(state)

  return reduceIdList(idsOfArtworkToUpdate, ignoredId)
}

export function createListOfArtworkAndTransformationIds (
  state: AppState,
  sku: string,
  selectedAttributes: Dictionary<string>,
  ignoredId: string
): string[] {
  const idsToUpdate = getSelectedArtworkTransformationIds(state, sku, selectedAttributes)

  return reduceIdList(idsToUpdate, ignoredId)
}

function reduceIdList (ids: string[], ignoredId: string): string [] {
  return ids.reduce((idsAcc, id) => {
    if (id !== ignoredId) {
      idsAcc.push(id)
    }

    return idsAcc
  }, [])
}
