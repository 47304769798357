import type { RegisterFormDataInterface } from '../interfaces'

export const INITIAL_REGISTER_FORM_DATA: RegisterFormDataInterface = {
  email: {
    value: '',
    dirty: false,
    error: null,
    availability: null,
    valid: false
  },
  password: {
    value: '',
    dirty: false,
    error: null
  },
  confirmPassword: {
    value: '',
    dirty: false,
    error: null
  },
  fullName: {
    value: '',
    dirty: false,
    error: null
  },
  companyName: {
    value: '',
    dirty: false,
    error: null
  },
  marketingWhyCreatingAccount: {
    value: '',
    dirty: false,
    error: null
  },
  marketingPriorityToday: {
    value: '',
    dirty: false,
    error: null
  },
  countryName: {
    value: '',
    dirty: false,
    error: null
  },
  billingCurrency: {
    value: '',
    dirty: false,
    error: null
  },
  agreedTerms: {
    value: false,
    dirty: false,
    error: null
  },
  passwordErrorMessage: {
    value: '',
    dirty: false,
    error: null
  },
  showPassword: {
    value: false,
    dirty: false,
    error: null
  }
}
