import toast from 'react-hot-toast'
import { useState, useRef, useEffect } from 'react'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'

import Button from '../../Button'
import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { UploadingImageInterface } from '../interfaces'
import { IconCheckMark } from '../../InlineMenu/components'
import { IMAGE_LIBRARY_FULL_PAGE_TOASTS } from '../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { StatusType, FetchErrorInterface } from '../../../interfaces'
import { CONTENT_CONTAINER_ID } from '../../Navigation/Navigation.component'
import { ImageLibraryImageType, useImageLibraryImages, useSplitToggle } from '../../../hooks'
import { uploadFileToImageLibrary, getDefaultImageLibraryErrorMessage } from '../../../helpers'

interface UploadFromFilesPropsInterface {
  isSaveToLibraryChecked: boolean
  type: ImageLibraryImageType
  onUploadStart: () => void
  onUploadComplete: () => void
  onSetUploadingFiles: (files: React.SetStateAction<Record<string, UploadingImageInterface>>) => void
}

export function UploadFromFiles({
  isSaveToLibraryChecked,
  type,
  onUploadStart,
  onUploadComplete,
  onSetUploadingFiles
}: UploadFromFilesPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()
  const [fileUploadStatus, setFileUploadStatus] = useState<StatusType>('idle')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  useEffect(() => {
    return () => {
      toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (fileUploadStatus === 'success') {
      timeoutId = setTimeout(() => {
        setFileUploadStatus('idle')
      }, 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [fileUploadStatus])

  async function handleFileUploads(files: FileList) {
    toast.dismiss(IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR)
    setFileUploadStatus('loading')
    window.analytics.track('Image library - clicked: Upload multiple images', { type: 'file' })
    onUploadStart()
    document.getElementById(CONTENT_CONTAINER_ID)?.scrollTo(0, 0)

    const fileObject: Record<string, UploadingImageInterface> = {}
    Array.from(files).map((file) => {
      fileObject[file.name] = { status: 'loading', file, name: file.name }
    })

    onSetUploadingFiles((filesState) => ({
      ...filesState,
      ...fileObject
    }))

    const errorCodes = []
    for (const [key, value] of Object.entries(fileObject)) {
      try {
        const response = await uploadFileToImageLibrary({
          file: value.file,
          shouldSaveToLibrary: isSaveToLibraryChecked,
          type
        })

        onSetUploadingFiles((filesState) => ({
          ...filesState,
          [key]: { ...filesState[key], status: 'success', imageLibraryImage: response }
        }))
      } catch (error) {
        const errorResponse = error as FetchErrorInterface<{ detail?: string }>
        const errorMessage = getDefaultImageLibraryErrorMessage(errorResponse) ?? ''
        const errorCode = errorResponse.status?.toString() ?? '0'
        onSetUploadingFiles((filesState) => ({
          ...filesState,
          [key]: { ...filesState[key], status: 'error', errorCode, errorMessage }
        }))
        errorCodes.push(errorCode)
      }
    }

    await mutateImageLibraryImages()

    if (errorCodes.length > 0) {
      createErrorToast({
        heading: 'Upload failed',
        id: IMAGE_LIBRARY_FULL_PAGE_TOASTS.ERROR,
        errorMessage: 'One or more images failed to upload',
        errorCode: errorCodes.join(', ')
      })
    }

    onSetUploadingFiles((filesState) =>
      Object.fromEntries(Object.entries(filesState).filter(([, v]) => v.status === 'error'))
    )
    setFileUploadStatus('success')
    onUploadComplete()
  }

  let fileTypes = '.png, .jpg, .jpeg'
  if (isPDFSplitOn) {
    fileTypes += ', .pdf'
  }

  return (
    <>
      <Button
        disabled={fileUploadStatus === 'success'}
        isLoading={fileUploadStatus === 'loading'}
        startIcon={fileUploadStatus === 'success' ? undefined : <CloudArrowUpIcon className="h-7 w-7" />}
        onClick={() => fileInputRef.current?.click()}
      >
        <UploadButtonContent fileUploadStatus={fileUploadStatus} />
      </Button>
      <input
        accept={fileTypes}
        data-test="image-library-upload-from-file-input"
        hidden
        multiple
        key={fileUploadStatus}
        ref={fileInputRef}
        type="file"
        onChange={(event) => {
          if (!event.target.files) {
            return
          }
          handleFileUploads(event.target.files)
        }}
      />

      {fileUploadStatus === 'loading' && <OverlayPortal />}
    </>
  )
}

function UploadButtonContent({ fileUploadStatus }: { fileUploadStatus: StatusType }) {
  switch (fileUploadStatus) {
    case 'loading':
      return <>Uploading...</>
    case 'success':
      return (
        <span className="flex items-center gap-2">
          <IconCheckMark isVisible={true} initial={true} transition={{ duration: 0.5 }} />
          Uploaded
        </span>
      )
    default:
      return (
        <span>
          Upload<span className="hidden sm:inline"> new images</span>
        </span>
      )
  }
}
