import { fetcher } from '../../../helpers'
import { OMSResponseInterface } from '../../../interfaces'

interface OrdersExportDownloadSuccessResponse extends OMSResponseInterface {
  data: {
    expiryDateTime: string
    url: string
  }
}

export function requestOrdersExportDownload(fileId: string, options?: RequestInit | undefined) {
  return fetcher<OrdersExportDownloadSuccessResponse>(
    `${process.env.REACT_APP_PRODIGI_OMS}/orders/exports/${fileId}`,
    options
  )
}
