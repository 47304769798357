import { GROUPED_COUNTRIES } from '../constants'
import { formatCountry } from '../../../helpers'
import { PricingInformationCategoryInterface } from '../interfaces'
import { DictionaryInterface, V3CountryInterface } from '../../../interfaces'
import { GroupedDropdownGroupInterface, GroupedDropdownOptionInterface } from '../components'

export function createCountryDownloadOptions({
  categories,
  category,
  countries,
  regionData,
  subcategory
}: {
  categories: Record<string, PricingInformationCategoryInterface>
  category: string
  countries: V3CountryInterface[]
  regionData: DictionaryInterface<string>
  subcategory: string
}) {
  const categoryCountryCodes = categories[category]?.subCategories[subcategory]?.countries || []
  const sortedCountries = sortAndFormatCountries(categoryCountryCodes, countries)

  const categoryRegionCodes = categories[category]?.subCategories[subcategory]?.regions || []
  const sortedRegions = sortAndFormatRegions(categoryRegionCodes, regionData)

  const mappedPopularCountries: GroupedDropdownOptionInterface[] = GROUPED_COUNTRIES.map((country) => {
    return {
      value: country.code,
      name: country.name
    }
  })
  const popularCountries = mappedPopularCountries.filter(
    (popular) =>
      sortedCountries.filter((country) => country.name === popular.name && country.value === popular.value).length > 0
  )

  const groups: GroupedDropdownGroupInterface[] = [
    {
      options: popularCountries,
      label: 'Popular'
    },
    { options: sortedRegions, label: 'Regions' },
    { options: sortedCountries, label: 'Countries' }
  ]

  return groups
}

function sortAndFormatRegions(
  regionsCodes: string[],
  regionData: DictionaryInterface<string>
): GroupedDropdownOptionInterface[] {
  if (regionsCodes.length === 0) {
    return []
  }

  const regions = Object.entries(regionData).reduce(
    (regionsAcc: GroupedDropdownOptionInterface[], [regionCode, regionName]) => {
      if (regionsCodes.includes(regionCode)) {
        regionsAcc.push({ value: regionCode, name: regionName })
      }

      return regionsAcc
    },
    []
  )

  regions.sort((a, b) => a.name.localeCompare(b.name))

  return regions
}

function sortAndFormatCountries(
  countryCodes: string[],
  countries: V3CountryInterface[]
): GroupedDropdownOptionInterface[] {
  const mappedCountries = countries.reduce(
    (countriesAcc: GroupedDropdownOptionInterface[], country: V3CountryInterface) => {
      if (countryCodes.includes(country.isoCode)) {
        const formattedName = formatCountry(country.name)
        countriesAcc.push({ value: country.isoCode, name: formattedName })
      }

      return countriesAcc
    },
    []
  )

  mappedCountries.sort((a, b) => a.name.localeCompare(b.name))

  return mappedCountries
}
