// @flow
import { connect } from 'react-redux'
import { updateOrderItemQuantity } from '../../../../actions/csvUpload'
import CsvItemProductController from './CsvItemProductController'
import type {
  AppState,
  RsaaStatus,
  FormattedOrderItem,
  CsvItemProductDetails,
  DispatchFunc
} from '../../../../types'
import { getCsvOrderQuoteRsaaStatusByOrderId } from '../../../../selectors/quotes'
import type { ComponentType } from 'react'

type DispatchProps = {|
  updateOrderItemQuantity: (quantity: number) => void
|}

type OwnProps = {|
  orderId: string,
  item: FormattedOrderItem,
  canBeEdited: boolean,
  productDetails: ?CsvItemProductDetails,
  openProductSearchModal: (orderItemId: string) => void
|}

type StateProps = {|
  quoteRsaaStatus: RsaaStatus
|}

function mapStateToProps (state: AppState, ownProps: OwnProps): StateProps {
  return {
    quoteRsaaStatus: getCsvOrderQuoteRsaaStatusByOrderId(state, ownProps.orderId)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc, ownProps: OwnProps): DispatchProps {
  return {
    updateOrderItemQuantity: (quantity: number) =>
      dispatch(updateOrderItemQuantity(quantity, ownProps.orderId, ownProps.item.id))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CsvItemProductController): ComponentType<OwnProps>)
