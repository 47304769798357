import { motion } from 'framer-motion'

import Button from '../../Button'
import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import { CATALOGUE_PRODUCTS } from '../../../constants'

export function ProductTypesList({
  category,
  onSelect
}: {
  category: string
  onSelect: (productType: string | null) => void
}) {
  const productTypes = CATALOGUE_PRODUCTS[category]?.productTypes

  if (!productTypes) {
    return (
      <div className="mt-12 flex w-full justify-center">
        <NotFound
          body={
            <p className="break-words text-center">
              <>
                We could not find products for this category. Please try again later and{' '}
                <SupportLink>contact us</SupportLink> if this issue persists.
              </>
            </p>
          }
        >
          <Button className="mx-auto mt-8" onClick={() => onSelect(null)}>
            Back to categories
          </Button>
        </NotFound>
      </div>
    )
  }

  return (
    <ul className="grid list-none grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
      {Object.entries(productTypes).map(([productType, details]) => (
        <motion.li key={productType} whileHover="hover">
          <button className="flex w-full cursor-pointer flex-col gap-4" onClick={() => onSelect(productType)}>
            <div className="aspect-square w-full overflow-hidden rounded border border-gray-200">
              <motion.img
                alt={productType}
                className="w-full object-contain object-center"
                src={details?.image}
                variants={{
                  hover: {
                    scale: 1.05
                  }
                }}
              />
            </div>

            <span className="w-full text-center text-black">{productType}</span>
          </button>
        </motion.li>
      ))}
    </ul>
  )
}
