// @flow

class Timer {
  _name: string
  _category: string
  _startTimestamp: number

  constructor (name: string) {
    this._name = name
    this._category = 'Misc'
    this._startTimestamp = 0
  }

  addCategory (category: string) {
    this._category = category
  }

  start () {
    this._startTimestamp = new Date().getTime()
  }

  complete () {
    if (this._startTimestamp === 0) {
      console.warn('Cannot complete timer before calling start()')
      return
    }

    const stopTimestamp = new Date().getTime()
    const duration = stopTimestamp - this._startTimestamp

    this._startTimestamp = 0

    if (process.env.NODE_ENV === 'development') {
      console.log(`### Completed timer ${this._name} - ${duration}ms`)
    }
  }
}

export default Timer
