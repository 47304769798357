// @flow
import type { Action } from '../types'

export function getStatusCodeFromAction (action: Action): ?number {
  if (action.payload && action.payload.statusCode) {
    return action.payload.statusCode
  } else if (action.payload && action.payload.status) {
    return action.payload.status
  } else if (action.statusCode) {
    return action.statusCode
  }
}
