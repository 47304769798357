import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function ResetPassword() {
  const history = useHistory()

  useEffect(() => {
    history.replace('/password-change' + window.location.search)
  }, [history])

  return null
}
