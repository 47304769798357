import { UploadFromFiles } from '.'
import { APP_ENVIRONMENTS } from '../../../constants'
import { ImageLibraryImageType } from '../../../hooks'
import { UploadingImageInterface } from '../interfaces'
import { UploadOptionValueType, UploadFromURL, UploadFromDropbox } from '../../ImageLibrary'

interface UploadChoiceFullPageDisplayPropsInterface {
  type: ImageLibraryImageType
  uploadChoice: UploadOptionValueType
  onUploadStart: () => void
  onUploadComplete: () => void
  onSetUploadingFiles: (files: React.SetStateAction<Record<string, UploadingImageInterface>>) => void
}

export function UploadChoiceFullPageDisplay({
  type,
  uploadChoice,
  onUploadStart,
  onUploadComplete,
  onSetUploadingFiles
}: UploadChoiceFullPageDisplayPropsInterface) {
  switch (uploadChoice) {
    case 'file':
      return (
        <UploadFromFiles
          isSaveToLibraryChecked={true}
          type={type}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
          onSetUploadingFiles={onSetUploadingFiles}
        />
      )
    case 'url':
      return (
        <UploadFromURL
          isSaveToLibraryChecked={true}
          type={type}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
        />
      )
    case 'dropbox':
      return (
        <UploadFromDropbox
          isSaveToLibraryChecked={true}
          type={type}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
        />
      )
    default:
      const unhandledUploadChoice: never = uploadChoice
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled upload choice ${unhandledUploadChoice} found. Add a new case to fix this.`)
      }
      return null
  }
}
