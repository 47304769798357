// @flow
import { connect } from 'react-redux'
import type { AppState, DispatchFunc } from '../../../../types'
import CsvMultiselectFiltersController from './CsvMultiselectFiltersController'
import { getIsMultiselectModeOn } from '../../../../selectors/csvUpload'
import { ignoreAllOrders, selectAllOrders, toggleMultiselectMode } from '../../../../actions/csvUpload'
import type { ComponentType } from 'react'

type DispatchProps = {|
  ignoreAllOrders: () => void,
  selectAllOrders: () => void,
  toggleMultiselectMode: () => void
|}

type StateProps = {|
  isMultiselectModeOn: boolean
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    isMultiselectModeOn: getIsMultiselectModeOn(state)
  }
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    ignoreAllOrders: () => {
      window.analytics.track('Click ignore all orders', {label: 'csv uploader'})
      dispatch(ignoreAllOrders())
    },
    selectAllOrders: () => {
      window.analytics.track('Click select all orders', {label: 'csv uploader'})
      dispatch(selectAllOrders())
    },
    toggleMultiselectMode: () => {
      window.analytics.track('Click toggle multiselect mode', {label: 'csv uploader'})
      dispatch(toggleMultiselectMode())
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CsvMultiselectFiltersController): ComponentType<{||}>)
