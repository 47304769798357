import useSWR from 'swr'

import { fetcher } from '../helpers'
import { OrderActionsDataSuccessInterface, FetchErrorInterface, OMSErrorResponseInterface } from '../interfaces'

export function useOrderActions(orderId: string) {
  const {
    data: orderActions,
    mutate: mutateOrderActions,
    error: orderActionsFetchError
  } = useSWR<OrderActionsDataSuccessInterface, FetchErrorInterface<OMSErrorResponseInterface>>(
    `${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/actions`,
    fetcher
  )

  return {
    orderActions,
    orderActionsFetchError,
    mutateOrderActions
  }
}
