import { getAnonymousId } from '../helpers/getAnonymousId'
import { FEATURE_NAMES } from './feature-names'

export function getSplitIOConfig(): SplitIO.IBrowserSettings {
  return {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_IO_API_KEY || '',
      key: getAnonymousId(),
      trafficType: 'anonymous'
    },
    debug: false,
    sync: { splitFilters: [{ type: 'byName', values: Object.values(FEATURE_NAMES) }] }
  }
}
