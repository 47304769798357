// @flow

import type { Element } from 'react'
import type { Facet, Event } from '../../../../types'

import React from 'react'
import Button from '../../../../components/Button'
import attributeLabelFormat from '../../../../helpers/attributeLabelFormat'
import styles from './FacetNavigation.module.css'

type FacetButtonProps = {|
  facet: Facet,
  onClickFacet: (facet: string) => void
|}

export default function FacetButton ({ facet, onClickFacet }: FacetButtonProps): null | Element<"div"> {
  const noOfSelectedFacets = facet.values.filter(f => f.selected).length

  function onClickFacetCallback (e: Event) {
    onClickFacet(e.currentTarget.value)
  }

  if (facet.values.length <= 1) {
    return null
  }

  return (
    <div key={facet.facet} className={styles.buttonOuterDiv}>
      {noOfSelectedFacets > 0
        ? (
          <Button size="small" value={facet.facet} onClick={onClickFacetCallback}>
            {`${attributeLabelFormat(facet.facet)} (${noOfSelectedFacets})`}
          </Button>
        )
        : (
          <Button size="small" value={facet.facet} onClick={onClickFacetCallback}>
            {attributeLabelFormat(facet.facet)}
          </Button>
        )}
    </div>
  )
}
