// @flow
export const SQUARESPACE_IGNORED_HEADERS = ['Shipping']

export const SQUARESPACE_DESCRIPTIONS = ['Lineitem variant', 'Lineitem name']

export const SQUARESPACE_CSV_HEADERS = [
  'Order ID',
  'Email',
  'Financial Status',
  'Paid at',
  'Fulfillment Status',
  'Fulfilled at',
  'Currency',
  'Subtotal',
  'Shipping',
  'Taxes',
  'Amount Refunded',
  'Total',
  'Discount Code',
  'Discount Amount',
  'Shipping Method',
  'Created at',
  'Lineitem quantity',
  'Lineitem name',
  'Lineitem price',
  'Lineitem sku',
  'Lineitem variant',
  'Lineitem requires shipping',
  'Lineitem taxable',
  'Lineitem fulfillment status',
  'Billing Name',
  'Billing Address1',
  'Billing Address2',
  'Billing City',
  'Billing Zip',
  'Billing Province',
  'Billing Country',
  'Billing Phone',
  'Shipping Name',
  'Shipping Address1',
  'Shipping Address2',
  'Shipping City',
  'Shipping Zip',
  'Shipping Province',
  'Shipping Country',
  'Shipping Phone',
  'Cancelled at',
  'Private Notes',
  'Channel Type',
  'Channel Name',
  'Channel Order Number',
  'Payment Method',
  'Payment Reference'
]
