// @flow
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { convertOrderIdToV4, convertV4OrderIdToV3OrderId } from '../../helpers/orderId'
import type { V4OrderCreationResponse, Thunk, DispatchFunc } from '../../types'

export const GET_ORDER_DETAILS_V4 = 'GET_ORDER_DETAILS_V4'
export const GET_ORDER_DETAILS_V4_SUCCESS = 'GET_ORDER_DETAILS_V4_SUCCESS'
export const GET_ORDER_DETAILS_V4_ERROR = 'GET_ORDER_DETAILS_V4_ERROR'

export function getOrderDetailsV4 (orderId: string): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const v4OrderId = convertOrderIdToV4(orderId)

    dispatch({
      [RSAA]: {
        method: 'GET',
        endpoint: buildEndpoint(v4OrderId),
        types: [
          {
            type: GET_ORDER_DETAILS_V4,
            meta: {
              enhancements: [ LOG_STATUS ],
              orderId
            }
          },
          {
            type: GET_ORDER_DETAILS_V4_SUCCESS,
            payload: async (action, state, rawResponse) => {
              const orderDetailsResponse: V4OrderCreationResponse = await rawResponse.json()
              return {
                ...orderDetailsResponse,
                order: {
                  ...orderDetailsResponse.order,
                  id: convertV4OrderIdToV3OrderId(orderDetailsResponse.order.id)
                }
              }
            },
            meta: {
              orderId
            }
          },
          {
            type: GET_ORDER_DETAILS_V4_ERROR,
            meta: {
              orderId
            }
          }
        ]
      }
    })
  }
}

function buildEndpoint (orderId: string): string {
  return selectProdigiApiEndpoint() + '/v4.0/Orders/' + orderId
}
