// @flow
import React from 'react'
import styles from './Inputs.module.css'
import cx from 'classnames'

type Props = {|
  className?: string,
  style?: any,
  uppercase?: boolean,
  autoFocus?: boolean,
  autoComplete?: string,
  name?: string,
  placeholder?: string,
  type?: string,
  autoCapitalize?: string,
  autoCorrect?: string,
  value?: string,
  required?: boolean,
  disabled?: boolean,
  id?: string,
  spellCheck?: boolean,
  tabIndex?: string,
  minLength?: string,
  maxLength?: string,
  defaultValue?: string,
  onBlur?: any,
  onFocus?: (any) => void,
  onChange: (any) => void,
  inputref?: React$ElementRef<Class<any>>,
  dataTest?: string
|}

type State = {|
  id: string
|}

function getId({ name, placeholder, type, ...rest }: Props): string {
  return `${(name || placeholder || 'no_name').toLowerCase().replace(/\s+/g, '_')}_${type || 'text'}_field`
}

class TextField extends React.Component<Props, State> {
  state: State = { id: getId(this.props) }

  render(): React$Node {
    const { style, className, uppercase, value, dataTest, ...otherProps } = this.props

    return (
      <input
        ref={this.props.inputref}
        data-test={dataTest ?? 'text-field-input'}
        id={this.state.id}
        spellCheck={false}
        type="text"
        className={cx(styles.primary, className, { [styles.upper]: uppercase })}
        style={style}
        value={value ?? ''}
        {...otherProps}
      />
    )
  }
}
export default TextField
