// @flow
import { compose } from 'redux'
import WithRsaaProps from './WithRsaaProps'
import WithRsaaNamePrefix from './WithRsaaNamePrefix'
import WithResetRsaaStatus from './WithResetRsaaStatus'
import type { ComponentType } from 'react'

type HocWithRsaa = (WrappedComp: ComponentType<*>) => ComponentType<*>

export default function WithRsaa (rsaaActionId: string, propNamePrefix?: string, reset?: boolean = true): HocWithRsaa {
  const wrappers = [
    reset ? WithResetRsaaStatus(rsaaActionId) : null,
    WithRsaaProps(rsaaActionId),
    propNamePrefix ? WithRsaaNamePrefix(propNamePrefix) : null
  ]

  return compose(...wrappers.filter(Boolean))
}
