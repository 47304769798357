// @flow
import React, { Component } from 'react'
import Radium from 'radium'
import SelectField from '../../../../components/inputs/SelectField'
import productAttributeFormat from '../../../../helpers/productAttributeFormat'
import { getAttrNameForCategory } from '../../../../helpers/getAttrNameForCategory'
import ColourSwatch from '../ColourSwatch'
import styles from './AttributeSelector.module.css'
import { reorderAttributes } from '../../../../helpers/reorderAttributes'
import type { Dictionary } from '../../../../types'

type Props = {|
  availableAttributes: Dictionary<string[]>,
  selectedAttributes: Dictionary<string>,
  attributeChanged: (name: string, e: any) => void,
  category: string,
  disabled: boolean
|}

class AttributeSelector extends Component<Props> {
  handleAttributeChange(name: string, e: any) {
    this.props.attributeChanged(name, e.target.value.replace(/\s/g, '_'))
  }

  sortAttributes = (attr: string[], key: string): string[] => reorderAttributes(key, attr, this.props.category)

  renderAttributeValue = (key) => {
    const { category, availableAttributes, selectedAttributes, attributeChanged } = this.props

    if (availableAttributes[key].length === 1) {
      return productAttributeFormat(availableAttributes[key][0])
    }

    let swatch
    if (key === 'frameColour') {
      swatch = (
        <ColourSwatch
          frame={availableAttributes.frame}
          selectedItemCategory={category}
          colour={productAttributeFormat(selectedAttributes[key])}
          className={styles.swatch}
        />
      )
    } else if (key === 'mountColour') {
      swatch = (
        <ColourSwatch
          frame={availableAttributes.mountColour}
          selectedItemCategory={category}
          colour={productAttributeFormat(selectedAttributes[key])}
          className={styles.swatch}
        />
      )
    }

    return (
      <React.Fragment>
        <SelectField
          disabled={this.props.disabled}
          value={selectedAttributes[key]}
          event={(name: string, e: any) => attributeChanged(name, e)}
          name={key}
          eventNeedsTwoArgs
        >
          {this.sortAttributes(availableAttributes[key], key).map((value, j) => (
            <option value={value} key={j}>
              {productAttributeFormat(value)}
            </option>
          ))}
        </SelectField>
        {swatch}
      </React.Fragment>
    )
  }

  render(): React$Node {
    const { category, availableAttributes } = this.props
    const availableAttributeNames = Object.keys(availableAttributes)

    const sortedKeys = availableAttributeNames
      .filter((a) => a !== 'channels')
      .sort((x, y) => {
        return availableAttributes[x].length - availableAttributes[y].length
      })

    return (
      <>
        {sortedKeys.map((key) => (
          <tr className={styles.row} key={key}>
            <td className={styles.label}>{getAttrNameForCategory(key, category)}:</td>
            <td className={styles.value}>{this.renderAttributeValue(key)}</td>
          </tr>
        ))}
      </>
    )
  }
}

export default (Radium(AttributeSelector): any)
