import { motion } from 'framer-motion'
import LoadingSpinner from '../../LoadingSpinner'

export function PaymentAuthProcessing({ message }: { message?: string }) {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="flex h-full w-full flex-col items-center justify-center space-y-6 text-gray-700"
      initial={{ opacity: 0 }}
    >
      <LoadingSpinner className="h-10 w-10" />
      <p>{message || `Processing payment`}</p>
    </motion.div>
  )
}
