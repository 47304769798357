import useSWR from 'swr'

import { azureSearchFetcher, removeDuplicates } from '../../../helpers'
import { selectAzureSearchSettings } from '../../../selectors/appSettings'
import { AzureSearchResultInterface, DictionaryInterface, FetchErrorInterface } from '../../../interfaces'
import { generateMultiSkuProductSearchQuery } from '../../../helpers/generateMultiSkuProductSearchQuery.helper'

export interface ProductSlaInterface {
  slaInHours: number
}

interface ProductSLAAzureSearchInterface extends AzureSearchResultInterface {
  sla?: number
}

type SearchSuccessType = { value: ProductSLAAzureSearchInterface[] }
// TODO: Strong type after asking BE team what the error response might look like
type SearchErrorType = unknown

interface useProductSearchInteface {
  countryCode: string
  productSkus: string[]
}

export function useProductSlas({ countryCode, productSkus }: useProductSearchInteface) {
  const uniqueSkus = removeDuplicates(productSkus)
  const query = generateMultiSkuProductSearchQuery(uniqueSkus)
  const productSearchUrl = getProductSearchUrl(countryCode, query)

  const { data, error } = useSWR<SearchSuccessType, FetchErrorInterface<SearchErrorType>>(
    productSearchUrl,
    azureSearchFetcher
  )

  const mappedResults = data && filterAndMapResults({ searchResult: data.value, skus: uniqueSkus })

  return {
    productsSlas: mappedResults,
    isLoading: Boolean(!data && !error),
    error
  }
}

const PRODUCT_SLA_SEARCH_PROPERTIES = ['sku', 'sla']

function getProductSearchUrl(countryCode: string, query: string) {
  const azureSearchEndpoint = selectAzureSearchSettings().endpoint

  const endpoint =
    `${azureSearchEndpoint}&search=${query}` +
    `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}') &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}` +
    `&$select=${PRODUCT_SLA_SEARCH_PROPERTIES.join()}`

  return endpoint
}

function filterAndMapResults({
  searchResult,
  skus
}: {
  searchResult: ProductSLAAzureSearchInterface[]
  skus: string[]
}) {
  const mappedResults: DictionaryInterface<ProductSlaInterface | undefined> = {}
  const skuList = skus.map((sku) => sku.toLowerCase())

  const filteredList = searchResult.filter((result) => skuList.indexOf(result.sku.toLowerCase()) > -1)

  filteredList.forEach((result) => {
    if (!result.sla) {
      return
    }

    let slaToUse = result.sla
    const percentageToIncrease = 20
    slaToUse = slaToUse + (percentageToIncrease / 100) * slaToUse

    mappedResults[result.sku.toUpperCase()] = { slaInHours: slaToUse }
  })

  return mappedResults
}
