import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { OrdersEmptyState } from '.'
import { useUser } from '../../../hooks'
import { ROUTE_PATHS } from '../../../constants'

export function NoOrders() {
  const { user } = useUser()

  const userHasToAddBillingDetails = !user?.billing.hasPaymentSetup && user?.billing.invoiceFrequency === 'PerOrder'

  return (
    <OrdersEmptyState title="No orders yet!">
      <p className="mt-8">All direct orders and orders received from other sales channels will appear here.</p>
      <p className="mt-8">Order our specially curated sample pack, and get 50% off your first sample order.</p>
      <motion.div
        animate={{ y: 0, opacity: 1 }}
        initial={{ y: -10, opacity: 0 }}
        className="mt-12"
        transition={{ delay: 0.3 }}
      >
        <Link className="btn btn-primary mx-auto max-w-sm" to={ROUTE_PATHS.CREATE_ORDER}>
          Place your first order
        </Link>
      </motion.div>
      {userHasToAddBillingDetails && (
        <p className="mt-12">
          Be sure to set up your{' '}
          <Link className="text-purple-600" to={ROUTE_PATHS.SETTINGS.BILLING}>
            payment details
          </Link>
        </p>
      )}
    </OrdersEmptyState>
  )
}
