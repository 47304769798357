import { DictionaryInterface } from '../../../interfaces'
import { ProductSlaInterface } from '../hooks'

export function findLongestSLA({ slaList }: { slaList: DictionaryInterface<ProductSlaInterface | undefined> }) {
  let longestSla = 0

  Object.values(slaList).forEach((skuData) => {
    if (!skuData) {
      return
    }

    if (skuData.slaInHours > longestSla) {
      longestSla = skuData.slaInHours
    }
  })

  return longestSla
}
