// @flow

export const V4_API_ACTION_OUTCOME = {
  CREATED: 'Created',
  CREATED_WITH_ISSUES: 'CreatedWithIssues',
  ON_HOLD: 'OnHold',
  VALIDATION_FAILED: 'ValidationFailed',
  OK: 'Ok',
  ACTION_NOT_AVAILABLE: 'ActionNotAvailable',
  ALREADY_EXISTS: 'AlreadyExists',
  CANCELLED: 'Cancelled',
  FAILED_TO_CANCEL: 'FailedToCancel',
  UPDATED: 'Updated',
  PARTIALLY_UPDATED: 'PartiallyUpdated',
  FAILED_TO_UPDATE: 'FailedToUpdate',
  NOT_AVAILABLE: 'NotAvailable',
  PAYMENT_FAILED: 'PaymentFailed'
}
