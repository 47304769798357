import { useEffect } from 'react'

import { FetchErrorInterface } from '../../../interfaces'
import { ImageLibraryImageInterface, useImageLibraryThumbnailGeneration } from '../../../hooks'

interface ThumbnailPollingPropsInterface {
  imageLibraryId: string
  onComplete: ({
    imageLibraryImage,
    imageLibraryImageFetchError
  }: {
    imageLibraryImage?: ImageLibraryImageInterface
    imageLibraryImageFetchError?: FetchErrorInterface<{ detail?: string }>
  }) => void
}

export function ThumbnailPolling({ imageLibraryId, onComplete }: ThumbnailPollingPropsInterface) {
  const { imageLibraryImage, imageLibraryImageFetchError } = useImageLibraryThumbnailGeneration(imageLibraryId, {
    config: { refreshInterval: 5000, revalidateOnMount: false }
  })

  useEffect(() => {
    if (imageLibraryImage && imageLibraryImage.upload_status !== 'pending') {
      onComplete({ imageLibraryImage, imageLibraryImageFetchError })
    }
    if (imageLibraryImageFetchError) {
      onComplete({ imageLibraryImage, imageLibraryImageFetchError })
    }
  }, [imageLibraryImage, imageLibraryImageFetchError, onComplete])

  return null
}
