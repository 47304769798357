// @flow
import React from 'react'

import styles from './sharedStyles.module.css'
import InputUnderlined from '../../../components/InputUnderlined'

type Props = {|
  autoFocus?: boolean,
  brandColour: string,
  isHexValid: boolean,
  brandColourChangeHandler: (event: SyntheticInputEvent<HTMLInputElement>) => void
|}

class BrandColour extends React.Component<Props> {
  render (): React$Node {
    const {
      autoFocus = false,
      brandColour,
      brandColourChangeHandler,
      isHexValid
    } = this.props

    return (
      <div className={styles.stepBrandColour}>
        <div className={styles.brandColourInput}>
          <InputUnderlined
            autoFocus={autoFocus}
            inputLabel="HEX VALUE"
            error={((brandColour) && (brandColour.length % 3 === 0) && (!isHexValid)) ? 'Not a valid hex value' : ''}
            inputPrefix="#"
            minLength="3"
            maxLength="6"
            placeholder="Hex value"
            onChange={brandColourChangeHandler}
            value={brandColour} />
        </div>
        <div
          className={styles.brandColourBlock}
          style={{backgroundColor: isHexValid ? `#${brandColour}` : 'transparent'}}>
        </div>
      </div>
    )
  }
}

export default BrandColour
