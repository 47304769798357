import { ApiPanel, WebhooksPanel } from './components'
import { SettingsHeader } from '../SettingsHeader.component'
import { SettingsContainer } from '../SettingsContainer.component'

export function SettingsApi() {
  return (
    <SettingsContainer>
      <SettingsHeader />
      <ApiPanel className="border-x border-t" />
      <WebhooksPanel className="border" />
    </SettingsContainer>
  )
}
