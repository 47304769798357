import clsx from 'clsx'
import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  useReactTable
} from '@tanstack/react-table'
import { useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { FunnelIcon } from '@heroicons/react/20/solid'

import {
  buildTableRowContentsAsString,
  buildVariantTableColumns,
  buildVariantTableColumnVisibility,
  orderVariantTableColumns
} from '../helpers'
import Button from '../../Button'
import { InlineMenuOptionInterface } from '../../InlineMenu'
import { VariantsPagination } from './VariantsPagination.component'
import { VariantsColumnFilter } from './VariantsColumnFilter.component'
import { VariantsGlobalSearchInput } from './VariantsGlobalSearchInput.component'
import { VariantsNotFoundWithAppliedFilters } from './VariantsNotFoundWithAppliedFilters.component'
import { ColumnFilterType, ProductCatalogueProductInterface, VariantRowType } from '../../../hooks'

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterType?: ColumnFilterType
    options?: string[] | InlineMenuOptionInterface[]
  }
}

export const VARIANTS_QUERY_PARAM_PREFIX = '-pv-'

export const VARIANTS_QUERY_PARAMS = {
  GLOBAL_SEARCH: 'variantsSearch'
}

export const VARIANTS_TABLE_MIN_PAGE_SIZE = 25

const globalFilterFn: FilterFn<VariantRowType> = (row, _, filterValue: string) => {
  const filterSplitIntoWords = filterValue.trim().toLowerCase().split(' ')

  const rowAsString = buildTableRowContentsAsString(row)

  const matchesAllWords = filterSplitIntoWords.every((word) => {
    return rowAsString.includes(word)
  })
  return matchesAllWords
}

export function Variants({ productData }: { productData: ProductCatalogueProductInterface }) {
  const variants = productData.variants
  const { search } = useLocation()

  const data = useMemo(() => variants.rows, [variants.rows])
  const columns = useMemo(
    () => buildVariantTableColumns(variants.columns, productData.name),
    [productData.name, variants.columns]
  )
  const columnOrder = useMemo(() => orderVariantTableColumns(productData.parentCategory), [productData.parentCategory])
  const columnVisibility = useMemo(
    () => buildVariantTableColumnVisibility(productData.rotatable, productData.parentCategory),
    [productData.parentCategory, productData.rotatable]
  )
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [globalSearchValue, setGlobalSearchValue] = useState('')
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    globalFilterFn: globalFilterFn,
    initialState: {
      columnOrder,
      columnVisibility,
      pagination: {
        pageSize: VARIANTS_TABLE_MIN_PAGE_SIZE
      }
    },
    state: {
      columnFilters,
      globalFilter: globalSearchValue
    },
    onGlobalFilterChange: setGlobalSearchValue,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  useEffect(() => {
    const newColumnFilters: ColumnFiltersState = []
    searchParams.forEach((value, key) => {
      if (key.startsWith(VARIANTS_QUERY_PARAM_PREFIX)) {
        const columnId = key.replace(VARIANTS_QUERY_PARAM_PREFIX, '')
        newColumnFilters.push({ id: columnId, value })
      }
    })

    const newGlobalSearchValue = searchParams.get(VARIANTS_QUERY_PARAMS.GLOBAL_SEARCH) ?? ''
    setGlobalSearchValue(newGlobalSearchValue)
    setColumnFilters(newColumnFilters)
  }, [searchParams])

  if (data.length === 0) {
    return null
  }

  const visibleRowsLength = table.getRowModel().rows.length
  const tableDataRequiresPagination = data.length > VARIANTS_TABLE_MIN_PAGE_SIZE

  return (
    <div id="variants" className="mb-16 mt-20 w-full max-w-full scroll-mt-[65px] xl:inline-block">
      {data.length > 1 && (
        <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
          <VariantsGlobalSearchInput
            tableStateGlobalSearchValue={table.getState().globalFilter ?? ''}
            productPluralName={productData.pluralName}
          />
          <div className="text-gray-700 md:mr-4">
            {columnFilters.length > 0 || table.getState().globalFilter ? (
              <span className="flex items-center gap-2">
                <FunnelIcon className="h-6 w-6 fill-gray-600" />
                <span>
                  <span className="hidden sm:inline">Displaying </span>
                  <strong className="text-black">{table.getRowCount()}</strong>
                  {' of '}
                  <strong className="text-black">{data.length}</strong>
                </span>
              </span>
            ) : (
              <span>
                <strong className="text-black">{data.length}</strong> variants{' '}
                <span className="hidden sm:inline">available</span>
              </span>
            )}
          </div>
        </div>
      )}

      <div
        className={clsx(
          'relative overflow-auto border-x border-y bg-white',
          tableDataRequiresPagination ? 'h-[600px]' : 'max-h-[600px]'
        )}
      >
        <table className="w-full border-separate bg-white">
          <thead className="sticky top-0 z-20 bg-white font-medium">
            <tr className="">
              {table.getFlatHeaders().map((header, index) => {
                const isFirstColumn = index === 0
                const isLastColumn = index + 1 === table.getFlatHeaders().length

                return (
                  <th
                    className={clsx(
                      'whitespace-nowrap border-b bg-white p-4 text-left',
                      isFirstColumn && 'z-10 bg-opacity-90 md:sticky md:left-0',
                      isLastColumn && 'sticky right-0 z-10 bg-opacity-90'
                    )}
                    style={{ backdropFilter: isFirstColumn || isLastColumn ? 'blur(4px)' : undefined }}
                    key={header.id}
                  >
                    <div className="flex w-full items-center gap-1">
                      {header.column.getCanFilter() ? (
                        <div>
                          <VariantsColumnFilter column={header.column} />
                        </div>
                      ) : null}
                      {header.column.getCanSort() ? (
                        <Button size="sm" variant="none" onClick={header.column.getToggleSortingHandler()}>
                          <span className="font-semibold">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>{' '}
                          {header.column.getIsSorted() === 'asc' && <ChevronUpIcon className="h-5 w-6" />}
                          {header.column.getIsSorted() === 'desc' && <ChevronDownIcon className="h-5 w-6" />}
                          {header.column.getIsSorted() === false && <ChevronUpDownIcon className="h-6 w-6" />}
                        </Button>
                      ) : (
                        <span className="font-semibold">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </span>
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => {
                  const isFirstColumn = index === 0
                  const isLastColumn = index + 1 === row.getVisibleCells().length

                  return (
                    <td
                      className={clsx(
                        'whitespace-nowrap border-b bg-white p-4 align-middle',
                        isFirstColumn && 'z-10 bg-opacity-90 font-medium md:sticky md:left-0',
                        isLastColumn && 'sticky right-0 z-10 bg-opacity-90'
                      )}
                      style={{ backdropFilter: isFirstColumn || isLastColumn ? 'blur(4px)' : undefined }}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>

        {visibleRowsLength === 0 && (
          <div className="sticky left-0 w-full">
            <VariantsNotFoundWithAppliedFilters />
          </div>
        )}
      </div>

      {tableDataRequiresPagination && visibleRowsLength > 0 && (
        <div className="mt-4 w-full">
          <VariantsPagination table={table} />
        </div>
      )}

      {/* <p className="mt-4 text-sm">* Additional costs may apply</p> */}
    </div>
  )
}
