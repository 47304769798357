// @flow
import { RSAA } from 'redux-api-middleware'
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../../types'
import { selectAzureSearchSettings } from '../../selectors/appSettings'
import { NO_SELECTION, ALL } from '../../data/catalogue'
import { searchProducts } from './searchProducts'

export const FETCH_FACETS = 'FETCH_FACETS'
export const RECEIVE_FACETS = 'RECEIVE_FACETS'
export const FAILED_FACETS = 'FAILED_FACETS'

export function getFacets(): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const azureSearchSettings = selectAzureSearchSettings()

    await dispatch({
      [RSAA]: {
        endpoint: buildUrl(getState, azureSearchSettings.endpoint),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': azureSearchSettings.key
        },
        types: [FETCH_FACETS, RECEIVE_FACETS, FAILED_FACETS]
      }
    })

    const query = getState().catalogue.searchQuery
    await dispatch(searchProducts(query))
  }
}

function buildUrl(getState: GetStateFunc, azureSearchEndpoint: string): string {
  const productType = getState().catalogue.selectedProductType
  const countryCode = getState().manualOrderForm.deliveryCountry
  const category = getState().catalogue.selectedItemCategory

  let productTypeQuery = ''
  let categoryQuery = ''

  if (category !== NO_SELECTION && category !== ALL) {
    categoryQuery = `and category eq '${encodeURIComponent(category)}'`
  }

  if (productType !== ALL) {
    productTypeQuery = `and productType eq '${encodeURIComponent(productType)}'`
  }

  return (
    `${azureSearchEndpoint}&search=*&$filter=destinationCountries/any(c: c eq '${countryCode}') ` +
    `${categoryQuery} ${productTypeQuery}` +
    `&$top=0&facet=glaze,count:100&facet=frame,count:100&facet=finish,count:100&facet=style,count:100` +
    `&facet=category&facet=edge&facet=brand&facet=mount&facet=mountColour&facet=paperType,count:100&facet=size,count:100&facet=gender&facet=frameColour,count:100` +
    `&facet=maxProductDimensionsMm,values:300|500|700|1000|1500&facet=productAspectRatio,values:95|105`
  )
}
