import { useEffect } from 'react'

import Dashboard from '../../components/Dashboard'
import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from 'src/v2/constants'

export default function DashboardPage() {
  useEffect(() => {
    document.title = 'Dashboard | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.DASHBOARD)
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <Dashboard />
    </main>
  )
}
