// @flow
import type { ToggleIsUsSalesTaxCollectedForAllOrders } from '../types'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'

import { getIsUsSalesTaxCollectedForAllUsOrders } from '../../selectors/csvUpload/csvUpload'

export const TOGGLE_IS_US_SALES_TAX_COLLECTED_FOR_ALL_ORDERS = 'TOGGLE_IS_US_SALES_TAX_COLLECTED_FOR_ALL_ORDERS'

export function toggleIsUsSalesTaxCollectedForAllOrders (): Thunk<ToggleIsUsSalesTaxCollectedForAllOrders> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const isUsSalesTaxCollectedForAllUsOrders = getIsUsSalesTaxCollectedForAllUsOrders(getState())

    return dispatch({
      type: TOGGLE_IS_US_SALES_TAX_COLLECTED_FOR_ALL_ORDERS,
      usSalesTaxToggleState: !isUsSalesTaxCollectedForAllUsOrders
    })
  }
}
