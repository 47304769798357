// @flow
import type {
  ThunkAsync,
  Action,
  ChangeSelectedAttributeData,
  CatalogueItem,
  MultiAssetBasketItem,
  DispatchFunc,
  GetStateFunc
} from '../../types'
import type {
  SelectMobileFacetScreen,
  ResetAttributes,
  ChangeQuantity,
  SetCurrentPreviewItem,
  ChangeMerchantOrderId,
  SelectAttribute,
  ChangeDeliveryCountry
} from '../types'
import { selectPwintyApiSettings } from '../../selectors/appSettings'
import {
  selectBasketItemById,
  selectCustomer,
  selectDeliveryCountry,
  selectInProgressOrder
} from '../../selectors/manualOrderForm'
import { RSAA } from 'redux-api-middleware'
import getLabImage from '../../helpers/getLabImage'
import { saveUnfinishedOrder } from './saveUnfinishedOrder'
import { fetchPreviewTemplate } from './fetchPreviewTemplate'
import { imageUrlUpdatedNoUpload } from '../../actions/manualOrderForm/uploadArtwork'
import { isNoImageProduct } from '../../helpers/isNoImageProduct'
import { PRINT_AREA_NAME } from '../../data'
import { updatePreview } from './updatePreview'
import type { BasketItemMetaDataType } from '../../types/basketItem'
import type { SetCostedAttributeKeysAction, UpdateBasketItemMetaDataActionType } from '../types/manualOrderForm'

export const SELECT_ATTRIBUTE = 'SELECT_ATTRIBUTE'
export const SET_COSTED_ATTRIBUTE_KEYS = 'SET_COSTED_ATTRIBUTE_KEYS'
export const UPDATE_BASKET_ITEM_META_DATA = 'UPDATE_BASKET_ITEM_META_DATA'
export const RESET_ATTRIBUTES = 'RESET_ATTRIBUTES'
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY'
export const UPDATE_PRICE = 'UPDATE_PRICE'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const REMOVE_BASKET_ITEM = 'REMOVE_BASKET_ITEM'
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA'
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES'
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED'
export const CHANGE_DELIVERY_COUNTRY = 'CHANGE_DELIVERY_COUNTRY'
export const SELECT_MOBILE_FACET_SCREEN = 'SELECT_MOBILE_FACET_SCREEN'
export const SET_CURRENT_PREVIEW_ITEM = 'SET_CURRENT_PREVIEW_ITEM'
export const MERCHANT_ORDER_ID_CHANGE = 'MERCHANT_ORDER_ID_CHANGE'

export function changeDeliveryCountry(country: string): ChangeDeliveryCountry {
  return {
    type: CHANGE_DELIVERY_COUNTRY,
    country
  }
}

export function fetchCountries(): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint
    dispatch({
      [RSAA]: {
        endpoint: endpoint + '/v3.0/countries',
        method: 'GET',
        types: [FETCH_COUNTRIES, RECEIVE_COUNTRIES, FETCH_COUNTRIES_FAILED]
      }
    })
  }
}

export function updateAddress(field: string, value: string): {| field: string, type: string, value: string |} {
  return {
    type: UPDATE_ADDRESS,
    field,
    value
  }
}

export function removeBasketItem(id: number): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    dispatch({
      type: REMOVE_BASKET_ITEM,
      id
    })
    saveUnfinishedOrder(
      selectInProgressOrder(getState()),
      selectCustomer(getState()),
      selectDeliveryCountry(getState())
    )
  }
}

export function changeSelectedAttribute({
  basketItem,
  catalogueItem,
  name,
  value
}: ChangeSelectedAttributeData): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    await dispatch(selectAttribute(name, value, basketItem.id))
    const updatedBasketItem = selectBasketItemById(getState(), basketItem.id)

    if (!updatedBasketItem) {
      return
    }

    await dispatch(fetchPreviewTemplate(basketItem.sku, basketItem.id, updatedBasketItem.selectedAttributes))
    await dispatch(updatePreview(updatedBasketItem))
    await dispatch(updateImageUrlForNoImageProduct(updatedBasketItem, catalogueItem))

    const mof = getState().manualOrderForm
    saveUnfinishedOrder(mof.inProgressOrder, mof.customer, mof.deliveryCountry)
  }
}

function updateImageUrlForNoImageProduct(
  basketItem: MultiAssetBasketItem,
  catalogueItem: CatalogueItem
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc) => {
    if (!isNoImageProduct(catalogueItem.productType) || !basketItem.selectedAttributes.frameColour) {
      return
    }

    const substituteImageUrl = getLabImage(basketItem.selectedAttributes.frameColour, catalogueItem.productType)

    if (substituteImageUrl) {
      await dispatch(imageUrlUpdatedNoUpload(basketItem.id, substituteImageUrl, PRINT_AREA_NAME.DEFAULT))
    }
  }
}

export function setCostedAttributeKeys(
  basketItemId: number,
  costedAttributeKeys: string[]
): SetCostedAttributeKeysAction {
  return { type: SET_COSTED_ATTRIBUTE_KEYS, id: basketItemId, costedAttributeKeys }
}

export function updateBasketItemMetaData(
  basketItemId: number,
  newMetaData: BasketItemMetaDataType
): UpdateBasketItemMetaDataActionType {
  return { type: UPDATE_BASKET_ITEM_META_DATA, id: basketItemId, newMetaData }
}

export function selectAttribute(name: string, value: any, basketItemId: number): SelectAttribute {
  return {
    type: SELECT_ATTRIBUTE,
    name: name,
    value: value,
    id: basketItemId
  }
}

export function resetAttributes(basketItemId: number): ResetAttributes {
  return {
    type: RESET_ATTRIBUTES,
    id: basketItemId
  }
}

export function changeQuantity(qty: number, basketItemId: number): ChangeQuantity {
  return {
    type: CHANGE_QUANTITY,
    qty: qty,
    id: basketItemId
  }
}

export function clearOrderData(): Action {
  return {
    type: CLEAR_ORDER_DATA
  }
}

export function selectMobileFacetScreen(facet: string): SelectMobileFacetScreen {
  return {
    type: SELECT_MOBILE_FACET_SCREEN,
    facet
  }
}

export function setCurrentPreviewItem(item: MultiAssetBasketItem): SetCurrentPreviewItem {
  return {
    type: SET_CURRENT_PREVIEW_ITEM,
    item
  }
}

export function changeMerchantOrderId(merchantOrderId: string): ChangeMerchantOrderId {
  return {
    type: MERCHANT_ORDER_ID_CHANGE,
    merchantOrderId
  }
}
