// @flow
import React, { PureComponent } from 'react'
import Button from '../Button'
import { Stack } from '../layout'
import Modal from '../Modal'

import styles from './LogOutErrorModal.module.css'

const MESSAGE = `Something went wrong and we weren't able to log you out safely.`

type Props = {|
  close: () => void,
  retry: () => void,
|}

export default class LogOutErrorModalView extends PureComponent <Props> {
  render (): React$Node {
    return (
      <Modal close={this.props.close}>
        <Stack className={styles.container}>
          <React.Fragment>
            <p className={styles.heading}>Unable to log out</p>
            <p>{MESSAGE}</p>

            <Stack direction='row'>
              <React.Fragment>
                <Button primary onClick={this.props.retry}>Try again</Button>
                <Button onClick={this.props.close}>Stay logged in</Button>
              </React.Fragment>
            </Stack>
          </React.Fragment>
        </Stack>
      </Modal>
    )
  }
}
