// @flow
import type { ArtworkServiceResponse, Action, AppState, Artwork, Response, Dictionary, ArtworkServiceErrorResponse } from '../../types'
import { generateArtworkUrl, generateResizedArtworkUrl } from '../../helpers/generateArtworkUrls'
import { POSSIBLE_INCORRECT_FILE_TYPE_MESSAGE, INCORRECT_FILE_MESSAGE } from '../../data/fileType'
import { DEFAULT_ERROR_MESSAGE, INVALID_COLOR_SPACE_ERROR_MESSAGE_PARTS } from '../../data/errorMessages'

const INCORRECT_FILE_TYPE_MESSAGE_PARTS = [
  'image format',
  'is invalid',
  'expected: jpg or png'
]

export async function mapArtworkServiceResponse (_: Action, __: AppState, rawResponse: Response<ArtworkServiceResponse>): Promise<Artwork> {
  const response = await rawResponse.json()
  const originalImageUrl = generateArtworkUrl(response.id)

  return {
    originalImageUrl: originalImageUrl,
    croppedImageUrl: originalImageUrl,
    mediumImageUrl: generateResizedArtworkUrl(1500, response.id),
    smallImageUrl: generateResizedArtworkUrl(250, response.id),
    artworkWidth: response.imageWidth,
    artworkHeight: response.imageHeight
  }
}

export async function mapArtworkServiceErrorResponse (_: Action, __: AppState, rawResponse: Response<ArtworkServiceErrorResponse>): Promise<Dictionary<string>> {
  try {
    const response = await rawResponse.json()
    const errorMessage = response.errors.artworkFile.toString()

    return {
      errorMessage: mapErrorMessage(errorMessage),
      debugMessage: JSON.stringify(response)
    }
  } catch {
    return {
      errorMessage: DEFAULT_ERROR_MESSAGE
    }
  }
}

function mapErrorMessage (errorMessage: string): string {
  if (errorMessage === POSSIBLE_INCORRECT_FILE_TYPE_MESSAGE) {
    return INCORRECT_FILE_MESSAGE
  } else if (containsAllPartsOfIncorrectFileTypeMessage(errorMessage)) {
    return INCORRECT_FILE_MESSAGE
  } else if (isColorSpaceError(errorMessage)) {
    return errorMessage
  } else {
    return DEFAULT_ERROR_MESSAGE
  }
}

function isColorSpaceError (errorMessage: string): boolean {
  return INVALID_COLOR_SPACE_ERROR_MESSAGE_PARTS.every(part => errorMessage.includes(part))
}

function containsAllPartsOfIncorrectFileTypeMessage (fullErrorMessage: string): boolean {
  const errorMessageInLowercase = fullErrorMessage.toLowerCase()
  return INCORRECT_FILE_TYPE_MESSAGE_PARTS.every(messagePart => errorMessageInLowercase.includes(messagePart))
}
