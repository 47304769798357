import Skeleton from '../../../Skeleton'
import SupportLink from '../../../SupportLink'
import { SettingsPanel } from '../SettingsPanel.component'
import { SettingsHeader } from '../SettingsHeader.component'
import { SettingsContainer } from '../SettingsContainer.component'
import { FEATURE_NAMES } from '../../../../../split-io/feature-names'
import { BrandedInsertsSetting } from './components/BrandedInsertsSetting.component'
import { useBrandingDetails, useMerchantBranding, useSplitToggle } from '../../../../hooks'

export function SettingsBranding() {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  return (
    <SettingsContainer>
      <SettingsHeader />

      <SettingsPanel
        className="border"
        title={<h2>Inserts</h2>}
        subtitle={
          <div className="mt-4 max-w-lg text-gray-600">
            <p>
              Stickers, postcards &amp; flyers to be included in your orders. Ideal for brand promotion, thank you notes
              or additional marketing materials.
            </p>
            <p className="mt-2">Insert availability is dependent on fulfilment location</p>
          </div>
        }
      >
        {isBrandingSettingsOn && <BrandedInsertsContent />}
      </SettingsPanel>
    </SettingsContainer>
  )
}

function BrandedInsertsContent() {
  const { brandingDetails, isLoadingBrandingDetails, brandingDetailsFetchError } = useBrandingDetails()
  const { merchantBrandingResponse, isLoadingMerchantBranding, merchantBrandingFetchError } = useMerchantBranding()

  if (brandingDetailsFetchError) {
    return <BrandedInsertsError status={`BFE-${brandingDetailsFetchError.status ?? '0'}`} />
  }

  if (merchantBrandingFetchError) {
    return <BrandedInsertsError status={`MBFE-${merchantBrandingFetchError.status ?? '0'}`} />
  }

  if (isLoadingBrandingDetails || !brandingDetails || isLoadingMerchantBranding || !merchantBrandingResponse) {
    return <BrandedInsertsLoading />
  }

  return <BrandedInsertsSetting brandingDetails={brandingDetails} merchantBranding={merchantBrandingResponse.data} />
}

function BrandedInsertsLoading() {
  return (
    <div className="flex flex-col gap-2">
      <Skeleton className="w-60" />
      <Skeleton className="w-48" />
      <Skeleton className="w-48" />
      <Skeleton className="w-44 py-6" />

      <div className="mt-4">
        <Skeleton className="w-48 py-8" />
      </div>
    </div>
  )
}

function BrandedInsertsError({ status }: { status: string }) {
  return (
    <div className="max-w-xl text-red-500">
      An error occurred while loading insert details, please refresh to try again or{' '}
      <SupportLink className="text-inherit underline">contact support</SupportLink> if the issue persists (code:{' '}
      {status})
    </div>
  )
}
