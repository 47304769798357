// @flow
import type { V4ApiServerError } from '../../../types'
import { V4_API_ACTION_OUTCOME } from '../../../data/v4ApiActionOutcome'
import { mapApiPaymentErrorMessage } from '../mapApiPaymentErrorMessage'
import { mapValidationErrorMessage } from './mapValidationErrorMessage'

export function mapApiErrorMessage (serverResponse: V4ApiServerError): ?string {
  if (!serverResponse.outcome) {
    return
  }

  if (serverResponse.outcome === V4_API_ACTION_OUTCOME.PAYMENT_FAILED) {
    return mapApiPaymentErrorMessage(serverResponse.message)
  } else if (serverResponse.outcome === V4_API_ACTION_OUTCOME.VALIDATION_FAILED && serverResponse.failures) {
    return mapValidationErrorMessage(serverResponse.failures)
  }
}
