import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { ROUTE_PATHS } from '../constants'

export default function HomePage() {
  const history = useHistory()

  useEffect(() => {
    history.replace(ROUTE_PATHS.DASHBOARD)
  }, [history])

  return null
}
