// @flow

import React, { PureComponent } from 'react'
import styles from './CsvUploadOverview.module.css'
import cx from 'classnames'
import Button from '../../../../components/Button'

type Props = {|
  className?: string,
  currentPageNumber: number,
  maxPageNumber: number,
  disableNextButton: boolean,
  disablePreviousButton: boolean,
  onPageChange: (newPageNumber: number) => void
|}

export default class CsvPaginator extends PureComponent<Props> {
  render (): React$Node {
    return (
      <div className={cx(styles.paginator, this.props.className)}>
        <Button
          disabled={this.props.disablePreviousButton}
          outline
          icon="angle-left"
          iconPosition="left"
          onClick={() => this.props.onPageChange(this.props.currentPageNumber - 1)}
        >Previous</Button>

        <div className={styles.paginatorSelectContainer}>
          Page <select className={styles.paginatorSelect} value={this.props.currentPageNumber} onChange={(event) => this.props.onPageChange(+event.target.value)}>
            {Array(this.props.maxPageNumber).fill().map((_, pageNumberIndex) => (
              <option key={pageNumberIndex} value={pageNumberIndex + 1}>{pageNumberIndex + 1}</option>
            ))}
          </select> of {this.props.maxPageNumber}
        </div>

        <Button
          disabled={this.props.disableNextButton}
          outline
          icon="angle-right"
          iconPosition="right"
          onClick={() => this.props.onPageChange(this.props.currentPageNumber + 1)}
        >Next</Button>
      </div>
    )
  }
}
