// See https://prodigiuk.atlassian.net/browse/HB-500 for more info
const PRODUCT_CATEGORIES_TO_FIT_IMAGE_BY_DEFAULT = ['Tattoos', 'Stickers', 'Kisscut stickers']

/**
 * Determine if the default behaviour for an image is to fit within template instead of fill on upload
 * @param {string} category - SKU category from Azure Search
 */
export function shouldFitImageInTemplate(category: string) {
  const shouldFitImage = Boolean(
    PRODUCT_CATEGORIES_TO_FIT_IMAGE_BY_DEFAULT.find(
      (categoriesToFit) => categoriesToFit.trim().toUpperCase() === category.trim().toUpperCase()
    )
  )
  return shouldFitImage
}
