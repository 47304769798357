// @flow
import React from 'react'
import styles from './CsvItems.module.css'
import type { FormattedOrderItem, Dictionary, CsvItemProductDetails, Status } from '../../../../types'
import { values } from '../../../../helpers/dictionary'
import CsvItemDetails from '../CsvItemDetails'
import { buildImageId } from '../../../../actions/images'

// $FlowFixMe: TypeScript file
import { CsvProductSearchV2 } from './CsvProductSearchV2.component'

type Props = {|
  isCompleted: boolean,
  canBeEdited: boolean,
  orderId: string,
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CsvItemProductDetails>,
  orderItemIdForEdit: ?string,
  idOfOrderItemWithOpenImageModal: ?string,
  artworkStatus: Dictionary<Status>,
  closeImageModal: () => void,
  openProductSearchModal: (itemId: string) => void,
  closeProductSearchModal: () => void,
  onProductSearchError: (errorMessage: string) => void,
  openImageModal: (orderItemId: string) => void
|}

export default function CsvItems(props: Props): React$Node {
  const { orderItems, orderId, productDetails, isCompleted, canBeEdited } = props

  return (
    <div className={styles.detailsContainer}>
      {orderItems &&
        values(orderItems).map((item, itemIndex) => {
          const details = values(productDetails).find((product) => product.sku.toLowerCase() === item.sku.toLowerCase())
          const imageId = buildImageId(item.id, item.selectedPrintArea)

          return (
            <section key={item.id} data-test={`order-item-${itemIndex}`}>
              <CsvItemDetails
                orderId={orderId}
                item={item}
                details={details}
                isCompleted={isCompleted}
                openProductSearchModal={props.openProductSearchModal}
                itemIndex={itemIndex}
                numberOfItems={values(orderItems).length}
                canBeEdited={canBeEdited}
                artworkStatus={props.artworkStatus[imageId]}
                idOfOrderItemWithOpenImageModal={props.idOfOrderItemWithOpenImageModal}
                closeImageModal={props.closeImageModal}
                openImageModal={props.openImageModal}
              />
              {canBeEdited && (
                <CsvProductSearchV2
                  onError={props.onProductSearchError}
                  defaultAttributes={item.selectedAttributes}
                  closeModal={props.closeProductSearchModal}
                  orderId={orderId}
                  orderItemId={item.id}
                  sku={item.sku}
                  isModalOpen={props.orderItemIdForEdit === item.id}
                  openImageModal={props.openImageModal}
                />
              )}
            </section>
          )
        })}
    </div>
  )
}
