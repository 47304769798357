export function selectOMSUrl(): string {
  return readRequiredString('REACT_APP_PRODIGI_OMS')
}

export function selectMerchantService(): string {
  return readRequiredString('REACT_APP_PRODIGI_MERCHANT_SERVICE')
}

export function selectEnvironmentName(): string {
  return readRequiredString('REACT_APP_ENV')
}

export function selectAzureSearchSettings(): { endpoint: string; key: string } {
  return {
    endpoint: readRequiredString('REACT_APP_SEARCH_ENDPOINT'),
    key: readRequiredString('REACT_APP_SEARCH_KEY')
  }
}

export function selectPigUrl(): string {
  return readRequiredString('REACT_APP_PIG_URL')
}

export function selectProdigiApiEndpoint(): string {
  return readRequiredString('REACT_APP_PRODIGI_API_ENDPOINT')
}

export function selectPwintyApiSettings(): { documentationUri: string; endpoint: string } {
  return {
    endpoint: readRequiredString('REACT_APP_ENDPOINT'),
    documentationUri: readRequiredString('REACT_APP_PWINTY_API_DOCUMENTATION_URI')
  }
}

export function selectTemplateServiceUrl(): string {
  return readRequiredString('REACT_APP_TEMPLATE_SERVICE')
}

function readRequiredString(settingName: string): string {
  const value = readOptionalString(settingName)

  if (!value) {
    throw new Error(`Setting ${settingName} has not been provided`)
  }

  return value
}

function readOptionalString(settingName: string): string | undefined {
  return process.env[settingName]
}
