// @flow
import React from 'react'

import styles from '../Apps.module.css'
import { TEST } from '../../../data/constants'
import type { AvailableAppType } from '../../../types'
import { PHOTOBOOK_MAKER_TITLE, PRINT_SHOP_TITLE } from '../constants'
import type { RouterHistory } from 'react-router'

type Props = {|
  appData: AvailableAppType,
  hasPhotobookMaker?: boolean,
  hasPrintShop?: boolean,
  history: RouterHistory
|}

class AppCard extends React.Component<Props> {
  onConnectClick: ((url: string, isExternal?: boolean) => void) = (url: string, isExternal?: boolean) => {
    if (isExternal) {
      window.open(url, '_blank')
    } else {
      this.props.history.push(url)
    }
  }

  hidePrintShopIfConnected: (() => void | boolean) = () => {
    return (
      !(process.env.REACT_APP_ENV === TEST) &&
      (this.props.hasPrintShop && this.props.appData.title === PRINT_SHOP_TITLE)
    )
  }

  hidePhotobookMakerIfConnected: (() => void | boolean) = () => {
    return (
      !(process.env.REACT_APP_ENV === TEST) &&
      (this.props.hasPhotobookMaker && this.props.appData.title === PHOTOBOOK_MAKER_TITLE)
    )
  }

  render (): React$Node {
    const {
      description,
      icon,
      linkText,
      title,
      imageHeightPx,
      imagePlacement,
      imageWidthPx,
      imageUrl,
      url
    } = this.props.appData

    // To be removed when more than 1 app per type is supported in print-engine
    if (this.hidePrintShopIfConnected()) {
      return null
    }
    if (this.hidePhotobookMakerIfConnected()) {
      return null
    }

    return (
      <div className={styles.newAppCard}>
        <div className={styles.newAppDetail}>
          <div className={styles.appCardIcon}>{icon}</div>
          <div className={styles.appCardTitle}>{title}</div>
          <div className={styles.appCardDescription}>{description}</div>
          <div className={styles.appCardActions}>
            <span className={styles.newAppCardLink} onClick={() => this.onConnectClick(url, this.props.appData.isExternal)}>
              {linkText}
            </span>
          </div>
        </div>
        <div className={styles.newAppGradient} style={{backgroundImage: this.props.appData.gradient}}></div>
        <div className={styles.newAppImage} style={{top: imagePlacement.top, right: imagePlacement.right, bottom: imagePlacement.bottom, left: imagePlacement.left}}>
          <img src={imageUrl} width={imageWidthPx} height={imageHeightPx} alt={title}></img>
        </div>
      </div>
    )
  }
}

export default AppCard
