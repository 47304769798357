import { useEffect } from 'react'
import useSWR, { SWRConfiguration } from 'swr'

import { formatSkuForMockup } from '../../../hooks'
import { FetchErrorInterface } from '../../../interfaces'
import { FetchError, getDefaultFetchOptions } from '../../../helpers'

interface MockupGenerationDataInterface {
  view_name: string
  variants: Record<string, string>
}

export function MockupsPolling3D({
  productSku,
  transformationId,
  mockupGenerationData,
  onComplete
}: {
  productSku: string
  transformationId: string
  mockupGenerationData: MockupGenerationDataInterface
  onComplete: (details: {
    mockupsData?: { url: string }
    mockupsFetchError?: FetchErrorInterface<{ detail?: string }>
  }) => void
}) {
  const { mockupsData, mockupsFetchError } = useMockupGeneration3D(
    { productSku, transformationId, mockupGenerationData },
    {
      config: { refreshInterval: 2000, revalidateOnMount: false, revalidateOnFocus: false }
    }
  )

  useEffect(() => {
    if (mockupsData && mockupsData.responseBodyJson && mockupsData.status === 200) {
      onComplete({ mockupsData: mockupsData.responseBodyJson, mockupsFetchError })
    }
    if (mockupsFetchError) {
      onComplete({ mockupsData: mockupsData?.responseBodyJson, mockupsFetchError })
    }
  }, [mockupsData, mockupsFetchError, onComplete])

  return null
}

function useMockupGeneration3D(
  {
    productSku,
    transformationId,
    mockupGenerationData
  }: {
    productSku: string
    transformationId: string
    mockupGenerationData: MockupGenerationDataInterface
  },
  { config = {} }: { config?: SWRConfiguration } = {}
) {
  const { data, error } = useSWR<
    { responseBodyJson: { url: string }; status: number },
    FetchErrorInterface<{ detail?: string }>
  >([productSku, mockupGenerationData, transformationId], mockupGenerationFetcher, config)

  return {
    mockupsData: data,
    isLoadingMockups: !data && !error,
    mockupsFetchError: error
  }
}

async function mockupGenerationFetcher(
  productSku: string,
  mockupGenerationData: MockupGenerationDataInterface,
  transformationId: string
) {
  const url = `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/mockups/${formatSkuForMockup(productSku)}/view/${
    mockupGenerationData.view_name
  }/transform/${transformationId}`

  return fetch(url, {
    ...getDefaultFetchOptions(),
    body: JSON.stringify(mockupGenerationData),
    method: 'POST'
  }).then(async (response) => {
    let responseBodyJson = null

    if (
      response.headers.get('content-type')?.includes('application/json') ||
      response.headers.get('content-type')?.includes('application/problem+json')
    ) {
      responseBodyJson = await response.json()
    }

    if (!response.ok) {
      throw new FetchError({
        message: response.statusText,
        responseBodyJson,
        name: 'FETCH_ERROR',
        status: response.status
      })
    }
    return { responseBodyJson, status: response.status }
  })
}
