import { useEffect, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import NotFound from '../NotFound'
import SupportLink from '../SupportLink'
import ClipboardCopy from '../ClipboardCopy'
import { ROUTE_PATHS } from '../../constants'
import { SalesChannelInterface } from '../../interfaces'
import { ProductsList } from './components/ProductsList.component'
import { useMerchantService, useSalesChannelProducts } from '../../hooks'
import { ConfigureActions } from './components/ConfigureActions.component'
import { ProductTileSkeleton } from './components/ProductTileSkeleton.component'
import { ConfigureHeader, ProductsEmpty, ProductsRetrieving } from './components'
import { ProductsListContainer } from './components/ProductsListContainer.component'

export default function SalesChannelsConfigure({ salesChannelId }: { salesChannelId: string }) {
  const { merchantDetails } = useMerchantService()

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  const salesChannel = merchantDetails.salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)

  if (!salesChannel) {
    return (
      <div className="grid min-h-screen place-content-center">
        <NotFound>
          <Link className="btn btn-primary mx-auto mt-8" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
            Back to sales channels
          </Link>
        </NotFound>
      </div>
    )
  }

  return (
    <>
      <div>
        <ConfigureHeader salesChannel={salesChannel} />
      </div>

      <div className="mt-12">
        <ProductActions salesChannelId={salesChannelId} />
        <div className="mt-6 lg:mt-8">
          <Products salesChannel={salesChannel} />
        </div>
      </div>
    </>
  )
}

function ProductActions({ salesChannelId }: { salesChannelId: string }) {
  const { search } = useLocation()
  const { salesChannelProductPages } = useSalesChannelProducts(salesChannelId)

  const hasFiltersApplied = Boolean(search)
  const channelHasProducts = Boolean(
    salesChannelProductPages?.[0].data.products && salesChannelProductPages[0].data.products.length > 0
  )
  const showActions = channelHasProducts || hasFiltersApplied

  if (!showActions) {
    return null
  }

  return <ConfigureActions />
}

function Products({ salesChannel }: { salesChannel: SalesChannelInterface }) {
  const {
    isLoadingSalesChannelProducts,
    salesChannelProductPages,
    salesChannelProductsFetchError,
    salesChannelProductPagesToLoadSize
  } = useSalesChannelProducts(salesChannel.id)
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0)
    })
  }, [searchParams])

  const hasErrorLoadingFirstPage =
    salesChannelProductsFetchError && salesChannelProductPagesToLoadSize <= 1 && salesChannel.numberOfProducts > 0

  if (hasErrorLoadingFirstPage) {
    const errorCode =
      salesChannelProductsFetchError?.responseBodyJson?.traceParent ?? salesChannelProductsFetchError?.status ?? '0'

    return (
      <div className="w-full pt-36">
        <NotFound
          className="mx-auto px-9"
          heading="Failed to load products"
          body={
            <>
              <p className="text-center">
                Please try refreshing the page or <SupportLink>contact us</SupportLink> if the issue persists.
              </p>
              <div className="mt-4 break-words text-center text-gray-600">
                Error code: {errorCode}
                <ClipboardCopy className="align-middle" text={errorCode.toString()} showText={false} />
              </div>
            </>
          }
        >
          <Link className="btn btn-primary mx-auto mt-8" to={ROUTE_PATHS.SALES_CHANNELS.INDEX}>
            Back to sales channels
          </Link>
        </NotFound>
      </div>
    )
  }

  if (isLoadingSalesChannelProducts && salesChannel.initialProductsDownloaded) {
    return (
      <ProductsListContainer>
        {Array.from({ length: 10 }, (_, i) => i).map((value) => (
          <ProductTileSkeleton key={value} />
        ))}
      </ProductsListContainer>
    )
  }

  if (isLoadingSalesChannelProducts && !salesChannel.initialProductsDownloaded) {
    return <ProductsRetrieving platformName={salesChannel.platform} />
  }

  const channelHasNoProducts = salesChannelProductPages?.[0]?.data?.products.length === 0

  if (channelHasNoProducts) {
    return (
      <ProductsEmpty
        initialProductsDownloaded={salesChannel.initialProductsDownloaded}
        platformName={salesChannel.platform}
        salesChannelName={salesChannel.description}
      />
    )
  }

  return <ProductsList salesChannel={salesChannel} />
}
