import { motion } from 'framer-motion'

import { useMerchantService } from '../../hooks'
import { SalesChannelPlatformEnum } from '../../enums'
import { ActiveSalesChannelList, SalesChannelsHeader } from './components'
import { ConnectNewSalesChannel } from './components/ConnectNewSalesChannel.component'

export default function SalesChannels() {
  const { merchantDetails } = useMerchantService()
  const salesChannels = merchantDetails?.salesChannels

  const externalActiveSalesChannels =
    salesChannels?.filter((salesChannel) => salesChannel.platform !== SalesChannelPlatformEnum.Prodigi) || []

  return (
    <motion.div animate={{ opacity: 1 }} className="container mx-auto flex flex-col" initial={{ opacity: 0 }}>
      <SalesChannelsHeader />
      {externalActiveSalesChannels?.length > 0 && (
        <ActiveSalesChannelList className="mt-16" activeChannels={externalActiveSalesChannels} />
      )}
      <ConnectNewSalesChannel className="mt-16" />
    </motion.div>
  )
}
