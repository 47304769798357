// @flow
import type { QuoteEndpointErrorResponse, RsaaStatus, Quote, FormattedCsvRow, CsvItemProductDetails, Dictionary, Status, FormattedOrderItem } from '../../types'
import { getPrintAreasWithoutImages, everyItemHasDetails, everyItemHasImages, isOrderAddressValid, isShippingMethodValid } from './checkCsvItem'
import { ERROR } from '../../data/rsaa'
import { BAD_REQUEST } from '../../data/statusCodes'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { entries, values } from '../dictionary'

const MISSING_DETAILS_ERROR_MESSAGE = 'Please select and review the required product SKU and attributes.'
const INVALID_ATTRIBUTES_ERROR_MESSAGE = 'Please select and review the required attributes.'
const MISSING_IMAGES_ERROR_MESSAGE = 'Please provide the required print image.'
const MISSING_MULTI_ASSET_IMAGES_ERROR_MESSAGE = 'Please provide required print images.'
const ADDRESS_INCOMPLETE_ERROR_MESSAGE = 'Address incomplete or missing.'
const SHIPPING_METHOD_ERROR_MESSAGE = 'The selected shipping method is not available. Please select from the available options.'

export function createOrderErrorMessage (
  order: FormattedCsvRow,
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CsvItemProductDetails>,
  artworkStatus: Dictionary<Status>,
  quotes: Quote[],
  quotesRsaaStatus: RsaaStatus
): ?string {
  if (hasInvalidAttributes(quotesRsaaStatus)) {
    return INVALID_ATTRIBUTES_ERROR_MESSAGE
  } else if (!everyItemHasDetails(orderItems, productDetails)) {
    return MISSING_DETAILS_ERROR_MESSAGE
  } else if (!everyItemHasImages(orderItems, productDetails, artworkStatus)) {
    return buildMissingImageErrorMessage(orderItems, productDetails, artworkStatus)
  } else if (!isOrderAddressValid(order)) {
    return ADDRESS_INCOMPLETE_ERROR_MESSAGE
  } else if (!isShippingMethodValid(order.preferredShippingMethod, quotes)) {
    return SHIPPING_METHOD_ERROR_MESSAGE
  } else {
    return null
  }
}

function hasInvalidAttributes (quotesRsaaStatus: RsaaStatus): boolean {
  if (
    quotesRsaaStatus.status !== ERROR ||
    quotesRsaaStatus.statusCode !== BAD_REQUEST ||
    !quotesRsaaStatus.errorPayload
  ) {
    return false
  }

  const quoteCreationError: QuoteEndpointErrorResponse = quotesRsaaStatus.errorPayload.response

  return quoteCreationError.outcome === V4_API_ACTION_OUTCOME.VALIDATION_FAILED
}

function buildMissingImageErrorMessage (
  orderItems: Dictionary<FormattedOrderItem>,
  productDetails: Dictionary<CsvItemProductDetails>,
  artworkStatus: Dictionary<Status>
): string {
  const printAreasWithoutImages = getPrintAreasWithoutImages(orderItems, productDetails, artworkStatus)
  const hasMoreThanOnePrintArea = values(productDetails).some(product => Object.keys(product.printAreas).length > 1)

  return hasMoreThanOnePrintArea
    ? buildMissingMultiAssetImageErrorMessage(printAreasWithoutImages)
    : MISSING_IMAGES_ERROR_MESSAGE
}

function buildMissingMultiAssetImageErrorMessage (
  printAreasWithoutImages : Array<[string, string, string]>
) {
  const printAreasWithoutImagesGroupedBySku = printAreasWithoutImages.reduce(
    (printAreaAcc, [_imageId, printAreaName, sku]) => {
      if (printAreaAcc[sku] && printAreaAcc[sku].includes(printAreaName)) {
        return printAreaAcc
      } else if (!printAreaAcc[sku]) {
        printAreaAcc[sku] = [printAreaName]
      } else {
        printAreaAcc[sku] = [...printAreaAcc[sku], printAreaName]
      }
      return printAreaAcc
    }, {})

  const messagePart2 = entries(printAreasWithoutImagesGroupedBySku).reduce(
    (messageAcc, [sku, printAreasWithoutImages]) => {
      const printAreaMessageWithCommas = printAreasWithoutImages.join(', ')
      const lastCommaIndex = printAreaMessageWithCommas.lastIndexOf(',')
      const printAreaMessageChars = printAreaMessageWithCommas.split('')
      if (lastCommaIndex > 0) {
        printAreaMessageChars.splice(lastCommaIndex, 1, ', and')
      }
      const printAreaMessage = printAreaMessageChars.join('')

      messageAcc += `${sku} is missing images for print area(s): ${printAreaMessage}. `

      return messageAcc
    }, '').trim()

  return MISSING_MULTI_ASSET_IMAGES_ERROR_MESSAGE + ' ' + messagePart2
}
