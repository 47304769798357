import { useEffect } from 'react'
import { useParams } from 'react-router'

import OrderDetail from '../../components/OrderDetail'
import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from '../../constants'

export default function OrderDetailPage() {
  const { id: orderId } = useParams<{ id: string }>()

  useEffect(() => {
    document.title = `Order ${orderId} | Prodigi`
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [orderId])

  useEffect(() => {
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.ORDERS, 'Order Detail')
  }, [])

  return (
    <main className="tailwind">
      <OrderDetail orderId={orderId} />
    </main>
  )
}
