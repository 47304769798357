// @flow
import { values } from '../../helpers/dictionary'
import type { FormattedCsvRow, Dictionary } from '../../types'

export function updateOrderShippingMethods (
  uploadedOrders: Dictionary<FormattedCsvRow>,
  idsOfOrdersToUpdate: string[],
  shippingMethod: string
): Dictionary<FormattedCsvRow> {
  return values(uploadedOrders).reduce((uploadedOrdersAcc, order) => {
    const isSelected = idsOfOrdersToUpdate.includes(order.id)

    if (isSelected) {
      uploadedOrdersAcc[order.id] = {
        ...order,
        preferredShippingMethod: shippingMethod
      }
    } else {
      uploadedOrdersAcc[order.id] = order
    }

    return uploadedOrdersAcc
  }, {})
}
