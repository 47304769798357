// @flow
import React from 'react'

import styles from './Apps.module.css'
import NewAppCard from './components/NewAppCard'
import ConnectedAppCard from './components/ConnectedAppCard'
import type { AvailableAppType, ConnectedAppType } from '../../types'
import type { RouterHistory } from 'react-router'

type Props = {|
  history: RouterHistory,
  appsAvailable: AvailableAppType[],
  connectedApps?: ConnectedAppType[],
  hasPhotobookMaker?: boolean,
  hasPrintShop?: boolean,
  onConnectedAppStatusChange?: (isOn: boolean, appToUpdate: ConnectedAppType) => void
|}

export default class AppsView extends React.PureComponent<Props> {
  render(): React$Node {
    const {
      appsAvailable,
      connectedApps = [],
      hasPhotobookMaker = false,
      hasPrintShop = false,
      onConnectedAppStatusChange,
      history
    } = this.props

    return (
      <main className={styles.appsPage}>
        {connectedApps.length > 0 && (
          <section className={styles.appsPageConnectedApps}>
            <div className={styles.appsPageTitle}>Manage your Apps</div>
            <div className={styles.appsPageSubtitle}>Configure app settings</div>
            <div className={styles.appsCardContainer}>
              {connectedApps.map((app) => (
                <ConnectedAppCard
                  appData={app}
                  handleAppStatusChange={(isOn, event) =>
                    onConnectedAppStatusChange && onConnectedAppStatusChange(isOn, app)
                  }
                  key={app.id}
                />
              ))}
            </div>
          </section>
        )}

        <section className={styles.appsPageNewApps}>
          <div className={styles.appsPageTitle}>Connect an app</div>
          <div className={styles.appsPageSubtitle}>Connect one of our solutions below or view our how-to-guides</div>
          <div className={styles.appsCardContainer}>
            {appsAvailable.map((app) => (
              <NewAppCard
                appData={app}
                hasPhotobookMaker={hasPhotobookMaker}
                hasPrintShop={hasPrintShop}
                history={history}
                key={app.title}
              />
            ))}
          </div>
        </section>

        {/* Commented out print-shop and photobook-maker as part of https://prodigiuk.atlassian.net/browse/HUB-1029 */}
        {/* {connectedApps.length === 0 && (
          <section className={styles.appsPageNoApps}>
            <div className={styles.appsPageTitle}>Manage your Apps</div>
            <div className={styles.appsPageSubtitle}>You currently have no connected apps</div>
          </section>
        )} */}
      </main>
    )
  }
}
