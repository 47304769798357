// @flow
import type { Action } from '../../types'

export const RESET_APP_STATE = 'RESET_APP_STATE'

export function resetAppState (): Action {
  return {
    type: RESET_APP_STATE
  }
}
