import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { ImageListEmpty } from './ImageListEmpty.component'
import { ImageListLoaded } from './ImageListLoaded.component'
import { ImageLibraryModeType } from '../ImageLibrary.component'
import { ImageTileSkeleton } from './ImageTileSkeleton.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'

interface ImageListPropsInterface {
  imageLibraryMode: ImageLibraryModeType
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function ImageList({ imageLibraryMode, onSelectImage }: ImageListPropsInterface) {
  const { imageLibraryPages, isLoadingImageLibraryImages, imageLibraryFetchError, imageLibraryPagesToLoadSize } =
    useImageLibraryImages()

  const hasErrorLoadingFirstPage = imageLibraryFetchError && imageLibraryPagesToLoadSize <= 1

  if (hasErrorLoadingFirstPage) {
    const errorCode = imageLibraryFetchError?.status ?? '0'

    return (
      <div className="w-full pt-12 sm:pt-24">
        <NotFound
          className="mx-auto px-9"
          heading="Failed to load images"
          body={
            <>
              <p className="text-center">
                Please try again later or <SupportLink>contact us</SupportLink> if the issue persists.
              </p>
              {imageLibraryFetchError.responseBodyJson?.detail && (
                <p className="mt-2 break-words text-center">{imageLibraryFetchError.responseBodyJson.detail}</p>
              )}
              <div className="mt-4 break-words text-center text-gray-600">
                Error code: {errorCode}
                <ClipboardCopy className="align-middle" text={errorCode.toString()} showText={false} />
              </div>
            </>
          }
        >
          <></>
        </NotFound>
      </div>
    )
  }

  if (isLoadingImageLibraryImages) {
    return (
      <div className="grid max-w-full grid-cols-2 gap-8 overflow-x-auto pt-8 pb-12 md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
        {Array.from({ length: 6 }, (_, i) => i).map((value) => (
          <ImageTileSkeleton key={value} />
        ))}
      </div>
    )
  }

  const isImageLibraryEmpty = Boolean(imageLibraryPages?.[0].length === 0)

  if (isImageLibraryEmpty) {
    return <ImageListEmpty />
  }

  return <ImageListLoaded imageLibraryMode={imageLibraryMode} onSelectImage={onSelectImage} />
}
