import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'

export function useOrdersOverview() {
  const {
    data: orderOverviewResponse,
    mutate: mutateOrdersOverview,
    isValidating: isValidatingOrderOverview,
    error
  } = useSWR<OrderOverviewDataSuccessInterface, FetchErrorInterface<OrderOverviewDataErrorInterface>>(
    `${process.env.REACT_APP_PRODIGI_OMS}/orders`,
    fetcher
  )

  return {
    isValidatingOrderOverview,
    orderOverviewResponse,
    error,
    mutateOrdersOverview
  }
}

interface OrderOverviewResponseInterface {
  outcome: string
  traceParent: string
}

export interface OrderOverviewDataErrorInterface extends OrderOverviewResponseInterface {
  data: {
    message: string
  }
}

export interface OrderOverviewDataSuccessInterface extends OrderOverviewResponseInterface {
  data: {
    requiresAttention: number
  }
}
