// @flow
import React from 'react'
import { debounce } from 'lodash'

import Stepper from '../../components/Stepper'
import BrandName from './components/BrandName'
import BrandLogo from './components/BrandLogo'
import BrandColour from './components/BrandColour'
import { ABORT_ERROR_NAME, HEX_REG_EXP } from '../../data/constants'
import processFetchResponse from '../../helpers/processFetchResponse'
import getDefaultFetchOptions from '../../helpers/getDefaultFetchOptions'
import { APP_TYPES, PRINT_ENGINE_PARAMS, PRINT_ENGINE_URL } from '../Apps/constants'
import type { AbortController, Match, MatchAppType, User } from '../../types'
import type { RouterHistory } from 'react-router'

type State = {|
  abortController: AbortController,
  abortSignal: AbortSignal,
  appType: MatchAppType,
  brandColour: {|
    isHexValid: boolean,
    value: string
  |},
  brandLogo: {|
    fileName: string,
    fileSize: string,
    url: string
  |},
  brandName: {|
    disableNext: boolean,
    errorText: string,
    isAvailable: boolean | null,
    isLoadingAvailability: boolean,
    value: string
  |},
  currentStep: number,
  hasError: boolean,
  isLoadingActionButton: boolean,
  loadingActionButtonText: string,
  printEngineAppType: string,
  totalSteps: number
|}

type Props = {|
  match: Match,
  user: User,
  history: RouterHistory
|}

class AddNewShop extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const appType = APP_TYPES[this.props.match.params.appType]
    const controller = new window.AbortController()
    const signal = controller.signal

    this.state = {
      abortController: controller,
      abortSignal: signal,
      appType,
      brandColour: {
        isHexValid: false,
        value: ''
      },
      brandLogo: {
        fileName: '',
        fileSize: '',
        url: ''
      },
      brandName: {
        value: '',
        isAvailable: null,
        isLoadingAvailability: false,
        disableNext: true,
        errorText: ''
      },
      currentStep: 1,
      hasError: false,
      isLoadingActionButton: false,
      loadingActionButtonText: 'Saving',
      printEngineAppType: appType.printEngineType,
      totalSteps: 3
    }

    this.checkBrandNameAvailability = debounce(
      this.checkBrandNameAvailability,
      500
    )
  }

  updateBrandNameState: ((
  disableNext: boolean,
  errorText: string,
  isAvailable: boolean | null,
  isLoadingAvailability: boolean,
  value: string
) => void) = (disableNext: boolean, errorText: string, isAvailable: boolean | null, isLoadingAvailability: boolean, value: string) => {
  this.setState({
    brandName: {
      disableNext,
      errorText,
      isAvailable,
      isLoadingAvailability,
      value
    }
  })
}

  checkBrandNameValidity: ((brandName: string) => boolean) = (brandName: string) => {
    const validNameRegex = /^[a-zA-Z0-9-_]+$/
    const isValidName = validNameRegex.test(brandName)
    const errorText =
      brandName === '' ? '' : 'Allowed characters - Alphanumeric, - and _'
    if (!isValidName) {
      this.updateBrandNameState(true, errorText, false, false, brandName)
    }
    return isValidName
  }

  checkBrandNameAvailability: any | ((brandName: string) => void) = (brandName: string) => {
    const url = `${PRINT_ENGINE_URL}/brand-available/?${PRINT_ENGINE_PARAMS.BRAND_NAME}=${brandName}&${PRINT_ENGINE_PARAMS.APP_TYPE}=${this.state.printEngineAppType}`
    fetch(url, {
      ...getDefaultFetchOptions(),
      signal: this.state.abortSignal
    })
      .then(processFetchResponse)
      .then((isAvailable: boolean) => {
        if (brandName !== this.state.brandName.value) {
          return
        }
        const errorText = !isAvailable
          ? 'Shop name already taken. Please try a different name'
          : ''
        this.updateBrandNameState(
          !isAvailable,
          errorText,
          isAvailable,
          false,
          brandName
        )
      })
      .catch(err => {
        if (err.name !== ABORT_ERROR_NAME) {
          console.error(err)
          const errorText =
            'Failed to fetch availability, please try again later'
          this.updateBrandNameState(true, errorText, null, false, brandName)
        }
      })
  }

  handleNext: (() => void) = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
      hasError: false
    })
  }

  handleBack: (() => void) = () => {
    if (this.state.currentStep === 1) {
      this.props.history.replace('/apps')
    } else {
      this.setState({
        currentStep: this.state.currentStep - 1,
        hasError: false
      })
    }
  }

  handleSaveChanges: (() => void) = () => {
    const {
      brandColour,
      brandLogo,
      brandName,
      printEngineAppType
    } = this.state

    const { match, user } = this.props

    const {
      APP_TYPE,
      BRAND_COLOUR,
      BRAND_NAME,
      BRAND_LOGO,
      MERCHANT_ID
    } = PRINT_ENGINE_PARAMS

    this.setState({
      hasError: false,
      isLoadingActionButton: true
    })

    const url = `${PRINT_ENGINE_URL}/config/`
    fetch(url, {
      ...getDefaultFetchOptions(),
      method: 'POST',
      body: JSON.stringify({
        [APP_TYPE]: printEngineAppType,
        [BRAND_COLOUR]: `#${brandColour.value}`,
        [BRAND_NAME]: brandName.value,
        [BRAND_LOGO]: brandLogo.url,
        [MERCHANT_ID]: user.merchantUniqueId
      }),
      signal: this.state.abortSignal
    })
      .then(processFetchResponse)
      .then(() => {
        this.props.history.replace(
          `/apps/configure/${match.params.appType}/${brandName.value}`
        )
      })
      .catch(err => {
        if (err.name !== ABORT_ERROR_NAME) {
          console.error(err)
          this.setState({
            hasError: true,
            isLoadingActionButton: false
          })
        }
      })
  }

  handleBrandNameChange: ((event: SyntheticInputEvent<HTMLInputElement>) => void) = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const brandName = event.target.value
    this.updateBrandNameState(true, '', null, true, brandName)
    if (!this.checkBrandNameValidity(brandName)) {
      return
    }
    this.checkBrandNameAvailability(brandName)
  }

  handleBrandLogoChange: ((fileName?: string, fileSize?: string, fullImageUrl?: string) => void) = (fileName: string = '', fileSize: string = '', fullImageUrl: string = '') => {
    this.setState({
      brandLogo: {
        fileName: fileName,
        fileSize: fileSize,
        url: fullImageUrl
      }
    })
  }

  handleBrandColourChange: ((event: SyntheticInputEvent<HTMLInputElement>) => void) = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      brandColour: {
        isHexValid: HEX_REG_EXP.test(event.target.value),
        value: event.target.value
      }
    })
  }

  componentWillUnmount () {
    this.state.abortController.abort()
  }

  render (): React$Node {
    const {
      appType,
      brandColour,
      brandName,
      brandLogo,
      currentStep,
      totalSteps
    } = this.state

    const allSteps = [
      {
        actionButtonText: 'Next',
        disableActionButton: brandName.disableNext,
        title: 'Add store address',
        subTitle:
          'This is the URL your store will be served from. It cannot be changed later',
        content: (
          <BrandName
            brandName={brandName.value}
            errorText={brandName.errorText}
            isAvailable={brandName.isAvailable}
            isLoadingAvailability={brandName.isLoadingAvailability}
            inputPrefix={appType.hostName + '/'}
            brandNameChangeHandler={this.handleBrandNameChange}
          />
        )
      },
      {
        actionButtonText: 'Next',
        disableActionButton: !brandLogo.url,
        title: 'Add logo',
        subTitle:
          'Recommended logo size is 150 x 50px. Upload as a PNG file for best results',
        content: (
          <BrandLogo
            fileName={brandLogo.fileName}
            fileSize={brandLogo.fileSize}
            fullImageUrl={brandLogo.url}
            brandLogoChangeHandler={(fileName, fileSize, fullImageUrl) =>
              this.handleBrandLogoChange(fileName, fileSize, fullImageUrl)
            }
          />
        )
      },
      {
        actionButtonText: 'Save',
        title: 'Add button colour',
        disableActionButton: !brandColour.isHexValid,
        subTitle:
          'Choose a hex value for your button colours. You can change this anytime',
        content: (
          <BrandColour
            autoFocus={true}
            brandColour={brandColour.value}
            isHexValid={brandColour.isHexValid}
            brandColourChangeHandler={this.handleBrandColourChange}
          />
        )
      }
    ]
    const currentStepIndex = currentStep - 1
    const {
      actionButtonText,
      disableActionButton,
      title,
      subTitle,
      content
    } = allSteps[currentStepIndex]
    const {
      hasError,
      isLoadingActionButton,
      loadingActionButtonText
    } = this.state

    return (
      <main style={{ maxWidth: '1440px', margin: 'auto' }}>
        <Stepper
          actionButtonText={actionButtonText}
          contentTitle={title}
          contentSubtitle={subTitle}
          currentStep={currentStep}
          disableActionButton={disableActionButton}
          hasError={hasError}
          isLoadingActionButton={isLoadingActionButton}
          loadingActionButtonText={loadingActionButtonText}
          stepperTitle={appType.connectTitle}
          totalSteps={totalSteps}
          onBack={this.handleBack}
          onComplete={this.handleSaveChanges}
          onNext={this.handleNext}
        >
          {content}
        </Stepper>
      </main>
    )
  }
}

export default AddNewShop
