import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SortOrdersByOption } from '../../../enums'
import { formatSortOrdersByName } from '../../../helpers'
import SingleSelectDropdown from '../../SingleSelectDropdown'
import { DEFAULT_ORDERS_SORT_ORDER, QUERY_PARAMS, ROUTE_PATHS, SORT_ORDERS_BY } from '../../../constants'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function OrdersListActionSort() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedSortParam = searchParams.get(QUERY_PARAMS.ORDERS.SORT)
  const selectedSortValue = useMemo(() => selectedSortParam || DEFAULT_ORDERS_SORT_ORDER, [selectedSortParam])
  const [selectedSortOption, setSelectedSortOption] = useState(selectedSortValue)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedSortOption(selectedSortValue)
  }, [selectedSortValue, searchParams])

  const updateSelectedSortOption = (selected: SortOrdersByOption) => {
    if (selected === SortOrdersByOption.OLDEST) {
      window.analytics.track('Clicked Sort By Oldest First')
    }
    if (selected === SortOrdersByOption.NEWEST) {
      window.analytics.track('Clicked Sort By Newest First')
    }
    setSelectedSortOption(selected)
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.ORDERS.SORT, selected)
    const newOrdersUrl = `${ROUTE_PATHS.ORDERS}?${newSearchParams.toString()}`
    history.push(newOrdersUrl)
  }

  return (
    <span className="w-full" data-test="orders-list-actions__sort-filter">
      <SingleSelectDropdown
        dropdownName={formatSortOrdersByName(selectedSortOption as SortOrdersByOption)}
        options={SORT_ORDERS_BY.map((option) => {
          return {
            content: <span>{formatSortOrdersByName(option)}</span>,
            searchLabel: formatSortOrdersByName(option),
            value: option
          }
        })}
        selected={selectedSortOption}
        updateSelected={(selected) => updateSelectedSortOption(selected as SortOrdersByOption)}
      />
    </span>
  )
}
