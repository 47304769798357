// @flow
import type { ClearProductSearch, ResetProductSearchResult, ToggleFacetExpansion } from '../types'

export const TOGGLE_FACET_EXPANSION = 'TOGGLE_FACET_EXPANSION'
export const RESET_PRODUCT_SEARCH_RESULTS = 'RESET_PRODUCT_SEARCH_RESULTS'
export const CLEAR_PRODUCT_SEARCH = 'CLEAR_PRODUCT_SEARCH'

export function toggleFacetExpansion (facet: string): ToggleFacetExpansion {
  return { type: TOGGLE_FACET_EXPANSION, facet }
}

export function resetProductSearchResults (): ResetProductSearchResult {
  return { type: RESET_PRODUCT_SEARCH_RESULTS }
}

export function clearProductSearch (): ClearProductSearch {
  return { type: CLEAR_PRODUCT_SEARCH }
}
