// @flow
import { connect } from 'react-redux'
import { logOut, REVOKE_ACCESS_TOKEN, KITE_LOGOUT } from '../../actions/auth'
import { getRsaaStatusProps } from '../../selectors/rsaa'
import { resetRsaaStatus } from '../../actions/rsaa'
import LogOutModalController from './LogOutErrorModalController'
import type { AppState, DispatchFunc } from '../../types'
import type { ComponentType } from 'react'

type StateProps = {|
  revokeTokenError: boolean,
  kiteLogoutError: boolean
|}

type DispatchProps = {|
  logOut: () => void,
  resetRsaaStatus: () => void
|}

function mapStateToProps (state: AppState): StateProps {
  return {
    revokeTokenError: getRsaaStatusProps(state, REVOKE_ACCESS_TOKEN).error,
    kiteLogoutError: getRsaaStatusProps(state, KITE_LOGOUT).error &&
      (getRsaaStatusProps(state, KITE_LOGOUT).statusMessage === 'showModal')
  }
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    logOut: () => {
      dispatch(logOut())
    },
    resetRsaaStatus: () => {
      dispatch(resetRsaaStatus(KITE_LOGOUT))
      dispatch(resetRsaaStatus(REVOKE_ACCESS_TOKEN))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(LogOutModalController): ComponentType<{||}>)
