import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Switch from '../../Switch'
import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { saveVariantData } from '../helpers/saveVariantData.helper'
import { VariantDataInterface } from '../SalesChannelProduct.component'
import { useMerchantService } from '../../../hooks/useMerchantService.hook'
import { SALES_CHANANEL_PRODUCT_TOAST_IDS } from '../constants/toastIds.const'
import { useSalesChannelProduct } from '../../../hooks/useSalesChannelProduct.hook'
import { FetchErrorInterface, OMSErrorResponseInterface, StatusType } from '../../../interfaces'

export function VariantFulfilmentToggle({
  className = '',
  variantData
}: {
  className: string
  variantData: VariantDataInterface
}) {
  const [changeFulfilmentStatus, setChangeFulfilmentStatus] = useState<StatusType>('idle')
  const { merchantDetails } = useMerchantService()
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { mutateSalesChannelProduct } = useSalesChannelProduct({ productId, salesChannelId })

  async function handleChangeFulfilment(shouldFulfill: boolean) {
    toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)
    setChangeFulfilmentStatus('loading')

    if (shouldFulfill) {
      window.analytics.track('Sales channels - fulfilment configured')
    } else {
      window.analytics.track('Sales channels - variant toggled off')
    }

    try {
      const newVariantData = {
        ...variantData,
        isFulfilled: shouldFulfill
      }
      await saveVariantData({
        merchantId: merchantDetails?.id ?? null,
        newVariantData,
        productId,
        salesChannelId
      })
      await mutateSalesChannelProduct()
      setChangeFulfilmentStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${variantData.id}-${errorResponse.responseBodyJson?.traceParent ?? '0'}-${
        errorResponse.status ?? '0'
      }`
      createErrorToast({
        errorCode,
        heading: 'Failed to update',
        id: SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR
      })
      setChangeFulfilmentStatus('error')
    }
  }

  return (
    <>
      <Switch
        className={className}
        checked={variantData.isFulfilled}
        disabled={changeFulfilmentStatus === 'loading'}
        onChange={handleChangeFulfilment}
      />

      {changeFulfilmentStatus === 'loading' && <OverlayPortal className="cursor-wait" />}
    </>
  )
}
