import { useEffect } from 'react'

import { ANALYTICS_PAGE_CATEGORIES, DEFAULT_DOCUMENT_TITLE } from '../../../constants'
import { SettingsPreferences } from '../../../components/Settings/components/SettingsPreferences'

export default function SettingsPreferencesPage() {
  useEffect(() => {
    document.title = 'Preferences Settings | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.SETTINGS, 'Preferences')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <SettingsPreferences />
    </main>
  )
}
