/**
• ^ asserts the start of the string.
• [...] defines a character class that includes:
  • \w which matches any alphanumeric character (letters and numbers) and underscores.
  • \s which matches any whitespace character (spaces, tabs, etc.).
  • Common punctuation marks & math symbols like .,;:!?'"(){}[\]/\\<>\-@#$%^&*±×÷=+¬~`|€£¥•
  • Currency symbols: €£¥.
  • Accented characters from \u00C0-\u00FF which covers many accented Latin characters.
• + ensures that the pattern matches one or more of the allowed characters.
• $ asserts the end of the string.
 */
export const PHOTOBOOK_SPINE_TEXT_REGEX = /^[\w\s.,;:!?'"(){}[\]/\\<>\-@#$%^&*±×÷=+¬~`|€£¥•\u00C0-\u00FF]+$/

export const PHOTOBOOK_SPINE_TEXT_MAX_LENGTH = 150

export const DEFAULT_PHOTOBOOK_META_DATA = {
  spineBackgroundColourHexCode: '#ffffff',
  spineTextColourHexCode: '#000000',
  spineText: '',
  spineTextSizePx: 16,
  spineTextFontFamily: 'Arial'
}

export const PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS = [
  {
    name: 'Arial',
    value: 'Arial'
  },
  {
    name: 'Brandon',
    value: 'Brandon'
  },
  {
    name: 'Dark Courier',
    value: 'DarkCourier'
  },
  {
    name: 'Georgia',
    value: 'Georgia'
  },
  {
    name: 'Gochi Hand',
    value: 'GochiHand'
  },
  {
    name: 'Helvetica',
    value: 'Helvetica'
  },
  {
    name: 'Tahoma',
    value: 'Tahoma'
  },
  {
    name: 'Times New Roman',
    value: 'TimesNewRoman'
  },
  {
    name: 'Verdana',
    value: 'Verdana'
  }
]

export const PHOTOBOOK_SPINE_TEXT_SIZE_OPTIONS = [
  {
    name: '4px',
    value: 4
  },
  {
    name: '5px',
    value: 5
  },
  {
    name: '6px',
    value: 6
  },
  {
    name: '7px',
    value: 7
  },
  {
    name: '8px',
    value: 8
  },
  {
    name: '9px',
    value: 9
  },
  {
    name: '10px',
    value: 10
  },
  {
    name: '11px',
    value: 11
  },
  {
    name: '12px',
    value: 12
  },
  {
    name: '13px',
    value: 13
  },
  {
    name: '14px',
    value: 14
  },
  {
    name: '15px',
    value: 15
  },
  {
    name: '16px',
    value: 16
  },
  {
    name: '17px',
    value: 17
  },
  {
    name: '18px',
    value: 18
  },
  {
    name: '19px',
    value: 19
  },
  {
    name: '20px',
    value: 20
  },
  {
    name: '21px',
    value: 21
  },
  {
    name: '22px',
    value: 22
  },
  {
    name: '23px',
    value: 23
  },
  {
    name: '24px',
    value: 24
  },
  {
    name: '25px',
    value: 25
  },
  {
    name: '26px',
    value: 26
  },
  {
    name: '27px',
    value: 27
  },
  {
    name: '28px',
    value: 28
  },
  {
    name: '29px',
    value: 29
  },
  {
    name: '30px',
    value: 30
  },
  {
    name: '31px',
    value: 31
  },
  {
    name: '32px',
    value: 32
  }
]
