// @flow

import React, { PureComponent } from 'react'
import type { Style } from '../../../types'
import classnames from 'classnames'
import WithProp from '../../hoc/WithProp'
import styles from './Flex.module.css'

type Props = {|
  children: React$Node,
  style?: Style,
  className?: string,
  alignEnd?: boolean,
  alignStart?: boolean,
  alignStretch?: boolean,
  justifyFlexStart?: boolean,
  justifyFlexEnd?: boolean,
  justifySpaceAround?: boolean,
  alignSelfEnd?: boolean,
  alignSelfStart?: boolean,
  alignCenter?: boolean,
  noWrap?: boolean,
  column?: boolean,
  justifyCenter?: boolean,
  unsetSize?: boolean
|}

class Flex extends PureComponent<Props> {
  render (): React$Node {
    const {
      children,
      alignEnd,
      alignStart,
      alignStretch,
      justifyFlexStart,
      justifyFlexEnd,
      justifySpaceAround,
      alignSelfEnd,
      alignSelfStart,
      justifyCenter,
      column,
      noWrap,
      unsetSize,
      className,
      alignCenter,
      ...restProps
    } = this.props

    const classes = classnames(
      className,
      styles.flex, {
        [styles.column]: column,
        [styles.alignEnd]: alignEnd,
        [styles.alignStart]: alignStart,
        [styles.alignCenter]: alignCenter,
        [styles.alignStretch]: alignStretch,
        [styles.justifyCenter]: justifyCenter,
        [styles.justifyStart]: justifyFlexStart,
        [styles.justifyEnd]: justifyFlexEnd,
        [styles.justifySpaceAround]: justifySpaceAround,
        [styles.alignSelfEnd]: alignSelfEnd,
        [styles.alignSelfStart]: alignSelfStart,
        [styles.noWrap]: noWrap,
        [styles.unsetSize]: unsetSize
      }
    )

    return <div className={classes} {...restProps}>{children}</div>
  }
}

export default {
  Row: Flex,
  Col: (WithProp({ column: true })(Flex): any)
}
