// @flow
import React, { PureComponent } from 'react'
import CsvItemProductView from './CsvItemProductView'
import { hasAllProductDetails } from '../../../../helpers/csvUpload'
import { filterOutEmptyElements } from '../../../../helpers/dictionary'
import { debounce } from 'lodash'
import type { RsaaStatus, FormattedOrderItem, CsvItemProductDetails } from '../../../../types'
import { LOADING } from '../../../../data/rsaa'

type Props = {|
  orderId: string,
  item: FormattedOrderItem,
  canBeEdited: boolean,
  productDetails: ?CsvItemProductDetails,
  quoteRsaaStatus: RsaaStatus,
  updateOrderItemQuantity: (quantity: number) => void,
  openProductSearchModal: (orderItemId: string) => void
|}

export default class CsvItemProductController extends PureComponent<Props> {
  openProductSearchModal: (() => void) = () => this.props.openProductSearchModal(this.props.item.id)

  onQuantityChange: ((e: SyntheticEvent<any>) => void) = (e: SyntheticEvent<*>) => {
    const newValue = parseInt(e.currentTarget.value, 10)
    this.debounceOrderItemQuantityChange(newValue)
  }

  debounceOrderItemQuantityChange: any = debounce(this.props.updateOrderItemQuantity, 500)

  get isProductComplete (): boolean {
    return this.props.productDetails
      ? hasAllProductDetails(this.props.item, this.props.productDetails)
      : false
  }

  get productCategory (): string {
    return this.props.productDetails
      ? this.props.productDetails.category
      : ''
  }

  get productDescription (): ?string {
    return this.props.productDetails
      ? this.props.productDetails.description
      : null
  }

  get selectedAttributes (): [string, string][] {
    const { selectedAttributes } = this.props.item

    return selectedAttributes
      ? filterOutEmptyElements(selectedAttributes)
      : []
  }

  get isLoading (): boolean {
    return this.props.quoteRsaaStatus.status === LOADING
  }

  render (): React$Node {
    return (
      <CsvItemProductView
        item={this.props.item}
        isLoading={this.isLoading}
        canBeEdited={this.props.canBeEdited}
        productDescription={this.productDescription}
        productCategory={this.productCategory}
        isProductComplete={this.isProductComplete}
        selectedAttributes={this.selectedAttributes}
        openProductSearchModal={this.openProductSearchModal}
        onQuantityChange={this.onQuantityChange}
      />
    )
  }
}
