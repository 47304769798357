// @flow
import { RSAA } from 'redux-api-middleware'
import { selectAuthServiceUrl } from '../../selectors/appSettings'
import type { RefreshAccessTokenRsaa } from '../types'

export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN'
export const REFRESH_ACCESS_TOKEN_SUCCESS = 'REFRESH_ACCESS_TOKEN_SUCCESS'
export const REFRESH_ACCESS_TOKEN_ERROR = 'REFRESH_ACCESS_TOKEN_ERROR'

export function refreshAccessTokenRsaa (accessToken: string, refreshToken: string): RefreshAccessTokenRsaa {
  return {
    [RSAA]: {
      endpoint: selectAuthServiceUrl() + '/api/auth/refresh',
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
      headers: { 'Content-Type': 'application/json' },
      types: [
        REFRESH_ACCESS_TOKEN,
        REFRESH_ACCESS_TOKEN_SUCCESS,
        REFRESH_ACCESS_TOKEN_ERROR
      ]
    }
  }
}
