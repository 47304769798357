// @flow
export const PRODIGI_CSV_HEADERS = [
  'Order ID',
  'Recipient Name',
  'Address 1',
  'Address 2',
  'Town',
  'State/County',
  'Country Code',
  'Postcode',
  'Shipping',
  'Sku',
  'Quantity',
  'Packing Slip',
  'attribute-wrap',
  'attribute-size',
  'attribute-framecolour',
  'attribute-mountcolour',
  'fileurl',
  'RecipientEmail',
  'RecipientPhone',
  'Order Value'
]

export const PRODIGI_SECOND_FORMAT_CSV_HEADERS = [
  'Order ID',
  'Recipient Name',
  'Address 1',
  'Address 2',
  'Town',
  'State/County',
  'Country Code',
  'Postcode',
  'Shipping',
  'Sku',
  'Quantity',
  'Packing Slip',
  'attribute-wrap',
  'attribute-framecolour',
  'attribute-mountcolour',
  'fileurl',
  'RecipientEmail',
  'RecipientPhone',
  'Order Value'
]

export const PRODIGI_CSV_HEADER_FORMATS = [
  PRODIGI_CSV_HEADERS,
  PRODIGI_SECOND_FORMAT_CSV_HEADERS
]
