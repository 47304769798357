import { useLocation, useHistory } from 'react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { useMemo, useState, useCallback, useEffect } from 'react'

import IconCross from '../../IconCross'
import { useMerchantService } from '../../../hooks'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { formatDateFilter, formatSourceFilter, formatStatusFilter } from '../helpers'
import FilterPill, { AppliedFiltersType } from '../../FilterPill/FilterPill.component'

export function OrdersListAppliedFilters() {
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersType | undefined>(undefined)

  const { merchantDetails } = useMerchantService()

  const handleStatusClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.ORDERS.STATUS)
    history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
  }, [history, searchParams])

  const handleDateClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.ORDERS.START_DATE)
    searchParams.delete(QUERY_PARAMS.ORDERS.END_DATE)
    history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
  }, [history, searchParams])

  const handleSourceClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.ORDERS.SOURCE)
    history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
  }, [history, searchParams])

  function handleClearAll() {
    searchParams.delete(QUERY_PARAMS.ORDERS.STATUS)
    searchParams.delete(QUERY_PARAMS.ORDERS.START_DATE)
    searchParams.delete(QUERY_PARAMS.ORDERS.END_DATE)
    searchParams.delete(QUERY_PARAMS.ORDERS.SOURCE)
    history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
  }

  useEffect(() => {
    const updatedAppliedFilters: AppliedFiltersType = {}
    searchParams.forEach((value, param) => {
      if (param === QUERY_PARAMS.ORDERS.STATUS) {
        updatedAppliedFilters['Status'] = { value: formatStatusFilter(value), onClearFilter: handleStatusClear }
      } else if (param === QUERY_PARAMS.ORDERS.START_DATE || param === QUERY_PARAMS.ORDERS.END_DATE) {
        updatedAppliedFilters['Date'] = {
          value: formatDateFilter({
            startDateParam: searchParams.get(QUERY_PARAMS.ORDERS.START_DATE),
            endDateParam: searchParams.get(QUERY_PARAMS.ORDERS.END_DATE)
          }),
          onClearFilter: handleDateClear
        }
      } else if (param === QUERY_PARAMS.ORDERS.SOURCE && merchantDetails?.salesChannels) {
        updatedAppliedFilters['Source'] = {
          value: formatSourceFilter(value, merchantDetails.salesChannels),
          onClearFilter: handleSourceClear
        }
      }
    })
    setAppliedFilters(updatedAppliedFilters)
  }, [handleDateClear, handleStatusClear, handleSourceClear, searchParams, merchantDetails?.salesChannels])

  if (!appliedFilters) {
    return null
  }

  const numberOfAppliedFilters = Object.keys(appliedFilters).length

  return (
    <AnimatePresence initial={false}>
      {numberOfAppliedFilters > 0 && (
        <motion.ul
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ type: 'tween' }}
          className="relative flex max-w-full gap-6 overflow-x-auto text-sm"
        >
          <AnimatePresence initial={false}>
            {Object.entries(appliedFilters).map(([name, value]) => (
              <FilterPill key={name} filterName={name} filterData={value} />
            ))}
            {numberOfAppliedFilters > 1 && (
              <motion.li
                key="clearFilters"
                className="min-w-fit"
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.button
                  whileHover={'hover'}
                  whileTap={'tap'}
                  variants={{
                    tap: {
                      scale: 0.95
                    }
                  }}
                  className="flex h-full items-center rounded-lg border border-gray-200 py-1 px-4"
                  onClick={handleClearAll}
                >
                  Clear all
                  <IconCross
                    className="ml-2 h-5 w-5"
                    variants={{
                      hover: {
                        scale: 1.25
                      },
                      tap: {
                        scale: 1
                      }
                    }}
                  />
                </motion.button>
              </motion.li>
            )}
          </AnimatePresence>
        </motion.ul>
      )}
    </AnimatePresence>
  )
}
