import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import OverlayPortal from '../../OverlayPortal'
import SelectProductModal from '../../SelectProductModal'
import { PrintAreasArtworkDataType } from '../../ImageEditor'
import { SelectedProductInterface } from '../../SelectProduct'
import { saveVariantData } from '../helpers/saveVariantData.helper'
import { VariantDataInterface } from '../SalesChannelProduct.component'
import { getNoImageProductData, isNoImageProduct } from '../../../helpers'
import { createErrorToast } from '../../Toast/helpers/createErrorToast.helper'
import { SALES_CHANANEL_PRODUCT_TOAST_IDS } from '../constants/toastIds.const'
import { useSalesChannelProduct } from '../../../hooks/useSalesChannelProduct.hook'
import { FetchErrorInterface, OMSErrorResponseInterface } from '../../../interfaces'
import { ProductDetailSuccessResponseInterface, useMerchantService } from '../../../hooks'
import { ProductAdditionalDataInterface } from '../../SelectProduct/SelectProduct.component'
import { getProductFileCompatibilityData } from '../../../helpers/getProductFileCompatibilityData.helper'

export function VariantSelectProductModal({
  countryCode,
  isSelectProductModalOpen,
  variantData,
  onSelectProductSuccess,
  onSelectProductModalOpenChange
}: {
  countryCode: string
  isSelectProductModalOpen: boolean
  variantData: VariantDataInterface
  onSelectProductSuccess: (nextStepToOpen: 'image-editor' | null) => void
  onSelectProductModalOpenChange: (isOpen: boolean) => void
}) {
  const [selectingSkuName, setSelectingSkuName] = useState<string>()
  const { merchantDetails } = useMerchantService()
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { mutateSalesChannelProduct } = useSalesChannelProduct({ productId, salesChannelId })

  useEffect(() => {
    if (!isSelectProductModalOpen) {
      setSelectingSkuName(undefined)
      toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)
    }
  }, [isSelectProductModalOpen])

  async function handleSaveSelectedProduct(
    newVariantData: VariantDataInterface,
    { openImageEditorOnSuccess }: { openImageEditorOnSuccess: boolean }
  ) {
    setSelectingSkuName(newVariantData.selectedProductData?.sku)
    toast.dismiss(SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR)

    try {
      await saveVariantData({
        merchantId: merchantDetails?.id ?? null,
        newVariantData,
        productId,
        salesChannelId
      })
      await mutateSalesChannelProduct()
      setSelectingSkuName(undefined)
      if (openImageEditorOnSuccess) {
        onSelectProductSuccess('image-editor')
      } else {
        onSelectProductSuccess(null)
      }
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${variantData.id}-${errorResponse.responseBodyJson?.traceParent ?? '0'}-${
        errorResponse.status ?? '0'
      }`
      createErrorToast({ id: SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR, errorCode, heading: 'Failed to update' })
      setSelectingSkuName(undefined)
    }
  }

  async function handleNoImageProductSelection(
    selectedProduct: SelectedProductInterface,
    {
      assetRequirementData,
      productDetails
    }: {
      assetRequirementData: Record<string, { required: boolean }>
      productDetails: ProductDetailSuccessResponseInterface
    }
  ) {
    const noImageProductData = getNoImageProductData({
      attributes: selectedProduct.selectedAttributes ?? {},
      productType: selectedProduct.searchResultData.productType,
      sku: selectedProduct.sku
    })
    const printAreas = Object.keys(productDetails.product.printAreas)
    const firstAvailablePrintArea = printAreas[0]

    const costInPounds = selectedProduct.searchResultData.basePriceFrom / 100
    const price = Number(costInPounds).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      useGrouping: true,
      currency: selectedProduct.searchResultData.currency
    })

    const newVariantData = {
      ...variantData,
      artworkData: {
        [firstAvailablePrintArea]: {
          artwork: undefined,
          artworkTransformations: undefined,
          previewImageUrl: noImageProductData?.labImage,
          printImageUrl: noImageProductData?.labImage,
          transformImageUrl: noImageProductData?.labImage
        }
      },
      assetRequirementData,
      selectedPrintArea: firstAvailablePrintArea,
      selectedProductData: {
        attributes: selectedProduct.selectedAttributes,
        productType: selectedProduct.searchResultData.productType,
        sku: selectedProduct.sku,
        price
      }
    }
    await handleSaveSelectedProduct(newVariantData, { openImageEditorOnSuccess: false })
  }

  async function handleSelectProduct(
    selectedProduct: SelectedProductInterface,
    { productDetails }: ProductAdditionalDataInterface
  ) {
    const assetRequirementData = Object.entries(productDetails.product.printAreas).reduce(
      (
        assetRequirementDataAcc: Record<string, { required: boolean }>,
        [productPrintAreaName, productPrintAreaData]
      ) => {
        return {
          ...assetRequirementDataAcc,
          [productPrintAreaName]: { required: productPrintAreaData.required }
        }
      },
      {}
    )

    if (isNoImageProduct(selectedProduct.searchResultData.productType)) {
      handleNoImageProductSelection(selectedProduct, { assetRequirementData, productDetails })
      return
    }

    const hasSkuChanged = variantData?.selectedProductData?.sku !== selectedProduct.sku
    const haveAttributesChanged = Object.entries(selectedProduct.selectedAttributes ?? {}).some(
      ([attributeName, attributeValue]) =>
        variantData?.selectedProductData?.attributes?.[attributeName] !== attributeValue
    )

    if (hasSkuChanged || haveAttributesChanged) {
      const printAreas = Object.keys(productDetails.product.printAreas)
      const firstAvailablePrintArea = printAreas[0]

      const productFileCompatibilityData = getProductFileCompatibilityData({
        productType: selectedProduct.searchResultData.productType
      })

      const newArtworkData = printAreas.reduce((updatedArtworkDataAcc: PrintAreasArtworkDataType, printAreaName) => {
        const currentArtworkData = variantData?.artworkData?.[printAreaName]?.artwork
        if (!currentArtworkData) {
          return {
            ...updatedArtworkDataAcc,
            [printAreaName]: null
          }
        }

        const isImageFileType = currentArtworkData.fileType
          ? currentArtworkData.fileType === 'png' || currentArtworkData.fileType === 'jpg'
          : true
        const isArtworkFileTypeSupportedOnNewProduct = productFileCompatibilityData
          ? productFileCompatibilityData.fileTypesAllowed.includes(currentArtworkData.fileType)
          : isImageFileType

        if (!isArtworkFileTypeSupportedOnNewProduct) {
          return {
            ...updatedArtworkDataAcc,
            [printAreaName]: null
          }
        }

        return {
          ...updatedArtworkDataAcc,
          [printAreaName]: {
            artwork: variantData?.artworkData?.[printAreaName]?.artwork,
            artworkTransformations: undefined,
            previewImageUrl: variantData?.artworkData?.[printAreaName]?.artwork?.mediumImageUrl,
            printImageUrl: variantData?.artworkData?.[printAreaName]?.artwork?.originalImageUrl,
            transformImageUrl: variantData?.artworkData?.[printAreaName]?.artwork?.originalImageUrl
          }
        }
      }, {})

      const costInPounds = selectedProduct.searchResultData.basePriceFrom / 100
      const price = Number(costInPounds).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        useGrouping: true,
        currency: selectedProduct.searchResultData.currency
      })

      const newVariantData = {
        ...variantData,
        artworkData: newArtworkData,
        assetRequirementData,
        selectedPrintArea: firstAvailablePrintArea,
        selectedProductData: {
          attributes: selectedProduct.selectedAttributes,
          productType: selectedProduct.searchResultData.productType,
          sku: selectedProduct.sku,
          price: price
        }
      }
      await handleSaveSelectedProduct(newVariantData, { openImageEditorOnSuccess: true })
      return
    }

    onSelectProductSuccess('image-editor')
  }

  return (
    <>
      <SelectProductModal
        countryCode={countryCode}
        closeOnEscape={!selectingSkuName}
        closeOnInteractionOutside={!selectingSkuName}
        defaultSearchQuery={variantData?.selectedProductData?.sku}
        defaultSelectedAttributes={variantData?.selectedProductData?.attributes ?? undefined}
        open={isSelectProductModalOpen}
        selectingSkuName={selectingSkuName}
        setOpen={onSelectProductModalOpenChange}
        showCountryPicker={false}
        onSelectProductWithAdditionalData={handleSelectProduct}
      />

      {Boolean(selectingSkuName) && <OverlayPortal />}
    </>
  )
}
