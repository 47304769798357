// @flow
import React, { Component } from 'react'
import { Redirect, matchPath } from 'react-router-dom'
import { REDIRECT } from '../../data/searchParams'
import type { ChildrenArray } from 'react'
import type { RouterHistory, Match, Location } from 'react-router'
import { findPaths } from '../../helpers/reactChildren'

type Props = {|
  match: Match,
  location: Location,
  history: RouterHistory,
  isUserLoggedIn: boolean,
  redirectPath: string,
  children: $ReadOnlyArray<ChildrenArray<*>>
|}

type State = {|
  protectedPaths: string[]
|}

export default class AuthenticatedRouteGuard extends Component<Props, State> {
  state: State = { protectedPaths: [] }

  get isCurrentPathProtected(): boolean {
    return this.state.protectedPaths.some((path) => matchPath(window.location.pathname, path))
  }

  get fullRedirectPath(): string {
    const savePreviousLocationInUrl = window.location.pathname !== '/'

    if (savePreviousLocationInUrl) {
      return (
        this.props.redirectPath +
        `?${REDIRECT}=` +
        encodeURIComponent(window.location.pathname + window.location.search)
      )
    }

    return this.props.redirectPath
  }

  get isCurrentlyOnRedirectPath(): boolean {
    return window.location.pathname === this.props.redirectPath
  }

  componentDidMount() {
    this.setProtectedPaths()
  }

  setProtectedPaths: () => void = () => {
    this.setState({ protectedPaths: findPaths(this.props.children).map((path) => path.value) })
  }

  render(): React$Element<*> | $ReadOnlyArray<ChildrenArray<*>> | null {
    if (this.isCurrentPathProtected && this.props.isUserLoggedIn) {
      return this.props.children
    } else if (this.isCurrentPathProtected) {
      return <Redirect to={this.fullRedirectPath} />
    } else {
      return null
    }
  }
}
