import { CheckIcon } from '@heroicons/react/20/solid'

import Button from '../../Button'
import { createErrorToast } from '../../Toast'
import { FetchErrorInterface } from '../../../interfaces'
import { TemplateServiceSuccessResponseInterface } from '../../../hooks'
import { ImageLibraryTransformInterface, transformImage } from '../../../helpers'
import { ArtworkInterface, ArtworkTransformationsInterface } from '../interfaces'
import { IMAGE_EDITOR_ERROR_TOAST_ID, ImageEditorSaveDataInterface } from './MultiAssetImageEditor.component'

interface ImageEditorSaveButtonPropsInterface {
  artworks: Record<string, ArtworkInterface | undefined>
  artworkTransformations: Record<string, ArtworkTransformationsInterface | undefined | null>
  isLoading: boolean
  templates: TemplateServiceSuccessResponseInterface
  onSaveStart: () => void
  onSaveError: () => void
  onSaveSuccess: (additionalData: ImageEditorSaveDataInterface) => void
}

export function ImageEditorSaveButton({
  artworks,
  artworkTransformations,
  isLoading,
  templates,
  onSaveStart,
  onSaveError,
  onSaveSuccess
}: ImageEditorSaveButtonPropsInterface) {
  async function handleSave() {
    onSaveStart()

    const artworkValuesWithData = Object.values(artworks).filter(Boolean)
    if (artworkValuesWithData.length === 0) {
      onSaveSuccess({ transformDataByPrintArea: {} })
      return
    }

    const transformationRequests: Promise<ImageLibraryTransformInterface>[] = []
    const printAreasForTransformationRequests: string[] = []

    try {
      Object.entries(artworks).forEach(([printAreaName, artworkData]) => {
        const artworkTransformationData = artworkTransformations[printAreaName]

        if (!artworkData) {
          return
        }
        if (!artworkTransformationData) {
          return
        }
        const template = templates.printAreas[printAreaName].orientations[artworkTransformationData.orientation]

        if (!template) {
          throw Error('No template')
        }

        transformationRequests.push(
          transformImage({
            artwork: artworkData,
            imageLibraryId: artworkData.imageLibraryId ?? '',
            template,
            transformations: artworkTransformationData,
            preview: false
          })
        )
        printAreasForTransformationRequests.push(printAreaName)
      })

      const transformedImagesData = await Promise.all(transformationRequests)

      const printAreasWithTransformUrls: Record<string, { transformationApiUrl: string; url: string }> = {}
      printAreasForTransformationRequests.forEach((printArea, index) => {
        printAreasWithTransformUrls[printArea] = {
          transformationApiUrl: transformedImagesData[index].transformation_api_url,
          url: transformedImagesData[index].url
        }
      })

      onSaveSuccess({ transformDataByPrintArea: printAreasWithTransformUrls })
    } catch (error) {
      console.error(error)
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>
      const imageLibraryIds = Object.values(artworks)
        .map((artwork) => artwork?.imageLibraryId)
        .join(' ')
      const errorCode = `${errorResponse.status ?? 0} ${imageLibraryIds}`
      createErrorToast({
        errorCode,
        errorMessage: errorResponse.responseBodyJson?.detail ?? errorResponse.message,
        heading: 'Failed to save image',
        id: IMAGE_EDITOR_ERROR_TOAST_ID
      })
      onSaveError()
    }
  }

  return (
    <Button
      dataTest="save-button"
      id="save-btn"
      isLoading={isLoading}
      startIcon={<CheckIcon className="hidden h-8 w-8 md:inline" />}
      className="min-w-[160px] justify-end"
      onClick={handleSave}
    >
      Save
    </Button>
  )
}
