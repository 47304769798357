import React from 'react'
import { Remark } from 'react-remark'

import { FooterContainer, FooterBlock } from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function Packaging({ productData }: { productData: ProductCatalogueProductInterface }) {
  const formattedName = productData.pluralName[0].toUpperCase() + productData.pluralName.slice(1).toLowerCase()

  const hasNoPackaging = productData.packaging?.every((p) => p.slug === 'no-packaging')
  const hasMultipleTypes = productData.packaging?.length > 1

  const regionFormatter = new Intl.ListFormat('en', { style: 'long', type: 'disjunction' })

  return (
    <FooterContainer>
      {productData.global && (
        <FooterBlock title="Fulfilment">
          <p>
            {formattedName} are part of our global print on demand range. Order using a single SKU and we automatically
            fulfil your order from the print lab nearest to your customer.
          </p>
          <p className="mt-2">Global products are greener, faster and cheaper.</p>
        </FooterBlock>
      )}

      {productData.packaging?.length > 0 && (
        <div>
          {!hasNoPackaging && (
            <FooterBlock title="White-label packaging">
              <p>
                All our shipments are white-label. There is no Prodigi branding on the outer packaging or on any
                included paperwork.
              </p>
              {hasMultipleTypes && (
                <p className="mt-4">
                  {formattedName} are shipped in a range of packaging types depending on the size/variant or where the
                  item is printed.
                </p>
              )}
              {productData.packaging?.map((p) => (
                <div className="mt-12" key={p.slug}>
                  <h4 className={hasMultipleTypes ? 'text-base' : ''}>{p.name}</h4>
                  {(productData.packaging?.length === 1 || productData.manufacturing?.regions.length === 1) && (
                    <p className="mt-0 text-xs text-gray-600">
                      When fulfilled from {regionFormatter.format(p.regions)}
                    </p>
                  )}
                  <p className="mt-4 lg:pr-12">
                    <Remark
                      rehypeReactOptions={{
                        components: {
                          p(props: JSX.IntrinsicAttributes) {
                            return <React.Fragment {...props} />
                          },
                          a(props: JSX.IntrinsicAttributes) {
                            return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                          }
                        }
                      }}
                    >
                      {p.description}
                    </Remark>
                  </p>
                  {p.images?.length > 0 && (
                    <ul className="mt-8 flex flex-wrap gap-4">
                      {p.images?.map((i) => (
                        <li className="p-1" key={i}>
                          <img className="h-56 w-56 object-contain object-center" src={i} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </FooterBlock>
          )}
        </div>
      )}
    </FooterContainer>
  )
}
