import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { createToast } from './createToast.helper'

export function createErrorToast({
  errorCode,
  errorMessage,
  heading,
  id,
  showLink = true
}: {
  errorCode?: string | number
  errorMessage?: string | React.ReactNode
  heading: string
  id: string
  showLink?: boolean
}) {
  createToast({
    content: (
      <>
        {errorMessage && <p className="mb-2">{errorMessage}</p>}
        {showLink && <p>
          Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
        </p>}
      </>
    ),
    duration: Infinity,
    footer: errorCode ? (
      <span className="text-sm text-gray-600">
        Code: {errorCode} <ClipboardCopy showText={false} text={errorCode.toString()} />
      </span>
    ) : null,
    heading,
    id,
    type: 'error-with-close'
  })
}
