// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'

import {
  CHANGE_DELIVERY_COUNTRY,
  FETCH_COUNTRIES_FAILED,
  REMOVE_BASKET_ITEM,
  ADD_BASKET_ITEM,
  FETCH_PREVIEW_TEMPLATE,
  FAILED_PREVIEW_TEMPLATE,
  INIT_IMAGE,
  SET_CURRENT_PREVIEW_ITEM
} from '../../../actions/manualOrderForm'
import { selectBasketItemById, selectBasketItems } from '../../../selectors/manualOrderForm'
import {
  FAILED_IMAGE_UPLOAD_FROM_FILE,
  FAILED_IMAGE_UPLOAD_FROM_URL,
  FINISH_IMAGE_UPLOAD_FROM_FILE,
  FINISH_IMAGE_UPLOAD_FROM_URL
} from '../../../actions/images'

export function manualOrderForm(action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case CHANGE_DELIVERY_COUNTRY: {
      return ['Delivery country changed', { deliveryCountry: action.country }]
    }

    case FETCH_COUNTRIES_FAILED: {
      return ['Fetching countries failed', { resp: { ...action.payload } }, 'error']
    }

    case ADD_BASKET_ITEM: {
      return ['Product added to basket', { ...action }]
    }

    case REMOVE_BASKET_ITEM: {
      const itemsToBeCreated = selectBasketItems(state)
      const basketItemId = action.id
      const item = itemsToBeCreated.find((item) => item.id === basketItemId)
      if (item) {
        return ['Product removed from basket', { sku: item.sku }]
      }
      return ['Product removed from basket']
    }

    case FINISH_IMAGE_UPLOAD_FROM_URL:
    case FINISH_IMAGE_UPLOAD_FROM_FILE: {
      const basketItem = selectBasketItemById(state, action.payload.itemId)
      if (basketItem && Object.keys(basketItem).length > 0) {
        const resp = {
          sku: basketItem.sku,
          image_metadata: action.payload.payload.image_metadata,
          format: action.payload.payload.format,
          height: action.payload.payload.height,
          width: action.payload.payload.width,
          url: action.payload.payload.url,
          signature: action.payload.payload.signature,
          tags: action.payload.payload.tags,
          version: action.payload.payload.version,
          type: action.payload.payload.type,
          basketItemId: action.payload.itemId
        }
        return ['Image uploaded', { data: JSON.stringify(resp) }]
      }
      return null
    }

    case FAILED_IMAGE_UPLOAD_FROM_FILE:
    case FAILED_IMAGE_UPLOAD_FROM_URL: {
      return ['Uploading image failed', { resp: { ...action.payload } }, 'error']
    }

    case FETCH_PREVIEW_TEMPLATE: {
      const basketItem = selectBasketItemById(state, action.itemId) ?? action.itemId
      return [
        'Fetching template',
        { basketItem: JSON.stringify(basketItem), sku: action.sku, attributes: JSON.stringify(action.attributes) }
      ]
    }

    case FAILED_PREVIEW_TEMPLATE: {
      const basketItem = selectBasketItemById(state, action.itemId) ?? action.itemId
      return [
        'Fetching template failed',
        { itemId: action.itemId, resp: JSON.stringify(action.errorDetails), basketItem: JSON.stringify(basketItem) },
        'error'
      ]
    }

    case INIT_IMAGE: {
      const basketItem = selectBasketItemById(state, action.itemId)
      if (basketItem && basketItem.hasOwnProperty('name') && basketItem.sku) {
        return [
          'Init image for cropping',
          { basketItem: JSON.stringify(basketItem), transformations: JSON.stringify(action.transformations) }
        ]
      }
      return ['Init image for cropping']
    }

    case SET_CURRENT_PREVIEW_ITEM: {
      return [
        'Setting current preview item',
        { sku: action.item.name, croppedUrl: action.item.croppedImageUrl, imageUrl: action.item.originalImageUrl }
      ]
    }

    default: {
      return null
    }
  }
}
