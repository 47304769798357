import clsx from 'clsx'

interface SelectFieldPropsInterface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  dataTest?: string
  uppercase?: boolean
}

export function SelectField({
  autoCapitalize = 'off',
  autoComplete = 'off',
  className = '',
  dataTest,
  disabled,
  uppercase,
  children,
  ...props
}: SelectFieldPropsInterface) {
  return (
    <select
      autoComplete={autoComplete}
      autoCapitalize={autoCapitalize}
      className={clsx(
        'mt-2 h-16 w-full cursor-pointer appearance-none self-center truncate rounded-md border border-gray-300 bg-white bg-arrow-down bg-[length:0.75em_0.75em] bg-[right_1em_center] bg-no-repeat pl-3 pr-14 text-input text-black outline-none focus:border-gray-700 disabled:cursor-not-allowed disabled:bg-gray-100 sm:text-base',
        uppercase ? 'uppercase' : '',
        className
      )}
      data-test={dataTest}
      disabled={disabled}
      {...props}
    >
      {children}
    </select>
  )
}
