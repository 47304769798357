import { formatCost } from '.'
import { ZERO_COST_SKUS } from '../constants'

export function formatPrice(sku: string, currency: string | undefined, price: number | undefined): string {
  try {
    if (isZeroCostProduct(sku, price)) {
      return 'Free'
    } else if (price && price > 0 && currency) {
      return 'From ' + formatCost({ amount: (price / 100).toString(), currencyCode: currency })
    } else {
      return ''
    }
  } catch (error) {
    return ''
  }
}

function isZeroCostProduct(sku: string, price: number | undefined): boolean {
  return (
    (!price || price === 0) &&
    Boolean(ZERO_COST_SKUS.find((zeroCostProductSku) => zeroCostProductSku.toLowerCase() === sku.toLowerCase()))
  )
}
