// @flow
import React from 'react'

import styles from './../AppSettings.module.css'
import Button from '../../../../../components/Button'

type Props = {|
  ctaText?: string,
  hasError?: boolean,
  errorMessage: string,
  isCtaVisible: boolean,
  isLoading?: boolean,
  message: string,
  onAccountCtaClick?: () => void
|}

export default class AccountView extends React.Component<Props> {
  render (): React$Node {
    const {
      ctaText = '',
      errorMessage,
      hasError = false,
      isLoading = false,
      onAccountCtaClick = () => {}
    } = this.props

    return (
      <div className={styles.appSettingsBlock}>
        <div className={styles.blockTitle}>Account &amp; Payment</div>
        <div className={styles.brandBlockTitle}>Stripe account</div>
        <div className={styles.brandBlockSubtitle}>{this.props.message}</div>
        {this.props.isCtaVisible && <>
          <Button primary className={styles.actionButton} disabled={isLoading} loading={isLoading} onClick={onAccountCtaClick}>
            {isLoading ? 'Loading' : ctaText}
          </Button>
          {hasError && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
        </>}
      </div>
    )
  }
}
