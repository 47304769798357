import { useEffect } from 'react'

import { LoadingPreview } from './LoadingPreview.component'
import { ArtworkInterface } from '../../ImageEditor/interfaces'
import { useImageLibraryThumbnailGeneration } from '../../../hooks'
import { IMAGE_LIBRARY_URL_EXPIRY_DATE_QUERY_PARAM } from '../../ImageEditor/components/ImageEditorPreview.component'

export function PdfThumbnailPreview({
  artwork,
  onArtworkError
}: {
  artwork: ArtworkInterface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onArtworkError?: (e?: CustomEvent<any> | string) => void
}) {
  const imageUrlExpiryParam = new URL(artwork.originalImageUrl).searchParams.get(
    IMAGE_LIBRARY_URL_EXPIRY_DATE_QUERY_PARAM
  )
  const imageExpiryDate = imageUrlExpiryParam ? new Date(imageUrlExpiryParam) : null
  const currentDate = new Date()
  const hasImageUrlExpired = imageExpiryDate ? currentDate > imageExpiryDate : false

  const { imageLibraryImage, imageLibraryImageFetchError, isLoadingImageLibraryImage } =
    useImageLibraryThumbnailGeneration(hasImageUrlExpired ? artwork.imageLibraryId : undefined, {
      touch: false,
      config: { revalidateOnFocus: false }
    })

  useEffect(() => {
    if (imageLibraryImageFetchError) {
      onArtworkError?.(`${imageLibraryImageFetchError.status ?? '0'}-${artwork.imageLibraryId ?? '0'}`)
    }
  }, [artwork.imageLibraryId, imageLibraryImageFetchError, onArtworkError])

  if (isLoadingImageLibraryImage) {
    return <LoadingPreview />
  }

  if (imageLibraryImageFetchError) {
    return null
  }

  return (
    <>
      <div className="grid h-[160px] w-[160px] place-content-center">
        <img
          loading="lazy"
          src={imageLibraryImage?.thumbnail_urls?.small ?? artwork.smallImageUrl}
          alt="preview"
          className="h-48 w-48 object-contain object-top md:h-64 md:w-64"
          data-test="line-item-image"
        />
      </div>
    </>
  )
}
