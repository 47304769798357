// @flow
import type { DispatchFunc, Dictionary, FormattedOrderItem, Thunk } from '../../types'
import { getV4ProductDetails } from '../catalogue'
import { values } from '../../helpers/dictionary'
import { removeDuplicates } from '../../helpers/array'

export function getV4ProductDetailsForCsvOrderItems (orderItems: Dictionary<FormattedOrderItem>): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const allSkus = removeDuplicates(values(orderItems).map(item => item.sku))

    const productDetailPromises = allSkus.map(sku => dispatch(getV4ProductDetails(sku)))

    return Promise.all(productDetailPromises)
  }
}
