import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from '../../../constants'

export function ProductInfoHeader() {
  return (
    <header className="flex justify-between gap-x-10 lg:gap-x-28">
      <div className="flex max-w-4xl flex-col">
        <h1 className="mt-0 mr-auto">Product information</h1>
        <p className="mt-8 lg:mt-16">
          Explore our range of customisable products, and get exact pricing and shipping to any destination country.
        </p>
        <p className="mt-4">
          From prints and posters; to customer printed t-shirts and phone cases we&apos;ve got the perfect product to
          help your business succeed.
        </p>

        <Link
          className="btn btn-primary my-12 w-fit"
          to={ROUTE_PATHS.PRODUCTS}
          onClick={() => window.analytics.track('Clicked - browse products')}
        >
          Browse products
        </Link>
      </div>

      <img
        alt="Print on demand products"
        className="hidden h-[270px] w-[270px] object-contain object-center sm:inline"
        src="/img/v2/product-info/hero-image.jpg"
      />
    </header>
  )
}
