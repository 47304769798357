import { PositionUnitConverter } from '@prodigi-group/components-image-editor'

import { CropRectangleInterface } from '../../../hooks'
import { PointInterface, LengthUnitType, DimensionsInterface } from '../interfaces'

export function convertPrintPxPositionToUserUnit(
  positionInPrintPx: PointInterface,
  userUnit: LengthUnitType,
  printDimensionsInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  cropRectangle: CropRectangleInterface,
  printDpi: number
): PointInterface {
  const converter = new PositionUnitConverter(printDimensionsInPx, artworkDimensionsInPx, cropRectangle, printDpi)

  const cropRectanglePosition = converter.printPxToCropRectanglePosition(positionInPrintPx)

  return converter.cropRectanglePositionToPositionUnit(cropRectanglePosition, userUnit)
}
