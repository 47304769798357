import ImageLibrary from '../ImageLibrary'
import Modal, { ModalPropsInterface } from '../Modal'
import { ImageLibraryPropsInterface } from '../ImageLibrary/ImageLibrary.component'

interface ImageLibraryModalPropsInterface extends Omit<ModalPropsInterface, 'children'>, ImageLibraryPropsInterface {}

export default function ImageLibraryModal({ config, type, onSelectImage, ...props }: ImageLibraryModalPropsInterface) {
  return (
    <Modal className="w-90vw max-w-[1400px]" title="Select image" {...props} closeOnEscape={false}>
      <div className="h-80vh overflow-y-auto" data-test="image-library-modal-container">
        <ImageLibrary config={config} type={type} onSelectImage={onSelectImage} />
      </div>
    </Modal>
  )
}
