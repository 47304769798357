import Button from '../../Button'
import { createToast } from '../../Toast'
import { VoteForPlatformEnum } from '../enums'
import { APP_ENVIRONMENTS } from '../../../constants'
import { VoteForPlatformInterface } from '../interfaces'

export function VoteForPlatformCard({ platform }: { platform: VoteForPlatformInterface }) {
  function voteForChannel() {
    window.analytics.track('Viewed: sales channel vote toast gather', {
      platformName: platform.name
    })
    createToast({
      content: 'Thanks for your interest',
      duration: 4000,
      heading: 'Your request has been successfully received',
      id: 'salesChannelVoteForPlatformSuccessToastId',
      type: 'success'
    })
  }

  return (
    <>
      <li className="flex w-full flex-col border border-gray-200 bg-white p-10">
        <div>
          <PlatformLogoFactory platformName={platform.name} />
        </div>

        <p className="mt-8 mb-16 max-w-lg">{platform.description}</p>

        <Button className="whitespace w-fit" variant="secondary" onClick={voteForChannel}>
          {platform.ctaButtonText}
        </Button>
      </li>
    </>
  )
}

function PlatformLogoFactory({ platformName }: { platformName: VoteForPlatformEnum }) {
  switch (platformName) {
    case VoteForPlatformEnum.Amazon:
      return (
        <img
          alt="Amazon logo"
          src="/img/logo/amazon-logo-orange.png"
          className="-ml-4 block h-16"
          style={{ transform: 'scale(0.8)' }}
        />
      )
    case VoteForPlatformEnum.eBay:
      return (
        <img
          alt="eBay logo"
          src="/img/logo/EBay_logo.svg"
          className="-ml-4 block h-16"
          style={{ transform: 'scale(0.8)' }}
        />
      )
    case VoteForPlatformEnum.TikTok:
      return (
        <img
          alt="TikTok logo"
          src="/img/logo/TikTok-logo.png"
          className="ml-3 block h-16"
          style={{ transform: 'scale(1.5)' }}
        />
      )
    case VoteForPlatformEnum.EKM:
      return (
        <img
          alt="EKM logo"
          src="/img/logo/ekm-logo-SVG.svg"
          className="-ml-2 block h-16"
          style={{ transform: 'scale(0.9)' }}
        />
      )
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
