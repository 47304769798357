// @flow
import ProductSearch from './ProductSearch'
import { connect } from 'react-redux'
import { searchProducts, selectItemCategory, selectProductType, clearProductSearch } from '../../../../actions/catalogue'
import { getProductSearchProps } from '../../../../selectors/catalogue'
import type { AppState, DispatchFunc, ThunkAsync, ProductSearchStateProps } from '../../../../types'
import type { ClearProductSearch } from '../../../../actions/types'
import { pushRedirect } from '../../../../actions/router'
import type { ComponentType } from 'react'

type DispatchProps = {|
  search: (query: string) => ThunkAsync<void>,
  selectProductType: (productType: string) => ThunkAsync<void>,
  selectItemCategory: (category: string) => ThunkAsync<void>,
  clearProductSearch: () => ClearProductSearch,
  redirect: (pathname: string) => void
|}

function mapStateToProps (state: AppState): ProductSearchStateProps {
  return getProductSearchProps(state)
}

function mapDispatchToProps (dispatch: DispatchFunc): DispatchProps {
  return {
    search: (query: string) => dispatch(searchProducts(query)),
    selectProductType: (productType: string) => dispatch(selectProductType(productType)),
    selectItemCategory: (category: string) => dispatch(selectItemCategory(category)),
    clearProductSearch: () => dispatch(clearProductSearch()),
    redirect: (pathname: string) => {
      dispatch(pushRedirect(pathname))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ProductSearch): ComponentType<{||}>)
