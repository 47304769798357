// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function TickMark ({ className }: Props): Element<"svg"> {
  return (
    <svg
      className={className}
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70711 9.29285L15 -4.57764e-05L16.4142 1.41417L5.70711 12.1213L0 6.41417L1.41421 4.99995L5.70711 9.29285Z"
      />
    </svg>
  )
}
