import { useOs } from '@mantine/hooks'
import { useDebouncedCallback } from 'use-debounce'
import { useEffect, useMemo, useState } from 'react'

import SearchField from '../../SearchField'
import { HOTKEYS } from '../../../constants/hotkeys.const'
import { useHistory, useLocation } from 'react-router-dom'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function OrdersListActionSearch() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchParam = searchParams.get(QUERY_PARAMS.ORDERS.SEARCH)
  const searchValueFromParam = useMemo(() => searchParam ?? '', [searchParam])

  const [searchValue, setSearchValue] = useState<string>(searchValueFromParam)

  const OS = useOs()
  const isAppleOs = OS === 'macos' || OS === 'ios'
  const searchHotkeyDisplay = isAppleOs ? HOTKEYS.ORDERS.SEARCH.DISPLAY.APPLE : HOTKEYS.ORDERS.SEARCH.DISPLAY.DEFAULT

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSearchValue(searchValueFromParam)
  }, [searchValueFromParam, searchParams])

  const debouncedSearch = useDebouncedCallback(async (searchValue: string | null) => {
    const searchTooSmall = searchValue && searchValue.trim().length < 3
    if (!searchValue || searchTooSmall) {
      searchParams.delete(QUERY_PARAMS.ORDERS.SEARCH)
      history.push(`${ROUTE_PATHS.ORDERS}?${searchParams.toString()}`)
      if (searchTooSmall) {
        setSearchValue(searchValue)
      }
      return
    }
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.ORDERS.SEARCH, searchValue)
    const newOrdersUrl = `${ROUTE_PATHS.ORDERS}?${newSearchParams.toString()}`
    history.push(newOrdersUrl)
  }, 500)

  function handleSearch(value: string) {
    setSearchValue(value)
    debouncedSearch(value)
  }

  return (
    <span className="w-full" data-test="orders-list-actions__search">
      <div className="relative isolate w-full">
        <SearchField
          hotkey={HOTKEYS.ORDERS.SEARCH.TRIGGER}
          hotkeyDisplay={searchHotkeyDisplay}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => {
            const newValue = event.target.value
            handleSearch(newValue)
          }}
          onClearSearch={() => handleSearch('')}
        />
      </div>
    </span>
  )
}
