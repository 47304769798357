import { nanoid } from 'nanoid'
import { useState } from 'react'
import toast from 'react-hot-toast'

import {
  BrandingDataInterface,
  MerchantBrandingType,
  useMerchantBranding,
  useMerchantService
} from '../../../../../hooks'
import Button from '../../../../Button'
import { BRANDING_SETTINGS_TOAST_ID } from '../constants'
import { createErrorToast, createToast } from '../../../../Toast'
import { BrandedInsertsSetModal } from '../../../../BrandedInsertSetModal'
import { updateMerchantBranding } from '../helpers/updateMerchantBranding.helper'
import { FetchErrorInterface, InsertSetType, StatusType } from '../../../../../interfaces'

export function AddNewInsertSet({
  brandingDetails,
  merchantBranding
}: {
  brandingDetails: BrandingDataInterface
  merchantBranding: MerchantBrandingType
}) {
  const { merchantDetails } = useMerchantService()
  const { mutateMerchantBranding } = useMerchantBranding()

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isBrandedInsertsModalOpen, setIsBrandedInsertsModalOpen] = useState(false)

  async function handleAddNewInsertSet(newInsertSetData: Omit<InsertSetType, 'id'>) {
    toast.dismiss(BRANDING_SETTINGS_TOAST_ID)
    setSaveStatus('loading')

    try {
      const newInsertSetId = nanoid()
      const insertSetsWithNewSet = [...merchantBranding.insertSets, { ...newInsertSetData, id: newInsertSetId }]

      const newInsertSets: InsertSetType[] = insertSetsWithNewSet.map((insertSet) => {
        if (insertSet.id === newInsertSetId) {
          return insertSet
        }

        return {
          ...insertSet,
          salesChannels: insertSet.salesChannels.filter((insertSetSalesChannel) => {
            const isSalesChannelIncludedInUpdatedInsertSet = newInsertSetData.salesChannels.some(
              (newInsertSetDataSalesChannel) => newInsertSetDataSalesChannel.id === insertSetSalesChannel.id
            )
            return !isSalesChannelIncludedInUpdatedInsertSet
          })
        }
      })

      await updateMerchantBranding({
        merchantId: merchantDetails?.id,
        newMerchantBranding: { ...merchantBranding, insertSets: newInsertSets }
      })
      await mutateMerchantBranding()
      setSaveStatus('success')
      handleOpenChange(false)
      createToast({ type: 'success', heading: 'Insert set created' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ traceParent?: string }>
      createErrorToast({
        errorCode: `${errorResponse.responseBodyJson?.traceParent ?? errorResponse.message ?? '0'}-${
          errorResponse.status ?? '0'
        }`,
        heading: 'Failed to create insert set',
        id: BRANDING_SETTINGS_TOAST_ID
      })
      setSaveStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setSaveStatus('idle')
      toast.dismiss(BRANDING_SETTINGS_TOAST_ID)
    }
    setIsBrandedInsertsModalOpen(isOpen)
  }

  return (
    <div>
      <Button onClick={() => handleOpenChange(true)}>Add insert set</Button>

      <BrandedInsertsSetModal
        brandingDetails={brandingDetails}
        saveStatus={saveStatus}
        open={isBrandedInsertsModalOpen}
        setOpen={handleOpenChange}
        onSave={handleAddNewInsertSet}
        onCancel={() => handleOpenChange(false)}
      />
    </div>
  )
}
