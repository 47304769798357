import { useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory, useParams } from 'react-router-dom'
import { NoSymbolIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import { createToast } from '../../Toast'
import { fetcher } from '../../../helpers'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { ROUTE_PATHS } from '../../../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../constants'
import { InlineMenuOptionInterface } from '../../InlineMenu'
import SingleSelectDropdown from '../../SingleSelectDropdown'
import { useMerchantService, useSalesChannelProducts } from '../../../hooks'
import { FetchErrorInterface, OMSResponseInterface } from '../../../interfaces'
import { useSalesChannelProductsOverview } from '../../SalesChannels/hooks'

type ActionValueType = 'fulfill-all' | 'ignore-all'

interface ActionOptionInterface extends InlineMenuOptionInterface {
  value: ActionValueType
}

const ACTION_OPTIONS: ActionOptionInterface[] = [
  {
    content: (
      <span className="flex items-center pr-6">
        <CheckIcon className="mr-2 h-7 w-7" /> Fulfil all products
      </span>
    ),
    searchLabel: 'Fulfil all products',
    value: 'fulfill-all'
  },
  {
    content: (
      <span className="flex items-center pr-6">
        <NoSymbolIcon className="mr-2 h-7 w-7" /> Ignore all products
      </span>
    ),
    searchLabel: 'Ignore all products',
    value: 'ignore-all'
  }
]

export function ProductActions() {
  const [actionModalToOpen, setActionModalToOpen] = useState<ActionValueType | null>(null)

  return (
    <>
      <SingleSelectDropdown
        align="end"
        dropdownName="Actions"
        triggerContent={
          <>
            <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
              <ChevronDownIcon className="h-7 w-7 text-gray-500" />
            </span>
            <span className="ml-8 select-none" data-test="singleselect-dropdown__button-text">
              Actions
            </span>
          </>
        }
        options={ACTION_OPTIONS}
        updateSelected={(value) => {
          setActionModalToOpen(value as ActionValueType)
        }}
      />

      <FulfillAllProductsModal actionModalToOpen={actionModalToOpen} setActionModalToOpen={setActionModalToOpen} />
      <IgnoreAllProductsModal actionModalToOpen={actionModalToOpen} setActionModalToOpen={setActionModalToOpen} />
    </>
  )
}

function FulfillAllProductsModal({
  actionModalToOpen,
  setActionModalToOpen
}: {
  actionModalToOpen: ActionValueType | null
  setActionModalToOpen: (isOpen: ActionValueType | null) => void
}) {
  const history = useHistory()
  const { salesChannelId } = useParams<{ salesChannelId: string }>()
  const { merchantDetails } = useMerchantService()
  const { clearPagesCache, mutateSalesChannelProducts, setSalesChannelProductPagesToLoadSize } =
    useSalesChannelProducts(salesChannelId)
  const { mutateSalesChannelProductsOverview } = useSalesChannelProductsOverview({ salesChannelId })

  const [fulfillAllProductsStatus, setFulfillAllProductsStatus] = useState(StatusEnum.Idle)

  async function handleFulfillAllProducts() {
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    setFulfillAllProductsStatus(StatusEnum.Loading)

    try {
      await fetcher(
        `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/merchants/${merchantDetails?.id}/saleschannel/${salesChannelId}/products`,
        {
          body: JSON.stringify({
            FulfillAllProducts: true
          }),
          method: 'PUT'
        }
      )
      history.push(ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(salesChannelId))
      window.scroll(0, 0)
      clearPagesCache()
      setSalesChannelProductPagesToLoadSize(1)
      await mutateSalesChannelProducts()
      mutateSalesChannelProductsOverview()
      createToast({ heading: 'Updated successfully', id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS, type: 'success' })
      setFulfillAllProductsStatus(StatusEnum.Success)
      handleOpenChange(false)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSResponseInterface>
      handleError(errorResponse)
      setFulfillAllProductsStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (isOpen) {
      return
    }
    setFulfillAllProductsStatus(StatusEnum.Idle)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    setActionModalToOpen(null)
  }

  const isFulfillRequestLoading = fulfillAllProductsStatus === StatusEnum.Loading
  const salesChannel = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === salesChannelId)

  return (
    <>
      <ConfirmationModal
        continueButton={{ text: 'Fulfil all products' }}
        closeButton={{ text: 'Cancel' }}
        closeOnEscape={!isFulfillRequestLoading}
        closeOnInteractionOutside={!isFulfillRequestLoading}
        isLoading={isFulfillRequestLoading}
        open={actionModalToOpen === 'fulfill-all'}
        title="Fulfil all products with Prodigi?"
        onCancel={() => handleOpenChange(false)}
        onContinue={handleFulfillAllProducts}
        setOpen={handleOpenChange}
      >
        Prodigi will automatically receive all orders for all items in your store, including any new items that you add{' '}
        {salesChannel ? `in ${salesChannel.platform}` : ''}
      </ConfirmationModal>

      {isFulfillRequestLoading && <OverlayPortal />}
    </>
  )
}

function IgnoreAllProductsModal({
  actionModalToOpen,
  setActionModalToOpen
}: {
  actionModalToOpen: ActionValueType | null
  setActionModalToOpen: (isOpen: ActionValueType | null) => void
}) {
  const history = useHistory()
  const { salesChannelId } = useParams<{ salesChannelId: string }>()
  const { merchantDetails } = useMerchantService()
  const { clearPagesCache, mutateSalesChannelProducts, setSalesChannelProductPagesToLoadSize } =
    useSalesChannelProducts(salesChannelId)
  const { mutateSalesChannelProductsOverview } = useSalesChannelProductsOverview({ salesChannelId })

  const [ignoreAllProductsStatus, setIgnoreAllProductsStatus] = useState(StatusEnum.Idle)

  async function handleIgnoreAllProducts() {
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    setIgnoreAllProductsStatus(StatusEnum.Loading)

    try {
      await fetcher(
        `${process.env.REACT_APP_OMS_PRODUCT_SERVICE}/merchants/${merchantDetails?.id}/saleschannel/${salesChannelId}/products`,
        {
          body: JSON.stringify({
            FulfillAllProducts: false
          }),
          method: 'PUT'
        }
      )

      history.push(ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(salesChannelId))
      window.scroll(0, 0)
      clearPagesCache()
      setSalesChannelProductPagesToLoadSize(1)
      await mutateSalesChannelProducts()
      mutateSalesChannelProductsOverview()
      createToast({ heading: 'Updated successfully', id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS, type: 'success' })
      setIgnoreAllProductsStatus(StatusEnum.Success)
      handleOpenChange(false)
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSResponseInterface>
      handleError(errorResponse)
      setIgnoreAllProductsStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (isOpen) {
      return
    }
    setIgnoreAllProductsStatus(StatusEnum.Idle)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    setActionModalToOpen(null)
  }

  const isIgnoreRequestLoading = ignoreAllProductsStatus === StatusEnum.Loading

  return (
    <>
      <ConfirmationModal
        continueButton={{ text: 'Turn off all fulfilment' }}
        closeButton={{ text: 'Cancel' }}
        closeOnEscape={!isIgnoreRequestLoading}
        closeOnInteractionOutside={!isIgnoreRequestLoading}
        isLoading={isIgnoreRequestLoading}
        open={actionModalToOpen === 'ignore-all'}
        title="Ignore all products?"
        onCancel={() => handleOpenChange(false)}
        onContinue={handleIgnoreAllProducts}
        setOpen={handleOpenChange}
      >
        Prodigi will no longer receive orders automatically from this store. Any orders currently in production will be
        unaffected.
      </ConfirmationModal>

      {isIgnoreRequestLoading && <OverlayPortal />}
    </>
  )
}

function handleError(errorResponse: FetchErrorInterface<OMSResponseInterface>) {
  createToast({
    content: 'Please try again later',
    duration: Infinity,
    footer: `${errorResponse.responseBodyJson?.outcome ?? errorResponse.message} (Code ${
      errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
    })`,
    heading: 'Failed to update',
    id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
    type: 'error-with-close'
  })
}
