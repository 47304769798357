// @flow
import { RSAA } from 'redux-api-middleware'
import Countries from 'country-list'
import type { DispatchFunc, ThunkAsync, UserDetails } from '../../../../types'
import { selectPwintyApiSettings } from '../../../../selectors/appSettings'
import { logIn } from '../../../../actions/auth'
import { LOG_STATUS } from '../../../../data/rsaa'

import { resetBeamerConfig } from '../../../helpers'
import {
  REQUEST_REGISTER_USER,
  RECEIVE_REGISTER_USER_RESPONSE,
  REGISTER_USER_ERROR
} from '../../../constants/registerUser.const'

interface MarketingDataType {
  marketingPriorityToday: string | undefined
  marketingWhyCreatingAccount: string | undefined
}

export function registerUser(
  userDetails: UserDetails,
  marketingData: MarketingDataType = { marketingPriorityToday: undefined, marketingWhyCreatingAccount: undefined }
): ThunkAsync<void> {
  return async (dispatch: DispatchFunc) => {
    const endpoint = selectPwintyApiSettings().endpoint
    const countryCode = Countries().getCode(userDetails.countryName)

    let body = Object.keys(userDetails)
      .map((key) => `${key}=${encodeURIComponent(userDetails[key])}`)
      .join('&')

    if (countryCode) {
      body += `&countryCode=${encodeURIComponent(countryCode)}`
    }

    if (marketingData.marketingPriorityToday) {
      body += `&marketingPriorityToday=${encodeURIComponent(marketingData.marketingPriorityToday)}`
    }

    if (marketingData.marketingWhyCreatingAccount) {
      body += `&marketingWhyCreatingAccount=${encodeURIComponent(marketingData.marketingWhyCreatingAccount)}`
    }

    const response = await dispatch({
      [RSAA]: {
        endpoint: endpoint + `/api/Account/Register`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        types: [
          { type: REQUEST_REGISTER_USER, meta: { enhancements: [LOG_STATUS] } },
          RECEIVE_REGISTER_USER_RESPONSE,
          {
            type: REGISTER_USER_ERROR,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            payload: async (_: any, _2: any, response: { json: () => any }) => {
              const jsonResponse = await response.json()

              if (jsonResponse.statusTxt.includes('is already taken')) {
                return { message: 'is already taken' }
              } else {
                return { message: jsonResponse.statusTxt }
              }
            }
          }
        ]
      }
    })

    if (response.type === RECEIVE_REGISTER_USER_RESPONSE) {
      resetBeamerConfig()
      window.beamer_config = {
        ...window.beamer_config,
        register_priority: marketingData.marketingPriorityToday,
        register_reason: marketingData.marketingWhyCreatingAccount,
        signed_up_at: new Date().toISOString()
      }
      window.analytics.track('User Registered')
      dispatch(logIn(userDetails.email, userDetails.password))
    }
  }
}
