// @flow
import React from 'react'
import cx from 'classnames'
import type { Element } from 'react'

import CsvFilterOrders from '../CsvFilterOrders'
import styles from './CsvUploadOverview.module.css'
import CsvSubmissionError from '../CsvSubmission/CsvSubmissionError'

type Props = {|
  totalOrders: number,
  submitCsvMsg: string,
  selectedFilter: string,
  submitCsvError: boolean,
  multipleOrders: boolean,
  submitCsvLoading: boolean,
  totalIgnoredOrders: number,
  submittedOrderCount: number,
  numberOfOrdersWithErrors: number,
  isAnyCsvOrderForUnitedStates: boolean,
  numberOfOrdersRequiringAction: number,
  isUsSalesTaxCollectedForAllUsOrders: boolean,
  toggleUsSalesTax: () => void,
  changeFilter: (newFilter: string) => void
|}

export default function CsvDescription (props: Props): Element<"div"> {
  const {
    totalOrders,
    totalIgnoredOrders,
    multipleOrders,
    submitCsvLoading,
    submitCsvError,
    toggleUsSalesTax,
    isAnyCsvOrderForUnitedStates
  } = props
  const messageStyle = cx(styles.message, styles.messagePadding)

  if (submitCsvLoading) {
    return (
      <div className={styles.message}>
        Order submission in progress: <strong className={styles.total}>{totalOrders - totalIgnoredOrders}</strong> order{multipleOrders ? 's' : ''} left.
      </div>
    )
  }
  return (
    <div className={styles.csvDescription}>
      {submitCsvError
        ? <CsvSubmissionError className={messageStyle} message={props.submitCsvMsg} submittedOrderCount={props.submittedOrderCount} />
        : (
          <div className={messageStyle} data-test='csv-description'>
            We&apos;ve detected <strong className={styles.total}>{totalOrders}</strong> order{multipleOrders ? 's' : ''} for import. Please review the details below to confirm they are correct before submitting.

            {isAnyCsvOrderForUnitedStates &&
              <div className={styles.salesTaxInfo}>
                <div className={styles.salesTaxDescription}>
                You have one or more orders being sent to the <b>United States</b>.
                These orders may be subject to US sales tax, unless it is from a marketplace (such as Etsy) that has already collected tax.
                </div>
                <div className={styles.salesTaxToggleContainer}>
                  <input
                    id='sales-tax-toggle'
                    data-test='sales-tax-toggle'
                    type='checkbox'
                    onChange={toggleUsSalesTax}
                    className={styles.salesTaxToggle}
                    checked={props.isUsSalesTaxCollectedForAllUsOrders}
                  />
                  <label htmlFor='sales-tax-toggle' className={styles.salesTaxLabel}>
                    I declare that sales tax has already been collected on these orders.
                  </label>
                </div>
              </div>
            }
          </div>
        )}
      <CsvFilterOrders
        numberOfOrdersWithErrors={props.numberOfOrdersWithErrors}
        numberOfOrdersRequiringAction={props.numberOfOrdersRequiringAction}
        submitCsvError={submitCsvError}
        submitCsvLoading={submitCsvLoading}
        totalOrders={totalOrders}
        totalIgnoredOrders={totalIgnoredOrders}
        changeFilter={props.changeFilter}
        selectedFilter={props.selectedFilter}
      />
    </div>
  )
}
