import { CompositeItem, CompositeStateReturn } from 'reakit/Composite'
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook'

import { EmptyState } from '.'
import Skeleton from '../../Skeleton'
import SupportLink from '../../SupportLink'
import {
  ConditionalPropsType,
  InlineMenuWithInfiniteScrollOptionInterface
} from '../InlineMenuWithInfiniteScroll.component'
import AnimatedCheckIcon from '../../AnimatedCheckIcon'

interface MenuContentProps {
  compositeProps: CompositeStateReturn
  fetchError?: {
    status: number | undefined
  }
  isLoadingFirstSetOfData: boolean
  itemsToloadSize: number
  optionPages: InlineMenuWithInfiniteScrollOptionInterface[][]
  pageSize: number
  queryParam: string
  searchingFor: string
  onTriggerScroll: () => void
  paginationTriggerRef: IntersectionObserverHookRefCallback
}

type InlineMenuWithInfiniteScrollPropsType = MenuContentProps & ConditionalPropsType

export default function InlineMenuWithInfiniteScrollContent({
  compositeProps,
  fetchError,
  isLoadingFirstSetOfData,
  itemsToloadSize,
  multiple,
  optionPages,
  pageSize,
  paginationTriggerRef,
  queryParam,
  searchingFor,
  selected,
  updateSelected,
  onTriggerScroll
}: InlineMenuWithInfiniteScrollPropsType) {
  const loadedPagesSize = optionPages.length
  const hasNextPage = optionPages[loadedPagesSize - 1].length > 0
  const isLoadingNextPage = hasNextPage && loadedPagesSize < itemsToloadSize

  const showNextLoadingSkeletons = !fetchError && (isLoadingNextPage || hasNextPage)
  const hasLoadedAllPages = optionPages.length > 2 && !hasNextPage && !isLoadingNextPage

  function onItemFocus({ index, pageIndex }: { index: number; pageIndex: number }) {
    const indexOffset = pageIndex * pageSize
    const maximumIndex = pageSize * itemsToloadSize
    const itemIndexInOptions = index + indexOffset + 1

    if (hasNextPage && !isLoadingNextPage && itemIndexInOptions === maximumIndex) {
      onTriggerScroll()
    }
  }

  function handleSelection({ value, isValueSelected }: { value: string; isValueSelected: boolean }) {
    if (!multiple) {
      updateSelected?.(value)
      return
    }

    let updatedSelectedValues: string[] = []
    if (!isValueSelected) {
      updatedSelectedValues = [...selected, value]
    } else {
      updatedSelectedValues = selected.filter((selectedValue) => selectedValue !== value)
    }
    updateSelected?.(updatedSelectedValues)
  }

  if (fetchError && itemsToloadSize <= 1) {
    return (
      <div className="mx-auto py-6 pl-5 pr-4 text-red-500">
        An error occurred while loading. Please try again later or{' '}
        <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists (Code{' '}
        {fetchError.status ?? '0'})
      </div>
    )
  }

  if (isLoadingFirstSetOfData) {
    return <LoadingSkeletons paginationTriggerRef={paginationTriggerRef} />
  }

  if (optionPages[0].length === 0) {
    return <EmptyState searchingFor={searchingFor} searchParam={queryParam} />
  }

  return (
    <div className="pb-4">
      {optionPages.map((page, index) => {
        const pageIndex = index
        return page.map((option, index) => {
          let isValueSelected = false
          if (multiple) {
            isValueSelected = selected.includes(option.value)
          } else {
            isValueSelected = option.value === selected
          }

          return (
            <CompositeItem
              {...compositeProps}
              aria-selected={isValueSelected}
              as="li"
              className={`group relative flex cursor-pointer select-none break-words py-2 pl-14 transition-colors duration-200 ease-in hover:bg-gray-100 hover:duration-100 hover:ease-out focus:outline-none focus-visible:bg-[hsl(0deg,0%,90%)] focus-visible:duration-100 focus-visible:ease-out ${
                option.disabled ? 'opacity-60' : ''
              }`}
              data-test="inline-menu-option"
              data-test-value={`inline-menu-option-${option.value}`}
              disabled={option.disabled}
              id={option.searchLabel}
              key={option.value + index}
              role="option"
              onFocus={() => onItemFocus({ index, pageIndex })}
              onClick={() => handleSelection({ value: option.value, isValueSelected })}
            >
              <span className="absolute inset-0 flex items-center pl-5 pr-2">
                <AnimatedCheckIcon dataTest="inline-menu-option-selected" isVisible={isValueSelected} />
              </span>
              {option.content}
            </CompositeItem>
          )
        })
      })}

      {itemsToloadSize > 1 && fetchError && (
        <div className="mx-auto pl-5 pt-6 pr-4 text-red-500">
          An error occurred while loading the next page. Please try again later or{' '}
          <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists (Code{' '}
          {fetchError.status ?? '0'})
        </div>
      )}

      {hasLoadedAllPages && (
        <div className="mx-auto max-w-[250px] pl-5 pr-4 pt-6 text-center text-gray-600">All loaded</div>
      )}

      {showNextLoadingSkeletons && <LoadingSkeletons paginationTriggerRef={paginationTriggerRef} />}
    </div>
  )
}

function LoadingSkeletons({ paginationTriggerRef }: { paginationTriggerRef: IntersectionObserverHookRefCallback }) {
  return (
    <div className="pl-10">
      {Array.from({ length: 2 }, (_, i) => i).map((value) => (
        <div className="py-3 px-4" key={value} ref={paginationTriggerRef}>
          <Skeleton className="h-[21px]" />
        </div>
      ))}
    </div>
  )
}
