import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StatusType } from '../../../../v2/interfaces'
import {
  PACKING_SLIP_ALLOWED_FILE_TYPES,
  PackingSlipDetails,
  PackingSlipLoading,
  UploadPackingSlip
} from '../../../../v2/components/PackingSlip/components'
import { createToast } from '../../../../v2/components/Toast'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import OverlayPortal from '../../../../v2/components/OverlayPortal'
import { getPackingSlip } from '../../../../selectors/manualOrderForm'
import { ImageLibraryImageInterface, useSplitToggle } from '../../../../v2/hooks'
import { removePackingSlip, uploadPackingSlipV2 } from '../../../../actions/packingSlip'

const PACKING_SLIP_UPLOAD_ERROR_TOAST_ID = 'packingSlipUploadErrorToast'

export function PackingSlipUploadV2({
  updatePackingSlipStatus,
  setUpdatePackingSlipStatus
}: {
  updatePackingSlipStatus: 'idle' | 'loading' | 'success' | 'error'
  setUpdatePackingSlipStatus: (status: 'idle' | 'loading' | 'success' | 'error') => void
}) {
  const dispatch = useDispatch()
  const packingSlip: string = useSelector((state) => getPackingSlip(state)?.url)
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    }
  }, [])

  async function handleUploadPackingUpdate(imageLibraryImage: ImageLibraryImageInterface) {
    setIsImageLibraryModalOpen(false)
    toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    setUpdatePackingSlipStatus('loading')

    if (isPDFSplitOn && !PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      createToast({
        content: `${
          imageLibraryImage.file_type
        } is not supported for packing slips. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: PACKING_SLIP_UPLOAD_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setUpdatePackingSlipStatus('error')
      return
    }

    if (!isPDFSplitOn && imageLibraryImage.mime_type !== 'image/png') {
      createToast({
        content: `Packing slip needs to be a png`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: PACKING_SLIP_UPLOAD_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setUpdatePackingSlipStatus('error')
      return
    }

    dispatch(uploadPackingSlipV2(imageLibraryImage.url, imageLibraryImage.name))
    setUpdatePackingSlipStatus('success')
    window.analytics.track('Added custom packing slip: MOF')
  }

  async function remove() {
    toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    setUpdatePackingSlipStatus('loading')
    dispatch(removePackingSlip())
    setUpdatePackingSlipStatus('success')
    window.analytics.track('Removed custom packing slip: MOF')
  }

  const isChangeLoading = updatePackingSlipStatus === 'loading'

  if (!packingSlip) {
    return (
      <PackingSlipContainer savePackingSlipStatus={updatePackingSlipStatus}>
        <UploadPackingSlip
          className="btn-secondary"
          isImageLibraryModalOpen={isImageLibraryModalOpen}
          isLoading={isChangeLoading}
          onOpenImageLibrary={() => window.analytics.track('Image library opened for packing slip: Order creation')}
          handleUploadPackingUpdate={handleUploadPackingUpdate}
          setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
        />
      </PackingSlipContainer>
    )
  }

  if (isChangeLoading) {
    return (
      <PackingSlipContainer savePackingSlipStatus={updatePackingSlipStatus}>
        <PackingSlipLoading />
      </PackingSlipContainer>
    )
  }

  return (
    <PackingSlipContainer savePackingSlipStatus={updatePackingSlipStatus}>
      <PackingSlipDetails isLoading={isChangeLoading} packingSlip={packingSlip} removePackingSlip={remove} />
    </PackingSlipContainer>
  )
}

function PackingSlipContainer({
  children,
  savePackingSlipStatus
}: {
  children: React.ReactNode
  savePackingSlipStatus: StatusType
}) {
  return (
    <div className="tailwind">
      <div className="isolate w-full">
        <div className="flex flex-col">
          <h2 className="text-[19px]">Packing slip</h2>

          {children}

          <span className="text-sm text-gray-600">
            Only included for global prints and wall art shipped from UK, EU, AUS and USA. Packing slips are printed in
            black & white A4/standard letter size.
          </span>
        </div>

        {savePackingSlipStatus === 'loading' && <OverlayPortal />}
      </div>
    </div>
  )
}
