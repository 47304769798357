const WALL_ART = 'Wall art'
const FRAMED = 'Framed'
const FRAMED_PRINTS = 'Framed Prints'
const FINE_ART_PRINTS = 'artPrints'
const PRINTS = 'Prints & posters'
const PRODUCT_CATEGORIES_WITH_BORDERS = [FINE_ART_PRINTS, PRINTS, WALL_ART, FRAMED, FRAMED_PRINTS]

const ROLLED_CANVAS = 'Rolled Canvas'

const PRODUCT_TYPES_WITH_BORDERS = [ROLLED_CANVAS]

export function displayBorder({
  attributes,
  category,
  productType
}: {
  attributes: Record<string, string>
  category: string
  productType: string
}): boolean {
  const hasMount = isProductMounted(attributes)
  return Boolean(
    (PRODUCT_CATEGORIES_WITH_BORDERS.includes(category) && !hasMount) ||
      PRODUCT_TYPES_WITH_BORDERS.includes(productType)
  )
}

function isProductMounted(attributes: Record<string, string>): boolean {
  const hasMount = attributes.mount && attributes.mount !== 'no mount/mat'
  const hasMountInStyle = attributes.style && !attributes.style.toString().includes('non-mounted')
  return Boolean(hasMount || hasMountInStyle)
}
