// @flow
import React, { Component } from 'react'
import type { Dictionary } from '../../../../types'
import { FRAME_COLOUR, GLAZE, MOUNT_COLOUR, PAPER_TYPE, SUBSTRATE_WEIGHT } from '../../../../data/productAttributes'
import styles from './SelectProduct.module.css'
import { FRAMED, FRAMED_PRINTS } from '../../../../data/productCategories'

type Props = {|
  attributes: Dictionary<string[]>,
  category: string
|}

export default class AvailableOptions extends Component<Props> {
  formatProductOption: ((option: string) => string) = (option: string) => {
    const { category } = this.props

    switch (option) {
      case FRAME_COLOUR:
        if (category === 'Wall Art' || category.toLowerCase().includes('canvas') || category === FRAMED || category === FRAMED_PRINTS) {
          return 'frame colour'
        } else {
          return 'colour'
        }
      case GLAZE:
        if (category.toLowerCase().includes('canvas')) {
          return 'coating'
        } else {
          return 'glaze'
        }
      case MOUNT_COLOUR:
        return 'mount colour'
      case PAPER_TYPE:
        return 'paper'
      case SUBSTRATE_WEIGHT:
        return 'substrate weight'
      default:
        return option
    }
  }

  getTextToDisplay: (() => string) = (): string => {
    const options = this.getOptions()
    let result = ''
    if (options && options.length > 0) {
      result = 'Choice of '
      options.forEach((option, index, arr) => {
        if (option !== 'channels') {
          result += this.formatProductOption(option)
          if (arr.length > 1 && arr.length === index + 2) {
            result += ' and '
          } else if (arr.length > 1 && arr.length > index + 1 && arr.length !== index + 1) {
            result += ', '
          }
        }
      })
    }

    if (result) {
      result += '.'
    }
    return result
  }

  getOptions: (() => ?Array<string>) = (): ?Array<string> => {
    const { attributes } = this.props
    if (attributes) {
      const keys = Object.keys(attributes)
      const availableOptions = keys.filter(x => attributes[x].length > 1)
      const sortedOptions = availableOptions.sort()
      if (availableOptions.length === 1 && availableOptions[0] === 'channels') {
        return []
      }
      return sortedOptions
    }
  }

  render (): React$Node {
    return <div className={styles.availableOptions}>{this.getTextToDisplay()}</div>
  }
}
