// @flow
import { connect } from 'react-redux'
import CsvOrderDetailsController from './CsvOrderDetailsController'
import type { DispatchFunc, FormattedCsvRow, AppState, Cost } from '../../../../types'
import {
  updateMerchantOrderId,
  updateCustomsValue,
  toggleIsUsSalesTaxCollectedByOrderId
} from '../../../../actions/csvUpload'
import {
  getCsvOrderPackingSlip,
  getV4OrderTotalByOrderId,
  getIsUsSalesTaxCollectedByOrderId
} from '../../../../selectors/csvUpload'
import { debounce } from 'lodash'
import type { ComponentType } from 'react'

type OwnProps = {|
  order: FormattedCsvRow,
  canBeEdited: boolean,
  isFetchingShipping: boolean,
  currencyCode: string
|}

type StateProps = {|
  orderTotal: ?Cost,
  packingSlip: string,
  isUsSalesTaxAlreadyCollected: boolean
|}

type DispatchProps = {|
  updateMerchantOrderId: (index: string, orderId: string) => void,
  updateCustomsValue: (index: string, customsValue: number) => void,
  toggleUsSalesTax: () => void
|}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    orderTotal: getV4OrderTotalByOrderId(state, ownProps.order.id),
    packingSlip: getCsvOrderPackingSlip(state, ownProps.order.id),
    isUsSalesTaxAlreadyCollected: getIsUsSalesTaxCollectedByOrderId(state, ownProps.order.id)
  }
}

function noop() {}

const debounceTrackingEvent = debounce(window.analytics.track ?? noop, 500)

function mapDispatchToProps(dispatch: DispatchFunc, ownProps: OwnProps): DispatchProps {
  return {
    toggleUsSalesTax: () => {
      dispatch(toggleIsUsSalesTaxCollectedByOrderId(ownProps.order.id))
    },
    updateMerchantOrderId: (index: string, orderId: string) => dispatch(updateMerchantOrderId(index, orderId)),
    updateCustomsValue: (index: string, customsValue: number) => {
      debounceTrackingEvent('Change customs value', { label: 'csv uploader' })
      dispatch(updateCustomsValue(index, customsValue))
    }
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(CsvOrderDetailsController): ComponentType<OwnProps>)
