import Skeleton from '../../Skeleton'
import { OrderDetailItemInterface } from '../../../interfaces'
import { LineItemChannelInfo } from './LineItemChannelInfo.component'

export function LineItemLoading({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  return (
    <>
      <td className="col-span-3 row-span-5 m-2 mr-4 w-[160px] align-top sm:col-span-1 md:p-2 md:pb-12 md:pr-4 xl:pr-8">
        <Skeleton className="h-[160px] w-[160px]" />
      </td>

      <td className="col-span-3 p-2 align-top sm:col-start-2 md:table-cell">
        <LineItemChannelInfo lineItem={lineItem} />

        <div className="mt-6 flex flex-col gap-2">
          <span className="text-sm uppercase text-gray-600">Product</span>
          <Skeleton className="w-64" />
          <Skeleton className="w-48" />
          <Skeleton className="w-40" />
        </div>
      </td>
    </>
  )
}
