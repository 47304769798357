import { QUERY_PARAMS } from '../constants'
import { ImageLibraryFileType, ImageLibraryImageType } from '../hooks'
import { getProductFileCompatibilityData } from './getProductFileCompatibilityData.helper'

export function generateImageLibraryFilterParamsForArtwork({
  productType,
  searchParams
}: {
  productType: string
  searchParams: URLSearchParams
}) {
  const newSearchParams = new URLSearchParams(searchParams)
  const productFileCompatibility = getProductFileCompatibilityData({ productType })

  let fileTypeFilters: ImageLibraryFileType[] = ['png', 'jpg', 'pdf (single page)']
  if (productFileCompatibility) {
    fileTypeFilters = productFileCompatibility.fileTypesAllowed
  }

  let typeFilters: ImageLibraryImageType[] = ['artwork']
  if (
    productFileCompatibility?.fileTypesAllowed.includes('pdf (single page)') ||
    productFileCompatibility?.fileTypesAllowed.includes('pdf (multi-page)')
  ) {
    typeFilters = ['artwork', 'book']
  }

  newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, typeFilters.join(','))
  newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, fileTypeFilters.join(','))

  return newSearchParams
}
