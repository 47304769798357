import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import { ImageListEmpty } from '../../ImageLibrary'
import { UploadingImageInterface } from '../interfaces'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'
import { FullPageImageListLoaded, UploadingImageComponent, ImageTileSkeleton } from '.'

interface ImageListPropsInterface {
  imageLibraryMode: ImageLibraryFullPageModeType
  selectedImages: ImageLibraryImageInterface[]
  uploadingFiles: Record<string, UploadingImageInterface>
  setSelectedImages: (selected: ImageLibraryImageInterface[]) => void
}

export function FullPageImageList({
  imageLibraryMode,
  selectedImages,
  uploadingFiles,
  setSelectedImages
}: ImageListPropsInterface) {
  const { imageLibraryPages, isLoadingImageLibraryImages, imageLibraryFetchError, imageLibraryPagesToLoadSize } =
    useImageLibraryImages()
  const isUploadingFiles = Object.keys(uploadingFiles).length > 0

  if (imageLibraryFetchError && imageLibraryPagesToLoadSize <= 1 && !isUploadingFiles) {
    const errorCode = imageLibraryFetchError?.status ?? '0'

    return (
      <div className="w-full pt-12 sm:pt-24">
        <NotFound
          className="mx-auto px-9"
          heading="Failed to load images"
          body={
            <>
              <p className="text-center">
                Please try again later or <SupportLink>contact us</SupportLink> if the issue persists.
              </p>
              {imageLibraryFetchError.responseBodyJson?.detail && (
                <p className="mt-2 break-words text-center">{imageLibraryFetchError.responseBodyJson.detail}</p>
              )}
              <div className="mt-4 break-words text-center text-gray-600">
                Error code: {errorCode}
                <ClipboardCopy className="align-middle" text={errorCode.toString()} showText={false} />
              </div>
            </>
          }
        >
          <></>
        </NotFound>
      </div>
    )
  }

  if (isLoadingImageLibraryImages) {
    return (
      <div className="grid max-w-full grid-cols-2 gap-8 overflow-x-auto pt-8 pb-12 md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
        {Object.entries(uploadingFiles)
          .reverse()
          .map(([key, fileDetails]) => (
            <UploadingImageComponent
              fileDetails={fileDetails}
              imageLibraryMode={imageLibraryMode}
              key={key + fileDetails.name}
            />
          ))}
        {Array.from({ length: 6 }, (_, i) => i).map((value) => (
          <ImageTileSkeleton key={value} />
        ))}
      </div>
    )
  }

  if (imageLibraryPages?.[0].length === 0 && !isUploadingFiles) {
    return <ImageListEmpty />
  }

  return (
    <div className="grid max-w-full grid-cols-2 gap-8 overflow-x-auto md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
      {Object.entries(uploadingFiles)
        .reverse()
        .map(([key, fileDetails]) => (
          <UploadingImageComponent
            fileDetails={fileDetails}
            imageLibraryMode={imageLibraryMode}
            key={key + fileDetails.name}
          />
        ))}
      {imageLibraryPages && imageLibraryPages[0].length > 0 && (
        <FullPageImageListLoaded
          imageLibraryMode={imageLibraryMode}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
        />
      )}
    </div>
  )
}
