// @flow
import * as ct from '../data/constants'

// $FlowFixMe: TypeScript file
import { generateUserId } from 'src/v2/helpers'

export type SegmentProperties = {|
  merchantUniqueId: string,
  firstName: string,
  lastName: string,
  email: string,
  company: string
|}

export function setupSegment({ merchantUniqueId, firstName, lastName, email, company }: SegmentProperties) {
  setSegmentProperties({ merchantUniqueId, firstName, lastName, email, company })
  if (process.env.REACT_APP_ENV !== ct.TEST) {
    setDomainLinkProperties()
  }
}

function setSegmentProperties({ merchantUniqueId, firstName, lastName, email, company }: SegmentProperties) {
  window.analytics.identify(generateUserId(merchantUniqueId), {
    name: firstName + ' ' + lastName,
    email: email,
    company: {
      name: company
    }
  })
}

function setDomainLinkProperties() {
  window.analytics.ready(function () {
    if (window.ga) {
      window.ga('require', 'linker')
      window.ga('linker:autoLink', [ct.DASHBOARD_DOMAIN])
    }
  })
}
