import { entries } from '../../../helpers'
import { QuoteRequestItemInterface } from '../../../hooks'
import { DictionaryInterface, QuoteAssetInterface } from '../../../interfaces'
import { ProductDetailInterface } from '../../../hooks/useProductDetails.hook'

export function buildQuote({
  productDetails
}: {
  productDetails: ProductDetailInterface
}): QuoteRequestItemInterface[] | null {
  if (!productDetails) {
    return null
  }

  return [
    {
      assets: buildQuoteAssets(productDetails),
      attributes: selectRandomRequiredAttributes(productDetails),
      copies: 1,
      sku: productDetails.sku
    }
  ]
}

function selectRandomRequiredAttributes(productDetails: ProductDetailInterface): DictionaryInterface<string> {
  return entries(productDetails.attributes).reduce(
    (attributeAcc: DictionaryInterface<string>, [attributeName, attributesValues]) => {
      if (attributesValues.length > 1) {
        attributeAcc[attributeName] = attributesValues[0]
      }
      return attributeAcc
    },
    {}
  )
}

function buildQuoteAssets(productDetails: ProductDetailInterface): QuoteAssetInterface[] {
  return getRequiredPrintAreaNames(productDetails).map((printArea) => ({ printArea }))
}

function getRequiredPrintAreaNames(productDetails: ProductDetailInterface): string[] {
  return entries(productDetails.printAreas).reduce((printAreaNameAcc: string[], [printAreaName, printAreaProps]) => {
    return printAreaProps.required ? [...printAreaNameAcc, printAreaName] : printAreaNameAcc
  }, [])
}
