// @flow

import type { Thunk, MultiAssetBasketItem, DispatchFunc, GetStateFunc } from '../../types'
import { copyArtworkDetails } from '../images'
import { initImage } from './uploadArtwork'
import { updateCroppedImageUrl } from './updateCroppedImageUrl'

export function updatePreview(item: ?MultiAssetBasketItem): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const selectedPrintArea = item?.selectedPrintArea
    if (!item || !selectedPrintArea || !item.printAreas || !item.printAreas[selectedPrintArea].artwork) {
      return
    }
    const artworkForPrintArea = item.printAreas[selectedPrintArea].artwork

    const updatedArtwork = {
      ...artworkForPrintArea,
      croppedImageUrl: artworkForPrintArea.originalImageUrl,
      transformImageUrl: artworkForPrintArea.originalImageUrl
    }

    dispatch(copyArtworkDetails(item.id.toString(), updatedArtwork, selectedPrintArea))
    dispatch(
      updateCroppedImageUrl({
        itemId: item.id,
        croppedImageUrl: artworkForPrintArea.originalImageUrl,
        selectedPrintArea,
        transformImageUrl: artworkForPrintArea.originalImageUrl
      })
    )
    dispatch(initImage(item.id, updatedArtwork, selectedPrintArea))
  }
}
