import { fetcher } from '../../../helpers'
import { MerchantDetailsInterface, TaxIdentifierType, UserInterface } from '../../../interfaces'

export function updateTaxIdentifiers({
  merchantDetails,
  newTaxIdentifier,
  user
}: {
  merchantDetails: MerchantDetailsInterface
  newTaxIdentifier: { countryCode: string; value: string; type: TaxIdentifierType }
  user: UserInterface
}) {
  const existingTaxIdentifierData = merchantDetails.taxIdentifiers.find(
    (taxIdentifier) => taxIdentifier.type === newTaxIdentifier.type
  )

  if (newTaxIdentifier.value === existingTaxIdentifierData?.value) {
    return null
  }

  if (!existingTaxIdentifierData && newTaxIdentifier.value === '') {
    return null
  }

  if (existingTaxIdentifierData && newTaxIdentifier.value === '') {
    return deleteTaxIdentifier({ merchantId: user.merchantUniqueId, taxIdentifierId: existingTaxIdentifierData.id })
  }

  if (existingTaxIdentifierData) {
    return putTaxIdentifier({
      countryCode: newTaxIdentifier.countryCode,
      merchantId: user.merchantUniqueId,
      taxIdentifierId: existingTaxIdentifierData.id,
      value: newTaxIdentifier.value
    })
  }

  return postTaxIdentifier({
    countryCode: newTaxIdentifier.countryCode,
    merchantId: user.merchantUniqueId,
    type: newTaxIdentifier.type,
    value: newTaxIdentifier.value
  })
}

function deleteTaxIdentifier({ merchantId, taxIdentifierId }: { merchantId: string; taxIdentifierId: number }) {
  return fetcher(
    `${process.env.REACT_APP_PRODIGI_MERCHANT_SERVICE}/merchants/${merchantId}/taxidentifiers/${taxIdentifierId}`,
    {
      method: 'DELETE'
    }
  )
}

function putTaxIdentifier({
  countryCode,
  merchantId,
  taxIdentifierId,
  value
}: {
  countryCode: string
  merchantId: string
  taxIdentifierId: number
  value: string
}) {
  return fetcher(
    `${process.env.REACT_APP_PRODIGI_MERCHANT_SERVICE}/merchants/${merchantId}/taxidentifiers/${taxIdentifierId}`,
    {
      body: JSON.stringify({ countryCode, value }),
      method: 'PUT'
    }
  )
}

function postTaxIdentifier({
  countryCode,
  merchantId,
  type,
  value
}: {
  countryCode: string
  merchantId: string
  type: TaxIdentifierType
  value: string
}) {
  return fetcher(`${process.env.REACT_APP_PRODIGI_MERCHANT_SERVICE}/merchants/${merchantId}/taxidentifiers/`, {
    body: JSON.stringify({ countryCode, type, value }),
    method: 'POST'
  })
}
