// @flow
import type { CustomerAddress, InProgressOrder, Country } from '../../../types'
import React, { PureComponent } from 'react'
import { saveUnfinishedOrder } from '../../../actions/manualOrderForm'
import OrderAddressView from './OrderAddressView'

type Props = {|
  countries: Country[],
  inProgressOrder: InProgressOrder,
  deliveryCountryCode: string,
  areAllBasketItemsValid: boolean,
  customer: CustomerAddress,
  onAddressChange: (field: string, value: string) => void,
  pushRedirect: (pathname: string) => void
|}

type State = {|
  isAddressValid: boolean
|}

export default class OrderAddressController extends PureComponent<Props, State> {
  state: State = {
    isAddressValid: false
  }

  componentDidMount: (() => any) = () => window.analytics.page('MOF', 'Address')

  onClickBack: (() => void) = () => this.props.pushRedirect('/orders/create/add-image')

  onClickNext: (() => void) = () => {
    saveUnfinishedOrder(this.props.inProgressOrder, this.props.customer, this.props.deliveryCountryCode)
    this.props.pushRedirect('/orders/create/summary')
  }

  onIsAddressValidChange: ((isAddressValid: boolean) => void) = (isAddressValid: boolean) => this.setState({ isAddressValid })

  get canClickNext (): boolean {
    return this.state.isAddressValid && this.props.areAllBasketItemsValid
  }

  get deliveryCountryName (): string {
    const country = this.props.countries.find(c => c.isoCode === this.props.deliveryCountryCode)

    return country
      ? country.name
      : ''
  }

  render (): React$Node {
    return (
      <OrderAddressView
        canClickNext={this.canClickNext}
        onClickNext={this.onClickNext}
        onClickBack={this.onClickBack}
        deliveryCountryCode={this.props.deliveryCountryCode}
        deliveryCountryName={this.deliveryCountryName}
        address={this.props.customer}
        onIsAddressValidChange={this.onIsAddressValidChange}
        onAddressChange={this.props.onAddressChange} />
    )
  }
}
