import { AnimatePresence, motion } from 'framer-motion'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { cn } from '../../helpers'

interface FormItemPropsInterface {
  className?: string
  dataTest?: string
  errorMessage?: string
  inputField?: React.ReactNode
  labelClassName?: string
  labelText?: string
  required?: boolean
  valid?: boolean
  validate?: boolean
}

const errorMessageVariants = {
  showErrorMessage: { opacity: 1, height: 'auto' },
  hideErrorMessage: { opacity: 0, height: 0 }
}

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function FormItem({
  className = '',
  dataTest,
  errorMessage,
  inputField,
  labelClassName = 'mt-4 pt-2 pb-2',
  labelText,
  required = false,
  valid,
  validate = false
}: FormItemPropsInterface) {
  return (
    <div className={className} data-test={dataTest}>
      <label className={cn('block text-gray-600', labelClassName)}>
        {labelText && <LabelText labelText={labelText} required={required} valid={valid} validate={validate} />}
        {inputField}
      </label>
      <ErrorMessage errorMessage={errorMessage} valid={valid} validate={validate} />
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

interface LabelTextProps {
  labelText: string
  required?: boolean
  valid?: boolean
  validate?: boolean
}

function LabelText({ labelText, required, valid, validate }: LabelTextProps) {
  return (
    <div className="flex items-center">
      <span>{labelText}</span>
      {required && <span className="ml-1 text-red-600">*</span>}
      {validate && <ValidIcon valid={valid} />}
    </div>
  )
}

function ValidIcon({ valid }: { valid?: boolean }) {
  const iconClassName = 'my-auto ml-auto text-sm h-8 w-8'
  if (valid) {
    return <CheckIcon className={`${iconClassName} text-green-700`} />
  }
  return <XMarkIcon className={`${iconClassName} text-red-700`} />
}

function ErrorMessage({
  errorMessage,
  valid,
  validate
}: {
  errorMessage?: string
  valid?: boolean
  validate: boolean
}) {
  return (
    <AnimatePresence initial={false}>
      <motion.span
        animate={validate && !valid && errorMessage ? 'showErrorMessage' : 'hideErrorMessage'}
        className="text-sm text-red-800"
        variants={errorMessageVariants}
      >
        {errorMessage}
      </motion.span>
    </AnimatePresence>
  )
}
