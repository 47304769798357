// @flow
import React, { PureComponent } from 'react'
import type { RsaaStatus } from '../../../../types'
import { LOADING, SUCCESS } from '../../../../data/rsaa'
import styles from './OrderCreationStatus.module.css'
import Button from '../../../../components/Button'
import LoadingIndicator from '../../../../components/LoadingIndicator'
import WhitePanel from '../../../../components/WhitePanel'

type Props = {|
  orderId: ?string,
  orderCreationStatus: RsaaStatus,
  goBack: () => void,
  viewOrder: () => void,
  placeAnotherOrder: () => void
|}

export default class OrderCreationStatus extends PureComponent<Props> {
  render(): React$Node {
    const { status, statusMessage } = this.props.orderCreationStatus
    if (status === LOADING) {
      return (
        <Container>
          <LoadingIndicator loadingText="Placing your order" className={styles.loadingIndicator} />
        </Container>
      )
    } else if (status === SUCCESS) {
      return (
        <Container>
          <WhitePanel heading="Order complete" className={styles.whitePanel} dataTest="order-complete-panel">
            <div className={styles.messageContainer}>
              <p>
                Your order reference is <strong data-test="order-id">{this.props.orderId}</strong>
              </p>

              <div className={styles.controls}>
                <Button
                  primary
                  className={styles.primaryButton}
                  onClick={this.props.viewOrder}
                  dataTest="view-order-button"
                >
                  View order
                </Button>
                <Button icon="plus" onClick={this.props.placeAnotherOrder} dataTest="place-another-order-button">
                  Place another order
                </Button>
              </div>
            </div>
          </WhitePanel>
        </Container>
      )
    } else {
      return (
        <Container>
          <WhitePanel heading="Order creation failed" className={styles.whitePanel} dataTest="order-failed-panel">
            <div className={styles.messageContainer}>
              <p>{statusMessage}</p>
              <Button
                className={styles.primaryButton}
                primary
                icon="chevron-left"
                onClick={this.props.goBack}
                dataTest="order-failed-back-button"
              >
                Go back
              </Button>
            </div>
          </WhitePanel>
        </Container>
      )
    }
  }
}

type ContainerProps = {|
  children: React$Node
|}

function Container({ children }: ContainerProps) {
  return <div className={styles.container}>{children}</div>
}
