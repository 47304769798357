/* eslint-disable no-use-before-define */
// @flow
export default class ImagePipelineBuilder {
  url: string

  constructor () {
    this.url = ''
  }

  static fromUrl (baseUrl: string): ImagePipelineBuilder {
    var imagePipelineBuilder = new ImagePipelineBuilder()
    var dimensions = [encodeURIComponent(baseUrl)]
    imagePipelineBuilder.url = 'fromUrl' + JSON.stringify(dimensions)
    return imagePipelineBuilder
  }

  static fromPipelineUrl (pipelineUrl: string): ImagePipelineBuilder {
    var imagePipelineBuilder = new ImagePipelineBuilder()
    imagePipelineBuilder.url = decodeURIComponent(
      pipelineUrl.slice(pipelineUrl.indexOf('fromUrl'))
    )
    return imagePipelineBuilder
  }

  static fromArtwork (artworkName: string): ImagePipelineBuilder {
    var imagePipelineBuilder = new ImagePipelineBuilder()
    imagePipelineBuilder.url = 'fromArtwork[' + JSON.stringify(artworkName) + ']'
    return imagePipelineBuilder
  }

  crop (left: number, top: number, height: number, width: number): this {
    var dimensions = [left, top, height, width]
    this.url += '|crop' + JSON.stringify(dimensions)
    return this
  }

  pad (
    left: number,
    top: number,
    right: number,
    bottom: number,
    colourHex?: string
  ): this {
    var dimensions = [left, top, right, bottom]

    if (typeof colourHex !== 'undefined') {
      dimensions = [left, top, right, bottom, colourHex]
    }

    this.url += '|pad' + JSON.stringify(dimensions)
    return this
  }

  scale (factor: number): this {
    var dimensions = [factor]
    this.url += '|scale' + JSON.stringify(dimensions)
    return this
  }

  rotate (angle: number): this {
    var dimensions = [angle]
    this.url += '|rotate' + JSON.stringify(dimensions)
    return this
  }

  resize (width: number, height: number): this {
    var dimensions = [width, height]
    this.url += '|resize' + JSON.stringify(dimensions)
    return this
  }

  preview (sku: string, previewType: string, width: number, height: number, attributes: {[name: string]: string}): this {
    var dimensions = [sku, previewType, width, height, attributes]
    this.url += '|preview' + JSON.stringify(dimensions)
    return this
  }

  negate (): this {
    this.url += '|negate[]'
    return this
  }

  toUrl (baseUrl: string, noCache: boolean): string {
    if (noCache === true) {
      return (
        baseUrl +
        '&no-cache=' +
        noCache.toString() +
        '&operation=' +
        encodeURIComponent(this.toOperation())
      )
    } else {
      return baseUrl + '&operation=' + encodeURIComponent(this.toOperation())
    }
  }

  toOperation (): string {
    return this.url
  }
}
