// @flow
import type { Element } from 'react'
import React from 'react'
import styles from './CsvFilterOrders.module.css'
import { ALL_ORDERS, REQUIRE_INFO, ORDERS_WITH_ERRORS } from '../../../../data/csvUpload'

type Props = {|
  submitCsvLoading: boolean,
  submitCsvError: boolean,
  totalOrders: number,
  totalIgnoredOrders: number,
  selectedFilter: string,
  numberOfOrdersRequiringAction: number,
  numberOfOrdersWithErrors: number,
  changeFilter: (newFilter: string) => void
|}

export default function CsvFilterOrders (props: Props): null | Element<"form"> {
  const { numberOfOrdersRequiringAction, totalIgnoredOrders, changeFilter, selectedFilter, submitCsvError, numberOfOrdersWithErrors } = props
  const allOrdersNotIgnored = props.totalOrders - totalIgnoredOrders

  if (props.submitCsvLoading) {
    return null
  }

  return (
    <form className={styles.form} data-test="csv-filter-orders-container">
      {(numberOfOrdersRequiringAction > 0 || numberOfOrdersWithErrors > 0) && (
        <label className={styles.formItem} onChange={() => changeFilter(ALL_ORDERS)} data-test="all-orders-label">
          <input type='radio' value={ALL_ORDERS} onChange={() => changeFilter(ALL_ORDERS)} checked={selectedFilter === ALL_ORDERS} />
          <p className={styles.labelText}>
            Show all orders (
            {allOrdersNotIgnored > 0 ? allOrdersNotIgnored : ''}
            {allOrdersNotIgnored > 0 && totalIgnoredOrders > 0 ? ',' : ''}
            {totalIgnoredOrders === 0 ? '' : totalIgnoredOrders + ' ignored'}
            )
          </p>
        </label>
      )}

      {numberOfOrdersRequiringAction > 0 && (
        <label className={styles.formItem} onChange={() => changeFilter(REQUIRE_INFO)} data-test="orders-with-issues-label">
          <input type='radio' value={REQUIRE_INFO} onChange={() => changeFilter(REQUIRE_INFO)} checked={selectedFilter === REQUIRE_INFO} />
          <p className={styles.labelText}>Show orders with issues (
            {numberOfOrdersRequiringAction}
          )</p>
        </label>
      )}

      {submitCsvError && numberOfOrdersWithErrors > 0 && (
        <label className={styles.formItem} onChange={() => changeFilter(ORDERS_WITH_ERRORS)}>
          <input type='radio' value={REQUIRE_INFO} onChange={() => changeFilter(ORDERS_WITH_ERRORS)} checked={selectedFilter === ORDERS_WITH_ERRORS} />
          <p className={styles.labelText}>Show orders that failed to submit (
            {numberOfOrdersWithErrors}
          )</p>
        </label>
      )}
    </form>
  )
}
