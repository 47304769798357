import React from 'react'
import { Remark } from 'react-remark'

import { FooterContainer, FooterBlock } from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function Materials({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <FooterContainer>
      <FooterBlock title="Materials & print technology">
        {productData.printingMethod && productData.printingMethod?.length > 0 && (
          <p className="mb-4">
            <span className="font-medium">Printing method:</span> {productData.printingMethod.join(', ')}
          </p>
        )}
        {productData.materials?.items?.length > 0 && (
          <p className="mb-4">
            <span className="font-medium">Materials:</span> {productData.materials.items.join(', ')}
          </p>
        )}
        {productData.weight && productData.weight.gsm && (
          <p className="mb-4">
            <span className="font-medium">Weight:</span> {productData.weight.gsm}gsm{' '}
            {productData.weight.ozyd !== '' && `(${Number(productData.weight.ozyd).toFixed(1)}oz/yd)`}
          </p>
        )}
        {productData.finish && productData.finish.length > 0 && (
          <p className="mb-4">
            <span className="font-medium">Finish:</span> {productData.finish.join(', ')}
          </p>
        )}
        {productData.texture && productData.texture !== '' && (
          <p className="mb-4">
            <span className="font-medium">Texture:</span> {productData.texture}
          </p>
        )}
        {productData.whiteness && productData.whiteness !== '' && (
          <p className="mb-4">
            <span className="font-medium">Whiteness:</span> {productData.whiteness}
          </p>
        )}

        {productData.materials?.notes && (
          <div className="text-sm text-gray-600">
            <Remark
              rehypeReactOptions={{
                components: {
                  p(props: JSX.IntrinsicAttributes) {
                    return <React.Fragment {...props} />
                  },
                  a(props: JSX.IntrinsicAttributes) {
                    return <a {...props} className="w-fit text-sm text-gray-600" target="_blank" rel="noreferrer" />
                  }
                }
              }}
            >
              {productData.materials?.notes}
            </Remark>
          </div>
        )}
      </FooterBlock>

      {productData.careInstructions && productData.careInstructions.length > 0 && (
        <FooterBlock title="Care instructions">
          <ul>
            {productData.careInstructions.map((i) => (
              <li className="mb-4" key={i}>
                <Remark
                  rehypeReactOptions={{
                    components: {
                      p(props: JSX.IntrinsicAttributes) {
                        return <React.Fragment {...props} />
                      },
                      a(props: JSX.IntrinsicAttributes) {
                        return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                      }
                    }
                  }}
                >
                  {i}
                </Remark>
              </li>
            ))}
          </ul>
        </FooterBlock>
      )}
    </FooterContainer>
  )
}
