// @flow

import type { Element as ReactElement, ChildrenArray } from 'react'
import React, { Component } from 'react'
import type { Dictionary } from '../../types'
import Button from '../Button'
import styles from './DropDown.module.css'
import cx from 'classnames'

type Props = {|
  text: string,
  children: ChildrenArray<ReactElement<*>>,
  style?: Dictionary<string>,
  dataTest?: string,
  buttonDataTest?: string,
  className?: string
|}

type State = {|
  dropdownExpanded: boolean
|}

class DropDownButton extends Component<Props, State> {
  node: HTMLDivElement | null
  state: State = {
    dropdownExpanded: false
  }

  componentDidMount () {
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  closePanel: (() => void) = () => {
    this.setState({
      dropdownExpanded: false
    })
  }

  onToggleExpandPanelClick: ((e: MouseEvent) => void) = (e: MouseEvent): void => {
    e.stopPropagation()
    this.setState({
      dropdownExpanded: !this.state.dropdownExpanded
    })
  }

  handleOutsideClick: ((e: MouseEvent) => void) = (e: MouseEvent): void => {
    const target: any = e.target
    if (this.node && this.node.contains(target)) {
      return
    }

    this.closePanel()
  }

  render (): React$Node {
    return (
      <div
        className={cx(styles.container, this.props.className)}
        style={this.props.style}
        ref={node => {
          this.node = node
        }}
        data-test={this.props.dataTest ?? 'dropdown-container'}
      >
        <Button
          className={cx(styles.button, this.props.className)}
          icon="angle-down"
          onClick={this.onToggleExpandPanelClick}
          data-test={this.props.buttonDataTest ?? 'dropdown-button'}
        >
          {this.props.text}
        </Button>
        {this.state.dropdownExpanded && (
          <div className={cx(styles.dropdownPanel, this.props.className)} onClick={this.onToggleExpandPanelClick}>
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}

export default DropDownButton
