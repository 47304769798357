// @flow
import { createLogger } from 'redux-logger'
import { LOG_LE, LOG_SUCCESS } from '../actions/logging'
import { SET_RSAA_STATUS, RESET_RSAA_STATUS, ADD_RSAA_ACTION } from '../actions/rsaa'

const IGNORED_ACTIONS = [
  LOG_LE,
  LOG_SUCCESS,
  ADD_RSAA_ACTION,
  SET_RSAA_STATUS,
  RESET_RSAA_STATUS
]

export default (createLogger({
  predicate: (getState, action) => !IGNORED_ACTIONS.includes(action.type)
}): any)
