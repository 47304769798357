// @flow
import { RSAA } from 'redux-api-middleware'
import type { ThunkAsync, GetStateFunc, DispatchFunc } from '../../types'
import { NO_SELECTION, ALL } from '../../data/catalogue'
import { searchProducts } from './searchProducts'
import { getFacets } from './getFacets'
import { filterOutUnavailableProductTypes } from './filterOutUnavailableProducts'
import { selectAzureSearchSettings } from '../../selectors/appSettings'

export const FETCH_PRODUCT_TYPES = 'FETCH_PRODUCT_TYPES'
export const RECEIVE_PRODUCT_TYPES = 'RECEIVE_PRODUCT_TYPES'
export const FAILED_PRODUCT_TYPES = 'FAILED_PRODUCT_TYPES'

export function getProductTypes(): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const azureSettings = selectAzureSearchSettings()
    const category = getState().catalogue.selectedItemCategory
    const countryCode = getState().manualOrderForm.deliveryCountry
    const query = getState().catalogue.searchQuery

    await dispatch({
      [RSAA]: {
        endpoint: buildUrl(countryCode, category, azureSettings.endpoint),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': azureSettings.key
        },
        types: [
          FETCH_PRODUCT_TYPES,
          {
            type: RECEIVE_PRODUCT_TYPES,
            payload: async (_, _2, res) => filterOutUnavailableProductTypes(await res.json())
          },
          FAILED_PRODUCT_TYPES
        ]
      }
    })

    if (category !== NO_SELECTION) {
      await dispatch(getFacets())
    } else if (category === NO_SELECTION && query) {
      await dispatch(searchProducts(query))
    }
  }
}

function buildUrl(countryCode: string, category: string, azureSearchEndpoint: string): string {
  let categoryQuery = ''

  if (category !== NO_SELECTION && category !== ALL) {
    categoryQuery = `and category eq '${encodeURIComponent(category)}'`
  }

  return (
    `${azureSearchEndpoint}&$filter=destinationCountries/any(c: c eq '${countryCode}')` +
    ` ${categoryQuery} &search=*&$top=0&facet=productType,count:100`
  )
}
