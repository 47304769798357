// @flow

// import { PRINT_AREA_NAME } from '../data/printAreaName'

export function getFirstAvailablePrintArea(printAreaNames: string[]): string {
  return printAreaNames[0]
  // return printAreaNames.includes(PRINT_AREA_NAME.DEFAULT)
  //   ? PRINT_AREA_NAME.DEFAULT
  //   : printAreaNames[0]
}
