import { EmailAvailabilityResultInterface } from '../interfaces'
import { EmailAvailabilityStatusEnum } from '../enums'
import { GENERIC_ERROR_MESSAGE } from '../constants/errorMessage.const'

export function processEmailAvailabilityResponse(response: Response): EmailAvailabilityResultInterface {
  switch (response.status) {
    case 200:
      return {
        availability: EmailAvailabilityStatusEnum.Available,
        error: undefined
      }
    case 409:
      return {
        availability: EmailAvailabilityStatusEnum.Unavailable,
        error: 'Email already in use'
      }
    case 400:
      return {
        availability: EmailAvailabilityStatusEnum.Error,
        error: 'The email field is required'
      }
    default:
      return {
        availability: EmailAvailabilityStatusEnum.Error,
        error: `${GENERIC_ERROR_MESSAGE} (${response.status})`
      }
  }
}
