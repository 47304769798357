// @flow
import React from 'react'
import type { ChildrenArray } from 'react'

import styles from './../AppSettings.module.css'
import Button from '../../../../../components/Button'
import BrandSettingBlock from './../components/BrandSettingBlock'

type Props = {|
  commonSettings: {| title: string, subTitle: string, content: ChildrenArray<*> |}[],
  disableSave: boolean,
  isSavingConfig: boolean,
  saveConfigError: boolean,
  onSave: (event: SyntheticEvent<HTMLButtonElement>) => void
|}

export default class ConfigureView extends React.Component<Props> {
  render (): React$Node {
    const {
      commonSettings,
      disableSave,
      isSavingConfig,
      saveConfigError,
      onSave
    } = this.props

    return (
      <>
        <div className={styles.appSettingsBlock}>
          <div className={styles.blockTitle}>Configure</div>
          {commonSettings.map(block => (
            <BrandSettingBlock key={block.title} title={block.title} subTitle={block.subTitle}>
              {block.content}
            </BrandSettingBlock>
          ))}
          <Button primary className={styles.actionButton} disabled={disableSave} loading={isSavingConfig} onClick={onSave}>
            {isSavingConfig ? 'Saving' : 'Save'}
          </Button>
          {saveConfigError && (
            <div className={styles.saveConfigError}>An error occurred while saving settings. Please try again later or contact support if the issue persists</div>
          )}
        </div>
      </>
    )
  }
}
