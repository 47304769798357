import { VariantDataInterface } from '../SalesChannelProduct.component'

export function copyVariantAllDetails({
  allVariantsData,
  variantDataToCopy
}: {
  allVariantsData: Record<string, VariantDataInterface>
  variantDataToCopy: VariantDataInterface
}) {
  return Object.keys(allVariantsData).reduce((variantsAcc: Record<string, VariantDataInterface>, variantKey) => {
    const currentVariantDetails = allVariantsData[variantKey]
    if (variantKey === variantDataToCopy.id || !currentVariantDetails.isFulfilled) {
      return {
        ...variantsAcc,
        [variantKey]: currentVariantDetails
      }
    }

    return {
      ...variantsAcc,
      [variantKey]: {
        ...currentVariantDetails,
        artworkData: variantDataToCopy.artworkData,
        assetRequirementData: variantDataToCopy.assetRequirementData,
        selectedPrintArea: variantDataToCopy.selectedPrintArea,
        selectedProductData: variantDataToCopy.selectedProductData
      }
    }
  }, {})
}
