// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import Button from './Button'
import Flex from './layout/Flex'

type Props = {||}

class PageNotFound extends Component<Props> {
  getStyles (): any {
    return {
      icon: {
        fontSize: '14rem',
        color: 'var(--accent-red)'
      },
      message: {
        marginTop: '-2%',
        marginBottom: '2%',
        fontSize: 'var(--sizes-large)'
      },
      container: {
        textAlign: 'center',
        marginTop: '10%'
      },
      title: {
        fontSize: 'var(--sizes-xxxlarge)',
        fontFamily: 'var(--fonts-bold)',
        color: 'var(--accent-red)'
      }
    }
  }

  render (): React$Node {
    const styles = this.getStyles()
    return (
      <div style={styles.container}>
        <FontAwesome name='ban' style={styles.icon} />
        <p style={styles.title}>404</p>
        <p style={styles.message}>Page Not Found</p>
        <Flex.Row justifyCenter>
          <Link to={`/orders`}>
            <Button primary icon='chevron-left'>Go back</Button>
          </Link>
        </Flex.Row>
      </div>
    )
  }
}

export default PageNotFound
