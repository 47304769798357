import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useSplitToggle } from '../../hooks'
import ProductInfo from '../../components/ProductInfo'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { DEFAULT_DOCUMENT_TITLE, ROUTE_PATHS } from '../../constants'

export default function ProductInfoPage() {
  useEffect(() => {
    document.title = 'Product Info | Prodigi'
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  if (isAPCSplitOn) {
    return <Redirect to={ROUTE_PATHS.PRODUCT_CATALOGUE} />
  }

  return (
    <main className="tailwind">
      <ProductInfo />
    </main>
  )
}
