// @flow
import React from 'react'
import type { Node, Element } from 'react'
import styles from './Heading.module.css'
import cx from 'classnames'

type Props = {|
  children: Node,
  className? : string
|}

export default function Heading ({children, className}: Props): Element<"span"> {
  return <span className={cx(styles.heading, className)}>{children}</span>
}
