// @flow
import { RSAA } from 'redux-api-middleware'
import { selectAuthServiceUrl } from '../../selectors/appSettings'
import type { DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { selectUserId } from '../../selectors/user'
import { LOG_STATUS } from '../../data/rsaa'

export const REVOKE_ACCESS_TOKEN = 'REVOKE_ACCESS_TOKEN'
export const REVOKE_ACCESS_TOKEN_SUCCESS = 'REVOKE_ACCESS_TOKEN_SUCCESS'
export const REVOKE_ACCESS_TOKEN_ERROR = 'REVOKE_ACCESS_TOKEN_ERROR'

export function revokeAccessToken (refreshToken: string): Thunk<void> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const userId = selectUserId(getState())

    return dispatch({
      [RSAA]: {
        endpoint: selectAuthServiceUrl() + '/api/auth/revoke',
        method: 'POST',
        body: JSON.stringify({ refreshToken, userId }),
        headers: { 'Content-Type': 'application/json' },
        types: [
          { type: REVOKE_ACCESS_TOKEN, meta: { enhancements: [ LOG_STATUS ] } },
          REVOKE_ACCESS_TOKEN_SUCCESS,
          REVOKE_ACCESS_TOKEN_ERROR
        ]
      }
    })
  }
}
