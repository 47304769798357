// @flow
import type { CustomerAddress, DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { selectCsvOrder } from '../../selectors/csvUpload'
import { fetchPricingAndShippingForCsvOrder } from './fetchPricingAndShippingForCsvOrder'

export const UPDATE_CSV_ORDER_ADDRESS = 'UPDATE_CSV_ORDER_ADDRESS'

export function updateCsvOrderAddress (newAddress: CustomerAddress, orderId: string): Thunk<*> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const orderBeforeUpdate = selectCsvOrder(getState(), orderId)

    dispatch({
      type: UPDATE_CSV_ORDER_ADDRESS,
      address: newAddress,
      orderId
    })

    if (orderBeforeUpdate.customer.countryCode !== newAddress.countryCode) {
      dispatch(fetchPricingAndShippingForCsvOrder(orderId))
    }
  }
}
