import { AnimatePresence, motion } from 'framer-motion'
import Button from '../../Button'
import { useState } from 'react'
import { OrderCancellationModal } from './OrderCancellationModal.component'
import { OrderStatusEnum } from '../../../enums'

interface OrderCancellationButtonPropsInterface {
  cancellationIsAvailable: boolean
  orderId: string
  status: OrderStatusEnum
  mutateOrderDetails: () => void
}

export function OrderCancellationButton({
  cancellationIsAvailable,
  orderId,
  status,
  mutateOrderDetails
}: OrderCancellationButtonPropsInterface) {
  const [open, setOpen] = useState(false)

  function onCancelButtonClick() {
    window.analytics.track('Clicked Cancel', { orderId })
    setOpen(true)
  }

  return (
    <>
      <AnimatePresence initial={false}>
        {cancellationIsAvailable && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            layout
            transition={{ duration: 0.3 }}
          >
            <Button onClick={onCancelButtonClick} variant="secondary" theme="danger">
              <span>
                Cancel<span className="hidden lg:inline"> order</span>
              </span>
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      <OrderCancellationModal
        open={open}
        orderId={orderId}
        status={status}
        mutateOrderDetails={mutateOrderDetails}
        setOpen={setOpen}
      />
    </>
  )
}
