// @flow
import React, { Component } from 'react'
import type { Style } from '../../types'
import PanelTitle from '../PanelTitle'
import styles from './WhitePanel.module.css'
import cx from 'classnames'

type Props = {|
  style?: Style,
  heading?: string,
  className?: string,
  dataTest?: string,
  headingStyle?: Style,
  id?: string,
  children: React$Node
|}

class WhitePanel extends Component<Props> {
  render(): React$Node {
    const { style, heading, headingStyle, children, id, className, dataTest } = this.props
    return (
      <div className={cx(styles.container, className)} style={style} data-test={dataTest} id={id}>
        {heading && <PanelTitle style={headingStyle}>{heading}</PanelTitle>}
        {children}
      </div>
    )
  }
}

export default WhitePanel
