// @flow
import { connect } from 'react-redux'
import MofImageEditor from './MofImageEditor'
import type { AppState, Dictionary, ArtworkTransformations, DispatchFunc, Match } from '../../../types'
import {
  saveTransformations,
  changeSelectedPrintAreaForBasketItem,
  removeBasketItemArtwork,
  uploadMOFArtworkFromFile
} from '../../../actions/manualOrderForm'
import { makeGetBasketItemById } from '../../../selectors/manualOrderForm'
import { pushRedirect } from '../../../actions/router'
import { getCatalogueItemBySku } from '../../../selectors/catalogue'
import { getArtworkProgress, getProductTemplates } from '../../../selectors/images'
import type { ComponentType } from 'react'
import type { MofImageEditorProps } from './MofImageEditor'
import type { Artwork, ImageEditorSaveDataType } from '../../../types/images'
import { onSelectMOFImageLibraryImage } from '../../../actions/manualOrderForm/uploadArtwork'

type OwnProps = {|
  match: Match
|}

function makeMapStateToProps(state: AppState) {
  const getBasketItemById = makeGetBasketItemById()

  return (state: AppState, ownProps): $Shape<MofImageEditorProps> => {
    const { basketItemId } = ownProps.match.params
    const basketItem = getBasketItemById(state, basketItemId)
    const catalogueItem = basketItem ? getCatalogueItemBySku(state, basketItem.sku) : null
    const templates = basketItem ? getProductTemplates(state, basketItem.sku, basketItem.selectedAttributes) : null

    return {
      catalogueItem,
      basketItem,
      templates,
      artworkUploadProgress: getArtworkProgress(state)
    }
  }
}

function mapDispatchToProps(dispatch: DispatchFunc, ownProps: OwnProps): $Shape<MofImageEditorProps> {
  return {
    saveTransformations: (
      basketItemId: number,
      updatedTransformations: Dictionary<?ArtworkTransformations>,
      additionalData: ImageEditorSaveDataType
    ) => {
      dispatch(saveTransformations(basketItemId, updatedTransformations, additionalData))
    },
    redirect: (address: string) => {
      dispatch(pushRedirect(address))
    },
    uploadArtworkFromFile: (itemId: number, file: File, printAreaName: string) => {
      dispatch(
        uploadMOFArtworkFromFile({
          file,
          itemId,
          printAreaName,
          isTilingEnabled: false,
          allowConcurrentRequests: false
        })
      )
    },
    removeBasketItemArtwork: (basketItemId: number, printAreaName: string) => {
      dispatch(removeBasketItemArtwork(basketItemId.toString(), printAreaName))
    },
    changeSelectedPrintAreaForBasketItem: (basketItemId: number, printAreaName: string) => {
      dispatch(changeSelectedPrintAreaForBasketItem(basketItemId.toString(), printAreaName))
    },
    onSelectImageLibraryImage: ({
      artwork,
      basketItemId,
      printAreaName,
      isTilingEnabled
    }: {|
      artwork: Artwork,
      basketItemId: number,
      printAreaName: string,
      isTilingEnabled: boolean
    |}) => {
      dispatch(onSelectMOFImageLibraryImage({ artwork, basketItemId, printAreaName, isTilingEnabled }))
    }
  }
}

export default (connect(makeMapStateToProps, mapDispatchToProps)(MofImageEditor): ComponentType<OwnProps>)
