import * as Portal from '@radix-ui/react-portal'
import { PORTAL_ID } from '../../constants/portalId.const'

/**
 * Useful to block the UI, for example, when something is loading and interactions should be prevented
 */
export function OverlayPortal({ className = '' }: { className?: string }) {
  return (
    <Portal.Root asChild container={document.getElementById(PORTAL_ID)}>
      <div className={`pointer-events-auto fixed inset-0 ${className}`}></div>
    </Portal.Root>
  )
}
