// @flow
import type { Node } from 'react'
import React from 'react'
import Switch from 'react-switch'

type Props = {|
  activeBoxShadow?: string,
  ariaLabel?: string,
  checked: boolean,
  checkedIcon?: boolean,
  className?: string,
  disabled?: boolean,
  handleDiameter?: number,
  height?: number,
  id?: string,
  offColor?: string,
  offHandleColor?: string,
  onColor?: string,
  onHandleColor?: string,
  uncheckedIcon?: boolean,
  width?: number,
  onChange: (checked: boolean, event: SyntheticInputEvent<HTMLInputElement>, id: string) => void
|}

function ToggleSwitch ({
  ariaLabel = 'Toggle switch',
  checked,
  onChange,
  ...otherProps
}: Props): Node {
  const computedStyle = document.documentElement
    ? getComputedStyle(document.documentElement)
    : {}

  const defaultProps = {
    activeBoxShadow: '0px 0px 2px 3px rgba(0, 0, 0, 0.1)',
    checkedIcon: false,
    uncheckedIcon: false,
    handleDiameter: 16,
    offColor: computedStyle.getPropertyValue('--color-grey-10').trim(),
    onColor: computedStyle.getPropertyValue('--color-grey-10').trim(),
    offHandleColor: computedStyle.getPropertyValue('--color-grey-40').trim(),
    onHandleColor: computedStyle.getPropertyValue('--color-alert-purple').trim(),
    height: 24,
    width: 46
  }

  return (
    <Switch
      aria-label={ariaLabel}
      checked={checked}
      onChange={onChange}
      {...defaultProps}
      {...otherProps} />
  )
}

export default ToggleSwitch
