// @noflow

function dateFormat(datetime: string) {
  return new Date(datetime).toLocaleDateString('en-gb', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

export default dateFormat
