import { OrderStatusEnum } from '../enums'

export function formatOrderStatusName(orderStatus: OrderStatusEnum) {
  switch (orderStatus) {
    case OrderStatusEnum.COMPLETE:
      return 'Shipped'
    case OrderStatusEnum.IN_PROGRESS:
      return 'In production'
    case OrderStatusEnum.CANCELLED:
      return 'Cancelled'
    case OrderStatusEnum.ON_HOLD:
      return 'Submitted'
    case OrderStatusEnum.REQUIRES_ATTENTION:
    case OrderStatusEnum.DRAFT:
      return 'Requires attention'
    default:
      return orderStatus
  }
}
