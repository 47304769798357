import cx from 'classnames'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface TextFieldPropsInterface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  dataTest?: string
  uppercase?: boolean
  hasError?: boolean
}

export const TextField = forwardRef(
  (
    {
      autoCapitalize = 'off',
      autoComplete = 'off',
      className = '',
      dataTest,
      disabled,
      uppercase,
      hasError = false,
      ...props
    }: TextFieldPropsInterface,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <input
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        className={twMerge(
          cx(
            'prodigiTextField',
            'mt-2 h-16 w-full cursor-text self-center truncate rounded-md border bg-white px-3 text-input text-black outline-none focus:border-gray-700 disabled:cursor-not-allowed disabled:bg-gray-100 sm:text-base',
            hasError ? 'border-red-400' : 'border-gray-300',
            uppercase && 'uppercase',
            className
          )
        )}
        data-test={dataTest}
        disabled={disabled}
        ref={ref ?? undefined}
        {...props}
      />
    )
  }
)

TextField.displayName = 'TextField'
