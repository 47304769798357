// @flow
import type { Action, AppState, LogEntriesMiddlewareResult } from '../../../types'

import {
  RECEIVE_USER_DETAILS,
  FETCH_USER_DETAILS_ERROR,
  UPDATE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_EMAIL_NOTIFICATION_ERROR,
  UPDATE_USER_NAME_SUCCESS,
  UPDATE_USER_NAME_ERROR
} from '../../../actions/user'

// $FlowFixMe: TypeScript file
import { REQUEST_REGISTER_USER, RECEIVE_REGISTER_USER_RESPONSE, REGISTER_USER_ERROR } from '../../../v2/constants'

export function user(action: Action, state: AppState): ?LogEntriesMiddlewareResult {
  switch (action.type) {
    case RECEIVE_USER_DETAILS: {
      const name = action.payload.data.name
      const id = action.payload.data.id
      return ['User details received', { user: name, userId: id, browser: navigator.userAgent }]
    }

    case FETCH_USER_DETAILS_ERROR: {
      return ['Fetching user details failed - logging out', { resp: { ...action.payload } }, 'error']
    }

    case RECEIVE_REGISTER_USER_RESPONSE: {
      return ['Registering new user']
    }

    case REQUEST_REGISTER_USER: {
      if (action.payload && action.error) {
        return ['Registering user failed', { resp: { ...action.payload } }, 'error']
      }
      return null
    }

    case REGISTER_USER_ERROR: {
      return ['Registering user failed', { resp: { ...action.payload.response } }, 'error']
    }

    case UPDATE_EMAIL_NOTIFICATION_SETTINGS_SUCCESS: {
      return ['Email notification settings updated', { resp: { ...action.payload } }]
    }

    case UPDATE_EMAIL_NOTIFICATION_ERROR: {
      return ['Updating email notification settings failed', { resp: { ...action.payload } }, 'error']
    }

    case UPDATE_USER_NAME_SUCCESS: {
      return ['User name updated', { resp: { ...action.payload } }]
    }

    case UPDATE_USER_NAME_ERROR: {
      return ['Updating user name failed', { resp: { ...action.payload } }, 'error']
    }

    default: {
      return null
    }
  }
}
