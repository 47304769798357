// @flow

import type { UpdateCsvItemPrintAreas } from '../types'
import { getFirstAvailablePrintArea } from '../../helpers/getFirstAvailablePrintArea'

import type { Dictionary, FormattedOrderItem, V4ProductDetails, DispatchFunc, GetStateFunc, Thunk } from '../../types'
import { values } from '../../helpers/dictionary'
import { copyArtworkDetails } from '../images'
import { getArtworkByItemId } from '../../selectors/images'

export const UPDATE_CSV_ITEM_PRINT_AREAS = 'UPDATE_CSV_ITEM_PRINT_AREAS'

export function updateCsvItemPrintAreas(
  item: FormattedOrderItem,
  productDetails: V4ProductDetails
): Thunk<UpdateCsvItemPrintAreas> {
  return (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const productPrintAreaNames = Object.keys(productDetails.printAreas)
    const firstAvailableProductPrintArea = getFirstAvailablePrintArea(productPrintAreaNames)

    const { printAreaImageUrls, transformImageUrls } = getUpdatedPrintAreaImageUrls(item, productPrintAreaNames)
    const atLeastOnePrintAreaHasAnImage = values(printAreaImageUrls).some((url) => Boolean(url))
    const hasImageForUnknownPrintArea = Boolean(item.unknownPrintAreaImageUrl)
    const artworkToCopy = getArtworkByItemId(getState(), item.id)

    if (!atLeastOnePrintAreaHasAnImage && hasImageForUnknownPrintArea && artworkToCopy) {
      printAreaImageUrls[firstAvailableProductPrintArea] = item.unknownPrintAreaImageUrl
      dispatch(copyArtworkDetails(item.id, artworkToCopy, firstAvailableProductPrintArea))
    }

    return dispatch({
      type: UPDATE_CSV_ITEM_PRINT_AREAS,
      orderItemId: item.id,
      selectedPrintArea: firstAvailableProductPrintArea,
      printAreaImageUrls,
      transformImageUrls
    })
  }
}

type UpdatedPrintAreasReturnType = {|
  printAreaImageUrls: Dictionary<string | null>,
  transformImageUrls: Dictionary<string | null>
|}

function getUpdatedPrintAreaImageUrls(
  item: FormattedOrderItem,
  productPrintAreas: string[]
): UpdatedPrintAreasReturnType {
  const updatedPrintAreaImageUrls = productPrintAreas.reduce(
    (printAreaImageUrlsAcc: Dictionary<string | null>, printAreaName: string) => {
      if (item.printAreaImageUrls[printAreaName]) {
        return {
          ...printAreaImageUrlsAcc,
          [printAreaName]: item.printAreaImageUrls[printAreaName]
        }
      }
      return {
        ...printAreaImageUrlsAcc,
        [printAreaName]: null
      }
    },
    {}
  )
  const transformImageUrls = productPrintAreas.reduce(
    (transformImageUrlsAcc: Dictionary<string | null>, printAreaName: string) => {
      if (item.transformImageUrls[printAreaName]) {
        return {
          ...transformImageUrlsAcc,
          [printAreaName]: item.transformImageUrls[printAreaName]
        }
      }
      return {
        ...transformImageUrlsAcc,
        [printAreaName]: null
      }
    },
    {}
  )

  return { printAreaImageUrls: updatedPrintAreaImageUrls, transformImageUrls }
}
