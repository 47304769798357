import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import InlineMenu from '../../InlineMenu'
import { OrderStatusEnum } from './../../../enums'
import { formatOrderStatusName } from '../../../helpers'
import { ORDER_STATUSES, QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'
import { useDebouncedUrlUpdate } from '../../../hooks/useDebouncedUrlUpdate.hook'

const OPTIONS = ORDER_STATUSES.map((option) => ({
  content: <span className="pr-10">{formatOrderStatusName(option)}</span>,
  value: option,
  searchLabel: formatOrderStatusName(option)
}))

export function OrdersListActionStatus() {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedStatusParam = searchParams.get(QUERY_PARAMS.ORDERS.STATUS)
  const selectedStatusDropdownValues = useMemo(
    () => (selectedStatusParam ? selectedStatusParam.split(',') : []),
    [selectedStatusParam]
  )
  const [selectedStatusValues, setSelectedStatusValues] = useState(selectedStatusDropdownValues)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedStatusValues(selectedStatusDropdownValues)
  }, [selectedStatusDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  const updateSelectedOrderStatus = (selectedValues: string[]) => {
    if (
      selectedValues.includes(OrderStatusEnum.REQUIRES_ATTENTION) &&
      !selectedValues.includes(OrderStatusEnum.DRAFT)
    ) {
      selectedValues.push(OrderStatusEnum.DRAFT)
    }

    if (
      !selectedValues.includes(OrderStatusEnum.REQUIRES_ATTENTION) &&
      selectedValues.includes(OrderStatusEnum.DRAFT)
    ) {
      selectedValues = selectedValues.filter((status) => status !== OrderStatusEnum.DRAFT)
    }

    setSelectedStatusValues(selectedValues)
    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: QUERY_PARAMS.ORDERS.STATUS,
      routePath: ROUTE_PATHS.ORDERS,
      selectedValues
    })
  }

  return (
    <InlineMenu
      ariaLabel="Order status"
      data-test="orders-list-actions__order-status-filter"
      multiple={true}
      options={OPTIONS}
      selected={selectedStatusValues}
      updateSelected={updateSelectedOrderStatus}
    />
  )
}
