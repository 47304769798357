// @flow
import type { UpdatePriceToUser } from '../types'

export const UPDATE_PRICE_TO_USER = 'UPDATE_PRICE_TO_USER'

export function updatePriceToUser (priceToUserAsInt: number, basketItemId: number): UpdatePriceToUser {
  return {
    type: UPDATE_PRICE_TO_USER,
    priceToUserAsInt,
    basketItemId
  }
}
