import { SCALE_TYPE } from '@prodigi-group/components-image-editor'

import { TemplateServiceSuccessResponseInterface } from '../../../hooks'
import { getDefaultTransformations } from './getDefaultTransformations.helper'
import { ArtworkInterface, ArtworkTransformationsInterface } from '../interfaces'
import { calculateScaleFactorAdjustment } from './calculateScaleFactorAdjustment.helper'
import { convertFillScaleToPrintSizeScale } from './convertFillScaleToPrintSizeScale.helper'

export function calculatePrintQuality({
  artwork,
  artworkTransformations: passedArtworkTransformations = null,
  category,
  templates,
  printAreaName,
  scaleType = SCALE_TYPE.PRINT_SIZE
}: {
  artwork: ArtworkInterface
  artworkTransformations?: ArtworkTransformationsInterface | null
  category?: string
  printAreaName: string
  templates: TemplateServiceSuccessResponseInterface
  scaleType?: string
}) {
  let artworkTransformations: ArtworkTransformationsInterface | null = passedArtworkTransformations

  const shouldCalulateDefaultTransformations = artwork && category && !artworkTransformations
  if (shouldCalulateDefaultTransformations) {
    const defaultArtworkTransformations = getDefaultTransformations(artwork, templates, 'fillPrintArea', printAreaName)
    artworkTransformations = defaultArtworkTransformations
  }

  if (!artworkTransformations) {
    return null
  }

  const selectedTemplate = templates?.printAreas?.[printAreaName]?.orientations?.[artworkTransformations.orientation]
  if (!selectedTemplate) {
    return null
  }

  const { scaleFactor: artworkScaleFactor, borderScale, borderFactor } = artworkTransformations
  const { printDpi, printResolution, cropRectangle } = selectedTemplate

  const artworkDimensionsInPx = { width: artwork.artworkWidth, height: artwork.artworkHeight }

  const imageEditorScaleFactorAdjustment = calculateScaleFactorAdjustment(
    printResolution,
    artworkDimensionsInPx,
    cropRectangle,
    printDpi,
    borderFactor,
    borderScale
  )

  const scaleFactor = (artworkScaleFactor * imageEditorScaleFactorAdjustment) / 100

  const fillConvertedScale = convertFillScaleToPrintSizeScale(
    scaleFactor,
    printDpi,
    borderScale,
    borderFactor,
    printResolution,
    artworkDimensionsInPx,
    cropRectangle
  )

  const printSizeScaleFactor = scaleType === SCALE_TYPE.PRINT_SIZE ? scaleFactor : fillConvertedScale

  const scaleFactorAdjustment = calculateScaleFactorAdjustment(
    printResolution,
    artworkDimensionsInPx,
    cropRectangle,
    printDpi,
    borderFactor,
    borderScale
  )

  const adjustedScaleFactor = printSizeScaleFactor / scaleFactorAdjustment

  const imageDpi = Math.min(printDpi / adjustedScaleFactor, printDpi)
  const qualityValue = Math.round((imageDpi / printDpi) * 100)

  return {
    label: getQualityLabel(qualityValue),
    value: qualityValue
  }
}

function getQualityLabel(qualityValue: number): 'Excellent' | 'Acceptable' | 'Poor' {
  if (qualityValue >= 100) {
    return 'Excellent'
  }
  if (qualityValue >= 50) {
    return 'Acceptable'
  }
  return 'Poor'
}
