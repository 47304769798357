// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {|
  className: string
|}

export default function ShopifyIcon ({ className }: Props): Element<"svg"> {
  return (
    <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="30" cy="30" r="30" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.3756 20.7345C31.3756 19.6677 31.3756 19.1337 31.1012 18.6008C31.65 18.6008 32.2 19.4007 32.4756 20.4675C32.2 20.4675 31.9256 20.7345 31.3756 20.7345ZM29.7256 29.0033C29.7256 29.0033 28.9 28.4693 27.8012 28.4693C26.15 28.4693 26.15 29.2703 26.15 29.5362C26.15 30.87 29.45 31.4028 29.45 34.3387C29.45 36.4712 27.8012 38.072 25.8756 38.072C23.6756 38.072 22.3012 36.4712 22.3012 36.4712L22.8512 34.3387C22.8512 34.3387 24.2256 35.4043 25.3256 35.4043C26.15 35.4043 26.4256 34.6045 26.4256 34.3387C26.4256 32.4697 23.6756 32.4697 23.6756 29.8032C23.6756 27.4025 25.3256 25.2688 28.6256 26.0687C30.0012 26.0687 30.5512 26.3357 30.5512 26.3357L29.7256 29.0033ZM28.9 17.7998C29.1756 17.7998 29.45 18.0668 29.45 18.0668C28.6256 18.3338 27.5256 19.6677 26.7 22.0683C26.15 22.3353 25.3256 22.3353 24.7756 22.6012C25.3256 20.7345 26.7 17.7998 28.9 17.7998ZM30.2756 20.7345V21.0003C29.45 21.2673 28.6256 21.5343 27.8012 21.8013C28.3512 19.9347 29.1756 19.1337 30.0012 18.6008C30.2756 19.1337 30.2756 19.9347 30.2756 20.7345ZM38.25 21.8013C38.25 21.5343 37.9756 21.5343 37.9756 21.5343L36.0512 21.2673C36.0512 21.2673 34.95 20.2017 34.6756 19.9347H34.1256C34.1256 19.9347 33.8512 19.9347 33.3012 20.2017C32.75 18.8678 31.9256 17.7998 30.5512 17.7998C30.0012 17.267 29.7256 17 29.1756 17C25.8756 17 24.5012 21.0003 23.95 22.8682C22.8512 23.4022 21.75 23.6692 21.75 23.6692C20.9256 23.935 20.9256 23.935 20.9256 24.4678C20.9256 25.0018 19 38.606 19 38.606L33.3012 41.2737L41.0012 39.6728L38.25 21.8013Z" />
    </svg>
  )
}
