import useSWR from 'swr'

import { fetcher } from '../../../helpers'
import { FetchErrorInterface } from '../../../interfaces'
import { useMerchantService } from '../../../hooks/useMerchantService.hook'

interface RutterProxySalesChannelInterface {
  prodigiSalesChannelId: string
  canResync: boolean
  lastPollDateTime: string
  isActive: boolean
  isSyncing: boolean
}

export function useRutterProdxySalesChannelDetails(salesChannelId?: string) {
  const { merchantDetails } = useMerchantService()
  const baseUrl = `${process.env.REACT_APP_RUTTER_PROXY}/merchantId/${merchantDetails?.id}/saleschannel/${salesChannelId}`

  const { data, error, mutate } = useSWR<RutterProxySalesChannelInterface, FetchErrorInterface<{ message?: string }>>(
    salesChannelId && merchantDetails?.id ? baseUrl : null,
    fetcher
  )

  return {
    channelDetails: data,
    isLoading: !data && !error,
    error,
    mutate
  }
}
