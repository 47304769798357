function Recyclable({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 60 60"
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
    >
      <title>Recyclable icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.048 51.5103C36.1877 61.4596 18.4447 59.9071 8.49178 48.0509C-1.45924 36.1938 0.0937286 18.4546 11.953 8.50521C17.0564 4.22406 23.3689 1.93588 29.9526 1.93588C30.7794 1.93588 31.6121 1.97266 32.4476 2.04525C39.9191 2.6986 46.6877 6.22187 51.5083 11.9656C61.4593 23.8217 59.9064 41.5619 48.048 51.5103M52.9906 10.7208C47.8389 4.58123 40.6027 0.815014 32.6161 0.117137C24.6334 -0.582676 16.8492 1.87006 10.7089 7.02234C-1.96948 17.657 -3.62895 36.6206 7.00852 49.2947C12.9367 56.3577 21.4519 60 30.0252 60C36.8345 60 43.6805 57.7012 49.2921 52.9932C61.9696 42.3585 63.629 23.3949 52.9906 10.7208"
        fill="black"
      />
      <path
        d="M25.5 23.25H19.6275C21.0825 21.0139 23.2212 19.3079 25.7248 18.3862C28.2284 17.4645 30.9628 17.3766 33.5204 18.1356C36.078 18.8945 38.3219 20.4596 39.9175 22.5977C41.5132 24.7357 42.3752 27.3322 42.375 30H44.625C44.6281 26.9912 43.7031 24.0547 41.9762 21.5908C40.2492 19.1269 37.8043 17.2556 34.9749 16.2321C32.1456 15.2086 29.0693 15.0827 26.1658 15.8715C23.2622 16.6604 20.6726 18.3256 18.75 20.64V16.5H16.5V25.5H25.5V23.25Z"
        fill="black"
      />
      <path
        d="M34.5 36.75H40.3725C38.9175 38.9861 36.7788 40.6922 34.2752 41.6139C31.7716 42.5355 29.0372 42.6234 26.4796 41.8645C23.922 41.1056 21.6781 39.5404 20.0825 37.4024C18.4868 35.2643 17.6248 32.6678 17.625 30H15.375C15.3719 33.0088 16.2969 35.9454 18.0239 38.4093C19.7508 40.8731 22.1957 42.7444 25.0251 43.7679C27.8545 44.7914 30.9307 44.9173 33.8343 44.1285C36.7378 43.3397 39.3275 41.6745 41.25 39.36V43.5H43.5V34.5H34.5V36.75Z"
        fill="black"
      />
    </svg>
  )
}

export { Recyclable }
