import { motion } from 'framer-motion'
import { SalesChannelInterface } from '../../../interfaces'
import { ActiveSalesChannelListRow } from './ActiveSalesChannelListRow.component'

interface ActiveSalesChannelListPropsInterface {
  activeChannels: SalesChannelInterface[]
  className?: string
}

export function ActiveSalesChannelList({ activeChannels, className = '' }: ActiveSalesChannelListPropsInterface) {
  return (
    <motion.div layout className={className}>
      <h2>Active channels</h2>
      <div className="mt-6 block w-full border">
        <ul className="block divide-y">
          {activeChannels.map((channel) => (
            <li key={channel.id}>
              <ActiveSalesChannelListRow channel={channel} />
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  )
}
