// @flow
import type { DispatchFunc, GetStateFunc, ThunkAsync } from '../../types'
import { refreshAccessToken } from './refreshAccessToken'
import { selectJwtIntervalInMinutes } from '../../selectors/appSettings'
import { MILLISECONDS_PER_MINUTE } from '../../data/constants'

export const START_JWT_TIMER = 'START_JWT_TIMER'

export function startJwtTimer (): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const jwtIntervalInMinutes = selectJwtIntervalInMinutes()
    const jwtIntervalInMilliseconds = jwtIntervalInMinutes * MILLISECONDS_PER_MINUTE

    const timerId = setInterval(() => dispatch(refreshAccessToken()), jwtIntervalInMilliseconds)

    dispatch({
      type: START_JWT_TIMER,
      timerId
    })
  }
}
