import { NO_SELECTION, RETRIEVABLE_PROPERTIES } from '../constants'

export function getProductSearchUrl(
  query: string,
  azureSearchEndpoint: string,
  countryCode: string,
  productType: string,
  category: string
): string {
  const formattedQuery = encodeURIComponent(query.trim().toLowerCase() + '*')
  const skuQuery = `"${encodeURIComponent(query.trim().toUpperCase())}"`
  const sizeSearchQuery = reverseSizeSearchQuery(query.trim().toLowerCase())

  let productTypeQuery = ''
  let categoryQuery = ''

  if (category !== NO_SELECTION && category !== '') {
    categoryQuery = `and category eq '${encodeURIComponent(category)}'`
  }

  if (productType !== NO_SELECTION && productType !== '') {
    productTypeQuery = `and productType eq '${encodeURIComponent(productType)}'`
  }

  // Note: We currently only use facets for the create order process
  // const generatedQuery = getFacetQuery(getState)

  let endpoint =
    `${azureSearchEndpoint}&search=${formattedQuery}` +
    `&$count=true&$top=50&%24filter=destinationCountries/any(c: c eq '${countryCode}')`

  if (category) {
    if (query && query !== '*') {
      if (sizeSearchQuery) {
        endpoint =
          `${azureSearchEndpoint}&search=${formattedQuery}|${sizeSearchQuery}` +
          `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}')${categoryQuery} ${productTypeQuery} &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}`
      } else {
        endpoint =
          `${azureSearchEndpoint}&search=${formattedQuery}|${skuQuery}` +
          `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}')${categoryQuery} ${productTypeQuery} &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}`
      }
    } else {
      endpoint =
        `${azureSearchEndpoint}&search=${formattedQuery}` +
        `&$count=true&$top=50&$filter=destinationCountries/any(c: c eq '${countryCode}')${categoryQuery} ${productTypeQuery} &scoringProfile=Boost by production country&scoringParameter=prodCountry-${countryCode}`
    }
  }

  endpoint += `&$select=${RETRIEVABLE_PROPERTIES.join()}`

  return endpoint
}

function reverseSizeSearchQuery(query: string): string {
  const wholeQuery = /^\d+X\d+$/i // will match '4x6'
  const spaceAtEnd = /\d+X\d+\s/gi // will match '4x6 print'
  const spaceInFront = /\s\d+X\d+((?=cm)|(\s|$))/gi // will match 'print 4x6', 'block 15x10cm'
  const wholeQueryWithCm = /^\d+X\d+cm$/gi // will match '15x10cm'
  const spaceAtEndWithCm = /\d+X\d+cm\s/gi // will match '15x10cm block'

  if (query.match(wholeQuery)) {
    const match = query.match(wholeQuery)

    if (match) {
      const reversed = match[0].split('x').reverse().join('x')

      return reversed + '*'
    }
  } else if (query.match(spaceInFront)) {
    const match = query.match(spaceInFront)

    if (match) {
      const reversed = ' ' + match[0].trim().split('x').reverse().join('x')

      const replacedWithReversed = query.replace(spaceInFront, reversed)
      return replacedWithReversed + '*'
    }
  } else if (query.match(spaceAtEnd)) {
    const match = query.match(spaceAtEnd)

    if (match) {
      const reversed = match[0].trim().split('x').reverse().join('x') + ' '

      const replacedWithReversed = query.replace(spaceAtEnd, reversed)
      return replacedWithReversed + '*'
    }
  } else if (query.match(wholeQueryWithCm)) {
    const match = query.match(wholeQueryWithCm)

    if (match) {
      const withoutCm = match[0].trim().slice(0, -2)
      const reversed = withoutCm.split('x').reverse().join('x') + 'cm'
      const replacedWithReversed = query.replace(wholeQueryWithCm, reversed)

      return replacedWithReversed + '*'
    }
  } else if (query.match(spaceAtEndWithCm)) {
    const match = query.match(spaceAtEndWithCm)

    if (match) {
      const withoutCm = match[0].trim().slice(0, -2)
      const reversed = withoutCm.split('x').reverse().join('x') + 'cm '

      const replacedWithReversed = query.replace(spaceAtEndWithCm, reversed)
      return replacedWithReversed + '*'
    }
  }

  return ''
}
