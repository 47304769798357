import { useState } from 'react'

import { InsertDataType } from '../../../interfaces'
import { InsertsAddPanel } from './InsertsAddPanel.component'
import { InsertsPanelItem } from './InsertsPanelItem.component'
import { BrandedInsertIdType, BrandingDetailsType } from '../../../hooks'
import { InsertSetSettingsType, InsertsSettingsPanel } from './InsertsSettingsPanel.component'

export function InsertsPanel({
  brandingDetailsWithoutImage,
  insertSetSettings,
  onChangeInsertData,
  onChangeInsertSetSettings
}: {
  brandingDetailsWithoutImage: BrandingDetailsType[]
  insertSetSettings: InsertSetSettingsType
  onChangeInsertData: (newInsertData: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) => void
  onChangeInsertSetSettings: (newInsertSetSettings: InsertSetSettingsType) => void
}) {
  const [selectedPanel, setSelectedPanel] = useState<'add-inserts' | 'settings'>('add-inserts')
  const [selectedInsertId, setSelectedInsertId] = useState<string>('')

  return (
    <div>
      <ul className="flex">
        <InsertsPanelItem isSelected={selectedPanel === 'add-inserts'} onClick={() => setSelectedPanel('add-inserts')}>
          Add inserts
        </InsertsPanelItem>
        <InsertsPanelItem isSelected={selectedPanel === 'settings'} onClick={() => setSelectedPanel('settings')}>
          Settings
        </InsertsPanelItem>
      </ul>

      <div className="border border-gray-200 p-8 lg:p-10">
        {selectedPanel === 'add-inserts' && (
          <InsertsAddPanel
            brandingDetailsWithoutImage={brandingDetailsWithoutImage}
            selectedInsertId={selectedInsertId}
            onChangeSelectedInsertId={setSelectedInsertId}
            onChangeInsertData={(newInsertData) => {
              setSelectedInsertId('')
              onChangeInsertData(newInsertData)
            }}
          />
        )}

        {selectedPanel === 'settings' && (
          <InsertsSettingsPanel
            insertSetSettings={insertSetSettings}
            onChangeInsertSetSettings={onChangeInsertSetSettings}
          />
        )}
      </div>
    </div>
  )
}
