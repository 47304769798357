import {
  calcFillScaleFactor,
  calcPreviewBorder,
  calcPrintDimensionsFillScaleFactor
} from '@prodigi-group/components-image-editor'

import { CropRectangleInterface } from '../../../hooks'
import { BorderUnitType, DimensionsInterface } from '../interfaces'

// In the new image editor adding a border affects the scale.
// This function calculates a value required to revert that change.
export function calculateScaleFactorAdjustment(
  printDimensionsInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  cropRectangle: CropRectangleInterface,
  printDpi: number,
  borderWidth: number,
  borderUnit: BorderUnitType
): number {
  const previewBorder = calcPreviewBorder(printDpi, borderUnit, borderWidth, printDimensionsInPx, cropRectangle)
  const printDimensionsWithBorder = calcPrintDimensionsFillScaleFactor(
    printDimensionsInPx,
    artworkDimensionsInPx,
    previewBorder,
    0
  )
  const printDimensionsWithoutBorder = calcPrintDimensionsFillScaleFactor(
    printDimensionsInPx,
    artworkDimensionsInPx,
    0,
    0
  )
  const printDimensionsDifference = printDimensionsWithoutBorder / printDimensionsWithBorder

  const fillScaleFactorWithBorder = calcFillScaleFactor(artworkDimensionsInPx, cropRectangle, previewBorder)
  const fillScaleFactorWithoutBorder = calcFillScaleFactor(artworkDimensionsInPx, cropRectangle, 0)
  const fillScaleFactorDifference = fillScaleFactorWithoutBorder / fillScaleFactorWithBorder

  return fillScaleFactorDifference / printDimensionsDifference
}
