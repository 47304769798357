// @flow
import type { Element } from 'react'
import React from 'react'

import styles from './Apps.module.css'
import Svg from '../../components/svg'
import { SUPPORT_EMAIL } from '../../data/constants'

type Props = {|
  message?: string,
  willErrorFixByWaiting?: boolean,
|}

export default class ErrorView extends React.PureComponent<Props> {
  getContentForErrorsFixedByWaiting: (() => Element<"div">) = () => {
    return (
      <div className={styles.waitingErrorContainer}>
        <div className={styles.errorLogoContainer}>
          <Svg.Clock />
        </div>
        <div className={styles.errorTextContainer}>
          <h1>Just a moment...</h1>
          <p>
            We’re just getting your apps ready to launch.
            Please wait a brief time and refresh this page.
            If it’s still not ready after a few minutes, please <a href={`mailto:${SUPPORT_EMAIL}`}><b>contact support</b></a> or use the chat widget below.
          </p>
        </div>
      </div>
    )
  }

  getDefaultErrorContent: (() => Element<"div">) = () => {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorLogoContainer}>
          <Svg.ErrorLogo />
        </div>
        <div className={styles.errorTextContainer}>
          <div>
            <h1>Oops!</h1>
            <h1>We have a problem.</h1>
          </div>
          <div className={styles.errorViewMessage}>
            {this.props.message ?? 'An error occurred with managing Apps.'}<br />
              Please refresh the page and try again.<br />
            <a href={`mailto:${SUPPORT_EMAIL}`}><b>Contact support</b></a> if this continues to happen or use the chat widget below.
          </div>
        </div>
      </div>
    )
  }

  render (): React$Node {
    const errorContent = this.props.willErrorFixByWaiting
      ? this.getContentForErrorsFixedByWaiting()
      : this.getDefaultErrorContent()

    return (
      <div className={styles.appsPage}>
        <header className={styles.header}>
          {errorContent}
        </header>
      </div>
    )
  }
}
