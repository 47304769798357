function BigCommerceLogoMarkIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="221 283 170 170"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#34313F"
          d="M310.3,379.2h21.1c6,0,9.8-3.3,9.8-8.6c0-5-3.8-8.6-9.8-8.6h-21.1c-0.7,0-1.3,0.6-1.3,1.2V378
       C309.1,378.7,309.6,379.2,310.3,379.2z"
        />
        <path
          fill="#34313F"
          d="M310.3,413.3h21.8c6.7,0,10.7-3.4,10.7-9.4c0-5.2-3.8-9.4-10.7-9.4h-21.8c-0.7,0-1.3,0.6-1.3,1.2v16.3
       C309.1,412.8,309.6,413.3,310.3,413.3z"
        />
        <path
          fill="#34313F"
          d="M387.5,284.4l-62.4,62.2h10.2c15.9,0,25.3,10,25.3,20.9c0,8.6-5.8,14.9-12,17.7c-1,0.4-1,1.8,0.1,2.2
       c7.2,2.8,12.3,10.3,12.3,19.1c0,12.4-8.3,22.3-24.4,22.3h-44.2c-0.7,0-1.3-0.6-1.3-1.2v-47.1l-68.5,68.2c-0.9,0.9-0.3,2.5,1,2.5
       h164.7c0.6,0,1.1-0.5,1.1-1.1V285.3C389.7,284.2,388.3,283.6,387.5,284.4z"
        />
      </g>
    </svg>
  )
}

export { BigCommerceLogoMarkIcon }
