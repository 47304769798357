import { APP_ENVIRONMENTS } from '../../../constants'
import { UploadFromURL } from './UploadFromURL.component'
import { UploadFromFile } from './UploadFromFile.component'
import { UploadFromDropbox } from './UploadFromDropbox.component'
import { UploadOptionValueType } from './UploadOptions.component'
import { ImageLibraryImageInterface, ImageLibraryImageType } from '../../../hooks'

interface UploadChoiceDisplayPropsInterface {
  isSaveToLibraryChecked: boolean
  type: ImageLibraryImageType
  uploadChoice: UploadOptionValueType
  onUploadStart: () => void
  onUploadComplete: () => void
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function UploadChoiceDisplay({
  isSaveToLibraryChecked,
  type,
  uploadChoice,
  onUploadStart,
  onUploadComplete,
  onSelectImage
}: UploadChoiceDisplayPropsInterface) {
  switch (uploadChoice) {
    case 'file':
      return (
        <UploadFromFile
          isSaveToLibraryChecked={isSaveToLibraryChecked}
          type={type}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
          onSelectImage={onSelectImage}
        />
      )
    case 'url':
      return (
        <UploadFromURL
          isSaveToLibraryChecked={isSaveToLibraryChecked}
          type={type}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
          onSelectImage={onSelectImage}
        />
      )
    case 'dropbox':
      return (
        <UploadFromDropbox
          isSaveToLibraryChecked={isSaveToLibraryChecked}
          type={type}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
          onSelectImage={onSelectImage}
        />
      )
    default:
      const unhandledUploadChoice: never = uploadChoice
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unhandled upload choice ${unhandledUploadChoice} found. Add a new case to fix this.`)
      }
      return null
  }
}
