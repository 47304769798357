import {
  calcPrintDimensionsFillScaleFactor,
  calcPrintDimensionsFitScaleFactor
} from '@prodigi-group/components-image-editor'

import {
  TemplateOrientationType,
  TemplateServiceSuccessResponseInterface
} from '../../../hooks/useTemplateService.hook'
import { ORIENTATION } from '../constants'
import { DimensionsInterface, ArtworkInterface, ArtworkTransformationsInterface } from '../interfaces'

export type ImageSizingType = 'fitPrintArea' | 'fillPrintArea'

const IMAGE_SIZING: Record<string, ImageSizingType> = {
  FIT: 'fitPrintArea',
  FILL: 'fillPrintArea'
}

/* -------------------------------------------------------------------------- */
/*                                   Helper                                   */
/* -------------------------------------------------------------------------- */

export function getDefaultTransformations(
  artwork: ArtworkInterface,
  templates: TemplateServiceSuccessResponseInterface,
  sizing: ImageSizingType,
  printAreaName: string
): ArtworkTransformationsInterface | null {
  const { artworkWidth, artworkHeight } = artwork

  const imageOrientation = determineImageOrientation(artworkWidth, artworkHeight)

  const shouldRotateImage = Boolean(
    sizing === IMAGE_SIZING.FILL && isImageRotationRequired(templates, imageOrientation, printAreaName)
  )

  const preferredTemplateOrientation = shouldRotateImage ? rotateOrientation(imageOrientation) : imageOrientation

  const templateOrientation = selectTemplateOrientation(templates, preferredTemplateOrientation, printAreaName)

  if (!templateOrientation) {
    return null
  }

  return getArtworkTransformations(artwork, templates, templateOrientation, shouldRotateImage, sizing, printAreaName)
}

/* -------------------------------------------------------------------------- */
/*                                Mini helpers                                */
/* -------------------------------------------------------------------------- */

function determineImageOrientation(imageWidth: number, imageHeight: number): TemplateOrientationType {
  const imageAspectRatio = imageWidth / imageHeight
  if (imageAspectRatio === 1) {
    return ORIENTATION.SQUARE
  } else if (imageAspectRatio > 1) {
    return ORIENTATION.LANDSCAPE
  } else {
    return ORIENTATION.PORTRAIT
  }
}

function isImageRotationRequired(
  templates: TemplateServiceSuccessResponseInterface,
  imageOrientation: TemplateOrientationType,
  printAreaName: string
): boolean {
  return (
    imageOrientation !== ORIENTATION.SQUARE &&
    !isProductSquare(templates, printAreaName) &&
    !hasTemplateWithMatchingOrientation(templates, imageOrientation, printAreaName)
  )
}

function isProductSquare(templates: TemplateServiceSuccessResponseInterface, printAreaName: string): boolean {
  const templatesByOrientation = templates.printAreas[printAreaName].orientations
  const templatesAsArray = Object.values(templatesByOrientation)
  const firstAvailableTemplate = templatesAsArray.find((template) => template !== null)

  return Boolean(
    firstAvailableTemplate &&
      firstAvailableTemplate.printResolution.height === firstAvailableTemplate.printResolution.width
  )
}

function hasTemplateWithMatchingOrientation(
  templates: TemplateServiceSuccessResponseInterface,
  orientation: TemplateOrientationType,
  printAreaName: string
): boolean {
  return Boolean(templates.printAreas[printAreaName].orientations[orientation])
}

function rotateOrientation(orientation: TemplateOrientationType): TemplateOrientationType {
  if (orientation === ORIENTATION.SQUARE) {
    return ORIENTATION.SQUARE
  } else if (orientation === ORIENTATION.LANDSCAPE) {
    return ORIENTATION.PORTRAIT
  } else {
    return ORIENTATION.LANDSCAPE
  }
}

function selectTemplateOrientation(
  templates: TemplateServiceSuccessResponseInterface,
  preferredTemplateOrientation: TemplateOrientationType,
  printAreaName: string
) {
  if (templates.printAreas[printAreaName]?.orientations[preferredTemplateOrientation]) {
    return preferredTemplateOrientation
  }

  const defaultTemplates = templates.printAreas[printAreaName]?.orientations ?? {}

  for (const [orientation, templateData] of Object.entries(defaultTemplates)) {
    if (templateData) {
      return orientation as TemplateOrientationType
    }
  }

  return null
}

function getArtworkTransformations(
  artwork: ArtworkInterface,
  templates: TemplateServiceSuccessResponseInterface,
  templateOrientation: TemplateOrientationType,
  shouldRotateImage: boolean,
  sizing: ImageSizingType,
  printAreaName: string
) {
  const { artworkWidth, artworkHeight } = artwork
  const selectedTemplate = templates.printAreas[printAreaName].orientations[templateOrientation]

  if (!selectedTemplate) {
    return null
  }

  const rotationAngle = shouldRotateImage ? 90 : 0

  const artworkDimensionsInPx = { width: artworkWidth, height: artworkHeight }

  const scaleFactor =
    sizing === IMAGE_SIZING.FIT
      ? calculateFitScaleFactor(selectedTemplate.printResolution, artworkDimensionsInPx, 0, rotationAngle)
      : calculateFillScaleFactor(selectedTemplate.printResolution, artworkDimensionsInPx, 0, rotationAngle)

  const transformations = buildArtworkTransformations(scaleFactor, rotationAngle, templateOrientation)

  return transformations
}

function buildArtworkTransformations(
  scaleFactor: number,
  rotationAngle: 0 | 90,
  templateOrientation: TemplateOrientationType
): ArtworkTransformationsInterface {
  return {
    orientation: templateOrientation,
    scaleFactor: scaleFactor,
    position: {
      x: 0,
      y: 0
    },
    rotationAngle: rotationAngle,
    borderFactor: 0,
    borderScale: 'cm',
    isTiled: false
  }
}

function calculateFitScaleFactor(
  printResolutionInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  borderInPx: number,
  rotationAngle: number
): number {
  const scaleFactor = calcPrintDimensionsFitScaleFactor(
    printResolutionInPx,
    artworkDimensionsInPx,
    borderInPx,
    rotationAngle
  )

  return scaleFactor
}

function calculateFillScaleFactor(
  printResolutionInPx: DimensionsInterface,
  artworkDimensionsInPx: DimensionsInterface,
  borderInPx: number,
  rotationAngle: number
): number {
  const scaleFactor = calcPrintDimensionsFillScaleFactor(
    printResolutionInPx,
    artworkDimensionsInPx,
    borderInPx,
    rotationAngle
  )

  return scaleFactor * 100
}
