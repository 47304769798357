import { useState } from 'react'

import {
  formatCost,
  formatToSentenceCase,
  productAttributeFormat,
  reorderAttributes,
  splitByPascalCase
} from '../../../helpers'
import { APPAREL_COLOURS } from '../constants'
import { ProductDetailInterface } from '../../../hooks'
import { buildListOfMissingVariants } from '../helpers'
import { ProductDataInterface, ProductInterface } from '../../../interfaces'

interface Attributes {
  attributes: string[]
  name: string
}
/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export function Variants({
  product,
  productDetails,
  skuSearchResult
}: {
  // TODO: Make required prop when removing split COSTED_ATTRIBUTES: 'dashboard_costed_attributes'
  product?: ProductDataInterface
  productDetails: ProductDetailInterface
  skuSearchResult: ProductInterface
}) {
  const costedAttributes = product?.pwintyProduct?.costedAttributes ?? {}
  const attributes: Attributes[] = []
  let colours: string[] = []

  Object.entries(productDetails.attributes).forEach(([attributeName, attributeOptions]) => {
    const isCostedAttribute = Object.keys(costedAttributes).some(
      (costedAttributeName) => costedAttributeName.toLowerCase() === attributeName.toLowerCase()
    )

    if (attributeOptions.length < 2 || isCostedAttribute) {
      return
    }

    const formattedName = formatToSentenceCase(splitByPascalCase(attributeName))
    if (formattedName.toLowerCase() === 'color' && skuSearchResult.category.toLowerCase() === 'apparel') {
      colours = attributeOptions.map(productAttributeFormat)
    } else {
      const reordered = reorderAttributes(attributeName, attributeOptions, skuSearchResult.category)

      attributes.push({
        attributes: reordered.map(productAttributeFormat),
        name: formattedName
      })
    }
  })

  if (attributes.length === 0) {
    return null
  }

  return (
    <>
      <h2 className="mt-0 text-lg md:mt-8 lg:mt-0 lg:font-normal">Variants</h2>
      <div className="flex flex-col gap-2">
        <>
          {Object.entries(costedAttributes).map(([costedAttributeKey, costedAttributeData]) => {
            if (!costedAttributeData) {
              return null
            }

            return (
              <span className="flex gap-3" key={costedAttributeKey}>
                <strong>{costedAttributeData.name}:</strong>
                <span>
                  {costedAttributeData.options
                    .map((option) => {
                      if (option.price.amount > 0) {
                        return `${option.name} (${formatCost({
                          amount: option.price.amount.toString(),
                          currencyCode: option.price.currency
                        })})`
                      }

                      return option.name
                    })
                    .join(', ')}
                </span>
              </span>
            )
          })}
          {attributes.map((attribute) => {
            return (
              <span className="flex gap-3" key={attribute.name}>
                <strong>{attribute.name}:</strong>
                <span>{attribute.attributes.join(', ')}</span>
              </span>
            )
          })}
          {colours.length > 0 && (
            <div className="mt-8 grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
              {colours.map((colour) => {
                return <ColourSwatch colour={colour} key={colour} />
              })}
            </div>
          )}
          <MissingVariants productDetails={productDetails} />
        </>
      </div>
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
function ColourSwatch({ colour }: { colour: string }) {
  const formattedColour = colour.replace(' ', '_').toLowerCase()
  const formattedMixedColours = formattedColour.includes('/') ? formattedColour.replace('/', '_and_') : formattedColour
  const colourNameWithoutSpaces = formattedMixedColours.replace(/\s+/g, '_')
  const isKnownApparelColour = APPAREL_COLOURS.includes(colourNameWithoutSpaces)

  return (
    <div className="flex items-center gap-4">
      {isKnownApparelColour && (
        <div className="items-center">
          <div
            className="h-12 w-12 rounded-full border border-gray-200 bg-center"
            style={{ backgroundImage: `url('/img/colours/apparel/${colourNameWithoutSpaces}.png')` }}
            title={colour}
          />
        </div>
      )}
      <span className="text-sm">{colour}</span>
    </div>
  )
}

function MissingVariants({ productDetails }: { productDetails: ProductDetailInterface }) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const missingVariants = buildListOfMissingVariants({ productDetails })

  if (missingVariants.length === 0) {
    return null
  }

  return (
    <div className="mt-8 text-sm text-gray-600">
      <span>Some variant combinations are unavailable. </span>
      <button className="text-purple-600" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Hide' : 'Show'}
      </button>
      {isOpen && (
        <ul className="m-4 grid max-w-5xl list-outside list-[square] pl-2 sm:grid-cols-2 lg:grid-cols-3">
          {missingVariants.map((variant, index) => (
            <li className="mr-8" key={index}>
              {variant}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
