// @flow
import { DEFAULT_ATTRIBUTE_NAMES, WIX_VARIANT_HEADER, WIX, UNKNOWN_FORMAT } from '../../data/csvUpload'
import { mapHeaders } from '.'
import type { Dictionary, CsvFileType } from '../../types'
import { values } from '../../helpers/dictionary'

export function splitCsvColumnsIfNecessary (csvRows: Dictionary<string>[], fileType: CsvFileType): Dictionary<string>[] {
  const csvHeaders = Object.keys(csvRows[0])
  const isWixFile = fileType === WIX || (fileType === UNKNOWN_FORMAT && csvHeaders.includes(WIX_VARIANT_HEADER))

  return isWixFile
    ? mapWixRows(csvRows, fileType)
    : csvRows
}

function mapWixRows (csvRows: Dictionary<string>[], fileType: CsvFileType): Dictionary<string>[] {
  const mappedRows = []

  csvRows.forEach(row => {
    if (!row) {
      return
    }

    const reduced = Object.keys(row).reduce((orderAcc, key) => {
      if (key !== WIX_VARIANT_HEADER) {
        orderAcc[key] = row[key]
        return orderAcc
      }

      DEFAULT_ATTRIBUTE_NAMES.forEach(name => { orderAcc[name] = '' })

      const attributes = row[key].split('|')

      attributes.forEach(attributesAndType => {
        const splitAttributeAndType = attributesAndType.split(':')
        const formattedHeader = mapHeaders([splitAttributeAndType[0].trim()], fileType)

        if (Object.keys(formattedHeader).length === 0) {
          return
        }

        const header = values(formattedHeader)[0]
        orderAcc[header] = splitAttributeAndType[1].trim()
      })

      return orderAcc
    }, {})

    mappedRows.push(reduced)
  })

  return mappedRows
}
