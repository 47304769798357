// @flow
import React, { PureComponent } from 'react'
import AddressFormView from './AddressFormView'
import {
  isAddressValid,
  isEmailValid,
  isPostcodeValid,
  isCountyOrStateRequired,
  isPostcodeRequired,
  areAllRequiredFieldsCompleted,
  isPhoneNumberRequired
} from '../../helpers/address'
import type { CustomerAddress } from '../../types'
import type { Node } from 'react'

type Props = {|
  children?: Node,
  address: CustomerAddress,
  onAddressChange: (field: string, value: string) => void,
  onIsAddressValidChange: (isAddressValid: boolean) => void
|}

export const ERROR_MESSAGES = {
  INCOMPLETE_FORM: 'Please complete all required fields.',
  INVALID_POSTCODE: 'Please enter a valid postcode.',
  INVALID_EMAIL: 'Please enter a valid email address.'
}

export default class AddressFormController extends PureComponent <Props> {
  componentDidUpdate: (() => void) = () => this.validateAddress()

  componentDidMount: (() => void) = () => this.validateAddress()

  validateAddress: (() => void) = () => this.props.onIsAddressValidChange(isAddressValid(this.props.address))

  get validationErrorMessage (): ?string {
    const { countryCode, postcode } = this.props.address

    if (!areAllRequiredFieldsCompleted(this.props.address)) {
      return ERROR_MESSAGES.INCOMPLETE_FORM
    } else if (isPostcodeRequired(countryCode) && !isPostcodeValid(countryCode, postcode)) {
      return ERROR_MESSAGES.INVALID_POSTCODE
    } else if (!this.isEmailValid) {
      return ERROR_MESSAGES.INVALID_EMAIL
    } else {
      return null
    }
  }

  get isEmailValid (): boolean {
    return this.props.address.email
      ? isEmailValid(this.props.address.email)
      : true
  }

  render (): React$Node {
    const { postcode, countryCode } = this.props.address
    return (
      <AddressFormView
        name={this.props.address.name}
        address1={this.props.address.address1}
        address2={this.props.address.address2}
        town={this.props.address.town}
        county={this.props.address.county}
        postcode={postcode}
        email={this.props.address.email}
        mobileTelephone={this.props.address.mobileTelephone}
        onChange={this.props.onAddressChange}
        validationErrorMessage={this.validationErrorMessage}
        isPhoneNumberRequired={isPhoneNumberRequired(countryCode)}
        isCountyOrStateRequired={isCountyOrStateRequired(countryCode)}
        isPostcodeRequired={isPostcodeRequired(countryCode)}
        isAddressValid={isAddressValid(this.props.address)}
        isPostcodeValid={isPostcodeValid(countryCode, postcode)}
        isEmailValid={this.isEmailValid}>
        {this.props.children}
      </AddressFormView>
    )
  }
}
